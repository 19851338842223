
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination from "@/hooks/useAntdPagination";
import { Quiz } from "@a4b/api/src/modules/Coins/types";
import moment from "moment";

const ManageQuiz = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const { networkInstance } = useAppContext();
  const { pagination, setPagination } = useAntdPagination();
  const [quizes, setQuizes] = useState<Quiz[]>([])
  const [activeQuizId, setActiveQuizId] = useState<string>();

  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }

  const getQuizes = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.coins.getQuiz()
      setQuizes(res.data.data.map(quiz => {
        quiz.supportedLanguages = Object.keys(quiz.question)
        quiz.start_at = moment(quiz.start_at).local()
        quiz.end_at = moment(quiz.end_at).local()
        return quiz;
      }))
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.coins])

  useEffect(() => {
    getQuizes()
  }, [getQuizes])

  const nextQuestionInitValue = useMemo(() => {
    let maxQuestionCode = undefined;
    let start_at = moment().startOf("day");
    let end_at = moment().endOf("day").add(1, 'day');
    if (quizes.length > 0) {
      let maxInt = 0;
      quizes.forEach((quiz) => {
        maxInt = Math.max(maxInt, parseInt(quiz.code.split("_")[1]))
      })
      let lastQuiz = quizes.find((quiz) => quiz.code === `question_${maxInt}`)
      if (lastQuiz) {
        start_at = moment(lastQuiz.start_at).add(1, 'day').startOf('day');
        end_at = moment(lastQuiz.end_at).add(1, 'day').endOf('day');
      }
      maxQuestionCode = `question_${maxInt + 1}`
    }
    return {
      code: maxQuestionCode,
      start_at,
      end_at
    };
  }, [quizes]);

  const drawerConfig = useDrawerConfig(setActiveDrawer, getQuizes, quizes.find((quiz) => quiz.id === activeQuizId), nextQuestionInitValue, activeDrawer);
  const { drawerProps, drawerContent } = drawerConfig;
  const tableConfig = useTableConfig(quizes, setActiveQuizId, setActiveDrawer, pagination, setPagination, setDrawer,);


  const onCreateRashipal = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_QUIZ);
  }

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  }

  return <SocialScreenContainer
    title={"Manage quiz"}
    filtersUI={<Filters />}
    extra={<Button onClick={onCreateRashipal}> Create quiz </Button>}
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />
};

export default withDefaultLayout(ManageQuiz)

