import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Drawer, Form, Input, InputNumber, Row, Select, Switch } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import TextArea from 'antd/lib/input/TextArea';
import { useAppContext } from '@/components/AppContext';
import { A4B_APPS } from '@/utils/constants';

interface Props {
    showItemCreation: boolean
    isEditJourney: boolean
    createItemCallback?: (status: boolean) => void
    closeItemCreation: () => void
    initialValues: any
    form: FormInstance<any>
    formSubmithandler: (values: any, campaignList: any[]) => void
}

const UserSegmentForm: React.FC<Props> = ({
    showItemCreation,
    closeItemCreation,
    createItemCallback,
    isEditJourney,
    initialValues,
    form,
    formSubmithandler
}) => {
    const { networkInstance, app } = useAppContext()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
    const [campaignIdArray, setCampaignIdArray] = useState<any[]>([])
    const [campaignList, setCampaignList] = useState<any>()
    const service_type = [
        {
            label: 'Generic',
            value: 'generic',
        },
        {
            label: 'Offerings',
            value: 'offerings',
        },
        {
            label: 'Pooja',
            value: 'pooja',
        },
        {
            label: 'Astro',
            value: 'astro'
        },
        {
            lebel: 'Wallet',
            value: 'wallet',
        }
    ]

    const getCampaignIds = (campaigns: any) => {
        let campaignArray: any[] = []
        campaigns?.map((campaign: any) => campaignArray.push([campaign?.id, campaign?.name]))
        setCampaignIdArray(campaignArray)
    }

    const getCampaignList = useCallback((async () => {
        try {
            const CampaignListResponse = await networkInstance.clientWithHeaders.monetisationPujaApi.campaignsAPi.getCampaignList({
                limit: 1000,
                offset: 0,
            }, true)
            setCampaignList(CampaignListResponse?.data?.data?.campaigns)
            getCampaignIds(CampaignListResponse?.data?.data?.campaigns)
        } catch (error) {
            console.log("Error: ", error)
        }
    }), [networkInstance.clientWithHeaders.monetisationPujaApi.campaignsAPi])

    useEffect(() => {
        if (isEditJourney) {
            form.setFieldsValue(initialValues);
        } else {
            form.resetFields()
        }
    }, [form, initialValues, isEditJourney])

    useEffect(() => {
        getCampaignList()
    }, [getCampaignList])

    const handleSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                formSubmithandler(values, campaignList)
            })
            .catch(error => {
                console.log('Temple Creation error')
            })

    }

    return (
        <>
            <Drawer
                title={`${isEditJourney ? "Update" : "Create New"}  User segment`}
                placement='right'
                onClose={closeItemCreation}
                visible={showItemCreation}
                destroyOnClose={true}
                size='default'
            >
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={handleSubmit}
                    initialValues={initialValues}
                >
                    <Col>
                        <Form.Item
                            label='Name'
                            name='name'
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                            rules={[{ required: true, message: 'Enter name' }]}
                        >
                            <Input placeholder='Enter Name' />
                        </Form.Item>

                        <Form.Item
                            label='Type'
                            name='type'
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                            rules={[{ required: true, message: 'Enter type' }]}
                        >
                            <Input placeholder='Enter Type' />
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.type !== currentValues.type
                            }
                        >
                            {({ getFieldValue }) => (
                                getFieldValue("type") === "new_user" ?
                                    <Form.Item
                                        label='User Id'
                                        name='user_ids'
                                        labelCol={{ span: 28 }}
                                        wrapperCol={{ span: 28 }}
                                        rules={[{ required: false }]}
                                    >
                                        <TextArea placeholder='Enter User Ids' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='User Id'
                                        name='user_ids'
                                        labelCol={{ span: 28 }}
                                        wrapperCol={{ span: 28 }}
                                        rules={[{ required: true, message: 'Enter User Ids' }]}
                                    >
                                        <TextArea placeholder='Enter User Ids' />
                                    </Form.Item>
                            )}
                        </Form.Item>


                        {campaignIdArray && <Form.Item
                            label='Campaigns'
                            name='campaigns'
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 22 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter campaign id',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                size='middle'
                                placeholder="Please select"
                                defaultValue={[]}
                                style={{ width: '100%' }}
                                allowClear
                            >
                                {campaignIdArray?.map((id: any) => (
                                    <Select.Option key={id[1]} value={id[1]}>
                                        {id[1]}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>}
                        <Form.Item
                            label='SKU Order'
                            name='sku_order'
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 22 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Select SKU Order',
                                },
                            ]}
                        >
                            <Select
                                size='middle'
                                placeholder="Please select"
                                style={{ width: '100%' }}
                                allowClear
                            >
                                {[{ name: "Ascending", value: "ASC" },
                                { name: "Descending", value: "DESC" }]?.map((data: any) => (
                                    <Select.Option key={data["value"]} value={data["value"]}>
                                        {data["name"]}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label='Service Type'
                            name='service_type'
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 22 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Service Type',
                                },
                            ]}
                        >
                            <Select
                                size='middle'
                                placeholder="Please select"
                                style={{ width: '100%' }}
                                allowClear
                                options={service_type}
                            >
                            </Select>
                        </Form.Item>

                        <Row justify='space-between' style={{ width: '90%' }}>
                            <Form.Item
                                label='Position'
                                name='position'
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 22 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter position',
                                    },
                                ]}
                            >
                                <InputNumber placeholder='Enter position' />
                            </Form.Item>
                            <Form.Item label="Active status" name="active" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                        </Row>

                        <Form.Item
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ paddingTop: '30px' }}
                        >
                            {isEditJourney ? (
                                <Button type='primary' htmlType="submit">
                                    Update User segment
                                </Button>
                            ) : (
                                <Button type='primary' htmlType="submit">
                                    Create User segment
                                </Button>
                            )}
                        </Form.Item>

                    </Col>
                </Form>
            </Drawer >
        </>
    )
}

export default UserSegmentForm
