import { CloseCircleOutlined, ExclamationCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { Alert, Button, Checkbox, Form, FormInstance, Input, Modal, Select } from "antd";
import styled from "styled-components";
import { DESTINATIONS, DESTINATIONS_ARRAY, EVENT_TYPES, PLATFORM_TYPES, PLATFORM_TYPES_ARRAY, PRODUCER_TYPES, PROPERTY_DATA_TYPES } from "../utils/constants";
import { useAppContext } from "../../../../../components/AppContext";
import React, { useCallback, useEffect } from "react";
import { EventGroup, EventPayload } from "@a4b/api/src/modules/Events/types";
import { ONLY_ALPHA_NUMARIC_RULE, REQUIRED_RULE } from "../../../../../utils/form.validation";
import TextArea from "antd/lib/input/TextArea";
import PropertyForm from "./PropertyForm";
import { useWatch } from "antd/lib/form/Form";

const PropertyFieldsContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 25px;
  didplay: flex;
  border-radius: 5px;
  border: 1px dashed #000;
  margin-bottom: 20px;
`;

const SubPropertyContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const PropertiesContainer = styled.div`
    background: #cccccc;
    padding: 20px;
    margin-bottom: 20px;
`

const renderOptions = (options: string[]) => {
    return options.map((option) => {
        return <Select.Option key={option} value={option}>{option}</Select.Option>
    })
}
interface CreateDefinationsProps {
    form: FormInstance<EventPayload>
    formData?: EventPayload,
    isTouched?: boolean
}

const CreateDefinations = ({ form, formData, isTouched }: CreateDefinationsProps) => {
    const { userProfile, networkInstance } = useAppContext();
    const [groups, setGroups] = React.useState<EventGroup[]>([]);
    const getGroups = useCallback(async () => {
        try {
            const response = await networkInstance.clientWithHeaders.events.getGroups();
            setGroups(response.data.data.groups);
        } catch (error) {

        }
    }, [networkInstance])

    useEffect(() => {
        getGroups();
    }, [getGroups])

    useEffect(() => {
        if (userProfile?.email) {
            setTimeout(() => {
                form.setFieldValue('updated_by', userProfile?.email || 'NA')
                form.setFieldValue('created_by', userProfile?.email || 'NA')
            }, 1000)
        }
    }, [form, userProfile])

    const name = useWatch("name", form)

    return <>
        {
            formData &&
            <>
                <Form.Item
                    style={{ display: 'none' }}
                    name="id"
                    label="id"
                >
                    <Input disabled={!!formData} />
                </Form.Item>
            </>
        }
        {name?.length > 40 && <Alert style={{ marginBottom: 10 }} showIcon message="The event name is bigger than 40 character. This event won't be visible on firebase." type="warning" />}
        <Form.Item
            name="name"
            label="Name"
            rules={formData ? [] : [
                { required: true, message: 'Please input your Name!' },
                ONLY_ALPHA_NUMARIC_RULE
            ]}
        >
            <Input disabled={!!formData} />
        </Form.Item>

        <Form.Item
            name="description"
            label="Description"
            rules={[{ max: 1000, message: 'Max 1000 characters allowed.' }]}
        >
            <TextArea />
        </Form.Item>
        <Form.Item
            name="group"
            label="Group"
            rules={REQUIRED_RULE}
        >
            <Select showSearch>{renderOptions(groups.map(g => g.name))}</Select>
        </Form.Item>
        <Form.Item
            name="tags"
            label="Tags"
            rules={[{
                validator(rule, value, callback) {
                    let isFailsCharLen = false;
                    for (let i = 0; i < value?.length; i++) {
                        if (value[i].length > 40) {
                            isFailsCharLen = true;
                        }
                    }
                    if (isFailsCharLen) {
                        callback("Max 40 characters allowed in a tag.");
                    } else {
                        callback(undefined);
                    }
                },
            }]}
        >
            <Select mode="tags" />
        </Form.Item>
        <PropertiesContainer>
            <Form.List name="properties" initialValue={[]}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => {
                            return <PropertyFieldsContainer>
                                <PropertyForm form={form} name={name} remove={remove} />
                                <Form.Item shouldUpdate={(prevValues, curValues) => prevValues['properties'][name]?.data_type !== curValues['properties'][name]?.data_type}>
                                    {() => {
                                        const dataType = form.getFieldValue(['properties', name, 'data_type']);
                                        return (
                                            (dataType === 'OBJECT' || dataType === 'ARRAY_OBJECT') &&
                                            <Form.List name={[name, "sub_properties"]} initialValue={[{}]}>
                                                {(subFields, { add: addSub, remove: removeSub }) => (
                                                    <>
                                                        <SubPropertyContainer >
                                                            {subFields.map(({ key: subKey, name: subName, ...restSubField }) => (
                                                                <PropertyFieldsContainer style={{ width: "48%", padding: '20px', fontSize: "6px" }}>
                                                                    <PropertyForm parentDataType={dataType} size="small" form={form} key={subKey} name={subName} remove={removeSub} />
                                                                </PropertyFieldsContainer>
                                                            ))}
                                                        </SubPropertyContainer>
                                                        <Button size="small" onClick={() => { addSub({}) }}> Add new Subproperty <PlusCircleFilled /></Button>
                                                    </>
                                                )}
                                            </Form.List>
                                        );
                                    }}
                                </Form.Item>
                            </PropertyFieldsContainer>
                        })}
                        <Button onClick={() => { add({}) }}> Add new property <PlusCircleFilled /></Button>
                    </>
                )}
            </Form.List>
        </PropertiesContainer >
        <Form.Item
            name="producer"
            label="Producer"
            rules={[{ required: true, message: 'Please input producer!' }]}
        >
            <Select showSearch> {renderOptions(PRODUCER_TYPES)} </Select>
        </Form.Item>
        <Form.Item
            name="event_type"
            label="Event Type"
            rules={[{ required: true, message: 'Please input your Type!' }]}
        >
            <Select showSearch> {renderOptions(EVENT_TYPES)} </Select>
        </Form.Item>
        <Form.Item
            name="platform_type"
            label="Platform Type"
            rules={REQUIRED_RULE}
        >
            <Select onChange={(value) => {
                if (value === PLATFORM_TYPES.BACKEND) {
                    const destinations: DESTINATIONS[] = form.getFieldValue('destinations') || [];
                    if (!destinations.includes(DESTINATIONS.MIXPANEL_IMPORT_EVENT)) {
                        form.setFieldValue('destinations', [...destinations, DESTINATIONS.MIXPANEL_IMPORT_EVENT]);
                    }
                }
            }} showSearch> {renderOptions(PLATFORM_TYPES_ARRAY)} </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => { return prevValues.platform_type !== curValues.platform_type }}>
            {() => {

                return form.getFieldValue(['platform_type']) === PLATFORM_TYPES.BACKEND ?
                    <Form.Item
                        name="platform_code"
                        label="Platform Code"
                        rules={[{
                            max: 10,
                            message: 'Max 10 characters allowed.'
                        },
                            ONLY_ALPHA_NUMARIC_RULE
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    : null
            }}
        </Form.Item>
        <Form.Item name="add_destinations" label="Add backend destinations" valuePropName="checked" initialValue={!!(formData?.destinations && formData.destinations.length > 0)}>
            <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.add_destinations !== curValues.add_destinations}>
            {() => {
                return <Form.Item name="destinations" label="Backend Destinations">
                    <Select mode="multiple" disabled={!form.getFieldValue("add_destinations")}>
                        {
                            DESTINATIONS_ARRAY.map((destination) => {
                                return <Select.Option key={destination} value={destination}> {destination}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                    ;
            }}
        </Form.Item>

        <Form.Item
            name="status"
            label="Status"
            initialValue={"DRAFT"}
        >
            <Input disabled />
        </Form.Item>
        {
            formData ? <Form.Item
                name="updated_by"
                label="Updated By"
                rules={[{ required: true, message: 'Please input your Updated By!' }]}
                initialValue={userProfile?.email || "NA"}
            >
                <Input disabled />
            </Form.Item> : <Form.Item
                name="created_by"
                label="Created By"
                rules={[{ required: true, message: 'Please input your Created By!' }]}
                initialValue={userProfile?.email || "NA"}
            >
                <Input disabled />
            </Form.Item>
        }
        {
            formData &&
            <Form.Item name="comment" label="Comment">
                <TextArea />
            </Form.Item>
        }
        {
            formData ? <Form.Item >
                <Button disabled={!isTouched} type="primary" onClick={async () => {
                    const validations = await form.validateFields();
                    console.log({ validations })
                    if (form.getFieldValue('comment')) {
                        form.submit()
                    } else {
                        Modal.confirm({
                            title: `Do you want to save your changes without a comment ?`,
                            icon: <ExclamationCircleFilled />,
                            onOk: () => {
                                form.submit();
                            },
                            onCancel() {

                            },
                        });
                    }
                }}> Submit </Button>
            </Form.Item> :
                <Form.Item >
                    <Button disabled={!isTouched} type="primary" htmlType="submit"> Submit </Button>
                </Form.Item>
        }

    </>
}

export default CreateDefinations;