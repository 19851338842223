import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import Attachment from "@/ui/organisms/Attachment";
import { ATTACHMENT } from "@/utils/enum";
import DeletableContainer from "../deletableContainer/DeletableContainer";
import Riddler from "@/network/Riddler/Riddler";

import { AppShareInstance } from "@/entities/feeds/Widgets";

interface Props {
  riddlerInstance: any;
  contentInstance: AppShareInstance;
}
const AppShareWidget: React.FC<Props> = ({
  riddlerInstance,
  contentInstance,
}) => {
  return (
    <Row>
      <Col span={24}>
        <Form.List name={["content", "items"]}>
          {(fields, { add, remove, move }) => {
            return (
              <div>
                {fields.map((field: any, index: number) => {
                  return (
                    <Form.Item key={field.key}>
                      <DeletableContainer
                        closeHandler={() => {
                          remove(field.name);
                        }}
                        itemText={`Item Attribute : ${index + 1}`}
                        showItemTextInBold={true}
                        moveFunction={move}
                        fields={fields}
                        currentIndex={index}
                        fieldsLength={fields.length}
                        move={move}
                        add={add}
                      >
                        <Row>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              required={true}
                              label="Title"
                              name={[field.name, "title"]}
                              rules={[
                                {
                                  validator: contentInstance.titleValidator,
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              required={true}
                              label="Subtitle"
                              name={[field.name, "subtitle"]}
                              rules={[
                                {
                                  validator: contentInstance.subtitleValidator,
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              required={true}
                              label="Share Text"
                              name={[field.name, "share_text"]}
                              rules={[
                                {
                                  validator: contentInstance.shareTextValidator,
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              required={true}
                              label="CTA Text"
                              name={[field.name, "cta_text"]}
                              rules={[
                                {
                                  validator: contentInstance.ctaTextValidator,
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              label="Image Attachment"
                              name={[field.name, "image_attachment_id"]}
                              rules={[
                                {
                                  validator:
                                    contentInstance.thumbnailImageValidator,
                                },
                              ]}
                            >
                              <Attachment
                                attachmentType={ATTACHMENT.IMAGE}
                                riddlerInstance={riddlerInstance}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </DeletableContainer>
                    </Form.Item>
                  );
                })}
                <Form.Item>
                  <Row justify="end">
                    <Col span={24}>
                      <Row justify="end">
                        <Col span={4}>
                          <Button
                            onClick={() => {
                              add();
                            }}
                            type="dashed"
                            icon={<PlusCircleOutlined />}
                            size="middle"
                          >
                            Add Item Attributes
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Item>
                <div style={{ height: "20px" }}></div>
              </div>
            );
          }}
        </Form.List>
      </Col>
    </Row>
  );
};

export default AppShareWidget;
