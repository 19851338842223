import { AxiosInstance } from "axios";
import { MachineConfig } from "../../utils/types";
import * as URL from "../apiRoutes";
import { getQueryParamByObject } from "../../utils/helper";

const serviceTemplate: MachineConfig = {
  services: {
    fetchData: async (context: any, event: any) => {},
  },
};
const getUploadConfig = (getProgress: any) => {
  return {
    headers: { "content-type": "multipart/form-data" },
    onUploadProgress: (event: any) => {
      const percent = (event.loaded / event.total) * 100;
      getProgress({ percent });
    },
  };
};
class FeedsRiddler {
  api: any;
  interceptorId: string;
  constructor(apiInstance: AxiosInstance) {
    this.api = apiInstance;
    this.interceptorId = this?.api?.interceptors?.response?.use(
      async (res: any) => res?.data?.data || res,
      async (error: any) => error?.response?.data?.error || error
    );
  }
  uploadImage = async (options: any, formData: any) => {
    const { onProgress, setProgressToState } = options;

    const config = getUploadConfig((progress: any) => {
      let { percent } = progress;
      const flooredPercent = Math.floor(percent);
      setProgressToState(flooredPercent);
      if (percent === 100) {
        setTimeout(() => setProgressToState(0), 1000);
      }
      onProgress({ percent });
    });
    return this.api.post(URL.UC_FILE_UPLOAD, formData, config);
  };
  getApiInstance() {
    return this.api;
  }
  getUCCodeForYoutubeLink(formData: any) {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    return this.api.post(URL.UC_FILE_UPLOAD, formData, config);
  }
}
export default FeedsRiddler;
