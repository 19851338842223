import { useAppContext } from '@/components/AppContext'
import React, { useCallback, useEffect, useState } from 'react'
import { message, Modal } from 'antd'
import type { TablePaginationConfig } from 'antd/es/table'
import ManageSections from '@a4b/ui/src/modules/monetisation/organisms/ManageSections'
import {
  EditSection,
  ManageSectionItem
} from '@a4b/api/src/modules/Monetisation/Offerings/types'
import CreateSection from '../CreateSection'
import { parseDataOnEdit } from './helper'

interface TabProps {
  currentTab: string
}
const ManageSectionScreen: React.FC<TabProps> = ({ currentTab }) => {
  const { networkInstance } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

  const [sectionList, setSectionList] = useState<ManageSectionItem[]>([])
  const [showItemCreation, setShowItemCreation] = React.useState(false)
  const [isEditJourney, setIsEditJourney] = React.useState(false)
  const [showSectionEnableModal, setShowSectionEnableModal] = useState(false)
  const [showSectionDisableModal, setShowSectionDisableModal] = useState(false)
  const [totalRecordsCount, setTotalRecordsCount] = useState(0)
  const [paginationParams, setPaginationParams] =
    useState<TablePaginationConfig>({
      current: 1,
      pageSize: 10,
    })

  const [editSection, setEditSection] = useState<EditSection>()
  const [sectionForStatusUpdate, setSectionForStatusUpdate] =
    useState<ManageSectionItem>()
  const rowSelectionChange = (selectedRowKeys: any) => {
    setRowSelectionConfig({
      ...rowSelectionConfig,
      selectedRowKeys: [...selectedRowKeys],
    })
  }

  const [rowSelectionConfig, setRowSelectionConfig] = useState<any>({
    selectedRowKeys: [],
    onChange: rowSelectionChange,
  })

  const getSections = useCallback(async () => {
    try {
      const sections = await monetisationOfferingsApi.storesApi.getSections({
        limit: paginationParams.pageSize,
        offset:
          Number((paginationParams?.current || 1) - 1) * Number(paginationParams.pageSize),
      })
      setSectionList(sections?.data?.data?.sections)
      setPaginationParams({ ...paginationParams, total: sections?.data?.data?.count || 10 })
    } catch (error) {
      message.error({
        content: 'Error while fetching sections',
        duration: 3,
      })
      console.log(error)
    }
  }, [monetisationOfferingsApi.storesApi, paginationParams?.current])

  useEffect(() => {
    if (currentTab !== "sections") {
      return;
    }
    getSections()
  }, [getSections, currentTab])

  const onCreateNew = () => {
    setShowItemCreation(true)
    setIsEditJourney(false)
    setEditSection(undefined)
  }

  const onFinishFailed = (error: any) => {
  }
  const showItemForm = () => {
    setShowItemCreation(true)
  }
  const closeItemCreationForm = () => {
    setShowItemCreation(false)
    isEditJourney && setIsEditJourney(false)
    setEditSection(undefined)
    setTimeout(() => {
      getSections()
    }, 500)
  }
  const onStatusChange = async (section: ManageSectionItem) => {
    setSectionForStatusUpdate(section)
    if (section?.active) {
      setShowSectionDisableModal(true)
    } else {
      setShowSectionEnableModal(true)
    }
  }

  const triggerEdit = async (sectionItem: ManageSectionItem) => {
    try {
      const sectionResponse = await monetisationOfferingsApi.storesApi.getSectionById(sectionItem?.id || '')
      const responseData = parseDataOnEdit(sectionResponse)
      setEditSection(responseData)
    } catch (error) {
      console.log(error)
      message.error({
        content: 'Error while fetching category.',
        duration: 2,
      })
    }
    setIsEditJourney(true)
    setShowItemCreation(true)
  }

  const triggerDelete = async (section: ManageSectionItem) => {
    try {
      await monetisationOfferingsApi.storesApi.deleteStoreCard(section?.id || '')
      message.success({
        content: 'Store card deleted successfully.',
        duration: 2,
      })

    } catch (error) {
      console.log(error)
      message.error({
        content: 'Error while deleting store card.',
        duration: 2,
      })
    }
  }

  const onItemCreateHandler = () => {
    getSections()
  }

  const onModalOk = async () => {
    message.loading({
      content: 'Updating section status',
      duration: 1,
      key: 'update_section',
    })

    try {
      const sectionResponse =
        await monetisationOfferingsApi.storesApi.getSectionById(sectionForStatusUpdate?.id || '')
      const editForm: any = {
        ...sectionResponse?.data?.data,
      }
      const section_type = editForm?.section_type
      const item_type = editForm?.item_type

      if ((section_type === 'hero' || section_type === 'list_item_small' || section_type === 'list_item_large') && item_type === 'sku') {
        const filteredStoreCard: any = editForm?.data?.map(
          (storeCard: any) => storeCard.id
        )
        editForm["data"] = filteredStoreCard
      }
      if ((section_type === 'hero' || section_type === 'list_item_small' || section_type === 'list_item_large') && item_type === 'store') {
        const filteredStoreList: any = editForm?.data.map(
          (storeCard: any) => storeCard.id
        )
        editForm["data"] = filteredStoreList
      }
      if (section_type === 'category') {
        const filteredCategories: any = editForm?.data?.map(
          (form_category: any) => form_category?.category?.id
        )
        editForm["data"] = filteredCategories
      }

      await monetisationOfferingsApi.storesApi.updateSection(
        sectionForStatusUpdate?.id || '',
        { ...editForm, active: !sectionResponse?.data?.data?.active },
      )
      setTimeout(() => {
        getSections()
      }, 500)
      setShowSectionEnableModal(false)
      setShowSectionDisableModal(false)
      message.success({
        content: 'Section card status updated sucessfully',
        duration: 3,
        key: 'update_section',
      })
    } catch (error) {
      setShowSectionEnableModal(false)
      setShowSectionDisableModal(false)
      message.error({
        content: 'Error while updating store card status',
        duration: 2,
        key: 'update_section',
      })

      console.log(error)
    }
  }

  const onModalCancel = () => {
    setShowSectionEnableModal(false)
    setShowSectionDisableModal(false)
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPaginationParams({
      ...pagination,
    })
  }
  return (
    <div>
      <ManageSections
        onCreateNew={onCreateNew}
        onStatusChange={onStatusChange}
        triggerEdit={triggerEdit}
        triggerDelete={triggerDelete}
        dataSource={sectionList}
        rowSelectionConfig={rowSelectionConfig}
        totalRecordsCount={totalRecordsCount}
        paginationParams={paginationParams}
        handleTableChange={handleTableChange}
      />
      {
        <CreateSection
          showItemCreation={showItemCreation}
          closeItemCreation={closeItemCreationForm}
          createItemCallback={onItemCreateHandler}
          UpdateItemCallback={onFinishFailed}
          isEditJourney={isEditJourney}
          formData={editSection}
        />
      }
      <Modal
        title='Activate Section'
        visible={showSectionEnableModal}
        onOk={onModalOk}
        onCancel={onModalCancel}
        okText=' Yes, Activate'
      >
        <p>Are you sure to activate this section ?</p>
      </Modal>
      <Modal
        title='Deactivate Section'
        visible={showSectionDisableModal}
        onOk={onModalOk}
        onCancel={onModalCancel}
        okText='Yes, Deactivate'
      >
        <p>Are you sure to Deactivate this section ?</p>
      </Modal>
    </div>
  )
}

export default ManageSectionScreen
