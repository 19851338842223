import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { debounce } from 'lodash'
import moment from 'moment'

import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
  CreateEditSchedule,
  CreateEditScheduleFormFields,
  SortableItemData,
} from '@a4b/ui/src/admin'

import { ContentTag, CreateScheduleOptions, ScheduleType } from '@a4b/api'

export const TagScheduleCreateEdit = () => {
  const [searchParams] = useSearchParams()
  const { scheduleId } = useParams()
  const navigate = useNavigate()
  const [searchItems, setSearchItems] = useState<SortableItemData[]>([])
  const [orderingItems, setOrderingItems] = useState<SortableItemData[]>([])
  const [date, setDate] = useState(searchParams.get('date') as string)
  const [type, setType] = useState<ScheduleType>(searchParams.get('type') as ScheduleType)
  const [language, setLanguage] = useState<string>(searchParams.get('language') as string)
  const { networkInstance, userProfile } = useAppContext()
  useEffect(() => {
    async function fetchScheduleDetails() {
      if (!scheduleId) {
        throw new Error("ScheduleId can't be empty")
      }
      const { data } =
        await networkInstance.clientWithHeaders.contentApi.schedule.getScheduleById(
          scheduleId,
        )
      const scheduleData = data.data
      setType(scheduleData.entity as ScheduleType);
      const { data: tagData } =
        await networkInstance.clientWithHeaders.contentApi.tag.getTags({
          ids: data.data.ordering,
          limit: data.data.ordering.length,
          language
        })

      setDate(moment(scheduleData.date).format('YYYY-MM-DD'))
      const orderingItems = scheduleData.ordering.map((order: string, index: number) => {
        const orderItem = tagData?.data.tags?.find((item: ContentTag) => item.id === order)
        if (!orderItem) return null
        return {
          id: orderItem.id,
          imageUrl: orderItem.image,
          name: orderItem.name,
        }
      }).filter((item: SortableItemData | null) => item !== null) as SortableItemData[]
      setOrderingItems(orderingItems)
    }
    if (scheduleId) fetchScheduleDetails()
  }, [language, networkInstance.clientWithHeaders.contentApi.schedule, networkInstance.clientWithHeaders.contentApi.tag, scheduleId])

  const onItemSearch = useCallback(
    async (value: string, offset?: number) => {
      const { data } = await networkInstance.clientWithHeaders.contentApi.tag.getTags({
        name: value,
        active: true,
        limit: 30,
        offset: (offset || 0) * 30,
        language
      })
      const tags: ContentTag[] = data.data.tags || []
      const searchItemData: SortableItemData[] = tags.map(tag => ({
        id: tag.id,
        name: tag.name,
        imageUrl: tag.image,
      }))
      setSearchItems(searchItemData)
    },
    [language, networkInstance.clientWithHeaders.contentApi.tag],
  )

  useEffect(() => {
    onItemSearch('')
  }, [onItemSearch])

  const handleAddItem = async (item: SortableItemData) => {
    const itemIndex = orderingItems.findIndex(
      orderItem => orderItem.id === item.id,
    )
    if (itemIndex === -1) {
      orderingItems.push(item)
      setOrderingItems([...orderingItems])
      return true
    }
    return false
  }

  const handleRemoveItem = (item: SortableItemData) => {
    const updatedOrderingItems = orderingItems.filter(
      orderingItem => orderingItem.id !== item.id,
    )
    setOrderingItems(updatedOrderingItems)
  }

  const getFormData = (): Partial<CreateEditScheduleFormFields> => {
    return {
      name: `Tag schedule for ${moment(date).format('DD-MMM-YYYY')}`,
      date: moment(date),
      ordering: orderingItems,
    }
  }

  const handleSubmitSchedule = async (values: CreateEditScheduleFormFields) => {
    const date = moment().format('YYYY-MM-DD') === values.date.format('YYYY-MM-DD')
      ? moment().add(1, 'minute').toISOString()
      : values.date.startOf('day').toISOString()
    const options: CreateScheduleOptions = {
      name: values.name,
      date,
      ordering: values.ordering.map(item => item.id),
      entity: type as ScheduleType,
      createdBy: userProfile.email || '',
      updatedBy: userProfile.email || '',
      language: language
    }

    if (!scheduleId) {
      try {
        const { data, status } =
          await networkInstance.clientWithHeaders.contentApi.schedule.createSchedule(
            options,
          )
        if (status === 201) {
          navigate('/content/tag-schedule')
          const skippedLibraries = data.data.skippedLibraries
          if (skippedLibraries) {
            return { success: true, message: `Successfully created schedule, skipped ${skippedLibraries}` };
          }
          return { success: true, message: 'Successfully created schedule' };
        }
        return { success: false, message: 'Failed to create schedule' };
      } catch (error) {
        return { success: false, message: 'Failed to create schedule' };
      }
    } else {
      try {
        const response =
          await networkInstance.clientWithHeaders.contentApi.schedule.updateSchedule(
            scheduleId,
            options,
          )
        if (response.status === 204) {
          navigate('/content/tag-schedule')
          return { success: true, message: 'Successfully updated schedule' };
        }
        return { success: false, message: 'Failed to update schedule' };
      } catch (error) {
        return { success: false, message: 'Failed to update schedule' };
      }
    }
  }

  const itemSearchDebounce = debounce(onItemSearch, 1000)

  return (
    <CreateEditSchedule
      date={date}
      title={`Schedule for ${type === ScheduleType.TAG_SCHEDULE ? 'Home' : 'Feature'} tag`}
      data={getFormData()}
      items={searchItems}
      onSearch={itemSearchDebounce}
      onAddItem={handleAddItem}
      onRemoveItem={handleRemoveItem}
      onSubmit={handleSubmitSchedule}
    />
  )
}

export default withDefaultLayout(TagScheduleCreateEdit)
