import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'

import { useEffect, useState, useRef, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { MusicMetaDataFormFields, MusicMetaDataUpdate, RiddlerAudioType } from '@a4b/ui/src/admin'
import { RcFile } from 'antd/lib/upload'
import { AudioData, AudioTag } from '@a4b/api'
import { CDN_BASE_URL, NEW_CDN_HOST } from '@/utils/constants'
import { debounce } from 'lodash'
import { AudioThumbnail, AudioUploadThumbnailOptions, VideoTemplate } from '@a4b/api/src/modules/Content/Audio/types'
import { openNotification } from '@/utils'


async function getMusicMetaData(data: AudioData): Promise<MusicMetaDataFormFields> {
  let lyrics = '';
  if (data.lyrics_path) {
    const response = await fetch(`${NEW_CDN_HOST}${data.lyrics_path}`, { method: 'GET' })
    lyrics = await response.text()
  }
  return {
    audio_language: data.audio_language,
    singer_name: data.singer_name,
    audio_type: data.audio_type,
    audio_title: data.audio_title,
    tags: data.tags,
    lyrics,
    //@ts-ignore 
    thumbnail_path: data.thumbnail_path,
    riddler_audio_id: data.riddler_audio_id || null,
    riddler_audio_type: data.riddler_audio_type as RiddlerAudioType,
    riddler_deeplink: data.riddler_deeplink || null,
    riddler_god_id: data.riddler_god_id || null,
    video_share_path: data.video_share_path || null,
    lyrics_path: data.lyrics_path,
    audio_title_search: data.audio_title_search,
    singer_name_search: data.singer_name_search,
    video_share_play_duration: data.video_share_play_duration,
    video_share_start_duration: data.video_share_start_duration,
    video_template_path: data.video_template_path,
    new_god_id: data.new_god_id,
    banner_image_path: data.banner_image_path,
    background_image_path: data.background_image_path,
    audio_types: data.audio_types,
    banner_titles: data.banner_titles,
    new_god_ids: data.new_god_ids,
    suggestive_tool_tip_texts: data.suggestive_tool_tip_texts
  }
}


const MusicCreateEdit = () => {
  const navigate = useNavigate()
  const { audioId } = useParams()
  const { networkInstance, userProfile } = useAppContext()
  const { clientWithHeaders } = networkInstance
  const [formData, setFormData] = useState<MusicMetaDataFormFields>()
  const [tags, setTags] = useState<AudioTag[]>([])
  const [thumbnail, setThumbnail] = useState<AudioThumbnail[]>([])
  const [videoTemplates, setVideoTemplates] = useState<VideoTemplate[]>([])

  // const shouldInit = useRef(true)

  const fetchMusicMetaData = useCallback(async function () {
    if (!audioId) return
    const response = await networkInstance.clientWithHeaders.contentApi.audio.getAudioById(audioId)
    if (response.status === 200) {
      const audioData = response.data.data.audio
      const formData = await getMusicMetaData(audioData)
      setFormData(formData)
    }
  }, [audioId, networkInstance.clientWithHeaders.contentApi.audio])

  useEffect(() => {
    fetchMusicMetaData()
  }, [fetchMusicMetaData])

  const uploadThumbnailS3 = async (
    file: RcFile | Blob,
  ) => {
    const { data } =
      await networkInstance.clientWithHeaders.contentApi.audio.getUrlToUploadThumbnail()

    const s3SignedUploadUrl = data.data.url
    const path = data.data.path

    const headers = new Headers()
    headers.append('ContentType', file.type)

    await fetch(s3SignedUploadUrl, {
      method: 'PUT',
      body: file,
      headers,
    })
    return path
  }

  const onTagSearch = useCallback(
    async (tag: string) => {
      const response = await networkInstance.clientWithHeaders.contentApi.audio.getTagsForAudio({
        tag
      })
      setTags(response.data.data.tags || [])
    },
    [networkInstance.clientWithHeaders.contentApi.audio],
  )

  const onSearchThumbnail = useCallback(
    async (code: string) => {
      const response = await networkInstance.clientWithHeaders.contentApi.audio.getThumbnailAudio({
        code
      })
      setThumbnail(response.data.data.thumbnails || [])
    },
    [networkInstance.clientWithHeaders.contentApi.audio],
  )



  const uploadLyrics = useCallback(async (lyrics: string) => {
    const { data } = await networkInstance.clientWithHeaders.contentApi.audio.getUrlToUploadLyrics()

    const s3SignedUploadUrl = data.data.url
    const path = data.data.path

    const file = new Blob([lyrics], {
      type: 'text/x-markdown',
    })
    const headers = new Headers()
    headers.append('ContentType', file.type)

    await fetch(s3SignedUploadUrl, {
      method: 'PUT',
      body: file,
      headers,
    })
    return path
  }, [networkInstance.clientWithHeaders.contentApi.audio])

  const tagSearchDebounceFn = debounce(onTagSearch, 1000)

  useEffect(() => {
    onTagSearch('')
  }, [onTagSearch])


  const handleSubmit = async (payload: any) => {
    const {
      audio_language,
      audio_title,
      tags,
      singer_name,
      lyrics,
      thumbnail_path,
      riddler_audio_type,
      riddler_audio_id,
      riddler_deeplink,
      new_god_id,
    } = payload;
    if (!audioId) return { success: false, message: 'failed to update music meta data' }
    let lyricsPath;
    if (lyrics !== formData?.lyrics) {
      lyricsPath = await uploadLyrics(lyrics)
    } else {
      lyricsPath = formData?.lyrics_path || null
    }
    const response = await networkInstance.clientWithHeaders.contentApi.audio.updateAudioMetaData({
      ...payload,
      audio_id: audioId,
      audio_language,
      audio_title,
      tags,
      singer_name,
      lyrics_path: lyricsPath,
      thumbnail_path: (thumbnail_path && thumbnail_path.length > 0) ? thumbnail_path : null,
      riddler_audio_type: riddler_audio_type ? riddler_audio_type : null,
      riddler_audio_id: riddler_audio_id ? riddler_audio_id : null,
      riddler_deeplink: riddler_deeplink ? riddler_deeplink : null,
      new_god_id: new_god_id ? new_god_id : null,
    })
    fetchMusicMetaData();
    if (response.status === 200) {
      return { success: true, message: 'successfully updated music meta data' }
    } else {
      return { success: false, message: 'failed to update music meta data' }
    }
  }

  const uploadThumbnail = async (options: AudioUploadThumbnailOptions) => {
    try {
      const res = await networkInstance.clientWithHeaders.contentApi.audio.uploadThumbnail(options);
      return res.data.data.message
    } catch (error) {
      return 'Failed to upload thubnail';
    }
  }


  const onSearchVideoTemplates = useCallback(async (code: string) => {
    try {
      const res = await networkInstance.clientWithHeaders.contentApi.audio.getVideoTemplates(50, 0, code);
      setVideoTemplates(res.data.data.video_templates);
    } catch (error) {
      console.log({ error })
      openNotification('error', { message: 'Failed to load video templates ' })
    }
  }, [networkInstance.clientWithHeaders.contentApi.audio])


  const uploadMedia = useCallback(async (options: any): Promise<{ url: string, path: string }> => {
    const headers = new Headers()
    headers.append('ContentType', options.file.type)
    let data;
    if (options.file.type.endsWith('png')) {
      data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_png');
    }
    if (options.file.type.endsWith('jpg')) {
      data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_jpg');
    }
    if (options.file.type.endsWith('jpeg')) {
      data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_jpeg');
    }
    if (options.file.type.endsWith('gif')) {
      data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_gif');
    }
    if (options.file.type.endsWith('webp')) {
      data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_webp');
    }
    if (!data) {
      throw "Frailed to get presinged url"
    }

    const { url, path } = data.data.data;
    const res = await fetch(url, {
      method: 'PUT',
      body: options.file,
      headers,
    })
    return { url, path }
  }, [networkInstance.clientWithHeaders.contentApi.audio])


  const searchVideoTemplates = debounce(onSearchVideoTemplates, 1000)


  return (
    <div>
      <MusicMetaDataUpdate
        data={formData}
        uploadThumbnailS3={uploadThumbnailS3}
        tags={tags}
        thumbnail={thumbnail}
        onSearchThumbnail={onSearchThumbnail}
        onTagSearch={tagSearchDebounceFn}
        onSubmit={handleSubmit}
        uploadThumbnail={uploadThumbnail}
        CDN_BASE_URL={CDN_BASE_URL}
        videoTemplates={videoTemplates}
        onSearchVideoTemplates={searchVideoTemplates}
        uploadDefaultBannerImage={uploadMedia}
        uploadBackgroundImage={uploadMedia} />
    </div>
  )
}

export default withDefaultLayout(MusicCreateEdit)
