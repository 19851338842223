/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
//types
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Switch, Table } from 'antd';
import type { TablePaginationConfig } from 'antd/es/table';
import styled from 'styled-components';
import { Sections } from '@a4b/api/src/modules/Monetisation/Astrologer/types'

import React from 'react';

import {
    ManageCategoryItem,
    ManageSectionItem,
    ManageStoreCard, StoreCard
} from '@a4b/api/src/modules/Monetisation/offerings/types'

interface Props {
    onCreateNew: () => void
    triggerEdit: (storeCard: any) => void
    dataSource: Sections[]
    rowSelectionConfig: any
    paginationParams: TablePaginationConfig
    handleTableChange: (paginationParams: TablePaginationConfig) => void
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const AstrologerSectionList: React.FC<Props> = ({
    onCreateNew,
    triggerEdit,
    dataSource,
    paginationParams,
    handleTableChange
}) => {
    const dataColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: '30%',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '20%',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: '15%',
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            width: '15%',
        },
        {
            title: 'Action',
            key: 'action',
            width: '20%',
            render: (text: any, record: any, index: any) => {
                return (
                    <Row style={{ width: 200 }}>
                        <Col span={14}>
                            <Switch
                                checked={record.active}
                            />
                        </Col>
                        <Col span={8}>
                            <Button onClick={() => triggerEdit(record)} type='link'>Edit</Button>
                        </Col>
                    </Row>
                )
            },
        },
    ]
    return (
        <Container style={{ width: '100%' }}>
            <Space direction={'vertical'} style={{ width: '100%' }}></Space>
            <BodyContainer>
                <Button type='text' onClick={onCreateNew} style={{ marginBottom: '10px' }}>
                    <PlusSquareOutlined /> Add New Section(Astrologer)
                </Button>

                <Table
                    style={{ width: "100%" }}
                    //rowSelection={rowSelectionConfig}
                    columns={dataColumns}
                    dataSource={dataSource}
                    pagination={paginationParams}
                    onChange={handleTableChange}
                />
            </BodyContainer>
        </Container>
    )
}

export default AstrologerSectionList
