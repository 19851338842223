import { useAppContext } from "@/components/AppContext";
import { v4 as uuidv4 } from 'uuid';
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber, Radio, Space, Button, Select } from "antd";
import { debounce } from "lodash";
import { useCallback, useEffect } from "react";
import { REQUIRED_RULE, SUPPORTED_IMAGE_ACCEPT_TYPE } from "@/utils/form.validation";
import { Shrine } from "@a4b/api/src/modules/Temple/types";
import FileUpload, { getEditImageFiles } from "@a4b/ui/src/modules/admin/components/FileUpload";
import { actionTypes, initialState } from "./GodForm";

interface GodFormFieldsProps {
    form: any
    editGod: Shrine.God
    state: typeof initialState
    dispatch: React.Dispatch<actionTypes>
    types: any
    showSubmitBtn?: boolean
}

const GodFormFields: React.FC<GodFormFieldsProps> = ({ form, editGod, state, dispatch, types, showSubmitBtn = true }) => {
    const { networkInstance } = useAppContext();
    const codeValidation = state.isCodeValid ? 'success' : 'error';

    useEffect(() => {
        if (editGod) {
            dispatch({ type: types.SET_FREEZE_CODE_FEILD, payload: true })
            dispatch({ type: types.SET_CODE_VALID, payload: true })
            dispatch({ type: types.SET_FREEZE_LANGUAGE_FEILD, payload: true })
        }
    }, [dispatch, editGod, types])

    useEffect(() => {
        if (!editGod) return;
        const godCopy: any = { ...editGod };
        godCopy['largeThumbnail'] = editGod?.thumbnails.large ? getEditImageFiles([{ url: editGod?.thumbnails.large.url, type: editGod?.thumbnails.large.type }]) : undefined;
        godCopy['miniThumbnail'] = editGod?.thumbnails.mini ? getEditImageFiles([{ url: editGod?.thumbnails.mini.url, type: editGod?.thumbnails.mini.type }]) : undefined;
        godCopy['smallRoundThumbnail'] = editGod?.thumbnails.small_round ? getEditImageFiles([{ url: editGod?.thumbnails.small_round.url, type: editGod?.thumbnails.small_round.type }]) : undefined;
        godCopy['smallSquareThumbnail'] = editGod?.thumbnails.small_square ? getEditImageFiles([{ url: editGod?.thumbnails.small_square.url, type: editGod?.thumbnails.small_square.type }]) : undefined;
        godCopy['displayStatus'] = editGod?.festive_special ? 'festive_special' : editGod?.pre_selected ? 'pre_selected' : 'none';
        godCopy['languages'] = [{ language_code: editGod?.language_code }]
        form.setFieldsValue(godCopy)
    }, [editGod, form])

    const checkIsCodeIsUnique = useCallback(async (languageCode: string) => {
        try {
            if (!form.getFieldValue('code')) {
                return false;
            }
            const checkUniqueRes = await networkInstance.clientWithHeaders.shrineApi.checkCodeUnique(form.getFieldValue('code'), languageCode);
            if (checkUniqueRes.data.data.is_unique) {
                if (!state.freezeCodeFeild) {
                    dispatch({ type: types.SET_FREEZE_CODE_FEILD, payload: true })
                }
                return true;
            }
        } catch (error) {

        }
        return false;
    }, [dispatch, form, networkInstance.clientWithHeaders.shrineApi, state.freezeCodeFeild, types])

    const checkIsCodeIsValid = debounce(useCallback(async (code) => {
        try {
            const checkValidRes = await networkInstance.clientWithHeaders.shrineApi.checkCodeValid(code);
            dispatch({ type: types.SET_CODE_VALID, payload: checkValidRes.data.data.is_valid });
        } catch (error) {
            dispatch({ type: types.SET_CODE_VALID, payload: false });
        }
    }, [dispatch, networkInstance.clientWithHeaders.shrineApi, types]), 1000)

    const getFileUploadUrl = useCallback(async (file) => {
        if (!state.freezeCodeFeild && codeValidation === 'success') {
            dispatch({ type: types.SET_FREEZE_CODE_FEILD, payload: true })
        }
        // try {
        const res = await networkInstance.clientWithHeaders.shrineApi.getS3SignedUrl(uuidv4() + file.name, 'system_gods', form.getFieldValue('code'));
        return res.data.data.info
        // } catch (error) {

        // }
    }, [codeValidation, dispatch, form, networkInstance.clientWithHeaders.shrineApi, state.freezeCodeFeild, types])

    const resetForm = () => {
        dispatch({ type: types.RESET_REDUCER, payload: undefined })
        form.resetFields()
    }

    return (
        <>
            <Form.Item
                label="Code"
                name="code"
                rules={[{
                    required: true, max: 70, validator: state.freezeCodeFeild ? undefined : (rule, value, callback) => {
                        let regex = new RegExp(/^[a-z0-9_]+$/);
                        checkIsCodeIsValid(value);
                        if (!regex.test(value)) callback("Only small case english latters, numbers and _ is allowed."); else callback(undefined)
                    }
                }]}
                hasFeedback
                validateStatus={codeValidation}
            >
                <Input disabled={state.freezeCodeFeild} />
            </Form.Item>

            <p style={{ color: 'red' }}>{!state.isCodeValid && 'Please enter a valid code'} </p>
            {
                state.isCodeValid && <>
                    <Form.List name="languages" initialValue={[{ language_code: undefined }]}>
                        {(fields, { add, remove, move }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Space style={{ display: 'flex', flexDirection: 'row', padding: '10px', justifyContent: 'space-between', background: ((index + 1) % 2 ? 'rgb(234 236 238)' : 'rgb(217 221 225)') }}>
                                        <Form.Item
                                            label="Select language "
                                            name={[field.name, 'language_code']}
                                            rules={[...REQUIRED_RULE, {
                                                validator(rule, value, callback) {
                                                    checkIsCodeIsUnique(value.value).then((isUnique) => {
                                                        if (isUnique || state.freezeLanguageFeild) {
                                                            callback(undefined)
                                                        } else {
                                                            callback("This language code is not allowed")
                                                        }
                                                    }).catch(() => {
                                                        callback("try again ")
                                                    })
                                                },
                                            }]}
                                        >
                                            <Select
                                                style={{ width: 200 }}
                                                showSearch
                                                optionFilterProp="label"
                                                labelInValue
                                                disabled={state.freezeLanguageFeild}
                                                onChange={(value) => {
                                                    dispatch({ type: types.UPDATE_SUPPPORTED_LANGUAGES, payload: form.getFieldValue('languages').map((language: any) => language.language_code.value) })
                                                }}
                                            >
                                                {state.supportedlanguages.map((item) => <Select.Option label={item.alt_name} value={item.value}>{item.alt_name} ({item.name})</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                        {fields.length > 1 && <Button onClick={() => {
                                            remove(field.name);
                                            setTimeout(() => {
                                                dispatch({ type: types.UPDATE_SUPPPORTED_LANGUAGES, payload: form.getFieldValue('languages').map((language: any) => language.language_code.value) })
                                            }, 100)
                                        }}><MinusCircleOutlined /> remove </Button>}
                                    </Space>
                                ))}
                                <Form.Item style={{ marginTop: '20px' }}>
                                    {
                                        !state.freezeLanguageFeild && <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add language
                                        </Button>
                                    }
                                </Form.Item>

                            </>
                        )}
                    </Form.List>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <FileUpload rules={REQUIRED_RULE} name={'largeThumbnail'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={getFileUploadUrl} title={'upload image'} />
                        <FileUpload rules={REQUIRED_RULE} name={'miniThumbnail'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={getFileUploadUrl} title={'upload image'} />
                        <FileUpload rules={REQUIRED_RULE} name={'smallRoundThumbnail'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={getFileUploadUrl} title={'upload image'} />
                        <FileUpload rules={REQUIRED_RULE} name={'smallSquareThumbnail'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={getFileUploadUrl} title={'upload image'} />
                    </div>
                    <Form.Item
                        label="Position"
                        name="position"
                        rules={[{ required: true }]}
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        label="Temple Speciality"
                        name="displayStatus"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group >
                            <Radio value={'festive_special'}>Festive Special</Radio>
                            <Radio value={'pre_selected'}>Pre Selected</Radio>
                            <Radio value={'none'}>Default</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {
                        showSubmitBtn &&
                        <Form.Item>
                            <Button type="primary" htmlType="submit" >Submit</Button>
                            {!editGod && <Button style={{ marginLeft: '10px' }} htmlType="submit" onClick={resetForm}>Reset</Button>}
                        </Form.Item>
                    }
                </>
            }
        </>
    )
}

export default GodFormFields;