/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Button, Col, Drawer, Form, Row } from 'antd'
import { ManageSectionItem } from '@a4b/api/src/modules/Monetisation/offerings/types'
import { Sections } from '@a4b/api/src/modules/Monetisation/Astrologer/types'

interface Props {
    onClose: () => void
    visible: boolean
    formSubmithandler: (values: any) => void
    form: any
    pageTitle: string
    renderer: any
    showUpdateButton: boolean
    setDandDModalVisibility: (show: boolean) => void
    sortedSectionList: Sections[]
    formChangeHandler: (values: any) => void
}

const AstrologerPageInfoForm: React.FC<Props> = ({
    onClose,
    visible,
    formSubmithandler,
    form,
    pageTitle,
    setDandDModalVisibility,
    sortedSectionList,
    renderer,
    showUpdateButton,
    formChangeHandler
}) => (
    <Drawer
        size='large'
        title={pageTitle}
        placement='right'
        onClose={onClose}
        visible={visible}
    >
        <Form
            form={form}
            layout='vertical'
            name='create Sections'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onChange={formChangeHandler}
        >
            <Col>
                <Row style={{ gap: '10px' }}>
                    {sortedSectionList?.map((item: Sections) => React.cloneElement(renderer, { data: item }))}
                </Row>
                <Row>
                    <Col span={24} style={{ paddingTop: '10px' }} >
                        <Button onClick={() => setDandDModalVisibility(true)} type='ghost' size='middle'>
                            Select Sections
                        </Button>
                    </Col>
                </Row>
                {
                    showUpdateButton && <Col span={24} style={{ paddingTop: '10px' }}>
                        <Button onClick={formSubmithandler} type='ghost' size='middle'>
                            Submit
                        </Button>
                    </Col>
                }
            </Col >
        </Form >
    </Drawer >
)

export default AstrologerPageInfoForm
