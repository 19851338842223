import React from "react";
import { Button, Checkbox, Col, Form, FormInstance, Input, Row, Switch } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { ATTACHMENT } from "@/utils/enum";
import DeletableContainer from "../deletableContainer/DeletableContainer";
import HeaderTags from "../headerTags/HeaderTags";
import Riddler from "@/network/Riddler/Riddler";
import Attachment from "@/ui/organisms/Attachment";
import HeroVideo from "@/entities/feeds/Widgets/HeroVideo";
import { HeroVideoInstance } from "@/entities/feeds/Widgets";
import { Schedule } from "@a4b/api/src/modules/Calendar/hora.types";

interface Props {
  riddlerInstance: any;
  contentInstance: HeroVideoInstance;
  form?: FormInstance<any>
}
const HeroVideoWidget: React.FC<Props> = ({
  riddlerInstance,
  contentInstance,
  form
}) => {
  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            required={true}
            label="Header"
            name={["content", "header"]}
            rules={[
              {
                validator: contentInstance.headerValidator,
              },
            ]}
          >
            <Input placeholder="Header" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ offset: 4, span: 16 }}
            name={["content", "show_item_details"]}
            initialValue={true}
            valuePropName="checked"
          >
            <Checkbox>show item attributions</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <HeaderTags widgetClass={contentInstance} />
        </Col>
      </Row>
      <Form.List name={["content", "items"]}>
        {(fields, { add, remove, move }) => {
          return (
            <div>
              {fields.map((field: any, index: number) => {
                return (
                  <Form.Item key={field.key}>
                    <DeletableContainer
                      closeHandler={() => {
                        remove(field.name);
                      }}
                      itemText={`Item Attribute : ${index + 1}`}
                      showItemTextInBold={true}
                      moveFunction={move}
                      fields={fields}
                      currentIndex={index}
                      fieldsLength={fields.length}
                      move={move}
                      add={add}
                    >
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="Title"
                            name={[field.name, "title"]}
                            rules={[
                              {
                                validator: contentInstance.titleValidator,
                              },
                            ]}
                          >
                            <Input placeholder="Title" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="Subtitle"
                            name={[field.name, "subtitle"]}
                            rules={[
                              {
                                validator: contentInstance.subtitleValidator,
                              },
                            ]}
                          >
                            <Input placeholder="Subtitle" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="CTA Text"
                            name={[field.name, "cta_text"]}
                            rules={[
                              {
                                validator: contentInstance.ctaTextValidator,
                              },
                            ]}
                          >
                            <Input placeholder="CTA text" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={false}
                            label="Is Expandable"
                            name={[field.name, "is_expandable"]}
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label="Image Attachment"
                            name={[field.name, "image_attachment_id"]}
                            rules={[
                              {
                                validator:
                                  contentInstance.imageAttachmentValidator,
                              },
                            ]}
                          >
                            <Attachment
                              attachmentType={ATTACHMENT.IMAGE}
                              riddlerInstance={riddlerInstance}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            name={[field.name, "video_attachment_id"]}
                            label="Video Attachment ID"
                            required={true}
                            rules={[
                              {
                                validator:
                                  contentInstance.videoRefCodeValidator,
                              },
                            ]}
                          >
                            <Attachment
                              attachmentType={ATTACHMENT.VIDEO}
                              riddlerInstance={riddlerInstance}
                            />
                          </Form.Item>
                        </Col>
                        <Col offset={3} style={{ display: 'flex', gap: 10 }} >
                          <Form.Item
                            {...field}
                            name={[field.name, "offering_cards"]}
                            label="Offering cards"
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Checkbox onChange={(e) => {
                              if (e.target.checked) {
                                if (form) {
                                  form.setFieldValue(["content", "items", field.name, "redirection_cards"], false);
                                }
                              }
                            }}></Checkbox>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "redirection_cards"]}
                            label="Redirection cards"
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Checkbox style={{ borderRadius: '100%' }} onChange={(e) => {
                              if (e.target.checked) {
                                if (form) {
                                  form.setFieldValue(["content", "items", field.name, "offering_cards"], false)
                                }
                              }
                            }}></Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </DeletableContainer>
                  </Form.Item>
                );
              })}

              <Form.Item>
                <Row justify="end">
                  <Col span={24}>
                    <Row justify="end">
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            add();
                            // addItem();
                          }}
                          type="dashed"
                          icon={<PlusCircleOutlined />}
                          size="middle"
                        >
                          Add Item Attributes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
              <div style={{ height: "20px" }}></div>
            </div>
          );
        }}
      </Form.List >
    </>
  );
};

export default HeroVideoWidget;
