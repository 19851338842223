import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/card/card";
import Spin from "antd/es/spin";
import Space from "antd/es/space";
import "antd/es/spin/style/css";
import "./services.css";
import { notification } from "antd";
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useAppContext } from "@/components/AppContext";
import { MasterTranslation } from "@a4b/api/src/modules/Bitbucket/types";

type NotificationType = "success" | "info" | "warning" | "error";

const BEARER_TOKEN: string = process.env.REACT_APP_BITBUCKET_BEARER_TOKEN || ""

const Services = () => {
  const [servicesApi, setServicesApi] = useState<MasterTranslation.MasterValue[]>([]);
  const [isLoadingService, setIsLoadingService] = useState<boolean>(false);
  const { networkInstance } = useAppContext();

  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const handleClick = (item: MasterTranslation.MasterValue) =>
    navigate(
      `/languages/${item?.path}?name=${item?.path}&hash=${item?.commit?.hash}`
    );

  const fetchServiceAPI = async () => {
    setIsLoadingService(true);
    try {
      const resData = await networkInstance.clientWithHeaders.bitbucketApi.getAllServices(BEARER_TOKEN);
      setServicesApi(resData?.data?.values);
      setIsLoadingService(false);
    } catch (error: any) {
      const openNotificationWithIcon = (type: NotificationType) => {
        api[type]({
          message: "Error",
          description: error.message,
        });
      };
      openNotificationWithIcon("error");
    }
  };

  useEffect(() => {
    !servicesApi?.length && fetchServiceAPI();
  }, [servicesApi]);

  if (isLoadingService) {
    return (
      <div className="loading">
        <Space size="middle">
          <Spin />
        </Space>
      </div>
    );
  }

  return (
    <div className="body">
      {contextHolder}
      {servicesApi.length && servicesApi.map((item, key) =>
        ((item?.path?.toLowerCase() !== ".gitignore") && (item?.path?.toLowerCase() !== "readme.md")) &&
        <Card
          key={key}
          onClick={() => handleClick(item)}
          data={item?.path}
          hash={item?.commit?.hash}
        />
      )}
    </div>
  );
}

export default withDefaultLayout(Services);
