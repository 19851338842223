import {
  Badge,
  Button,
  Image, message, Popconfirm,
  Space,
  Table,
  Tag
} from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import { PresetColorTypes, PresetStatusColorType } from 'antd/lib/_util/colors'

import React, { ReactElement, ReactEventHandler, SyntheticEvent } from 'react'

//types
import type {
  ContentStatus, LibraryContent,
  SupportedContentFormats,
  SupportedContentTypes
} from '@a4b/api'
import moment from 'moment'
import { useState } from '@storybook/addons'
import { EditOutlined, FileAddOutlined } from '@ant-design/icons'

type ClickHandler = (contentItem: LibraryContent) => void
type ClickHandlerWithPromise = (contentItem: LibraryContent) => Promise<boolean>
interface ImageWithSizeProps {
  imagePath: string
}

const ImageWithSize = (props: ImageWithSizeProps) => {
  const [size, setSize] = React.useState(-1);
  return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
    <Image onLoad={(event: SyntheticEvent<HTMLImageElement>): void => {
      fetch(props.imagePath).then(resp => { console.log({ resp }); setSize(Math.ceil(resp?.headers?.get("content-length") as unknown as number / 1000)) })
    }
    } src={props.imagePath} width={50} preview={false} />
    <p>
      {size !== -1 && <><b>Size:</b> {size} kB </>}
    </p>
  </div>
}

export interface Props {
  loading: boolean
  data: LibraryContent[]
  onPageChanged: (page: number, pageSize: number) => void
  total: number
  onEditContentClicked: ClickHandler
  onSendToQCClicked: ClickHandlerWithPromise
  onViewContentClicked: ClickHandler
  onDeleteContentClicked: ClickHandlerWithPromise
  onSendToQCDraft: ClickHandlerWithPromise
}

const getColumns = (
  onEditContentClicked: ClickHandler,
  onSendToQCClicked: ClickHandler,
  onViewContentClicked: ClickHandler,
  onDeleteContentClicked: ClickHandler,
  onSendToQCDraft: ClickHandler,
): ColumnsType<LibraryContent> => [
    {
      title: 'Thumnail',
      dataIndex: 'thumbnailId',
      key: 'thumbnailId',
      render: (thumbnail: string) => {
        return <ImageWithSize imagePath={thumbnail}></ImageWithSize>
      },
    },
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (tags: { id: string; name: string }[]) => {
        return (
          <>
            {(tags || []).map((tag, index) => {
              if (!tag?.name) {
                return
              }
              const colorIndex = tag.name.charCodeAt(0) % PresetColorTypes.length
              return (
                <Tag color={PresetColorTypes[colorIndex]} key={index}>
                  {tag.name}
                </Tag>
              )
            })}
          </>
        )
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: SupportedContentTypes) => {
        switch (type) {
          case 'ARTICLE': {
            return <Tag color='blue'>{type}</Tag>
          }
          case 'BOOK': {
            return <Tag color='green'>{type}</Tag>
          }
          default: {
            return
          }
        }
      },
    },
    {
      title: 'Available Formats',
      dataIndex: 'formats',
      key: 'formats',
      render: (formats: SupportedContentFormats[]) => {
        return (
          <>
            {formats.map((format, index) => {
              let color = ''
              switch (format) {
                case 'audio': {
                  color = 'purple'
                  break
                }
                case 'video': {
                  color = 'lime'
                  break
                }
                case 'pdf': {
                  color = 'red'
                  break
                }
                case 'text': {
                  color = 'orange'
                  break
                }
                default: {
                  color = 'red'
                  break
                }
              }
              return (
                <Tag key={index} color={color}>
                  {format.toUpperCase()}
                </Tag>
              )
            })}
          </>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: ContentStatus) => {
        let text, statusColor: PresetStatusColorType
        switch (status) {
          case 'DRAFT': {
            text = 'In Draft'
            statusColor = 'default'
            break
          }
          case 'CHANGE_REQUESTED': {
            text = 'Change Requested'
            statusColor = 'error'
            break
          }
          case 'READY_TO_BE_MODERATED': {
            text = 'Ready to Moderated'
            statusColor = 'warning'
            break
          }
          case 'PUBLISHED': {
            text = 'Published'
            statusColor = 'success'
            break
          }
          case 'DELETED': {
            text = 'Deleted'
            statusColor = 'error'
            break
          }
          default: {
            text = 'In Draft'
            statusColor = 'default'
            break
          }
        }
        return (
          <Tag>
            <Badge status={statusColor} text={text.toUpperCase()} />
          </Tag>
        )
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: number) => {
        return <div>{moment(createdAt).format('lll')}</div>
      }
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (createdAt: number) => {
        return <div>{moment(createdAt).format('lll')}</div>
      }
    },
    {
      title: 'Author ',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render(value, record, index) {
        return <div>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '5px' }}><FileAddOutlined /> {record.createdBy}</div>
          {record.updatedBy && record.updatedBy.length > 0 && <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}> <EditOutlined /> {record.updatedBy}</div>}
        </div>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'status',
      key: 'status',
      render: (status: ContentStatus, item: LibraryContent) => {
        return (
          <Space>
            {(status === 'DRAFT' || status === 'CHANGE_REQUESTED') && (<>
              <Button
                size='small'
              >
                <a style={{ paddingLeft: '15px' }} type="link" href={`/content/edit-draft/${item.id}`} target="_blank" rel="noreferrer"> Edit (New tab)</a>
              </Button>
              <Button
                size='small'
                type='primary'
                onClick={onEditContentClicked.bind(this, item)}
              >
                Edit
              </Button>
            </>
            )}
            {(status === 'DRAFT' || status === 'CHANGE_REQUESTED' || status === 'DELETED') && (<>
              <Button size='small' onClick={onSendToQCClicked.bind(this, item)}>
                Send To QC
              </Button>
              {(status === 'DELETED') && (
                <Button size='small' onClick={onSendToQCDraft.bind(this, item)}>
                  Send To Draft
                </Button>
              )}
            </>
            )}
            {(status === 'READY_TO_BE_MODERATED' || status === 'DELETED' || status === 'PUBLISHED') && <>
              <Button
                size='small'
              >
                <a style={{ paddingLeft: '15px' }} type="link" href={`/content/edit-draft/${item.id}`} target="_blank" rel="noreferrer"> View (New tab)</a>
              </Button>
              <Button
                size='small'
                type='primary'
                onClick={onViewContentClicked.bind(this, item)}
              >
                View
              </Button>

            </>}
            {(status === 'READY_TO_BE_MODERATED' || status === 'PUBLISHED') && (
              <Popconfirm
                title={`Are you sure to delete this content?`}
                onConfirm={onDeleteContentClicked.bind(this, item)}
                okText='Yes'
                cancelText='No'
              >
                <Button size='small' danger>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </Space>
        )
      },
    },
  ]

export const ContentListing: React.FC<Props> = ({
  loading,
  data,
  total,
  onPageChanged,
  onEditContentClicked,
  onSendToQCClicked,
  onViewContentClicked,
  onDeleteContentClicked,
  onSendToQCDraft
}) => {
  const handleSentToQCClicked = async (item: LibraryContent) => {
    const success = await onSendToQCClicked(item)
    success
      ? message.success('content sent to QC')
      : message.error('failed to send to QC')
  }

  const handleDeleteContentClicked = async (item: LibraryContent) => {
    const success = await onDeleteContentClicked(item)
    success
      ? message.success('successfully deleted content')
      : message.error('failed to delete content')
  }

  return (
    <Table
      data-testid='listing-form'
      bordered
      size='small'
      loading={loading}
      columns={getColumns(
        onEditContentClicked,
        handleSentToQCClicked,
        onViewContentClicked,
        handleDeleteContentClicked,
        onSendToQCDraft
      )}
      rowKey={record => record.id}
      dataSource={data}
      pagination={{
        hideOnSinglePage: true,
        onChange: onPageChanged,
        total: total,
      }}
    />
  )
}

export default ContentListing
