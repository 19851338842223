import React from 'react'
import { Card, Col, Row, Space } from 'antd'
import styled from 'styled-components'
import PageInfoItem from '@a4b/ui/src/modules/monetisation/molecules/PageInfoItem'

interface Props {
  onCreateNew: () => void
  dataSource: any
  onClickhandler: (pageType: string, language_code: string) => void
  isSubtitle?: boolean
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const ManagePagesPage: React.FC<Props> = ({
  dataSource,
  onClickhandler,
  isSubtitle
}) => {
  return (
    <Container style={{ width: '100%' }}>
      <Space direction={'vertical'} style={{ width: '100%' }}></Space>
      <BodyContainer>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              dataSource?.map((page: any, index: number) => (
                <PageInfoItem
                  data={page}
                  onClickhandler={onClickhandler}
                  key={index}
                  isSubTitlePresent={isSubtitle!}
                />
              ))}
          </Col>
        </Row>
      </BodyContainer>
    </Container>
  )
}

export default ManagePagesPage
