import { Shrine } from "@/../../../packages/a4b-api/src/modules/Temple/types";
import { SUPPORTED_LANGUAGES_LIST } from "@/utils/constants";
import { Card, Collapse, Space } from "antd";
import SortStores from "./forms/SortStores";

export interface GodCardProps {
  god: Shrine.God;
  isDefaultActive?: boolean,
  showSortStores?: boolean
}
type FlexDirection = "column" | "row"
type FlexWrap = 'wrap'
const thumbnailCardStyle = { display: 'flex', border: '1px solid black', flexDirection: 'column' as FlexDirection, justifyContent: 'center', alignItems: 'center', rowGap: '20px', width: '150px', height: '300px' }
const GodCard = ({ god, isDefaultActive, showSortStores }: GodCardProps) => {
  return <Card>
    <Collapse defaultActiveKey={isDefaultActive ? ['1'] : []}>
      <Collapse.Panel header={god.code} key="1" >
        <b> Thumbnails</b>
        <br />
        <br />
        <Space>
          <div style={thumbnailCardStyle}>
            <img width={100} src={god.thumbnails.large.url}></img>
            <div>
              large
            </div>
          </div>
          <div style={thumbnailCardStyle}>
            <img width={100} src={god.thumbnails.mini.url}></img>
            <div>
              mini
            </div>
          </div>
          <div style={thumbnailCardStyle}>
            <img width={100} src={god.thumbnails.small_round.url}></img>
            <div>
              small round
            </div>
          </div>
          <div style={thumbnailCardStyle}>
            <img width={100} src={god.thumbnails.small_square.url}></img>
            <div>
              small square
            </div>
          </div>
        </Space>
        <br></br>
        <br></br>
        <b>Avatars</b>
        <br></br>
        <br></br>
        <Space style={{ flexWrap: 'wrap' }}>
          {god.avatars?.map((avatar, index) =>
            <div key={avatar.code} style={avatar.active ? { ...thumbnailCardStyle, width: '200px' } : { opacity: 0.5, ...thumbnailCardStyle, width: '200px' }}>
              <div><img style={{ width: '100px' }} src={avatar.attachment.url}></img></div>
              <div>{avatar.code}</div>
            </div>
          )}
        </Space>
        <div><b>Active:</b> {god.active ? 'Yes' : 'No'}</div>
        <div><b>Pre selected:</b> {god.pre_selected ? 'Yes' : 'No'}</div>
        <div><b>Festive Special:</b> {god.pre_selected ? 'Yes' : 'No'}</div>
        <div><b>language:</b> {SUPPORTED_LANGUAGES_LIST.find((language) => language.value === god.language_code)?.alt_name}</div>
      </Collapse.Panel>
    </Collapse >
    {
      showSortStores &&
      <SortStores god={god} />
    }
  </Card>
}

export default GodCard;