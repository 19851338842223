import React from "react";
import { Button, Col, Form, Row, Input } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { ATTACHMENT } from "@/utils/enum";
import DeletableContainer from "../deletableContainer/DeletableContainer";
import Riddler from "@/network/Riddler/Riddler";
import Attachment from "@/ui/organisms/Attachment";
import MenuLogin from "@/entities/feeds/Widgets/MenuLogin";
import { MenuLoginInstance } from "@/entities/feeds/Widgets";
const { TextArea } = Input;
interface Props {
  riddlerInstance: any;
  contentInstance: MenuLoginInstance;
}
const MenuLoginWidget: React.FC<Props> = ({
  riddlerInstance,
  contentInstance,
}) => {
  return (
    <Row>
      <Col span={24}>
        <Form.List name={["content", "items"]}>
          {(fields, { add, remove, move }) => {
            return (
              <div>
                {fields.map((field: any, index: number) => {
                  return (
                    <Form.Item key={field.key}>
                      <DeletableContainer
                        closeHandler={() => {
                          remove(field.name);
                        }}
                        itemText={`Item Attribute : ${index + 1}`}
                        showItemTextInBold={true}
                        moveFunction={move}
                        fields={fields}
                        currentIndex={index}
                        fieldsLength={fields.length}
                        move={move}
                        add={add}
                      >
                        <Row>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              required={true}
                              label="Default Title"
                              name={[field.name, "default_title"]}
                              rules={[
                                {
                                  validator:
                                    contentInstance.defaultTitleValidator,
                                },
                              ]}
                            >
                              <TextArea rows={3} placeholder="Default Title" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              required={true}
                              label="Default Subtitle"
                              name={[field.name, "default_subtitle"]}
                              rules={[
                                {
                                  validator:
                                    contentInstance.defaultSubtitleValidator,
                                },
                              ]}
                            >
                              <TextArea
                                rows={3}
                                placeholder="Default Subtitle"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              label="Default Image Attachment"
                              name={[field.name, "default_image_attachment_id"]}
                              rules={[
                                {
                                  validator:
                                    contentInstance.defaultAttachmentIdValidator,
                                },
                              ]}
                            >
                              <Attachment
                                attachmentType={ATTACHMENT.IMAGE}
                                riddlerInstance={riddlerInstance}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </DeletableContainer>
                    </Form.Item>
                  );
                })}
                <Form.Item>
                  <Row justify="end">
                    <Col span={24}>
                      <Row justify="end">
                        <Col span={4}>
                          <Button
                            onClick={() => {
                              add();
                            }}
                            type="dashed"
                            icon={<PlusCircleOutlined />}
                            size="middle"
                          >
                            Add Item Attributes
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Item>
                <div style={{ height: "20px" }}></div>
              </div>
            );
          }}
        </Form.List>
      </Col>
    </Row>
  );
};

export default MenuLoginWidget;
