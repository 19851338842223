import { ArrowLeftOutlined } from '@ant-design/icons'
import { Layout, PageHeader } from 'antd'
import styled from 'styled-components'

import React from 'react'
import { useNavigate } from 'react-router-dom'

export interface Props {
  children: any
  title: string
  subTitle?: string
  backIcon?: any
  showBackIcon?: boolean
  backButtonClickHandler?: () => void
  createNewHandler?: () => void
  extra?: any[]
}
const ContainerWrapper = styled.div`
  overflow: scroll;
  margin-top: 10px;
  width: 100%;
  height: 100%;
`
const PageWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
`
const PageLayout: React.FunctionComponent<Props> = ({
  title,
  subTitle,
  children,
  backIcon,
  showBackIcon,
  backButtonClickHandler,

  extra,
}: Props) => {
  const navigate = useNavigate()
  const backClickHandler = () => {
    navigate(-1)
  }
  return (
    <Layout style={{ height: `calc(100vh - 40px)`, paddingBottom: '30px' }}>
      <PageWrapper>
        <div style={{ boxShadow: '0px 1px 0px 0px #888' }}>
          <PageHeader
            key='page-header'
            className='site-page-header'
            onBack={backButtonClickHandler || backClickHandler}
            title={title}
            subTitle={subTitle}
            style={{
              backgroundColor: '#ffffff',
            }}
            backIcon={
              backIcon ||
              (showBackIcon === false ? null : <ArrowLeftOutlined />)
            }
            extra={extra}
          />
        </div>
        <ContainerWrapper>{children}</ContainerWrapper>
      </PageWrapper>
    </Layout>
  )
}
export default PageLayout
