import { PlusCircleOutlined } from '@ant-design/icons'
import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Row,
  Select,
  Space,
  Typography,
  message,
} from 'antd'
import styled from 'styled-components'

import React, { useState } from 'react'

import { SortableItemData } from '../types'

export interface Props {
  items: SortableItemData[]
  onSearch: (value: string, offset?: number) => void
  onAddItem: (item: SortableItemData) => Promise<boolean>
}

const { Option } = AutoComplete

const SelectImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
`

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SearchItems: React.FC<Props> = ({
  items,
  onSearch,
  onAddItem,
}) => {
  const [offset, setOffset] = React.useState(0);
  const [searchKey, setSearchKey] = useState('');
  const handleSelect = async (value: string) => {
    const selectedItem = JSON.parse(value) as SortableItemData
    setSearchKey('')

    if (selectedItem) {
      const success = await onAddItem(selectedItem)
      success
        ? message.success('Item added')
        : message.error('Item already exists')
    }
  }

  const handleSearch = (key: string) => {
    setSearchKey(key)
    setOffset(0);
    onSearch(key, 0)
  }

  return (
    <Row
      style={{ marginBottom: '18px', display: 'flex', alignItems: 'center' }}
    >
      <Col span={4} style={{ textAlign: 'right', paddingRight: '8px' }}>
        Search items:
      </Col>
      <Col span={20}>
        <Select
          value={searchKey}
          showSearch
          placeholder={'Search items to sort/rearrange'}
          filterOption={false}
          searchValue={searchKey}
          onSearch={handleSearch}
          onSelect={handleSelect}
          style={{ minWidth: '400px', width: '100%' }}
          dropdownRender={(menu) => {
            return <div>
              {menu}
              <Divider></Divider>
              <Button type="text"
                onClick={() => {
                  setOffset(Math.max(offset - 1, 0));
                  onSearch(searchKey, Math.max(offset - 1, 0))
                }}
                disabled={offset === 0}
              >
                Previous Page {offset}
              </Button>
              (Page {offset + 1})
              <Button type="text" value={1 + 1}
                onClick={() => {
                  setOffset(offset + 1);
                  onSearch(searchKey, offset + 1)
                }
                }
                disabled={items.length < 30}
              >
                Next Page {offset + 2}
              </Button>
            </div>
          }
          }
        >
          {items.map((item, index) => (
            <Option key={index} value={JSON.stringify(item)}>
              <OptionContainer>
                <Space direction='horizontal'>
                  <SelectImage src={item.imageUrl} />
                  <Typography.Text>{item.name}</Typography.Text>
                </Space>
                <Button type='link'>
                  <PlusCircleOutlined />
                </Button>
              </OptionContainer>
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  )
}
