export const sectionPageKeys = [
  'rashifal_gemini_banner_items',
  'rashifal_leo_banner_items',
  'panchang_banner_items',
  'panchang_banner_items',
  'panchang_banner_items',
  'panchang_banner_items',
  'panchang_banner_items',
  'rashifal_cancer_banner_items',
  'rashifal_aries_banner_items',
  'rashifal_aries_banner_items',
  'rashifal_taurus_banner_items',
  'rashifal_taurus_banner_items',
  'rashifal_taurus_banner_items',
  'rashifal_taurus_banner_items',
  'rashifal_gemini_banner_items',
  'rashifal_gemini_banner_items',
  'rashifal_cancer_banner_items',
  'rashifal_cancer_banner_items',
  'rashifal_leo_banner_items',
  'rashifal_leo_banner_items',
  'rashifal_leo_banner_items',
  'rashifal_leo_banner_items',
  'rashifal_virgo_banner_items',
  'rashifal_virgo_banner_items',
  'rashifal_virgo_banner_items',
  'rashifal_virgo_banner_items',
  'rashifal_virgo_banner_items',
  'rashifal_taurus_banner_items',
  'rashifal_cancer_banner_items',
  'rashifal_leo_banner_items',
  'rashifal_virgo_banner_items',
  'panchang_banner_items',
  'rashifal_aries_banner_items',
  'rashifal_taurus_banner_items',
  'rashifal_cancer_banner_items',
  'rashifal_leo_banner_items',
  'rashifal_virgo_banner_items',
  'rashifal_aries_banner_items',
  'rashifal_cancer_banner_items',
  'rashifal_cancer_banner_items',
  'rashifal_taurus_banner_items',
  'rashifal_libra_banner_items',
  'rashifal_libra_banner_items',
  'rashifal_libra_banner_items',
  'rashifal_scorpio_banner_items',
  'rashifal_scorpio_banner_items',
  'rashifal_scorpio_banner_items',
  'rashifal_scorpio_banner_items',
  'rashifal_scorpio_banner_items',
  'rashifal_sagittarius_banner_items',
  'rashifal_sagittarius_banner_items',
  'rashifal_sagittarius_banner_items',
  'rashifal_sagittarius_banner_items',
  'rashifal_sagittarius_banner_items',
  'rashifal_capricorn_banner_items',
  'rashifal_capricorn_banner_items',
  'rashifal_capricorn_banner_items',
  'rashifal_capricorn_banner_items',
  'rashifal_aquarius_banner_items',
  'rashifal_aquarius_banner_items',
  'rashifal_aquarius_banner_items',
  'rashifal_pisces_banner_items',
  'rashifal_pisces_banner_items',
  'rashifal_pisces_banner_items',
  'rashifal_pisces_banner_items',
  'rashifal_pisces_banner_items',
  'panchang_banner_items',
  'rashifal_scorpio_banner_items',
  'rashifal_scorpio_banner_items',
  'rashifal_sagittarius_banner_items',
  'rashifal_capricorn_banner_items',
  'rashifal_aquarius_banner_items',
  'rashifal_pisces_banner_items',
  'rashifal_aries_banner_items',
  'rashifal_gemini_banner_items',
  'rashifal_libra_banner_items',
  'rashifal_sagittarius_banner_items',
  'rashifal_capricorn_banner_items',
  'rashifal_aquarius_banner_items',
  'rashifal_capricorn_banner_items',
  'rashifal_libra_banner_items',
  'rashifal_aries_banner_items',
  'rashifal_aries_banner_items',
  'rashifal_aquarius_banner_items',
  'rashifal_aquarius_banner_items',
  'rashifal_gemini_banner_items',
  'rashifal_gemini_banner_items',
  'rashifal_gemini_banner_items',
  'rashifal_pisces_banner_items',
  'rashifal_libra_banner_items',
  'rashifal_libra_banner_items',
  'store_list_page_pooja',
  'srimandir_services_page',
  'banner_large_items_slp',
  'order_history_l2_page_offerings',
  'order_history_l2_page_pooja',
  'sankalp_banners_slp',
  'remedy_list_page',
]

export const stepsPageKeys = [
  'payment_failed_page',
  'payment_successful_page_pooja',
  'payment_successful_page',
]

export const faqPageKeys = [
  'store_list_page',
  'cart_review_page',
  'cart_review_page_pooja',
  'store_list_page_pooja',
  'order_fulfilment_generic_faq',
  'remedy_cart_review_page_faq',
  'remedy_order_fulfilment_faq',
]

export const tutorialsPageKeys = ['store_list_page']
export const reviewsPageKeys = ['store_list_page_pooja']

console.log(
  [
    ...faqPageKeys,
    ...reviewsPageKeys,
    ...tutorialsPageKeys,
    ...sectionPageKeys,
    ...stepsPageKeys,
  ].length,
)
