import React, { useCallback, useEffect, useState } from 'react'
import { Form, message, Modal, Col, Input } from 'antd'
import type { TablePaginationConfig } from 'antd/es/table'
import { useAppContext } from '@/components/AppContext'
import { useParams, useSearchParams } from 'react-router-dom'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import _ from 'lodash'
import BatchView from '../../components/BatchView'
import BatchListPage from '@a4b/ui/src/modules/monetisation/puja/organisms/BatchListPage'
import { YODA_CDN_URL, PUJA_PRESIGN_URL, youtubeUrlRegex, A4B_APPS } from '@/utils/constants'
import {
  Batch,
  MetaData,
  SKUFilters,
} from '@a4b/api/src/modules/Monetisation/Puja/types'
import CompletedPreviewModal from './CompletedPreviewModal'
import PreviewModal from './PreviewModal'
import UploadMediaModal from './UploadMediaModal'
import Error from '@/components/Error'
import CreateDeliveryOrder from './CreateDeliveryOrder'
import { deliveryOrderPayload, SANKALP_VIDEO_STATUS } from '../../utils/helper'

interface DataType {
  batch_id: string
  product_name: string
  store_name: string
}

interface DataType {
  key: React.Key
  name: string
  age: number
  address: string
}

const getPageBreadcrumbs = (TempleName: string, hubId: string) => [
  {
    path: '/monetisation/puja/puja-updates',
    breadcrumbName: 'Puja Updates',
  },
  {
    path: '/monetisation/puja',
    breadcrumbName: 'Hub List',
  },
  {
    path: `/monetisation/puja/hub/${hubId}`,
    breadcrumbName: TempleName,
  },
  {
    path: '/monetisation/puja/batches',
    breadcrumbName: 'Batch List',
  },
]

const BatchListScreen: React.FC = () => {
  const { networkInstance, monetisationRoles, languageState, app } = useAppContext()
  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi

  let { hubId, templeId } = useParams()
  let [batchData, setBatchData] = useState<Batch[]>([])
  let [paginatedBatchData, setPaginatedBatchData] = useState<Batch[]>([])
  let [skuFilters, setSkuFilters] = useState<SKUFilters[]>([])
  const [showbatchView, setShowbatchView] = React.useState(false)
  const [isPreviewDone, setIsPreviewDone] = React.useState(false)
  const [showPreview, setShowPreview] = React.useState(false)
  const [currentTab, setCurrentTab] = React.useState<string>()
  const [totalRecord, setTotalRecord] = React.useState<number>(0)
  const [isComplete, setIsComplete] = React.useState(false)
  const [isCompletedPoojaService, setCompletedPoojaService] = React.useState(false)
  const [currentCompletedVideo, setCurrentCompletedVideo] = React.useState('')

  const [isPaginationChanged, setPaginationChanged] = React.useState(false)
  const [metaMap, setMetaMap] = React.useState<MetaData | undefined>()

  const [reducedItemNoCount, setReducedItemCount] = React.useState()
  const [currentBatchView, setCurrentBatchView] = React.useState<
    Batch | undefined
  >()
  const [isGenerateBatch, setIsGenerateBatch] = React.useState<boolean>(false)
  const [showMediaUploadModal, setShowMediaUploadModal] = React.useState<boolean>(false)
  const [showUploadButton, setShowUploadButton] = useState<boolean>(false)
  const [selectionType] = useState<string>('checkbox')
  const [loading, setLoading] = useState<boolean>(false)
  const [errorPage, setErrorPage] = React.useState<boolean>(false)
  const [showCreateOrderForm, setShowCreateOrderForm] = React.useState<boolean>(false)
  const [isDeliveryOrderCreated, setIsDeliveryOrderCreated] = React.useState<boolean>(false)
  const [selectedRow, setSelectedRow] = React.useState<any>()

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50, 100, 200],
    showSizeChanger: true,
  })

  const [batchForm] = Form.useForm()
  const [videoUrlForm] = Form.useForm()
  const [mediaUploadForm] = Form.useForm()
  const [createOrderForm] = Form.useForm()
  const [currentStep, setCurrentStep] = useState(1)
  const [searchParams] = useSearchParams()

  const [showEditVideoUrlForm, setShowEditVideoUrlForm] = useState<boolean>(false)
  const [videoFormObj, setVideoFormObj] = useState({
    batch_id: '',
    hash_code: '',
    media_type: 'youtube',
  })

  const getBatchList = useCallback(
    async (isForcefully?: boolean) => {
      if (!templeId || !monetisationRoles?.email || (isPaginationChanged && !isForcefully)) {
        return
      }
      try {
        const batchListList = await monetisationPujaApi.batchApi.getBatchList({
          store_id: templeId || '',
          user_email: monetisationRoles?.email || '',
          batch_status: isComplete ? 'completed' : '',
          limit: pagination.pageSize!,
          offset:
            Number((pagination?.current || 1) - 1) * Number(pagination.pageSize),
          ...((currentTab !== 'all_sku') && { inventory_id: currentTab }),
          sku_filter: !skuFilters?.length ? true : false
        })
        setPagination({ ...pagination, total: batchListList?.data?.data?.total || 10 })
        setMetaMap(batchListList?.data?.data?.meta_data)

        setPaginatedBatchData(batchListList?.data?.data?.batches)
        if (!batchListList?.data?.data?.batches) {
          message.warning('Batch list is empty')
          return
        }
        if (isForcefully) {
          message.success('Batch list refreshed successfully')
        }
        if (!skuFilters?.length)
          setSkuFilters(batchListList?.data?.data?.sku_filters)
        setBatchData(batchListList?.data?.data?.batches)
        setTotalRecord(batchListList?.data?.data?.batches.length)
        const reducedValue = batchListList?.data?.data?.batches.reduce(
          (acc: any, cur: Batch) => {
            if (cur.status)
              acc[cur.inventory_id] = acc[cur.inventory_id]
                ? acc[cur.inventory_id] + 1
                : 1
            return acc
          },
          {},
        )
        setReducedItemCount(reducedValue)
      } catch (error) {
        setErrorPage(true)
        console.log(error)
      }
    },
    [templeId, monetisationRoles?.email, isPaginationChanged, monetisationPujaApi.batchApi, isComplete, pagination?.current, pagination?.pageSize, currentTab],
  )

  useEffect(() => {
    getBatchList()
  }, [getBatchList, isComplete])


  // useEffect(() => {
  //   if (currentBatchView) {
  //     const config = localStorage.getItem(currentBatchView?.batch_id)
  //     const parsedConfig = JSON.parse(config || '{}')
  //     if (currentBatchView.product_type === 'pooja') {
  //       if (parsedConfig?.namelistDownload) {
  //         setCurrentStep(3)
  //       } else if (parsedConfig?.orderMediaUpload) {
  //         setCurrentStep(2)
  //       } else {
  //         setCurrentStep(1)
  //       }
  //     }
  //     if (currentBatchView.product_type === 'item') {
  //       if (parsedConfig?.namelistDownload) {
  //         setCurrentStep(2)
  //       } else {
  //         setCurrentStep(1)
  //       }
  //     }
  //     if (currentBatchView.product_type === 'prayer') {
  //       if (parsedConfig?.namelistDownload) {
  //         setCurrentStep(2)
  //       } else if (parsedConfig?.prayerDownload) {
  //         setCurrentStep(1)
  //       }
  //     }
  //   }
  // }, [currentBatchView])

  const generateBatch = async () => {
    setIsGenerateBatch(true)
    try {
      await monetisationPujaApi.batchApi.generateBatches({
        store_id: templeId || '',
        user_email: monetisationRoles?.email || '',
      })
      message.success({
        content: 'Batches generated successfully',
        duration: 3,
      })
      getBatchList()
      setIsGenerateBatch(false)
    } catch (error) {
      message.error({
        content: 'Error while generating batch',
        duration: 3,
      })
    }
  }

  // const getNameTranslation = async (
  //   batch_id: string,
  //   nameList: string[],
  //   callback: any,
  // ) => {
  //   const hindiTranslation = await monetisationPujaApi.batchApi.translateStringsToHindi(nameList)
  //   callback(hindiTranslation?.data.data?.output?.strings.map((item: string, index: number) => `${item} - [ ${nameList[index]} ]`))
  // }

  const onViewBatchClick = async (batchCode: string, batch?: Batch) => {
    if (batch && batch?.product_type !== 'pooja') {
      message.warning('Invalid product type')
      return
    }
    let translatedBatch = null
    const batchData = await fetchBatch(batchCode)
    handleChangeStep(2)
    if (batchData) {
      // let nameList: string[][] = batchData.pooja_member_data?.map((item: PoojaMemberData) => {
      //   const names = item?.members?.map((member: Member) => member?.full_name)
      //   return names?.length ? names : [""]
      // })
      // try {
      //   const translationResponse = await monetisationPujaApi.batchApi.translatePujaTextToEnglish(
      //     batchData.batch_id,
      //     nameList,
      //   )

      //   translatedBatch = {
      //     ...batchData,
      //     pooja_member_data: batchData?.pooja_member_data?.map((pujaMember: PoojaMemberData, poojaMembersIndex: number) => {
      //       return {
      //         ...pujaMember, members: pujaMember?.members?.map((members: Member, membersIndex: number) => {
      //           return { ...members, full_name: translationResponse?.data?.[poojaMembersIndex]?.[membersIndex] }
      //         })
      //       }
      //     }),

      //   }
      //   message.success({ content: 'Translation fetched!' })
      // } catch (error) {
      //   message.error({ content: "Translation failed" })
      // }

      const batchContent = translatedBatch ?? batchData
      setCurrentBatchView(batchContent)
      setShowbatchView(true)
      if (batchData?.video_tagging_status === SANKALP_VIDEO_STATUS.PROCESSED
        || batchData?.video_tagging_status === SANKALP_VIDEO_STATUS.PROCESSING
        || batchData?.video_tagging_status === SANKALP_VIDEO_STATUS.NOT_APPLICABLE
      ) {
        setCurrentStep(3)
      } else {
        setCurrentStep(1)
      }
      // Since we're not using this case, commenting the block. Later we should remove this else case

      // const inventoryById = await monetisationPujaApi.storesApi.getInventoryById(batchData?.inventory_id)
      // getNameTranslation(
      //   batchData.batch_id,
      //   batchData.offering_names,
      //   (result: string[]) => {
      //     message.destroy('trans')
      //     setCurrentBatchView({
      //       ...batchData,
      //       offering_names: result || [''],
      //       intro_url: inventoryById?.data?.data?.IntroVideoUrl,
      //       outro_url: inventoryById?.data?.data?.OutroVideoUrl
      //     })
      //     setShowbatchView(true)
      //   },
      // )        
    } else {
      console.error('Batch data is invalid or not fetched');
    }
  }

  const closeHandler = () => {
    setShowbatchView(false)
    setCurrentBatchView(undefined)
    setCurrentStep(1)
  }

  const handleFormSubmit = () => {
    if (!currentBatchView) {
      return
    }
    batchForm
      .validateFields()
      .then(async values => {
        const batchFormData = batchForm.getFieldsValue()
        const formData = {
          hash_code: currentBatchView?.hash_code,
          video_url: batchFormData?.videoType === "youtube" ? batchFormData?.video_url : batchFormData?.video_url?.[0],
          media_type: batchFormData?.videoType
        }
        try {
          await monetisationPujaApi.batchApi.completeBatch(
            currentBatchView.batch_id,
            formData,
          )
          message.success({
            content: 'Batch updated successfully',
            duration: 3,
          })
          setShowbatchView(false)
          batchForm.resetFields()
          getBatchList()
        } catch (error) {
          message.error({
            content: 'Error while updating batch',
            duration: 3,
          })
          console.log(error)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  // const initiatePreviewStatus = useCallback(() => {
  //   if (!currentBatchView?.batch_id) {
  //     return
  //   }
  //   const config = localStorage.getItem(currentBatchView.batch_id)
  //   const parsedConfig = JSON.parse(config || '{}')
  //   if (parsedConfig?.isPreviewDone) {
  //     setIsPreviewDone(true)
  //   }

  //   localStorage.setItem(
  //     currentBatchView.batch_id,
  //     JSON.stringify({ ...parsedConfig }),
  //   )
  // }, [currentBatchView?.batch_id])

  // useEffect(() => {
  //   initiatePreviewStatus()
  // }, [initiatePreviewStatus])

  const updatePreviewStatus = (previewStatus: boolean) => {
    if (!currentBatchView?.batch_id) {
      return
    }
    const config = localStorage.getItem(currentBatchView.batch_id)
    const parsedConfig = JSON.parse(config || '{}')

    localStorage.setItem(
      currentBatchView.batch_id,
      JSON.stringify({ ...parsedConfig, isPreviewDone: previewStatus }),
    )
  }
  const resetPreview = () => {
    if (!currentBatchView?.batch_id) {
      return
    }

    const config = localStorage.getItem(currentBatchView.batch_id)
    const parsedConfig = JSON.parse(config || '{}')
    localStorage.setItem(
      currentBatchView.batch_id,
      JSON.stringify({ ...parsedConfig, isPreviewDone: false }),
    )
  }

  const openPreviewModal = () => {
    handleOpenPreviewModal()
  }


  const onTabChange = (activeKey: string) => {
    setCurrentTab(activeKey)
  }

  const handleCloseModal = () => {
    setShowPreview(false)
  }
  const handleOpenPreviewModal = () => {
    setShowPreview(true)
  }
  const handleQcDone = () => {
    handleCloseModal()
    setIsPreviewDone(true)
    updatePreviewStatus(true)
  }
  const getVideoUrl = () => {
    return batchForm.getFieldsValue().videoType === "youtube" ? batchForm.getFieldsValue().video_url : batchForm.getFieldsValue().video_url?.[0]
  }
  const onFormChange = () => {
    resetPreview()
    setIsPreviewDone(false)
  }

  const onCompleteChange = () => {
    setIsComplete(!isComplete)
  }
  const handleCompletedVideoPreview = (videoUrl: string, video_type: string, record: any) => {
    setCurrentCompletedVideo(videoUrl)
    setCompletedPoojaService(video_type === "youtube" ? true : false)
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination({
      ...pagination,
    })
  }

  const rowSelectionChange = (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    setShowUploadButton(selectedRows?.length > 0)
    setRowSelection({
      ...rowSelection,
      selectedRows: [...selectedRows],
    })
  }

  const [rowSelection, setRowSelection] = useState<any>({
    selectedRows: [],
    onChange: rowSelectionChange
  })

  const handleClickUploadMedia = () => {
    setShowMediaUploadModal(true)
  }

  const handleUploadMediaMultiple = async () => {
    setLoading(true)
    try {
      await mediaUploadForm.validateFields()
      const mediaUploadFormData = mediaUploadForm.getFieldsValue()
      let payload = mediaUploadFormData?.order_media?.map((item: any) => ({
        type: mediaUploadFormData?.order_media_type,
        media_url: item,
      }))
      const formData = {
        order_media: payload,
      }

      const setDelay = (ms = 100) => new Promise((r) => setTimeout(r, ms))
      let failedBatches = []

      for (const row of rowSelection?.selectedRows) {
        try {
          await setDelay()
          await monetisationPujaApi.batchApi.uploadOrderMedia(row?.batch_id, formData)
          message.success({
            content: `Order Media updated for - ${row?.batch_code}`,
            duration: 3,
          })
        } catch (error) {
          failedBatches.push(row?.batch_id);
          message.error({
            content: `Error while updating batch - ${row?.batch_code}`,
            duration: 2,
          })
        }
      }
      setLoading(false)
      setShowMediaUploadModal(false)
      setRowSelection({ ...rowSelection, selectedRowKeys: [] })
      setShowUploadButton(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancelUploadMedia = () => {
    setShowMediaUploadModal(false)
  }

  const handleClickCreateDelivery = (record: any) => {
    createOrderForm?.resetFields()
    setSelectedRow(record)
    setShowCreateOrderForm(true)
  }

  const createDelivery = () => {
    createOrderForm
      .validateFields()
      .then(async (values: any) => {
        const payload = deliveryOrderPayload(values, selectedRow?.batch_id)
        try {
          const successDelivery = await monetisationPujaApi.batchApi.createDeliveryOrder(payload)
          setIsDeliveryOrderCreated(true)
          setShowCreateOrderForm(false)
          message.success(successDelivery?.data?.data, 2)
          getBatchList()
        } catch (error) {
          message.error('Error while creating Delivery Order Creation!', 2)
        }
      })
      .catch(error => {
        console.log('Create delivery order error')
      })
  }


  const handleChangeStep = (step: number) => {
    setCurrentStep(step)
  }

  const fetchBatch = useCallback(async (batchCode?: string) => {
    try {
      const batchData = await monetisationPujaApi.batchApi.getBatchByCode(batchCode ?? currentBatchView?.batch_code!)
      message.success('Fetched latest batch data', 2)
      return batchData?.data?.data
    } catch (error) {
      message.error('Error while Fetching latest batch data', 2)
      return null
    }
  }, [currentBatchView?.batch_code, monetisationPujaApi.batchApi])

  const updateLatestBatchVideo = (batch: any) => {
    setShowEditVideoUrlForm(true)
    videoUrlForm.setFieldsValue({
      'batch_id': batch?.batch_id,
      'existing_url': batch?.video_url
    })
    setVideoFormObj({
      ...videoFormObj,
      batch_id: batch.batch_id,
      hash_code: batch.hash_code,
    })
  }

  const handleFormVideoUrlSubmit = () => {
    videoUrlForm
      .validateFields()
      .then(async values => {
        const videoUrlFormData = videoUrlForm.getFieldsValue()
        console.log(videoUrlFormData)
        const formData = {
          hash_code: videoFormObj?.hash_code,
          video_url: videoUrlFormData?.video_url,
          media_type: videoFormObj?.media_type
        }
        try {
          await monetisationPujaApi.batchApi.completeBatch(
            videoFormObj.batch_id,
            formData,
          )
          message.success({
            content: 'Batch updated successfully',
            duration: 3,
          })
          videoUrlForm?.resetFields()
          setShowEditVideoUrlForm(false)
          getBatchList()
        } catch (error) {
          message.error({
            content: 'Error while updating batch',
            duration: 3,
          })
          console.log(error)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const onSearch = async (criteria: string) => {
    const key = "search"
    message.open({
      key,
      type: 'loading',
      content: 'Searching...',
    });
    if (criteria) {
      try {

        const batchResponse = await monetisationPujaApi?.batchApi?.searchBatches(criteria?.trim())
        message.destroy(key);
        if (batchResponse?.data?.error) {
          message.error(batchResponse?.data?.error?.message);
        } else {
          setPaginatedBatchData([batchResponse?.data?.data])
        }
      } catch (error) {
        message.error(`Error occured:${error}`);
      }
    } else {
      getBatchList()
    }
  }

  return (
    <div style={{ padding: '0 40px' }}>
      {
        errorPage ?
          <Error text='Error While fetching Batch List!' /> :
          <>
            <AdminBreadcrumb
              routes={getPageBreadcrumbs(searchParams.get('name') || '', hubId || '')}
            />
            <BatchListPage
              meta_data={metaMap}
              onSearch={onSearch}
              itemList={paginatedBatchData || []}
              onViewBatchClick={onViewBatchClick}
              generateBatch={generateBatch}
              handleTableChange={handleTableChange}
              pagination={pagination}
              skuFilters={skuFilters!}
              onTabChange={onTabChange}
              isComplete={isComplete}
              onCompleteChange={onCompleteChange}
              reducedItemCount={reducedItemNoCount}
              batchCount={batchData.length}
              handleCompletedVideoPreview={handleCompletedVideoPreview}
              languageSelected={languageState?.monetisationLanguage}
              isGenerateBatch={isGenerateBatch}
              handleClickUploadMedia={handleClickUploadMedia}
              showUploadButton={showUploadButton}
              selectionType={selectionType}
              rowSelection={rowSelection}
              handleCreateDelivery={handleClickCreateDelivery}
              updateLatestBatchVideo={updateLatestBatchVideo}
              isLoading={loading}
            />
          </>
      }
      <Modal
        title="Update Batch video url"
        visible={showEditVideoUrlForm}
        onOk={handleFormVideoUrlSubmit}
        onCancel={() => {
          videoUrlForm?.resetFields()
          setShowEditVideoUrlForm(false)
        }}
        okText='Submit'
        cancelText='Edit'
        zIndex={2000}
        className='video-url-edit-form'
      >
        <Form
          form={videoUrlForm}
          layout='vertical'
          name='create store'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
        >
          <Col span={12}>
            <Form.Item
              label='Batch ID'
              name={['batch_id']}
              labelCol={{ span: 32 }}
              wrapperCol={{ span: 32 }}
              style={{ width: '450px' }}
            >
              <Input placeholder='Enter media url' readOnly disabled />
            </Form.Item>
            <Form.Item
              label='Existing media url'
              name={['existing_url']}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '450px' }}
              rules={[{ required: true, message: 'Please input media url' }]}
            >
              <Input placeholder='Enter media url' disabled />
            </Form.Item>
            <Form.Item
              label='New media URL'
              name={['video_url']}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '450px' }}
              rules={[
                {
                  required: true,
                  message: 'Please input media URL',
                },
                {
                  pattern: youtubeUrlRegex,
                  message: 'Please input a valid URL',
                },
              ]}
            >
              <Input placeholder='Enter media URL' />
            </Form.Item>

          </Col>
        </Form>

      </Modal>
      <BatchView
        visible={showbatchView}
        batchData={currentBatchView}
        onClose={closeHandler}
        currentStep={currentStep}
        cdnUrl={YODA_CDN_URL}
        preSignUrl={PUJA_PRESIGN_URL}
        form={batchForm}
        handleFormSubmit={handleFormSubmit}
        isPreviewDone={isPreviewDone}
        openPreview={openPreviewModal}
        onFormChange={onFormChange}
        fetchBatch={onViewBatchClick}
        handleChangeStep={handleChangeStep}
      />
      <PreviewModal
        isPujaService={currentBatchView?.product_type === "pooja"}
        visible={showPreview}
        closeModal={handleCloseModal}
        handleDoneQc={handleQcDone}
        videoUrl={getVideoUrl()}
        batchData={currentBatchView}
        videoType={batchForm.getFieldsValue().videoType}
      />
      {
        <CompletedPreviewModal
          title={currentCompletedVideo?.split('/')?.pop() || ''}
          visible={!!currentCompletedVideo}
          closeModal={() => setCurrentCompletedVideo('')}
          handleDoneQc={() => { }}
          videoUrl={currentCompletedVideo}
          batchData={currentBatchView}
          okTitle={''}
          closeTitle={'Close'}
          isPujaService={isCompletedPoojaService}
        />
      }
      <UploadMediaModal
        visibleMediaUpload={showMediaUploadModal}
        handleOk={handleUploadMediaMultiple}
        handleCancel={handleCancelUploadMedia}
        mediaUploadForm={mediaUploadForm}
      />
      <CreateDeliveryOrder
        showItemCreation={showCreateOrderForm}
        closeItemCreation={() => setShowCreateOrderForm(false)}
        form={createOrderForm}
        initialValues={null}
        createDelivery={createDelivery}
      />
    </div>
  )
}

export default withDefaultLayout(BatchListScreen)
