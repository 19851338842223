export const MUSIC_STATES = ['NEW_AUDIO', 'READY_TO_BE_MODERATED', 'READY_TO_BE_PUBLISHED', 'DEACTIVATED', 'PUBLISHED', 'UNSUCCESS_NEW_SONG'];
export const THIRD_PARTY_NAMES = ['times_music', 'bhakti_dhara', 'bhakti_mala', 'jukebox', 'pxy', 'sm']
export const AUDIO_TRANSITION_CONFIG = {
    'NEW_AUDIO': ["READY_TO_BE_MODERATED"],
    'READY_TO_BE_MODERATED': ['READY_TO_BE_PUBLISHED', 'DEACTIVATED'],
    'READY_TO_BE_PUBLISHED': ['PUBLISHED', 'DEACTIVATED'],
    'PUBLISHED': ['DEACTIVATED'],
    'DEACTIVATED': ['NEW_AUDIO'],
    'UNSUCCESS_NEW_SONG': [],
};




