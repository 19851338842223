import { Card, Drawer, Space, Table, TableProps } from 'antd'
import styled from 'styled-components'

import React, { FunctionComponent } from 'react'
export interface DrawerProps {
  title: string,
  visible: boolean,
  onClose?: ((e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void) | undefined,
  width?: number | string
}
export interface SocialScreenContainerProps {
  title: React.ReactNode,
  extra?: React.ReactNode,
  tableProps?: TableProps<any>,
  content?: React.ReactNode,
  drawerProps?: DrawerProps,
  drawerContent?: React.ReactNode,
  filtersUI?: React.ReactNode,
  customPagination?: React.ReactNode
}

const Container = styled.div`
  padding: 20px;
`

export const SocialScreenContainer: FunctionComponent<SocialScreenContainerProps> = ({
  title,
  extra,
  tableProps,
  drawerProps,
  drawerContent,
  content,
  filtersUI,
  customPagination
}) => {
  return (
    <Container>
      <Card
        title={title}
        extra={extra}
      >
        <Space direction='vertical' size='middle' style={{ width: '100%' }}>
          {filtersUI && filtersUI}
          {tableProps && <>
            <Table {...tableProps}></Table>
            {customPagination && customPagination}
          </>}

          {content && content}
        </Space>
      </Card>
      <Drawer
        placement="right"
        {...drawerProps}
      >
        {drawerContent}
      </Drawer>
    </Container>
  )
}
