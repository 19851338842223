import { prettifyCamelCase } from "@/utils/helper";
import { Form, FormItemProps, Select, SelectProps } from "antd";


interface SelectFormProps {
  onChange?: (val: string) => void;
  selected?: string;
  list?: { value: string, lable: string }[]
  optionList?: JSX.Element[]
  antdSelectProps?: SelectProps
}

export function FormSelect(props: SelectFormProps) {
  const { selected, onChange, list, optionList, antdSelectProps } = props;

  return <Select
    showSearch
    optionLabelProp="label"
    optionFilterProp="label"
    onChange={onChange}
    style={{ width: '200px' }}
    value={selected}
    {...antdSelectProps}
  >
    {optionList ? optionList : list && list.map((listItem, index) => (
      <Select.Option label={listItem.lable} value={listItem.value} key={listItem.value}>
        {listItem.lable}
      </Select.Option>
    ))}
  </Select>
}

interface FormItemSelectProps extends SelectFormProps {
  name: string, // only camel case is allowed 
  antdFormItemProps?: FormItemProps
}

export function FormItemSelect(props: FormItemSelectProps) {
  const { selected, onChange, list, antdSelectProps, optionList, name, antdFormItemProps } = props;
  return <Form.Item
    label={prettifyCamelCase(name)}
    name={name}
    {...antdFormItemProps}
  >
    <FormSelect {...{ selected, list, optionList, onChange, antdSelectProps }} />
  </Form.Item>
}
