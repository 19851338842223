import { Rashifal } from "@a4b/api/src/modules/Content/Rashifal/types";
import { SUPPORTED_LANGUAGES_LIST, getLanguageNameFromValue } from "@/utils/constants";
import { Button, Switch, TableColumnType, TablePaginationConfig, TableProps, Tag } from "antd";
import moment from "moment";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { openNotification } from "@/utils";
import { useAppContext } from "@/components/AppContext";
import { renderDate, renderDateTime } from "../../../../../components/TableHelpers/ColumnHelper";
import { EditOutlined } from "@ant-design/icons";
import { Quiz } from "@a4b/api/src/modules/Coins/types";
import styled from "styled-components";

const Light = styled.b`
 font-weight: 600;
`

export default function useTableConfig<T>(
  dataSource: T[],
  setActiveQuizId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setActiveDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | undefined>>,
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
): TableProps<Quiz[]> {
  const columns: TableColumnType<Quiz>[] = [
    {
      title: 'Code',
      dataIndex: 'code',
      width: '100px',
    },
    {
      title: 'Question',
      dataIndex: 'question',
      render(value, record, index) {
        return record.supportedLanguages.map((language, index) => {
          return <div>
            <Light>  {SUPPORTED_LANGUAGES_LIST.find((lang) => (lang.value === language))?.alt_name}</Light>: {value[language]}
            {record.supportedLanguages.length > index + 1 && <hr></hr>}
          </div>
        })
      },
    },
    {
      title: 'Options',
      dataIndex: 'options',
      render(value, record, index) {
        return record.supportedLanguages.map((language, index) => {
          return <div>
            <div style={{ display: "flex", width: '100%', marginBottom: 10, flexDirection: 'row', flexWrap: "wrap", gap: 10 }}>
              {
                value.map((option: any, index: number) => {
                  return <Tag color={index == record.correct_index ? "green" : 'red'}>{option[language]}</Tag>
                })
              }
            </div>
            {record.supportedLanguages.length > index + 1 && <hr></hr>}
          </div>
        })
      },
    },
    {
      title: 'Start date',
      dataIndex: 'start_at',
      render: renderDateTime
    },
    {
      title: 'End date',
      dataIndex: 'end_at',
      render: renderDateTime
    },
    {
      title: 'Initial correct  %',
      dataIndex: 'initial_correct_percentage',
      width: '50px',
    },
    {
      title: 'Final correct  %',
      dataIndex: 'final_correct_percentage',
      width: '50px',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      width: '100px',
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button type="link" onClick={() => { setActiveQuizId(value); setActiveDrawer(DRAWER_TYPE.UPDATE_QUIZ) }}> <EditOutlined />Edit</Button>
        </div>
      },
    }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  //@ts-ignore
  return { columns, dataSource, pagination, onChange: tableOnChange, size: 'small', bordered: true }
}