import { Button, Checkbox, Form, FormInstance, Input, InputNumber, Select, Space, Typography } from "antd";
import { SuggestionCardPayload } from "@a4b/api/src/modules/SuggestionCards/types";
import { SUPPORTED_LANGUAGES_LIST } from "@a4b/ui/src/modules/admin/constants";
import { AntdFileUploadV2 } from "../../../../../components/Forms/AntdFileUpload";
import { useAppContext } from "../../../../../components/AppContext";
import { useCallback } from "react";
import { CaretDownOutlined, CaretUpOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";


export interface CreateSuggestiveCardProps {
    form: FormInstance<SuggestionCardPayload>
}

const CreateSuggestiveCard = function (props: CreateSuggestiveCardProps) {
    const { form } = props;
    const { networkInstance } = useAppContext();

    const uploadMedia = useCallback(async (options: any): Promise<{ url: string, path: string }> => {
        const headers = new Headers()
        headers.append('ContentType', options.file.type)
        let data;
        if (options.file.type.endsWith('png')) {
            data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_png');
        }
        if (options.file.type.endsWith('jpg')) {
            data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_jpg');
        }
        if (options.file.type.endsWith('jpeg')) {
            data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_jpeg');
        }
        if (options.file.type.endsWith('gif')) {
            data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_gif');
        }
        if (options.file.type.endsWith('webp')) {
            data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_webp');
        }
        if (options.file.type.endsWith('svg+xml')) {
            data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_svg');
        }
        if (!data) {
            throw "Failed to get presinged url"
        }

        const { url, path } = data.data.data;
        const res = await fetch(url, {
            method: 'PUT',
            body: options.file,
            headers,
        })
        return { url, path }
    }, [networkInstance.clientWithHeaders.contentApi.audio])

    const cardType = Form.useWatch('card_type', form);

    return <div>
        <Form.Item required label="Card Type" name={'card_type'}>
            <Select style={{ width: '250px' }} >
                <Select.Option value="shubh_mantra"> Shubh mantra </Select.Option>
                <Select.Option value="god_set"> God set </Select.Option>
            </Select>
        </Form.Item>
        <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="language"
            label={"Language"}
            initialValue={'hi'}
            required
        >
            <Select style={{ width: '250px' }}>
                {SUPPORTED_LANGUAGES_LIST.filter((language) => language.value === 'hi').map((language) => {
                    return <Select.Option value={language.value}>{language.name} ({language.alt_name})</Select.Option>
                })}
            </Select>
        </Form.Item>
        <Form.Item required label="Song not played position " name="song_not_played_position">
            <InputNumber></InputNumber>
        </Form.Item>

        <Form.Item required label="Song paused position" name="song_paused_position">
            <InputNumber></InputNumber>
        </Form.Item>

        <Form.Item required label="Song played position" name="song_played_position">
            <InputNumber></InputNumber>
        </Form.Item>
        <Form.Item label="Active" name="active" valuePropName="checked" initialValue={false} required>
            <Checkbox></Checkbox>
        </Form.Item>
        {
            cardType === 'shubh_mantra' &&
            <div >
                <Form.Item name="god_set" hidden>
                    <Input></Input>
                </Form.Item>
                <div style={{ padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }}>
                    <Typography.Title>
                        Titles
                    </Typography.Title>
                    <Form.Item required name={["shubh_mantra", "title", "sunday"]} label="Shubh mantra title sunday">
                        <Input></Input>
                    </Form.Item>
                    <Form.Item required name={["shubh_mantra", "title", "monday"]} label="Shubh mantra title monday">
                        <Input></Input>
                    </Form.Item>
                    <Form.Item required name={["shubh_mantra", "title", "tuesday"]} label="Shubh mantra title thusday">
                        <Input></Input>
                    </Form.Item>
                    <Form.Item required name={["shubh_mantra", "title", "wednesday"]} label="Shubh mantra title wednesday">
                        <Input></Input>
                    </Form.Item><Form.Item required name={["shubh_mantra", "title", "thursday"]} label="Shubh mantra title thursday">
                        <Input></Input>
                    </Form.Item>
                    <Form.Item required name={["shubh_mantra", "title", "friday"]} label="Shubh mantra title friday">
                        <Input></Input>
                    </Form.Item>
                    <Form.Item required name={["shubh_mantra", "title", "saturday"]} label="Shubh mantra title saturday">
                        <Input></Input>
                    </Form.Item>
                </div>
                <div style={{ padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }}>
                    <Typography.Title>
                        Sub Titles
                    </Typography.Title>
                    <Form.Item required name={["shubh_mantra", "sub_title", "sunday"]} label="Shubh mantra sub_title sunday">
                        <Input></Input>
                    </Form.Item>
                    <Form.Item required name={["shubh_mantra", "sub_title", "monday"]} label="Shubh mantra sub_title monday">
                        <Input></Input>
                    </Form.Item>
                    <Form.Item required name={["shubh_mantra", "sub_title", "tuesday"]} label="Shubh mantra sub_title tuesday">
                        <Input></Input>
                    </Form.Item>
                    <Form.Item required name={["shubh_mantra", "sub_title", "wednesday"]} label="Shubh mantra sub_title wednesday">
                        <Input></Input>
                    </Form.Item><Form.Item required name={["shubh_mantra", "sub_title", "thursday"]} label="Shubh mantra sub_title thursday">
                        <Input></Input>
                    </Form.Item>
                    <Form.Item required name={["shubh_mantra", "sub_title", "friday"]} label="Shubh mantra sub_title friday">
                        <Input></Input>
                    </Form.Item>
                    <Form.Item required name={["shubh_mantra", "sub_title", "saturday"]} label="Shubh mantra sub_title saturday">
                        <Input></Input>
                    </Form.Item>
                </div>
                <div style={{ padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }}>
                    <Typography.Title>
                        Background images
                    </Typography.Title>
                    <AntdFileUploadV2
                        rules={[{ required: true }]}
                        name={["shubh_mantra", "back_ground_image", "sunday"]}
                        accept="image/png,image/webp"
                        s3UploadApi={uploadMedia}
                        title={'upload icon'}
                    />
                    <AntdFileUploadV2
                        rules={[{ required: true }]}
                        name={["shubh_mantra", "back_ground_image", "monday"]}
                        accept="image/png,image/webp"
                        s3UploadApi={uploadMedia}
                        title={'upload icon'}
                    />
                    <AntdFileUploadV2
                        rules={[{ required: true }]}
                        name={["shubh_mantra", "back_ground_image", "tuesday"]}
                        accept="image/png,image/webp"
                        s3UploadApi={uploadMedia}
                        title={'upload icon'}
                    />
                    <AntdFileUploadV2
                        rules={[{ required: true }]}
                        name={["shubh_mantra", "back_ground_image", "wednesday"]}
                        accept="image/png,image/webp"
                        s3UploadApi={uploadMedia}
                        title={'upload icon'}
                    />
                    <AntdFileUploadV2
                        rules={[{ required: true }]}
                        name={["shubh_mantra", "back_ground_image", "thursday"]}
                        accept="image/png,image/webp"
                        s3UploadApi={uploadMedia}
                        title={'upload icon'}
                    />
                    <AntdFileUploadV2
                        rules={[{ required: true }]}
                        name={["shubh_mantra", "back_ground_image", "friday"]}
                        accept="image/png,image/webp"
                        s3UploadApi={uploadMedia}
                        title={'upload icon'}
                    />
                    <AntdFileUploadV2
                        rules={[{ required: true }]}
                        name={["shubh_mantra", "back_ground_image", "saturday"]}
                        accept="image/png,image/webp"
                        s3UploadApi={uploadMedia}
                        title={'upload icon'}
                    />
                </div>

            </div>}
        {cardType === 'god_set' && <>
            {/* God set form  */}
            <Form.Item required name={["god_set", "title"]} label="God set title ">
                <Input></Input>
            </Form.Item>

            <AntdFileUploadV2
                rules={[{ required: true }]}
                name={['god_set', 'icon_path']}
                accept="image/svg+xml,image/png,image/webp"
                s3UploadApi={uploadMedia}
                title={'upload icon'}
            // fileValidation={iconValidations}
            // fileList={iconEditFileList}
            />

            <Form.List name={["god_set", "god_set"]} initialValue={[{}, {}]}>
                {(fields, { add, remove, move }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', alignItems: 'center', gap: 20, minHeight: '70px', padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 100 }}>
                                    <CaretUpOutlined disabled={name !== 0} onClick={() => move(name, name - 1)} style={{ cursor: 'pointer', fontSize: 20 }} />
                                    <CaretDownOutlined disabled={name !== fields.length - 1} onClick={() => move(name, name + 1)} style={{ cursor: 'pointer', fontSize: 20 }} />
                                </div>
                                <AntdFileUploadV2
                                    rules={[{ required: true }]}
                                    name={[name, 'image_path']}
                                    accept="image/png,image/webp"
                                    s3UploadApi={uploadMedia}
                                    title={'upload icon'}
                                // fileValidation={iconValidations}
                                // fileList={iconEditFileList}
                                />
                                <Form.Item
                                    {...restField}
                                    name={[name, 'god_id']}
                                    label="God id"
                                    required
                                    style={{ width: '300px' }}
                                >
                                    <Input placeholder="God id" />
                                </Form.Item>
                                {
                                    name > 1 &&
                                    <Button><MinusCircleOutlined onClick={() => remove(name)} /></Button>
                                }
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add god set
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

        </>

        }

        <Form.Item>
            <Button type="primary" htmlType="submit"> Submit </Button>
        </Form.Item>
    </div>
}

export default CreateSuggestiveCard;