import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import AntdFormWrapper from "../../../../../components/Forms/AntdFormWrapper";
import { FormInstance } from "antd";
import AddQuiz from "../components/AddQuiz";
import { useAppContext } from "../../../../../components/AppContext";
import { AddQuizPayload, Quiz, UpdateQuizPayload } from "@a4b/api/src/modules/Coins/types";

// This interface is just a example for AntdFormWrapper 
interface TestComProps {
    form: FormInstance<string>
}

const TestCom = ({ form }: TestComProps) => <div> CREATE SOMETHING 2 DATA </div>
export enum DRAWER_TYPE {
    CREATE_QUIZ = 'Create Quiz',
    UPDATE_QUIZ = 'Update Quiz'
}



export default function useDrawerConfig(
    setActiveDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | undefined>>,
    getQuizes: () => Promise<void>,
    quiz?: Quiz,
    nextQuestionInitValues?: {
        code: string | undefined;
        start_at: moment.Moment;
        end_at: moment.Moment;
    },
    activeDrawer?: DRAWER_TYPE,
): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {
    const { networkInstance, userProfile } = useAppContext();
    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_QUIZ,
                width: 1000,
            },
            drawerContent: <AntdFormWrapper<any, any>
                FormFields={AddQuiz}
                initValues={nextQuestionInitValues}
                createApi={networkInstance.clientWithHeaders.coins.addQuiz}
                submitSuccessfulCallback={() => { getQuizes(); setActiveDrawer(undefined) }}
            />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.UPDATE_QUIZ,
                width: 900,
            },
            drawerContent: <AntdFormWrapper<UpdateQuizPayload, UpdateQuizPayload>
                FormFields={AddQuiz}
                updateApi={networkInstance.clientWithHeaders.coins.updateQuizPayload}
                // @ts-ignore
                formData={{ ...quiz, updated_by: userProfile.email || 'NA' }}
                submitSuccessfulCallback={() => { getQuizes(); setActiveDrawer(undefined) }}
            />
        }
    ], [getQuizes, networkInstance.clientWithHeaders.coins.addQuiz, networkInstance.clientWithHeaders.coins.updateQuizPayload, nextQuestionInitValues, quiz, setActiveDrawer, userProfile.email])

    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}