import React, { useCallback, useEffect, useState } from 'react'
import { message, Modal } from 'antd'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import ManageUsersPage from './components/ManageUserPage'
import { HubList, Users } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import CreateUser from './components/CreateUser'
import { formatUserArchive } from './utils/helper'

const getPageBreadcrumbs = () => [
    {
        path: '/monetisation/offerings/manage-users/user',
        breadcrumbName: 'Manage Users',
    },
]

const ManageUserScreen: React.FC = () => {
    const { networkInstance, monetisationRoles } = useAppContext()
    const { monetisationOfferingsApi,userApi } = networkInstance.clientWithHeaders
    const [showUserCreation, setShowUserCreation] = React.useState(false)
    const [isEditJourney, setIsEditJourney] = React.useState(false)

    const [userList, setUserList] = useState<Users[]>([])
    const [user, setUser] = useState<Users>()
    const [hubList, setHubList] = useState<HubList>()

    const getUserList = useCallback(async () => {
        try {
            const userList = await userApi.getUserList('regular', '', '')
            setUserList(userList?.data?.data?.users)
        } catch (error) {
            message.error({
                content: 'Error while fetching user List',
                duration: 3,
            })
            console.log(error)
        }
    },[])

    const getHubList = async () => {
        try {
            const hubList = await monetisationOfferingsApi.hub.getHubList({
                limit: 100,
                offset: 0,
            }, 'dropdown')
            setHubList(hubList?.data?.data)
        } catch (error) {
            console.log(error)
            message.error('Error while fetching hub list', 3)
        }
    }

    useEffect(() => {
        getUserList();
        getHubList()
    }, [])

    const onCreateNew = () => {
        setShowUserCreation(true)
        setIsEditJourney(false)
        setUser(undefined)
    }

    const closeUserCreationForm = () => {
        setShowUserCreation(false)
        isEditJourney && setIsEditJourney(false)
        setUser(undefined)
        getUserList()
        getHubList()
    }

    const triggerEdit = (user: Users) => {
        setUser(user)
        setIsEditJourney(true)
        setShowUserCreation(true)
    }

    const archiveUser = async (user: Users) => {
        try {
            const updateFormData = formatUserArchive(user, monetisationRoles?.id, 'ARCHIVE_MONETIZATION_USER')
            const id = user?.id || ''
            await userApi.UpdateUser(
                { ...updateFormData, ...{ id: id } },
                id,
            )
            getUserList();
            getHubList()
            message.success({ content: 'User Archived Successfully', duration: 3 })
        } catch (error) {
            message.error({
                content: 'Error occured while archiving user',
                duration: 3,
            })
        }
    }


    const triggerDelete = (user: Users) => {
        Modal.confirm({
            title: 'Are you sure you want to Delete?',
            content: user.user_type === 'hub_associate' ? 'This user might have active batches.' : '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                archiveUser(user)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    return (
        <div style={{ padding: '0 40px' }}>
            <AdminBreadcrumb routes={getPageBreadcrumbs()} />
            <ManageUsersPage
                onCreateNew={onCreateNew}
                triggerEdit={triggerEdit}
                triggerDelete={triggerDelete}
                dataSource={userList}
                hubList={hubList}
            />
            {
                <CreateUser
                    showUserCreation={showUserCreation}
                    closeUserCreation={closeUserCreationForm}
                    isEditJourney={isEditJourney}
                    formData={user}
                    hubList={hubList}
                />
            }
        </div>
    )
}

export default withDefaultLayout(ManageUserScreen)
