import { Button, Form, Popconfirm, Select } from "antd";
import Search from "antd/lib/input/Search";
import { useState, useCallback } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../../../components/AppContext";
import { openNotification } from "../../../../../utils";
import { useForm, useWatch } from "antd/lib/form/Form";
import { UserData } from "@a4b/api/src/modules/AdminControl/types";
import { USER_ROLES } from "../../../../../utils/constants";

const ContentContainer = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const StyledSearch = styled(Search)`
    width: 500px;
`
interface ContentProps {

}

const Content = function (props: ContentProps) {
    const form = useForm()[0]
    const { networkInstance } = useAppContext();
    const [userDetails, setUserDetails] = useState<UserData>();

    const getUserDetails = useCallback(async (user) => {
        try {
            const res = await networkInstance.clientWithHeaders.adminControl.getUserData(user)
            setUserDetails(res.data.data);
        } catch (error) {

        }
    }, [networkInstance.clientWithHeaders.adminControl])

    const onSearch = (value: any) => {
        console.log({ value })
        getUserDetails(value);
    }

    const roles = useWatch('roles', form) || [];
    let isModified = false;
    if (roles?.length === userDetails?.roles?.length) {
        for (let i = 0; i < roles?.length; i++) {
            if (roles[i] !== userDetails?.roles[i]) {
                isModified = true
            }
        }
    } else {
        isModified = true
    }
    return <ContentContainer>
        <StyledSearch placeholder="Search using email, mobile, user id..." onSearch={onSearch} enterButton />
        <br></br>
        <br></br>
        <Form form={form} onFinish={async (values) => {
            const exsitingRolesSet = new Set(userDetails?.roles);
            const newRoles = new Set(values.roles);
            const promises: any[] = []
            values.roles.forEach((role: USER_ROLES) => {
                // @ts-ignore
                if (!exsitingRolesSet.has(role)) {
                    promises.push(networkInstance.clientWithHeaders.adminControl.assignRole(userDetails?.id as string, role as ('ADMIN_SUPER' | 'ADMIN_CONTENT' | 'ADMIN_MONETIZATION' | 'ADMIN_DEFAULT')))
                }
            });
            userDetails?.roles.forEach((role: string) => {
                if (!newRoles.has(role)) {
                    promises.push(networkInstance.clientWithHeaders.adminControl.deleteRole(userDetails.id, role as ('ADMIN_SUPER' | 'ADMIN_CONTENT' | 'ADMIN_MONETIZATION' | 'ADMIN_DEFAULT')))
                }
            })
            try {
                const res = await Promise.all(promises)
                setUserDetails({ ...userDetails as UserData, roles: values.roles })
                openNotification("success", { message: "Roles updated sucessfully." })
            } catch (error) {
                console.log({ error })
                openNotification("error", { message: "Something went wrong." })
                return error;
            }
            return
        }}>
            {
                userDetails &&
                <>
                    <Form.Item name={"roles"} label="Roles" initialValue={userDetails.roles}>
                        <Select mode="multiple" placeholder="" style={{ width: '450px' }}>
                            <Select.Option value={USER_ROLES.ADMIN_CONTENT}>
                                {USER_ROLES.ADMIN_CONTENT}
                            </Select.Option>
                            <Select.Option value={USER_ROLES.ADMIN_MONETIZATION}>
                                {USER_ROLES.ADMIN_MONETIZATION}
                            </Select.Option>
                            <Select.Option value={USER_ROLES.ADMIN_SUPER}>
                                {USER_ROLES.ADMIN_SUPER}
                            </Select.Option>
                            <Select.Option value={USER_ROLES.ADMIN_DEFAULT}>
                                {USER_ROLES.ADMIN_DEFAULT}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Popconfirm
                        title={<p>Assigning New roles <b>{roles.join()}</b> to <b>{userDetails.email}</b></p>}
                        onConfirm={() => { form.submit() }}
                        okText="Confirm"
                        cancelText="No"
                    >
                        <Button
                            disabled={!isModified}
                            type="primary">
                            Update
                        </Button>
                    </Popconfirm>
                </>
            }

        </Form>
    </ContentContainer>
}

export default Content;