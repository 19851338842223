import { Form, Input, Row, Select, Col, Button } from "antd"
import React from "react"
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"

interface Props {
    fields: any
    cdnUrl: string
    preSignUrl: string
    add: () => void
    remove: (item: any) => void
    datatype: string
}
const { Option } = Select

const BannerLarge: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, datatype }) => {
    return (
        <>
            <Row justify="space-between" style={{ width: '100%' }}>
                {fields.map((field: any, index: number) => {
                    return (
                        <div key={index} style={{
                            border: '1px solid #e0dcdc',
                            marginRight: '0px',
                            marginBottom: '10px',
                            padding: '10px',
                            width: '45%'
                        }}>
                            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Form.Item
                                    label={`Deeplink ${index + 1}`}
                                    name={[field.name, 'deeplink']}
                                    labelCol={{ span: 32 }}
                                    wrapperCol={{ span: 32 }}
                                    style={{ flexBasis: '80%', marginRight: '10px' }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Deeplink',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter Deeplink' />
                                </Form.Item>
                                <Button
                                    style={{ marginTop: '28px', marginRight: '5px', flexBasis: '10%', }}
                                    onClick={() => {
                                        remove(field.name)
                                    }}
                                    type='ghost'
                                    icon={<DeleteOutlined />}
                                    size='middle'
                                    danger
                                ></Button>
                            </Row>

                            <Row justify="space-between">
                                <Form.Item
                                    label={`Media type ${index + 1}`}
                                    name={[field.name, 'media', 'media_type']}
                                    labelCol={{ span: 18 }}
                                    wrapperCol={{ span: 18 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter description of the category',
                                        },
                                    ]}
                                >
                                    <Select style={{ width: 150 }} placeholder='Please Select Media type'>
                                        <Option value={'image'} key={'image'}>
                                            Image
                                        </Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={`Media`}
                                    name={[field.name, 'media', 'media_url']}
                                    rules={[
                                        {
                                            required: true,
                                            min: 0,
                                            max: 1,
                                            validator: async (rules: any, values: any) => {
                                                if (values?.length < rules.min) {
                                                    return Promise.reject('Please upload Media')
                                                }
                                            },
                                        },
                                    ]}
                                    valuePropName='fileList'
                                    style={{ paddingLeft: '10px' }}
                                >
                                    <S3Upload
                                        cdnUrl={cdnUrl}
                                        preSignUrl={preSignUrl}
                                        min={1}
                                        max={1}
                                    />
                                </Form.Item>

                            </Row>
                        </div>
                    )
                })}
                <Col span={24} style={{ paddingTop: '10px' }}>
                    <Row justify='start'>
                        <Col span={4}>
                            <Button
                                onClick={() => {
                                    add()
                                }}
                                type='ghost'
                                icon={<PlusCircleOutlined />}
                                size='middle'
                            >
                                {datatype}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default BannerLarge