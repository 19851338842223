import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import CreateJourney from "../components/CreateJourney";
import ViewJourney from "../components/ViewJourney";
import { Journey } from "../utils/types";
import CustomNudge from "../components/CustomNudge";

export enum DRAWER_TYPE {
    CREATE_JOURNEY = 'Create Journey',
    VIEW_JOURNEY = 'View Journey',
    EDIT_JOURNEY = 'Edit Journey',
    CUSTOM_NUDGE = 'CUSTOM_NUDGE'
}



export default function useDrawerConfig(viewJourney?: Journey, editJourney?: Journey, activeDrawer?: DRAWER_TYPE): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {


    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_JOURNEY,
                width: `90vw`,
            },
            drawerContent: <CreateJourney />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.VIEW_JOURNEY,
                width: `90vw`,
            },
            drawerContent: viewJourney && <ViewJourney journey={viewJourney} />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.EDIT_JOURNEY,
                width: `90vw`,
            },
            drawerContent: editJourney && <CreateJourney journey={editJourney} />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CUSTOM_NUDGE,
                width: `60vw`,
            },
            drawerContent: <CustomNudge />
        }
    ], [editJourney, viewJourney])
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}