import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import {
  PaginationParams,
  Response,
  Section,
  SectionDetails,
  SectionList,
} from './types'
import { apiClient } from '../../../apiClient'

class SectionsApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createSection = (payload: Section) =>
    this.api.post(`gw1/titan/internal/v1/sections`, payload)

  updateSection = (sectionId: string, payload: Section) =>
    this.api.put(`gw1/titan/internal/v1/sections/${sectionId}`, payload)

  getSectionById = (sectionId: string) =>
    this.api.get<Response<SectionDetails>>(
      `gw1/titan/internal/v1/sections/${sectionId}`,
    )

  getSections = (pagination: PaginationParams) =>
    this.api.get<SectionList>(`gw1/titan/internal/v1/sections`, {
      params: pagination,
    })
}

export default SectionsApi
