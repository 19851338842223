import { Button, DatePicker, Form, message, Select } from 'antd'
import { SUPPORTED_LANGUAGES_CONFIG_ENGLISH_MODE } from '@a4b/ui/src/modules/admin/constants'
import moment from 'moment'

import React, { useEffect } from 'react'

export interface CloneScheduleFormFields {
  cloneFrom: moment.Moment
  cloneTo: moment.Moment
  language: string
}

export interface Props {
  cloneFrom?: moment.Moment
  languageCode?: string
  onCloneScheduleSubmit: (values: CloneScheduleFormFields) => Promise<{ success: boolean, message: string }>
  onClose: () => void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getFromDateDisabled = (current: moment.Moment) => {
  return current && current > moment().startOf('day')
}

const getToDateDisabled = (current: moment.Moment) => {
  return (
    current &&
    (current < moment().startOf('day') || current > moment().add(30, 'd'))
  )
}

const { useForm } = Form
export const CloneScheduleForm: React.FC<Props> = ({
  cloneFrom,
  onCloneScheduleSubmit,
  languageCode,
  onClose,
}) => {
  const [form] = useForm<CloneScheduleFormFields>()

  useEffect(() => {
    if (!cloneFrom) return
    form.setFieldsValue({ cloneFrom, language: languageCode })
  }, [cloneFrom])

  const onFinish = async (values: CloneScheduleFormFields) => {
    onClose()
    const response = await onCloneScheduleSubmit(values)
    response.success
      ? message.success(response.message)
      : message.error(response.message)
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      onFinish={onFinish}
    >
      <Form.Item label='From Date' name={'cloneFrom'}>
        <DatePicker disabled={!!cloneFrom} />
      </Form.Item>
      <Form.Item label='To Date' name={'cloneTo'}>
        <DatePicker disabledDate={getToDateDisabled} />
      </Form.Item>
      <Form.Item label='Language' name={'language'} required>
        <Select
          showSearch
          optionLabelProp="label"
          optionFilterProp="label"
          style={{ width: '200px' }}
          disabled={!!languageCode}
        >
          {SUPPORTED_LANGUAGES_CONFIG_ENGLISH_MODE.map((lang, index) => (
            <Select.Option label={lang.alt_name} value={lang.value} key={lang.code}>
              {lang.alt_name}( {lang.name} )
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ span: 20 }}>
        <Button type='primary' htmlType='submit'>
          Clone
        </Button>
      </Form.Item>
    </Form>
  )
}
