import {
  Button,
  Col,
  Image,

  InputNumber,
  Row,
  Space,
  Tag,
  Typography
} from 'antd'

import React from 'react'
import { Product } from '@a4b/api/src/modules/Monetisation/Puja/types'

const { Title } = Typography

interface Props {
  formItems: Product
  added: (item: Product) => void
}

const OfferingItem: React.FC<Props> = ({ formItems, added }) => (
  <Row
    style={{ paddingTop: '10px', width: '100%' }}
    key={`${formItems.id}-view`}
  >
    <Col span={3}>
      <Image src={formItems.image_url} />
    </Col>
    <Col span={10}>
      <div style={{ paddingLeft: '10px' }}>
        <Title level={5}>{formItems.title}</Title>
        <Row>{formItems.description}</Row>
      </div>
    </Col>
    <Col span={3} style={{ justifyContent: 'center', alignItems: 'start', display: 'flex', flexShrink: 0 }}>
      <Tag color='blue'>{formItems?.item_type}</Tag>
    </Col>
    <Col span={4}>
      <InputNumber value={formItems.price} disabled />
    </Col>
    <Col span={1}></Col>
    <Col span={2}>
      <Space>
        <Button
          type='primary'
          onClick={() => {
            added(formItems)
          }}
          size='small'
        >
          Add
        </Button>
      </Space>
    </Col>
  </Row>
)

export default OfferingItem
