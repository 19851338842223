import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { Faq, Faqs, Response } from './types'

class FaqsApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createFaqs = (payload: Faqs) =>
    this.api.post<Response<any>>(`/yoda/internal/v1/faq/create-faq`, payload)

  updateFaqs = (payload: Faqs) =>
    this.api.put<Response<any>>(`/yoda/internal/v1/faq/update-faq`, payload)

  deleteFaq = (id: string) =>
    this.api.delete<Response<any>>(`/yoda/internal/v1/faq/${id}`)

  getFaqs = ({ faq_type }: { faq_type: string }) =>
    this.api.get<Response<Faq[]>>(
      `yoda/internal/v1/faq/faq-list?faq_type=${faq_type}`,
    )
}

export default FaqsApi
