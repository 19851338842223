import styled from "styled-components"
import { NudgeData } from "../utils/types"
import { IMAGE_TYPES, LANGUAGE_TYPES, NUMBER_TYPES, TEMPLATE_TYPES, URL_TYPES } from "../../Journey/utils/contants"
import { Card, Image, Table } from "antd"
import { NEW_CDN_HOST } from "../../../../../utils/constants"
import { renderDate } from "../../../../../components/HelperComponents"

const StyledTr = styled.tr`
  
`
const StyledTable = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
`

const StyledTd = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 4px 8px;
  text-align: center;
`
const StyledTh = styled.th`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 4px 8px;
  text-align: center;
  background: #f5f5f5;
`

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    border: 1px solid #dddddd;
    padding: 10px;
    border-radius: 5px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`


interface ViewNudgeProps {
    nudge: NudgeData
}
const ViewNudge = function (props: ViewNudgeProps) {
    const { nudge } = props;
    const nudgeArray = Object.keys(nudge).map((key) => ({
        key: key as keyof NudgeData,
        value: nudge[key as keyof NudgeData]
    }))

    const renderAttribute = (attribute: string, value: any) => {
        switch (true) {
            case URL_TYPES.has(attribute):
                return <span> <a href={value}>{value}</a></span>
            case LANGUAGE_TYPES.has(attribute):
                const languages: string[] = Object.keys(value)
                const languageTexts: string[] = Object.values(value)
                return <div>
                    <StyledTable >
                        <thead>
                            {
                                languages.map((language) => {
                                    return <StyledTh key={language}>{language}</StyledTh>
                                })
                            }

                        </thead>
                        <StyledTr>
                            {
                                languageTexts.map((languageText) => {
                                    return <StyledTd >{languageText}</StyledTd>
                                })
                            }
                        </StyledTr>
                    </StyledTable>
                </div>
            case IMAGE_TYPES.has(attribute):
                return <Image
                    width={100}
                    src={NEW_CDN_HOST + '/' + value}
                />;
            case NUMBER_TYPES.has(attribute):
                return <span>{value}</span>
            case TEMPLATE_TYPES.has(attribute):
                return <Card>
                    {
                        value.map((question: any) => {
                            const titleLanguages: string[] = Object.keys(question['title'])
                            const titleLanguageTexts: string[] = Object.values(question['title'])
                            const options = question.options || [];
                            const placeholders = question.placeholder || {};
                            const placeholderLanguagesKeys: string[] = Object.keys(placeholders)
                            const placeholderLanguagesTexts: string[] = Object.values(placeholders)
                            return <><OptionsContainer >
                                {<div><strong>Code  </strong>: {question.code}  </div>}
                                {<div><strong>Type  </strong>: {question.type}  </div>}
                                <div>
                                    <span><strong>Title </strong></span>
                                    <StyledTable >
                                        <thead>
                                            {
                                                titleLanguages.map((language) => {
                                                    return <StyledTh key={language}>{language}</StyledTh>
                                                })
                                            }
                                        </thead>
                                        <StyledTr>
                                            {
                                                titleLanguageTexts.map((languageText) => {
                                                    return <StyledTd >{languageText}</StyledTd>
                                                })
                                            }
                                        </StyledTr>
                                    </StyledTable>
                                </div>
                                <div>
                                    {
                                        options.length > 0 && <>
                                            {options.map((option: any, index: number) => {
                                                const optionLanguages: string[] = Object.keys(option)
                                                const optionLanguageTexts: string[] = Object.values(option)
                                                return <>
                                                    <span><strong> Option {index += 1} </strong></span>
                                                    <StyledTable >
                                                        <thead>
                                                            {
                                                                optionLanguages.map((language) => {
                                                                    return <StyledTh key={language}>{language}</StyledTh>
                                                                })
                                                            }
                                                        </thead>
                                                        <StyledTr>
                                                            {
                                                                optionLanguageTexts.map((languageText) => {
                                                                    return <StyledTd >{languageText}</StyledTd>
                                                                })
                                                            }
                                                        </StyledTr>
                                                    </StyledTable>
                                                </>
                                            })}
                                        </>
                                    }
                                </div>
                                <div>
                                    {
                                        placeholderLanguagesKeys.length > 0 && <>
                                            <span><strong>Placeholders </strong></span>
                                            <StyledTable >
                                                <thead>
                                                    {
                                                        placeholderLanguagesKeys.map((language) => {
                                                            return <StyledTh key={language}>{language}</StyledTh>
                                                        })
                                                    }
                                                </thead>
                                                <StyledTr>
                                                    {
                                                        placeholderLanguagesTexts.map((languageText) => {
                                                            return <StyledTd >{languageText}</StyledTd>
                                                        })
                                                    }
                                                </StyledTr>
                                            </StyledTable>
                                        </>
                                    }
                                </div>
                                {question.limit && <div><strong>Limit  </strong>: {question.limit}  </div>}
                            </OptionsContainer>

                                <div style={{ height: '10px' }}>

                                </div>
                            </>
                        })
                    }
                </Card>
            default:
                return null;
        }
    }

    const renderValue = function (key: keyof NudgeData, value: any) {
        switch (key) {
            case 'template':
                return <span>{value} </span>;
            case 'page_tag':
                return <span> {value} </span>;
            case 'anchor_tag':
                return <span> {value} </span>;
            case 'created_at':
                return renderDate(value);
            case 'updated_at':
                return renderDate(value);
            case 'id':
                return value;
            case 'code':
                return value;
            case 'attributes':

                const attributeArray = Object.keys(value).map((key) => ({
                    key: key,
                    value: value[key]
                }));

                const columns = [
                    {
                        title: 'Attribute Key',
                        dataIndex: 'key',
                        align: 'center'
                    },
                    {
                        title: 'Value',
                        dataIndex: 'value',
                        render(value: any, record: { key: keyof NudgeData, value: any }, index: number) {
                            return renderAttribute(record.key, value);
                        },
                        align: 'center'
                    }
                ]
                // @ts-ignore
                return < Table dataSource={attributeArray} columns={columns} style={{ fontSize: '10px' }} size="small" pagination={false} showHeader={false} />
        }
    }


    const columns = [
        {
            title: 'Nudge Key',
            dataIndex: 'key',
            align: 'center'
        },
        {
            title: 'Value',
            dataIndex: 'value',
            render(value: any, record: { key: keyof NudgeData, value: any }, index: number) {
                return renderValue(record.key, value);
            },
            align: 'center'
        }
    ]

    // @ts-ignore
    return <Table dataSource={nudgeArray} size="large" columns={columns} bordered pagination={false} showHeader={false} />
}

export default ViewNudge;