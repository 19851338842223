import { useCallback, useEffect, useState } from "react"
import { useAppContext } from "@/components/AppContext"
import { Button, Col, DatePicker, Drawer, Form, Row, Spin, Typography, message, Modal, Select, Tag } from "antd"
import { useForm } from 'antd/lib/form/Form'
import { YODA_CDN_URL, PUJA_PRESIGN_URL, A4B_APPS } from '@/utils/constants'
import { LoadingOutlined } from "@ant-design/icons"
import PujaMedia from "../PujaMedia"
import MessageCard from "../MessageCard"
import { PujaUpdateForm as PujaUpdateFormType, parseStoreResponse, preparePayload } from "../../utils/helper"

const { Title } = Typography
const { Option } = Select

interface PujaUpdateFormProps {
    showForm: boolean
    store: { id: string, title: string }
    handleCloseForm: (updateStoreList?: boolean) => void
}

const PujaUpdateForm: React.FC<PujaUpdateFormProps> = ({ showForm, store, handleCloseForm }) => {
    const [pujaUpdateForm] = useForm()
    const { networkInstance, countryState, app } = useAppContext()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi

    const [currentPujaState, setCurrentPujaState] = useState('BOOKED')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isEnabled, setIsEnabled] = useState<boolean>(false)
    const [showReschedule, setShowReschedule] = useState<boolean>(false)
    const [updateConfirmDialog, setUpdateConfirmDialog] = useState<boolean>(false)

    const getStoreById = useCallback(async (storeId: string) => {
        setIsLoading(true)
        let storeResponse
        if (storeId) {
            storeResponse = await monetisationPujaApi.storesApi.getStoreById(
                storeId,
                countryState?.countryCode
            )
        }
        const storeResponseData = storeResponse?.data?.data
        const parsedStoreResponse = parseStoreResponse(storeResponseData)
        pujaUpdateForm.setFieldsValue(parsedStoreResponse)
        setCurrentPujaState((parsedStoreResponse?.puja_store_state && parsedStoreResponse?.puja_store_state?.length > 0) ? parsedStoreResponse?.puja_store_state : 'BOOKED')
        setIsLoading(false)
    }, [countryState?.countryCode, monetisationPujaApi.storesApi, pujaUpdateForm])

    useEffect(() => {
        store?.id && getStoreById(store?.id)
    }, [getStoreById, store?.id])


    const updatePujaDetails = async (values: PujaUpdateFormType) => {
        if (values?.puja_end_time && !values?.priest_medias) {
            message.warning('Please upload Puja end video', 3)
            return
        }
        const payload = preparePayload(values)
        try {
            await monetisationPujaApi.storesApi.updatePujaStatus(
                store?.id,
                payload,
                countryState?.countryCode
            )
            handleCloseForm(true)
            message.success({ content: 'Puja Status updated Successfully', duration: 3 })
        } catch (error: any) {
            message.error({ content: error.response.data.error.message, duration: 3 })
            console.log({ error })
        }
    }

    const submitForm = () => {
        pujaUpdateForm
            .validateFields()
            .then((values: any) => {
                updatePujaDetails(values)
            })
            .catch((error) => {
                console.log('Puja Update form error', error)
            })
    }

    return (
        <Drawer
            title={
                <Row justify="space-between" style={{ width: '100%' }}>
                    <span>Puja Update Form</span>
                    <Tag color="blue">{currentPujaState}</Tag>
                </Row>
            }
            placement='right'
            onClose={() => {
                handleCloseForm()
                pujaUpdateForm?.resetFields()
            }}
            visible={showForm}
            destroyOnClose={true}
            width={800}
        >
            {isLoading ?
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
                </div>
                :
                <Form
                    form={pujaUpdateForm}
                    layout='vertical'
                    initialValues={{ remember: true }}
                    onFinish={submitForm}
                    onFieldsChange={() => setIsEnabled(true)}
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start' }}
                >
                    <Col style={{ width: '100%' }}>
                        <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            <Title level={5} style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {store?.title}
                            </Title>
                        </div>

                        <div style={{ marginTop: '10px' }}>
                            <Form.Item
                                name='puja_start_time'
                                label='Puja start Date & Time'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Pick time',
                                    },
                                ]}
                            >
                                <DatePicker showTime />
                            </Form.Item>

                            <Form.Item
                                label='Puja start media'
                            >
                                <Row>
                                    <Form.List name={['puja_media']}>
                                        {(fields, { add, remove, move }) => {
                                            return (
                                                <PujaMedia
                                                    onSortEnd={({ oldIndex, newIndex }) => {
                                                        move(oldIndex, newIndex)
                                                    }}
                                                    distance={1}
                                                    helperClass='sortableHelper'
                                                    fields={fields}
                                                    add={add}
                                                    remove={remove}
                                                    cdnUrl={YODA_CDN_URL}
                                                    preSignUrl={PUJA_PRESIGN_URL}
                                                    datatype='Puja start media'
                                                    form={pujaUpdateForm}
                                                />
                                            )
                                        }}
                                    </Form.List>
                                </Row>
                            </Form.Item>

                            <Form.Item
                                name='puja_end_time'
                                label='Puja end Date & Time'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Pick time',
                                    },
                                ]}
                            >
                                <DatePicker showTime />
                            </Form.Item>

                            <Form.Item noStyle shouldUpdate={(prevValue, currenValue) => prevValue?.puja_end_time !== currenValue?.puja_end_time}>
                                {({ getFieldValue }) => {
                                    if (getFieldValue('puja_end_time')) {
                                        return <Form.Item label='Add Puja End Media'>
                                            <Row>
                                                <Form.List name={['priest_medias']}>
                                                    {(fields, { add, remove, move }) => {
                                                        return (
                                                            <PujaMedia
                                                                onSortEnd={({ oldIndex, newIndex }) => {
                                                                    console.log('oldIndex', oldIndex, 'newIndex', newIndex);
                                                                    move(oldIndex, newIndex);
                                                                }}
                                                                distance={1}
                                                                helperClass='sortableHelper'
                                                                fields={fields}
                                                                add={add}
                                                                remove={remove}
                                                                cdnUrl={YODA_CDN_URL}
                                                                preSignUrl={PUJA_PRESIGN_URL}
                                                                datatype='Puja end Medias'
                                                                form={pujaUpdateForm}
                                                            />
                                                        );
                                                    }}
                                                </Form.List>
                                            </Row>
                                        </Form.Item>
                                    }
                                }}

                            </Form.Item>

                            <Row justify="space-between" style={{ alignItems: 'center', marginBottom: '20px' }}>
                                <Form.Item
                                    label='Message cards'
                                    style={{ width: '100%' }}
                                >
                                    <Form.List name={['message_cards']}>
                                        {(fields, { add, remove, move }) => {
                                            return (
                                                <MessageCard
                                                    onSortEnd={({ oldIndex, newIndex }) => {
                                                        console.log('oldIndex', oldIndex, 'newIndex', newIndex)
                                                        move(oldIndex, newIndex)
                                                    }}
                                                    distance={1}
                                                    helperClass='sortableHelper'
                                                    form={pujaUpdateForm}
                                                    fields={fields}
                                                    add={add}
                                                    remove={remove}
                                                    cdnUrl={YODA_CDN_URL}
                                                    preSignUrl={PUJA_PRESIGN_URL}
                                                    datatype='Message card'
                                                />
                                            )
                                        }}
                                    </Form.List>
                                </Form.Item>
                                <Button type="primary" style={{ marginTop: '0px' }} onClick={() => setShowReschedule(true)}>Reschedule / Cancel</Button>
                                <Modal
                                    title="Puja Reschedule / Cancel"
                                    visible={showReschedule}
                                    onOk={submitForm}
                                    onCancel={() => setShowReschedule(false)}
                                    width="55vw"
                                    style={{ marginTop: '-95px' }}
                                    okText='Update'
                                >
                                    <Form.Item
                                        label="Puja state"
                                        name={['new_puja_state']}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select Puja state',
                                            },
                                        ]}
                                    >
                                        <Select placeholder='Select Puja state'>
                                            <Option value='RESCHEDULED'>RESCHEDULED</Option>
                                            <Option value='CANCELLED'>CANCELLED</Option>
                                        </Select>
                                    </Form.Item>
                                </Modal>
                            </Row>
                        </div>
                        <Button style={{ marginTop: '20px' }} disabled={!isEnabled} onClick={() => setUpdateConfirmDialog(true)}>Update</Button>
                        <Modal
                            title="Puja Reschedule / Cancel"
                            visible={updateConfirmDialog}
                            onOk={submitForm}
                            onCancel={() => setUpdateConfirmDialog(false)}
                            width="35vw"
                            okText='Update'
                        >
                            <Form.Item
                                label="Puja state"
                                name={['new_puja_state']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select Puja state',
                                    },
                                ]}
                            >
                                <Select defaultValue={'BOOKED'} placeholder='Select Puja state'>
                                    <Option value='BOOKED'>BOOKED</Option>
                                    <Option value='SCHEDULED'>SCHEDULED</Option>
                                    <Option value='STARTED'>STARTED</Option>
                                    <Option value='COMPLETED'>COMPLETED</Option>
                                </Select>
                            </Form.Item>
                        </Modal>
                    </Col>
                </Form>
            }
        </Drawer>
    )
}

export default PujaUpdateForm