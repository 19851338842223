import { throwError } from '../../../utils/helper';

type Item = {
  image_attachment_id: string;
  title: string;
  subtitle: string;
  cta_text: string;
};

class Greeting {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */

  header!: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items: Item[] = [];

  getSerializedData = (widgetFormData: Greeting) => {
    let itemModified = widgetFormData?.items?.map((item: Item) => {
      return {
        ...item,
      };
    });
    widgetFormData.items = itemModified;
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: Greeting) => {
    return widgetFormData;
  };

  headerValidator = async (rule: any, value: any) => {
    if (!value) return Promise.resolve();
  };

  imageCodeValidator = async (rule: any, value: any) => {
    if (!value) throwError('Image Attachment required!');
  };
  titleValidator = async (rule: any, value: any) => {
    if (!value) throwError('Title required!');
  };
  subtitleValidator = async (rule: any, value: any) => {
    if (!value) throwError('SubTitle required!');
  };
  ctaTextValidator = async (rule: any, value: any) => {
    if (!value) throwError('CTA Text required!');
  };
}
export default Greeting;
export const GreetingInstance = new Greeting();
