import React from "react"
import { Col, Form, Row, Button, Input } from "antd"
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import TextArea from "antd/lib/input/TextArea"

interface Props {
    fields: any
    add: () => void
    remove: (item: any) => void
}

const Faqs: React.FC<Props> = ({ fields, add, remove }) => {
    return (
        <>
            <Col span={24}>
                {fields.map((field: any, index: number) => {
                    return (
                        <div key={index} style={{
                            border: '1.5px solid #e0dcdc',
                            marginRight: '0px',
                            marginBottom: '10px',
                            width: '41vw',
                            padding: '10px',
                            gap: '5px'
                        }}>
                            <Col
                                style={{
                                    marginLeft: '3px',
                                    display: 'contents'
                                }}>
                                <Row justify="space-between">
                                    <Form.Item
                                        label={`Question ${index + 1}`}
                                        name={[field.name, 'question']}
                                        labelCol={{ span: 28 }}
                                        wrapperCol={{ span: 28 }}
                                    >
                                        <Input style={{width: '230%'}} placeholder="Enter question" />
                                    </Form.Item>
                                    <Button
                                        onClick={() => {
                                            remove(field.name)
                                        }}
                                        type='ghost'
                                        icon={<DeleteOutlined />}
                                        size='middle'
                                        danger
                                    ></Button>
                                </Row>
                                <Form.Item
                                    label={`Answer ${index + 1}`}
                                    name={[field.name, 'answer']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    style={{ marginTop: '10px' }}
                                >
                                    <TextArea placeholder="Enter answer" />
                                </Form.Item>
                            </Col>
                        </div>
                    )
                })}
            </Col>
            <Col span={24} style={{ paddingTop: '10px' }}>
                <Row justify='start'>
                    <Col span={4}>
                        <Button
                            onClick={() => {
                                add()
                            }}
                            type='ghost'
                            icon={<PlusCircleOutlined />}
                            size='middle'
                        >
                            {`Add FAQ`}
                        </Button>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default Faqs