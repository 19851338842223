/* eslint-disable no-constant-condition */
import { Button, Col, Form, Input, InputNumber, Row, Select, Card, Modal, Switch } from 'antd'
import styled from 'styled-components'

import React from 'react'
import { useState } from 'react';

import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'

import Timings from '../Timings';
import Faqs from '../Faqs';
import TempleCommittee from '../TempleCommittee';
import TravelDetails from '../TravelDetails';
import { City } from '@a4b/api/src/modules/Content/Temples/types';
import SocialMedia from '../SocialMedia';
import { CreateStore } from '@a4b/api/src/modules/Monetisation/offerings/types';

const { Option } = Select
const { TextArea } = Input

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 10px;
`
const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
interface TempleCreateOrUpdateProps {
    cdnUrl: string
    preSignUrl: string
    form: any
    submitFormHandler: () => void
    cityList: City[]
    language: string
    handleLanguageChange: (language: string) => void
    StoreList: CreateStore[]
}

interface ModalConfig {
    name: string
    visible: boolean
}

const ManageTempleCreateOrUpdate: React.FC<TempleCreateOrUpdateProps> = ({
    cdnUrl,
    preSignUrl,
    form,
    submitFormHandler,
    cityList,
    language,
    handleLanguageChange,
    StoreList
}) => {

    const [showModal, setShowModal] = useState<ModalConfig[]>([
        { name: 'templeTiming', visible: false },
        { name: 'faq', visible: false },
        { name: 'templecommittee', visible: false },
        { name: 'travelDetails', visible: false },
        { name: 'paymentDetails', visible: false },
        { name: 'socialMedia', visible: false },
    ])

    const handleModalOk = (modalName: string) => {
        setShowModal((prevModals: ModalConfig[]) =>
            prevModals.map((modal) =>
                modal.name === modalName ? { ...modal, visible: false } : modal
            )
        );
    };

    const handleModalCancel = (modalName: string) => {
        setShowModal((prevModals: ModalConfig[]) =>
            prevModals.map((modal: ModalConfig) =>
                modal.name === modalName ? { ...modal, visible: false } : modal
            )
        );
    };

    const openModal = (modalName: string) => {
        setShowModal((prevModals: ModalConfig[]) =>
            prevModals.map((modal: ModalConfig) =>
                modal.name === modalName ? { ...modal, visible: true } : modal
            )
        );
    };

    return (
        <Container
            title='Manage Temples'
            style={{ width: '100%' }}
            extra={
                <Row>
                    <Col>
                        <Select
                            style={{ width: 200 }}
                            placeholder="Select language"
                            defaultValue={language}
                            options={[{
                                label: 'Hindi', value: 'hi'
                            }, {
                                label: 'English', value: 'en'
                            }]}
                            onChange={handleLanguageChange}
                        />
                    </Col>
                </Row>
            }
        >
            <Row>
                <Col span={24}>
                    <Form
                        form={form}
                        layout='vertical'
                        name='create temple'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                    >
                        <Row>
                            <Col span={10}>
                                <Form.Item
                                    label='Reference Name'
                                    name='reference_name'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        { required: true, message: 'Please input Reference name' },
                                    ]}
                                >
                                    <Input placeholder='Enter Reference name' />
                                </Form.Item>
                                <Form.Item
                                    label='Temple title'
                                    name='title'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        { required: true, message: 'Please input title of the temple!' },
                                    ]}
                                >
                                    <Input placeholder='Enter temple title' />
                                </Form.Item>
                                <Form.Item
                                    label='Sub Title'
                                    name='sub_title'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input subtitle of the temple!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter temple subtitle' />
                                </Form.Item>
                                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                    <Form.Item
                                        label='Slug'
                                        name='slug'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        style={{ width: '70%' }}
                                        rules={[
                                            { required: true, message: 'Please input Slug' },
                                        ]}
                                    >
                                        <Input placeholder='Enter Slug' />
                                    </Form.Item>
                                    <Form.Item
                                        label='Priority'
                                        name='priority'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            { required: true, message: 'Please input Priority' },
                                        ]}
                                    >
                                        <InputNumber style={{ width: 110 }} placeholder='Enter priority' />
                                    </Form.Item>
                                </Row>
                                <Row justify='space-between'>
                                    <Form.Item
                                        label='Latitude'
                                        name='latitude'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input latitute',
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: 110 }} placeholder='Enter latitute' />
                                    </Form.Item>
                                    <Form.Item
                                        label='Longitude'
                                        name='longitude'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input longitute',
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: 110 }} placeholder='Enter longitute' />
                                    </Form.Item>
                                    <Form.Item
                                        label='City'
                                        name='city_code'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            { required: true, message: 'Please input short description!' },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: 300 }}
                                            placeholder="Select City"
                                            options={cityList}
                                        />
                                    </Form.Item>
                                </Row>
                                <Form.Item
                                    label='Special note'
                                    name='special_note'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: false
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter special note' />
                                </Form.Item>

                                <Form.Item
                                    label='Offerings'
                                    name='offerings'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        { required: false },
                                    ]}
                                >
                                    <TextArea placeholder='Enter offerings' allowClear />
                                </Form.Item>

                                <Form.Item
                                    label={`Temple Gallery images`}
                                    name='gallery_urls'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    extra='Upload images'
                                    rules={[
                                        {
                                            min: 1,
                                            max: 10,
                                            validator: async (rules: any, values: any) => {
                                                if (values?.length < rules.min) {
                                                    return Promise.reject('Please upload Temple listing Images')
                                                }
                                            },
                                        },
                                    ]}
                                    valuePropName='fileList'
                                >
                                    <S3Upload
                                        cdnUrl={cdnUrl}
                                        preSignUrl={preSignUrl}
                                        min={1}
                                        max={10}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={1}></Col>
                            <Col span={12}>
                                <Form.Item
                                    label='Significance'
                                    name='significance'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        { required: false, message: 'Please input significance!' },
                                    ]}
                                >
                                    <TextArea placeholder='Enter significance' allowClear />
                                </Form.Item>
                                <Form.Item
                                    label='Short Description'
                                    name='short_desc'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        { required: false, message: 'Please input short description!' },
                                    ]}
                                >
                                    <TextArea placeholder='Enter short description' allowClear />
                                </Form.Item>
                                <Form.Item
                                    label='Long Description'
                                    name='long_desc'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        { required: false, message: 'Please input long description!' },
                                    ]}
                                >
                                    <TextArea placeholder='Enter long description' allowClear />
                                </Form.Item>
                                <Form.Item
                                    label='History Description'
                                    name='history_desc'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        { required: false, message: 'Please input history description!' },
                                    ]}
                                >
                                    <TextArea placeholder='Enter history description' allowClear />
                                </Form.Item>
                                <Form.Item
                                    label='Architecture Description'
                                    name='architecture_desc'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        { required: false, message: 'Please input Architecture description!' },
                                    ]}
                                >
                                    <TextArea placeholder='Enter Architecture description' allowClear />
                                </Form.Item>

                                <Row justify='space-between'>
                                    <Row justify='space-between' style={{ width: '45%' }}>
                                        <Form.Item
                                            label='Pooja Enable'
                                            name='is_pooja_enabled'
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Please input Pooja enable',
                                                },
                                            ]}
                                            valuePropName='checked'
                                        >
                                            <Switch />
                                        </Form.Item>
                                        <Form.Item
                                            label='Live Status'
                                            name='is_live_enabled'
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Please input live',
                                                },
                                            ]}
                                            valuePropName='checked'
                                        >
                                            <Switch />
                                        </Form.Item>
                                        <Form.Item
                                            label='Donation'
                                            name='is_donations_enabled'
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Please input Donation',
                                                },
                                            ]}
                                            valuePropName='checked'
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Row>
                                </Row>
                                <Row justify='space-between'>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) =>
                                            (prevValues.is_live_enabled !== currentValues.is_live_enabled)
                                        }
                                    >
                                        {({ getFieldValue }) => (
                                            ((getFieldValue("is_live_enabled") === true) &&
                                                (
                                                    <Form.Item
                                                        label='Live Config URL'
                                                        name='live_redirection_url'
                                                        rules={[
                                                            { required: true, message: 'Please input Redirection URL' },
                                                        ]}
                                                    >
                                                        <Input style={{ width: 350 }} placeholder='Enter Live Redirection URL' />
                                                    </Form.Item>
                                                ))
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) =>
                                            prevValues.is_donations_enabled !== currentValues.is_donations_enabled
                                        }
                                    >
                                        {({ getFieldValue }) => (
                                            (getFieldValue("is_donations_enabled") === true &&
                                                <Form.Item
                                                    label='Donation type'
                                                    name='donation_type'
                                                    rules={[{ required: true, message: 'Select Donation type' }]}
                                                    style={{ width: '40%' }}
                                                >
                                                    <Select
                                                        placeholder='Select Donation type'
                                                    >
                                                        <Option value='STATIC'>Static</Option>
                                                        <Option value='REDIRECTION'>Redirection</Option>
                                                        <Option value='COMING_SOON'>Coming soon</Option>
                                                    </Select>
                                                </Form.Item>))}
                                    </Form.Item>
                                </Row>

                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        (prevValues.donation_type !== currentValues.donation_type || prevValues.is_donations_enabled !== currentValues.is_donations_enabled)
                                    }
                                >
                                    {({ getFieldValue }) => (
                                        ((getFieldValue("is_donations_enabled") === true && getFieldValue("donation_type") === "REDIRECTION") &&
                                            (
                                                <Form.Item
                                                    label='Donation Redirection URL'
                                                    name='donation_redirection_url'
                                                    rules={[
                                                        { required: true, message: 'Please input Redirection URL' },
                                                    ]}
                                                >
                                                    <Input placeholder='Enter Redirection URL' />
                                                </Form.Item>
                                            )) ||
                                        ((getFieldValue("is_donations_enabled") === true && getFieldValue("donation_type")) === "STATIC" &&
                                            (
                                                <Form.Item
                                                    label='Payment details'
                                                    name={'payment_details'}
                                                    rules={[
                                                        { required: true, message: 'Please input payment details' },
                                                    ]}
                                                >
                                                    <Button
                                                        style={{ width: 150, background: '#1890ff', color: 'white' }}
                                                        onClick={() => { openModal('paymentDetails') }}
                                                    >
                                                        Payment details
                                                    </Button>
                                                    <Modal
                                                        title="Payment details"
                                                        visible={showModal?.[4]?.visible}
                                                        onOk={() => { handleModalOk('paymentDetails') }}
                                                        onCancel={() => handleModalCancel('paymentDetails')}
                                                        width="45vw"
                                                        style={{ marginTop: '-60px' }}
                                                    >
                                                        <Form.Item
                                                            label='Receiver Name'
                                                            name={['payment_details', 'receiver_name']}
                                                            labelCol={{ span: 24 }}
                                                            wrapperCol={{ span: 24 }}
                                                            rules={[
                                                                { required: true, message: 'Please input Receiver name' },
                                                            ]}
                                                        >
                                                            <Input placeholder='Enter Receiver name' />
                                                        </Form.Item>
                                                        <Row style={{ width: '100%' }} justify='space-between'>
                                                            <Form.Item
                                                                label='UPI ID'
                                                                name={['payment_details', 'upi']}
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                                rules={[
                                                                    { required: true, message: 'Please input UPI ID' },
                                                                ]}
                                                            >
                                                                <Input style={{ width: '140%' }} placeholder='Enter UPI ID' />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label='QR Code Image'
                                                                name={['payment_details', 'qrcode_img']}
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                                valuePropName='fileList'
                                                                style={{ width: '30%' }}
                                                                rules={[
                                                                    {
                                                                        min: 0,
                                                                        max: 1,
                                                                        validator: async (
                                                                            rules: any,
                                                                            values: any,
                                                                        ) => {
                                                                            if (values?.length < rules.min) {
                                                                                return Promise.reject(
                                                                                    'Upload QR Code Image',
                                                                                )
                                                                            }
                                                                        },
                                                                    },
                                                                ]}
                                                            >
                                                                <S3Upload
                                                                    cdnUrl={cdnUrl}
                                                                    preSignUrl={preSignUrl}
                                                                    min={1}
                                                                    max={1}
                                                                />
                                                            </Form.Item>
                                                        </Row>
                                                        <h3>Bank details</h3>
                                                        <Row style={{ background: '#f5f5f5', paddingLeft: '12px', paddingRight: '12px' }} justify='space-between'>
                                                            <Form.Item
                                                                label='Bank type'
                                                                name={['payment_details', 'bank_details', 'account_type']}
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                                rules={[{ required: true, message: 'Select Bank type' }]}
                                                            >
                                                                <Select style={{ width: '100%' }} placeholder='Select Bank type'>
                                                                    <Option value='SAVINGS'>Savings</Option>
                                                                    <Option value='CURRENT'>Current</Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item
                                                                label='Bank name'
                                                                name={['payment_details', 'bank_details', 'bank_name']}
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                                rules={[
                                                                    { required: true, message: 'Please input Bank name' },
                                                                ]}
                                                            >
                                                                <Input placeholder='Enter Bank name' />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label='Account number'
                                                                name={['payment_details', 'bank_details', 'account_number']}
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                                rules={[
                                                                    { required: true, message: 'Please input Account number ' },
                                                                ]}
                                                            >
                                                                <Input style={{ width: '100%' }} placeholder='Enter Account number ' maxLength={16} minLength={16} />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label='IFSC code'
                                                                name={['payment_details', 'bank_details', 'ifsc_code']}
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                                rules={[
                                                                    { required: true, message: 'Please input IFSC code' },
                                                                ]}
                                                            >
                                                                <Input placeholder='Enter IFSC code' />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label='Beneficiary name'
                                                                name={['payment_details', 'bank_details', 'beneficiary_name']}
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                                rules={[
                                                                    { required: true, message: 'Please input Beneficiary name' },
                                                                ]}
                                                            >
                                                                <Input placeholder='Enter Beneficiary name' />
                                                            </Form.Item>
                                                        </Row>
                                                    </Modal>
                                                </Form.Item>
                                            ))
                                    )}
                                </Form.Item>

                                <Row justify='space-between'>
                                    <Form.Item
                                        label='Temple timings'
                                        name='timings'
                                        rules={[
                                            { required: false },
                                        ]}
                                    >
                                        <Button
                                            style={{ width: 150, background: '#1890ff', color: 'white' }}
                                            onClick={() => openModal('templeTiming')}
                                        >
                                            Temple timings
                                        </Button>
                                        <Modal
                                            title="Temple timings Information"
                                            visible={showModal?.[0]?.visible}
                                            onOk={() => { handleModalOk('templeTiming') }}
                                            onCancel={() => handleModalCancel('templeTiming')}
                                            width="60vw"
                                            style={{ marginTop: '-60px' }}
                                        >
                                            <Form.List name='timings'>
                                                {(fields, { add, remove }) => {
                                                    return (
                                                        <Timings fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} />
                                                    )
                                                }}
                                            </Form.List>
                                        </Modal>
                                    </Form.Item>
                                    <Form.Item
                                        label={`FAQ`}
                                        name='faq'
                                        rules={[
                                            { required: false },
                                        ]}
                                    >
                                        <Button
                                            style={{ width: 150, background: '#1890ff', color: 'white' }}
                                            onClick={() => openModal('faq')}
                                        >
                                            FAQ
                                        </Button>
                                        <Modal
                                            title="FAQ"
                                            visible={showModal?.[1]?.visible}
                                            onOk={() => { handleModalOk('faq') }}
                                            onCancel={() => handleModalCancel('faq')}
                                            width="45vw"
                                        >
                                            <Form.List name='faq'>
                                                {(fields, { add, remove }) => {
                                                    return (
                                                        <Faqs fields={fields} add={add} remove={remove} />
                                                    )
                                                }}
                                            </Form.List>
                                        </Modal>
                                    </Form.Item>
                                    <Form.Item
                                        label={`Temple committee`}
                                        name='temple_committee'
                                        rules={[
                                            { required: false },
                                        ]}
                                    >
                                        <Button
                                            style={{ width: 150, background: '#1890ff', color: 'white' }}
                                            onClick={() => openModal('templecommittee')}
                                        >
                                            Temple committee
                                        </Button>
                                        <Modal
                                            title="Temple committee"
                                            visible={showModal?.[2]?.visible}
                                            onOk={() => { handleModalOk('templecommittee') }}
                                            onCancel={() => handleModalCancel('templecommittee')}
                                            width="45vw"
                                        >
                                            <Form.List name={['temple_committee', 'temple_committee_members']}>
                                                {(fields, { add, remove }) => {
                                                    return (
                                                        <TempleCommittee fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} />
                                                    )
                                                }}
                                            </Form.List>
                                        </Modal>
                                    </Form.Item>
                                    <Form.Item
                                        label={`Travel details`}
                                        name='travel_details'
                                        rules={[
                                            { required: false },
                                        ]}
                                    >
                                        <Button
                                            style={{ width: 150, background: '#1890ff', color: 'white' }}
                                            onClick={() => openModal('travelDetails')}
                                        >
                                            Travel details
                                        </Button>
                                        <Modal
                                            title="Travel details"
                                            visible={showModal?.[3]?.visible}
                                            onOk={() => { handleModalOk('travelDetails') }}
                                            onCancel={() => handleModalCancel('travelDetails')}
                                            width="45vw"
                                        >
                                            <TravelDetails />
                                        </Modal>
                                    </Form.Item>
                                    <Form.Item
                                        label={`Social media`}
                                        name='social_media'
                                        rules={[
                                            { required: false },
                                        ]}
                                    >
                                        <Button
                                            style={{ width: 150, background: '#1890ff', color: 'white' }}
                                            onClick={() => openModal('socialMedia')}
                                        >
                                            Social Media
                                        </Button>
                                        <Modal
                                            title="Social Media"
                                            visible={showModal?.[5]?.visible}
                                            onOk={() => { handleModalOk('socialMedia') }}
                                            onCancel={() => handleModalCancel('socialMedia')}
                                            width="45vw"
                                        >
                                            <SocialMedia />
                                        </Modal>
                                    </Form.Item>
                                </Row>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        true
                                    }
                                >
                                    {({ getFieldValue }) => (
                                        getFieldValue("is_avatar_enabled") === true &&

                                        <Form.Item
                                            label='Chadava store list'
                                            name={['chadava', 'store_id']}
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                { required: false, message: 'Please input short description!' },
                                            ]}
                                        >
                                            <Select
                                                style={{ width: 300 }}
                                                placeholder="Select store"
                                                options={StoreList}
                                            />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form >
                </Col >
                <Col span={24}>
                    <LabelRightAlign>
                        <Button style={{ width: '20%' }} type='primary' onClick={submitFormHandler}>
                            Submit
                        </Button>
                    </LabelRightAlign>
                </Col>
            </Row >
        </Container>

    )
}
export default ManageTempleCreateOrUpdate
