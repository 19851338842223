import "./card.css";

export default function Card(props: {
  onClick: () => void;
  data: string;
  hash: string;
}) {
  return (
    <div className="box" onClick={props.onClick}>
      <p>{props.data.toUpperCase()}</p>
    </div>
  );
}
