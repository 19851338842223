import { Shrine } from '@/../../../packages/a4b-api/src/modules/Temple/types';
import { REQUIRED_RULE, SUPPORTED_IMAGE_ACCEPT_TYPE } from '@/utils/form.validation';
import FileUpload from '@a4b/ui/src/modules/admin/components/FileUpload';
import { DatePicker, Form, Input, InputNumber, message, Select } from "antd"
import { RcFile } from 'antd/lib/upload';


const imageFileValidations = (file: RcFile) => {
  let isFailed = false;
  const isLt2M = file.size <= 1024 * 1024;
  if (!isLt2M) {
    isFailed = true;
    message.error('Mp3 file smaller than 1MB');
  }
  return !isFailed;
};



export const FlowerPropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>
    <Form.Item
      label="Density"
      name="density"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Dimension"
      name={'dimension'}
      rules={[{ required: true }]}
      style={{ width: '30%' }}
    >
      <Select>
        <Select.Option key='30x30' value='30x30'> 30 X 30 </Select.Option>,
        <Select.Option key='40x40' value='40x40'> 40 X 40 </Select.Option>,
        <Select.Option key='50x50' value='50x50'> 50 X 50 </Select.Option>,
        <Select.Option key='60x60' value='60x60'> 60 X 60 </Select.Option>,
        <Select.Option key='50x100' value='50x100'> 50 X 100 </Select.Option>,
      </Select>
    </Form.Item>
    <FileUpload rules={[{ required: true }]} name={'base_attachment'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
    <FileUpload rules={[{ required: true }]} name={'animated_attachment'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
  </>
}

export const BasePropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>
    <FileUpload rules={[{ required: true }]} name={'animated_attachment'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
  </>
}

export const ThaliPropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>
    <FileUpload rules={[{ required: true }]} name={'animated_attachment'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
  </>
}


export const InstrumentPropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>
    <Form.Item
      label="Total duration (In seconds)"
      name="total_duration"
      rules={[{ required: true }]}
    >
      <InputNumber />
    </Form.Item>
    <FileUpload rules={[{ required: true }]} name={'audio_attachment'} accept='.mp3,audio/*' fileValidation={imageFileValidations} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
  </>
}

export const CarpetPropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>
    <Form.Item
      label="Message code"
      name="message_code"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
  </>
}



export const AartiPropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>
    <FileUpload rules={[{ required: true }]} name={'base_attachment'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
    <Form.Item
      label="Dimension"
      name={'dimension'}
      rules={[{ required: true }]}
      style={{ width: '30%' }}
    >
      <Select>
        <Select.Option key='30x30' value='30x30'> 30 X 30 </Select.Option>,
        <Select.Option key='40x40' value='40x40'> 40 X 40 </Select.Option>,
        <Select.Option key='50x50' value='50x50'> 50 X 50 </Select.Option>,
        <Select.Option key='60x60' value='60x60'> 60 X 60 </Select.Option>,
        <Select.Option key='50x100' value='50x100'> 50 X 100 </Select.Option>,
      </Select>
    </Form.Item>
    <FileUpload rules={[{ required: true }]} name={'audio_attachment'} accept='.mp3,audio/*' fileValidation={imageFileValidations} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
    {/* <Form.Item
      label="Total duration (In seconds)"
      name="total_duration"
      rules={[{ required: true }]}
    >
      <InputNumber />
    </Form.Item> */}
    <FileUpload rules={[{ required: true }]} name={'animated_attachment'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
  </>
}

export const CtaPropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>
    <Form.Item
      label="Cta text code"
      name="cta_text_code"
      rules={[{ required: false }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Deeplink"
      name="deeplink"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
  </>
}


export const OfferingPropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>

    {/* <Form.Item
      label="Total duration (In seconds)"
      name="total_duration"
      rules={[{ required: true }]}
    >
      <InputNumber />
    </Form.Item> */}
    <Form.Item
      label="Dimension"
      name={'dimension'}
      rules={[{ required: true }]}
      style={{ width: '30%' }}
    >
      <Select>
        <Select.Option key='30x30' value='30x30'> 30 X 30 </Select.Option>,
        <Select.Option key='40x40' value='40x40'> 40 X 40 </Select.Option>,
        <Select.Option key='50x50' value='50x50'> 50 X 50 </Select.Option>,
        <Select.Option key='60x60' value='60x60'> 60 X 60 </Select.Option>,
        <Select.Option key='50x100' value='50x100'> 50 X 100 </Select.Option>,
      </Select>
    </Form.Item>
    <FileUpload rules={[{ required: true }]} name={'base_attachment'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
    <FileUpload rules={[{ required: true }]} name={'animated_attachment'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
  </>
}

export const VisheshPropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>
    <Form.Item
      label="Dimension"
      name={'dimension'}
      rules={[{ required: true }]}
      style={{ width: '30%' }}
    >
      <Select>
        <Select.Option key='30x30' value='30x30'> 30 X 30 </Select.Option>,
        <Select.Option key='40x40' value='40x40'> 40 X 40 </Select.Option>,
        <Select.Option key='50x50' value='50x50'> 50 X 50 </Select.Option>,
        <Select.Option key='60x60' value='60x60'> 60 X 60 </Select.Option>,
        <Select.Option key='50x100' value='50x100'> 50 X 100 </Select.Option>,
      </Select>
    </Form.Item>
    <FileUpload rules={[{ required: true }]} name={'base_attachment'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
    <FileUpload rules={[{ required: true }]} name={'animated_attachment'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />

    <Form.Item name="date_range" label="Date" rules={REQUIRED_RULE}>
      <DatePicker.RangePicker
        onChange={(val) => {
          console.log({ val });
        }}
      />
    </Form.Item>
  </>
}

export const IgnitablePropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>
    {/* <Form.Item
      label="Total duration (In seconds)"
      name="total_duration"
      rules={[{ required: true }]}
    >
      <InputNumber />
    </Form.Item> */}
    <Form.Item
      label="Classification"
      name={'classification'}
      rules={[{ required: true }]}
      style={{ width: '30%' }}
    >
      <Select>
        <Select.Option key='diya' value='diya'> diya </Select.Option>,
        <Select.Option key='others' value='others'> others </Select.Option>,
      </Select>
    </Form.Item>
    <Form.Item
      label="Dimension"
      name={'dimension'}
      rules={[{ required: true }]}
      style={{ width: '30%' }}
    >
      <Select>
        <Select.Option key='30x30' value='30x30'> 30 X 30 </Select.Option>,
        <Select.Option key='40x40' value='40x40'> 40 X 40 </Select.Option>,
        <Select.Option key='50x50' value='50x50'> 50 X 50 </Select.Option>,
        <Select.Option key='60x60' value='60x60'> 60 X 60 </Select.Option>,
        <Select.Option key='50x100' value='50x100'> 50 X 100 </Select.Option>,
      </Select>
    </Form.Item>
    <FileUpload rules={[{ required: true }]} name={['0']} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
    <FileUpload rules={[{ required: true }]} name={['100']} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={props.getFileUploadUrl} title={'upload image'} />
  </>
}


export const ChadavaPropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>
    <Form.Item
      label="Cta text code"
      name="cta_text_code"
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Deeplink"
      name="deeplink"
      rules={[{
        required: true,
      },
      {
        type: "url",
        message: "This field must be a valid url."
      }]}
    >
      <Input />
    </Form.Item>
  </>
}

export const PrayerPropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>
    <Form.Item
      label="type"
      name={['audio_attachment', 'type']}
      hidden
      initialValue={'M3U8'}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Audio Attachment Path"
      name={['audio_attachment', 'path']}
      rules={[{
        validator(rule, value: string | undefined, callback) {
          if (value && value.endsWith('.m3u8')) {
            callback(undefined);
          } else {
            callback("Audio Attachment Path should ends with .m3u8");
          }
        },
      }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Audio Duration (In seconds)"
      name="audio_duration"
      required
    >
      <Input />
    </Form.Item>
  </>
}

export const PrayerCompletionPropertyComponent = (props: { getFileUploadUrl: (file: any) => Promise<Shrine.S3Info> }) => {
  return <>
    <FileUpload rules={[{ required: true }]} name={'media_attachment'} accept={'video/mp4'} getS3PathApi={props.getFileUploadUrl} title={'upload mp4'} />
  </>
}
