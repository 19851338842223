import { Table } from 'antd';

import { ATTACHMENT } from '@/utils/enum';
import LazyImageRender from '../LazyImageRender';

interface Props {
  record: any;
}
const ContentCollectionDataTable = ({ record }: Props) => {
  const dataColumn = [
    {
      title: 'Image Attachment',
      dataIndex: 'image_attachment_id',
      key: 'image_attachment_id',
      ellipsis: true,
      width: '10%',
      render: (text: any, record: any, index: any) => {
        return <LazyImageRender id={text} attachmentType={ATTACHMENT.IMAGE} />;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      width: '10%',
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'CTA Action',
      dataIndex: 'cta_action_type',
      key: 'cta_action_type',
      ellipsis: true,

      width: '10%',
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'CTA Redirection',
      dataIndex: 'cta_redirection_type',
      key: 'cta_redirection_type',
      ellipsis: true,
      width: '10%',
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Song Id',
      dataIndex: 'r_song_id',
      key: 'r_song_id',
      ellipsis: true,
      width: '10%',
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Song of the day',
      dataIndex: 'song_of_the_day',
      key: 'song_of_the_day',
      ellipsis: true,
      width: '10%',
      render: (text: any, record: any, index: any) => {
        return <div>{text ? 'Yes' : 'No'}</div>;
      },
    },
    {
      title: 'Article Id',
      dataIndex: 'r_article_id',
      key: 'r_article_id',
      ellipsis: true,
      width: '10%',
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Video Attachment',
      dataIndex: 'video_attachment_id',
      key: 'video_attachment_id',
      ellipsis: true,
      width: '10%',
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
  ];
  return (
    <div className="padding-left">
      <Table
        columns={dataColumn}
        dataSource={record?.content?.items || []}
        pagination={false}
        scroll={{ y: 240 }}
      ></Table>
    </div>
  );
};
export default ContentCollectionDataTable;
