import { Button, Form, Input, Select } from "antd";
import { useEffect } from "react";

interface FiltersProps {
  functionality: string;
  subFunctionality: string;
  setFunctionality: (val: string) => void
  setSubFunctionality: (val: string) => void
  reloadShareFunctionality: () => void
}
export default function Filters(props: FiltersProps) {
  const form = Form.useForm()[0]
  const { functionality, subFunctionality, setFunctionality, setSubFunctionality, reloadShareFunctionality } = props
  useEffect(() => {
    form.setFieldsValue({ functionality, subFunctionality });
  }, [form, functionality, subFunctionality])
  return <div style={{ display: 'flex', gap: '20px' }}>
    <Form
      form={form}
      onFinish={reloadShareFunctionality}
      layout="inline"
    >
      <Form.Item
        name={"functionality"}
      >
        <Input value={functionality} style={{ width: '300px' }} placeholder="Filter by functionality" onChange={(e) => { setFunctionality(e.target.value) }}></Input>
      </Form.Item>
      <Form.Item
        name={"subFunctionality"}
      >
        <Input value={subFunctionality} style={{ width: '300px' }} onChange={(e) => { setSubFunctionality(e.target.value) }} placeholder="Filter by sub functionality"></Input>
      </Form.Item>
      <Form.Item >
        <Button type="primary" htmlType="submit">Apply</Button>
      </Form.Item>
    </Form>
  </div>
} 