import { CreateShareFunctionalityPayload, Functionality } from "@a4b/api/src/modules/ShareText/types"
import { Button, Checkbox, Form, Input, InputNumber, Select, Space, UploadFile } from "antd"
import { useCallback, useEffect } from "react";
import { useAppContext } from "../../../../../components/AppContext";
import { AntdFileUploadV2 } from "../../../../../components/Forms/AntdFileUpload";
import { openNotification } from "../../../../../utils";
import { SHARE_TEXT_CONFIG } from "../../helpers/config";
import { SUPPORTED_LANGUAGES_LIST } from "../../../../../utils/constants";
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";

interface CreateUpdateFunctionalityProps {
  editFunctionality?: Functionality | null
  reloadShareFunctionality: () => void
}
const CreateUpdateFunctionality = (props: CreateUpdateFunctionalityProps) => {
  const form = Form.useForm<CreateShareFunctionalityPayload>()[0];
  const { editFunctionality, reloadShareFunctionality } = props;
  const { networkInstance } = useAppContext();

  const createUpdateFunctionality = useCallback(async (values) => {
    const display_name_language_json: { [key: string]: string } = {}
    const display_name_language_default_json: { [key: string]: string } = {}
    values.display_name_language_json.forEach((data: { language: string, value: string }) => {
      display_name_language_json[data.language] = data.value;
    })
    values.display_name_language_json = display_name_language_json;
    values.display_name_language_default_json = display_name_language_default_json;
    values.share_apps_order = null;
    if (editFunctionality) {
      try {
        const res = await networkInstance.clientWithHeaders.shareText.updateShareFunctionality(editFunctionality.id, values);
        reloadShareFunctionality();
        openNotification('success', { message: "Share text app updated sucessfully" })
      } catch (error) {
        openNotification('error', { message: "Failed to update Share text app", description: (error as string).toString() })
      }
    } else {
      try {
        const res = await networkInstance.clientWithHeaders.shareText.createShareFunctionality(values);
        reloadShareFunctionality();
        openNotification('success', { message: "Share text functionality created sucessfully" })
      } catch (error) {
        openNotification('error', { message: "Failed to create Share text functionality", description: (error as string).toString() })
      }
    }
  }, [editFunctionality, networkInstance.clientWithHeaders.shareText, reloadShareFunctionality])

  useEffect(() => {
    if (editFunctionality) {
      const copyEditFunctionality = editFunctionality;
      //@ts-ignore
      copyEditFunctionality['display_name_language_json'] = Object.keys(copyEditFunctionality['display_name_language_json']).map((languageKey: string) => {
        console.log({ key: languageKey })
        return {
          language: languageKey,
          //@ts-ignore
          value: copyEditFunctionality['display_name_language_json'][languageKey]
        }
      })
      form.setFieldsValue({ ...copyEditFunctionality })
    }
  }, [editFunctionality, form])


  return <div>
    <Form
      form={form}
      onFinish={createUpdateFunctionality}
      layout="vertical"
    >
      <Form.Item
        name={'functionality'}
        label={'Functionality'}
        required
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        name={"sub_functionality"}
        label={"Sub functionality "}
        required
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        name={"version"}
        label={"Version"}
        required
      >
        <Input />
      </Form.Item>

      {/* <Form.Item
        name={"share_apps_order"}
        label={"Share app order"}
        required
      >
        <Select></Select>
      </Form.Item> */}
      <Form.Item
        name={"app_deeplink"}
        label={"App deeplink"}
        initialValue={'/download_app'}
        required
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"feature_deeplink"}
        label={"Feature deeplink"}
        initialValue={"/"}
        required
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"deeplink_provider"}
        label={"Deeplink provider"}
        initialValue={'BRANCH_IO'}
        required
      >
        <Select
        >
          {
            SHARE_TEXT_CONFIG.data.deeplink_provider.map((deeplinkProvider) => <Select.Option value={deeplinkProvider}>{deeplinkProvider}</Select.Option>)
          }
        </Select>
      </Form.Item>
      <Form.Item
        name="dynamic_share_keys"
        label="Dynamic share keys"
        rules={[{ max: 50, type: 'array' }]}
      >
        <Select
          showSearch
          mode='tags'
          filterOption={false}
          style={{ minWidth: '400px', width: '100%' }}
        >
          {[].map((dynamicShareKey, index) => (
            <Select.Option key={index} value={dynamicShareKey}>
              {dynamicShareKey}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={"active"}
        label={"Active"}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        name={"freezed"}
        label={"Freezed"}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        name={"support_multiple_identification"}
        label={"support Multiple Identification"}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <label > Display name language json</label>
      <Form.List name="display_name_language_json" initialValue={[{ language: 'hi' }, { language: 'en' }]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginTop: '20px', alignItems: 'flex-start', minHeight: '70px', padding: '15px', background: name % 2 === 0 ? 'rgb(226 231 236)' : 'rgb(217 219 221)' }}>
                <Form.Item
                  label={'Select Language'}
                  {...restField}
                  name={[name, 'language']}
                  rules={[{
                    required: true, validator(rule, value, callback) {
                      if (form.getFieldValue('display_name_language_json').filter((singer: any) => singer.language === value).length > 1) {
                        callback("Language is already added!")
                      } else {
                        callback(undefined)
                      }
                    },
                  }]}
                >
                  <Select
                    style={{ width: '180px' }}
                    placeholder={'Select language'}
                  >
                    {SUPPORTED_LANGUAGES_LIST.map(language =>
                      <Select.Option key={language.value} value={language.value} >
                        {language.alt_name} ({language.name})
                      </Select.Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={[name, 'value']}
                  label="Value"
                  required
                >
                  <Input></Input>
                </Form.Item>
                {name >= 2 && <Button style={{ border: '1px solid light' }} onClick={() => remove(name)}>
                  <MinusCircleOutlined /> remove
                </Button>}
              </Space>
            ))}
            <Form.Item>
              <Button style={{ width: '200px' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit" >Submit</Button>
      </Form.Item>
    </Form>
  </div>
}


export default CreateUpdateFunctionality;