//context
import { useAppContext } from '@/components/AppContext'
import { Form, message } from 'antd'

//hoc
//organisms
//hooks
//types
import React, { useEffect } from 'react'

import HubCreation from '@a4b/ui/src/modules/monetisation/puja/molecules/HubCreation'

import { HubManager } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { A4B_APPS } from '@/utils/constants'

interface Props {
  showCreateHub: boolean
  journetType: 'create' | 'edit' | 'view'
  hubId?: string
  createHubCallback?: (status: boolean) => void
  UpdateHubCallback?: (status: boolean) => void
  closeCreateHub: () => void
}

const CreateHub: React.FC<Props> = ({
  showCreateHub,
  closeCreateHub,
  createHubCallback,
  UpdateHubCallback,
}) => {
  const { networkInstance, app } = useAppContext()
  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi

  const [hubManagerList, setHubManagerList] = React.useState<HubManager[]>([])
  const [initialValues, setInitialValues] = React.useState<any>()
  const [hubCreateForm] = Form.useForm()
  const getHubManagerList = async () => {
    const hubManagerList = await monetisationPujaApi.hub.getHubManagerList({
      limit: 100,
      offset: 0,
    })
    setHubManagerList(hubManagerList?.data?.data)
  }
  useEffect(() => {
    getHubManagerList()
  }, [])

  const onFinish = async (values: any) => {
    const messageKey = 'create-hub-message'
    try {
      message.loading({ content: 'Creating Hub...', key: messageKey })
      await monetisationPujaApi.hub.createhub(values)
      message.success({ content: 'Hub Created Successfully', key: messageKey })
      hubCreateForm.resetFields()
      createHubCallback?.(true)
    } catch (error) {
      message.error({
        content: `Hub Creation Failed : ${error}`,
        key: messageKey,
      })
      console.log('Hub Creation Form Error==>', error)
    }
  }
  const onFinishFailed = (error: any) => { }
  return (
    <HubCreation
      visible={showCreateHub}
      onClose={closeCreateHub}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      hubManagersList={hubManagerList}
      initialValues={initialValues}
      form={hubCreateForm}
    />
  )
}

export default CreateHub
