import { LibraryMediaType, SupportedContentFormats } from '@a4b/api'
import { RcFile } from 'antd/lib/upload'
import type { Editor, Position } from 'codemirror'
//type
import SimpleMDE from 'easymde'
import 'easymde/dist/easymde.min.css'
import * as Marked from 'marked'

import React, { useCallback, useMemo, useState } from 'react'
import SimpleMdeReact from 'react-simplemde-editor'

export interface Props {
  value?: string
  onChange?: (value: string) => void
}

const toolbarOptions = [
  'bold',
  'italic'
]

export const LyricsEditor: React.FC<Props> = ({
  value,
  onChange,
}) => {
  const [simpleMdeInstance, setMdeInstance] = useState<SimpleMDE | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [codemirrorInstance, setCodemirrorInstance] = useState<Editor | null>(
    null,
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lineAndCursor, setLineAndCursor] = useState<Position | null>(null)


  const getMdeInstanceCallback = useCallback(
    (simpleMde: SimpleMDE) => {
      setMdeInstance(simpleMde)
    },
    [],
  )

  const getCmInstanceCallback = useCallback((editor: Editor) => {
    setCodemirrorInstance(editor)
  }, [])

  const getLineAndCursorCallback = useCallback((position: Position) => {
    setLineAndCursor(position)
  }, [])

  const getOptions = useMemo(() => {
    return {
      autofocus: true,
      spellChecker: false,
      previewRender: function (text) {
        const renderer = new Marked.Renderer()
        return Marked.parse(text, { renderer })
      },
      toolbar: toolbarOptions,
    } as SimpleMDE.Options
  }, [])

  return (
    <SimpleMdeReact
      value={value}
      onChange={onChange}
      options={getOptions}
      getMdeInstance={getMdeInstanceCallback}
      getCodemirrorInstance={getCmInstanceCallback}
      getLineAndCursor={getLineAndCursorCallback}
    />
  )
}
