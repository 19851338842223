
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination from "@/hooks/useAntdPagination";
import { AudioThumbnail } from "@a4b/api";
import { useSearchParams } from "react-router-dom";

const Thumbnails = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const drawerConfig = useDrawerConfig(activeDrawer);
  const { drawerProps, drawerContent } = drawerConfig;
  const { networkInstance } = useAppContext();
  const { pagination, setPagination } = useAntdPagination();
  const [thumbnails, setThumbnails] = useState<AudioThumbnail[]>([]);
  const [searchParams] = useSearchParams();

  const code = useMemo(() => searchParams.get('code'), [searchParams]);

  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }

  const getThumbnails = useCallback(async () => {
    const limit = pagination.pageSize || 20;
    const res = await networkInstance.clientWithHeaders.contentApi.audio.getThumbnailAudio({ limit: limit, offset: ((pagination.current || 1) - 1) * limit, code: code || '' });
    setThumbnails(res.data.data.thumbnails);
    if (pagination.total !== res.data.data.count) {
      setPagination({ ...pagination, total: res.data.data.count, current: 1 })
    }
  }, [code, networkInstance.clientWithHeaders.contentApi.audio, pagination, setPagination])

  useEffect(() => {
    getThumbnails();
  }, [getThumbnails])




  const tableConfig = useTableConfig(thumbnails, pagination, setPagination, setDrawer);


  const onCreateRashipal = () => {
    setActiveDrawer(DRAWER_TYPE.UPLOAD_THUMBNAIL);
  }

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  }

  return <SocialScreenContainer
    title={"Thumbnails"}
    filtersUI={<Filters code={code} />}
    extra={<Button onClick={onCreateRashipal}> Create thumbnail </Button>}
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />

};

export default withDefaultLayout(Thumbnails)