import { useAppContext } from '@/components/AppContext'
import DragAndDropModal from '@/hoc/DragAndDropModal'
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import { Col, Row, Spin, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import _, { filter } from 'lodash'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import OfferingFeedPage from '@a4b/ui/src/modules/monetisation/molecules/OfferingFeedPage'
import PageInfoForm from '@a4b/ui/src/modules/monetisation/molecules/PageInfoForm'
import ManagePagesPage from '@a4b/ui/src/modules/monetisation/organisms/ManagePages'
import {
  ManageSectionItem,
  PageListItem,
} from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { formatPageinfoByPageType } from './helper'
import { INITIAL_STATE, managePageReducer } from './managePageReducer'

interface Props { }

interface ShowSectionInfoProps {
  data?: Partial<ManageSectionItem>
}
const ShowSectionInfo: React.FC<ShowSectionInfoProps> = ({ data }) => {
  return (
    <Row style={{ border: '1px solid grey', width: '100%' }}>
      <Col span={12}>Title:{data?.title}</Col>
      <Col span={12}>Desc:{data?.description}</Col>
      <Col span={12}>Section Type:{data?.section_type}</Col>
      <Col span={12}>Item Type:{data?.item_type}</Col>
    </Row>
  )
}
const ManagePagesScreen: React.FC<Props> = () => {
  const { networkInstance, languageState } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders
  const [pageForm] = useForm()
  const [feedsForm] = useForm()
  const [reducerState, managePageDispatch] = useReducer(
    managePageReducer,
    INITIAL_STATE,
  )
  const [showPageForm, setShowPageForm] = React.useState(false)
  const [showPageFeed, setShowPageFeed] = React.useState(false)
  const [showUpdateButton, setShowUpdateButton] = React.useState(false)
  const [currentPageTypeData, setCurrentPageTypeData] = React.useState<
    PageListItem | undefined
  >()
  const [pageInfoList, setPageInfoList] = React.useState<PageListItem[]>([])
  const [showDandDModal, setDandDModalVisibility] = useState(false)

  const [sortedSectionList, setSortedSectionList] = useState<
    ManageSectionItem[]
  >([])

  const [sectionList, setSectionList] = useState<ManageSectionItem[]>([])
  const [showItemCreation, setShowItemCreation] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const getSections = useCallback(
    async (pageList: any, currentPageData: PageListItem | undefined) => {
      setShowUpdateButton(false)
      try {
        const sections = await monetisationOfferingsApi.storesApi.getSections({
          limit: 1000,
          offset: 0,
        })
        setSectionList(sections?.data?.data?.sections)
        if (pageList.length) {
          const feedPage = pageList?.find(
            (item: PageListItem) =>
              (item.key === currentPageData?.key) && (item.language_code === currentPageData?.language_code),
          )
          const filteredSection = sections?.data?.data?.sections?.filter(
            (section: ManageSectionItem) =>
              feedPage?.sections?.includes(section.id),
          )
          const sortedItems = _.sortBy(filteredSection, (item: ManageSectionItem) => feedPage?.sections?.indexOf(item?.id))
          if (sortedItems?.length) {
            setSortedSectionList(sortedItems)
          } else {
            setSortedSectionList([])
          }
        }
      } catch (error) {
        message.error({
          content: 'Error while fetching sections',
          duration: 3,
        })
        console.log(error)
      }
    },
    [monetisationOfferingsApi.storesApi],
  )

  const getFaqsForPage = useCallback(
    async (currentPageType: string) => {
      try {
        const faqList = await monetisationOfferingsApi.faqsApi?.getFaqs({
          faq_type: currentPageType,
        })

        managePageDispatch({ type: 'SET_FAQS', payload: faqList?.data?.data })
        pageForm.setFieldsValue({ faqs: faqList?.data?.data })
        setShowPageForm(true)
      } catch (error) {
        message.error({
          content: `Error while fetching Faq's for page ${currentPageType}`,
          duration: 3,
        })
        console.log(error)
      }
    },
    [monetisationOfferingsApi.faqsApi],
  )
  const getReviewsForPage = useCallback(
    async (currentPageTypeData: any) => {
      if (
        currentPageTypeData?.key !== 'offerings_feed_page' &&
        currentPageTypeData?.key !== 'offerings_feed_page_with_pooja' &&
        currentPageTypeData?.key !== 'store_list_page_pooja'
      ) {
        return
      }
      try {
        const reviews = await monetisationOfferingsApi.reviewApi?.getReviews(
          currentPageTypeData?.id || '',
        )
        feedsForm.setFieldsValue({
          reviews: reviews?.data?.data?.length
            ? reviews?.data?.data?.map((item: any) => ({
              ...item,
              user_image_url: [item.user_image_url],
            }))
            : [{}],
        })
        pageForm.setFieldsValue({
          reviews: reviews?.data?.data?.length
            ? reviews?.data?.data?.map((item: any) => ({
              ...item,
              user_image_url: [item.user_image_url],
            }))
            : [{}],
        })
      } catch (error) {
        message.error({
          content: `Error while fetching review for page ${currentPageTypeData?.key}`,
          duration: 3,
        })
        console.log(error)
      }
    },
    [monetisationOfferingsApi.reviewApi],
  )

  const getTutorialsForPage = useCallback(
    async (currentPageType: string) => {
      try {
        const tutorials = await monetisationOfferingsApi.tutorialApi?.getTutorials({
          tutorial_type: currentPageType,
        })

        managePageDispatch({
          type: 'SET_TUTORIAL',
          payload: tutorials?.data?.data,
        })
        if (
          currentPageType !== 'offerings_feed_page' &&
          currentPageType !== 'offerings_feed_page_with_pooja'
        ) {
          pageForm.setFieldsValue({
            tutorials: tutorials?.data
              ? {
                ...tutorials?.data?.data,
                media_url: [tutorials?.data?.data?.media?.media_url],
              }
              : {},
          })
          setShowPageForm(true)
        } else {
          const help_tutorials =
            await monetisationOfferingsApi.tutorialApi?.getTutorials({
              tutorial_type: 'offerings_feed_help_tutorial_page',
            })
          feedsForm.setFieldsValue({
            tutorials: {
              ...tutorials?.data?.data,
              media_url: [tutorials?.data?.data?.media?.media_url],
            },
            help_tutorials: {
              ...help_tutorials?.data?.data,
              media_url: [help_tutorials?.data?.data?.media?.media_url],
            },
          })
        }
      } catch (error) {
        message.error({
          content: `Error while fetching Faq's for page ${currentPageType}`,
          duration: 3,
        })
        console.log(error)
      }
    },
    [monetisationOfferingsApi.tutorialApi],
  )

  const getStepsForPage = useCallback(
    async (currentPageType: string) => {
      try {
        const steps = await monetisationOfferingsApi.stepsApi?.getSteps(currentPageType)
        managePageDispatch({ type: 'SET_STEPS', payload: steps?.data?.data })
        pageForm.setFieldsValue({ steps: steps?.data?.data })
        setShowPageForm(true)
      } catch (error) {
        message.error({
          content: `Error while fetching Steps's for page ${currentPageType}`,
          duration: 3,
        })
        console.log(error)
      }
    },
    [monetisationOfferingsApi.stepsApi, pageForm],
  )

  const getPageInfoList = useCallback(async () => {
    try {
      setIsLoading(true)
      const pageInfoList = await monetisationOfferingsApi.pageInfoApi.getPageInfoList()
      setPageInfoList(pageInfoList?.data?.data?.pages)
      const feedPage = pageInfoList?.data?.data?.pages?.find(
        (item: PageListItem) => item.key === 'offerings_feed_page',
      )

      if (sectionList?.length) {
        const filteredSection = sectionList?.filter(
          (section: ManageSectionItem) =>
            feedPage?.sections?.includes(section.id),
        )

        if (filteredSection?.length) {
          setSortedSectionList(filteredSection)
        }
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      message.error({
        content: 'Error while fetching page info list',
        duration: 3,
      })
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getPageInfoList()
  }, [getPageInfoList])

  const onCreateNew = () => {
    setShowItemCreation(true)
  }
  const closePageForm = () => {
    setShowPageForm(false)
    setShowPageFeed(false)
    setTimeout(() => {
      getPageInfoList()
    }, 500)
  }

  const handleCreateApiCalls = async (formData: any) => {
    switch (currentPageTypeData?.key) {
      case 'store_list_page_pooja':
        try {
          if (formData?.faqs?.create?.faqs?.length) {
            await monetisationOfferingsApi.faqsApi.createFaqs({
              ...formData?.faqs?.create,
              language_code: languageState?.monetisationLanguage,
            })
          }
          if (formData?.faqs?.update?.faqs?.length) {
            await monetisationOfferingsApi.faqsApi.updateFaqs({
              ...formData?.faqs?.update,
              language_code: languageState?.monetisationLanguage,
            })
          }
          if (formData?.reviews?.create?.reviews?.length) {
            try {
              await monetisationOfferingsApi.reviewApi.createReviews(
                formData?.reviews?.create,
              )
              message.success({
                content: 'Reviews Created Successfully',
                duration: 2,
                key: 'review_create',
              })
            } catch (error) {
              console.log('error occured while creating faq', error)
              message.error({
                content: 'Error occured while creating faqs and reviews',
                duration: 2,
                key: 'review_create',
              })
            }
          }
          if (formData?.reviews?.update?.reviews?.length) {
            try {
              await monetisationOfferingsApi.reviewApi.updateReviews(
                formData?.reviews?.update,
              )
              message.success({
                content: 'Reviews Updated Successfully',
                duration: 2,
                key: 'review_update',
              })
            } catch (error) {
              console.log('error occured while updating faq', error)
              message.destroy('review_update')
            }
          }

          const payload = sortedSectionList?.map((item: any) => item?.id)
          try {
            await monetisationOfferingsApi?.pageInfoApi?.updatePages(
              currentPageTypeData?.id || '',
              {
                sections: payload,
              },
            )
            message.success({ content: 'Section updated sucessfully', duration: 3 })
            getPageInfoList()

            setShowPageFeed(false)
          } catch (error) {
            message.error({ content: 'Error occured while updating pages.' })
          }

          message.success({
            content: 'Faqs and Reviews submitted successfully',
            duration: 3,
          })
          pageForm.resetFields()
          setCurrentPageTypeData(undefined)
          setShowPageForm(false)
        }
        catch (error) {
          message.error({
            content: 'Faqs and Reviews failed while submission',
            duration: 2,
          })
          console.log(error)
        }
        return

      case 'store_list_page':
        message.loading({
          content: 'Creating faqs and tutorials...',
          duration: 1,
        })
        try {
          if (formData?.faqs?.create?.faqs?.length) {
            await monetisationOfferingsApi.faqsApi.createFaqs({
              ...formData?.faqs?.create,
              language_code: languageState?.monetisationLanguage,
            })
          }
          if (formData?.faqs?.update?.faqs?.length) {
            await monetisationOfferingsApi.faqsApi.updateFaqs({
              ...formData?.faqs?.update,
              language_code: languageState?.monetisationLanguage,
            })
          }
          if (formData?.tutorials && formData?.tutorials.create) {
            await monetisationOfferingsApi.tutorialApi.createTutorials({
              ...formData?.tutorials?.create,
              language_code: languageState?.monetisationLanguage,
            })
          }
          if (formData?.tutorials && formData?.tutorials.update) {
            await monetisationOfferingsApi.tutorialApi.updateTutorials({
              ...formData?.tutorials?.update,
              language_code: languageState?.monetisationLanguage,
            })
          }
          message.success({
            content: 'Faqs and tutorials submitted successfully',
            duration: 3,
          })
          pageForm.resetFields()
          setCurrentPageTypeData(undefined)
          setShowPageForm(false)
        } catch (error) {
          message.error({
            content: 'Faqs and tutorials failed while submission',
            duration: 2,
          })
          console.log(error)
        }
        return
      case 'cart_review_page':
      case 'cart_review_page_pooja':
        message.loading({
          content: 'Creating faqs...',
          duration: 1,
        })
        try {
          if (formData?.faqs?.create?.faqs?.length) {
            await monetisationOfferingsApi.faqsApi.createFaqs({
              ...formData?.faqs?.create,
              language_code: languageState?.monetisationLanguage,
            })
          }
          if (formData?.faqs?.update?.faqs?.length) {
            await monetisationOfferingsApi.faqsApi.updateFaqs({
              ...formData?.faqs?.update,
              language_code: languageState?.monetisationLanguage,
            })
          }
          message.success({
            content: 'Faqs created successfully',
            duration: 3,
          })
          pageForm.resetFields()
          setCurrentPageTypeData(undefined)
          setShowPageForm(false)
        } catch (error) {
          message.error({
            content: 'Faqs creation failed',
            duration: 2,
          })
          console.log(error)
        }
        return
      case 'payment_successful_page_pooja':
      case 'payment_successful_page':
      case 'payment_failed_page':
        message.loading({
          content: 'Creating steps...',
          duration: 1,
        })

        if (formData?.steps?.update?.steps?.length) {
          try {
            await monetisationOfferingsApi.stepsApi.updateSteps({
              ...formData?.steps?.update,
              language_code: languageState?.monetisationLanguage,
            })

            message.success({
              content: 'Steps updated successfully',
              duration: 3,
            })
          } catch (error) {
            message.error({
              content: 'Steps creation failed',
              duration: 2,
            })
            console.log(error)
          }
        }
        if (formData?.steps?.create?.steps?.length) {
          try {
            await monetisationOfferingsApi.stepsApi.createSteps({
              ...formData?.steps?.create,
              language_code: languageState?.monetisationLanguage,
            })

            message.success({
              content: 'Steps created successfully',
              duration: 3,
            })
          } catch (error) {
            message.error({
              content: 'Steps creation failed',
              duration: 2,
            })
            console.log(error)
          }
        }
        pageForm.resetFields()
        setCurrentPageTypeData(undefined)
        setShowPageForm(false)
        return
      default:
        const payload = sortedSectionList?.map((item: any) => item?.id)
        try {
          await monetisationOfferingsApi?.pageInfoApi?.updatePages(
            currentPageTypeData?.id || '',
            {
              sections: payload,
            },
          )
          message.success({ content: 'Section updated sucessfully', duration: 3 })
          getPageInfoList()

          setShowPageFeed(false)
        } catch (error) {
          message.error({ content: 'Error occured while updating pages.' })
        }
        message.success({
          content: 'Update sections banner items',
          duration: 3,
        })
        pageForm.resetFields()
        setCurrentPageTypeData(undefined)
        setShowPageForm(false)
    }
  }

  const formSubmithandler = (sections: any) => {
    pageForm
      .validateFields()
      .then(values => {
        if (currentPageTypeData) {
          const formData = formatPageinfoByPageType(
            currentPageTypeData,
            { ...pageForm.getFieldsValue(), sections },
            languageState?.monetisationLanguage
          )
          handleCreateApiCalls(formData)
        }
      })
      .catch(error => {
        console.log('Validation error', error, pageForm.getFieldsError())
      })
  }
  const onClickHandler = (pageType: string, language_code: string) => {
    const filteredPageType = pageInfoList.find(item => item.key === pageType && item.language_code === language_code)
    setCurrentPageTypeData(filteredPageType)
    if (
      pageType === 'offerings_feed_page' ||
      pageType === 'offerings_feed_page_with_pooja'
    ) {
      getReviewsForPage(filteredPageType)
      getTutorialsForPage(filteredPageType?.key || '')
      setShowPageFeed(true)
      getSections(pageInfoList, filteredPageType)
    } else {
      if (filteredPageType?.key === 'store_list_page') {
        getFaqsForPage(filteredPageType?.key || '')
        getTutorialsForPage(filteredPageType?.key || '')
      }
      if (
        filteredPageType?.key === 'cart_review_page' ||
        filteredPageType?.key === 'cart_review_page_pooja'
      ) {
        getFaqsForPage(filteredPageType?.key || '')
      }
      if (
        filteredPageType?.key === 'payment_successful_page' ||
        filteredPageType?.key === 'payment_successful_page_pooja' ||
        filteredPageType?.key === 'payment_failed_page'
      ) {
        getStepsForPage(filteredPageType?.key || '')
      }
      if (filteredPageType?.key === 'store_list_page_pooja') {
        getReviewsForPage(filteredPageType)
        getFaqsForPage(filteredPageType?.key || '')
        getSections(pageInfoList, filteredPageType)
      }
      if (filteredPageType?.key?.includes('banner_items')
        || filteredPageType?.key === 'srimandir_services_page'
        || filteredPageType?.key === 'banner_large_items_slp'
        || filteredPageType?.key === ('order_history_l2_page_offerings')
        || filteredPageType?.key === ('order_history_l2_page_pooja')
        || filteredPageType?.key === ('sankalp_banners_slp')
        || filteredPageType?.key === ('promotional_banners_v2_slp')
        || filteredPageType?.key === ('promotional_banners_slp')
      ) {
        getSections(pageInfoList, filteredPageType)
        setShowPageForm(true)
      }
    }
  }

  const handleOnCancel = () => {
    setDandDModalVisibility(false)
  }

  const handleOnOk = (sortedList: any[]) => {
    setSortedSectionList(sortedList)
    /* const feedPage = pageInfoList?.find(
      (item: PageListItem) => item.key === 'offerings_feed_page',
    ) */
    const sortedIds = sortedList?.map((item: any) => item?.id)
    if (!_.isEqual(currentPageTypeData?.sections, sortedIds)) {
      setShowUpdateButton(true)
    } else {
      setShowUpdateButton(true)
    }
    setDandDModalVisibility(false)
  }

  const updatePages = async () => {
    /* const feedPage: any = pageInfoList?.find(
      (item: PageListItem) => item.key === 'offerings_feed_page' || item.key === "offerings_feed_page_with_pooja"
    ) */
    const payload = sortedSectionList?.map((item: any) => item?.id)
    feedsForm.validateFields().then(async values => {
      const formData = formatPageinfoByPageType(currentPageTypeData, {
        ...feedsForm.getFieldsValue(),
        store_id: currentPageTypeData?.id,
      }, languageState?.monetisationLanguage)

      if (formData?.reviews?.create?.reviews?.length) {
        try {
          await monetisationOfferingsApi.reviewApi.createReviews(
            formData?.reviews?.create,
          )
          message.success({
            content: 'Reviews Created Successfully',
            duration: 2,
            key: 'review_create',
          })
        } catch (error) {
          console.log('error occured while creating faq', error)
          message.error({
            content: 'Error occured while creating faqs and reviews',
            duration: 2,
            key: 'review_create',
          })
        }
      }
      if (formData?.reviews?.update?.reviews?.length) {
        try {
          await monetisationOfferingsApi.reviewApi.updateReviews(
            formData?.reviews?.update,
          )
          message.success({
            content: 'Reviews Updated Successfully',
            duration: 2,
            key: 'review_update',
          })
        } catch (error) {
          console.log('error occured while updating faq', error)
          message.destroy('review_update')
        }
      }
      try {
        if (formData?.tutorials && formData?.tutorials.create) {
          await monetisationOfferingsApi.tutorialApi.createTutorials(
            formData?.tutorials?.create,
          )
        }
        if (formData?.tutorials && formData?.tutorials.update) {
          await monetisationOfferingsApi.tutorialApi.updateTutorials({
            ...formData?.tutorials?.update,
            language_code: languageState?.monetisationLanguage,
          })
        }
        if (formData?.help_tutorials && formData?.help_tutorials.create) {
          await monetisationOfferingsApi.tutorialApi.createTutorials(
            formData?.help_tutorials?.create,
          )
        }
        if (formData?.help_tutorials && formData?.help_tutorials.update) {
          await monetisationOfferingsApi.tutorialApi.updateTutorials({
            ...formData?.help_tutorials?.update,
            language_code: languageState?.monetisationLanguage,
          })
        }
        message.success({
          content: 'Tutorials submitted successfully',
          duration: 3,
        })
        feedsForm.resetFields()
        setCurrentPageTypeData(undefined)
      } catch (error) { }
    })

    try {
      await monetisationOfferingsApi?.pageInfoApi?.updatePages(
        currentPageTypeData?.id || '',
        {
          sections: payload,
        },
      )
      message.success({ content: 'Section updated sucessfully', duration: 3 })
      getPageInfoList()

      setShowPageFeed(false)
    } catch (error) {
      message.error({ content: 'Error occured while updating pages.' })
    }
  }

  const deleteFaq = async (id: string, callback: any) => {
    try {
      await monetisationOfferingsApi.faqsApi.deleteFaq(id)
      message.success({
        content: 'Faq deleted successfully',
        duration: 3,
      })
      callback()
      getFaqsForPage(currentPageTypeData?.key || '')
    } catch (error) {
      message.error({
        content: 'Faq deletion failed',
        duration: 2,
      })
      //pageForm.resetFields()
      getFaqsForPage(currentPageTypeData?.key || '')
      console.log(error)
    }
  }
  const deleteSteps = async (id: string, callback: any) => {
    try {
      await monetisationOfferingsApi.stepsApi.deleteStep(id)
      message.success({
        content: 'Step deleted successfully',
        duration: 3,
      })
      callback()
      getStepsForPage(currentPageTypeData?.key || '')
    } catch (error) {
      message.error({
        content: 'Step deletion failed',
        duration: 2,
      })
      getStepsForPage(currentPageTypeData?.key || '')
      console.log(error)
    }
  }
  const deleteReview = async (id: string, callback: any) => {
    try {
      await monetisationOfferingsApi.reviewApi.deleteReview(id)
      message.success({
        content: 'Review deleted successfully',
        duration: 3,
      })
      callback()
      getReviewsForPage(currentPageTypeData)
    } catch (error) {
      message.error({
        content: 'Review deletion failed',
        duration: 2,
      })
    }
  }
  const deleteTutorial = async (id: string) => {
    try {
      await monetisationOfferingsApi.tutorialApi.deleteTutorials(id)
      message.success({
        content: 'tutorial deleted successfully',
        duration: 3,
      })
      feedsForm.resetFields()
      getTutorialsForPage(currentPageTypeData?.key || '')
      getReviewsForPage(currentPageTypeData)
    } catch (error) {
      message.error({
        content: 'Faq deletion failed',
        duration: 2,
      })
      //pageForm.resetFields()
      getTutorialsForPage(currentPageTypeData?.key || '')
      console.log(error)
    }
  }
  const onReviewFormChange = () => {
    setShowUpdateButton(true)
  }
  const formChangeHandler = (values: any) => {
    setShowUpdateButton(true)
  }
  return (
    <div style={{ padding: '0 40px' }}>
      {
        isLoading ?
          <div style={{ minHeight: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <Spin />
          </div>
          :
          <>
            <ManagePagesPage
              onCreateNew={onCreateNew}
              dataSource={pageInfoList}
              onClickhandler={onClickHandler}
              isSubtitle={true}
            />
            <PageInfoForm
              form={pageForm}
              cdnUrl={YODA_CDN_URL}
              preSignUrl={YODA_PRESIGN_URL}
              formSubmithandler={formSubmithandler}
              initialValues={reducerState}
              onClose={closePageForm}
              pageType='info'
              visible={showPageForm}
              pageTitle={currentPageTypeData?.name || ''}
              currentPageTypeData={currentPageTypeData}
              deleteFaq={deleteFaq}
              deleteTutorials={deleteTutorial}
              deleteSteps={deleteSteps}
              deleteReview={deleteReview}
              setDandDModalVisibility={setDandDModalVisibility}
              sortedSectionList={sortedSectionList}
              showUpdateButton={showUpdateButton}
              updatePages={updatePages}
              renderer={<ShowSectionInfo key={'section-props'} />}
              formChangeHandler={formChangeHandler}
            />
            <OfferingFeedPage
              cdnUrl={YODA_CDN_URL}
              preSignUrl={YODA_PRESIGN_URL}
              onClose={closePageForm}
              visible={showPageFeed}
              pageTitle={currentPageTypeData?.name || ''}
              currentPageTypeData={currentPageTypeData}
              setDandDModalVisibility={setDandDModalVisibility}
              renderer={<ShowSectionInfo key={'section-props'} />}
              sortedSectionList={sortedSectionList}
              showUpdateButton={showUpdateButton}
              updatePages={updatePages}
              form={feedsForm}
              deleteReview={deleteReview}
              deleteTutorials={deleteTutorial}
              onFormChange={onReviewFormChange}
              pageKey={currentPageTypeData?.key || ''}
            />
            <DragAndDropModal
              showModal={showDandDModal}
              handleOnCancel={handleOnCancel}
              handleOnOk={handleOnOk}
              sortedData={sortedSectionList}
              itemList={sectionList}
              title={`Select and arrange sections`}
            >
              <ShowSectionInfo key={'section-rendered'} />
            </DragAndDropModal>
          </>
      }
    </div>
  )
}

export default ManagePagesScreen
