import { Button, Col, Row, Typography, Tag } from 'antd'
import styled from 'styled-components'

import React from 'react'

import { PageListItem } from '@a4b/api/src/modules/Monetisation/offerings/types'
import { SUPPORTED_LANGUAGES_LIST } from "../../../../../../../apps/admin/src/utils/constants";

const { Title } = Typography
interface Props {
  data: PageListItem
  onClickhandler: (pageType: string, language_code: string) => void
  isSubTitlePresent: boolean
}
const Container = styled.div`
  padding: 10px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  .ant-card-body {
    flex-grow: 1;
  }
  flex-direction: row;
  &:hover {
    cursor: pointer;
  }
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const ContentWrapper = styled(Title)`
  margin-bottom: 0 !important;
  margin-top: 0;
`
const SubTitleWrapper = styled.span`
  color: #434343;
`
const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`
const Anchor = styled.div`
  margin-bottom: 20px;
  display: block;
`
const PageInfoItem: React.FC<Props> = ({ data, onClickhandler, isSubTitlePresent }) => {
  return (
    <Anchor key={data?.id} onClick={() => onClickhandler(data?.key, data?.language_code)}>
      <Container>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          style={{ width: '100%' }}
        >
          <Col className='gutter-row' span={10}>
            <InfoWrapper>
              <Col style={{ alignContent: 'center', alignItems: 'center', height: '100%' }}>
                <ContentWrapper level={5}>{data?.name}</ContentWrapper>
                {isSubTitlePresent && <SubTitleWrapper>{`Faq's, Tutorials, Steps and Reviews `}</SubTitleWrapper>}
              </Col>
              <Col>
                <Tag color='green'>
                  {SUPPORTED_LANGUAGES_LIST.filter((lang) => lang?.value === data?.language_code)?.[0]?.alt_name}
                </Tag>
              </Col>
            </InfoWrapper>
          </Col>
          <Col className='gutter-row' span={3}></Col>
          <Col className='gutter-row' span={3}></Col>
          <Col className='gutter-row' span={8}>
            <LabelRightAlign>
              <Button type='text'> &rarr;</Button>
            </LabelRightAlign>
          </Col>
        </Row>
      </Container>
    </Anchor>
  )
}
export default PageInfoItem
