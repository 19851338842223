import { Button, Form, FormInstance, Input } from "antd";
import { useAppContext } from "../../../../../components/AppContext";
import { useEffect } from "react";
import { GroupPayload } from "@a4b/api/src/modules/Events/types";

interface CreateDefinationsProps {
    form: FormInstance<GroupPayload>
}

const CreateGroup = ({ form }: CreateDefinationsProps) => {
    const { userProfile } = useAppContext();
    useEffect(() => {
        form.setFieldValue('created_by', userProfile?.email || 'NA')
    }, [form, userProfile?.email])

    return <>
        <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please input your Name!' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            name="created_by"
            label="Created By"
            rules={[{ required: true, message: 'Please input your Created By!' }]}
            initialValue={userProfile?.email || "NA"}
        >
            <Input disabled />
        </Form.Item>
        <Form.Item >
            <Button type="primary" htmlType="submit"> Submit </Button>
        </Form.Item>
    </>
}

export default CreateGroup;