import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { PoojaOrder } from './types'

class OrdersApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getOrders = (
    pageNo: string,
    orderStatus: string,
    serviceType: string,
    bookingId: string,
    contactNumber: string,
    userId: string,
    storeId: string,
    bookingWhatsapp: string,
    bookingMemberData: string,
    deliveryStatus: string,
    bookingAddress: string,
  ) =>
    this.api.get<PoojaOrder>(`yoda/internal/v1/orders`, {
      params: {
        page_no: pageNo,
        order_status: orderStatus,
        ...(serviceType && { service_type: serviceType }),
        ...(bookingId && { booking_id: bookingId }),
        ...(contactNumber && { contact_number: contactNumber }),
        ...(userId && { user_id: userId }),
        ...(storeId && { store_id: storeId }),
        ...(bookingWhatsapp && { booking_whatsapp_number: bookingWhatsapp }),
        ...(bookingMemberData && { booking_member_data: bookingMemberData }),
        ...(deliveryStatus && { delivery_status: deliveryStatus }),
        ...(bookingAddress && { booking_address: bookingAddress }),
      },
    })

  updateOrder = (orderId: string, action: string, payload: any) =>
    this.api.patch(
      `gw1/yoda/internal/v1/orders/${orderId}/${action}`,
      payload,
      {
        headers: { 'x-afb-platform': 'web' },
      },
    )
}

export default OrdersApi
