import { Document, Page, StyleSheet, Text, View, Font } from '@react-pdf/renderer'

import React, { useEffect } from 'react'

import { Batch } from '@a4b/api/src/modules/Monetisation/Puja/types'

interface Props {
  batchData: Batch | undefined
  dataType: 'name_list' | 'prayer_list'
}

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Noto Sans Hindi',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    marginLeft: 50,
    marginTop: 50,
  },
  header: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: "10px"
  },
  section: {
    marginLeft: 10,
    padding: 5,
  },
  prayerNote: {
    margin: 20,
  },
})
const BatchPrintView: React.FC<Props> = ({ batchData, dataType }) => {
  if (dataType === 'name_list') {
    return (
      <Document>
        <Page size='A4' style={styles.page}>
          <View style={styles.header}>
            <Text>{`Batch ID/Code : ${batchData?.batch_code || batchData?.batch_id}`}</Text>
          </View>
          {batchData?.offering_names?.map((item: string, index: number) => (
            <View style={styles.section} key={index}>
              <Text>{`${index + 1}. ${item}`} {batchData?.meta_data?.quantity_list?.[index] ? `X ${batchData?.meta_data?.quantity_list?.[index]}` : ""}</Text>
            </View>
          ))}
        </Page>
      </Document>
    )
  }
  if (dataType === 'prayer_list') {
    return (
      <Document>
        <Page size='A4' style={styles.page}>
          <View style={styles.header}>
            <Text>{`Batch ID/Code : ${batchData?.batch_code || batchData?.batch_id}`}</Text>
          </View>
          {batchData?.meta_data?.prayer_notes?.map(
            (item: string, index: number) => (
              <View style={styles.prayerNote} key={index}>
                <Text>{`${index + 1}. ${item}`}</Text>
              </View>
            ),
          )}
        </Page>
      </Document>
    )
  }
  return <div></div>
}
export default BatchPrintView
