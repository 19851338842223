import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ScheduleOutlined,
} from '@ant-design/icons'
import { Badge, Button, Calendar, message, Popconfirm, Space } from 'antd'
import moment from 'moment'
import styled from 'styled-components'

import React from 'react'

import { ScheduleData, ScheduleType } from '@a4b/api'

export interface Props {
  type: ScheduleType
  schedules?: ScheduleData[]
  onViewScheduleClick: (schedule: ScheduleData) => void
  onCreateScheduleClick: (date: moment.Moment) => void
  onCloneScheduleClick: (schedule: ScheduleData) => void
  onDeleteScheduleClick: (schedule: ScheduleData) => Promise<boolean>
}

const CreateScheduleButton = styled(Button)`
  position: absolute;
  bottom: 0;
  right: -6px;
  padding: 0;
  margin: 0 12px;
`

const EditScheduleButton = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 16px;
  padding: 0;
  margin: 0 12px;
`

const DeleteScheduleButton = styled(Button)`
  position: absolute;
  bottom: 0;
  right: -6px;
  padding: 0;
  margin: 0 12px;
`

const CloneScheduleButton = styled(Button)`
  position: absolute;
  bottom: 0;
  left: -6px;
  padding: 0;
  margin: 0 12px;
`



export const CalendarView: React.FC<Props> = ({
  schedules,
  onViewScheduleClick,
  onCreateScheduleClick,
  onCloneScheduleClick,
  onDeleteScheduleClick
}) => {
  const getDisabledDate = (current: moment.Moment) => {
    if (!current) {
      return true
    }
    if (current > moment().add(30, 'd')) {
      return true
    }
    if (current < moment().subtract(1, 'd')) {
      const schedule = schedules?.find(
        schedule => schedule.date === current.format('YYYY-MM-DD'),
      )
      if (!schedule) {
        return true
      }
    }
    return false
  }

  const handleDeleteSchedule = async (schedule: ScheduleData) => {
    const success = await onDeleteScheduleClick(schedule)
    success
      ? message.success('Successfully delete schedule')
      : message.error('Failed to delete schedule')
  }

  const dateCellRender = (value: moment.Moment) => {
    const schedule = schedules?.find(
      schedule => schedule.date === value.format('YYYY-MM-DD'),
    )
    const isDisabled = getDisabledDate(value)
    if (!schedule) {
      if (isDisabled) {
        return
      }
      return (
        <>
          <CreateScheduleButton
            type='link'
            onClick={onCreateScheduleClick.bind(this, value)}
          >
            <ScheduleOutlined />
          </CreateScheduleButton>
        </>
      )
    }
    const disableDelete = value > moment().subtract(1, 'd') ? true : false
    return (
      <>
        <Badge status={'success'} size='small' text={'Scheduled'} />
        <Space>
          <CloneScheduleButton
            type='link'
            onClick={onCloneScheduleClick.bind(this, schedule)}
          >
            <CopyOutlined />
          </CloneScheduleButton>
          <EditScheduleButton
            style={{ right: !disableDelete ? '-6px' : undefined }}
            type='link'
            onClick={onViewScheduleClick.bind(this, schedule)}
          >
            <EditOutlined />
          </EditScheduleButton>
          {
            disableDelete &&
            < Popconfirm placement="topLeft" title={'Are you sure you want to delete this Schedule?'} onConfirm={handleDeleteSchedule.bind(this, schedule)} okText="Yes" cancelText="No">
              <DeleteScheduleButton
                type='link'
              >
                <DeleteOutlined />
              </DeleteScheduleButton>
            </Popconfirm>
          }
        </Space>
      </>
    )
  }

  return (
    <div style={{ maxWidth: '800px' }}>
      <Calendar
        dateCellRender={dateCellRender}
        disabledDate={getDisabledDate}
      />
    </div>
  )
}

export default CalendarView
