//context
import { useAppContext } from '@/components/AppContext'

//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import { Modal, message } from 'antd'

//hooks
import { useEffect, useState, useCallback, useRef } from 'react'

//organisms
import ManageCityPage from '@a4b/ui/src/modules/monetisation/molecules/Temple/ManageCityPage'

//types
import { City } from '@a4b/api/src/modules/Content/Temples/types'

import './helper'
import { parseCityResponseData } from './helper'

const getPageBreadcrumbs = () => [
    {
        path: '/temples/manage-temples',
        breadcrumbName: 'Manage Temples',
    },
    {
        path: '/temples/manage-cities',
        breadcrumbName: 'Manage City',
    },
]

const ManageCities = () => {
    const { networkInstance } = useAppContext()
    const { clientWithHeaders } = networkInstance

    const [showCityEnableModal, setShowCityEnableModal] = useState<boolean>(false)
    const [showCityDisableModal, setShowCityDisableModal] = useState<boolean>(false)
    const [currentCity, setCurrentCity] = useState<any>()

    const [cityList, setCityList] = useState<City[]>()

    const getCityList = async () => {
        try {
            const CityListResponse = await clientWithHeaders.contentApi.temples.getCityList()
            if (!CityListResponse?.data?.data?.Cities?.length) {
                setCityList([])
                message.info('No cities found', 2)
                return
            }
            const parsedCityResponse = parseCityResponseData(CityListResponse?.data?.data?.Cities)
            setCityList(parsedCityResponse)
        } catch (error) {
            message.error('Error while fetching Cities', 3)
        }
    }

    useEffect(() => {
        getCityList()
    }, [])

    const onStatusChange = (item: any) => {
        setCurrentCity(item)
        if (item?.is_active) {
            setShowCityDisableModal(true)
        } else {
            setShowCityEnableModal(true)
        }
    }
    const onModalOk = async () => {
        message.loading({
            content: 'Updating city status',
            duration: 1,
            key: 'update_status',
        })
        try {
            await clientWithHeaders.contentApi.temples.updateCityStatus(currentCity?.city_code, { is_active: !currentCity?.is_active })
            setShowCityEnableModal(false)
            setShowCityDisableModal(false)
            message.success({
                content: 'City status updated sucessfully',
                duration: 2,
                key: 'update_status',
            })
            getCityList()
        } catch (error) {
            setShowCityEnableModal(false)
            setShowCityDisableModal(false)
            message.error({
                content: 'Error while updating City status',
                duration: 2,
                key: 'update_status',
            })
        }
    }

    const onModalCancel = () => {
        setShowCityEnableModal(false)
        setShowCityDisableModal(false)
    }

    return (
        <div style={{ padding: '0 40px' }}>
            <AdminBreadcrumb routes={getPageBreadcrumbs()} />
            <ManageCityPage
                onStatusChange={onStatusChange}
                cityList={cityList!}
            />
            <Modal
                title='Activate City'
                visible={showCityEnableModal}
                onOk={onModalOk}
                onCancel={onModalCancel}
                okText=' Yes, Activate'
            >
                <p>Are you sure to activate this City?</p>
            </Modal>
            <Modal
                title='Deactivate City'
                visible={showCityDisableModal}
                onOk={onModalOk}
                onCancel={onModalCancel}
                okText='Yes, Deactivate'
            >
                <p>Are you sure to Deactivate this City?</p>
            </Modal>
        </div>
    )
}

export default withDefaultLayout(ManageCities)
