import React, { useState } from 'react';
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import InputColorPreview from '../InputColorPreview';

const { Option } = Select;

type ColorPropertiesProps = {
    fieldPath: Array<string>;
    formInstance: FormInstance;
};

const ColorProperties: React.FC<ColorPropertiesProps> = ({ fieldPath, formInstance }) => {
    const [colorType, setColorType] = useState<string>('solid');

    const handleColorTypeChange = (value: string) => {
        setColorType(value);
    };

    return (
        <div style={{ border: '1px #8c8c8c dashed', padding: '15px', borderRadius: '10px', marginBottom: '10px' }}>
            <Form.Item
                label="Color Type"
                name={[...fieldPath, 'type']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: 'Please select background color type!' }]}
            >
                <Select
                    placeholder="Select color type"
                    onChange={handleColorTypeChange}
                    value={colorType}
                >
                    <Option value="linear_gradient">Linear Gradient</Option>
                    <Option value="solid">Solid</Option>
                </Select>
            </Form.Item>

            <Form.Item shouldUpdate={() => true}>
                {({ getFieldValue }) =>
                    getFieldValue([...fieldPath, 'type']) === 'linear_gradient' ? (
                        <>
                            <InputColorPreview fieldPath={[...fieldPath, 'colors', 0]} formInstance={formInstance} />
                            <InputColorPreview fieldPath={[...fieldPath, 'colors', 1]} formInstance={formInstance} />
                        </>
                    ) : (
                        <InputColorPreview fieldPath={[...fieldPath, 'colors', 0]} formInstance={formInstance} />
                    )
                }
            </Form.Item>

            <Form.Item
                label="Color Args"
                name={[...fieldPath, 'args']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: 'Please select the color args!' }]}
            >
                <Select
                    placeholder="Select color args"
                >
                    <Option value="to_right">To Right</Option>
                    <Option value="to_left">To Left</Option>
                    <Option value="to_top">To Top</Option>
                    <Option value="to_bottom">To Bottom</Option>
                </Select>
            </Form.Item>
        </div>
    );
};

export default ColorProperties;
