import Widget from '@/entities/feeds/Widget'
import { Col, Form, Input, Row, Select } from 'antd'

import CodeInput from '../../components/CodeInput'

const { TextArea } = Input

interface Props {
  form: any
  initialValues: any
  finishHandler: (formData: any) => void

  isEdit?: boolean
  masterWidgetList: { value: string; label: string }[]
}
const CreateWidget: React.FunctionComponent<Props> = ({
  form,
  initialValues,
  finishHandler,
  masterWidgetList,
}: Props) => {
  return (
    <Form
      form={form}
      name='create_widget'
      onFinish={finishHandler}
      initialValues={initialValues}
    >
      <Row>
        <Col span={24}>
          <Row justify='center'>
            <Col span={20}>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                required={true}
                label='Code'
                name='code'
                rules={[
                  {
                    validator: Widget.codeValidator,
                  },
                ]}
                tooltip='Max allowed char length is 64'
              >
                <CodeInput
                  maxLength={64}
                  placeHolder='Enter unique widget code'
                  showCount
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={20}>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label='Widget Type'
                name='widget_type'
                required={true}
                hasFeedback={true}
                rules={[
                  {
                    validator: Widget.widgetTypeValidator,
                  },
                ]}
              >
                <Select
                  placeholder='Select widget type'
                  options={masterWidgetList}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={20}>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                required={true}
                label='Description'
                name='description'
                rules={[
                  {
                    validator: Widget.descriptionValidator,
                  },
                ]}
                tooltip='Max allowed char length is 128'
              >
                <TextArea
                  showCount
                  maxLength={128}
                  placeholder='Enter widget description'
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default CreateWidget
