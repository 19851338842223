import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { UserData, LoginPayload, Role } from "./types"
import { apiClient } from '../../apiClient'

class AdminControl {
    private api: AxiosInstance

    constructor(config: AxiosRequestConfig) {
        this.api = apiClient.create(config)

    }

    // Write all the get calls below 
    getUserData = async (user: string | number) =>
        this.api.get<{ data: UserData }>(`gw1/atom/internal/v1/users?q=${user}`)


    getUserRoles = async (userId: string | number) =>
        this.api.get<{ data: UserData }>(`/gw1/atom/internal/v1/users/${userId}/roles`)

    // Write all the post calls below
    login = async (payload: LoginPayload) => {
        return this.api.post<{
            data: {
                token: {
                    access_token: string
                    refresh_token: string
                    expires_in: number
                    refresh_expires_in: number
                    not_before_policy: number
                    token_type: string
                }
            }
        }>(
            `/gw1/atom/internal/v1/sessions/sso`,
            payload
        )
    }

    assignRole = (user: string, role: Role) => {
        return this.api.post(
            `/gw1/atom/internal/v1/users/${user}/roles/${role}`,
        )
    }

    refreshToken = (token: string) => {
        return this.api.post(
            `/gw1/atom/internal/v1/sessions/refresh-token`,
            {
                "refresh_token": token
            }
        )
    }

    // Write all delete calls below 
    deleteRole = (user: string, role: Role) => {
        return this.api.delete(
            `/gw1/atom/internal/v1/users/${user}/roles/${role}`,
        )
    }

}

export default AdminControl;
