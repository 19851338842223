import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import AntdFormWrapper from "../../../../../components/Forms/AntdFormWrapper";
import { FormInstance } from "antd";
import CreateSuggestiveCard, { CreateSuggestiveCardProps } from "../components/CreateSuggestiveCard";
import { SuggestionCardPayload } from "@a4b/api/src/modules/SuggestionCards/types";
import { useAppContext } from "../../../../../components/AppContext";


export enum DRAWER_TYPE {
    CREATE_SUGGESTIVE_CARD = 'Create Suggestive card',
}

const FILE_PAYLOAD_PATH = [
    'god_set.icon_path',
    'god_set.god_set.*.image_path',
    'shubh_mantra.back_ground_image.sunday',
    'shubh_mantra.back_ground_image.monday',
    'shubh_mantra.back_ground_image.tuesday',
    'shubh_mantra.back_ground_image.wednesday',
    'shubh_mantra.back_ground_image.thursday',
    'shubh_mantra.back_ground_image.friday',
    'shubh_mantra.back_ground_image.saturday',
];

export default function useDrawerConfig(activeDrawer?: DRAWER_TYPE): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {
    const { networkInstance } = useAppContext();

    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_SUGGESTIVE_CARD,
                width: 900,
            },
            drawerContent: <AntdFormWrapper<SuggestionCardPayload, SuggestionCardPayload>
                FormFields={CreateSuggestiveCard}
                createApi={networkInstance.clientWithHeaders.suggestiveCards.createSuggestiveCard}
                extractFilePaths={FILE_PAYLOAD_PATH}
            />
        }
    ], [networkInstance.clientWithHeaders.suggestiveCards.createSuggestiveCard])
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}