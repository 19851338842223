import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { PlaylistResponse } from './playlist.types'
import { SongInfoRes } from './songInfo.types'
import { SongsResponse } from './songs.types'
import { apiClient } from '../../apiClient'

class SongsApi {
  api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getPlaylist = async (userId: string): Promise<PlaylistResponse> => {
    const response = await this.api.get<PlaylistResponse>(
      `/expresso/mandir/api/playlist/getPlayList.php?userId=${userId}`,
    )
    return response?.data
  }
  getSongs = async (playlistId: string): Promise<SongsResponse> => {
    const response = await this.api.get<SongsResponse>(
      `/expresso/mandir/api/songs/getSongs.php?playlist_id=${playlistId}`,
    )
    return response?.data
  }
  getSongInfo = async (songId: string): Promise<SongInfoRes> => {
    const response = await this.api.get<SongInfoRes>(
      `/expresso/mandir/api/songs/songInfo.php?audioId=${songId}`,
    )
    return response?.data
  }
}

export default SongsApi
