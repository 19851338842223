import { useRef } from 'react'
import { Button, Modal, Row, message } from 'antd'
import QRCode from 'react-qr-code'
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons'
import { logo } from './qrLogoBase64'

interface Props {
    showModal: boolean
    handleOk: () => void
    handleCancelQrModal: () => void
    value: string
}
const QRPreviewModal: React.FC<Props> = ({
    showModal,
    handleOk,
    handleCancelQrModal,
    value
}) => {
    const qrCodeRef = useRef<any>(null)

    const handleDownloadClick = () => {
        const svgElement = qrCodeRef.current.querySelector('svg')
        const svgData = new XMLSerializer().serializeToString(svgElement)

        const canvas = document.createElement('canvas')
        const ctx: any = canvas.getContext('2d')

        const img = new Image()
        img.onload = () => {
            var qrLogo = new Image()
            qrLogo.src = logo?.url
            qrLogo.onload = () => {
                canvas.width = img.width
                canvas.height = img.height
                ctx.drawImage(img, 0, 0)
                ctx.drawImage(qrLogo, 206, 206, 100, 100)

                const url = canvas.toDataURL('image/png')
                const a = document.createElement('a')
                a.href = url;
                a.download = 'qrcode.png'
                a.click()
            }
        }

        img.src = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgData)
    }

    const copyClipBoard = () => {
        var copyText: any = document.getElementById("deeplink")
        copyText.select()
        copyText.setSelectionRange(0, 99999)
        navigator.clipboard.writeText(copyText.value)
        message.success('Deeplink Copied Succesfully')
    }

    return (
        <Modal
            title="QR Code Preview"
            visible={showModal}
            onOk={handleCancelQrModal}
            onCancel={handleCancelQrModal}
            width={1000}
        >
            <Row style={{ width: '100%', alignItems: 'end' }} justify='space-between'>
                <div style={{ position: 'relative', display: 'inline-block' }} ref={qrCodeRef}>
                    <img id='qr-logo' src='https://srm-sbox-cdn.a4b.io/yoda/1695288532679.svg' alt="Logo" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1' }} />
                    <QRCode value={value} size={512} />
                </div>
                <input style={{ display: 'none' }} id='deeplink' type='text' value={value}></input>
                <Button onClick={copyClipBoard} icon={<CopyOutlined />} size='large'>
                    Copy Deeplink
                </Button>
                <Button onClick={handleDownloadClick} type="primary" icon={<DownloadOutlined />} size='large'>
                    Download
                </Button>
            </Row>
        </Modal>
    )
}

export default QRPreviewModal