import { Col, Form, Modal, Radio, Row } from 'antd'
import S3Upload, { AllowedFormats } from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'

interface Props {
    visibleMediaUpload: boolean
    handleOk: () => void
    handleCancel: () => void
    mediaUploadForm: any
}
const UploadMediaModal: React.FC<Props> = ({
    visibleMediaUpload,
    handleOk,
    handleCancel,
    mediaUploadForm
}) => {
    return (
        <Modal
            title="Media Upload"
            visible={visibleMediaUpload}
            onOk={handleOk}
            okText='Submit'
            onCancel={handleCancel}
            width="45vw"
        >
            <Form form={mediaUploadForm}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label='Order Media Type'
                            name='order_media_type'
                            rules={[
                                {
                                    required: false,
                                    message:
                                        'Please select order media type!',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={'video'}>Video</Radio>
                                <Radio value={'image'}>Image</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.order_media_type !==
                                currentValues.order_media_type
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('order_media_type') ===
                                'video' && (
                                    <Form.Item
                                        label='Order Media'
                                        name='order_media'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        valuePropName='fileList'
                                        rules={[
                                            {
                                                min: 0,
                                                max: 15,
                                                validator: async (
                                                    rules: any,
                                                    values: any,
                                                ) => {
                                                    if (
                                                        values?.length < rules.min
                                                    ) {
                                                        return Promise.reject(
                                                            'Please upload MP4 media url',
                                                        )
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        <S3Upload
                                            cdnUrl={YODA_CDN_URL}
                                            preSignUrl={YODA_PRESIGN_URL}
                                            allowedFileExtension={[
                                                AllowedFormats.MP4,
                                            ]}
                                            min={0}
                                            max={15}
                                        />
                                    </Form.Item>
                                )
                            }
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.order_media_type !==
                                currentValues.order_media_type
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('order_media_type') ===
                                'image' && (
                                    <Form.Item
                                        label='Order Media'
                                        name='order_media'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        valuePropName='fileList'
                                        rules={[
                                            {
                                                min: 0,
                                                max: 15,
                                                validator: async (
                                                    rules: any,
                                                    values: any,
                                                ) => {
                                                    if (
                                                        values?.length < rules.min
                                                    ) {
                                                        return Promise.reject(
                                                            'Please upload image ',
                                                        )
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        <S3Upload
                                            cdnUrl={YODA_CDN_URL}
                                            preSignUrl={YODA_PRESIGN_URL}
                                            allowedFileExtension={[
                                                AllowedFormats.PNG,
                                                AllowedFormats.JPEG,
                                                AllowedFormats.WEBP
                                            ]}
                                            min={0}
                                            max={15}
                                        />
                                    </Form.Item>
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default UploadMediaModal
