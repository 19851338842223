import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import CreateUpdateFunctionality from "../components/CreateUpdateFunctionality";
import { Functionality } from "@a4b/api/src/modules/ShareText/types";


export enum DRAWER_TYPE {
    CREATE_FUNCTIONALITY = 'Create Functionality',
    EDIT_FUNCTIONALITY = 'Edit functionality'
}


export default function useDrawerConfig(reloadShareFunctionality: () => void, activeDrawer?: DRAWER_TYPE, editFunctionality?: Functionality | null): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {
    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_FUNCTIONALITY,
                width: 1000,
            },
            drawerContent: <CreateUpdateFunctionality reloadShareFunctionality={reloadShareFunctionality}></CreateUpdateFunctionality>
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.EDIT_FUNCTIONALITY,
                width: 1000,
            },
            drawerContent: <CreateUpdateFunctionality editFunctionality={editFunctionality} reloadShareFunctionality={reloadShareFunctionality}></CreateUpdateFunctionality>
        }
    ], [editFunctionality, reloadShareFunctionality])
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}