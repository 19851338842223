import { AxiosRequestConfig } from 'axios'
import CalendarApi from './modules/Calendar'
import ContentApi from './modules/Content'
import LocationApi from './modules/Location'
import AuthApi from './modules/Login/Auth'
import MandirApi from './modules/Mandir'
import MonetisationOfferingsApi from './modules/Monetisation/Offerings'
import MonetisationPujaApi from './modules/Monetisation/Puja'
import MonetisationAstroApi from './modules/Monetisation/Astrologer'
import MandaliApi from './modules/Janmashtami/Mandali'
import CommunityApi from './modules/Social/Community'
import ShrineApi from './modules/Temple/Shrine'
import SongsApi from './modules/Songs'
import BitbucketApi from './modules/Bitbucket'
import LanguagesApi from './modules/System/Languages'
import ShareText from './modules/ShareText'
import SuggestiveCardApi from './modules/SuggestionCards/SuggestionCards'
import Atom from './modules/Atom/Auth'
import InAppNudgeApi from './modules/InAppNudge/InAppNudge'
import EventsApi from './modules/Events/Events'
import DynamicCohort from './modules/DynamicCohort/DynamicCohort'
import Gita from './modules/Gita/Gita'
import Coins from './modules/Coins/Coins'
import WalletRechargePackages from './modules/Wallet/Wallet';
import AdminControl from './modules/AdminControl/AdminControl';
import Riddler from './modules/Riddler';
import Carnage from './modules/Carnage';
import Feeds from './modules/Feeds';
import UserApi from './modules/Monetisation/Offerings/UserApi'
import CommunityOldApi from './modules/Social/CommunityOld'
import AbExperiments from './modules/AbExperiments/AbExperiments'
import TemplesApi from './modules/Monetisation/Offerings/TemplesApi'

export type {
  FeedPageCardData
} from './modules/PujaWeb/PujaWebTypes'
export type {
  Category
} from './modules/Articles/types'
export type {
  GenerateOTPOptions,
  GenerateOTPResponse,
  SignUpOptions,
  SignUpResponse,
  UserSummary,
} from './modules/Login/types'

export type {
  CommunityTag,
  Community,
  CommunityAuthor,
} from './modules/Social/types'

export type {
  ContentTagCreateOptions,
  ContentTagQueryParams,
  ContentTag,
  CreateLibraryContentOptions,
  LibraryContent,
  SupportedPlatforms,
  SupportedContentTypes,
  ContentStatus,
  LibraryMediaType,
  SupportedContentFormats,
  CreateScheduleOptions,
  LibraryContentQueryParams,
  ScheduleData,
  BulkCloneTagContentScheduleOptions
} from './modules/Content/Library/types'

export type {
  AudioTagQueryParams,
  AudioTag,
  AudioData,
  AudioPlaylist,
  AudioPlaylistCreateUpdateOptions,
  AudioThumbnail
} from './modules/Content/Audio/types'

export type {
  TempleList,
  Temple,
  TempleDetails,
  PaymentDetails,
  BankDetails,
  SocialMedia,
  Timing,
  SocialMedia2,
  Faq,
  TravelDetails,
  TempleCommittee,
  TempleCommitteeMember
} from './modules/Content/Temples/types'

export type {
  UserUploadedGodData
} from './modules/Mandir/god.types'

export type { AxiosError } from 'axios'

export enum ScheduleType {
  TAG_SCHEDULE = 'tags',
  TAG_FEATURED_SCHEDULE = 'featuredTags',
  TAG_CONTENT_SCHEDULE = 'library',
}

export class A4BApi {
  communityApi: CommunityApi
  communityOldApi: CommunityOldApi
  authApi: AuthApi
  songsApi: SongsApi
  calendarApi: CalendarApi
  mandirApi: MandirApi
  locationApi: LocationApi
  monetisationOfferingsApi: MonetisationOfferingsApi
  monetisationPujaApi: MonetisationPujaApi
  monetisationAstroPujaApi: MonetisationPujaApi
  monetisationAstroApi: MonetisationAstroApi
  monetisationNewAstroApi: MonetisationAstroApi
  contentApi: ContentApi
  mandaliApi: MandaliApi
  shrineApi: ShrineApi
  bitbucketApi: BitbucketApi
  languagesApi: LanguagesApi
  shareText: ShareText
  suggestiveCards: SuggestiveCardApi
  atom: Atom
  inAppNudge: InAppNudgeApi
  events: EventsApi
  dynamicCohort: DynamicCohort
  gita: Gita
  coins: Coins
  abExperiments: AbExperiments
  wallet: WalletRechargePackages
  walletAstro: WalletRechargePackages
  adminControl: AdminControl
  riddler: Riddler
  carnage: Carnage
  feeds: Feeds
  userApi: UserApi
  templesApi: TemplesApi

  constructor(
    config: AxiosRequestConfig,
    monetisationExtraHeaders?: {
      "Accept-Language": string;
    },
    bitBucketConfig?: AxiosRequestConfig
  ) {
    this.communityApi = new CommunityApi(config)
    this.shrineApi = new ShrineApi(config)
    this.communityOldApi = new CommunityOldApi(config)
    this.suggestiveCards = new SuggestiveCardApi(config)
    this.authApi = new AuthApi(config)
    this.songsApi = new SongsApi(config)
    this.calendarApi = new CalendarApi(config)
    this.mandirApi = new MandirApi(config)
    this.locationApi = new LocationApi(config)
    this.shareText = new ShareText(config)
    this.monetisationOfferingsApi = new MonetisationOfferingsApi(config, monetisationExtraHeaders)
    this.monetisationPujaApi = new MonetisationPujaApi(config, monetisationExtraHeaders)
    this.monetisationAstroPujaApi = new MonetisationPujaApi(config, monetisationExtraHeaders, { 'x-afb-app-name': 'astro' })
    this.monetisationAstroApi = new MonetisationAstroApi(config, monetisationExtraHeaders)
    this.monetisationNewAstroApi = new MonetisationAstroApi(config, monetisationExtraHeaders, { 'x-afb-app-name': 'astro' })
    this.contentApi = new ContentApi(config)
    this.mandaliApi = new MandaliApi(config)
    this.bitbucketApi = new BitbucketApi(bitBucketConfig || {})
    this.languagesApi = new LanguagesApi(config)
    this.atom = new Atom(config)
    this.inAppNudge = new InAppNudgeApi(config)
    this.events = new EventsApi(config)
    this.dynamicCohort = new DynamicCohort(config)
    this.gita = new Gita(config)
    this.coins = new Coins(config)
    this.abExperiments = new AbExperiments(config)
    this.wallet = new WalletRechargePackages(config)
    this.walletAstro = new WalletRechargePackages(config, { 'x-afb-app-name': 'astro' })
    this.adminControl = new AdminControl(config)
    this.riddler = new Riddler(config)
    this.carnage = new Carnage(config)
    this.feeds = new Feeds(config);
    this.userApi = new UserApi(config)
    this.templesApi = new TemplesApi(config)
  }
}

export default A4BApi
