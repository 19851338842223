import { FormSelect } from "@/components/Forms/Select";
import { SUPPORTED_LANGUAGES_LIST } from "@/utils/constants";
import { DatePicker } from "antd";
import moment from "moment";

interface FiltersProps {
  languageCode: string;
  setLanguageCode: (languageCode: string) => void;
  setDate: (date: string) => void;
  date: string
}
export default function Filters(props: FiltersProps) {
  const { languageCode, setLanguageCode, setDate, date } = props;

  return <div style={{ display: 'flex', gap: '20px' }}>
    <FormSelect
      onChange={setLanguageCode}
      selected={languageCode}
      list={SUPPORTED_LANGUAGES_LIST.map((language) => ({ lable: `${language.name} (${language.alt_name})`, value: language.value }))}
    />
    <DatePicker value={moment(date)} onChange={(date) => { if (date) setDate(date.format('YYYY-MM-DD')) }} />
  </div>
} 