import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import AntdFormWrapper from "../../../../../components/Forms/AntdFormWrapper";
import { FormInstance } from "antd";

// This interface is just a example for AntdFormWrapper 
interface TestComProps {
    form: FormInstance<string>
}

const TestCom = ({ form }: TestComProps) => <div> CREATE SOMETHING 2 DATA </div>
export enum DRAWER_TYPE {
    CREATE_SOMETHING1 = 'Create Something 1',
    CREATE_SOMETHING2 = 'Create Something 2'
}



export default function useDrawerConfig(activeDrawer?: DRAWER_TYPE): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {

    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_SOMETHING1,
                width: 1000,
            },
            drawerContent: <AntdFormWrapper<string, string> FormFields={TestCom} />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_SOMETHING2,
                width: 900,
            },
            drawerContent: <AntdFormWrapper<string, string> FormFields={TestCom} />
        }
    ], [])
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}