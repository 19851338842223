
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination, { DEFAULT_PAGINATION_CONFIG } from "@/hooks/useAntdPagination";
import { Functionality, Sharetext } from "@a4b/api/src/modules/ShareText/types";
import { useSearchParams, useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { SHARE_TEXT_CONFIG } from "../helpers/config";
import { debounce } from "lodash";



const ShareTextListing = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const { networkInstance } = useAppContext();
  const { pagination, setPagination } = useAntdPagination();
  const [shareTexts, setShareTexts] = useState<Sharetext[] | null>(null);
  const [editShareText, setEditShareText] = useState<Sharetext>();
  const [cloneShareText, setCloneShareText] = useState<Sharetext>();
  const [searchParams] = useSearchParams();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(searchParams.get('language') || '');
  const [shareFunctionlities, setShareFunctionalities] = useState<Functionality[] | null>()
  const [selectedShareFunctionlityCode, setSelectedShareFunctionality] = useState<string>(searchParams.get('selectedShareFunctionlity') || '')
  const [identifier, setIdentifier] = useState<string>(searchParams.get('identifier') || '')

  const updateSelectedShareFunctionality = (val: string) => {
    setSelectedShareFunctionality(val);
  }

  const updateIdentifier = (val: string) => {
    setIdentifier(val);
  }

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        language: selectedLanguage,
        selectedShareFunctionlity: selectedShareFunctionlityCode,
        identifier: identifier
      }).toString()
    });
  }, [identifier, location.pathname, navigate, selectedLanguage, selectedShareFunctionlityCode])


  const onChangeLanguage = useCallback((val: string) => {
    setSelectedLanguage(val);
  }, [])

  const getShareFunctionalities = useCallback(async (functionalityText: string, subFunctionalityText: string) => {
    try {
      const res = await networkInstance.clientWithHeaders.shareText.searchShareFunctionalities(20, 0, { functionality_text: functionalityText, sub_functionality_text: subFunctionalityText });
      setShareFunctionalities(res.data.data.functionalities)
    } catch (error) {
    }
  }, [networkInstance.clientWithHeaders.shareText])

  const getShareFunctionalitiesDebounce = useMemo(() => debounce(getShareFunctionalities, 1000), [getShareFunctionalities])



  const getShareTexts = useCallback(async (selectedLanguage: string, selectedShareFunctionlityCode: string, identifier: string) => {
    try {
      const res = await networkInstance.clientWithHeaders.shareText.getShareTexts((pagination.pageSize) || 50, ((pagination.current || 1) - 1) * (pagination.pageSize || 50), selectedLanguage, selectedShareFunctionlityCode, identifier);
      setShareTexts(res.data.data.share_texts)
      if (pagination.total !== res.data.data.count) {
        setPagination({ ...DEFAULT_PAGINATION_CONFIG, total: res.data.data.count })
      }
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.shareText, pagination, setPagination])

  const reloadShareText = useCallback(async () => {
    getShareTexts(selectedLanguage, selectedShareFunctionlityCode, identifier);
  }, [getShareTexts, identifier, selectedLanguage, selectedShareFunctionlityCode])

  const drawerConfig = useDrawerConfig(reloadShareText, activeDrawer, editShareText, cloneShareText, selectedShareFunctionlityCode);
  const { drawerProps, drawerContent } = drawerConfig;

  useEffect(() => {
    getShareFunctionalitiesDebounce('', '');
    reloadShareText()
  }, [getShareFunctionalitiesDebounce, reloadShareText])


  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }

  const onEditShareText = useCallback((record: Sharetext) => {
    setEditShareText(record);
    setActiveDrawer(DRAWER_TYPE.UPDATE_SHARE_TEXT);
  }, [])

  const onCloneShareText = useCallback((record: Sharetext) => {
    setCloneShareText(record);
    setActiveDrawer(DRAWER_TYPE.CREATE_SHATE_TEXT_WITH_CLONE);
  }, [])


  const tableConfig = useTableConfig(shareTexts || [], pagination, setPagination, setDrawer, onEditShareText, onCloneShareText);


  const onCreateSharetext = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_SHATE_TEXT);
  }

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  }

  return <SocialScreenContainer
    title={"Share text"}
    filtersUI={<div>
      <Filters
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={onChangeLanguage}
        shareFunctionlities={shareFunctionlities}
        updateSelectedShareFunctionality={updateSelectedShareFunctionality}
        selectedShareFunctionlityCode={selectedShareFunctionlityCode}
        getShareFunctionalitiesDebounce={getShareFunctionalitiesDebounce}
        identifier={identifier}
        updateIdentifier={updateIdentifier}
        reloadShareText={reloadShareText}
      />
      {
        selectedLanguage.length === 0 &&
        identifier.length === 0 && selectedShareFunctionlityCode.length !== 0 &&
        <Button type="primary" onClick={() => { setActiveDrawer(DRAWER_TYPE.CREATE_SHATE_TEXT_WITH_DEFAULT_FUNCTIONALITY) }}> Create share text for selected functionality ?</Button>
      }
    </div>}
    extra={<Button onClick={onCreateSharetext}> Create share text </Button>}
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />

};

export default withDefaultLayout(ShareTextListing)