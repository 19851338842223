export const deliveryOrderPayload = (data: any, batchId: string) => {
  let formattedTimeIso
  const pickupTime = new Date(data?.pickup_info?.pickup_time)
  let processedPickupTime
  if (isNaN(pickupTime.getTime())) {
    processedPickupTime = new Date()
  } else {
    processedPickupTime = new Date(data?.pickup_info?.pickup_time)
  }
  formattedTimeIso = processedPickupTime.toISOString()
  let payload: any = {
    ...data,
    pickup_info: {
      ...data.pickup_info,
      phone: data?.pickup_info?.phone?.toString() || '',
      email: data?.pickup_info?.email || '',
      pincode: data?.pickup_info?.pincode?.toString() || '',
      pickup_time: formattedTimeIso,
    },
    batch_id: batchId,
  }
  return payload
}
