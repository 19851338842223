import { Table } from "antd";
import { getReadableWidgetName } from "@/utils/helper";

interface Props {
  widget: any;
}
const Widget = ({ widget }: Props) => {
  const getdescription = (record: any) => {
    return (
      <div style={{ paddingLeft: "48px" }}>
        <b>Widget Descrption : </b>
        {record.description}
      </div>
    );
  };
  const dataColumn = [
    {
      title: "ID (Widget)",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "30%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      ellipsis: true,
      width: "20%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Widget Type",
      dataIndex: "widget_type",
      key: "widget_type",
      ellipsis: true,
      width: "20%",
      render: (text: any, record: any, index: any) => {
        return <div>{getReadableWidgetName(text)}</div>;
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      ellipsis: true,
      width: "15%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      ellipsis: true,
      width: "15%",
      render: (text: any, record: any, index: any) => {
        return <span>{text === true ? "Yes" : "No"}</span>;
      },
    },
  ];
  return (
    <Table
      columns={dataColumn}
      dataSource={[widget]}
      expandable={{
        expandedRowRender: getdescription,
        expandRowByClick: true,
      }}
      pagination={false}
    ></Table>
  );
};

export default Widget;
