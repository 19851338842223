import { LibraryMediaType, SupportedContentFormats } from '@a4b/api'
import { RcFile } from 'antd/lib/upload'
import type { Editor, Position } from 'codemirror'
//type
import SimpleMDE from 'easymde'
import 'easymde/dist/easymde.min.css'
import * as Marked from 'marked'

import React, { useCallback, useMemo, useState } from 'react'
import SimpleMdeReact from 'react-simplemde-editor'
import { ImageFormFields, ImageMarkdownPopup } from '../ImageMarkdownPopup'

import { VideoFormFields, VideoMarkdownPopup } from '../VideoMarkdownPopup'
import {
  YoutubeFormFields,
  YoutubeMarkdownPopup
} from '../YoutubeMarkdownPopup'

export interface Props {
  value?: string
  onChange?: (value: string) => void
  uploadUrl: string
  cdnHost: string,
  disabled?: boolean,
  imageUploadFunction: (
    media_type: LibraryMediaType,
    fileFormat: SupportedContentFormats,
    file: RcFile,
  ) => Promise<string>
}

const defaultToolbarOptions = [
  'bold',
  'italic',
  'quote',
  'unordered-list',
  'ordered-list',
  'link',
  'strikethrough',
  'table',
  'heading',
  'horizontal-rule',
  'preview',
  'side-by-side',
  'fullscreen',
  'guide',
]

export const MarkdownEditor: React.FC<Props> = ({
  value,
  onChange,
  uploadUrl,
  cdnHost,
  disabled = false,
  imageUploadFunction
}) => {
  const [simpleMdeInstance, setMdeInstance] = useState<SimpleMDE | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [codemirrorInstance, setCodemirrorInstance] = useState<Editor | null>(
    null,
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lineAndCursor, setLineAndCursor] = useState<Position | null>(null)
  const [showYoutubePopup, setShowYoutubePopup] = useState(false)
  const [showVideoPopup, setShowVideoPopup] = useState(false)
  const [showImagePopup, setShowImagePopup] = useState(false)

  const getMdeInstanceCallback = useCallback(
    (simpleMde: SimpleMDE) => {
      if (disabled) {
        simpleMde.codemirror.setOption('readOnly', true)
        const toolbar = document.querySelector('.editor-toolbar')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        toolbar.style.display = 'none'
      }
      setMdeInstance(simpleMde)
    },
    [disabled],
  )

  const getCmInstanceCallback = useCallback((editor: Editor) => {
    setCodemirrorInstance(editor)
  }, [])

  const getLineAndCursorCallback = useCallback((position: Position) => {
    setLineAndCursor(position)
  }, [])

  const getOptions = useMemo(() => {
    return {
      autofocus: true,
      spellChecker: false,
      previewRender: function (text) {
        const renderer = new Marked.Renderer()
        return Marked.parse(text, { renderer })
      },
      toolbar: [
        ...defaultToolbarOptions,
        {
          name: 'youtube',
          action: () => {
            setShowYoutubePopup(true)
          },
          className: 'fa fa-youtube',
          text: 'Youtube',
        },
        {
          name: 'video',
          action: () => {
            setShowVideoPopup(true)
          },
          className: 'fa fa-camera',
          text: 'Video',
        },
        {
          name: 'image',
          action: editor => {
            setShowImagePopup(true)
          },
          className: 'fa fa-image',
          text: 'Image',
        }
      ],
    } as SimpleMDE.Options
  }, [])

  const onYoutubeLinkAdded = (values: YoutubeFormFields) => {
    setShowYoutubePopup(false)
    const currentValue = simpleMdeInstance?.value()
    const mdText = `[<img src="${cdnHost}/${values.thumbnail[0].response?.data.content.s3_path}" width="100%">](${values.link})`

    const position = codemirrorInstance?.getCursor()
    if (position) {
      codemirrorInstance?.setSelection(position, position);
      const insertText = mdText
      codemirrorInstance?.replaceSelection(insertText);
    } else {
      simpleMdeInstance?.value(
        currentValue + mdText,
      )
    }
  }

  const onVideoLinkAdded = (values: VideoFormFields) => {
    setShowVideoPopup(false)
    const currentValue = simpleMdeInstance?.value()
    const thumbnail = `${cdnHost}/${values.thumbnail[0].response?.data.content.s3_path}`
    const videoLink = `${cdnHost}/${values.videoFile[0].response?.data.content.s3_path}`
    const mdText = `[<img src="${thumbnail}" width="100%">](${videoLink})`

    const position = codemirrorInstance?.getCursor()
    if (position) {
      codemirrorInstance?.setSelection(position, position);
      const insertText = mdText
      codemirrorInstance?.replaceSelection(insertText);
    } else {
      simpleMdeInstance?.value(
        currentValue + mdText,
      )
    }
  }

  const onImageLinksAdded = (values: ImageFormFields) => {
    setShowImagePopup(false)
    const currentValue = simpleMdeInstance?.value()
    const imageLinks = values.images.map((image) => {
      const imageString = `${cdnHost}/images/library/${image.response}`
      return `<img src="${imageString}" width="100%">`
    })
    const mdText = imageLinks.join('')
    const position = codemirrorInstance?.getCursor()
    if (position) {
      codemirrorInstance?.setSelection(position, position);
      const insertText = mdText
      codemirrorInstance?.replaceSelection(insertText);
    } else {
      simpleMdeInstance?.value(
        currentValue + mdText
      )
    }
  }

  const onImagePopupClosed = () => {
    setShowImagePopup(false)
  }

  const onYoutubePopupClosed = () => {
    setShowYoutubePopup(false)
  }

  const onVideoPopupClosed = () => {
    setShowVideoPopup(false)
  }

  return (
    <div>
      <SimpleMdeReact
        value={value}
        onChange={onChange}
        options={getOptions}
        getMdeInstance={getMdeInstanceCallback}
        getCodemirrorInstance={getCmInstanceCallback}
        getLineAndCursor={getLineAndCursorCallback}
      />
      <ImageMarkdownPopup
        showModal={showImagePopup}
        onClose={onImagePopupClosed}
        uploadFunction={imageUploadFunction}
        onSubmit={onImageLinksAdded}
      />
      <YoutubeMarkdownPopup
        showModal={showYoutubePopup}
        onClose={onYoutubePopupClosed}
        uploadUrl={uploadUrl}
        onSubmit={onYoutubeLinkAdded}
      />
      <VideoMarkdownPopup
        showModal={showVideoPopup}
        onClose={onVideoPopupClosed}
        uploadUrl={uploadUrl}
        onSubmit={onVideoLinkAdded}
      />
    </div>
  )
}
