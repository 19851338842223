import { useAppContext } from '@/components/AppContext';
import { AttachmentServiceResponse } from '@/modules/social/types';
import { Alert, Button, Modal, Upload, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import React, { useEffect, useState } from 'react';
enum ALERT_TYPE {
    ERROR = 'error',
    SUCCESS = 'success'
}
const CsvUploadAudio: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState<{ message: string, type: ALERT_TYPE }>();
    const [open, setOpen] = useState(false);
    const [csvUploadPaths, setCsvUploadPaths] = useState<{ url: string, path: string }>();
    const [file, setFile] = useState<UploadFile<AttachmentServiceResponse>>();
    const { networkInstance } = useAppContext();


    useEffect(() => {
        if (open) {
            (async () => {
                const uploadUrlRes = await networkInstance.clientWithHeaders.contentApi.audio.getUrlToUploadCSV();
                setCsvUploadPaths(uploadUrlRes.data.data)
                setLoading(false);
                setFile(undefined);
            })()
        }
    }, [networkInstance.clientWithHeaders.contentApi.audio, open])

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleUploadChange = async (info: UploadChangeParam<UploadFile<AttachmentServiceResponse>>) => {
        if (info.file.status === 'done') {
            setAlert({ message: 'Csv updated successfully', type: ALERT_TYPE.SUCCESS })
            setTimeout(() => {
                setAlert(undefined);
            }, 3000)
        }
        setFile(info.file)
    }
    return (
        <div>
            <Button type="primary" onClick={showModal}>
                Upload Csv
            </Button>
            {
                open && <Modal
                    visible={open}
                    title="Upload CSV"
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            Cancel
                        </Button>
                    ]}
                >
                    {alert && <Alert message={alert?.message} type={alert?.type} />}
                    <p>
                        * Miximum CSV rows allowed is 500
                    </p>
                    {
                        loading ? 'Loading upload config...' : <Upload.Dragger
                            maxCount={1}
                            // disabled={file ? true : false}
                            // fileList={file ? [file] : undefined}
                            multiple={false}
                            // action={csvUploadPaths?.url}
                            customRequest={async (options: any) => {


                                const headers = new Headers()
                                headers.append('ContentType', options.file.type)
                                try {
                                    if (csvUploadPaths) {
                                        await fetch(csvUploadPaths?.url, {
                                            method: 'PUT',
                                            body: options.file,
                                            headers,
                                        })
                                        await networkInstance.clientWithHeaders.contentApi.audio.uploadCsvPath({ path: csvUploadPaths?.path })
                                        return options.onSuccess('Uploaded')
                                    }
                                } catch (error: any) {
                                    let errorMsg = error.message + '\n\n'
                                    if (error.response.data.error) {
                                        errorMsg += JSON.stringify(error.response.data.error)
                                    }
                                    setAlert({ message: errorMsg, type: ALERT_TYPE.ERROR })
                                    setTimeout(() => {
                                        setAlert(undefined);
                                    }, 5000)
                                }
                            }
                            }
                            method='PUT'
                            accept='text/csv; charset=utf-8'
                            // data={() => getDataForUpload(mediaFormat)}
                            name='input_file'
                            onChange={handleUploadChange}
                        >
                            + upload
                        </Upload.Dragger>
                    }

                </Modal>
            }

        </div>
    );
};

export default CsvUploadAudio;