import React, { useMemo } from 'react';
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'antd/dist/antd.css';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { AudioTag } from '@a4b/api/src/modules/Content/Audio/types';
import { Avatar, Button, Checkbox, Col, DatePicker, Form, Input, Radio, RadioChangeEvent, Row, Select, Space, Typography, Upload } from 'antd';
import { Community, CommunityAuthor, CommunityCategory, CommunityMessage, CommunityTags, CreateMessagePayload } from '@/../../../packages/a4b-api/src/modules/Social/types';
import { SocialScreenContainer } from '@a4b/ui/src/admin';
import { openNotification } from '@/utils';
import { MessageType } from '@/modules/social/types/message';
import moment, { Moment } from 'moment';
import { title } from 'process';
import communities from '../communities';
import { SendMessageFormFields } from '@/ui/organisms/Message/Send';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid'
import { UPLOAD_CONTENT_URL } from '@/utils/constants';
import { range } from 'lodash'
import { MessageHeaderCard } from '@a4b/ui/src/modules/admin/components/Social1.5/MessageHeaderCard';
import { MessageListCard } from '@a4b/ui/src/modules/admin/components/Social1.5/MessageListCard';






type MediaFormat = 'IMAGE' | 'AUDIO' | 'VIDEO'


const defaultPagination = {
  current: 1,
  pageSize: 20,
  total: 2000
}


function getDisabledDate(selected: Moment) {
  return selected && selected < moment().startOf('day')
}

const UploadButton = () => (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
)


// const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
//   notification[type]({
//     message: title,
//     description
//   });
// };

const { Option } = Select;

function getDisabledDateTime(selected: Moment) {
  const current = moment()
  if (selected && selected.date() === current.date()) {
    const currentHour = current.hour()
    const currentMinutes = current.minute() + 1
    if (selected.hour() === currentHour) {
      return {
        disabledHours: () => range(0, currentHour),
        disabledMinutes: () => range(0, currentMinutes),
      }
    } else {
      return {
        disabledHours: () => range(0, currentHour),
      }
    }
  } else {
    return
  }
}

const SocialMessages = () => {
  const navigate = useNavigate();
  let { id: communityId } = useParams();
  const { networkInstance, userProfile } = useAppContext();
  const [showDrawer, setShowDrawer] = useState(false);
  const [schedule, setSchedule] = useState('now');
  const [messages, setMessages] = useState<CommunityMessage[]>([]);
  const [showExtraFields, setShowExtraFields] = useState(true);
  const [mediaFormat, setMediaFormat] = useState<MediaFormat>('IMAGE')
  const [sending, setSending] = useState(false)
  const [admins, setAdmins] = useState<CommunityAuthor[]>([]);
  const [communities, setCommunities] = useState<Community[]>([])
  const [selectedCommunity, setSelectedCommunity] = useState<string>()
  const [communityTag, setCommunityTag] = useState<CommunityTags[]>([])

  const [pagination, setPagination] = useState(defaultPagination);
  const [form] = Form.useForm<CreateMessagePayload>()

  useEffect(() => {
    if (communityId && communities) {
      setSelectedCommunity(communityId);
    }
  }, [communities, communityId])

  const onFormValuesChanged = useCallback((
    changedValues: Partial<CreateMessagePayload>,
    values: CreateMessagePayload,
  ) => {
    // if (changedValues.community_id) {
    //   onCommunityChanged(changedValues.community_id)
    // }
    const messageType = values.type
    if (messageType === MessageType.ANNOUNCEMENT) {
      setShowExtraFields(false)
    } else if (messageType === MessageType.REGULAR) {
      setShowExtraFields(true)
    }
  }, [])

  const loadMessages = useCallback(async () => {
    if (!selectedCommunity) return;
    try {
      const res = await networkInstance.clientWithHeaders.communityApi.getCommunityMessages(selectedCommunity, pagination.pageSize, pagination.pageSize * (pagination.current - 1));
      setMessages(res.data.data.messages);
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.communityApi, pagination, selectedCommunity])

  const loadCommunities = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.communityApi.getCommunities();
      setCommunities(res.data.data.communities);
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.communityApi])


  const loadContentTags = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.communityApi.getContentTags();
      setCommunityTag(res.data.data.tags);
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.communityApi])


  const loadCommunityAuthors = useCallback(async (community_id: string) => {
    try {
      const res = await networkInstance.clientWithHeaders.communityApi.getCommunityAuthors(community_id);
      console.log(res);
      setAdmins(res.data.data.authors);
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.communityApi])

  useEffect(() => {
    loadCommunities();
  }, [loadCommunities])

  useEffect(() => {
    loadMessages();
  }, [loadMessages])

  useEffect(() => {
    loadContentTags();
  }, [loadContentTags])

  const handleDrawerClose = useCallback(() => {
    setShowDrawer(false);
  }, [setShowDrawer])

  const handleDrawerOpen = useCallback(() => {
    setShowDrawer(true);
  }, [setShowDrawer])

  const onScheduleOptionChanged = useCallback((e: RadioChangeEvent) => {
    setSchedule(e.target.value)
  }, [])

  const getDataForUpload = useCallback((mediaFormat: MediaFormat) => {
    const ref_content_code = `social_${uuidv4()}`
    const media_type = mediaFormat.toUpperCase()
    const video_source = mediaFormat === 'VIDEO' ? 'INTERNAL_CUSTOM' : undefined
    return {
      ref_content_code,
      media_type,
      video_source,
    }
  }, [])

  const normFile = useCallback((e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }, [])

  const createMessage = useCallback(async (values: CreateMessagePayload) => {
    console.log({ values })
    const payload: CreateMessagePayload = {
      community_id: values.community_id,
      type: values.type,
      text: values.text,
      //@ts-ignore
      attachments: values?.path?.map((a: any, index: number): { type: string, path: string } => {
        console.log({ a });
        return {
          type: mediaFormat,
          path: a.response.data.content.s3_path as string,
          //@ts-ignore
          thumbnail: mediaFormat !== 'IMAGE' ? values.thumbnail && values.thumbnail[index].response.data.content.s3_path : null
        }
      }),
      author_id: values.author_id,
      tag_ids: values.tag_ids,
      send_push_notification: !!values.send_push_notification,
      scheduled_for: new Date(values.scheduled_for).getTime(),
      send_now: schedule === 'now',
      deeplink: values.deeplink
    }

    try {
      await networkInstance.clientWithHeaders.communityApi.createMessage(payload)
      openNotification('success', { message: 'Message created successfully!' })
      form.resetFields();
      loadMessages();
    } catch (error: any) {
      openNotification('error', { message: 'Message creation failed!', description: error.toString(), })
    }
  }, [form, loadMessages, mediaFormat, networkInstance.clientWithHeaders.communityApi, schedule])

  const onMediaFormatOptionChanged = useCallback((e: RadioChangeEvent) => {
    setMediaFormat(e.target.value);
    form.setFieldValue('path', undefined)
  }, [form])



  const drawerContent = useMemo(() => {
    return <Form
      form={form}
      onFinish={createMessage}
      layout="vertical"
      onValuesChange={onFormValuesChanged}

    //  initialValues={{ requiredMarkValue: requiredMark }}
    //  onValuesChange={onRequiredTypeChange}
    //  requiredMark={requiredMark}
    >
      <Form.Item
        label='Community'
        name='community_id'
        rules={[{ required: true, message: 'Select a community' }]}
      >
        <Select disabled={false} onChange={(value) => {
          loadCommunityAuthors(value);
        }}>
          {communities && communities.map(
            ({ id, en_name, profile_attachment_url }, index) => (
              <Option key={id} value={id}>
                <Avatar
                  src={profile_attachment_url}
                  size={'small'}
                  style={{ marginRight: '8px' }}
                />
                {en_name}
              </Option>
            ),
          )}
        </Select>
      </Form.Item>
      <Form.Item
        label='Message Type'
        name='type'
        rules={[{ required: true, message: 'Select a message Type' }]}
        initialValue={MessageType.REGULAR}
      >
        <Radio.Group>
          <Radio value={MessageType.REGULAR}>Regular</Radio>
          {/* <Radio value={MessageType.ANNOUNCEMENT}>Announcement</Radio> */}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label='Text Message'
        name='text'
        rules={[{ required: true, message: 'Add a text message' }]}
      >
        <Input.TextArea />
      </Form.Item>
      {/* <Form.Item
        label='Deeplink'
        name='deeplink'
        rules={[{ required: true, message: 'Deeplink required' }]}
      >
        <Input />
      </Form.Item> */}
      {showExtraFields && (
        <>
          <Form.Item
            label='Tags'
            name='tag_ids'
            rules={[{ required: true, message: 'Please choose a tag' }]}
          >
            <Select mode='multiple'>
              {communityTag && communityTag.map((tag, index) => (
                <Option key={index} value={tag.name}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='Author'
            name='author_id'
            rules={[
              {
                required: true,
                message: 'Please choose a user to post it as',
              },
            ]}
          >
            <Select>
              {admins && admins.map(({ id, name, profile_image_url }, index) => (
                <Option key={id} value={id}>
                  <Avatar
                    src={profile_image_url}
                    size={'small'}
                    style={{ marginRight: '8px' }}
                  />
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Row style={{ marginBottom: '24px' }}>

            Media Format:

            <Col span={18} style={{ paddingLeft: '20px' }}>
              <Radio.Group
                onChange={onMediaFormatOptionChanged}
                value={mediaFormat}
              >
                <Radio value={'IMAGE'}>Image</Radio>
                <Radio value={'VIDEO'}>Video</Radio>
                <Radio value={'AUDIO'}>Audio</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
            <Form.Item
              label='Attachment'
              name={'path'}
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload.Dragger
                action={UPLOAD_CONTENT_URL}
                accept={`${mediaFormat}/*`}
                data={() => getDataForUpload(mediaFormat)}
                name='input_file'
                listType='picture'
                multiple={false}
              >
                <UploadButton />
              </Upload.Dragger>
            </Form.Item>
            {
              mediaFormat !== 'IMAGE' &&
              <Form.Item
                label='Thumbnail'
                name={'thumbnail'}
                valuePropName='fileList'
                getValueFromEvent={normFile}
              >
                <Upload.Dragger
                  action={UPLOAD_CONTENT_URL}
                  accept={`image/*`}
                  data={() => getDataForUpload(mediaFormat)}
                  name='input_file'
                  listType='picture'
                  multiple={false}
                >
                  <UploadButton />
                </Upload.Dragger>
              </Form.Item>
            }
          </Space>

          <Row style={{ marginBottom: '24px' }}>

            Schedule:
            <Col span={18} style={{ paddingLeft: '20px' }}>
              <Radio.Group
                onChange={onScheduleOptionChanged}
                value={schedule}
              >
                <Radio value={'now'}> Now</Radio>
                <Radio value={'later'}>Later</Radio>
              </Radio.Group>
            </Col>
          </Row>
          {schedule === 'later' && (
            <Form.Item label='Schedule' name='scheduled_for'>
              <DatePicker
                showToday
                format='MMMM Do YYYY, h:mm A'
                disabledDate={getDisabledDate}
                //@ts-ignore
                disabledTime={getDisabledDateTime}
                showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
              />
            </Form.Item>
          )}
        </>
      )}
      <Form.Item
        label='Send notification'
        name='send_push_notification'
        valuePropName='checked'
      >
        <Checkbox style={{ width: '20px' }} />
      </Form.Item>

      <Button type='primary' htmlType='submit' loading={sending}>
        {form.getFieldValue('type') === MessageType.REGULAR ? (
          schedule === 'now' ? (
            <span>Send Now</span>
          ) : (
            <span>Send Later</span>
          )
        ) : (
          <span>Send</span>
        )}
      </Button>
    </Form>
  }, [admins, communities, communityTag, createMessage, form, getDataForUpload, loadCommunityAuthors, mediaFormat, normFile, onFormValuesChanged, onMediaFormatOptionChanged, onScheduleOptionChanged, schedule, sending, showExtraFields])

  const columns = useMemo(() => [
    {
      title: 'Text',
      dataIndex: 'text',
    },
    {
      title: 'Has Attachment',
      dataIndex: 'has_attachment',
    },
    {
      title: 'Message Status',
      dataIndex: 'schedule_status',
    },
    {
      title: 'Message scheduled for ',
      dataIndex: 'scheduled_for'
    },

    {
      title: 'Posted on ',
      dataIndex: 'posted_on'
    },
    {
      title: 'Send Push Notification ',
      dataIndex: 'send_push_notification'
    },
    {
      title: 'Action',
      dataIndex: 'code',
      render: () => <Button type="link"> Preview </Button>
    },
  ], []);


  // const content = useMemo(() => {
  //   return <div>
  //     <MessageHeaderCard></MessageHeaderCard>
  //     {
  //       messages.map((message) => {
  //         return <MessageListCard></MessageListCard>
  //       })
  //     }
  //   </div>
  // }, [messages]) 

  const filtersUI = useMemo(() => {
    return <div>
      {!selectedCommunity && <p style={{ color: 'red' }}> Please select a community </p>}
      <Form.Item>
        <Select onChange={(communityId) => {
          setSelectedCommunity(communityId);
        }} value={selectedCommunity}>
          {
            communities.map((community: Community) => {
              return <Option value={community.id}> {community.local_name}</Option>
            })
          }
        </Select>
      </Form.Item>
    </div>
  }, [communities, selectedCommunity])


  const customPagination = useMemo(() => {
    return <div>
      <Button type="link" onClick={() => {
        const paginationCopy = { ...pagination };
        paginationCopy.current = Math.max(1, paginationCopy.current - 1)
        setPagination(paginationCopy);
      }}> Prev </Button>
      <span> Page no {pagination.current} </span>
      <Button type="link" onClick={() => {
        const paginationCopy = { ...pagination };
        paginationCopy.current = paginationCopy.current + 1;
        setPagination(paginationCopy);
      }}> next </Button>
    </div>
  }, [pagination])

  const drawerProps = useMemo(() => ({ title: 'Create message', width: 700, visible: showDrawer, onClose: handleDrawerClose }), [handleDrawerClose, showDrawer])

  return (
    <SocialScreenContainer
      title={'Community Message'}
      drawerProps={drawerProps}
      drawerContent={drawerContent}
      tableProps={{
        columns, dataSource: messages, pagination: false
      }}
      extra={<Button onClick={handleDrawerOpen}> Create message </Button>}
      filtersUI={filtersUI}
      customPagination={customPagination}
    >
    </SocialScreenContainer>
  )
}

export default withDefaultLayout(SocialMessages)
