import LazyImageRender from "@/modules/feeds/pages/schedulesDataTable/LazyImageRender";
import Riddler from "@/network/Riddler/Riddler";
import { ATTACHMENT, ATTACHMENT_OP } from "@/utils/enum";
import { AttachmentAction } from "@/utils/types";
import { isEqual } from "lodash";
import {
  CloseSquareOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Input } from "antd";
import { useEffect, useReducer, useRef, useState } from "react";
import ModalForm from "./ModalForm";
import { attachmentReducer, getInitialState } from "./reducer";
export interface AttachmentProps {
  attachmentType: ATTACHMENT;
  value?: string | string[];
  onChange?: (value: string | string[]) => void;
  id?: string;
  riddlerInstance: Riddler;
}
const Attachment = ({
  value,
  onChange,
  attachmentType,
  riddlerInstance,
}: AttachmentProps) => {
  const [{ attachmentIdList, currentIndex }, attachmentDispatch] = useReducer(
    attachmentReducer,
    getInitialState(value)
  );
  const firstUpdate = useRef(true);
  const [modalVisible, setModalVisibility] = useState(false);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    let changedValue = Array.isArray(value)
      ? attachmentIdList
      : attachmentIdList[0];

    onChange?.(changedValue);
  }, [attachmentIdList, value]);

  const clearValueHandler = (index: number) => {
    let CLEAR_EVENT: AttachmentAction = {
      type: ATTACHMENT_OP.CLEAR,
      indexValue: index,
    };
    attachmentDispatch(CLEAR_EVENT);
  };
  const handleCancel = () => {
    setModalVisibility(false);
  };
  const handleOk = (value: string, previewUrl: string) => {
    if (value) {
      let INSERT_UCCODE: AttachmentAction = {
        type: ATTACHMENT_OP.INSERT_UCCODE,
        ucCode: value,
      };
      attachmentDispatch(INSERT_UCCODE);
      setModalVisibility(false);
    }
  };
  const showModal = (index: number) => {
    let CURRENT_INDEX: AttachmentAction = {
      type: ATTACHMENT_OP.CURRENT_INDEX,
      indexValue: index,
    };
    attachmentDispatch(CURRENT_INDEX);
    setModalVisibility(true);
  };
  const addItem = (index: number) => {
    let ADD_EVENT: AttachmentAction = {
      type: ATTACHMENT_OP.ADD,
    };
    attachmentDispatch(ADD_EVENT);
  };
  const deleteItem = (index: number) => {
    let REMOVE_EVENT: AttachmentAction = {
      type: ATTACHMENT_OP.REMOVE,
      indexValue: index,
    };
    attachmentDispatch(REMOVE_EVENT);
  };

  const ucCodeComponent = (
    ucCode: string,
    index: number,
    length: number,
    isArray: boolean
  ) => {
    return (
      <div key={`${ucCode}${index}`} style={{ paddingTop: "10px" }}>
        <Input.Group compact>
          {
            <LazyImageRender
              id={ucCode}
              width={32}
              height={32}
              attachmentType={attachmentType}
            />
          }
          <Input
            disabled
            value={ucCode}
            style={{ width: ucCode ? "75%" : "75%" }}
          />
          {ucCode ? (
            <Button
              type="primary"
              onClick={() => clearValueHandler(index)}
              icon={<CloseSquareOutlined />}
            />
          ) : (
            <Button
              type="primary"
              onClick={() => showModal(index)}
              icon={<UploadOutlined />}
            />
          )}
          <span style={{ paddingLeft: "5px" }}>
            {index === length - 1 ? (
              <Button
                disabled={!isArray}
                type="primary"
                onClick={() => addItem(index + 1)}
                icon={<PlusSquareOutlined />}
              />
            ) : (
              <Button
                type="primary"
                onClick={() => deleteItem(index)}
                icon={<MinusSquareOutlined />}
              />
            )}
          </span>
        </Input.Group>
      </div>
    );
  };
  return (
    <>
      {attachmentIdList.map((item: string, index: number) =>
        ucCodeComponent(
          item,
          index,
          attachmentIdList.length,
          Array.isArray(value)
        )
      )}

      <ModalForm
        riddlerInstance={riddlerInstance}
        attachmentType={attachmentType}
        modalVisible={modalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
      />
    </>
  );
};
export default Attachment;
