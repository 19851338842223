import { Faq, Step, Tutorial } from "@a4b/api/src/modules/Monetisation/Offerings/types"
import { useAppContext } from "@/components/AppContext"


export interface ManagePageState {

}
export type INITIALIZE_DATA = { type: "INITIALIZE_DATA", payload: any }
export type SET_FAQS_DATA = { type: "SET_FAQS", payload: Faq[] }
export type SET_TUTORIAL_DATA = { type: "SET_TUTORIAL", payload: Tutorial }
export type SET_STEP_DATA = { type: "SET_STEPS", payload: Step[] }
export type MANAGE_PAGE_ACTIONS = INITIALIZE_DATA | SET_FAQS_DATA | SET_TUTORIAL_DATA | SET_STEP_DATA


export const INITIAL_STATE = {}

export const managePageReducer = (state: ManagePageState, action: MANAGE_PAGE_ACTIONS) => {
    const { payload } = action;
    switch (action.type) {
        case 'INITIALIZE_DATA':
            return state;
        case 'SET_FAQS':
            return { ...state, faqs: payload }
        case 'SET_TUTORIAL':
            return { ...state, tutorials: { ...payload, media_url: [payload?.media?.media_url] } }
        case 'SET_STEPS':
            return { ...state, steps: { ...payload } }
        default: return state;
    }
}