import { useEffect, useState, useCallback } from 'react'
import { useAppContext } from '@/components/AppContext'
//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { UserSegmentsData } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import ManageUserSegmentsPage from '@a4b/ui/src/modules/monetisation/organisms/ManageUserSegmentsPage'
import type { TablePaginationConfig } from 'antd/es/table';
import { useForm } from 'antd/lib/form/Form'
import { message, Modal } from 'antd';
import CreateUserSegment from '../CreateUserSegment'
import { handleCreateOrUpdate, parseUserSegmentByIdResponse } from './helper'

const ManageUserSegments = () => {
    const { networkInstance } = useAppContext()
    const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

    const [createUserSegmentForm] = useForm()
    const [userSegmentList, setUserSegmentList] = useState<UserSegmentsData>()
    const [paginationParams, setPaginationParams] =
        useState<TablePaginationConfig>({
            current: 1,
            pageSize: 10,
        })
    const [showUserSegmentCreation, setShowUserSegmentCreation] = useState<boolean>(false)
    const [isEditJourney, setIsEditJourney] = useState<boolean>(false)
    const [editUserSegment, setEditUserSegment] = useState<any>()
    const [showEnableModal, setShowEnableModal] = useState<boolean>(false)
    const [showDisableModal, setShowDisableModal] = useState<boolean>(false)
    const [messageApi, contextHolder] = message.useMessage()
    const [activeRow, setActiveRow] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const getAllUserSegments = useCallback(async () => {
        try {
            setIsLoading(true)
            const userSegments = await monetisationOfferingsApi.userSegmentsApi.getAllUserSegments({
                limit: paginationParams.pageSize,
                offset: Number((paginationParams?.current || 1) - 1) * Number(paginationParams.pageSize),
            }, {
                filterType: 'regular'
            })
            setUserSegmentList(userSegments?.data?.data)
            setPaginationParams({ ...paginationParams, total: userSegments?.data?.data?.count || 10 })
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("Error: ", error)
        }
    }, [monetisationOfferingsApi.userSegmentsApi, paginationParams?.current])

    useEffect(() => {
        getAllUserSegments()
    }, [getAllUserSegments])

    const onItemCreateHandler = () => {
        getAllUserSegments()
    }

    const closeItemCreationForm = () => {
        setShowUserSegmentCreation(false)
        isEditJourney && setIsEditJourney(false)
        setEditUserSegment(undefined)
    }

    const onCreateNew = () => {
        setShowUserSegmentCreation(true)
        setIsEditJourney(false)
        setEditUserSegment(undefined)
    }

    const onStatusChange = (item: any) => {
        setActiveRow(item)
        if (item?.active) {
            setShowDisableModal(true)
        } else {
            setShowEnableModal(true)
        }
    }

    const triggerEdit = async (data: any) => {
        try {
            const userSegmentByIdResponse = await monetisationOfferingsApi.userSegmentsApi.getUserSegmentById(data?.id)
            const CampaignListResponse = await monetisationOfferingsApi.campaignsAPi.getCampaignList({
                limit: 1000,
                offset: 0,
            }, true)
            const parsedData = parseUserSegmentByIdResponse(userSegmentByIdResponse?.data?.data, CampaignListResponse?.data?.data?.campaigns)
            setEditUserSegment(parsedData)
            setIsEditJourney(true)
            setShowUserSegmentCreation(true)
        } catch (error) {
            console.log("Error: ", error)
        }
    }
    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPaginationParams({
            ...pagination,
        })
    }

    const onModalOk = async () => {
        message.loading({
            content: 'Updating user segment status',
            duration: 1,
            key: 'update_user_segment_status',
        })

        try {
            const UserSegmentByIdResData = await monetisationOfferingsApi.userSegmentsApi.getUserSegmentById(activeRow?.id)
            const responseData = UserSegmentByIdResData?.data?.data
            await monetisationOfferingsApi.userSegmentsApi.updateUserSegmentStatus(activeRow?.id, { active: !responseData?.active, service_type: activeRow?.service_type })
            setTimeout(() => {
                getAllUserSegments()
            }, 500)
            setShowEnableModal(false)
            setShowDisableModal(false)
            message.success({
                content: 'User segment status updated successfully',
                duration: 3,
                key: 'update_user_segment_status',
            })
        } catch (error) {
            setShowEnableModal(false)
            setShowDisableModal(false)
            message.error({
                content: 'Error while updating status',
                duration: 2,
                key: 'update_user_segment_status',
            })
            console.log(error)
        }
    }

    const onModalCancel = () => {
        setShowEnableModal(false)
        setShowDisableModal(false)
    }

    const formSubmithandler = async (values: any, campaignList: any[]) => {
        const formattedData: any = handleCreateOrUpdate(values, campaignList, editUserSegment)
        if (isEditJourney) {
            try {
                await monetisationOfferingsApi.userSegmentsApi.updateUserSegment(editUserSegment?.id, formattedData)
                messageApi.open({
                    type: 'success',
                    content: 'Updated User Segment',
                });
                closeItemCreationForm()
                setTimeout(() => {
                    getAllUserSegments()
                }, 500)
            } catch (error) {
                console.log("Error while updating: ", error)
            }
        } else {
            try {
                await monetisationOfferingsApi.userSegmentsApi.createUserSegment(formattedData)
                messageApi.open({
                    type: 'success',
                    content: 'Created User segment',
                });
                closeItemCreationForm()
                setTimeout(() => {
                    getAllUserSegments()
                }, 500)
            } catch (error) {
                console.log("Error while creating: ", error)
            }
        }

    }

    return (
        <div style={{ padding: '0 40px' }}>
            {contextHolder}
            <ManageUserSegmentsPage
                userSegmentList={userSegmentList}
                paginationParams={paginationParams}
                handleTableChange={handleTableChange}
                onCreateNew={onCreateNew}
                onStatusChange={onStatusChange}
                triggerEdit={triggerEdit}
                isLoading={isLoading}
            />
            <CreateUserSegment
                showItemCreation={showUserSegmentCreation}
                closeItemCreation={closeItemCreationForm}
                createItemCallback={onItemCreateHandler}
                isEditJourney={isEditJourney}
                form={createUserSegmentForm}
                initialValues={editUserSegment}
                formSubmithandler={formSubmithandler}
            />
            <Modal
                title='Activate User segment'
                visible={showEnableModal}
                onOk={onModalOk}
                onCancel={onModalCancel}
                okText=' Yes, Activate'
            >
                <p>Are you sure to activate this User segment ?</p>
            </Modal>
            <Modal
                title='Deactivate User segment'
                visible={showDisableModal}
                onOk={onModalOk}
                onCancel={onModalCancel}
                okText='Yes, Deactivate'
            >
                <p>Are you sure to Deactivate this User segment ?</p>
            </Modal>
        </div>
    )
}

export default withDefaultLayout(ManageUserSegments)
