// import { getLanguageNameFromValue } from '@/utils/constants'
import moment from 'moment'

import { City, TempleDetails } from '@a4b/api/src/modules/Content/Temples/types'
import { CreateStore } from '@a4b/api/src/modules/Monetisation/Offerings/types'

export const parseTempleByIdResponse = (temples: TempleDetails) => {
  const templeList = {
    slug: temples?.slug,
    reference_name: temples?.reference_name,
    gallery_urls: temples?.gallery_urls?.map(gallery => gallery),
    latitude: temples?.latitude,
    longitude: temples?.longitude,
    city_code: temples?.city_code,
    donation_redirection_url: temples?.donation_redirection_url,
    is_donations_enabled: temples?.is_donations_enabled,
    is_pooja_enabled: temples?.is_pooja_enabled,
    donation_type: temples?.donation_type,
    payment_details: {
      upi: temples?.payment_details?.upi,
      qrcode_img: [temples?.payment_details?.qrcode_img],
      receiver_name: temples?.payment_details?.receiver_name,
      bank_details: {
        account_number: temples?.payment_details?.bank_details?.account_number,
        ifsc_code: temples?.payment_details?.bank_details?.ifsc_code,
        beneficiary_name:
          temples?.payment_details?.bank_details?.beneficiary_name,
        bank_name: temples?.payment_details?.bank_details?.bank_name,
        account_type: temples?.payment_details?.bank_details?.account_type,
      },
    },
    language_code: temples?.language_code,
    title: temples?.title,
    sub_title: temples?.sub_title,
    short_desc: temples?.short_desc,
    significance: temples?.significance,
    long_desc: temples?.long_desc,
    history_desc: temples?.history_desc,
    architecture_desc: temples?.architecture_desc,
    timings: temples?.timings?.map((time: any) => ({
      start_time: moment(time?.start_time, 'HH:mm a'),
      end_time: moment(time?.end_time, 'HH:mm a'),
      description: time?.description,
      icon_url: [time?.icon_url],
    })),
    offerings: temples?.offerings,
    faq: temples?.faq?.map(item => ({
      question: item?.question,
      answer: item?.answer,
    })),
    travel_details: {
      air: temples?.travel_details?.air,
      road: temples?.travel_details?.road,
      train: temples?.travel_details?.train,
    },
    special_note: temples?.special_note,
    temple_committee: {
      temple_committee_members:
        temples?.temple_committee?.temple_committee_members?.map(member => ({
          id: member?.id,
          name: member?.name,
          thumbnail_img_url: [member?.thumbnail_img_url],
          image_url: [member?.image_url],
          designation: member?.designation,
          description: member?.description,
          social_media: {
            fb: member?.social_media?.fb,
            instagram: member?.social_media?.instagram,
            youtube: member?.social_media?.youtube,
          },
        })),
    },
    social_media: {
      fb: temples?.social_media?.fb,
      instagram: temples?.social_media?.instagram,
      youtube: temples?.social_media?.youtube,
    },
    priority: temples?.priority,
    is_live_enabled: temples?.is_live_enabled,
    live_redirection_url: temples?.live_redirection_url,
    is_avatar_enabled: temples?.is_avatar_enabled,
    ...(temples?.is_avatar_enabled && {
      chadava: {
        store_id: temples?.chadava?.store_id,
      },
    }),
  }
  return templeList
}

export const payloadTempleData = (
  temples: TempleDetails,
  languageCode: string,
) => {
  const templeList = {
    reference_name: temples?.reference_name,
    gallery_urls: temples?.gallery_urls?.flatMap(gallery =>
      Array.isArray(gallery)
        ? gallery.replace(/\s/g, '')
        : [gallery.replace(/\s/g, '')],
    ),
    slug: temples?.slug,
    priority: temples?.priority,
    latitude: temples?.latitude,
    longitude: temples?.longitude,
    city_code: temples?.city_code,
    is_live_enabled: temples?.is_live_enabled || false,
    ...(temples?.is_live_enabled && {
      live_config: {
        redirection_url: temples?.live_redirection_url,
      },
    }),
    is_donations_enabled: temples?.is_donations_enabled,
    is_pooja_enabled: temples?.is_pooja_enabled,
    ...(temples?.is_donations_enabled && {
      donation_type: temples?.donation_type,
    }),
    ...(temples?.is_donations_enabled &&
      temples?.donation_type === 'STATIC' && {
        payment_details: {
          upi: temples?.payment_details?.upi,
          qrcode_img: temples?.payment_details?.qrcode_img?.[0].replace(
            /\s/g,
            '',
          ),
          receiver_name: temples?.payment_details?.receiver_name,
          bank_details: {
            account_number:
              temples?.payment_details?.bank_details?.account_number,
            ifsc_code: temples?.payment_details?.bank_details?.ifsc_code,
            beneficiary_name:
              temples?.payment_details?.bank_details?.beneficiary_name,
            bank_name: temples?.payment_details?.bank_details?.bank_name,
            account_type: temples?.payment_details?.bank_details?.account_type,
          },
        },
      }),
    ...(temples?.is_donations_enabled &&
      temples?.donation_type === 'REDIRECTION' && {
        donation_redirection_url: temples?.donation_redirection_url,
      }),
    language_code: languageCode,
    title: temples?.title,
    sub_title: temples?.sub_title,
    significance: temples?.significance,
    short_desc: temples?.short_desc,
    long_desc: temples?.long_desc,
    history_desc: temples?.history_desc,
    architecture_desc: temples?.architecture_desc,
    timings: temples?.timings?.map((time: any) => {
      const startTime = moment(time?.start_time, 'hh:mm A')
      const endTime = moment(time?.end_time, 'hh:mm A')
      return {
        start_time: startTime?.format('hh:mm A'),
        end_time: endTime?.format('hh:mm A'),
        description: time?.description,
        icon_url: time?.icon_url?.[0].replace(/\s/g, ''),
      }
    }),
    offerings: temples?.offerings,
    faq: temples?.faq?.map(item => ({
      question: item?.question,
      answer: item?.answer,
    })),
    travel_details: {
      air: temples?.travel_details?.air,
      road: temples?.travel_details?.road,
      train: temples?.travel_details?.train,
    },
    special_note: temples?.special_note,
    temple_committee: {
      temple_committee_members:
        temples?.temple_committee?.temple_committee_members?.map(
          (member: any) => ({
            id: member?.id,
            name: member?.name,
            thumbnail_img_url: member?.thumbnail_img_url?.[0].replace(
              /\s/g,
              '',
            ),
            image_url: member?.image_url?.[0].replace(/\s/g, ''),
            designation: member?.designation,
            description: member?.description,
            social_media: {
              fb: member?.social_media?.fb,
              instagram: member?.social_media?.instagram,
              youtube: member?.social_media?.youtube,
            },
          }),
        ),
    },
    social_media: {
      fb: temples?.social_media?.fb,
      instagram: temples?.social_media?.instagram,
      youtube: temples?.social_media?.youtube,
    },
    chadava: {
      store_id: temples?.chadava?.store_id,
    },
  }
  return templeList
}

export const parseCities = (cities: City[], languageCode: string) => {
  const cityListData = cities?.map(city => {
    const cityName: any = city?.translations
    return {
      value: city?.city_code,
      label: cityName?.[languageCode]?.city_name,
    }
  })
  return cityListData
}

export const parseStores = (stores: CreateStore[]) => {
  const cityListData = stores?.map(store => {
    return {
      value: store?.id,
      label: store?.title,
    }
  })
  return cityListData
}
