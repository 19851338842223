import { Input } from 'antd';
import { useEffect, useState } from 'react';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  id?: string;
  showCount?: boolean;
  placeHolder: string;
  maxLength?: number;
}
const CodeInput = ({
  value,
  showCount,
  placeHolder,
  onChange,
  maxLength,
}: Props) => {
  const [inputValue, setInput] = useState(value || '');
  useEffect(() => {
    onChange?.(inputValue);
  }, [inputValue, onChange]);
  const inputChangeHandler = (event: any) => {
    try {
      let value = event?.target?.value;
      let valueUpperCase = String(value).toUpperCase();
      setInput(valueUpperCase);
    } catch (error) {
      console.log('Error occured');
    }
  };
  return (
    <Input
      value={value}
      maxLength={maxLength}
      placeholder={placeHolder}
      showCount={showCount}
      onChange={inputChangeHandler}
    />
  );
};
export default CodeInput;
