import { AartiPropertyComponent, BasePropertyComponent, CarpetPropertyComponent, ChadavaPropertyComponent, CtaPropertyComponent, FlowerPropertyComponent, IgnitablePropertyComponent, InstrumentPropertyComponent, OfferingPropertyComponent, PrayerCompletionPropertyComponent, PrayerPropertyComponent, ThaliPropertyComponent, VisheshPropertyComponent } from "./dynamic-items-form"

export enum FORM_ITEM_TYPE {
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    IMAGE = 'IMAGE',
    MP3 = 'MP3',
    MP4 = 'MP4',
    DATE_RANGE = "DATE-RANGE"
}

export enum GOD_ITEM_TYPES {
    FLOWER = 'flower',
    INSTRUMENT = 'instrument',
    AARTI = 'aarti',
    CTA = 'cta',
    OFFERING = 'offering',
    IGNITABLE = 'ignitable',
    BACKGROUND = 'layer_background',
    MIDDLE_LAYER = 'layer_middle',
    FLOOR_LAYER = "layer_floor",
    ARCH_LAYER = 'layer_arch',
    ITEM_BASE = 'base',
    GARLAND = 'garland',
    CHADAVA_ICON = 'chadava_icon',
    PRAYER = 'prayer',
    THALI = 'thali',
    CARPET = 'carpet',
    PRAYER_COMPLETION = 'prayer_completion',
    VISHESH = 'vishesh'
}

export const GOD_ITEM_FLOWER = {
    density: { type: FORM_ITEM_TYPE.STRING, validation: [{ required: true }] },
    animated_attachment: { type: FORM_ITEM_TYPE.IMAGE },
    dimension: { type: FORM_ITEM_TYPE.STRING },
    base_attachment: { type: FORM_ITEM_TYPE.IMAGE },
    propertyForm: FlowerPropertyComponent,
}

export const GOD_ITEM_INSTRUMENT = {
    audio_attachment: { type: FORM_ITEM_TYPE.MP3 },
    total_duration: { type: FORM_ITEM_TYPE.NUMBER },
    propertyForm: InstrumentPropertyComponent
}


export const GOD_ITEM_AARTI = {
    animated_attachment: { type: FORM_ITEM_TYPE.IMAGE },
    base_attachment: { type: FORM_ITEM_TYPE.IMAGE },
    dimension: { type: FORM_ITEM_TYPE.STRING },
    audio_attachment: { type: FORM_ITEM_TYPE.MP3 },
    // total_duration: { type: FORM_ITEM_TYPE.NUMBER },
    propertyForm: AartiPropertyComponent
}

export const GOD_ITEM_CTA = {
    cta_text_code: { type: FORM_ITEM_TYPE.STRING },
    deeplink: { type: FORM_ITEM_TYPE.STRING },
    propertyForm: CtaPropertyComponent
}


export const GOD_ITEM_OFFERING = {
    // total_duration: { type: FORM_ITEM_TYPE.NUMBER },
    base_attachment: { type: FORM_ITEM_TYPE.IMAGE },
    animated_attachment: { type: FORM_ITEM_TYPE.IMAGE },
    dimension: { type: FORM_ITEM_TYPE.STRING },
    propertyForm: OfferingPropertyComponent
}

export const GOD_ITEM_IGNITABLE = {
    // total_duration: { type: FORM_ITEM_TYPE.NUMBER },
    '0': { type: FORM_ITEM_TYPE.IMAGE },
    // '25': { type: FORM_ITEM_TYPE.IMAGE },
    // '50': { type: FORM_ITEM_TYPE.IMAGE },
    '100': { type: FORM_ITEM_TYPE.IMAGE },
    dimension: { type: FORM_ITEM_TYPE.STRING },
    classification: { type: FORM_ITEM_TYPE.STRING },
    propertyForm: IgnitablePropertyComponent
}

export const GOD_ITEM_BACKGROUND = {
    propertyForm: null
}

export const GOD_ITEM_MIDDLE_LAYER = {
    propertyForm: null
}

export const GOD_ITEM_FLOOR_LAYER = {
    propertyForm: null
}

export const GOD_ITEM_ARCH_LAYER = {
    propertyForm: null
}

export const GOD_ITEM_ITEM_BASE = {
    animated_attachment: {
        type: FORM_ITEM_TYPE.IMAGE
    },
    propertyForm: BasePropertyComponent
}


export const GOD_ITEM_ITEM_CARPET = {
    message_code: {
        type: FORM_ITEM_TYPE.STRING
    },
    propertyForm: CarpetPropertyComponent
}


export const GOD_ITEM_GARLAND = {
    propertyForm: null
}

export const GOD_ITEM_THALI = {
    animated_attachment: {
        type: FORM_ITEM_TYPE.IMAGE
    },
    propertyForm: ThaliPropertyComponent
}

export const GOD_ITEM_CHADAVA_ICON = {
    cta_text_code: {
        type: FORM_ITEM_TYPE.STRING
    },
    deeplink: {
        type: FORM_ITEM_TYPE.STRING
    },
    propertyForm: ChadavaPropertyComponent
}

export const GOD_ITEM_PRAYER = {
    audio_attachment: {
        type: FORM_ITEM_TYPE.STRING
    },
    audio_duration: {
        type: FORM_ITEM_TYPE.NUMBER
    },
    propertyForm: PrayerPropertyComponent
}

export const GOD_ITEM_PRAYER_COMPLETION = {
    media_attachment: {
        type: FORM_ITEM_TYPE.MP4
    },
    propertyForm: PrayerCompletionPropertyComponent
}

export const GOD_ITEM_VISHESH = {
    base_attachment: { type: FORM_ITEM_TYPE.IMAGE },
    animated_attachment: { type: FORM_ITEM_TYPE.IMAGE },
    dimension: { type: FORM_ITEM_TYPE.STRING },
    date_range: { type: FORM_ITEM_TYPE.DATE_RANGE, field_names: ['start_time', 'end_time'] },
    propertyForm: VisheshPropertyComponent
}



export const FORM_CONFIG = {
    [GOD_ITEM_TYPES.FLOWER]: GOD_ITEM_FLOWER,
    [GOD_ITEM_TYPES.INSTRUMENT]: GOD_ITEM_INSTRUMENT,
    [GOD_ITEM_TYPES.AARTI]: GOD_ITEM_AARTI,
    [GOD_ITEM_TYPES.CTA]: GOD_ITEM_CTA,
    [GOD_ITEM_TYPES.OFFERING]: GOD_ITEM_OFFERING,
    [GOD_ITEM_TYPES.IGNITABLE]: GOD_ITEM_IGNITABLE,
    [GOD_ITEM_TYPES.BACKGROUND]: GOD_ITEM_BACKGROUND,
    [GOD_ITEM_TYPES.MIDDLE_LAYER]: GOD_ITEM_MIDDLE_LAYER,
    [GOD_ITEM_TYPES.FLOOR_LAYER]: GOD_ITEM_FLOOR_LAYER,
    [GOD_ITEM_TYPES.ARCH_LAYER]: GOD_ITEM_ARCH_LAYER,
    [GOD_ITEM_TYPES.ITEM_BASE]: GOD_ITEM_ITEM_BASE,
    [GOD_ITEM_TYPES.GARLAND]: GOD_ITEM_GARLAND,
    [GOD_ITEM_TYPES.CHADAVA_ICON]: GOD_ITEM_CHADAVA_ICON,
    [GOD_ITEM_TYPES.PRAYER]: GOD_ITEM_PRAYER,
    [GOD_ITEM_TYPES.THALI]: GOD_ITEM_THALI,
    [GOD_ITEM_TYPES.CARPET]: GOD_ITEM_ITEM_CARPET,
    [GOD_ITEM_TYPES.PRAYER_COMPLETION]: GOD_ITEM_PRAYER_COMPLETION,
    [GOD_ITEM_TYPES.VISHESH]: GOD_ITEM_VISHESH
}

//@ts-ignore
export const GOD_ITEMS: GOD_ITEM_TYPES[] = Object.keys(GOD_ITEM_TYPES).map((key) => GOD_ITEM_TYPES[key]);

