
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination from "@/hooks/useAntdPagination";
import { Chapter } from "@a4b/api/src/modules/Gita/types";
import { useSearchParams } from "react-router-dom";



const GitaChapters = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const { networkInstance } = useAppContext();
  const { pagination, setPagination } = useAntdPagination();
  const [chapters, setChapters] = useState<Chapter[]>([])
  const [searchParams, setSearchParams] = useSearchParams();
  const [chapterId, setChapterId] = useState();

  const getChapters = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.gita.getChapters();
      const chapters: Chapter[] = res.data.data.chapters.map((chapter) => {
        const content = { ...chapter }
        content.content = Object.keys(chapter.content).map((language: string) => {
          return {
            language,
            //@ts-ignore
            name: chapter.content[language].name,
            // @ts-ignore
            description: chapter.content[language].description
          }
        })
        content.meta.keywords.split(",")
        content.banner_attachment.path = new URL(content.banner_attachment.url).pathname
        content.thumbnail_attachment.path = new URL(content.thumbnail_attachment.url).pathname
        return content;
      })
      setChapters(chapters)
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.gita])

  useEffect(() => {
    getChapters();
  }, [getChapters])

  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }
  const drawerConfig = useDrawerConfig(getChapters, setActiveDrawer, activeDrawer, chapters.find((chapter) => chapter.id === chapterId));
  const { drawerProps, drawerContent } = drawerConfig;

  const tableConfig = useTableConfig<Chapter>(chapters, pagination, setPagination, setDrawer, setChapterId);


  const onCreateRashipal = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_CHAPTER);
  }

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  }

  return <SocialScreenContainer
    title={"Gita Chapters"}
    filtersUI={<Filters />}
    extra={<Button onClick={onCreateRashipal}> Create New Chapter </Button>}
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />

};

export default withDefaultLayout(GitaChapters)