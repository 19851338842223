import { Variant } from "@a4b/api/src/modules/AbExperiments/types"
import { Button, Form, FormInstance, Input, Select } from "antd"
import styled from "styled-components"

interface VariantsSystemIdsProps {
    form: FormInstance<Variant>,
    formData?: Variant
}

const SubmitButton = styled(Button)`
    position: absolute;
    top: 10px;
    right: 10px;
`

const VariantsSystemIds = function (props: VariantsSystemIdsProps) {
    const { form, formData } = props;
    const handleInputKeyDown = (e: any) => {
        // Prevent adding tags with spaces
        if (e.keyCode === 32) {
            e.preventDefault();
        }
    };

    return <div>
        <Form.Item name="experiment_id" label="Experiment id">
            <Input disabled></Input>
        </Form.Item>
        <Form.Item name={"variant_code"} label="Variant code">
            <Input disabled></Input>
        </Form.Item>
        <Form.Item name={"system_ids"} label={`Forced System IDs`}>
            <Select
                mode="tags"
                onInputKeyDown={handleInputKeyDown}
            ></Select>
        </Form.Item>

        <SubmitButton type="primary" htmlType="submit"> Update </SubmitButton>
    </div>
}

export default VariantsSystemIds;

