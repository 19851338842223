import { UploadOutlined } from '@ant-design/icons'
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input, message, Radio, RadioChangeEvent, Row,
  Select,
  Space, Upload,
  UploadProps
} from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { RangePickerProps } from 'antd/lib/date-picker'
import type { RcFile } from 'antd/lib/upload'
import type { UploadFile } from 'antd/lib/upload/interface'
import moment from 'moment'
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import React, {
  useCallback,
  useEffect,
  useState
} from 'react'

import type {
  ContentStatus,
  ContentTag,
  LibraryMediaType,
  SupportedContentFormats,
  SupportedContentTypes,
  SupportedPlatforms,
} from '@a4b/api'

//constants
import { CONTENT_FORMATS, CONTENT_TYPES, getLanguageNameFromValue, PLATFORMS } from '../../../constants'
//component
import { MarkdownEditor } from '../MarkdownEditor'

export interface Props {
  data?: ContentFormFields
  status?: ContentStatus
  uploadUrl: string
  cdnHost: string,
  tags: ContentTag[]
  uploadFunction: (
    media_type: LibraryMediaType,
    fileFormat: SupportedContentFormats,
    file: RcFile,
  ) => Promise<string>
  onTagSearch: (value: string, offset: number) => void
  onSave: (data: ContentFormFields) => Promise<boolean>
  onPublish: (data: ContentFormFields) => Promise<boolean>
  onSendQC: (data: ContentFormFields) => Promise<boolean>
  onRequestChanges: (data: ContentFormFields) => Promise<boolean>
  onFormChanged: (value: ContentFormFields) => void
}

interface MediaMetaData {
  consumptionTime: string
  attachment: UploadFile<string>[]
  thumbnailId: UploadFile<string>[]
}

interface VideoMediaMetaData {
  consumptionTime: string
  attachment?: UploadFile<string>[]
  youtubeLink?: string
  thumbnailId: UploadFile<string>[]
}


export type ContentFormFields = {
  name: string
  description: string
  shareText: string
  tags: string[]
  platforms: SupportedPlatforms[]
  type: SupportedContentTypes,
  formats: Omit<SupportedContentFormats, 'image'>[]
  textMetaData?: {
    consumptionTime: string
    attachment: string // md data
    thumbnailId: UploadFile<string>[]
  }
  pdfMetaData?: MediaMetaData
  videoMetaData?: VideoMediaMetaData
  audioMetaData?: MediaMetaData
  validity?: [moment.Moment, moment.Moment]
  thumbnail?: UploadFile<string>[]
  validityCheckbox?: boolean,
  showThumbnailImage: boolean
}

type VideoType = 'youtube' | 'video'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

const disabledDate: RangePickerProps['disabledDate'] = current => {
  return current && current < moment().startOf('day')
}

const { useForm } = Form
const { RangePicker } = DatePicker

const feildsToValidate = ['name', 'description', 'shareText', 'tags', 'platforms', 'type', 'formats', 'thumbnail'];


export const CreateEditForm: React.FC<Props> = ({
  status = 'DRAFT',
  data,
  uploadFunction,
  uploadUrl,
  cdnHost,
  tags,
  onTagSearch,
  onSave,
  onPublish,
  onSendQC,
  onRequestChanges,
  onFormChanged,
}) => {
  const [form] = useForm<ContentFormFields>()
  const [validity, setValidity] = useState(false)
  const [videoType, setVideoType] = useState<VideoType>('video')
  const [offset, setOffset] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');

  useEffect(() => {
    if (!data) return
    form.setFieldsValue(data)
    if (data.validity && data.validity.length > 0) setValidity(true)

    if (data.videoMetaData?.youtubeLink) {
      setVideoType('youtube')
    } else {
      setVideoType('video')
    }
  }, [data])

  const onFinish = useCallback(
    async (values: ContentFormFields) => {
      values['validityCheckbox'] = validity;
      let success = false
      if (status === 'DRAFT' || status === 'CHANGE_REQUESTED') {
        success = await onSave(values)
      } else {
        success = await onPublish(values)
      }
      success
        ? message.success('Content Saved')
        : message.error('Failed to save content')
    },
    [status, validity],
  )

  const handleSendQC = async () => {
    const errors = form.getFieldsError().filter(({ errors }) => errors.length)
    if (errors && errors.length > 0) {
      console.log({ errors })
      return
    }
    const values = form.getFieldsValue()
    values['validityCheckbox'] = validity;

    const success = await onSendQC(values)
    success
      ? message.success('Content Sent To QC')
      : message.error('Failed to save content')
  }

  const handleSaveAsDraft = async () => {
    form.validateFields(feildsToValidate);
    const errors = form.getFieldsError(feildsToValidate).filter(({ errors }) => errors.length)
    if (errors && errors.length > 0) {
      console.log({ errors })
      return
    }
    const values = form.getFieldsValue()
    values['validityCheckbox'] = validity;

    const success = await onSave(values)
    success
      ? message.success('Content Saved')
      : message.error('Failed to save content')
  }

  const handleRequestChanges = async () => {
    const errors = form.getFieldsError().filter(({ errors }) => errors.length)
    if (errors && errors.length > 0) {
      console.log({ errors })
      return
    }
    const values = form.getFieldsValue()
    const success = await onRequestChanges(values)
    success
      ? message.success('Content Saved')
      : message.error('Failed to save content')
  }

  const onFinishFailed = useCallback(
    (errorInfo: ValidateErrorEntity<ContentFormFields>) => {
      console.log(errorInfo)
    },
    [],
  )

  const onUpload: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
  }) => {
    const fileName = (file as RcFile).name
    const fileExt = fileName.substring(fileName.lastIndexOf('.') + 1)
    const mediaFormat =
      fileExt === 'png' || fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'gif'
        ? 'image'
        : 'content'
    let fileFormat
    if (fileExt === 'png' || fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'gif') {
      fileFormat = 'image'
    } else if (fileExt === 'mp3') {
      fileFormat = 'audio'
    } else if (fileExt === 'mp4') {
      fileFormat = 'video'
    } else if (fileExt === 'pdf') {
      fileFormat = 'pdf'
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await uploadFunction(mediaFormat, fileFormat, file)
    if (onSuccess) {
      onSuccess(response)
    }
  }

  const isFormDisabled = () => {
    return status !== 'DRAFT' && status !== 'CHANGE_REQUESTED'
  }

  const onValiditySelected = (e: CheckboxChangeEvent) => {
    setValidity(e.target.checked)
  }

  const handleValuesChanged = (
    changeValues: Partial<ContentFormFields>,
    allValues: ContentFormFields,
  ) => {
    onFormChanged(allValues)
  }

  const handleVideoTypeChange = (e: RadioChangeEvent) => {
    setVideoType(e.target.value as VideoType)
  }

  const handleSearch = (key: string, _offset?: number) => {
    onTagSearch(key, _offset || offset)
  }

  return (
    <Row style={{ padding: '40px' }}>
      <Col span={24} xs={24} sm={24} md={24} lg={24}>
        <Card title={'Content Upload'}>
          <Form
            name='content-upload-form'
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={handleValuesChanged}
            autoComplete='off'
          >
            <Form.Item
              label='Name'
              name='name'
              rules={[{ required: true, message: 'Please input name!' }]}
            >
              <Input disabled={isFormDisabled()} />
            </Form.Item>
            <Form.Item
              label='Description'
              name='description'
              rules={[
                { required: true, message: 'Please input your description!' },
              ]}
            >
              <Input.TextArea disabled={isFormDisabled()} />
            </Form.Item>
            <Form.Item
              label='Share Text'
              name='shareText'
              rules={[
                { required: true, message: 'Please input your share text!' },
              ]}
            >
              <Input.TextArea disabled={isFormDisabled()} />
            </Form.Item>
            <Form.Item
              label='Tags'
              name='tags'
              rules={[
                { required: true, message: 'Please select atleast one tag!' },
              ]}
            >
              <Select
                showSearch
                filterOption={false}
                mode='multiple'
                searchValue={searchText}
                onSearch={(searchText) => {
                  setOffset(0)
                  onTagSearch(searchText, 0)
                  setSearchText(searchText)
                }}
                disabled={isFormDisabled()}
                dropdownRender={(menu) => {
                  return <div>
                    {menu}
                    <Divider></Divider>
                    <Button type="text"
                      onClick={() => {
                        setOffset(Math.max(offset - 1, 0));
                        handleSearch(searchText, Math.max(offset - 1, 0));
                      }}
                      disabled={offset === 0}
                    >
                      Previous Page {offset}
                    </Button>
                    (Page {offset + 1})
                    <Button type="text" value={1 + 1}
                      onClick={() => {
                        console.log('offset', offset);
                        setOffset(offset + 1);
                        handleSearch(searchText, offset + 1)
                      }
                      }
                      disabled={tags.length < 30}
                    >
                      Next Page {offset + 2}
                    </Button>
                  </div>
                }}
              >
                {tags.map((tag, index) => (
                  <Select.Option key={index} value={tag.id} >
                    {
                      tag.active ? <Badge status="success" text={`${tag.name} (${getLanguageNameFromValue(tag.language)})`} /> : <Badge status="error" text={`${tag.name} (${getLanguageNameFromValue(tag.language)})`} />
                    }
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='Platforms'
              name='platforms'
              rules={[
                {
                  required: true,
                  message: 'Please select atleast one platform!',
                },
              ]}
            >
              <Checkbox.Group disabled={isFormDisabled()}>
                {PLATFORMS.map((format, index) => (
                  <Checkbox key={index} value={format}>
                    {format}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              label='Content Type'
              name='type'
              rules={[
                {
                  required: true,
                  message: 'Please select any one content type!',
                },
              ]}
            >
              <Radio.Group disabled={isFormDisabled()}>
                {CONTENT_TYPES.map((type, index) => (
                  <Radio value={type} key={index}>
                    {type}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Row style={{ marginBottom: '24px' }}>
              <Col span={6} style={{ textAlign: 'right', paddingRight: '8px' }}>
                Validity
              </Col>
              <Col>
                <Checkbox
                  checked={validity}
                  onChange={onValiditySelected}
                  disabled={isFormDisabled()}
                />
              </Col>
            </Row>
            {validity && (
              <Form.Item
                label='Validity Range'
                name='validity'
                rules={[
                  {
                    required: true,
                    message: 'Please select valid range!',
                  },
                ]}
              >
                <RangePicker
                  disabledDate={disabledDate}
                  disabled={isFormDisabled()}
                />
              </Form.Item>
            )}
            <Form.Item
              label='format'
              name='formats'
              rules={[
                {
                  required: true,
                  message: 'Please select atleast one format!',
                },
              ]}
            >
              <Checkbox.Group
                disabled={isFormDisabled()}
              >
                {CONTENT_FORMATS.map((format, index) => (
                  <Checkbox key={index} value={format}>
                    {format.toUpperCase()}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.formats !== currentValues.formats}
            >
              {({ getFieldValue }) =>
                getFieldValue('formats') && getFieldValue('formats').indexOf('text') !== -1 && (
                  <>
                    <Form.Item
                      label='Add Text'
                      name={['textMetaData', 'attachment']}
                      rules={[
                        { required: true, message: 'Please input your content' },
                      ]}
                    >
                      <MarkdownEditor
                        disabled={isFormDisabled()}
                        uploadUrl={uploadUrl}
                        cdnHost={cdnHost}
                        imageUploadFunction={uploadFunction}
                      />
                    </Form.Item>
                    <Form.Item
                      label='Text Thumbnail'
                      name={['textMetaData', 'thumbnailId']}
                      valuePropName='fileList'
                      getValueFromEvent={normFile}
                      rules={[
                        {
                          required: true,
                          message: 'Please upload a thumbnail for text!',
                        },
                      ]}
                    >
                      <Upload
                        disabled={isFormDisabled()}
                        multiple={false}
                        maxCount={1}
                        accept={'.png,.jpg,.jpeg,.gif'}
                        name='input_file'
                        listType='picture'
                        customRequest={onUpload}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={isFormDisabled()}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      label='Text Read Time'
                      name={['textMetaData', 'consumptionTime']}
                      rules={[
                        {
                          required: true,
                          message: 'Please input text read time',
                        },
                      ]}
                    >
                      <Input disabled={isFormDisabled()} type='number' placeholder='Read time in minutes' />
                    </Form.Item>
                  </>
                )}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.formats !== currentValues.formats}
            >
              {({ getFieldValue }) =>
                getFieldValue('formats') && getFieldValue('formats').indexOf('audio') !== -1 && (
                  <>
                    <Form.Item
                      label='Audio'
                      name={['audioMetaData', 'attachment']}
                      valuePropName='fileList'
                      getValueFromEvent={normFile}
                      rules={[
                        { required: true, message: 'Please upload a audio file!' },
                      ]}
                    >
                      <Upload
                        disabled={isFormDisabled()}
                        multiple={false}
                        maxCount={1}
                        accept={'.mp3'}
                        customRequest={onUpload}
                        name='input_file'
                        listType='text'
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={isFormDisabled()}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      label='Audio Thumbnail'
                      name={['audioMetaData', 'thumbnailId']}
                      valuePropName='fileList'
                      getValueFromEvent={normFile}
                      rules={[
                        {
                          required: true,
                          message: 'Please upload a thumbnail for audio file!',
                        },
                      ]}
                    >
                      <Upload
                        disabled={isFormDisabled()}
                        multiple={false}
                        maxCount={1}
                        accept={'.png,.jpg,.jpeg,.gif'}
                        name='input_file'
                        listType='picture'
                        customRequest={onUpload}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={isFormDisabled()}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      label='Audio Listen Time'
                      name={['audioMetaData', 'consumptionTime']}
                      rules={[
                        {
                          required: true,
                          message: 'Please input listen time for audio',
                        },
                      ]}
                    >
                      <Input type={'number'} disabled={isFormDisabled()} placeholder='Listen time in minutes' />
                    </Form.Item>
                  </>
                )}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.formats !== currentValues.formats}
            >
              {({ getFieldValue }) =>
                getFieldValue('formats') && getFieldValue('formats').indexOf('video') !== -1 && (
                  <>
                    <Row style={{ marginBottom: '12px' }}>
                      <Col offset={6}>
                        <Radio.Group value={videoType} onChange={handleVideoTypeChange}>
                          <Radio value={'video'}>
                            Video
                          </Radio>
                          <Radio value={'youtube'}>
                            Youtube
                          </Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                    {
                      videoType === 'video' ?
                        <Form.Item
                          label='Video'
                          name={['videoMetaData', 'attachment']}
                          valuePropName='fileList'
                          getValueFromEvent={normFile}
                          rules={[
                            { required: true, message: 'Please upload a video file!' },
                          ]}
                        >
                          <Upload
                            multiple={false}
                            maxCount={1}
                            accept={'video/*'}
                            name='input_file'
                            customRequest={onUpload}
                            listType='text'
                            disabled={isFormDisabled()}
                          >
                            <Button
                              icon={<UploadOutlined />}
                              disabled={isFormDisabled()}
                            >
                              Upload
                            </Button>
                          </Upload>
                        </Form.Item>
                        :
                        <Form.Item
                          label='Video'
                          name={['videoMetaData', 'youtubeLink']}
                          rules={[
                            { required: true, message: 'Please add youtube link!' },
                          ]}
                        >
                          <Input disabled={isFormDisabled()}
                          />
                        </Form.Item>
                    }

                    <Form.Item
                      label='Video Thumbnail'
                      name={['videoMetaData', 'thumbnailId']}
                      valuePropName='fileList'
                      getValueFromEvent={normFile}
                      rules={[
                        {
                          required: true,
                          message: 'Please upload a thumbnail for video file!',
                        },
                      ]}
                    >
                      <Upload
                        disabled={isFormDisabled()}
                        multiple={false}
                        maxCount={1}
                        accept={'.png,.jpg,.jpeg,.gif'}
                        name='input_file'
                        listType='picture'
                        customRequest={onUpload}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={isFormDisabled()}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      label='Video View Time'
                      name={['videoMetaData', 'consumptionTime']}
                      rules={[
                        {
                          required: true,
                          message: 'Please input listen time for audio',
                        },
                      ]}
                    >
                      <Input type={'number'} disabled={isFormDisabled()} placeholder='Listen time in minutes' />
                    </Form.Item>
                  </>
                )}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.formats !== currentValues.formats}
            >
              {({ getFieldValue }) =>
                getFieldValue('formats') && getFieldValue('formats').indexOf('pdf') !== -1 && (
                  <>
                    <Form.Item
                      label='PDF'
                      name={['pdfMetaData', 'attachment']}
                      valuePropName='fileList'
                      getValueFromEvent={normFile}
                      rules={[
                        { required: true, message: 'Please upload a pdf file!' },
                      ]}
                    >
                      <Upload
                        multiple={false}
                        maxCount={1}
                        accept={'application/pdf'}
                        name='input_file'
                        customRequest={onUpload}
                        listType='text'
                        disabled={isFormDisabled()}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={isFormDisabled()}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      label='PDF Thumbnail'
                      name={['pdfMetaData', 'thumbnailId']}
                      valuePropName='fileList'
                      getValueFromEvent={normFile}
                      rules={[
                        {
                          required: true,
                          message: 'Please upload a thumbnail for pdf file!',
                        },
                      ]}
                    >
                      <Upload
                        disabled={isFormDisabled()}
                        multiple={false}
                        maxCount={1}
                        accept={'.png,.jpg,.jpeg,.gif'}
                        name='input_file'
                        listType='picture'
                        customRequest={onUpload}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={isFormDisabled()}
                        >
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      label='PDF Read Time'
                      name={['pdfMetaData', 'consumptionTime']}
                      rules={[
                        {
                          required: true,
                          message: 'Please input PDF Read time in minutes',
                        },
                      ]}
                    >
                      <Input type={'number'} disabled={isFormDisabled()} placeholder='Read time in minutes' />
                    </Form.Item>
                  </>
                )}
            </Form.Item>
            <Form.Item
              label='Add Thumbnail'
              name='thumbnail'
              valuePropName='fileList'
              getValueFromEvent={normFile}
              rules={[
                { required: true, message: 'Please add a thumbnail image' },
              ]}
            >
              <Upload
                multiple={false}
                maxCount={1}
                accept={'.png,.jpg,.jpeg,.gif'}
                name='input_file'
                listType='picture'
                customRequest={onUpload}
                disabled={isFormDisabled()}
              >
                <Button icon={<UploadOutlined />} disabled={isFormDisabled()}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item label={"Show thumbnail image"} name={"showThumbnailImage"} valuePropName="checked" initialValue={false}>
              <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{ span: 16 }} shouldUpdate>
              <Space>
                {(status === 'DRAFT' || status === 'CHANGE_REQUESTED') ? (
                  <>
                    <Button type='primary' onClick={handleSaveAsDraft}>
                      Save As Draft
                    </Button>
                    <Button onClick={handleSendQC}>Send To QC</Button>
                  </>
                ) : (
                  <>
                    {
                      (status === 'READY_TO_BE_MODERATED') &&
                      <>
                        <Button type='primary' htmlType='submit'>
                          Publish
                        </Button>
                        <Button onClick={handleRequestChanges}>
                          Request Changes
                        </Button>
                      </>
                    }

                  </>
                )}
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col >
    </Row >
  )
}
