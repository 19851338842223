import React, { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import type { TablePaginationConfig } from 'antd/es/table'
import { CheckCircleTwoTone, EditOutlined, ExclamationCircleOutlined, EyeOutlined, SyncOutlined, UploadOutlined, WarningTwoTone } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  Tabs,
  Tag,
  Typography,
} from 'antd'
import {
  Batch,
  MetaData,
  SKUFilters
} from '@a4b/api/src/modules/Monetisation/Puja/types'

const { Search } = Input

interface Props {
  isLoading: boolean
  itemList: Batch[]
  meta_data: MetaData | undefined
  onSearch: (value: string) => void
  onViewBatchClick: (batchCode: string, batch?: Batch) => void
  updateLatestBatchVideo: (batch: Batch) => void
  generateBatch: (isForcefully: boolean) => void
  handleTableChange: (paginationParams: TablePaginationConfig) => void
  pagination: TablePaginationConfig
  skuFilters: SKUFilters[] | undefined
  onTabChange: (activeTab: string) => void
  isComplete: boolean
  onCompleteChange: () => void
  reducedItemCount: any
  batchCount: number
  handleCompletedVideoPreview: (videoUrl: string, product_type: string, record: any) => void
  languageSelected: string
  isGenerateBatch: boolean
  handleClickUploadMedia: () => void
  showUploadButton: boolean
  selectionType: string
  rowSelection: any
  handleCreateDelivery: (record: any) => void
}

interface DataType {
  key: React.Key
  name: string
  age: number
  address: string
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BatchListPage: React.FC<Props> = ({
  isLoading,
  itemList,
  onSearch,
  onViewBatchClick,
  generateBatch,
  handleTableChange,
  pagination,
  skuFilters,
  onTabChange,
  isComplete,
  onCompleteChange,
  reducedItemCount,
  batchCount,
  handleCompletedVideoPreview,
  languageSelected,
  isGenerateBatch,
  handleClickUploadMedia,
  showUploadButton,
  selectionType,
  rowSelection,
  handleCreateDelivery,
  updateLatestBatchVideo,
}) => {

  const ShowClickPostError = ({ error }: any) => {
    const [visible, setVisible] = useState(false)
    const showErrorDetailsModal = () => {
      setVisible(true)
    }

    const hideErrorDetailsModal = () => {
      setVisible(false)
    }

    const subTableColumns = [
      {
        title: 'Order Id',
        dataIndex: 'order_id',
        key: 'order_id',
        align: 'center',
      },
      {
        title: 'Booking Id',
        dataIndex: 'booking_id',
        key: 'booking_id',
        align: 'center',
      },
      {
        title: 'Error',
        dataIndex: 'click_post_error',
        key: 'click_post_error',
        align: 'center',
      },
    ]
    return (
      <>
        {(error && error?.length) ? <div style={{ width: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px', cursor: 'pointer' }} onClick={showErrorDetailsModal}><WarningTwoTone style={{ fontSize: '24px' }} twoToneColor="red" /></div> : <div></div>}
        <Modal
          title="Click Post Error"
          visible={visible}
          onCancel={hideErrorDetailsModal}
          footer={null}
        >
          <Table
            columns={subTableColumns}
            dataSource={error}
            pagination={false} />
        </Modal>
      </>
    );
  }
  const baseColumns = [
    {
      title: 'Batch Id/Batch Code',
      dataIndex: 'batch_id',
      key: 'batch_id',
      filtered: true,
      fixed: 'left',
      render: (text: any, record: Batch) => <span>{record?.batch_code || record?.batch_id}</span>,
    },
    {
      title: 'No of Items',
      dataIndex: 'no_of_items',
      key: 'no_of_items',
      width: '5%',
    },
    {
      title: 'HashCode',
      dataIndex: 'hash_code',
      key: 'hash_code',
      // width: '10%',
      ellipsis: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: 'SKU Name',
      dataIndex: 'product_name',
      key: 'product_name',
      // width: '10%',
      render: (text: any, record: Batch) => <span>{record?.inventory_names?.[languageSelected] || text}</span>,
    },
    // {
    //   title: 'Unbatched Count',
    //   width: '10%',
    //   ellipsis: true,
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   render: (text: any, record: Batch) => {
    //     const filterInventory: any = Object.keys(meta_data?.batch_map || {})?.map((id: string) => { return id?.includes(record?.inventory_id) && meta_data?.batch_map?.[id] }).filter((item: any) => item)
    //     return <span>{filterInventory?.[0]?.count ? <Tag color='red'>{filterInventory?.[0]?.count}</Tag> : <Tag color='green'>Empty</Tag>}</span>
    //   },
    // },
    {
      title: 'SLA Breach Status',
      dataIndex: 'is_sla_breached',
      key: 'is_sla_breached',
      // width: '9%',
      render: (text: boolean, record: Batch) => {
        if (text) {
          return <Tag icon={<ExclamationCircleOutlined />} color='#f50'>SLA Breach</Tag>
        } else {
          return 'N/A'
        }
      }
    },
    {
      title: 'Temple Name',
      dataIndex: 'store_name',
      key: 'store_name',
      // width: '12%',
      ellipsis: true,
      render: (text: any, record: Batch) => <span>{record?.store_names?.[languageSelected] || text}</span>,
    },
    {
      title: 'Product Type',
      dataIndex: 'product_type',
      key: 'product_type',
      width: '8%',
      render: (text: any, record: Batch) => {
        if (text === 'pooja') {
          return <Tag color='#87d068'>{text?.toUpperCase()}</Tag>
        } else {
          return <Tag color='#108ee9'>{text?.toUpperCase()}</Tag>
        }
      }
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      // width: '8%',
      render: (text: any) => (
        <span>{moment.unix(text).format('ddd DD-MMM-YYYY, hh:mm A')}</span>
      ),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      // width: '6%',
      render: (text: any) => {
        if (text === 'processing') {
          return <Tag color='blue'>
            <span style={{ marginRight: '5px' }}>{text?.toUpperCase()}</span>
            <SyncOutlined spin color='blue' />
          </Tag>
        } else {
          return <Tag color='green'>
            <span>{text?.toUpperCase()}</span>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          </Tag>
        }
      },
    },
    {
      title: 'Action',
      key: 'action',
      // width: '8%',
      render: (text: any, record: any, index: any) => (
        <Row>
          <Col span={12}>
            {record.status === 'completed' ? (
              record?.video_url ? (
                <Button
                  type='link'
                  onClick={() => handleCompletedVideoPreview(record?.video_url, record?.media_type, record)}
                >
                  <EyeOutlined />
                  Preview Video
                </Button>
              ) : (
                <span>Video not available</span>
              )
            ) : (
              <Button type='link' onClick={() => onViewBatchClick(record?.batch_code, record)}>
                <EyeOutlined />
                View Order
              </Button>
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: 'Create Delivery Orders',
      dataIndex: 'Create Delivery Orders',
      key: 'Create Delivery Orders',
      fixed: 'right',
      // width: '8%',
      render: (text: string, record: any) => {
        const clickPostErrors = record?.meta_data?.click_post_errors?.length
        if (!record?.delivery_initiated && record?.product_type === 'pooja' && clickPostErrors) {
          return <Row style={{ width: '100%' }} justify='space-between'>
            <Button type='primary' onClick={() => handleCreateDelivery(record)}>Generate</Button>
            <ShowClickPostError error={record?.meta_data?.click_post_errors} />
          </Row>
        } else if (!record?.delivery_initiated && record?.product_type === 'pooja') {
          return <Button type='primary' onClick={() => handleCreateDelivery(record)}>Generate</Button>
        } else {
          return <span>-</span>
        }
      },
    }
  ]

  const newVideoColumn = {
    title: 'New Video',
    key: 'new_video',
    // width: '8%',
    render: (text: any, record: any, index: any) => (
      <Row>
        <Col span={12}>
          {record.status === 'completed' && (
            <Button type='link' onClick={() => updateLatestBatchVideo(record)}>
              <EditOutlined />
              Video url
            </Button>
          )}
        </Col>
      </Row>
    ),
  };

  const insertPosition = baseColumns?.length - 1;

  const columns = [...baseColumns]
  if (isComplete) {
    columns.splice(insertPosition, 0, newVideoColumn)
  }

  return (
    <Container style={{ width: '100%', height: '100%' }}>
      {showUploadButton && <Button
        type="primary"
        shape="circle"
        icon={<UploadOutlined />}
        size='large'
        style={{
          position: 'fixed', bottom: 40, right: 30,
          zIndex: 100,
          height: '50px',
          width: '50px'
        }}
        onClick={handleClickUploadMedia}
      />}
      <Space direction={'vertical'} style={{
        width: '100%'
      }}>
      </Space>
      <Row>
        <Col span={12}></Col>
        <Col span={12}>
          <Row justify='end'></Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs
            onChange={onTabChange}
            type='card'
            tabBarExtraContent={{
              left: (
                <Search
                  placeholder='search by batch code'
                  onSearch={(searchCrieteria: string) => {
                    onSearch(searchCrieteria)
                  }}

                  style={{ width: 380 }}
                  allowClear
                />
              ),
              right: (
                <Row style={{ width: 420 }} justify={'center'}>
                  <Col span={14}>
                    <Space direction='horizontal'>
                      <Typography.Title level={5}>
                        Show Completed Batch :{' '}
                        <Switch
                          checked={isComplete}
                          onChange={onCompleteChange}
                        ></Switch>
                      </Typography.Title>
                    </Space>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={9}>
                    <Button
                      type='primary'
                      onClick={() => generateBatch(true)}
                      disabled={isGenerateBatch}
                    >
                      Generate Batch
                    </Button>
                  </Col>
                </Row>
              ),
            }}
            centered
            defaultActiveKey='1'
          >
            <Tabs.TabPane tab={`(${batchCount})All SKU`} key='all_sku'>
              <Row style={{ paddingTop: '10px' }}>
                <Col span={24}>
                  <Table
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                    rowKey={(record: Batch) => record?.batch_id}
                    columns={columns}
                    dataSource={itemList}
                    pagination={pagination}
                    onChange={handleTableChange}
                    scroll={{ x: 2400 }}
                    size='middle'
                    loading={isLoading}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
            {skuFilters?.map((product: SKUFilters) => (
              <Tabs.TabPane
                tab={
                  reducedItemCount?.[product?.sku_id]
                    ? `(${reducedItemCount?.[product?.sku_id]})${product?.sku_name}`
                    : product?.sku_name
                }
                key={product.sku_id}
              >
                <Row style={{ paddingTop: '10px' }}>
                  <Col span={24}>
                    <Table
                      rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }}
                      rowKey={(record: Batch) => record?.batch_id}
                      columns={columns}
                      dataSource={itemList}
                      pagination={pagination}
                      onChange={handleTableChange}
                      scroll={{ x: 2400 }}
                      loading={isLoading}
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

export default BatchListPage
