import { City } from '@a4b/api/src/modules/Content/Temples/types'
import { LanguageState } from '@a4b/api/src/modules/Monetisation/Offerings/types'

export type Translation = {
  city_name: string
  state_name: string
  language_code: string
}

export type CityFormObject = {
  thumbnail_img_url: string[]
  translations: Translation[]
}

export const parseCityResponseData = (
  city: City,
  languageState: LanguageState,
) => {
  const cityFormObject: CityFormObject = {
    thumbnail_img_url: [city?.thumbnail_img_url],
    translations: Object.entries(city?.translations).map(
      ([language_code, translation]) => ({
        city_name: translation.city_name,
        state_name: translation.state_name,
        language_code,
      }),
    ),
  }
  return cityFormObject
}

export const parseCityCreationPayload = (data: any) => {
  const translations: any = {}
  data?.translations?.forEach(
    ({ city_name, state_name, language_code }: any) => {
      translations[language_code] = { city_name, state_name }
    },
  )
  const cityPayload: any = {
    thumbnail_img_url: data.thumbnail_img_url?.[0]?.replace(/\s/g, ''),
    translations,
  }
  return cityPayload
}
