import { Col, Form, Row, Button, Input, Select, Switch } from "antd"
import React from "react"
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'

interface Props {
    fields: any
    add: () => void
    remove: (item: any) => void
    cdnUrl: string
    preSignUrl: string
}
const { Option } = Select

const AlpBanner: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, }) => {
    return (
        <>
            {fields.map((field: any, index: number) => {
                return (
                    <div key={index} style={{
                        border: '1.5px dashed #e0dcdc',
                        marginRight: '0px',
                        marginBottom: '10px',
                        padding: '10px',
                    }}>
                        <Row justify="space-between" style={{ width: '100%' }}>
                            <Form.Item
                                label={`Title`}
                                name={[field.name, 'title']}
                                rules={[{ required: true, message: 'Title' }]}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                style={{ width: '38%' }}
                            >
                                <Input placeholder='Enter title' />

                            </Form.Item>
                            <Form.Item
                                label={`Sub title`}
                                name={[field.name, 'sub_title']}
                                rules={[{ required: true, message: 'Sub title' }]}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                style={{ width: '38%' }}
                            >
                                <Input placeholder='Enter Sub title' />
                            </Form.Item>
                            <Form.Item
                                label={`Visible`}
                                name={[field.name, 'media', 'visible']}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input Pooja enable',
                                    },
                                ]}
                                valuePropName='checked'
                                initialValue={true}
                            >
                                <Switch />
                            </Form.Item>
                            <Button
                                onClick={() => {
                                    remove(field.name)
                                }}
                                style={{ marginTop: '28px' }}
                                type='ghost'
                                icon={<DeleteOutlined />}
                                size='middle'
                                danger
                            ></Button>
                        </Row>
                        <Row justify="space-between" style={{ width: '100%' }}>
                            <Form.Item
                                label={`Deeplink`}
                                name={[field.name, 'deeplink']}
                                rules={[{ required: false, message: 'Deeplink' }]}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                style={{ flexBasis: '38%' }}
                            >
                                <Input placeholder='Enter Deeplink' />

                            </Form.Item>

                            <Form.Item
                                label={`Media type`}
                                name={[field.name, 'media', 'media_type']}
                                rules={[{ required: true, message: 'Select Media type' }]}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                style={{ flexBasis: '30%' }}
                            >
                                <Select
                                    placeholder='Select Media type'
                                >
                                    <Option value='image'>Image</Option>
                                    <Option value='gif'>GIF</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={`Media url`}
                                name={[field.name, 'media', 'media_url']}
                                valuePropName='fileList'
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                style={{ marginBottom: '0px', flexBasis: '20%' }}
                                rules={[
                                    {
                                        required: false,
                                        min: 0,
                                        max: 1,
                                        validator: async (
                                            rules: any,
                                            values: any,
                                        ) => {
                                            if (values?.length < rules.min) {
                                                return Promise.reject(
                                                    'Please Upload Image',
                                                )
                                            }
                                        },
                                    },
                                ]}
                            >
                                <S3Upload
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    min={1}
                                    max={1}
                                />
                            </Form.Item>
                        </Row>
                    </div>
                )
            })}
            <Col span={24} style={{ paddingTop: '5px' }}>
                <Row justify='start'>
                    <Col span={4}>
                        <Button
                            onClick={() => {
                                add()
                            }}
                            type='ghost'
                            icon={<PlusCircleOutlined />}
                            size='middle'
                        >
                            Add Banners
                        </Button>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default AlpBanner