import { Rashifal } from "@a4b/api/src/modules/Content/Rashifal/types";
import { Button, message, TableColumnType, TablePaginationConfig, TableProps } from "antd";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import { renderBoolean, renderImage } from "../../../../../components/TableHelpers/ColumnHelper";
import { Shrine } from "@a4b/api/src/modules/Temple/types";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CopyOutlined } from "@ant-design/icons";

export default function useTableConfig<T>(
  setActiveDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | undefined>>,
  dataSource: T[],
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
): TableProps<Rashifal.RashifalItem[]> {
  const { god_id = '', avatar_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();


  const navigate = useNavigate();

  const columns: TableColumnType<Shrine.AvatarPayload>[] = [
    {
      title: "Attachment",
      dataIndex: "attachment",
      render(value, record, index) {
        return renderImage(value.url);
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      render(value, record, index) {
        return <div>
          <p>
            Id: <b>{record?.id} <CopyOutlined style={{ fontSize: 20 }} onClick={() => { navigator.clipboard.writeText(record?.id!); message.info('Id Copied', 3) }} /></b>
          </p>
          <p>
            Code: <b>{value}</b>
          </p>
        </div>
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'AccessType',
      dataIndex: 'access_type',
    },
    {
      title: "Position",
      dataIndex: "position"
    },
    {
      title: "Names",
      dataIndex: "names",
      render(value, record, index) {
        return <div>
          <p>
            default: <b>{value.default}</b>
          </p>
          <p>
            english: <b>{value.english}</b>
          </p>
        </div>
      },
    },
    {
      title: "Status",
      dataIndex: "active",
      render: renderBoolean
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* <Switch style={{ width: '90px' }} checked={record.active} unCheckedChildren="Activate" /> */}
          <Button type="link" onClick={() => {
            navigate(`/shrine/gods/${god_id}/avatars/` + value + "?" + searchParams.toString())
          }}>Edit</Button>
          {/* <Button type="link" >Delete</Button> */}
        </div>
      },
    }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  //@ts-ignore
  return { columns, dataSource, pagination, onChange: tableOnChange }
}