import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { message } from 'antd'
import type { TablePaginationConfig } from 'antd/es/table'
import { useEffect, useState, useCallback } from 'react'
import ManageAstroTable, { AstrologerSegment, FILTERS, Tfilter } from './components/ManageAstroListPage'
import { AstroAnalytics, AstroDetails } from '@a4b/api/src/modules/Monetisation/Astrologer/types'
import { openNotification } from '../../../../../utils'
import { useSearchParams } from 'react-router-dom'
import { A4B_APPS } from '@/utils/constants'
import MonetisationAstroApi from '@a4b/api/src/modules/Monetisation/Astrologer'

interface AstrologerListProps {
}

const AstrologerList: React.FC<AstrologerListProps> = () => {
    const { networkInstance, countryState, app } = useAppContext()
    const { monetisationAstroApi, monetisationNewAstroApi } = networkInstance.clientWithHeaders
    const api: MonetisationAstroApi = (app && app === A4B_APPS.SRIMANDIR) ? monetisationAstroApi : monetisationNewAstroApi
    const isSrimandir = app === A4B_APPS.SRIMANDIR
    const [astroList, setAstroList] = useState<AstroDetails[]>()
    const [astroAgentAnalytics, setAstroAgentAnalytics] = useState<{
        [key: string]: AstroAnalytics
    }>()
    const [astroAnalytics, setAstroAnalytics] = useState<{
        [key: string]: AstroAnalytics
    }>()
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 100,
    })
    const [queryParams] = useSearchParams();
    const segment: AstrologerSegment = queryParams.get('segment') as AstrologerSegment || AstrologerSegment.LIST;
    const [filter, setFilter] = useState<Tfilter>("today");

    const getAgentList = useCallback(async () => {
        try {
            const agentListResponse = await api.agentApi.getAllAgents({
                limit: pagination.pageSize!,
                offset:
                    Number((pagination?.current || 1) - 1) * Number(pagination.pageSize)
            }, countryState?.countryCode)
            if (!agentListResponse?.data?.data) {
                message.info('No data found', 2)
                setAstroList([])
                setPagination({ ...pagination, total: 0 })
                return
            }
            setAstroList(agentListResponse?.data?.data)
            setPagination({ ...pagination, total: agentListResponse?.data?.count || 100 })
        } catch (error) {
            message.error('Error while fetching Agents', 3)
        }
    }, [api.agentApi, pagination?.current, countryState?.countryCode])


    const updateStatus = useCallback(async (id: string, status: string) => {
        try {
            await api.agentApi.updateAgentStatus(id, { status })
            getAgentList();
            openNotification("success", { "message": "Status update successful." })
        } catch (error) {
            openNotification("error", { "message": "Something went wrong!" })
        }
    }, [getAgentList, api.agentApi])

    useEffect(() => {
        getAgentList()
    }, [getAgentList])

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPagination({
            ...pagination,
        })
    }

    const getAgentAnalytics = useCallback(async () => {
        if (segment === AstrologerSegment.ANALYTICS) {
            try {
                const promise = astroList?.map(async (agent) => {
                    return api.agentApi.getAgentAnalytics(agent.id, filter)
                })
                const response = await Promise.all(promise!);
                const agentAnalytics = response.reduce((acc, res, index) => {
                    acc[astroList![index].id] = res?.data?.data
                    return acc
                }, {} as { [key: string]: AstroAnalytics })
                setAstroAgentAnalytics(agentAnalytics)
            } catch (error) {
                message.error('Error while fetching Agents', 3)
            }
        }
    }, [astroList, filter, api.agentApi, segment])


    const getAstroAnalytics = useCallback(async () => {
        if (segment === AstrologerSegment.ANALYTICS) {
            try {

                const promise = FILTERS?.map(async (filter) => {
                    return api.agentApi.getAnalytics(filter)
                })
                const response = await Promise.all(promise!);
                const agentAnalytics = response.reduce((acc, res, index) => {
                    acc[FILTERS[index]] = res?.data?.data
                    return acc
                }, {} as { [key: string]: AstroAnalytics })
                setAstroAnalytics(agentAnalytics)
            } catch (error) {
                message.error('Error while fetching Agents', 3)
            }
        }
    }, [api.agentApi, segment])

    useEffect(() => {
        getAgentAnalytics()
    }, [getAgentAnalytics])

    useEffect(() => {
        getAstroAnalytics()
    }, [getAstroAnalytics])

    return (
        //  backgroundColor: isSrimandir ? '#69b1ff' : '#ffd591'
        <div style={{ padding: '20px', marginTop: '0px', minHeight: '100vh' }}>
            <div style={{ marginTop: '40px' }}>
                <ManageAstroTable
                    astroList={astroList!}
                    handleTableChange={handleTableChange}
                    pagination={pagination}
                    updateStatus={updateStatus}
                    segment={segment}
                    filter={filter}
                    setFilter={setFilter}
                    astroAgentAnalytics={astroAgentAnalytics}
                    astroAnalytics={astroAnalytics}
                />
            </div>
        </div>
    )
}

export default withDefaultLayout(AstrologerList)
