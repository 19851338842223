import React from 'react';
import {
    Table,
    Tag,
    Tooltip,
    Button,
} from 'antd'
import type { ColumnsType } from 'antd/es/table';

interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
}

interface Props {
    storeContentList: any
    triggerEdit: (record: any) => void
}

const formatDate = (date: string): string => {
    if (date) {
        const newDate = new Date(date);
        const day = newDate.getUTCDate().toString().padStart(2, '0');
        const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(newDate);
        const year = newDate.getUTCFullYear().toString();
        let hours = newDate.getUTCHours();
        const minutes = newDate.getUTCMinutes().toString().padStart(2, '0');

        let period = 'am';
        if (hours >= 12) {
            period = 'pm';
        }

        if (hours > 12) {
            hours -= 12;
        } else if (hours === 0) {
            hours = 12;
        }

        const formattedTime = `${hours}:${minutes} ${period}`;

        return `${day}-${month}-${year} - ${formattedTime}`;
    }
    return "-";
};


const ManageSchedulerPage: React.FC<Props> = ({ storeContentList, triggerEdit }) => {
    const data = storeContentList

    const columns: ColumnsType<DataType> = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Store Id',
            dataIndex: 'store_id',
            key: 'store_id',
            width: '17%',
            ellipsis: {
                showTitle: false,
            },
            render: (store_id: string) => (
                <Tooltip placement="topLeft" title={store_id}>
                    {store_id}
                </Tooltip>),
        },
        {
            title: 'Start time',
            dataIndex: 'start_datetime',
            key: 'start_datetime',
            width: '15%',
            render: (text: string) => {
                return formatDate(text)
            }
        },
        {
            title: 'End time',
            dataIndex: 'end_datetime',
            key: 'end_datetime',
            width: '15%',
            render: (text: string) => {
                return formatDate(text)
            }
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            width: '15%',
            render: (text: string) => {
                return text
            }
        },
        {
            title: 'Language',
            dataIndex: 'language_code',
            key: 'language_code',
            width: '10%',
            render: (text: string) => {
                return <Tag color='blue' >
                    {String(text)}
                </Tag>
            },
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: '10%',
            render: (text: string, record: any) => {
                return <Button onClick={() => { triggerEdit(record) }} type='link' color='cyan' style={{ cursor: 'pointer' }}>Edit</Button>
            }
        },
    ];

    return (
        <Table
            dataSource={data}
            columns={columns}
        />
    )

}

export default ManageSchedulerPage
