
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button, Checkbox, Select } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination from "@/hooks/useAntdPagination";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { openNotification } from "../../../../utils";
import { Shrine } from "@a4b/api/src/modules/Temple/types";
import { ArrowLeftOutlined, BackwardOutlined } from "@ant-design/icons";

const AvatarPage = function () {
  const { networkInstance } = useAppContext();
  const { pagination, setPagination } = useAntdPagination();
  const [god, setGod] = useState<Shrine.God>();
  const [avatars, setAvatars] = useState<Shrine.AvatarPayload[]>([]);
  const { god_id = '', avatar_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (avatar_id) {
      setActiveDrawer(DRAWER_TYPE.UPDATE_AVATAR)
    }
  }, [avatar_id])

  const loadAvatars = useCallback(async () => {
    try {
      const avatarsRes = await networkInstance.clientWithHeaders.shrineApi.getAvatars(god_id, searchParams.toString());
      setAvatars(avatarsRes.data.avatars.map((avatar: Shrine.AvatarPayload) => {
        if (avatar.attachment?.url) {
          avatar.attachment['path'] = new URL(avatar.attachment?.url)?.pathname;
        }
        if (avatar.paid_avatar_details) {
          if (avatar.paid_avatar_details?.playback_audio?.url) {
            avatar.paid_avatar_details.playback_audio['path'] = new URL(avatar.paid_avatar_details?.playback_audio?.url)?.pathname;
          }
          if (avatar.paid_avatar_details?.playback_audio_image?.url) {
            avatar.paid_avatar_details.playback_audio_image['path'] = new URL(avatar.paid_avatar_details?.playback_audio_image?.url)?.pathname;
          }
          if (avatar.paid_avatar_details?.lock_icon?.url) {
            avatar.paid_avatar_details.lock_icon['path'] = new URL(avatar.paid_avatar_details?.lock_icon?.url)?.pathname;
          }
          if (avatar.paid_avatar_details?.curtain_unlocked?.url) {
            avatar.paid_avatar_details.curtain_unlocked['path'] = new URL(avatar.paid_avatar_details?.curtain_unlocked?.url)?.pathname;
          }
          if (avatar.paid_avatar_details?.curtain_locked?.url) {
            avatar.paid_avatar_details.curtain_locked['path'] = new URL(avatar.paid_avatar_details?.curtain_locked?.url)?.pathname;
          }
          if (avatar.paid_avatar_details?.default_attachment?.url) {
            avatar.paid_avatar_details.default_attachment['path'] = new URL(avatar.paid_avatar_details?.default_attachment?.url)?.pathname;
          }
        }
        if (avatar?.promotional_avatar_details?.audio_attachment?.url) {
          avatar.promotional_avatar_details.audio_attachment['path'] = new URL(avatar?.promotional_avatar_details?.audio_attachment?.url)?.pathname;
        }
        // avatar.attachment.path = new URL(avatar.attachment?.path)?.pathname;
        // avatar.attachment.path = new URL(avatar.attachment?.path)?.pathname;
        // avatar.attachment.path = new URL(avatar.attachment?.path)?.pathname;
        return avatar;
      }))
    } catch (error) {
      openNotification("error", { message: "Failed to load avatars" })
    }
  }, [god_id, networkInstance.clientWithHeaders.shrineApi, searchParams])


  const getGodDetails = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.shrineApi.getGod(god_id);
      setGod(res.data.data.god);
    } catch (error) {
      openNotification("error", { message: "Failed to load god details" });
    }
    loadAvatars();
  }, [god_id, loadAvatars, networkInstance.clientWithHeaders.shrineApi])

  useEffect(() => {
    getGodDetails();
  }, [getGodDetails])
  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }


  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const onDrawerClose = () => {
    setActiveDrawer(undefined);
    navigate(`/shrine/gods/${god_id}/avatars?${searchParams.toString()}`)
  }
  const drawerConfig = useDrawerConfig(loadAvatars, onDrawerClose, setActiveDrawer, god_id, avatars.find((avatar) => (avatar_id === avatar.id)), activeDrawer);
  const { drawerProps, drawerContent } = drawerConfig;

  const tableConfig = useTableConfig(setActiveDrawer, avatars, pagination, setPagination, setDrawer,);

  const createAvatar = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_AVATAR);
  }

  return <SocialScreenContainer
    title={
      <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", paddingRight: "10vw" }}>
          <img src={god?.thumbnails?.large?.url} height={50} width={50} alt={'god image'} /> &nbsp;
          <div>code : <b>{god?.code}</b></div>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: '1px solid #ccc',
          borderTop: '1px solid #ccc',
          padding: '12px 25px', gap: '8px'
        }}>
          <Select allowClear placeholder={"Type"} value={searchParams.get('type')} onChange={(value) => {
            if (value) {
              searchParams.set("type", value)
              setSearchParams(searchParams)
              loadAvatars();
            } else {
              searchParams.delete("type")
              setSearchParams(searchParams)
            }
          }
          } style={{ width: '200px' }}>
            <Select.Option value={'default'}> default</Select.Option>
            <Select.Option value={'layered'}> layered</Select.Option>
            <Select.Option value={'temple'}> temple</Select.Option>
            <Select.Option value={'promotional'}> promotional</Select.Option>
          </Select>
          <Select allowClear placeholder={"Access type"} value={searchParams.get('access_type')} onChange={(value) => {
            if (value) {
              searchParams.set("access_type", value)
              setSearchParams(searchParams)
              loadAvatars();
            } else {
              searchParams.delete("access_type")
              setSearchParams(searchParams)
            }
          }} style={{ width: '200px' }}>
            <Select.Option value={'free'}> free</Select.Option>
            <Select.Option value={'paid'}> paid</Select.Option>
          </Select>
          <Checkbox
            checked={Boolean(searchParams.get('active') === "true")}
            onChange={(e) => {
              if (e.target.checked) {
                searchParams.set("active", e.target.checked.toString())
                setSearchParams(searchParams)
                loadAvatars();
              } else {
                searchParams.delete("active")
                setSearchParams(searchParams)
              }
            }}
          ></Checkbox>
          <label>Active</label>
          <Checkbox
            checked={Boolean(searchParams.get('active') === "false")}
            onChange={(e) => {
              if (e.target.checked) {
                searchParams.set("active", "false")
                setSearchParams(searchParams)
                loadAvatars();
              } else {
                searchParams.delete("active")
                setSearchParams(searchParams)
              }
            }}
          ></Checkbox>
          <label>Inactive</label>
        </div>
      </div>
    }
    // filtersUI={<Filters />}
    extra={
      <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
        <Button onClick={() => { navigate("/shrine/gods") }}><ArrowLeftOutlined />  Back  </Button>
        &nbsp;
        <Button type="primary" onClick={createAvatar}> Create Avatar </Button>
        <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => navigate('/shrine/schedule?type=avatar')}>Avatar Schedule</Button>
      </div>
    }
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />

};

export default withDefaultLayout(AvatarPage)