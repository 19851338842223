import { Button, Checkbox, Form, Input, Select } from "antd";
import { useState } from "react";

interface FiltersProps {
  updateCardType: (cardType: 'shubh_mantra' | 'god_set') => void
  updateIsActive: (isActive: boolean) => void
  cardType: "shubh_mantra" | "god_set"
  isActive: boolean
  getSuggestiveCards: (cardType: any, isActive: any) => Promise<void>
}
export default function Filters(props: FiltersProps) {
  const { updateCardType, updateIsActive, cardType, isActive, getSuggestiveCards } = props
  return <Form
    layout="inline"
  >
    <Form.Item
      label={'Card type'}
    >
      <Select style={{ width: '250px' }} value={cardType} onChange={updateCardType}>
        <Select.Option value="shubh_mantra"> Shubh mantra </Select.Option>
        <Select.Option value="god_set"> God set </Select.Option>
      </Select>
    </Form.Item>
    <Form.Item
      label=" Is active"
    >
      <Checkbox checked={isActive} onChange={(e) => { updateIsActive(e.target.checked) }}></Checkbox>
    </Form.Item>
    <Form.Item>
      <Button type="primary" onClick={() => { getSuggestiveCards(cardType, isActive) }}> Apply </Button>
    </Form.Item>
  </Form>
} 