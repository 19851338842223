import { MiniMandir } from "@/entities/feeds/Widgets";
import NumberInput from "@/modules/feeds/components/Number";
import { Col, Form, Input, Row } from "antd";
import React from "react";

interface Props {
  contentInstance: MiniMandir;
}
const MiniMandirWidget: React.FC<Props> = ({ contentInstance }) => {
  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              required={true}
              label="Default Cta"
              name={["content", "default_cta_text"]}
              rules={[
                {
                  validator: contentInstance.defaultCtaValidator,
                },
              ]}
            >
              <Input placeholder="Default cta" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              required={true}
              label="Main Cta"
              name={["content", "main_cta_text"]}
              rules={[
                {
                  validator: contentInstance.mainCtaValidator,
                },
              ]}
            >
              <Input placeholder="Main cta" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              required={true}
              label="Item Count"
              name={["content", "item_count"]}
              rules={[
                {
                  validator: contentInstance.mainCtaValidator,
                },
              ]}
            >
              <NumberInput initValue={0} placeholder="Item count" />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MiniMandirWidget;
