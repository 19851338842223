import React, { useCallback, useState, useEffect } from 'react'
import { Button, Form, Input, Modal, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'

//types
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload/interface'
import { AttachmentServiceResponse } from 'types'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { LibraryMediaType, SupportedContentFormats } from '@a4b/api'

export type ImageFormFields = {
  images: UploadFile<AttachmentServiceResponse>[]
}

const { useForm } = Form

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

interface Props {
  showModal: boolean
  uploadFunction: (
    media_type: LibraryMediaType,
    fileFormat: SupportedContentFormats,
    file: RcFile,
  ) => Promise<string>
  onSubmit: (values: ImageFormFields) => void
  onClose: () => void
}
export const ImageMarkdownPopup: React.FC<Props> = ({
  showModal,
  uploadFunction,
  onSubmit,
  onClose,
}) => {
  const [imageForm] = useForm<ImageFormFields>()

  useEffect(() => {
    if (!showModal) {
      imageForm.resetFields()
    }
  }, [showModal])

  const onModalCancel = useCallback(() => {
    onClose()
  }, [])

  const handleImageFormSubmit = (values: ImageFormFields) => {
    onSubmit(values)
  }

  const handleImageFormFailed = (
    errorInfo: ValidateErrorEntity<ImageFormFields>,
  ) => {
    console.log({ errorInfo })
  }

  const onUpload: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
  }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await uploadFunction('image', 'image', file)
    if (onSuccess) {
      onSuccess(response)
    }
  }

  return (
    <Modal
      title='Insert Image Link'
      visible={showModal}
      onCancel={onModalCancel}
      footer={null}
    >
      <Form
        form={imageForm}
        onFinish={handleImageFormSubmit}
        onFinishFailed={handleImageFormFailed}
      >
        <Form.Item
          label='Image'
          name='images'
          valuePropName='fileList'
          getValueFromEvent={normFile}
          rules={[{ required: true, message: 'Please upload a image file!' }]}
        >
          <Upload
            accept={'image/*'}
            customRequest={onUpload}
            name='input_file'
            listType='text'

          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
