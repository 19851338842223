import { throwError } from '../../../utils/helper';

type ActionItem = {
  image_attachment_id: string;
  title: string;
  subtitle: string;
  icon_attachment_id: string;
  cta_action_type: string;
  cta_redirection_type?: string;
  r_song_id?: string;
  song_of_the_day?: boolean;
  r_article_id?: string;
  r_god_id?: number;
  video_attachment_id?: string;
  video_attachment_type?: string; //temp valiable
};

class Action {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items!: ActionItem[];

  getSerializedData = (contentData: Action) => {
    let items = contentData.items?.map((item: ActionItem): ActionItem => {
      delete item?.video_attachment_type;
      if (item?.r_god_id === 0) delete item.r_god_id;
      if (item.song_of_the_day) delete item.r_song_id;
      if (!item.song_of_the_day) delete item.song_of_the_day;
      return {
        ...item,
      };
    });
    contentData.items = items;
    return contentData;
  };
  getDeSerializedData = (actionFormData: Action) => {
    return actionFormData;
  };

  titleValidator = async (rule: any, value: any) => {
    if (!value) throwError('Title required.');
  };
  subtitleValidator = async (rule: any, value: any) => {
    if (!value) throwError('SubTitle required.');
  };
  imageAttachmentValidator = async (rule: any, value: any) => {
    if (Array.isArray(value)) {
      let isEmptyPresent = value.some((item: string) => !item);
      isEmptyPresent && throwError('UC code should not be empty.');
    } else {
      if (!value) throwError('Image Attachment required.');
    }
  };
  iconAttachmentValidator = async (rule: any, value: any) => {
    if (!value) throwError('Icon Attachment required.');
  };
  ctaActionValidator = async (rule: any, value: any) => {
    if (!value) throwError('CTA Action Type required.');
  };
  songIdValidator = async (rule: any, value: any) => {
    if (!value) throwError('Song ID required.');
  };
  articleIdValidator = async (rule: any, value: any) => {
    if (!value) throwError('Article ID required.');
  };
  redirectionTypeValidator = async (rule: any, value: any) => {
    if (!value) throwError('Redirection Type required.');
  };

  videoAttachmentTypeValidator = async (rule: any, value: any) => {
    if (!value) throwError('Video Attachment Type required.');
  };
  videoRefCodeValidator = async (rule: any, value: any) => {
    if (!value) throwError('Video ref Code required.');
  };
}
export default Action;
export const ActionInstance = new Action();
