import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import AntdFormWrapper from "../../../../../components/Forms/AntdFormWrapper";
import { FormInstance } from "antd";
import CreateAvatarForm from "../components/CreateAvatarForm";
import { Shrine } from "@a4b/api/src/modules/Temple/types";
import { useAppContext } from "../../../../../components/AppContext";


export enum DRAWER_TYPE {
    CREATE_AVATAR = 'Create Avatar',
    UPDATE_AVATAR = 'Update Avatar'
}

export default function useDrawerConfig(
    loadAvatars: () => Promise<void>,
    onDrawerClose: () => void,
    setActiveDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | undefined>>,
    god_id: string, avatar: Shrine.AvatarPayload | undefined,
    activeDrawer?: DRAWER_TYPE
): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {
    const { networkInstance } = useAppContext()
    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_AVATAR,
                width: 1000,
            },
            drawerContent: <AntdFormWrapper<Shrine.AvatarPayload, Shrine.AvatarPayload>
                FormFields={CreateAvatarForm}
                createApi={async (payload) => {
                    return networkInstance.clientWithHeaders.shrineApi.createAvatars(god_id, payload)
                }}
                submitSuccessfulCallback={() => {
                    onDrawerClose();
                    loadAvatars()
                }}
            />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.UPDATE_AVATAR,
                width: 900,
            },
            drawerContent: <AntdFormWrapper<Shrine.AvatarPayload, Shrine.AvatarPayload>
                formData={avatar}
                FormFields={CreateAvatarForm}
                updateApiId={
                    async (avatarId, payload) => {
                        return networkInstance.clientWithHeaders.shrineApi.updateAvatars(god_id, avatarId, payload)
                    }
                }
                submitSuccessfulCallback={() => {
                    onDrawerClose();
                    loadAvatars()
                }}
            />
        }
    ], [avatar, god_id, loadAvatars, networkInstance.clientWithHeaders.shrineApi, onDrawerClose])
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}