import { Shrine } from '@/../../../packages/a4b-api/src/modules/Temple/types'
import { useAppContext } from '@/components/AppContext'
import { FormItemSelect } from '@/components/Forms/Select'
import { openNotification } from '@/utils'
import { MoreOutlined, WarningOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Dropdown,
  Empty,
  Form,
  InputNumber,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
} from 'antd'
import 'antd/dist/antd.css'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { GOD_ITEM_TYPES } from '../screens/god-items/dynamic-items-definitions'
import GodCard from './GodCard'

const DISPLAY_POS = [
  { value: 'left_1', lable: 'Left 1' },
  { value: 'left_2', lable: 'Left 2' },
  { value: 'left_3', lable: 'Left 3' },
  { value: 'right_1', lable: 'Right 1' },
  { value: 'right_2', lable: 'Right 2' },
]
export interface GodItemMappingProps {
  god: Shrine.God
}

const GodItemMapping = ({ god }: GodItemMappingProps) => {
  const { networkInstance, userProfile } = useAppContext()
  const [form] = Form.useForm()
  const [orderMappingForm] = Form.useForm()
  const [avatarCodes, setAvatarCodes] = useState<string[]>([])
  const [selectedAvatarCode, setselectedAvatar] = useState<string>('')
  const [selectedItemType, setSelectedItemType] = useState<
    GOD_ITEM_TYPES | undefined | string
  >(GOD_ITEM_TYPES?.FLOWER)
  const [godItems, setGodItems] = useState<Shrine.GodItem[]>([])

  const [filteredGodItems, setFilteredGodItems] = useState<Shrine.Item[]>([])
  const [isEditModalOpen, setModalState] = useState(false)
  const [isAddModalOpen, setAddModalState] = useState(false)
  const [currentEditGodItem, setCurrentEditGodItem] = useState<Shrine.GodItem>()
  console.log({ godItems, filteredGodItems })


  const loadGodAvatars = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.shrineApi.getGodAvatarCodes(
        god.id,
      )
      setAvatarCodes(res.data.avatar_codes)
    } catch (error) { }
  }, [god.id, networkInstance.clientWithHeaders.shrineApi])

  useEffect(() => {
    loadGodAvatars()
  }, [loadGodAvatars])

  const getGodItems = useCallback(
    async (godId, avatarCode, itemType) => {
      if (!godId || !avatarCode || !itemType) {
        return
      }
      try {
        const godItems = await networkInstance.clientWithHeaders.shrineApi.getGodItems_v2(
          300,
          0,
          itemType,
          true
        )
        const godItemAssociationMap =
          await networkInstance.clientWithHeaders.shrineApi.getGodItemAssocaitionByFilter(
            godId,
            avatarCode,
            itemType,
          )
        const { items } = godItemAssociationMap?.data?.data
        const filteredGodItemAssociationMap: any =
          godItems.data.data.items?.filter(
            (item: Shrine.GodItem) =>
              item?.active &&
              !items?.find(
                (godItem: Shrine.GodItem) => godItem?.item_id === item.id,
              ),
          )
        setFilteredGodItems(filteredGodItemAssociationMap)
        setGodItems(items)
      } catch (error) {
        console.log('Error', error)
      }
    },
    [networkInstance.clientWithHeaders.shrineApi],
  )

  useEffect(() => {
    if (selectedAvatarCode) return
    form.setFieldValue('god_avatar_code', 'god_default')
    setselectedAvatar('god_default')
    getGodItems(god?.id, 'god_default', selectedItemType)
  }, [form, getGodItems, god?.id, selectedAvatarCode, selectedItemType])

  const handleFormChange = useCallback(
    (values: any) => {
      if (values?.[0]?.name?.[0] === 'god_avatar_code') {
        setselectedAvatar(values?.[0]?.value)
        getGodItems(god?.id, values?.[0]?.value, selectedItemType)
      }
    },
    [getGodItems, god?.id, selectedItemType],
  )

  const onTabChange = async (key: string) => {
    setSelectedItemType(key)
    getGodItems(god?.id, selectedAvatarCode, key)
    orderMappingForm.resetFields()
  }

  const editOrderFormHandler = (values: any) => { }

  const handleEdit = useCallback(
    (currentEdit: Shrine.GodItem) => {
      setCurrentEditGodItem(currentEdit)
      orderMappingForm.setFieldValue('item_type', currentEdit?.item_code)
      orderMappingForm.setFieldValue(
        'display_order',
        currentEdit?.display_order,
      )
      orderMappingForm.setFieldValue(
        'display_pos',
        currentEdit?.property?.display_pos,
      )
      orderMappingForm.setFieldValue('item_id', currentEdit?.item_id)

      setModalState(true)
    },
    [orderMappingForm],
  )

  const deleteGodItemAssciation = useCallback(
    async (itemId: string, godAvatarCode?: string) => {
      if (god?.id === undefined) return
      if (godAvatarCode === undefined) return
      try {
        await networkInstance.clientWithHeaders.shrineApi.deleteGodItemAssocaition_2(
          itemId,
        )
        openNotification('success', {
          message: `Association deleted sucessfully.`,
        })
        getGodItems(god?.id, selectedAvatarCode, selectedItemType)
      } catch (error: any) {
        openNotification('error', { message: `Association deletion failed.` })
      }
    },
    [
      getGodItems,
      god?.id,
      networkInstance.clientWithHeaders.shrineApi,
      selectedAvatarCode,
      selectedItemType,
    ],
  )

  /* Handle display order mapping form create and edit flow */
  const handleFormSubmit = useCallback(async () => {
    orderMappingForm?.validateFields().then(async values => {
      if (isEditModalOpen) {
        const { display_order, display_pos } = values
        const payload: any = { display_order, id: currentEditGodItem?.id }
        if (selectedItemType === GOD_ITEM_TYPES.CTA) {
          payload['property'] = {
            display_pos: display_pos,
          }
        }
        try {
          await networkInstance.clientWithHeaders.shrineApi.updateAssociatedItemToGod(
            god.id,
            payload,
          )
          openNotification('success', {
            message: `god id ${god.id} update associate sucessfully`,
          })
        } catch (error: any) {
          openNotification('error', {
            message: `god id ${god.id} associated failed with item id ${values.item_id}`,
          })
        }
      } else {
        const { display_order, item_type, display_pos } = values
        const payload: any = {
          display_order,
          item_id: item_type,
          god_avatar_code: selectedAvatarCode, // @ts-ignore
          property: {},
        }
        if (selectedItemType === GOD_ITEM_TYPES.CTA) {
          payload['property'] = {
            display_pos: display_pos,
          }
        }
        try {
          // @ts-ignore
          await networkInstance.clientWithHeaders.shrineApi.associateItemToGod(
            god.id,
            payload,
          )
          openNotification('success', {
            message: `god id ${god.id} associated sucessfully with item id ${item_type}`,
          })
        } catch (error: any) {
          openNotification('error', {
            message: `god id ${god.id} associated failed with item id ${values.item_id}`,
          })
        }
      }

      orderMappingForm.resetFields()
      setModalState(false)
      setAddModalState(false)
      getGodItems(god?.id, selectedAvatarCode, selectedItemType)
    })
  }, [
    currentEditGodItem?.id,
    orderMappingForm,
    getGodItems,
    god.id,
    isEditModalOpen,
    networkInstance.clientWithHeaders.shrineApi,
    selectedAvatarCode,
    selectedItemType,
  ])

  const columns = useMemo(
    () => [
      {
        title: 'Display order',
        dataIndex: 'display_order',
        width: '15%',
      },
      {
        title: 'God Level Mapped',
        dataIndex: 'god_level_mapping',
        width: '15%',
        render: (default_god_map: { type: string; url: string }) =>
          default_god_map ? (
            <Tag color='green'>Yes</Tag>
          ) : (
            <Tag color='red'>No</Tag>
          ),
      },
      {
        title: 'Thumbnail',
        dataIndex: 'item_thumbnail',
        render: (thumbnail: { type: string; url: string }) => (
          <img src={thumbnail.url} height={50} width={50} alt={'god image'} />
        ),
      },
      {
        title: 'Code',
        dataIndex: 'item_code',
      },
      {
        title: 'Access type info',
        dataIndex: 'access_type',
        render(value: string, record: any, index: number) {
          console.log({ value, record })
          switch (value) {
            case 'free':
              return <div>
                Access Type: <b>{value}</b>
              </div>
            case 'coins_based':
              return <div>
                Access Type: <b>{value}</b>
                Coins threshold : <b>{record?.coins_threshold}</b>
                Validity prd secs: <b>{record?.validity_prd_secs}</b>
              </div>
            case 'days_active_based':
              return <div>
                Access Type: <b>{value}</b>
                Coins threshold : <b>{record?.coins_threshold}</b>
                Days active threshold : <b>{record?.days_active_threshold}</b>
                Validity prd secs: <b>{record?.validity_prd_secs}</b>
              </div>
            case 'vip':
              return <div>
                Access Type: <b>{value}</b>
              </div>
            default:
              return null
          }
        }
      },
      {
        title: 'Action',
        width: 120,
        dataIndex: 'id',
        render: (id: string, dataSource: Shrine.GodItem) => {
          const menu = (
            <Menu>
              <Menu.Item key='edit' onClick={() => handleEdit(dataSource)}>
                <Button type='link'> Edit </Button>
              </Menu.Item>
              <Menu.Item
                key='god_item_mapping'
                onClick={() => {
                  deleteGodItemAssciation(id, dataSource.id)
                }}
              >
                <Button
                  type='link'
                  onClick={() => {
                    //@ts-ignore
                  }}
                >
                  {' '}
                  Delete assocaition{' '}
                </Button>
              </Menu.Item>
            </Menu>
          )
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
              }}
            >
              <Dropdown overlay={menu} placement='bottomLeft'>
                <div>
                  {' '}
                  <MoreOutlined style={{ fontSize: 30 }} />
                </div>
              </Dropdown>
              {/* <Link  to={`/temple/god-items/${id}/flower`} > Associate flower </Link>
        <Link to={`/temple/god-items/${id}/garland`} > Associate garland </Link> */}
            </div>
          )
        },
      },
    ],
    [deleteGodItemAssciation, handleEdit],
  )

  return (
    <>
      <Form form={form} onFieldsChange={handleFormChange} layout='vertical'>
        <Space direction='vertical' size='middle' style={{ width: '100%' }}>
          <GodCard god={god}></GodCard>
          <FormItemSelect
            name={'god_avatar_code'}
            antdFormItemProps={{ rules: [{ required: true }] }}
            antdSelectProps={{ style: { width: '30%' } }}
            optionList={avatarCodes.map(avatarCode => (
              <Select.Option value={avatarCode}> {avatarCode} </Select.Option>
            ))}
            selected={selectedAvatarCode}
          />
          <Button
            onClick={() => setAddModalState(true)}
            disabled={!selectedAvatarCode}
          >
            Associate Item
          </Button>
          {!selectedAvatarCode ? (
            <Row>
              <Col span={24}>
                <Empty
                  description={
                    <span>
                      <p>No Data</p>
                      Please select avatar code
                    </span>
                  }
                />
              </Col>
            </Row>
          ) : (
            <Tabs onChange={onTabChange} style={{ width: '100%' }}>
              {Object.values(GOD_ITEM_TYPES)?.map((item: string) => (
                <Tabs.TabPane tab={item} key={item}>
                  <Table {...{ columns, dataSource: godItems }}></Table>
                </Tabs.TabPane>
              ))}
            </Tabs>
          )}
        </Space>
      </Form>
      <Modal
        title={isAddModalOpen ? 'Accociate item' : `Edit associated item`}
        visible={isEditModalOpen || isAddModalOpen}
        onOk={() => { }}
        onCancel={() => {
          setModalState(false)
          setAddModalState(false)
        }}
        width={800}
        footer={[
          <Button
            key='back'
            onClick={() => {
              setModalState(false)
              setAddModalState(false)
            }}
          >
            Cancel
          </Button>,
          isEditModalOpen ? (
            selectedAvatarCode === 'god_default' ? (
              <Popconfirm
                placement='topLeft'
                title='This item is associated with default god,Are you sure you want to edit ?'
                onConfirm={handleFormSubmit}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  htmlType='submit'
                  onClick={(event: React.MouseEvent) => {
                    orderMappingForm
                      .validateFields()
                      .catch(() => event?.stopPropagation())
                  }}
                >
                  {' '}
                  Update
                </Button>
              </Popconfirm>
            ) : (
              <Button key='submit' type='primary' onClick={handleFormSubmit}>
                Submit
              </Button>
            )
          ) : selectedAvatarCode === 'god_default' ? (
            <Popconfirm
              placement='topLeft'
              title='Are you sure you want to associate with default god ?'
              onConfirm={handleFormSubmit}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='primary'
                htmlType='submit'
                onClick={(event: React.MouseEvent) => {
                  orderMappingForm
                    .validateFields()
                    .catch(() => event?.stopPropagation())
                }}
              >
                {' '}
                Submit
              </Button>
            </Popconfirm>
          ) : (
            <Button
              key='submit'
              type='primary'
              htmlType='submit'
              onClick={handleFormSubmit}
            >
              Submit
            </Button>
          ),
        ]}
      >
        <Form form={orderMappingForm} onFinish={editOrderFormHandler}>
          <Form.Item
            label=''
            name='item_id'
            rules={[{ required: false }]}
            hidden={true}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label=''
            name='god_avatar_code'
            rules={[{ required: false }]}
            hidden={true}
          >
            <InputNumber />
          </Form.Item>
          <Row>
            {currentEditGodItem?.god_level_mapping ? (
              <Col span={24} style={{ paddingBottom: '20px' }}>
                <Space>
                  <Tag color='warning'>
                    <WarningOutlined />
                    <span>This item associated with default god.</span>
                  </Tag>
                </Space>
              </Col>
            ) : (
              ''
            )}
            <Col span={16}>
              {isEditModalOpen ? (
                <Form.Item
                  label='Item code'
                  name='item_type'
                  rules={[{ required: true }]}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                >
                  <Select
                    optionLabelProp='label'
                    optionFilterProp='label'
                    showSearch
                    disabled={true}
                  >
                    {godItems.map((godItem: Shrine.GodItem) => (
                      <Select.Option
                        key={godItem.item_id}
                        value={godItem.item_id}
                        label={godItem.item_code}
                      >
                        <span role='img' aria-label={godItem.item_code}>
                          <img
                            height={50}
                            width={50}
                            src={godItem?.item_thumbnail?.url}
                          />
                        </span>
                        {godItem.item_code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  label='Item code'
                  name='item_type'
                  rules={[{ required: true }]}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                >
                  <Select
                    optionLabelProp='label'
                    optionFilterProp='label'
                    showSearch
                  >
                    {filteredGodItems.map((godItem: Shrine.Item) => (
                      <Select.Option
                        key={godItem.id}
                        value={godItem.id}
                        label={godItem.code}
                      >
                        <span role='img' aria-label={godItem.code}>
                          <img
                            height={50}
                            width={50}
                            src={godItem?.thumbnail?.url}
                          />
                        </span>
                        {godItem.code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col span={8} style={{ paddingLeft: '10px' }}>
              <Form.Item
                label='Display order'
                name='display_order'
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            {selectedItemType === GOD_ITEM_TYPES.CTA && (
              <Col span={12} style={{ paddingLeft: '10px' }}>
                <Form.Item
                  label='Display position'
                  name='display_pos'
                  rules={[{ required: true }]}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Select>
                    {DISPLAY_POS.map(pos => (
                      <Select.Option value={pos.value}>
                        {pos.lable}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default GodItemMapping
