import React, { useEffect, useState } from 'react'

import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Modal, Divider, Switch, DatePicker } from 'antd'

import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import { SUPPORTED_COUNTRY_LIST } from '../../../admin/constants';

const { Option } = Select
const { TextArea } = Input
interface Props {
    cdnUrl: string
    preSignUrl: string
    form: any
    isEditJourney: boolean
    submitFormHandler: () => void
}

const StoreContentCreation: React.FC<Props> = ({
    cdnUrl,
    preSignUrl,
    form,
    submitFormHandler,
    isEditJourney,
}) => {
    const [visibleCta, setVisibleCta] = useState<boolean>(false);
    const [countryListData, setCountryListData] = useState<any[]>()

    const showModalCta = () => {
        setVisibleCta(true);
    };

    const handleCtaOk = () => {
        setVisibleCta(false);
    };

    const handleCtaCancel = () => {
        setVisibleCta(false);
    };

    const parseCountryList = (countryList: any) => {
        const list: any = []
        countryList
            ?.map((country: any) =>
                list.push({
                    label: country?.country_name,
                    value: country?.country_code,
                }),
            )
        setCountryListData(list)
    }

    useEffect(() => {
        parseCountryList(SUPPORTED_COUNTRY_LIST)
    }, []);

    return (
        <Row>
            <Col span={24}>
                <Form
                    form={form}
                    layout='vertical'
                    name='create temple'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Col>
                        <Row style={{ width: '100%' }}>
                            <Form.Item
                                name={'start_datetime'}
                                label='Start Time'
                                style={{ flexBasis: '30%' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Pick Date',
                                    },
                                ]}
                            >
                                <DatePicker showTime />
                            </Form.Item>
                            <Form.Item
                                name={'end_datetime'}
                                label='End Time'
                                style={{ marginLeft: '10px' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Pick Date',
                                    },
                                ]}
                            >
                                <DatePicker showTime />
                            </Form.Item>
                        </Row>
                        <Divider />
                    </Col>

                    <Col>
                        <Row style={{ width: '100%' }}>
                            <Form.Item
                                label='Name of temple'
                                name='title'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ flexBasis: '50%' }}
                                rules={[
                                    { required: true, message: 'Please input name of the temple!' },
                                ]}
                            >
                                <Input placeholder='Enter temple name' />
                            </Form.Item>
                            <Form.Item
                                label='Display Title'
                                name='display_title'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ flexBasis: '48%', marginLeft: '10px' }}
                                rules={[
                                    { required: false },
                                ]}
                            >
                                <Input placeholder='Enter display title' />
                            </Form.Item>
                        </Row>
                        <Form.Item
                            label='Sub Title'
                            name='sub_title'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input subtitle of the temple!',
                                },
                            ]}
                        >
                            <Input placeholder='Enter temple subtitle' />
                        </Form.Item>
                        <Form.Item
                            label='Short Description'
                            name='short_description'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                { required: true, message: 'Please input short description!' },
                            ]}
                        >
                            <TextArea placeholder='Enter short description' />
                        </Form.Item>
                        <Form.Item
                            label='Long Description'
                            name='long_description'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                { required: true, message: 'Please input long description!' },
                            ]}
                        >
                            <TextArea placeholder='Enter long description' />
                        </Form.Item>
                        <Form.Item
                            label={`Tags`}
                            name='tags'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: false,
                                    message: 'Please select Tags!',
                                },
                            ]}
                        >
                            <Select placeholder='Tags' mode="multiple">
                                <Option value='Festival'>Festival</Option>
                                <Option value='Kundali dosha'>Kundali dosha</Option>
                                <Option value='Business & Finance'>Business & Finance</Option>
                                <Option value='Marriage & Love'>Marriage & Love</Option>
                                <Option value='Career'>Career</Option>
                            </Select>
                        </Form.Item>
                        <Row style={{ width: '100%' }}>
                            <Form.Item
                                label={`CTA`}
                                name='cta'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ flexBasis: '25%' }}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please enter CTA details',
                                    },
                                ]}
                            >
                                <Button style={{ width: 100, background: '#1890ff', color: 'white' }} onClick={showModalCta}>
                                    CTA
                                </Button>
                                <Modal
                                    title="CTA"
                                    visible={visibleCta}
                                    onOk={handleCtaOk}
                                    onCancel={handleCtaCancel}
                                    width="45vw"
                                    style={{ marginTop: '-95px' }}
                                >
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label='Cta'
                                                name={['cta', 'text']}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                rules={[{ required: true, message: 'Please input cta text!' }]}
                                                style={{ flexBasis: '25%' }}
                                            >
                                                <Input placeholder='Enter Cta text' />
                                            </Form.Item>

                                            <Form.Item
                                                label="Media type"
                                                name={['cta', 'media', 'media_type']}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'Please select cta media type!',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder='Type'>
                                                    <Option value='lottie'>GIF</Option>
                                                    <Option value='image'>Image</Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                name={['cta', 'media', 'media_url']}
                                                label="Media url"
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                rules={[
                                                    {
                                                        min: 0,
                                                        max: 1,
                                                        validator: async (rules: any, values: any) => {
                                                            if (values?.length < rules.min) {
                                                                return Promise.reject('Please upload Cta media Image')
                                                            }
                                                        },
                                                    },
                                                ]}
                                                valuePropName='fileList'
                                            >
                                                <S3Upload
                                                    cdnUrl={cdnUrl}
                                                    preSignUrl={preSignUrl}
                                                    min={1}
                                                    max={1}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                label="Visible"
                                                name={['cta', 'media', 'visible']}
                                                valuePropName="checked"
                                            >
                                                <Switch />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Modal>
                            </Form.Item>

                            <Form.Item
                                label='Position'
                                name='position'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ flexBasis: '25%' }}
                                rules={[{
                                    validator: async (rule: any, value: any) => {
                                        if (!value) throw new Error('Position is required.');
                                    }
                                }]}
                            >
                                <InputNumber placeholder="Enter store position" min={1} />

                            </Form.Item>

                            <Form.Item
                                label='Country code'
                                name='country_code'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ flexBasis: '25%' }}
                                rules={[{ required: false, message: 'Please select country code' }]}
                            >
                                <Select
                                    placeholder='Country code'
                                    options={countryListData}
                                    disabled
                                >
                                </Select>

                            </Form.Item>

                            <Form.Item
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                label="Active"
                                name='active'
                                valuePropName="checked"
                                style={{ flexBasis: '25%', paddingLeft: '50px' }}
                            >
                                <Switch />
                            </Form.Item>
                        </Row>

                        <Row>
                            <Form.Item
                                label={`Icon URL`}
                                name='icon_url'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ flexBasis: '30%' }}
                                rules={[
                                    {
                                        min: 0,
                                        max: 1,
                                        validator: async (rules: any, values: any) => {
                                            if (values?.length < rules.min) {
                                                return Promise.reject('Please upload icon')
                                            }
                                        },
                                    },
                                ]}
                                valuePropName='fileList'
                            >
                                <S3Upload cdnUrl={cdnUrl} preSignUrl={preSignUrl} min={0} max={1} />
                            </Form.Item>
                            <Form.Item
                                label={`Cover Media type`}
                                name='coverMediaType'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ flexBasis: '25%' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select cover media type!',
                                    },
                                ]}
                            >
                                <Select style={{ width: 100 }} placeholder='Type'>
                                    <Option value='lottie'>Lottie</Option>
                                    <Option value='image'>Image</Option>
                                    <Option value='video'>Video</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={`Cover Media`}
                                name='media_url'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ flexBasis: '25%' }}
                                rules={[
                                    {
                                        min: 1,
                                        max: 1,
                                        validator: async (rules: any, values: any) => {
                                            if (values?.length < rules.min) {
                                                return Promise.reject('Please upload Cover media Images')
                                            }
                                        },
                                    },
                                ]}
                                valuePropName='fileList'
                            >
                                <S3Upload
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    min={1}
                                    max={1}
                                />
                            </Form.Item>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) =>
                                    prevValues.coverMediaType !== currentValues.coverMediaType
                                }
                            >
                                {({ getFieldValue }) => (
                                    getFieldValue("coverMediaType") === "video" &&
                                    <Col span={12}>
                                        <Form.Item
                                            label={`Thumbnail`}
                                            name='thumbnail'
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    min: 1,
                                                    max: 1,
                                                    validator: async (rules: any, values: any) => {
                                                        if (values?.length < rules.min) {
                                                            return Promise.reject('Please upload Thumbnail Image')
                                                        }
                                                    },
                                                },
                                            ]}
                                            valuePropName='fileList'
                                        >
                                            <S3Upload
                                                cdnUrl={cdnUrl}
                                                preSignUrl={preSignUrl}
                                                min={0}
                                                max={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                )}

                            </Form.Item>
                        </Row>
                    </Col>
                </Form >
            </Col >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '5px' }}>
                <Space>
                    {isEditJourney ? (
                        <Button type='primary' onClick={submitFormHandler}>
                            Update
                        </Button>
                    ) : <Button type='primary' onClick={submitFormHandler}>
                        Submit
                    </Button>}
                </Space>
            </div>

        </Row >
    )
}
export default StoreContentCreation
