import { Button, Card, Collapse, Form, Input, Select } from "antd"
import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../../../../components/AppContext";
import { openErrorNotification, openNotification } from "../../../../../utils";
import { REQUIRED_RULE } from "../../../../../utils/form.validation";
import CreateRules from "./CreateRules";
import { Action, ActionType, Condition, ConditionAND, ConditionEVAL, ConditionOR, ConditionType, Rule } from "../utils/types";
import styled from "styled-components";
import { ActionContextProvider, GOAL_COUNT } from "../utils/constants";
import { titleCase } from "../../../../../utils/helper";
import { PlusCircleOutlined } from "@ant-design/icons";
import { camelCase } from "lodash";

const RuleTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}`

interface CreateActionProps {
    actionData?: Action;
    eventsAndProperties: any,
    commonEventProperties: any
}

const CreateAction = (props: CreateActionProps) => {
    const { actionData, eventsAndProperties, commonEventProperties } = props;
    const context = useAppContext();
    const { networkInstance } = context;
    const createApi = networkInstance.clientWithHeaders.dynamicCohort.postAction;
    const updateApi = networkInstance.clientWithHeaders.dynamicCohort.putAction;
    const [actionTypes, setActionTypes] = useState<ActionType>({})
    const [rules, setRules] = useState<(Rule | undefined)[]>([undefined]);
    const [activeKeys, setActiveKey] = useState([0]);
    const [form] = Form.useForm();

    const getAccessTypes = useCallback(async () => {
        try {
            const res = await networkInstance.clientWithHeaders.dynamicCohort.getActionTypes();
            setActionTypes(res.data.data.action_types)
        } catch (error) {

        }
    }, [networkInstance.clientWithHeaders.dynamicCohort])

    useEffect(() => {
        getAccessTypes()
    }, [getAccessTypes])

    const createUpdate = useCallback(async (values) => {
        const convertFileObjectToPath = (data: any, path = '', matchPath = '') => {
            if (Array.isArray(data)) {
                data.forEach((item, i) => {
                    convertFileObjectToPath(item, path + `.${i}`, matchPath + '.*')
                })
                return
            }
            if (typeof data === 'object') {
                Object.keys(data).forEach((key) => {
                    convertFileObjectToPath(data[key], path === '' ? path + key : path + `.${key} `, matchPath === '' ? matchPath + key : matchPath + `.${key} `)
                })
            }
        }
        convertFileObjectToPath(values, '', '');
        values['rules'] = rules;
        if (actionData) {
            try {
                if (updateApi) {
                    values['id'] = actionData.id
                    await updateApi(values)
                } else {
                    throw Error("For Edit flow updateApi is required!")
                }
                openNotification('success', { message: "Update sucessful" })
            } catch (error) {
                openNotification('error', { message: "Failed to update", description: (error as string).toString() })
                openErrorNotification(error);
            }
        } else {
            try {
                if (createApi) {
                    await createApi(values);
                } else {
                    throw Error("For create flow createApi is required!")
                }
                openNotification('success', { message: "Create sucessfully" })
            } catch (error: any) {
                openNotification('error', { message: "Failed to create", description: error?.response?.data?.data?.error?.message })
                openErrorNotification(error);
            }
        }
    }, [rules, actionData, updateApi, createApi])

    useEffect(() => {
        if (actionData) {
            console.log({ actionData })
            form.setFieldsValue({
                code: actionData.code,
                type: actionData.type,
                properties: actionData.properties
            });
            setRules(actionData.rules);
        }
    }, [actionData, form, actionTypes])

    const modifyCondition = useCallback((identifier: string = 'root', ruleIndex, condition?: Condition, rule?: Rule) => {
        if (identifier === 'root') {
            rules[ruleIndex] = rule;
            setRules([...rules])
            return;
        }

        const __modifyCondition = (conditions: Condition[], localIdentifier: string) => {
            for (let i = 0; i < conditions.length; i++) {
                if (identifier === localIdentifier + i) {
                    if (condition) {
                        conditions[i] = condition
                    } else {
                        conditions.splice(i, 1)
                    }
                    return conditions;
                } else {
                    if (conditions[i].type !== ConditionType.EVAL) {
                        // @ts-ignore
                        conditions[i].conditions = __modifyCondition(conditions[i].conditions, localIdentifier + i)
                    }
                }
            }
            if (localIdentifier + (conditions.length + 1) === identifier) {
                if (condition) {
                    conditions.push(condition)
                }
            }
            return conditions;
        }
        const rulesCopy = [...rules]
        const currentRule = rulesCopy[ruleIndex];
        if (currentRule) {
            //@ts-ignore
            rulesCopy[ruleIndex].condition.conditions = __modifyCondition(currentRule?.condition?.conditions || [], 'root')
            setRules(rulesCopy);
        }
    }, [rules])

    const validateRules = useCallback(() => {
        let isValid = true;
        rules.forEach(rule => {
            console.log({ rule })
            if (!rule) {
                isValid = false;
            } else {
                const validationCondition = (conditions: Condition[]) => {
                    if (conditions.length < 2) {
                        isValid = false;
                    }
                    conditions.forEach((condition) => {
                        if (condition.type === ConditionType.AND || condition.type === ConditionType.OR) {
                            validationCondition(condition.conditions as unknown as Condition[])
                        }
                    })
                }
                if (rule.condition.type === ConditionType.AND || rule.condition.type === ConditionType.OR) {
                    validationCondition(rule.condition.conditions as unknown as Condition[])
                }

            }
        });
        return isValid;
    }, [rules])

    const actionTypeKeys = Object.keys(actionTypes);

    const type = Form.useWatch('type', form);

    return <ActionContextProvider value={{}}>
        <Form
            form={form}
            onFinish={createUpdate}
            layout="vertical"
        >
            <Form.Item rules={REQUIRED_RULE} name="code" label="Code">
                <Input></Input>
            </Form.Item>
            <Form.Item rules={REQUIRED_RULE} name="type" label="Type">
                <Select>
                    {
                        actionTypeKeys.map((actionType) => {
                            return <Select.Option value={actionType}> {actionType}</Select.Option>
                        })
                    }
                </Select>
            </Form.Item>
            {
                type && actionTypes[type] && actionTypes[type].required_keys.map((key) => {
                    return <Form.Item name={['properties', key]} label={titleCase(key)} rules={REQUIRED_RULE}>
                        <Input></Input>
                    </Form.Item>
                })
            }

            <Button size="middle" disabled={!validateRules()} style={{ position: 'fixed', top: 10, right: 10 }} type="primary" htmlType="submit"> Save </Button>
        </Form>

        <Collapse style={{ margin: '10px 0px' }} activeKey={activeKeys}>
            {rules.map((rule, index) => {
                // @ts-ignore
                return <Collapse.Panel
                    header={
                        <RuleTitle onClick={() => {
                            if (activeKeys.includes(index)) {
                                setActiveKey(activeKeys.filter((activeKey) => activeKey !== index))
                            } else {
                                setActiveKey([...activeKeys, index])
                            }
                        }}>
                            {"Rule " + (index + 1)}
                            {rules.length > 0 && <Button type="link" onClick={() => setRules(rules.filter((_, i) => i !== index))}>Remove</Button>}
                        </RuleTitle>
                    }
                >
                    <CreateRules eventsAndProperties={eventsAndProperties} commonEventProperties={commonEventProperties} modifyCondition={(identifier: string | undefined, condition?: Condition) => {
                        if (identifier === 'root') {
                            modifyCondition(identifier, index, undefined, condition as unknown as Rule)
                        } else {
                            modifyCondition(identifier, index, condition)
                        }
                    }} rule={rule} />
                </Collapse.Panel>

            })}
        </Collapse>
        <Button role="button" data-testid="add-role" type="dashed" onClick={() => {
            setRules(rules.concat(undefined))
            setActiveKey([...activeKeys, rules.length])
        }}> <PlusCircleOutlined></PlusCircleOutlined> Add rule </Button>
    </ActionContextProvider >
}

export default CreateAction;