import { Descriptions, Table } from "antd";
import moment from "moment";

interface Props {
  record: any;
}
const PanchangDataTable = ({ record }: Props) => {
  const dataColumn = [
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
      ellipsis: true,
      width: "10%",
      render: (text: any, record: any, index: any) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "CTA Text",
      dataIndex: "cta_text",
      key: "cta_text",
      ellipsis: true,
      width: "10%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Cyclic Duration",
      dataIndex: "cyclic_duration",
      key: "cyclic_duration",
      ellipsis: true,
      width: "10%",
      render: (text: any, record: any, index: any) => {
        return <div>{moment(text).format("ss")}</div>;
      },
    },
    {
      title: "Cyclic Tags",
      dataIndex: "cyclic_tags",
      key: "cyclic_tags",
      ellipsis: true,
      width: "10%",
      render: (text: any, record: any, index: any) => {
        return (
          <Descriptions size="small" bordered>
            {text?.map((item: any) => {
              return (
                <>
                  <Descriptions.Item label="Text">
                    {item?.text}
                  </Descriptions.Item>
                  <Descriptions.Item label="Color">
                    {item?.color}
                  </Descriptions.Item>
                </>
              );
            })}
          </Descriptions>
        );
      },
    },
  ];
  return (
    <div className="padding-left">
      <Table
        columns={dataColumn}
        dataSource={record?.content?.items || []}
        pagination={false}
        scroll={{ y: 240 }}
      ></Table>
    </div>
  );
};
export default PanchangDataTable;
