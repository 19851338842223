
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination from "@/hooks/useAntdPagination";
import { VideoTemplate } from "@/../../../packages/a4b-api/src/modules/Content/Audio/types";
import { openNotification } from "@/utils";

const VideoTemplateScreen = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const drawerConfig = useDrawerConfig(activeDrawer);
  const { drawerProps, drawerContent } = drawerConfig;
  const { networkInstance } = useAppContext();
  const [videoTemplates, setVideoTemplates] = useState<VideoTemplate[]>([])
  const { pagination, setPagination } = useAntdPagination();

  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }

  const getVideoTemplates = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.contentApi.audio.getVideoTemplates(pagination.pageSize || 50, (pagination.pageSize || 50) * ((pagination.current || 1) - 1));
      setVideoTemplates(res.data.data.video_templates);
      if (pagination.total === -1) {
        setPagination({ ...pagination, total: res.data.data.count })
      }
    } catch (error) {
      console.log({ error })
      openNotification('error', { message: 'Failed to load video templates ' })
    }
  }, [networkInstance.clientWithHeaders.contentApi.audio, pagination, setPagination])

  useEffect(() => {
    getVideoTemplates()
  }, [getVideoTemplates])

  const tableConfig = useTableConfig(videoTemplates, pagination, setPagination, setDrawer);


  const onCreateRashipal = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_SOMETHING1);
  }

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  }

  return <SocialScreenContainer
    title={"Music Video template"}
    filtersUI={<Filters />}
    extra={<Button onClick={onCreateRashipal}> Create video template </Button>}
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />

};

export default withDefaultLayout(VideoTemplateScreen)