import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import type { TablePaginationConfig } from 'antd/es/table'
import { Temple } from '@a4b/api/src/modules/Content/Temples/types'

//types
import { PlusSquareOutlined } from '@ant-design/icons'
import {
    Button,
    Card,
    Col,
    Input,
    Row,
    Space,
    Switch,
    Table,
    Tag,
    Select,
    Checkbox
} from 'antd'


export interface CityList {
    label: string
    value: string
}

interface Props {
    onStatusChange: (item: any) => void
    handleAvatarChange: (item: any, isAvatarEdit?: boolean) => void
    templeList: Temple[]
    handleTableChange: (paginationParams: TablePaginationConfig) => void
    pagination: TablePaginationConfig
    cityList: CityList[]
    generateQR: (link: string) => void
    handleFilters: (type: string, value: any) => void
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
  .ant-card-head-wrapper {
    display: block;
    width: 100%;
  }
  .ant-card-head-title {
    flex-basis: 30%;
  }
  .ant-card-extra {
    flex-basis: 70%;
  }
`

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`
const { Search } = Input

const TempleListTablePage: React.FC<Props> = ({
    onStatusChange,
    handleAvatarChange,
    templeList,
    handleTableChange,
    pagination,
    cityList,
    generateQR,
    handleFilters
}) => {
    const dataColumns = [
        {
            title: 'Refrence Name',
            dataIndex: 'reference_name',
            key: 'reference_name',
            width: '14%',
            fixed: true,
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: '16%',
        },
        {
            title: 'Temple code',
            dataIndex: 'temple_code',
            key: 'temple_code',
        },
        {
            title: 'Languages',
            key: 'languages',
            dataIndex: 'languages',
            width: '13%',
            render: (tags: any) => (
                <>
                    {tags.map((tag: any) => {
                        return (
                            <Tag color={tag?.startsWith('English') ? 'blue' : 'purple'} key={tag}>
                                {tag}
                            </Tag>
                        )
                    })}
                </>
            ),
        },
        {
            title: 'Priority',
            key: 'priority',
            dataIndex: 'priority',
        },
        {
            title: 'Active',
            key: 'is_active',
            render: (text: any, record: any) => {
                return (
                    <Switch
                        checked={record.is_active}
                        onChange={() => onStatusChange(record)}
                    />
                )
            },
        },
        {
            title: 'Avatar',
            children: [
                {
                    title: 'Status',
                    key: 'is_avatar_enabled',
                    render: (text: any, record: any) => {
                        if (record?.languages?.includes('English') && record?.languages?.includes('Hindi')) {
                            return (
                                <Switch
                                    checked={record.is_avatar_enabled}
                                    onChange={() => handleAvatarChange(record)}
                                />
                            )
                        }
                    },
                },
                {
                    title: 'Details',
                    key: 'avatar_details',
                    render: (text: any, record: any) => {
                        if (record?.is_avatar_enabled) {
                            return <Button onClick={() => handleAvatarChange(record, true)} type='primary'>Avatar</Button>
                        } else {
                            return null
                        }
                    },
                },
            ]
        },
        {
            title: 'QR Code',
            key: 'qr_code',
            dataIndex: 'deeplink',
            render: (text: string) => {
                if (text) {
                    return <Button type='link' onClick={() => generateQR(text)} >Get QR</ Button>
                } else {
                    return null
                }
            }
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            fixed: 'right',
            render: (text: string, record: any) => {
                return <Link to={`/temples/create?edit=true&temple_id=${record.id}&lang=${record.lang}`}>
                    <Button type='link' color='cyan' style={{ cursor: 'pointer' }}>Edit</Button>
                </Link>
            }
        },

    ]
    return (
        <Container
            title='Manage Temples'
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            extra={
                <Row style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <Checkbox
                        style={{ marginRight: '10px' }}
                        onChange={(e) => handleFilters('avatarOnBoard', e)}
                    >Avatar Onboarded Temples</Checkbox>
                    <Select
                        size='middle'
                        placeholder="Active status"
                        style={{ width: '15%', marginRight: '10px' }}
                        allowClear
                        options={[
                            {
                                label: 'Active',
                                value: true
                            },
                            {
                                label: 'InActive',
                                value: false
                            },
                        ]}
                        onChange={(value: boolean) => {
                            handleFilters('active', value)
                        }}
                    />
                    <Select
                        size='middle'
                        placeholder="Filter by cities"
                        tokenSeparators={[',']}
                        style={{ width: '15%', marginRight: '10px' }}
                        allowClear
                        options={cityList}
                        onChange={(value: string) => {
                            handleFilters('city', value)
                        }}
                    />
                    <Search
                        placeholder='Search by reference name'
                        onSearch={(value: string) => {
                            handleFilters('referenceName', value)
                        }}
                        style={{ width: '35%', marginRight: '10px' }}
                        allowClear
                    />
                    <Button type='primary'>
                        <Link to='/temples/create'>
                            <PlusSquareOutlined /> Add new Temple
                        </Link>
                    </Button>
                </Row>
            }
        >
            <Space direction={'vertical'} style={{ width: '100%' }}></Space>
            <Row>
                <Col span={28}>
                    <Table
                        sticky
                        bordered
                        scroll={{ x: 1500 }}
                        size='large'
                        rowKey={(record: any) => record?.id}
                        columns={dataColumns}
                        dataSource={templeList}
                        pagination={pagination}
                        onChange={handleTableChange}
                    />
                </Col>
            </Row>
            <LabelRightAlign>
            </LabelRightAlign>
        </Container>
    )
}

export default TempleListTablePage