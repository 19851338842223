import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { CreateUser, Response, UpdateUser, UserRoles } from './types'

class UserApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getUserByEmail = (email: string) =>
    this.api.get<Response<UserRoles>>(
      `/yoda/internal/v1/users/${email}`,
    )

  getUserList = (filterType: string, userType: string, hubId: any) =>
    this.api.get<any>(
      `/gw1/yoda/internal/v1/users?filter_type=${filterType}&user_type=${userType}&hub_id=${hubId}`,
    )

  CreateUser = (payload: CreateUser) =>
    this.api.post<Response<any>>(
      `/yoda/internal/v1/users/create-user`,
      payload,
    )

  UpdateUser = (payload: UpdateUser, id: string) =>
    this.api.put<Response<any>>(
      `/gw1/yoda/internal/v1/users/${id}`,
      payload,
    )
}
export default UserApi
