import { throwError } from "./../../../utils/helper";
import moment from "moment";
type Item = {
  image_attachment_id: string;
  scheduled_time: any;
  title: string;
  subtitle: string;
  video_attachment_id: string;
  cta_text: string;
  video_attachment_type?: string;
};
type HeaderTags = {
  text: string;
  color: string;
};
class YoutubeSchedule {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header!: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header_tags!: HeaderTags[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items: Item[] = [];

  getSerializedData = (widgetFormData: YoutubeSchedule) => {
    let itemModified = widgetFormData?.items?.map((item: Item) => {
      delete item.video_attachment_type;
      return {
        ...item,
        scheduled_time: item.scheduled_time.unix(),
      };
    });
    widgetFormData.items = itemModified;
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: YoutubeSchedule) => {
    let itemModified = widgetFormData?.items?.map((item: Item) => {
      return {
        ...item,
        scheduled_time: moment.unix(item.scheduled_time),
      };
    });
    widgetFormData.items = itemModified;
    return widgetFormData;
  };

  headerValidator = async (rule: any, value: any) => {
    if (!value) return Promise.resolve();
  };
  headerTagsTextValidator = async (rule: any, value: any) => {
    if (!value) throwError("Header tags text required!");
  };
  headerTagsColorValidator = async (rule: any, value: any) => {
    if (!value) throwError("Header tags color required!");
  };
  imageAttachmentValidator = async (rule: any, value: any) => {
    if (!value) throwError("Image Attachment required!");
  };
  videoAttachmentValidator = async (rule: any, value: any) => {
    if (!value) throwError("Video Attachment ID required!");
  };
  scheduledTimeValidator = async (rule: any, value: any) => {
    if (!value) throwError("Schedule time text required!");
  };
  titleValidator = async (rule: any, value: any) => {
    if (!value) throwError("Title required!");
  };
  subtitleValidator = async (rule: any, value: any) => {
    if (!value) throwError("SubTitle required!");
  };
  ctaTextValidator = async (rule: any, value: any) => {
    if (!value) throwError("CTA Text required!");
  };
  videoAttachmentTypeValidator = async (rule: any, value: any) => {
    if (!value) throwError("Video Attachment Type required!");
  };
  videoRefCodeValidator = async (rule: any, value: any) => {
    if (!value) throwError("Video ref Code required!");
  };
}
export default YoutubeSchedule;
export const YoutubeScheduleInstance = new YoutubeSchedule();
