import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { RcFile } from 'antd/lib/upload'
import type { UploadFile } from 'antd/es/upload/interface'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//screens
import {
  TagCreateEdit as TagCreateEditScreen,
  TagFormFields,
} from '@a4b/ui/src/admin'

import { ContentTagCreateOptions } from '@a4b/api'

const getAttachement = (fileUrl: string): UploadFile<string> => {
  const fileName = fileUrl.slice(fileUrl.lastIndexOf('/') + 1)
  return {
    uid: fileName,
    name: fileName,
    status: 'done',
    response: fileName,
    url: fileUrl,
    thumbUrl: fileUrl,
  }
}

const TagsCreateEdit = () => {
  const { tagId } = useParams()
  const navigate = useNavigate()
  const { networkInstance, userProfile } = useAppContext()
  const [tagData, setTagData] = useState<TagFormFields>()

  useEffect(() => {
    async function initTag() {
      if (!tagId) {
        return
      }
      const { data } = await networkInstance.clientWithHeaders.contentApi.tag.getTagById(tagId)
      setTagData({
        ...data.data,
        image: [getAttachement(data.data.image)],
      })
    }
    if (tagId) initTag()
  }, [networkInstance.clientWithHeaders.contentApi.tag, tagId])

  const handleUploadImage = async (file: RcFile) => {
    const { data } =
      await networkInstance.clientWithHeaders.contentApi.library.getPresignedUrlForImageUpload(file?.type?.toLowerCase())

    const s3SignedUploadUrl = data.data.url
    const attachmentId = data.data.attachmentId

    const headers = new Headers()
    headers.append('ContentType', file.type)

    await fetch(s3SignedUploadUrl, {
      method: 'PUT',
      body: file,
      headers,
    })
    return attachmentId
  }

  const handleSubmit = async ({
    name,
    description,
    active,
    language,
    image,
  }: TagFormFields) => {
    if (!userProfile.email) {
      return false
    }

    const options: ContentTagCreateOptions = {
      name,
      description,
      active,
      language,
      image: image[0].response || '',
      createdBy: userProfile.email,
      updatedBy: userProfile.email,
    }

    if (!tagId) {
      try {
        // @ts-ignore
        for (const lang of options.language) {
          const option = { ...options, language: lang }
          await networkInstance.clientWithHeaders.contentApi.tag.createTag(option)
        }
        navigate('/content/tags')
        return true
      } catch (error) {
        return false
      }
    } else {
      try {
        const response = await networkInstance.clientWithHeaders.contentApi.tag.updateTag(tagId, options)
        return true
      } catch (error) {
        return false
      }
    }
  }

  return (
    <div>
      <TagCreateEditScreen
        data={tagData}
        onSubmit={handleSubmit}
        onUploadImage={handleUploadImage}
      />
    </div>
  )
}
export default withDefaultLayout(TagsCreateEdit)
