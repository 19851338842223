import { Form, Input, Row, Select, InputNumber } from "antd"
import React from "react"
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'

interface Props {
    fields: any
    cdnUrl: string
    preSignUrl: string
}
const { Option } = Select

const PreviousOrderRecommendations: React.FC<Props> = ({ fields, cdnUrl, preSignUrl }) => {
    return (
        <>
            <Row justify="space-between">
                {fields.map((field: any, index: number) => {
                    return (
                        <div key={index} style={{
                            border: '1px solid #e0dcdc',
                            marginRight: '0px',
                            marginBottom: '10px',
                            width: '49%',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            paddingTop: '10px',
                            gap: '5px'
                        }}>
                            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Form.Item
                                    label={`Day ${index + 1}`}
                                    name={[field.name, 'day']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Day',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter Day' disabled />
                                </Form.Item>

                                <Form.Item
                                    label={`Day Number ${index + 1}`}
                                    name={[field.name, 'day_number']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Day Number',
                                        },
                                    ]}
                                >
                                    <InputNumber placeholder="Enter Day number" disabled />
                                </Form.Item>
                            </Row>

                            <Form.Item
                                label={`Deeplink ${index + 1}`}
                                name={[field.name, 'deeplink']}
                                labelCol={{ span: 32 }}
                                wrapperCol={{ span: 32 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter Deeplink',
                                    },
                                ]}
                            >
                                <Input placeholder='Enter Deeplink' />
                            </Form.Item>

                            <Form.Item
                                label={`God Name ${index + 1}`}
                                name={[field.name, 'god_name']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter God name',
                                    },
                                ]}
                            >
                                <Input placeholder='Enter God name' />
                            </Form.Item>

                            <Form.Item
                                label={`Card Type ${index + 1}`}
                                name={[field.name, 'card_type']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter Card Type',
                                    },
                                ]}
                            >
                                <Select style={{ width: 150 }} placeholder='Please Select Card type'>
                                    <Option value={'regular'} key={'regular'}>
                                        Regular
                                    </Option>
                                    <Option value={'festival'} key={'festival'}>
                                        Festival
                                    </Option>
                                </Select>
                            </Form.Item>

                            <Row justify="space-between">
                                <Form.Item
                                    label={`Media type ${index + 1}`}
                                    name={[field.name, 'media', 'media_type']}
                                    labelCol={{ span: 18 }}
                                    wrapperCol={{ span: 18 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter description of the category',
                                        },
                                    ]}
                                >
                                    <Select style={{ width: 150 }} placeholder='Please Select Media type'>
                                        <Option value={'image'} key={'image'}>
                                            Image
                                        </Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={`Media`}
                                    name={[field.name, 'media', 'media_url']}
                                    rules={[
                                        {
                                            required: true,
                                            min: 0,
                                            max: 1,
                                            validator: async (rules: any, values: any) => {
                                                if (values?.length < rules.min) {
                                                    return Promise.reject('Please upload Media')
                                                }
                                            },
                                        },
                                    ]}
                                    valuePropName='fileList'
                                    style={{ paddingLeft: '10px' }}
                                >
                                    <S3Upload
                                        cdnUrl={cdnUrl}
                                        preSignUrl={preSignUrl}
                                        min={1}
                                        max={1}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={`Media thumbnail`}
                                    name={[field.name, 'media', 'media_thumbnail']}
                                    rules={[
                                        {
                                            required: true,
                                            min: 0,
                                            max: 1,
                                            validator: async (rules: any, values: any) => {
                                                if (values?.length < rules.min) {
                                                    return Promise.reject('Please upload Media thumbnail')
                                                }
                                            },
                                        },
                                    ]}
                                    valuePropName='fileList'
                                    hidden
                                >
                                    <S3Upload
                                        cdnUrl={cdnUrl}
                                        preSignUrl={preSignUrl}
                                        min={1}
                                        max={1}
                                    />
                                </Form.Item>
                            </Row>
                        </div>
                    )
                })}
            </Row>
        </>
    )
}

export default PreviousOrderRecommendations