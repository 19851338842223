import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import {
  CreateHub,
  HubList,
  HubManager,
  Pagination,
  Response,
  STORE_SERVICE_TYPE,
  Stores,
} from './types'

class Hub {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getHubList = (paginationParams: Pagination, filterType: string = '') =>
    this.api.get<Response<HubList>>(
      `/yoda/internal/v1/hubs/hub-list?filter_type=${filterType}`,
      {
        params: paginationParams,
      },
    )
  getHubManagerList = (paginationParams: Pagination) =>
    this.api.get<Response<HubManager[]>>(
      `/yoda/internal/v1/users/hub-manager-list`,
      {
        params: paginationParams,
      },
    )
  getStoresByHubId = (
    hubId: string,
    serviceType: STORE_SERVICE_TYPE,
    storeId: string,
  ) =>
    this.api.get<Response<Stores>>(`/yoda/internal/v1/hubs/stores`, {
      params: {
        hub_id: hubId,
        ...(storeId && { store_id: storeId }),
        ...(serviceType && { service_type: serviceType }),
      },
    })
  createhub = (payload: CreateHub) =>
    this.api.post<Response<any>>(`/yoda/internal/v1/hubs/create-hub`, payload)
}

export default Hub
