import React from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Route } from 'antd/lib/breadcrumb/Breadcrumb'

export type BreadcrumbData = {
  path?: string,
  name: string
}
interface Props {
  routes: Route[]
}

const Container = styled.div`
  padding: 20px 0;
`

function itemRender(route: Route, params: any, routes: Array<Route>, paths: Array<string>) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  );
}

const AdminBreadcrumb:React.FC<Props> = ({routes}) => {
  return  (
    <Container>
      <Breadcrumb itemRender={itemRender} routes={routes} />
    </Container>
  )
}

export default AdminBreadcrumb