import { useAppContext } from "@/components/AppContext";
import { openNotification } from "@/utils";
import { SUPPORTED_LANGUAGES_LIST } from "@/utils/constants";
import { REQUIRED_RULE } from "@/utils/form.validation";
import { TS_GENERICS } from "@/utils/ts.generics";
import { Shrine } from "@a4b/api/src/modules/Temple/types";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Select, Space } from "antd";
import { useCallback, useReducer } from "react";
import GodCard from "../GodCard";
interface CloneGodFormProps {
  god: Shrine.God
  loadGods: () => Promise<void>
  handleDrawerClose: () => void
}

export interface GodFormProps {
  loadGods: () => Promise<void>
  handleDrawerClose: () => void
  editGod?: Shrine.God
}

enum effects {
}

const initialState = {
  freezeLanguageFeild: false,
  supportedlanguages: SUPPORTED_LANGUAGES_LIST,
  effects: Object.values(effects)
};

enum types {
  SET_FREEZE_LANGUAGE_FEILD = 'TOGGLE_FREEZE_LANGUAGE_FEILD',
  UPDATE_SUPPPORTED_LANGUAGES = 'UPDATE_SUPPPORTED_LANGUAGES'
}

type actionTypes = TS_GENERICS.REDUCER_ACTION<types.SET_FREEZE_LANGUAGE_FEILD, boolean>
  | TS_GENERICS.REDUCER_ACTION<types.UPDATE_SUPPPORTED_LANGUAGES, [string]>


function reducer(state: typeof initialState, action: actionTypes): typeof initialState {
  switch (action.type) {
    case types.SET_FREEZE_LANGUAGE_FEILD:
      return { ...state, freezeLanguageFeild: action.payload }
    case types.UPDATE_SUPPPORTED_LANGUAGES:
      return { ...state, supportedlanguages: SUPPORTED_LANGUAGES_LIST.filter(language => !action.payload.includes(language.value)) }
    default:
      throw new Error();
  }
}


const CloneGodForm = function ({ god, loadGods, handleDrawerClose }: CloneGodFormProps) {
  const { networkInstance } = useAppContext();
  const [state, dispatch] = useReducer(reducer, initialState)
  const [form] = Form.useForm();
  const cloneGod = useCallback(async (values) => {
    const payload = {
      languages: values.languages.map((language: any) => language.language_code.value)
    }
    console.log({ payload });
    try {
      await networkInstance.clientWithHeaders.shrineApi.cloneGod(god.id, payload)
      openNotification('success', { message: 'God cloned successfully!' })
      form.resetFields();
      loadGods();
      handleDrawerClose();
    } catch (error: any) {
      openNotification('error', { message: 'God cloning  failed!', description: error.toString(), })
    }
  }, [form, god.id, handleDrawerClose, loadGods, networkInstance.clientWithHeaders.shrineApi])


  const checkIsCodeIsUnique = useCallback(async (languageCode: string) => {
    try {
      const checkUniqueRes = await networkInstance.clientWithHeaders.shrineApi.checkCodeUnique(god.code, languageCode);
      if (checkUniqueRes.data.data.is_unique) {
        return true;
      }
    } catch (error) {

    }
    return false;
  }, [god.code, networkInstance.clientWithHeaders.shrineApi])

  return <div>
    <GodCard god={god} />
    <br></br>
    <br></br>
    <Form
      form={form}
      onFinish={cloneGod}
      layout="vertical"
    >
      <Form.List name="languages" initialValue={[{ language_code: undefined }]}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <Space style={{ display: 'flex', flexDirection: 'row', padding: '10px', justifyContent: 'space-between', background: ((index + 1) % 2 ? 'rgb(234 236 238)' : 'rgb(217 221 225)') }}>
                <Form.Item
                  label="Select language "
                  name={[field.name, 'language_code']}
                  rules={[...REQUIRED_RULE, {
                    validator(rule, value, callback) {
                      checkIsCodeIsUnique(value.value).then((isUnique) => {
                        if (isUnique || state.freezeLanguageFeild) {
                          callback(undefined)
                        } else {
                          callback("This language code is not allowed")
                        }
                      }).catch(() => {
                        callback("try again ")
                      })
                    },
                  }]}
                >
                  <Select
                    style={{ width: 200 }}
                    showSearch
                    optionFilterProp="label"
                    labelInValue
                    disabled={state.freezeLanguageFeild}
                    onChange={(value) => {
                      dispatch({ type: types.UPDATE_SUPPPORTED_LANGUAGES, payload: form.getFieldValue('languages').map((language: any) => language.language_code.value) })
                    }}
                  >
                    {state.supportedlanguages.map((item) => <Select.Option label={item.alt_name} value={item.value}>{item.alt_name} ({item.name})</Select.Option>)}
                  </Select>
                </Form.Item>
                {fields.length > 1 && <Button onClick={() => {
                  remove(field.name);
                  setTimeout(() => {
                    dispatch({ type: types.UPDATE_SUPPPORTED_LANGUAGES, payload: form.getFieldValue('languages').map((language: any) => language.language_code.value) })
                  }, 100)
                }}><MinusCircleOutlined /> remove </Button>}
              </Space>
            ))}
            <Form.Item style={{ marginTop: '20px' }}>
              {
                !state.freezeLanguageFeild && <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add language
                </Button>
              }
            </Form.Item>

          </>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit" >Submit</Button>
      </Form.Item>
    </Form>
  </div>
}

export default CloneGodForm;