import { Affix } from 'antd'
import styled from 'styled-components'

import React, { useEffect, useState } from 'react'


//components
import {
  ContentFormFields,
  CreateEditForm,
  Props as CreateEditFormProps
} from '../../components/LibraryContent/CreateEditForm'
import ContentPreview from '../../components/LibraryContent/ContentPreview'

interface Props extends Omit<CreateEditFormProps, 'onFormChanged'> {
  cdnHost: string
}

const Container = styled.div`
  display: flex;
  width: 100%;
  
  > div {
    width: 50%;
  }
`

const PreviewAffix = styled(Affix)`
  .ant-affix {
    display: flex;
    justify-content: center;
  }
`

const getPreviewThumbnail = (data?: ContentFormFields) => {
  if (!data) return ''
  if (data.thumbnail && data.thumbnail[0].response) {
    return data.thumbnail[0].response
  }
  return ''
}

const getPreviewContent = (data?: ContentFormFields) => {
  if (!data) return ''
  if (data.textMetaData && data.textMetaData.attachment)
    return data.textMetaData.attachment
  return ''
}

export const ContentUpload: React.FC<Props> = ({ data, cdnHost, ...props }) => {
  const [previewThumbnail, setPreviewThumbnail] = useState('')
  const [previewContent, setPreviewContent] = useState('')

  useEffect(() => {
    if (data) {
      setPreviewThumbnail(`${cdnHost}/images/library/${getPreviewThumbnail(data)}`)
      setPreviewContent(getPreviewContent(data))
    }
  }, [data])

  const handleFormChange = (values: ContentFormFields) => {
    setPreviewThumbnail(
      `${cdnHost}/images/library/${getPreviewThumbnail(
        values,
      )}`,
    )
    setPreviewContent(getPreviewContent(values))
  }

  return (
    <Container>
      <CreateEditForm data={data} {...props} cdnHost={cdnHost} onFormChanged={handleFormChange} />
      <PreviewAffix offsetTop={65}>
        <ContentPreview content={previewContent} thumbnail={previewThumbnail} />
      </PreviewAffix>
    </Container>
  )
}

export default ContentUpload
