import { Card, Col, Modal, Radio, RadioChangeEvent, Row, Select } from 'antd'
import moment from 'moment'
import styled from 'styled-components'

import React, { useState } from 'react'

import { ContentTag, ScheduleData, ScheduleType } from '@a4b/api'

//components
import CalendarView, {
  Props as CalendarViewProps,
} from '../../components/LibraryContent/Schedule/CalendarView'
import {
  CloneScheduleForm,
  CloneScheduleFormFields,
} from '../../components/LibraryContent/Schedule/CloneScheduleForm'
import { SUPPORTED_LANGUAGES_CONFIG_ENGLISH_MODE } from '@a4b/ui/src/modules/admin/constants/index';

interface Props extends Omit<CalendarViewProps, 'onCloneScheduleClick'> {
  tagData?: ContentTag,
  onTagTypeChanged?: (type: ScheduleType) => void,
  setLanguageCode?: (val: string) => void,
  languageCode?: string | undefined
  onCloneScheduleSubmit: (
    schedule: ScheduleData,
    values: CloneScheduleFormFields,
  ) => Promise<{ success: boolean, message: string }>
}

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
`

export const ScheduleList: React.FC<Props> = ({
  type,
  tagData,
  schedules,
  onCloneScheduleSubmit,
  onCreateScheduleClick,
  onViewScheduleClick,
  onTagTypeChanged,
  onDeleteScheduleClick,
  setLanguageCode,
  languageCode
}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [cloneFromDate, setCloneFromDate] = useState<moment.Moment>()
  const [scheduleData, setScheduleData] = useState<ScheduleData>()

  const handleCheckboxChange = (e: RadioChangeEvent) => {
    if (!onTagTypeChanged) return
    onTagTypeChanged(e.target.value)
  }

  const handleCloneScheduleClick = (data: ScheduleData) => {
    setShowModal(true)
    setCloneFromDate(moment(data.date))
    setScheduleData(data)
  }

  const handleCloseClicked = () => {
    setShowModal(false)
    setCloneFromDate(undefined)
    setScheduleData(undefined)
  }

  const handleCloneScheduleSubmit = async (values: CloneScheduleFormFields) => {
    if (!scheduleData) {
      throw new Error("Schedule data can't be empty")
    }
    const response = await onCloneScheduleSubmit(scheduleData, values)
    return response
  }
  const getTitle = () => {
    switch (type) {
      case ScheduleType.TAG_SCHEDULE:
        return 'Home Tag Schedule'
      case ScheduleType.TAG_FEATURED_SCHEDULE:
        return 'Featured Tag Schedule'
      case ScheduleType.TAG_CONTENT_SCHEDULE:
        return `${tagData ? tagData.name : ''} Tag Content Schedule`
    }
  }

  const onLanguageSelect = (value: any) => {
    if (!setLanguageCode) return
    setLanguageCode(value)
  }


  return (
    <Container>
      <Card title={getTitle()} style={{ width: '60%' }}>
        {(type === ScheduleType.TAG_SCHEDULE ||
          type === ScheduleType.TAG_FEATURED_SCHEDULE) && (
            <>
              <Row style={{ width: '100%', padding: '20px' }}>
                <Col>
                  <Radio.Group onChange={handleCheckboxChange} value={type}>
                    <Radio value={ScheduleType.TAG_SCHEDULE}>Home Tag</Radio>
                    <Radio value={ScheduleType.TAG_FEATURED_SCHEDULE}>
                      Feature Tag
                    </Radio>
                  </Radio.Group>

                </Col>
                <Select
                  showSearch
                  optionLabelProp="label"
                  optionFilterProp="label"
                  onChange={onLanguageSelect}
                  style={{ width: '400px' }}
                  value={languageCode}
                >
                  {SUPPORTED_LANGUAGES_CONFIG_ENGLISH_MODE.map((lang, index) => (
                    <Select.Option label={lang.alt_name} value={lang.value} key={lang.code}>
                      {lang.alt_name}( {lang.name} )
                    </Select.Option>
                  ))}
                </Select>
              </Row>
            </>
          )}
        {
          <CalendarView
            type={type}
            schedules={schedules}
            onViewScheduleClick={onViewScheduleClick}
            onCreateScheduleClick={onCreateScheduleClick}
            onCloneScheduleClick={handleCloneScheduleClick}
            onDeleteScheduleClick={onDeleteScheduleClick}
          />
        }
      </Card>
      <Modal
        title='Schedule Clone'
        visible={showModal}
        footer={null}
        onCancel={handleCloseClicked}
      >
        <CloneScheduleForm
          cloneFrom={cloneFromDate}
          languageCode={languageCode || tagData?.language}
          onCloneScheduleSubmit={handleCloneScheduleSubmit}
          onClose={handleCloseClicked}
        />
      </Modal>
    </Container >
  )
}
