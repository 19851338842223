import moment from 'moment'

export const extractPath = (url: string) => {
  const basePath: string =
    process.env.REACT_APP_NEW_CDN_PATH || 'https://srm-sbox-cdn.a4b.io'
  const path = url?.substring(basePath.length)
  return path
}

export const transpileFormData = (formData: any, language: string) => {
  const targetConfig = JSON.parse(formData?.target_config)
  const transpiledData = {
    ...formData,
    position: Number(formData?.position),
    start_time: formData?.start_time?.valueOf(),
    end_time: formData?.end_time?.valueOf(),
    lang: language,
    active: formData?.active,
    target_config: targetConfig,
  }
  return transpiledData
}

export const parseFormData = (formData: any, isClone: boolean) => {
  const transpiledData = {
    ...formData,
    ...(isClone && { code: '' }),
    position: Number(formData?.position),
    start_time: moment(formData?.start_time),
    end_time: moment(formData?.end_time),
    content: {
      ...formData?.content,
      icon_attachment: {
        type: formData?.content?.icon_attachment?.type,
        path: extractPath(formData?.content?.icon_attachment?.url),
      },
    },
    target_config: JSON.stringify({ ...formData?.target_cfg }, null, '\t'),
    active: formData?.active,
  }
  return transpiledData
}
