import Schedule from "@/entities/feeds/Schedule";
import { SelectOptions } from "@/utils/types";
import { daysOfWeek, widgetTypeList } from "@/utils/utilProps";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  TimePicker,
} from "antd";
import debounce from "lodash/debounce";
import moment from "moment";
import styled from "styled-components";
import NumberInput from "../components/Number";
import { SUPPORTED_LANGUAGES_LIST } from "../../../utils/constants";

const WidgetWrapper = styled.div`
  padding-right: 30px;
  padding-left: 30px;
`;
const { Option } = Select;
const { RangePicker } = DatePicker;
interface Props {
  ScheduleInstance: Schedule;
  widgetCodeSearchHandler: (value: any) => void;
  widgetCodeSelectHandler: (value: any, optionValue: any) => void;
  autoCompleteOptions: any;
  platformList: any[];
  propertyList: any[];
  isEditJourney: boolean;
  updateDaysSelection: (value: any) => void;
}
export const CreateScheduleForm = ({
  ScheduleInstance,
  widgetCodeSearchHandler,
  widgetCodeSelectHandler,
  autoCompleteOptions,
  platformList,
  propertyList,
  isEditJourney,
  updateDaysSelection,
}: Props) => {
  return (
    <WidgetWrapper>
      <Divider type="horizontal" orientation="left">
        {" "}
        Schedule Content
      </Divider>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="language"
            label={"Language"}
            initialValue={'hi'}
            required
          >
            <Select style={{ width: '250px' }}>
              {SUPPORTED_LANGUAGES_LIST.filter((language) => language.value === 'hi').map((language) => {
                return <Select.Option value={language.value}>{language.name} ({language.alt_name})</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="language_mode"
            label={"Language mode"}
            initialValue={'hi'}
            required
          >
            <Select style={{ width: '250px' }}>
              {SUPPORTED_LANGUAGES_LIST.filter((language) => language.value === 'hi' || language.value === 'en').map((language) => {
                return <Select.Option value={language.value}>{language.name} ({language.alt_name})</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            label="Platform Property Code"
            name="platform_property_code"
            required={true}
            hasFeedback={true}
            rules={[
              {
                validator: ScheduleInstance.propertyValidator,
              },
            ]}
          >
            <Select placeholder="Select Property Type" disabled={isEditJourney}>
              {propertyList?.map((item: SelectOptions, index: number) => {
                return (
                  <Option value={item.value} key={`${item.value}_${index}`}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="widget_code"
            label="Widget"
            required={true}
            hasFeedback={true}
            validateTrigger="onChange"
            rules={[
              {
                validator: ScheduleInstance.widgetCodeValidator,
              },
            ]}
          >
            <AutoComplete
              className="capitalize"
              disabled={isEditJourney}
              onSearch={debounce(widgetCodeSearchHandler, 500)}
              onSelect={widgetCodeSelectHandler}
              placeholder="Search by widget code"
              style={{ textTransform: "uppercase" }}
            >
              {autoCompleteOptions.map((item: any, index: number) => (
                <Option value={item.label} key={item.value}>
                  {item.label}
                </Option>
              ))}
            </AutoComplete>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            label="Widget Type"
            name="widget_type"
            required={true}
            rules={[
              {
                validator: ScheduleInstance.widgetTypeValidator,
              },
            ]}
            hasFeedback={true}
          >
            <Select disabled placeholder="search above by widget code">
              {widgetTypeList?.map((item: SelectOptions, index: number) => {
                return (
                  <Option value={item.value} key={`${item.value}_${index}`}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Divider type="horizontal" orientation="left">
        {" "}
        Schedule Attributes
      </Divider>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="startAndEndDay"
            required={true}
            label="Validity Date Range"
            rules={[
              {
                validator: ScheduleInstance.startAndEndDateValidator,
              },
            ]}
          >
            <RangePicker
              ranges={{
                Default: [
                  moment("2022-01-01 00:00:00", "YYYY-MM-DD HH:mm:ss"),
                  moment("2029-12-31 23:59:59", "YYYY-MM-DD HH:mm:ss"),
                ],
              }}
              showTime
              format={"YYYY-MM-DD HH:mm:ss"}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="startAndEndTime"
            label="Scheduled Time Range"
            required={true}
            rules={[
              {
                validator: ScheduleInstance.startAndEndTimeValidator,
              },
            ]}
          >
            <TimePicker.RangePicker
              ranges={{
                Default: [moment().startOf("day"), moment().endOf("day")],
              }}
              format={"HH:mm:ss"}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            label="Days of the week"
            name="daysOfTheWeek"
            required={true}
            rules={[
              {
                validator: ScheduleInstance.daysOfTheWeekValidator,
              },
            ]}
          >
            <Checkbox.Group
              onChange={(event: any) => {
                updateDaysSelection(event);
              }}
            >
              <Row>
                {daysOfWeek?.map((item: any, index: number) => {
                  return (
                    <Col span={4} key={item.value}>
                      <Checkbox
                        value={item.value}
                        style={{ lineHeight: "32px" }}
                        key={`${item.value}_${index}`}
                      >
                        {item.label}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            required={true}
            label="Order"
            name="order"
            rules={[
              {
                validator: ScheduleInstance.orderValidator,
              },
            ]}
            tooltip={{
              title: "Min Order:1 and Max Order:99",
              icon: <InfoCircleOutlined />,
            }}
          >
            <NumberInput placeholder="Order" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            required={true}
            label="Priority"
            name="priority"
            rules={[
              {
                validator: ScheduleInstance.priorityValidator,
              },
            ]}
            tooltip={{
              title: "Min Priority:1 and Max Priority:99",
              icon: <InfoCircleOutlined />,
            }}
          >
            <NumberInput placeholder="Priority" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}></Col>
      </Row>
    </WidgetWrapper>
  );
};
export default CreateScheduleForm;
