import SimpleImageUplaod from "@/components/Forms/SimpleImageUpload";
import { formatJson } from "@/utils/helper";
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Space, Switch, Tag, message } from "antd";
import { useEffect, useState } from "react"

import { v4 as uuidv4 } from 'uuid';

interface CreateActionCardsProps {
    form: any
    onFinish: (values: any) => void
    initialValues: any
    language: string
    networkInstance: any
    showForm: boolean
    closeHandler: () => void
}
const { TextArea } = Input

const CreateActionCards: React.FC<CreateActionCardsProps> = ({ form, onFinish, initialValues, language, networkInstance, closeHandler, showForm }) => {
    const [startColor, setStartColor] = useState<any>()
    const [endColor, setEndColor] = useState<any>()
    async function s3UplaodApi(fileType?: { type: string; } | undefined, options?: any): Promise<{ url: string; path: string; type?: string | undefined; }> {
        const res = await networkInstance.clientWithHeaders.shrineApi.getS3SignedUrl(uuidv4() + options?.file.name, 'action-cards', 'default')
        return res.data.data.info
    }

    useEffect(() => {
        form.setFieldsValue(initialValues)
        setStartColor(initialValues?.content?.color_code_from)
        setEndColor(initialValues?.content?.color_code_to)
    }, [form, initialValues])

    return <Drawer
        title="Create Action Card"
        placement="right"
        width={"70%"}
        visible={showForm}
        onClose={closeHandler}
    >
        <Form
            form={form}
            name="Create_action_card"
            onFinish={onFinish}
            scrollToFirstError
            onValuesChange={(changedValues, allValues) => {
                const startColorValue = allValues?.content?.color_code_from
                const endColor = allValues?.content?.color_code_to
                setStartColor(startColorValue)
                setEndColor(endColor)
            }}
        >
            <Row style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <Col span={11}>
                    <Form.Item
                        labelCol={{ span: 8 }}
                        name="code"
                        label="Card Code"
                        rules={[
                            {
                                required: true,
                                message: 'Please input card code.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                        labelCol={{ span: 8 }}
                        name="position"
                        label="Position"
                        rules={[
                            {
                                required: true,
                                message: 'Please input position.',
                            },
                        ]}
                    >
                        <Input inputMode="decimal" />
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                        labelCol={{ span: 8 }}
                        name={["content", "default", "text"]}
                        label="Default Text"
                        rules={[
                            {
                                required: true,
                                message: 'Please input default text.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                        labelCol={{ span: 8 }}
                        name={["content", "default", "cta_text"]}
                        label="Default CTA Text"
                        rules={[
                            {
                                required: false,
                                message: 'Please input default cta text.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                        labelCol={{ span: 8 }}
                        name={["content", "english", "text"]}
                        label="English Text"
                        rules={[
                            {
                                required: true,
                                message: 'Please input default text.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                        labelCol={{ span: 8 }}
                        name={["content", "english", "cta_text"]}
                        label="English CTA Text"
                        rules={[
                            {
                                required: false,
                                message: 'Please input default cta text.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <SimpleImageUplaod
                        labelCol={{ span: 8 }}
                        name={["content", "icon_attachment"]}
                        getS3PathApi={s3UplaodApi}
                        form={form}
                        allowUploadUsingUrl
                        rules={[
                            {
                                required: true,
                                message: 'Please upload icon.',
                            },
                        ]}
                    />
                </Col>
                <Col span={11}>
                    <Form.Item
                        labelCol={{ span: 8 }}
                        name={["content", "deeplink"]}
                        label="Deeplink"
                        rules={[
                            {
                                required: false,
                                message: 'Please input Deeplink.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => true
                        }
                    >
                        <>
                            <Form.Item
                                labelCol={{ span: 8 }}
                                name={["content", "color_code_from"]}
                                label="Color Code From"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input Color',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            {startColor && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        width: '30px',
                                        height: '30px',
                                        backgroundColor: startColor,
                                    }}
                                />
                            )}
                        </>
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => true
                        }
                    >
                        <>
                            <Form.Item
                                labelCol={{ span: 8 }}
                                name={["content", "color_code_to"]}
                                label="Color Code To"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input Color',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            {endColor && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        width: '30px',
                                        height: '30px',
                                        backgroundColor: endColor,
                                    }}
                                />
                            )}
                        </>
                    </Form.Item>
                </Col>
                {/* <Col span={11}>
                <Form.Item
                    labelCol={{ span: 8 }}
                    name={["target_config", "min_age"]}
                    label="Min age"
                    rules={[
                        {
                            required: true,
                            message: 'Please input Min age',
                        }
                    ]}
                >
                    <InputNumber />
                </Form.Item>
            </Col>
            <Col span={11}>
                <Form.Item
                    labelCol={{ span: 8 }}
                    name={["target_config", "max_age"]}
                    label="Max age"
                    rules={[
                        {
                            required: true,
                            message: 'Please input Max age',
                        }
                    ]}
                >
                    <InputNumber />
                </Form.Item>
            </Col>
            <Col span={11}>
                <Form.Item
                    labelCol={{ span: 8 }}
                    name={["target_config", "min_cohort"]}
                    label="Min cohort"
                    rules={[
                        {
                            required: true,
                            message: 'Please input Min cohort',
                        }
                    ]}
                >
                    <InputNumber />
                </Form.Item>
            </Col>
            <Col span={11}>
                <Form.Item
                    labelCol={{ span: 8 }}
                    name={["target_config", "max_cohort"]}
                    label="Max cohort"
                    rules={[
                        {
                            required: true,
                            message: 'Please input Max cohort',
                        }
                    ]}
                >
                    <InputNumber />
                </Form.Item>
            </Col> */}
                <Col span={11}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => true
                        }
                    >
                        {({ getFieldValue }) => (
                            <Form.Item
                                labelCol={{ span: 8 }}
                                name={"start_time"}
                                label="Start Time"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select start time.',

                                    },
                                    {
                                        validator: async (rules: any, values: any) => {
                                            const endTime = getFieldValue("end_time")
                                            if (values > endTime) {
                                                throw new Error("Start time should be less than end time.")
                                            }
                                        },
                                    }
                                ]}
                            >
                                <DatePicker showTime />
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => true
                        }
                    >
                        {({ getFieldValue }) => (

                            <Form.Item
                                labelCol={{ span: 8 }}
                                name={"end_time"}
                                label="End Time"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select end time.',

                                    },
                                    {
                                        validator: async (rules: any, values: any) => {
                                            const startTime = getFieldValue("start_time")

                                            if (startTime >= values) {

                                                throw new Error("End time should be greater than start time.")
                                            }
                                        },
                                    }
                                ]}
                            >
                                <DatePicker showTime />
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => true
                        }
                    >
                        {({ getFieldValue, setFieldsValue }) => (
                            <>
                                <Form.Item
                                    labelCol={{ span: 8 }}
                                    name="target_config"
                                    label="Target config"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Target Config',

                                        },
                                        {
                                            validator: async (rules: any, values: any) => {
                                                const targetConfig = getFieldValue("target_config")
                                                try {
                                                    JSON.parse(targetConfig)
                                                } catch (e) {
                                                    throw new Error(`${String(e)}`)
                                                }
                                            },
                                        }
                                    ]}
                                >
                                    <TextArea rows={4} />
                                </Form.Item>
                                <Tag
                                    onClick={() => {
                                        const prettyJson = formatJson(getFieldValue("target_config"))
                                        setFieldsValue({ "target_config": prettyJson })
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        top: 5,
                                        right: 0,
                                        zIndex: 1000
                                    }}
                                    color="blue"
                                >Pretty</Tag>
                            </>
                        )}
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                        labelCol={{ span: 8 }}
                        name="active"
                        label="Active Status"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ paddingTop: "50px", display: "flex", justifyContent: "end", justifyItems: "center" }}>
                    <Space>
                        <Button type="primary" htmlType="submit" style={{ justifySelf: "center" }}>
                            Submit
                        </Button>
                        <Button type="primary" htmlType="button" style={{ justifySelf: "center" }} onClick={closeHandler}>
                            Cancel
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    </Drawer >

}
export default CreateActionCards