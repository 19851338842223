import React, { useEffect, useState } from 'react'
import { useForm } from 'antd/lib/form/Form'
import { message } from 'antd'
import { useAppContext } from '@/components/AppContext'
import { TempleData } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { formatTempleCreation } from '../../utils/helper'
import TempleCreation from '../CreateTempleShrineForm'

interface Props {
    showTempleCreation: boolean
    isEditJourney: boolean
    closeTempleCreation: () => void
    formData?: TempleData
    hubList: any
}

const CreateUser: React.FC<Props> = ({
    showTempleCreation,
    closeTempleCreation: closeTempleCreation,
    isEditJourney,
    formData,
    hubList,
}) => {
    const { networkInstance, monetisationRoles } = useAppContext()
    const { monetisationOfferingsApi, templesApi } = networkInstance.clientWithHeaders

    const [editFormData, setEditFormData] = useState<any>()
    const [createTempleForm] = useForm()

    useEffect(() => {
        if (isEditJourney) {
            const editForm = {
                ...formData,
            }
            setEditFormData(editForm)
            createTempleForm.setFieldsValue(editForm)
        } else {
            setTimeout(() => {
                createTempleForm.resetFields()
            }, 300)
            setEditFormData({})

        }
    }, [isEditJourney, formData, createTempleForm])

    const handleCreateOrUpdate = async (payload: any) => {
        if (isEditJourney) {
            try {
                const id = formData?.temple_id || ''
                await templesApi.UpdateTemple(
                    { ...payload, ...{ id: id } },
                    id,
                )
                message.success('Temple updated successfully')
                createTempleForm.resetFields()
                closeTempleCreation()
            } catch (error: any) {
                message.error({
                    content: 'Error While updating temple',
                    duration: 2,
                })
            }
        } else {
            try {
                message.loading({ content: 'Creating temple...', key: 'create-temple' })
                await templesApi.CreateTemple(
                    payload,
                )
                message.success({
                    content: 'Temple created successfully',
                    duration: 2,
                })
                createTempleForm.resetFields()
                closeTempleCreation()
            } catch (error: any) {
                message.error({
                    content: 'Error while creating temple',
                    duration: 2,
                })
            }
        }
    }

    const formSubmithandler = async () => {
        try {
            const values = await createTempleForm.validateFields()
            const payload = formatTempleCreation(values)
            handleCreateOrUpdate(payload)
        } catch (error) {
            console.error('Error preparing payload:', error)
        }
    }

    return (
        <TempleCreation
            visible={showTempleCreation}
            onClose={closeTempleCreation}
            formSubmithandler={formSubmithandler}
            form={createTempleForm}
            initialValues={editFormData}
            isEditJourney={isEditJourney}
            hubList={hubList}
        />
    )
}

export default CreateUser
