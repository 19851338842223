import React, { useState, useEffect } from 'react';
import { Button, Col, List, Modal, Row, Space, Typography } from 'antd'

import {
    Batch,
    Member,
    PoojaMemberData,
} from '@a4b/api/src/modules/Monetisation/Offerings/types'
import ReactPlayer from 'react-player'

interface Props {
    visible: boolean
    closeModal: () => void
    handleDoneQc: () => void
    videoUrl: string
    batchData: Batch | undefined
    isPujaService: boolean
    videoType: string
}
const PreviewModal: React.FC<Props> = ({
    visible,
    handleDoneQc,
    closeModal,
    videoUrl,
    batchData,
    isPujaService,
    videoType
}) => {

    const [corruptedVideos, setCorruptedVideos] = useState<string[]>([]);
    const [disableDoneButton, setDisableDoneButton] = useState<boolean>(false);

    useEffect(() => {
        const checkVideoUrl = async () => {
            try {
                const response = await fetch(videoUrl);
                if (!response?.ok) {
                    setCorruptedVideos([videoUrl]);
                    setDisableDoneButton(true);
                }else {
                    setCorruptedVideos([]);
                    setDisableDoneButton(false);
                }
            } catch (error) {
                console.error("Error occurred while fetching video:", error);
            }
        };
        checkVideoUrl();
    }, [videoUrl]);

    let findMaxMembersCount: number[] = []
    if (batchData?.product_type === "pooja") {
        const max = batchData?.pooja_member_data?.reduce((prev: PoojaMemberData, current: PoojaMemberData) => {
            return (prev?.members?.length > current?.members?.length) ? prev : current
        })
        findMaxMembersCount = Array.from({ length: Number(max?.members?.length) }).map((u, i) => i)
    }
    const getUrl = (url: string) => new URLSearchParams(url?.split("?")?.pop())?.get("v")
    return (
        <Modal
            title={videoUrl?.split('/')?.pop()}
            centered
            closable={true}
            visible={visible}
            onCancel={closeModal}
            width={2000}
            destroyOnClose={true}
            footer={[
                <Button key='cancel' onClick={closeModal}>
                    Cancel
                </Button>,
                <Button key='done' type='primary' onClick={handleDoneQc} disabled={disableDoneButton}>
                    Done with QC
                </Button>,
            ]}
        >
            <Row justify='space-between'>
                <Col span={11}>
                    <Row justify='space-between' style={{ width: '100%', marginTop: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                            <span style={{ fontWeight: '500', marginBottom: '5px' }}>Intro Video</span>
                            <video width='240' height='250' controls>
                                <source src={batchData?.intro_url} type='video/mp4'></source>
                            </video>
                            <br />
                            {corruptedVideos?.includes(videoUrl) && <p style={{ color: 'red' }}>This video is corrupted.</p>}

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                            <span style={{ fontWeight: '500', marginBottom: '5px' }}>Outro Video</span>
                            <video width='240' height='250' controls>
                                <source src={batchData?.outro_url} type='video/mp4'></source>
                            </video>
                            <br />
                            {corruptedVideos?.includes(videoUrl) && <p style={{ color: 'red' }}>This video is corrupted.</p>}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginTop: '20px' }}>
                            <span style={{ fontWeight: '500', marginBottom: '5px' }}>Original Video</span>
                            {videoType === 'youtube' ? (
                                <ReactPlayer
                                    url={videoUrl}
                                    width='440px'
                                    height='250px'
                                />
                            ) : (
                                <video width='240' height='250' controls>
                                    <source src={videoUrl} type='video/mp4'></source>
                                </video>
                            )}
                            <br />
                            {corruptedVideos?.includes(videoUrl) && <p style={{ color: 'red' }}>This video is corrupted.</p>}


                        </div>
                    </Row>
                </Col>

                <Col span={12} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <Row>
                        <Col span={24}>
                            {' '}
                            <Typography.Title
                                level={4}
                            >{`Batch ID : ${batchData?.batch_id}`}</Typography.Title>
                        </Col>
                    </Row>
                    <Row>
                        {batchData?.product_type === 'pooja' ? (
                            <Col style={{ position: "relative", width: "600px", overflow: "scroll" }}>
                                <table className='puja-table'>
                                    <thead>
                                        <tr>
                                            <th>Pooja Name / ID</th>
                                            <th>SKU ID</th>
                                            <th>Gotra</th>
                                            {findMaxMembersCount?.map((item: number, index: number) => (
                                                <th key={index}>Name {index + 1}</th>
                                            ))}

                                            <th>Phone Number</th>
                                            <th>WhatsApp Number</th>
                                            <th>Opted for prasad</th>
                                            <th>Address</th>
                                            <th>Pincode</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {batchData?.pooja_member_data?.map(
                                            (pujamembers: PoojaMemberData, index: number) => {
                                                return (<tr key={index}>
                                                    <td>{batchData?.product_name}</td>
                                                    <td>{batchData?.inventory_id}</td>
                                                    <td>{pujamembers?.gotra}</td>
                                                    {findMaxMembersCount?.map(
                                                        (index: number) => (
                                                            <>
                                                                {<td key={index}>{pujamembers?.members?.[index]?.full_name}</td>}
                                                            </>
                                                        ),
                                                    )}
                                                    <td>{pujamembers?.mobile_number}</td>
                                                    <td>{pujamembers?.whatsapp_number}</td>
                                                    <td>{pujamembers?.is_opted_for_prasad ? "Yes" : "No"}</td>
                                                    <td>
                                                        {`${pujamembers?.booking_address?.house_info},${pujamembers?.booking_address?.area_info}`}
                                                        <br />
                                                        {`${pujamembers?.booking_address?.city_name},${pujamembers?.booking_address?.landmark}`}
                                                        <br />
                                                        {`${pujamembers?.booking_address?.state_name}`}
                                                    </td>
                                                    <td>
                                                        {pujamembers?.booking_address?.pin_code}
                                                    </td>
                                                </tr>)
                                            }

                                        )}
                                    </tbody>
                                </table>
                            </Col>
                        ) : (
                            <Col
                                span={20}
                                style={{
                                    height: '560px',
                                    position: 'relative',
                                    overflow: 'scroll',
                                }}
                            >
                                <List
                                    bordered
                                    dataSource={batchData?.offering_names}
                                    renderItem={(item, index) => (
                                        <List.Item>
                                            <Row>
                                                <Col>
                                                    <Space>
                                                        <Typography.Title
                                                            style={{ marginBottom: '0px' }}
                                                            level={5}
                                                        >{`${index + 1}.`}</Typography.Title>{' '}
                                                        {` ${item}`} {batchData?.meta_data?.quantity_list?.[index] ? `X ${batchData?.meta_data?.quantity_list?.[index]}` : ""}
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}

export default PreviewModal
