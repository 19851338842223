import type { Response } from '../../types'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { GetConfigRes, GetShareFunctionalityRes, GetShareAppsRes, GetShareFunctionalitiesCopyRes, GetShareAppRes, GetShareTextsRes, CreateShareAppPayload, CreateShareFunctionalityPayload, CreateShareTextPayload, UpdateShareAppPayload, UpdateShareFunctionalityPayload, UpadteShareTextPayload, ShareappTextFunctionality } from './types'
import { apiClient } from '../../apiClient'

class ShareText {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  // Write all the get calls below 

  getConfig = async () =>
    this.api.get<GetConfigRes>('/referral/internal/v1/share_apps/config')



  // GetShareText = async () =>
  //     this.api.get<GetShareTextRes>('/referral/internal/v1/share_texts/cdcd0548-1b80-4d96-b8b3-6e03115198ce')



  getShareFunctionality = async () =>
    this.api.get<GetShareFunctionalityRes>('/referral/internal/v1/share_functionalities/c907aa02-28bd-4256-84e4-9e32ee2cded0')

  searchShareFunctionalities = async (limit: number, offset: number, params: { functionality_text: string, sub_functionality_text: string }) => this.api
    .get<Response<ShareappTextFunctionality>>(`/referral/internal/v1/share_functionalities/search?limit=${limit}&offset=${offset}&functionality_text=${params.functionality_text}&sub_functionality_text=${params.sub_functionality_text}`)


  getShareApps = async (limit: number, offset = 0, platform?: string) => {
    let queryParams = '';
    if (platform) {
      queryParams += `platform=${platform}`
    }
    return this.api.get<GetShareAppsRes>(`/referral/internal/v1/share_apps?${queryParams}&limit=${limit}&offset=${offset}`)
  }


  getShareFunctionalities = async () =>
    this.api.get<GetShareFunctionalitiesCopyRes>('/referral/internal/v1/share_functionalities')



  getShareApp = async () =>
    this.api.get<GetShareAppRes>('/referral/internal/v1/share_apps/b80d82f6-c6c4-464c-99bc-655ace8a20d0')



  getShareTexts = async (limit: number, offset: number, language: string, shareFunctionalityCode: string, identifier: string) =>
    this.api.get<GetShareTextsRes>(`/referral/internal/v1/share_texts?limit=${limit}&offset=${offset}&language=${language}&share_functionality_code=${shareFunctionalityCode}&identifier=${identifier}`)

  getPreSignedUrlPng = async () =>
    this.api.get<{ data: { data: { url: string, path: string } } }, { data: { data: { url: string, path: string } } }>('/referral/internal/v1/upload/app_png')


  getPreSignedUrlJpeg = async () =>
    this.api.get<{ data: { url: string, path: string } }>('/referral/internal/v1/upload/app_jpeg')


  getPreSignedUrlJpg = async () =>
    this.api.get<{ data: { url: string, path: string } }>('/referral/internal/v1/upload/app_jpg')


  getShareTextPreSignedUrlPng = async () =>
    this.api.get<Response<{ data: { url: string, path: string } }>, Response<{ data: { url: string, path: string } }>>('/referral/internal/v1/upload/text_png')


  getShareTextPreSignedUrlJpeg = async () =>
    this.api.get<Response<{ data: { url: string, path: string } }>, Response<{ data: { url: string, path: string } }>>('/referral/internal/v1/upload/text_jpeg')


  getShareTextPreSignedUrlGif = async () =>
    this.api.get<Response<{ data: { url: string, path: string } }>, Response<{ data: { url: string, path: string } }>>('/referral/internal/v1/upload/text_gif')


  getShareTextPreSignedUrlJpg = async () =>
    this.api.get<Response<{ data: { url: string, path: string } }>, Response<{ data: { url: string, path: string } }>>('/referral/internal/v1/upload/text_jpg')


  getShareTextPreSignedUrlMp4 = async () =>
    this.api.get<Response<{ data: { url: string, path: string } }>, Response<{ data: { url: string, path: string } }>>('/referral/internal/v1/upload/text_mp4')


  // Write all the post calls below
  createShareApp = (payload: CreateShareAppPayload) =>
    this.api.post(
      '/referral/internal/v1/share_apps',
      payload,
    )

  createShareFunctionality = (payload: CreateShareFunctionalityPayload) =>
    this.api.post(
      '/referral/internal/v1/share_functionalities',
      payload,
    )

  createShareText = (payload: CreateShareTextPayload) =>
    this.api.post(
      '/referral/internal/v1/share_texts',
      payload,
    )



  // Write all the patch calls below 


  // Write all the put calls below 

  updateShareApp = (id: string, payload: UpdateShareAppPayload) =>
    this.api.put('/referral/internal/v1/share_apps/' + id, payload)



  updateShareFunctionality = (id: string, payload: UpdateShareFunctionalityPayload) =>
    this.api.put('/referral/internal/v1/share_functionalities/' + id, payload)



  upadteShareText = (id: string, payload: UpadteShareTextPayload) =>
    this.api.put('/referral/internal/v1/share_texts/' + id, payload)

}

export default ShareText;