import Riddler from "@/network/Riddler/Riddler";
import { ATTACHMENT } from "@/utils/enum";
import {
  getRiddlerCdnAbsoluteUrl,
  parseQueryString,
  referenceCodeValidator,
} from "@/utils/helper";
import { getErrorByType } from "@/utils/messages";
import { videoAttachmentType } from "@/utils/utilProps";
import {
  AutoComplete,
  Form,
  Image,
  Input,
  message,
  Modal,
  Progress,
  Select,
  Upload,
} from "antd";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../components/AppContext";
import { SEARCH_BY_UC_CODE_FOR_UPLOAD } from "@a4b/api/src/modules/Carnage/apiRoutes";

interface Props {
  /* setPreviewVisibility: (value: boolean) => void; */
  /* previewVisible: boolean;
   */
  modalVisible: boolean;
  handleCancel: () => void;
  handleOk: (attachmentId: string, previewUrl: string) => void;
  attachmentType: ATTACHMENT;

  riddlerInstance: Riddler;
}
const ModalForm = ({
  modalVisible,
  handleCancel,
  handleOk,
  attachmentType,
  riddlerInstance,
}: Props) => {
  const { networkInstance } = useAppContext();
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewFile, setPreviewFile] = useState("");
  const [progress, setProgress] = useState(0);
  const [disableUpload, setDisableUpload] = useState(false);
  const [selectedUcCode, setSelectedUcCode] = useState("");
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any>([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [previewVisible, setPreviewVisibility] = useState(false);
  const [youtubeVideoId, setYoutubeVideoId] = useState("");
  const [videoSource, setVideoSource] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");

  const searchByUcCode = async (ucCode: string) => {
    let searchUcCodePromise = new Promise(async (resolve, reject) => {
      try {
        let RIDDLERUploadBaseUrl = process.env.REACT_APP_RIDDLER_API_URL;
        let searchResponse = await networkInstance.clientWithHeaders.carnage.getSearchByCode(`${RIDDLERUploadBaseUrl}/${SEARCH_BY_UC_CODE_FOR_UPLOAD}${ucCode}`)

        resolve(searchResponse?.data?.data);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return searchUcCodePromise;
  };

  const uploadForm = Form.useForm()[0];
  useEffect(() => {
    uploadForm.resetFields();
    setAutoCompleteValue("");
    setSelectedUcCode("");
    setAutoCompleteOptions([]);
  }, [modalVisible]);
  const getUploaderTitle = () =>
    attachmentType === ATTACHMENT.VIDEO ? "Video Uploader" : "Image Uploader";

  const submitForm = async () => {
    const formmData = new FormData();
    formmData.append("ref_content_code", autoCompleteValue);
    formmData.append("media_type", attachmentType.toString());
    if (attachmentType === ATTACHMENT.VIDEO) {
      let video_youtube_id = uploadForm.getFieldValue("video_youtube_id");

      let urlObject: any;
      if (video_youtube_id.includes("?v=")) {
        urlObject = parseQueryString(video_youtube_id);
      }
      formmData.append("video_source", videoSource || "");
      formmData.append(
        "video_youtube_id",
        urlObject?.v || video_youtube_id || ""
      );
    }
    try {
      const { content } = await riddlerInstance.getUCCodeForYoutubeLink(
        formmData
      );

      setSelectedUcCode(content?.u_content_id);

      setPreviewUrl(getRiddlerCdnAbsoluteUrl(content));
      let video_attachment_type = uploadForm.getFieldValue(
        "video_attachment_type"
      );
      if (
        video_attachment_type === "INTERNAL_YOUTUBE" ||
        video_attachment_type === "EXTERNAL_YOUTUBE"
      ) {
        let previewUrl = getRiddlerCdnAbsoluteUrl(content);
        let selectedUcCode = content?.u_content_id;
        handleOk(selectedUcCode, previewUrl);
      }
    } catch (errorObj: any) {
      let errorMessage =
        getErrorByType[errorObj.type] || "Something Went wrong.";
      message.error(errorMessage);
    }
  };

  const uploadImage = async (antdOptions: any) => {
    let { file, onSuccess, onError } = antdOptions;
    const options = { ...antdOptions, setProgressToState: setProgress };

    const formData = new FormData();
    if (
      (attachmentType === ATTACHMENT.VIDEO &&
        videoSource === "INTERNAL_CUSTOM") ||
      attachmentType === ATTACHMENT.IMAGE
    ) {
      formData.append("input_file", file);
    }
    formData.append("ref_content_code", autoCompleteValue);
    formData.append("media_type", attachmentType.toString());
    if (attachmentType === ATTACHMENT.VIDEO) {
      formData.append("video_source", videoSource || "");
    }

    try {
      const { content }: any = await riddlerInstance.uploadImage(
        options,
        formData
      );
      onSuccess(content);
      setSelectedUcCode(content?.u_content_id);
      setPreviewUrl(getRiddlerCdnAbsoluteUrl(content));
    } catch (errorObj: any) {
      let errorMessage =
        getErrorByType[errorObj.type] || "Something Went wrong.";
      message.error(errorMessage);
      onError(errorObj);
    }
  };
  const fileUploadHandler = ({ file, fileList, event }: any) => {
    setFileList(fileList);
  };

  const previewHandler = (file: any) => {
    let previewUrl = getRiddlerCdnAbsoluteUrl(file?.response?.content);
    setPreviewFile(previewUrl);
    setPreviewVisibility(true);
  };

  const renderItem = (item: any) => ({
    value: item.ref_content_code,
    key: item.u_content_id,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {item.ref_content_code}
        {item.media_type === "IMAGE" && (
          <span>
            <Image
              width={50}
              height={50}
              src={getRiddlerCdnAbsoluteUrl(item)}
            />
          </span>
        )}
        {item.media_type === "VIDEO" && (
          <video
            autoPlay
            muted
            style={{ width: "80px", height: "50px" }}
            src={getRiddlerCdnAbsoluteUrl(item)}
          ></video>
        )}
      </div>
    ),
  });
  const handleSearch = async (value: string) => {
    try {
      await referenceCodeValidator(null, value);
      let searchResponse: any = await searchByUcCode(value);

      let ucCodeList = searchResponse?.contents
        .map((item: any) => {
          if (
            (attachmentType === ATTACHMENT.VIDEO &&
              item.video_source === videoSource) ||
            item.media_type === attachmentType
          ) {
            return {
              label: item.media_type,
              options: [renderItem(item)],
            };
          }
        })
        .filter((item: any) => item);
      setSelectedUcCode("");
      setAutoCompleteOptions(ucCodeList);
      setDisableUpload(false);
    } catch (error) {
      setAutoCompleteOptions([]);
    }
  };
  const handleSelect = (value: string, optionValue: any) => {
    setSelectedUcCode(optionValue?.key);

    setDisableUpload(true);
  };
  const closePreview = () => {
    setPreviewVisibility(false);
  };
  const uploadFilesCompoment = () => {
    return (
      <Form.Item
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        label="Upload file"
        name={"fileUpload"}
        fieldKey=""
        rules={[
          {
            validator: async (rule: any, value: any) => {
              let isFileUploaded = fileList.filter(
                (item) => item.status === "done"
              );
              if (!disableUpload && autoCompleteValue && !isFileUploaded.length)
                return Promise.reject("Please upload the file.");
            },
          },
        ]}
      >
        <Upload
          defaultFileList={fileList}
          listType="picture-card"
          onChange={fileUploadHandler}
          customRequest={uploadImage}
          onPreview={previewHandler}
        >
          {fileList.length === 0 || (fileList.length > 0 && fileList.length < 1)
            ? "+ Click to upload"
            : null}
        </Upload>

        {progress > 0 ? <Progress percent={progress} /> : null}
      </Form.Item>
    );
  };
  const videoAttachmentIdComponent = () => {
    return (
      <Form.Item
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        label={"Youtube Video ID"}
        name={"video_youtube_id"}
        rules={[
          {
            validator: async (rule: any, value: any) => {
              if (!value) return Promise.reject("Youtube video id required");
            },
          },
        ]}
      >
        <Input
          onChange={(event: any) => {
            setYoutubeVideoId(event?.target?.value);
          }}
          placeholder="Youtube Video ID"
        />
      </Form.Item>
    );
  };
  const getUploadComponents = () => {
    if (
      !disableUpload &&
      autoCompleteValue &&
      ((attachmentType === ATTACHMENT.VIDEO &&
        videoSource &&
        videoSource === "INTERNAL_CUSTOM") ||
        attachmentType === ATTACHMENT.IMAGE)
    ) {
      return uploadFilesCompoment();
    }
    if (
      attachmentType === ATTACHMENT.VIDEO &&
      videoSource &&
      videoSource !== "INTERNAL_CUSTOM" &&
      autoCompleteValue &&
      !selectedUcCode
    ) {
      return videoAttachmentIdComponent();
    }
  };
  const videoAttachmentTypeValidator = async (rule: any, value: any) => {
    if (!value) return Promise.reject("Video Attachment Type required!");
  };
  const getConfirmButtonStatus = () => {
    if (attachmentType === ATTACHMENT.IMAGE && selectedUcCode) {
      return false;
    }
    if (attachmentType === ATTACHMENT.VIDEO) {
      let videoAttachmentType = uploadForm.getFieldValue([
        "video_attachment_type",
      ]);
      if (videoAttachmentType === "INTERNAL_CUSTOM" && selectedUcCode) {
        return false;
      }
      let youtube_url = uploadForm.getFieldValue(["video_youtube_id"]);
      if (
        (videoAttachmentType === "INTERNAL_YOUTUBE" ||
          videoAttachmentType === "EXTERNAL_YOUTUBE") &&
        (selectedUcCode || youtube_url)
      ) {
        return false;
      }
    }
    return true;
  };
  const handleGetUcCode = () => {
    let videoAttachmentType = uploadForm.getFieldValue([
      "video_attachment_type",
    ]);
    if (
      videoAttachmentType === "INTERNAL_YOUTUBE" ||
      videoAttachmentType === "EXTERNAL_YOUTUBE"
    ) {
      if (selectedUcCode) {
        handleOk(selectedUcCode, previewUrl);
      } else {
        submitForm();
      }
    } else {
      handleOk(selectedUcCode, previewUrl);
    }
  };
  const getAutoComplete = () => {
    return (
      <>
        <Form.Item
          label="Search by ref code"
          name={"reference_code"}
          fieldKey={"reference_code"}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          rules={[
            {
              validator: async (rule: any, value: any) => {
                return referenceCodeValidator(rule, autoCompleteValue);
              },
            },
          ]}
        >
          <AutoComplete
            className="capitalize"
            value={autoCompleteValue}
            onChange={(value: any) => {
              setAutoCompleteValue(value);
            }}
            onSearch={debounce(handleSearch, 300)}
            onSelect={handleSelect}
            placeholder="Reference Code"
            options={autoCompleteOptions}
            style={{ width: "80%" }}
            notFoundContent="No content found, Please upload the content"
          ></AutoComplete>
        </Form.Item>

        {/*  <Modal visible={previewVisible} footer={null} onCancel={closePreview}>
          <img alt="example" style={{ width: "100%" }} src={previewFile} />
        </Modal> */}
      </>
    );
  };

  const getComponentsByAttachmentType = () => {
    if (attachmentType === ATTACHMENT.IMAGE) {
      return getAutoComplete();
    }
    if (attachmentType === ATTACHMENT.VIDEO) {
      return (
        <>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            name={"video_attachment_type"}
            label="Video Attachment Type"
            required={true}
            rules={[
              {
                validator: videoAttachmentTypeValidator,
              },
            ]}
            hasFeedback={true}
          >
            <Select
              onChange={(value: any) => {
                setVideoSource(value);
                uploadForm.setFieldsValue({ video_youtube_id: "" });
              }}
              placeholder="Video attachment type"
              options={videoAttachmentType}
            />
          </Form.Item>

          {videoSource && getAutoComplete()}
        </>
      );
    }
  };
  return (
    <Form.Item shouldUpdate={true} noStyle>
      {({ }) => {
        return (
          <Modal
            bodyStyle={{ height: 400 }}
            visible={modalVisible}
            title={getUploaderTitle()}
            okText="Get UC Code"
            onCancel={handleCancel}
            onOk={handleGetUcCode}
            okButtonProps={{ disabled: getConfirmButtonStatus() }}
            cancelButtonProps={{ disabled: false }}
            width={"70%"}
          >
            <Form
              name="upload"
              form={uploadForm}
              scrollToFirstError
              onValuesChange={(value) => { }}
              initialValues={{}}
              preserve={false}
            >
              <div>{getComponentsByAttachmentType()}</div>
              {!autoCompleteOptions.length && getUploadComponents()}
            </Form>
          </Modal>
        );
      }}
    </Form.Item>
  );
};
export default ModalForm;
