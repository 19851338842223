import type { UploadFile } from 'antd/es/upload/interface'
import moment from 'moment'

import { ContentFormFields } from '@a4b/ui/src/admin'

import { LibraryContent } from '@a4b/api'

const getAttachement = (fileUrl: string): UploadFile<string> => {
  const fileName = fileUrl.slice(fileUrl.lastIndexOf('/') + 1)
  return {
    uid: fileName,
    name: fileName,
    status: 'done',
    response: fileName,
    url: fileUrl,
    thumbUrl: fileUrl,
  }
}

export const getFormattedData = async ({
  name,
  description,
  shareText,
  tags,
  platforms,
  type,
  formats,
  textMetaData,
  audioMetaData,
  videoMetaData,
  pdfMetaData,
  thumbnailId,
  validFrom,
  validTo,
  showThumbnailImage
}: LibraryContent): Promise<ContentFormFields> => {
  const data: ContentFormFields = {
    name,
    description,
    shareText,
    formats,
    tags: (tags || []).map(tag => tag.id),
    platforms,
    type,
    showThumbnailImage
  }

  if (validFrom && validTo) {
    data.validity = [moment(validFrom), moment(validTo)]
  }

  if (textMetaData) {
    const response = await fetch(textMetaData.attachmentId)
    const text = await response.text()
    data.textMetaData = {
      consumptionTime: textMetaData?.consumptionTime.toString() || '0',
      attachment: text,
      thumbnailId: [getAttachement(textMetaData.thumbnailId)],
    }
  }

  if (audioMetaData) {
    data.audioMetaData = {
      consumptionTime: audioMetaData?.consumptionTime.toString() || '0',
      attachment: [getAttachement(audioMetaData.attachmentId)],
      thumbnailId: [getAttachement(audioMetaData.thumbnailId)],
    }
  }

  if (videoMetaData) {
    data.videoMetaData = {
      consumptionTime: videoMetaData?.consumptionTime?.toString() || '0',
      attachment: videoMetaData.attachmentId
        ? [getAttachement(videoMetaData.attachmentId)]
        : undefined,
      thumbnailId: [getAttachement(videoMetaData.thumbnailId)],
      youtubeLink: videoMetaData.youtubeLink,
    }
  }

  if (pdfMetaData) {
    data.pdfMetaData = {
      consumptionTime: pdfMetaData?.consumptionTime.toString() || '0',
      attachment: [getAttachement(pdfMetaData.attachmentId)],
      thumbnailId: [getAttachement(pdfMetaData.thumbnailId)],
    }
  }

  if (thumbnailId) {
    data.thumbnail = [getAttachement(thumbnailId)]
  }

  return data
}
