
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination from "@/hooks/useAntdPagination";
import { SuggestiveCard } from "@a4b/api/src/modules/SuggestionCards/types";
import { openNotification } from "../../../../utils";



const SuggestionCards = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const drawerConfig = useDrawerConfig(activeDrawer);
  const { drawerProps, drawerContent } = drawerConfig;
  const { networkInstance } = useAppContext();
  const { pagination, setPagination } = useAntdPagination();
  const [cardType, setCardType] = useState<'shubh_mantra' | 'god_set'>('god_set');
  const [isActive, setIsActive] = useState(true);
  const [suggestionCards, setSuggestiveCards] = useState<SuggestiveCard[]>([])

  const updateCardType = useCallback((cardType: 'shubh_mantra' | 'god_set') => {
    setCardType(cardType);
  }, [])

  const updateIsActive = useCallback((isActive: boolean) => {
    setIsActive(isActive);
  }, [])

  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }

  const getSuggestiveCards = useCallback(async (cardType, isActive) => {
    try {
      const res = await networkInstance.clientWithHeaders.suggestiveCards.getSuggestiveCards({ limit: pagination.pageSize || 20, offset: (pagination.pageSize || 20) * ((pagination.current || 1) - 1), cardType, activeType: isActive });
      setSuggestiveCards(res.data.data.suggestion_cards)
      if (pagination.total !== res.data.data.count) {
        setPagination({ ...pagination, current: 1, total: res.data.data.count })
      }
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.suggestiveCards, pagination, setPagination])


  const activateDeactivateSuggestionCard = useCallback(async (suggestionCardId, isActive) => {
    try {
      const res = await networkInstance.clientWithHeaders.suggestiveCards.activateDeactivateSuggestionCard(suggestionCardId, isActive);
      setSuggestiveCards(suggestionCards.map((suggestionCard) => suggestionCard.id === suggestionCardId ? { ...suggestionCard, active: !suggestionCard.active } : suggestionCard))
    } catch (error) {
      openNotification("error", { message: 'Something went worng! ', description: 'Only one active suggestion card is allowed!' })
    }
  }, [networkInstance.clientWithHeaders.suggestiveCards, suggestionCards])

  useEffect(() => {
    getSuggestiveCards(cardType, isActive);
  }, [])

  const tableConfig = useTableConfig(suggestionCards, activateDeactivateSuggestionCard, pagination, setPagination, setDrawer);


  const createSuggestionCard = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_SUGGESTIVE_CARD);
  }

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  }

  return <SocialScreenContainer
    title={"Suggestion cards"}
    filtersUI={<Filters
      getSuggestiveCards={getSuggestiveCards}
      updateCardType={updateCardType}
      updateIsActive={updateIsActive} cardType={cardType} isActive={isActive} />}
    extra={<Button onClick={createSuggestionCard}> Create Suggestion card </Button>}
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />

};

export default withDefaultLayout(SuggestionCards)