/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
//types
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Switch, Table } from 'antd';
import type { TablePaginationConfig } from 'antd/es/table';
import styled from 'styled-components';

import React from 'react';

import {
  ManageCategoryItem,
  ManageStoreCard, StoreCard
} from '@a4b/api/src/modules/Monetisation/offerings/types';

interface Props {
  onCreateNew: () => void
  onStatusChange: (storeCard: ManageCategoryItem) => void
  triggerEdit: (storeCard: ManageCategoryItem) => void
  triggerDelete: (storeCard: ManageCategoryItem) => void
  dataSource: ManageCategoryItem[]
  rowSelectionConfig: any
  totalRecordsCount: number
  paginationParams: TablePaginationConfig
  handleTableChange: (paginationParams: TablePaginationConfig) => void
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const ManageCategories: React.FC<Props> = ({
  onCreateNew,
  triggerEdit,
  triggerDelete,
  dataSource,
  onStatusChange,
  totalRecordsCount,
  paginationParams,
  handleTableChange
}) => {
  const dataColumns = [
    {
      title: 'Id',
      dataIndex: 'category_id',
      key: 'category_id',
      width: '30%',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '20%',
    },
    {
      title: 'Item Type',
      dataIndex: 'item_type',
      key: 'item_type',
      width: '15%',
    },
    {
      title: 'Template',
      dataIndex: 'template',
      key: 'template',
      width: '15%',
    },
    {
      title: 'Action',
      key: 'action',
      width: '20%',
      render: (text: any, record: any, index: any) => {
        return (
          <Row style={{ width: 200 }}>
            <Col span={8}>
              <Switch
                checked={record.active}
                onChange={() => onStatusChange(record)}
              />
            </Col>
            <Col span={8}>
              <Button onClick={() => triggerEdit(record)} type='link'>Edit</Button>
            </Col>
            <Col span={8}>
              <Button danger disabled onClick={() => triggerDelete(record)} ><DeleteOutlined /></Button>
            </Col>
          </Row>
        )
      },
    },
  ]
  return (
    <Container style={{ width: '100%' }}>
      <Space direction={'vertical'} style={{ width: '100%' }}></Space>
      <BodyContainer>
        <Button type='text' onClick={onCreateNew}>
          <PlusSquareOutlined /> Add New Category
        </Button>

        <Table
          style={{ width: "100%" }}
          //rowSelection={rowSelectionConfig}
          columns={dataColumns}
          dataSource={dataSource}
          pagination={paginationParams}
          onChange={handleTableChange}
        />
      </BodyContainer>
    </Container>
  )
}

export default ManageCategories
