import React from 'react'
import styled from 'styled-components'
import { Card, Col, Row, Space, Steps } from 'antd'

const { Step } = Steps
interface Props {
  currentStep: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  steps: any[]
  nextHandler: () => void
  previousHandler: () => void
  isEditJourney: boolean
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const OverrideSteps = styled(Steps)`
  && .ant-steps-item-description {
    max-width: 250px;
  }
  padding-bottom: 20px;
`
const CreateTemplePage: React.FC<Props> = ({
  currentStep,
  steps,
  nextHandler,
  previousHandler,
  isEditJourney,

}) => {
  return (
    <Container title='Create Store' style={{ width: '100%' }}>
      <Space direction={'vertical'} style={{ width: '100%' }}></Space>
      <OverrideSteps
        style={{ paddingLeft: 10, paddingRight: 10 }}
        current={currentStep}
      >
        {steps.map(item => (
          <Step
            key={item.title}
            title={item.title}
            description={item.description}
          />
        ))}
      </OverrideSteps>
      <Row>
        <Col span={24}>{steps[currentStep].content}</Col>
      </Row>
    </Container>
  )
}

export default CreateTemplePage
