import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { Response } from '../../../types'
import type {
  ContentTag,
  ContentTagCreateOptions,
  ContentTagQueryParams,
} from './types'
import { apiClient } from '../../../apiClient'

class TagApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createTag = (options: ContentTagCreateOptions) =>
    this.api.post<Response<{ id: string }>>(
      `/content/internal/v1/library/tags`,
      options,
    )

  updateTag = (id: string, options: Partial<ContentTagCreateOptions>) =>
    this.api.put<Response<{ id: string }>>(
      `/content/internal/v1/library/tags/${id}`,
      options,
    )
  getTags = (params?: ContentTagQueryParams) =>
    this.api.get<Response<{ tags: ContentTag[] | null }>>(
      `/content/internal/v1/library/tags`,
      {
        params: {
          ...params,
          ids: params?.ids?.join(','),
        },
      },
    )

  getTagsCount = (params?: ContentTagQueryParams) =>
    this.api.get<Response<{ count: number }>>(
      `/content/internal/v1/library/tags/count`,
      {
        params: {
          ...params,
          ids: params?.ids?.join(','),
        },
      },
    )

  getTagById = (id: string) =>
    this.api.get<Response<ContentTag>>(
      `/content/internal/v1/library/tags/${id}`,
    )

  deleteTagById = (id: string) =>
    this.api.delete(`/content/internal/v1/library/tags/${id}`)
}

export default TagApi
