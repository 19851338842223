import React from 'react';
import { Button, Col, Form, Input, Row, Switch } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import SongPlaylist from '@/entities/feeds/Widgets/SongPlaylist';
import NumberInput from '@/modules/feeds/components/Number';

import DeletableContainer from '../deletableContainer/DeletableContainer';
import HeaderTags from '../headerTags/HeaderTags';
import { SongPlaylistInstance } from '@/entities/feeds/Widgets';

interface Props {
  contentInstance: SongPlaylistInstance;
}
const SongPlaylistWidget: React.FC<Props> = ({ contentInstance }) => {
  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            required={true}
            label="Header"
            name={['content', 'header']}
            rules={[
              {
                validator: contentInstance.headerValidator,
              },
            ]}
          >
            <Input placeholder="Header" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <HeaderTags widgetClass={contentInstance} />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.List name={['content', 'items']}>
            {(fields, { add, remove, move }) => {
              return (
                <div>
                  <Row justify="start">
                    {fields.map((field: any, index: number) => {
                      return (
                        <Col
                          key={field.key}
                          span={12}
                          style={{ margin: '20px' }}
                        >
                          <DeletableContainer
                            closeHandler={() => {
                              remove(field.name);
                            }}
                            itemText={`Item Attribute : ${index + 1}`}
                            showItemTextInBold={true}
                            moveFunction={move}
                            fields={fields}
                            currentIndex={index}
                            isInnerContainer={true}
                            fieldsLength={fields.length}
                            move={move}
                            add={add}
                          >
                            <Row>
                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  labelCol={{ span: 10 }}
                                  wrapperCol={{ span: 6 }}
                                  label="Song of the day"
                                  name={[field.name, 'song_of_the_day']}
                                  valuePropName="checked"
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, currentValues) =>
                                    currentValues?.content?.items[field.name]
                                      ?.song_of_the_day !==
                                    prevValues?.content?.items[field.name]
                                      ?.song_of_the_day
                                  }
                                >
                                  {({ getFieldValue }) =>
                                    getFieldValue([
                                      'content',
                                      'items',
                                      field.name,
                                      'song_of_the_day',
                                    ]) != true ? (
                                      <Form.Item
                                        {...field}
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        label="Song ID"
                                        name={[field.name, 'r_song_id']}
                                        rules={[
                                          {
                                            validator:
                                              contentInstance?.songIdValidator,
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder="Song ID"
                                        />
                                      </Form.Item>
                                    ) : null
                                  }
                                </Form.Item>
                              </Col>
                            </Row>
                          </DeletableContainer>
                        </Col>
                      );
                    })}
                  </Row>
                  <Form.Item>
                    <Row>
                      <Col span={24}>
                        <Row justify="end">
                          <Col span={4}>
                            <Button
                              onClick={() => {
                                add();
                              }}
                              type="dashed"
                              icon={<PlusCircleOutlined />}
                              size="middle"
                            >
                              Add Item Attributes
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Col>
      </Row>
    </>
  );
};

export default SongPlaylistWidget;
