
import React from 'react'

import {
  CreateEditTag,
  Props as CreateEditTagProps
} from '../../components/LibraryContent/Tag/CreateEdit'

export const TagCreateEdit: React.FC<CreateEditTagProps> = props => {
  return <CreateEditTag {...props} />
}

export default TagCreateEdit
