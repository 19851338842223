import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form';


type InputColorPreviewProps = {
    fieldPath: any;
    formInstance: FormInstance;
};

const InputColorPreview: React.FC<InputColorPreviewProps> = ({ fieldPath, formInstance }) => {
    const [color, setColor] = useState<string>('');

    // Watch for changes in the specific form field
    const watchedColor = Form.useWatch([...fieldPath], formInstance);

    useEffect(() => {
        if (watchedColor) {
            setColor(watchedColor);
        }
    }, [watchedColor])

    const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formInstance.setFieldValue([...fieldPath], e.target.value)
        setColor(e.target.value);
    }

    return (
        <Form.Item
            label="Color codes"
            name={[...fieldPath]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: 'Please enter the first color!' }]}
        >
            <Input
                placeholder="Enter the first color"
                onChange={handleColorChange}
                value={color}
            />
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    width: '30px',
                    height: '31px',
                    backgroundColor: color,
                    border: '1px solid #ccc',
                }}
            />
        </Form.Item>
    );
};

export default InputColorPreview
