import { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { apiClient } from '../../apiClient'
import { AbexperimentPayload, Experiments, SystemIdPayload } from './types'

class AbExperiments {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  // Write all the get calls below
  getExperiments = async () =>
    this.api.get<{ data: { experiments: Experiments[] } }>(
      '/gw1/abe/internal/v1/experiment/all',
    )

  // Write all the post calls below
  addExperiments = (payload: AbexperimentPayload) => {
    return this.api.post('/gw1/abe/internal/v1/experiment', payload)
  }

  patchSystemIds = (payload: SystemIdPayload) => {
    return this.api.patch(`/gw1/abe/internal/v1/experiment`, payload)
  }

  getExperimentsBySegment = (headers?: AxiosRequestHeaders) =>
    this.api.get<{ data: { experiments: Experiments[] } }>(
      '/gw1/abe/external/v1/experiment',
      {
        headers,
      },
    )

  // Write all the patch calls below

  // Write all the put calls below
}

export default AbExperiments
