export enum MessageType {
  REGULAR = 'REGULAR',
  ANNOUNCEMENT = 'ANNOUNCEMENT'
}

export enum ScheduleStatus {
  SCHEDULED = 'SCHEDULED',
  POSTING = 'POSTING',
  POSTED = 'POSTED'
}

export type Message = {
  id: string;
  community_id: string;
  type: MessageType;
  text: string;
  has_attachment: boolean;
  has_link: boolean;
  schedule_status: ScheduleStatus;
  scheduled_for: number;
  posted_on: number;
  posted_by: string;
  created_on: number;
  created_by: string;
}
