import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import {
  AutoComplete,
  Button,
  Col,
  Row,
  Space,
  Typography,
  message,
} from 'antd'
import styled from 'styled-components'

import React, { useState } from 'react'

import { SortableItemAudio } from '@a4b/api/src/modules/Content/Audio/types'

export interface Props {
  items: SortableItemAudio[]
  onSearch: (value: string) => void
  onAddItem: (item: SortableItemAudio) => Promise<boolean>
  language_code: string
}

const { Option } = AutoComplete

const SelectImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
`

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SearchItems: React.FC<Props> = ({
  items,
  onSearch,
  onAddItem,
  language_code
}) => {
  const [searchKey, setSearchKey] = useState('')
  const handleSelect = async (value: string) => {
    const selectedItem = JSON.parse(value) as SortableItemAudio
    setSearchKey(selectedItem.name)
    if (selectedItem) {
      const success = await onAddItem(selectedItem)
      success
        ? message.success('Item added')
        : message.error('Item already exists')
    }
  }


  const handleSearch = (key: string) => {
    setSearchKey(key)
    onSearch(key)
  }

  return (
    <>
      <Row
      >
        <Col style={{ textAlign: 'right', paddingBottom: '8px' }}>
          Search Audios
        </Col>
      </Row>
      <Row>

        <Col span={24} style={{ paddingBottom: '16px' }}>
          <AutoComplete
            value={searchKey}
            showSearch
            placeholder={<p><SearchOutlined /> Search audios to sort/rearrange </p>}
            filterOption={false}
            onSearch={handleSearch}
            onSelect={handleSelect}
            style={{ width: '100%' }}
          >
            {items.map((item, index) => (
              <Option key={index} value={JSON.stringify(item)} >
                <OptionContainer style={item.audio_language !== language_code ? { background: '#FFCCCB', padding: '10px' } : {}}>
                  <Space direction='horizontal'>
                    <SelectImage src={item.imageUrl || ''} />
                    <Typography.Text>{item.name}</Typography.Text>
                  </Space>
                  <Button type='link'>
                    <PlusCircleOutlined />
                  </Button>
                </OptionContainer>
              </Option>
            ))}
          </AutoComplete>
        </Col>
      </Row>
    </>
  )
}
