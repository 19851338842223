import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, InputNumber, FormInstance, Button, Input, Select } from "antd";
import styled from "styled-components";
import { REQUIRED_RULE } from "../../../../../utils/form.validation";
import React from "react";
import { OPERATORS } from "../utils/types";

interface EventSchemaFormProps {
  form: FormInstance<any>,
  headingBackground?: string
}

const PropertiesContainer = styled.div<{ heading: string, headingBackground: string }>`
  border: 1px solid #000;
  position: relative;
  border-radius: 5px;
  margin-bottom: 80px;
  margin-top: 80px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  &:before {
    content: "${(props) => props.heading}";
    display: block;
    position: absolute;
    margin-left: 20px;
    margin-top: 0px;
    transform: translateY(-50%);
    background: ${(props: { headingBackground?: string }) => props.headingBackground || "#fff"};
    padding: 0px 15px; 
    font-size: 20px;
  }
  &>div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 3%;
    padding-bottom: 3%;
  }
`
const PropertyField = styled.div`
  margin: 0px 2% 2% 2%;
  width: 100%;
  padding: 10px;
  didplay: flex;
  border-radius: 5px;
  border: 1px dashed #000;
`;

const ButtonContainer = styled(Form.Item)`
  position: absolute;
  bottom: 0px;
  margin: 0px;
  left: 30px;
  width: 400px;
  transform: translateY(50%);
`;

const CloseCircleOutlinedStuyled = styled(CloseCircleOutlined)`
  float: right;
  font-size: 20px;
  color: red;
`;

const RULES_ENTRY_DAY_SINCE_INSTALL = [
  { required: true, message: "Entry day since install required" },
  {
    validator(rule: any, value: any, callback: any) {
      if (value >= 0 && value <= 1000) {
        callback();
      } else {
        callback("Entry day since install should be >= 0 and <= 1000");
      }
    },
  }
]

const RULES_USER_ID_MOD_100 = [
  { required: true, message: "User id mod 100 cutoff required" },
  {
    validator(rule: any, value: any, callback: any) {
      if (value >= 0 && value <= 100) {
        callback();
      } else {
        callback("User id mod 100 cutoff should be > 0 and <= 100");
      }
    },
  }
]

const RULES_PROMOTER_LEVEL = [
  { required: true, message: "Promoter level cutoff required" },
]

const FORM_ITEM_CONFIG: { [key: string]: any } = {
  'days_from_install': {
    label: 'Value ( Days from install )',
    rules: RULES_ENTRY_DAY_SINCE_INSTALL,
    formItem: <InputNumber />
  },
  'user_id_mod_100': {
    label: 'Value ( User id mod 100 )',
    rules: RULES_USER_ID_MOD_100,
    formItem: <InputNumber />
  },
  'promoter_level': {
    label: 'Value ( Promoter level )',
    rules: RULES_PROMOTER_LEVEL,
    formItem: <Select>
      <Select.Option value={1}>1</Select.Option>
      <Select.Option value={2}>2</Select.Option>
      <Select.Option value={2}>3</Select.Option>
    </Select>
  }
}


const JourneyCohortForm = ({ form, headingBackground }: EventSchemaFormProps) => {
  const entryProperties = Form.useWatch(['cohort', 'entry_properties'], form);
  return <>
    <PropertiesContainer headingBackground={headingBackground || '#fff'} heading="Entry cohort">
      <div>
        <Form.List
          name={["cohort", "entry_properties"]}
          initialValue={[{
            "path": "days_from_install",
            "operator": "GREATER_THAN_OR_EQUAL",
            "value_int64": undefined
          }
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <>
                {fields.map((field, index) => (
                  <PropertyField key={field.key}>
                    {
                      index > 0 &&
                      <CloseCircleOutlinedStuyled
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    }
                    <Form.Item name={[field.name, "path"]} label="Event property path" rules={REQUIRED_RULE}>
                      <Input disabled></Input>
                    </Form.Item>
                    <Form.Item name={[field.name, "operator"]} label="Operator" rules={REQUIRED_RULE}>
                      <Select disabled={field.name === 0}>
                        <Select.Option value={OPERATORS.EQUAL}>{OPERATORS.EQUAL}</Select.Option>
                        <Select.Option value={OPERATORS.EXISTS}>{OPERATORS.EXISTS}</Select.Option>
                        <Select.Option value={OPERATORS.GREATER_THAN_OR_EQUAL}>{OPERATORS.GREATER_THAN_OR_EQUAL}</Select.Option>
                        <Select.Option value={OPERATORS.LESS_THAN_OR_EQUAL}>{OPERATORS.LESS_THAN_OR_EQUAL}</Select.Option>
                      </Select>
                    </Form.Item>
                    {/* <Form.Item name={[field.name, "value_int64"]} label={"Value" + (index === 0 ? " ( Days from install ) " : " ( User id mod 100 ) ")}
                      rules={index === 0 ? RULES_ENTRY_DAY_SINCE_INSTALL : RULES_USER_ID_MOD_100}
                    >
                      <InputNumber />
                    </Form.Item> */}
                    {
                      entryProperties && entryProperties[field.name].operator !== OPERATORS.EXISTS &&
                      <Form.Item name={[field.name, "value_int64"]} label={FORM_ITEM_CONFIG[entryProperties[index]?.path]?.label}
                        rules={FORM_ITEM_CONFIG[entryProperties[index]?.path]?.rules}
                      >
                        {FORM_ITEM_CONFIG[entryProperties[index]?.path]?.formItem}
                      </Form.Item>
                    }
                  </PropertyField>
                ))}
              </>
              <ButtonContainer>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <Button
                    type="dashed"
                    onClick={() => add({
                      "path": "user_id_mod_100",
                      "operator": undefined,
                      "value_int64": undefined
                    }
                    )}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Add user id mod 100
                  </Button>
                  {
                    entryProperties && !entryProperties.find((property: { path: string }) => property.path === 'promoter_level')
                    &&
                    <Button
                      type="dashed"
                      onClick={() => add({
                        "path": "promoter_level",
                        "operator": undefined,
                        "value_int64": undefined
                      }
                      )}
                      style={{ width: '60%' }}
                      icon={<PlusOutlined />}
                    >
                      Add promoter level
                    </Button>
                  }
                </div>

                <Form.ErrorList errors={errors} />
              </ButtonContainer>
            </>
          )}
        </Form.List>
      </div>
    </PropertiesContainer >
    <PropertiesContainer headingBackground={headingBackground || '#fff'} heading="Exit cohort">
      <div>
        <Form.List
          name={["cohort", "exit_properties"]}
          initialValue={[{
            "path": "days_from_install",
            "operator": "GREATER_THAN_OR_EQUAL",
            "value_int64": undefined
          }
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <>
                {fields.map((field, index) => (
                  <PropertyField key={field.key}>
                    <Form.Item name={[field.name, "path"]} label="Exit property path" rules={REQUIRED_RULE}>
                      <Input disabled></Input>
                    </Form.Item>
                    <Form.Item name={[field.name, "operator"]} label="Operator" rules={REQUIRED_RULE}>
                      <Input disabled></Input>
                    </Form.Item>
                    <Form.Item name={[field.name, "value_int64"]} label={"Value" + ` ( Days from install )`}
                      rules={[
                        { required: true, message: "Entry day since install required" },
                        {
                          validator(rule, value, callback) {
                            if (value > 0 && value <= 1000) {
                              if (value <= form.getFieldValue(["cohort", "entry_properties", 0, "value_int64"])) {
                                callback("Exit day since install should be > entry day since install");
                              } else {
                                callback();
                              }
                            } else {
                              callback("Entry day since install should be > 0 and <= 1000");
                            }
                          },
                        }
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </PropertyField>
                ))}
              </>
            </>
          )}
        </Form.List>
      </div>
    </PropertiesContainer >
  </>;
};

export default JourneyCohortForm;