import { useAppContext } from "@/components/AppContext";
import AntdFileUpload, { getPathFormFile } from "@/components/Forms/AntdFileUpload";
import { openNotification } from "@/utils";
import { Button, Form, Input, message } from "antd";
import { RcFile } from "antd/lib/upload";
import { useCallback } from "react";
interface AddVideoFormProps {
}

const imageFileValidations = (file: RcFile) => {
  let isFailed = false;
  const isLt2M = file.size <= 1024 * 1024 * 20;
  if (!isLt2M) {
    isFailed = true;
    message.error('Mp3 file smaller than 1MB');
  }
  return !isFailed;
};

const AddVideoForm = function ({ }: AddVideoFormProps) {
  const { networkInstance } = useAppContext();
  const [form] = Form.useForm();
  const addVideo = useCallback(async (values) => {
    const payload = {
      code: values.code,
      path: getPathFormFile(values.path)
    }
    try {
      await networkInstance.clientWithHeaders.contentApi.audio.postVideoShareTemplate(payload);
      openNotification('success', { message: `Created new video share template` })
    } catch (error) {
      openNotification('error', { message: `New video share template creation failed` })
    }
  }, [networkInstance.clientWithHeaders.contentApi.audio])


  const getFileUploadUrl = useCallback(async () => {
    // try {
    const res = await networkInstance.clientWithHeaders.contentApi.audio.getUploadVedioTemplateUrl();
    return res.data.data
    // } catch (error) {

    // }
  }, [networkInstance.clientWithHeaders.contentApi.audio])

  return <div>
    <Form
      form={form}
      onFinish={addVideo}
      layout="vertical"
    >
      <Form.Item name={'code'} label={'Code'} rules={[{ required: true }]}>
        <Input></Input>
      </Form.Item>

      <AntdFileUpload rules={[{ required: true }]} name={'path'} accept="video/mp4,video/x-m4v,video/*" fileValidation={imageFileValidations}
        getS3PathApi={getFileUploadUrl}
        title={'upload video'} />
      <Form.Item>
        <Button type="primary" htmlType="submit" >Submit</Button>
      </Form.Item>
    </Form>
  </div>
}

export default AddVideoForm;