import { Slider } from "antd";
import { useState } from "react";

interface SliderFiltersProps {
  tooltipVisible: boolean,
  setActiveExperimentWithInRange: React.Dispatch<React.SetStateAction<number[]>>
  setHideTooltip: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SliderFilter(props: SliderFiltersProps) {
  const [range, setRange] = useState([1, 100])
  return <Slider range style={{ paddingTop: "20px" }}
    onAfterChange={(value) => {
      props.setActiveExperimentWithInRange(value);
      props.setHideTooltip(true);
      setTimeout(() => {
        props.setHideTooltip(false);
      }, 301)
    }}
    onChange={(range) => {
      setRange(range);
    }}
    min={1}
    value={[range[0], range[1]]}
    tooltipVisible={props.tooltipVisible}
    // @ts-ignore
    getTooltipPopupContainer={(a: any) => document.querySelector(".ant-slider-step")}
  />
} 