import moment, { Moment } from 'moment'

import {
  Panchang,
  TimeInfo,
} from '@a4b/api/src/modules/Calendar/panchang.types'

import { COLORS } from './colors'
import { TIME_INFO_TYPE } from './enum'
import { TIME_INFO_COLORS } from './types'
import { notification } from 'antd'

export const getTimeCardData = (panchangInfo: Panchang): TimeInfo[] => {
  const shubhSamay = {
    title: 'शुभ मुहूर्त',
    time: panchangInfo.shubhSamay.time,
    type: TIME_INFO_TYPE.GOOD,
  }
  const guliKaal = {
    title: 'गुलिकाल',
    time: panchangInfo.guliKaal.time,
    type: TIME_INFO_TYPE.MODERATE,
  }
  const rahuKaal = {
    title: 'राहुकाल',
    time: panchangInfo.rahuKaal.time,
    type: TIME_INFO_TYPE.BAD,
  }
  const yamghantKaal = {
    title: 'यमघण्टकाल',
    time: panchangInfo.yamghantKaal.time,
    type: TIME_INFO_TYPE.BAD,
  }
  const TimeInfoList: TimeInfo[] = [
    shubhSamay,
    guliKaal,
    rahuKaal,
    yamghantKaal,
  ]

  return TimeInfoList
}

export const getColorsByTimeType = (type: TIME_INFO_TYPE): TIME_INFO_COLORS => {
  let colors: TIME_INFO_COLORS = {
    backgroundColor: COLORS.YELLOW[50],
    titleColor: COLORS.ORANGE[100],
  }
  if (type === TIME_INFO_TYPE.GOOD) {
    colors = {
      backgroundColor: COLORS.SECONDARY['GREEN-50'],
      titleColor: COLORS.SECONDARY['GREEN-100'],
    }
  }
  if (type === TIME_INFO_TYPE.MODERATE) {
    colors = {
      backgroundColor: COLORS.YELLOW[50],
      titleColor: COLORS.ORANGE[100],
    }
  }
  if (type === TIME_INFO_TYPE.BAD) {
    colors = {
      backgroundColor: COLORS.PINK[50],
      titleColor: COLORS.PINK[100],
    }
  }
  return colors
}

export const arrayIntoChunks = <T>(array: T[], chunkSize: number) => {
  const res = []
  while (array.length > 0) {
    const chunk = array.splice(0, chunkSize)
    res.push(chunk)
  }
  return res
}
export const isToday = (date: Moment) => {
  const datePoint = date.startOf('day')
  return moment().startOf('day').isSame(datePoint)
}
export const isTomorrow = (date: Moment) => {
  const datePoint = date.startOf('day')
  const tomo = moment().startOf('day').add(1, 'days')
  return tomo.isSame(datePoint)
}

export enum DAYS {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
}
export const getCurrentDateFormated = () => {
  const currentDate = new Date()
  let month: string | number = currentDate.getMonth() + 1
  month = month.toString.length > 1 ? month : `0${month}`
  let date: string | number = currentDate.getDate()
  date = date.toString.length > 1 ? date : `0${date}`
  return `${Number(date)}-${Number(month)}-${currentDate.getFullYear()}`
}

export const getDaysBetweenDates = function (
  startDate: Moment,
  endDate: Moment,
  format: string,
) {
  const now = startDate.clone(),
    dates = []
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(format))
    now.add(1, 'days')
  }
  return dates
}

export const SITEMAP_URL =
  'https://s3.ap-south-1.amazonaws.com/cdn.appsforbharat.com/sitemap/panchang/sitemap.xml'


export const getDateWordFromDate = (date: string) => {
  const dateObj = moment(date, 'DD-MM-YYYY')
  let day = ''
  if (dateObj.isSame(new Date(), 'day')) {
    day = 'today'
  } else if (
    dateObj.isSame(moment().clone().subtract(1, 'days').startOf('day'), 'day')
  ) {
    day = 'yesterday'
  } else if (
    dateObj.isSame(moment().clone().add(1, 'days').startOf('day'), 'day')
  ) {
    day = 'tomorrow'
  } else {
    day = date
  }
  return day
}


export const openErrorNotification = (error: any) => {
  notification.config({ duration: 10 })
  let description = ""
  if (error?.response?.data?.error?.message && error?.response?.data?.error?.code) {
    description = error?.response?.data?.error?.message
  } else {
    try {
      Object.keys(error?.response?.data?.error).forEach((key) => {
        description += `\n${key}: `
        error?.response?.data?.error[key].forEach((err: string) => {
          description += `\n ${err}`
        })
      })
    } catch (error) {
      description = "Something went wrong!"
    }
  }
  notification['error']({
    message: "Error",
    description: description
  });
};

