/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
//types
import { PlusSquareOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Image, List, Row, Space, Table } from 'antd'
import styled from 'styled-components'

import React from 'react'

import { Product } from '@a4b/api/src/modules/Monetisation/offerings/types'
import S3Upload, { AllowedFormats } from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'



const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  width:100%;
  .ant-card-body {
    flex-grow: 1;
  }
`
const FormContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  width:100%;
  height:300px;
  background:#edece8;
  padding:20px 50px 20px 50px;
  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`
export interface EPujaVideoProps {
  cdnUrl: string
  preSignUrl: string
  formSubmithandler: () => void
  form: any
  initialValues: any
  dataSource: any[]
}
const EpujaVideoUpload: React.FC<EPujaVideoProps> = ({ cdnUrl, preSignUrl, form, initialValues, dataSource, formSubmithandler }) => {

  return (
    <Container title='Upload Intro, Outro with pooja video for video processing' >
      <Space direction={'vertical'} style={{ width: '100%' }}></Space>
      <BodyContainer>
        <Row style={{ width: '100%' }}>
          <Col span={10} style={{ height: "400px", position: "relative", overflow: "scroll", padding: "20px" }}>
            <List
              header={<div>Uploaded videos</div>}
              bordered
              dataSource={dataSource}
              renderItem={item => (
                <List.Item>
                  <Row>
                    <Col span={24}>
                      <b>
                        Uploaded File:
                      </b>
                      {item?.uploadedFileName}
                    </Col>
                    <Col span={24}>
                      <b>
                        Final Video:
                      </b>
                      {item?.videoUrl}
                    </Col>
                    <Col span={24}>
                      <video width="220" height="140" controls src={item?.videoUrl}>
                      </video>
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </Col>
          <Col span={14}>
            <Form
              form={form}
              layout='vertical'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={initialValues}
            >
              <FormContainer>
                <Row>
                  <Col
                    span={4}
                    style={{
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      height: '50px',
                    }}
                  >
                    <Row justify='end'>
                      <Form.Item
                        style={{ height: '50px' }}
                        label='Intro Video'
                        name={'intro_video_url'}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        valuePropName='fileList'
                        rules={[
                          {
                            min: 1,
                            max: 1,
                            validator: async (
                              rules: any,
                              values: any,
                            ) => {
                              if (values?.length < rules.min) {
                                return Promise.reject(
                                  'Please upload intro video',
                                )
                              }
                            },
                          },
                        ]}
                      >
                        <S3Upload
                          preserveRawFilename={true}
                          allowedFileExtension={[AllowedFormats.MP4]}
                          cdnUrl={cdnUrl}
                          preSignUrl={preSignUrl}
                          min={1}
                          max={1}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col
                    span={2}
                  ></Col>
                  <Col
                    span={4}
                    style={{
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      height: '50px',
                    }}
                  >
                    <Row justify='end'>
                      <Form.Item
                        style={{ height: '50px' }}
                        label='Puja video'
                        name={'raw_video_url'}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        valuePropName='fileList'
                        rules={[
                          {
                            min: 1,
                            max: 1,
                            validator: async (
                              rules: any,
                              values: any,
                            ) => {
                              if (values?.length < rules.min) {
                                return Promise.reject(
                                  'Please upload pooja video',
                                )
                              }
                            },
                          },
                        ]}
                      >
                        <S3Upload
                          preserveRawFilename={true}
                          allowedFileExtension={[AllowedFormats.MP4]}
                          cdnUrl={cdnUrl}
                          preSignUrl={preSignUrl}
                          min={1}
                          max={1}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col
                    span={2}
                  ></Col>
                  <Col
                    span={4}
                    style={{
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      height: '50px',
                    }}
                  >
                    <Row justify='end'>
                      <Form.Item
                        style={{ height: '50px' }}
                        label='Outro Video'
                        name={'outro_video_url'}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        valuePropName='fileList'
                        rules={[
                          {
                            min: 1,
                            max: 1,
                            validator: async (
                              rules: any,
                              values: any,
                            ) => {
                              if (values?.length < rules.min) {
                                return Promise.reject(
                                  'Please upload outro video',
                                )
                              }
                            },
                          },
                        ]}
                      >
                        <S3Upload
                          preserveRawFilename={true}
                          allowedFileExtension={[AllowedFormats.MP4]}
                          cdnUrl={cdnUrl}
                          preSignUrl={preSignUrl}
                          min={1}
                          max={1}
                        />
                      </Form.Item>
                    </Row>
                  </Col>

                </Row>

              </FormContainer>
              <Row style={{ position: "relative", paddingTop: "10px" }}>
                <Col span={18}>
                </Col>
                <Col span={6}>
                  <Button type='primary' onClick={formSubmithandler}>Submit</Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>


      </BodyContainer>
    </Container>
  )
}

export default EpujaVideoUpload
