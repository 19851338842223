import { notification } from "antd";


export function isLoginEnabled() {
  const loginEnabled = process.env.REACT_APP_LOGIN_ENABLED === 'true' || process.env.REACT_APP_LOGIN_ENABLED === undefined
  return loginEnabled
}

export function extractFilename(fileUrl: string) {
  return fileUrl.substring(fileUrl.lastIndexOf('/') + 1)
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const openNotification = (type: NotificationType, data: { message: string, description?: string, onClick?: () => void }) => {
  notification.config({ duration: 10 })
  notification[type](data);
};


export const openErrorNotification = (error: any) => {
  notification.config({ duration: 10 })
  let description = ""
  if (error?.response?.data?.error?.message && error?.response?.data?.error?.code) {
    description = error?.response?.data?.error?.message
  } else {
    try {
      Object.keys(error?.response?.data?.error).forEach((key) => {
        description += `\n${key}: `
        error?.response?.data?.error[key].forEach((err: string) => {
          description += `\n ${err}`
        })
      })
    } catch (error) {
      description = "Something went wrong!"
    }
  }
  console.log(error?.response?.data?.error?.args, 'chcek ');
  const errorTraceNudge: { message: string, description: string }[] = []
  if (error?.response?.data?.error?.args) {
    const args = typeof error?.response?.data?.error?.args === 'string' ? JSON.parse(error?.response?.data?.error?.args) : error?.response?.data?.error?.args;
    const extractAllErrors = (args: any, level: string) => {
      Object.keys(args).forEach((key) => {
        if (key === 'affirmative' || key === 'fallback') {
          extractAllErrors(args[key], level + key + ' -> ')
        } else {
          errorTraceNudge.push({
            message: level,
            description: `${key}: ${JSON.stringify(args[key])}`
          })
        }
      })
    }
    extractAllErrors(args, '');
  }
  notification['error']({
    message: "Error",
    description: description
  });

  errorTraceNudge.forEach((error) => {
    notification['error'](error);
  });
};
