import { AudioUploadThumbnailOptions } from '@a4b/api/src/modules/Content/Audio/types';
import { UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Modal, Upload } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload';
import React, { useState } from 'react';

interface UploadThumbnailModalProps {
  uploadThumbnailS3: (file: RcFile) => Promise<string>
  uploadThumbnail: (options: AudioUploadThumbnailOptions) => Promise<string>
}
const UploadThumbnailModal: React.FC<UploadThumbnailModalProps> = (props: UploadThumbnailModalProps) => {
  const { uploadThumbnailS3, uploadThumbnail } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alert, setAlert] = useState<{ type: 'success' | 'error', message: string }>()

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onUpload: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
  }) => {
    const response = await uploadThumbnailS3(file as RcFile)
    if (onSuccess) {
      onSuccess(response)
    }
  }

  const onFinish = async (data: any) => {
    try {
      const res = await uploadThumbnail({
        path: data.image_path.file.response,
        code: data.code
      })
      setAlert({ type: 'success', message: "Thumbnail uploaded successfully!" });
      return res;
    } catch (error: any) {
      setAlert({ type: 'error', message: error.toString() });
    }

  }

  const onFinishFailed = (data: any) => {
    console.log({ data })

  }

  return (
    <>
      <Button type="link" onClick={showModal}>
        Upload Thumbnail
      </Button>
      {
        isModalOpen &&
        <Modal title=" Upload Thumbnail" visible={true} onCancel={handleCancel} footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>
        ]}>
          {alert && <Alert
            {...alert}
            closable
            onClose={() => { setAlert(undefined) }}
          />}

          <Form
            name='music-metadata-upload-form'
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <Form.Item
              name="image_path"
              wrapperCol={{ span: 18, offset: 6 }}
            >
              <Upload
                multiple={false}
                maxCount={1}
                accept={'.png,.jpg,.jpeg'}
                name='image_path'
                listType='picture'
                customRequest={onUpload}
              >
                <Button icon={<UploadOutlined />}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true, message: 'Please select a code' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
              <Button type='primary' htmlType='submit'>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      }
    </>
  );
};

export default UploadThumbnailModal;
