//context
import { useAppContext } from '@/components/AppContext'
//hoc
import { message, Modal } from 'antd'
import type { TablePaginationConfig } from 'antd/es/table'

//organisms
//hooks
//types
import React, { useCallback, useEffect, useState } from 'react'

import ManageCategories from '@a4b/ui/src/modules/monetisation/organisms/ManageCategories'

import {
  EditCategory,
  ManageCategoryItem,
  ManageStoreCard,
  StoreCard
} from '@a4b/api/src/modules/Monetisation/Offerings/types'

import CreateCategory from '../CreateCategory'


interface TabProps {
  currentTab: string
}

const ManageCategoriesScreen: React.FC<TabProps> = ({ currentTab }) => {
  const { networkInstance } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

  const [categories, setCategories] = useState<ManageCategoryItem[]>([])
  const [showItemCreation, setShowItemCreation] = React.useState(false)
  const [isEditJourney, setIsEditJourney] = React.useState(false)
  const [showCategoryEnableModal, setShowCategoryEnableModal] = useState(false)
  const [showCategoryDisableModal, setShowCategoryDisableModal] = useState(false)
  const [totalRecordsCount, setTotalRecordsCount] = useState(0)
  const [paginationParams, setPaginationParams] =
    useState<TablePaginationConfig>({
      current: 1,
      pageSize: 10,
    })

  const [editCategory, setEditCategory] = useState<EditCategory>()
  const [categoryForStatusUpdate, setCategoryForStatusUpdate] =
    useState<ManageCategoryItem>()
  const [storeCards, setStoreCards] = useState<ManageStoreCard[]>([])
  const rowSelectionChange = (selectedRowKeys: any) => {
    setRowSelectionConfig({
      ...rowSelectionConfig,
      selectedRowKeys: [...selectedRowKeys],
    })
  }

  const [rowSelectionConfig, setRowSelectionConfig] = useState<any>({
    selectedRowKeys: [],
    onChange: rowSelectionChange,
  })

  const getCategories = useCallback(async () => {
    if (currentTab !== "categories") {
      return;
    }
    try {
      const categories = await monetisationOfferingsApi.storesApi.getCategories({
        limit: paginationParams.pageSize,
        offset:
          Number((paginationParams?.current || 1) - 1) * Number(paginationParams.pageSize),
      })

      setCategories(categories?.data?.data?.categories)
      setPaginationParams({ ...paginationParams, total: categories?.data?.data?.count || 10 })
    } catch (error) {
      message.error({
        content: 'Error while fetching categories',
        duration: 3,
      })
      console.log(error)
    }
  }, [monetisationOfferingsApi.storesApi, currentTab])

  useEffect(() => {
    getCategories()
  }, [getCategories])

  const onCreateNew = () => {
    setShowItemCreation(true)
    setIsEditJourney(false)
    setEditCategory(undefined)
  }

  const onFinishFailed = (error: any) => {
    //console.log('Item Creation Form Error==>', error)
  }
  const showItemForm = () => {
    setShowItemCreation(true)
  }
  const closeItemCreationForm = () => {
    setShowItemCreation(false)
    isEditJourney && setIsEditJourney(false)
    setEditCategory(undefined)
    setTimeout(() => {
      getCategories()
    }, 1000)
  }
  const onStatusChange = async (category: ManageCategoryItem) => {
    setCategoryForStatusUpdate(category)
    if (category?.active) {
      setShowCategoryDisableModal(true)
    } else {
      setShowCategoryEnableModal(true)
    }
  }

  const triggerEdit = async (categoryItem: ManageCategoryItem) => {
    try {
      const categoryResponse =
        await monetisationOfferingsApi.storesApi.getCategoryById(categoryItem?.category_id || '')
      setEditCategory(categoryResponse?.data?.data.category)
    } catch (error) {
      console.log(error)
      message.error({
        content: 'Error while fetching category.',
        duration: 2,
      })
    }
    setIsEditJourney(true)
    setShowItemCreation(true)
  }

  const triggerDelete = async (category: ManageCategoryItem) => {
    try {
      await monetisationOfferingsApi.storesApi.deleteStoreCard(category?.category_id || '')
      message.success({
        content: 'Store card deleted successfully.',
        duration: 2,
      })

    } catch (error) {
      console.log(error)
      message.error({
        content: 'Error while deleting store card.',
        duration: 2,
      })
    }
  }

  const onItemCreateHandler = () => {
    getCategories()
  }

  const onModalOk = async () => {
    message.loading({
      content: 'Updating store card status',
      duration: 1,
      key: 'update_status',
    })

    try {
      const categoryResponse =
        await monetisationOfferingsApi.storesApi.getCategoryById(categoryForStatusUpdate?.category_id || '')
      const formData: any = categoryResponse?.data?.data?.category
      formData["data_uuids"] = formData?.card_items?.map((item: ManageCategoryItem) => item?.id)
      await monetisationOfferingsApi.storesApi.updateCategory(
        categoryForStatusUpdate?.category_id || '',
        { ...formData, item_type: formData?.card_type, active: !formData?.active },
      )
      setShowCategoryEnableModal(false)
      setShowCategoryDisableModal(false)
      message.success({
        content: 'Category status updated sucessfully',
        duration: 3,
        key: 'update_status',
      })
      setTimeout(() => {
        getCategories()
      }, 1000)
    } catch (error) {
      setShowCategoryEnableModal(false)
      setShowCategoryDisableModal(false)
      message.error({
        content: 'Error while updating store card status',
        duration: 2,
        key: 'update_status',
      })

      console.log(error)
    }
  }

  const onModalCancel = () => {
    setShowCategoryEnableModal(false)
    setShowCategoryDisableModal(false)
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {

    setPaginationParams({
      ...pagination,
    })
  }
  return (
    <div >
      <ManageCategories
        onCreateNew={onCreateNew}
        onStatusChange={onStatusChange}
        triggerEdit={triggerEdit}
        triggerDelete={triggerDelete}
        dataSource={categories}
        rowSelectionConfig={rowSelectionConfig}
        totalRecordsCount={totalRecordsCount}
        paginationParams={paginationParams}
        handleTableChange={handleTableChange}
      />
      {
        <CreateCategory
          showItemCreation={showItemCreation}
          closeItemCreation={closeItemCreationForm}
          createItemCallback={onItemCreateHandler}
          UpdateItemCallback={onFinishFailed}
          isEditJourney={isEditJourney}
          formData={editCategory}
        />
      }
      <Modal
        title='Activate Category'
        visible={showCategoryEnableModal}
        onOk={onModalOk}
        onCancel={onModalCancel}
        okText=' Yes, Activate'
      >
        <p>Are you sure to activate this category ?</p>
      </Modal>
      <Modal
        title='Deactivate Category'
        visible={showCategoryDisableModal}
        onOk={onModalOk}
        onCancel={onModalCancel}
        okText='Yes, Deactivate'
      >
        <p>Are you sure to Deactivate this category ?</p>
      </Modal>
    </div>
  )
}

export default ManageCategoriesScreen
