import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { UserSegment, UserSegments } from './types'

class UserSegmentsApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getAllUserSegments = (paginationParams: any, filterParams: any) =>
    this.api.get<UserSegments>(`yoda/internal/v1/user-segments/`, {
      params: {
        limit: paginationParams?.limit,
        offset: paginationParams?.offset,
        filter_type: filterParams?.filterType
      },
    })

  getUserSegmentById = (id: string) =>
    this.api.get(`yoda/internal/v1/user-segments/${id}`)

  createUserSegment = (payload: any) =>
    this.api.post(`yoda/internal/v1/user-segments`, payload)

  updateUserSegment = (id: string, payload: any) =>
    this.api.put(`yoda/internal/v1/user-segments/${id}`, payload)

  updateUserSegmentStatus = (id: string, payload: any) =>
    this.api.patch(`/yoda/internal/v1/user-segments/${id}`, payload)
}

export default UserSegmentsApi
