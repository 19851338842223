import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { Response, Tutorial } from './types'

class Tutorials {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createTutorials = (payload: Tutorial) =>
    this.api.post<Response<any>>(
      `/yoda/internal/v1/tutorials/create-tutorial`,
      payload,
    )
  updateTutorials = (payload: Tutorial) =>
    this.api.put<Response<any>>(
      `yoda/internal/v1/tutorials/${payload?.id}/update-tutorial`,
      payload,
    )
  deleteTutorials = (id: string) =>
    this.api.delete<Response<any>>(`yoda/internal/v1/tutorials/${id}`)

  getTutorials = ({ tutorial_type }: { tutorial_type: string }) =>
    this.api.get<Response<Tutorial>>(
      `/yoda/internal/v1/tutorials/get-tutorial?tutorial_type=${tutorial_type}`,
    )
}

export default Tutorials
