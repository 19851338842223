import { Form, Input, InputNumber, Button, Popconfirm, Checkbox } from "antd";
import styled from "styled-components";
import EventSchemaForm from "./EventSchemaForm";
import NudgeForm from "./NudgeForm";
import { Affirmative, Fallback, ICreateNode, IUpdateNode, Journey } from "../utils/types";
import { useCallback, useEffect } from "react";
import { REQUIRED_RULE } from "../../../../../utils/form.validation";

const HiddenFormItem = styled(Form.Item)`
  display: none;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`

const StyledDiv = styled.div`
    width: 100%;
`
const StyledForm = styled(Form)`
  overflow: scroll;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  background: ${(props: { backgorund?: string }) => props.backgorund || "#fff"};
`;

export interface AffirmationFallbackCreateProps {
  onCreate?: ICreateNode,
  type: "affirmative" | "fallback",
  parentIdentifier: [number, number],
  node?: Journey | Affirmative | Fallback,
  onUpdate?: IUpdateNode,
  editIdentifier?: [number, number],
  updateEditIdentifier?: (identifier?: [number, number]) => void,
  background: string
}

const AffirmationFallbackCreate = (props: AffirmationFallbackCreateProps) => {
  const [form] = Form.useForm();
  const { onCreate, type, parentIdentifier
    , node, onUpdate, updateEditIdentifier, background } = props;
  const onFinish = (values: any) => {
    console.log(values, "values");
    if (values['configure-nudge-template'] === false) {
      values['nudge'] = null;
    }
    try {
      delete values['timeout_interval_seconds'];
      delete values['configure-nudge-template'];
      delete values["nudge"]["wait_interval_seconds"];
    } catch (error) {

    }

    if (node) {
      if (onUpdate) onUpdate(parentIdentifier, values);
      if (updateEditIdentifier) updateEditIdentifier(undefined);
      return;
    }
    const fallbackParentIdentifier: [number, number] = [parentIdentifier[0] - 1, parentIdentifier[1]];
    if (onCreate) onCreate(type === "fallback" ? fallbackParentIdentifier : parentIdentifier, { type, values });
  }

  useEffect(() => {
    if (node) {
      const nodeCopy = { ...node }

      if ('timeout_interval' in node) {
        // @ts-ignore
        nodeCopy['timeout_interval_seconds'] = node['timeout_interval'] / 1000;
      }
      if ('nudge' in node && node['nudge'] && node['nudge']['template'] !== undefined) {
        form.setFieldValue(["configure-nudge-template"], true);
      } else {
        form.setFieldValue(["configure-nudge-template"], false);
      }

      if ('nudge' in node && node['nudge']) {
        if ('wait_interval_millis' in node['nudge']) {
          form.setFieldValue(["nudge", "wait_interval_seconds"], (node['nudge']['wait_interval_millis'] || 0) / 1000);
        }
      }

      if ('trigger_count_cap' in node) {
        form.setFieldValue(["configure_trigger_count_cap"], true);
      } else {
        form.setFieldValue(["configure_trigger_count_cap"], false);
      }

      form.setFieldsValue(nodeCopy);
    }
  }, [node, form]);

  const resetNudgeForm = useCallback(() => {
    form.setFieldValue(["nudge", "template"], undefined);
    form.setFieldValue(["nudge", "page_tag"], undefined);
    form.setFieldValue(["nudge", "anchor_tag"], undefined);
  }, [form])

  const showNudgeTemplate = Form.useWatch('configure-nudge-template', form);
  const configureTriggerCountCap = Form.useWatch('configure_trigger_count_cap', form);

  useEffect(() => {
    if (showNudgeTemplate === false) {
      resetNudgeForm();
    }
  }, [showNudgeTemplate, resetNudgeForm])

  return <StyledDiv >
    <StyledForm
      form={form}
      onFinish={onFinish}
      layout="vertical"
      backgorund={background}
    >
      <Form.Item name="name" label="Name" required>
        <Input />
      </Form.Item>
      <Form.Item name={"timeout_interval_seconds"} label="Timeout interval (seconds)" rules={[REQUIRED_RULE[0], {
        validator(rule, value, callback) {
          if (value >= 0 && value <= 100) {
            callback();
          } else {
            callback("Timeout should be >= 0 and <= 100");
          }
        },
      }]}>
        <InputNumber onChange={(interval_seconds: number) => { if (interval_seconds) form.setFieldValue('timeout_interval', interval_seconds * 1000) }} />
      </Form.Item>
      <HiddenFormItem name="timeout_interval" >
        <InputNumber />
      </HiddenFormItem>
      <HiddenFormItem name={"affirmative"} initialValue={undefined} />
      {
        type === 'affirmative' &&
        <HiddenFormItem name={"fallback"} initialValue={undefined} />
      }
      <EventSchemaForm type={type} form={form} headingBackground={background} />
      <Form.Item name={"configure-nudge-template"} label="Configure nudge template" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      {
        showNudgeTemplate &&
        <NudgeForm form={form} headingBackground={background} type={type}></NudgeForm>
      }
      {
        type === 'affirmative' &&
        <>
          <Form.Item label="Configure trigger count cap" name={"configure_trigger_count_cap"} valuePropName="checked">
            <Checkbox onChange={(e) => {
              if (e.target.checked) {
                form.setFieldValue('trigger_count_cap', undefined);
              }
            }}></Checkbox>
          </Form.Item>
          {
            configureTriggerCountCap &&
            <Form.Item label="Trigger count cap" name={"trigger_count_cap"} rules={[{ required: true }]}>
              <InputNumber />
            </Form.Item>
          }
        </>
      }
      <ButtonContainer>
        <Button type="primary" htmlType="submit"> {node
          ? 'Update' : 'Add'} </Button>
        {
          node
            ? <Popconfirm
              placement="top"
              title={"Are you sure you want to exit edit mode? All changes will be lost."}
              onConfirm={() => {
                if (updateEditIdentifier) updateEditIdentifier(undefined);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="default"  > Cancel </Button>
            </Popconfirm> : <Popconfirm
              placement="top"
              title={"Are you sure you want to exit edit mode?"}
              onConfirm={() => {
                const fallbackParentIdentifier: [number, number] = [parentIdentifier[0] - 1, parentIdentifier[1]];
                onCreate && onCreate(type === "fallback" ? fallbackParentIdentifier : parentIdentifier, { type, values: undefined })
              }}
              okText="Yes"
              cancelText="No"
            >
              {parentIdentifier.toString() !== "0,1" && <Button type="default"  > Remove </Button>}
            </Popconfirm>
        }
      </ButtonContainer>
    </StyledForm>
  </StyledDiv>
}

export default AffirmationFallbackCreate;