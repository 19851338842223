import { Col, Form, Row, Button, Input, Select } from "antd"
import React from "react"
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'

interface Props {
    fields: any
    add: () => void
    remove: (item: any) => void
}

const Translations: React.FC<Props> = ({ fields, add, remove }) => {
    return (
        <>
            <Row>
                {fields.map((field: any, index: number) => {
                    return (
                        <div key={index} style={{
                            border: '1.5px dashed #e0dcdc',
                            margin: '10px',
                            width: '28vw',
                            padding: '10px',
                            gap: '5px'
                        }}>
                            <Col
                                style={{
                                    marginLeft: '3px',
                                    display: 'contents'
                                }}>
                                <Row style={{ width: '100%' }} justify="end">
                                    <Button
                                        onClick={() => {
                                            remove(field.name)
                                        }}
                                        type='ghost'
                                        icon={<DeleteOutlined />}
                                        size='middle'
                                        danger
                                    ></Button>

                                </Row>
                                <Form.Item
                                    label={`City name ${index + 1}`}
                                    name={[field.name, 'city_name']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                >
                                    <Input placeholder="Input City name" />
                                </Form.Item>
                                <Form.Item
                                    label={`State name ${index + 1}`}
                                    name={[field.name, 'state_name']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    style={{ marginTop: '10px' }}
                                >
                                    <Input placeholder="Input State name" />
                                </Form.Item>
                                <Form.Item
                                    label={`Select language ${index + 1}`}
                                    name={[field.name, 'language_code']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    style={{ marginTop: '10px' }}
                                >
                                    <Select
                                        placeholder="Select language"
                                        options={[
                                            { label: 'Hindi', value: 'hi' },
                                            { label: 'English', value: 'en' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        </div>
                    )
                })}
            </Row>
            <Col span={24} style={{ paddingTop: '5px', margin: '10px' }}>
                <Row justify='start'>
                    <Col span={4}>
                        <Button
                            onClick={() => {
                                add()
                            }}
                            type='ghost'
                            icon={<PlusCircleOutlined />}
                            size='middle'
                        >
                            Add Translations
                        </Button>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default Translations