/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Drawer,
  Form, Input,
  Row,
  Select
} from 'antd'

import React from 'react'

import {
  CreateStore, StoreCard
} from '@a4b/api/src/modules/Monetisation/offerings/types'


const { TextArea } = Input
const { Option } = Select
interface Props {
  onClose: () => void
  visible: boolean
  cdnUrl: string
  preSignUrl: string
  formSubmithandler: (values: any) => void
  form: any
  initialValues: StoreCard
  isEditJourney: boolean
  storeList: CreateStore[]
  onFormChange: (formValues: any) => void
  openSelectionWindow: () => void
  sortedData: any[]
  componentRender: any
}
const CategoryCreation: React.FC<Props> = ({
  onClose,
  visible,
  cdnUrl,
  preSignUrl,
  formSubmithandler,
  form,
  initialValues,
  isEditJourney,
  storeList,
  onFormChange,
  openSelectionWindow,
  componentRender,
  sortedData,
}) => {
  return (
    <Drawer
      title='Create New Category'
      placement='right'
      onClose={onClose}
      visible={visible}
      destroyOnClose={true}
      size='large'
    >
      <Form
        form={form}
        layout='vertical'
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        initialValues={initialValues}
        onValuesChange={onFormChange}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label='Title'
              name='title'
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
              rules={[{ required: true, message: 'Enter title', }]}
            >
              <Input placeholder='Enter title' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Description'
              name='description'
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
              rules={[
                {
                  required: true,
                  message: 'Enter description of the category',
                },
              ]}
            >
              <TextArea placeholder='Enter description' rows={3} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Select Template'
              name='template'
              rules={[{ required: true, message: 'Please select template!' }]}
            >
              <Select placeholder='Please select template'>
                <Option value={'card_small'} key={'card_small'}>
                  Small Card
                </Option>
                <Option value={'card_medium'} key={'card_medium'}>
                  Medium Card
                </Option>
                <Option value={'card_large'} key={'card_large'}>
                  Large Card
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={`Position`}
              name='position'
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
              rules={[{ required: true, message: 'Please input position!' }]}
            >
              <Input type={'number'} placeholder={`position`} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Item Type'
              name='item_type'
              rules={[{ required: true, message: 'Please select item type!' }]}
            >
              <Select placeholder='Please select item type'>
                <Option value={'sku'} key={'sku'}>
                  SKU
                </Option>
                <Option value={'store'} key={'store'}>
                  Store
                </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.item_type !== currentValues.item_type
              }
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
            >
              {({ getFieldValue }) =>
                getFieldValue('item_type') === 'store' && (
                  <Button
                    onClick={openSelectionWindow}
                    style={{ marginTop: '20px' }}
                    type='primary'
                    icon={<SelectOutlined />}
                  >
                    Select Store
                  </Button>
                )
              }
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.item_type !== currentValues.item_type
              }
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
            >
              {({ getFieldValue }) =>
                getFieldValue('item_type') == 'sku' && (
                  <Button
                    onClick={openSelectionWindow}
                    style={{ marginTop: '20px' }}
                    type='primary'
                    icon={<SelectOutlined />}
                  >
                    Select Store Card
                  </Button>
                )
              }
            </Form.Item>
          </Col>
          <Col span={6}></Col>
          <Col span={18} style={{ height: "300px", overflow: "scroll" }}>
            {sortedData?.map(item => (
              <Col key={item?.id} span={24}>
                {React.cloneElement(componentRender, { data: item })}
              </Col>
            ))}
          </Col>
        </Row>

        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ paddingTop: '30px' }}
        >
          {isEditJourney ? (
            <Button type='primary' onClick={formSubmithandler}>
              Update Item
            </Button>
          ) : (
            <Button type='primary' onClick={formSubmithandler}>
              Create Item
            </Button>
          )}
        </Form.Item>
      </Form>
    </Drawer>
  )
}
export default CategoryCreation
