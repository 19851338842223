import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import styled from 'styled-components'
import { Card, Space, Tabs } from 'antd'
import ManageAstrologerSections from './screens/ManageAstrologerSections'
import AstrologerPages from './screens/AstrologerPages';
import { useAppContext } from '@/components/AppContext';
import { A4B_APPS } from '@/utils/constants';

interface AstrologerPageSectionProps {
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  .ant-card-body {
    padding-top:10px;
    flex-grow: 1;
  }
`
const AstrologerPageSection: React.FC<AstrologerPageSectionProps> = () => {
    const { app } = useAppContext()
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<string>('sections')
    const isSrimandir = app === A4B_APPS.SRIMANDIR

    const getPageBreadcrumbs = () => [
        {
            path: isSrimandir ? '/monetisation/astrologer/astrologer-list' : '/astro/astrologer/astrologer-list',
            breadcrumbName: 'Astrologer List',
        },
        {
            path: isSrimandir ? '/monetisation/astrologer/manage-page-section' : '/astro/astrologer/manage-page-section',
            breadcrumbName: 'Manage Page Section',
        },
    ]

    useEffect(() => {
        const currentTab = searchParams.get("current_tab") || "sections";
        setActiveTab(currentTab)
    }, [activeTab, searchParams])

    const onTabChange = (activeTab: string) => {
        setActiveTab(activeTab)
        setSearchParams({ current_tab: activeTab })
    }

    return (
        <div style={{ width: "100%", padding: '0 30px' }}>
            <AdminBreadcrumb routes={getPageBreadcrumbs()} />
            <Container style={{ width: '100%' }}>
                <Space direction={'vertical'} style={{ width: '100%' }}></Space>
                <Tabs centered activeKey={activeTab} onChange={onTabChange}>
                    <Tabs.TabPane tab="Manage Sections" key="sections">
                        <ManageAstrologerSections currentTab={activeTab} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Manage Pages" key="pages">
                        <AstrologerPages />
                    </Tabs.TabPane>
                </Tabs>
            </Container>
        </div>
    )
}

export default withDefaultLayout(AstrologerPageSection)
