import { Rashifal } from "@a4b/api/src/modules/Content/Rashifal/types";
import { NEW_CDN_HOST, getLanguageNameFromValue } from "@/utils/constants";
import { Button, Switch, TableColumnType, TablePaginationConfig, TableProps, Tag } from "antd";
import moment from "moment";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { openNotification } from "@/utils";
import { useAppContext } from "@/components/AppContext";
import { Shareapp } from "@a4b/api/src/modules/ShareText/types";

export default function useTableConfig<T>(
  dataSource: T[],
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
  triggerEditShareApp: (shareApp: Shareapp) => void
): TableProps<Rashifal.RashifalItem[]> {

  const { networkInstance } = useAppContext();

  const columns: TableColumnType<Shareapp>[] = [
    {
      title: 'S No',
      dataIndex: 'id',
      render(value, record, index) {
        return index + 1
      },
    },
    {
      title: 'Icon',
      dataIndex: 'icon_path',
      render(value, record, index) {
        return <img src={NEW_CDN_HOST + value} height={50} width={50}></img>
      },
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
    },
    {
      title: 'Package type',
      dataIndex: 'package_type'
    },
    {
      title: 'Package',
      dataIndex: 'package',
    },
    {
      title: 'Action package',
      dataIndex: 'action_package'
    },
    {
      title: 'landing',
      dataIndex: 'landing'
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render(value, record, index) {
        return value ? <Tag color="red">Active</Tag> : <Tag color="green">Inactive</Tag>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button type="link" onClick={() => triggerEditShareApp(record)}>Edit</Button>
        </div>
      },
    }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  //@ts-ignore
  return { columns, dataSource, pagination, onChange: tableOnChange }
}