import { useMemo } from 'react';
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'antd/dist/antd.css';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { Button, Form, Space, Table, Tag } from 'antd';
import { SocialScreenContainer } from '@a4b/ui/src/admin';
import { openNotification } from '@/utils';
import { Shrine } from '@/../../../packages/a4b-api/src/modules/Temple/types';
import GodCard from './GodCard';


const defaultPagination = {
  current: 1,
  pageSize: 50,
  total: -1
}

export interface TempleGodAssociatedItemsProps {
  god: Shrine.God
}

const TempleGodAssociatedItems = ({ god }: TempleGodAssociatedItemsProps) => {
  const navigate = useNavigate();
  const { networkInstance, userProfile } = useAppContext();
  const [associateItemId, setAssociateItemId] = useState<undefined | string>();
  const [godItems, setGodItems] = useState<Shrine.Item[]>([])
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
  const [form] = Form.useForm();
  const godId = god.id;

  const loadGodItems = useCallback(async () => {
    if (godId === undefined) return
    try {
      const res = await networkInstance.clientWithHeaders.shrineApi.getAssociatedItemsOfGod(godId);
      setGodItems(res.data.data.items);
      if (pagination.total === -1) {
        setPagination({ ...pagination, total: res.data.data.count })
      }
    } catch (error) {

    }
  }, [godId, networkInstance.clientWithHeaders.shrineApi, pagination])

  useEffect(() => {
    loadGodItems()
  }, [loadGodItems])

  const deleteGodItemAssciation = useCallback(async (itemId: string, godAvatarCode?: string) => {
    if (godId === undefined) return
    if (godAvatarCode === undefined) return
    try {
      await networkInstance.clientWithHeaders.shrineApi.deleteGodItemAssocaition(godId, itemId, godAvatarCode)
      openNotification('success', { message: `god id ${godId} associated sucessfully with item id ${associateItemId}` })
      loadGodItems();
    } catch (error: any) {
      openNotification('error', { message: `god id ${godId} associated failed with item id ${associateItemId}` })
    }
  }, [associateItemId, godId, loadGodItems, networkInstance.clientWithHeaders.shrineApi])

  const columns = useMemo(() => [
    {
      title: 'Thumbnail',
      dataIndex: 'item_thumbnail',
      render: (thumbnail: { type: string, url: string }) => <img src={thumbnail.url} height={50} width={50} alt={'god image'} />
    },
    {
      title: 'Attechments',
      dataIndex: 'item_attachment',
      render: (attachment: { type: string, url: string }) => <img src={attachment.url} height={50} width={50} alt={'god image'} />
    },
    {
      title: 'Code',
      dataIndex: 'item_code',
    },
    {
      title: 'Type',
      dataIndex: 'item_type',

    },
    {
      title: 'God avatar code',
      dataIndex: 'god_avatar_code',
    },
    {
      title: 'Action',
      dataIndex: 'item_id',
      render: (id: string, data: Shrine.Item) => <>

        {<Button type="link" onClick={() => {
          //@ts-ignore
          deleteGodItemAssciation(id, data.god_avatar_code)
        }} > Delete assocaition </Button>}
      </>
    },
  ], [deleteGodItemAssciation]);

  return (
    <Space direction='vertical' size='middle' style={{ width: '100%' }}>
      <GodCard god={god}></GodCard>
      <Table {...{ columns, dataSource: godItems }}></Table>
    </Space>
    // <SocialScreenContainer
    //   title={'God item association'}
    //   tableProps={{ columns, dataSource: godItems }}
    //   filtersUI={<GodCard god={god}></GodCard>}
    // >
    // </SocialScreenContainer>
  )
}

export default TempleGodAssociatedItems;
