//context
import { useForm } from 'antd/lib/form/Form'
import React, { useEffect } from 'react'

import { Button, Col, DatePicker, Drawer, Form, Input, InputNumber, Select, Switch } from 'antd'
import { FormInstance } from 'antd/es/form/Form'

interface Props {
    showItemCreation: boolean
    isEditJourney: boolean
    createItemCallback?: (status: boolean) => void
    closeItemCreation: () => void
    initialValues: any
    form: FormInstance<any>
    formSubmithandler: (values: any) => void
}

const { Option } = Select;

const CreateCampaigns: React.FC<Props> = ({
    showItemCreation,
    closeItemCreation,
    createItemCallback,
    isEditJourney,
    initialValues,
    form,
    formSubmithandler
}) => {
    const [createItemForm] = useForm()

    useEffect(() => {
        if (isEditJourney) {
            form.setFieldsValue(initialValues);
        } else {
            form.resetFields()
        }
    }, [createItemForm, form, initialValues, isEditJourney])

    return (
        <>
            <Drawer
                title={`${isEditJourney ? "Update" : "Create New"}  Campaign`}
                placement='right'
                onClose={closeItemCreation}
                visible={showItemCreation}
                destroyOnClose={true}
                size='default'
            >
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={formSubmithandler}
                    initialValues={initialValues}
                >
                    <Col >
                        <Form.Item
                            label='Name'
                            name='name'
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                            rules={[{ required: true, message: 'Enter name' }]}
                        >
                            <Input placeholder='Enter Name' />
                        </Form.Item>
                        <Form.Item
                            label='Sub title'
                            name='sub_title'
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                            rules={[{ required: false }]}
                        >
                            <Input placeholder='Enter Sub title' />
                        </Form.Item>

                        <Form.Item
                            label='Start Date'
                            name='start_date'
                            valuePropName='value'

                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Pick Date',
                                },
                            ]}
                        >
                            <DatePicker />
                        </Form.Item>

                        <Form.Item
                            label='End Date'
                            name='end_date'
                            valuePropName='value'

                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Pick Date',
                                },
                            ]}
                        >
                            <DatePicker />
                        </Form.Item>

                        <Form.Item
                            name="discount_type"
                            label="Discount"
                            rules={[{ required: true, message: 'Enter discount type' }]}
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 22 }}
                        >
                            <Select
                                placeholder="Select a option"
                                allowClear
                            >
                                <Option value="percentage">Percentage</Option>
                                <Option value="absolute">Absolute</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="campaign_type"
                            label="Campaigh Type"
                            rules={[{ required: true, message: 'Select compaign type' }]}
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 22 }}
                        >
                            <Select
                                placeholder="Select campaign type"
                                allowClear
                            >
                                <Option value="generic">Generic</Option>
                                <Option value="custom">Custom</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label='Value'
                            name='value'
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 22 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter value',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const discountType = getFieldValue('discount_type')
                                        if (discountType === 'percentage' && value > 100) {
                                            return Promise.reject('Value should not be greater than 100 for percentage discount')
                                        }
                                        return Promise.resolve()
                                    },
                                }),
                            ]}
                        >
                            <InputNumber placeholder='Enter value' />
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues?.discount_type !== currentValues?.discount_type
                            }
                        >
                            {({ getFieldValue }) => (
                                getFieldValue("discount_type") === "percentage" && <Form.Item
                                    label='Max Amount'
                                    name='max_amount'
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 22 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter max amount',
                                        },
                                    ]}
                                >
                                    <InputNumber placeholder='Enter max amount' />
                                </Form.Item>)}
                        </Form.Item>


                        <Form.Item label="Active status" name="active" valuePropName="checked">
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ paddingTop: '30px' }}
                        >
                            {isEditJourney ? (
                                <Button type='primary' htmlType="submit">
                                    Update Campaign
                                </Button>
                            ) : (
                                <Button type='primary' htmlType="submit">
                                    Create Campaign
                                </Button>
                            )}
                        </Form.Item>

                    </Col>
                </Form>
            </Drawer >
        </>
    )
}

export default CreateCampaigns
