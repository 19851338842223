import { Rashifal } from "@a4b/api/src/modules/Content/Rashifal/types";
import { getLanguageNameFromValue } from "@/utils/constants";
import { Button, Switch, TableColumnType, TablePaginationConfig, TableProps } from "antd";
import moment from "moment";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { openNotification } from "@/utils";
import { useAppContext } from "@/components/AppContext";

export default function useTableConfig<T>(
  dataSource: T[],
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
): TableProps<Rashifal.RashifalItem[]> {

  const { networkInstance } = useAppContext();

  const columns: TableColumnType<Rashifal.RashifalItem>[] = [
    {
      title: 'S No',
      dataIndex: 'id',
      render(value, record, index) {
        return index + 1
      },
    },
    {
      title: 'Title 1',
      dataIndex: 'title',
    },
    {
      title: 'Title 2',
      dataIndex: 'title2',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Switch style={{ width: '90px' }} checked={record.active} unCheckedChildren="Activate" />
          <Button type="link" >Edit</Button>
          <Button type="link" >Delete</Button>
        </div>
      },
    }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  //@ts-ignore
  return { columns, dataSource, pagination, onChange: tableOnChange }
}