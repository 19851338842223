/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import styled from 'styled-components'
import { PlusSquareOutlined } from '@ant-design/icons'
import { Button, Card, Col, Input, Space, Table } from 'antd'
import { HubListItem, Users } from '@a4b/api/src/modules/Monetisation/Offerings/types'

interface Props {
    onCreateNew: () => void
    triggerEdit: (user: Users) => void
    triggerDelete: (user: Users) => void
    dataSource: any[]
    hubList: any
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const ManageUsersPage: React.FC<Props> = ({
    onCreateNew,
    triggerEdit,
    triggerDelete,
    dataSource,
    hubList
}) => {
    const [searchUser, setSearchUser] = useState<string>('')

    const filteredUsers = dataSource?.filter(data =>
        data?.name?.toLowerCase()?.includes(searchUser?.toLowerCase())
    )

    const getHubNameById = (id: string): string => {
        const hub = hubList?.hubs?.find((h: HubListItem) => h?.id === id);
        return hub ? hub?.name : id;
    };

    const filterByNameInput = () => {
        return <Input
            placeholder="Search by name"
            value={searchUser}
            onChange={(e: any) => {
                setSearchUser(e?.target?.value);
            }}
            size="small"
            style={{ width: '150px', marginTop:'10px',height:'30px'  }}
        />
    };

    const userType: any = {
        super_admin: 'Super Admin',
        admin: 'Admin',
        hub_manager: 'Hub Manager',
        hub_associate: 'Hub Associate'
    };

    const dataColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            ellipsis: true,
            render: (text: any,) => {
                return <span>{text}</span>
            },
        },
        {
            title: <div> Name <br></br> {filterByNameInput()}</div>,
            dataIndex: 'name',
            key: 'name',
            width: '12%',
            ellipsis: true,
            render: (text: any,) => {
                return <span>{text}</span>
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '16%',
            ellipsis: true,
            render: (text: any,) => {
                return <span>{text}</span>
            },
        },
        {
            title: 'User Type',
            dataIndex: 'user_type',
            key: 'user_type',
            width: '9%',
            render: (text: any) => {
                return <span>{userType[text]}</span>;
            },
        },
        {
            title: 'Contact number',
            dataIndex: 'contact_number',
            key: 'contact_number',
            width: '12%',
        },
        {
            title: 'Hubs',
            dataIndex: 'hub_ids',
            key: 'hub_ids',
            width: '15%',
            render: (hubs: string[]) => (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {hubs?.map((hubId, index) => (
                        <div key={index} style={{ padding: '1px 6px', border: '1px solid #d9d9d9', borderRadius: '4px', backgroundColor: '#f5f5f5' }}>
                            {getHubNameById(hubId)}
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: 'Edit',
            key: 'edit',
            width: '9%',
            render: (text: any, record: any,) => (
                <Col span={12}>
                    <Button type='link' onClick={() => triggerEdit(record)}>
                        Edit
                    </Button>
                </Col>
            ),
        },
        {
            title: 'Delete',
            key: 'delete',
            width: '9%',
            render: (text: any, record: any) => (
                <Col span={12}>
                    <Button type='link' onClick={() => triggerDelete(record)}>
                        Delete
                    </Button>
                </Col>
            )
        }
    ]
    return (
        <Container title='Manage User' style={{ width: '100%' }}>
            <Space direction={'vertical'} style={{ width: '100%' }}></Space>
            <BodyContainer>
                <Button type='text' onClick={onCreateNew}>
                    <PlusSquareOutlined /> Add New User
                </Button>
                <Table
                    columns={dataColumns}
                    dataSource={filteredUsers}
                    pagination={{ defaultPageSize: 8 }}
                />
            </BodyContainer>
        </Container>
    )
}

export default ManageUsersPage
