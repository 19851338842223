export const PRIMARY = '#F18912'

const BLACK = {
  100: '#000000',
  90: '#242424',
  80: '#333333',
  70: '#4D4C4C',
  60: '#707070',
  50: '#969696',
  40: '#B2B2B2',
  30: '#CBCACA',
  20: '#E8E8E8',
  10: '#F8F8F8',
}
const RED = {
  20: '#FFC9C5',
  30: '#FFF6F5',
  50: '#FED5D5',
}
const WHITE = {
  100: '#FFFFFF',
  50: '#808080',
  60: '#999999',
  25: '#404040',
}

const BROWN = {
  100: '#6A3A0C',
  90: '#4D3D3D',
  10: '#B49C85',
  20: '#776660',
}

const GREEN = {
  100: '#00BD68',
  50: '#D2EBC4',
  30: '#F6FBF4',
  40: '#36B37E',
}
const GREY = {
  20: '#E6E4EB',
  10: '#F9F8FD',
  60: '#DBDBDB',
  50: '#484848',
  40: '#C8C8C8',
  30: '#D4C9C2',
  70: '#838693',
}
const YELLOW = {
  20: '#F2EDBF',
  30: '#FAF8F5',
  10: '#FFFCF9',
  50: '#F5F1CE',
}

const BLUE = {
  100: '#1573DF',
  90: '#1D293F',
}

const ORANGE = {
  100: '#F18912',
  90: '#FC8C2C',
  60: '#fc9740',
  40: '#fdba7f',
}
const PINK = {
  100: '#FF5151',
  50: '#FFE5E6',
}
function getSecondaryColors() {
  return {
    'BLUE-100': BLUE[100],
    'GREEN-100': GREEN[100],
    'GREEN-50': GREEN[50],
    'GREEN-30': GREEN[30],
    'GREEN-40': GREEN[40],
    'RED-100': '#FF5151',
    'BROWN-100': BROWN[100],
    'BROWN-10': BROWN[10],
    'BlUE-GRAY': '#455A64',
    'BLUE-GREEN': '#0F7A8A',
  }
}

function getBackgroundColors() {
  return {
    'ORANGE-10': '#FEF5EC',
    'ORANGE-30': '#FFE9CA',
    'ORANGE-20': '#FFF7E7',
    'ORANGE-40': '#FCCFB1',
    'ORANGE-80': '#FC8C2C',
    'WHITE-100': WHITE[100],
    GRAY: '#F9F8F6',
    'GRAY-10': '#7C8087',
  }
}

export const COLORS = {
  PRIMARY,
  SECONDARY: getSecondaryColors(),
  BACKGROUND: getBackgroundColors(),
  WHITE,
  BLACK,
  BROWN,
  GREY,
  BLUE,
  ORANGE,
  PINK,
  GRADIENT: {
    GREEN: ['#01D473', '#01C06A'],
    ORANGE: ['#F18912', '#E7720D'],
    ORANGE2: ['#F18912', '#FFEE00'],
  },
  YELLOW,
  RED,
}
