import { Rashifal } from "@a4b/api/src/modules/Content/Rashifal/types";
import { NEW_CDN_HOST, getLanguageNameFromValue } from "@/utils/constants";
import { Button, Switch, TableColumnType, TablePaginationConfig, TableProps, Tag } from "antd";
import moment from "moment";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { openNotification } from "@/utils";
import { useAppContext } from "@/components/AppContext";
import { AudioThumbnail } from "@a4b/api";
import { CopyOutlined } from "@ant-design/icons";
import styled from "styled-components";

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
`

const StyledCopyOutlined = styled(CopyOutlined)`
  cursor: pointer;
  padding: 10px
`

const StyledImg = styled.img`
  width: 80px;
  height: 80px;
  padding: 5px;
  border: 1px solid #ccc;
`

export default function useTableConfig<T>(
  dataSource: T[],
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
): TableProps<AudioThumbnail[]> {

  const { networkInstance } = useAppContext();

  const columns: TableColumnType<AudioThumbnail>[] = [
    {
      title: 'S No',
      dataIndex: 'id',
      render(value, record, index) {
        return index + 1
      },
    },
    {
      title: 'Image ',
      dataIndex: 'image_path',
      width: 300,
      render(value, record, index) {
        return <ImageContainer>
          <StyledImg src={NEW_CDN_HOST + value} />
        </ImageContainer>
      }
    },
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Image path',
      dataIndex: 'image_path',
      width: 300,
      render(value, record, index) {
        return <ImageContainer>
          <Tag>{value} &nbsp;<StyledCopyOutlined onClick={() => navigator.clipboard.writeText(value)} /></Tag>
        </ImageContainer>
      }
    },
    // {
    //   title: 'Actions',
    //   dataIndex: 'id',
    //   render(value, record, index) {
    //     return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    //       <Button type="link" >Edit</Button>
    //       <Button type="link" >Delete</Button>
    //     </div>
    //   },
    // }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  //@ts-ignore
  return { columns, dataSource, pagination, onChange: tableOnChange }
}