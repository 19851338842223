import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../apiClient'
import { Response } from '../../types'
import {
  ApiResponse,
  EmailOTPVerifyResponse,
  GenerateOtpRequest,
  GuestUserResponse,
  SocialVerifyResponse,
  UserData,
  UserDataResponse,
  VerifyEmailOtpData,
  VerifyOtpRequest,
  VerifyOtpResponse,
  VerifySocialLogin,
} from './types'

type Header = {
  [key: string]: string
}
class AuthApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getApiInstance = () => this.api

  guestSignUp = async () =>
    this.api.post<GuestUserResponse>('/atom/external/v1/auth/anonymous')

  generateOTP = (payload: GenerateOtpRequest) =>
    this.api.post<null>('/atom/external/v1/auth/generate-otp', payload)

  verifyOtp = async (payload: VerifyOtpRequest) =>
    this.api.post<VerifyOtpResponse>(
      '/atom/external/v1/auth/verify-otp',
      payload,
    )

  socialLogin = async (payload: Partial<VerifySocialLogin>) => {
    return this.api.post<SocialVerifyResponse>(
      '/atom/external/v1/auth/social',
      payload,
    )
  }
  emailOTPLogin = async (payload: Partial<VerifySocialLogin>) => {
    return this.api.post<EmailOTPVerifyResponse>(
      '/gw1/atom/external/v1/auth/email-otp',
      payload,
    )
  }

  logout = async (accessToken: string) =>
    this.api.post<GuestUserResponse>('/atom/external/v1/auth/logout', null, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

  refreshToken = async (payload: { refresh_token: string }) =>
    this.api.post<GuestUserResponse>(
      '/atom/external/v1/auth/refresh-token',
      payload,
    )

  getUserDetails = async (userData: UserData) =>
    this.api.get<UserDataResponse>(
      `/atom/external/v1/users/${userData?.userId}`,
    )

  updateUserProfile = async (userData: UserData, payload) =>
    this.api.patch(`/atom/external/v1/users/${userData?.userId}`, payload)

  getUserDetailsV2 = async (userData: UserData) =>
    this.api.get<ApiResponse>(`/atom/external/v2/users/${userData?.userId}`)

  sendEmailOtp = async (payload: { email: string }) =>
    this.api.post<null>(
      '/gw1/atom/external/v1/auth/generate-email-otp',
      payload,
    )
  verifyEmailOtp = async (payload: { email: string; otp: string }) =>
    this.api.post<Response<VerifyEmailOtpData>>(
      '/gw1/atom/external/v1/auth/verify-email-otp',
      payload,
    )
}

export default AuthApi
