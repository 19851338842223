import { Rashifal } from "@a4b/api/src/modules/Content/Rashifal/types";
import useAntdPagination from "@/hooks/useAntdPagination";
import { getLanguageNameFromValue } from "@/utils/constants";
import { Button, Switch, TableColumnType, TablePaginationConfig, TableProps } from "antd";
import moment from "moment";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { openNotification } from "@/utils";
import { useAppContext } from "@/components/AppContext";

export default function useTableConfig(
  dataSource: Rashifal.RashifalItem[],
  setDrawer: (Drawer: DRAWER_TYPE) => void,
  editRashifal: (rashifal: Rashifal.RashifalItem) => void,
  loadData: () => Promise<void>,
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>
): TableProps<Rashifal.RashifalItem[]> {

  const { networkInstance } = useAppContext();

  const toggleRashifal = async (record: Rashifal.RashifalItem) => {
    const payload: Rashifal.EditRashifalPayload = {
      active: !record.active,
      rashi: record.rashi,
      rashifal_data: record.rashifal_data,
      date: moment(record.date).format("YYYY-MM-DD"),
      language: record.language
    }
    try {
      await networkInstance.clientWithHeaders.contentApi.rashifal.editRashifal(record.id, payload);
      loadData();
      openNotification('success', { message: 'Rashifald updated successfully!' })
    } catch (error: any) {
      openNotification('error', { message: 'Rashifals updation failed!', description: error.toString(), })
    }
  };

  const deleteRashifal = async (record: Rashifal.RashifalItem) => {
    try {
      await networkInstance.clientWithHeaders.contentApi.rashifal.deleteRashifal(record.id);
      loadData();
      openNotification('success', { message: 'Rashifal deleted successfully!' })
    } catch (error: any) {
      openNotification('error', { message: 'Rashifals deleted failed!', description: error.toString(), })
    }
  };

  const columns: TableColumnType<Rashifal.RashifalItem>[] = [
    {
      title: 'S No',
      dataIndex: 'id',
      render(value, record, index) {
        return index + 1
      },
    },
    {
      title: 'Rashi',
      dataIndex: 'rashi',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render(value, record, index) {
        return moment(value).format("MMMM Do YYYY");
      },
    },
    {
      title: 'Language',
      dataIndex: 'language',
      render(value, record, index) {
        return getLanguageNameFromValue(value)
      },
    },
    {
      title: 'Rashifal data',
      dataIndex: 'rashifal_data'
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Switch style={{ width: '80px' }} checked={record.active} onClick={() => { toggleRashifal(record); }} checkedChildren={'Deactivate'} unCheckedChildren="Activate" />
          <Button type="link" onClick={() => { editRashifal(record); setDrawer(DRAWER_TYPE.EDIT_RASHI) }}>Edit</Button>
          <Button type="link" onClick={() => { deleteRashifal(record); }}>Delete</Button>
        </div>
      },
    }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  //@ts-ignore
  return { columns, dataSource, pagination, onChange: tableOnChange }
}