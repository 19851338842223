import { Descriptions } from "antd";

import { ATTACHMENT } from "@/utils/enum";
import LazyImageRender from "../LazyImageRender";

interface Props {
  record: any;
}
const MenuLoginDataTable = ({ record }: Props) => {
  return (
    <div className="padding-left">
      <Descriptions size="small" title="Menu Login" bordered>
        {record?.content?.items?.map((item: any) => {
          return (
            <>
              <Descriptions.Item label="Detault Title">
                {item.default_title}
              </Descriptions.Item>
              <Descriptions.Item label="Main CTA Text">
                {item.default_subtitle}
              </Descriptions.Item>
              <Descriptions.Item label="Item Count">
                return{" "}
                <LazyImageRender
                  id={item.default_image_attachment_id}
                  attachmentType={ATTACHMENT.IMAGE}
                />
              </Descriptions.Item>
            </>
          );
        })}
      </Descriptions>
    </div>
  );
};
export default MenuLoginDataTable;
