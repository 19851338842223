import React from "react";
import { Button, Form, Input, InputNumber } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { ONLY_ALPHA_NUMARIC_RULE, REQUIRED_RULE } from "../../../../../utils/form.validation";
import styled from "styled-components";
import NudgeForm from './NudgeForm';

const HiddenFormItem = styled(Form.Item)`
  display: none;
`;


interface CreateNudgeProps {
  form: FormInstance<any>
  formData?: any,
  isTouched?: boolean
}

const CreateNudge = ({ form, formData, isTouched }: CreateNudgeProps) => {
  return (<div
  >
    {
      formData &&
      <HiddenFormItem name="id" label={"Id"}>
        <Input></Input>
      </HiddenFormItem>
    }
    <Form.Item name="code" label={"Code"} rules={[REQUIRED_RULE[0], ONLY_ALPHA_NUMARIC_RULE]}>
      <Input disabled={!!formData}></Input>
    </Form.Item>
    <NudgeForm form={form} type="affirmative"></NudgeForm>
    <Form.Item>
      <Button type="primary" htmlType="submit">Submit</Button>
    </Form.Item>
  </div>)
}

export default CreateNudge;