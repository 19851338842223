import React, { useState } from 'react'
import { Form, Upload } from 'antd'
import { v4 as uuidv4 } from 'uuid';
import { PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';

//types
import { UploadFile } from 'antd/lib/upload/interface';
import { NEW_CDN_HOST, UPLOAD_CONTENT_URL } from '../../../../../utils/constants';
import { REQUIRED_RULE } from '../../../../../utils/form.validation';
import { FormInstance } from 'antd/es/form/Form';
import { AttachmentServiceResponse } from '../../../../social/types';

const UploadButton = () => (
    <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
    </div>
)

interface Props {
    imagePath?: string,
    attribute: string,
    form: FormInstance<any>
}

const UploadImage: React.FC<Props> = (props) => {
    const { imagePath, attribute, form } = props;
    const [file, setFile] = useState<undefined | UploadFile<AttachmentServiceResponse>>(imagePath ? {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: NEW_CDN_HOST + '/' + imagePath,
    } : undefined)

    const handleUploadChange = (info: UploadChangeParam<UploadFile<AttachmentServiceResponse>>) => {
        if (!info.file || !info.file.response) {
            return
        }
        setFile(info.file)
        form.setFieldValue(["nudge", "attributes", attribute], info.file.response.data.content.s3_path);
    }

    const getDataForUpload = () => {
        const ref_content_code = `cdn_upload_${uuidv4()}`
        return {
            ref_content_code,
            media_type: 'IMAGE',
        }
    }

    return (
        <Form.Item
            name={["nudge", "attributes", attribute]}
            rules={REQUIRED_RULE}
            label={attribute}
            valuePropName="list"
            initialValue={imagePath}
        >
            <Upload.Dragger
                maxCount={1}
                // disabled={file ? true : false}
                fileList={file ? [file] : undefined}
                multiple={false}
                action={UPLOAD_CONTENT_URL}
                accept={'image/*'}
                name='input_file'
                listType='picture'
                onChange={handleUploadChange}
                onRemove={() => setFile(undefined)}
                data={() => getDataForUpload()}
            >
                <UploadButton />
            </Upload.Dragger>
        </Form.Item>
    )
}

export default UploadImage;