import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import {
    Table,
    Space,
    Card,
    Button,
    Tag,
    Drawer,
    Col,
    Row,
    Typography,
    Tooltip,
    Input,
    Select,
    Badge,
    Form,
    InputNumber,
    Modal,
} from 'antd'
import styled from 'styled-components'
import { Product } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { PlusSquareOutlined, SortAscendingOutlined } from '@ant-design/icons'
import OfferingItem from '@a4b/ui/src/modules/monetisation/molecules/OfferingItem'
import { TablePaginationConfig } from 'antd/es/table'

type AddedItems = { added: Product[] }

interface Props {
    AddedItems: AddedItems
    triggerEdit: (record: any) => void
    productList: Product[]
    createItem: () => void
    added: (item: Product) => void
    currentStep: number
    nextHandler: () => void
    previousHandler: () => void
    handleTableChange: (paginationParams: TablePaginationConfig) => void
    paginationParams: TablePaginationConfig
    loading: boolean
    sortBy?: ('price' | 'position' | 'created_at')
    updateSortBy: (sortBy?: ('price' | 'position' | 'created_at')) => void
    archiveInventory: (inventoryId: string) => void
    enableEditPosition: boolean
    enableUpdate: (status: boolean) => void
    updatePosition: () => void
    formInstance: any
    initialValues: any
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`
const ListAllItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - 220px);
  overflow: scroll;
  background-color: #f5f5f5;
`

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 10px;
`

const { Title } = Typography

const AssignOfferingStepPage: React.FC<Props> = ({
    AddedItems,
    triggerEdit,
    productList,
    createItem,
    added,
    currentStep,
    nextHandler,
    previousHandler,
    paginationParams,
    handleTableChange,
    loading,
    sortBy,
    updateSortBy,
    archiveInventory,
    enableEditPosition,
    enableUpdate,
    updatePosition,
    formInstance,
    initialValues,
}) => {
    const navigate = useNavigate()
    const dataSource = AddedItems?.added

    const [filterData, setFilterData] = useState(dataSource);
    const [title, setTitle] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [showItemsPanel, setShowItemsPannel] = useState(false);

    const [searchTitle, setSearchTitle] = useState<string>('')

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTitle(event?.target?.value);
    }

    const filteredProducts = productList?.filter(item =>
        item?.title?.toLowerCase()?.includes(searchTitle?.toLowerCase())
    )

    useEffect(() => {
        setFilterData(dataSource);
        setTitle('')
        setDisplayName('')
    }, [dataSource])

    const filterByTitleInput = () => {
        return <Input
            placeholder="Search Title"
            size='small'
            value={title}
            onChange={e => {
                const currValue = e.target.value;
                setTitle(currValue);
            }}
        />
    };

    const filterByDisplayNameInput = () => {
        return <Input
            size='small'
            placeholder="Search Display name"
            value={displayName}
            onChange={e => {
                const currValue = e.target.value;
                setDisplayName(currValue);
            }}
        />
    };

    const showDeleteConfirm = (id: string, archiveInventory: (inventoryId: string) => void) => {
        Modal.confirm({
          title: 'Are you sure you want to delete this inventory?',
          content: 'This action cannot be undone.',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            archiveInventory(id);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };

    useEffect(() => {
        const filterByTitle = dataSource
            .filter((entry: any) => {
                return entry.title.toLowerCase().includes(title.toLowerCase())
            })
            .filter((entry: any) => {
                if (displayName === "") {
                    return true;
                }
                return entry.display_name?.toLowerCase().includes(displayName.toLowerCase())
            });
        if (title.trim() === "" && displayName.trim() === "") {
            setFilterData(dataSource);
            return
        }
        setFilterData(filterByTitle);
    }, [title, displayName, dataSource])

    const columns = [
        {
            title: 'Inventory Id',
            dataIndex: 'id',
            key: 'inventory_id',
            width: '15%',
            ellipsis: true,
            render: (store_id: string) => (
                <Tooltip placement="topLeft" title={store_id}>
                    {store_id}
                </Tooltip>
            ),
        },
        {
            title: <div> Title <br></br> {filterByTitleInput()}</div>,
            dataIndex: 'title',
            key: 'title',
            width: '15%',
            ellipsis: true,
            render: (title: string) => (
                <Tooltip placement="topLeft" title={title}>
                    {title}
                </Tooltip>
            ),
        },
        {
            title: 'Batch',
            dataIndex: 'batch_size',
            key: 'batch_size',
            width: '7%',
        },
        {
            title: 'Quantities ',
            dataIndex: 'quantities',
            key: 'quantities',
            width: '10%',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: '7%',
            render: (text: number) => {
                if (text) {
                    return (
                        <Tag color='green' key={text} style={{ width: '100%', textAlign: 'center' }}>
                            {text.toString()}
                        </Tag>
                    )
                }
                return "-"
            }
        },
        {
            title: 'Position',
            key: 'position',
            width: '10%',
            render: (text: string, record: any) => {
                return enableEditPosition ? (
                    <Form.Item
                        noStyle={true}
                        name={record.id}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 12 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input position!',
                            },
                        ]}
                    >
                        <InputNumber placeholder='Enter position' />
                    </Form.Item>
                ) : (
                    <span> {`${record.position}`}</span>
                )
            },
        },
        {
            title: <div>Display name <br></br> {filterByDisplayNameInput()}</div>,
            dataIndex: 'display_name',
            key: 'display_name',
            ellipsis: true,
            width: '12%',
            render: (display_name: string) => {
                if (display_name?.length) {
                    return <Tooltip placement="topLeft" title={display_name}>
                        {display_name}
                    </Tooltip>
                } else {
                    return <p>-</p>
                }

            },
        },
        {
            title: 'Item type',
            dataIndex: 'item_type',
            key: 'item_type',
            width: '10%',
        },
        {
            title: 'Campaign Ids',
            dataIndex: 'campaigns',
            key: 'campaigns',
            width: '15%',
            render: (text: string, record: any) => {
                if (record?.campaignList?.length) {
                    return <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '8px' }}>{
                        record?.campaignList?.map((campaign: string) => {
                            return <Tag color="blue" key={campaign}>
                                {campaign}
                            </Tag>
                        })
                    }</div>

                } else {
                    return <p>-</p>
                }
            }
        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            width: '7%',
            render: (text: string, record: any) => {
                return <Badge.Ribbon text={record.active ? 'Active' : 'InActive'} color={record?.active ? 'green' : 'red'} style={{ marginTop: '-20px' }}></Badge.Ribbon>
            }
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: '6%',
            render: (text: string, record: any) => {
                return <Button onClick={() => { triggerEdit(record) }} type='link' color='cyan' style={{ cursor: 'pointer' }}>Edit</Button>
            }
        },
        {
            title: 'Delete',
            dataIndex: 'archived',
            key: 'archived',
            width: '6%',
            render: (text: string, record: any) => {
                return <Button onClick={() => { showDeleteConfirm(record.id, archiveInventory) }} type='link' color='cyan' style={{ cursor: 'pointer' }}>Delete</Button>
            }
        }
    ];

    return (
        <div>
            <Row justify='space-between'>
                <Col style={{ paddingBottom: '10px' }}>
                    <Button onClick={() => setShowItemsPannel(!showItemsPanel)} type='dashed' size='small' style={{ borderColor: 'black' }}>
                        Show Items Pannel
                    </Button>
                </Col>
                <Col style={{ paddingLeft: '10px' }}>
                    {enableEditPosition ? (
                        <Button type='default' onClick={() => enableUpdate(false)}>
                            Cancel Edit Position
                        </Button>
                    ) : (
                        <Button type='primary' onClick={() => enableUpdate(true)}>
                            Edit Position
                        </Button>
                    )}
                </Col>
                {enableEditPosition && <Col style={{ paddingLeft: '10px' }}>
                    <Button type='primary' onClick={updatePosition}>
                        Update Position
                    </Button>
                </Col>}
                <Col style={{ float: 'right' }}>
                    <SortAscendingOutlined /> Sort by : &nbsp;
                    <Select placeholder="Sort by price, position ..." value={sortBy} allowClear size='small' style={{ width: "200px" }} onChange={(value) => { updateSortBy(value) }}>
                        <Select.Option value="price"> Price</Select.Option>
                        <Select.Option value="position"> Position</Select.Option>
                        <Select.Option value="created_at"> Created at</Select.Option>
                    </Select>
                </Col>
            </Row>
            <Container style={{ width: '100%' }}>

                <Space direction={'vertical'} style={{ width: '100%' }}></Space>
                <Drawer
                    width={600}
                    visible={showItemsPanel}
                    placement='left'
                    closable
                    onClose={() => setShowItemsPannel(!showItemsPanel)}
                >
                    <Col span={24}>
                        <Title level={4}>All Items</Title>
                        <Row>
                            <Col span={4}>
                                <Button onClick={createItem} type='link' size='small'>
                                    <PlusSquareOutlined /> Add
                                </Button>
                            </Col>
                            <Input
                                placeholder="Search by title"
                                value={searchTitle}
                                onChange={handleSearch}
                                size="small"
                                style={{ width: '150px', marginBottom: '20px', marginLeft: '-10px' }}
                            />
                        </Row>
                        <ListAllItems>
                            {filteredProducts?.map((item: Product) => (
                                <OfferingItem formItems={item} key={item.id} added={added} />
                            ))}
                        </ListAllItems>
                    </Col>
                </Drawer>
                <BodyContainer>
                    <Form form={formInstance} initialValues={initialValues}>
                        {filterData && <Table
                            dataSource={filterData}
                            columns={columns}
                            pagination={paginationParams}
                            onChange={handleTableChange}
                            loading={loading}
                        />}
                    </Form>

                </BodyContainer>
            </Container >
            <Col span={24}>
                <LabelRightAlign>
                    <Space>
                        <Button type='primary' onClick={() => navigate("/monetisation/offerings/temples")}>
                            Cancel
                        </Button>
                        {currentStep > 0 && (
                            <Button type='primary' onClick={previousHandler}>
                                Previous
                            </Button>
                        )}
                        {currentStep !== 2 && (
                            <Button type='primary' onClick={nextHandler}>
                                Next
                            </Button>
                        )}
                    </Space>
                </LabelRightAlign>
            </Col>
        </div >
    )

}

export default AssignOfferingStepPage
