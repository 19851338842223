
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button, Modal, TablePaginationConfig } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import { Journey, NudgeConfiguration } from "./utils/types";
import { openNotification } from "../../../../utils";
import { JourneyContextProvider, NEXT_STATUS_CONFIG, NEXT_STATUS_CONFIG_KEYS, RUN_PARALLEL_ALWAYS_FILTER_TYPES } from "./utils/contants";
import { ExclamationCircleFilled } from "@ant-design/icons";

const JourneyPage = function () {
  const [viewJourney, setViewJourney] = useState<Journey>();
  const [editJourney, setEditJourney] = useState<Journey>();
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const drawerConfig = useDrawerConfig(viewJourney, editJourney, activeDrawer);
  const { drawerProps, drawerContent } = drawerConfig;
  const { networkInstance } = useAppContext();
  const [pagination, setPagination] = useState<TablePaginationConfig>({ current: 1, pageSize: 100 });
  const [nudgeConfiguration, setNudgeConfiguration] = useState<NudgeConfiguration>();
  const [eventsAndProperties, setEventsAndProperties] = useState<any>([]);
  const [commonEventProperties, setCommanEventProperties] = useState<any>({});
  const [allJourneys, setAllJourneys] = useState<any>([]);
  const [runParallelAlways, setRunParallelAlways] = useState(RUN_PARALLEL_ALWAYS_FILTER_TYPES.BOTH)

  const updateViewJourney = (journey?: Journey) => {
    setViewJourney(journey);
    setActiveDrawer(DRAWER_TYPE.VIEW_JOURNEY);
  }

  const updateEditJourney = (journey?: Journey) => {
    setEditJourney(journey);
    setActiveDrawer(DRAWER_TYPE.EDIT_JOURNEY);
  }

  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }

  const getNudgeConfiguration = useCallback(async () => {
    const { data } = await networkInstance.clientWithHeaders.inAppNudge.getNudgeConfiguration();
    setNudgeConfiguration(data.data.templates);
  }, [networkInstance]);

  const getEventsAndProperties = useCallback(async () => {
    const { data } = await networkInstance.clientWithHeaders.inAppNudge.getEventsAndProperties();
    setEventsAndProperties(data.data.events)
    setCommanEventProperties(data.data.common_properties)
  }, [networkInstance]);

  const getAllJourneys = useCallback(async () => {
    const { data } = await networkInstance.clientWithHeaders.inAppNudge.getAllJourneys();
    setAllJourneys(data.data.journeys)
  }, [networkInstance]);

  const changeJourneyState = async (journeyId: string, currentStatus: NEXT_STATUS_CONFIG_KEYS) => {
    try {
      await networkInstance.clientWithHeaders.inAppNudge.publishJournay(journeyId, NEXT_STATUS_CONFIG[currentStatus]);
      openNotification('success', { message: `Journey ${NEXT_STATUS_CONFIG[currentStatus]} successfully` });
      getAllJourneys();
    } catch (error) {
      openNotification('error', { message: `Journey ${NEXT_STATUS_CONFIG[currentStatus]} failed` });
    }
  }

  useEffect(() => {
    getNudgeConfiguration();
    getEventsAndProperties();
    getAllJourneys();
  }, [getAllJourneys, getEventsAndProperties, getNudgeConfiguration]);

  const filteredJourneys = useMemo(() => allJourneys.filter((journey: Journey) => {
    if (runParallelAlways === RUN_PARALLEL_ALWAYS_FILTER_TYPES.BOTH) {
      return true
    }
    if (runParallelAlways === RUN_PARALLEL_ALWAYS_FILTER_TYPES.YES) {
      return journey.run_parallel_always
    } else {
      return !journey.run_parallel_always
    }
  }), [allJourneys, runParallelAlways])

  const tableConfig = useTableConfig(changeJourneyState, updateViewJourney, updateEditJourney, filteredJourneys, pagination, setPagination, setDrawer);

  const onJourneyCreate = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_JOURNEY);
  }

  const onCustomNudge = () => {
    setActiveDrawer(DRAWER_TYPE.CUSTOM_NUDGE);
  }

  const onDrawerClose = () => {
    if (activeDrawer === DRAWER_TYPE.CREATE_JOURNEY || activeDrawer === DRAWER_TYPE.EDIT_JOURNEY) {
      Modal.confirm({
        title: 'All changes will be lost. Are you sure you want to close',
        icon: <ExclamationCircleFilled />,
        // content: 'Some descriptions',
        onOk() {
          setActiveDrawer(undefined);
        },
        onCancel() {
        },
      });
    } else {
      setActiveDrawer(undefined);
    }
  }

  const reloadJourneys = () => {
    getAllJourneys();
  }
  if (!nudgeConfiguration) return null;

  return <JourneyContextProvider value={{ nudgeConfiguration, eventsAndProperties, commonEventProperties, reloadJourneys }}>
    <SocialScreenContainer
      title={"In app nudge journey"}
      filtersUI={<Filters runParallelAlways={runParallelAlways} setRunParallelAlways={setRunParallelAlways} />}
      extra={<>
        <Button onClick={onCustomNudge} style={{ marginRight: '20px' }}> Custom Nudge </Button>
        <Button onClick={onJourneyCreate}> Create journey </Button>
      </>
      }
      tableProps={tableConfig}
      drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
      drawerContent={drawerContent}
    />
  </JourneyContextProvider>
};

export default withDefaultLayout(JourneyPage)