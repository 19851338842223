import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { SendNotification } from './types'

class SystemApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  triggerEvent = (payload: SendNotification) =>
    this.api.post(`/gw1/puja/internal/v1/system`, payload)
}

export default SystemApi
