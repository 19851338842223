import { Input } from 'antd';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  initValue?: number;
  id?: string;
  placeholder?: string;
  style?: any;
  fleldName?: string;
}
const NumberInput = ({
  value,
  onChange,
  initValue,
  placeholder = '',
  style,
  fleldName,
}: Props) => {
  const [number, setNumber] = useState(() => value !== undefined ? value : initValue || 0);

  const triggerChange = useCallback(
    (changedValue: number) => {
      onChange?.(changedValue);
    },
    [onChange]
  );

  const onNumberChange = (event: any) => {
    const newNumber = parseInt(event.target.value || '0', 10);

    if (Number.isNaN(number)) {
      return;
    }
    setNumber(newNumber);
    triggerChange(newNumber);
  };

  return (
    <span>
      <Input
        type="text"
        value={number}
        onChange={onNumberChange}
        style={{
          width: '50%',
          ...style,
        }}
        placeholder={placeholder}
      />
    </span>
  );
};
export default NumberInput;
