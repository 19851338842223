import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import {
  GenerateOTPOptions,
  GenerateOTPResponse,
  RefreshTokenResponse,
  SignUpOptions,
  SignUpResponse,
  SignupUserData,
} from './types'
import { apiClient } from '../../apiClient'

type Header = {
  [key: string]: string
}
class AuthApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  generateOTP = (options: GenerateOTPOptions) =>
    this.api.post<GenerateOTPResponse>(
      '/expresso/mandir/api/users/generateOTP.php',
      options,
    )

  signUp = ({
    referralId = 0,
    guestUserId = 0,
    isPurohitUser = false,
    ...rest
  }: SignUpOptions) =>
    this.api.post<SignUpResponse>('/expresso/mandir/api/users/signupV2.php', {
      referralId,
      guestUserId,
      ...rest,
      isPurohitUser,
    })

  guestSignUp = async (userData: SignupUserData, headers?: Header) =>
    this.api.post<SignUpResponse>(
      '/expresso/mandir/api/users/signupV2.php',
      userData,
      {
        headers: {
          ...headers,
        },
      },
    )
  refreshToken = async (refreshToken: string, headers?: Header) => {
    const response = await this.api.post<RefreshTokenResponse>(
      '/expresso/mandir/api/users/generateAccessToken.php',
      {},
      {
        headers: {
          ...headers,
          'refresh-token': `Bearer ${refreshToken}`,
        },
      },
    )
    return response?.data
  }
}

export default AuthApi
