import { Button, Card, Empty } from 'antd'
import styled from 'styled-components'

import React, { useCallback, useState } from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { SortableCardItem } from './CardItem'
import { SortableItemAudio } from '@a4b/api/src/modules/Content/Audio/types'

interface CardListProps {
  limit: number
  items: SortableItemAudio[]
  onRemoveItem: (item: SortableItemAudio) => void
  language_code: string
}

const Container = styled(Card)`
  .ant-card-body {
    display: flex;
    flex-wrap: wrap;
  }
`

const CardList: React.FC<CardListProps> = ({ items, limit, onRemoveItem, language_code }) => {
  const [cardType, setCardType] = useState<'small' | 'medium' | 'large'>('medium');

  const changeCardType = useCallback((type: typeof cardType) => {
    setCardType(type);
  }, [])

  return (
    <div>
      <div style={{ position: 'sticky', top: '50px', marginTop: '32px', zIndex: 100, width: '300px' }}>
        <span><b>Audio card view </b></span>
        <br />
        <Button onClick={() => { changeCardType('small') }} type={cardType === 'small' ? 'primary' : 'default'}>S</Button>
        <Button onClick={() => { changeCardType('medium') }} type={cardType === 'medium' ? 'primary' : 'default'}>M</Button>
        <Button onClick={() => { changeCardType('large') }} type={cardType === 'large' ? 'primary' : 'default'}>L</Button>
      </div>
      <Container >
        {items && items.length > 0 ? (
          items.map((item, index) => (
            <SortableCardItem
              enabled={index + 1 <= limit}
              key={`item-${item.id}`}
              index={index}
              item={item}
              itemIndex={index}
              cardType={cardType}
              onRemoveItem={onRemoveItem}
              isLanguageCodeError={item.audio_language !== language_code}
            />
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              height: '300px',
              alignItems: 'center',
            }}
          >
            <Empty description='please add items to rearrange' />
          </div>
        )}
      </Container>
    </div>
  )
}

export const SortableCardList = SortableContainer(CardList)
