import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import CreateAction from "../components/CreateAction";
import { Action } from "../utils/types";


export enum DRAWER_TYPE {
    CREATE_COHORT = 'Create action',
    EDIT_COHORT = "Edit action"
}



export default function useDrawerConfig(
    eventsAndProperties: any,
    commonEventProperties: any,
    activeDrawer?: DRAWER_TYPE,
    action?: Action,
): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {

    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_COHORT,
                width: '80vw',
            },
            drawerContent: <CreateAction eventsAndProperties={eventsAndProperties} commonEventProperties={commonEventProperties} />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.EDIT_COHORT,
                width: '80vw',
            },
            drawerContent: <CreateAction actionData={action} eventsAndProperties={eventsAndProperties} commonEventProperties={commonEventProperties} />
        },
    ], [action, commonEventProperties, eventsAndProperties])
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}