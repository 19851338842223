import { getWidgetEntityInstance } from "./../../utils/helper";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Content } from "@/utils/types";
import moment from "moment";
import { throwError } from "../../utils/helper";
import { daysOfWeek } from "../../utils/utilProps";

class Schedule {
  widget_id!: string;
  platform!: string;
  property!: string;
  widget_code!: string;
  widget_type!: string | undefined;
  order!: string;
  priority!: string;
  start_timestamp!: string;
  end_timestamp!: string;
  start_day_time!: string;
  end_day_time!: string;
  valid_on_days!: string;
  startAndEndDay!: any[];
  startAndEndTime!: any[];
  daysOfTheWeek!: string[];
  content!: Content;
  language_mode: string | undefined;
  language: string | undefined;

  getContent = () => {
    return this.content;
  };
  setContentType = (content: Content) => {
    this.content = content;
  };
  getSerializedData = (scheduleForm: any) => {
    if (scheduleForm["startAndEndDay"]?.length) {
      let [startDate, endDate] = scheduleForm?.startAndEndDay;
      scheduleForm.start_timestamp = startDate && startDate.unix();
      scheduleForm.end_timestamp = endDate && endDate.unix();
    }
    if (scheduleForm["startAndEndTime"]?.length) {
      let [startTime, endTime] = scheduleForm?.startAndEndTime;
      scheduleForm.start_day_time = startTime && startTime.format("HH:mm:ss");
      scheduleForm.end_day_time = endTime && endTime.format("HH:mm:ss");
    }
    scheduleForm.valid_on_days = this.getSerializeDaysOfWeek(
      scheduleForm.daysOfTheWeek
    ).join("");
    let widgetInstance = getWidgetEntityInstance(scheduleForm.widget_type);
    scheduleForm.content = widgetInstance.getSerializedData(
      scheduleForm.content
    );

    delete scheduleForm.widget_code;
    delete scheduleForm.daysOfTheWeek;
    delete scheduleForm.startAndEndDay;
    delete scheduleForm.startAndEndTime;
    delete scheduleForm.widget_type;
    return { schedule: scheduleForm };
  };

  getDeSerializedData = (scheduleData: any) => {
    scheduleData["platform_property_code"] =
      scheduleData?.platform_property?.code;
    scheduleData["widget_type"] = scheduleData?.widget?.widget_type;
    scheduleData["widget_code"] = scheduleData?.widget?.code;

    scheduleData["startAndEndDay"] = [];
    scheduleData["startAndEndTime"] = [];
    scheduleData["startAndEndDay"][0] = moment.unix(
      scheduleData.start_timestamp
    );

    scheduleData["startAndEndDay"][1] = moment.unix(scheduleData.end_timestamp);
    scheduleData["startAndEndTime"][0] = moment(
      scheduleData.start_day_time,
      "HH:mm:ss"
    );
    scheduleData["startAndEndTime"][1] = moment(
      scheduleData.end_day_time,
      "HH:mm:ss"
    );
    scheduleData.daysOfTheWeek = this.getDeSerializeDaysOfWeek(
      scheduleData.valid_on_days
    );
    let widgetInstance = getWidgetEntityInstance(
      scheduleData?.widget?.widget_type
    );
    scheduleData.content = widgetInstance?.getDeSerializedData(
      scheduleData.content
    );


    return scheduleData;
  };
  getDeSerializeDaysOfWeek = (days: string) => {
    let daysValue = [];
    let eachDayList = days?.split("");
    let isAllDays = eachDayList?.includes("0");

    daysValue = days
      ?.split("")
      ?.map((day: string, index: number) => {
        if (Number(day) === 1) return daysOfWeek[index + 1].value;
      })
      .filter((item) => item);
    if (!isAllDays) {
      daysValue?.push("All");
    }
    return daysValue;
  };
  getSerializeDaysOfWeek = (daysList: string[]) => {
    let allRemoved = daysList.filter((day) => day !== "All");
    let removedAllFromDaysOfWeek = daysOfWeek.filter(
      (item) => item.value !== "All"
    );
    return removedAllFromDaysOfWeek.map((day) =>
      allRemoved.includes(day.value) ? 1 : 0
    );
  };

  getFormFieldsForDisable = (record: any) => {
    return {
      platform_property_code: record?.platform_property?.code,
      order: record?.order,
      priority: record?.priority,
      widget_id: record?.widget?.id,
      start_timestamp: record?.start_timestamp,
      end_timestamp: record?.end_timestamp,
      start_day_time: record?.start_day_time,
      end_day_time: record?.end_day_time,
      daysOfTheWeek: this.getDeSerializeDaysOfWeek(record?.valid_on_days),
      content: record?.content,
      active: record?.active,
      archive: record?.archive,
      language_mode: record?.language_mode,
      language: record?.language,
    };
  };
  platformValidator = async (rule: any, value: any) => {
    if (!value) throwError("Platform required.");
  };
  propertyValidator = async (rule: any, value: any) => {
    if (!value) throwError("Property required.");
  };
  widgetCodeValidator = async (rule: any, value: any) => {
    if (!value) throwError("Widget Code required.");
  };
  daysOfTheWeekValidator = async (rule: any, value: any) => {
    if (!value) throwError("Days of the week required.");
    if (value && !value.length) throwError("Days of the week required.");
  };
  startAndEndDateValidator = async (rule: any, value: any) => {
    if (!value) throwError("Start and end date required.");
    if (value && value.length < 2)
      throwError("Both Start and End date required.");
  };
  startAndEndTimeValidator = async (rule: any, value: any) => {
    if (!value) throwError("Start and end time required.");
    if (value && value.length < 2)
      throwError("Both Start and End time required.");
  };
  orderValidator = async (rule: any, value: any) => {
    if (!value) throwError("Order required.");
    if (!value || value <= 0 || value > 99)
      throwError("Priority should be between 1 and 99.");
  };
  priorityValidator = async (rule: any, value: any) => {
    if (!value) throwError("Priority required.");
    if (value === 0) throwError("Priority should be greater than 0.");
    //if (!value) throwError("Priority required.");
    if (value <= 0 || value > 99)
      throwError("Priority should be between 1 and 99.");
  };
  widgetTypeValidator = async (rule: any, value: any) => {
    if (!value)
      throwError(
        "Please do search by widget code above and choose a valid widget type."
      );
  };
}
export default Schedule;
export const ScheduleInstance = new Schedule();
