import React, { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useAppContext } from '@/components/AppContext'
import TempleCreation from '../components/stores/TempleCreation'
import StoreCloneModal from '@a4b/ui/src/modules/monetisation/molecules/StoreCloneModal'
import ConfirmationPopup from '@a4b/ui/src/modules/monetisation/molecules/ConfirmationPopup'
import { HubList, LanguageState, TemplesList, STORE_SERVICE_TYPE } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { formatCreateTempleApi, parseCreateTempleApiEdit } from '../utils/helper'
import { debounce } from 'lodash'

interface Props {
  isEditJourney: boolean
  currentStep: number
  storeId: string
  nextHandler: (store_id?: string) => void
  previousHandler: () => void
  languageState: LanguageState
}

const TempleCreationStep: React.FC<Props> = ({
  currentStep,
  isEditJourney,
  storeId,
  previousHandler,
  nextHandler,
  languageState
}) => {
  const { networkInstance, countryState, setLanguageContext, setCountryState } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders
  const [templeFormInstance] = useForm()
  const [hubListData, setHubList] = useState<HubList>()
  const [templesListData, setTemplesList] = useState<TemplesList>()
  const [searchParams, setSearchParams] = useSearchParams()

  const [slugState, setSlugState] = useState<boolean>(false)
  const [storeData, setStoreData] = useState<any>()
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [showCloneModal, setShowCloneModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [locationData, setLocationData] = useState<any>()
  const [addressRemoved, setAddressRemoved] = useState(false);
  const [cloneFormInstance] = useForm()

  const getHubList = useCallback(async () => {
    try {
      const hubList = await monetisationOfferingsApi.hub.getHubList({
        limit: 100,
        offset: 0,
      })
      setHubList(hubList?.data?.data)
    } catch (error) {
      console.log(error)
      message.error('Error while fetching hub list', 3)
    }
  }, [monetisationOfferingsApi.hub])

  const getTemplesList = useCallback(async () => {
    try {
      const templesList = await monetisationOfferingsApi.templesApi.GetTempleList()
      setTemplesList(templesList?.data?.data)
    } catch (error) {
      console.log(error)
      message.error('Error while fetching temple list', 3)
    }
  }, [monetisationOfferingsApi.templesApi])

  const getStore = useCallback(async () => {
    try {
      setIsLoading(true)
      let storeResponse
      if (storeId) {
        storeResponse = await monetisationOfferingsApi.storesApi.getStoreById(
          storeId,
          countryState?.countryCode
        )
      }
      const parsedData = parseCreateTempleApiEdit(storeResponse?.data?.data, languageState)
      setSearchParams(
        createSearchParams({
          // Todo - fix the type later 
          service_type: parsedData?.service_type as any,
          store_type: parsedData?.store_type,
          edit: searchParams.get('edit') || '',
          store_id: searchParams.get("store_id") || "",
          language_code: languageState?.monetisationLanguage,
          active: storeResponse?.data?.data?.active ? "true" : "false",
          country_code: countryState?.countryCode
        }),
      )
      templeFormInstance.setFieldsValue({
        ...parsedData,
        ...(!parsedData?.temple_media?.media_type && {
          temple_media: {
            media_type: 'image'
          }
        })
      })

      setLocationData(parsedData?.location_details)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
      // message.error('Error while fetching store', 3)
    }
  }, [monetisationOfferingsApi.storesApi, storeId, templeFormInstance, countryState?.countryCode])


  useEffect(() => {
    if (currentStep === 0) {
      getHubList()
      getTemplesList()
    }
  }, [currentStep, getHubList, getTemplesList])

  useEffect(() => {
    isEditJourney && getStore()
  }, [getStore, isEditJourney])

  useEffect(() => {
    !isEditJourney && templeFormInstance.setFieldsValue({
      temple_media: {
        media_type: 'image'
      }
    })
  }, [countryState?.countryCode, isEditJourney, searchParams, templeFormInstance])

  const handleStoreCreateOrUpdate = async () => {
    if (isEditJourney) {
      try {
        if (!slugState) {
          await monetisationOfferingsApi.storesApi.updateStore(
            storeData,
            storeId
          )
          message.success({ content: 'Temple Updated Successfully', duration: 2 })
          nextHandler()
        } else {
          message.error({ content: 'Please Enter Valid slug', duration: 2 })
        }
      } catch (error: any) {
        message.error('Error while updating temple' + error?.message, 3)
      }
    } else {
      try {
        if (!slugState) {
          const createResponse = await monetisationOfferingsApi.storesApi.createStore(
            storeData,
          )
          message.success({ content: 'Temple Created Successfully', duration: 2 })
          setSearchParams(
            createSearchParams({
              edit: searchParams.get('edit') || "true",
              store_id: createResponse?.data?.data?.store_id,
              current_step: currentStep.toString(),
              service_type: storeData?.service_type,
            }),
          )

          setLanguageContext({ ...languageState, isLanguageSelectionActive: false })
          setCountryState({ ...countryState, isCountryCodeActive: false })
          nextHandler(createResponse?.data?.data?.store_id)
        } else {
          message.error({ content: 'Please enter valid slug', duration: 2 })
        }
      } catch (error: any) {
        console.log(error)
        message.error('Error while creating temple' + error?.message, 3)
      }
    }
  }

  const handleOk = () => {
    setShowConfirmation(false)
    handleStoreCreateOrUpdate()
  }

  const handleCancel = () => {
    setShowConfirmation(false)
  }

  const handleCloneModal = () => {
    setShowCloneModal((prevState) => (!prevState))
  }

  const updateStoreByDestinationId = async (data: any, values: any) => {
    const storePayload = {
      ...data,
      language_code: values?.destination_language,
      country_code: values?.destination_country
    }
    try {
      await monetisationOfferingsApi.storesApi.updateStore(
        storePayload,
        storeId,
      )
      message.success({ content: `Temple Cloned Successfully to language:${values?.destination_language}, country: ${values?.destination_country} `, duration: 2 })
      setShowCloneModal((prevState) => (!prevState))
    } catch (error: any) {
      message.error('Error while cloning temple' + error?.message, 3)
    }
  }

  const getStoreBySourceId = async (values: any) => {
    const countryCode = values?.source_country
    const languageCode = values?.source_language
    try {
      const storeResponse = await monetisationOfferingsApi.storesApi.getStoreById(
        storeId,
        countryCode,
        languageCode
      )
      const storeData = storeResponse?.data?.data
      if (
        languageCode === storeData?.language_code &&
        countryCode === storeData?.country_code
      ) {
        updateStoreByDestinationId(storeResponse?.data?.data, values)
      } else {
        message.error(`No data available for language: ${languageCode}, country: ${countryCode}`, 3)
      }
    } catch (error) {
      message.error('Error while fetching store by source Id', 3)
    }
  }

  const handleCloneSubmit = () => {
    cloneFormInstance
      .validateFields()
      .then((values: any) => {
        getStoreBySourceId(values)
      })
  }

  const handleTempleCreateOrUpdate = async (values: any) => {
    searchParams.set("service_type", values?.service_type)
    searchParams.set("store_type", values?.store_type)
    searchParams.set("language_code", languageState?.monetisationLanguage)
    searchParams.set("country_code", countryState?.countryCode)
    setSearchParams(searchParams)

    const isTrueSet = (searchParams.get("active")?.toLowerCase() === 'true')

    if (isEditJourney) {
      const formData = formatCreateTempleApi({
        ...values,
        position: templeFormInstance.getFieldValue("position"),
        id: storeId,
      }, languageState, isTrueSet, isEditJourney, countryState?.countryCode, addressRemoved)
      setStoreData({ ...formData, country_code: countryState?.countryCode })
      setShowConfirmation(true)
    } else {
      const formData = formatCreateTempleApi(
        values,
        languageState,
        isTrueSet,
        isEditJourney,
        countryState?.countryCode, addressRemoved)
      setStoreData({ ...formData, country_code: countryState?.countryCode })
      setShowConfirmation(true)
    }
  }
  const handleTempleCreate = () => {
    templeFormInstance
      .validateFields()
      .then((values: any) => {
        handleTempleCreateOrUpdate(values)
      })
      .catch(error => {
        console.log('Temple Creation error')
      })
  }
  const submitFormHandler = () => {
    handleTempleCreate()
  }
  const validateSlugField = async (slug: string) => {
    if (slug) {
      const slugResponse = await monetisationOfferingsApi.storesApi.getSlugDuplicate(slug)
      setSlugState(slugResponse?.data?.data?.is_duplicate)
      return slugResponse?.data?.data?.is_duplicate
    }
    return
  }
  const debouncedHandler: any = debounce(validateSlugField, 1000)

  const onValuesChange = (values: any) => {
    debouncedHandler(values?.slug)
  }

  const handleRemoveAddress = (removed: any) => {
    setAddressRemoved(removed);
  };

  return (
    <>
      <TempleCreation
        hubList={hubListData}
        form={templeFormInstance}
        submitFormHandler={submitFormHandler}
        isEditJourney={isEditJourney}
        currentStep={currentStep}
        previousHandler={previousHandler}
        nextHandler={() => nextHandler()}
        onValuesChange={onValuesChange}
        slugState={slugState}
        handleCloneModal={handleCloneModal}
        acceptLanguage={languageState?.monetisationLanguage}
        isLoading={isLoading}
        locationDetails={locationData}
        onRemoveAddress={handleRemoveAddress}
        templesList={templesListData}
      />

      {storeData &&
        <ConfirmationPopup
          storeData={storeData}
          visible={showConfirmation}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={'Store'}
        />
      }
      <StoreCloneModal
        form={cloneFormInstance}
        showCloneModal={showCloneModal}
        handleCloneModal={handleCloneModal}
        handleCloneSubmit={handleCloneSubmit}
      />
    </>
  )
}

export default TempleCreationStep
