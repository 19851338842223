//app context
import { useAppContext } from '@/components/AppContext'
import { debounce } from 'lodash'

import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import type { ContentFilterFormFields } from '@a4b/ui/src/admin'

import type { ContentTag, LibraryContent } from '@a4b/api'

const FILTER_KEY_LIBRARY = 'FILTER_KEY_LIBRARY';

const saveFilters = (filters: ContentFilterFormFields, key: string) => {
  localStorage.setItem(FILTER_KEY_LIBRARY + key, JSON.stringify(filters));
}

const getFilters = (key: string) => {
  const filtersString = localStorage.getItem(FILTER_KEY_LIBRARY + key);
  return filtersString ? JSON.parse(filtersString) as ContentFilterFormFields : null;
}

const useLibrary = (defaultFilter: ContentFilterFormFields, filterKey: string) => {
  let navigate = useNavigate()
  const { networkInstance, userProfile } = useAppContext()
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState<LibraryContent[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const savedFilters = getFilters(filterKey)
  const [filter, setFilter] = useState<ContentFilterFormFields>(savedFilters ? savedFilters : defaultFilter)
  const [tags, setTags] = useState<ContentTag[]>([])

  const onEditContentClicked = useCallback(
    (item: LibraryContent) => {
      navigate(`/content/edit-draft/${item.id}`)
    },
    [navigate],
  )
  const onViewContentClicked = useCallback(
    (item: LibraryContent) => {
      navigate(`/content/edit-draft/${item.id}`)
    },
    [navigate],
  )
  const onApplyFilterClicked = useCallback(
    (filter: ContentFilterFormFields) => {
      saveFilters(filter, filterKey);
      setFilter(filter)
    },
    [filterKey],
  )
  const onAddNewContentClicked = useCallback(() => {
    navigate('/content/create-draft')
  }, [navigate])

  const onSendToQCClicked = useCallback(
    async (item: LibraryContent) => {
      const response = await networkInstance.clientWithHeaders.contentApi.library.updateLibraryContentById(
        item.id,
        {
          status: 'READY_TO_BE_MODERATED',
          updatedBy: userProfile.email,
        },
      )
      if (response.status === 204) {
        const updatedContent = content.filter(content => content.id !== item.id)
        setContent([...updatedContent])
        return true
      }
      return false
    },
    [networkInstance.clientWithHeaders.contentApi.library, userProfile.email, content],
  )

  const onSendToQCDraft = useCallback(
    async (item: LibraryContent) => {
      const response = await networkInstance.clientWithHeaders.contentApi.library.updateLibraryContentById(
        item.id,
        {
          status: 'DRAFT',
          updatedBy: userProfile.email,
        },
      )
      if (response.status === 204) {
        const updatedContent = content.filter(content => content.id !== item.id)
        setContent([...updatedContent])
        return true
      }
      return false
    },
    [networkInstance.clientWithHeaders.contentApi.library, userProfile.email, content],
  )

  const onDeleteContentClicked = useCallback(
    async (item: LibraryContent) => {
      const response = await networkInstance.clientWithHeaders.contentApi.library.updateLibraryContentById(
        item.id,
        {
          status: 'DELETED',
          updatedBy: userProfile.email,
        },
      )
      if (response.status === 204) {
        const updatedContent = [...content]
        const contentToUpdate = updatedContent.find(
          content => content.id === item.id,
        )
        if (contentToUpdate) {
          contentToUpdate.status = 'DELETED'
        }
        setContent(updatedContent)
        return true
      }
      return false
    },
    [networkInstance.clientWithHeaders.contentApi.library, userProfile.email, content],
  )

  useEffect(() => {
    async function init() {
      setLoading(true)
      const p1 = networkInstance.clientWithHeaders.contentApi.library.getLibraryContent(filter)
      const p2 = networkInstance.clientWithHeaders.contentApi.library.getLibraryContentCount(filter)
      const [response1, response2] = await Promise.all([p1, p2])
      setContent(response1.data.data.libraries)
      setTotalCount(response2.data.data.count)
      setLoading(false)
    }
    init()
  }, [networkInstance.clientWithHeaders.contentApi.library, filter])

  const onPageChanged = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true)
      const params = {
        ...filter,
        limit: pageSize,
        offset: (page - 1) * pageSize,
      }
      const response = await networkInstance.clientWithHeaders.contentApi.library.getLibraryContent(params)
      setContent(response.data.data.libraries)
      setLoading(false)
    },
    [networkInstance.clientWithHeaders.contentApi.library, filter],
  )

  const handleTagSearch = useCallback(
    async (value: string, offset?: number) => {
      const { data } = await networkInstance.clientWithHeaders.contentApi.tag.getTags({
        name: value,
        limit: 30,
        offset: (offset || 0) * 30,
      })
      setTags(data.data.tags || [])
    },
    [networkInstance.clientWithHeaders.contentApi.tag],
  )

  useEffect(() => {
    handleTagSearch('')
  }, [handleTagSearch])

  const onTagSearch = debounce(handleTagSearch, 1000)

  return {
    loading,
    tags,
    content,
    filter,
    totalCount,
    onPageChanged,
    onEditContentClicked,
    onSendToQCClicked,
    onViewContentClicked,
    onApplyFilterClicked,
    onAddNewContentClicked,
    onDeleteContentClicked,
    onSendToQCDraft,
    onTagSearch,
  }
}

export default useLibrary
