import { VideoData } from '@a4b/api/src/modules/Monetisation/Puja/types'

import { Values } from '../components/SankalpNameTagging/type'

export const timeFormat =
  /^(?:([01]?\d|2[0-3]):([0-5]\d)$|^([01]?\d|2[0-3]):([0-5]\d):([0-5]\d)$|^([0-5]?[0-9]):([0-5]\d)$)/

export enum SANKALP_VIDEO_STATUS {
  PROCESSING = 'processing',
  PROCESSED = 'processed',
  NOT_APPLICABLE = 'not-applicable',
}

export const prepareNameTaggingPayload = (data: Values): VideoData => {
  const nameList =
    data?.pooja_member_data
      ?.filter(item => item?.start_time && item?.end_time)
      .map(item => ({
        order_id: item?.order_id,
        start: item?.start_time,
        end: item?.end_time,
        text: `${item?.gotra}: ${item?.names?.replace?.(/ - /g, ', ')}`,
      })) || []

  const payload: VideoData = {
    file: data?.raw_video?.[0],
    info: nameList,
  }

  return payload
}

export const deliveryOrderPayload = (data: any, batchId: string) => {
  let formattedTimeIso
  const pickupTime = new Date(data?.pickup_info?.pickup_time)
  let processedPickupTime
  if (isNaN(pickupTime.getTime())) {
    processedPickupTime = new Date()
  } else {
    processedPickupTime = new Date(data?.pickup_info?.pickup_time)
  }
  formattedTimeIso = processedPickupTime.toISOString()
  let payload: any = {
    ...data,
    pickup_info: {
      ...data.pickup_info,
      phone: data?.pickup_info?.phone?.toString() || '',
      email: data?.pickup_info?.email || '',
      pincode: data?.pickup_info?.pincode?.toString() || '',
      pickup_time: formattedTimeIso,
    },
    batch_id: batchId,
  }
  return payload
}
