import { ContentStatus, SupportedContentFormats } from '@a4b/api'

export const CONTENT_FORMATS: SupportedContentFormats[] = [
  'text',
  'audio',
  'video',
  'pdf',
]
export const PLATFORMS = ['WEB', 'MOBILE']

export const CONTENT_TYPES = [
  // 'BOOK',
  'ARTICLE',
]

export const CONTENT_STATUS: ContentStatus[] = [
  'DRAFT',
  'CHANGE_REQUESTED',
  'READY_TO_BE_MODERATED',
  'PUBLISHED',
  'DELETED',
]

export interface Country {
  country_name: string
  country_code: string
}

export type CountryList = Country[]

export interface Language {
  name: string
  alt_name: string
  code: string
  value: string
  state: string
}

export type LanguageList = Language[]

export const SUPPORTED_LANGUAGES = ['HINDI', 'ENGLISH']

export const SUPPORTED_LANGUAGES_LIST = [
  {
    "name": "বাংলা",
    "alt_name": "Bangla",
    "code": "bengali",
    "value": "bn",
    "state": "coming_soon"
  },

  {
    "name": "हिन्दी",
    "alt_name": "Hindi",
    "code": "hindi",
    "value": "hi",
    "state": "live"
  },
  {
    "name": "English",
    "alt_name": "English",
    "code": "english",
    "value": "en",
    "state": "live"
  },
  {
    name: 'English GB',
    alt_name: 'English GB',
    code: 'english-GB',
    value: 'en-GB',
    state: 'coming_soon',
  },
  {
    name: 'English US',
    alt_name: 'English US',
    code: 'english-US',
    value: 'en-US',
    state: 'coming_soon',
  },
  {
    "name": "ગુજરાતી",
    "alt_name": "Gujarati",
    "code": "gujarati",
    "value": "gu",
    "state": "live"
  },
  {
    "name": "ಕನ್ನಡ",
    "alt_name": "Kannada",
    "code": "kannada",
    "value": "kn",
    "state": "coming_soon"
  },
  {
    "name": "മലയാളം",
    "alt_name": "Malayalam",
    "code": "malayalam",
    "value": "ml",
    "state": "coming_soon"
  },
  {
    "name": "मराठी",
    "alt_name": "Marathi",
    "code": "marathi",
    "value": "mr",
    "state": "live"
  },
  {
    "name": "தமிழி",
    "alt_name": "Tamil",
    "code": "tamil",
    "value": "ta",
    "state": "coming_soon"
  },
  {
    "name": "తెలుగు",
    "alt_name": "Telugu",
    "code": "telugu",
    "value": "te",
    "state": "coming_soon"
  },
  {
    "name": "भोजपुरी",
    "alt_name": "Bhojpuri",
    "code": "bhojpuri",
    "value": "hi-bj",
    "state": "live"
  },
  {
    "name": "राजस्थानी",
    "alt_name": "Rajasthani",
    "code": "rajasthani",
    "value": "hi-rj",
    "state": "live"
  },
  {
    "name": "हरियाणवी",
    "alt_name": "Haryanvi",
    "code": "haryanvi",
    "value": "hi-hr",
    "state": "live"
  }
]

export const SUPPORTED_COUNTRY_LIST = [
  { country_name: 'United States', country_code: 'US' },
  { country_name: 'India', country_code: 'IN' },
]


export const SUPPORTED_LANGUAGES_CONFIG_ENGLISH_MODE = [
  {
    name: 'বাংলা',
    alt_name: 'Bangla',
    code: 'bengali',
    value: 'bn',
    state: 'coming_soon',
  },
  {
    name: 'বাংলা (English Mode)',
    alt_name: 'Bangla (English Mode)',
    code: 'bengali_english',
    value: 'bn_en',
    state: 'coming_soon',
  },
  {
    name: 'English',
    alt_name: 'English',
    code: 'english',
    value: 'en',
    state: 'coming_soon',
  },
  {
    name: 'ગુજરાતી',
    alt_name: 'Gujarati',
    code: 'gujarati',
    value: 'gu',
    state: 'live',
  },
  {
    name: 'ગુજરાતી (English Mode)',
    alt_name: 'Gujarati (English Mode)',
    code: 'gujarati_english',
    value: 'gu_en',
    state: 'live',
  },
  {
    name: 'हिन्दी',
    alt_name: 'Hindi',
    code: 'hindi',
    value: 'hi',
    state: 'live',
  },
  {
    name: 'हिन्दी (English Mode)',
    alt_name: 'Hindi (English Mode)',
    code: 'hindi_english',
    value: 'hi_en',
    state: 'live',
  },
  {
    name: 'ಕನ್ನಡ',
    alt_name: 'Kannada',
    code: 'kannada',
    value: 'kn',
    state: 'coming_soon',
  },
  {
    name: 'ಕನ್ನಡ (English Mode)',
    alt_name: 'Kannada (English Mode)',
    code: 'kannada_english',
    value: 'kn_en',
    state: 'coming_soon',
  },
  {
    name: 'മലയാളം',
    alt_name: 'Malayalam',
    code: 'malayalam',
    value: 'ml',
    state: 'coming_soon',
  },
  {
    name: 'മലയാളം (English Mode)',
    alt_name: 'Malayalam (English Mode)',
    code: 'malayalam_english',
    value: 'ml_en',
    state: 'coming_soon',
  },
  {
    name: 'मराठी',
    alt_name: 'Marathi',
    code: 'marathi',
    value: 'mr',
    state: 'coming_soon',
  },
  {
    name: 'मराठी (English Mode)',
    alt_name: 'Marathi (English Mode)',
    code: 'marathi_english',
    value: 'mr_en',
    state: 'coming_soon',
  },
  {
    name: 'தமிழி',
    alt_name: 'Tamil',
    code: 'tamil',
    value: 'ta',
    state: 'coming_soon',
  },
  {
    name: 'தமிழி (English Mode)',
    alt_name: 'Tamil (English Mode)',
    code: 'tamil_english',
    value: 'ta_en',
    state: 'coming_soon',
  },
  {
    name: 'తెలుగు',
    alt_name: 'Telugu',
    code: 'telugu',
    value: 'te',
    state: 'coming_soon',
  },
  {
    name: 'తెలుగు (English Mode)',
    alt_name: 'Telugu (English Mode)',
    code: 'telugu_english',
    value: 'te_en',
    state: 'coming_soon',
  },
  {
    name: 'भोजपुरी',
    alt_name: 'Bhojpuri',
    code: 'bhojpuri',
    value: 'hi-bj',
    state: 'live',
  },
  {
    name: 'भोजपुरी (English Mode)',
    alt_name: 'Bhojpuri (English Mode)',
    code: 'bhojpuri_english',
    value: 'hi-bj_en',
    state: 'live',
  },
  {
    name: 'राजस्थानी',
    alt_name: 'Rajasthani',
    code: 'rajasthani',
    value: 'hi-rj',
    state: 'live',
  },
  {
    name: 'राजस्थानी (English Mode)',
    alt_name: 'Rajasthani (English Mode)',
    code: 'rajasthani_english',
    value: 'hi-rj_en',
    state: 'live',
  },
  {
    name: 'हरियाणवी',
    alt_name: 'Haryanvi',
    code: 'haryanvi',
    value: 'hi-hr',
    state: 'live',
  },
  {
    name: 'हरियाणवी (English Mode)',
    alt_name: 'Haryanvi (English Mode)',
    code: 'haryanvi_english',
    value: 'hi-hr_en',
    state: 'live',
  },
]

export const PLAYLIST_TYPES = [
  'RIDDLER_SUPPORT',
  'SHUBH_MANTRA',
  'ALARM',
  'TRENDING',
  'POPULAR'
]

export const getLanguageNameFromValue = (value: string) => {
  return SUPPORTED_LANGUAGES_LIST.find((language) => language.value === value)?.alt_name || value
}
