import { UploadOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Upload,
  UploadFile,
  message,
  Select,
} from 'antd'
import { RcFile, UploadProps } from 'antd/lib/upload'
//types
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import React, { useCallback, useEffect } from 'react'

import { SUPPORTED_LANGUAGES, SUPPORTED_LANGUAGES_CONFIG_ENGLISH_MODE } from '../../../../constants'


export interface TagFormFields {
  name: string
  description: string
  language: string
  active: boolean
  image: UploadFile<string>[]
}

export interface Props {
  data?: TagFormFields
  onSubmit: (values: TagFormFields) => Promise<boolean>
  onUploadImage: (file: RcFile) => Promise<string>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

const { useForm } = Form
export const CreateEditTag: React.FC<Props> = ({
  data,
  onSubmit,
  onUploadImage,
}) => {
  const [form] = useForm<TagFormFields>()

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data)
    }
  }, [data, form])

  const onFinish = useCallback(async (values: TagFormFields) => {
    console.log({ values })
    const success = await onSubmit(values)
    success
      ? message.success('Successfully created a tag')
      : message.error('Failed to create a tag')
  }, [])

  const onFinishFailed = useCallback(
    (errorInfo: ValidateErrorEntity<TagFormFields>) => {
      console.log(errorInfo)
    },
    [],
  )

  const handleUploadImage: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
  }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await onUploadImage(file)
    if (onSuccess) {
      onSuccess(response)
    }
  }

  return (
    <Row style={{ padding: '40px' }}>
      <Col span={12} xs={24} sm={24} md={24} lg={12}>
        <Card title='Tag Create/Edit'>
          <Form
            name='tag-create-edit-form'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <Form.Item
              label='Name'
              name='name'
              rules={[{ required: true, message: 'Please input name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Description'
              name='description'
              rules={[{ required: true, message: 'Please input description!' }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              label='Language'
              name='language'
              rules={[{ required: true, message: 'Please choose a language!' }]}
            >
              <Select
                showSearch
                optionLabelProp="label"
                optionFilterProp="label"
                mode={data ? undefined : 'multiple'}
              >
                {SUPPORTED_LANGUAGES_CONFIG_ENGLISH_MODE.map((lang, index) => (
                  <Select.Option label={lang.alt_name} value={lang.value} key={lang.code}>
                    {lang.alt_name}( {lang.name} )
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='Active'
              name='active'
              valuePropName='checked'
              rules={[
                {
                  required: true,
                  message: 'Please select active or inactive state',
                },
              ]}
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              label='Add Image'
              name='image'
              valuePropName='fileList'
              getValueFromEvent={normFile}
              rules={[{ required: true, message: 'Please add an image' }]}
            >
              <Upload
                multiple={false}
                maxCount={1}
                accept={'.png,.gif'}
                name='input_file'
                listType='picture'
                customRequest={handleUploadImage}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default CreateEditTag
