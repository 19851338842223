import React from "react";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { ATTACHMENT } from "@/utils/enum";
import DeletableContainer from "../deletableContainer/DeletableContainer";
import HeaderTags from "../headerTags/HeaderTags";
import Attachment from "@/ui/organisms/Attachment";
import Riddler from "@/network/Riddler/Riddler";
import YoutubeSchedule from "@/entities/feeds/Widgets/YoutubeSchedule";
import { YoutubeScheduleInstance } from "@/entities/feeds/Widgets";

interface Props {
  riddlerInstance: any;
  contentInstance: YoutubeSchedule;
}
const YoutubeScheduleWidget: React.FC<Props> = ({
  riddlerInstance,
  contentInstance,
}) => {
  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            required={true}
            label="Header"
            name={["content", "header"]}
            rules={[
              {
                validator: contentInstance?.headerValidator,
              },
            ]}
          >
            <Input placeholder="Header" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <HeaderTags widgetClass={contentInstance} />
        </Col>
      </Row>
      <Form.List name={["content", "items"]}>
        {(fields, { add, remove, move }) => {
          return (
            <div>
              {fields.map((field: any, index: number) => {
                return (
                  <Form.Item key={field.key}>
                    <DeletableContainer
                      closeHandler={() => {
                        remove(field.name);
                      }}
                      itemText={`Item Attribute : ${index + 1}`}
                      showItemTextInBold={true}
                      moveFunction={move}
                      fields={fields}
                      currentIndex={index}
                      fieldsLength={fields.length}
                      move={move}
                      add={add}
                    >
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="Title"
                            name={[field.name, "title"]}
                            rules={[
                              {
                                validator: contentInstance?.titleValidator,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="Subtitle"
                            name={[field.name, "subtitle"]}
                            rules={[
                              {
                                validator: contentInstance?.subtitleValidator,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="Schedule Time"
                            name={[field.name, "scheduled_time"]}
                            rules={[
                              {
                                validator:
                                  contentInstance?.scheduledTimeValidator,
                              },
                            ]}
                          >
                            <DatePicker showTime showToday />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="CTA Text"
                            name={[field.name, "cta_text"]}
                            rules={[
                              {
                                validator: contentInstance?.ctaTextValidator,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label="Image Attachment"
                            name={[field.name, "image_attachment_id"]}
                            rules={[
                              {
                                validator:
                                  contentInstance?.imageAttachmentValidator,
                              },
                            ]}
                          >
                            <Attachment
                              attachmentType={ATTACHMENT.IMAGE}
                              riddlerInstance={riddlerInstance}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            name={[field.name, "video_attachment_id"]}
                            label="Video Attachment ID"
                            required={true}
                            rules={[
                              {
                                validator:
                                  contentInstance?.videoRefCodeValidator,
                              },
                            ]}
                          >
                            <Attachment
                              attachmentType={ATTACHMENT.VIDEO}
                              riddlerInstance={riddlerInstance}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </DeletableContainer>
                  </Form.Item>
                );
              })}

              <Form.Item>
                <Row justify="end">
                  <Col span={24}>
                    <Row justify="end">
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            add();
                            // addItem();
                          }}
                          type="dashed"
                          icon={<PlusCircleOutlined />}
                          size="middle"
                        >
                          Add Item Attributes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
              <div style={{ height: "20px" }}></div>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

export default YoutubeScheduleWidget;
