import { Col, Form, Row, Button, TimePicker, Input } from "antd"
import React from "react"
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import TextArea from "antd/lib/input/TextArea"

interface Props {
    fields: any
    add: () => void
    remove: (item: any) => void
    cdnUrl: string
    preSignUrl: string
}

const Timings: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, }) => {
    return (
        <>
            <Col span={32}>
                {fields.map((field: any, index: number) => {
                    return (
                        <div key={index} style={{
                            border: '1.5px solid #e0dcdc',
                            marginRight: '0px',
                            marginBottom: '10px',
                            width: '55vw',
                            padding: '10px',
                            gap: '5px'
                        }}>
                            <Col
                                style={{
                                    marginLeft: '3px',
                                    display: 'contents'
                                }}>
                                <Row style={{ width: '100%' }} justify="space-between">
                                    <Form.Item
                                        label={`Icon url`}
                                        name={[field.name, 'icon_url']}
                                        labelCol={{ span: 28 }}
                                        rules={[
                                            {
                                                required: false,
                                                min: 0,
                                                max: 1,
                                                validator: async (rules: any, values: any) => {
                                                    if (values?.length < rules.min) {
                                                        return Promise.reject('Please upload Media')
                                                    }
                                                },
                                            },
                                        ]}
                                        valuePropName='fileList'
                                    >
                                        <S3Upload
                                            cdnUrl={cdnUrl}
                                            preSignUrl={preSignUrl}
                                            min={1}
                                            max={1}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={`Start time ${index + 1}`}
                                        name={[field.name, 'start_time']}
                                        labelCol={{ span: 28 }}
                                        wrapperCol={{ span: 28 }}
                                    >
                                        <TimePicker use12Hours format="h:mm a" />
                                    </Form.Item>
                                    <Form.Item
                                        label={`End time ${index + 1}`}
                                        name={[field.name, 'end_time']}
                                        labelCol={{ span: 28 }}
                                        wrapperCol={{ span: 28 }}
                                    >
                                        <TimePicker use12Hours format="h:mm a" />
                                    </Form.Item>

                                    <Button
                                        onClick={() => {
                                            remove(field.name)
                                        }}
                                        type='ghost'
                                        icon={<DeleteOutlined />}
                                        size='middle'
                                        danger
                                    ></Button>

                                </Row>
                                <Form.Item
                                    label={`Description ${index + 1}`}
                                    name={[field.name, 'description']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    style={{ marginTop: '10px' }}
                                >
                                    <TextArea placeholder="Input description" />
                                </Form.Item>
                            </Col>
                        </div>
                    )
                })}
            </Col>
            <Col span={24} style={{ paddingTop: '10px' }}>
                <Row justify='start'>
                    <Col span={4}>
                        <Button
                            onClick={() => {
                                add()
                            }}
                            type='ghost'
                            icon={<PlusCircleOutlined />}
                            size='middle'
                        >
                            Add Timings
                        </Button>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default Timings