import { createGenericContext } from "../../Journey/utils/helpers"
import { ConditionType } from "./types";

export enum GOAL_COUNT {
    SINCE_RULE_CREATION = 'SINCE_RULE_CREATION',
    DAILY = 'DAILY',
    DAILY_TZ = 'DAILY_TZ',
    DAILY_TZ_NO_TERMINATION = 'DAILY_TZ_NO_TERMINATION',
    IN_A_DAY_TZ = 'IN_A_DAY_TZ',
}

export const GOAL_COUNT_TYPES = [
    GOAL_COUNT.SINCE_RULE_CREATION,
    GOAL_COUNT.DAILY,
    GOAL_COUNT.DAILY_TZ,
    GOAL_COUNT.DAILY_TZ_NO_TERMINATION,
    GOAL_COUNT.IN_A_DAY_TZ
]

export const [useActionContext, ActionContextProvider] = createGenericContext<{}>();

export const CONDITION_TYPES = [
    ConditionType.EVAL,
    ConditionType.AND,
    ConditionType.OR
]