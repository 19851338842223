import { throwError } from "../../../utils/helper";

type Item = {
  image_attachment_id: string;
  title: string;
  subtitle: string;
  video_attachment_id: string;
  cta_text: string;
  video_attachment_type?: string;
};
type HeaderTags = {
  text: string;
  color: string;
};
class YoutubeLive {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header!: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header_tags!: HeaderTags[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items: Item[] = [];

  getSerializedData = (widgetFormData: YoutubeLive) => {
    let itemModified = widgetFormData?.items?.map((item: Item) => {
      delete item.video_attachment_type;
      return {
        ...item,
      };
    });
    widgetFormData.items = itemModified;
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: YoutubeLive) => {
    return widgetFormData;
  };

  headerValidator = async (rule: any, value: any) => {
    if (!value) return Promise.resolve();
  };
  headerTagsTextValidator = async (rule: any, value: any) => {
    if (!value) throwError("Header tags text required!");
  };
  headerTagsColorValidator = async (rule: any, value: any) => {
    if (!value) throwError("Header tags color required!");
  };
  thumbnailImageValidator = async (rule: any, value: any) => {
    if (!value) throwError("Image Attachment required!");
  };
  titleValidator = async (rule: any, value: any) => {
    if (!value) throwError("Title required!");
  };
  subtitleValidator = async (rule: any, value: any) => {
    if (!value) throwError("SubTitle required!");
  };
  ctaTextValidator = async (rule: any, value: any) => {
    if (!value) throwError("CTA Text required!");
  };

  imageCodeValidator = async (rule: any, value: any) => {
    if (!value) throwError("Image Code required!");
  };
  videoAttachmentTypeValidator = async (rule: any, value: any) => {
    if (!value) throwError("Video Attachment Type required!");
  };
  videoRefCodeValidator = async (rule: any, value: any) => {
    if (!value) throwError("Video ref Code required!");
  };
}
export default YoutubeLive;
export const YoutubeLiveInstance = new YoutubeLive();
