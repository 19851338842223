import { useAppContext } from "@/components/AppContext";
import { FormSelect } from "@/components/Forms/Select";
import { openNotification } from "@/utils";
import { SUPPORTED_LANGUAGES_LIST } from "@/utils/constants";
import { Button, Checkbox, DatePicker, Form, Select, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";

export const RASHIES = ["aries", "taurus", "gemini", "cancer", "leo", "virgo", "libra", "scorpio", "sagittarius", "capricorn", "aquarius", "pisces"];
interface AddEditRashifalProps {
}

const AddRashifal = function (props: AddEditRashifalProps) {
  const [form] = Form.useForm();
  const { networkInstance } = useAppContext();
  const createRashifal = async (values: any) => {
    values.date = values.date.format("YYYY-MM-DD");
    try {
      await networkInstance.clientWithHeaders.contentApi.rashifal.createRashifal(values);
      openNotification('success', { message: 'Rashifals created successfully!' })
      form.resetFields();
    } catch (error: any) {
      openNotification('error', { message: 'Rashifals creation failed!', description: error.toString(), })
    }
  };

  return <div>
    <Form
      form={form}
      onFinish={createRashifal}
      layout="vertical"
    >
      <Form.Item
        label="Language"
        name="language"
        rules={[{
          required: true,
        }
        ]}
      >
        <FormSelect list={SUPPORTED_LANGUAGES_LIST.map((language) => ({ lable: `${language.name} (${language.alt_name})`, value: language.value }))}  ></FormSelect>
      </Form.Item>
      <Form.List name="rashifals" initialValue={RASHIES.map(rashi => ({ rashi: rashi, active: false, rashifal_data: '' }))}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} align="baseline" style={{ width: '100%', display: 'flex', gap: '30px' }}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                  }
                >
                  {() => (
                    <Form.Item
                      {...field}
                      label="rashi"
                      name={[field.name, 'rashi']}
                      rules={[{ required: true }]}
                    >
                      <Select disabled={true} style={{ width: 130 }}>

                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Rashifal data"
                  name={[field.name, 'rashifal_data']}
                  rules={[{ required: true }]}
                  style={{ width: '500px' }}
                >
                  <TextArea rows={4} autoSize />
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Active"
                  name={[field.name, 'active']}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>

              </Space>
            ))}
          </>
        )}
      </Form.List>
      <Form.Item
        label="Date"
        name="date"
        rules={[{
          required: true,
        }
        ]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" >Submit</Button>
      </Form.Item>
    </Form>
  </div>
}

export default AddRashifal;