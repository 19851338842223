import React, { useMemo } from 'react';
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'antd/dist/antd.css';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { Button, Form, Input, InputNumber } from 'antd';
import { SocialScreenContainer } from '@a4b/ui/src/admin';
import { CommunityCategory } from '@/../../../packages/a4b-api/src/modules/Social/types';
import { openNotification } from '@/utils';




const defaultPagination = {
  current: 1,
  pageSize: 50,
  total: -1
}

const SocialCommunityTags = () => {
  const navigate = useNavigate();
  const { networkInstance, userProfile } = useAppContext();
  const [showDrawer, setShowDrawer] = useState(false);
  const [editId, setEditId] = useState<undefined | string>();
  const [tags, setTags] = useState<CommunityCategory[]>([])
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
  const [form] = Form.useForm();

  const loadCommunityTags = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.communityApi.getCommunityTags();
      setTags(res.data.data.categories);
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.communityApi])

  useEffect(() => {
    loadCommunityTags()
  }, [loadCommunityTags])

  const handleDrawerClose = useCallback(() => {
    setShowDrawer(false);
    setEditId(undefined);
  }, [setShowDrawer])

  const handleDrawerOpen = useCallback(() => {
    setShowDrawer(true);
  }, [setShowDrawer])

  const createCommunityTag = useCallback(async (values) => {
    const payload = {
      code: values.code,
      local_name: values.name,
      discription: values.discription
    }
    try {
      await networkInstance.clientWithHeaders.communityApi.createCategory(payload)
      openNotification('success', { message: 'Community tag created successfully!' })
      form.resetFields();
      loadCommunityTags();
    } catch (error: any) {
      openNotification('error', { message: 'Community tag creation failed!', description: error.toString(), })
    }
  }, [form, loadCommunityTags, networkInstance.clientWithHeaders.communityApi])


  const updateCommunityTag = useCallback(async (values) => {
    if (!editId) return
    const payload = {
      rank: values.rank,
    }
    try {
      await networkInstance.clientWithHeaders.communityApi.updateCategory(payload, editId);
      openNotification('success', { message: 'Community tag updated successfully!' })
      form.resetFields();
      loadCommunityTags();
    } catch (error: any) {
      openNotification('error', { message: 'Community tag updated failed!', description: error.toString(), })
    }
  }, [editId, form, loadCommunityTags, networkInstance.clientWithHeaders.communityApi])

  const drawerContent = useMemo(() => {
    if (editId) {
      return <Form
        form={form}
        onFinish={updateCommunityTag}
        layout="vertical"
      //  initialValues={{ requiredMarkValue: requiredMark }}
      //  onValuesChange={onRequiredTypeChange}
      //  requiredMark={requiredMark}
      >
        <Form.Item
          label="Rank"
          name="rank"
          rules={[{ type: 'number', message: 'Please input rank > 0', min: 1 }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" >Submit</Button>
        </Form.Item>
      </Form>
    }
    return <Form
      form={form}
      onFinish={createCommunityTag}
      layout="vertical"
    //  initialValues={{ requiredMarkValue: requiredMark }}
    //  onValuesChange={onRequiredTypeChange}
    //  requiredMark={requiredMark}
    >
      <Form.Item
        label="Code"
        name="code"
        rules={[{ required: true, message: 'Please input code!', max: 70 }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Name of tag"
        name="name"
        rules={[{ required: true, message: 'Please input name!', max: 70 }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Tag description"
        name="description"
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" >Submit</Button>
      </Form.Item>
    </Form>
  }, [createCommunityTag, editId, form, updateCommunityTag])

  const columns = useMemo(() => [
    {
      title: 'Name',
      dataIndex: 'local_name',
    },
    {
      title: 'Local name',
      dataIndex: 'local_name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Rank',
      dataIndex: 'rank',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (id: string) => <Button type="link" onClick={() => { setEditId(id); handleDrawerOpen(); }} > Edit </Button>
    },
  ], [handleDrawerOpen]);

  const drawerProps = useMemo(() => ({ title: editId ? 'Update community tag' : 'Create community tag', visible: showDrawer, onClose: handleDrawerClose }), [handleDrawerClose, showDrawer])

  return (
    <SocialScreenContainer
      title={'Community tags'}
      drawerProps={drawerProps}
      drawerContent={drawerContent}
      tableProps={{ columns, dataSource: tags }}
      extra={<Button onClick={handleDrawerOpen}> Create community tag </Button>}
    >
    </SocialScreenContainer>
  )
}

export default withDefaultLayout(SocialCommunityTags)
