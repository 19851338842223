import React, { useState } from 'react'
import styled from 'styled-components'
import { PlusSquareOutlined } from '@ant-design/icons'
import { Button, Card, Col, Input, Space, Table, Tag } from 'antd'
import { HubListItem } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { TempleData } from '@a4b/api/src/modules/Monetisation/Offerings/types'

interface Props {
    onCreateNew: () => void
    triggerEdit: (temple: TempleData) => void
    dataSource: any[]
    hubList: any
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const ManageTemplesPage: React.FC<Props> = ({
    onCreateNew,
    triggerEdit,
    dataSource,
    hubList
}) => {
    console.log('this is the data source', dataSource)

    const [searchTemple, setSearchTemple] = useState<string>('')
    const filteredTemples = dataSource?.filter(data =>
        data?.name?.toLowerCase()?.includes(searchTemple?.toLowerCase())
    )
    const getHubNameById = (id: string): string => {
        const hub = hubList?.hubs?.find((h: HubListItem) => h?.id === id);
        return hub ? hub?.name : id;
    };

    const filterByNameInput = () => {
        return <Input
            placeholder="Search by name"
            value={searchTemple}
            onChange={(e: any) => {
                setSearchTemple(e?.target?.value);
            }}
            size="small"
            style={{ width: '150px', marginTop: '10px', height: '30px' }}
        />
    };
    const dataColumns = [
        {
            title: 'Id',
            dataIndex: 'temple_id',
            key: 'id',
            width: '10%',
            ellipsis: true,
            render: (text: any,) => {
                return <span>{text}</span>
            },
        },
        {
            title: <div> Name <br></br> {filterByNameInput()}</div>,
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ellipsis: true,
            render: (text: any,) => {
                return <span>{text}</span>
            },
        },
        {
            title: 'God Name',
            dataIndex: 'god_name',
            key: 'god_name',
            width: '10%',
            ellipsis: true,
            render: (text: any) => {
                return <Tag color="blue">{text}</Tag>
            },
        },
        {
            title: 'Hub',
            dataIndex: 'hub_id',
            key: 'hub_id',
            width: '15%',
            render: (hubId: string) => (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    <div style={{ padding: '1px 6px', border: '1px solid #d9d9d9', borderRadius: '4px', backgroundColor: '#f5f5f5' }}>
                        {getHubNameById(hubId)}
                    </div>
                </div>
            ),
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            width: '15%',
            render: (text: any) => {
                return <Tag color={text? 'green' :'red'}>{text? 'Active' : 'Inactive'}</Tag>
            },
        },
        {
            title: 'Edit',
            key: 'edit',
            width: '9%',
            render: (text: any, record: any,) => (
                <Col span={12}>
                    <Button type='link' onClick={() => triggerEdit(record)}>
                        Edit
                    </Button>
                </Col>
            ),
        },
    ]
    return (
        <Container title='Manage Temples' style={{ width: '100%' }}>
            <Space direction={'vertical'} style={{ width: '100%' }}></Space>
            <BodyContainer>
                <Button type='text' onClick={onCreateNew}>
                    <PlusSquareOutlined /> Add New Temple
                </Button>
                <Table
                    columns={dataColumns}
                    dataSource={filteredTemples}
                    pagination={{ defaultPageSize: 10 }}
                />
            </BodyContainer>
        </Container>
    )
}

export default ManageTemplesPage
