import { Rashifal } from "@a4b/api/src/modules/Content/Rashifal/types";
import { getLanguageNameFromValue } from "@/utils/constants";
import { Button, Switch, TableColumnType, TablePaginationConfig, TableProps } from "antd";
import moment from "moment";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { openNotification } from "@/utils";
import { useAppContext } from "@/components/AppContext";
import { renderDateTime } from "../../../../../components/TableHelpers/ColumnHelper";

export default function useTableConfig<T>(
  dataSource: T[],
  setNudgeId: React.Dispatch<React.SetStateAction<string | undefined>>,
  isLoadingNudge: boolean,
  setActiveDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | undefined>>,
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
): TableProps<Rashifal.RashifalItem[]> {

  const { networkInstance } = useAppContext();

  const columns: TableColumnType<Rashifal.RashifalItem>[] = [
    {
      title: 'Id',
      dataIndex: 'id'
    },
    {
      title: 'Code',
      dataIndex: 'code'
    },
    {
      title: 'Template ',
      dataIndex: 'template',
    },
    {
      title: 'Page tag',
      dataIndex: 'page_tag',
    },
    {
      title: 'Anchor tag',
      dataIndex: 'anchor_tag'
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      render: renderDateTime
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      render: renderDateTime
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button type="link" onClick={() => {
            setActiveDrawer(DRAWER_TYPE.UPDATE_NUDGE)
            setNudgeId(value);
          }}>Edit</Button>
          <Button type="link" onClick={() => {
            setActiveDrawer(DRAWER_TYPE.VIEW_NUDGE)
            setNudgeId(value);
          }}>View</Button>
        </div>
      },
    }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  //@ts-ignore
  return { columns, dataSource, pagination, onChange: tableOnChange, loading: isLoadingNudge, bordered: true }
}