import { Button, Image, Popconfirm, Space, Table } from 'antd'
import moment from 'moment'

import React, { useCallback, useMemo } from 'react'

import type { ContentTag } from '@a4b/api'
import { getLanguageNameFromValue } from '@a4b/ui/src/modules/admin/constants'

type EditClickedHandler = (item: ContentTag) => void
type ActiveDeactiveClickedHandler = (item: ContentTag) => void
type ViewScheduleClickedHandler = (item: ContentTag) => void
export interface Props {
  tags: ContentTag[]
  loading: boolean
  total: number
  onEditClicked: EditClickedHandler
  onActiveDeactiveClicked: ActiveDeactiveClickedHandler
  onViewScheduleClicked: ViewScheduleClickedHandler
  onPageChanged: (page: number, pageSize: number) => void
}

const getColumns = (
  onEditClicked: EditClickedHandler,
  onActiveDeactiveClicked: ActiveDeactiveClickedHandler,
  onViewScheduleClicked: ViewScheduleClickedHandler,
) => [
    {
      title: 'image',
      dataIndex: 'image',
      key: 'image',
      render: (image: string) => {
        return <Image src={image} width={50} preview={false} />
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Language',
      dataIndex: 'language', // convert to readble name 
      key: 'language',
      render: (language: string) => {
        return <span>{getLanguageNameFromValue(language)}</span>
      }
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (active: boolean) => {
        return <div>{active.toString()}</div>
      },
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (createdAt: number) => {
        return <div>{moment(createdAt).format('LLLL')}</div>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      render: (id: string, item: ContentTag) => {
        return (
          <Space>
            <Button type='primary' onClick={onEditClicked.bind(this, item)}>
              Edit
            </Button>
            <Button onClick={onViewScheduleClicked.bind(this, item)}>
              View Schedule
            </Button>
            <Popconfirm
              title={`Are you sure to ${item.active ? 'Deactivate' : 'Activate'
                } this tag?`}
              onConfirm={onActiveDeactiveClicked.bind(this, item)}
              okText='Yes'
              cancelText='No'
            >
              <Button>{item.active ? 'Deactivate' : 'Activate'}</Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

export const TagListing: React.FC<Props> = ({
  loading,
  tags,
  total,
  onEditClicked,
  onActiveDeactiveClicked,
  onViewScheduleClicked,
  onPageChanged,
}) => {
  const handleEditClicked = useCallback(
    (item: ContentTag) => {
      onEditClicked(item)
    },
    [onEditClicked],
  )
  const handleActiveDeactiveClicked = useCallback(
    (item: ContentTag) => {
      onActiveDeactiveClicked(item)
    },
    [onActiveDeactiveClicked],
  )

  const handleViewScheduleClicked = useCallback(
    (item: ContentTag) => {
      onViewScheduleClicked(item)
    },
    [onViewScheduleClicked],
  )

  const columns = useMemo(
    () =>
      getColumns(
        handleEditClicked,
        handleActiveDeactiveClicked,
        handleViewScheduleClicked,
      ),
    [onActiveDeactiveClicked, onEditClicked],
  )

  return (
    <Table
      bordered
      loading={loading}
      columns={columns}
      dataSource={tags}
      rowKey={record => record.id}
      pagination={{
        hideOnSinglePage: true,
        onChange: onPageChanged,
        total: total,
      }}
    />
  )
}

export default TagListing
