import { Form, FormInstance } from "antd";
import { useCallback, useEffect, useState } from "react";
import { openErrorNotification, openNotification } from "../../utils";
import _ from "lodash";
import { Store } from "antd/lib/form/interface";
/*
    This component is experimental to improve Form reuseability as we create lot of forms in admin panel. 
    Don't use this to lot of places until this is stable. 
*/
export interface AntdFormWrapperProps<TypeFormData, TypeFormFields> {
    FormFields: React.ComponentType<{ form: FormInstance<TypeFormFields>, formData?: TypeFormData, isTouched?: boolean, initValues?: any }>,
    createApi?: (payload: TypeFormFields) => Promise<any>,
    updateApi?(payload: TypeFormFields): Promise<any>,
    updateApiId?(id: string, payload: TypeFormFields): Promise<any>,
    formData?: TypeFormData,
    extractFilePaths?: string[] // Please note if there is a array index in path it will matched by (*). Ex - god_sets.*.image_path (god_sets is a Array )
    submitSuccessfulCallback?: () => void
    syncIsFormTouched?: React.Dispatch<React.SetStateAction<boolean>> | undefined,
    initValues?: any
}

const AntdFormWrapper = function <TypeFormData, TypeFormFeilds>(props: AntdFormWrapperProps<TypeFormData, TypeFormFeilds>) {
    const { FormFields, createApi, updateApi, updateApiId, formData, extractFilePaths, submitSuccessfulCallback: submitSucessfulCallback, syncIsFormTouched, initValues } = props;
    const [form] = Form.useForm<TypeFormFeilds>();
    const [isTouched, setIsTouched] = useState(false);

    useEffect(() => {
        if (syncIsFormTouched) {
            syncIsFormTouched(isTouched)
        }
    }, [isTouched, syncIsFormTouched]);

    const createUpdate = useCallback(async (values: TypeFormFeilds) => {
        const convertFileObjectToPath = (data: any, path = '', matchPath = '') => {
            if (extractFilePaths?.includes(matchPath)) {
                //@ts-ignore
                _.set(values, path, _.get(values, path + '.0.response.path'))
                return
            }
            if (Array.isArray(data)) {
                data.forEach((item, i) => {
                    convertFileObjectToPath(item, path + `.${i}`, matchPath + '.*')
                })
                return
            }
            if (data && typeof data === 'object') {
                Object.keys(data).forEach((key) => {
                    convertFileObjectToPath(data[key], path === '' ? path + key : path + `.${key}`, matchPath === '' ? matchPath + key : matchPath + `.${key}`)
                })
            }
        }
        if (extractFilePaths && extractFilePaths.length > 0) {
            convertFileObjectToPath(values, '', '');
        }

        if (formData) {
            try {
                if (updateApi) {
                    await updateApi(values)
                } else {
                    if (updateApiId) {
                        // @ts-ignore  // TODO - need to do more investigation 
                        await updateApiId(formData.id, values)
                    } else {
                        throw Error("For Edit flow updateApiId is required!")
                    }
                }

                openNotification('success', { message: "Updated successfully" })
                if (submitSucessfulCallback) {
                    setTimeout(() => {
                        submitSucessfulCallback();
                    }, 200)
                }
            } catch (error) {
                console.log({ error: error })
                openNotification('error', { message: "Failed to update", description: (error as string).toString() })
                openErrorNotification(error);
            }
        } else {
            try {
                if (createApi) {
                    await createApi(values);
                    if (submitSucessfulCallback) {
                        setTimeout(() => {
                            submitSucessfulCallback();
                        }, 200)
                    }
                } else {
                    throw Error("For create flow createApi is required!")
                }
                openNotification('success', { message: "Created sucessfully" })
            } catch (error: any) {
                console.log({ error })
                openNotification('error', { message: "Failed to create", description: error?.response?.data?.data?.error?.message })
                openErrorNotification(error);
            }
        }
    }, [createApi, extractFilePaths, formData, submitSucessfulCallback, updateApi, updateApiId])

    useEffect(() => {
        if (formData) {
            // @ts-ignore  // TODO - need to do more investigation 
            form.setFieldsValue(formData);
        }
    }, [formData, form])

    return <div>
        <Form
            form={form}
            onFinish={createUpdate}
            layout="vertical"
            onFieldsChange={() => {
                setIsTouched(true);
            }}
            initialValues={initValues}
        >
            <FormFields initValues={initValues} form={form} formData={formData} isTouched={isTouched}></FormFields>
        </Form>
    </div>
}

export default AntdFormWrapper;