import {
    Badge,
    Button,
    Card,
    Col,
    Popconfirm,
    Row,
    Select,
    Space,
    Switch,
    Table,
} from 'antd'
import styled from 'styled-components'

import React, { useEffect, useState } from 'react'

import { AstroDetails } from '@a4b/api/src/modules/Monetisation/Astrologer/types'

interface Props {
    astroList: AstroDetails[]
    onSubmit: (value: any) => void
}

const { Option } = Select

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`
const ManageAstrologerStatus: React.FC<Props> = ({ astroList, onSubmit }) => {


    const handleChangeStatus = (astro: AstroDetails) => {
        onSubmit({ ...astro, status: astro?.status === "online" ? "offline" : "online" })
    }

    const cancel = (e: React.MouseEvent<HTMLElement>) => {
        console.log(e)
        // message.error('Click on No');
    }
    return (
        <Space direction='vertical' size={16}>
            {astroList?.map((astro: AstroDetails) => (
                <Badge.Ribbon key={astro?.id} text={astro?.status === "online" ? "Online" : "Offline"} color={astro?.status === "online" ? "green" : "red"}>
                    <Card

                        /* bodyStyle={{
                          backgroundColor: astro?.status === 'online' ? '#adebad' : '#ffcccc',
                        }}
                        headStyle={{
                          backgroundColor: astro?.status === 'online' ? '#adebad' : '#ffcccc',
                        }} */
                        title={astro?.name}
                        style={{ width: 300 }}

                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                            }}
                        >
                            Phone:
                            <span
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    padding: '12px',
                                }}
                            >
                                {astro?.phone}
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                                paddingTop: "8px"
                            }}
                        >
                            Change your Status:
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    paddingLeft: '12px',
                                }}
                            >
                                <Popconfirm
                                    title={
                                        astro?.status === 'online'
                                            ? 'Are you sure to go offline?'
                                            : 'Are you sure to go online?'
                                    }
                                    onConfirm={() => handleChangeStatus(astro)}
                                    okText='Yes'
                                    cancelText='No'
                                >
                                    <Switch checked={astro?.status === 'online' ? true : false} />
                                </Popconfirm>
                            </div>
                        </div>

                    </Card>
                </Badge.Ribbon>
            ))}
        </Space>
    )
}

export default ManageAstrologerStatus
