import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { debounce } from 'lodash'
import moment from 'moment'

import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
  CreateEditSchedule,
  CreateEditScheduleFormFields,
  SortableItemData,
} from '@a4b/ui/src/admin'

import { CreateScheduleOptions, ScheduleType, ContentTag, LibraryContent } from '@a4b/api'

export const TagContentScheduleCreateEdit = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { tagId, scheduleId } = useParams()
  const [tagData, setTagData] = useState<ContentTag>()
  const [searchItems, setSearchItems] = useState<SortableItemData[]>([])
  const [orderingItems, setOrderingItems] = useState<SortableItemData[]>([])
  const [date, setDate] = useState(searchParams.get('date') as string)
  const [language, setLanguage] = useState<string>(searchParams.get('language') as string)
  const { networkInstance, userProfile } = useAppContext()

  useEffect(() => {
    async function fetchScheduleDetails() {
      if (!scheduleId) {
        throw new Error("ScheduleId can't be empty")
      }
      const { data } =
        await networkInstance.clientWithHeaders.contentApi.schedule.getScheduleById(
          scheduleId,
        )
      const scheduleData = data.data
      const { data: libraryData } =
        await networkInstance.clientWithHeaders.contentApi.library.getLibraryContent({
          ids: data.data.ordering,
          limit: data.data.ordering.length
        })
      setDate(moment(scheduleData.date).format('YYYY-MM-DD'))
      const orderingItems = scheduleData.ordering.map((order: string, index: number) => {
        const orderItem = libraryData?.data.libraries?.find((item) => item.id === order)
        if (!orderItem) return null
        return {
          id: orderItem.id,
          imageUrl: orderItem.thumbnailId,
          name: orderItem.name,
        }
      }).filter((item: SortableItemData | null) => item !== null) as SortableItemData[]
      setOrderingItems(orderingItems)
    }
    if (scheduleId) fetchScheduleDetails()
  }, [
    networkInstance.clientWithHeaders.contentApi.schedule,
    networkInstance.clientWithHeaders.contentApi.library,
    scheduleId,
  ])

  useEffect(() => {
    async function initTag() {
      if (!tagId) {
        return
      }

      const { data } = await networkInstance.clientWithHeaders.contentApi.tag.getTagById(
        tagId,
      )
      setTagData(data.data)
    }

    if (tagId) initTag()
  }, [networkInstance.clientWithHeaders.contentApi.tag, tagId])

  const onItemSearch = useCallback(
    async (value: string, offset?: number) => {
      const tags = tagData ? [tagData.id] : []
      const { data } =
        await networkInstance.clientWithHeaders.contentApi.library.getLibraryContent({
          name: value,
          tags,
          status: ['PUBLISHED'],
          limit: 30,
          offset: (offset || 0) * 30,
        })
      const libraries = data.data.libraries
      const searchItemData: SortableItemData[] = libraries.map(library => ({
        id: library.id,
        name: library.name,
        imageUrl: library.thumbnailId,
      }))
      setSearchItems(searchItemData)
    },
    [networkInstance.clientWithHeaders.contentApi.library, tagData],
  )

  useEffect(() => {
    if (tagData) onItemSearch('')
  }, [tagData, onItemSearch])

  const handleAddItem = async (item: SortableItemData) => {
    const itemIndex = orderingItems.findIndex(
      orderItem => orderItem.id === item.id,
    )
    if (itemIndex === -1) {
      orderingItems.push(item)
      setOrderingItems([...orderingItems])
      return true
    }
    return false
  }

  const handleRemoveItem = (item: SortableItemData) => {
    const updatedOrderingItems = orderingItems.filter(
      orderingItem => orderingItem.id !== item.id,
    )
    setOrderingItems(updatedOrderingItems)
  }

  const getFormData = (): Partial<CreateEditScheduleFormFields> => {
    return {
      name: `Tag content schedule for ${moment(date).format('DD-MMM-YYYY')}`,
      date: moment(date),
      ordering: orderingItems,
    }
  }

  const handleSubmitSchedule = async (values: CreateEditScheduleFormFields) => {
    const date = moment().format('YYYY-MM-DD') === values.date.format('YYYY-MM-DD')
      ? moment().add(1, 'minute').toISOString()
      : values.date.startOf('day').toISOString()
    const options: CreateScheduleOptions = {
      name: values.name,
      date,
      ordering: values.ordering.map(item => item.id),
      entity: ScheduleType.TAG_CONTENT_SCHEDULE,
      parentId: tagId,
      createdBy: userProfile.email || '',
      updatedBy: userProfile.email || '',
      language: language
    }

    if (!scheduleId) {
      try {
        const { status, data } =
          await networkInstance.clientWithHeaders.contentApi.schedule.createSchedule(
            options,
          )
        if (status === 201) {
          navigate(`/content/tag-content-schedule/${tagId}`)
          const skippedLibraries = data.data.skippedLibraries
          if (skippedLibraries) {
            return { success: true, message: `Successfully created schedule, skipped ${skippedLibraries}` };
          }
          return { success: true, message: 'Successfully created schedule' };
        }
        return { success: false, message: 'Failed to create schedule' };
      } catch (error) {
        return { success: false, message: 'Failed to create schedule' };
      }
    } else {
      try {
        const { status } =
          await networkInstance.clientWithHeaders.contentApi.schedule.updateSchedule(
            scheduleId,
            options,
          )
        if (status === 204) {
          navigate(`/content/tag-content-schedule/${tagId}`)
          return { success: true, message: 'Successfully updated schedule' };
        }
        return { success: false, message: 'Failed to update schedule' };
      } catch (error) {
        return { success: false, message: 'Failed to update schedule' };
      }
    }
  }

  const itemSearchDebounce = debounce(onItemSearch, 1000)

  return (
    <CreateEditSchedule
      date={date}
      title={`Schedule for ${tagData ? tagData.name : ''} tag`}
      data={getFormData()}
      items={searchItems}
      onSearch={itemSearchDebounce}
      onAddItem={handleAddItem}
      onRemoveItem={handleRemoveItem}
      onSubmit={handleSubmitSchedule}
    />
  )
}

export default withDefaultLayout(TagContentScheduleCreateEdit)
