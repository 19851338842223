import { Button, Col, Modal, Row } from 'antd'

import { Batch } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import ReactPlayer from 'react-player'
interface Props {
    visible: boolean
    closeModal: () => void
    handleDoneQc: () => void
    videoUrl: string
    batchData: Batch | undefined
    okTitle: string
    closeTitle: string
    title: string
    isPujaService: boolean
}
const CompletedPreviewModal: React.FC<Props> = ({
    visible,
    handleDoneQc,
    closeModal,
    videoUrl,
    batchData,
    okTitle,
    closeTitle,
    title,
    isPujaService
}) => {
    const footer = []
    const youtubeUrl: string = 'https://www.youtube.com/'

    if (closeTitle) {
        footer.push(<Button key='cancel' onClick={closeModal}>
            {closeTitle}
        </Button>)
    }
    if (okTitle) {
        footer.push(<Button key='done' type='primary' onClick={handleDoneQc}>
            {okTitle}
        </Button>)
    }
    return (
        <Modal
            title={title}
            centered
            closable={true}
            visible={visible}
            onCancel={closeModal}
            width={400}
            destroyOnClose={true}
            footer={footer}
        >
            <Row justify='center' >
                <Col span={24} style={{ justifySelf: "center" }}>
                    {videoUrl.match(youtubeUrl) ? (
                        <ReactPlayer
                            url={videoUrl}
                            width='100%'
                            height='100%'
                        />
                    ) : <video width='340' height='600' controls>
                        <source src={videoUrl} type='video/mp4'></source>
                    </video>}
                </Col>
            </Row>
        </Modal>
    )
}

export default CompletedPreviewModal
