import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import DeletableContainer from "../deletableContainer/DeletableContainer";
import { MenuNotificationInstance } from "@/entities/feeds/Widgets";

interface Props {
  contentInstance: MenuNotificationInstance;
}
const MenuNotificationWidget: React.FC<Props> = ({ contentInstance }) => {
  return (
    <Row>
      <Col span={24}>
        <Form.List name={["content", "items"]}>
          {(fields, { add, remove, move }) => {
            return (
              <div>
                <Row justify="start">
                  {fields.map((field: any, index: number) => {
                    return (
                      <Col key={field.key} span={14} style={{ margin: "20px" }}>
                        <DeletableContainer
                          closeHandler={() => {
                            remove(field.name);
                          }}
                          itemText={`Item Attribute : ${index + 1}`}
                          showItemTextInBold={true}
                          moveFunction={move}
                          fields={fields}
                          currentIndex={index}
                          isInnerContainer={true}
                          fieldsLength={fields.length}
                          move={move}
                          add={add}
                        >
                          <Form.Item
                            {...field}
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 24 }}
                            required={true}
                            label="Title"
                            name={[field.name, "title"]}
                            rules={[
                              {
                                validator: contentInstance.titleValidator,
                              },
                            ]}
                          >
                            <Input placeholder="Title" />
                          </Form.Item>
                        </DeletableContainer>
                      </Col>
                    );
                  })}
                </Row>
                <Form.Item>
                  <Row>
                    <Col span={24}>
                      <Row justify="end">
                        <Col span={4}>
                          <Button
                            onClick={() => {
                              add();
                            }}
                            type="dashed"
                            icon={<PlusCircleOutlined />}
                            size="middle"
                          >
                            Add Item Attributes
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Col>
    </Row>
  );
};

export default MenuNotificationWidget;
