import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { Response } from '../../../types'
import {
  AudioContentQueryParams,
  AudioData,
  AudioMetaDataUpdateOptions,
  AudioPlaylist,
  AudioPlaylistCreateUpdateOptions,
  AudioPlaylistQueryParams,
  AudioSearchParams,
  AudioTag,
  AudioTagQueryParams,
  AudioThumbnail,
  AudioThumbnailQueryParams,
  AudioUploadCSVOptions,
  AudioUploadThumbnailOptions,
  BulkAudioStateUpdateOptions,
  Music,
  VideoTemplateRes,
} from './types'
import { apiClient } from '../../../apiClient'


class AudioApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getAudio = (params: AudioContentQueryParams) =>
    this.api.get<Response<{ count: number; audios: Music.Audio[] }>>(
      `/content/internal/v1/audio`,
      {
        params,
      },
    )

  getAudioDataCsv = (params: AudioContentQueryParams) => {
    return this.api.get<Response<{ message: string }>>('/content/internal/v1/audio/bulk_update', {
      params
    })
  }

  getAudioById = (id: string) =>
    this.api.get<Response<{ audio: AudioData }>>(
      `/content/internal/v1/audio/${id}`,
    )

  getSongUrl = (audioId: string) =>
    this.api.get<
      Response<{
        audio_id: number
        original_audio_url: string
        cdn_audio_url: string
        m3u8_audio_url: string
      }>
    >(`/content/internal/v1/audio/listen/${audioId}`)

  getVideoTemplates = (limit: number, offset: number, code?: string) => this.api.get<VideoTemplateRes>(
    `/content/internal/v1/audio/video_share_template?limit=${limit}&offset=${offset}&code=${code || ''}`,
  )

  updateAudioMetaData = (options: AudioMetaDataUpdateOptions) =>
    this.api.patch(`/content/internal/v1/audio/metadata`, options)

  transliterateAudio = (audioId: string) =>
    this.api.patch(`/content/internal/v1/audio/transliterate/${audioId}`)

  bulkUpdateAudioState = (options: BulkAudioStateUpdateOptions) =>
    this.api.patch(`/content/internal/v1/audio/state`, options)

  getUrlToUploadCSV = () =>
    this.api.get<Response<{ url: string; path: string }>>(
      `/content/internal/v1/audio/upload/csv`,
    )

  getUploadVedioTemplateUrl = () =>
    this.api.get<Response<{ url: string; path: string }>>(
      `/content/internal/v1/audio/upload/video_share`,
    )

  getUrlToAudioUpdateCSV = () =>
    this.api.get<Response<{ url: string; path: string }>>(
      `/content/internal/v1/audio/upload/bulk_audio_csv`,
    )

  getUrlToAudioUpload = () =>
    this.api.get<Response<{ url: string; path: string }>>(
      `/content/internal/v1/audio/upload/m3u8_conversion`,
    )

  uploadCsvPath = (options: AudioUploadCSVOptions) =>
    this.api.post(`/content/internal/v1/audio/csv`, options)


  updateAudioCsvPath = (options: AudioUploadCSVOptions) =>
    this.api.patch(`/content/internal/v1/audio/bulk_update`, options)

  getUrlToUploadThumbnail = () =>
    this.api.get<Response<{ url: string; path: string }>>(
      `/content/internal/v1/audio/upload/thumbnail`,
    )

  generatePresignedUrl = (typeStr: string) => this.api.get<Response<{ url: string; path: string }>>(
    `/content/internal/v1/audio/upload/${typeStr}`,
  )

  uploadThumbnail = (options: AudioUploadThumbnailOptions) =>
    this.api.post<{
      data: {
        "message": string
      }
    }>(`/content/internal/v1/audio/thumbnail`, options)

  getUrlToUploadAudioForConversion = (payload: {
    file_path: string,
    file_indicator: string
  }) => {
    return this.api.post<Response<{ path: string }>>('/content/internal/v1/audio/convert_to_m3u8', payload)
  }

  postVideoShareTemplate = (options: { code: string, path: string }) => {
    this.api.post<{
      data: {
        "message": string
      }
    }>(`/content/internal/v1/audio/video_share_template`, options)
  }

  getUrlToUploadLyrics = () =>
    this.api.get<Response<{ url: string; path: string }>>(
      `/content/internal/v1/audio/upload/lyrics`,
    )

  uploadLyrics = (options: AudioUploadCSVOptions) =>
    this.api.post(`/content/internal/v1/audio/csv`, options)

  getTagsForAudio = (params: AudioTagQueryParams) =>
    this.api.get<Response<{ tags: AudioTag[]; count: number }>>(
      `/content/internal/v1/audio/tags`,
      {
        params,
      },
    )

  getThumbnailAudio = (params: AudioThumbnailQueryParams) =>
    this.api.get<Response<{ thumbnails: AudioThumbnail[]; count: number }>>(
      `/content/internal/v1/audio/thumbnail`,
      {
        params,
      },
    )

  getAudioPlaylists = (params?: AudioPlaylistQueryParams) =>
    this.api.get<Response<{ playlists: AudioPlaylist[]; count: number }>>(
      `/content/internal/v1/audio/playlist`,
      {
        params,
      },
    )

  getAudioPlaylistById = (id: string) =>
    this.api.get<Response<{ playlist: AudioPlaylist }>>(
      `/content/internal/v1/audio/playlist/${id}`,
    )

  getAudiosForPlaylist = (id: string) =>
    this.api.get<Response<{ audios: AudioData[] }>>(
      `/content/internal/v1/audio/playlist/${id}/songs`,
    )

  searchAudios = (params: AudioSearchParams) =>
    this.api.get<Response<{ audios: Music.Audio[]; count: number }>>(
      `/content/internal/v1/audio/search`,
      { params },
    )

  createPlaylist = (options: AudioPlaylistCreateUpdateOptions) =>
    this.api.post(`/content/internal/v1/audio/playlist`, options)

  updatePlaylist = (
    id: string,
    options: Partial<AudioPlaylistCreateUpdateOptions>,
  ) => this.api.patch(`/content/internal/v1/audio/playlist/${id}`, options)

  getCsvPlaylistUpdateUrl = async () =>
    this.api.get<Response<{ url: string; path: string }>>('/content/internal/v1/audio/upload/bulk_playlist_update')



  getCsvPlaylistCreateUrl = async () =>
    this.api.get<Response<{ url: string; path: string }>>('/content/internal/v1/audio/upload/bulk_playlist_create')



  getBulkPlaylistUpdateData = async (language?: string, playlistType?: string) => {
    let queryParam = '?'
    if (language) {
      queryParam += `language_code=${language}&`
    }
    if (playlistType) {
      queryParam += `playlist_type=${playlistType}&`
    }
    this.api.get<Response<{ message: string }>>(`/content/internal/v1/audio/playlists/bulk_update${queryParam}`)
  }



  // getBulkPlaylistCreateCsv = async () =>
  //   this.api.get<GetBulkPlaylistCreateCsvRes>('/content/internal/v1/audio/playlists/bulk_create')




  bulkPlaylistCreate = (payload: { path: string }) =>
    this.api.post(
      '/content/internal/v1/audio/playlists/bulk_create',
      payload,
    )

  bulkPlaylistUpdate = (payload: { path: string }) =>
    this.api.put(
      '/content/internal/v1/audio/playlists/bulk_update',
      payload,
    )



}

export default AudioApi
