//context
import { useAppContext } from '@/components/AppContext'
//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'
//organisms
import CDNUpload from '@/ui/organisms/CDNUpload'
import { UPLOAD_CONTENT_URL, CDN_HOST, NEW_CDN_HOST } from '@/utils/constants'

import React from 'react'

interface Props { }

const CDNUploadScreen: React.FC<Props> = () => {
  return (
    <div style={{ padding: '0 40px' }}>
      <CDNUpload uploadUrl={UPLOAD_CONTENT_URL} cdnHost={NEW_CDN_HOST} />
    </div>
  )
}

export default withDefaultLayout(CDNUploadScreen)
