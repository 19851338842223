import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { PanchangRequest, PanchangV2Request } from '@a4b/ui/src/utils/enum'

import { ChogadiyaResponse } from './chogadiya.types'
import { HoraResponse } from './hora.types'
import { MonthlyPanchang } from './panchang_monthly.types.d'
import { Panchang, PanchangData, Response } from './panchang_v2.types.d'
import { RashifalResponse } from './rashifal.types.d'
import { apiClient } from '../../apiClient';


interface HoraResponseWrapper {
  data: HoraResponse
  [key: string]: any
}
interface ChogadiyaResponseWrapper {
  data: ChogadiyaResponse
  [key: string]: any
}

class CalendarApi {
  api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
    if (typeof window === 'undefined') {
      this.api?.interceptors?.request?.use((config: any) => {
        config.headers['Cookie'] = `${config.headers['Cookie'] ? config.headers['Cookie'] + ';' : ''
          }platform-type=vercel`
        return config
      })
    }
  }

  getCalendar = async (data: PanchangRequest): Promise<Panchang> => {
    const response = await this.api.post<Panchang>(
      '/expresso/mandir/api/calendar/advanced.php',
      data,
    )
    return response?.data
  }

  getPanchangV2 = (
    data: PanchangV2Request,
    acceptLanguage: string,
  ): Promise<Response<PanchangData>> => {
    return this.api.get('/gandalf/external/v1/panchang/daily', {
      params: data,
      headers: { 'accept-language': acceptLanguage },
      transformRequest: (data, headers) => {
        delete headers?.['x-afb-platform']
        return JSON.stringify(data)
      },
    })
  }

  getMonthlyPanchang = (
    data: PanchangV2Request,
    acceptLanguage: string,
  ): Promise<Response<MonthlyPanchang>> => {
    return this.api.get('/gandalf/external/v1/panchang/monthly', {
      params: data,
      headers: { 'accept-language': acceptLanguage },
      transformRequest: (data, headers) => {
        delete headers?.['x-afb-platform']
        return JSON.stringify(data)
      },
    })
  }

  getHora = async (): Promise<HoraResponseWrapper> => {
    const response = await this.api.get<HoraResponseWrapper>(
      '/expresso/mandir/api/calendar/static.php?cityId=1&resourceName=hora',
    )
    return response?.data
  }
  getChogadiya = async (): Promise<ChogadiyaResponseWrapper> => {
    const response = await this.api.get<ChogadiyaResponseWrapper>(
      '/expresso/mandir/api/calendar/static.php?cityId=1&resourceName=choghadiya',
    )
    return response?.data
  }
  getRashifal = async (): Promise<RashifalResponse> => {
    const response = await this.api.get<RashifalResponse>(
      '/expresso/mandir/api/panditKaksh/getZodiac.php',
    )
    return response?.data
  }
}

export default CalendarApi
