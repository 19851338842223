export interface Action {
    properties: any
    id: string  // no whitespace allowed 
    code: string
    rules: Rule[]
    type: 'ADD_TO_COHORT' | 'REMOVE_FROM_COHORT'
    callback_url: string
}

export enum ConditionType {
    EVAL = 'EVAL',
    AND = 'AND',
    OR = 'OR'
}


export interface Rule {
    id?: string
    code: string
    goal_count: number
    goal_count_unit: string
    condition: Condition
}

export type Condition = ConditionEVAL | ConditionAND | ConditionOR


export interface ConditionEVAL {
    __identifier?: string
    type: ConditionType.EVAL
    property: Property
}

export interface ConditionAND {
    __identifier?: string
    type: ConditionType.AND
    conditions: ConditionEVAL | ConditionOR[] | ConditionAND[]
}


export interface ConditionOR {
    __identifier?: string
    type: ConditionType.OR
    conditions: ConditionEVAL | ConditionOR[] | ConditionAND[]
}

export interface Property {
    path: string
    operator: string
    value_string: string
}

export interface ActionType {
    [key: string]: AddToMonetizationCohort
}

export interface AddToMonetizationCohort {
    event_name: string
    url: Url
    service_title: string
    required_keys: string[]
}

export interface Url {
    prod: string
    sbox: string
}

