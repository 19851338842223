import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../../components/AppContext";
import { Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import styled from "styled-components";
import { renderDateTime } from "../../../../../components/TableHelpers/ColumnHelper";
import { EVENT_STATE_COLOR_CODES } from "../utils/constants";

const StyledTr = styled.tr`
  width: fit-content;
  text-align: center;
  padding: 5px;
`
const StyledTable = styled.table`
  font-family: arial, sans-serif;
  width: 100%;
  font-size: 12px;
  table-layout: auto;
  border-collapse: collapse;
`

const StyledTd = styled.td`
  text-align: center;
  padding: 5px;
  word-wrap: break-word;  
  &.field {
    color: #595959;
    font-weight: 400;
  }
`

const StyledTdRed = styled.td`
  border: 5px solid #fff;
  text-align: left;
  background-color: #fff1f0;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
  padding-right: 5px;
  padding-left: 18px;
  text-align: right;
  position: relative;
  word-wrap: break-word;

  &::before {
    font-size: 10px;
    position: absolute;
    left: 0;
    top: 0;
    content: '-';
    display: flex;
    padding: 0px 2px;
    text-align: center;
    justify-content: center;
    background: #ffccc7;
    width: 15px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 100%;
    align-items: center;
  }
`

const StyledTdGreen = styled.td`
  text-align: left;
  border: 5px solid #fff;
  background-color: #f6ffed;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
  padding-right: 5px;
  padding-left: 18px;
  text-align: right;
  position: relative;
  word-wrap: break-word;

  &::before {
    font-size: 10px;
    position: absolute;
    left: 0;
    top: 0;
    content: '+';
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    padding: 0px 2px;
    text-align: center;
    justify-content: center;
    background: #d9f7be;
    width: 15px;
    height: 100%;
    align-items: center;
  }
`


const StyledTh = styled.td`
//   border: 1px solid #d9d9d9;
  padding: 4px;
  width: 100px;
  text-align: center;
  font-wight: 700;
  font-size: 13px;
  word-wrap: break-word;
`

const StyledTag = styled(Tag)`
  margin: 2px;
`

const renderValue = function (fieldName?: string, value?: string) {
    if (!fieldName) return " "
    if (fieldName === 'status') {
        // @ts-ignore
        return value ? <Tag color={EVENT_STATE_COLOR_CODES[value]}> {value}</Tag> : ''
    }
    if (fieldName === 'tags') {
        return value ? value.split(",").map((tag) => {
            return <StyledTag> {tag}</StyledTag>
        }) : ' '
    }
    if (fieldName === 'options') {
        return value ? value.split(",").map((tag) => {
            return <StyledTag > {tag}</StyledTag>
        }) : ' '
    }
    if (fieldName === 'destinations') {
        if (value == "") {
            return ""
        }
        return <pre style={{ textAlign: 'left' }}>
            {JSON.stringify(JSON.parse(
                value || ""), null, 4)}
        </pre>
    }
    if (fieldName === 'sub_properties') {
        if (value == "") {
            return ""
        }
        return <pre style={{ textAlign: 'left' }}>
            {JSON.stringify(JSON.parse(
                value || ""), null, 4)}
        </pre>
    }
    return value || ' '
}

export interface ChangeLogProps {
    definitionId: string
}
const ChangeLog = function (props: ChangeLogProps) {
    const { definitionId } = props;
    const { networkInstance } = useAppContext();
    const [changeLogs, setChangeLogs] = useState<any>();
    const getChangeLog = useCallback(async () => {
        try {
            const res = await networkInstance.clientWithHeaders.events.getChangeLog({
                filters: {
                    definition_id: definitionId
                }
            })
            setChangeLogs(res.data.data.change_logs)
        } catch (error) {

        }
    }, [definitionId, networkInstance.clientWithHeaders.events])

    useEffect(() => {
        getChangeLog();
    }, [getChangeLog])

    const columns: ColumnsType<any> = useMemo<ColumnsType<any>>(() => {
        return [
            {
                title: 'Timestamp',
                dataIndex: 'timestamp',
                render: renderDateTime,
                align: 'center',
                className: 'move-column-top',
                width: 200,
                ellipsis: true,
            },
            {
                title: 'Super property change',
                dataIndex: 'super_property_changes',
                render: (super_property_changes: any[], record, index) => {
                    return <>
                        <StyledTable >
                            {
                                super_property_changes?.length > 0 &&
                                <thead>
                                    <StyledTr>
                                        <StyledTh>Field name</StyledTh>
                                        <StyledTh> Old value </StyledTh>
                                        <StyledTh> New Value</StyledTh>
                                    </StyledTr>
                                    {super_property_changes?.map((item) => {
                                        return <StyledTr key={item.from?.field}>
                                            <StyledTd className="field">{item.from?.field}</StyledTd>
                                            <StyledTdRed>{renderValue(item.from?.field, item.from?.string_value)}</StyledTdRed>
                                            <StyledTdGreen>{renderValue(item.to?.field, item.to?.string_value)} </StyledTdGreen>
                                        </StyledTr>
                                    })}
                                </thead>
                            }
                        </StyledTable>
                    </>
                },
                align: 'center',
                width: 200,
                ellipsis: true,
                className: 'move-column-top'
            },
            {
                title: 'Property changes',
                dataIndex: 'property_changes',
                width: 200,
                ellipsis: true,
                render: (property_changes: any[], record, index) => {
                    return <>
                        <StyledTable >
                            {
                                property_changes?.length > 0 &&
                                <thead>
                                    <StyledTr>
                                        <StyledTh>Property name</StyledTh>
                                        <StyledTh>Field name</StyledTh>
                                        <StyledTh> Old value </StyledTh>
                                        <StyledTh> New Value</StyledTh>
                                        <StyledTh> Operation</StyledTh>
                                    </StyledTr>
                                    {property_changes?.map((item) => {
                                        return <StyledTr key={item.from?.field}>
                                            <StyledTd className="field">{item.name}</StyledTd>
                                            <StyledTd className="field">{item.to?.field}</StyledTd>
                                            <StyledTdRed>{renderValue(item.from?.field, item.from?.string_value)}</StyledTdRed>
                                            <StyledTdGreen>{renderValue(item.to?.field, item.to?.string_value)} </StyledTdGreen>
                                            <StyledTd>{item.operation} </StyledTd>
                                        </StyledTr>
                                    })}
                                </thead>
                            }
                        </StyledTable>
                    </>
                },
                align: 'center',
                className: 'move-column-top'
            },
            {
                title: 'Version',
                dataIndex: 'from_version',
                render: (from_version: string, record: any, index) => {
                    return <>
                        <StyledTable >
                            <thead>
                                <StyledTr>
                                    <StyledTh> From </StyledTh>
                                    <StyledTh> To</StyledTh>
                                </StyledTr>
                                <StyledTr >
                                    <StyledTdRed>{record.from_version}</StyledTdRed>
                                    <StyledTdGreen>{record.to_version}</StyledTdGreen>
                                </StyledTr>
                            </thead>
                        </StyledTable>
                    </>
                },
                align: 'center',
                className: 'move-column-top',
            },
            {
                title: 'Status',
                dataIndex: 'form_status',
                render: (form_status: string, record: any, index) => {
                    return <>
                        <StyledTable >
                            <thead>
                                <StyledTr>
                                    <StyledTh> From </StyledTh>
                                    <StyledTh> To</StyledTh>
                                </StyledTr>
                                <StyledTr >
                                    <StyledTdRed>{renderValue('status', record.from_status)}</StyledTdRed>
                                    <StyledTdGreen>{renderValue('status', record.to_status)}</StyledTdGreen>
                                </StyledTr>
                            </thead>
                        </StyledTable>
                    </>
                },
                align: 'center',
                className: 'move-column-top',
            },
            {
                title: 'Comment',
                dataIndex: 'comment',
                align: 'center',
                width: 200,
                ellipsis: true
            },
            {
                title: 'Changed by',
                dataIndex: 'changed_by',
                align: 'center',
                className: 'move-column-top',
            }
        ]
    }, [])

    return <div>
        <Table bordered={true} sticky={{ offsetHeader: -25 }} dataSource={changeLogs} columns={columns} size="small" scroll={{ x: true }} className="change-log" />
    </div>
}

export default ChangeLog;