import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button, Segmented } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination from "@/hooks/useAntdPagination";
import { GetRechargePackageRes } from "@a4b/api/src/modules/Wallet/types";
import { SEGMENT } from "./components/utils/types";
import { A4B_APPS } from "@/utils/constants";
import WalletRechargePackages from "@a4b/api/src/modules/Wallet/Wallet";

interface RechargePackagesProps {
}

const RechargePackages: React.FC<RechargePackagesProps> = () => {
  const { networkInstance, app, countryState } = useAppContext();
  const { wallet, walletAstro } = networkInstance.clientWithHeaders
  const api: WalletRechargePackages = (app === A4B_APPS.SRIMANDIR) ? wallet : walletAstro
  const isSrimandir = app === A4B_APPS.SRIMANDIR
  const countryCode = app !== A4B_APPS.SRIMANDIR ? 'US' : countryState?.countryCode

  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const { pagination, setPagination } = useAntdPagination();
  const [rechargePackages, setRechargePackages] = useState<GetRechargePackageRes[]>([])
  const [rechargePackageId, setRechargePackageId] = useState<string>()
  const [segment, setSegment] = useState<SEGMENT>(SEGMENT.CONTROLED)


  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }
  const onUpdateRechargePackage = () => {
    setActiveDrawer(DRAWER_TYPE.UPDATE_RECHARGE_PACKAGE);
  }

  const onDeleteRechargePackage = () => {
    setActiveDrawer(DRAWER_TYPE.UPDATE_RECHARGE_PACKAGE);
  }

  const getActiveRechargePackages = useCallback(async () => {
    try {
      const res = await api.getRechargePackage(segment === SEGMENT.VARIANT, countryCode)
      setRechargePackages(res.data.data.sort((a: any, b: any) => a.order - b.order) as unknown as GetRechargePackageRes[])
    } catch (error) {

    }
  }, [api, countryCode, segment])

  const tableConfig = useTableConfig(getActiveRechargePackages, setRechargePackageId,
    onUpdateRechargePackage, rechargePackages,
    pagination, setPagination, setDrawer,);


  const onCreateRechargePackage = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_RECHARGE_PACKAGE);
  }

  const drawerConfig = useDrawerConfig(
    setActiveDrawer,
    getActiveRechargePackages,
    segment,
    rechargePackages.find(
      (e) => { return (e.id == rechargePackageId) }
    ),
    activeDrawer,
  )
  const { drawerProps, drawerContent } = drawerConfig;

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  }

  useEffect(() => {
    getActiveRechargePackages()
  }, [getActiveRechargePackages])

  return <div style={{ marginTop: '40px' }}>
    <SocialScreenContainer
      title={
        <div>
          Recharge Packages
          <div style={{ marginTop: "10px" }}>
            <Segmented options={[SEGMENT.CONTROLED, SEGMENT.VARIANT]} value={segment} onChange={(value) => { setSegment(value as SEGMENT) }} disabled={!isSrimandir}></Segmented>
          </div>
        </div>
      }
      filtersUI={<Filters />}
      extra={<Button onClick={onCreateRechargePackage}> Create Recharge Package </Button>}
      tableProps={tableConfig}
      drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
      drawerContent={drawerContent}
    />
  </div>
}

export default withDefaultLayout(RechargePackages)
