import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import AntdFormWrapper from "../../../../../components/Forms/AntdFormWrapper";
import { FormInstance } from "antd";
import CreateNudge from "../components/CreateNudge";
import { useAppContext } from "../../../../../components/AppContext";
import ViewNudge from "../components/ViewNudge";
import { NudgeData } from "../utils/types";

// This interface is just a example for AntdFormWrapper 
interface TestComProps {
    form: FormInstance<string>
}

export enum DRAWER_TYPE {
    CREATE_NUDGE = ' Create nudge',
    UPDATE_NUDGE = ' Update nudge',
    VIEW_NUDGE = ' View nudge',
}



export default function useDrawerConfig(
    loadNudges: () => Promise<void>,
    setActiveDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | undefined>>,
    activeDrawer?: DRAWER_TYPE,
    nudge?: NudgeData,
): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {
    const { networkInstance } = useAppContext()
    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_NUDGE,
                width: 1000,
            },
            drawerContent: <AntdFormWrapper<any, any>
                submitSuccessfulCallback={() => {
                    setActiveDrawer(undefined);
                    loadNudges();
                }}
                createApi={networkInstance.clientWithHeaders.inAppNudge.postNudge}
                FormFields={CreateNudge}
            />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.UPDATE_NUDGE,
                width: 1000,
            },
            drawerContent: <AntdFormWrapper<any, any>
                submitSuccessfulCallback={() => {
                    setActiveDrawer(undefined);
                    loadNudges();
                }}
                formData={nudge}
                updateApi={networkInstance.clientWithHeaders.inAppNudge.putNudge}
                FormFields={CreateNudge}
            />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.VIEW_NUDGE,
                width: '80vw',
            },
            drawerContent: nudge && <ViewNudge nudge={nudge} />
        },
    ], [networkInstance.clientWithHeaders.inAppNudge.postNudge, networkInstance.clientWithHeaders.inAppNudge.putNudge, nudge])
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}