/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import { PlusSquareOutlined } from '@ant-design/icons'
import { Button, Card, Col, Image, Row, Space, Table } from 'antd'
import { Product } from '@a4b/api/src/modules/Monetisation/Puja/types'

interface Props {
  onCreateNew: () => void
  onStatusChange: (status: boolean) => void
  triggerEdit: (temple: Product) => void
  triggerDelete: (temple: Product) => void
  dataSource: any[]
  rowSelectionConfig: any
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const ManageItemsPage: React.FC<Props> = ({
  onCreateNew,
  triggerEdit,
  triggerDelete,
  dataSource,
}) => {
  const dataColumns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      width: '16%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '20%',
      ellipsis: true,
      render: (text: any,) => {
        return <span>{text}</span>
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Item type',
      dataIndex: 'item_type',
      key: 'item_type',
      width: '10%',
    },
    {
      title: 'Item Image',
      dataIndex: 'image_url',
      key: 'image_url',
      width: '12%',
      render: (text: any,) => {
        return <Image width={50} height={50} src={text}></Image>
      },
    },
    {
      title: 'Bottom Sheet Image',
      dataIndex: 'bottomsheet_image_url',
      key: 'bottomsheet_image_url',
      width: '12%',
      render: (text: any,) => {
        return <Image width={50} height={50} src={text}></Image>
      },
    },

    {
      title: 'Action',
      key: 'action',
      width: '15%',
      render: (text: any, record: any,) => (
        <Row>
          <Col span={12}>
            <Button type='link' onClick={() => triggerEdit(record)}>
              Edit
            </Button>
          </Col>
          <Col span={12}>
            <Button type='link' onClick={() => triggerDelete(record)} disabled>
              Delete
            </Button>
          </Col>
        </Row>
      ),
    },
  ]
  return (
    <Container title='Manage Products' style={{ width: '100%' }}>
      <Space direction={'vertical'} style={{ width: '100%' }}></Space>
      <BodyContainer>
        <Button type='text' onClick={onCreateNew}>
          <PlusSquareOutlined /> Add New Product
        </Button>

        <Table
          columns={dataColumns}
          dataSource={dataSource}
          pagination={{ defaultPageSize: 8 }}
        />
      </BodyContainer>
    </Container>
  )
}

export default ManageItemsPage
