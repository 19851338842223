export const SHARE_TEXT_CONFIG = {
    "data": {
        "platform_type": [
            "WEB_ANDROID",
            "WEB_IOS",
            "WEB",
            "ANDROID",
            "BACKEND",
            "IOS",
            "KAIOS"
        ],
        "available_languages": [
            "kn",
            "te",
            "hi-bj",
            "ta",
            "en-GB",
            "en",
            "gu",
            "hi",
            "ml",
            "bn",
            "hi-hr",
            "hi-rj",
            "mr"
        ],
        "package_type": [
            "internal",
            "external"
        ],
        "attachment_type": [
            "jpeg",
            "jpg",
            "png",
            "gif",
            "mp4",
            "mp3"
        ],
        "deeplink_provider": [
            "BRANCH_IO",
            "FIREBASE"
        ],
        "assets": [
            "app_png",
            "app_jpeg",
            "app_jpg",
            "text_png",
            "text_jpeg",
            "text_jpg",
            "text_gif",
            "text_mp4"
        ],
        "media_type": [
            "IMAGE",
            "VIDEO"
        ],
        "allowed_active_app_share_text_length": {
            "03fa25c4-068f-4552-a016-6b0e3eff8fcb": 560,
            "059b3e39-1427-49d5-911d-a82e8a994d3b": 560,
            "0e6132ff-756a-4d32-9096-7101ce0b0ddf": 560,
            "1e0934f0-b2a8-4a5d-8cc9-a60b0fa8d4d2": 560,
            "2aa86514-1cf6-4140-9846-f7a04bbfac21": 560,
            "2e2da3c3-e35d-4140-a9e4-85a6c8fc093a": 560,
            "3159e5b1-92b7-4e2f-b0f5-2736702e5d15": 560,
            "3521b7ea-d1ce-4705-bd36-42acdb54cac5": 560,
            "3d916ebd-193f-4503-8a1e-ee0bf6d239f9": 560,
            "55481050-7df6-4766-9e11-d41fb178fd71": 560,
            "596778d3-ffc5-4d8a-8d8a-a166afbe8e3f": 560,
            "84a05d46-e88d-4b70-9df9-b4ff550a6fb3": 560,
            "a154a1ed-64f9-4302-b751-95964eb0edc8": 560,
            "a80a942a-9dc1-4f90-bdfb-2c1b6b1d235f": 560,
            "a93035c5-a4df-4441-9b12-316f8b447df3": 560,
            "b80d82f6-c6c4-464c-99bc-655ace8a20d0": 560,
            "d048c3d5-e58a-441a-a8fd-0288682509a4": 560,
            "e5689374-8eb0-4eb1-bfd2-881e1614c34b": 560,
            "eb0d4ede-a3aa-451f-9d19-287f617b5ef2": 560,
            "ebcbfb3f-c3ed-4f09-89ee-64cdf9a1094f": 560,
            "f04463a3-c6d1-4805-bb13-018645fbb1cf": 560,
            "fafce2b3-fe2a-4e97-92cf-669161889eec": 560
        }
    }
}