import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import ManageItemsPage from '@a4b/ui/src/modules/monetisation/organisms/ManageItemsPage'
import { Product } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import CreateItem from '../CreateItem'

const getPageBreadcrumbs = () => [
  {
    path: '/monetisation/offerings/home-page',
    breadcrumbName: 'Home page',
  },
  {
    path: '/monetisation/offerings/items',
    breadcrumbName: 'Manage Products',
  },
]

const ManageItemScreen: React.FC = () => {
  const { networkInstance } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders
  const [showItemCreation, setShowItemCreation] = React.useState(false)
  const [isEditJourney, setIsEditJourney] = React.useState(false)
  const [productList, setProductList] = useState<Product[]>([])
  const [product, setProduct] = useState<Product>()
  const rowSelectionChange = (selectedRowKeys: any) => {
    setRowSelectionConfig({
      ...rowSelectionConfig,
      selectedRowKeys: [...selectedRowKeys],
    })
  }

  const [rowSelectionConfig, setRowSelectionConfig] = useState<any>({
    selectedRowKeys: [],
    onChange: rowSelectionChange,
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const getProductList = async () => {
    try {
      const productList = await monetisationOfferingsApi.productApi.getProductList({
        limit: 500,
        offset: 0,
      })

      setProductList(productList?.data?.data)
    } catch (error) {
      message.error({
        content: 'Error while fetching product list',
        duration: 3,
      })
      console.log(error)
    }
  }

  useEffect(() => {
    getProductList()
  }, [])

  useEffect(() => {
    searchParams.get('isCreateFlow') === "true" ? setShowItemCreation(true) : setShowItemCreation(false)
  }, [])

  const onCreateNew = () => {
    setShowItemCreation(true)
    setSearchParams(
      createSearchParams({
        isCreateFlow: "true"
      })
    )
    setIsEditJourney(false)
    setProduct(undefined)
  }

  const onFinishFailed = (error: any) => {
    //console.log('Item Creation Form Error==>', error)
  }

  const showItemForm = () => {
    setShowItemCreation(true)
  }

  const closeItemCreationForm = () => {
    setShowItemCreation(false)
    setSearchParams(
      createSearchParams({
        isCreateFlow: "false"
      })
    )
    isEditJourney && setIsEditJourney(false)
    setProduct(undefined)
    getProductList()
  }

  const onStatusChange = (status: boolean) => { }

  const triggerEdit = (temple: Product) => {
    setProduct(temple)
    setIsEditJourney(true)
    setShowItemCreation(true)
  }

  const triggerDelete = (temple: Product) => { }
  const onItemCreateHandler = () => {
    getProductList()
  }

  return (
    <div style={{ padding: '0 40px' }}>
      <AdminBreadcrumb routes={getPageBreadcrumbs()} />
      <ManageItemsPage
        onCreateNew={onCreateNew}
        onStatusChange={onStatusChange}
        triggerEdit={triggerEdit}
        triggerDelete={triggerDelete}
        dataSource={productList}
        rowSelectionConfig={rowSelectionConfig}
      />
      {
        <CreateItem
          showItemCreation={showItemCreation}
          closeItemCreation={closeItemCreationForm}
          createItemCallback={onItemCreateHandler}
          UpdateItemCallback={onFinishFailed}
          isEditJourney={isEditJourney}
          formData={product}
        />
      }
    </div>
  )
}

export default withDefaultLayout(ManageItemScreen)
