import { Alert, Button, Col, Form, FormInstance, Input, InputNumber, Row, Slider, message } from "antd";
import styled from "styled-components";
import { ONLY_ALPHA_NUMARIC_RULE, REQUIRED_RULE } from "../../../../../utils/form.validation";
import TextArea from "antd/lib/input/TextArea";
import { useAppContext } from "../../../../../components/AppContext";
import { UpdateQuizPayload } from "@a4b/api/src/modules/Coins/types";
import { CloseCircleFilled, CloseCircleOutlined, DeleteColumnOutlined, DeleteOutlined } from "@ant-design/icons";
import Chart from "react-google-charts";
import { useMemo } from "react";


const StyledDiv = styled.div`
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
    & > div {
        border-bottom: 1px solid #ccc;
    }
    & > div:last-child{
        border-bottom: none;
    }
    &::before {
        content: "Variants";
        position: absolute;
        background: #fff;
        top: -12px;
        padding: 0px 10px;
    }
`

const SubmitButton = styled(Button)`
    position: absolute;
    top: 10px;
    right: 10px;
`

export const data = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
];

export const options = {
};

interface AddQuizProps {
    form: FormInstance<UpdateQuizPayload>,
    formData?: UpdateQuizPayload
}

const AddExperiments = function (props: AddQuizProps) {
    const { form, formData } = props;
    const { userProfile } = useAppContext();
    const variants = Form.useWatch('variants', form);

    const variantsDistribution = useMemo(() => {
        const distribution: [[string, string | number]] = [["Task", ""]]
        const firstCodeIndex: { [key: string]: number } = {}
        let index = 1;
        variants?.forEach((variant: any) => {
            if (firstCodeIndex.hasOwnProperty(variant.code)) {
                distribution[firstCodeIndex[variant.code]][1] = distribution[firstCodeIndex[variant.code]][1] + variant.lte - variant.gte + 1
            } else {
                const code = variant.code || "Code" + (index + 1)
                firstCodeIndex[code] = index;
                index += 1
                distribution.push([code, variant.lte - variant.gte + 1])
            }
        });
        return distribution;
    }, [variants])

    return <>
        <div style={{ position: "relative", display: "flex", }}>
            <div style={{ width: "70%" }}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item rules={[...REQUIRED_RULE, ONLY_ALPHA_NUMARIC_RULE]} name={'code'} label="Experiment Code">
                            <Input placeholder="Code"></Input>
                        </Form.Item>
                    </Col>
                    <Col span={18}>
                        <Form.Item rules={REQUIRED_RULE} name={"description"} label="Experiment Description">
                            <TextArea placeholder="Description"></TextArea>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item hidden rules={REQUIRED_RULE} name={"created_by"} initialValue={userProfile?.email || "NA"}>
                    <Input disabled />
                </Form.Item>
                <Form.List
                    name="variants"
                    initialValue={[{
                        code: "CONTROL",
                        description: undefined,
                        gte: 1,
                        lte: 100,
                    }]}
                    rules={[{
                        validator(rule, value, callback) {
                            const valueSet = new Set<string>([])
                            let isDistributionValid = true;
                            let isMax100 = false;
                            let isMin1 = false;
                            value.sort((a: any, b: any) => a.gte - b.gte)
                            value?.forEach((variant: any, index: number) => {
                                valueSet.add(variant.code);
                                if (isDistributionValid && variant.gte > variant.lte) {
                                    isDistributionValid = false;
                                }
                                if (isDistributionValid && index < value.length - 1 && variant.lte + 1 !== value[index + 1].gte) {
                                    isDistributionValid = false;
                                }
                                if (variant.lte === 100) {
                                    isMax100 = true
                                }
                                if (variant.gte === 1) {
                                    isMin1 = true
                                }
                            })
                            if (isMax100 === false || isMin1 === false) {
                                callback("Distribution coverage is incomplete. It should cover 1 to 100.")
                            } else if (!isDistributionValid) {
                                callback("Distribution should not overlap.")
                            } else if (value?.length > 1 && !valueSet.has("CONTROL")) {
                                callback("Atleast one CONTROL variant required.")
                            } else {
                                callback(undefined);
                            }
                        },
                    }]}
                >
                    {(fields, { add, remove }, { errors }) => (<div style={{ position: "relative" }}>
                        <div style={{ minHeight: '50px', fontSize: '30px' }}>
                            <Form.ErrorList errors={errors} />
                            {
                                variants?.length === 1 &&
                                <Alert
                                    message="Move the slider right to left to add new variants."
                                    type="warning"
                                />
                            }
                        </div>

                        <StyledDiv style={errors.length > 0 ? { border: "1px solid #ff4d4f" } : {}}>
                            {fields.map((field, index) => (
                                <Row gutter={16} style={{ paddingTop: "20px", position: 'relative' }}>
                                    <Col span={4}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "code"]}
                                            label="Code"
                                            rules={[...REQUIRED_RULE, ONLY_ALPHA_NUMARIC_RULE]}
                                        >
                                            <Input placeholder="Variant Code" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "description"]}
                                            label="Description"
                                            rules={REQUIRED_RULE}
                                        >
                                            <TextArea placeholder="Variant description" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item name={[field.name, "gte"]} label="GTE" >
                                            <InputNumber min={1}></InputNumber>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item name={[field.name, "lte"]} label="LTE">
                                            <InputNumber max={100}></InputNumber>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={9}>
                                        <Slider disabled range style={{ paddingTop: "20px", color: "red" }}
                                            min={1}
                                            value={[form.getFieldValue(['variants', index, 'gte']), form.getFieldValue(['variants', index, 'lte'])]}
                                            tooltipVisible={variants?.length > 1 || form.getFieldValue(['variants', index, 'lte']) !== 100}
                                            getTooltipPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
                                        />
                                    </Col>

                                    {
                                        (index === variants?.length - 1 && index > 0) &&
                                        <Button
                                            size="small"
                                            onClick={
                                                () => {
                                                    const _variants = form.getFieldValue('variants');
                                                    _variants.pop()
                                                    _variants[_variants.length - 1].lte = 100
                                                    form.setFieldValue('variants', _variants)
                                                }
                                            }
                                            style={{ position: "absolute", right: 10, bottom: 0 }}
                                        >
                                            <DeleteOutlined />  delete
                                        </Button>
                                    }
                                </Row>
                            ))}

                        </StyledDiv>

                        <Button type="primary" size="small" style={{ position: "absolute", bottom: -12, left: 12 }} onClick={() => {
                            add({
                                code: undefined,
                                description: undefined,
                                gte: undefined,
                                lte: undefined,
                            })
                        }}>Add variant</Button>
                    </div>
                    )}
                </Form.List>
                <Form.List
                    name="applicable_platforms"
                    initialValue={[]}
                    rules={[{
                        validator(rule, value, callback) {
                            let isValid = true;
                            value?.forEach((val: any, index: number) => {
                                if (val.type === "ANDROID" || val.type === "IOS") {
                                    if (val.lte < val.gte) {
                                        isValid = false;
                                        callback("Min supported version should be less than max supported version for type " + val.type)
                                    }
                                }
                            })
                            if (isValid) {
                                callback(undefined)
                            }
                        }
                    }
                    ]
                    }
                >
                    {(fields, { add, remove }, { errors }) => {
                        const platforms = form.getFieldValue("applicable_platforms");
                        let isAndroid = false;
                        let isIos = false;
                        let isWeb = false;
                        platforms?.forEach((platform: any) => {
                            if (platform.type === "ANDROID") {
                                isAndroid = true;
                            } else if (platform.type === "IOS") {
                                isIos = true;
                            } else if (platform.type === "WEB") {
                                isWeb = true;
                            }
                        })

                        return <>
                            {
                                errors.length > 0 &&
                                <Alert
                                    message="Platform validation failed"
                                    description={errors.join(", ")}
                                    type="error"
                                />
                            }
                            {/* Existing code */}
                            {
                                fields.map((field, index) => (
                                    <div style={{ display: "flex", flexDirection: "row", gap: "20px", marginTop: "20px" }}>
                                        <Form.Item
                                            name={[field.name, "type"]}
                                            rules={[...REQUIRED_RULE]}
                                            label="Platform Type"
                                        >
                                            <Input disabled placeholder="Platform Type" />
                                        </Form.Item>
                                        <Form.Item shouldUpdate={
                                            (prevValues, currentValues) => {
                                                return prevValues["applicable_platforms"]?.[index]?.type !== currentValues["applicable_platforms"][index]?.type
                                            }}
                                        >
                                            {() => {
                                                const type = form.getFieldValue(["applicable_platforms", index, "type"])
                                                return <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
                                                    <Form.Item
                                                        name={[field.name, "gte"]}
                                                        rules={[...REQUIRED_RULE]}
                                                        label="Min supported version"
                                                    >
                                                        <InputNumber min={0} max={1000} disabled={type === "WEB"} placeholder="Min supported version" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[field.name, "lte"]}
                                                        rules={[...REQUIRED_RULE]}
                                                        label="Max supported version"
                                                    >
                                                        <InputNumber min={0} max={1000} disabled={type === "WEB"} placeholder="Max supported version" />
                                                    </Form.Item>
                                                </div>
                                            }}
                                        </Form.Item >
                                        <Button style={{ marginTop: "25px" }} onClick={() => { remove(field.name) }}><CloseCircleFilled /> Remove</Button>
                                    </div>
                                ))
                            }
                            <div style={{ display: "flex", gap: "20px", marginTop: '32px' }}>

                                {!isAndroid &&
                                    <Button onClick={() => add({
                                        type: "ANDROID",
                                        gte: 0,
                                        lte: 1000
                                    })}>
                                        Configure Android Version Support
                                    </Button>
                                }
                                {!isIos &&
                                    <Button onClick={() => add({
                                        type: "IOS",
                                        gte: 0,
                                        lte: 1000
                                    })}>
                                        Configure IOS Version Support
                                    </Button>
                                }
                                {!isWeb &&
                                    <Button onClick={() => add({
                                        type: "WEB",
                                        gte: 0,
                                        lte: 0
                                    })}>
                                        Configure Web Version Support
                                    </Button>
                                }
                            </div>
                        </>
                    }
                    }
                </Form.List >
            </div >
            <div style={{ flexGrow: 1, position: 'fixed', right: 0 }}>
                <Chart
                    chartType="PieChart"
                    data={variantsDistribution}
                    options={options}
                    width={'100%'}
                />
            </div>
        </div >
        {
            formData ?
                <SubmitButton type="primary" htmlType="submit"> Update </SubmitButton> :
                <SubmitButton type="primary" htmlType="submit"> Create </SubmitButton>
        }
    </>
}

export default AddExperiments;