import React from 'react';
import {
    Table,
    Button,
    Space,
    Card,
    Switch,
    Tooltip,
} from 'antd'
import { CampaignsData } from '@a4b/api/src/modules/Monetisation/offerings/types'
import type { TablePaginationConfig } from 'antd/es/table';
import { PlusSquareOutlined } from '@ant-design/icons';
import styled from 'styled-components';
interface Props {
    CampaignList: CampaignsData | undefined
    paginationParams: TablePaginationConfig
    handleTableChange: (paginationParams: TablePaginationConfig) => void
    onCreateNew: () => void
    onStatusChange: (item: any) => void
    triggerEdit: (data: any) => void
    isLoading: boolean
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const ManageCampaignsPage: React.FC<Props> = ({ CampaignList, paginationParams, handleTableChange, onCreateNew, onStatusChange, triggerEdit, isLoading }) => {

    const dataSource = CampaignList?.campaigns

    const formatDate = (text: string) => {
        const date = new Date(text);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
        const year = date.getUTCFullYear().toString();
        return `${day}-${month}-${year}`
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 270,
            ellipsis: {
                showTitle: false,
            },
            render: (id: string) => (
                <Tooltip placement="topLeft" title={id}>
                    {id}
                </Tooltip>),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (id: string) => (
                <Tooltip placement="topLeft" title={id}>
                    {id}
                </Tooltip>),
        },
        {
            title: 'Discount type',
            dataIndex: 'discount_type',
            key: 'discount_type',
        },
        {
            title: 'Campaign type',
            dataIndex: 'campaign_type',
            key: 'campaign_type',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            width: 100
        },
        {
            title: 'Start date',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (text: string) => {
                if (text) {
                    return formatDate(text)
                }
                return "-"
            }
        },
        {
            title: 'End date',
            dataIndex: 'end_date',
            key: 'end_date',
            render: (text: string) => {
                if (text) {
                    return formatDate(text)
                }
                return "-"
            }
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text: string) => {
                if (text) {
                    return formatDate(text)
                }
                return "-"
            }
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (text: string, record: any) => {
                return <Switch
                    checked={record.active}
                    onChange={() => onStatusChange(record)}
                />
            }
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text: string, record: any) => {
                return <Button onClick={() => { triggerEdit(record) }} type='link' color='cyan' style={{ cursor: 'pointer' }}>Edit</Button>
            }
        },
    ];

    return (
        <Container style={{ width: '100%' }}>
            <Space direction={'vertical'} style={{ width: '100%' }}></Space>
            <BodyContainer>
                <Button type='text' onClick={onCreateNew}>
                    <PlusSquareOutlined /> Create Campaign
                </Button>

                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={paginationParams}
                    onChange={handleTableChange}
                    loading={isLoading}
                />
            </BodyContainer>
        </Container>
    )

}

export default ManageCampaignsPage
