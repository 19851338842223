import { StoreCard } from '@a4b/api/src/modules/Monetisation/Offerings/types'

export const formatItemCreation = (formData: any): StoreCard => {
  let cover_media = {
    type: 'video',
    media_url: '',
    media_thumbnail: '',
  }
  if (formData?.media_type === 'video') {
    cover_media = {
      type: 'video',
      media_url: formData?.media_url?.[0],
      media_thumbnail: formData?.media_thumbnail?.[0],
    }
  }
  if (formData?.media_type === 'image') {
    cover_media = {
      type: 'image',
      media_url: formData?.media_url?.[0],
      media_thumbnail: formData?.media_url?.[0],
    }
  }
  const formatedObject: StoreCard = {
    title: formData?.title,
    description: formData?.description,
    tag: formData?.tag,
    store_id: formData?.store_id,
    inventory_id: formData?.inventory_id,
    position: Number(formData?.position),
    cover_media: cover_media,
    active: formData?.active === undefined ? false : formData?.active,
    id: formData?.id,
  }

  return formatedObject
}
