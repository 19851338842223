import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import { Card, Space, Tabs } from 'antd'
import ManageCategoriesScreen from '../ManageCategories'
import ManagePages from '../ManagePages'
import ManageSectionScreen from '../ManageSections'
import ManageStoreCardScreen from '../ManageStoreCard'

interface Props { }

const getPageBreadcrumbs = () => [
  {
    path: '/monetisation/offerings/home-page',
    breadcrumbName: 'Home page',
  },
  {
    path: '/monetisation/offerings/manage_page_section',
    breadcrumbName: 'Manage Page Section',
  },
]

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  .ant-card-body {
    padding-top:10px;
    flex-grow: 1;
  }
`
const ManagePageSectionScreen: React.FC<Props> = () => {
  const { networkInstance } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("store_cards")


  useEffect(() => {
    const currentTab = searchParams.get("current_tab") || "store_cards"
    setActiveTab(currentTab)
  }, [activeTab, searchParams])

  const onTabChange = (activeTab: string) => {
    setActiveTab(activeTab)
    setSearchParams({ current_tab: activeTab })
  }

  return (
    <div style={{ width: "100%", padding: '0 40px' }}>
      <AdminBreadcrumb routes={getPageBreadcrumbs()} />
      <Container style={{ width: '100%' }}>
        <Space direction={'vertical'} style={{ width: '100%' }}></Space>
        <Tabs centered activeKey={activeTab} onChange={onTabChange}>
          <Tabs.TabPane tab="Manage Store Cards" key="store_cards">
            <ManageStoreCardScreen currentTab={activeTab} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Manage Categories" key="categories">
            <ManageCategoriesScreen currentTab={activeTab} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Manage Sections" key="sections">
            <ManageSectionScreen currentTab={activeTab} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Manage Pages" key="pages">
            <ManagePages />
          </Tabs.TabPane>
        </Tabs>
      </Container>
    </div>
  )
}

export default withDefaultLayout(ManagePageSectionScreen)
