import { useAppContext } from '@/components/AppContext'
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import { message, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AssignFaqs from '@a4b/ui/src/modules/monetisation/molecules/AssignFaqs'

import { LanguageState, Product, Sessions, StoreSessionDay } from '@a4b/api/src/modules/Monetisation/Offerings/types'

import {
  formatFaqsAndreviewsApi
} from '../utils/helper'
import moment from 'moment'

interface Props {
  isEditJourney: boolean
  currentStep: number
  storeId: string
  nextHandler: (store_id?: string) => void
  previousHandler: () => void
  languageState: LanguageState
}
type FaqReviewFormData = {
  faqs: Faqs[]
  reviews: reviews[]
}
type Faqs = { question: string; answer: string }

type reviews = {
  userReview: string
  userName: string
  userRating: number
  userImageUrl: string
}
type AddedItems = { added: Product[] }
const FaqsAndReviewStep: React.FC<Props> = ({
  currentStep,
  isEditJourney,
  storeId,
  previousHandler,
  nextHandler,
  languageState
}) => {
  const { networkInstance, countryState } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders
  const [faqFormInstance] = useForm()
  let navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const [showStoreEnableModal, setShowStoreEnableModal] = useState(false)
  const [storeSessionJourney, setStoreSessionJourney] = useState("create")
  const [storeSession, setStoreSession] = useState<StoreSessionDay[] | []>([])
  const [serviceType, setServiceType] = useState<string>("")

  const [faqAndreviewData, setFaqAndreviewData] = useState<FaqReviewFormData>(
    () => ({
      faqs: [{ question: '', answer: '' }],
      reviews: [
        {
          userName: '',
          userReview: '',
          userRating: 5,
          userImageUrl: '',
        },
      ],
      status: true,
    }),
  )

  const getfaqList = useCallback(async () => {
    try {
      const faqList = await monetisationOfferingsApi.storesApi.getFaqs(storeId)
      const reviewList = await monetisationOfferingsApi.reviewApi.getReviews(storeId)
      const storeResponse = await monetisationOfferingsApi.storesApi.getStoreById(
        storeId,
      )
      const storeTimeResponse = await monetisationOfferingsApi.storesApi.getStoreTime(
        storeId,
      )
      const poojaBenefits = await monetisationOfferingsApi.stepsApi.getSteps("pooja_benefits", storeId)
      const poojaProcess = await monetisationOfferingsApi.stepsApi.getSteps("pooja_process", storeId)
      setStoreSession(storeTimeResponse?.data?.data?.sessions)
      setServiceType(storeResponse?.data?.data?.service_type)

      const faqAndreviewData: any = {
        faqs: faqList?.data?.data,
        reviews: reviewList?.data?.data.map((item: any) => ({
          ...item,
          user_image_url: [item.user_image_url],
        })),
        pooja_benefits: poojaBenefits?.data?.data.map((item: any) => ({
          ...item,
          pooja_benefit_icon: [item.icon]
        })),
        pooja_process: poojaProcess?.data?.data.map((item: any) => ({
          ...item,
          pooja_process_icon: [item.icon]
        })),
        status: storeResponse?.data?.data?.active,
      }

      if (storeTimeResponse?.data?.data?.sessions?.length) {
        const { opening_hour, closing_hour } = storeTimeResponse?.data?.data?.sessions?.[0]
        let sessionList = []
        sessionList.push(moment(opening_hour, "HH:mm"))
        sessionList.push(moment(closing_hour, "HH:mm"))
        faqAndreviewData["session"] = sessionList
        setStoreSessionJourney("edit")

      } else {
        setStoreSessionJourney("create")
      }
      setFaqAndreviewData(faqAndreviewData)
      faqFormInstance.setFieldsValue(faqAndreviewData)
    } catch (error) {
      message.error({
        content: 'Error while fetching product list',
        duration: 3,
      })
      console.log(error)
    }
  }, [faqFormInstance, monetisationOfferingsApi.storesApi, storeId])

  useEffect(() => {
    if (isEditJourney && currentStep === 2) {
      searchParams.set("language_code", languageState.monetisationLanguage)
      setSearchParams(searchParams)
      getfaqList()
    }
  }, [currentStep, getfaqList, isEditJourney])

  const deleteFaq = async (id: string, callback: any) => {
    try {
      await monetisationOfferingsApi.faqsApi.deleteFaq(id)
      message.success({
        content: 'Faq deleted successfully',
        duration: 3,
      })
      callback()
    } catch (error) {
      message.error({
        content: 'Faq deletion failed',
        duration: 2,
      })
      console.log(error)
    }
  }
  const deleteSteps = async (id: string, callback: any) => {
    try {
      await monetisationOfferingsApi.stepsApi.deleteStep(id)
      message.success({
        content: 'Step deleted successfully',
        duration: 3,
      })
      callback()
    } catch (error) {
      message.error({
        content: 'Step deletion failed',
        duration: 2,
      })
      console.log(error)
    }
  }
  const deleteReview = async (id: string, callback: any) => {
    try {
      await monetisationOfferingsApi.reviewApi.deleteReview(id)
      message.success({
        content: 'Review deleted successfully',
        duration: 3,
      })
      callback()
    } catch (error) {
      message.error({
        content: 'Review deletion failed',
        duration: 2,
      })
    }
  }

  const handleFaqCreateOrUpdate = async (values: any) => {
    if (isEditJourney) {
      const formatedData = formatFaqsAndreviewsApi(values, storeId, storeSession)
      if (storeSessionJourney === "edit") {
        if (values?.session?.length) {
          try {
            await monetisationOfferingsApi.storesApi.updateStoreTime(storeId, formatedData.session)
            message.success({
              content: 'Store Timing Updated Successfully',
              duration: 2,
              key: 'session',
            })
          } catch (error) {
            console.log('error occured while updating store timing', error)
            message.destroy('session')
          }
        }
      }
      if (storeSessionJourney === "create") {
        if (values?.session?.length) {
          try {
            await monetisationOfferingsApi.storesApi.createStoreTime(storeId, formatedData.session)
            message.success({
              content: 'Store Timing Created Successfully',
              duration: 2,
              key: 'session',
            })
          } catch (error) {
            console.log('error occured while updating store timing', error)
            message.destroy('session')
          }
        }
      }

      if (formatedData?.faqs?.faqs?.create?.faqs?.length) {
        try {
          await monetisationOfferingsApi.faqsApi.updateFaqs({
            ...formatedData?.faqs?.faqs?.create,
            language_code: languageState?.monetisationLanguage,
          })
          message.success({
            content: 'Faqs Created Successfully',
            duration: 2,
            key: 'faqs_create',
          })
        } catch (error) {
          console.log('error occured while creating faq', error)
          message.error({
            content: 'Error occured while creating faqs',
            duration: 2,
            key: 'faqs_create',
          })
        }
      }

      if (formatedData?.faqs?.faqs?.update?.faqs?.length) {
        try {
          await monetisationOfferingsApi.faqsApi.updateFaqs({
            ...formatedData?.faqs?.faqs?.update,
            language_code: languageState?.monetisationLanguage,
          })
          message.success({
            content: 'Faqs Updated Successfully',
            duration: 2,
            key: 'faqs_update',
          })
        } catch (error) {
          console.log('error occured while updating faq', error)
          message.error({
            content: 'Error occured while updating faqs',
            duration: 2,
            key: 'faqs_update',
          })
        }
      }

      if (formatedData?.reviews?.reviews?.create?.reviews?.length) {
        try {
          await monetisationOfferingsApi.reviewApi.updateReviews({
            ...formatedData?.reviews?.reviews?.create,
            language_code: languageState?.monetisationLanguage,
          }

          )
          message.success({
            content: 'Reviews Created Successfully',
            duration: 2,
            key: 'review_create',
          })
        } catch (error) {
          console.log('error occured while creating faq', error)
          message.error({
            content: 'Error occured while creating faqs and reviews',
            duration: 2,
            key: 'review_create',
          })
        }
      }
      if (formatedData?.reviews?.reviews?.update?.reviews?.length) {
        try {
          await monetisationOfferingsApi.reviewApi.updateReviews({
            ...formatedData?.reviews?.reviews?.update,
            language_code: languageState?.monetisationLanguage,
          })
          message.success({
            content: 'Reviews Updated Successfully',
            duration: 2,
            key: 'review_update',
          })
        } catch (error) {
          console.log('error occured while updating faq', error)
          message.destroy('review_update')
        }
      }

      if (formatedData?.pooja_benefits?.pooja_benefits?.create?.steps?.length) {
        try {
          await monetisationOfferingsApi.stepsApi.updateSteps({
            ...formatedData?.pooja_benefits?.pooja_benefits?.create,
            language_code: languageState?.monetisationLanguage,
          }

          )
          message.success({
            content: 'pooja_benefits Created Successfully',
            duration: 2,
            key: 'pooja_benefits',
          })
        } catch (error) {
          console.log('error occured while creating pooja_benefits', error)
          message.error({
            content: 'Error occured while creating pooja_benefits',
            duration: 2,
            key: 'pooja_benefits',
          })
        }
      }
      if (formatedData?.pooja_benefits?.pooja_benefits?.update?.steps?.length) {
        try {
          await monetisationOfferingsApi.stepsApi.updateSteps({
            ...formatedData?.pooja_benefits?.pooja_benefits?.update,
            language_code: languageState?.monetisationLanguage,
          }

          )
          message.success({
            content: 'pooja_benefits Updated Successfully',
            duration: 2,
            key: 'pooja_benefits',
          })
        } catch (error) {
          console.log('error occured while updating pooja_benefits', error)
          message.error({
            content: 'Error occured while updating pooja_benefits',
            duration: 2,
            key: 'pooja_benefits',
          })
        }
      }

      if (formatedData?.pooja_process?.pooja_process?.create?.steps?.length) {
        try {
          await monetisationOfferingsApi.stepsApi.updateSteps({
            ...formatedData?.pooja_process?.pooja_process?.create,
            language_code: languageState?.monetisationLanguage,
          })
          message.success({
            content: 'pooja_process Created Successfully',
            duration: 2,
            key: 'pooja_process',
          })
        } catch (error) {
          console.log('error occured while creating pooja_process', error)
          message.error({
            content: 'Error occured while creating pooja_process',
            duration: 2,
            key: 'pooja_process',
          })
        }
      }
      if (formatedData?.pooja_process?.pooja_process?.update?.steps?.length) {
        try {
          await monetisationOfferingsApi.stepsApi.updateSteps({
            ...formatedData?.pooja_process?.pooja_process?.update,
            language_code: languageState?.monetisationLanguage,
          }
          )
          message.success({
            content: 'pooja_process Updated Successfully',
            duration: 2,
            key: 'pooja_process',
          })
        } catch (error) {
          console.log('error occured while updating pooja_process', error)
          message.error({
            content: 'Error occured while updating pooja_process',
            duration: 2,
            key: 'pooja_process',
          })
        }
      }
      if (formatedData.status) {
        try {
          setShowStoreEnableModal(true)
        } catch (error) {
          console.log('error occured while creating faq', error)
          message.success({
            content: 'Error occured while creating faqs and reviews',
            duration: 2,
            key: 'store_create',
          })
        }
      } else {
        setTimeout(() => {
          navigate('/monetisation/offerings/temples')
        }, 1000)
      }

    }
    else {
      const formatedData = formatFaqsAndreviewsApi(values, storeId, storeSession)
      if (storeSessionJourney === "edit") {
        if (values?.session?.length) {
          try {
            await monetisationOfferingsApi.storesApi.updateStoreTime(storeId, formatedData.session)
            message.success({
              content: 'Store Timing updated Successfully',
              duration: 2,
              key: 'session',
            })
          } catch (error) {
            console.log('error occured while updating store timing', error)
            message.destroy('session')
          }
        }
      }
      if (storeSessionJourney === "create") {
        if (values?.session?.length) {
          try {
            await monetisationOfferingsApi.storesApi.createStoreTime(storeId, formatedData.session)
            message.success({
              content: 'Store Timing Created Successfully',
              duration: 2,
              key: 'session',
            })
          } catch (error) {
            console.log('error occured while updating store timing', error)
            message.destroy('session')
          }
        }
      }

      if (formatedData.status) {
        try {
          setShowStoreEnableModal(true)
        } catch (error) {
          console.log('error occured while creating faq', error)
          message.success({
            content: 'Error occured while creating faqs and reviews',
            duration: 2,
            key: 'store_create',
          })
        }
      } else {
        setTimeout(() => {
          navigate('/monetisation/offerings/temples')
        }, 1000)
      }
    }
  }

  const handleTutorialsAndReview = () => {
    faqFormInstance
      .validateFields()
      .then((values: any) => {
        handleFaqCreateOrUpdate(values)
      })
      .catch((error: any) => { })
  }
  const onStoreEnableModalOk = async () => {
    setShowStoreEnableModal(false)

    message.loading({
      content: 'Enabling Store...',
      duration: 2,
      key: 'status_enable',
    })
    try {
      await monetisationOfferingsApi.storesApi.updateStoreStatus(
        storeId,
        faqFormInstance.getFieldValue('status'),
        languageState.monetisationLanguage,
        countryState?.countryCode
      )
      message.success({ content: 'Store Enabled Successfully', duration: 3 })
      setTimeout(() => {
        navigate('/monetisation/offerings/temples')
      }, 1000)
    } catch (error) {
      console.log('error occured while updating store status', error)
      message.destroy('status_enable')
    }
  }

  const onStoreEnableModalCancel = () => {
    setShowStoreEnableModal(false)
  }

  return (
    <>
      <AssignFaqs
        form={faqFormInstance}
        cdnUrl={YODA_CDN_URL}
        preSignUrl={YODA_PRESIGN_URL}
        faqFromInitialValues={faqAndreviewData}
        submitFormHandler={handleTutorialsAndReview}
        isEditJourney={isEditJourney}
        currentStep={currentStep}
        previousHandler={previousHandler}
        nextHandler={nextHandler}
        serviceType={serviceType}
        deleteFaq={deleteFaq}
        deleteSteps={deleteSteps}
        deleteReview={deleteReview}
      />
      <Modal
        title='Activate Store'
        visible={showStoreEnableModal}
        onOk={onStoreEnableModalOk}
        onCancel={onStoreEnableModalCancel}
        okText='Activate'
      >
        <p>Are you sure to activate this store ?</p>
      </Modal>
    </>
  )
}

export default FaqsAndReviewStep
