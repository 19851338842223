import { Image, Tag } from "antd";
import moment from "moment-timezone";

export function renderDateTime<T>(value: any, record: T, index: number): React.ReactNode {
    return <div>{moment(value).tz("Asia/Calcutta").format(" MMMM Do, YYYY HH:mm:ss")} IST</div>
}

export function renderDate<T>(value: any, record: T, index: number): React.ReactNode {
    return <div>{moment(value).tz("Asia/Calcutta").format(" MMMM Do, YYYY")}</div>
}

export function renderBoolean<T>(value: boolean, record: T, index: number): React.ReactNode {
    if (value) {
        return <Tag color="green">{"Yes"}</Tag>
    } else {
        return <Tag color="red">{"No"}</Tag>

    }
}


export function renderImage(url: string, width = 100) {
    return <Image src={url} width={width}></Image>
}