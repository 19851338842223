import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { apiClient } from '../../apiClient'
import { Community, CommunityUserProfile } from './TypesOld';


//types
// import { Community, CommunityUserProfile } from '@social/types/community'

export type CreateCommunityOptions = {
    code: string,
    category_ids: string[],
    deeplink: string;
    local_name: string;
    en_name: string;
    share_text: string;
    profile_picture_attachment_id?: string;
    cover_picture_attachment_id?: string;
    long_description: string;
    short_description: string;
    nudge_message: string;
    welcome_message: string;
    rank?: number;
}


class CommunityOldApi {
    private api: AxiosInstance
    constructor(config: AxiosRequestConfig) {
        this.api = apiClient.create(config)
    }

    //TODO: fix return type
    private searchCommunities = () => this.api.get<Community>('/vixen/internal/v1/communities')

    createNewCommunity = async (options: CreateCommunityOptions) => {
        try {
            await this.api.post('/vixen/internal/v1/communities', options)
            return { data: 'success' }
        } catch (error) {
            console.log({ error })
            return { err: 'Failed to create community' }
        }
    }

    fetchCommunities = async () => {
        try {
            const response = await this.api.get<{ communities: Community[], total_count: number }>('/vixen/internal/v1/communities?limit=20')
            const data = response.data.communities
            return { data: data }
        } catch (error) {
            console.log({ error })
            return { err: 'Failed to Fetch' }
        }
    }

    getCommunity = async (id?: string) => {
        if (!id) {
            return { err: 'ID cannot be undefined' }
        }
        try {
            const response = await this.api.get<Community>(`/vixen/internal/v1/communities/${id}`)
            const data = response.data
            return { data }
        } catch (error) {
            console.log({ error })
            return { err: 'Failed to Fetch community' }
        }
    }

    getAuthorsForCommunity = async (community_id?: string) => {
        if (!community_id) {
            return { err: 'communityId cannot be undefined' }
        }
        try {
            const config = { params: { community_id } }
            const response = await this.api.get<{ authors: CommunityUserProfile[], total_count: number }>(`/vixen/internal/v1/authors`, config)
            const data = response.data.authors
            return { data }
        } catch (error) {
            console.log({ error })
            return { err: 'Failed to Fetch community' }
        }
    }
}

export default CommunityOldApi

