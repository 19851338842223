import { CloseCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Card, Space, Typography } from 'antd'
import styled from 'styled-components'

import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import { SortableItemAudio } from '@a4b/api/src/modules/Content/Audio/types'

const { Paragraph } = Typography;

interface Props {
  item: SortableItemAudio
  enabled: boolean
  itemIndex: number
  onRemoveItem: (item: SortableItemAudio) => void
  cardType: 'small' | 'medium' | 'large'
  isLanguageCodeError: boolean
}

const { Meta } = Card

const Container = styled.div`
  margin: 10px;
  width: 400px;
  position: relative;
  overflow: scroll;
`

const CardImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: auto;
`

const Overlay = styled.div`
  position: absolute;
  background: gray;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.4;
`
const CloseButton = styled(Button)`
  position: absolute;
  right: 5px;
  top: 0px;
  padding: 0;

  svg {
    background: white !important;
    border-radius: 100%;
  }
`

const CardItem: React.FC<Props> = ({
  item,
  enabled,
  itemIndex,
  onRemoveItem,
  cardType,
  isLanguageCodeError
}) => {
  const handleRemoveItemClicked = () => {
    onRemoveItem(item)
  }
  let errorMsg;
  if (isLanguageCodeError) {
    errorMsg = "Language code is not matching with audio language still wants to procsess?"
  }
  if (cardType === 'small') {
    return (
      <Container style={{ width: '300px' }}>
        {errorMsg && <Alert type='warning' showIcon message={errorMsg} />}
        <div style={{ border: '1px solid #e2dede', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>

          {item.imageUrl && <img style={{
            width: '70px',
            height: '70px',
            padding: '5px'
          }} src={item.imageUrl} />}
          <div>
            <Typography.Text >
              <b> S no: {itemIndex + 1} </b>
            </Typography.Text>
            <br />
            <Typography.Text >
              {item.audio_title}
            </Typography.Text>
            <br />
            <Typography.Text onClick={() => { navigator.clipboard.writeText(item.id) }}>
              {item.id}
            </Typography.Text>
          </div>

        </div>
        {!enabled && <Overlay />}
      </Container>
    )
  }

  if (cardType === 'medium') {
    return (
      <Container style={{ width: '100%' }}>
        {errorMsg && <Alert type='warning' showIcon message={errorMsg} />}
        <div style={{ border: '1px solid #e2dede', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          {item.imageUrl && <img style={{
            width: '70px',
            height: '70px',
            padding: '5px'
          }} src={item.imageUrl} />}
          <div>
            <Typography.Text >
              <b> S no: {itemIndex + 1} </b>
            </Typography.Text>
            <br />
            <Typography.Text >
              {item.audio_title}
            </Typography.Text>
            <br />
            <Typography.Text onClick={() => { navigator.clipboard.writeText(item.id) }}>
              Audio id: {item.id}
            </Typography.Text>
          </div>

        </div>
        {!enabled && <Overlay />}
      </Container>
    )
  }

  return (
    <Container >
      {errorMsg && <Alert type='warning' showIcon message={errorMsg} />}
      <Card >
        {item.imageUrl && <CardImage src={item.imageUrl} />}
        <Paragraph style={{ width: '200%', marginTop: '10px' }}>
          <b> S no: </b>{itemIndex + 1}
        </Paragraph>
        <Paragraph >
          <b> Audio title: </b>{item.audio_title}
        </Paragraph>
        <Paragraph onClick={() => { navigator.clipboard.writeText(item.id) }}>
          <b> Audio id: </b>{item.id}
        </Paragraph>
        <Paragraph >
          <b> Audio language: </b>{item.audio_language}
        </Paragraph>
        <Paragraph >
          <b> Audio type: </b>{item.audio_type}
        </Paragraph>
        <Paragraph >
          <b> Audio unique id: </b>{item.partner_audio_unique_identifier}
        </Paragraph>

        <Paragraph >
          <b> Audio partner name: </b>{item.partner_name}
        </Paragraph>
        <Paragraph >
          <b> Audio riddler audio id: </b>{item.riddler_audio_id}
        </Paragraph>
        <Paragraph >
          <b> Audio riddler deeplink: </b>{item.riddler_deeplink}
        </Paragraph>
        <Paragraph >
          <b> Audio riddler god id: </b>{item.riddler_god_id}
        </Paragraph>
        <CloseButton onClick={handleRemoveItemClicked} danger type='link'>
          <CloseCircleOutlined />
        </CloseButton>
      </Card>
      {!enabled && <Overlay />}
    </Container>
  )
}

export const SortableCardItem = SortableElement(CardItem)
