import { AntdFileUploadV2 } from "@a4b/ui/src/modules/admin/components/FileUpload"
import { UploadOutlined } from "@ant-design/icons"
import { Form, Upload, Button, Input, FormInstance } from "antd"
import { useCallback } from "react"
import { useAppContext } from "../../../../../components/AppContext"
interface TestComProps {
    form: FormInstance<any>,

}



const UploadThumbnail = ({ form }: TestComProps) => {
    const { networkInstance } = useAppContext();

    const uploadMedia = useCallback(async (options: any): Promise<{ url: string, path: string }> => {
        const headers = new Headers()
        headers.append('ContentType', options.file.type)

        const data = await networkInstance.clientWithHeaders.contentApi.audio.getUrlToUploadThumbnail();
        const { url, path } = data.data.data;
        const res = await fetch(url, {
            method: 'PUT',
            body: options.file,
            headers,
        })
        return { url, path }
    }, [networkInstance.clientWithHeaders.contentApi.audio])

    return <>
        <AntdFileUploadV2
            rules={[{ required: true }]}
            name={"path"}
            accept="image/png"
            s3UploadApi={uploadMedia}
            title={'upload icon'}
        />
        <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true, message: 'Please select a code' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
            <Button type='primary' htmlType='submit'>
                Save
            </Button>
        </Form.Item>
    </>

}

export default UploadThumbnail;