import React from 'react'

import { Button, Col, Form, Row, Card } from 'antd'
import styled from 'styled-components'

import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import Translations from '../Translations'

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`

interface Props {
    cdnUrl: string
    preSignUrl: string
    form: any
    submitFormHandler: () => void
}

const CityCreateOrUpdate: React.FC<Props> = ({
    cdnUrl,
    preSignUrl,
    form,
    submitFormHandler
}) => {
    return (
        <Container
            title='Create City'
            style={{ width: '100%' }}
        >
            <Row>
                <Col span={32}>
                    <Form
                        form={form}
                        layout='vertical'
                        name='create temple'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                    >
                        <Row>
                            <Col span={32}>
                                <Form.Item
                                    label='Thumbnail image'
                                    name='thumbnail_img_url'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    valuePropName='fileList'
                                    style={{ margin: '10px' }}
                                    rules={[
                                        {
                                            min: 1,
                                            max: 1,
                                            validator: async (
                                                rules: any,
                                                values: any,
                                            ) => {
                                                if (values?.length < rules.min) {
                                                    return Promise.reject(
                                                        'Upload Thumbnail Image',
                                                    )
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <S3Upload
                                        cdnUrl={cdnUrl}
                                        preSignUrl={preSignUrl}
                                        min={1}
                                        max={1}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='City details'
                                    name='translations'
                                    style={{ marginTop: '20px' }}
                                    rules={[
                                        { required: true, message: 'Please input City details' },
                                    ]}
                                >
                                    <Form.List name='translations'>
                                        {(fields, { add, remove }) => {
                                            return (
                                                <Translations fields={fields} add={add} remove={remove} />
                                            )
                                        }}
                                    </Form.List>
                                </Form.Item>
                                <Button
                                    type='primary'
                                    onClick={submitFormHandler}
                                    style={{ marginLeft: '10px', marginTop: '5px' }}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form >
                </Col >
            </Row >
        </Container >

    )
}
export default CityCreateOrUpdate