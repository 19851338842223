import {
  DeleteFilled,
  PlusSquareFilled,
  UpSquareFilled,
  DownSquareFilled,
} from "@ant-design/icons";
import { Button } from "antd";
interface Props {
  children: any;
  closeHandler?: () => void;
  itemText?: string;
  showItemTextInBold?: boolean;

  fieldName?: number;
  moveFunction?: (from: number, to: number) => void;
  fields?: any[];
  currentIndex: number;
  isInnerContainer?: boolean;
  fieldsLength: number;
  move: (from: number, to: number) => void;
  add: (value: string, index: number) => void;
  [key: string]: any;
}
const DeletableContainer = ({
  children,
  closeHandler,

  itemText,
  showItemTextInBold,
  draggable,
  fieldName,
  moveFunction,
  isInnerContainer,
  currentIndex,
  fields,
  fieldsLength,
  add,
  move,
  ...restOfTheProps
}: Props) => {
  return (
    <div
      {...restOfTheProps}
      style={{
        width: "100%",
        position: "relative",
        paddingTop: "10px",
        paddingRight: "40px",
        paddingLeft: "40px",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          top: "12px",
          right: "42px",
        }}
      >
        {isInnerContainer ? (
          <DeleteFilled onClick={closeHandler} style={{ color: "red" }} />
        ) : (
          <Button
            onClick={closeHandler}
            danger
            icon={<DeleteFilled />}
            size={"small"}
          ></Button>
        )}
      </div>

      <div
        style={{
          position: "relative",
          border: "1px solid #d3cccc",
          padding: "10px",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "0",
            fontWeight: showItemTextInBold ? "bold" : "",
            margin: "-12px 0 0 10px" /* half of font-size */,
            background: "#fff",
            padding: "0 3px",
          }}
        >
          {itemText}
        </span>

        {currentIndex > 0 && (
          <span
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              fontWeight: showItemTextInBold ? "bold" : "",
              margin: "3px 100px 3px 0px" /* half of font-size */,
              background: "#fff",
              padding: "0 0px",
              zIndex: 4,
            }}
          >
            <Button
              onClick={() => {
                move(currentIndex, currentIndex - 1);
              }}
              icon={<UpSquareFilled style={{ color: "#1990FF" }} />}
              size={"small"}
            >
              Move
            </Button>
          </span>
        )}
        {currentIndex > 0 && (
          <span
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              fontWeight: showItemTextInBold ? "bold" : "",
              margin: "3px 30px 3px 0px" /* half of font-size */,
              background: "#fff",
              padding: "0 0px",
              zIndex: 4,
            }}
          >
            <Button
              onClick={() => {
                add("", currentIndex);
              }}
              icon={<PlusSquareFilled style={{ color: "#1990FF" }} />}
              size={"small"}
            >
              Add
            </Button>
          </span>
        )}

        {currentIndex < fieldsLength - 1 && (
          <span
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              fontWeight: showItemTextInBold ? "bold" : "",
              margin: "0px 30px 3px 0px" /* half of font-size */,
              background: "#fff",
              padding: "0 0px",
              zIndex: 4,
            }}
          >
            <Button
              onClick={() => {
                add("", currentIndex + 1);
              }}
              icon={<PlusSquareFilled style={{ color: "#1990FF" }} />}
              size={"small"}
            >
              Add
            </Button>
          </span>
        )}

        {currentIndex < fieldsLength - 1 && (
          <span
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              fontWeight: showItemTextInBold ? "bold" : "",
              margin: "0px 100px 3px 0px" /* half of font-size */,
              background: "#fff",
              padding: "0 0px",
              zIndex: 4,
            }}
          >
            <Button
              onClick={() => {
                move(currentIndex, currentIndex + 1);
              }}
              icon={<DownSquareFilled style={{ color: "#1990FF" }} />}
              size={"small"}
            >
              Move
            </Button>
          </span>
        )}

        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginRight: "20px",
            marginLeft: "20px",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
export default DeletableContainer;
