import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import CreateUpdateShareTextForm from "../components/CreateUpdateShareTextForm";
import { Sharetext } from "@a4b/api/src/modules/ShareText/types";


export enum DRAWER_TYPE {
    CREATE_SHATE_TEXT = 'Create share text',
    UPDATE_SHARE_TEXT = 'Update share text',
    CREATE_SHATE_TEXT_WITH_DEFAULT_FUNCTIONALITY = 'Create share text ',
    CREATE_SHATE_TEXT_WITH_CLONE = 'Create share text (Cloned)',
}



export default function useDrawerConfig(getShareTexts: () => Promise<void>, activeDrawer?: DRAWER_TYPE, editShareText?: Sharetext, cloneShareText?: Sharetext, defaultFunctionalityCode?: string): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {
    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_SHATE_TEXT,
                width: 1000,
            },
            drawerContent: <CreateUpdateShareTextForm getShareTexts={getShareTexts} />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_SHATE_TEXT_WITH_DEFAULT_FUNCTIONALITY,
                width: 1000,
            },
            drawerContent: <CreateUpdateShareTextForm getShareTexts={getShareTexts} defaultFunctionalityCode={defaultFunctionalityCode} />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.UPDATE_SHARE_TEXT,
                width: 1000,
            },
            drawerContent: <CreateUpdateShareTextForm getShareTexts={getShareTexts} editShareText={editShareText} />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_SHATE_TEXT_WITH_CLONE,
                width: 1000,
            },
            drawerContent: <CreateUpdateShareTextForm getShareTexts={getShareTexts} cloneShareText={cloneShareText} />
        }
    ], [cloneShareText, defaultFunctionalityCode, editShareText, getShareTexts])

    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}