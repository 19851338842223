import { TablePaginationConfig } from "antd";
import { useState } from "react"

export const DEFAULT_PAGINATION_CONFIG = {
    current: 1,
    pageSize: 20,
    total: -1
}

const useAntdPagination = () => {
    const [pagination, setPagination] = useState<TablePaginationConfig>(DEFAULT_PAGINATION_CONFIG);
    return { pagination, setPagination }
}

export default useAntdPagination;