//hooks
//types
import React from 'react'

//organisms
import HubListPage from '@a4b/ui/src/modules/monetisation/organisms/HubListPage'

import { HubList } from '@a4b/api/src/modules/Monetisation/offerings/types'

export interface Props {
  triggerCreationForm: () => void
  hubList: HubList | undefined
  showAddNewHub: boolean
}

export const HubListScreen: React.FC<Props> = ({
  triggerCreationForm,
  hubList,
  showAddNewHub,
}) => {
  return (
    <HubListPage
      data={hubList}
      triggerCreationForm={triggerCreationForm}
      showAddNewHub={showAddNewHub}
    />
  )
}

export default HubListScreen
