import React from 'react';
import {
    Button,
    Card,
    Col,
    Row,
    Table,
    TableProps,
} from 'antd';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';

interface Props {
    tableConfig: TableProps<any>;
    setShowCategoryDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`;

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`;

const CategoryList: React.FC<Props> = ({
    tableConfig,
    setShowCategoryDrawer
}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const config: TableProps<any> = {
        ...tableConfig,
        pagination: false
    }

    return (
        <Container
            title={<Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Button type='primary' onClick={() => {
                    const newSearchParams = new URLSearchParams(searchParams.toString())
                    newSearchParams.set('edit', 'false')
                    setSearchParams(newSearchParams)
                    setShowCategoryDrawer(true)
                }}>
                    Create category
                </Button>
            </Row>}
            style={{ width: '100%' }}
            extra={
                <Row style={{ width: '100%', fontSize: '15px' }}>
                    Manage categories
                </Row>
            }
        >
            <Row>
                <Col span={28}>
                    <Table {...config} />
                </Col>
            </Row>
            <LabelRightAlign>
            </LabelRightAlign>
        </Container>
    );
};

export default CategoryList;
