import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'

import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { CloneScheduleFormFields, ScheduleList } from '@a4b/ui/src/admin'

import { CreateScheduleOptions, ScheduleType, ScheduleData } from '@a4b/api'
import moment from 'moment'
const LANGUAGE_KEY = 'LANGAUGE_KEY'
const saveLanguageToLocalStorage = (language: string) => window.localStorage.setItem(LANGUAGE_KEY, language)
const getLanguageToLocalStorage = () => window.localStorage.getItem(LANGUAGE_KEY)
const useLanguage = () => {
  const [languageCode, changeLanguageCode] = useState<string>(getLanguageToLocalStorage() || 'hi');
  const setLanguageCode = (val: string) => {
    saveLanguageToLocalStorage(val);
    changeLanguageCode(val)
  }
  return { languageCode, setLanguageCode }
}

const TagScheduleList = () => {
  const { languageCode, setLanguageCode } = useLanguage();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { networkInstance, userProfile } = useAppContext()
  const [scheduleType, setScheduleType] = useState<ScheduleType>(
    searchParams.get('type') as ScheduleType || ScheduleType.TAG_SCHEDULE
  )
  const [schedulesDates, setScheduledDates] = useState<ScheduleData[]>([])
  console.log("second one it")
  useEffect(() => {
    navigate(`/content/tag-schedule?type=${scheduleType}`, { replace: true })
  }, [navigate, scheduleType])

  useEffect(() => {
    async function initSchedules() {
      if (!languageCode) return
      const { data } =
        await networkInstance.clientWithHeaders.contentApi.schedule.getSchedulesPreview({
          resource: scheduleType,
          language: languageCode
        })
      if (data.data) {
        const schedule = Object.keys(data.data).map((key, index) => ({
          date: key,
          id: data.data[key],
        }))
        setScheduledDates(schedule)
      }
    }

    initSchedules()
  }, [languageCode, networkInstance.clientWithHeaders.contentApi.schedule, scheduleType])

  const handleCreateSchedule = (date: moment.Moment) => {
    navigate(
      `/content/tag-schedule/create?type=${scheduleType}&date=${date.format(
        'YYYY-MM-DD',
      )}&language=${languageCode}`,
    )
  }

  const handleViewSchedule = (schedule: ScheduleData) => {
    navigate(`/content/tag-schedule/edit/${schedule.id}?language=${languageCode}`)
  }

  const handleTagTypeChanged = (value: ScheduleType) => {
    setScheduleType(value)
  }

  const handleCloneScheduleSubmit = async (
    schedule: ScheduleData,
    values: CloneScheduleFormFields,
  ) => {
    const { data } =
      await networkInstance.clientWithHeaders.contentApi.schedule.getScheduleById(
        schedule.id,
      )
    const clonedScheduleData = data.data
    const cloneToDate = values.cloneTo

    const date = moment().format('YYYY-MM-DD') === cloneToDate.format('YYYY-MM-DD')
      ? moment().add(1, 'minute').toISOString()
      : cloneToDate.startOf('day').toISOString()

    const options: CreateScheduleOptions = {
      name: `Tag schedule for ${cloneToDate.format('DD-MM-YYYY')}`,
      date,
      ordering: clonedScheduleData.ordering,
      entity: scheduleType,
      createdBy: userProfile.email || '',
      updatedBy: userProfile.email || '',
      language: languageCode as string
    }

    try {
      const { data, status } =
        await networkInstance.clientWithHeaders.contentApi.schedule.createSchedule(options)
      const scheduleId = data.data.id
      const updatedScheduleDates = [...schedulesDates]
      updatedScheduleDates.push({
        id: scheduleId,
        date: cloneToDate.format('YYYY-MM-DD'),
      })
      setScheduledDates(updatedScheduleDates)
      const skippedLibraries = data.data.skippedLibraries
      if (skippedLibraries) {
        return { success: true, message: `Successfully created schedule, skipped ${skippedLibraries}` };
      }
      return { success: true, message: 'Successfully created schedule' };
    } catch (error) {
      return { success: false, message: 'Failed to create schedule' };
    }
  }

  const handleDeleteSchedule = async (schedule: ScheduleData) => {
    try {
      const response =
        await networkInstance.clientWithHeaders.contentApi.schedule.deleteScheduleById(schedule.id)
      if (response.status === 200) {
        const scheduleId = schedule.id
        const updatedScheduleDates = schedulesDates.filter((schedule) => schedule.id !== scheduleId)
        setScheduledDates(updatedScheduleDates)
        return true
      }
      return false
    } catch (error) {
      return false
    }
  }

  return (
    <div>
      <ScheduleList
        type={scheduleType}
        schedules={schedulesDates}
        onViewScheduleClick={handleViewSchedule}
        onCreateScheduleClick={handleCreateSchedule}
        onTagTypeChanged={handleTagTypeChanged}
        onCloneScheduleSubmit={handleCloneScheduleSubmit}
        onDeleteScheduleClick={handleDeleteSchedule}
        setLanguageCode={setLanguageCode}
        languageCode={languageCode}
      />
    </div>
  )
}

export default withDefaultLayout(TagScheduleList)
