import {
  cyclicDurationDeSerialize,
  cyclicDurationSerialize,
  throwError,
} from "../../../utils/helper";
/* eslint-disable @typescript-eslint/no-unused-vars */
type CyclicTags = {
  text: string;
  color: string;
};
type Item = {
  day: string;
  cyclic_duration: string;
  cyclic_tags: CyclicTags[];
  ctaText: string;
};
type HeaderTags = {
  text: string;
  color: string;
};
class Panchang {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  header!: string;
  headerTags!: HeaderTags[];
  items: Item[] = [];
  getSerializedData = (widgetFormData: Panchang) => {
    let itemsModified = widgetFormData?.items?.map((item: any) => {
      return {
        ...item,
        cyclic_duration: cyclicDurationSerialize(item.cyclic_duration),
        day: Number(item.day),
      };
    });
    widgetFormData.items = itemsModified;
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: Panchang) => {
    let itemsModified = widgetFormData?.items?.map((item: any) => {
      return {
        ...item,
        cyclic_duration: cyclicDurationDeSerialize(item.cyclic_duration),
      };
    });
    widgetFormData.items = itemsModified;
    return widgetFormData;
  };

  headerValidator = async (rule: any, value: any) => {
    if (!value) throwError("Header required!");
  };
  headerTagsTextValidator = async (rule: any, value: any) => {
    if (!value) throwError("Header tags text required!");
  };
  headerTagsColorValidator = async (rule: any, value: any) => {
    if (!value) throwError("Header tags color required!");
  };
  dayValidator = async (rule: any, value: any) => {
    if (value === undefined) throwError("Day required!");
  };
  cyclicDurationValidator = async (rule: any, value: any) => {
    if (!value) {
      throwError("Cyclic Duration must be required.");
    }
    if (value?.seconds() === 0) {
      throwError("Cyclic Duration must be greater than 0 seconds.");
    }
    if (!value) throwError("Cyclic Duration required!");
  };
  cyclicTagsValidator = async (rule: any, value: any) => {
    if (!value) throwError("Cyclic tags required!");
  };
  ctaTextValidator = async (rule: any, value: any) => {
    if (!value) throwError("CTA Text required!");
  };
  textTagValidator = async (rule: any, value: any) => {
    if (!value) throwError("Cyclic Text required!");
  };
  cyclicTagsCountValidator = async (rule: any, value: any, fields: any) => {
    if (!fields.length) throwError("Atleast one cyclic tags required!");
  };
}
export default Panchang;
export const PanchangInstance = new Panchang();
