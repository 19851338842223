import { CreateShareTextPayload, Functionality, Shareapp, Sharetext } from "@a4b/api/src/modules/ShareText/types"
import { Button, Checkbox, Form, Input, InputNumber, Select, Space, Tag, Typography, UploadFile, message } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../../components/AppContext";
import { AntdFileUploadV2, getAntUploadFile } from "../../../../../components/Forms/AntdFileUpload";
import { openNotification } from "../../../../../utils";
import { SHARE_TEXT_CONFIG } from "../../helpers/config";
import { NEW_CDN_HOST, SUPPORTED_LANGUAGES_LIST } from "../../../../../utils/constants";
import { InfoCircleFilled, InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormSelect } from "../../../../../components/Forms/Select";
import { debounce } from "lodash";
import { RcFile } from "antd/lib/upload";
import { AxiosResponse } from "axios";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";

const MediaUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const iconValidations = (file: RcFile) => {
  if (!file) return true;
  let isFailed = true;
  switch (file.type) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/jpg':
    case 'image/webp': {
      const isLt2M = file.size <= 300 * 1024;
      if (!isLt2M) {
        isFailed = false;
        message.error('Image must smaller than 300kb');
      }
      break;
    }
    case 'image/gif':
      {
        const allowedSize = file.size <= 1024 * 1024;
        if (!allowedSize) {
          isFailed = false;
          message.error('Gif Image must smaller than 1MB');
        }
      }
      break

    case 'video/mp4':
      const allowedSize = file.size <= (1024 * 1024) * 2;
      if (!allowedSize) {
        isFailed = false;
        message.error('Gif Image must smaller than 1MB');
      }
      break;

    default:
      message.error('File type is not allowed !');
  }

  return isFailed;
};

const DEFAULT_DYNAMIC_KEYS = ['feature_deeplink', 'app_deeplink'];
interface CreateUpdateShareTextFormProps {
  editShareText?: Sharetext | null
  cloneShareText?: Sharetext | null
  defaultFunctionalityCode?: string | undefined
  getShareTexts: () => Promise<void>
}
const CreateUpdateShareTextForm = (props: CreateUpdateShareTextFormProps) => {
  const form = Form.useForm<CreateShareTextPayload>()[0];
  // const form = Form.useForm();
  const { editShareText, defaultFunctionalityCode, getShareTexts, cloneShareText } = props;
  const { networkInstance } = useAppContext();
  const [shareFunctionlities, setShareFunctionalities] = useState<Functionality[] | null>()

  const uploadMedia = useCallback(async (options: any): Promise<{ url: string, path: string }> => {
    const headers = new Headers()
    headers.append('ContentType', options.file.type)
    let data;
    if (options.file.type.endsWith('png')) {
      data = await networkInstance.clientWithHeaders.shareText.getShareTextPreSignedUrlPng();
    }
    if (options.file.type.endsWith('jpg')) {
      data = await networkInstance.clientWithHeaders.shareText.getShareTextPreSignedUrlJpg();
    }
    if (options.file.type.endsWith('jpeg')) {
      data = await networkInstance.clientWithHeaders.shareText.getShareTextPreSignedUrlJpeg();
    }
    if (options.file.type.endsWith('gif')) {
      data = await networkInstance.clientWithHeaders.shareText.getShareTextPreSignedUrlGif();
    }
    if (options.file.type.endsWith('mp4')) {
      data = await networkInstance.clientWithHeaders.shareText.getShareTextPreSignedUrlMp4();
    }
    if (!data) {
      throw "Frailed to get presinged url"
    }

    const { url, path } = data.data.data;
    const res = await fetch(url, {
      method: 'PUT',
      body: options.file,
      headers,
    })
    return { url, path }
  }, [networkInstance.clientWithHeaders.shareText])





  const [shareApps, setShareApp] = useState<Shareapp[] | null>(null);


  const getShareApps = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.shareText.getShareApps(500, 0);
      setShareApp(res.data.data.share_apps)
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.shareText])



  const getShareFunctionalities = useCallback(async (functionalityText: string, subFunctionalityText: string) => {
    try {
      const res = await networkInstance.clientWithHeaders.shareText.searchShareFunctionalities(50, 0, { functionality_text: functionalityText, sub_functionality_text: subFunctionalityText });
      setShareFunctionalities(res.data.data.functionalities)
    } catch (error) {
    }
  }, [networkInstance.clientWithHeaders.shareText])

  const getShareFunctionalitiesDebounce = useMemo(() => debounce(getShareFunctionalities, 1000), [getShareFunctionalities])

  const createUpdateShareText = useCallback(async (values) => {
    if (Array.isArray(values.media) && values.media.length === 0) {
      values.media = null;
    }
    if (values.media && values.media.length > 0) {
      values.media = {
        path: values.media[0].response.path,
        type: values.media[0].response.path.endsWith('mp4') ? 'VIDEO' : 'IMAGE'
      }
    }
    if (editShareText) {
      try {
        await networkInstance.clientWithHeaders.shareText.upadteShareText(editShareText.id, values);
        getShareTexts();
        openNotification('success', { message: "Share text  updated sucessfully" })
      } catch (error) {
        openNotification('error', { message: "Failed to update Share text ", description: (error as string).toString() })
      }
    } else {
      try {
        await networkInstance.clientWithHeaders.shareText.createShareText(values);
        getShareTexts();
        openNotification('success', { message: "Share text  created sucessfully" })
      } catch (error) {
        openNotification('error', { message: "Failed to create Share text ", description: (error as string).toString() })
      }
    }
  }, [editShareText, getShareTexts, networkInstance.clientWithHeaders.shareText])

  useEffect(() => {
    if (editShareText) {
      const editShareTextCopy = editShareText;
      form.setFieldsValue({ ...editShareTextCopy, language: editShareText.language_code, media: editShareText.media ? getAntUploadFile(editShareText.media.path) : undefined })
    }
  }, [editShareText, form])

  useEffect(() => {
    if (defaultFunctionalityCode) {
      form.setFieldsValue({ share_functionality_code: defaultFunctionalityCode })
    }
  }, [defaultFunctionalityCode, form])

  useEffect(() => {
    if (cloneShareText) {
      console.log({ cloneShareText })
      form.setFieldsValue({ ...cloneShareText, language: '', media: cloneShareText.media ? getAntUploadFile(cloneShareText.media.path) : undefined })
    }
  }, [cloneShareText, form])


  useEffect(() => {
    getShareApps();
    getShareFunctionalities(editShareText?.share_functionality_code ? editShareText.share_functionality_code.split('_')[0] : '', '');
  }, [editShareText?.share_functionality_code, getShareApps, getShareFunctionalities])


  const functionalityCode = Form.useWatch('share_functionality_code', form);
  const media = Form.useWatch('media', form);
  const isApplicableForAllApps = Form.useWatch('is_applicable_to_all_share_apps', form);

  const shareText = Form.useWatch('share_text', form) || '';
  const shareTextPlain = Form.useWatch('share_text_plain', form) || '';
  const dynamicShareKeys = shareFunctionlities?.find((functionality) => functionality.code === functionalityCode)?.dynamic_share_keys || [];

  const enterDyanamicKeys = useCallback((dynamicKey: string) => {
    form.setFieldValue('share_text', `${shareText}{{.${dynamicKey}}}`)
  }, [form, shareText])

  const enterDyanamicKeysInPlanText = useCallback((dynamicKey: string) => {
    form.setFieldValue('share_text_plain', `${shareTextPlain}{{.${dynamicKey}}}`)
  }, [form, shareTextPlain])


  const addMediaUsingPath = useCallback((path: string) => {
    form.setFieldValue('media', getAntUploadFile(path))
  }, [form])

  return <div>
    <Form
      form={form}
      onFinish={createUpdateShareText}
      layout="vertical"
    >
      <Form.Item
        name={'identifier'}
        label={'Identifier'}
        required
        initialValue={'default'}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Language"
        name="language"
        rules={[{
          required: true,
        }
        ]}
      >
        <Select>
          {SUPPORTED_LANGUAGES_LIST.map((language) => <Select.Option value={language.value}>{language.name} ({language.alt_name})</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item
        name={"share_functionality_code"}
        label={"Share app functionality"}
        required
      >
        <Select
          onSearch={(val) => { if (val.length > 1) { getShareFunctionalitiesDebounce(val, '') } }}
          showSearch
          optionFilterProp="label"
          placeholder="Search using functionality"
        >
          {
            shareFunctionlities && shareFunctionlities.map((shareFunctionlity) => <Select.Option label={shareFunctionlity.functionality} value={shareFunctionlity.code}><div >
              {shareFunctionlity.code}
            </div></Select.Option>)
          }
        </Select>
      </Form.Item>
      {
        functionalityCode && <div>
          <Form.Item
            name={"share_text"}
            label={"Share text"}
            required
          >
            <TextArea style={{ minHeight: '150px' }} />
          </Form.Item>
          <div>
            <Typography.Paragraph> <InfoCircleOutlined></InfoCircleOutlined> Click below tags to enter dynamic keys </Typography.Paragraph>
            {
              dynamicShareKeys && dynamicShareKeys.map((dynamicKey) => {
                return <Tag onClick={(e) => { enterDyanamicKeys(dynamicKey) }} color="blue"> {dynamicKey}</Tag>
              })
            }
            {DEFAULT_DYNAMIC_KEYS.map((dynamicKey) => {
              return <Tag onClick={(e) => { enterDyanamicKeys(dynamicKey) }} color="blue"> {dynamicKey}</Tag>
            })}
          </div>
          <br></br>
        </div>
      }

      <Form.Item
        name={"share_text_plain"}
        label={"Share text plain"}
        required
      >
        <TextArea style={{ minHeight: '150px' }} />
      </Form.Item>
      {DEFAULT_DYNAMIC_KEYS.map((dynamicKey) => {
        return <Tag onClick={(e) => { enterDyanamicKeysInPlanText(dynamicKey) }} color="blue"> {dynamicKey}</Tag>
      })}
      <br></br>
      <Form.Item
        name={"is_applicable_to_all_share_apps"}
        label={"Applicable to all share apps"}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      {
        !isApplicableForAllApps && <Form.Item
          name={"share_app_ids"}
          label={"Share app id's"}
          required
        >
          <Select
            mode="multiple"

          >
            {
              shareApps && shareApps.map((shareApp) => <Select.Option value={shareApp.id}><div >
                <img src={NEW_CDN_HOST + shareApp.icon_path} height={20} width={20}></img> {shareApp.name}
              </div></Select.Option>)
            }
          </Select>
        </Form.Item>
      }
      <MediaUploadContainer>

        <AntdFileUploadV2
          rules={[{ required: false }]}
          name={'media'}
          accept="image/png,image/jpg,image/jpeg,image/gif,video/mp4"
          s3UploadApi={uploadMedia}
          title={'upload media'}
          fileValidation={iconValidations}
          fileList={media}
        />
        <div> Or</div>
        <Form.Item style={{ width: '70%', margin: 0 }}>
          <Input onChange={(e) => {
            if (e.target.value.trim().length > 0) {
              addMediaUsingPath(e.target.value.trim())
              e.target.value = '';
            }
          }} placeholder="Paste media path" />
        </Form.Item>
      </MediaUploadContainer>

      <Form.Item
        name={"is_platform_media_priority"}
        label={"Is platform media priority"}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item
        name={"active"}
        label={"Active"}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" >Submit</Button>
      </Form.Item>
    </Form>
  </div>
}


export default CreateUpdateShareTextForm;