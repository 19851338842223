import React, { useCallback, useEffect } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import { PlusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { ATTACHMENT } from '@/utils/enum';
import DeletableContainer from '../deletableContainer/DeletableContainer';
import HeaderTags from '../headerTags/HeaderTags';
import Attachment from '@/ui/organisms/Attachment';
import Riddler from '@/network/Riddler/Riddler';
import ContentCollection from '@/entities/feeds/Widgets/ContentCollection';
import NumberInput from '@/modules/feeds/components/Number';
import { ContentCollectionInstance } from '@/entities/feeds/Widgets';
import CommunityApi from '@/modules/social/api/Community';
import { Community } from '@/modules/social/types/community';
import { useAppContext } from '../../../../../components/AppContext';
import { CreateStore } from '@a4b/api/src/modules/Monetisation/Offerings/types';

interface Props {
  ctaActionType: any[];
  ctaRedirectionType: any[];
  riddlerInstance: any;
  communities?: Community[],
  contentInstance: ContentCollectionInstance;
}
const ContentCollectionWidget: React.FC<Props> = ({
  ctaActionType,
  ctaRedirectionType,
  riddlerInstance,
  communities,
  contentInstance,
}: Props) => {
  const { networkInstance } = useAppContext();
  const [storeList, setStoreList] = React.useState<CreateStore[]>([])
  const [templeList, setTempleList] = React.useState<CreateStore[]>([])

  const getStoreList = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.monetisationOfferingsApi.storesApi.getStoreList({
        limit: 1000,
        offset: 0,
      }, undefined, true);
      setTempleList(res.data.data.store_list.filter((store) => store.service_type === 'offerings'))
      setStoreList(res.data.data.store_list.filter((store) => store.service_type === 'pooja'))
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.monetisationOfferingsApi.storesApi])

  useEffect(() => {
    getStoreList()
  }, [getStoreList])

  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            required={true}
            label="Header"
            name={['content', 'header']}
            rules={[
              {
                validator: contentInstance.headerValidator,
              },
            ]}
          >
            <Input placeholder="Header" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <HeaderTags widgetClass={contentInstance} />
        </Col>
      </Row>
      <Form.List name={['content', 'items']}>
        {(fields, { add, remove, move }) => {
          return (
            <div>
              {fields.map((field: any, index: number) => {
                return (
                  <Form.Item key={field.key}>
                    <DeletableContainer
                      closeHandler={() => {
                        remove(field.name);
                      }}
                      itemText={`Item Attribute : ${index + 1}`}
                      showItemTextInBold={true}
                      moveFunction={move}
                      fields={fields}
                      currentIndex={index}
                      fieldsLength={fields.length}
                      move={move}
                      add={add}
                    >
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="Title"
                            name={[field.name, 'title']}
                            rules={[
                              {
                                validator: contentInstance.titleValidator,
                              },
                            ]}
                          >
                            <Input placeholder="Title" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="Subtitle"
                            name={[field.name, 'subtitle']}
                          >
                            <Input placeholder="Subtitle" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label="Thumbnail Image"
                            name={[field.name, 'image_attachment_id']}
                            rules={[
                              {
                                validator:
                                  contentInstance.thumbnailImageValidator,
                              },
                            ]}
                          >
                            <Attachment
                              attachmentType={ATTACHMENT.IMAGE}
                              riddlerInstance={riddlerInstance}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            name={[field.name, 'cta_action_type']}
                            label="CTA Action Type "
                            required={true}
                            rules={[
                              {
                                validator: contentInstance.ctaActionValidator,
                              },
                            ]}
                            hasFeedback={true}
                          >
                            <Select
                              placeholder="CTA Action Type"
                              options={ctaActionType}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              currentValues?.content?.items[field.name]
                                ?.cta_action_type !==
                              prevValues?.content?.items[field.name]
                                ?.cta_action_type
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue([
                                'content',
                                'items',
                                field.name,
                                'cta_action_type',
                              ]) === 'PLAY_SONG' ? (
                                <Row>
                                  <Col span={4}></Col>
                                  {'   '}
                                  <Col span={12}>
                                    <Form.Item
                                      {...field}
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 8 }}
                                      label="Song of the day"
                                      name={[field.name, 'song_of_the_day']}
                                      valuePropName="checked"
                                    >
                                      <Switch />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item
                                      noStyle
                                      shouldUpdate={(
                                        prevValues,
                                        currentValues
                                      ) =>
                                        currentValues?.content?.items[
                                          field.name
                                        ]?.song_of_the_day !==
                                        prevValues?.content?.items[field.name]
                                          ?.song_of_the_day
                                      }
                                    >
                                      {({ getFieldValue }) =>
                                        getFieldValue([
                                          'content',
                                          'items',
                                          field.name,
                                          'song_of_the_day',
                                        ]) != true ? (
                                          <Form.Item
                                            {...field}
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                            label="Song ID"
                                            name={[field.name, 'r_song_id']}
                                            rules={[
                                              {
                                                validator:
                                                  contentInstance?.songIdValidator,
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Song ID"
                                            />
                                          </Form.Item>
                                        ) : null
                                      }
                                    </Form.Item>
                                  </Col>
                                </Row>
                              ) : null
                            }
                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              currentValues?.content?.items[field.name]
                                ?.cta_action_type !==
                              prevValues?.content?.items[field.name]
                                ?.cta_action_type
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue([
                                'content',
                                'items',
                                field.name,
                                'cta_action_type',
                              ]) === 'RING_BELL' ||
                                getFieldValue([
                                  'content',
                                  'items',
                                  field.name,
                                  'cta_action_type',
                                ]) === 'PLAY_SHANKH' ||
                                getFieldValue([
                                  'content',
                                  'items',
                                  field.name,
                                  'cta_action_type',
                                ]) === 'FLOWER_SHOWER' ? (
                                <Form.Item
                                  {...field}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 16 }}
                                  label="God ID"
                                  name={[field.name, 'r_god_id']}
                                  tooltip={{
                                    title:
                                      'When God Id is 0 ,No value is configured.',
                                    icon: <InfoCircleOutlined />,
                                  }}
                                >
                                  <NumberInput
                                    initValue={0}
                                    placeholder="God ID"
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              currentValues?.content?.items[field.name]
                                ?.cta_action_type !==
                              prevValues?.content?.items[field.name]
                                ?.cta_action_type
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue([
                                'content',
                                'items',
                                field.name,
                                'cta_action_type',
                              ]) === 'OPEN_ARTICLE' ? (
                                <Form.Item
                                  {...field}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 16 }}
                                  label="Article ID"
                                  name={[field.name, 'r_article_id']}
                                  rules={[
                                    {
                                      validator:
                                        contentInstance.articleIdValidator,
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Article ID"
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              currentValues?.content?.items[field.name]
                                ?.cta_action_type !==
                              prevValues?.content?.items[field.name]
                                ?.cta_action_type
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue([
                                'content',
                                'items',
                                field.name,
                                'cta_action_type',
                              ]) === 'REDIRECTION' ? (
                                <Form.Item
                                  {...field}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 16 }}
                                  name={[field.name, 'cta_redirection_type']}
                                  label="Redirection Type"
                                  required={true}
                                  rules={[
                                    {
                                      validator:
                                        contentInstance.redirectionTypeValidator,
                                    },
                                  ]}
                                  hasFeedback={true}
                                >
                                  <Select
                                    placeholder="Redirection Type"
                                    options={ctaRedirectionType}
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              currentValues?.content?.items[field.name]
                                ?.cta_redirection_type !==
                              prevValues?.content?.items[field.name]
                                ?.cta_redirection_type
                            }
                          >
                            {({ getFieldValue }) => <>
                              {
                                getFieldValue([
                                  'content',
                                  'items',
                                  field.name,
                                  'cta_redirection_type',
                                ]) === 'POOJA_SERVICE' &&
                                <>
                                  <Form.Item
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    label={"Pooja Id "} name={[field.name, 'pooja_id']}>
                                    <Select>
                                      {
                                        storeList && storeList.map((item) => (<Select.Option value={item.id}>{item.title}</Select.Option>))
                                      }
                                    </Select>
                                  </Form.Item>
                                </>
                              }
                              {
                                getFieldValue([
                                  'content',
                                  'items',
                                  field.name,
                                  'cta_redirection_type',
                                ]) === 'CHADHAVA' &&
                                <>
                                  <Form.Item
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    label={"Temple Id "} name={[field.name, 'temple_id']}>
                                    <Select>
                                      {
                                        templeList && templeList.map((item) => (<Select.Option value={item.id}>{item.title}</Select.Option>))
                                      }
                                    </Select>
                                  </Form.Item>
                                </>

                              }
                            </>
                            }

                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              currentValues?.content?.items[field.name]
                                ?.cta_action_type !==
                              prevValues?.content?.items[field.name]
                                ?.cta_action_type
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue([
                                'content',
                                'items',
                                field.name,
                                'cta_action_type',
                              ]) === 'PLAY_VIDEO' ? (
                                <Form.Item
                                  {...field}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 16 }}
                                  name={[field.name, 'video_attachment_id']}
                                  label="Video Attachment ID"
                                  required={true}
                                  rules={[
                                    {
                                      validator:
                                        contentInstance.videoRefCodeValidator,
                                    },
                                  ]}
                                >
                                  <Attachment
                                    attachmentType={ATTACHMENT.VIDEO}
                                    riddlerInstance={riddlerInstance}
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              currentValues?.content?.items[field.name]
                                ?.cta_action_type !==
                              prevValues?.content?.items[field.name]
                                ?.cta_action_type
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue([
                                'content',
                                'items',
                                field.name,
                                'cta_action_type',
                              ]) === 'SOCIAL_COMMUNITY' ? (
                                <Form.Item
                                  {...field}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 16 }}
                                  name={[field.name, 'community_id']}
                                  label="Social Community Code"
                                >
                                  <Select>
                                    {
                                      communities && communities.map((item) => (<Select.Option value={item.code}>{item.en_name}</Select.Option>))
                                    }
                                  </Select>
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                    </DeletableContainer>
                  </Form.Item>
                );
              })}

              <Form.Item>
                <Row justify="end">
                  <Col span={24}>
                    <Row justify="end">
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            add();
                            // addItem();
                          }}
                          type="dashed"
                          icon={<PlusCircleOutlined />}
                          size="middle"
                        >
                          Add Item Attributes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
              <div style={{ height: '20px' }}></div>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

export default ContentCollectionWidget;
