import { Card, Empty } from 'antd'
import styled from 'styled-components'

import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'

import type { SortableItemData } from '../types'
import { SortableCardItem } from './CardItem'

interface CardListProps {
  limit: number
  items: SortableItemData[]
  onRemoveItem: (item: SortableItemData) => void
}

const Container = styled(Card)`
  .ant-card-body {
    display: flex;
    flex-wrap: wrap;
  }
`

const CardList: React.FC<CardListProps> = ({ items, limit, onRemoveItem }) => {
  return (
    <Container>
      {items && items.length > 0 ? (
        items.map((item, index) => (
          <SortableCardItem
            enabled={index + 1 <= limit}
            key={`item-${item.id}`}
            index={index}
            item={item}
            itemIndex={index}
            onRemoveItem={onRemoveItem}
          />
        ))
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '300px',
            alignItems: 'center',
          }}
        >
          <Empty description='please add items to rearrange' />
        </div>
      )}
    </Container>
  )
}

export const SortableCardList = SortableContainer(CardList)
