import { Button, Checkbox, Form, FormInstance, FormListFieldData, Input, InputNumber, Select } from "antd";
import styled from "styled-components";
import { LIVE_SUPPORTED_LANGUAGES } from "../../utils/contants";
import { REQUIRED_RULE } from "../../../../../../utils/form.validation";
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";

const LanguageFields = styled.div<{ attribute: string, backgroundColor?: string }>`
  border: 1px dotted #000;
  position: relative;
  border-radius: 5px;
  margin: 30px 0px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  padding-top: 30px;
  &::before {
    content: ${(props: { attribute: string }) => `"${props.attribute.toUpperCase()}"`};
    display: block;
    position: absolute;
    margin-left: 20px;
    margin-top: 0px;
    transform: translateY(calc(-50% - 30px));
    padding: 0px 15px; 
    font-size: 20px;
    background-color: ${props => props.backgroundColor || "#fff"};
  }
`

const OptionsContainer = styled.div`
  border: 1px solid #000;
  position: relative;
  border-radius: 5px;
  margin-bottom: 80px;
  margin-top: 80px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  &:before {
    content: "Options ";
    display: block;
    position: absolute;
    margin-left: 20px;
    margin-top: -20px;
    transform: translateY(-50%);
    background: ${(props: { headingBackground?: string }) => props.headingBackground || "#fff"};
    padding: 0px 15px; 
    font-size: 20px;
  }
  &>div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 3%;
    padding-bottom: 3%;
  }
  
`

const QuestionContainer = styled.div`
  border: 1px solid #000;
  position: relative;
  border-radius: 5px;
  margin-bottom: 80px;
  margin-top: 80px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  &:before {
    content: "Questions ";
    display: block;
    position: absolute;
    margin-left: 20px;
    margin-top: -20px;
    transform: translateY(-50%);
    background: ${(props: { headingBackground?: string }) => props.headingBackground || "#fff"};
    padding: 0px 15px; 
    font-size: 20px;
  }
  &>div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 3%;
    padding-bottom: 3%;
  }
`

interface NPSFeedbackTemplateQuestionProps {
    path: string[]
    backgroundColor: string,
    form: FormInstance<any>,
    questionField: FormListFieldData,
    questionFeilds: FormListFieldData[],
    add: (defaultValue?: any, insertIndex?: number | undefined) => void
    remove: (index: number | number[]) => void
}

const NPSFeedbackTemplateQuestion = (props: NPSFeedbackTemplateQuestionProps) => {
    const { path, backgroundColor, form, questionField, questionFeilds, add, remove } = props;
    const type = Form.useWatch(path.concat([questionField.name + '', "type"]), form);

    return <>
        <div style={{ margin: '2%', padding: '5%', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', position: 'relative' }}>
            {
                questionFeilds.length > 1 &&
                <CloseCircleFilled style={{ position: 'absolute', right: 20, top: 10, fontSize: '20px', color: 'red' }} onClick={() => { remove(questionField.name) }} color="red" />
            }
            <h2 style={{
                width: '100%',
                textAlign: 'center'
            }}> Question  {questionField.name + 1}</h2>
            <Form.Item name={[questionField.name, "code"]} label="Code" required>
                <Input></Input>
            </Form.Item>
            <LanguageFields attribute={"Title"} backgroundColor={backgroundColor}>
                {LIVE_SUPPORTED_LANGUAGES.map((lang) => {
                    return <Form.Item
                        key={lang.value}
                        name={[questionField.name, "title", lang.value]}
                        rules={lang.value === 'hi' ? REQUIRED_RULE : []}
                        label={lang.alt_name}
                        style={{ width: '100%' }}
                    >
                        <Input></Input>
                    </Form.Item>
                })}
            </LanguageFields>
            <Form.Item style={{ width: '100%' }} name={[questionField.name, "type"]} label="Type" required>
                <Select >
                    <Select.Option value="NQT_STAR_RATING">NQT_STAR_RATING</Select.Option>
                    <Select.Option value="NQT_TEXT_COMMENT">NQT_TEXT_COMMENT</Select.Option>
                </Select>
            </Form.Item>
            {type !== undefined ?
                (type === "NQT_STAR_RATING" ?
                    <Form.List name={[questionField.name, "options"]} initialValue={[{}]}>
                        {(fields, { add, remove }) => (
                            <OptionsContainer headingBackground={backgroundColor}>
                                {fields.map((field) => (
                                    <div >
                                        <LanguageFields attribute={"Option " + (field.name + 1)} backgroundColor={backgroundColor}>
                                            <CloseCircleFilled style={{ position: 'absolute', right: 20, top: 10, fontSize: '20px', color: 'red' }} onClick={() => { remove(field.name) }} color="red" />
                                            {LIVE_SUPPORTED_LANGUAGES.map((lang) => {
                                                return <Form.Item
                                                    key={lang.value}
                                                    name={[field.name, lang.value]}
                                                    rules={lang.value === 'hi' ? REQUIRED_RULE : []}
                                                    label={lang.alt_name}
                                                    style={{ width: '100%' }}
                                                >
                                                    <Input></Input>
                                                </Form.Item>
                                            })}
                                        </LanguageFields>
                                    </div>
                                ))}
                                {
                                    fields.length < 11 &&
                                    <div>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{ width: '60%' }}
                                            icon={<PlusOutlined />}
                                        >
                                            Add new option
                                        </Button>
                                    </div>
                                }
                            </OptionsContainer>
                        )}
                    </Form.List>
                    :
                    <>
                        <Form.Item style={{ width: '100%' }} name={[questionField.name, "__enter_placeholder_active"]} label="Enter placeholder" valuePropName="checked">
                            <Checkbox ></Checkbox>
                        </Form.Item>
                        <LanguageFields attribute={"Placeholder"} backgroundColor={backgroundColor}>
                            {LIVE_SUPPORTED_LANGUAGES.map((lang) => {
                                return <Form.Item
                                    key={lang.value}
                                    name={[questionField.name + '', "placeholder", lang.value]}
                                    rules={lang.value === 'hi' ? REQUIRED_RULE : []}
                                    label={lang.alt_name}
                                    style={{ width: '100%' }}
                                >
                                    <Input></Input>
                                </Form.Item>
                            })}
                        </LanguageFields>

                        <Form.Item name={[questionField.name, "limit",]} rules={REQUIRED_RULE} label={"Limit"}>
                            <InputNumber></InputNumber>
                        </Form.Item>
                    </>
                )
                : null
            }
        </div>
    </>
}

interface NPSFeedbackTemplateProps {
    path: string[]
    backgroundColor: string,
    form: FormInstance<any>
}
const NPSFeedbackTemplate = (props: NPSFeedbackTemplateProps) => {
    const { path, backgroundColor, form } = props;
    return <Form.List name={path} initialValue={[{}]}>
        {(questionFeilds, { add, remove }) => (
            <QuestionContainer headingBackground={backgroundColor} >
                {questionFeilds.map((questionFeild) => (
                    <NPSFeedbackTemplateQuestion
                        backgroundColor={backgroundColor}
                        path={path}
                        form={form}
                        questionField={questionFeild}
                        questionFeilds={questionFeilds}
                        add={add}
                        remove={remove}
                    />
                ))}
                {
                    questionFeilds.length < 11 &&
                    <div>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: '60%' }}
                            icon={<PlusOutlined />}
                        >
                            Add new question
                        </Button>
                    </div>
                }
            </QuestionContainer>
        )}
    </Form.List>

}

export default NPSFeedbackTemplate;