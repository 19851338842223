import React from 'react'

import { MetaDataEditForm, Props as MetaDataEditFormProps } from '../../components/MusicContent/MetaDataEditForm'
type Props = MetaDataEditFormProps

export const MusicMetaDataUpdate: React.FC<Props> = (props) => {
  return <div>
    <MetaDataEditForm {...props} />
  </div>
}
