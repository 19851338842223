import React from 'react'
import {
    Card,
    Col,
    Row,
    Space,
    Table,
    Tag,
    Tooltip
} from 'antd'
import styled from 'styled-components'

import type { TablePaginationConfig } from 'antd/es/table'
import { CallDetails } from '@a4b/api/src/modules/Monetisation/Astrologer/types'

interface Props {
    callList: CallDetails[]
    handleTableChange: (paginationParams: TablePaginationConfig) => void
    pagination: TablePaginationConfig
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`
const ManageCallListPage: React.FC<Props> = ({
    callList,
    handleTableChange,
    pagination
}) => {
    const dataColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: '15%',
            ellipsis: {
                showTitle: false,
            },
            render: (id: string) => (
                <Tooltip placement="topLeft" title={id}>
                    {id}
                </Tooltip>),
        },
        {
            title: 'User Id',
            dataIndex: 'user_id',
            key: 'user_id',
            width: '10%',
        },
        {
            title: 'Agent Id',
            dataIndex: 'agent_id',
            key: 'agent_id',
            width: '15%',
            ellipsis: {
                showTitle: false,
            },
            render: (agent_id: string) => (
                <Tooltip placement="topLeft" title={agent_id}>
                    {agent_id}
                </Tooltip>),
        },
        {
            title: 'Agent Number',
            dataIndex: 'agent_number',
            key: 'agent_number',
            render: (text: string) => {
                if (!text) {
                    return '-'
                } else {
                    { text }
                }
            }
        },
        {
            title: 'Charge',
            dataIndex: 'charge',
            key: 'charge',
            render: (text: string) => {
                if (text) {
                    return (
                        <Tag color='blue'>{text}</Tag>
                    )
                } else {
                    return (
                        <Tag color='blue'>-</Tag>
                    )
                }
            },
        },
        {
            title: 'Duration',
            key: 'duration',
            dataIndex: 'duration',
            render: (text: number,) => {
                const minutes = Math.floor(text / 60)
                return minutes
            },
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: 'Country',
            dataIndex: 'country_code',
            key: 'country_code',
            render: (text: string) => {
                return text === 'IN' ? 'India' : 'US'
            },
        },
        {
            title: 'Service provider',
            dataIndex: 'service_provider',
            key: 'service_provider',
        },
    ]
    return (
        <Container
            title='Manage Calls'
            style={{ width: '100%' }}
        >
            <Space direction={'vertical'} style={{ width: '100%' }}></Space>
            <Row>
                <Col span={28}>
                    <Table
                        sticky={true}
                        rowKey={(record: any) => record?.id}
                        columns={dataColumns}
                        dataSource={callList}
                        pagination={pagination}
                        onChange={handleTableChange}
                    />
                </Col>
            </Row>
            <LabelRightAlign>
            </LabelRightAlign>
        </Container>
    )
}

export default ManageCallListPage