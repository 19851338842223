import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { Collapse, notification, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import React from 'react';
import 'antd/dist/antd.css';
import { Button, Card, Divider, Space, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { AudioContentQueryParams, AudioContentState, AudioTag, BulkAudioStateUpdateOptions, Music } from '@a4b/api/src/modules/Content/Audio/types';
import MusicFilter, { ContentFilterFormFields } from '@a4b/ui/src/modules/admin/components/music/MusicFilter';
import { AUDIO_TRANSITION_CONFIG, MUSIC_STATES, THIRD_PARTY_NAMES } from '@/utils/music.constants';
import { CDN_BASE_URL } from '@/utils/constants';
import CsvUploadAudio from './CSVUploadAudio';
import PlaySongs from './PlaySongs';
import { getLanguageNameFromValue } from '@a4b/ui/src/modules/admin/constants';
const { Panel } = Collapse;
const { Text } = Typography;

const defaultFilter: ContentFilterFormFields = {
  status: 'NEW_AUDIO',
  title: '',
  singer: '',
  tag: '',
  audioType: '',
  audioLanguage: '',
  thirdPartyName: ['times_music']
}

const defaultPagination = {
  current: 1,
  pageSize: 50,
  total: -1
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
  notification[type]({
    message: title,
    description
  });
};

const MusicListing = () => {
  const [audios, setAudios] = useState<Music.Audio[]>();
  const [loading, setLoading] = useState(false);
  const { networkInstance } = useAppContext();
  const [filters, setFilters] = useState<ContentFilterFormFields>(defaultFilter);
  const [tags, setTags] = useState<AudioTag[]>([])
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);


  const loadAudio = useCallback(async () => {
    try {
      const pageSize = pagination.pageSize || 50;

      const getAudioParams: AudioContentQueryParams = {
        state: filters.status,
        partner_name: filters.thirdPartyName[0],
        limit: pageSize,
        offset: ((pagination.current || 1) - 1) * pageSize,
        audio_title: filters.title,
        audio_language: filters.audioLanguage,
        audio_type: filters.audioType,
        singer_name: filters.singer,
        tag: filters.tag
      }

      try {
        setLoading(true);
        const audioList = await networkInstance.clientWithHeaders.contentApi.audio.getAudio(getAudioParams)
        if (audioList.status === 204) {
          setAudios([]);
        } else {
          setAudios(audioList.data.data.audios);
          if (audioList.data.data.count !== pagination.total) {
            setPagination({ ...pagination, total: audioList.data.data.count })
          }
        }
        setLoading(false);
      } catch (error) {

      }
    } catch (error) {

    }
  }, [filters, networkInstance.clientWithHeaders.contentApi.audio, pagination])

  const loadTags = useCallback(async (tagQuery: string) => {
    try {
      const tagList = await networkInstance.clientWithHeaders.contentApi.audio.getTagsForAudio({ tag: tagQuery });
      if (tagList.status === 204) {
        setTags([])
      } else {
        setTags(tagList.data.data.tags)
      }
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.contentApi.audio])

  useEffect(() => {
    loadAudio();
  }, [loadAudio])

  useEffect(() => {
    loadTags('');
  }, [loadTags]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[] | undefined>()

  const translateAudioState = async (translateTo: string) => {
    if (selectedRowKeys) {
      const params: BulkAudioStateUpdateOptions = {
        audio_ids: selectedRowKeys.map((selectedRow) => selectedRow + ''),
        current_state: filters.status,
        update_state: translateTo as AudioContentState
      }
      try {
        await networkInstance.clientWithHeaders.contentApi.audio.bulkUpdateAudioState(params);
        setSelectedRowKeys(undefined);
        setFilters({ ...filters, status: translateTo as AudioContentState })
        openNotificationWithIcon('success', `audios translated to ${translateTo}`, `Ids 
        ${JSON.stringify(params.audio_ids)}
        `)
      } catch (error) {
        openNotificationWithIcon('error', 'translate error', JSON.stringify(error))
      }
    }
  }

  const onSelectedRowKeysChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  const onTagSearch = useCallback((tagQuery) => {
    loadTags(tagQuery)
  }, [loadTags])

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    // filters: Record<string, FilterValue>,
    // sorter: SorterResult<DataType>,
  ) => {
    setPagination(newPagination)
  };

  const columns = useMemo(() => [
    {
      title: 'S.no',
      dataIndex: 'id',
      render: (id: string, audioRecord: Music.Audio, index: number) => {
        return (((pagination.current || 1) - 1) * (pagination.pageSize || 50)) + index + 1
      }
    },
    {
      title: 'Thumbnail ',
      dataIndex: 'thumbnail_path',
      render: (thumbnailPath: string) => {
        return thumbnailPath ? <img src={CDN_BASE_URL + '/' + thumbnailPath} height={50} width={50} alt="thumbnail"></img> : ''
      }
    },
    {
      title: 'Audio Title',
      dataIndex: 'audio_title',
      render: (titleObj: { en: string, hi: string }, audioRecord: Music.Audio) => {
        return <Space direction="vertical">
          <Text>search : {audioRecord.audio_title_search}</Text>
          <Text>En : {titleObj.en}</Text>
          <Text>Hi : {titleObj.hi}</Text>
        </Space>
      }
    },
    {
      title: 'Singer',
      dataIndex: 'singer_name',
      render: (singer: { en: string, hi: string }, audioRecord: Music.Audio) => {
        return <Space direction="vertical">
          <Text>search : {audioRecord.singer_name_search}</Text>
          <Text>En : {singer.en}</Text>
          <Text>Hi : {singer.hi}</Text>
        </Space>
      }
    },
    {
      title: 'Partner Details',
      dataIndex: 'id',
      render: (id: string, audioRecord: Music.Audio) =>
        <Space direction="vertical">
          <Text>{audioRecord.partner_name}</Text>
          <Text>Unique code : {audioRecord.partner_audio_unique_identifier}</Text>
          <Text>Metadata version : {audioRecord.partner_metadata_version}</Text>
          <Text>Audio version : {audioRecord.audio_version}</Text>
        </Space>

    },
    {
      title: 'Language',
      dataIndex: 'audio_language',
      render: (language: string) => getLanguageNameFromValue(language)
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (tags: string[]) => <p>{tags.join(', ')}</p>
    }, {
      title: 'Other Details',
      dataIndex: ['original_path'],
      render: (originalPath: string, audioRecord: Music.Audio) => {
        return <Collapse >
          <Panel header={` Original Path: ${originalPath}`} key="">

            <p>
              MP3 Path: {audioRecord.mp3_path ? <> {CDN_BASE_URL + '/' + audioRecord.mp3_path} </> : '-'}
            </p>
            <p>
              Bitrate: {audioRecord.mp3_bitrate ? audioRecord.mp3_bitrate : '-'}
            </p>
            <p>
              Duration Seconds: {audioRecord.duration_miliseconds ? audioRecord.duration_miliseconds / 1000 : '-'}
            </p>
            <p>
              Riddler Audio Type: {audioRecord.riddler_audio_type}
            </p>
            <p>
              Lyrics Path: {audioRecord.lyrics_path ? CDN_BASE_URL + '/' + audioRecord.lyrics_path : '-'}
            </p>
            <p>
              Riddler Audio Id: {audioRecord.riddler_audio_id ? audioRecord.riddler_audio_id : '-'}
            </p>
            <p>
              Riddler Deeplink: {audioRecord.riddler_deeplink ? audioRecord.riddler_deeplink : '-'}
            </p>
            <p>
              Deeplink: {audioRecord.deeplink ? audioRecord.deeplink : '-'}
            </p>
            <p>
              M3u8 Path: {audioRecord.m3u8_path ? CDN_BASE_URL + '/' + audioRecord.m3u8_path : '-'}
            </p>
            <p>
              Video Share Path: {audioRecord.video_share_path ? audioRecord.video_share_path : '-'}
            </p>
            <p>

              Riddler God Id: {audioRecord.riddler_god_id ? audioRecord.riddler_audio_id : '-'}
            </p>
            <p>
              Created At: {new Date(audioRecord.created_at * 1000).toLocaleString()}
            </p>
            <p>
              Updated At: {new Date(audioRecord.updated_at * 1000).toLocaleString()}
            </p>
          </Panel>
        </Collapse>
      }
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (id: string, audioRecord: Music.Audio) => {
        return <div style={{ display: 'flex', flexDirection: 'column' }}>
          <a style={{ paddingLeft: '15px' }} type="link" href={`/music/edit/${id}`} target="_blank" rel="noreferrer"> edit</a>
          {audioRecord.mp3_path && <PlaySongs audioId={id} ></PlaySongs>}
          {/* songPath={CDN_BASE_URL + '/' + audioRecord.mp3_path} */}
        </div>
      }
    }
  ], [pagination]);

  return (
    <Card
      title='Music listing'
      extra={<CsvUploadAudio></CsvUploadAudio>}
    >
      <Space direction='vertical' size='middle' style={{ width: '100%' }} />
      <MusicFilter tags={tags} onTagSearch={onTagSearch} filter={filters} statusOptions={MUSIC_STATES} thirdPartyNameOptions={THIRD_PARTY_NAMES} onApplyFilterClicked={function (filter: ContentFilterFormFields): void {
        setFilters(filter);
        setPagination(defaultPagination);
      }}  ></MusicFilter>
      {
        selectedRowKeys && selectedRowKeys?.length > 0 && <div style={{ position: 'sticky', top: '-20px', zIndex: 100 }}>
          <Divider />
          {
            AUDIO_TRANSITION_CONFIG[filters.status].map((state) => <Button style={{ margin: '5px' }}
              onClick={() => { translateAudioState(state); }} key={state} type='primary' htmlType='submit'>
              Translate to {state}
            </Button>)
          }
        </div>
      }
      {audios ?
        <div>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={[...audios]}
            pagination={pagination}
            rowKey={record => record.id}
            loading={loading}
            onChange={handleTableChange}
            expandable={{ showExpandColumn: true, defaultExpandAllRows: true, defaultExpandedRowKeys: [''] }}
          />
        </div>
        : <div>loading</div>}
      <Link to={'/music/bulk-update'}> Bulk update audios</Link>
    </Card>

  )
}

export default withDefaultLayout(MusicListing)
