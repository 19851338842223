import React from "react"
import { Col, Form } from "antd"
import TextArea from "antd/lib/input/TextArea"


const TravelDetails = () => {
    return (
        <>
            <Col span={24}>
                <Col
                    style={{
                        marginLeft: '3px',
                        display: 'contents'
                    }}>
                    <Form.Item
                        label={`Air`}
                        name={['travel_details', 'air']}
                        labelCol={{ span: 28 }}
                        wrapperCol={{ span: 28 }}
                        style={{ marginTop: '10px' }}
                        rules={[
                            {
                                required: false
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter answer" />
                    </Form.Item>
                    <Form.Item
                        label={`Train`}
                        name={['travel_details', 'train']}
                        labelCol={{ span: 28 }}
                        wrapperCol={{ span: 28 }}
                        style={{ marginTop: '10px' }}
                        rules={[
                            {
                                required: false
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter answer" />
                    </Form.Item>
                    <Form.Item
                        label={`Road`}
                        name={['travel_details', 'road']}
                        labelCol={{ span: 28 }}
                        wrapperCol={{ span: 28 }}
                        style={{ marginTop: '10px' }}
                        rules={[
                            {
                                required: false
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter answer" />
                    </Form.Item>
                </Col>
            </Col>
        </>
    )
}

export default TravelDetails