//context
import { useAppContext } from '@/components/AppContext'
import { API_BASE_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import { message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { v4 as uuidv4 } from 'uuid';
//hoc
//organisms
//hooks
//types
import React, { useCallback, useEffect, useState } from 'react'

import StoryCreation from '@a4b/ui/src/modules/monetisation/molecules/storyCreation'

import { Product } from '@a4b/api/src/modules/Monetisation/Offerings/types'

import { formatStoryCreation, parseStoryCreation } from './helper'
import { AxiosRequestHeaders } from 'axios';
import { CDN_BASE_URL } from '@/utils/constants'
import moment from 'moment';

interface Props {
  showItemCreation: boolean
  isEditJourney: boolean
  createItemCallback?: (status: boolean) => void
  UpdateItemCallback?: (status: boolean) => void
  closeStoryCreation: () => void
  formData?: any
  isCloneJourney?: boolean
}

const CreateStory: React.FC<Props> = ({
  showItemCreation,
  closeStoryCreation,
  createItemCallback,
  UpdateItemCallback,
  isEditJourney,
  formData,
  isCloneJourney
}) => {
  const { networkInstance, userProfile } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

  const [editFormData, setEditFormData] = useState<any>()
  const [createItemForm] = useForm()
  const headers: AxiosRequestHeaders = {
    'X-AFB-R-UID': userProfile.email || '',
    'x-afb-app-ver': '1.0.0',
    'x-afb-device-id': window.navigator.userAgent,
    'x-afb-platform': 'web',
  }
  useEffect(() => {
    if (isEditJourney || isCloneJourney) {
      const editFormPrefill = parseStoryCreation(formData)
      const editForm = {
        ...formData, ...editFormPrefill,

      }
      setEditFormData(editForm)
      createItemForm.setFieldsValue(editForm)
    } else {
      setTimeout(() => {
        createItemForm.resetFields()
      }, 300)
      setEditFormData({})

    }
  }, [isEditJourney, formData, createItemForm, isCloneJourney])

  const deleteStory = async (id: string) => {
    try {
      await networkInstance.clientWithHeaders.shrineApi.deleteStory(id)
      closeStoryCreation();
    } catch (error) {
      console.log('Error in deleting story')
    }
  }

  const handleCreateOrUpdate = async (values: any) => {
    if (isEditJourney) {
      const updateFormData = formatStoryCreation(values)
      const id = formData?.id || ''
      const updateItem = await networkInstance?.clientWithHeaders?.shrineApi?.updateStory(
        { ...updateFormData, ...{ id: id } },
      )
      if (updateItem) {
        message.success('Story updated successfully')

        if (UpdateItemCallback) {
          UpdateItemCallback(true)
        }
      } else {
        message.error('Story update failed')
      }
      createItemForm.resetFields()
      closeStoryCreation()
    } else {
      const createFormData = formatStoryCreation(values)
      message.loading({ content: 'Creating item...', key: 'create-item' })
      await networkInstance?.clientWithHeaders?.shrineApi?.createStory(createFormData)
      message.success({
        content: 'Story Created Successfully',
        duration: 2,
        key: 'create-story',
      })
      createItemForm.resetFields()
      closeStoryCreation()
    }
  }

  const formSubmithandler = () => {

    createItemForm
      .validateFields()
      .then(values => {
        handleCreateOrUpdate(values)
      })
      .catch(errorInfo => { })
  }


  const onFormChange = (formValues: any, allFields: any) => {
    if (formValues?.[0]?.name?.[0] === "header_use_same" && formValues?.[0]?.value) {
      const hi = createItemForm.getFieldValue(['header_texts', 'hi']);
      createItemForm?.setFieldValue(['header_texts', 'gu'], hi)
      createItemForm?.setFieldValue(['header_texts', 'mr'], hi)
      createItemForm?.setFieldValue(['header_texts', 'hi-hr'], hi)
      createItemForm?.setFieldValue(['header_texts', 'hi-bj'], hi)
      createItemForm?.setFieldValue(['header_texts', 'hi-rj'], hi)
      createItemForm?.setFieldValue(['header_texts', 'en'], hi)
    }
    if (formValues?.[0]?.name?.[0] === "description_use_same" && formValues?.[0]?.value) {
      const hi = createItemForm.getFieldValue(['description_texts', 'hi']);
      createItemForm?.setFieldValue(['description_texts', 'gu'], hi)
      createItemForm?.setFieldValue(['description_texts', 'mr'], hi)
      createItemForm?.setFieldValue(['description_texts', 'hi-hr'], hi)
      createItemForm?.setFieldValue(['description_texts', 'hi-bj'], hi)
      createItemForm?.setFieldValue(['description_texts', 'hi-rj'], hi)
      createItemForm?.setFieldValue(['description_texts', 'en'], hi)
    }
    if (formValues?.[0]?.name?.[0] === "icon_use_same" && formValues?.[0]?.value) {
      const hi = createItemForm.getFieldValue(['icon_texts', 'hi']);
      createItemForm?.setFieldValue(['icon_texts', 'gu'], hi)
      createItemForm?.setFieldValue(['icon_texts', 'mr'], hi)
      createItemForm?.setFieldValue(['icon_texts', 'hi-hr'], hi)
      createItemForm?.setFieldValue(['icon_texts', 'hi-bj'], hi)
      createItemForm?.setFieldValue(['icon_texts', 'hi-rj'], hi)
      createItemForm?.setFieldValue(['icon_texts', 'en'], hi)
    }
    if (formValues?.[0]?.name?.[0] === "share_use_same" && formValues?.[0]?.value) {
      const hi = createItemForm.getFieldValue(['share_text', 'hi']);
      createItemForm?.setFieldValue(['share_text', 'gu'], hi)
      createItemForm?.setFieldValue(['share_text', 'mr'], hi)
      createItemForm?.setFieldValue(['share_text', 'hi-hr'], hi)
      createItemForm?.setFieldValue(['share_text', 'hi-bj'], hi)
      createItemForm?.setFieldValue(['share_text', 'hi-rj'], hi)
      createItemForm?.setFieldValue(['share_text', 'en'], hi)
    }
    if (formValues?.[0]?.name?.[0] === "cta_use_same" && formValues?.[0]?.value) {
      const hi = createItemForm.getFieldValue(['cta', 'hi']);
      createItemForm?.setFieldValue(['cta', 'gu'], hi)
      createItemForm?.setFieldValue(['cta', 'mr'], hi)
      createItemForm?.setFieldValue(['cta', 'hi-hr'], hi)
      createItemForm?.setFieldValue(['cta', 'hi-bj'], hi)
      createItemForm?.setFieldValue(['cta', 'hi-rj'], hi)
      createItemForm?.setFieldValue(['cta', 'en'], hi)
    }
    if (formValues?.[0]?.name?.[1] === "is_external") {
      createItemForm?.setFieldValue(['cta', 'is_external'], true)
    }
  }
  return (
    <StoryCreation
      visible={showItemCreation}
      onClose={closeStoryCreation}
      apiBaseUrl={API_BASE_URL}
      url={CDN_BASE_URL}
      formSubmithandler={formSubmithandler}
      form={createItemForm}
      initialValues={editFormData}
      isEditJourney={isEditJourney}
      isCloneJourney={isCloneJourney}
      headers={headers}
      onFormChange={onFormChange}
      deleteStory={deleteStory}
    />
  )
}

export default CreateStory
