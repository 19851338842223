import { Card } from "antd"
import styled from "styled-components"
import { Condition, ConditionType, Rule } from "../utils/types"
import ConditionForm from "./ConditionForm"
import CreateRootRule from "./CreateRootRule";


const StyledCard = styled(Card) <{ cardType?: ConditionType }>`
    width: 400px;
`;

const StyledCondition = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-left: 150px;
    position: relative;
    border-left: 1px solid #f5f5f5;
    &.root {
        border: none;
        padding: 30px;
    }
`

const RenderCondition = ({ conditions, modifyCondition, identifier, type, eventsAndProperties, commonEventProperties }: {
    conditions: Partial<Condition>[],
    modifyCondition: (identifier: string | undefined, condition?: Condition) => void,
    identifier: string
    type: ConditionType.AND | ConditionType.OR,
    eventsAndProperties: any;
    commonEventProperties: any;
}) => {
    return <StyledCondition >
        {conditions.map((item, index) => {
            return item.type === 'EVAL' ?
                <StyledCard >
                    <ConditionForm
                        eventsAndProperties={eventsAndProperties}
                        commonEventProperties={commonEventProperties}
                        modifyCondition={modifyCondition}
                        identifier={identifier + index}
                        formData={item}
                    />
                </StyledCard>
                :
                <>
                    <StyledCard >
                        <ConditionForm
                            eventsAndProperties={eventsAndProperties}
                            commonEventProperties={commonEventProperties}
                            identifier={identifier + index} formData={item} modifyCondition={modifyCondition}></ConditionForm>
                    </StyledCard>
                    {

                        'conditions' in item && <RenderCondition
                            eventsAndProperties={eventsAndProperties}
                            commonEventProperties={commonEventProperties}
                            type={item.type as (ConditionType.AND | ConditionType.OR)} conditions={item.conditions as Partial<Condition>[]} modifyCondition={modifyCondition} identifier={identifier + index} />
                    }
                </>
        })}
        <StyledCard style={conditions.length < 2 ? { borderColor: 'red' } : {}} >
            <ConditionForm eventsAndProperties={eventsAndProperties}
                commonEventProperties={commonEventProperties}
                modifyCondition={modifyCondition} identifier={identifier + (conditions.length + 1)} ></ConditionForm>
        </StyledCard>
    </StyledCondition>
}

interface CreateConditionProps {
    rule: Rule | undefined;
    modifyCondition: (identifier: string | undefined, condition?: Condition) => void;
    eventsAndProperties: any;
    commonEventProperties: any;
}

const CreateRules = (props: CreateConditionProps) => {
    const { rule, modifyCondition, eventsAndProperties, commonEventProperties } = props;
    const renderRoot = () => {
        if (rule) {
            if ('property' in rule.condition && rule.condition?.type === ConditionType.EVAL) {
                return <StyledCard cardType={rule.condition?.type}>
                    <CreateRootRule eventsAndProperties={eventsAndProperties} commonEventProperties={commonEventProperties} modifyCondition={modifyCondition} identifier={'root'} formData={rule} />
                </StyledCard>
            } else {
                return <><StyledCard cardType={rule.condition.type}>
                    <CreateRootRule eventsAndProperties={eventsAndProperties} commonEventProperties={commonEventProperties} identifier={'root'} formData={rule} modifyCondition={modifyCondition}></CreateRootRule>
                </StyledCard>
                    {
                        'conditions' in rule.condition ?
                            <RenderCondition
                                eventsAndProperties={eventsAndProperties} commonEventProperties={commonEventProperties}
                                type={rule.condition.type} conditions={rule.condition.conditions as Partial<Condition>[]} modifyCondition={modifyCondition} identifier={"root"} />
                            : null
                    }
                </>
            }
        } else {
            return <CreateRootRule eventsAndProperties={eventsAndProperties} commonEventProperties={commonEventProperties} identifier={'root'} modifyCondition={modifyCondition}></CreateRootRule>
        }
    }
    return <StyledCondition className="root">
        {renderRoot()}
    </StyledCondition>
}
export default CreateRules;