import { Col, Form, Row, Select, Button } from "antd"
import React, { } from "react"
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { CreateStore } from "@a4b/api/src/modules/Monetisation/offerings/types"

interface Props {
    fields: any
    add: () => void
    remove: (item: any) => void
    storeList: CreateStore[]
    getInventoryList: (storeId: string) => void
    inventoryList: any
}

const SanakalpBanner: React.FC<Props> = ({ fields, add, remove, storeList, getInventoryList, inventoryList }) => {
    return (
        <Form.Item
            noStyle
            shouldUpdate={true}
            labelCol={{ span: 22 }}
            wrapperCol={{ span: 22 }}
        >
            {({ getFieldValue }) =>
                <>
                    <Row justify="space-between" style={{ width: '100%' }}>
                        {fields.map((field: any, index: number) => {
                            return (
                                <div key={index} style={{
                                    border: '1px solid #e0dcdc',
                                    marginRight: '0px',
                                    marginBottom: '5px',
                                    width: '100%',
                                    padding: '10px',
                                }}>
                                    <Row style={{ justifyContent: 'space-between', width: '100%' }}>
                                        <Form.Item
                                            label={`Select Store - ${index + 1}`}
                                            name={[field.name, 'store_id']}
                                            labelCol={{ span: 28 }}
                                            wrapperCol={{ span: 28 }}
                                            style={{ flexBasis: '45%' }}
                                            rules={[{ required: true, message: 'Please select Store' }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Search to Select Store"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label as string)?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA: any, optionB: any) =>
                                                    ((optionA?.name as string) || '')?.toLowerCase()?.localeCompare((optionB?.name as string) || '')
                                                }
                                                options={storeList?.filter(item => item?.active === true)?.map((store) => ({
                                                    label: store.title,
                                                    value: store.id,
                                                }))}
                                                onChange={(value) => {
                                                    getInventoryList(String(value))
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={`Select Inventory - ${index + 1}`}
                                            name={[field.name, 'inventory_id']}
                                            labelCol={{ span: 28 }}
                                            wrapperCol={{ span: 28 }}
                                            style={{ flexBasis: '45%' }}
                                            rules={[{ required: true, message: 'Please select Inventory' }]}
                                        >
                                            <Select
                                                placeholder="Select Inventory"
                                                optionFilterProp="children"
                                                options={inventoryList?.[getFieldValue(['data', 'sankalp_banner_items', index])?.store_id]}
                                            />
                                        </Form.Item>

                                        <Button
                                            style={{ flexBasis: '5%', marginTop: '30px' }}
                                            onClick={() => {
                                                remove(field.name);
                                            }}
                                            type='ghost'
                                            icon={<DeleteOutlined />}
                                            size='middle'
                                            danger
                                        />
                                    </Row>
                                </div>
                            );
                        })
                        }
                    </Row>
                    <Col span={24} style={{ paddingTop: '10px' }}>
                        <Row justify='start'>
                            <Col span={4}>
                                <Button
                                    onClick={() => {
                                        add()
                                    }}
                                    type='ghost'
                                    icon={<PlusCircleOutlined />}
                                    size='middle'
                                >
                                    Sankalp banner
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </>
            }
        </Form.Item>
    )
}

export default SanakalpBanner