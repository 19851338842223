import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { Response } from '../../../types'
import type {
  BulkCloneTagContentScheduleOptions,
  ContentSchedule,
  CreateScheduleOptions,
  SchedulePreviewParams,
} from './types'
import { apiClient } from '../../../apiClient'

class ScheduleApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createSchedule = (options: CreateScheduleOptions) =>
    this.api.post<Response<{ id: string; skippedLibraries: number }>>(
      `/content/internal/v1/library/schedule`,
      options,
    )

  updateSchedule = (id: string, options: Partial<CreateScheduleOptions>) =>
    this.api.put(`/content/internal/v1/library/schedule/${id}`, options)

  getScheduleById = (id: string) =>
    this.api.get<Response<ContentSchedule>>(
      `/content/internal/v1/library/schedule/${id}`,
    )

  deleteScheduleById = (id: string) =>
    this.api.delete(`/content/internal/v1/library/schedule/${id}`)

  getSchedulesPreview = (params: SchedulePreviewParams) =>
    this.api.get<Response<{ [date: string]: string }>>(
      `/content/internal/v1/library/schedule/preview`,
      {
        params,
      },
    )

  bulkCloneTagContentSchedules = (
    options: BulkCloneTagContentScheduleOptions,
  ) => this.api.post(`/content/internal/v1/library/schedule/clone`, options)
}

export default ScheduleApi
