export const defaultFromItemProps = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const platformList = [
  { value: "ANDROID", label: "ANDROID" },
  { value: "IOS", label: "IOS" },
  { value: "DESKTOP", label: "DESKTOP" },
  { value: "MOBILE", label: "MOBILE" },
];
export const propertyList = [
  { value: "home_page", label: "Home Page" },
  { value: "menu", label: "Menu Page" },
  { value: "profile", label: "Profile" },
];
export const widgetTypeList = [
  { value: "hp_action", label: "HP Action" },
  { value: "hp_appShare", label: "HP App Share" },
  { value: "hp_youtube_schedule", label: "Youtube Schedule" },
  { value: "hp_panchang", label: "HP Panchang" },
  { value: "hp_heroImage", label: "HP Hero Image" },
  { value: "hp_heroVideo", label: "HP Hero Video" },
  { value: "hp_heroArticle", label: "HP Hero Article" },
  { value: "hp_songPlaylist", label: "HP Song Playlist" },
];
export const daysOfWeek = [
  { value: "All", label: "All" },
  { value: "Sun", label: "Sun" },
  { value: "Mon", label: "Mon" },
  { value: "Tue", label: "Tue" },
  { value: "Wed", label: "Wed" },
  { value: "Thr", label: "Thr" },
  { value: "Fri", label: "Fri" },
  { value: "Sat", label: "Sat" },
];
export const getAllDays = ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"];
export const ActionWidgetCtaType = [{ value: "ONLY_ICON", label: "ONLY_ICON" }];
/* export const ActionWidgetCtaType = [
  { value: "ONLY_TEXT", label: "ONLY_TEXT" },
  { value: "TEXT_WITH_ICON", label: "TEXT_WITH_ICON" },
  { value: "ONLY_ICON", label: "ONLY_ICON" },
  { value: "FULL", label: "FULL" },
]; */
export const ActionWidgetCtaIconType = [
  { value: "PLAY_ICON", label: "PLAY_ICON" },
  { value: "BELL_ICON", label: "BELL_ICON" },
  { value: "FLOWER_ICON", label: "FLOWER_ICON" },
  { value: "CONCH_ICON", label: "CONCH_ICON" },
  { value: "LIVE_CTA_ICON", label: "LIVE_CTA_ICON" },
  { value: "WHATSAPP_SHARE_ICON", label: "WHATSAPP_SHARE_ICON" },
  { value: "REMINDER_ICON", label: "REMINDER_ICON" },
];
export const ActionWidgetCtaActionType = [
  { value: "OLD", label: "OLD" },
  { value: "FLOWER_SHOWER", label: "FLOWER_SHOWER" },
  { value: "PLAY_SONG", label: "PLAY_SONG" },
  { value: "RING_BELL", label: "RING_BELL" },
  { value: "SOFT_REMINDER", label: "SOFT_REMINDER" },
  { value: "DIYA", label: "DIYA" },
  { value: "GARLAND", label: "GARLAND" },
  { value: "REDIRECT", label: "REDIRECT" },
  { value: "YOUTUBE_IN_APP", label: "YOUTUBE_IN_APP" },
];
export const TagsColor = [
  { label: "GREEN", value: "#00bd68" },
  { label: "RED", value: "#f7493e" },
  { label: "DEFAULT_ORANGE", value: "#ee8111" },
];
export const CyclicTagsIcon = [
  { label: "SUN", value: "SUN" },
  { label: "MOON", value: "MOON" },
  { label: "ARBITRARY", value: "ARBITRARY" },
];
export const HeroContentFeatureType = [
  { label: "SUVICHAR_TEXT", value: "SUVICHAR_TEXT" },
  { label: "SUVICHAR_IMAGE", value: "SUVICHAR_IMAGE" },
  { label: "GEETA", value: "GEETA" },
  { label: "TV", value: "TV" },
  { label: "RASHIFAL", value: "RASHIFAL" },
];
export const PanchangDays = [
  { label: "YESTERDAY", value: -1 },
  { label: "TODAY", value: 0 },
  { label: "TOMORROW", value: 1 },
  { label: "DAY AFTER TOMORROW", value: 2 },
];

export const orientationType = [
  { label: "HORIZONTAL_LIST", value: "HORIZONTAL_LIST" },
  { label: "HORIZONTAL_CAROUSEL", value: "HORIZONTAL_CAROUSEL" },
  { label: "VERTICAL_LIST", value: "VERTICAL_LIST" },
  { label: "GRID", value: "GRID" },
  { label: "CUSTOM", value: "CUSTOM" },
];

export const ctaActionType = [
  { label: "REMINDER", value: "REMINDER" },
  { label: "PLAY_SHANKH", value: "PLAY_SHANKH" },
  { label: "RING_BELL", value: "RING_BELL" },
  { label: "FLOWER_SHOWER", value: "FLOWER_SHOWER" },
  { label: "PLAY_SONG", value: "PLAY_SONG" },
  { label: "PLAY_VIDEO", value: "PLAY_VIDEO" },
  { label: "OPEN_ARTICLE", value: "OPEN_ARTICLE" },
  { label: "REDIRECTION", value: "REDIRECTION" },
];

export const videoAttachmentType = [
  { label: "INTERNAL_CUSTOM", value: "INTERNAL_CUSTOM" },
  { label: "INTERNAL_YOUTUBE", value: "INTERNAL_YOUTUBE" },
  { label: "EXTERNAL_YOUTUBE", value: "EXTERNAL_YOUTUBE" },
];
export const ctaRedirectionType = [
  { label: "MANDIR", value: "MANDIR" },
  { label: "PANCHANG", value: "PANCHANG" },
  { label: "SUJHAV_FEED", value: "SUJHAV_FEED" },
  { label: "LIBRARY", value: "LIBRARY" },
  { label: "SUVICHAR_TEXT", value: "SUVICHAR_TEXT" },
  { label: "SUVICHAR_MORNING", value: "SUVICHAR_MORNING" },
  { label: "SUVICHAR_EVENING", value: "SUVICHAR_EVENING" },
  { label: "SUVICHAR_NIGHT", value: "SUVICHAR_NIGHT" },
  { label: "GEETA_MAIN", value: "GEETA_MAIN" },
  { label: "GEETA_CHAPTER_1", value: "GEETA_CHAPTER_1" },
  { label: "GEETA_CHAPTER_2", value: "GEETA_CHAPTER_2" },
  { label: "GEETA_CHAPTER_3", value: "GEETA_CHAPTER_3" },
  { label: "GEETA_CHAPTER_4", value: "GEETA_CHAPTER_4" },
  { label: "GEETA_CHAPTER_5", value: "GEETA_CHAPTER_5" },
  { label: "GEETA_CHAPTER_6", value: "GEETA_CHAPTER_6" },
  { label: "GEETA_CHAPTER_7", value: "GEETA_CHAPTER_7" },
  { label: "GEETA_CHAPTER_8", value: "GEETA_CHAPTER_8" },
  { label: "GEETA_CHAPTER_9", value: "GEETA_CHAPTER_9" },
  { label: "GEETA_CHAPTER_10", value: "GEETA_CHAPTER_10" },
  { label: "GEETA_CHAPTER_11", value: "GEETA_CHAPTER_11" },
  { label: "GEETA_CHAPTER_12", value: "GEETA_CHAPTER_12" },
  { label: "GEETA_CHAPTER_13", value: "GEETA_CHAPTER_13" },
  { label: "GEETA_CHAPTER_14", value: "GEETA_CHAPTER_14" },
  { label: "GEETA_CHAPTER_15", value: "GEETA_CHAPTER_15" },
  { label: "GEETA_CHAPTER_16", value: "GEETA_CHAPTER_16" },
  { label: "GEETA_CHAPTER_17", value: "GEETA_CHAPTER_17" },
  { label: "GEETA_CHAPTER_18", value: "GEETA_CHAPTER_18" },
  { label: "TV", value: "TV" },
  { label: "COIN_MAIN", value: "COIN_MAIN" },
  { label: "COIN_RULES", value: "COIN_RULES" },
  { label: "COIN_LEVELS", value: "COIN_LEVELS" },
  { label: "COIN_PASSBOOK", value: "COIN_PASSBOOK" },
  { label: "HORA", value: "HORA" },
  { label: "CHOGHADIYA", value: "CHOGHADIYA" },
  { label: "OFFERING_BOTTOMSHEET", value: "OFFERING_BOTTOMSHEET" },
  { label: "RASHIFAL", value: "RASHIFAL" },
  { label: "PROFILE", value: "PROFILE" },
  { label: "LOGIN", value: "LOGIN" },
  { label: "KUNDLI", value: "KUNDLI" },
  { label: "ALARM_LIST", value: "ALARM_LIST" },
  { label: "ALARM_PLAYLIST", value: "ALARM_PLAYLIST" },
];
export const sortByOptions = [
  { label: "Created", value: "CREATED" },
  { label: "Updated", value: "UPDATED" },
];

export const sortByCreated = (a: any, b: any) => {
  return b?.created_at - a?.created_at;
};
export const sortByUpdated = (a: any, b: any) => {
  return b?.updated_at - a?.updated_at;
};
