import React, { useCallback, useState } from 'react'
import { Button, ColProps, Form, Input, Upload, message } from 'antd'
import { v4 as uuidv4 } from 'uuid';
import { FileImageOutlined, LinkOutlined, UploadOutlined } from '@ant-design/icons';
import _ from 'lodash';

//types
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { FormInstance } from 'antd/es/form/Form';
import { NEW_CDN_HOST } from '../../utils/constants';
import { Rule } from 'antd/lib/form';
import { isValidHttpUrl } from '../../utils/helper';


const EXTENSION_TO_TYPE_MAPPING_BACKEND = {
    'png': 'PNG',
    'jpg': 'JPG',
    'jpeg': 'JPG',
    'webp': 'WEBP',
    'json': 'JSON',
    'pdf': 'PDF',
    'gif': 'GIF',
    'mp3': 'MP3',
    'mp4': 'MP4',
    'm3u8': 'M3U8',
    'svg': 'SVG'
}

const EXTENSION_TO_TYPE_MAPPING = {
    'png': 'image/png',
    'jpg': 'image/jpg',
    'jpeg': 'image/jpeg',
    'webp': 'image/webp',
    'json': 'json',
    'pdf': 'pdf',
    'gif': 'image/gif',
    'mp3': 'audio/mp3',
    'mp4': 'video/mp4',
    'm3u8': 'audio/m3u8',
    'svg': 'image/svg'
}

export enum UPLOAD_TYPES {
    UPLOAD = "UPLOAD",
    LINK = "LINK"
}

const UploadButton = () => (
    <>
        <div style={{ marginTop: 5, marginBottom: 5 }}> <UploadOutlined /> &nbsp;
            Click to Upload
        </div>
    </>
)

const getAcceptTypeExtention = (acceptType: string) => {
    if (acceptType.indexOf(".") > -1) {
        return acceptType.split('.').pop();
    } else {
        return acceptType.split('/').pop();
    }
}

interface Props {
    name: (string | number)[],
    form: FormInstance<any>,
    getS3PathApi: (fileType?: { type: string }, options?: any) => Promise<{ url: string, path: string, type?: string }>,
    formListPath?: (string | number)[],
    accept?: string
    rules?: Rule[] | undefined
    sizeLimitInMB?: number
    disabled?: boolean
    defaultUploadType?: UPLOAD_TYPES
    allowUploadUsingUrl?: boolean
    labelCol?: ColProps
}

/*
    Right now just image upload is supported. If needed we will support other file upload options later 
*/

const SimpleImageUplaod: React.FC<Props> = (props) => {
    const {
        name,
        form,
        accept = 'image/*',
        formListPath,
        getS3PathApi,
        disabled = false,
        rules,
        sizeLimitInMB = 2,
        allowUploadUsingUrl = false,
        labelCol,
        defaultUploadType = UPLOAD_TYPES.UPLOAD
    } = props;
    const [uploadType, setUploadType] = useState(defaultUploadType)
    const getDataForUpload = () => {
        const ref_content_code = `cdn_upload_${uuidv4()}`
        return {
            ref_content_code,
            media_type: 'IMAGE',
        }
    }

    const uploadAction: UploadProps['customRequest'] = useCallback(async (options: any) => {
        const headers = new Headers()
        headers.append('ContentType', options.file.type)
        const { path, url, type } = await getS3PathApi({ type: options.file.type }, options);
        const res = await fetch(url, {
            method: 'PUT',
            body: options.file,
            headers,
        })
        if (options.onSuccess) options.onSuccess(options.file);
        setTimeout(() => {
            form.setFieldValue(formListPath || name, { path: path, type });
        }, 100)
    }, [form, formListPath, getS3PathApi, name])

    const beforeUpload = useCallback((file: RcFile) => {
        let isValidFile = false;
        if (accept === 'image/*' && file.type.startsWith("image")) {
            isValidFile = true;
        }
        if (accept === 'audio/*' && file.type.startsWith("audio")) {
            isValidFile = true;
        }
        if (accept === 'video/*' && file.type.startsWith("video")) {
            isValidFile = true;
        }

        const acceptArray = accept.split(",")
        acceptArray.forEach((_accept) => {
            const acceptExt = getAcceptTypeExtention(_accept)?.toLowerCase();
            if (acceptExt === file.type.split("/").pop()?.toLowerCase()) {
                isValidFile = true;
            }
            if ((acceptExt === "mp3" || acceptExt === "mp4") && file.type.split("/").pop()?.toLowerCase() === "mpeg") {
                isValidFile = true
            }
        })
        if (!isValidFile) {
            message.error(`${file.type} is not allowed. Allowed types are ${accept}`, 5)
        }
        const isLt2M = file.size / 1024 / 1024 < sizeLimitInMB;
        if (!isLt2M) {
            message.error(`Size must smaller than ${sizeLimitInMB}MB!`, 5);
        }
        return isValidFile && isLt2M;
    }, [accept, sizeLimitInMB]);

    const formImage = Form.useWatch(formListPath || name, form);

    const file: UploadFile<any> | undefined = formImage ?
        {
            uid: '-1',
            name: formImage?.path?.replace(/^.*[\\\/]/, ''),
            status: 'done',
            url: NEW_CDN_HOST + formImage.path,
        } : undefined;

    const uploadUsingUrl = useCallback((event) => {
        const urlOrPath = event.target.value;
        const file: any = {
            type: EXTENSION_TO_TYPE_MAPPING[urlOrPath.split('.').pop() as keyof typeof EXTENSION_TO_TYPE_MAPPING_BACKEND] || '',
            size: 0
        }
        if (beforeUpload(file)) {
            if (isValidHttpUrl(urlOrPath)) {
                let path = new URL(urlOrPath).pathname;
                path = path.length > 3 && path.substring(0, 5) === '/gw1/' ? path.slice(4) : path;
                form.setFieldValue(formListPath || name, {
                    path: path,
                    type: EXTENSION_TO_TYPE_MAPPING_BACKEND[urlOrPath.split('.').pop() as keyof typeof EXTENSION_TO_TYPE_MAPPING_BACKEND]
                })
            } else {
                form.setFieldValue(formListPath || name, {
                    path: urlOrPath,
                    type: EXTENSION_TO_TYPE_MAPPING_BACKEND[urlOrPath.split('.').pop() as keyof typeof EXTENSION_TO_TYPE_MAPPING_BACKEND]
                })
            }
        }
    }, [beforeUpload, form, formListPath, name])

    return (
        <Form.Item
            labelCol={labelCol}
            name={name}
            rules={rules}
            label={_.startCase(name[name.length - 1] + '')}
            valuePropName="list"
        >
            <Upload.Dragger
                disabled={disabled}
                maxCount={1}
                // disabled={file ? true : false}
                fileList={file ? [file] : undefined}
                multiple={false}
                customRequest={uploadAction}
                accept={accept}
                beforeUpload={beforeUpload}
                name='input_file'
                listType="picture"
                // onChange={handleUploadChange}
                onRemove={() => {
                    setTimeout(() => {
                        form.setFieldValue(formListPath || name, undefined)
                    })
                }}
                data={() => getDataForUpload()}
                style={{ position: 'relative' }}
            >
                {
                    allowUploadUsingUrl && <>
                        <Button size='small' disabled={uploadType === UPLOAD_TYPES.UPLOAD} onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setUploadType(UPLOAD_TYPES.UPLOAD)
                        }} style={{ position: 'absolute', right: 30, top: 2 }}>
                            <FileImageOutlined  ></FileImageOutlined>
                        </Button>
                        <Button size='small' disabled={uploadType === UPLOAD_TYPES.LINK} onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setUploadType(UPLOAD_TYPES.LINK)
                        }} style={{ position: 'absolute', right: 2, top: 2 }}>
                            <LinkOutlined  ></LinkOutlined>
                        </Button>
                    </>
                }
                {
                    uploadType === UPLOAD_TYPES.UPLOAD ?
                        <UploadButton />
                        :
                        <Input
                            onBlur={uploadUsingUrl}
                            onKeyDown={function (event) {
                                if (event.keyCode === 13) {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    uploadUsingUrl(event);
                                }
                            }}
                            disabled={disabled}
                            onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                            }}
                            style={{ width: "calc( 100% - 80px )", float: 'left', marginLeft: 10 }} placeholder='Paste url or path...' />
                }
            </Upload.Dragger>

        </Form.Item>
    )
}

export default SimpleImageUplaod;