export const GET_MASTER_WIDGETS = "carnage/internal/v1/master-widgets";
export const CREATE_WIDGET = "carnage/internal/v1/widgets";
export const DELETE_WIDGET = "carnage/internal/v1/widgets";
export const GET_PLATFORMS = "carnage/internal/v1/platforms";
export const GET_PROPERTIES = "carnage/internal/v1/platform-properties";
export const GET_WIDGETS = "carnage/internal/v1/widgets";
export const DISABLE_WIDGETS = "carnage/internal/v1/widgets";
export const SEARCH_WIDGETS = "carnage/internal/v1/widgets/search?";
export const GET_SCHEDULE = "carnage/internal/v1/schedules";
export const DELETE_SCHEDULE = "carnage/internal/v1/schedules";
export const DISABLE_SCHEDULE = "carnage/internal/v1/schedules";
export const CREATE_SCHEDULE = "carnage/internal/v1/schedules";
export const PREVIEW_SCHEDULE = "carnage/internal/v1/schedules/preview";
export const GET_CTA_REDIRECTION_TYPE = "carnage/internal/v1/cta/redirection-types";
export const GET_CTA_ACTION_TYPE = "carnage/internal/v1/cta/action-types";
export const DEACTIVATE_WIDGET = "carnage/internal/v1/widgets";
export const SEARCH_BY_WIDGET_CODE = "carnage/internal/v1/widgets/search-by-code";

export const GET_S3_PATH =
  "expresso/mandir/service-api/universal-content/contentById.php?contentId=";
export const UC_FILE_UPLOAD =
  "expresso/mandir/service-api/universal-content/addContent.php";
export const SEARCH_BY_UC_CODE_FOR_UPLOAD =
  "expresso/mandir/service-api/universal-content/contentsByCode.php?limit=30&code=";
