import type { ActionInstance } from '@/entities/feeds/Widgets'
import Riddler from '@/network/Riddler/Riddler'
import Attachment from '@/ui/organisms/Attachment'
import { ATTACHMENT } from '@/utils/enum'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, Form, FormInstance, Input, Radio, Row } from 'antd'

import React from 'react'

import DeletableContainer from '../deletableContainer/DeletableContainer'

interface Props {
  form: FormInstance
  riddlerInstance: any
  contentInstance: ActionInstance
}

const ImageWidget: React.FC<Props> = ({
  form,
  contentInstance,
  riddlerInstance,
}) => {
  const validator = (key: string, _: any, value: string) => {
    const values = form.getFieldsValue()
    if (!values || !values.content || !values.content.items) {
      return Promise.resolve()
    }

    const reject = values.content.items.some((item: any) => item[key] !== value)
    if (reject) {
      return Promise.reject(new Error(`${key} should be same for all items`))
    }
    return Promise.resolve()
  }

  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            required={true}
            label='Header'
            name={['content', 'header']}
          >
            <Input placeholder='Header' />
          </Form.Item>
        </Col>
      </Row>
      <Form.List name={['content', 'items']}>
        {(fields, { add, remove, move }) => {
          return (
            <div>
              {fields.map((field: any, index: number) => {
                return (
                  <Form.Item key={field.key}>
                    <DeletableContainer
                      closeHandler={() => {
                        remove(field.name)
                      }}
                      itemText={`Item Attribute : ${index + 1}`}
                      showItemTextInBold={true}
                      moveFunction={move}
                      fields={fields}
                      currentIndex={index}
                      fieldsLength={fields.length}
                      move={move}
                      add={add}
                    >
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label='Image'
                            name={[field.name, 'image_attachment_id']}
                            rules={[
                              {
                                validator:
                                  contentInstance?.imageAttachmentValidator,
                              },
                            ]}
                          >
                            <Attachment
                              attachmentType={ATTACHMENT.IMAGE}
                              riddlerInstance={riddlerInstance}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label='Orientation'
                            name={[field.name, 'orientation']}
                            rules={[
                              {
                                validator: validator.bind(this, 'orientation'),
                              },
                            ]}
                          >
                            <Radio.Group>
                              <Radio value={'LANDSCAPE'}>Landscape</Radio>
                              <Radio value={'PORTRAIT'}>Portrait</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label='Image Type'
                            name={[field.name, 'image_type']}
                            rules={[
                              { validator: validator.bind(this, 'image_type') },
                            ]}
                          >
                            <Radio.Group>
                              <Radio value={'STORY'}>Story</Radio>
                              <Radio value={'STATIC'}>Static</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label='Expandable'
                            name={[field.name, 'is_expandable']}
                            rules={[
                              {
                                required: true,
                                message: 'Please set Expandable to True',
                              },
                            ]}
                          >
                            <Radio.Group>
                              <Radio value={true}>True</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </DeletableContainer>
                  </Form.Item>
                )
              })}

              <Form.Item>
                <Row justify='end'>
                  <Col span={24}>
                    <Row justify='end'>
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            add()
                          }}
                          type='dashed'
                          icon={<PlusCircleOutlined />}
                          size='middle'
                        >
                          Add Item Attributes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
              <div style={{ height: '20px' }}></div>
            </div>
          )
        }}
      </Form.List>
    </>
  )
}

export default ImageWidget
