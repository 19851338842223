import { CreateStore } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { HeroContentInstance } from '@/entities/feeds/Widgets'
import NumberInput from '@/modules/feeds/components/Number'
import Riddler from '@/network/Riddler/Riddler'
import Attachment from '@/ui/organisms/Attachment'
import { ATTACHMENT } from '@/utils/enum'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Form, Input, Row, Select, Switch } from 'antd'

import React from 'react'

import DeletableContainer from '../deletableContainer/DeletableContainer'
import HeaderTags from '../headerTags/HeaderTags'
import TextArea from 'antd/lib/input/TextArea'

interface Props {
  ctaRedirectionType: any[]
  riddlerInstance: any
  contentInstance: HeroContentInstance
  storeList: CreateStore[]
}
const HeroContentWidget: React.FC<Props> = ({
  ctaRedirectionType,
  riddlerInstance,
  contentInstance,
  storeList
}: Props) => {
  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            required={true}
            label='Header'
            name={['content', 'header']}
            rules={[
              {
                validator: contentInstance.headerValidator,
              },
            ]}
          >
            <Input placeholder='Header' />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ offset: 4, span: 16 }}
            name={['content', 'show_item_details']}
            initialValue={true}
            valuePropName='checked'
          >
            <Checkbox>show item attributions</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <HeaderTags widgetClass={contentInstance} />
        </Col>
      </Row>
      <Form.List name={['content', 'items']}>
        {(fields, { add, remove, move }) => {
          return (
            <div>
              {fields.map((field: any, index: number) => {
                return (
                  <Form.Item key={field.key}>
                    <DeletableContainer
                      closeHandler={() => {
                        remove(field.name)
                      }}
                      itemText={`Item Attribute : ${index + 1}`}
                      showItemTextInBold={true}
                      moveFunction={move}
                      fields={fields}
                      currentIndex={index}
                      fieldsLength={fields.length}
                      move={move}
                      add={add}
                    >
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label='Title'
                            name={[field.name, 'title']}
                            rules={[
                              {
                                validator: contentInstance.titleValidator,
                              },
                            ]}
                          >
                            <Input placeholder='Title' />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label='Subtitle'
                            name={[field.name, 'subtitle']}
                            rules={[
                              {
                                validator: contentInstance.subtitleValidator,
                              },
                            ]}
                          >
                            <Input placeholder='Subtitle' />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label='CTA Text'
                            name={[field.name, 'cta_text']}
                            rules={[
                              {
                                validator: contentInstance.ctaTextValidator,
                              },
                            ]}
                          >
                            <Input placeholder='CTA text' />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={false}
                            label='Is Expandable'
                            name={[field.name, 'is_expandable']}
                            valuePropName='checked'
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label='Image Attachment'
                            name={[field.name, 'image_attachment_id']}
                            rules={[
                              {
                                validator:
                                  contentInstance.imageAttachmentValidator,
                              },
                            ]}
                          >
                            <Attachment
                              attachmentType={ATTACHMENT.IMAGE}
                              riddlerInstance={riddlerInstance}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            name={[field.name, 'cta_redirection_type']}
                            label='Redirection Type'
                            // onChange={onCTARedirectionChange}
                            required={true}
                            rules={[
                              {
                                validator:
                                  contentInstance.redirectionTypeValidator,
                              },
                            ]}
                            hasFeedback={true}
                          >
                            <Select
                              placeholder='Redirection Type'
                              options={ctaRedirectionType}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                              return true
                            }}
                          >
                            {({ getFieldValue }) => {
                              const ctaRedirection = getFieldValue([
                                'content',
                                'items',
                                field.name,
                                'cta_redirection_type',
                              ])
                              if (ctaRedirection === 'ARTICLE') {
                                return <Form.Item
                                  {...field}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 16 }}
                                  required={true}
                                  label='Article Id'
                                  name={[field.name, 'r_article_id']}
                                >
                                  <Input
                                    placeholder="Article ID"
                                  />
                                </Form.Item>
                              }

                              if (ctaRedirection === 'CHADHAVA') {
                                return <Form.Item
                                  {...field}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 16 }}
                                  label='Temple Id'
                                  name={[field.name, 'temple_id']}
                                >
                                  <Select
                                    placeholder="Temple id"
                                  >
                                    {
                                      storeList.map((store) => {
                                        return <Select.Option value={store.id}>
                                          {store.title}
                                        </Select.Option>
                                      })
                                    }
                                  </Select>
                                </Form.Item>
                              }
                              if (ctaRedirection === 'POOJA_SERVICE') {
                                return (<Form.Item
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 16 }}
                                  label={"Pooja Id "} name={[field.name, 'pooja_id']}>
                                  <Select>
                                    {
                                      storeList && storeList.map((item) => (<Select.Option value={item.id}>{item.title}</Select.Option>))
                                    }
                                  </Select>
                                </Form.Item>)
                              }
                              if (ctaRedirection === 'EXTERNAL') {
                                return (<Form.Item
                                  {...field}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 16 }}
                                  required={true}
                                  label='External Url'
                                  name={[field.name, 'external_url']}
                                  rules={[{
                                    type: "url",
                                    message: "This field must be a valid url."
                                  }]}
                                >
                                  <Input
                                    placeholder="External Url"
                                  />
                                </Form.Item>)
                              }

                              return null
                            }}
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                              return true
                            }}
                          >
                            {({ getFieldValue }) => {
                              return getFieldValue([
                                'content',
                                'items',
                                field.name,
                                'cta_redirection_type',
                              ]) === 'WEB' ? (
                                <Form.Item
                                  {...field}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 16 }}
                                  required={true}
                                  label='Web URL'
                                  name={[field.name, 'web_url']}
                                >
                                  <Input
                                    placeholder='Web URL'
                                  />
                                </Form.Item>
                              ) : null
                            }}
                          </Form.Item>
                        </Col>
                      </Row>
                    </DeletableContainer>
                  </Form.Item>
                )
              })}

              <Form.Item>
                <Row justify='end'>
                  <Col span={24}>
                    <Row justify='end'>
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            add()
                            // addItem();
                          }}
                          type='dashed'
                          icon={<PlusCircleOutlined />}
                          size='middle'
                        >
                          Add Item Attributes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
              <div style={{ height: '20px' }}></div>
            </div>
          )
        }}
      </Form.List>
    </>
  )
}

export default HeroContentWidget
