import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode } from "react";
import AddRashifal from "../components/AddRashifal";
import EditRashifal from "../components/EditRashifal";
import { Rashifal } from "@/../../../packages/a4b-api/src/modules/Content/Rashifal/types";


export enum DRAWER_TYPE {
    CREATE_RASHI = 'Create Rashi',
    EDIT_RASHI = 'Edit Rashi',
}

export default function useDrawerConfix(rashifal?: Rashifal.RashifalItem, activeDrawer?: DRAWER_TYPE): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_RASHI,
                width: 1000,
            },
            drawerContent: <AddRashifal></AddRashifal>
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.EDIT_RASHI,
                width: 900,
            },
            drawerContent: rashifal && <EditRashifal rashifal={rashifal}></EditRashifal>
        }
    ];
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}