import { SortableItemAudio } from '@a4b/api/src/modules/Content/Audio/types'
import { arrayMoveImmutable } from 'array-move'

import React from 'react'
import { SortEndHandler } from 'react-sortable-hoc'

import { SortableCardList } from './CardList'

export interface Props {
  value?: SortableItemAudio[]
  limit: number
  onChange?: (value: SortableItemAudio[]) => void
  onRemoveItem: (value: SortableItemAudio) => void
  language_code: string
}

export const SortableCards: React.FC<Props> = ({
  value = [],
  limit,
  onChange,
  onRemoveItem,
  language_code
}) => {
  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const updatedItems = arrayMoveImmutable(value, oldIndex, newIndex)
    onChange && onChange(updatedItems)
  }

  return (
    <SortableCardList
      axis='xy'
      limit={limit}
      items={value}
      onRemoveItem={onRemoveItem}
      onSortEnd={onSortEnd}
      language_code={language_code}
      distance={1}
    />
  )
}
