import { Store } from "@/../../../packages/a4b-api/src/modules/Mandir/god.types";
import { Shrine } from "@/../../../packages/a4b-api/src/modules/Temple/types";
import { useAppContext } from "@/components/AppContext";
import { FormItemCheckbox } from "@/components/Forms/Checkbox";
import { FormItemSelect } from "@/components/Forms/Select";
import { openNotification } from "@/utils";
import { Form, Select, Button, Card } from "antd";
import { useState, useCallback, useEffect } from "react";
import { SortableContainer, SortEndHandler, SortableElement } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'


export interface SortStoresProps {
  god: Shrine.God;
}

const SortStores = ({ god }: SortStoresProps) => {
  const { networkInstance } = useAppContext();
  const [form] = Form.useForm();
  const [showSortStore, setShowSortStore] = useState(false)
  const [stores, setStores] = useState<Store.StoreList[]>();
  const [selectedSortedStores, setSelectedSortedStores] = useState<string[]>([])
  const [avatarCodes, setAvatarCodes] = useState<string[]>([]);

  const getStores = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.mandirApi.getStores();
      setStores(res.data.data.store_list.filter((store) => store.active))
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.mandirApi])


  const loadGodAvatars = useCallback(async () => {
    try {
      console.log("this is gettong called")
      const res = await networkInstance.clientWithHeaders.shrineApi.getGodAvatarCodes(god.id);
      // @ts-ignore
      setAvatarCodes(res.data.avatar_codes);
    } catch (error) {
    }
  }, [god.id, networkInstance.clientWithHeaders.shrineApi])

  useEffect(() => {
    getStores()
    loadGodAvatars();
  }, [getStores, loadGodAvatars])

  const postSortesStores = useCallback(async (values) => {
    const payload: Store.UpdateIconStorePayload = {
      god_code: god.code,
      god_avatar_code: values['selectAvatar'],
      sort_stores: !!values['sortStores'],
      sorted_store_ids: selectedSortedStores
    }
    try {
      networkInstance.clientWithHeaders.mandirApi.updateIconStoreList(payload);
      openNotification('success', { message: 'Store list updated for avatar code - ' + payload.god_avatar_code })
    } catch (error) {
      openNotification('error', { message: 'Store update failed ' })
    }
  }, [god.code, networkInstance.clientWithHeaders.mandirApi, selectedSortedStores])

  const sortStores = Form.useWatch('sortStores', form);
  const selectAvatar = Form.useWatch('selectAvatar', form);


  const getAvatarStores = useCallback(async (selectAvatar: string) => {
    try {
      const res = await networkInstance.clientWithHeaders.mandirApi.getAvatarStores(god.code, selectAvatar);
      if (res.data.data?.store_ids.length > 0) {
        setSelectedSortedStores(res.data.data?.store_ids)
      }
    } catch (error) {

    }
  }, [god.code, networkInstance.clientWithHeaders.mandirApi])

  useEffect(() => {
    if (selectAvatar) {
      getAvatarStores(selectAvatar)
    }
  }, [getAvatarStores, selectAvatar])


  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const updatedItems = arrayMoveImmutable(selectedSortedStores, oldIndex, newIndex)
    setSelectedSortedStores(updatedItems)
  }


  console.log({ selectedSortedStores })

  return <div style={{ margin: '20px' }}>
    <Button onClick={() => { setShowSortStore(!showSortStore) }}> {showSortStore ? 'Cancel' : 'Sort store for this god'}</Button>
    <br></br>
    <br></br>
    <br></br>
    {
      showSortStore &&
      <Form
        form={form}
        onFinish={postSortesStores}
        // style={{ maxWidth:  }}
        layout="vertical"
      >
        <FormItemSelect
          name={"selectAvatar"}
          antdFormItemProps={{ rules: [{ required: true }], }}
          antdSelectProps={{ style: { width: '100%' } }}
          optionList={avatarCodes.map((avatarCode) => <Select.Option key={avatarCode} value={avatarCode}> {avatarCode} </Select.Option>)}
        />
        <FormItemCheckbox name={'sortStores'} />
        {
          sortStores && stores &&
          <>
            <FormItemSelect
              name={"selectStoresToSort"}
              antdSelectProps={{
                mode: 'multiple', style: { width: '100%' }, onChange: (storeIds) => {
                  setSelectedSortedStores(storeIds);
                }, value: selectedSortedStores
              }}
              optionList={stores.map((store) => <Select.Option label={<><img src={store.icon_url} alt="store-icon" height={30} width={30} /> &nbsp; {store.title}</>} value={store.id}><img src={store.icon_url} alt="store-icon" height={30} width={30} /> &nbsp; {store.title} </Select.Option>)}
            />
            <SortableStores axis="xy" onSortEnd={onSortEnd} stores={selectedSortedStores.map((item) => (stores || []).find((store) => store.id === item) as Store.StoreList)} />
          </>
        }
        <br></br>
        <br></br>
        <Form.Item>
          <Button type="primary" htmlType="submit" >Submit</Button>
        </Form.Item>
      </Form>
    }
  </div>
}

export interface StoresProps {
  stores: Store.StoreList[]
}

export interface StoreItemProps {
  store: Store.StoreList,
  sNo: number
}

const StoreItem = SortableElement((props: StoreItemProps) => {
  const { store, sNo } = props;
  return <Card style={{ margin: '15px 0px', zIndex: '10000', }} bodyStyle={{ display: 'flex', flexDirection: 'row', gap: '15px', alignItems: 'center' }} >
    <img src={store.icon_url} style={{ border: '1px solid rgb(0,0,0,0.3)', padding: '1px', height: '60px' }}></img>
    <p>{store.title}</p>
  </Card>

})

const Stores = (props: StoresProps) => {
  const { stores } = props;
  return <Card style={{ width: '600px' }}>
    {stores.map((store, index) => <StoreItem store={store} key={store.id} index={index} sNo={index + 1}></StoreItem>)}
  </Card>
}

const SortableStores = SortableContainer(Stores);




export default SortStores;




