import { throwError } from "../../utils/helper";

class Widget {
  id?: string;
  name!: string;
  code!: string;
  description?: string;
  widget_type!: string;
  archive!: boolean;
  active!: boolean;
  orientation_type!: string;
  created_by!: string;
  created_at!: string;

  constructor(widget: any) {
    this.id = widget.id;
    this.name = widget.name;
    this.code = widget.code;
    this.description = widget.description;
    this.widget_type = widget.widget_type;
    this.active = widget.active;
    this.archive = widget.archive;
    this.orientation_type = widget.orientation_type;
    this.created_by = widget.created_by;
    this.created_at = widget.created_at;
  }

  static getInitialValues() {
    return new Widget({
      name: "",
      code: "",
      description: "",
      widget_type: "",
    });
  }

  static codeValidator = async (rule: any, value: any) => {
    if (!value) throwError("Code required!");
    let pattern = /^(?:[A-Z0-9]+_{0,1})+[A-Z0-9]+$/i;
    if (!pattern.test(value)) {
      throwError(
        "Code must be only alphanumeric and underscore will be allowed in the middle."
      );
    }
    if (value && value.length > 64) {
      throwError("Code must be lessthan or equal to 64 chars");
    }
  };
  static descriptionValidator = async (rule: any, value: any) => {
    if (!value) throwError("Description required!");
    if (value && value.length > 128) {
      throwError("Description must be lessthan or equal to 128 chars");
    }
  };
  static widgetTypeValidator = async (rule: any, value: any) => {
    if (!value) throwError("Widget Type required!");
  };
  static orientationTypeValidator = async (rule: any, value: any) => {
    if (!value) throwError("Orientation Type required!");
  };
}

export default Widget;
