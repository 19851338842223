import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Card, Col, Row, Space, Spin } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { youtubeUrlRegex } from '@/utils/constants'

interface Props {
    loading: boolean
    batches: any
    handleVideoClick: (batch: any) => void
    getYouTubeThumbnailUrl: any
    pagination: any
    handlePageChange: (value: any) => void
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const StyledCard = styled(Card) <{ isLoading?: boolean }>`
  width: 185px;
  height: 310px;
  transition: transform 0.1s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 8px; 

  &:hover {
    transform: scale(1.05);
  }

  .ant-card-meta-description {
    white-space: nowrap;
  }
`;

const ManageVideoQCPage: React.FC<Props> = ({
    loading,
    batches,
    handleVideoClick,
    getYouTubeThumbnailUrl,
    pagination,
    handlePageChange
}) => {

    const [videoLoading, setVideoLoading] = useState<{ [key: string]: boolean }>({});

    const handleVideoLoadStart = (id: string) => {
        setVideoLoading((prev) => ({ ...prev, [id]: true }));
    };

    const handleVideoLoadedData = (id: string) => {
        setVideoLoading((prev) => ({ ...prev, [id]: false }));
    };

    return (
        <Container title='Manage Video QC' style={{ width: '100%' }}>
            <Space direction={'vertical'} style={{ width: '100%' }}></Space>
            <Spin spinning={loading}>
                <BodyContainer>
                    <Row gutter={[16, 16]}>
                        {batches?.map((batch: any) => (
                            <Col key={batch?.id}>
                                <StyledCard
                                    key={batch?.id}
                                    hoverable
                                    isLoading={videoLoading[batch?.id]}
                                    onClick={() => handleVideoClick(batch)}
                                    cover={
                                        batch?.video_url ? (
                                            youtubeUrlRegex?.test(batch?.video_url) ? (
                                                <img
                                                    key={batch?.video_url}
                                                    src={getYouTubeThumbnailUrl(batch?.video_url)}
                                                    alt={batch?.title}
                                                    style={{ height: '260px', objectFit: 'cover', cursor: 'pointer' }}
                                                />
                                            ) : (
                                                <>
                                                    <video
                                                        key={batch?.video_url}
                                                        src={batch?.video_url}
                                                        onLoadStart={() => handleVideoLoadStart(batch?.id)}
                                                        onLoadedData={() => handleVideoLoadedData(batch?.id)}
                                                        style={{ height: '260px', objectFit: 'cover', cursor: 'pointer' }}
                                                    />
                                                    {videoLoading[batch?.id] && (
                                                        <Spin
                                                            spinning={true}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                color: '#1890ff',
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            )
                                        ) : (
                                            <div style={{ height: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'default' }}>
                                                No video available
                                            </div>
                                        )

                                    }
                                    style={{ transition: 'transform 0.3s' }}
                                    onMouseEnter={(e) => {
                                        (e?.currentTarget as HTMLElement).style.transform = 'scale(1.05)';
                                    }}
                                    onMouseLeave={(e) => {
                                        (e?.currentTarget as HTMLElement).style.transform = 'scale(1)';
                                    }}
                                >
                                    <Meta description={`${batch?.batch_code ? batch?.batch_code : batch?.batch_id}`} style={{ marginTop: '-10px' }} />
                                </StyledCard>
                            </Col>
                        ))}
                    </Row>
                </BodyContainer>

                {pagination?.current &&
                    <div style={{ display: 'flex', justifyContent: 'right', width: '100%', paddingTop: '10px' }}>
                        <Button onClick={() => handlePageChange('previous')} disabled={pagination?.current === 1}>Prev</Button>
                        <Button style={{ marginLeft: '5px', marginRight: '5px' }}>{String(pagination?.current)}</Button>
                        <Button onClick={() => handlePageChange('next')} disabled={pagination?.is_last_page}>Next</Button>
                    </div>
                }

            </Spin>
        </Container>
    )
}

export default ManageVideoQCPage
