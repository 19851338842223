/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Drawer, Form, Input, InputNumber, Popconfirm, Rate, Row, Typography } from 'antd'

import React from 'react'

import { ManageSectionItem, PageListItem } from '@a4b/api/src/modules/Monetisation/offerings/types'
import S3Upload from '../S3Upload'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'


const { Title } = Typography

const { TextArea } = Input

interface Props {
  onClose: () => void
  visible: boolean
  cdnUrl: string
  preSignUrl: string
  pageTitle: string
  currentPageTypeData: PageListItem | undefined
  setDandDModalVisibility: (flag: boolean) => void
  sortedSectionList: ManageSectionItem[]
  renderer: any
  showUpdateButton: boolean
  updatePages: () => void
  form: any
  deleteReview: (reviewId: string, callback: any) => void
  deleteTutorials: (id: string) => void
  onFormChange: () => void
  pageKey: string
}
const OfferingFeedPage: React.FC<Props> = ({
  onClose,
  visible,
  cdnUrl,
  preSignUrl,
  setDandDModalVisibility,
  pageTitle,
  currentPageTypeData,
  sortedSectionList,
  renderer,
  showUpdateButton,
  updatePages,
  form,
  deleteReview,
  onFormChange,
  deleteTutorials,
  pageKey
}) => {
  return (
    <Drawer
      size='large'
      title={pageTitle}
      placement='right'
      onClose={onClose}
      visible={visible}
    >
      <Form
        form={form}
        layout='vertical'
        name='create faqs'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onChange={onFormChange}
        initialValues={{ tutorials: {} }}
      >
        <Row>
          <Col span={24} style={{ paddingTop: '10px' }}>
            <Title level={4}>User reviews</Title>
          </Col>
          <Col span={24}>

            <Form.List name={['reviews']}>
              {(fields, { add, remove, move }) => {

                return (
                  <Row  >
                    {fields.map((field: any, index: number) => {
                      return (
                        <Col span={12} key={`reviews-${field.key}`}>
                          <Row
                            style={{
                              border: '1px solid #e0dcdc',
                              marginRight: '3px',
                              marginBottom: '3px',
                            }}
                          >
                            <Col
                              span={20}
                              style={{
                                marginLeft: '3px',
                              }}
                            >
                              <Form.Item
                                label={`Review ${index + 1}`}
                                name={[field.name, 'review']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input review!',
                                  },
                                ]}
                              >
                                <TextArea placeholder='Enter review' />
                              </Form.Item>
                              <Form.Item
                                label=''
                                name={[field.name, 'user_name']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input username!',
                                  },
                                ]}
                              >
                                <Input placeholder='Enter username' />
                              </Form.Item>

                              <Form.Item
                                label={`User image`}
                                name={[field.name, 'user_image_url']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                  {
                                    min: 1,
                                    max: 1,
                                    validator: async (
                                      rules: any,
                                      values: any,
                                    ) => {
                                      if (values?.length < rules.min) {
                                        return Promise.reject(
                                          'Please upload user image',
                                        )
                                      }
                                    },
                                  },
                                ]}
                                valuePropName='fileList'
                              >
                                <S3Upload
                                  cdnUrl={cdnUrl}
                                  preSignUrl={preSignUrl}
                                  min={1}
                                  max={1}
                                />
                              </Form.Item>
                              <Form.Item
                                label='Position'
                                name={[field.name, 'position']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input position!',
                                  },
                                ]}
                              >
                                <InputNumber placeholder='Enter position' />
                              </Form.Item>
                              <Form.Item
                                label=''
                                name={[field.name, 'rating']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input rating!',
                                  },
                                ]}
                              >
                                <Rate />
                              </Form.Item>
                            </Col>

                            <Col
                              span={2}
                              style={{
                                alignSelf: 'start',
                                paddingLeft: '10px',
                                paddingTop: '5px',
                                marginRight: '10px'
                              }}
                            >


                              <Popconfirm
                                placement="topRight"
                                title={"Please confirm to delete"}
                                onConfirm={() => {

                                  deleteReview(form.getFieldsValue()?.reviews?.[field.name]?.id, () => {

                                    remove(field.name)
                                  })
                                }}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button

                                  type='ghost'
                                  icon={<DeleteOutlined />}
                                  size='middle'
                                  danger
                                ></Button>
                              </Popconfirm>
                            </Col>
                          </Row>
                        </Col>
                      )
                    })}

                    <Col span={24} style={{ paddingTop: '10px' }}>
                      <Row justify='start'>
                        <Col span={4}>
                          <Button
                            onClick={() => {
                              add()
                            }}
                            type='ghost'
                            icon={<PlusCircleOutlined />}
                            size='middle'
                          >
                            Add Review
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )
              }}
            </Form.List>

          </Col>
          <>
            <Col span={24} style={{ paddingTop: '10px' }}>
              <Title level={4}>Add Tutorial(Optional)</Title>
            </Col>
            <Col span={24}>

              <Row>
                <Col span={12} key={`tutorials`}>
                  <Row
                    style={{
                      border: '1px solid #e0dcdc',
                      marginRight: '3px',
                      marginBottom: '3px',
                    }}
                  >
                    <Col
                      span={20}
                      style={{
                        marginLeft: '3px',
                      }}
                    >
                      <Form.Item noStyle name={["tutorials", "id"]}>

                      </Form.Item>
                      <Form.Item
                        label='Title'
                        name={["tutorials", "title"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: false,
                            message: 'Please input title!',
                          },
                        ]}
                      >
                        <Input placeholder='Enter title' />
                      </Form.Item>
                      <Form.Item
                        label='Description'
                        name={['tutorials', 'description']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: false,
                            message: 'Please input description!',
                          },
                        ]}
                      >
                        <Input placeholder='Enter description' />
                      </Form.Item>
                      <Form.Item
                        label={`Upload Media`}
                        name={['tutorials', 'media_url']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            min: 1,
                            max: 1,
                            validator: async (
                              rules: any,
                              values: any,
                            ) => {
                              if (values?.length < rules.min) {
                                return Promise.reject(
                                  'Please upload user image',
                                )
                              }
                            },
                          },
                        ]}
                        valuePropName='fileList'
                      >
                        <S3Upload
                          cdnUrl={cdnUrl}
                          preSignUrl={preSignUrl}
                          min={1}
                          max={1}
                        />
                      </Form.Item>

                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={7}></Col>
                <Col span={4}>
                  <Popconfirm
                    placement="topRight"
                    title={"Please confirm to delete"}
                    onConfirm={() => {
                      deleteTutorials(form.getFieldsValue()?.tutorials?.id)
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type='ghost'
                      icon={<DeleteOutlined />}
                      size='middle'
                      danger
                    >Delete tutorial</Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </>

          <><Col span={24} style={{ paddingTop: '10px' }}>
            <Title level={4}>Add Tutorial for offerings feed help tutorial page</Title>

          </Col>
            <Col span={24}>
              <Row>
                <Col span={12} >
                  <Row
                    style={{
                      border: '1px solid #e0dcdc',
                      marginRight: '3px',
                      marginBottom: '3px',
                    }}
                  >
                    <Col
                      span={20}
                      style={{
                        marginLeft: '3px',
                      }}
                    >
                      <Form.Item noStyle name={["help_tutorials", "id"]}>

                      </Form.Item>
                      <Form.Item
                        label='Title'
                        name={["help_tutorials", "title"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: false,
                            message: 'Please input title!',
                          },
                        ]}
                      >
                        <Input placeholder='Enter title' />
                      </Form.Item>
                      <Form.Item
                        label='Description'
                        name={['help_tutorials', 'description']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: false,
                            message: 'Please input description!',
                          },
                        ]}
                      >
                        <Input placeholder='Enter description' />
                      </Form.Item>
                      <Form.Item
                        label={`Upload Media`}
                        name={['help_tutorials', 'media_url']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            min: 1,
                            max: 1,
                            validator: async (
                              rules: any,
                              values: any,
                            ) => {
                              if (values?.length < rules.min) {
                                return Promise.reject(
                                  'Please upload user image',
                                )
                              }
                            },
                          },
                        ]}
                        valuePropName='fileList'
                      >
                        <S3Upload
                          cdnUrl={cdnUrl}
                          preSignUrl={preSignUrl}
                          min={1}
                          max={1}
                        />
                      </Form.Item>

                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={7}></Col>
                <Col span={4}>
                  <Popconfirm
                    placement="topRight"
                    title={"Please confirm to delete"}
                    onConfirm={() => {
                      deleteTutorials(form.getFieldsValue()?.help_tutorials?.id)
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button

                      type='ghost'
                      icon={<DeleteOutlined />}
                      size='middle'
                      danger
                    >Delete feed help tutorial</Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </>
        </Row >
        <Row>
          {sortedSectionList?.map((item: ManageSectionItem) => React.cloneElement(renderer, { data: item }))}
        </Row>
        <Row>

          <Col span={18} ></Col>
          <Col span={6} >
            <Button onClick={() => setDandDModalVisibility(true)} type='ghost' size='middle'>
              Select Sections
            </Button>
          </Col>
          {showUpdateButton && <Col span={24} style={{ paddingTop: '10px' }}>
            <Button onClick={updatePages} type='ghost' size='middle'>
              Submit
            </Button>
          </Col>}
        </Row>
      </Form>
    </Drawer >
  )
}
export default OfferingFeedPage
