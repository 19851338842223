export enum OPERATORS {
  EQUAL = "EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
  EXISTS = "EXISTS",
  GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
  LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
}

export interface EventProperty {
  path: string;
  operator: string;
  value_int64?: number;
  value_string?: number;
}

export interface EventSchemaType {
  event_name: string;
  properties: EventProperty[];
}

export interface CohortProperty {
  path: string;
  operator: OPERATORS;
  value_int64: number;
}

export interface Cohort {
  exit_properties: CohortProperty[];
  entry_properties: CohortProperty[];
}

export interface Nudge {
  attributes: any;
  anchor_tag: string;
  page_tag: string;
  property: string;
  template: string;
  wait_interval_millis?: number;
  question?: {
    code: string;
    type: 'NQT_STAR_RATING' | 'NQT_TEXT_COMMENT';
    title: string;
    options?: { [key: string]: string }[];
    placeholder?: { [key: string]: string };
    limit?: number;
  }
}

export interface Affirmative {
  identifier: [number, number];
  name: string;
  timeout_interval: number;
  event_schema: EventSchemaType;
  affirmative?: Affirmative | null;
  fallback?: Fallback | null;
  nudge: Nudge;
  trigger_count_cap: number;
}

export interface Fallback {
  identifier: [number, number];
  name: string;
  event_schema: EventSchemaType;
  affirmative?: Affirmative | null;
  nudge: Nudge;
  timeout_interval: number;
  trigger_count_cap: number;
}


export type ICreateNode = (identifier: [number, number], nodeData: {
  type: 'affirmative';
  values: Affirmative | null | undefined;
} | {
  type: 'fallback';
  values: Fallback | null | undefined;
}) => void


export type IUpdateNode = (identifier: [number, number], nodeData: Affirmative | Fallback | Journey) => void
export type IDeleteNode = (identifier: [number, number]) => void

export interface Journey {
  id?: string;
  status: 'DRAFTED' | 'PUBLISHED' | 'DEPRECATED';
  identifier: [number, number];
  priority: number;
  name: string;
  run_parallel_always: boolean;
  event_schema: EventSchemaType;
  cohort: Cohort;
  affirmative: Affirmative | null;
  fallback?: Fallback | null;
}
export type IJourneyLevel = [
  Fallback | null | undefined,
  Affirmative | Journey | null | undefined,
]
export type IJourneyLevels = IJourneyLevel[]

export interface NudgeConfiguration {
  NT_BOTTOMSHEET_V1: NtBottomsheetV1
  NT_FULL_PAGE_V1: NtFullPageV1
  NT_PILL_LEFT_WITH_TEXT_AND_ICON_V1: NtPillLeftWithTextAndIconV1
  NT_PILL_RIGHT_WITH_TEXT_V1: NtPillRightWithTextV1
  NT_RIPPLE_PULSE_V1: NtRipplePulseV1
  NT_RIPPLE_V1: NtRippleV1
  NT_SHINE_V1: NtShineV1
};


export interface NtBottomsheetV1 {
  NPT_ALL: NptAll
  god: God
}

export interface NptAll {
  NAT_EMPTY: string[]
}

export interface God {
  NAT_EMPTY: string[]
}

export interface NtFullPageV1 {
  NPT_ALL: NptAll2
  god: God2
}

export interface NptAll2 {
  NAT_EMPTY: string[]
}

export interface God2 {
  NAT_EMPTY: string[]
}

export interface NtPillLeftWithTextAndIconV1 {
  god: God3
}

export interface God3 {
  music: string[]
}

export interface NtPillRightWithTextV1 {
  god: God4
}

export interface God4 {
  aarti: string[]
  flower: string[]
  panchang: string[]
  shankh: string[]
}

export interface NtRipplePulseV1 {
  god: God5
  header: Header
  mandir_bottomsheet: MandirBottomsheet
}

export interface God5 {
  aarti: any[]
  flower: any[]
  music: any[]
  panchang: any[]
  shankh: any[]
}

export interface Header {
  coins: any[]
}

export interface MandirBottomsheet {
  item: string[]
}

export interface NtRippleV1 {
  god: God6
  header: Header2
  mandir_bottomsheet: MandirBottomsheet2
}

export interface God6 {
  aarti: any[]
  flower: any[]
  music: any[]
  panchang: any[]
  shankh: any[]
}

export interface Header2 {
  coins: any[]
}

export interface MandirBottomsheet2 {
  item: string[]
}

export interface NtShineV1 {
  mandir_bottomsheet: MandirBottomsheet3
}

export interface MandirBottomsheet3 {
  tab: string[]
}




