
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination from "@/hooks/useAntdPagination";
import { JourneyContextProvider } from "../Journey/utils/contants";
import { NudgeConfiguration } from "../Journey/utils/types";
import { NudgeData } from "./utils/types";





const Nudges = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const { networkInstance } = useAppContext();
  const { pagination, setPagination } = useAntdPagination();
  const [nudgeConfiguration, setNudgeConfiguration] = useState<NudgeConfiguration>();
  const [nudges, setNudges] = useState<NudgeData[]>([]);
  const [isLoadingNudge, setIsLoadingNudge] = useState(false);
  const [nudgeId, setNudgeId] = useState<string>();

  const loadNudges = useCallback(async () => {
    try {
      setIsLoadingNudge(true)
      const res = await networkInstance.clientWithHeaders.inAppNudge.getNudges();
      setNudges(res.data.data.nudges as unknown as NudgeData[])
    } catch (error) {

    }
    setIsLoadingNudge(false);
  }, [networkInstance.clientWithHeaders.inAppNudge])

  const nudge = useMemo(() => (nudges.find((nudge) => nudge.id === nudgeId)), [nudgeId, nudges])
  const drawerConfig = useDrawerConfig(loadNudges, setActiveDrawer, activeDrawer, nudge);
  const { drawerProps, drawerContent } = drawerConfig;

  const getNudgeConfiguration = useCallback(async () => {
    const { data } = await networkInstance.clientWithHeaders.inAppNudge.getNudgeConfiguration();
    setNudgeConfiguration(data.data.templates);
  }, [networkInstance]);

  useEffect(() => {
    getNudgeConfiguration();
    loadNudges()
  }, [getNudgeConfiguration, loadNudges]);

  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }

  const tableConfig = useTableConfig(nudges, setNudgeId, isLoadingNudge, setActiveDrawer, pagination, setPagination, setDrawer);


  const createNudge = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_NUDGE);
  }

  const onDrawerClose = () => {
    setNudgeId(undefined);
    setActiveDrawer(undefined);
  }

  if (!nudgeConfiguration) return null;
  // @ts-ignore Need to fix this later 
  return <JourneyContextProvider value={{ nudgeConfiguration }}>
    <SocialScreenContainer
      title={"Nudges"}
      filtersUI={<Filters />}
      extra={<Button onClick={createNudge}> Create Nudge </Button>}
      tableProps={tableConfig}
      drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
      drawerContent={drawerContent}
    />
  </JourneyContextProvider>

};

export default withDefaultLayout(Nudges)