import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { Faq, Faqs, Response, Review } from './types'

class ReviewApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createReviews = (payload: any) =>
    this.api.post<Response<any>>(
      `/yoda/internal/v1/reviews/create-review`,
      payload,
    )
  updateReviews = (payload: any) =>
    this.api.put<Response<any>>(
      `/yoda/internal/v1/reviews/update-reviews`,
      payload,
    )

  getReviews = (store_id: string) =>
    this.api.get<Response<Review[]>>(
      `/yoda/internal/v1/reviews/reviews-of-store?store_id=${store_id}`,
    )
  deleteReview = (reviewId: string) =>
    this.api.delete<Response<Review[]>>(`/yoda/internal/v1/reviews/${reviewId}`)
}

export default ReviewApi
