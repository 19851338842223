import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { UserSegment, UserSegments } from './types'

class UserSegmentsApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getAllUserSegments = (paginationParams: any) =>
    this.api.get<UserSegments>(`/gw1/puja/internal/v1/user-segments/`, {
      params: {
        limit: paginationParams?.limit,
        offset: paginationParams?.offset,
      },
    })

  getUserSegmentById = (id: string) =>
    this.api.get(`/gw1/puja/internal/v1/user-segments/${id}`)

  createUserSegment = (payload: any) =>
    this.api.post(`/gw1/puja/internal/v1/user-segments`, payload)

  updateUserSegment = (id: string, payload: any) =>
    this.api.put(`/gw1/puja/internal/v1/user-segments/${id}`, payload)

  updateUserSegmentStatus = (id: string, payload: any) =>
    this.api.patch(`/gw1/puja/internal/v1/user-segments/${id}`, payload)
}

export default UserSegmentsApi
