import { arrayMoveImmutable } from 'array-move'

import React from 'react'
import { SortEndHandler } from 'react-sortable-hoc'

import type { SortableItemData } from '../types'
import { SortableCardList } from './CardList'

export interface Props {
  value?: SortableItemData[]
  limit: number
  onChange?: (value: SortableItemData[]) => void
  onRemoveItem: (value: SortableItemData) => void
}

export const SortableCards: React.FC<Props> = ({
  value = [],
  limit,
  onChange,
  onRemoveItem,
}) => {
  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const updatedItems = arrayMoveImmutable(value, oldIndex, newIndex)
    onChange && onChange(updatedItems)
  }

  return (
    <SortableCardList
      axis='xy'
      limit={limit}
      items={value}
      onRemoveItem={onRemoveItem}
      onSortEnd={onSortEnd}
    />
  )
}
