import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../apiClient'
import type { Response } from '../../types'
import { Avatar } from '../AdminControl/types'
import type { Shrine } from './types'

class ShrineApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  /*
   get calls 
  */
  getGods = (
    limit = 10,
    offset = 0,
    onlyUserUploaded: boolean,
    languageCode: string,
  ) =>
    this.api.get<Response<Shrine.GodData>>(
      `/loki/internal/v1/gods?limit=${limit}&offset=${offset}&only_user_uploaded=${onlyUserUploaded}&language_code=${languageCode}`,
    )

  getAvatars = (godId: string, query?: string) =>
    this.api.get<any>(
      `gw1/loki/internal/v1/${godId}/avatars?limit=90&offset=0&` + query,
    )

  getGod = (id: string) =>
    this.api.get<Response<{ god: Shrine.God }>>(
      `gw1/loki/internal/v1/gods/${id}`,
    )

  getItems = (limit = 10, offset = 0) =>
    this.api.get<Response<Shrine.GodItemsData>>(
      `/loki/internal/v1/items/code:chadava_icon_lakshmi_mata`,
    )

  getGodItems = (limit = 10, offset = 0, itemType: string) =>
    this.api.get<Response<Shrine.GodItemsRes>>(
      `/loki/internal/v1/items?limit=${limit}&offset=${offset}&item_type=${itemType}`,
    )

  getItem = (itemId: string) => {
    return this.api.get<Response<{ item: Shrine.Item }>>(
      `/loki/internal/v1/items/${itemId}`,
    )
  }

  getGodItems_v2 = (
    limit = 10,
    offset = 0,
    itemType: string,
    active?: boolean,
  ) =>
    this.api.get<Response<Shrine.GodItemResponse>>(
      `/loki/internal/v1/items?limit=${limit}&offset=${offset}&item_type=${itemType}&${
        active ? 'active=true' : ''
      }`,
    )

  getGodItem = (godId: string, itemId: string, limit: number, offset: number) =>
    this.api.get<Response<Shrine.GodData>>(
      `/loki/internal/v1/gods/${godId}/items/${itemId}?detailed=false`,
    )

  getItemSchedule = () => {
    return this.api.get<Response<Shrine.GodItemSchedule[]>>(
      `/gw2/loki/internal/v1/schedule/items`,
    )
  }

  getS3SignedUrl = (fileName: string, module: string, sub_module: string) =>
    this.api.get<Response<{ info: Shrine.S3Info }>>(
      `/loki/internal/v1/attachments/init?file_name=${fileName}&&module=${module}&sub_module=${sub_module}`,
    )

  checkCodeValid = (code: string) =>
    this.api.get<Response<{ is_valid: boolean }>>(
      `/loki/internal/v1/gods/${code}/is_valid`,
    )
  checkCodeUnique = (code: string, languageCode: string) =>
    this.api.get<Response<{ is_unique: boolean }>>(
      `/loki/internal/v1/gods/${code}/is_unique?language_code=${languageCode}`,
    )

  checkGodItemCodeUnique = (code: string) =>
    this.api.get<Response<{ is_unique: boolean }>>(
      `/loki/internal/v1/items/${code}/is_unique`,
    )

  getAssociatedItemsOfGod = (godId: string) =>
    this.api.get<Response<Shrine.GodItemsRes>>(
      `/loki/internal/v1/gods/${godId}/items`,
    )
  getAssociatedItemsOfGod_2 = (godId: string) =>
    this.api.get<Response<Shrine.GodItemResponse>>(
      `/loki/internal/v1/gods/${godId}/items`,
    )

  getGodAvatarCodes = (godId: string) =>
    this.api.get<{ avatar_codes: string[] }>(
      `/loki/internal/v1/gods/${godId}/avatar-codes`,
    )

  getAvatarSchedules = () =>
    this.api.get<Response<Shrine.AvatarSchedule[]>>(
      `/gw2/loki/internal/v1/schedule/avatars`,
    )

  getGodSchedules = () =>
    this.api.get<Response<Shrine.GodSchedule[]>>(
      `/gw2/loki/internal/v1/schedule/gods`,
    )

  getAvatarById = (avatarId: string) =>
    this.api.get<Response<Shrine.AvatarPayload>>(
      `gw2/loki/internal/v1/avatars/${avatarId}`,
    )

  /*
   post calls 
  */

  createAvatars = (godId: string, payload: Shrine.AvatarPayload) =>
    this.api.post<Response<Shrine.AvatarPayload[]>>(
      `gw1/loki/internal/v1/god/${godId}/avatar`,
      payload,
    )

  createGod = (payload: Shrine.CreateGodPayload) =>
    this.api.post('/loki/internal/v1/gods', payload)

  createGodItem = (payload: Shrine.CreateGodItemPayload) =>
    this.api.post('/loki/internal/v1/items', payload)

  postSchedule = (payload: Shrine.CreateSchedulePayload) =>
    this.api.post('gw1/loki/internal/v1/schedule/item', payload)

  associateItemToGod = (
    godId: string,
    payload: {
      item_id: string
      display_order: number
      god_avatar_code: string
    },
  ) => this.api.post('/loki/internal/v1/gods/' + godId + '/items', payload)

  cloneGod = (
    godId: string,
    payload: {
      languages: [string]
    },
  ) => this.api.post(`/loki/internal/v1/gods/${godId}/clone`, payload)

  createAvatarSchedule = (payload: Shrine.AvatarSchedulePayload) =>
    this.api.post(`/gw2/loki/internal/v1/schedule/avatars`, payload)

  createGodItemSchedule = (payload: Shrine.CreateItemSchedulePayload) =>
    this.api.post(`/gw2/loki/internal/v1/schedule/items`, payload)

  createGodSchedule = (payload: Shrine.GodSchedulePayload) =>
    this.api.post(`/gw2/loki/internal/v1/schedule/gods`, payload)

  /*
    put calls 
  */

  putSchedule = (payload: Shrine.CreateSchedulePayload) =>
    this.api.put('gw1/loki/internal/v1/schedule/item', payload)

  updateGodItemSchedule = (
    scheduleId: string,
    payload: Shrine.CreateItemSchedulePayload,
  ) =>
    this.api.put(`/gw2/loki/internal/v1/schedule/items/${scheduleId}`, payload)

  updateAvatarSchedule = (
    scheduleId: string,
    payload: Shrine.AvatarSchedulePayload,
  ) =>
    this.api.put(
      `/gw2/loki/internal/v1/schedule/avatars/${scheduleId}`,
      payload,
    )

  updateGodSchedule = (
    scheduleId: string,
    payload: Shrine.GodSchedulePayload,
  ) =>
    this.api.put(`/gw2/loki/internal/v1/schedule/gods/${scheduleId}`, payload)

  /*
   patch calls 
  */
  updateGodItem = (id: string, payload: Shrine.UpdateGodItem) =>
    this.api.patch('/loki/internal/v1/items/' + id, payload)

  updateGod = (id: string, payload: any) =>
    this.api.patch('/loki/internal/v1/gods/' + id, payload)

  updateGodStatus = (id: string, payload: any) =>
    this.api.patch(`/loki/internal/v1/gods/${id}/status`, payload)

  updateAvatars = (
    godId: string,
    avatarId: string,
    payload: Shrine.AvatarPayload,
  ) => {
    if (payload.attachment?.url) {
      delete payload.attachment.url
    }
    if (payload.paid_avatar_details) {
      if (payload.paid_avatar_details?.playback_audio?.url) {
        delete payload.paid_avatar_details.playback_audio['url']
      }
      if (payload.paid_avatar_details?.playback_audio_image?.url) {
        delete payload.paid_avatar_details.playback_audio_image['url']
      }
      if (payload.paid_avatar_details?.lock_icon?.url) {
        delete payload.paid_avatar_details.lock_icon['url']
      }
      if (payload.paid_avatar_details?.curtain_unlocked?.url) {
        delete payload.paid_avatar_details.curtain_unlocked['url']
      }
      if (payload.paid_avatar_details?.curtain_locked?.url) {
        delete payload.paid_avatar_details.curtain_locked['path']
      }
    }

    return this.api.patch<Response<Shrine.AvatarPayload[]>>(
      `gw1/loki/internal/v1/god/${godId}/avatar/${avatarId}`,
      payload,
    )
  }

  updateAssociatedItemToGod = (
    godId: string,
    payload: {
      id: string
      display_order: number
    },
  ) =>
    this.api.patch('/loki/internal/v1/god-avatar-items/' + payload?.id, payload)

  /*
   delete calls 
  */

  deleteGodItemAssocaition = (
    godId: string,
    GodItemId: string,
    godAvatarCode: string,
  ) =>
    this.api.delete(
      `/loki/internal/v1/gods/${godId}/items/${GodItemId}?avatar_code=${godAvatarCode}`,
    )
  getGodItemAssocaitionByFilter = (
    god_id: string,
    avatar_code: string,
    item_type: string,
  ) =>
    this.api.get<Response<Shrine.GodItemResponse>>(
      `/loki/internal/v1/god-avatar-items`,
      {
        params: { god_id, avatar_code, item_type },
      },
    )

  deleteGodItemAssocaition_2 = (GodItemId: string) =>
    this.api.delete(`/loki/internal/v1/god-avatar-items/${GodItemId}`)

  getStories = (params: any) => {
    return this.api.get<Shrine.StoriesData>('/magneto/internal/v1/posts', {
      params: params,
    })
  }

  deleteStory = (postId: string) => {
    return this.api.delete<Shrine.StoriesData>(
      `/magneto/internal/v1/posts/${postId}`,
    )
  }

  getStoriesSignedUrl = (extension: string) => {
    return this.api.get(
      `/magneto/internal/v1/posts/attachment/upload?extension=${extension}`,
    )
  }
  createStory = (formData: any) =>
    this.api.post('/magneto/internal/v1/posts', formData)

  updateStory = (formData: any) =>
    this.api.patch(`/magneto/internal/v1/posts/${formData?.id}`, formData)

  createActionCard = (formData: any) =>
    this.api.post('/gw1/loki/internal/v1/action-cards', formData)

  updateActionCard = (formData: any, id: string) =>
    this.api.patch(`/gw1/loki/internal/v1/action-cards/${id}`, formData)

  getActionCards = (params: any, language: string) => {
    return this.api.get<Response<Shrine.ActionCardResponse>>(
      '/gw1/loki/internal/v1/action-cards',
      {
        params: params,
        headers: {
          'x-afb-language-mode': language,
          'accept-language': language,
        },
      },
    )
  }
}

export default ShrineApi
