import { Spin } from "antd";
import styled from "styled-components";

const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = () => {
  return (
    <LoadingWrapper>
      <div>
        Loading <Spin size="large" />
      </div>
    </LoadingWrapper>
  );
};
export default Loading;
