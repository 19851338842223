/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Drawer, Form, Input, Select } from 'antd'

import React from 'react'

import { HubManager } from '@a4b/api/src/modules/Monetisation/offerings/types'

interface Props {
  onClose: () => void
  visible: boolean
  onFinish: (values: any) => void
  onFinishFailed: (errorInfo: any) => void
  hubManagersList: HubManager[]
  initialValues?: any
  form: any
}
const { Option } = Select
const HubCreation: React.FC<Props> = ({
  onClose,
  visible,
  onFinish,
  onFinishFailed,
  hubManagersList,
  initialValues,
  form,
}) => {
  return (
    <Drawer
      title='Create New Hub'
      placement='right'
      onClose={onClose}
      visible={visible}
    >
      <Form
        onSubmitCapture={e => {
          e.preventDefault()
        }}
        layout='vertical'
        name='create hub'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item
          label='Name of the hub'
          name='name'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input hub name!' }]}
        >
          <Input placeholder='Enter Name of the hub' />
        </Form.Item>

        <Form.Item
          label={`Hub manager's Name`}
          name='manager_email'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            { required: true, message: 'Please select hub manager name!' },
          ]}
        >
          <Select placeholder='Please select the hub manager'>
            {hubManagersList.map((manager: HubManager) => (
              <Option value={manager.email} key={manager.id}>
                {manager.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label='' shouldUpdate={() => true}>
          {({ getFieldValue }) => {
            return <div>{getFieldValue('manager_email')}</div>
          }}
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ paddingTop: '30px' }}
        >
          <Button type='primary' htmlType='submit'>
            Create Hub
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
export default HubCreation
