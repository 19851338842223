import { useAppContext } from '@/components/AppContext'
import Property from '@/entities/feeds/Property'
import Schedule, { ScheduleInstance } from '@/entities/feeds/Schedule'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
// import { getProperties } from '@/usecases/views/common'
// import {
//   deleteSchedules,
//   disableSchedules,
//   getSchedulesByFilter,
//   getSchedulesById,
//   previewSchedules,
// } from '@/usecases/views/schedules'
import { getWidgetsNameAndClass } from '@/utils/helper'
import { scheduleApiMessages } from '@/utils/messages'
import { SelectOptions } from '@/utils/types'
import { sortByCreated, sortByUpdated } from '@/utils/utilProps'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Tabs,
  message,
} from 'antd'
import moment from 'moment'
import styled from 'styled-components'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import EmptyData from '../../components/Empty'
import PageLayout from '../../components/PageLayout'
import ScheduleManageDataTable from './ScheduleManageDataTable'
import { SEARCH_BY_UC_CODE_FOR_UPLOAD } from '../../../../network/apiRoutes'

const { TabPane } = Tabs
const WidgetWrapper = styled.div`
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
`

const { RangePicker } = DatePicker
const { Option } = Select
interface Props { }
const ManageSchedule = () => {
  const [form] = Form.useForm()
  const { userProfile } = useAppContext()
  const scheduleSearchFilterForm = Form.useForm()[0]
  const schedulePreviewFilterForm = Form.useForm()[0]

  const [scheduleList, setScheduleList] = useState<any>([])
  const [scheduleFilter, setScheduleFilter] = useState<any>({})
  const [propertyList, setPropertyList] = useState<SelectOptions[]>([])

  const [currentTab, setCurrentTab] = useState('')

  const [searchById, setSearchById] = useState(false)
  const { networkInstance } = useAppContext();

  const getSchedules = () => {
    networkInstance.clientWithHeaders.feeds.getSchedules();
    let schedulesPromise = new Promise(async (resolve, reject) => {
      try {
        let schedulesResponse = await networkInstance.clientWithHeaders.feeds.getSchedules();
        resolve(schedulesResponse?.data?.data);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return schedulesPromise;
  };
  const getSchedulesByFilter = (filterData: any) => {
    let schedulesPromise = new Promise(async (resolve, reject) => {
      try {
        let schedulesResponse =
          await networkInstance.clientWithHeaders.feeds.getSchedulesByFilter(filterData);

        resolve(schedulesResponse?.data?.data);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return schedulesPromise;
  };
  const getSchedulesById = (id: string) => {
    let schedulesPromise = new Promise(async (resolve, reject) => {
      try {
        let schedulesResponse: any =
          await networkInstance.clientWithHeaders.feeds.getSchedulesById(id);
        resolve(schedulesResponse?.data?.data);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return schedulesPromise;
  };
  const editSchedulesById = (data: any, id: string) => {
    let schedulesPromise = new Promise(async (resolve, reject) => {
      try {
        let schedulesResponse =
          await networkInstance.clientWithHeaders.feeds.editSchedulesById(data, id);
        resolve(true);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return schedulesPromise;
  };
  const createSchedule = (data: any) => {
    let schedulesPromise = new Promise(async (resolve, reject) => {
      try {
        let schedulesResponse = await networkInstance.clientWithHeaders.feeds.createSchedules(
          data
        );
        resolve(true);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return schedulesPromise;
  };

  const searchByUcCode = async (ucCode: string) => {
    let searchUcCodePromise = new Promise(async (resolve, reject) => {
      try {
        let RIDDLERUploadBaseUrl = process.env.REACT_APP_RIDDLER_API_URL;
        let searchResponse = await networkInstance.clientWithHeaders.carnage.getSearchByCode(
          `${RIDDLERUploadBaseUrl}/${SEARCH_BY_UC_CODE_FOR_UPLOAD}${ucCode}`
        );

        resolve(searchResponse?.data?.data);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return searchUcCodePromise;
  };

  const previewSchedules = (filterData: any) => {
    let schedulesPromise = new Promise(async (resolve, reject) => {
      try {
        let schedulesResponse =
          await networkInstance.clientWithHeaders.feeds.previewSchedules(filterData);

        resolve(schedulesResponse?.data?.data);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return schedulesPromise;
  };
  const deleteSchedules = (data: any, id: string) => {
    let schedulesPromise = new Promise(async (resolve, reject) => {
      try {
        let schedulesResponse = await networkInstance.clientWithHeaders.feeds.deleteSchedule(
          data,
          id
        );

        resolve(true);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return schedulesPromise;
  };
  const disableSchedules = (data: any, id: string) => {
    let schedulesPromise = new Promise(async (resolve, reject) => {
      try {
        let schedulesResponse = await networkInstance.clientWithHeaders.feeds.disableSchedule(
          data,
          id
        );
        resolve(true);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return schedulesPromise;
  };
  const getCtaRedirectionType = () => {
    let ctaTypePromise = new Promise(async (resolve, reject) => {
      try {
        let schedulesResponse =
          await networkInstance.clientWithHeaders.feeds.getCtaRedirectionType();
        resolve(schedulesResponse?.data?.data);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return ctaTypePromise;
  };
  const getCtaActionType = () => {
    let ctaTypePromise = new Promise(async (resolve, reject) => {
      try {
        let schedulesResponse =
          await networkInstance.clientWithHeaders.feeds.getCtaActionType();
        resolve(schedulesResponse?.data?.data);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return ctaTypePromise;
  };

  const navigate = useNavigate()

  useEffect(() => {
    let filters: any = {}

    const getPropertiesList = async () => {
      networkInstance.clientWithHeaders.feeds.getProperties()
        .then((propertiesList: any) => {
          let propertiesOption = propertiesList?.data.data.platform_properties.map(
            (item: Property) => ({
              value: item.code,
              label: item.code,
            }),
          )
          setPropertyList(propertiesOption)
          let property = propertiesList?.data.data.platform_properties[0].code
          filters.platform_property_code = property
          scheduleSearchFilterForm.setFieldsValue({
            platform_property_code: property,
            startAndEndDay: [moment().startOf('day'), moment().endOf('day')],
          })
          handleSearch(null)
          setScheduleFilter({
            ...filters,
          })
        })
        .catch((error: any) => {
          console.log({ error })
          message.error('Error occured while fetching properties.')
        })
    }

    getPropertiesList()
  }, [])

  useEffect(() => {
    localStorage.removeItem('scheduleClone')
    /* scheduleSearchFilterForm.setFieldsValue({
      startAndEndDay: [moment().startOf("day"), moment().endOf("day")],
    });
    handleSearch(null); */
  }, [])

  const handleSearch = (event: any) => {
    scheduleSearchFilterForm.submit()
  }
  const searchFormValidated = () => {
    let formValue = scheduleSearchFilterForm.getFieldsValue()

    if (searchById) {
      searchScheduleById(formValue['id'])
    } else {
      if (formValue['startAndEndDay']?.length) {
        let [startDate, endDate] = formValue?.startAndEndDay
        formValue.start_timestamp = startDate && startDate.unix()
        formValue.end_timestamp = endDate && endDate.unix()
      }
      delete formValue['startAndEndDay']
      delete formValue['id']
      getScheduleList(formValue)
    }
  }

  const previewFormValidated = () => {
    let formValue = schedulePreviewFilterForm.getFieldsValue()
    formValue.ts = formValue?.ts?.unix()
    getPreviewSchedule(formValue)
  }
  const deleteScheduleHandler = async (record: any) => {
    try {
      let payload = {
        schedule: {
          updated_by: userProfile?.email,
        },
      }
      let deleteScheduleResponse = await deleteSchedules(payload, record.id)
      if (deleteScheduleResponse) {
        message.success(scheduleApiMessages.DELETE_SUCCESS)
        searchFormValidated()
      }
    } catch (error: any) {
      console.log('Error Occured:', error)
      let errorMessage = error?.message || 'Something went wrong.'
      message.error(errorMessage)
    }
  }
  const disableSchedule = async (record: any) => {
    try {
      let formValues = ScheduleInstance.getFormFieldsForDisable(record)
      let widgetClass = getWidgetsNameAndClass(record?.widget?.widget_type)
      let scheduleFormData = ScheduleInstance.getSerializedData(formValues)

      scheduleFormData.schedule.active = !scheduleFormData.schedule.active
      scheduleFormData.schedule.updated_by = userProfile?.email
      let id = record.id || ''

      let scheduleDisabled: any = await disableSchedules(scheduleFormData, id)
      if (scheduleDisabled) message.success(scheduleApiMessages.DISABLE_SUCCESS)
    } catch (error: any) {
      console.log('Error Occured:', error)
      let errorMessage = error?.message || 'Something went wrong.'
      message.error(errorMessage)
    }
  }
  const EditScheduleHandler = (record: any) => {
    navigate(`/feeds/schedule/edit/${record.id}`)
  }
  const cloneScheduleHandler = (record: any) => {
    localStorage.setItem('scheduleClone', record.id)
    navigate('/feeds/schedule/create')
  }

  const actionMenuClickHandler = (key: string, record: any) => {
    switch (key) {
      case 'clone':
        cloneScheduleHandler(record)
        return
      case 'delete':
        deleteScheduleHandler(record)
        return
      case 'edit':
        EditScheduleHandler(record)
        return
      case 'toggleStatus':
        disableSchedule(record)
        return
    }
  }
  const getScheduleList = async (scheduleFilter: any) => {
    if (!scheduleFilter.platform_property_code) return
    try {
      let schedules: any = await getSchedulesByFilter(scheduleFilter)
      if (schedules?.schedules?.length) {
        let scheduleListSanitized = schedules?.schedules.map(
          (schedule: any) => {
            let deSerializedData =
              ScheduleInstance.getDeSerializedData(schedule)
            return { ...deSerializedData, key: schedule.id }
          },
        )
        if (currentTab === 'created') {
          let sorted = scheduleListSanitized.sort(sortByCreated)
          setScheduleList([...sorted])
          return
        }
        if (currentTab === 'updated') {
          let sorted = scheduleListSanitized.sort(sortByUpdated)
          setScheduleList([...sorted])
          return
        }
        setScheduleList(scheduleListSanitized)
      }
      console.log('scheduleList:', scheduleList)
    } catch (error: any) {
      console.log('Error Occured:', error)
      let errorMessage = error?.message || 'Something went wrong.'
      message.error(errorMessage)
    }
  }

  const getPreviewSchedule = async (scheduleFilter: any) => {
    try {
      let schedules: any = await previewSchedules(scheduleFilter)
      if (schedules?.schedules?.length) {
        let scheduleListSanitized = schedules?.schedules.map(
          (schedule: any) => {
            let deSerializedData =
              ScheduleInstance.getDeSerializedData(schedule)
            return { ...deSerializedData, key: schedule.id }
          },
        )
        setScheduleList(scheduleListSanitized)
      }
    } catch (error: any) {
      console.log('Error Occured:', error)
      let errorMessage = error?.message || 'Something went wrong.'
      message.error(errorMessage)
    }
  }

  const searchScheduleById = async (id: string) => {
    try {
      let getScheduleResponse: any = await getSchedulesById(id)
      let scheduleListSanitized = getScheduleResponse?.schedules.length
        ? getScheduleResponse?.schedules.map((schedule: any) => {
          let deSerializedData =
            ScheduleInstance.getDeSerializedData(schedule)
          return { ...deSerializedData, key: schedule.id }
        })
        : []

      setScheduleList(scheduleListSanitized)
    } catch (error: any) {
      console.log('Error Occured:', error)
      let errorMessage = error?.message || 'Something went wrong.'
      message.error(errorMessage)
    }
  }
  let createNewHandler = () => {
    navigate('/feeds/schedule/create')
  }
  const handleOnFieldsChange = (value: any) => {
    setScheduleList([])
    setScheduleFilter({ ...scheduleFilter, ...value })
  }

  const tabChangeHandler = (tabChang: any) => {
    setCurrentTab(tabChang)
    if (tabChang === 'preview') {
      setScheduleList([])
    }
    /* if (tabChang === "search") {
      getScheduleList(scheduleFilter);
    }
    if (tabChang === "preview") {
      setScheduleList([]);
    } */
    if (tabChang === 'created') {
      let sorted = scheduleList.sort(sortByCreated)
      setScheduleList([...sorted])
    }
    if (tabChang === 'updated') {
      let sorted = scheduleList.sort(sortByUpdated)
      setScheduleList([...sorted])
    }
  }
  const startAndEndDateValidator = async (rule: any, value: any) => {
    if (searchById) {
      return Promise.resolve()
    }
    if (!value) return Promise.reject('Start and end date required.')
    if (value && value.length < 2)
      return Promise.reject('Both Start and End date required.')
    let [from, to] = value
    let isGreater = to.isSameOrBefore(from)
    if (isGreater) {
      return Promise.reject('End date should be greater than Start date.')
    }
  }
  const searchByIdValidator = async (rule: any, value: any) => {
    if (!value) return Promise.reject('Schedule ID required.')
  }
  const platformCodeValidator = async (rule: any, value: any) => {
    if (!value) return Promise.reject('Platform code required.')
  }
  const timeStandRequired = async (rule: any, value: any) => {
    if (!value) return Promise.reject('Timestamp required.')
  }
  const CreateNewSchedulerButton = () => {
    return (
      <Button
        key='create-new'
        type='primary'
        htmlType='button'
        onClick={createNewHandler}
        icon={<PlusOutlined />}
      >
        Create Schedule
      </Button>
    )
  }

  const getEmptyComponent = () => {
    return currentTab === 'search' ? (
      <EmptyData
        description='No,Schedules created yet!'
        actionTitle='Create Schedule'
        actionHandler={createNewHandler}
      />
    ) : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 60,
        }}
        description={<span>{'No, Data found.'}</span>}
      ></Empty>
    )
  }
  return (
    <PageLayout
      title='Manage Schedules'
      showBackIcon={false}
      backButtonClickHandler={() => { }}
      extra={[CreateNewSchedulerButton()]}
    >
      <Row>
        <Col span={24}>
          <WidgetWrapper>
            <Tabs defaultActiveKey='search' onChange={tabChangeHandler}>
              <TabPane tab='Search' key='search'>
                <WidgetWrapper>
                  <Row justify='start' style={{ padding: '10px' }}>
                    <Col span={4}>
                      <div>
                        <b>Search by ID:</b>{' '}
                        <Switch
                          onChange={(value: any) => {
                            setSearchById(value)
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Form
                    form={scheduleSearchFilterForm}
                    name='scheduleFilterFormContainer'
                    scrollToFirstError
                    onValuesChange={handleOnFieldsChange}
                    onFinish={searchFormValidated}
                  >
                    {searchById ? (
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label='Search By ID'
                            name='id'
                            rules={[
                              {
                                validator: searchByIdValidator,
                              },
                            ]}
                          >
                            <Input placeholder='Enter ID' />
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={4}>
                          <Button
                            type='primary'
                            icon={<SearchOutlined />}
                            onClick={handleSearch}
                          >
                            Search{' '}
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <div>
                        <Row>
                          <Col span={12}>
                            <Form.Item
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              label='Platform Property Code'
                              name='platform_property_code'
                              required={true}
                              hasFeedback={true}
                              rules={[{ validator: platformCodeValidator }]}
                            >
                              <Select>
                                {propertyList?.map(
                                  (item: SelectOptions, index: number) => {
                                    return (
                                      <Option
                                        value={item.value}
                                        key={`${item.value}_${index}`}
                                      >
                                        {item.label}
                                      </Option>
                                    )
                                  },
                                )}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              name='startAndEndDay'
                              required={true}
                              label='Search Date Range'
                              rules={[
                                {
                                  validator: startAndEndDateValidator,
                                },
                              ]}
                            >
                              <RangePicker
                                ranges={{
                                  Today: [
                                    moment().startOf('day'),
                                    moment().endOf('day'),
                                  ],
                                  'This Month': [
                                    moment().startOf('month'),
                                    moment().endOf('month'),
                                  ],
                                }}
                                showTime
                                format={'YYYY-MM-DD HH:mm:ss'}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row justify='end'>
                          <Col span={3}>
                            <Button
                              type='primary'
                              icon={<SearchOutlined />}
                              onClick={handleSearch}
                            >
                              Search{' '}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Form>
                </WidgetWrapper>
              </TabPane>
              <TabPane tab='Recently Created' key='created'>
                <WidgetWrapper>
                  <Form
                    form={scheduleSearchFilterForm}
                    name='scheduleFilterFormContainer'
                    scrollToFirstError
                    onValuesChange={handleOnFieldsChange}
                    onFinish={searchFormValidated}
                  >
                    <div>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label='Platform Property Code'
                            name='platform_property_code'
                            required={true}
                            hasFeedback={true}
                            rules={[{ validator: platformCodeValidator }]}
                          >
                            <Select>
                              {propertyList?.map(
                                (item: SelectOptions, index: number) => {
                                  return (
                                    <Option
                                      value={item.value}
                                      key={`${item.value}_${index}`}
                                    >
                                      {item.label}
                                    </Option>
                                  )
                                },
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            name='startAndEndDay'
                            required={true}
                            label='Search Date Range'
                            rules={[
                              {
                                validator: startAndEndDateValidator,
                              },
                            ]}
                          >
                            <RangePicker
                              ranges={{
                                Today: [
                                  moment().startOf('day'),
                                  moment().endOf('day'),
                                ],
                                'This Month': [
                                  moment().startOf('month'),
                                  moment().endOf('month'),
                                ],
                              }}
                              showTime
                              format={'YYYY-MM-DD HH:mm:ss'}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify='end'>
                        <Col span={3}>
                          <Button
                            type='primary'
                            icon={<SearchOutlined />}
                            onClick={handleSearch}
                          >
                            Search{' '}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </WidgetWrapper>
              </TabPane>
              <TabPane tab='Recently Updated' key='updated'>
                <WidgetWrapper>
                  <Form
                    form={scheduleSearchFilterForm}
                    name='scheduleFilterFormContainer'
                    scrollToFirstError
                    onValuesChange={handleOnFieldsChange}
                    onFinish={searchFormValidated}
                  >
                    <div>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label='Platform Property Code'
                            name='platform_property_code'
                            required={true}
                            hasFeedback={true}
                            rules={[{ validator: platformCodeValidator }]}
                          >
                            <Select>
                              {propertyList?.map(
                                (item: SelectOptions, index: number) => {
                                  return (
                                    <Option
                                      value={item.value}
                                      key={`${item.value}_${index}`}
                                    >
                                      {item.label}
                                    </Option>
                                  )
                                },
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            name='startAndEndDay'
                            required={true}
                            label='Search Date Range'
                            rules={[
                              {
                                validator: startAndEndDateValidator,
                              },
                            ]}
                          >
                            <RangePicker
                              ranges={{
                                Today: [
                                  moment().startOf('day'),
                                  moment().endOf('day'),
                                ],
                                'This Month': [
                                  moment().startOf('month'),
                                  moment().endOf('month'),
                                ],
                              }}
                              showTime
                              format={'YYYY-MM-DD HH:mm:ss'}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify='end'>
                        <Col span={3}>
                          <Button
                            type='primary'
                            icon={<SearchOutlined />}
                            onClick={handleSearch}
                          >
                            Search{' '}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </WidgetWrapper>
              </TabPane>
              <TabPane tab='Preview' key='preview'>
                <WidgetWrapper>
                  <Row justify='start'>
                    <Col span={4}>
                      <div>
                        <b>Preview filter:</b>
                      </div>
                    </Col>
                  </Row>
                  <Form
                    form={schedulePreviewFilterForm}
                    name='scheduleFilterFormContainer'
                    scrollToFirstError
                    onValuesChange={handleOnFieldsChange}
                    preserve={false}
                    onFinish={previewFormValidated}
                  >
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          label='Platform Property Code'
                          name='platform_property_code'
                          required={true}
                          hasFeedback={true}
                          rules={[{ validator: platformCodeValidator }]}
                        >
                          <Select>
                            {propertyList?.map(
                              (item: SelectOptions, index: number) => {
                                return (
                                  <Option
                                    value={item.value}
                                    key={`${item.value}_${index}`}
                                  >
                                    {item.label}
                                  </Option>
                                )
                              },
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          name='ts'
                          required={true}
                          label='Preview Date and Time'
                          rules={[
                            {
                              validator: timeStandRequired,
                            },
                          ]}
                        >
                          <DatePicker
                            placeholder='Select Date and Time'
                            showTime
                            format={'YYYY-MM-DD HH:mm:ss'}
                          ></DatePicker>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify='end'>
                      <Col span={6}>
                        <Button
                          type='primary'
                          icon={<SearchOutlined />}
                          onClick={() => {
                            schedulePreviewFilterForm.submit()
                          }}
                        >
                          Preview{' '}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </WidgetWrapper>
              </TabPane>
            </Tabs>
          </WidgetWrapper>
        </Col>
      </Row>
      <Row key='data-table'>
        <Col span={24}>
          {scheduleList.length ? (
            <div>
              <div>
                {
                  <ScheduleManageDataTable
                    scheduleList={scheduleList}
                    actionMenuClickHandler={actionMenuClickHandler}
                    deleteScheduleHandler={deleteScheduleHandler}
                    currentTab={currentTab}
                  />
                }
              </div>
            </div>
          ) : (
            getEmptyComponent()
          )}
        </Col>
      </Row>
    </PageLayout>
  )
}

export default withDefaultLayout(ManageSchedule)
