import { Col, Form, Image, Modal, Row, Select, Switch, Tag } from 'antd'
import { List } from './index'
import { TempleDetail } from '@a4b/api/src/modules/Content/Temples/types'
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import { CONTENT_CDN_URL, CONTENT_TOI_PRESIGN_URL } from '@/utils/constants'
interface Props {
    showModal: boolean
    handleOk: () => void
    handleCancel: () => void
    form: any
    godList: List[]
    templeDetail: TempleDetail
    isAvatarEdit: boolean
}
const AvatarUploadModal: React.FC<Props> = ({
    showModal,
    handleOk,
    handleCancel,
    form,
    godList,
    templeDetail,
    isAvatarEdit
}) => {
    return (
        <Modal
            title={isAvatarEdit ? "Media Upload" : "Avatar Status"}
            visible={showModal}
            onOk={handleOk}
            okText={isAvatarEdit ? 'Update' : templeDetail?.is_avatar_enabled ? 'Disable Avatar' : 'Enable Avatar'}
            onCancel={handleCancel}
            okButtonProps={{ disabled: (templeDetail?.is_avatar_enabled && !isAvatarEdit) }}
        >
            <Form form={form}>
                <Row style={{ marginBottom: '35px' }}>
                    <span>Temple Id: <Tag color='blue'>{templeDetail?.id}</Tag></span>
                </Row>
                <Row style={{ marginBottom: '35px' }}>
                    <span>Reference Name: <Tag color='blue'>{templeDetail?.name}</Tag></span>
                </Row>
                {
                    !isAvatarEdit && <Row justify='space-between'>
                        <Form.Item
                            label='Language'
                            name='language_code'
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}>
                            <Select
                                style={{ width: 170 }}
                                placeholder="Select language"
                                options={[{
                                    label: 'Hindi', value: 'hi'
                                }, {
                                    label: 'English', value: 'en'
                                }]}
                                disabled
                            />
                        </Form.Item>
                        <Form.Item
                            label='Onboard Reg Temple'
                            name='onboard_on_regional_temple'
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                            valuePropName='checked'
                        >
                            <Switch disabled={templeDetail?.is_avatar_enabled} />
                        </Form.Item>
                    </Row>
                }
                <Col span={22}>
                    <Form.Item
                        label='Select God'
                        name='god_id'
                        labelCol={{ span: 32 }}
                        wrapperCol={{ span: 32 }}>
                        <Select
                            style={{ width: 390 }}
                            size='middle'
                            placeholder="Select God"
                            options={godList?.map(god => ({
                                label: (
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50px!important' }}>
                                        <Image width={30} height={30} src={god?.thumbnail} style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                                        <span style={{ marginLeft: '10px' }}>
                                            {god?.label}
                                        </span>
                                    </div>
                                ),
                                value: god.value
                            }))}
                            disabled={isAvatarEdit || templeDetail?.is_avatar_enabled}
                        />
                    </Form.Item>
                    {isAvatarEdit &&
                        <Form.Item
                            label='Avatar'
                            name={'avatar_link'}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            valuePropName='fileList'
                            rules={[
                                {
                                    min: 1,
                                    max: 1,
                                    validator: async (
                                        rules: any,
                                        values: any,
                                    ) => {
                                        if (values?.length < rules.min) {
                                            return Promise.reject(
                                                '',
                                            )
                                        }
                                    },
                                },
                            ]}
                        >
                            <S3Upload
                                cdnUrl={CONTENT_CDN_URL}
                                preSignUrl={CONTENT_TOI_PRESIGN_URL}
                                min={1}
                                max={1}
                                templeCode={templeDetail?.temple_code}
                            />
                        </Form.Item>
                    }
                </Col>
            </Form>
        </Modal>
    )
}

export default AvatarUploadModal