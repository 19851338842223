import { AxiosRequestConfig } from 'axios'

import AgentApi from '../Astrologer/Agent'
import PagesApi from '../Astrologer/Pages'
import ReviewsApi from '../Astrologer/Reviews'
import SectionsApi from '../Astrologer/Sections'

class MonetisationAstroApi {
  agentApi: AgentApi
  pagesApi: PagesApi
  reviewsApi: ReviewsApi
  sectionsApi: SectionsApi

  constructor(
    config: AxiosRequestConfig,
    monetisationExtraHeaders?: { 'Accept-Language': string },
    appHeaders?: { 'x-afb-app-name': string }
  ) {
    const headers = {
      ...config.headers,
      ...(monetisationExtraHeaders || {}),
      ...(appHeaders || {}),
    };
    const updatedConfig = { ...config, headers };
    this.agentApi = new AgentApi(updatedConfig)
    this.pagesApi = new PagesApi(updatedConfig)
    this.reviewsApi = new ReviewsApi(updatedConfig)
    this.sectionsApi = new SectionsApi(updatedConfig)
  }
}

export default MonetisationAstroApi
