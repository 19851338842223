import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../apiClient'
import {
  CityLocation,
  DistrictResponse,
  DistrictSlugData,
  DistrictSlugResponse,
  Locality,
  LocalityData,
  LocalityResponse,
  OneLocation,
  Response,
} from './location.types'
import { DistrictData } from './location.types.d'

type Header = {
  [key: string]: string
}
class LocationApi {
  api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
    if (typeof window === 'undefined') {
      this.api?.interceptors?.request?.use((config: any) => {
        config.headers['Cookie'] = `${
          config.headers['Cookie'] ? config.headers['Cookie'] + ';' : ''
        }platform-type=vercel`
        return config
      })
    }
  }

  async getLocations(
    cityName?: string,
    limit?: number,
    offset?: number,
  ): Promise<CityLocation> {
    const response = await this.api.get<CityLocation>(
      '/expresso/mandir/astroyogi/locations.php',
      {
        params: { cityName, limit, offset },
      },
    )
    return response.data
  }
  async getLocationsFromAtlas(q?: string, limit?: number, offset = 0) {
    return this.api.get<LocalityData>('/atlas/external/v1/localities', {
      params: { q, limit, offset: offset },
      transformRequest: (data, headers) => {
        delete headers?.['x-afb-platform']
        return JSON.stringify(data)
      },
    })
  }
  async getDistricts(params?: any): Promise<DistrictData> {
    const response = await this.api.get<DistrictResponse>(
      '/atlas/external/v1/districts',
      {
        params: params,
        transformRequest: (data, headers) => {
          delete headers['x-afb-platform']
          return JSON.stringify(data)
        },
      },
    )
    return response?.data?.data
  }
  async getDistrictsBySlug(slug?: string): Promise<DistrictSlugData> {
    const response = await this.api.get<DistrictSlugResponse>(
      '/atlas/external/v1/districts/' + slug,
    )
    return response?.data?.data
  }
  async getLocationsFromAtlasBySlug(slug?: string): Promise<LocalityData> {
    const response = await this.api.get<LocalityData>(
      '/atlas/external/v1/localities/slug/' + slug,
      {
        transformRequest: (data, headers) => {
          delete headers?.['x-afb-platform']
          return JSON.stringify(data)
        },
      },
    )
    return response.data
  }
  async getLocation(slug?: string): Promise<OneLocation> {
    const response = await this.api.get<OneLocation>(
      '/expresso/mandir/astroyogi/location.php',
      { params: { slug } },
    )
    return response.data
  }
  async getLocationsFromAtlasById(id?: string) {
    return this.api.get<Response<LocalityResponse>>(
      '/atlas/external/v1/localities/' + id,
      {
        transformRequest: (data, headers) => {
          delete headers?.['x-afb-platform']
          return JSON.stringify(data)
        },
      },
    )
  }
}

export default LocationApi
