import { Form, Input, Row, Select, Col, Button } from "antd"
import React from "react"
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import { DeleteOutlined, HolderOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { SortableContainer, SortableElement } from "react-sortable-hoc"

interface Props {
    fields: any
    cdnUrl: string
    preSignUrl: string
    add: () => void
    remove: (item: any) => void
    datatype: string
}
const { Option } = Select;

interface FormItemsProps {
    index: number
    field: any
    remove: (item: any) => void
    cdnUrl: string
    preSignUrl: string
    itemIndex: number
}
const FormItems = (props: FormItemsProps) => {
    const { itemIndex, field, remove, cdnUrl, preSignUrl } = props;
    return (
        <div style={{
            border: '1px solid #e0dcdc',
            marginRight: '0px',
            marginBottom: '10px',
            padding: '10px 0px',
            width: '100%',
            position: 'relative',
            zIndex: 10000000,
            backgroundColor: 'white',
            cursor: 'grab'
        }}>
            <Row style={{ width: '100%', justifyContent: 'space-between', alignItems: "start", paddingLeft: '10px', paddingRight: "10px" }}>
                <HolderOutlined />
                <Row style={{ justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
                    <Form.Item
                        label={`Deeplink ${itemIndex + 1}`}
                        name={[field.name, 'deeplink']}
                        labelCol={{ span: 28 }}
                        wrapperCol={{ span: 28 }}
                        style={{ flexBasis: '85%' }}
                    >
                        <Input placeholder='Enter deeplink' />
                    </Form.Item>

                    <Button
                        style={{ flexBasis: '8%' }}
                        onClick={() => {
                            remove(field.name)
                        }}
                        type='ghost'
                        icon={<DeleteOutlined />}
                        size='middle'
                        danger
                    ></Button>


                </Row>

                <Col span={10}>
                    <Form.Item
                        label={`Media type ${itemIndex + 1}`}
                        name={[field.name, 'media_type']}
                        labelCol={{ span: 28 }}
                        wrapperCol={{ span: 28 }}
                    >
                        <Select placeholder='Please Select Media type'>
                            <Option value={'image'} key={'image'}>
                                Image
                            </Option>
                            <Option value={'video'} key={'video'}>
                                Video
                            </Option>
                        </Select>
                    </Form.Item>

                </Col>

                <Row>
                    <Form.Item
                        label={`Media`}
                        name={[field.name, 'media_url']}
                        labelCol={{ span: 28 }}
                        rules={[
                            {
                                required: true,
                                min: 0,
                                max: 1,
                                validator: async (rules: any, values: any) => {
                                    if (values?.length < rules.min) {
                                        return Promise.reject('Please upload Media')
                                    }
                                },
                            },
                        ]}
                        valuePropName='fileList'
                    >
                        <S3Upload
                            cdnUrl={cdnUrl}
                            preSignUrl={preSignUrl}
                            min={1}
                            max={1}
                        />
                    </Form.Item>


                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues?.data?.info_media_web?.media_list?.[itemIndex]?.media_type !== currentValues?.data?.info_media_web?.media_list?.[itemIndex]?.media_type

                        }
                    >
                        {({ getFieldValue }) => {
                            return getFieldValue(['data'])?.info_media_web?.media_list?.[itemIndex]?.media_type === 'video' && (
                                <Form.Item
                                    label={`Thumbnail`}
                                    name={[field.name, 'thumbnail']}
                                    labelCol={{ span: 28 }}
                                    rules={[
                                        {
                                            required: true,
                                            min: 0,
                                            max: 1,
                                            validator: async (rules: any, values: any) => {
                                                if (values?.length < rules.min) {
                                                    return Promise.reject('Please upload Thumbnail')
                                                }
                                            },
                                        },
                                    ]}
                                    valuePropName='fileList'
                                >
                                    <S3Upload
                                        cdnUrl={cdnUrl}
                                        preSignUrl={preSignUrl}
                                        min={1}
                                        max={1}
                                    />
                                </Form.Item>
                            )
                        }
                        }
                    </Form.Item>
                </Row>
            </Row>


        </div>
    )
}

const SortableFormItems = SortableElement(FormItems);

const Banners: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, datatype }) => {
    return (
        <>
            <Row justify="space-between" style={{ width: '100%' }}>
                {fields && fields.map((field: any, index: number) => {
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <SortableFormItems
                            index={index}
                            itemIndex={index}
                            field={field}
                            remove={remove}
                            cdnUrl={cdnUrl}
                            preSignUrl={preSignUrl}
                        />
                    )
                })}
                <Col span={24} style={{ paddingTop: '10px' }}>
                    <Row justify='start'>
                        <Col span={4}>
                            <Button
                                onClick={() => {
                                    add()
                                }}
                                type='ghost'
                                icon={<PlusCircleOutlined />}
                                size='middle'
                            >
                                {datatype}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default SortableContainer(Banners)