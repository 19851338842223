import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { Response } from './types'
import {
  CategoryListResponse,
  CategoryResponse,
  CreateCategoryPayload,
} from './types/categories'

class CategoriesApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createCategory = (payload: CreateCategoryPayload) =>
    this.api.post(`/gw1/puja/internal/v1/categories`, payload)

  updateCategory = (categoryId: string, payload: CreateCategoryPayload) =>
    this.api.patch(`/gw1/puja/internal/v1/categories/${categoryId}`, payload)

  getCategories = () =>
    this.api.get<CategoryListResponse>(`/gw1/puja/internal/v1/categories`)

  getCategoryById = (categoryId: string) =>
    this.api.get<CategoryResponse>(
      `/gw1/puja/internal/v1/categories/${categoryId}`,
    )

  deleteCategory = (categoryId: string) =>
    this.api.delete(`/gw1/puja/internal/v1/categories/${categoryId}`)
}

export default CategoriesApi
