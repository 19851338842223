import { Button, Checkbox, Form, Input, InputNumber, Radio, Select } from "antd";
import { GOD_ITEM_TYPES, GOD_ITEMS, FORM_CONFIG } from "./dynamic-items-definitions"
import FileUpload from '@a4b/ui/src/modules/admin/components/FileUpload';
import { useAppContext } from "@/components/AppContext";
import { debounce } from "lodash";
import { useCallback, useState } from "react";

const requiredRule = [{ required: true }]
const FREE_ITEM_TYPES = new Set([GOD_ITEM_TYPES.BACKGROUND, GOD_ITEM_TYPES.ARCH_LAYER, GOD_ITEM_TYPES.FLOOR_LAYER, GOD_ITEM_TYPES.MIDDLE_LAYER, GOD_ITEM_TYPES.CTA, GOD_ITEM_TYPES.ITEM_BASE])

interface GodItemFormProps {
    form: any
    editItem: any
    showSubmit?: boolean
}

const GodItemForm: React.FC<GodItemFormProps> = ({ form, editItem, showSubmit = true }) => {
    const { networkInstance } = useAppContext();
    const type = Form.useWatch('type', form);
    const access_type = Form.useWatch('access_type', form);
    const code = Form.useWatch('code', form);
    const [isCodeUnique, setIsCodeUnique] = useState(false);
    const [freezeType, setFreezeType] = useState(false);

    const checkIsCodeUnique = debounce(useCallback(async (code) => {
        try {
            const res = await networkInstance.clientWithHeaders.shrineApi.checkGodItemCodeUnique(code);
            setIsCodeUnique(res.data.data.is_unique);
        } catch (error) {
            console.log(error)
            setIsCodeUnique(false);
        }
    }, [networkInstance.clientWithHeaders.shrineApi]), 1000)

    const getFileUploadUrl = useCallback(async (file) => {
        if (!freezeType) {
            setFreezeType(true);
        }
        // try {
        const res = await networkInstance.clientWithHeaders.shrineApi.getS3SignedUrl(file.name, 'items', form.getFieldValue('type'));
        return res.data.data.info
        // } catch (error) {

        // }
    }, [form, freezeType, networkInstance.clientWithHeaders.shrineApi])

    return (
        <div>
            <Form.Item
                label="God item type"
                name="type"
                rules={[{ required: true, message: 'Please input name!' }]}
            >
                <Select
                    onChange={(val) => {
                        // setting default value for code 
                        form.setFieldValue('code', val + '_')

                        // setting static access type free for free items 
                        if (FREE_ITEM_TYPES.has(val)) {
                            form.setFieldValue('access_type', 'free')
                        }
                    }}
                    disabled={!!editItem}
                >
                    {GOD_ITEMS.map((item) => <Select.Option value={item}>{item}</Select.Option>)}
                </Select>
            </Form.Item>
            {
                type && <> <Form.Item
                    label="Code"
                    name="code"
                    rules={editItem ? [] : [{
                        required: true, validator: (rule, value, callback) => {
                            let regex = new RegExp(/^[a-z0-9_]+$/);
                            checkIsCodeUnique(value);
                            if (!regex.test(value)) callback("Only small case english latters, numbers and _ is allowed."); else callback(undefined);
                        }
                    }]}

                    hasFeedback
                    validateStatus={editItem ? 'success' : isCodeUnique ? 'success' : 'error'}
                >
                    <Input maxLength={32} disabled={!!editItem} />
                </Form.Item>
                    <p style={{ color: 'red' }}>{!editItem && !isCodeUnique && 'Please enter a valid code'} </p>
                    {
                        type !== GOD_ITEM_TYPES.PRAYER &&
                        <FileUpload rules={requiredRule} name={'thumbnail'} accept={`.png,.jpg,.jpeg,.gif,.webp,${code === 'cta_right_2_new_default' ? 'application/JSON' : ''}`} getS3PathApi={getFileUploadUrl} title={'upload image'} />
                    }
                    <FileUpload rules={requiredRule} name={'attachment'} accept={`.png,.jpg,.jpeg,.gif,.webp,${code === 'cta_right_2_new_default' ? 'application/JSON' : ''}`} getS3PathApi={getFileUploadUrl} title={'upload image'} />
                    {
                        //@ts-ignore
                        type && FORM_CONFIG[type].propertyForm && FORM_CONFIG[type].propertyForm({ getFileUploadUrl })
                    }
                    <Form.Item
                        label="Access types"
                        name="access_type"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group disabled={FREE_ITEM_TYPES.has(type) || ((editItem?.access_type === 'free' || editItem?.access_type === 'vip'))} >
                            <Radio disabled={!!editItem} value={'free'}>Free</Radio>
                            <Radio value={'coins_based'}>Coins based</Radio>
                            <Radio value={'days_active_based'}>Days active based</Radio>
                            <Radio disabled={!!editItem} value={'vip'}>VIP</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {
                        access_type === 'coins_based' && <>
                            <Form.Item
                                label="Coins cost"
                                name="coins_cost"
                                rules={[{ required: true }]}
                            >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                label="Validity Period (In seconds)"
                                name="validity_period"
                                rules={[{ required: true }]}
                            >
                                {/* <DatePicker
              format="MM-DD HH:mm:ss"
            // showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
            /> */}
                                <Select style={{ width: '200px' }} >
                                    <Select.Option value={43200}> 12 Hours </Select.Option>
                                    <Select.Option value={86400}> 1 Day </Select.Option>
                                    <Select.Option value={777600}> 9 Days </Select.Option>
                                    <Select.Option value={31536000}> 1 year </Select.Option>
                                </Select>
                            </Form.Item>
                        </>

                    }
                    {
                        access_type === 'days_active_based' && <>
                            <Form.Item
                                label="Coins cost"
                                name="coins_cost"
                                rules={[{ required: true }]}
                            >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                label="Days active milestone"
                                name="days_active"
                                rules={[{ required: true }]}
                            >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                label="Validity Period (In seconds)"
                                name="validity_period"
                                rules={[{ required: true }]}
                            >
                                <Select style={{ width: '200px' }} >
                                    <Select.Option value={43200}> 12 Hours </Select.Option>
                                    <Select.Option value={86400}> 1 Day </Select.Option>
                                    <Select.Option value={777600}> 9 Days </Select.Option>
                                    <Select.Option value={31536000}> 1 year </Select.Option>
                                </Select>
                            </Form.Item>
                        </>
                    }
                </>
            }
            <Form.Item
                label={"Available for all gods"}
                name={"available_for_all_gods"}
                valuePropName='checked'
            >
                <Checkbox></Checkbox>
            </Form.Item>

            {
                showSubmit &&
                <Form.Item>
                    <Button type="primary" htmlType="submit" >Submit</Button>
                    <Button style={{ marginLeft: '10px' }} htmlType="submit" onClick={() => { setFreezeType(false); form.resetFields(); }}>Reset</Button>
                </Form.Item>
            }
        </div>
    )
}

export default GodItemForm