import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { Col, Row } from 'antd'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import jwt_decode from "jwt-decode";
import { LoginPayload } from '@a4b/api/src/modules/AdminControl/types'

const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_NEW_AUTH_CLIENT_ID
let google = (global as any).google
let window = (global as any).window

interface LoginProps {
  login: (loginPayload: LoginPayload) => Promise<void>
}

const LoginPage = ({ login }: LoginProps) => {
  const navigate = useNavigate()

  const handleCredentialResponse = useCallback(
    (response: any) => {
      const jwt: any = jwt_decode(response.credential)
      if (!jwt?.email || jwt?.email?.split("@")?.[1] !== "appsforbharat.com") {
        google.accounts.id.revoke(jwt?.email, (done: any) => {
          console.log('consent revoked');
        });
        window.location.href = '/login';
      }
      if (jwt?.email && jwt?.email?.includes("appsforbharat.com")) {
        login({
          provider: 'google',
          id_token: response.credential,
        })
      }
    },
    [login],
  )


  useEffect(() => {
    const authToken: string = Cookies.get('gauth-token') || ""
    if (!authToken) {
      google.accounts.id.initialize({
        client_id: GOOGLE_AUTH_CLIENT_ID, // Replace with your Google Client ID
        ux_mode: 'popup',
        callback: handleCredentialResponse, // We choose to handle the callback in client side, so we include a reference to a function that will handle the response
      })
      // You can skip the next instruction if you don't want to show the "Sign-in" button
      google.accounts.id.renderButton(
        document.getElementById('loginButtonContainer'), // Ensure the element exist and it is a div to display correcctly
        { theme: 'outline', size: 'large' }, // Customization attributes
      )
      google.accounts.id.prompt()
    } else {
      const jwt: any = jwt_decode(authToken)
      if (!jwt?.email || jwt?.email?.split("@")?.[1] !== "appsforbharat.com") {
        google.accounts.id.revoke(jwt?.email, (done: any) => {
          console.log('consent revoked');
        });
        Cookies.set('gauth-token', ``, { expires: 0 });
        window.location.href = '/login';
      }
      if (jwt?.email && jwt?.email?.includes("appsforbharat.com")) {
        window.location.href = '/'
      }
    }
  }, [handleCredentialResponse, navigate])


  return (
    <Row style={{ height: '300px' }} justify='center' align='middle'>
      <Col>
        <div id='loginButtonContainer'></div>
      </Col>
    </Row>
  )
}

export default withDefaultLayout(LoginPage)
