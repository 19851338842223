import React from "react"
import { Col, Form, Input } from "antd"

const SocialMedia = () => {
    return (
        <>
            <Col span={24}>
                <Col
                    style={{
                        marginLeft: '3px',
                        display: 'contents'
                    }}>
                    <Form.Item
                        label={`Facebook`}
                        name={['social_media', 'fb']}
                        labelCol={{ span: 28 }}
                        wrapperCol={{ span: 28 }}
                        style={{ marginTop: '10px' }}
                        rules={[
                            {
                                required: false
                            },
                        ]}
                    >
                        <Input placeholder="Enter facebook url" />
                    </Form.Item>
                    <Form.Item
                        label={`Instagram`}
                        name={['social_media', 'instagram']}
                        labelCol={{ span: 28 }}
                        wrapperCol={{ span: 28 }}
                        style={{ marginTop: '10px' }}
                        rules={[
                            {
                                required: false
                            },
                        ]}
                    >
                        <Input placeholder="Enter instagram url" />
                    </Form.Item>
                    <Form.Item
                        label={`Youtube`}
                        name={['social_media', 'youtube']}
                        labelCol={{ span: 28 }}
                        wrapperCol={{ span: 28 }}
                        style={{ marginTop: '10px' }}
                        rules={[
                            {
                                required: false
                            },
                        ]}
                    >
                        <Input placeholder="Enter youtube url" />
                    </Form.Item>
                </Col>
            </Col>
        </>
    )
}

export default SocialMedia