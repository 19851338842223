import { throwError } from "../../../utils/helper";

type Item = {
  default_title: string;
  default_subtitle: string;
  default_image_attachment_id: string;
};

class MenuLogin {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items: Item[] = [];

  getSerializedData = (widgetFormData: MenuLogin) => {
    let itemModified = widgetFormData?.items?.map((item: Item) => {
      return {
        ...item,
      };
    });
    widgetFormData.items = itemModified;
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: MenuLogin) => {
    return widgetFormData;
  };

  defaultTitleValidator = async (rule: any, value: any) => {
    if (!value) throwError("Default title required!");
  };
  defaultSubtitleValidator = async (rule: any, value: any) => {
    if (!value) throwError("Default subtitle required!");
  };
  defaultAttachmentIdValidator = async (rule: any, value: any) => {
    if (!value) throwError("Default attachment id required!");
  };
}
export default MenuLogin;
export const MenuLoginInstance = new MenuLogin();
