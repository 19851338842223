import { Badge, Button, Input, Modal, PaginationProps, Select, Switch, TableColumnType, TablePaginationConfig, TableProps, Tag, message } from "antd";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import { EventGroup } from "@a4b/api/src/modules/Events/types";
import { EVENT_STATE_COLOR_CODES, EVENT_STATUS, EVENT_STATUS_LIST, EVENT_TYPES, PLATFORM_TYPES_ARRAY, PRODUCER_TYPES, VALID_STATE_CHANGES } from "../utils/constants";
import styled from "styled-components";
import { CloseCircleOutlined, CopyFilled, EditFilled, ExclamationCircleFilled, SettingOutlined } from "@ant-design/icons";
import { openErrorNotification, openNotification } from "../../../../../utils";
import { useSearchParams } from 'react-router-dom';
import { renderDateTime } from "../../../../../components/TableHelpers/ColumnHelper";
import { useEffect, useState } from "react";
import { CopyOutlined } from "@ant-design/icons";

const SelectStatus = styled(Select)`
  width: 200px;
`

const Title = styled.div`
  position: relative;
  text-align: center;
`

const TitleFilter = styled.div`
  position: absolute;
  width: calc(100% + 16px);
  top: -50px;
  left: 0px;
  display: flex;
  flex-grow: 1;
`

const TitleFilterWithoutSorter = styled.div`
  position: absolute;
  width: 100%;
  top: -50px;
  left: 0px;
  display: flex;
  flex-grow: 1;
`

const TitleFilterAction = styled.div`
  position: absolute;
  width: calc(100% + 16px);
  top: -50px;
  left: -8px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`

const SelectFilter = styled<typeof Select>(Select)`
  flex-grow: 1
`

const ClosedButtonStyled = styled(Button)`
  padding: 4px
`

const StyledTagListing = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap:5px
`

const stopPropagation = (e: any) => e.stopPropagation()

const showTotal: PaginationProps['showTotal'] = (total) => `Total ${total} items`;


export default function useTableConfig<T>(
  isLoading: boolean,
  dataSource: any[],
  groups: EventGroup[],
  pagination: {
    limit: number;
    offset: number;
    total: number;
  },
  setPagination: React.Dispatch<React.SetStateAction<{
    limit: number;
    offset: number;
    total: number;
  }>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
  updateNavigationId: (definationId: string) => void,
  getEvents: () => Promise<void>,
  showColumnSettingDrawer: () => void,
  tableColumn: {
    [key: string]: boolean;
  }
): TableProps<any[]> {
  const { networkInstance, userProfile } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setName(searchParams.get("name") || "")
  }, [searchParams])

  useEffect(() => {
    setDescription(searchParams.get("description") || "")
  }, [searchParams])


  const updatedSearchParams = new URLSearchParams(searchParams.toString());
  const [updateStatus, setUpdateStatus] = useState(null);
  const handleNameFilter = (e: any) => {
    if (e.target.value.trim() === "") {
      updatedSearchParams.delete('name')
    } else {
      updatedSearchParams.set('name', e.target.value);
    }
    setSearchParams(updatedSearchParams);
  }

  const handleNameKeyDown = function (e: any) {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleNameFilter(e);
    }
  }

  const handlePlatformCodeFilter = (e: any) => {
    if (e.target.value.trim() === "") {
      updatedSearchParams.delete('platform_code')
    } else {
      updatedSearchParams.set('platform_code', e.target.value);
    }
    setSearchParams(updatedSearchParams);
  }

  const handlePlatformCodeKeyDown = function (e: any) {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handlePlatformCodeFilter(e);
    }
  }

  const handleDescriptionFilter = (e: any) => {
    if (e.target.value.trim() === "") {
      updatedSearchParams.delete('description')
    } else {
      updatedSearchParams.set('description', e.target.value);
    }
    setSearchParams(updatedSearchParams);
  }

  const handleDescriptionKeyDown = function (e: any) {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleDescriptionFilter(e);
    }
  }


  //@ts-ignore
  const columns: TableColumnType<any>[] = [
    {
      title: "Created at",
      dataIndex: 'created_at',
      render: renderDateTime,
      key: 'created_at',
      width: 150,
      sorter: (a: any, b: any) => a?.created_at - b?.created_at
    },
    {
      title: <Title > Name
        <TitleFilter onClick={stopPropagation} >
          <Input onChange={(e) => { setName(e.target.value); }} value={name} placeholder="Name"
            onKeyDown={handleNameKeyDown}
            onBlur={handleNameFilter}
          />
        </TitleFilter>
      </Title>,
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '45px',
      render: (text: string, record: any) => {
        return <div>
          {
            text
          }
          <CopyOutlined style={{
            marginLeft: "10px"
          }} onClick={
            () => {
              navigator.clipboard.writeText(text)
              message.success("Event name copied")
            }
          } />
        </div>
      },
    },
    {
      title: "Id",
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: <Title > Description
        <TitleFilter onClick={stopPropagation}>
          <Input onChange={(e) => { setDescription(e.target.value); }} value={description} placeholder="Description "
            onKeyDown={handleDescriptionKeyDown}
            onBlur={handleDescriptionFilter} />
        </TitleFilter>
      </Title>,
      dataIndex: 'description',
      width: '600px',
      align: 'center',
      key: 'description',
      sorter: (a: any, b: any) => a?.description.localeCompare(b?.description),
    },
    {
      title: <Title > Producer
        <TitleFilter onClick={stopPropagation}>
          <SelectFilter
            value={searchParams.get('producer')}
            showSearch
            placeholder="Producer " onChange={(value: any) => {
              let updatedSearchParams = new URLSearchParams(searchParams.toString());
              if (value === '') {
                updatedSearchParams.delete('producer');
              } else {
                updatedSearchParams.set('producer', value);
              }
              setSearchParams(updatedSearchParams);
            }} >
            {
              PRODUCER_TYPES.map((producer) => {
                return <Select.Option value={producer}> {producer}</Select.Option>
              })
            }
          </SelectFilter>
          {
            updatedSearchParams.get('producer') &&
            <ClosedButtonStyled onClick={() => {
              updatedSearchParams.delete('producer')
              setSearchParams(updatedSearchParams);
            }}><CloseCircleOutlined /></ClosedButtonStyled>
          }
        </TitleFilter>
      </Title >,
      dataIndex: 'producer',
      align: 'center',
      key: 'producer',
      sorter: (a: any, b: any) => a?.producer.localeCompare(b?.producer),
      width: '200px'
    },
    {
      title: <Title > Event type
        <TitleFilter onClick={stopPropagation}>
          <SelectFilter
            value={searchParams.get('event_type')}
            showSearch
            placeholder="Event type " onChange={(value: any) => {
              let updatedSearchParams = new URLSearchParams(searchParams.toString());
              if (value === '') {
                updatedSearchParams.delete('event_type');
              } else {
                updatedSearchParams.set('event_type', value);
              }
              setSearchParams(updatedSearchParams);
            }} >
            {
              EVENT_TYPES.map((event_type) => {
                return <Select.Option value={event_type}> {event_type}</Select.Option>
              })
            }
          </SelectFilter>
          {
            updatedSearchParams.get('event_type') &&
            <ClosedButtonStyled onClick={() => {
              updatedSearchParams.delete('event_type')
              setSearchParams(updatedSearchParams);
            }}><CloseCircleOutlined /></ClosedButtonStyled>
          }
        </TitleFilter>
      </Title >,
      dataIndex: 'event_type',
      align: 'center',
      key: 'event_type',
      sorter: (a: any, b: any) => a?.event_type.localeCompare(b?.event_type),
      width: '200px'
    },
    {
      title: <Title > Group
        <TitleFilter onClick={stopPropagation}>
          <SelectFilter
            value={searchParams.get('group')}
            showSearch
            placeholder="Group " onChange={(value: any) => {
              let updatedSearchParams = new URLSearchParams(searchParams.toString());
              if (value === '') {
                updatedSearchParams.delete('group');
              } else {
                updatedSearchParams.set('group', value);
              }
              setSearchParams(updatedSearchParams);
            }} >
            {
              groups.map((group) => {
                return <Select.Option value={group.name}> {group.name}</Select.Option>
              })
            }
          </SelectFilter>
          {
            updatedSearchParams.get('group') &&
            <ClosedButtonStyled onClick={() => {
              updatedSearchParams.delete('group')
              setSearchParams(updatedSearchParams);
            }}><CloseCircleOutlined /></ClosedButtonStyled>
          }
        </TitleFilter>
      </Title >,
      dataIndex: 'group',
      align: 'center',
      key: 'group',
      width: 300,
      sorter: (a: any, b: any) => a?.group.localeCompare(b?.group)
    },
    {
      title: <Title > Tags
        <TitleFilterWithoutSorter onClick={stopPropagation}>
          <SelectFilter value={(searchParams.get('tags') || '').split(',').filter((val) => val.trim() !== '')} placeholder="Tags " mode="tags" onChange={(value: any) => {
            let updatedSearchParams = new URLSearchParams(searchParams.toString());
            if (value.length === 0) {
              updatedSearchParams.delete('tags')
            } else {
              updatedSearchParams.set('tags', value);
            }
            setSearchParams(updatedSearchParams);
          }}>
          </SelectFilter>
          {
            updatedSearchParams.get('tags') &&
            <ClosedButtonStyled onClick={() => {
              updatedSearchParams.delete('tags')
              setSearchParams(updatedSearchParams);
            }}><CloseCircleOutlined /></ClosedButtonStyled>
          }
        </TitleFilterWithoutSorter>
      </Title>,
      dataIndex: 'tags',
      align: 'center',
      render: (value: string[]) => {
        return <StyledTagListing >
          {
            value?.map((tag) => {
              return <Tag> {tag} </Tag>
            })
          }
        </StyledTagListing>
      },
      key: 'tags',
      width: '500px',
    },
    {
      title: <Title > Platform type
        <TitleFilter onClick={stopPropagation}>
          <SelectFilter
            value={searchParams.get('platform_type')}
            showSearch
            placeholder="Platform type " onChange={(value: any) => {
              let updatedSearchParams = new URLSearchParams(searchParams.toString());
              if (value === '') {
                updatedSearchParams.delete('platform_type');
              } else {
                updatedSearchParams.set('platform_type', value);
              }
              setSearchParams(updatedSearchParams);
            }} >
            {
              PLATFORM_TYPES_ARRAY.map((platform_type) => {
                return <Select.Option value={platform_type}> {platform_type}</Select.Option>
              })
            }
          </SelectFilter>
          {
            updatedSearchParams.get('platform_type') &&
            <ClosedButtonStyled onClick={() => {
              updatedSearchParams.delete('platform_type')
              setSearchParams(updatedSearchParams);
            }}><CloseCircleOutlined /></ClosedButtonStyled>
          }
        </TitleFilter>
      </Title >,
      dataIndex: 'platform_type',
      align: 'center',
      key: 'platform_type',
      width: 200,
      sorter: (a: any, b: any) => a?.platform_type.localeCompare(b?.platform_type)
    },
    {
      title: "Updated at",
      dataIndex: 'updated_at',
      render: renderDateTime,
      key: 'updated_at',
      width: 150,
      sorter: (a: any, b: any) => a?.updated_at - b?.updated_at
    },
    {

      title: 'Created by',
      dataIndex: 'created_by',
      key: 'created_by',
      width: 150,
      sorter: (a: any, b: any) => a?.created_by.localeCompare(b?.created_by)
    },
    {
      title: 'Updated by',
      dataIndex: 'updated_by',
      key: 'updated_by',
      width: 150,
      sorter: (a: any, b: any) => a?.updated_by.localeCompare(b?.updated_by)
    }, {
      title: <Title >
        Platform code
        <TitleFilter onClick={stopPropagation}>
          <Input defaultValue={searchParams.get('platform_code') || undefined} placeholder="Platform code"
            onKeyDown={handlePlatformCodeKeyDown}
            onBlur={handlePlatformCodeFilter}
          />
        </TitleFilter>
      </Title>,
      dataIndex: 'platform_code',
      key: 'platform_code',
      width: 200,
      sorter: (a: any, b: any) => a?.platform_code.localeCompare(b?.platform_code)
    },
    {
      title: "Version",
      dataIndex: 'version',
      key: 'version',
      align: 'center',
      sorter: (a: any, b: any) => a?.version - b?.version,
      width: 150
    },
    {
      title: <Title > Status
        <TitleFilter onClick={stopPropagation}>
          <SelectFilter value={searchParams.get('status')} placeholder="Status" onChange={(value: any) => {
            let updatedSearchParams = new URLSearchParams(searchParams.toString());
            updatedSearchParams.set('status', value);
            setSearchParams(updatedSearchParams);
          }}>
            {
              EVENT_STATUS_LIST.map((status) => {
                return <Select.Option key={status}>
                  <Badge color={EVENT_STATE_COLOR_CODES[status]} text={status}></Badge>
                </Select.Option>
              })
            }
          </SelectFilter>
          {
            updatedSearchParams.get('status') &&
            <ClosedButtonStyled onClick={() => {
              updatedSearchParams.delete('status')
              setSearchParams(updatedSearchParams);
            }}><CloseCircleOutlined /></ClosedButtonStyled>
          }
        </TitleFilter>
      </Title>,
      dataIndex: 'status',
      align: 'center',
      key: 'status',
      render(value: EVENT_STATUS) {
        return <Tag color={EVENT_STATE_COLOR_CODES[value]} > {value}</Tag>
      },
      width: 200,
      sorter: (a: any, b: any) => a?.status.localeCompare(b?.status)
    },
    {
      title: <Title> Actions
        <TitleFilterAction>
          <Button type="primary" onClick={showColumnSettingDrawer}> Column setting <SettingOutlined /> </Button>
        </TitleFilterAction>
      </Title>,
      dataIndex: 'id',
      align: 'center',
      key: 'action',
      fixed: 'right',
      width: 200,
      render(value: string, record: any) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* <Switch style={{ width: '90px' }} checked={record.active} unCheckedChildren="Activate" /> */}
          <SelectStatus size="small" value={updateStatus} placeholder="Click to change status" onSelect={(value: any) => {
            Modal.confirm({
              title: `Are you sure. you want to change the status form ${record.status} to ${value}`,
              icon: <ExclamationCircleFilled />,
              onOk: async () => {
                try {
                  await networkInstance.clientWithHeaders.events.patchDefination({ id: record.id, name: record.name, status: value as string, updated_by: userProfile.email || 'NA' })
                  getEvents()
                } catch (error) {
                  openNotification('error', { message: 'Status change failed ' + error })
                  openErrorNotification(error);
                }
              },
              onCancel() {
                setUpdateStatus(null);
              },
            });
          }}>
            {
              VALID_STATE_CHANGES[record.status as EVENT_STATUS].map((status) => {
                return <Select.Option value={status}><Badge style={{ width: '100%' }} color={EVENT_STATE_COLOR_CODES[status]} text={status} /></Select.Option>
              })
            }
          </SelectStatus>
          <div style={{ display: 'flex', width: 200, flexWrap: 'wrap', gap: '5px', marginTop: '5px', justifyContent: 'space-between' }}>
            <Button type="link" size="small" onClick={() => { updateNavigationId(record.id); setDrawer(DRAWER_TYPE.PREVIEW_DEFINATION); }}>Preview</Button>
            <Button type="link" size="small" onClick={() => { navigator.clipboard.writeText(window.location.hostname + '/events-management/list/' + record.id); }}><CopyFilled /> Copy path</Button>
            {
              record.status === EVENT_STATUS.DRAFT &&
              <Button type="link" size="small" onClick={() => { updateNavigationId(record.id); setDrawer(DRAWER_TYPE.EDIT_DEFINATIONS); }}><EditFilled /> Edit</Button>
            }
            {
              record.status === EVENT_STATUS.IMPLEMENTED &&
              <Button type="link" size="small" onClick={() => { updateNavigationId(record.id); setDrawer(DRAWER_TYPE.CHECK_TICKETS); }}> Check Tickets </Button>
            }
            <Button type="link" size="small" onClick={() => { updateNavigationId(record.id); setDrawer(DRAWER_TYPE.CLONE_DEFINATIONS); }}>Clone</Button>
            {/* <Button type="link" >Delete</Button> */}
          </div>
        </div>
      },

    }
  ].filter((column) => tableColumn[column.key])

  const tableOnChange = (_pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination({ limit: _pagination.pageSize || pagination.limit, offset: (_pagination.current || 1) - 1, total: pagination.total })
  }

  return {
    columns,
    dataSource,
    pagination: { current: pagination.offset + 1, pageSize: pagination.limit, total: pagination.total, showTotal: showTotal },
    onChange: tableOnChange,
    size: 'small',
    scroll: { x: 'max-content' },
    className: 'events-table',
    showSorterTooltip: false,
    sticky: { offsetHeader: 0 },
    loading: isLoading,
    bordered: true
  }
}


