import { Table } from "antd";

import LazyImageRender from "../LazyImageRender";
import { ATTACHMENT } from "@/utils/enum";

interface Props {
  record: any;
}
const YoutubeLiveDataTable = ({ record }: Props) => {
  const dataColumn = [
    {
      title: "Image Attachment",
      dataIndex: "image_attachment_id",
      key: "image_attachment_id",
      ellipsis: true,
      width: "10%",
      render: (text: any, record: any, index: any) => {
        return <LazyImageRender id={text} attachmentType={ATTACHMENT.IMAGE} />;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      width: "10%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Subtitle",
      dataIndex: "subtitle",
      key: "subtitle",
      ellipsis: true,
      width: "10%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Video Attachment",
      dataIndex: "video_attachment_id",
      key: "video_attachment_id",
      ellipsis: true,
      width: "10%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "CTA Text",
      dataIndex: "cta_text",
      key: "cta_text",
      ellipsis: true,
      width: "10%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
  ];
  return (
    <div className="padding-left">
      <Table
        columns={dataColumn}
        dataSource={record?.content?.items || []}
        pagination={false}
        scroll={{ y: 240 }}
      ></Table>
    </div>
  );
};
export default YoutubeLiveDataTable;
