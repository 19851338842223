import React, { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { useAppContext } from '@/components/AppContext';
import withDefaultLayout from '@/hoc/WithDefaultLayout';
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb';
import { Batch } from '@/../../../packages/a4b-api/src/modules/Monetisation/Offerings/types';
import ManageVideoQCPage from './components/ManageVideoQcPage';
import ModalPreview from './components/PreviewVideoModal';

const getPageBreadcrumbs = () => [
    {
        path: '/monetisation/offerings/video-qc',
        breadcrumbName: 'Manage Video QC',
    },
];

const ManageBatchVideoQC: React.FC = () => {
    const { networkInstance, monetisationRoles } = useAppContext();
    const { monetisationOfferingsApi } = networkInstance.clientWithHeaders;

    const [batches, setBatches] = useState<any[]>([]);
    const [batchData, setBatchData] = useState<any | null>(null);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 30, isLastPage: false });
    const [activeTab, setActiveTab] = useState<string>('1');


    const getBatchList = useCallback(async (page: number, pageSize: number) => {
        setLoading(true);
        try {
            const response = await monetisationOfferingsApi.batchApi.getBatchList({
                limit: pageSize,
                offset: (page - 1) * pageSize,
                user_email: monetisationRoles?.email || '',
                batch_status: 'completed',
                filter_type: 'qc_panel'
            });

            const { batches } = response?.data?.data;

            const isLastPage = response?.data?.data?.is_last_page

            const batchList = batches?.map((batch: Batch) => ({
                batch_id: batch?.batch_id,
                batch_code: batch?.batch_code,
                product_name: batch?.product_name,
                product_type: batch?.product_type,
                no_of_items: batch?.no_of_items,
                video_url: batch?.video_url,
                processed_video_url: batch?.meta_data?.processed_video_url,
                store_name: batch?.store_name,
                offering_names: batch?.offering_names,
                meta_data: batch?.meta_data,
            }));

            setBatches(batchList);
            setPagination((prev) => ({ ...prev, isLastPage }));
        } catch (error) {
            message.error('Failed to fetch videos');
        } finally {
            setLoading(false);
        }
    }, [monetisationOfferingsApi?.batchApi, monetisationRoles?.email]);

    useEffect(() => {
        getBatchList(pagination?.current, pagination?.pageSize);
    }, [getBatchList, pagination?.current, pagination?.pageSize]);

    const handleVideoClick = (value: any) => {
        setBatchData(value);
        setVisible(true);
        setActiveTab('1')
    };

    const handleModalClose = () => {
        setVisible(false);
        setBatchData(null);
    };

    const setPageChange = (page: number, pageSize: number) => {
        setPagination((prev) => ({ ...prev, current: page, pageSize }));
    };

    const getYouTubeThumbnailUrl = (url: string) => {
        const urlObj = new URL(url);
        const videoId = urlObj?.searchParams?.get('v');
        return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : '';
    };

    const handleTabChange = (key: string) => {
        setActiveTab(key);
    };

    const handlePageChange = (value: 'previous' | 'next') => {
        if (value === 'previous' && pagination?.current > 1) {
            setPageChange(pagination?.current - 1, pagination?.pageSize);
        } else if (value === 'next' && !pagination?.isLastPage) {
            setPageChange(pagination?.current + 1, pagination?.pageSize);
        }
    };


    return (
        <div style={{ padding: '0 40px' }}>
            <AdminBreadcrumb routes={getPageBreadcrumbs()} />
            <ManageVideoQCPage
                loading={loading}
                batches={batches}
                handleVideoClick={handleVideoClick}
                getYouTubeThumbnailUrl={getYouTubeThumbnailUrl}
                pagination={pagination}
                handlePageChange={handlePageChange}
            />
            <ModalPreview
                visible={visible}
                batchData={batchData}
                handleModalClose={handleModalClose}
                activeTab={activeTab}
                handleTabChange={handleTabChange}
            />
        </div>
    );
};

export default withDefaultLayout(ManageBatchVideoQC);
