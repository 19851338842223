import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import HeroArticle from "@/entities/feeds/Widgets/HeroArticle";
import NumberInput from "@/modules/feeds/components/Number";
import DeletableContainer from "../deletableContainer/DeletableContainer";
import HeaderTags from "../headerTags/HeaderTags";
import { HeroArticleInstance } from "@/entities/feeds/Widgets";

interface Props {
  contentInstance: HeroArticleInstance;
}
const HeroArticleWidget: React.FC<Props> = ({ contentInstance }) => {
  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            required={true}
            label="Header"
            name={["content", "header"]}
            rules={[
              {
                validator: contentInstance.headerValidator,
              },
            ]}
          >
            <Input placeholder="Header" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <HeaderTags widgetClass={contentInstance} />
        </Col>
      </Row>
      <Form.List name={["content", "items"]}>
        {(fields, { add, remove, move }) => {
          return (
            <div>
              {fields.map((field: any, index: number) => {
                return (
                  <Form.Item key={field.key}>
                    <DeletableContainer
                      closeHandler={() => {
                        remove(field.name);
                      }}
                      itemText={`Item Attribute : ${index + 1}`}
                      showItemTextInBold={true}
                      moveFunction={move}
                      fields={fields}
                      currentIndex={index}
                      fieldsLength={fields.length}
                      move={move}
                      add={add}
                    >
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="Article ID"
                            name={[field.name, "r_article_id"]}
                            rules={[
                              {
                                validator: contentInstance.articleIdvalidator,
                              },
                            ]}
                          >
                            <Input
                              placeholder="Article ID"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="CTA Text"
                            name={[field.name, "cta_text"]}
                            rules={[
                              {
                                validator: contentInstance.ctaTextValidator,
                              },
                            ]}
                          >
                            <Input placeholder="Cta text" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </DeletableContainer>
                  </Form.Item>
                );
              })}

              <Form.Item>
                <Row justify="end">
                  <Col span={24}>
                    <Row justify="end">
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            add();
                            // addItem();
                          }}
                          type="dashed"
                          icon={<PlusCircleOutlined />}
                          size="middle"
                        >
                          Add Item Attributes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
              <div style={{ height: "20px" }}></div>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

export default HeroArticleWidget;
