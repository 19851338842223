import { Rashifal } from "@a4b/api/src/modules/Content/Rashifal/types";
import { getLanguageNameFromValue } from "@/utils/constants";
import { Button, Switch, TableColumnType, TablePaginationConfig, TableProps } from "antd";
import moment from "moment";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { openNotification } from "@/utils";
import { useAppContext } from "@/components/AppContext";

export default function useTableConfig<T>(
  dataSource: T[],
  pagination: TablePaginationConfig,
  setEditId: (id: string) => void,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
): TableProps<Rashifal.RashifalItem[]> {

  const { networkInstance } = useAppContext();

  const columns: TableColumnType<Rashifal.RashifalItem>[] = [
    {
      title: 'S No',
      dataIndex: 'id',
      render(value, record, index) {
        return index + 1
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
    },
    // {
    //   title: 'Rules',
    //   dataIndex: 'rules',
    //   render(value, record) {
    //     return JSON.stringify(value)
    //   }
    // },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'callback_url',
      dataIndex: 'type',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render(value, record) {
        return <Button onClick={() => { setEditId(value) }}> Edit</Button>
      }
    }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  //@ts-ignore
  return { columns, dataSource, pagination, onChange: tableOnChange }
}