import { useAppContext } from '@/components/AppContext'
import { LoginState } from '@/entities/UserProfile'
import DefaultLayout from '@/ui/templates/DefaultLayout'
import { isLoginEnabled } from '@/utils'

interface WithLayoutProps { }

const withDefaultLayout = <P extends object>(
  Component: React.ComponentType<P>,
) => {

  const WithDefaultLayout: React.FC<P & WithLayoutProps> = props => {

    const { userProfile, monetisationRoles, userRoles } = useAppContext()
    const loginEnabled = isLoginEnabled()

    return (
      <DefaultLayout
        userRole={monetisationRoles?.user_type || ''}
        roles={userRoles}
        loggedIn={
          userProfile.loginStatus === LoginState.LOGGED_IN || !loginEnabled
        }
      >
        <Component {...(props as P)} />
      </DefaultLayout>
    )
  }
  return WithDefaultLayout
}

export default withDefaultLayout
