import { prettifyCamelCase } from "@/utils/helper";
import { Form, Checkbox } from "antd"

interface FormItemCheckboxProps {
    name: string,
}
export const FormItemCheckbox = (props: FormItemCheckboxProps) => {
    const { name } = props;
    return <Form.Item name={name} valuePropName="checked" >
        <Checkbox>{prettifyCamelCase(name)}</Checkbox>
    </Form.Item>
}