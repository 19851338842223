import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Table as AntdTable, Input, Tag, Modal, Button, notification, Menu, Space, Form } from "antd";
import { useAppContext } from "@/components/AppContext";
import { SmileOutlined, CaretDownOutlined, ExclamationCircleFilled, WarningOutlined } from '@ant-design/icons';
import '../addTranslations/addTranslations.css'
import Spin from "antd/es/spin";
import { Languages } from "@a4b/api/src/modules/Bitbucket/types";

type NotificationType = "success" | "info" | "warning" | "error";

const BEARER_TOKEN: string = process.env.REACT_APP_BITBUCKET_BEARER_TOKEN || ""
const GOOGLE_OAUTH_TOKEN: string = process.env.REACT_APP_GOOGLE_CLOUD_API || ""

const { TextArea } = Input;

const languageJson: { [key: string]: string } = {
    gu: "Gujarati",
    hi: "Hindi",
    ur: "Haryanvi",
    zh: "Bhojpuri",
    zu: "Rajasthani",
    en: "English",
    kn: "Kannada",
    ml: "Malayalam",
    mr: "Marathi",
    ta: "Tamil",
    te: "Telugu",
    bn: "Bengali",
};

const AddTranslations = () => {
    const { networkInstance, userProfile } = useAppContext();

    const [languageApi, setLanguageApi] = useState<Languages.LanguageResponse[]>([]);
    const [tableApi, setTableApi] = useState<Array<any>>([]);
    const [checkTableApi, setCheckTableApi] = useState<Array<any>>([]);
    const [isLoadingJson, setIsLoadingJson] = useState<boolean>(false);
    const [updateBtn, setUpdateBtn] = useState<boolean>(false);
    const [langJson, setLangJson] = useState<string>("");
    const [conflict, setConflict] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [translated, setTranslated] = useState("");
    const [count, setCount] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [text, setText] = useState<string>("")
    const [language, setLanguage] = useState("en");
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [columns, setColumns] = useState<any>([
        {
            title: "Key",
            dataIndex: "key",
            key: "key",
        },
    ]);
    const [api, contextHolder] = notification.useNotification();
    const { confirm } = Modal;

    const navigate = useNavigate();
    const search: string = useLocation().search;
    const serviceName: string = new URLSearchParams(search).get("name") || "";
    const serviceHash: string = new URLSearchParams(search).get("hash") || "";

    useEffect(() => {
        if (!languageApi?.length) {
            fetchLanguages();
            fetchHindiJson();
        }
    }, []);

    // Get languages for particular service
    const fetchLanguages = async () => {
        try {
            const resData = await networkInstance.clientWithHeaders.bitbucketApi.getAllLanguages(BEARER_TOKEN, serviceName, serviceHash);
            const menuData: any = resData?.data?.values?.map((item: Languages.Value) => ({
                item,
                label:
                    item?.path?.split("/")?.[1].split(".")?.[1] in languageJson
                        ? languageJson[(item.path.split("/")[1].split(".")[1])]
                        : item.path.split("/")[1].split(".")[1],
                key: item.path,
            }));
            setLanguageApi(menuData);
        } catch (error: any) {
            const openNotificationWithIcon = (type: NotificationType) => {
                api[type]({
                    message: "Error",
                    description: error.message,
                });
            };
            openNotificationWithIcon("error");
        }
    };

    // Get Hindi Json
    const fetchHindiJson = async () => {
        setIsLoadingJson(true);
        try {
            const resDataTable = await networkInstance.clientWithHeaders.bitbucketApi.getJsonData(
                BEARER_TOKEN,
                serviceName,
                serviceHash,
                `${serviceName}.hi.json`
            );
            setCheckTableApi(resDataTable?.data);
            let tableData = Object.entries(resDataTable?.data).map(([key, value]) => {
                return { key, value };
            });
            let newColumns = columns;
            newColumns.push({
                title: "Hindi",
                dataIndex: "value",
                key: "value",
                render: (value: string, record: any, index: number) => {
                    return (
                        <>
                            <TextArea
                                className="hindi_column"
                                autoSize={{ minRows: 1, maxRows: 8 }}
                                defaultValue={value}
                                disabled
                                id="textbox"
                                name={record.error}
                                title={value}
                            />
                        </>
                    );
                },
            });
            setTableApi(tableData);
            setIsLoadingJson(false);
            setColumns(newColumns);
        } catch (error: any) {
            const openNotificationWithIcon = (type: NotificationType) => {
                api[type]({
                    message: "Error",
                    description: error.message,
                });
            };
            openNotificationWithIcon("error");
        }
    };

    const checkParagraphsForMatchingCurlyBraces = (
        paragraph1: string,
        paragraph2: string,
    ) => {
        if (!paragraph2?.length || (!paragraph1?.length && paragraph2.length) || (!paragraph1?.length && !paragraph2?.length)) {
            return true
        } else {
            const regex = /{{(.*?)}}/g;

            const matches1: string[] = [];
            let match1;
            while ((match1 = regex.exec(paragraph1)) !== null) {
                matches1.push(match1[1]);
            }

            const matches2: string[] = [];
            let match2;
            while ((match2 = regex.exec(paragraph2)) !== null) {
                matches2.push(match2[1]);
            }
            const result = matches1.every((value: string) => matches2.includes(value)) &&
                matches2.every((value: string) => matches1.includes(value)) &&
                matches1.length === matches2.length;

            return result ? true : false
        }
    }

    //Notification for merge conflict cases
    const showPageNumbers = (title: string, conflictArray: []) => {
        const args = {
            message: title,
            description:
                `Page Numbers: ${conflictArray}`,
            duration: 0,
            icon: <WarningOutlined style={{ color: "#ff4d4f" }} />,
        };
        notification.open(args);
    };

    const openNotificationClipboard = () => {
        const args = {
            message: "Success",
            description:
                'Text copied to clipboard',
            icon: <SmileOutlined style={{ color: "#1677ff" }} />
        };
        notification.open(args);
    };

    const showConflict = () => {
        confirm({
            title: `Merge conflict in ${langJson}  someone altered this information. Therefore, you must first pull their changes and update it.`,
            icon: <ExclamationCircleFilled />,
            content: "",
            style: { width: "80%", height: "30vh", color: "green" },
            onOk() { },
        });
    };

    const handleMenuClick = async ({ key }: any) => {
        const selectedItem: any = languageApi.find((item: any) => item.label === key);
        setName((selectedItem.key).split(".")[1]);
        const JsonFileName: string = (selectedItem.key).split("/")[1];
        const JsonKey: string = (selectedItem.key).split(".")[1];

        // Onclick to store current json - initial state
        try {
            const resData = await networkInstance.clientWithHeaders.bitbucketApi.getJsonData(BEARER_TOKEN, serviceName, serviceHash, JsonFileName);
            setCheckTableApi(resData?.data);
        } catch (error: any) {
            const openNotificationWithIcon = (type: NotificationType) => {
                api[type]({
                    message: "Error",
                    description: error.message,
                });
            };
            openNotificationWithIcon("error");
        }
        setLangJson(JsonFileName);

        try {
            const resData = await networkInstance.clientWithHeaders.bitbucketApi.getJsonData(BEARER_TOKEN, serviceName, serviceHash, JsonFileName);
            let tableData = [...tableApi];
            Object.entries(resData?.data).map(([key, value]) => {
                let index = tableData.findIndex((data) => key === data.key);
                tableData[index]["newValue"] = value;
            });

            let newColumns = [...columns];
            (!count) ? setCount(true) : newColumns.pop()

            setColumns([...newColumns]);
            newColumns.push({
                title: languageJson[JsonKey],
                dataIndex: "newValue",
                key: "newValue",
                render: (value: string, record: any, index: number) => {
                    return (
                        <>
                            <TextArea
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                defaultValue={value}
                                id="textbox"
                                name={record.error}
                                onChange={(e) => {
                                    if (tableApi === checkTableApi) {
                                        setUpdateBtn(false);
                                    } else {
                                        let modifiedIndex = ((page - 1) * 10) + index
                                        tableData[modifiedIndex]["newValue"] = e.target.value;
                                        setTableApi(tableData);
                                        setUpdateBtn(true);
                                    }
                                }}
                                title={value}
                            />
                        </>
                    );
                },
            });
            setTableApi(tableData);
            setIsLoadingJson(false);
            setColumns([...newColumns]);
        } catch (error: any) {
            const openNotificationWithIcon = (type: NotificationType) => {
                api[type]({
                    message: "Error",
                    description: error.message,
                });
            };
            openNotificationWithIcon("error");
        }

    }

    // Upload - OK button
    const handleClick: any = async () => {
        let data = [...tableApi];

        let newJSON: any = {};
        tableApi.forEach((data) => {
            newJSON[data.key] = data.newValue;
        });

        let alert: boolean = false;
        const curlyBraceArray: any = []
        let r_index: number = 0

        data.forEach(function (arrayItem) {
            if (
                !checkParagraphsForMatchingCurlyBraces(
                    arrayItem.value,
                    newJSON[arrayItem.key]
                )
            ) {
                arrayItem.error = "error";
                alert = true;
                r_index++;
                let curlyBraceIndex: any = (r_index % 10 == 0) ? (Math.floor(r_index / 10)) : (Math.floor((r_index / 10)) + 1);
                curlyBraceArray.push(curlyBraceIndex);
            } else {
                r_index++;
                arrayItem.error = "";
            }
        });

        alert ? (showPageNumbers("Values inside the curly braces are not editable!", curlyBraceArray)) : <></>

        if (!conflict) {
            let newColumns = [...columns];
            let tableData = [...tableApi];
            newColumns.pop();
            setColumns([...newColumns]);

            newColumns.push({
                title: languageJson[name],
                dataIndex: "newValue",
                key: "newValue",
                render: (value: string, record: any, index: number) => {
                    return (
                        <>
                            {record.error ? <>
                                <Form.Item label="Fail" validateStatus="error" hasFeedback help="Check values inside curly braces">
                                    <TextArea
                                        autoSize={{ minRows: 1, maxRows: 6 }}
                                        defaultValue={value}
                                        id="textbox"
                                        status={record.error}
                                        bordered={true}
                                        onChange={(e) => {
                                            if (tableApi === checkTableApi) {
                                                setUpdateBtn(false);
                                            } else {
                                                let modifiedIndex = ((page - 1) * 10) + index
                                                tableData[modifiedIndex]["newValue"] = e.target.value;
                                                setTableApi(tableData);
                                                setUpdateBtn(true);
                                            }
                                        }}
                                        title={value}
                                    />
                                </Form.Item>
                            </> :
                                <>
                                    <Form.Item>
                                        <TextArea
                                            autoSize={{ minRows: 1, maxRows: 6 }}
                                            defaultValue={value}
                                            id="textbox"
                                            status={record.error}
                                            bordered={true}
                                            onChange={(e) => {
                                                if (tableApi === checkTableApi) {
                                                    setUpdateBtn(false);
                                                } else {
                                                    let modifiedIndex = ((page - 1) * 10) + index
                                                    tableData[modifiedIndex]["newValue"] = e.target.value;
                                                    setTableApi(tableData);
                                                    setUpdateBtn(true);
                                                }
                                            }}
                                            title={value}
                                        />
                                    </Form.Item>
                                </>
                            }
                        </>
                    );
                },
            });
            setColumns([...newColumns]);
            setTableApi([...data]);
        }

        const pathSrc: any = `/${serviceName}/${langJson}`

        const formData: any = new FormData();

        let checkTableData: any = Object.entries(checkTableApi).map(
            ([key, value]) => {
                return { key, value };
            }
        );
        const newTable = [...data]
        const change: { [key: string]: string } = {};
        for (let i = 0; i < checkTableData.length; i++) {
            const initialJson = checkTableData[i];
            const updatedJson = newTable[i];
            if (initialJson["value"] !== updatedJson["newValue"]) {
                change[initialJson["key"]] = updatedJson["newValue"];
            }
        }

        formData.append("message", userProfile?.name + JSON.stringify(change));

        formData.append(pathSrc, JSON.stringify(newJSON, null, 2));

        try {
            const latestCommitRes: any = await networkInstance.clientWithHeaders.bitbucketApi.getCommits(BEARER_TOKEN);
            const resDataCheck = await networkInstance.clientWithHeaders.bitbucketApi.getJsonData(BEARER_TOKEN, serviceName, latestCommitRes?.data?.values[0]?.hash, langJson);
            if (!alert) {
                setUpdateBtn(true);
                if (
                    JSON.stringify(resDataCheck?.data) === JSON.stringify(checkTableApi) ||
                    conflict
                ) {
                    setIsLoadingJson(true);
                    await networkInstance.clientWithHeaders.bitbucketApi.postJsonData(BEARER_TOKEN, formData);
                    setIsLoadingJson(false);
                    navigate("/translations/services");
                } else {
                    setIsLoadingJson(false);
                    setConflict(true);
                    const latestCommitResFinal: any = await networkInstance.clientWithHeaders.bitbucketApi.getCommits(BEARER_TOKEN);
                    const latestCommitData = await networkInstance.clientWithHeaders.bitbucketApi.getJsonData(
                        BEARER_TOKEN,
                        serviceName,
                        latestCommitResFinal?.data?.values[0]?.hash,
                        langJson
                    );
                    let tableData = [...tableApi];

                    showConflict();

                    const conflictPages: any = []
                    let rowIndex: any = 0

                    Object.entries(latestCommitData.data).map(([key, value]) => {
                        let index = tableData.findIndex((data) => key === data.key);
                        if (tableData[index]["newValue"] !== value) {
                            rowIndex++;
                            let conflictIndex: number = (rowIndex % 10 == 0) ? (Math.floor(rowIndex / 10)) : (Math.floor((rowIndex / 10)) + 1);
                            conflictPages.push(conflictIndex);
                            tableData[index]["newValue2"] = value;
                        } else {
                            rowIndex++;
                            tableData[index]["newValue2"] = "";
                        }
                    });
                    let setConflictPages: any = Array.from(new Set(conflictPages))
                    showPageNumbers("Merge Conflict pages", setConflictPages)

                    let newColumns = [...columns];
                    newColumns.push({
                        title: "Latest commit",
                        key: "newValue2",
                        dataIndex: "newValue2",
                        render: (tag: any) => {
                            return (
                                tag && (
                                    <Tag
                                        className="conflictTag"
                                        color={"green"}
                                        key={tag}
                                        title="Click to copy to clipboard"
                                        onClick={() => {
                                            navigator.clipboard.writeText(tag);
                                            openNotificationClipboard();
                                        }}
                                    >
                                        {tag}
                                    </Tag>
                                )
                            );
                        },
                    });
                    setColumns(newColumns);
                    setTableApi([...tableData]);
                }
            } else {
                setUpdateBtn(false);
            }
        } catch (error: any) {
            const openNotificationWithIcon = (type: NotificationType) => {
                api[type]({
                    message: "Error",
                    description: error.message,
                });
            };
            openNotificationWithIcon("error");
        }
    };

    const showConfirm = () => {
        confirm({
            title: 'Do you want to update these changes?',
            content: 'Updated changes will be uploaded in bitbucket',
            onOk() {
                handleClick();
            },
            onCancel() {
            },
        });
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        const translatedText = await networkInstance.clientWithHeaders.bitbucketApi.googleTranslate(GOOGLE_OAUTH_TOKEN, text, language);
        setTranslated(translatedText?.data?.data?.translations[0]?.translatedText)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const menu = (
        <Menu onClick={handleMenuClick}>
            {languageApi?.map((item: any) => (
                <Menu.Item key={item.label}>{item.label}</Menu.Item>
            ))}
        </Menu>
    );

    const onPageClick = (info: any) => {
        let newColumns = [...columns]
        let tableData = [...tableApi]
        if (!conflict) {
            if (count) {
                newColumns.pop()
                newColumns.push({
                    title: languageJson[name],
                    dataIndex: "newValue",
                    key: "newValue",
                    render: (value: string, record: any, index: number) => {
                        return (
                            <>
                                {record.error ? <Form.Item label="Fail" validateStatus="error" hasFeedback help="Check values inside curly braces">
                                    <TextArea
                                        autoSize={{ minRows: 1, maxRows: 6 }}
                                        defaultValue={value}
                                        id="textbox"
                                        name={record.error}
                                        status={record.error}
                                        bordered={true}
                                        onChange={(e) => {
                                            if (tableApi === checkTableApi) {
                                                setUpdateBtn(false);
                                            } else {
                                                let modifiedIndex = ((info.current - 1) * info.pageSize) + index
                                                tableData[modifiedIndex]["newValue"] = e.target.value;
                                                setTableApi(tableData);
                                                setUpdateBtn(true);
                                            }
                                        }}
                                        title={value}
                                    />
                                </Form.Item> :
                                    <>
                                        <Form.Item>
                                            <TextArea
                                                autoSize={{ minRows: 1, maxRows: 6 }}
                                                defaultValue={value}
                                                id="textbox"
                                                name={record.error}
                                                status={record.error}
                                                bordered={true}
                                                onChange={(e) => {
                                                    if (tableApi === checkTableApi) {
                                                        setUpdateBtn(false);
                                                    } else {
                                                        let modifiedIndex = ((info.current - 1) * info.pageSize) + index
                                                        tableData[modifiedIndex]["newValue"] = e.target.value;
                                                        setTableApi(tableData);
                                                        setUpdateBtn(true);
                                                    }
                                                }}
                                                title={value}
                                            />
                                        </Form.Item>
                                    </>
                                }


                            </>
                        );
                    },
                });
            }
        } else {
            let newColumns = [...columns]
            newColumns.pop()
            newColumns.push({
                title: "Latest commit",
                key: "newValue2",
                dataIndex: "newValue2",
                render: (tag: any) => {
                    return (
                        tag && (
                            <Tag
                                className="conflictTag"
                                color={"green"}
                                key={tag}
                                title="Click to copy to clipboard"
                                onClick={() => {
                                    navigator.clipboard.writeText(tag);
                                    openNotificationClipboard();
                                }}
                            >
                                {tag}
                            </Tag>
                        )
                    );
                },
            });
            setColumns(newColumns);
            setTableApi([...tableData]);
        }
        setPageSize(info.pageSize);
        setPage(info.current);
        setColumns([...newColumns]);
    }

    if (isLoadingJson) {
        return (
            <div className="loading">
                <Space size="middle">
                    <Spin />
                </Space>
            </div>
        );
    }

    return (
        < div className="body-table" >
            {contextHolder}
            <div className="dropdown-head">
                {conflict ? (
                    <>
                        <Dropdown overlay={menu} disabled>
                            <a onClick={e => e.preventDefault()}>
                                {name ? (
                                    <>
                                        <Space>
                                            <Button className="languagesdropdown">
                                                {languageJson[name]} <CaretDownOutlined />
                                            </Button>
                                        </Space>
                                    </>
                                ) : (
                                    <>
                                        <Space>
                                            <Button className="languagesdropdown">
                                                Languages <CaretDownOutlined />
                                            </Button>
                                        </Space>
                                    </>
                                )}
                            </a>
                        </Dropdown>

                    </>
                ) : (
                    <>
                        <Dropdown overlay={menu}>
                            <a onClick={e => e.preventDefault()}>
                                {name ? (
                                    <>
                                        <Space>
                                            <Button className="languagesdropdown">
                                                {languageJson[name]} <CaretDownOutlined />
                                            </Button>
                                        </Space>
                                    </>
                                ) : (
                                    <>
                                        <Space>
                                            <Button className="languagesdropdown">
                                                Languages <CaretDownOutlined />
                                            </Button>
                                        </Space>
                                    </>
                                )}
                            </a>
                        </Dropdown>

                    </>)}

                {updateBtn ? (
                    <button className="pushChangeBtn" onClick={showConfirm}>
                        Update
                    </button>
                ) : (
                    <button className="pushChangeBtnDisabled" disabled>
                        Update
                    </button>
                )}

                <button className="pushChangeBtn" onClick={showModal}>
                    Translate
                </button>
                <Modal
                    className="googleTranslateModal"
                    title="Google Translate"
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel} style={{ color: "white", backgroundColor: "#1890ff" }}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" onClick={handleOk}>
                            Translate
                        </Button>,
                    ]}
                >
                    <div className="translationModalBody">
                        <input
                            type="text"
                            value={text}
                            id="textBoxTranslate"
                            placeholder="Enter you text"
                            onChange={(e) => setText(e.target.value)}
                        />
                        <select
                            className="translateOptions"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                        >
                            <option value="en">English</option>
                            <option value="hi">Hindi</option>
                            <option value="gu">Gujarati</option>
                            <option value="mr">Marathi</option>
                            <option value="kn">Kannada</option>
                            <option value="te">Telugu</option>
                            <option value="bn">Bengali</option>
                            <option value="bho">Bhojpuri</option>
                            <option value="ml">Malayalam</option>
                            <option value="ta">Tamil</option>
                        </select>
                        {translated && (
                            <div
                                className="translationText"
                                title="Click to copy to clipboard"
                                onClick={() => {
                                    navigator.clipboard.writeText(translated);
                                    openNotificationClipboard();
                                }}
                            >
                                {translated}
                            </div>
                        )}
                    </div>
                </Modal>

            </div>
            <div className="tableContainer">
                <div className="tableContainerOuterDiv">
                    <AntdTable columns={[...columns]} dataSource={tableApi} onChange={onPageClick} scroll={{ y: "100%", scrollToFirstRowOnChange: true, x: "100%" }} pagination={{ defaultPageSize: 10, showSizeChanger: true }} />
                </div>
            </div>
        </div >
    );
}


export default withDefaultLayout(AddTranslations);