import { useEffect, useState, useCallback } from 'react'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { message } from 'antd'
import type { TablePaginationConfig } from 'antd/es/table'
import ManageCallListPage from '@a4b/ui/src/modules/monetisation/organisms/ManageCallListPage'
import { CallDetails } from '@a4b/api/src/modules/Monetisation/Astrologer/types'
import { A4B_APPS } from '@/utils/constants'
import MonetisationAstroApi from '@a4b/api/src/modules/Monetisation/Astrologer'

interface ManageCallsProps {
}

const ManageCalls: React.FC<ManageCallsProps> = () => {
    const { networkInstance, app } = useAppContext()
    const { monetisationAstroApi, monetisationNewAstroApi } = networkInstance.clientWithHeaders
    const api: MonetisationAstroApi = (app === A4B_APPS.SRIMANDIR) ? monetisationAstroApi : monetisationNewAstroApi
    const [callList, setCallList] = useState<CallDetails[]>()
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: false,
    })

    const getCallList = useCallback(async () => {
        try {
            const callListResponse = await api.agentApi.getCallList({
                limit: pagination.pageSize!,
                offset:
                    Number((pagination?.current || 1) - 1) * Number(pagination.pageSize)
            }, 'completed')
            if (!callListResponse?.data) {
                message.info('No data found', 2)
                setCallList([])
                setPagination({ ...pagination, total: 0 })
                return
            }
            setCallList(callListResponse?.data?.data?.calls)
            setPagination({ ...pagination, total: callListResponse?.data?.data?.total_count || 10 })
        } catch (error) {
            message.error('Error while fetching Call list', 3)
        }
    }, [api.agentApi, pagination?.current])

    useEffect(() => {
        getCallList()
    }, [getCallList])

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPagination({
            ...pagination,
        })
    }

    return (
        <div style={{ padding: '55px 40px' }}>
            <ManageCallListPage
                callList={callList!}
                handleTableChange={handleTableChange}
                pagination={pagination}
            />
        </div>
    )
}

export default withDefaultLayout(ManageCalls)
