import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppContext } from '../../../../../components/AppContext';
import { Definition, Ticket } from '@a4b/api/src/modules/Events/types';
import { Button, Radio, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { renderBoolean, renderDateTime as renderDateTable } from '../../../../../components/TableHelpers/ColumnHelper';
import { renderDate } from '../../../../../components/HelperComponents';
import styled from 'styled-components';
import { openErrorNotification, openNotification } from '../../../../../utils';
import UserProfile from '../../../../../entities/UserProfile';

const StyledPre = styled.pre`
    background: #434343;
    color: #91d5ff;
    font-size: 10;
    padding: 10px;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    min-height: 90px;
`

const style = `
    .error-table .ant-table table {
        font-size: 11px;
    }
`

enum TICKETS_FILTERS {
    TICKETS = "TICKETS",
    UNRESOLVED = 'UNRESOLVED',
    ARCHIVED = 'ARCHIVED'
}

function renderError(value: any, parentRecord: Ticket, index: number, userProfile: UserProfile, resolveTicket: (parentTicketId: string, ticketId: string) => Promise<void>) {
    if (value.length === 0) { return null }
    return <div>
        <Table className='error-table' dataSource={value} columns={[{
            title: 'key',
            dataIndex: 'key'
        },
        {
            title: 'Expected',
            dataIndex: 'expected',
            render(value, record, index) {
                const valueCopy = { ...value };
                delete valueCopy['event_identification']
                return <StyledPre>
                    {JSON.stringify(valueCopy, undefined, 2)}
                </StyledPre>;
            },
        },
        {
            title: 'Actual',
            dataIndex: 'actual',
            render(value, record, index) {
                if (!value) {
                    return
                }
                const valueCopy = { ...value };
                delete valueCopy['event_identification']
                return <StyledPre>
                    {JSON.stringify(valueCopy, undefined, 2)}
                </StyledPre>;
            },
        },
        {
            title: 'Resolved',
            dataIndex: 'resolved_by',
            width: '400px',
            render(value, record, index) {
                return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    <>
                        {renderBoolean(record.is_resolved, {}, index)}
                        <Button
                            type="default"
                            size='small'
                            style={{ marginTop: '10px' }}
                            onClick={() => {
                                resolveTicket(parentRecord.id, record.id);
                            }}
                        >
                            Mark Resolved
                        </Button>
                    </>
                    <span>{value ? <span>Resolved by <b>{value}</b> at <b>{renderDateTable(record.resolved_at, {}, index)}</b></span> : ''}</span>
                </div>
            },
        },
        {
            title: 'Observed time',
            dataIndex: 'observed_time',
            render: renderDateTable,
            width: '200px'
        },
        {
            title: 'Event identification',
            dataIndex: 'actual',
            width: '150px',
            render(value, record, index) {
                if (!value) {
                    return
                }
                return <div>
                    <p> User id: <b>{value.event_identification.user_id}</b></p>
                    <p> App versions : <b>{value.event_identification.app_versions.join()}</b></p>
                    <p> Time : <b>{renderDate(value.event_identification.timestamp)}</b></p>
                </div>;
            },
        }
        ]} size='small' pagination={false} bordered ></Table>
    </div>
}

interface CheckTicketsProps {
    definition_id: string
    defination: Definition | undefined
}

const CheckTickets = function (props: CheckTicketsProps) {
    const { definition_id, defination } = props;
    const { networkInstance, userProfile } = useAppContext()

    const [tickets, setTickets] = useState<Ticket[]>([])
    const [ticketFilter, setFilter] = useState(TICKETS_FILTERS.TICKETS)

    const getTickets = useCallback(async () => {
        try {
            const res = await networkInstance.clientWithHeaders.events.getTickets({
                filters: {
                    definition_id
                }
            })
            res.data.data.tickets.sort((t1: Ticket, t2: Ticket) => t2.definition_version - t1.definition_version)
            setTickets(res.data.data.tickets);
        } catch (error) {

        }
    }, [definition_id, networkInstance.clientWithHeaders.events])

    useEffect(() => {
        getTickets();
    }, [getTickets])

    const latestDefinitionVersion = defination?.version;

    const resolveTicket = useCallback(async (parentTicketId: string, ticketId: string) => {
        try {
            const parentRecordCopy: Ticket = { ...tickets.find((t) => t.id === parentTicketId) } as Ticket;
            parentRecordCopy.super_property_errors = parentRecordCopy.super_property_errors?.map((superPropertyError) => {
                if (superPropertyError.id === ticketId) {
                    superPropertyError.is_resolved = true
                    superPropertyError.resolved_by = userProfile?.email || "NA"
                }
                return superPropertyError;
            }) || null
            parentRecordCopy.property_errors = parentRecordCopy.property_errors?.map((propertyError) => {
                if (propertyError.id === ticketId) {
                    propertyError.is_resolved = true
                    propertyError.resolved_by = userProfile?.email || "NA"
                }
                return propertyError;
            }) || null
            await networkInstance.clientWithHeaders.events.resolveTickets(parentRecordCopy)
            openNotification("success", { message: "Ticket resolved sucessfully!" })
            getTickets();
        } catch (error) {
            openErrorNotification(error)
        }
    }, [getTickets, networkInstance.clientWithHeaders.events, tickets, userProfile?.email])

    const columns: ColumnType<Ticket>[] = useMemo(() => {
        return [{
            title: 'Definition name',
            dataIndex: 'definition_name',
            align: 'center',
            render: (value, record, index) => {
                return <div>
                    <p> {value}</p>
                    <p> <span >Version: </span> <b>{record.definition_version}</b></p>
                </div>
            }
        },
        {
            title: 'Property Errors',
            dataIndex: 'property_errors',
            render: (value, record, index) => {
                return renderError(value || [], record, index, userProfile, resolveTicket)
            },
            align: 'center',
            width: '800px',
        },
        {
            title: 'Super Property Errors',
            dataIndex: 'super_property_errors',
            render: (value, record, index) => {
                return renderError(value || [], record, index, userProfile, resolveTicket)
            },
            align: 'center',
            width: '800px',
        },
        {
            title: 'Last verified ',
            dataIndex: 'last_verified_timestamp',
            render: renderDateTable,
            align: 'center'

        },
        {
            title: 'Archived',
            dataIndex: 'is_archived',
            render: renderBoolean,
            align: 'center'
        },
        ]
    }, [resolveTicket, userProfile])

    let ticketsToDisplay = tickets;
    if (TICKETS_FILTERS.TICKETS === ticketFilter) {
        ticketsToDisplay = ticketsToDisplay.filter((ticket) => (ticket.definition_version === latestDefinitionVersion))
    } else if (TICKETS_FILTERS.UNRESOLVED === ticketFilter) {
        ticketsToDisplay = tickets?.map((ticket) => {
            return {
                ...ticket,
                property_errors: ticket.property_errors?.filter((property_error) => !property_error.is_resolved),
                super_property_errors: ticket.super_property_errors?.filter((super_property_error) => !super_property_error.is_resolved)
            }
        })
        ticketsToDisplay = ticketsToDisplay?.filter((ticket) => !(ticket.property_errors.length === 0 && ticket.super_property_errors.length === 0))
        ticketsToDisplay = ticketsToDisplay.filter((ticket) => (ticket.definition_version === latestDefinitionVersion))
    } else {
        ticketsToDisplay = ticketsToDisplay.filter((ticket) => (ticket.definition_version !== latestDefinitionVersion))
    }

    return <div>
        <style>
            {style}
        </style>
        <Radio.Group onChange={(e) => { setFilter(e.target.value) }} value={ticketFilter} style={{ marginBottom: '10px' }}>
            <Radio.Button value={TICKETS_FILTERS.TICKETS}> Tickets </Radio.Button>
            <Radio.Button value={TICKETS_FILTERS.UNRESOLVED}> Unresolved</Radio.Button>
            <Radio.Button value={TICKETS_FILTERS.ARCHIVED}> Archived </Radio.Button>
        </Radio.Group>
        {
            tickets !== null ? <div>
                <Table dataSource={ticketsToDisplay} columns={columns} bordered pagination={false} scroll={{ x: '2000px' }}></Table>
            </div> : <div>
                <h3> No tickets found </h3>
            </div>
        }
    </div>;
}

export default CheckTickets;