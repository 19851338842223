import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import React, { ReactNode, useMemo } from "react";
import AntdFormWrapper from "../../../../../components/Forms/AntdFormWrapper";
import CreateDefinations from "../components/CreateDefinations";
import { useAppContext } from "../../../../../components/AppContext";
import { Definition, EventPayload, GroupPayload } from "@a4b/api/src/modules/Events/types";
import EventPreview from "../components/previews";
import CreateGroup from "../components/CreateGroup";
import ChangeColumnSetting from "../components/ChangeColumnSetting";
import CheckTickets from "../components/CheckTickets";

export enum DRAWER_TYPE {
    CREATE_DEFINATIONS = 'Create Definition',
    CLONE_DEFINATIONS = 'Create Definition (Clone)',
    EDIT_DEFINATIONS = 'Edit Definitions',
    PREVIEW_DEFINATION = 'Preview event',
    CREATE_GROUP = 'Create Group',
    CHANGE_COLUMN_SETTING = 'Change column setting',
    CHECK_TICKETS = 'Tickets'
}

export default function useDrawerConfig(
    setActiveDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | undefined>>,
    tableColumn: {
        [key: string]: boolean;
    },
    setTableColumns: React.Dispatch<React.SetStateAction<{
        [key: string]: boolean;
    }>>,
    getEvents: () => Promise<void>,
    updateNavigationId: () => void,
    eventDefination?: Definition, activeDrawer?: DRAWER_TYPE,
    setIsFormTouched?: React.Dispatch<React.SetStateAction<boolean>>
): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;

} | { drawerProps: undefined, drawerContent: undefined } {

    const { networkInstance } = useAppContext();
    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_DEFINATIONS,
                width: '70vw',
            },
            drawerContent: <AntdFormWrapper<EventPayload, EventPayload>
                extractFilePaths={[]}
                FormFields={CreateDefinations}
                createApi={networkInstance.clientWithHeaders.events.createEvent}
                submitSuccessfulCallback={() => { getEvents(); setActiveDrawer(undefined) }}
                syncIsFormTouched={setIsFormTouched}
            />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CLONE_DEFINATIONS,
                width: '70vw',
            },
            drawerContent: <AntdFormWrapper<EventPayload, EventPayload>
                extractFilePaths={[]}
                FormFields={CreateDefinations}
                createApi={networkInstance.clientWithHeaders.events.createEvent}
                submitSuccessfulCallback={() => { updateNavigationId(); getEvents(); setActiveDrawer(undefined) }}
                syncIsFormTouched={setIsFormTouched}
                initValues={eventDefination}
            />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.EDIT_DEFINATIONS,
                width: '70vw',
            },
            drawerContent: eventDefination && <AntdFormWrapper<EventPayload, EventPayload>
                formData={eventDefination}
                FormFields={CreateDefinations}
                updateApi={networkInstance.clientWithHeaders.events.updateEvent}
                submitSuccessfulCallback={() => { getEvents(); setActiveDrawer(DRAWER_TYPE.PREVIEW_DEFINATION) }}
                syncIsFormTouched={setIsFormTouched}
            />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.PREVIEW_DEFINATION,
                width: '90vw',
            },
            drawerContent: eventDefination && <EventPreview defination={eventDefination} setActiveDrawer={setActiveDrawer}></EventPreview>
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_GROUP,
                width: '70vw',
            },
            drawerContent: <AntdFormWrapper<GroupPayload, GroupPayload> FormFields={CreateGroup} createApi={networkInstance.clientWithHeaders.events.createGroup} />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CHANGE_COLUMN_SETTING,
            },
            drawerContent: <ChangeColumnSetting onApplyCallback={() => { setActiveDrawer(undefined) }} setTableColumns={setTableColumns} tableColumn={tableColumn}></ChangeColumnSetting>
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CHECK_TICKETS,
                width: '90vw'
            },
            drawerContent: <CheckTickets defination={eventDefination} definition_id={eventDefination?.id || ''} />
        }
    ], [eventDefination, getEvents, networkInstance.clientWithHeaders.events.createEvent, networkInstance.clientWithHeaders.events.createGroup, networkInstance.clientWithHeaders.events.updateEvent, setActiveDrawer, setIsFormTouched, setTableColumns, tableColumn, updateNavigationId])
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}