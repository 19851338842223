
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination from "@/hooks/useAntdPagination";
import { Action } from "./utils/types";
import { useSearchParams } from "react-router-dom";

const DynamicCohort = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const { networkInstance } = useAppContext();
  const { pagination, setPagination } = useAntdPagination();
  const [actions, setActions] = useState<Action[]>([]);
  const [eventsAndProperties, setEventsAndProperties] = useState<any>([]);
  const [commonEventProperties, setCommanEventProperties] = useState<any>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  useEffect(() => {
    if (id) {
      setActiveDrawer(DRAWER_TYPE.EDIT_COHORT);
    }
  }, [id])

  const setEditId = useCallback((id: string) => {
    setSearchParams(`id=${id}`)
  }, [setSearchParams])
  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }

  const getDynamicCohort = useCallback(async (params: any) => {
    try {
      const res: any = await networkInstance.clientWithHeaders.dynamicCohort.getAllActions();
      setActions(res.data.data.actions as Action[]);
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.dynamicCohort])

  const getEventsAndProperties = useCallback(async () => {
    const { data } = await networkInstance.clientWithHeaders.events.getEventProperties();
    setEventsAndProperties(data.data.events)
    setCommanEventProperties(data.data.common_properties)
  }, [networkInstance]);

  useEffect(() => {
    getDynamicCohort(pagination);
    getEventsAndProperties();
  }, [getDynamicCohort, getEventsAndProperties, pagination]);

  const action = useMemo(() => { return actions.find((action => action.id === id)) }, [actions, id])
  const drawerConfig = useDrawerConfig(eventsAndProperties, commonEventProperties, activeDrawer, action);
  const { drawerProps, drawerContent } = drawerConfig;

  const tableConfig = useTableConfig(actions, pagination, setEditId, setPagination, setDrawer,);


  const onCreateCohort = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_COHORT);
  }

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  }

  return <SocialScreenContainer
    title={"Dynamic Cohort"}
    filtersUI={<Filters />}
    extra={<><Button onClick={onCreateCohort}> Create new action </Button></>}
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />

};

export default withDefaultLayout(DynamicCohort)