import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import TempleListPage from '@a4b/ui/src/modules/monetisation/organisms/TempleListPage'
import { STORE_SERVICE_TYPE, Store, Stores } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import Error from '@/components/Error'

interface Props { }

const getPageBreadcrumbs = () => [
  {
    path: '/monetisation/offerings',
    breadcrumbName: 'Hub List',
  },
  {
    path: '/monetisation/offerings/home/hub',
    breadcrumbName: 'Temple List',
  },
]

interface FiltersType {
  serviceType: STORE_SERVICE_TYPE | undefined
  storeId: string | undefined
}

const TempleListScreen: React.FC<Props> = () => {
  const { networkInstance, monetisationRoles } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders
  let { hubId } = useParams()

  const [stores, setStores] = React.useState<Stores>()
  const [storesPaginated, setStoresPaginated] = React.useState<Store[]>([])
  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [errorPage, setErrorPage] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  let [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState<FiltersType>({
    serviceType: 'offerings',
    storeId: ''
  })

  const getHubManagerList = useCallback(async () => {
    try {
      setIsLoading(true)
      const storeListByHubId = await monetisationOfferingsApi.hub.getStoresByHubId(
        hubId || '', filters?.serviceType!, filters?.storeId!
      )
      setStores(storeListByHubId?.data?.data)
      setStoresPaginated(storeListByHubId?.data?.data.stores.slice(0, pageSize))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setErrorPage(true)
    }
  }, [hubId, monetisationOfferingsApi.hub, pageSize, filters?.storeId, filters?.serviceType])

  useEffect(() => {
    getHubManagerList()
  }, [getHubManagerList])

  const paginationHandler = (page: number, pageSize: number) => {
    let derivedPage = page === 0 ? 0 : page - 1
    setCurrentPage(page)
    let store =
      stores?.stores?.slice(
        derivedPage * pageSize,
        (derivedPage + 1) * pageSize,
      ) || []
    setStoresPaginated(store)
  }

  const updateFilters = (type: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value
    }))
  }


  return (
    <div style={{ padding: '0 40px' }}>
      {errorPage ?
        <Error text='Error While fetching Temple List!' /> :
        <>
          <AdminBreadcrumb routes={getPageBreadcrumbs()} />
          <TempleListPage
            hubId={hubId || ''}
            hubName={searchParams.get('name')}
            data={stores}
            paginationOnChange={paginationHandler}
            currentPage={currentPage}
            totalRecords={stores?.stores.length || 0}
            dataRecords={storesPaginated}
            showAddNewTemple={monetisationRoles?.user_type === 'admin' || monetisationRoles?.user_type === 'super_admin'}
            pageSize={pageSize}
            updateFilters={updateFilters}
            filters={filters}
            isLoading={isLoading}
          />
        </>
      }
    </div>
  )
}

export default withDefaultLayout(TempleListScreen)
