import {
  CreateOrUpdateReview,
  ReviewByAgent,
} from '@a4b/api/src/modules/Monetisation/Astrologer/types'

export const parseReviewResponse = (data: ReviewByAgent[]) => {
  const reviewResponse = {
    data: data?.map((item: any) => ({
      id: item?.id,
      agent_id: item?.agent_id,
      user_image_url: [item?.user_image_url],
      review: item?.review,
      user_name: item?.user_name,
      position: item?.position,
      rating: item?.rating,
      language_code: item?.language_code,
      agent_reply: item?.agent_reply,
    })),
  }
  return reviewResponse
}

export const reviewPayload = (
  data: any,
  languageCode: string,
  agentId: string,
) => {
  const payload: CreateOrUpdateReview = {
    agent_id: agentId,
    reviews: data?.data?.map((item: any) => ({
      ...(item?.id && {
        id: item.id,
      }),
      agent_id: agentId,
      user_image_url: item?.user_image_url?.[0],
      review: item?.review,
      user_name: item?.user_name,
      position: item?.position,
      rating: item?.rating,
      language_code: languageCode,
      agent_reply: item?.agent_reply,
    })),
    language_code: languageCode,
  }
  return payload
}
