import { Form, Input } from "antd";
import { EventGroup } from "@a4b/api/src/modules/Events/types";
import { useSearchParams } from "react-router-dom";
import styled from 'styled-components';

interface FiltersProps {
  groups: EventGroup[],
}

const StyledInput = styled(Input)`
  width: 300px;
  margin-right: 8px;
`

export default function Filters(props: FiltersProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const handlePropertyNameFilter = (e: any) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (e.target.value.trim() === '') {
      updatedSearchParams.delete('property_name')
    } else {
      updatedSearchParams.set('property_name', e.target.value);
    }
    setSearchParams(updatedSearchParams);
  }

  const handlePropertyNameKeydown = function (e: any) {
    if (e.key === 'Enter') {
      handlePropertyNameFilter(e);
    }
  }

  return <StyledInput placeholder="Filter using property name" defaultValue={searchParams.get('property_name') || undefined} onBlur={handlePropertyNameFilter} onKeyDown={handlePropertyNameKeydown}></StyledInput>

} 