import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import {
  GodDataWrapper,
  Store,
  UpdateGodSelectionRequest,
  UserUploadedGodData,
  UserUploadedGodParams,
} from './god.types'
import { apiClient } from '../../apiClient'

type Header = {
  [key: string]: string
}
class MandirApi {
  api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getGodList = async (userId: string, accessToken: string, header?: Header) =>
    this.api.get<GodDataWrapper>(
      `/expresso/mandir/api/gods/god_list.php?userId=${userId}`,
      {
        headers: {
          ...header,
        },
      },
    )

  getStores = async () =>
    this.api.get<Store.RootObject>('/yoda/internal/v1/stores/store-list?limit=100&offset=0')


  getAvatarStores = async (godCode: string, godAvatar: string) =>
    this.api.get<{
      data: { store_ids: string[] };
    }>(`/yoda/internal/v1/stores/icon-specific-positions?god_code=${godCode}&god_avatar_code=${godAvatar}`)


  updateGodSelection = async (
    userData: UpdateGodSelectionRequest,
    accessToken: string,
    header?: Header,
  ) =>
    this.api.post<GodDataWrapper>(
      `/expresso/mandir/api/gods/updateGodOrder.php`,
      userData,
      {
        headers: {
          ...header,
        },
      },
    )

  updateIconStoreList = async (
    payload: Store.UpdateIconStorePayload
  ) =>
    this.api.put<GodDataWrapper>(
      `/yoda/internal/v1/stores/icon-specific-positions`,
      payload
    )

  getUserUploadedGods = (params?: UserUploadedGodParams) =>
    this.api.get<{ data: { gods: UserUploadedGodData[]; count: number } }>(
      `/loki/internal/v1/gods/user-uploaded`,
      { params },
    )

  deleteUserUploadedGod = (id: string, file_name: string, userId: number) =>
    this.api.delete(`/loki/external/v2/user-gods/${id}`, {
      params: {
        file_name,
      },
      headers: {
        'X-AFB-R-UID': userId,
      },
    })
}

export default MandirApi
