import React, { useCallback, useEffect, useState } from 'react'
import { useAppContext } from '@/components/AppContext'
import { useForm } from 'antd/lib/form/Form'
import { message } from 'antd'
import type { TablePaginationConfig } from 'antd/es/table'
import { A4B_APPS, YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import AstrologerSectionList from '@a4b/ui/src/modules/monetisation/organisms/AstrologerSectionList'
import AstrologerSectionCreation from '../../components/CreateSection'
import { Sections, EditSection } from '@a4b/api/src/modules/Monetisation/Astrologer/types'
import { parseSectionData, payloadSectionData } from './helper'
import MonetisationAstroApi from '@a4b/api/src/modules/Monetisation/Astrologer'

interface TabProps {
  currentTab: string
}

const ManageAstroSectionScreen: React.FC<TabProps> = ({ currentTab }) => {
  const { networkInstance, app } = useAppContext()
  const { monetisationAstroApi, monetisationNewAstroApi } = networkInstance.clientWithHeaders
  const api: MonetisationAstroApi = (app === A4B_APPS.SRIMANDIR) ? monetisationAstroApi : monetisationNewAstroApi
  const [createItemForm] = useForm()
  const [sectionList, setSectionList] = useState<Sections[]>()
  const [sectionId, setSectionId] = useState<string>()
  const [showSectionCreation, setShowSectionCreation] = useState<boolean>(false)
  const [isEditJourney, setIsEditJourney] = useState<boolean>(false)
  const [paginationParams, setPaginationParams] =
    useState<TablePaginationConfig>({
      current: 1,
      pageSize: 10,
    })
  const [editSection, setEditSection] = useState<EditSection>()
  const rowSelectionChange = (selectedRowKeys: any) => {
    setRowSelectionConfig({
      ...rowSelectionConfig,
      selectedRowKeys: [...selectedRowKeys],
    })
  }

  const [rowSelectionConfig, setRowSelectionConfig] = useState<any>({
    selectedRowKeys: [],
    onChange: rowSelectionChange,
  })

  const getSections = useCallback(async () => {
    try {
      const sections = await api.sectionsApi.getSections({
        limit: paginationParams.pageSize,
        offset:
          Number((paginationParams?.current || 1) - 1) * Number(paginationParams.pageSize),
      })
      setSectionList(sections?.data?.data?.sections)
      setPaginationParams({ ...paginationParams, total: sections?.data?.data?.count || 10 })
    } catch (error) {
      message.error({
        content: 'Error while fetching sections',
        duration: 2,
      })
    }
  }, [api.sectionsApi, paginationParams?.current])

  useEffect(() => {
    if (currentTab !== "sections") {
      return;
    }
    getSections()
  }, [getSections, currentTab])

  const onCreateNew = () => {
    setShowSectionCreation(true)
    setIsEditJourney(false)
    setEditSection(undefined)
  }

  const closeItemCreationForm = () => {
    setShowSectionCreation(false)
    isEditJourney && setIsEditJourney(false)
    setEditSection(undefined)
    setTimeout(() => {
      getSections()
    }, 500)
  }

  const triggerEdit = async (sectionItem: any) => {
    setSectionId(sectionItem?.id)
    try {
      const section = await api.sectionsApi.getSectionById(sectionItem?.id)
      const parsedSectionData = parseSectionData(section?.data?.data)
      setEditSection(parsedSectionData)
    } catch (error) {
      message.error({
        content: 'Error while fetching section',
        duration: 2,
      })
    }
    setIsEditJourney(true)
    setShowSectionCreation(true)
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPaginationParams({
      ...pagination,
    })
  }

  const handleCreateOrUpdateSection = async (values: any) => {
    const id = sectionId || ''
    if (isEditJourney) {
      try {
        await api.sectionsApi.updateSection(id, {
          ...values,
        })

        message.success('Section updated successfully')
        createItemForm.resetFields()
        closeItemCreationForm()
      } catch (error) {
        message.error('Section update failed')
      }
    } else {
      message.loading({ content: 'Creating item...', key: 'create-section' })
      await api.sectionsApi.createSection(values)
      message.success({
        content: 'Section created successfully',
        duration: 2,
        key: 'create-section',
      })
    }
    createItemForm.resetFields()
    closeItemCreationForm()
  }

  const formSubmithandler = () => {
    createItemForm
      .validateFields()
      .then(values => {
        const payload = payloadSectionData(sectionId, values)
        handleCreateOrUpdateSection(payload)
      })
      .catch(errorInfo => { })
  }

  return (
    <div>
      <AstrologerSectionList
        onCreateNew={onCreateNew}
        triggerEdit={triggerEdit}
        dataSource={sectionList!}
        rowSelectionConfig={rowSelectionConfig}
        paginationParams={paginationParams}
        handleTableChange={handleTableChange}
      />
      <AstrologerSectionCreation
        visible={showSectionCreation}
        onClose={closeItemCreationForm}
        cdnUrl={YODA_CDN_URL}
        preSignUrl={YODA_PRESIGN_URL}
        formSubmithandler={formSubmithandler}
        form={createItemForm}
        initialValues={editSection}
        isEditJourney={isEditJourney}
      />
    </div>
  )
}

export default ManageAstroSectionScreen
