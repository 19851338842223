import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { Response } from '../../types'
import { SuggestionCardPayload, SuggestiveCards } from './types'
import { apiClient } from '../../apiClient'

class SuggestiveCardApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  /*
   get calls 
  */
  getSuggestiveCards = (options: {
    limit: number,
    offset: number,
    cardType: 'shubh_mantra' | 'god_set',
    activeType: boolean,
  }

  ) => {
    const { activeType, cardType, limit, offset } = options;
    return this.api.get<Response<SuggestiveCards>, Response<SuggestiveCards>>(
      `/content/internal/v1/audio/suggestion_card?limit=${limit}&offset=${offset}&card_type=${cardType}&active_type=${activeType}`,
    )
  }

  /*
   post calls 
  */

  createSuggestiveCard = (payload: SuggestionCardPayload) =>
    this.api.post<SuggestionCardPayload>('/content/internal/v1/audio/suggestion_card', payload)

  /*
   patch calls 
  */

  activateDeactivateSuggestionCard = (suggestionId: string, activate: boolean) =>
    this.api.patch<SuggestionCardPayload>(`/content/internal/v1/audio/suggestion_card/deactivate?suggestion_id=${suggestionId}&activate=${activate}`)

  /*
   delete calls 
  */


}

export default SuggestiveCardApi;
