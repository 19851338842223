import { Col, Form, Input, Row, Select, Button } from "antd"
import React from "react"
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import { AllowedFormats } from "@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload"

interface Props {
    fields: any
    add: () => void
    remove: (item: any) => void
    cdnUrl: string
    preSignUrl: string
    type: string
}

const { Option } = Select
const InfoMedia: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, type }) => {
    return (
        <>
            <Row justify="space-between" style={{ width: '100%' }}>
                {fields.map((field: any, index: number) => {
                    return (
                        <div key={index} style={{
                            border: '1px solid #e0dcdc',
                            marginRight: '0px',
                            marginBottom: '10px',
                            width: '49%',
                            padding: '10px',
                        }}>
                            <Row style={{ justifyContent: 'space-between', width: '100%' }}>

                                <Form.Item
                                    label={`Deeplink ${index + 1}`}
                                    name={[field.name, 'deeplink']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    style={{ flexBasis: '85%' }}
                                >
                                    <Input placeholder='Enter deeplink' />
                                </Form.Item>

                                <Button
                                    style={{ flexBasis: '8%' }}
                                    onClick={() => {
                                        remove(field.name)
                                    }}
                                    type='ghost'
                                    icon={<DeleteOutlined />}
                                    size='middle'
                                    danger
                                ></Button>


                            </Row>

                            <Col span={10}>
                                <Form.Item
                                    label={`Media type ${index + 1}`}
                                    name={[field.name, 'media_type']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                >
                                    <Select placeholder='Please Select Media type'>
                                        <Option value={'image'} key={'image'}>
                                            Image
                                        </Option>
                                        <Option value={'video'} key={'video'}>
                                            Video
                                        </Option>
                                    </Select>
                                </Form.Item>

                            </Col>

                            <Row>
                                <Form.Item
                                    noStyle
                                    shouldUpdate
                                >
                                    {({ getFieldValue }) => {
                                        return <Form.Item
                                            label={`Media`}
                                            name={[field.name, 'media_url']}
                                            labelCol={{ span: 28 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    min: 0,
                                                    max: 1,
                                                    validator: async (rules: any, values: any) => {
                                                        if (values?.length < rules.min) {
                                                            return Promise.reject('Please upload Media')
                                                        }
                                                    },
                                                },
                                            ]}
                                            valuePropName='fileList'
                                        >
                                            <S3Upload
                                                cdnUrl={cdnUrl}
                                                preSignUrl={preSignUrl}
                                                min={1}
                                                max={1}
                                                allowedFileExtension={getFieldValue(['data'])?.[type][index]?.media_type === 'video' ?
                                                    [AllowedFormats.MP4] :
                                                    [AllowedFormats.JPEG, AllowedFormats.JPG, AllowedFormats.GIF, AllowedFormats.PNG, AllowedFormats.WEBP]}
                                            />
                                        </Form.Item>
                                    }}
                                </Form.Item>


                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.data?.info_media[index]?.media_type !== currentValues?.data?.info_media[index]?.media_type
                                    }
                                    }
                                >
                                    {({ getFieldValue }) => {
                                        return getFieldValue(['data'])?.info_media[index]?.media_type === 'video' && (
                                            <Form.Item
                                                label={`Thumbnail`}
                                                name={[field.name, 'thumbnail']}
                                                labelCol={{ span: 28 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        min: 0,
                                                        max: 1,
                                                        validator: async (rules: any, values: any) => {
                                                            if (values?.length < rules.min) {
                                                                return Promise.reject('Please upload Thumbnail')
                                                            }
                                                        },
                                                    },
                                                ]}
                                                valuePropName='fileList'
                                            >
                                                <S3Upload
                                                    cdnUrl={cdnUrl}
                                                    preSignUrl={preSignUrl}
                                                    min={1}
                                                    max={1}
                                                />
                                            </Form.Item>
                                        )
                                    }
                                    }
                                </Form.Item>
                            </Row>
                        </div>
                    )
                })}
            </Row>
            <Col span={24} style={{ paddingTop: '10px' }}>
                <Row justify='start'>
                    <Col span={4}>
                        <Button
                            onClick={() => {
                                add()
                            }}
                            type='ghost'
                            icon={<PlusCircleOutlined />}
                            size='middle'
                        >
                            Add Info media
                        </Button>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default InfoMedia