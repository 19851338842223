import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { Response } from '../../types'
import type {

} from './types'
import { apiClient } from '../../apiClient'

class InAppNudgeApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getNudgeConfiguration = () =>
    this.api.get<Response<any>>(
      `/usher/internal/v1/journey/nudge`,
    )

  getEventsAndProperties = () =>
    this.api.get<Response<any>>(
      `/gw1/lex/internal/v1/registry`,
    )

  getAllJourneys = () =>
    this.api.get<Response<any>>(
      `/usher/internal/v1/journey`,
    )


  createJournay = (data: any) =>
    this.api.post<Response<any>>(
      `/usher/internal/v1/journey`,
      data,
    )

  customNudge = (data: any) =>
    this.api.post<Response<any>>(
      `/usher/internal/v1/journey/nudge/custom`,
      data,
    )

  editJournay = (data: any) =>
    this.api.put<Response<any>>(
      `/usher/internal/v1/journey`,
      data,
    )

  publishJournay = (id: string, to_status: string) =>
    this.api.patch<Response<any>>(
      `/usher/internal/v1/journey`,
      {
        id,
        to_status
      }
    )

  getNudges = () =>
    this.api.get<Response<any>>(
      `gw1/usher/internal/v1/nudge`,
    )

  postNudge = (payload: any) =>
    this.api.post<Response<any>>(
      `gw1/usher/internal/v1/nudge`,
      payload
    )

  putNudge = (payload: any) =>
    this.api.put<Response<any>>(
      `gw1/usher/internal/v1/nudge`,
      payload
    )
}

export default InAppNudgeApi
