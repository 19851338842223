import React from 'react';
import { Form, Input } from 'antd';

type CompletedCardProps = {
    fieldPath: Array<string>
}

const CompletedCard: React.FC<CompletedCardProps> = ({ fieldPath }) => {
    return (
        <div style={{ border: '1px #8c8c8c dashed', padding: '15px', borderRadius: '10px', marginBottom: '10px' }}>
            <Form.Item
                label='Completed Card Title'
                name={[...fieldPath, 'title']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Input placeholder='Enter completed card title' />
            </Form.Item>
            <Form.Item
                label='Completed Card Subtitle'
                name={[...fieldPath, 'subtitle']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Input placeholder='Enter completed card subtitle' />
            </Form.Item>
            <Form.Item
                label='Completed Card Description'
                name={[...fieldPath, 'description']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Input.TextArea placeholder='Enter completed card description' />
            </Form.Item>
            <Form.Item
                label='Completed Card CTA'
                name={[...fieldPath, 'cta']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Input placeholder='Enter completed card CTA' />
            </Form.Item>
        </div>
    );
};

export default CompletedCard;
