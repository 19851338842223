import { useAppContext } from '@/components/AppContext'
import { Spin, message } from 'antd'
import { A4B_APPS, YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import { useEffect, useState, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'antd/lib/form/Form'
import AstrologerCreation, { VARIANT_TYPES } from '../../components/AstrologerCreation'
import { LanguageState } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { agentPayloadData, parseAgentResponse } from './helper'
import MonetisationAstroApi from '@a4b/api/src/modules/Monetisation/Astrologer'

interface Props {
    isEditJourney: boolean
    currentStep: number
    agent: string
    nextHandler: (agent_id?: string) => void
    previousHandler: () => void
    languageState: LanguageState
    variant: VARIANT_TYPES
}

const CreateAstrologerStep: React.FC<Props> = ({
    currentStep,
    isEditJourney,
    agent,
    previousHandler,
    nextHandler,
    languageState,
    variant,
}) => {
    const [agentForm] = useForm()
    const { networkInstance, countryState, app } = useAppContext()
    const { monetisationAstroApi, monetisationNewAstroApi } = networkInstance.clientWithHeaders
    const isSrimandir = app === A4B_APPS.SRIMANDIR
    const api: MonetisationAstroApi = (isSrimandir) ? monetisationAstroApi : monetisationNewAstroApi
    const [searchParams] = useSearchParams()
    const [agentId] = useState<string>(searchParams.get('agent_id') as string)
    const [loading, setLoading] = useState<boolean>(false)

    const languageCode = isSrimandir ? languageState?.monetisationLanguage : 'en'
    const countryCode = isSrimandir ? countryState?.countryCode : 'US'

    const getAgentById = useCallback(async () => {
        setLoading(true)
        if (!agentId) {
            return
        }
        try {
            const agentResponse = await api.agentApi.getAgentById(agentId, countryState?.countryCode, variant === VARIANT_TYPES.VARIANT)
            const parsedAgentResponse = parseAgentResponse(agentResponse?.data?.data)
            if (variant === VARIANT_TYPES.VARIANT) {
                const controlData = agentForm.getFieldsValue(true);
                if (parsedAgentResponse.description === null || parsedAgentResponse.description === "") {
                    agentForm.setFieldsValue({ ...controlData, variant_enabled: true })
                } else {
                    agentForm.setFieldsValue({ ...parsedAgentResponse, variant_enabled: true })
                }
            } else {
                agentForm.setFieldsValue(parsedAgentResponse)
            }
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            message.error('Error while fetching Agent', 2)
        }
    }, [agentId, api.agentApi, countryState?.countryCode, variant, agentForm])

    useEffect(() => {
        if (isEditJourney) {
            getAgentById()
        } else {
            agentForm.setFieldsValue({ app_code: isSrimandir ? A4B_APPS.SRIMANDIR : A4B_APPS.ASTRO })
        }
    }, [agentForm, getAgentById, isEditJourney, isSrimandir])

    const handleAgentCreateOrUpdate = async (values: any) => {
        if (agentId) {
            try {
                const agentPayload = agentPayloadData(values, languageCode, countryCode)
                await api.agentApi.updateAgent(agentId, agentPayload)
                message.success('Agent updated successfully', 2)
                nextHandler(agentId)
            } catch (error) {
                message.error('Error while updating Agent', 2)
            }
        } else {
            try {
                const PayloadTemple = agentPayloadData(values, languageCode, countryCode)
                const createAgentResponse = await api.agentApi.createAgent(PayloadTemple)
                message.success('Agent created successfully', 2)
                nextHandler(createAgentResponse?.data?.data?.agent_id)
            } catch (error) {
                message.error('Error while creating Agent', 2)
            }
        }
    }

    const submitFormHandler = () => {
        agentForm
            .validateFields()
            .then((values: any) => {
                const displayNames: { [key: string]: string } = {}
                values['display_names'].forEach((name: { language: string, name: string }) => {
                    displayNames[name.language] = name.name;
                    displayNames[name.language] = name.name;
                });

                values['display_names'] = displayNames;
                const phoneValidationPattern = /^[0-9]{10}$/
                const emailValidationPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                if (!phoneValidationPattern.test(values?.phone)) {
                    message.warning('Phone number should be 10 digits', 2)
                    return
                }
                if (!emailValidationPattern.test(values?.email)) {
                    message.warning('Invalid E-mail', 2)
                    return
                }
                else {
                    handleAgentCreateOrUpdate(values)
                }
            })
            .catch(error => {
                console.log('Agent creation error')
            })
    }

    return (
        loading ?
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spin /></div>
            :
            <AstrologerCreation
                cdnUrl={YODA_CDN_URL}
                preSignUrl={YODA_PRESIGN_URL}
                form={agentForm}
                currentStep={currentStep}
                previousHandler={previousHandler}
                nextHandler={() => nextHandler(agentId)}
                isEditJourney={isEditJourney}
                submitFormHandler={submitFormHandler}
                acceptLanguage={languageCode}
                variant={variant}
            />

    )
}

export default CreateAstrologerStep
