import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import {
  Campaigns,
  CreateCampaign,
  UpdateCampaign,
  UpdateCampaignStatus,
} from './types'

class CampaignsApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getCampaignList = (paginationParams: any, active?: boolean) =>
    this.api.get<Campaigns>(`/karna/internal/v1/campaigns`, {
      params: {
        limit: paginationParams?.limit,
        offset: paginationParams?.offset,
        active: active !== undefined ? active : null,
      },
    })

  getCampaignById = (campaignId: string) =>
    this.api.get(`/karna/internal/v1/campaigns/${campaignId}`)

  createCampaign = (payload: CreateCampaign) =>
    this.api.post(`/karna/internal/v1/campaigns`, payload)

  updateCampaign = (campaignId: string, payload: UpdateCampaign) =>
    this.api.put(`/karna/internal/v1/campaigns/${campaignId}`, payload)

  updateCampaignStatus = (campaignId: string, payload: UpdateCampaignStatus) =>
    this.api.patch(`/karna/internal/v1/campaigns/${campaignId}`, payload)
}

export default CampaignsApi
