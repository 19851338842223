import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Upload } from 'antd'
import React, { useCallback, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

//types
import { UploadFile } from 'antd/lib/upload/interface'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { AttachmentServiceResponse } from 'types'

export type YoutubeFormFields = {
  thumbnail: UploadFile<AttachmentServiceResponse>[]
  link: string
}

const { useForm } = Form

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

const getDataForUpload = () => {
  const ref_content_code = `youtube_thumbnail_${uuidv4()}`
  return {
    ref_content_code,
    media_type: 'IMAGE',
  }
}

interface Props {
  showModal: boolean
  uploadUrl: string
  onSubmit: (values: YoutubeFormFields) => void
  onClose: () => void
}
export const YoutubeMarkdownPopup: React.FC<Props> = ({
  showModal,
  uploadUrl,
  onSubmit,
  onClose,
}) => {
  const [youtubeForm] = useForm<YoutubeFormFields>()

  useEffect(() => {
    if (!showModal) {
      youtubeForm.resetFields()
    }
  }, [showModal])

  const onModalCancel = useCallback(() => {
    onClose()
  }, [])

  const handleYoutubeFormSubmit = (values: YoutubeFormFields) => {
    onSubmit(values)
  }

  const handleYoutubeFormFailed = (
    errorInfo: ValidateErrorEntity<YoutubeFormFields>,
  ) => {
    console.log({ errorInfo })
  }

  return (
    <Modal
      title='Insert Youtube Link'
      visible={showModal}
      onCancel={onModalCancel}
      footer={null}
    >
      <Form
        form={youtubeForm}
        onFinish={handleYoutubeFormSubmit}
        onFinishFailed={handleYoutubeFormFailed}
      >
        <Form.Item
          label='Youtube Thumbnail'
          name='thumbnail'
          valuePropName='fileList'
          getValueFromEvent={normFile}
          rules={[{ required: true, message: 'Please upload a image file!' }]}
        >
          <Upload
            action={uploadUrl}
            accept={'image/*'}
            data={() => getDataForUpload()}
            name='input_file'
            listType='picture'
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item label='Youtube Link' name='link'>
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
