import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import AntdFormWrapper from "../../../../../components/Forms/AntdFormWrapper";
import { FormInstance } from "antd";
import AddExperiments from "../components/AddExperiments";
import { useAppContext } from "../../../../../components/AppContext";
import { AddQuizPayload, Quiz, UpdateQuizPayload } from "@a4b/api/src/modules/Coins/types";
import { Experiments } from "@a4b/api/src/modules/AbExperiments/types";
import VariantsSystemIds from "../components/VariantsSystemIds";

// This interface is just a example for AntdFormWrapper 
interface TestComProps {
    form: FormInstance<string>
}

const TestCom = ({ form }: TestComProps) => <div> CREATE SOMETHING 2 DATA </div>
export enum DRAWER_TYPE {
    CREATE_EXPERIMENTS = 'Create AB experiments',
    UPDATE_SYSTEM_IDS = "Update system ids"
}



export default function useDrawerConfig(
    setActiveDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | undefined>>,
    getExperiments: () => Promise<void>,
    onDrawerClose: () => void,
    systemIdForm?: {
        experiment_id: string;
        variant_code: string;
        system_ids: string[];
    },
    experiment?: Experiments,
    activeDrawer?: DRAWER_TYPE,
): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {
    const { networkInstance, userProfile } = useAppContext();
    // define Drawers 
    const drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_EXPERIMENTS,
                width: '98vw',
            },
            drawerContent: <AntdFormWrapper<any, any>
                FormFields={AddExperiments}
                initValues={{ ...experiment, created_by: userProfile?.email || "" }}
                createApi={networkInstance.clientWithHeaders.abExperiments.addExperiments}
                submitSuccessfulCallback={() => {
                    getExperiments();
                    setActiveDrawer(undefined)
                }}
            />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.UPDATE_SYSTEM_IDS,
                width: '50vw',
            },
            drawerContent: systemIdForm && <AntdFormWrapper<any, any>
                FormFields={VariantsSystemIds}
                initValues={{}}
                formData={systemIdForm}
                updateApi={networkInstance.clientWithHeaders.abExperiments.patchSystemIds}
                submitSuccessfulCallback={() => {
                    getExperiments();
                    onDrawerClose();
                }}
            />
        }
    ], [experiment, userProfile?.email, networkInstance.clientWithHeaders.abExperiments.addExperiments, networkInstance.clientWithHeaders.abExperiments.patchSystemIds, systemIdForm, getExperiments, setActiveDrawer, onDrawerClose])

    return drawers.find((drawer) => drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}