import React, { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import ManageTemplesPage from './components/ManageTempleShrinesPage'
import { HubList } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import CreateTemple from './components/CreateTempleShrine'
import { TempleData, TemplesList } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { formatTempleCreation, parseTempleData } from './utils/helper'

const getPageBreadcrumbs = () => [
    {
        path: '/monetisation/offerings/manage-temples',
        breadcrumbName: 'Manage Temples',
    },
]

const ManageTempleScreen: React.FC = () => {
    const { networkInstance, monetisationRoles } = useAppContext()
    const { monetisationOfferingsApi, templesApi } = networkInstance.clientWithHeaders
    const [showTempleCreation, setShowTempleCreation] = React.useState(false)
    const [isEditJourney, setIsEditJourney] = React.useState(false)
    const [templesList, setTemplesList] = React.useState<TemplesList>({ temples_list: [] });
    const [temple, setTemple] = React.useState<TempleData | undefined>(undefined)
    const [hubList, setHubList] = React.useState<HubList>()

    const getTempleList = useCallback(async () => {
        try {
          const response = await templesApi.GetTempleList();
          const fetchedTemplesList: TemplesList = response?.data?.data;
      
          if (fetchedTemplesList && Array.isArray(fetchedTemplesList.temples_list)) {
            setTemplesList(fetchedTemplesList);
          }
        } catch (error) {
          message.error({
            content: 'Error while fetching temple list',
            duration: 3,
          });
          console.error("Error fetching temples:", error);
        }
      }, [templesApi]);

    const getHubList = async () => {
        try {
            const hubList = await monetisationOfferingsApi.hub.getHubList({
                limit: 100,
                offset: 0,
            }, 'dropdown')
            setHubList(hubList?.data?.data)
        } catch (error) {
            console.log(error)
            message.error('Error while fetching hub list', 3)
        }
    }
    useEffect(() => {
        getTempleList();
        getHubList()
    }, [])

    const onCreateNew = () => {
        setShowTempleCreation(true)
        setIsEditJourney(false)
        setTemple(undefined)
    }

    const closeTempleCreationForm = () => {
        setShowTempleCreation(false)
        isEditJourney && setIsEditJourney(false)
        setTemple(undefined)
        getTempleList()
        getHubList()
    }

    const triggerEdit = (temple: TempleData) => {
        console.log("this is the temple data", temple)
        const parsedTempleData = parseTempleData(temple)
        setTemple(parsedTempleData)
        setIsEditJourney(true)
        setShowTempleCreation(true)
        console.log("after setting the data", temple)
    }

    return (
        <div style={{ padding: '0 40px' }}>
            <AdminBreadcrumb routes={getPageBreadcrumbs()} />
            <ManageTemplesPage
                onCreateNew={onCreateNew}
                triggerEdit={triggerEdit}
                dataSource={templesList.temples_list}
                hubList={hubList}
            />
            {
                <CreateTemple
                    showTempleCreation={showTempleCreation}
                    closeTempleCreation={closeTempleCreationForm}
                    isEditJourney={isEditJourney}
                    formData={temple}
                    hubList={hubList}
                />
            }
        </div>
    )
}

export default withDefaultLayout(ManageTempleScreen)