import { AlpBanners } from '@a4b/api/src/modules/Monetisation/Astrologer/types'

export const parseSectionData = (section: any) => {
  const sectionData = {
    title: section?.title,
    description: section?.description,
    type: section?.type,
    position: section?.position,
    active: section?.active,
    app_code: section?.app_code,
    country_code: section?.country_code,
    data: section?.data?.data?.map((item: AlpBanners) => ({
      ...item,
      media: {
        media_type: item?.media?.media_type,
        media_url: [item?.media?.media_url],
        visible: item?.media?.visible,
      },
    })),
  }
  return sectionData
}

export const payloadSectionData = (sectionId?: string, section?: any) => {
  const sectionPayload = {
    ...(sectionId && {
      id: sectionId,
    }),
    title: section?.title,
    description: section?.description,
    type: section?.type,
    position: section?.position,
    active: section?.active,
    app_code: section?.app_code,
    country_code: section?.country_code,
    data: {
      type: 'alp_banners',
      data: section?.data?.map((item: any) => ({
        ...item,
        media: {
          media_type: item?.media?.media_type,
          media_url: item?.media?.media_url?.[0],
          visible: item?.media?.visible,
        },
      })),
    },
  }
  return sectionPayload
}
