import { Form, Select } from "antd";
import { SHARE_TEXT_CONFIG } from "../../helpers/config";

interface FiltersProps {
  selectedPlatform: string,
  changeSelectedPlatform: (val: string) => void;
}

export default function Filters(props: FiltersProps) {
  const { selectedPlatform, changeSelectedPlatform } = props;
  return <div style={{ display: 'flex', gap: '20px' }}>
    <Form layout="vertical">
      <Form.Item label="Select platform " >
        <Select
          value={selectedPlatform}
          onChange={changeSelectedPlatform}
          style={{ width: '200px' }}
        >
          {SHARE_TEXT_CONFIG.data.platform_type.map((platform: string) =>
            <Select.Option value={platform}>{platform}</Select.Option>
          )}
        </Select>
      </Form.Item>
    </Form>


  </div>
} 