import { Col, Form, Input, Row, Select, Button } from "antd"
import React from "react"
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'

interface Props {
    fields: any
    add: () => void
    remove: (item: any) => void
    cdnUrl: string
    preSignUrl: string
}

const { Option } = Select
const PreviousPoojaMedia: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl }) => {
    return (
        <>
            <Row justify="space-between">
                {fields.map((field: any, index: number) => {
                    return (
                        <div key={index} style={{
                            border: '1px solid #e0dcdc',
                            marginRight: '0px',
                            marginBottom: '10px',
                            padding: '10px',
                            gap: '5px'
                        }}>
                            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Form.Item
                                    label={`Title ${index + 1}`}
                                    name={[field.name, 'title']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ flexBasis: '40%' }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Title',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter Title' />
                                </Form.Item>

                                <Form.Item
                                    label={`Sub Title ${index + 1}`}
                                    name={[field.name, 'sub_title']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ flexBasis: '45%' }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter subtitle',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Please type Sub Title' />
                                </Form.Item>

                                <Button
                                    style={{ marginTop: '30px', marginRight: '5px', flexBasis: '8%' }}
                                    onClick={() => {
                                        remove(field.name)
                                    }}
                                    type='ghost'
                                    icon={<DeleteOutlined />}
                                    size='middle'
                                    danger
                                ></Button>

                            </Row>


                            <Row justify="space-between">
                                <Form.Item
                                    label={`Media type ${index + 1}`}
                                    name={[field.name, 'media', 'media_type']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter description of the category',
                                        },
                                    ]}
                                >
                                    <Select placeholder='Please Select Media type'>
                                        <Option value={'image'} key={'image'}>
                                            Image
                                        </Option>
                                        <Option value={'youtube'} key={'youtube'}>
                                            Youtube
                                        </Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.data?.media_cards[index]?.media?.media_type !== currentValues?.data?.media_cards[index]?.media?.media_type
                                    }
                                    }
                                >
                                    {({ getFieldValue }) => {
                                        return getFieldValue(['data'])?.media_cards[index]?.media?.media_type === 'youtube' ? (
                                            <Form.Item
                                                label={`Media`}
                                                name={[field.name, 'media', 'media_url']}
                                                labelCol={{ span: 28 }}
                                                wrapperCol={{ span: 28 }}
                                                rules={[{ required: true, message: 'Input video url' }]}
                                            >
                                                <Input placeholder='Enter media url' />
                                            </Form.Item>
                                        ) : (
                                            <Form.Item
                                                label={`Media`}
                                                name={[field.name, 'media', 'media_url']}
                                                labelCol={{ span: 28 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        min: 0,
                                                        max: 1,
                                                        validator: async (rules: any, values: any) => {
                                                            if (values?.length < rules.min) {
                                                                return Promise.reject('Please upload Media')
                                                            }
                                                        },
                                                    },
                                                ]}
                                                valuePropName='fileList'
                                            >
                                                <S3Upload
                                                    cdnUrl={cdnUrl}
                                                    preSignUrl={preSignUrl}
                                                    min={1}
                                                    max={1}
                                                />
                                            </Form.Item>
                                        )
                                    }
                                    }

                                </Form.Item>

                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.data?.media_cards[index]?.media?.media_type !== currentValues?.data?.media_cards[index]?.media?.media_type
                                    }
                                    }
                                >
                                    {({ getFieldValue }) => {
                                        return getFieldValue(['data'])?.media_cards[index]?.media?.media_type === 'youtube' && (
                                            <Form.Item
                                                label={`Thumbnail`}
                                                name={[field.name, 'media', 'media_thumbnail']}
                                                labelCol={{ span: 28 }}
                                                style={{ paddingLeft: '20px' }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        min: 1,
                                                        max: 1,
                                                        validator: async (rules: any, values: any) => {
                                                            if (values?.length < rules.min) {
                                                                return Promise.reject('Please upload Thumbnail')
                                                            }
                                                        },
                                                    },
                                                ]}
                                                valuePropName='fileList'
                                            >
                                                <S3Upload
                                                    cdnUrl={cdnUrl}
                                                    preSignUrl={preSignUrl}
                                                    min={1}
                                                    max={1}
                                                />
                                            </Form.Item>
                                        )
                                    }
                                    }
                                </Form.Item>


                            </Row>

                        </div>
                    )
                })}

                <Col span={24} style={{ paddingTop: '10px' }}>
                    <Row justify='start'>
                        <Col span={4}>
                            <Button
                                onClick={() => {
                                    add()
                                }}
                                type='ghost'
                                icon={<PlusCircleOutlined />}
                                size='middle'
                            >
                                Previous pooja media
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default PreviousPoojaMedia