import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { Collapse, notification, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import React from 'react';
import 'antd/dist/antd.css';
import { Button, Card, Divider, Space, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { AudioContentQueryParams, AudioContentState, AudioTag, BulkAudioStateUpdateOptions, Music } from '@a4b/api/src/modules/Content/Audio/types';
import MusicFilter, { ContentFilterFormFields } from '@a4b/ui/src/modules/admin/components/music/MusicFilter';
import { AUDIO_TRANSITION_CONFIG, MUSIC_STATES, THIRD_PARTY_NAMES } from '@/utils/music.constants';
import { CDN_BASE_URL } from '@/utils/constants';
import CsvUploadUpdateAudio from './CSVUploadUpdateAudio';
import { getLanguageNameFromValue } from '@a4b/ui/src/modules/admin/constants';
const { Panel } = Collapse;
const { Text } = Typography;



const defaultFilter: ContentFilterFormFields = {
  status: 'NEW_AUDIO',
  title: '',
  singer: '',
  tag: '',
  audioType: '',
  audioLanguage: '',
  thirdPartyName: ['times_music']
}

const defaultPagination = {
  current: 1,
  pageSize: 50,
  total: -1
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
  notification[type]({
    message: title,
    description
  });
};

const MusicBulkUpdate = () => {
  const { networkInstance } = useAppContext();
  const [filters, setFilters] = useState<ContentFilterFormFields>(defaultFilter);
  const [tags, setTags] = useState<AudioTag[]>([])

  const generateAudioCsv = useCallback(async (filters) => {
    try {
      // const pageSize = pagination.pageSize || 50;

      const params: any = {
        state: filters.status,
        partner_name: filters.thirdPartyName[0],
        // limit: pageSize,
        // offset: ((pagination.current || 1) - 1) * pageSize,
        audio_title: filters.title,
        audio_language: filters.audioLanguage,
        audio_type: filters.audioType,
        singer_name: filters.singer,
        tag: filters.tag
      }
      try {
        await networkInstance.clientWithHeaders.contentApi.audio.getAudioDataCsv(params)
      } catch (error) {
      }
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.contentApi.audio])

  const loadTags = useCallback(async (tagQuery: string) => {
    try {
      const tagList = await networkInstance.clientWithHeaders.contentApi.audio.getTagsForAudio({ tag: tagQuery });
      if (tagList.status === 204) {
        setTags([])
      } else {
        setTags(tagList.data.data.tags)
      }
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.contentApi.audio])

  useEffect(() => {
    loadTags('');
  }, [loadTags]);

  const onTagSearch = useCallback((tagQuery) => {
    loadTags(tagQuery)
  }, [loadTags])

  return (
    <Card
      title='Music bulk update'
      extra={<CsvUploadUpdateAudio />}
    >
      <MusicFilter
        withPrompt={true}
        tags={tags}
        onTagSearch={onTagSearch}
        filter={filters}
        statusOptions={MUSIC_STATES}
        thirdPartyNameOptions={THIRD_PARTY_NAMES}
        onApplyFilterClicked={function (_filter: ContentFilterFormFields): void {
          generateAudioCsv(_filter);
          setFilters(_filter);
        }} buttonText="Generate audio csv files."
      />
    </Card>
  )
}

export default withDefaultLayout(MusicBulkUpdate)
