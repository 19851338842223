import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { Pagination, Response, Step, Steps } from './types'

class StepsApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createSteps = (payload: Steps) =>
    this.api.post<Response<any>>(
      `/yoda/internal/v1/steps/create-steps`,
      payload,
    )

  getSteps = (step_type: string, entityId?: string) =>
    this.api.get<Response<Step[]>>(`/yoda/internal/v1/steps/steps-list`, {
      params: { type: step_type, entity_id: entityId },
    })

  updateSteps = (payload: Steps) =>
    this.api.put<Response<Step[]>>(
      `/yoda/internal/v1/steps/update-steps`,
      payload,
    )
  deleteStep = (id: string) =>
    this.api.delete<Response<Step[]>>(`/yoda/internal/v1/steps/${id}`)
}

export default StepsApi
