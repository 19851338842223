//hooks
import { useState } from 'react'
import { useForm } from 'antd/lib/form/Form'

//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'

//component
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'

import { Col, Form, Row, message, Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import styled from 'styled-components'
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'


const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
  .ant-card-body {
    flex-grow: 1;
  }
`
const ManageS3Upload = () => {
    const [form] = useForm()
    const [mediaUrlList, setMediaUrlList] = useState<any>([])

    const onValuesChange = (value: any) => {
        setMediaUrlList(value)
    }

    const handleCopy = (text: string) => {
        const textArea = document.createElement('textarea')
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.select()
        document.execCommand('copy')
        document.body.removeChild(textArea)
        message.success('Text copied', 2)
    }

    const clearComponent = () => {
        form.resetFields()
        setMediaUrlList([])
    }

    return (
        <div style={{ padding: '0px 40px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Container>
                <Row justify='space-between' style={{ width: '100%' }}>
                    <Col span={4}>
                        <Form
                            form={form}
                            layout='vertical'
                            name='Upload media'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            initialValues={{ remember: true }}
                            onValuesChange={onValuesChange}
                        >
                            <Col>
                                <Form.Item
                                    label={`Media upload`}
                                    name='media_upload'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            min: 0,
                                            max: 1,
                                            validator: async (rules: any, values: any) => {
                                                if (values?.length < rules.min) {
                                                    return Promise.reject('Please upload icon')
                                                }
                                            },
                                        },
                                    ]}
                                    valuePropName='fileList'
                                >
                                    <S3Upload cdnUrl={YODA_CDN_URL} preSignUrl={YODA_PRESIGN_URL} min={0} max={1} />
                                </Form.Item>
                                <Button style={{ width: '100%' }} type="text" danger onClick={clearComponent}>
                                    Clear
                                </Button>
                            </Col>
                        </Form>
                    </Col>
                    <Col span={18}>
                        <Row style={{ marginTop: '28px' }}>
                            {mediaUrlList?.media_upload?.length > 0 && mediaUrlList?.media_upload?.map((mediaUrl: string) => (
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '13px', justifyContent: 'space-around', width: '100%' }}>
                                    <span style={{ height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: '8px' }}>
                                        {mediaUrl}
                                    </span>
                                    <span style={{ cursor: 'pointer' }} onClick={() => handleCopy(mediaUrl)}>
                                        <CopyOutlined />
                                    </span>
                                </div>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withDefaultLayout(ManageS3Upload)
