import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'

import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//screens
import {
  ContentFilterFormFields,
  ContentList as ContentListScreen,
} from '@a4b/ui/src/admin'

import type { LibraryContent } from '@a4b/api'

import useLibrary from '../../hooks/useLibrary'

interface Props { }

const defaultFilter: ContentFilterFormFields = {
  status: ['DRAFT', 'CHANGE_REQUESTED'],
}

const CreatorListing: React.FC<Props> = () => {
  const {
    loading,
    tags,
    totalCount,
    content,
    filter,
    onTagSearch,
    onEditContentClicked,
    onSendToQCClicked,
    onViewContentClicked,
    onPageChanged,
    onApplyFilterClicked,
    onDeleteContentClicked,
    onSendToQCDraft
  } = useLibrary(defaultFilter, 'Creator')
  let navigate = useNavigate()

  const onAddNewContentClicked = () => {
    navigate('/content/create-draft')
  }

  return (
    <div>
      <ContentListScreen
        title='Creator Library Content'
        loading={loading}
        tags={tags}
        data={content}
        filter={filter}
        onPageChanged={onPageChanged}
        total={totalCount}
        onTagSearch={onTagSearch}
        onEditContentClicked={onEditContentClicked}
        onSendToQCClicked={onSendToQCClicked}
        onViewContentClicked={onViewContentClicked}
        onApplyFilterClicked={onApplyFilterClicked}
        onAddNewContentClicked={onAddNewContentClicked}
        onDeleteContentClicked={onDeleteContentClicked}
        onSendToQCDraft={onSendToQCDraft}
      />
    </div>
  )
}

export default withDefaultLayout(CreatorListing)
