import React from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Switch } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { ATTACHMENT } from '@/utils/enum';
import DeletableContainer from '../deletableContainer/DeletableContainer';
import HeaderTags from '../headerTags/HeaderTags';
import Riddler from '@/network/Riddler/Riddler';
import Attachment from '@/ui/organisms/Attachment';
import HeroSong from '@/entities/feeds/Widgets/HeroSong';
import NumberInput from '@/modules/feeds/components/Number';
import { HeroSongInstance } from '@/entities/feeds/Widgets';

interface Props {
  riddlerInstance: any;
  contentInstance: HeroSongInstance;
}
const HeroSongWidget: React.FC<Props> = ({
  riddlerInstance,
  contentInstance,
}) => {
  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            required={true}
            label="Header"
            name={['content', 'header']}
            rules={[
              {
                validator: contentInstance.headerValidator,
              },
            ]}
          >
            <Input placeholder="Header" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ offset: 4, span: 16 }}
            name={["content", "show_item_details"]}
            initialValue={true}
            valuePropName="checked"
          >
            <Checkbox>show item attributions</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <HeaderTags widgetClass={contentInstance} />
        </Col>
      </Row>
      <Form.List name={['content', 'items']}>
        {(fields, { add, remove, move }) => {
          return (
            <div>
              {fields.map((field: any, index: number) => {
                return (
                  <Form.Item key={field.key}>
                    <DeletableContainer
                      closeHandler={() => {
                        remove(field.name);
                      }}
                      itemText={`Item Attribute : ${index + 1}`}
                      showItemTextInBold={true}
                      moveFunction={move}
                      fields={fields}
                      currentIndex={index}
                      fieldsLength={fields.length}
                      move={move}
                      add={add}
                    >
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="Title"
                            name={[field.name, 'title']}
                            rules={[
                              {
                                validator: contentInstance.titleValidator,
                              },
                            ]}
                          >
                            <Input placeholder="Title" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            required={true}
                            label="Subtitle"
                            name={[field.name, 'subtitle']}
                            rules={[
                              {
                                validator: contentInstance.subtitleValidator,
                              },
                            ]}
                          >
                            <Input placeholder="Subtitle" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label="Image Attachment"
                            name={[field.name, 'image_attachment_id']}
                            rules={[
                              {
                                validator:
                                  contentInstance.thumbnailImageAttachmentValidator,
                              },
                            ]}
                          >
                            <Attachment
                              attachmentType={ATTACHMENT.IMAGE}
                              riddlerInstance={riddlerInstance}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                            label="Song of the day"
                            name={[field.name, 'song_of_the_day']}
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              currentValues?.content?.items[field.name]
                                ?.song_of_the_day !==
                              prevValues?.content?.items[field.name]
                                ?.song_of_the_day
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue([
                                'content',
                                'items',
                                field.name,
                                'song_of_the_day',
                              ]) != true ? (
                                <Form.Item
                                  {...field}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 16 }}
                                  label="Song ID"
                                  name={[field.name, 'r_song_id']}
                                  rules={[
                                    {
                                      validator:
                                        contentInstance?.songIdValidator,
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Song ID"
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                    </DeletableContainer>
                  </Form.Item>
                );
              })}

              <Form.Item>
                <Row justify="end">
                  <Col span={24}>
                    <Row justify="end">
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            add();
                            // addItem();
                          }}
                          type="dashed"
                          icon={<PlusCircleOutlined />}
                          size="middle"
                        >
                          Add Item Attributes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
              <div style={{ height: '20px' }}></div>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

export default HeroSongWidget;
