//hooks
import React, { useEffect } from 'react'

import { Drawer, message } from 'antd'
import { FormInstance } from 'antd/es/form/Form'

import StoreContentCreation from '@a4b/ui/src/modules/monetisation/molecules/StoreContentCreation';
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'

//context
import { useAppContext } from '@/components/AppContext'
import { formatCreateOrEditStoreContent } from './helper';

interface Props {
    showItemCreation: boolean
    closeItemCreation: () => void
    initialValues: any
    form: FormInstance<any>
    isEdit: boolean
    selectedValue: any
}

const CreateScheduler: React.FC<Props> = ({
    showItemCreation,
    closeItemCreation,
    initialValues,
    form,
    isEdit,
    selectedValue,
}) => {
    const { networkInstance, languageState, countryState } = useAppContext()
    const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [form, initialValues])

    const handleCreateStoreContent = async (values: any) => {
        if (isEdit) {
            try {
                const formData = formatCreateOrEditStoreContent({
                    ...values,
                    position: form.getFieldValue("position"),
                    store_id: selectedValue,
                    country_code: countryState?.countryCode
                }, initialValues, languageState)

                await monetisationOfferingsApi.schedulerApi.updateStoreContent(
                    initialValues?.id,
                    formData,
                )
                message.success({ content: 'Updated Store Content', duration: 2 })
                closeItemCreation()


            } catch (error: any) {
                console.log(error)
                message.error('Error while updating Store Content' + error?.message, 3)
            }
        } else {
            try {
                const formData = formatCreateOrEditStoreContent({
                    ...values,
                    position: form.getFieldValue("position"),
                    store_id: selectedValue,
                    country_code: countryState?.countryCode
                }, initialValues, languageState)
                await monetisationOfferingsApi.schedulerApi.createStoreContent(
                    formData,
                )
                message.success({ content: 'Created Store Content', duration: 2 })
                closeItemCreation()

            } catch (error: any) {
                console.log(error)
                message.error('Error while creating Store Content' + error?.message, 3)
            }
        }
    }

    const submitFormHandler = () => {
        form.validateFields()
            .then((values: any) => {
                handleCreateStoreContent(values)
            })
            .catch(error => {
                console.log('Create Store content error')
            })
    }

    return (
        <>
            <Drawer
                title={isEdit ? 'Edit Scheduler' : 'Create New  Scheduler'}
                placement='right'
                onClose={closeItemCreation}
                visible={showItemCreation}
                destroyOnClose={true}
                size='large'
            >
                <StoreContentCreation
                    cdnUrl={YODA_CDN_URL}
                    preSignUrl={YODA_PRESIGN_URL}
                    form={form}
                    submitFormHandler={submitFormHandler}
                    isEditJourney={isEdit}
                />
            </Drawer >
        </>
    )
}

export default CreateScheduler
