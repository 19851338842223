import axios from 'axios';
import useCaseInstance from '..';
import Platform from '../../entities/feeds/Platform';
import Properties from '../../entities/feeds/Properties';

import { GET_S3_PATH } from './../../network/apiRoutes';
// export const getPlatforms = () => {
//   let platformPromise = new Promise(async (resolve, reject) => {
//     try {
//       let platformResponse = await useCaseInstance.apiInstance.getPlatforms();

//       let platform: Platform = new Platform(
//         platformResponse?.data?.data?.platforms
//       );
//       resolve(platform);
//     } catch (error: any) {
//       reject(error?.response?.data.error || error);
//     }
//   });
//   return platformPromise;
// };
// export const getProperties = () => {
//   let propertiesPromise = new Promise(async (resolve, reject) => {
//     try {
//       let propertiesResponse =
//         await useCaseInstance.apiInstance.getProperties();
//       let properties = new Properties();
//       properties.appendProperties(
//         propertiesResponse?.data?.data?.platform_properties
//       );

//       resolve(properties);
//     } catch (error: any) {
//       reject(error?.response?.data.error || error);
//     }
//   });
//   return propertiesPromise;
// };
export const getS3PathById = (id: string) => {
  let masterWidgetPromise = new Promise(async (resolve, reject) => {
    try {
      let baseUrl = process.env.REACT_APP_RIDDLER_API_URL;
      let masterWidgetResponse = await axios.get(
        `${baseUrl}/${GET_S3_PATH}${id}`
      );
      resolve(masterWidgetResponse?.data?.data);
    } catch (error: any) {
      reject(error?.response?.data.error || error);
    }
  });
  return masterWidgetPromise;
};
// export const getMasterWidgets = () => {
//   let masterWidgetPromise = new Promise(async (resolve, reject) => {
//     try {
//       let masterWidgetResponse =
//         await useCaseInstance.apiInstance.getWidgetMaster();
//       resolve(masterWidgetResponse?.data?.data);
//     } catch (error: any) {
//       reject(error?.response?.data.error || error);
//     }
//   });
//   return masterWidgetPromise;
// };
