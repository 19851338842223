import { Rashifal } from "@a4b/api/src/modules/Content/Rashifal/types";
import { NEW_CDN_HOST, getLanguageNameFromValue } from "@/utils/constants";
import { Button, Switch, TableColumnType, TablePaginationConfig, TableProps, Tag, Typography } from "antd";
import moment from "moment";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { openNotification } from "@/utils";
import { useAppContext } from "@/components/AppContext";
import { SuggestiveCard } from "@a4b/api/src/modules/SuggestionCards/types";

export default function useTableConfig<T>(
  dataSource: T[],
  activateDeactivateSuggestionCard: (suggestionCardId: any, isActive: any) => Promise<void>,
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
): TableProps<SuggestiveCard[]> {

  const { networkInstance } = useAppContext();

  const columns: TableColumnType<SuggestiveCard>[] = [
    {
      title: 'S No',
      key: 'id',
      dataIndex: 'id',
      render(value, record, index) {
        return index + 1
      },
    },
    {
      title: 'Card type details',
      dataIndex: 'card_type',
      render(value, record, index) {
        return <div >
          <Typography.Paragraph><b> Card Type </b>: {value}</Typography.Paragraph>
          {
            value === 'god_set' && <>
              <Typography.Paragraph><b> God Set Title </b>: {record.god_set_content?.title}</Typography.Paragraph>
              <Typography.Paragraph><b> God Set Icon </b>: <img src={NEW_CDN_HOST + record.god_set_content?.icon_path} alt="" height={30} width={30} /></Typography.Paragraph>
            </>
          }
        </div>
      },
    },
    {
      title: 'Language',
      dataIndex: 'language_code',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      render(value: {
        "songs_not_played": number,
        "song_paused": number,
        "song_played": number
      }, record, index) {
        return <>
          <Typography.Paragraph><strong> Song not played</strong>: {value.songs_not_played}</Typography.Paragraph>
          <Typography.Paragraph><strong> Song paused</strong>: {value.song_paused}</Typography.Paragraph>
          <Typography.Paragraph><strong> Song played</strong>: {value.song_played}</Typography.Paragraph>
        </>
      },
    },
    {
      title: 'active',
      dataIndex: 'active',
      render(value, record, index) {
        return <Tag color={value ? 'green' : 'red'}>{value ? 'Active' : 'Inactive'}</Tag>
      },
    }
    ,
    {
      title: 'Actions',
      dataIndex: 'id',
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Switch style={{ width: '100px' }} onClick={() => {
            activateDeactivateSuggestionCard(record.id, !record.active)
          }} checked={record.active} unCheckedChildren="Activate" checkedChildren="Deactivate" />
        </div>
      },
    }
  ]
  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  return {
    //@ts-ignore
    columns, dataSource: dataSource.map((row) => ({ key: row.id, ...row })), pagination, onChange: tableOnChange, expandedRowRender:
      (record: SuggestiveCard) => <div style={{ display: 'flex', gap: '20px' }}>
        {record.card_type === 'god_set' ? <div style={{ display: 'flex', width: '80vw', flexDirection: 'column' }}>

          <Typography.Title level={2}> Card CTA'S </Typography.Title>

          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 15, }}>
            {record?.god_set_content?.card_ctas.map((cta) => {
              return <div style={{ padding: '10px', width: 250, border: '1px dashed #000' }}>
                <img src={NEW_CDN_HOST + cta.image_path} alt="" height={100} width={100} />
                <Typography.Paragraph><b>God Id</b>  {cta.god_id}</Typography.Paragraph>
                <Typography.Paragraph><b>Deeplink</b> : {cta.deeplink}</Typography.Paragraph>
              </div>
            })}
          </div>
        </div> : <div style={{ display: 'flex', width: '80vw', flexDirection: 'row', justifyContent: 'space-between' }}>
          {
            record.shubh_mantra_content?.title.map((t, i) => {
              return <div style={{ display: 'flex', width: 200, flexDirection: 'column', alignItems: 'center', border: '1px solid black', flexWrap: 'wrap' }}>
                <img src={NEW_CDN_HOST + record.shubh_mantra_content?.back_ground_image_path[i]} alt="" height={200} width={200} style={{ padding: 2 }} />
                <Typography.Paragraph> <b> Title </b> : {t}</Typography.Paragraph>
                <Typography.Paragraph> <b> Sub Title </b> : {record.shubh_mantra_content?.sub_title[i]}</Typography.Paragraph>
              </div>
            })
          }
        </div>
        }
      </div >
  }
}