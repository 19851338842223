import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Select, InputNumber, FormInstance, Button, Input } from "antd";
import styled from "styled-components";
import { OPERATORS } from "../utils/types";
import { REQUIRED_RULE } from "../../../../../utils/form.validation";
import { useJourneyContext } from "../utils/contants";

interface EventSchemaFormProps {
  form: FormInstance<any>,
  headingBackground?: string
  type: "affirmative" | "fallback"
}

const PropertiesContainer = styled.div`
  border: 1px solid #000;
  position: relative;
  border-radius: 5px;
  margin-bottom: 80px;
  margin-top: 80px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  &:before {
    content: "Event Properties";
    display: block;
    position: absolute;
    margin-left: 20px;
    margin-top: 0px;
    transform: translateY(-50%);
    background: ${(props: { headingBackground?: string }) => props.headingBackground || "#fff"};
    padding: 0px 15px; 
    font-size: 20px;
  }
  &>div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 3%;
    padding-bottom: 3%;
  }
`
const PropertyField = styled.div`
  margin: 0px 2% 2% 2%;
  width: 100%;
  padding: 10px;
  didplay: flex;
  border-radius: 5px;
  border: 1px dashed #000;
`;

const ButtonContainer = styled(Form.Item)`
  position: absolute;
  bottom: 0px;
  margin: 0px;
  left: 30px;
  width: 300px;
  transform: translateY(50%);
`;

const CloseCircleOutlinedStuyled = styled(CloseCircleOutlined)`
  float: right;
  font-size: 20px;
  color: red;
`;

const renderCompressionFeild = (property: {
  data_type: "STRING" | "INT64" | "BOOLEAN",
  options: string[]
}, operator: OPERATORS, fieldName: number) => {
  let field: JSX.Element | undefined = undefined;
  let name = "value_string";

  if (operator === OPERATORS.EXISTS) {
    return null;
  } else {
    if (property.options.length > 0) {
      field = <Select showSearch>
        {property.options.map((option) => {
          return <Select.Option key={option} value={option}>{option}</Select.Option>
        })}
      </Select>
    } else {
      switch (property.data_type) {
        case "STRING":
          field = <Input></Input>
          break;
        case "INT64":
          field = <InputNumber></InputNumber>
          name = "value_int64";
          break;

        default:
          field = <Input></Input>;
          break;
      }
    }
  }

  return <Form.Item name={[fieldName, name]} label="Enter comparison value" >
    {field}
  </Form.Item>
}

const renderOperatorsOptions = (property: {
  data_type: "STRING" | "INT64" | "BOOLEAN",
  options: string[]
}) => {

  switch (property.data_type) {
    case "INT64":
      return <>
        <Select.Option value={OPERATORS.EQUAL}>{OPERATORS.EQUAL}</Select.Option>
        <Select.Option value={OPERATORS.NOT_EQUAL}>{OPERATORS.NOT_EQUAL}</Select.Option>
        <Select.Option value={OPERATORS.EXISTS}>{OPERATORS.EXISTS}</Select.Option>
        <Select.Option value={OPERATORS.GREATER_THAN_OR_EQUAL}>{OPERATORS.GREATER_THAN_OR_EQUAL}</Select.Option>
        <Select.Option value={OPERATORS.LESS_THAN_OR_EQUAL}>{OPERATORS.LESS_THAN_OR_EQUAL}</Select.Option>
      </>
    case "STRING":
      return <>
        <Select.Option value={OPERATORS.EQUAL}>{OPERATORS.EQUAL}</Select.Option>
        <Select.Option value={OPERATORS.NOT_EQUAL}>{OPERATORS.NOT_EQUAL}</Select.Option>
        <Select.Option value={OPERATORS.EXISTS}>{OPERATORS.EXISTS}</Select.Option>
      </>

    case "BOOLEAN":
      return <>
        <Select.Option value={OPERATORS.EQUAL}>{OPERATORS.EQUAL}</Select.Option>
        <Select.Option value={OPERATORS.NOT_EQUAL}>{OPERATORS.NOT_EQUAL}</Select.Option>
        <Select.Option value={OPERATORS.EXISTS}>{OPERATORS.EXISTS}</Select.Option>
      </>
    default:
      return <>
        <Select.Option value={OPERATORS.EQUAL}>{OPERATORS.EQUAL}</Select.Option>
        <Select.Option value={OPERATORS.NOT_EQUAL}>{OPERATORS.NOT_EQUAL}</Select.Option>
        <Select.Option value={OPERATORS.EXISTS}>{OPERATORS.EXISTS}</Select.Option>
      </>
  }

}

const EventSchemaForm = ({ form, headingBackground, type }: EventSchemaFormProps) => {
  const eventName: string = Form.useWatch(["event_schema", "event_name"], form);
  const eventSchemaProperties: string = Form.useWatch(["event_schema", "properties"], form);
  const { eventsAndProperties: NUDGE_EVENT_PROPERTIES, commonEventProperties } = useJourneyContext();
  //@ts-ignore
  const eventProperties = eventName ? { ...NUDGE_EVENT_PROPERTIES[eventName], ...commonEventProperties } : {};
  //@ts-ignore
  return <>
    <Form.Item name={["event_schema", "event_name"]} label="Event name" rules={REQUIRED_RULE}>
      <Select showSearch onChange={() => { form.setFieldValue(["event_schema", "properties"], []) }}>
        {
          Object.keys(NUDGE_EVENT_PROPERTIES).map((event) => {
            return <Select.Option key={event} value={event}>{event}
            </Select.Option>
          })
        }
      </Select>
    </Form.Item>
    <PropertiesContainer headingBackground={headingBackground}>
      <div>
        <Form.List
          name={["event_schema", "properties"]}
          initialValue={[]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <>
                {fields.map((field, index) => (
                  <PropertyField key={field.key}>
                    <CloseCircleOutlinedStuyled
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                    <Form.Item required name={[field.name, "path"]} label="Event property path" rules={REQUIRED_RULE}>
                      <Select showSearch disabled={!eventName}>
                        {/* @ts-ignore */}
                        {eventName && Object.keys(eventProperties).map((property) => {
                          return <Select.Option key={property} value={property}>{property}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item name={[field.name, "operator"]} label="Operator" rules={REQUIRED_RULE}>
                      <Select showSearch>
                        {/* @ts-ignore */}
                        {eventName && eventSchemaProperties[field.name]?.path && renderOperatorsOptions(eventProperties[eventSchemaProperties[field.name]?.path])}
                      </Select>
                    </Form.Item>

                    {/* @ts-ignore */}
                    {eventName && eventSchemaProperties[field.name]?.path && renderCompressionFeild(eventProperties[eventSchemaProperties[field.name]?.path], eventSchemaProperties[field.name]?.operator, field.name)}
                  </PropertyField>
                ))}
              </>
              <ButtonContainer>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Add new property
                </Button>
                <Form.ErrorList errors={errors} />
              </ButtonContainer>
            </>
          )}
        </Form.List>
      </div>
    </PropertiesContainer>
  </>;
};

export default EventSchemaForm;