import { ArrowLeftOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import React from 'react'
import { ContentTag } from '@a4b/api'
import { SortableItemData } from '../Schedule/types'

export interface Props {
  items: SortableItemData[]
}

const Wrapper = styled.div`
  background: white;
  width: 360px;
  height: 600px;
  margin-top: 40px;
`

const Container = styled.div`
  background: white;
  width: 360px;
  height: 520px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  font-family: 'Mukta';
`

const TopBar = styled.div`
  height: 24px;
  width: 100%;
  background: #f18912;
`

const Header = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`

const Thumbnail = styled.div`
  height: 128px;
  display: flex;
  margin-right: 10px;
`

const ThumbnailImage = styled.img`
  background: #d9d9d9;
  border-radius: 12px;
  width: 96px;
  height: 128px;
  object-fit: cover;
`

const Content = styled.div`
  padding: 16px;
  word-break: break-all;
`

const ItemContainer = styled.div`
  padding: 8px 16px;
  display: flex;
`
const ItemText = styled.div`
  font-size: 18px;
  font-weight: bold;
`

export const SchedulePreview: React.FC<Props> = ({ items }) => {

  return (
    <Wrapper>
      <TopBar />
      <Header>
        <ArrowLeftOutlined />
      </Header>
      <Container>
        {
          items.map((item, index) => (
            <ItemContainer key={index}>
              <Thumbnail>
                <ThumbnailImage src={item.imageUrl} />
              </Thumbnail>
              <ItemText>{item.name}</ItemText>
            </ItemContainer>
          ))
        }
      </Container>
    </Wrapper >
  )
}

export default SchedulePreview
