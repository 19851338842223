import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { PujaFilterPayload, PujaFiltersResponse } from './types/puja-filters'

class PujaFiltersApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createPujaFilter = (payload: PujaFilterPayload) =>
    this.api.post(`/gw1/puja/internal/v1/puja-filters`, payload)

  updatePujaFilter = (payload: PujaFilterPayload) =>
    this.api.patch(`/gw1/puja/internal/v1/puja-filters`, payload)

  getPujaFilters = (puja_id?: string) =>
    this.api.get<PujaFiltersResponse>(
      `/gw1/puja/internal/v1/puja-filters?puja_id=${puja_id}`,
    )

  deletePujaFilter = (id: string) =>
    this.api.delete(`/gw1/puja/internal/v1/puja-filters/${id}`)
}

export default PujaFiltersApi
