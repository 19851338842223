import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import adminMenus from '@/utils/menus/adminMenus'
import { Card, Space } from 'antd'

interface Props { }

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  .ant-card-body {
    flex-grow: 1;
  }
  
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const HomepageContainer: React.FC<Props> = () => {
    const { monetisationRoles } = useAppContext()

    const monetisationMenu = adminMenus?.find(menu => menu?.id === 'monetisation');
    const categories = monetisationMenu?.children?.filter(child =>
        ['offerings', 'astrologer', 'wallet', 'epuja', 'campaigns']?.includes(child?.id)
    ) || [];

    return (
        <Container style={{ alignSelf: 'center', marginTop: '80px', marginLeft: '50px', marginRight: '50px' }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px',
                backgroundColor: 'white',
                boxSizing: 'border-box',
            }}>
                <div style={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    fontSize: '24px',
                    fontWeight: 'bold',
                }}>
                    <h3 >Welcome - Monetisation/Offerings</h3>
                </div>

                <div style={{
                    right: '20px',
                    top: '20px',
                    textAlign: 'left',
                    marginLeft: 'auto',
                }}>
                    <h3>Name : {monetisationRoles?.name} ({monetisationRoles?.user_type})</h3>
                    <h3>Email: {monetisationRoles?.email}</h3>
                </div>
            </div>
            
            <Space direction={'vertical'} style={{ width: '100%' }}></Space>
            <BodyContainer>
                {categories?.map(menu => (
                    <div key={menu?.id} style={{ marginBottom: '20px', width: '100%' }}>
                        <h2>{menu?.title}</h2>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            marginBottom: '40px',
                            gap: '10px',
                        }}>
                            {menu?.linkItem?.map(item => (
                                <Link
                                    key={item?.id}
                                    to={item?.path}
                                    style={{
                                        backgroundColor: '#e6f4ff',
                                        color: '#69b1ff',
                                        fontWeight: 'bold',
                                        borderRadius: '3px',
                                        height: '10vh',
                                        width: '15vw',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textDecoration: 'none',
                                        margin: '25px',
                                        transition: 'top 0.5s ease',
                                    }}
                                >
                                    {item?.name}
                                </Link>
                            ))}
                        </div>
                    </div>
                ))}
            </BodyContainer>
        </Container>
    );
}

export default withDefaultLayout(HomepageContainer)
