import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { EventFilters, EventGroup, EventList, EventPatchDefination, EventPayload, GroupPayload } from './types';
import { apiClient } from '../../apiClient';

const modifyEvent = (payload: EventPayload) => {
  const destinations: any = payload.destinations?.length ? {} : null;
  if (payload.destinations?.length) {
    payload.destinations.forEach((destination) => {
      destinations[destination] = { "is_allowed": true }
    })
  }
  payload.destinations = destinations;
  return payload;
}

class Events {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getEvents = async (filter: EventFilters) => {
    return this.api.post<EventList>(`/lex/internal/v1/definition/search?`,
      filter
    )
  }

  getEventProperties = async () => {
    return this.api.get<any>(`gw1/lex/internal/v1/registry`)
  }



  createEvent = async (payload: EventPayload) =>
    this.api.post('/lex/internal/v1/definition', modifyEvent(payload))


  updateEvent = async (payload: EventPayload) =>
    this.api.put('/lex/internal/v1/definition', modifyEvent(payload))


  patchDefination = async (payload: EventPatchDefination) =>
    this.api.patch('/lex/internal/v1/definition', payload)

  getGroups = async () =>
    this.api.get(`lex/internal/v1/group`)

  createGroup = async (payload: GroupPayload) =>
    this.api.post('/lex/internal/v1/group', payload)

  getChangeLog = async (filter: {
    filters: {
      definition_id: string
    }
  }) => {
    return this.api.post<any>(`lex/internal/v1/changelogs`,
      filter
    )
  }

  getTickets = async (filter: {
    filters: {
      definition_id: string
    }
  }) => {
    return this.api.post<any>(`gw1/lex/internal/v1/tickets`,
      filter
    )
  }

  resolveTickets = async (body: any) => {
    return this.api.post<any>(`gw1/lex/internal/v1/resolve-tickets`,
      body
    )
  }

}

export default Events;
