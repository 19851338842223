import React from 'react'
import styled from 'styled-components'

import { PlaylistsCreateEditForm, Props as PlaylistsCreateEditFormProps } from '../../components/MusicContent/PlaylistsCreateEditForm'

const Container = styled.div`
  padding: 40px;
`

export const MusicPlaylistCreateEditScreen: React.FC<PlaylistsCreateEditFormProps> = (props) => {
  return <Container>
    <PlaylistsCreateEditForm {...props} />
  </Container>
}

