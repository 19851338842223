import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import type { Response } from '../../types'
import { apiClient } from '../../apiClient'

class DynamicCohortApi {
    private api: AxiosInstance

    constructor(config: AxiosRequestConfig) {
        this.api = apiClient.create(config)
    }

    getAllActions = () =>
        this.api.get<Response<any>>(
            `/argo/internal/v1/action`,
        )

    getActionTypes = () =>
        this.api.get<Response<any>>(
            `/argo/internal/v1/action/type`,
        )

    postAction = (data: any) =>
        this.api.post<Response<any>>(
            `/argo/internal/v1/action`,
            data,
        )

    putAction = (data: any) =>
        this.api.put<Response<any>>(
            `/argo/internal/v1/action`,
            data,
        )

    patchAction = (id: string, to_status: string) =>
        this.api.patch<Response<any>>(
            `/argo/internal/v1/action`,
            {
                id,
                to_status
            }
        )
}

export default DynamicCohortApi;
