import { useEffect, useState, useCallback } from 'react'
import { useAppContext } from '@/components/AppContext'
//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { CampaignsData } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import ManageCampaignsPage from '@a4b/ui/src/modules/monetisation/organisms/ManageCampaignsPage'
import type { TablePaginationConfig } from 'antd/es/table';
import CreateCampaigns from '../CreateCampaigns'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import { message, Modal } from 'antd';

const ManageCampaigns = () => {
    const { networkInstance } = useAppContext()
    const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

    const [messageApi, contextHolder] = message.useMessage();
    const [createCampaignForm] = useForm()
    const [CampaignList, setCampaignList] = useState<CampaignsData>()
    const [paginationParams, setPaginationParams] =
        useState<TablePaginationConfig>({
            current: 1,
            pageSize: 10,
        })
    const [showCampaignCreation, setShowCampaignCreation] = useState<boolean>(false)
    const [isEditJourney, setIsEditJourney] = useState<boolean>(false)
    const [editCampaign, setEditCampaign] = useState<any>()
    const [showSectionEnableModal, setShowSectionEnableModal] = useState(false)
    const [showSectionDisableModal, setShowSectionDisableModal] = useState(false)
    const [activeStatusUpdate, setActiveStatusUpdate] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const getCampaignList = useCallback(async () => {
        try {
            setIsLoading(true)
            const CampaignListResponse = await monetisationOfferingsApi.campaignsAPi.getCampaignList({
                limit: paginationParams.pageSize,
                offset: Number((paginationParams?.current || 1) - 1) * Number(paginationParams.pageSize),
            })
            setCampaignList(CampaignListResponse?.data?.data)
            setPaginationParams({ ...paginationParams, total: CampaignListResponse?.data?.data?.count || 10 })
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("Error: ", error)
        }
    }, [monetisationOfferingsApi.campaignsAPi, paginationParams?.current])

    useEffect(() => {
        getCampaignList()
    }, [getCampaignList])

    const onItemCreateHandler = () => {
        getCampaignList()
    }

    const closeItemCreationForm = () => {
        setShowCampaignCreation(false)
        isEditJourney && setIsEditJourney(false)
        setEditCampaign(undefined)
    }

    const onCreateNew = () => {
        setShowCampaignCreation(true)
        setIsEditJourney(false)
        setEditCampaign(undefined)
    }

    const onStatusChange = (item: any) => {
        setActiveStatusUpdate(item)
        if (item?.active) {
            setShowSectionDisableModal(true)
        } else {
            setShowSectionEnableModal(true)
        }
    }

    const triggerEdit = async (data: any) => {
        try {
            const CampaignByIdResponse = await monetisationOfferingsApi.campaignsAPi.getCampaignById(data?.id)
            const responseData = CampaignByIdResponse?.data?.data;
            let parseData: any = {
                campaign_id: responseData?.id,
                name: responseData?.name,
                sub_title: responseData?.sub_title,
                start_date: moment(moment(responseData?.start_date).format('YYYY-MM-DD')),
                end_date: moment(moment(responseData?.end_date).format('YYYY-MM-DD')),
                discount_type: responseData?.discount_type,
                max_amount: responseData?.max_amount,
                campaign_type: responseData?.campaign_type,
                value: responseData?.value,
                active: responseData?.active
            }
            setEditCampaign(parseData)
            setIsEditJourney(true)
            setShowCampaignCreation(true)
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPaginationParams({
            ...pagination,
        })
    }

    const onModalOk = async () => {
        message.loading({
            content: 'Updating section status',
            duration: 1,
            key: 'update_section',
        })

        try {
            const CampaignByIdResData = await monetisationOfferingsApi.campaignsAPi.getCampaignById(activeStatusUpdate?.id)
            const responseData = CampaignByIdResData?.data?.data;
            await monetisationOfferingsApi.campaignsAPi.updateCampaignStatus(activeStatusUpdate?.id, { active: !responseData?.active })
            setTimeout(() => {
                getCampaignList()
            }, 500)
            setShowSectionEnableModal(false)
            setShowSectionDisableModal(false)
            message.success({
                content: 'Campaign status updated successfully',
                duration: 2,
                key: 'update_campaign',
            })
        } catch (error) {
            setShowSectionEnableModal(false)
            setShowSectionDisableModal(false)
            message.error({
                content: 'Error while updating store card status',
                duration: 2,
                key: 'update_section',
            })
            console.log(error)
        }
    }

    const onModalCancel = () => {
        setShowSectionEnableModal(false)
        setShowSectionDisableModal(false)
    }

    const handleCreateOrUpdate = (values: any) => {
        const startDate = new Date(values?.start_date)
        const formattedStartDate: string = moment(startDate).format("YYYY-MM-DD")

        const endDate = new Date(values?.end_date)
        const formattedEndDate: string = moment(endDate).format("YYYY-MM-DD")
        const payload: any = {
            name: values?.name,
            sub_title: values?.sub_title,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            value: values?.value,
            campaign_type: values?.campaign_type,
            discount_type: values?.discount_type,
            active: values?.active || false,
        }
        if (values?.discount_type === 'percentage') {
            payload["max_amount"] = values?.max_amount
        }
        if (editCampaign?.campaign_id) {
            payload.id = editCampaign?.campaign_id
        }
        return payload
    }

    const formSubmithandler = async (values: any) => {
        const today = new Date()
        const formattedtodaysDate: any = moment(moment(today).format("YYYY-MM-DD"))
        if (values?.start_date - formattedtodaysDate < 0) {
            messageApi.open({
                type: 'warning',
                content: 'Start date should not be less than today',
            });
        } else if (values?.end_date - values?.start_date < 0) {
            messageApi.open({
                type: 'warning',
                content: 'End date should be greater than start date',
            });
        } else {
            const formattedData: any = handleCreateOrUpdate(values)
            if (isEditJourney) {
                try {
                    await monetisationOfferingsApi.campaignsAPi.updateCampaign(editCampaign?.campaign_id, formattedData)
                    messageApi.open({
                        type: 'success',
                        content: 'Updated Campaign',
                    });
                    closeItemCreationForm()
                    setTimeout(() => {
                        getCampaignList()
                    }, 500)
                } catch (error) {
                    console.log("Error while updating: ", error)
                }
            } else {
                try {
                    await monetisationOfferingsApi.campaignsAPi.createCampaign(formattedData)
                    messageApi.open({
                        type: 'success',
                        content: 'Created Campaign',
                    });
                    closeItemCreationForm()
                    setTimeout(() => {
                        getCampaignList()
                    }, 500)
                } catch (error) {
                    console.log("Error while creating: ", error)
                }
            }
        }
    }

    return (
        <div style={{ padding: '0 40px' }}>
            {contextHolder}
            <ManageCampaignsPage
                CampaignList={CampaignList}
                paginationParams={paginationParams}
                handleTableChange={handleTableChange}
                onCreateNew={onCreateNew}
                onStatusChange={onStatusChange}
                triggerEdit={triggerEdit}
                isLoading={isLoading}
            />
            <CreateCampaigns
                showItemCreation={showCampaignCreation}
                closeItemCreation={closeItemCreationForm}
                createItemCallback={onItemCreateHandler}
                isEditJourney={isEditJourney}
                form={createCampaignForm}
                initialValues={editCampaign}
                formSubmithandler={formSubmithandler}
            />
            <Modal
                title='Activate Campaign'
                visible={showSectionEnableModal}
                onOk={onModalOk}
                onCancel={onModalCancel}
                okText=' Yes, Activate'
            >
                <p>Are you sure to activate this Campaign ?</p>
            </Modal>
            <Modal
                title='Deactivate Campaign'
                visible={showSectionDisableModal}
                onOk={onModalOk}
                onCancel={onModalCancel}
                okText='Yes, Deactivate'
            >
                <p>Are you sure to Deactivate this Campaign ?</p>
            </Modal>
        </div>
    )
}

export default withDefaultLayout(ManageCampaigns)
