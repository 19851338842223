import React, { useEffect, useMemo, useState } from 'react';
import { useMachine } from '@xstate/react';
import { Button, Col, Drawer, Form, message, Row, Space } from 'antd';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import { useAppContext } from '@/components/AppContext';
import Widget from '@/entities/feeds/Widget';
import withDefaultLayout from '@/hoc/WithDefaultLayout';
import getDefaultApiMachineConfig from '@/network/ApiMachine';
import Feeds from '@/network/Feeds/Feeds';
// import {
//   createWidget,
//   deactivateWidget,
//   deleteWidget,
//   editWidget,
//   getWidget,
//   getWidgets,
// } from '@/usecases/views/widgets';

import { STATUS } from '@/utils/enum';
import {
  getFeedsCarnageInstance,
  isCreateRoute,
  masterWidgetOptions,
} from '@/utils/helper';
import { widgetApiMessages } from '@/utils/messages';
import { SelectOptions } from '@/utils/types';
import EmptyData from '../../components/Empty';
import Loading from '../../components/Loading';
import PageLayout from '../../components/PageLayout';
import CreateWidget from './CreateWidget';
import WidgetManageDataTable from './WidgetManageDataTable';
// import { getMasterWidgets } from '@/usecases/views/common';

interface Props { }

const ManageWidgets: React.FunctionComponent<Props> = ({ }: Props) => {
  const { networkInstance, userProfile } = useAppContext();

  const getWidgets = () => {
    let widgetsPromise = new Promise(async (resolve, reject) => {
      try {
        let widgetResponse = await networkInstance.clientWithHeaders.carnage.getWidgets()

        let widgetList = widgetResponse?.data?.data.widgets.map((item: any) => {
          return new Widget(item)
        })
        resolve(widgetList)
      } catch (error: any) {
        reject(error?.response?.data.error || error)
      }
    })
    return widgetsPromise
  }
  const getWidget = (id: string) => {
    let widgetPromise = new Promise(async (resolve, reject) => {
      try {
        let widgetPromise = await networkInstance.clientWithHeaders.carnage.getWidget(id)
        let widget = new Widget(widgetPromise?.data?.data?.widgets[0])
        resolve(widget)
      } catch (error: any) {
        reject(error?.response?.data.error || error)
      }
    })
    return widgetPromise
  }
  const createWidget = (data: any) => {
    let widgetPromise = new Promise(async (resolve, reject) => {
      try {
        let widgetResponse = await networkInstance.clientWithHeaders.carnage.createWidget(data)
        resolve(true)
      } catch (error: any) {
        reject(error?.response?.data.error || error)
      }
    })
    return widgetPromise
  }
  const editWidget = (data: any, id: string) => {
    let widgetEditPromise = new Promise(async (resolve, reject) => {
      try {
        let widgetResponse = await networkInstance.clientWithHeaders.carnage.editWidget(
          data,
          id,
        )
        resolve(true)
      } catch (error: any) {
        reject(error?.response?.data.error || error)
      }
    })
    return widgetEditPromise
  }
  const deleteWidget = (data: any, id: string) => {
    let widgetDeletePromise = new Promise(async (resolve, reject) => {
      try {
        let widgetResponse = await networkInstance.clientWithHeaders.carnage.deleteWidget(
          data,
          id,
        )
        resolve(true)
      } catch (error: any) {
        reject(error?.response?.data.error || error)
      }
    })
    return widgetDeletePromise
  }
  const deactivateWidget = (data: any, id: string) => {
    let widgetDeactivatePromise = new Promise(async (resolve, reject) => {
      try {
        let widgetResponse = await networkInstance.clientWithHeaders.carnage.deactivateWidget(
          data,
          id,
        )
        resolve(true)
      } catch (error: any) {
        reject(error?.response?.data.error || error)
      }
    })
    return widgetDeactivatePromise
  }
  const searchByWidgetCode = (code: string) => {
    let widgetPromise = new Promise(async (resolve, reject) => {
      try {
        let widgetResponse = await networkInstance.clientWithHeaders.carnage.searchByWidgetCode(
          code.toUpperCase(),
        )
        resolve(widgetResponse?.data?.data?.widgets)
      } catch (error: any) {
        reject(error?.response?.data.error || error)
      }
    })
    return widgetPromise
  }

  const getMasterWidgets = () => {
    let masterWidgetPromise = new Promise(async (resolve, reject) => {
      try {
        let masterWidgetResponse =
          await networkInstance.clientWithHeaders.feeds.getWidgetMaster();
        resolve(masterWidgetResponse?.data?.data);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return masterWidgetPromise;
  };

  const getMasterWidgetList = async () => {
    try {
      let widgetListResponse: any = await getMasterWidgets();
      let masterWidgetOption = masterWidgetOptions(
        widgetListResponse?.master_widgets
      );
      setMasterWidgets(masterWidgetOption);
    } catch (error: any) {
      message.error(error?.message);
      console.log(error);
    }
  };
  useEffect(() => {
    getMasterWidgetList();
  }, []);

  const [form] = Form.useForm();
  const location = useLocation();

  const [isDrawerVisible, setDrawerVisibility] = useState(false);
  const [widgetList, setWidgetList] = useState<any>([]);
  const [masterWidgets, setMasterWidgets] = useState<SelectOptions[]>([]);
  const [editWidgetData, setEditWidgetData] = useState<Widget | null>();
  const [isEditJourney, setIsEditJourney] = useState(false);
  const [isLoading, setLoadingStatus] = useState(false);

  const rowSelectionChange = (selectedRowKeys: any) => {
    setRowSelectionConfig({
      ...rowSelectionConfig,
      selectedRowKeys: [...selectedRowKeys],
    });
  };

  const [rowSelectionConfig, setRowSelectionConfig] = useState<any>({
    selectedRowKeys: [],
    onChange: rowSelectionChange,
  });
  const drawerCLoseHandler = () => {
    setDrawerVisibility(false);
  };
  const openCreateDrawer = () => {
    resetHandler();
    setDrawerVisibility(true);
  };
  const getWidgetList = async () => {
    try {
      let widgetListResponse: any = await getWidgets();
      let updatedList = widgetListResponse.map((item: any) => ({
        ...item,
        key: item.id,
      }));
      setLoadingStatus(false);
      setWidgetList([...updatedList]);
    } catch (error) {
      message.error(widgetApiMessages.widgetListGetError);
      console.log(widgetApiMessages.widgetListGetError, error);
    }
  };
  useEffect(() => {
    isCreateRoute(location.pathname) && openCreateDrawer();
    setLoadingStatus(true);
    getWidgetList();
  }, []);

  const resetHandler = () => {
    form.resetFields();
  };

  const formSubmitHandler = () => {
    form.submit();
  };
  let createNewHandler = () => {
    openCreateDrawer();
  };

  const formDataCaptureHandler = (formData: any) => {
    createWidgetCall(formData);
  };
  const createWidgetCall = async (data: any) => {
    try {
      if (isEditJourney) {
        let widgetData = {
          widget: {
            ...data,
            code: data.code.toUpperCase(),
            updated_by: userProfile?.email,
          },
        };
        let updateResponse = await editWidget(widgetData, editWidgetData?.id as string);
        if (updateResponse) {
          message.success(widgetApiMessages.widgetUpdateSuccess);
          setDrawerVisibility(false);
          resetFieldsAfterEdit();
          getWidgetList();
        }
      } else {
        let widgetData = {
          widget: {
            ...data,
            code: data.code.toUpperCase(),
            created_by: userProfile?.email,
          },
        };

        let createResponse = await createWidget(widgetData);
        if (createResponse) {
          message.success(widgetApiMessages.widgetCreateSuccess);
          setDrawerVisibility(false);
          resetFieldsAfterEdit();
          getWidgetList();
        }
      }
    } catch (error: any) {
      if (isEditJourney) {
        let errorMessage = error?.message || 'Something went wrong.';
        message.error(errorMessage);
      } else {
        if (error?.message === 'err-widget-code-already-exists') {
          message.error(widgetApiMessages.widgetExistError);
        } else {
          let errorMessage = error?.message || 'Something went wrong.';
          message.error(errorMessage);
        }
      }
      console.log(widgetApiMessages.widgetCreateError, error);
    }
  };
  const deleteWidgetHandler = async (record: any) => {
    let payload = {
      widget: {
        updated_by: userProfile?.email,
      },
    };
    try {
      let deleteWidgetResponse: any = await deleteWidget(payload, record.id);
      if (deleteWidgetResponse) {
        getWidgetList();
        message.success(widgetApiMessages.widgetDeleteSuccess);
      }
    } catch (error: any) {
      let errorMessage = error?.message || 'Something went wrong.';
      message.error(errorMessage);

      console.log(widgetApiMessages.widgetDeleteError, error);
    }
  };
  const EditWidgetHandler = async (record: Widget) => {
    setIsEditJourney(true);
    try {
      let id = record.id || '';
      let widget: any = await getWidget(id);
      setEditWidgetData({ ...widget });
    } catch (error: any) {
      let errorMessage = error?.message || 'Something went wrong.';
      message.error(errorMessage);

      setDrawerVisibility(false);
      console.log(widgetApiMessages.widgetGetErrorForEdit, error);
    }

    setDrawerVisibility(true);
  };
  const disableWidget = async (record: Widget) => {
    try {
      let id = record.id || '';
      let payload = {
        widget: {
          updated_by: userProfile?.email,
        },
      };
      let widget: any = await deactivateWidget(payload, id);
      if (widget) message.success(widgetApiMessages.widgetDeactivatedSuccess);
    } catch (error: any) {
      let errorMessage =
        error?.message ||
        widgetApiMessages.widgetDisableError ||
        'Something went wrong.';
      message.error(errorMessage);

      console.log(widgetApiMessages.widgetDisableError, error);
    }
  };
  const resetFieldsAfterEdit = () => {
    if (!isDrawerVisible) {
      form.resetFields();
      setEditWidgetData(null);
      setIsEditJourney(false);
    }
  };
  const actionMenuClickHandler = (key: string, record: any) => {
    switch (key) {
      case 'clone':
        //cloneScheduleHandler(record);
        return;
      case 'delete':
        deleteWidgetHandler(record);
        return;
      case 'edit':
        EditWidgetHandler(record);
        return;
      case 'toggleStatus':
        disableWidget(record);
        return;
    }
  };

  useEffect(() => {
    resetFieldsAfterEdit();
  }, [isDrawerVisible]);
  const CreateNewWidgetButton = () => {
    return (
      <Button
        key="create-new"
        type="primary"
        htmlType="button"
        onClick={createNewHandler}
        icon={<PlusOutlined />}
      >
        Create Widget
      </Button>
    );
  };
  const getDrawerActionColtrolls = () => {
    return (
      <Space>
        {!isEditJourney && (
          <Button
            key="reset"
            type="dashed"
            htmlType="button"
            onClick={resetHandler}
            icon={<ReloadOutlined />}
          >
            Reset
          </Button>
        )}
        <Button
          key="submit"
          type="primary"
          htmlType="button"
          onClick={formSubmitHandler}
        >
          {isEditJourney ? 'Update' : 'Create'}
        </Button>
      </Space>
    );
  };

  return (
    <PageLayout
      title="Manage Widgets"
      showBackIcon={false}
      backButtonClickHandler={() => { }}
      createNewHandler={openCreateDrawer}
      extra={[CreateNewWidgetButton()]}
    >
      <Drawer
        title="Create Widget"
        width={640}
        onClose={drawerCLoseHandler}
        visible={isDrawerVisible}
        bodyStyle={{ paddingBottom: 40 }}
        footerStyle={{
          display: 'flex',
          justifyContent: 'end',
        }}
        extra={getDrawerActionColtrolls()}
        footer={[]}
        destroyOnClose={true}
      >
        <CreateWidget
          form={form}
          finishHandler={formDataCaptureHandler}
          initialValues={editWidgetData}
          masterWidgetList={masterWidgets}
        />
      </Drawer>
      {isLoading ? (
        <Loading />
      ) : (
        <Row>
          <Col span={24}>
            {widgetList.length ? (
              <div>
                <div
                  style={{
                    height: '10px',
                    display: 'flex',
                    backgroundColor: '#fff ',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {/* <div>Filters Goes here</div> */}
                </div>
                <WidgetManageDataTable
                  dataSource={widgetList}
                  actionMenuClickHandler={actionMenuClickHandler}
                />
              </div>
            ) : (
              <EmptyData
                description="No,Widgets created yet!"
                actionTitle="Create Widget"
                actionHandler={openCreateDrawer}
              />
            )}
          </Col>
        </Row>
      )}
    </PageLayout>
  );
};

export default withDefaultLayout(ManageWidgets);
