import React, { useMemo } from 'react';
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'antd/dist/antd.css';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { AudioTag } from '@a4b/api/src/modules/Content/Audio/types';
import { SocialScreenContainer } from '@a4b/ui/src/modules/admin/screens';
import { Button, Card, Space, Typography, Image, Switch, Radio, Form, Select, Upload } from 'antd';
import { CheckSquareFilled, UploadOutlined } from '@ant-design/icons';
import Input from 'antd/lib/input/Input';
import { Community, CommunityAuthor } from '@/../../../packages/a4b-api/src/modules/Social/types';
import { UPLOAD_CONTENT_URL } from '@/utils/constants';
import { openNotification } from '@/utils';
import { v4 as uuidv4 } from 'uuid'



const { Text, Title, Link } = Typography;

const normFile = (e: any) => {
  console.log({ e })
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

const defaultPagination = {
  current: 1,
  pageSize: 50,
  total: -1
}


// const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
//   notification[type]({
//     message: title,
//     description
//   });
// };


const { Option } = Select;

const SocialAdmins = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const navigate = useNavigate();
  const { networkInstance, userProfile } = useAppContext();
  const [admins, setAdmins] = useState<CommunityAuthor[]>([]);
  const [communities, setCommunities] = useState<Community[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
  const [form] = Form.useForm();


  const getDataForUpload = useCallback(() => {
    const ref_content_code = `social_${uuidv4()}`
    return {
      ref_content_code,
      media_type: 'IMAGE',
    }
  }, [])

  const loadCommunityAuthors = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.communityApi.getAuthors();
      console.log(res);
      setAdmins(res.data.data.authors);
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.communityApi])

  const loadCommunities = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.communityApi.getCommunities();
      setCommunities(res.data.data.communities);
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.communityApi])

  useEffect(() => {
    loadCommunityAuthors();
    loadCommunities();
  }, [loadCommunities, loadCommunityAuthors])



  const content = useMemo(() => <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
    {
      admins.map((a) =>
        <div style={{ display: 'flex', width: '300px', margin: '20px', flexDirection: 'column', border: '1px solid #c8c4c4', padding: '10px' }}>
          <div style={{ display: 'flex', marginBottom: '30px', }}>
            <Image
              width={50}
              height={50}
              style={{ borderRadius: '100%' }}
              src={a.profile_image_url}
            /> <div style={{ display: 'flex', marginLeft: '10px', flexDirection: 'column' }}>
              <Text > {a.name} </Text>
              <Text disabled> {1} Communities </Text>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div><Switch checked={true} disabled /> <Text disabled>{true ? 'Active' : 'Disactive'}</Text></div>
            {/* <Button type='link'> edit </Button> */}
          </div>
        </div>
      )
    }
  </div>, [admins])

  const createAuthor = useCallback(async (values) => {
    const payload = {
      name: values.name,
      profile_image_id: values.profile_image_id[0].response.data.content.u_content_id,
      profile_image_path: values.profile_image_id[0].response.data.content.s3_path,
      community_id: values.community_id
    }
    try {
      await networkInstance.clientWithHeaders.communityApi.createAuthor(payload)
      openNotification('success', { message: 'Community admin created successfully!' })
      form.resetFields();
      loadCommunityAuthors();
    } catch (error: any) {
      openNotification('error', { message: 'Community admin creation failed!', description: error.toString(), })
    }
  }, [form, loadCommunityAuthors, networkInstance.clientWithHeaders.communityApi])

  const drawerContent = useMemo(() => {
    return <Form
      form={form}
      layout="vertical"
      onFinish={createAuthor}
    //  initialValues={{ requiredMarkValue: requiredMark }}
    //  onValuesChange={onRequiredTypeChange}
    //  requiredMark={requiredMark}
    >
      <Form.Item
        label=""
        name="profile_image_id"
        valuePropName='fileList'
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Profile Picture required' }]}
      >
        <Upload
          action={UPLOAD_CONTENT_URL}
          accept={'image/*'}
          data={() => getDataForUpload()}
          name='input_file'
          listType='picture'
          multiple={false}
        >
          <Button icon={<UploadOutlined />}>Add profile photo</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="Name of admin"
        name="name"
        rules={[{ required: true, message: 'Please input name of admin!', max: 70 }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Communities assigned"
        name="community_id"
        rules={[{ required: true, message: 'Community required!' }]}
      >
        <Select>
          {
            communities.map((community) => {
              return <Option value={community.id} >{community.local_name}</Option>
            })
          }
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  }, [communities, createAuthor, form, getDataForUpload])
  return (
    <SocialScreenContainer
      title={'Community admins'}
      extra={<Button onClick={() => { setIsDrawerOpen(true); }}> Create new admin </Button>}
      content={content}
      drawerProps={{ title: 'Create new admin', visible: isDrawerOpen, onClose: () => { setIsDrawerOpen(false); } }}
      drawerContent={drawerContent}
    />
  )
}

export default withDefaultLayout(SocialAdmins)

