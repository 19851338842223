import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { apiClient } from '../../apiClient'

class LanguagesApi {
  api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getLanguages = () =>
    this.api.get(`/yoda/internal/v1/languages`, {
      transformRequest: (data, headers) => {
        delete headers['x-afb-app-ver']
        //delete headers['X-AFB-R-UID']
        delete headers['x-afb-platform']
        delete headers['x-afb-device-id']
        return JSON.stringify(data)
      },
    })
}

export default LanguagesApi
