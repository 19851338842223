import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { PageList, Response, UpdatePage } from './types'
import { apiClient } from '../../../apiClient'

class PagesApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getPages = () => this.api.get<Response<PageList>>(`gw1/titan/internal/v1/pages`)

  updatePage = (pageId: string, payload: UpdatePage) =>
    this.api.put(`gw1/titan/internal/v1/pages/${pageId}`, payload)

  getPageById = (pageId: string) =>
    this.api.get(`gw1/titan/internal/v1/pages/${pageId}`)
}

export default PagesApi
