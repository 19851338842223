import { AxiosRequestConfig } from 'axios'

//api
import AudioApi from './Audio'
import LibraryApi from './Library/Library'
import ScheduleApi from './Library/Schedule'
import TagApi from './Library/Tag'
import Rashifal from './Rashifal'
import TempleApi from './Temples'

class ContentApi {
  tag: TagApi
  library: LibraryApi
  schedule: ScheduleApi
  audio: AudioApi
  rashifal: Rashifal
  temples: TempleApi

  constructor(config: AxiosRequestConfig) {
    this.tag = new TagApi(config)
    this.library = new LibraryApi(config)
    this.schedule = new ScheduleApi(config)
    this.audio = new AudioApi(config)
    this.rashifal = new Rashifal(config)
    this.temples = new TempleApi(config)
  }
}

export default ContentApi
