import { Button, Card, Space } from 'antd'
import styled from 'styled-components'

import React from 'react'

//components
import {
  ContentListing,
  Props as ContentListingProps,
} from '../../components/LibraryContent/Listing'
import {
  LibraryContentFilter,
  Props as LibraryContentFilterProps,
} from '../../components/LibraryContent/ListingFilter'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends ContentListingProps, LibraryContentFilterProps {
  title: string
  onAddNewContentClicked: () => void
}

const Container = styled.div`
  padding: 40px;
`

export const ContentList: React.FC<Props> = ({
  loading,
  tags,
  data,
  title,
  total,
  filter,
  onPageChanged,
  onTagSearch,
  onEditContentClicked,
  onSendToQCClicked,
  onViewContentClicked,
  onApplyFilterClicked,
  onAddNewContentClicked,
  onDeleteContentClicked,
  onSendToQCDraft
}) => {
  return (
    <Container>
      <Card
        title={title}
        extra={
          <>
            <Space>
              <Button onClick={onAddNewContentClicked} type='primary'>
                Create Content
              </Button>
            </Space>
          </>
        }
      >
        <Space direction='vertical' size='middle' style={{ width: '100%' }}>
          <LibraryContentFilter
            filter={filter}
            tags={tags}
            onTagSearch={onTagSearch}
            onApplyFilterClicked={onApplyFilterClicked}
          />
          <ContentListing
            loading={loading}
            data={data}
            total={total}
            onPageChanged={onPageChanged}
            onEditContentClicked={onEditContentClicked}
            onSendToQCClicked={onSendToQCClicked}
            onViewContentClicked={onViewContentClicked}
            onDeleteContentClicked={onDeleteContentClicked}
            onSendToQCDraft={onSendToQCDraft}
          />
        </Space>
      </Card>
    </Container>
  )
}
