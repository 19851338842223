import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { CreateTemple, GetTemplesListResp, Response, TempleData } from './types'
import { apiClient } from '../../../apiClient'
import { TemplesList } from './types'

class TemplesApi {
    private api: AxiosInstance

    constructor(config: AxiosRequestConfig) {
        this.api = apiClient.create(config)
    }

    CreateTemple = (payload: CreateTemple) => {
        this.api.post<Response<any>>(
            `/gw2/yoda/internal/v1/temples`,
            payload,
        )
    }

    UpdateTemple = (payload: CreateTemple, temple_id: string) => {
        this.api.put<Response<any>>(
            `/gw2/yoda/internal/v1/temples/${temple_id}`,
            payload,
        )
    }

    GetTempleList = async (): Promise<Response<GetTemplesListResp>> =>
        this.api.get<GetTemplesListResp>(
            `/gw2/yoda/internal/v1/temples`,
        )


    GetTempleById = (temple_id: string) =>
        this.api.get<Response<TempleData>>(
            `/gw2/yoda/internal/v1/temples/${temple_id}`,
        )

}

export default TemplesApi