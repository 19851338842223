//context
import { useAppContext } from '@/components/AppContext'
//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import { message, Modal } from 'antd'
import type { TablePaginationConfig } from 'antd/es/table'

//organisms
//hooks
//types
import React, { useCallback, useEffect, useState } from 'react'

import ManageStoreCardPage from '@a4b/ui/src/modules/monetisation/puja/organisms/ManageStoreCard'

import {
  ManageStoreCard,
  StoreCard
} from '@a4b/api/src/modules/Monetisation/Offerings/types'

import CreateStoreCard from '../../components/store-cards/CreateStoreCard'
import { A4B_APPS } from '@/utils/constants'
interface TabProps {
  currentTab: string
}
const ManageStoreCardScreen: React.FC<TabProps> = ({ currentTab }) => {
  const { networkInstance, app } = useAppContext()
  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi


  const [showItemCreation, setShowItemCreation] = React.useState(false)
  const [isEditJourney, setIsEditJourney] = React.useState(false)
  const [showStoreEnableModal, setShowStoreEnableModal] = useState(false)
  const [showDeleteStoreModal, setDeleteStoreModal] = useState(false)
  const [showStoreDisableModal, setShowStoreDisableModal] = useState(false)
  const [totalRecordsCount, setTotalRecordsCount] = useState(0)
  const [paginationParams, setPaginationParams] =
    useState<TablePaginationConfig>({
      current: 1,
      pageSize: 10,
    })

  const [storeCard, setStoreCard] = useState<StoreCard>()
  const [deleteStoreCard, setDeleteStoreCard] = useState<StoreCard>()
  const [storeCardForStatusUpdate, setStoreCardForStatusUpdate] =
    useState<StoreCard>()
  const [storeCards, setStoreCards] = useState<ManageStoreCard[]>([])
  const rowSelectionChange = (selectedRowKeys: any) => {
    setRowSelectionConfig({
      ...rowSelectionConfig,
      selectedRowKeys: [...selectedRowKeys],
    })
  }

  const [rowSelectionConfig, setRowSelectionConfig] = useState<any>({
    selectedRowKeys: [],
    onChange: rowSelectionChange,
  })

  const getStoreCards = useCallback(async (paginationParams: any) => {
    try {
      const storeCardResponse = await monetisationPujaApi.storesApi.getStoreCards({
        limit: paginationParams.pageSize,
        offset:
          Number((paginationParams?.current || 1) - 1) * Number(paginationParams.pageSize),
      })
      setStoreCards(storeCardResponse?.data?.data?.store_cards)
      setPaginationParams({ ...paginationParams, total: storeCardResponse?.data?.data?.count })
    } catch (error) {
      console.log(error)
      message.error({
        content: 'Error while fetching store card list.',
        duration: 2,
      })
    }
  }, [monetisationPujaApi.storesApi])

  useEffect(() => {
    if (currentTab !== "store_cards") {
      return;
    }
    getStoreCards(paginationParams)
  }, [getStoreCards, currentTab])

  const onCreateNew = () => {
    setShowItemCreation(true)
    setIsEditJourney(false)
    setStoreCard(undefined)
  }

  const onFinishFailed = (error: any) => {
    //console.log('Item Creation Form Error==>', error)
  }
  const showItemForm = () => {
    setShowItemCreation(true)
  }
  const closeItemCreationForm = () => {
    setShowItemCreation(false)
    isEditJourney && setIsEditJourney(false)
    setStoreCard(undefined)
    setTimeout(() => {
      getStoreCards(paginationParams)
    }, 1000)
  }
  const onStatusChange = async (storeCard: StoreCard) => {
    setStoreCardForStatusUpdate(storeCard)
    if (storeCard?.active) {
      setShowStoreDisableModal(true)
    } else {
      setShowStoreEnableModal(true)
    }
  }

  const triggerEdit = async (storeCard: StoreCard) => {
    try {
      const storeCardResponse =
        await monetisationPujaApi.storesApi.getStoreCardById(storeCard?.id || '')
      setStoreCard(storeCardResponse?.data?.data)
    } catch (error) {
      console.log(error)
      message.error({
        content: 'Error while fetching store card list.',
        duration: 2,
      })
    }
    setIsEditJourney(true)
    setShowItemCreation(true)
  }

  const triggerDelete = async (storeCard: StoreCard) => {
    setDeleteStoreModal(true)
    setDeleteStoreCard(storeCard)
  }

  const handleStoreDelete = async () => {
    try {
      await monetisationPujaApi.storesApi.deleteStoreCard(deleteStoreCard?.id || '')
      message.success({
        content: 'Store card deleted successfully.',
        duration: 2,
      })
      getStoreCards(paginationParams)
    } catch (error) {
      console.log(error)
      message.error({
        content: 'Error while deleting store card.',
        duration: 2,
      })
    }
    setDeleteStoreModal(false)
  }
  const onItemCreateHandler = () => {
    getStoreCards(paginationParams)
  }

  const onModalOk = async () => {
    message.loading({
      content: 'Updating store card status',
      duration: 1,
      key: 'update_status',
    })

    try {
      await monetisationPujaApi.storesApi.updateStatusStoreCard(
        storeCardForStatusUpdate?.id || '',
        { active: !storeCardForStatusUpdate?.active, id: storeCardForStatusUpdate?.id },
      )
      setShowStoreEnableModal(false)
      setShowStoreDisableModal(false)
      message.success({
        content: 'Store card status updated sucessfully',
        duration: 3,
        key: 'update_status',
      })
      getStoreCards(paginationParams)
    } catch (error) {
      setShowStoreEnableModal(false)
      setShowStoreDisableModal(false)
      message.error({
        content: 'Error while updating store card status',
        duration: 2,
        key: 'update_status',
      })

      console.log(error)
    }
  }

  const onModalCancel = () => {
    setShowStoreEnableModal(false)
    setShowStoreDisableModal(false)
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    console.log("pagination:", pagination)
    setPaginationParams({
      ...pagination,
    })
    getStoreCards({ ...paginationParams, ...pagination })
  }
  return (
    <div style={{ width: '100%' }}>

      <ManageStoreCardPage
        onCreateNew={onCreateNew}
        onStatusChange={onStatusChange}
        triggerEdit={triggerEdit}
        triggerDelete={triggerDelete}
        dataSource={storeCards}
        rowSelectionConfig={rowSelectionConfig}
        totalRecordsCount={totalRecordsCount}
        paginationParams={paginationParams}
        handleTableChange={handleTableChange}
      />
      {
        <CreateStoreCard
          showItemCreation={showItemCreation}
          closeItemCreation={closeItemCreationForm}
          createItemCallback={onItemCreateHandler}
          UpdateItemCallback={onFinishFailed}
          isEditJourney={isEditJourney}
          formData={storeCard}
        />
      }
      <Modal
        title='Activate Store card'
        visible={showStoreEnableModal}
        onOk={onModalOk}
        onCancel={onModalCancel}
        okText=' Yes, Activate'
      >
        <p>Are you sure to activate this store card ?</p>
      </Modal>
      <Modal
        title='Deactivate Store card'
        visible={showStoreDisableModal}
        onOk={onModalOk}
        onCancel={onModalCancel}
        okText='Yes, Deactivate'
      >
        <p>Are you sure to Deactivate this store card ?</p>
      </Modal>
      <Modal
        title='Delete Store card'
        visible={showDeleteStoreModal}
        onOk={() => handleStoreDelete()}
        onCancel={() => { setDeleteStoreModal(false) }}
        okText=' Yes, Delete'
      >
        <p>Are you sure to delete this store card ?</p>
      </Modal>
    </div>
  )
}

export default ManageStoreCardScreen
