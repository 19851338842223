import { Shrine } from "@/../../../packages/a4b-api/src/modules/Temple/types";
import { getPathFormFile, getTypeFormFile } from "@a4b/ui/src/modules/admin/components/FileUpload";
import { useAppContext } from "@/components/AppContext";
import { openErrorNotification, openNotification } from "@/utils";
import { Form } from "antd";
import { useCallback, useReducer } from "react";
import { SUPPORTED_LANGUAGES_LIST } from "@/utils/constants";
import { TS_GENERICS } from "@/utils/ts.generics";
import GodFormFields from "./god-form-fields";

export interface GodFormProps {
  loadGods: () => Promise<void>
  handleDrawerClose: () => void
  editGod?: Shrine.God
}

enum effects {
}

export const initialState = {
  isCodeValid: false,
  freezeCodeFeild: false,
  freezeLanguageFeild: false,
  supportedlanguages: SUPPORTED_LANGUAGES_LIST,
  effects: Object.values(effects)
};

export enum types {
  SET_CODE_VALID = 'SET_CODE_VALID',
  SET_FREEZE_CODE_FEILD = 'TOOGLE_FREEZE_CODE_FEILD',
  SET_FREEZE_LANGUAGE_FEILD = 'TOGGLE_FREEZE_LANGUAGE_FEILD',
  UPDATE_SUPPPORTED_LANGUAGES = 'UPDATE_SUPPPORTED_LANGUAGES',
  RESET_REDUCER = 'RESET_REDUCER'
}

export type actionTypes = TS_GENERICS.REDUCER_ACTION<types.SET_CODE_VALID, boolean>
  | TS_GENERICS.REDUCER_ACTION<types.SET_FREEZE_CODE_FEILD, boolean>
  | TS_GENERICS.REDUCER_ACTION<types.SET_FREEZE_LANGUAGE_FEILD, boolean>
  | TS_GENERICS.REDUCER_ACTION<types.UPDATE_SUPPPORTED_LANGUAGES, [string]>
  | TS_GENERICS.REDUCER_ACTION<types.RESET_REDUCER, undefined>


function reducer(state: typeof initialState, action: actionTypes): typeof initialState {
  switch (action.type) {
    case types.SET_CODE_VALID:
      return { ...state, isCodeValid: action.payload };
    case types.SET_FREEZE_CODE_FEILD:
      return { ...state, freezeCodeFeild: action.payload }
    case types.SET_FREEZE_LANGUAGE_FEILD:
      return { ...state, freezeLanguageFeild: action.payload }
    case types.UPDATE_SUPPPORTED_LANGUAGES:
      return { ...state, supportedlanguages: SUPPORTED_LANGUAGES_LIST.filter(language => !action.payload.includes(language.value)) }
    case types.RESET_REDUCER:
      return initialState;
    default:
      throw new Error();
  }
}

const GodForm = ({ editGod, loadGods, handleDrawerClose }: GodFormProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { networkInstance } = useAppContext();
  const [form] = Form.useForm();

  const createGod = useCallback(async (values) => {
    const valuesCopy = JSON.parse(JSON.stringify(values));
    const payload: any = {
      code: valuesCopy.code,
      thumbnails: {
        large: {
          path: getPathFormFile(valuesCopy.largeThumbnail),
          type: getTypeFormFile(valuesCopy.largeThumbnail)
        },
        mini: {
          path: getPathFormFile(valuesCopy.miniThumbnail),
          type: getTypeFormFile(valuesCopy.miniThumbnail)
        },
        small_round: {
          path: getPathFormFile(valuesCopy.smallRoundThumbnail),
          type: getTypeFormFile(valuesCopy.smallRoundThumbnail)
        },
        small_square: {
          path: getPathFormFile(valuesCopy.smallSquareThumbnail),
          type: getTypeFormFile(valuesCopy.smallSquareThumbnail)
        },
      },
      languages: valuesCopy.languages.map((language: any) => language.language_code.value),
      festive_special: values.displayStatus === 'festive_special',
      position: valuesCopy.position,
      pre_selected: valuesCopy.displayStatus === 'pre_selected',
      loader_text: values.loader_text
    }
    try {
      await networkInstance.clientWithHeaders.shrineApi.createGod(payload)
      openNotification('success', { message: 'God created successfully!' })
      form.resetFields();
      dispatch({ type: types.RESET_REDUCER, payload: undefined })
      loadGods();
      handleDrawerClose();
    } catch (error: any) {
      openNotification('error', { message: 'God creation failed!', description: error.toString(), })
    }
  }, [form, handleDrawerClose, loadGods, networkInstance.clientWithHeaders.shrineApi])

  const updateGod = useCallback(async (values) => {
    const valuesCopy = JSON.parse(JSON.stringify(values));
    if (editGod?.id === undefined) return null;
    const payload = {
      code: valuesCopy.code,
      thumbnails: {
        large: {
          path: getPathFormFile(valuesCopy.largeThumbnail),
          type: getTypeFormFile(valuesCopy.largeThumbnail)
        },
        mini: {
          path: getPathFormFile(valuesCopy.miniThumbnail),
          type: getTypeFormFile(valuesCopy.miniThumbnail)
        },
        small_round: {
          path: getPathFormFile(valuesCopy.smallRoundThumbnail),
          type: getTypeFormFile(valuesCopy.smallRoundThumbnail)
        },
        small_square: {
          path: getPathFormFile(valuesCopy.smallSquareThumbnail),
          type: getTypeFormFile(valuesCopy.smallSquareThumbnail)
        },
      },
      festive_special: values.displayStatus === 'festive_special',
      position: values.position,
      pre_selected: values.displayStatus === 'pre_selected',
      loader_text: values.loader_text
    }

    try {
      await networkInstance.clientWithHeaders.shrineApi.updateGod(editGod?.id, payload)
      openNotification('success', { message: 'God updated successfully!' })
      form.resetFields();
      dispatch({ type: types.RESET_REDUCER, payload: undefined });
      loadGods();
      handleDrawerClose();
    } catch (error: any) {
      openNotification('error', { message: 'God updation failed!', description: error.toString(), })
      openErrorNotification(error);
    }
  }, [editGod?.id, form, handleDrawerClose, loadGods, networkInstance.clientWithHeaders.shrineApi])

  return <Form
    form={form}
    onFinish={editGod ? updateGod : createGod}
    layout="vertical"
  >
    <GodFormFields form={form} editGod={editGod!} state={state} dispatch={dispatch} types={types} />
  </Form >
}

export default GodForm;