import React, { useRef } from 'react';
import { Row, Col, Tag, Button, Typography, Spin, Empty } from 'antd';
import styled from 'styled-components';
import { Filter } from '@a4b/api/src/modules/Monetisation/Puja/types/filters';
import { LoadingOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { parseFilterData } from '../../utils/helper';
const { Title } = Typography

const Container = styled.div<{ active: boolean }>`
  padding: 10px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: 1px solid ${({ active }) => (active ? '#1890ff' : '#bfbfbf')};
  ${({ active }) => active && 'box-shadow: 0 0 10px rgba(24, 144, 255, 0.6);'}
  .ant-card-body {
    flex-grow: 1;
  }
  &:hover {
    cursor: pointer;
    border-color: #1890ff;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContentWrapper = styled(Title)`
  margin-bottom: 0 !important;
  margin-top: 0;
`

const SubTitleWrapper = styled.span`
  color: #434343;
`;

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`;

const Anchor = styled.div`
  margin-bottom: 20px;
  display: block;
`;

interface FiltersListProps {
    category: { id: string; name: string };
    updateCurrentFilter: (filter: Filter | undefined) => void;
    activeFilterId: string | null
    setActiveFilterId: React.Dispatch<React.SetStateAction<string | null>>
    filters: Filter[]
    isLoadingFilters: boolean
}

const FiltersList: React.FC<FiltersListProps> = ({ category, filters, isLoadingFilters, updateCurrentFilter, activeFilterId, setActiveFilterId }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const containerRef = useRef<HTMLDivElement>(null)

    return (
        <div ref={containerRef} style={{ display: 'flex', width: '100%' }}>
            <Col span={24} style={{ width: '100%' }}>
                {
                    isLoadingFilters ?
                        <Row justify='center' style={{ width: '100%', alignItems: 'center', height: '50vh' }}>
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
                        </Row>
                        :
                        filters?.length > 0 ?
                            filters?.map((filter: any) => (
                                <Anchor key={filter.id} onClick={() => {
                                    const data: any = parseFilterData(filter)
                                    const newSearchParams = new URLSearchParams(searchParams.toString())
                                    newSearchParams.set('edit-filter', 'true')
                                    setSearchParams(newSearchParams)
                                    setActiveFilterId(filter.id)
                                    updateCurrentFilter(data)
                                }
                                }>
                                    <Container active={filter.id === activeFilterId}>
                                        <Row style={{ width: '100%', alignItems: 'center' }} justify='space-between'>
                                            <Col>
                                                <InfoWrapper>
                                                    <Col style={{ alignContent: 'center', alignItems: 'center', height: '100%' }}>
                                                        <ContentWrapper style={{ fontSize: '16px' }}>{filter.name}</ContentWrapper>
                                                        <SubTitleWrapper>{filter.slug}</SubTitleWrapper>
                                                    </Col>
                                                </InfoWrapper>
                                            </Col>
                                            {/* <Col>
                                                <Tag color='blue'>{filter.id}</Tag>
                                            </Col> */}
                                            <Col>
                                                <LabelRightAlign>
                                                    <Button type='text'> &rarr;</Button>
                                                </LabelRightAlign>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Anchor>
                            )) :
                            <Empty description="No Filters available" />
                }
            </Col>
        </div>
    );
};

export default React.memo(FiltersList);
