import { throwError } from '../../../utils/helper';

type Item = {
  title: string;
  icon_attachment_id: string;
  cta_action_type: string;
  cta_redirection_type?: string;
  r_song_id?: string;
  song_of_the_day?: boolean;
  r_article_id?: string;
  r_god_id?: number;
  video_attachment_id?: string;
  video_attachment_type?: string; //temp valiable
};
class ActionList {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items: Item[] = [];

  getSerializedData = (widgetFormData: ActionList) => {
    let items = widgetFormData?.items.map((item: Item): Item => {
      delete item?.video_attachment_type;
      if (item.song_of_the_day) delete item.r_song_id;
      if (!item.song_of_the_day) delete item.song_of_the_day;
      if (item?.r_god_id === 0) delete item.r_god_id;
      return {
        ...item,
      };
    });
    widgetFormData.items = items;
    return widgetFormData;
  };
  getDeSerializedData = (actionFormData: ActionList) => {
    return actionFormData;
  };

  titleValidator = async (rule: any, value: any) => {
    if (!value) throwError('Title required!');
  };
  iconCodeValidator = async (rule: any, value: any) => {
    if (!value) throwError('Icon Code required!');
  };
  ctaActionValidator = async (rule: any, value: any) => {
    if (!value) throwError('CTA Action Type required!');
  };
  songIdValidator = async (rule: any, value: any) => {
    if (!value) throwError('Song ID required!');
  };
  articleIdValidator = async (rule: any, value: any) => {
    if (!value) throwError('Article ID required!');
  };
  redirectionTypeValidator = async (rule: any, value: any) => {
    if (!value) throwError('Redirection Type required!');
  };

  videoAttachmentTypeValidator = async (rule: any, value: any) => {
    if (!value) throwError('Video Attachment Type required!');
  };
  videoRefCodeValidator = async (rule: any, value: any) => {
    if (!value) throwError('Video ref Code required!');
  };
}
export default ActionList;
export const ActionListInstance = new ActionList();
