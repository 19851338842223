import { throwError } from '../../../utils/helper';

type Item = {
  image_attachment_id: string;
  title: string;
  subtitle: string;
  r_song_id?: number;
  song_of_the_day?: boolean;
};
type HeaderTags = {
  text: string;
  color: string;
};
class HeroSong {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header!: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header_tags!: HeaderTags[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items: Item[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  show_item_details!: boolean;

  getSerializedData = (widgetFormData: HeroSong) => {
    let itemModified = widgetFormData?.items?.map((item: Item) => {
      if (item.song_of_the_day) delete item.r_song_id;
      if (!item.song_of_the_day) delete item.song_of_the_day;
      return {
        ...item,
      };
    });
    widgetFormData.items = itemModified;
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: HeroSong) => {
    return widgetFormData;
  };

  headerValidator = async (rule: any, value: any) => {
    if (!value) return Promise.resolve();
  };
  headerTagsTextValidator = async (rule: any, value: any) => {
    if (!value) throwError('Header tags text required!');
  };
  headerTagsColorValidator = async (rule: any, value: any) => {
    if (!value) throwError('Header tags color required!');
  };
  thumbnailImageAttachmentValidator = async (rule: any, value: any) => {
    if (!value) throwError('Image Attachment required!');
  };
  titleValidator = async (rule: any, value: any) => {
    if (!value) throwError('Title required!');
  };
  subtitleValidator = async (rule: any, value: any) => {
    if (!value) throwError('SubTitle required!');
  };
  songIdValidator = async (rule: any, value: any) => {
    if (!value) throwError('Song ID required!');
  };
}
export default HeroSong;
export const HeroSongInstance = new HeroSong();
