import { Descriptions } from 'antd';

interface Props {
  record: any;
}
const SongPlaylistDataTable = ({ record }: Props) => {
  const dataColumn = [
    {
      title: 'Song Id',
      dataIndex: 'r_song_id',
      key: 'r_song_id',
      ellipsis: true,
      width: '10%',
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Song of the day',
      dataIndex: 'song_of_the_day',
      key: 'song_of_the_day',
      ellipsis: true,
      width: '10%',
      render: (text: any, record: any, index: any) => {
        return <div>{text ? 'Yes' : 'No'}</div>;
      },
    },
  ];
  return (
    <div className="padding-left">
      {
        <Descriptions size="small" title="Song Playlist" bordered>
          {record?.content?.items?.map((item: any) => {
            return (
              <Descriptions.Item label="Song ID">
                {item?.r_song_id}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      }
    </div>
  );
};
export default SongPlaylistDataTable;
