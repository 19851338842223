import { Descriptions, Tag } from "antd";

interface Props {
  header: string;
  header_tags: any[];
}
const HeaderTags = ({ header, header_tags }: Props) => {
  return (
    <div className="padding-left">
      {header && (
        <Descriptions size="small" title={`Header : ${header}`} bordered>
          {header_tags?.map((item) => {
            return (
              <>
                <Descriptions.Item key={item.text} label="Text">
                  {item.text}
                </Descriptions.Item>
                <Descriptions.Item key={item.color} label="Color">
                  <Tag color={item.color}>{item.color}</Tag>
                </Descriptions.Item>
              </>
            );
          })}
        </Descriptions>
      )}
    </div>
  );
};
export default HeaderTags;
