
export const UC_FILE_UPLOAD =
    "expresso/mandir/service-api/universal-content/addContent.php";
import { AxiosRequestConfig } from 'axios'
import { apiClient } from '../../apiClient'

export interface ServiceHandlers {
    [key: string]: (context: any, event: any) => void
}
export interface MachineConfig {
    services: ServiceHandlers
}

const getUploadConfig = (getProgress: any) => {
    return {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (event: any) => {
            const percent = (event.loaded / event.total) * 100;
            getProgress({ percent });
        },
    };
};
class Riddler {
    api: any;
    interceptorId: string;
    constructor(config: AxiosRequestConfig) {
        this.api = apiClient.create({ ...config, timeout: 30000 });
        this.interceptorId = this?.api?.interceptors?.response?.use(
            async (res: any) => res?.data?.data || res,
            async (error: any) => error?.response?.data?.error || error
        );
    }

    uploadImage = async (options: any, formData: any) => {
        const { onProgress, setProgressToState } = options;

        const config = getUploadConfig((progress: any) => {
            const { percent } = progress;
            const flooredPercent = Math.floor(percent);
            setProgressToState(flooredPercent);
            if (percent === 100) {
                setTimeout(() => setProgressToState(0), 1000);
            }
            onProgress({ percent });
        });
        return this.api.post(UC_FILE_UPLOAD, formData, config);
    };
    getUCCodeForYoutubeLink(formData: any) {
        const config = {
            headers: { "content-type": "multipart/form-data" },
        };
        return this.api.post(UC_FILE_UPLOAD, formData, config);
    }
}
export default Riddler;
