import { Alert, Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select } from "antd";
import { SUPPORTED_LANGUAGES_LIST, getLanguageNameFromValue } from "../../../../../utils/constants";
import styled from "styled-components";
import { useWatch } from "antd/lib/form/Form";
import { REQUIRED_RULE } from "../../../../../utils/form.validation";
import TextArea from "antd/lib/input/TextArea";
import { useAppContext } from "../../../../../components/AppContext";
import { Chapter } from "@a4b/api/src/modules/Gita/types";
import { UpdateQuizPayload } from "@a4b/api/src/modules/Coins/types";
import moment from "moment-timezone";

const QuestionContainer = styled(Row)`
    padding: 30px;
    margin: 10px 0px;
    border: 1px dotted #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    &.option {
        cursor: pointer;
    }
`

const SubmitButton = styled(Button)`
    position: absolute;
    top: 10px;
    right: 10px;
`

interface AddQuizProps {
    form: FormInstance<UpdateQuizPayload>,
    formData?: UpdateQuizPayload
}
const AddQuiz = function (props: AddQuizProps) {
    const { form, formData } = props;
    const { userProfile } = useAppContext();
    const supportedLanguages = useWatch("supportedLanguages", form) || ["hi"];
    const correctIndex = useWatch('correct_index', form);

    return <>
        {
            formData &&
            <Form.Item hidden rules={REQUIRED_RULE} name={"id"} label={"Id"}>
                <Input disabled></Input>
            </Form.Item>
        }
        <Form.Item hidden rules={REQUIRED_RULE} name={"correct_index"} label={"Correct index"}>
            <Select>
                <Select.Option value={0}> 1</Select.Option>
                <Select.Option value={1}> 2</Select.Option>
                <Select.Option value={2}> 3</Select.Option>
                <Select.Option value={3}> 4</Select.Option>
            </Select>
        </Form.Item>
        <Row justify="space-between" gutter={[16, 16]} >
            <Col span={24}>
                <Form.Item rules={REQUIRED_RULE} name={"code"} label={"Question code"}>
                    <Input disabled></Input>
                </Form.Item>
            </Col>


            <Col span={12}>
                <Form.Item rules={REQUIRED_RULE} name={"start_at"} label={"Start at"} initialValue={moment().startOf("day")}>
                    <DatePicker showTime></DatePicker>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item rules={[...REQUIRED_RULE, {
                    validator(rule, value, callback) {
                        if (moment(form.getFieldValue('start_at')).valueOf() >= moment(value).valueOf()) {
                            callback("`End at` should be greater than equal to `Start at`")
                        }
                        callback(undefined);
                    },
                }]} name={"end_at"} label={"End at"} initialValue={moment().endOf("day")}>
                    <DatePicker showTime></DatePicker>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item rules={REQUIRED_RULE} name={"initial_correct_percentage"} label={"Initial correct percentage"}>
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item rules={[...REQUIRED_RULE, {
                    validator(rule, value, callback) {
                        if (form.getFieldValue('initial_correct_percentage') <= value) {
                            callback("`Initial correct percentage` should be greater than `Final correct percentage`")
                        }
                        callback(undefined);
                    }
                }]} name={"final_correct_percentage"} label={"Final correct percentage"}>
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item rules={REQUIRED_RULE} name="supportedLanguages" label="Select supported languages" initialValue={["hi"]}>
                    <Select mode="multiple">
                        {
                            SUPPORTED_LANGUAGES_LIST.map((language) => {
                                return <Select.Option value={language.value} disabled={language.value === 'hi'}>
                                    {language.alt_name}
                                </Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
        </Row >
        <QuestionContainer >
            {
                supportedLanguages.map((language: string) => {
                    return <Col span={24}>
                        <Form.Item rules={REQUIRED_RULE} name={['question', language]} label={`Question (${language})`}>
                            <TextArea />
                        </Form.Item>
                    </Col>
                })
            }
        </QuestionContainer>
        <Row justify="space-between" gutter={[16, 16]}>
            <Col span={12}>
                <QuestionContainer onClick={() => {
                    form.setFieldValue('correct_index', 0)
                }} className="option" style={{
                    background: correctIndex !== undefined ? correctIndex === 0 ? "#b7eb8f" : "#ffccc7" : '#d9d9d9'
                }}>
                    {
                        supportedLanguages.map((language: string) => {
                            return <Col span={24}>
                                <Form.Item
                                    rules={[...REQUIRED_RULE, { max: 20 }]}
                                    name={['options', 0, language]}
                                    label={`Option 1 (${getLanguageNameFromValue(language)})`}
                                >
                                    <Input onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }} />
                                </Form.Item>
                            </Col>
                        })
                    }
                </QuestionContainer>
            </Col>
            <Col span={12}>
                <QuestionContainer className="option" onClick={() => {
                    form.setFieldValue('correct_index', 1)
                }} style={{
                    background: correctIndex !== undefined ? correctIndex === 1 ? "#b7eb8f" : "#ffccc7" : '#d9d9d9'
                }}>
                    {
                        supportedLanguages.map((language: string) => {
                            return <Col span={24}>
                                <Form.Item rules={[...REQUIRED_RULE, { max: 20 }]} name={['options', 1, language]} label={`Option 2 (${getLanguageNameFromValue(language)})`}>
                                    <Input
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        })
                    }
                </QuestionContainer>
            </Col>
            <Col span={12}>
                <QuestionContainer className="option" onClick={() => {
                    form.setFieldValue('correct_index', 2)
                }} style={{
                    background: correctIndex !== undefined ? correctIndex === 2 ? "#b7eb8f" : "#ffccc7" : '#d9d9d9'
                }}>
                    {
                        supportedLanguages.map((language: string) => {
                            return <Col span={24}>
                                <Form.Item rules={[...REQUIRED_RULE, { max: 20 }]} name={['options', 2, language]} label={`Option 3 (${getLanguageNameFromValue(language)})`}>
                                    <Input
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        })
                    }
                </QuestionContainer>
            </Col>
            <Col span={12}>
                <QuestionContainer className="option" onClick={() => {
                    form.setFieldValue('correct_index', 3)
                }} style={{
                    background: correctIndex !== undefined ? correctIndex === 3 ? "#b7eb8f" : "#ffccc7" : '#d9d9d9'
                }}>
                    {
                        supportedLanguages.map((language: string) => {
                            return <Col span={24}>
                                <Form.Item rules={[...REQUIRED_RULE, { max: 20 }]} name={['options', 3, language]} label={`Option 4 (${getLanguageNameFromValue(language)})`}>
                                    <Input
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        })
                    }
                </QuestionContainer>
            </Col>
            {
                <Alert style={{ marginBottom: '15px', marginLeft: '5px' }}
                    message={"Click on option to select the correct option "}
                    type={correctIndex === undefined ? 'error' : 'info'} showIcon />
            }
        </Row>
        <Row justify="space-between" gutter={[16, 16]}>
            <Col span={12}>
                <Form.Item rules={REQUIRED_RULE} name={"created_by"} label={"Created by"} initialValue={userProfile?.email || "NA"}>
                    <Input disabled />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item rules={REQUIRED_RULE} name={"updated_by"} label={"Updated by"} initialValue={userProfile?.email || "NA"}>
                    <Input disabled />
                </Form.Item>
            </Col>
        </Row>
        {
            formData ?
                <SubmitButton type="primary" htmlType="submit"> Update </SubmitButton> :
                <SubmitButton type="primary" htmlType="submit"> Create </SubmitButton>
        }
    </>
}

export default AddQuiz;