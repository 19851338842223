import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import CreateUpdateAppForm from "../components/CreateUpdateAppForm";
import { Shareapp } from "@a4b/api/src/modules/ShareText/types";


export enum DRAWER_TYPE {
    CREATE_APP = 'Create app',
    UPDATE_APP = 'Update app'
}




export default function useDrawerConfig(getShareTextApps: () => Promise<void>, activeDrawer?: DRAWER_TYPE, editShareApp?: Shareapp | null): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {

    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_APP,
                width: 1000,
            },
            drawerContent: <CreateUpdateAppForm getShareTextApps={getShareTextApps}></CreateUpdateAppForm>
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.UPDATE_APP,
                width: 1000,
            },
            drawerContent: editShareApp && <CreateUpdateAppForm editShareApp={editShareApp} getShareTextApps={getShareTextApps}></CreateUpdateAppForm>
        }
    ], [editShareApp, getShareTextApps])

    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}