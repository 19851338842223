import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Button, Col, Empty, List, Modal, Row } from 'antd'
import styled from 'styled-components'

import React, { useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'



interface HasId {
    id: string
    [key: string]: any
}

export interface DragAndDropProps {
    children: any
    showModal: boolean
    handleOnCancel: () => void
    handleOnOk: (sortedList: HasId[]) => void
    itemList: HasId[]
    sortedData: HasId[]
    title: string
}
export const COLUMN_NAMES = {
    FROM: 'From',
    TO: 'To',
}

const Container = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const IconWrapper = styled.span`
  width: 10%;
`
const ContentWrapper = styled.span`
  width: 90%;
`

const DragAndDropModal: React.FC<DragAndDropProps> = ({
    children,
    showModal,
    handleOnCancel,
    handleOnOk,
    itemList,
    title,
    sortedData
}) => {
    const [fromItems, setFromItems] = useState<any[]>([])
    const [sortedItems, setSortedItems] = useState<any[]>([])

    useEffect(() => {
        const uniqueIds = new Set<string>([])
        const uniqueItems: HasId[] = [];
        itemList?.forEach((item: HasId) => {
            if (!uniqueIds.has(item.id)) {
                uniqueItems.push(item)
                uniqueIds.add(item.id)
            }
        })
        setFromItems(uniqueItems);
        const uniqueSortedIds = new Set<string>([])
        const uniqueSortedItems: HasId[] = [];
        sortedData.forEach((item: HasId) => {
            if (!uniqueSortedIds.has(item.id)) {
                uniqueSortedItems.push(item)
                uniqueSortedIds.add(item.id)
            }
        })
        if (uniqueSortedItems?.length) {
            setSortedItems(uniqueSortedItems)
            setFromItems(uniqueItems.filter((from: HasId) => !uniqueSortedItems.filter((item: HasId) => item.id === from.id).length))
        }
        return () => {

            setFromItems([])
            setSortedItems([])
        }
    }, [itemList, sortedData])

    const addPropsToReactElement = (element: any, props: any) => {
        if (React.isValidElement(element)) {
            return React.cloneElement(element, props)
        }

        return element
    }

    const addPropsToChildren = (element: any, props: any) => {
        if (!Array.isArray(element)) {
            return addPropsToReactElement(element, props)
        }
        return element.map(childElement =>
            addPropsToReactElement(childElement, props),
        )
    }

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',

        // change background colour if dragging
        background: isDragging ? 'lightgreen' : '',
        width: '100%',
        // styles we need to apply on draggables
        ...draggableStyle,
    })

    const getListStyle = (isDraggingOver: any) => ({
        background: isDraggingOver ? 'lightblue' : '',
    })
    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const items = reorder(
            sortedItems,
            result.source.index,
            result.destination.index,
        )

        setSortedItems(items)
    }

    const transferTo = (id: string) => {
        if (sortedItems.find((item: HasId) => item.id === id)) return;
        const selectItemIndex = fromItems?.findIndex((item: HasId) => item.id === id)
        const a = fromItems?.splice(selectItemIndex, 1)
        setFromItems([...fromItems])
        setSortedItems([...sortedItems, ...a])
    }
    const transferBack = (id: string) => {
        if (fromItems.find((item: HasId) => item.id === id)) return;
        const selectItemIndex = sortedItems?.findIndex(
            (item: HasId) => item.id === id,
        )
        const a = sortedItems?.splice(selectItemIndex, 1)
        setFromItems([...fromItems, ...a])
        setSortedItems([...sortedItems])
    }
    return (
        <Modal
            title={title}
            centered
            destroyOnClose={true}
            visible={showModal}
            onOk={() => {
                handleOnOk(sortedItems)
            }}
            onCancel={handleOnCancel}
            width={1250}
        >
            <Row>
                <Col
                    span={11}
                    style={{
                        height: '650px',
                        position: 'relative',
                        overflow: 'scroll',
                        border: '1px solid #838693',
                    }}
                >
                    {fromItems?.map((item: HasId, index) => (
                        <Row
                            align='middle'
                            key={item.id}
                            style={{
                                border: `1px solid #E6E4EB`,
                                marginTop: '5px',
                                marginLeft: '5px',
                                marginRight: '5px',
                                backgroundColor: item?.active ? '#F3FCF4' : ''
                            }}
                        >
                            <Col span={22}>
                                <div>{addPropsToChildren(children, { data: item })}</div>
                            </Col>
                            <Col span={2}>
                                <Button type='primary' onClick={() => transferTo(item.id)}>
                                    <ArrowRightOutlined />
                                </Button>
                            </Col>
                        </Row>
                    ))}
                </Col>
                <Col span={1}></Col>
                <Col
                    span={12}
                    style={{
                        height: '650px',
                        position: 'relative',
                        overflow: 'scroll',
                        border: '1px solid #838693',
                    }}
                >
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='droppable'>
                            {provided => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {sortedItems?.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={
                                                        getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style,
                                                        )
                                                    }

                                                >
                                                    <Container style={{ backgroundColor: item?.active ? '#F3FCF4' : '' }}>
                                                        <IconWrapper>
                                                            <Button
                                                                type='primary'
                                                                onClick={() => {
                                                                    transferBack(item.id)
                                                                }}
                                                            >
                                                                <ArrowLeftOutlined />
                                                            </Button>
                                                        </IconWrapper>
                                                        <ContentWrapper>
                                                            {addPropsToChildren(children, { data: item })}
                                                        </ContentWrapper>
                                                    </Container>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Col>
            </Row>
        </Modal>
    )
}
export default DragAndDropModal
