import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { Response } from './types'
import { CreateFilterPayload, FiltersResponse } from './types/filters'

class FiltersApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createFilter = (payload: CreateFilterPayload) =>
    this.api.post(`/gw1/puja/internal/v1/filters`, payload)

  updateFilter = (id: string, payload: CreateFilterPayload) =>
    this.api.patch(`/gw1/puja/internal/v1/filters/${id}`, payload)

  getFilters = (categoryId: string) =>
    this.api.get<FiltersResponse>(
      `/gw1/puja/internal/v1/filters?category_id=${categoryId}`,
    )

  getFilterById = (filterId: string) =>
    this.api.get<Response<any>>(`/gw1/puja/internal/v1/filters/${filterId}`)

  deleteFilter = (filterId: string) =>
    this.api.delete(`/gw1/puja/internal/v1/filters/${filterId}`)
}

export default FiltersApi
