import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormProps,
  message,
  Row,
  Select,
  Input,
  Checkbox,
  Space,
  Typography,
  DatePicker,
  TimePicker,
  InputNumber,
} from 'antd'
import { RcFile, UploadFile } from 'antd/lib/upload';
import { AudioThumbnail } from '@a4b/api';
import UploadThumbnailModal from '../MetaDataEditForm/UploadThumbnailModal';
import { AudioUploadThumbnailOptions, SortableItemAudio } from '@a4b/api/src/modules/Content/Audio/types';
import { SearchItems } from '../Schedule/SearchItems';
import { SortableCards } from '../Schedule/SortableCards';
import { SUPPORTED_LANGUAGES_LIST, PLAYLIST_TYPES } from '../../../constants';
import { CopyOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { AntdFileUploadV2, getAntUploadFile } from '../../FileUpload';
import { openErrorNotification } from '../../../../../utils/helper';
import moment, { utc } from 'moment';
import styled from 'styled-components';
import { NEW_CDN_HOST } from '../../../../../../../../apps/admin/src/utils/constants';

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
`

export interface Props {
  data?: Partial<PlaylistCreateEditFormFields>;
  onRemoveAudioItem: (item: SortableItemAudio) => void;
  onAddAudioItem: (item: SortableItemAudio) => Promise<boolean>;
  searchItems: SortableItemAudio[];
  onSearchAudio: (searchKey: string) => void;
  onSubmit: (values: PlaylistCreateEditFormFields) => Promise<{ success: boolean, message: string }>;
  onSearchThumbnail: (code: string) => Promise<void>;
  thumbnail: AudioThumbnail[];
  CDN_BASE_URL: string;
  uploadThumbnailS3: (file: RcFile) => Promise<string>,
  uploadThumbnail: (options: AudioUploadThumbnailOptions) => Promise<string>
  getAudioByAudioIds: (audioIds: string[]) => Promise<void>
  uploadDefaultBannerImage: (options: any) => Promise<{
    url: string;
    path: string;
  }>,
  uploadTabImage: (options: any) => Promise<{
    url: string;
    path: string;
  }>,
  uploadBackgroundImage: (options: any) => Promise<{
    url: string;
    path: string;
  }>,
  isClone: boolean;
}

export type PlaylistCreateEditFormFields = {
  name: string;
  name_lang: any
  code: string;
  title: string
  title_lang: any
  description: string;
  max_preview_count: string;
  playlist_type: string;
  riddler_playlist_id?: string
  rddler_monthly_count?: string
  playlist_order?: string
  // trending_filter?: any;
  active: boolean;
  // start_datetime: number;
  // end_datetime?: any;
  day_eligibility: string[];
  // active_time: ActiveTime[];
  thumbnail_path?: string;
  audios: SortableItemAudio[];
  language_code: string;
  loki_playlist_id: string;
  default_banner_titles: any;
  default_banner_image_path?: string
  tab_image_path?: string
  background_image_path?: string,
  trending_filter?: {
    days: number
    days_of_week: string[]
    god: string
    active_time: ActiveTime
  },
  active_time?: ActiveTime[]
}

export interface ActiveTime {
  start_time: number
  end_time: number
}

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}


const { useForm } = Form
const { Option } = Select;

const DAYS_ELIGIBLE = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

const calculateDurationInSeconds = (startTime: any, endTime: any) => {
  const duration = moment.duration(endTime.diff(startTime));
  return duration.asSeconds();
}

const deafaultStartTime = moment().utcOffset(0);
deafaultStartTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
deafaultStartTime.toISOString()


const deafaultEndTime = moment().utcOffset(0);
deafaultEndTime.set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
deafaultEndTime.toISOString()


export const PlaylistsCreateEditForm: React.FC<Props> = ({ data, thumbnail, CDN_BASE_URL, searchItems, onSearchThumbnail, onSubmit,
  uploadThumbnail, uploadThumbnailS3, onRemoveAudioItem,
  uploadBackgroundImage, uploadDefaultBannerImage, uploadTabImage,
  onAddAudioItem, onSearchAudio, getAudioByAudioIds, isClone }) => {
  const [audioIds, setAudioIds] = useState('');
  const [form] = useForm<PlaylistCreateEditFormFields>()
  const ref = useRef(false);
  const [isTouched, setIsTouched] = useState<boolean>();


  useEffect(() => {
    if (!data) {
      form.resetFields()
      return
    }
    if (isTouched) {
      // this is just to avoid change of data in edit mode because of audio change 
      form.setFieldValue('audios', data.audios)
      return
    }

    if (isClone) {
      data.code = '';
    }

    let names: { language: string, name: string }[] = []

    if (data.name_lang) {
      Object.keys(data?.name_lang).forEach((key) => {
        names.push({ language: key, name: data.name_lang[key] })
      })
    } else {
      names = [{ language: 'hi', name: '' }, { language: 'en', name: '' }]
    }

    let titles: { language: string, title: string }[] = []

    if (data.title_lang) {
      Object.keys(data?.title_lang).forEach((key) => {
        titles.push({ language: key, title: data.title_lang[key] })
      })
    } else {
      titles = [{ language: 'hi', title: '' }, { language: 'en', title: '' }]
    }

    let default_banner_titles: { language: string, title: string }[] = []
    if (data.default_banner_titles) {
      Object.keys(data?.default_banner_titles).forEach((key) => {
        default_banner_titles.push({ language: key, title: data.default_banner_titles[key] })
      })
    } else {
      default_banner_titles = [{ language: 'hi', title: '' }, { language: 'en', title: '' }]
    }

    const tab_image_path = data.tab_image_path ? getAntUploadFile(data.tab_image_path, CDN_BASE_URL) : undefined;
    const default_banner_image_path = data.default_banner_image_path ? getAntUploadFile(data.default_banner_image_path, CDN_BASE_URL) : undefined;
    const background_image_path = data.background_image_path ? getAntUploadFile(data.background_image_path, CDN_BASE_URL) : undefined;
    const active_time = data.active_time?.map((t) => {
      const startTimeDuration = moment.duration(t.start_time, 'seconds');
      const startTime = moment().utcOffset(0);
      startTime.set({ hours: startTimeDuration.get('hours'), minute: startTimeDuration.get('minutes'), seconds: startTimeDuration.get('seconds'), millisecond: 0 })

      const endTimeDuration = moment.duration(t.end_time, 'seconds');
      const endTime = moment().utcOffset(0);
      endTime.set({ hours: endTimeDuration.get('hours'), minute: endTimeDuration.get('minutes'), seconds: endTimeDuration.get('seconds'), millisecond: 0 })

      return {
        start_time: startTime,
        end_time: endTime
      }
    })

    let trending_filter: any = data.trending_filter;
    if (data.trending_filter?.active_time) {
      const copyActiveTime: any = { ...data.trending_filter?.active_time }
      const startTimeduration = moment.duration(copyActiveTime.start_time, 'seconds');
      const endTimeduration = moment.duration(copyActiveTime.end_time, 'seconds');
      copyActiveTime.start_time = moment().utcOffset(0);
      copyActiveTime.end_time = moment().utcOffset(0);
      copyActiveTime.start_time.set({ hours: startTimeduration.get('hours'), minute: startTimeduration.get('minutes'), seconds: startTimeduration.get('seconds'), millisecond: 0 })
      copyActiveTime.end_time.set({ hours: endTimeduration.get('hours'), minute: endTimeduration.get('minutes'), seconds: endTimeduration.get('seconds'), millisecond: 0 })
      trending_filter = { ...trending_filter };
      trending_filter.active_time = copyActiveTime;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore 
    form.setFieldsValue({
      ...data,
      name_lang: names,
      title_lang: titles,
      default_banner_titles,

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore 
      tab_image_path, default_banner_image_path, background_image_path, active_time, trending_filter
    })
  }, [data, isClone])

  const onFinish = async (values: any) => {
    const name_lang = {}
    values?.name_lang.forEach((element: { language: string, name: any[] }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      name_lang[element.language] = element.name;
    })
    const title_lang = {}
    values?.title_lang.forEach((element: { language: string, title: string }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      title_lang[element.language] = element.title;
    })

    const default_banner_titles = {}
    if (values.default_banner_titles) {
      values?.default_banner_titles.forEach((element: { language: string, title: string }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        default_banner_titles[element.language] = element.title;
      })
    }
    values.tab_image_path = (values.tab_image_path && values.tab_image_path.length > 0) ? values.tab_image_path[0].response.path : null
    values.default_banner_image_path = values.default_banner_image_path && values.default_banner_image_path.length > 0 ? values.default_banner_image_path[0].response.path : null
    values.background_image_path = values.background_image_path && values.background_image_path.length > 0 ? values.background_image_path[0].response.path : null

    if (values.trending_filter) {
      values.trending_filter.active_time.start_time = calculateDurationInSeconds(deafaultStartTime, values.trending_filter.active_time.start_time.utcOffset(0, true));
      values.trending_filter.active_time.end_time = calculateDurationInSeconds(deafaultStartTime, values.trending_filter.active_time.end_time.utcOffset(0, true));
    }

    if (values.active_time) {
      values.active_time = values.active_time.map((t: any) => {
        return {
          start_time: calculateDurationInSeconds(deafaultStartTime, t.start_time.utcOffset(0, true)),
          end_time: calculateDurationInSeconds(deafaultStartTime, t.end_time.utcOffset(0, true))
        }
      })
    }

    // console.log({ values })

    try {
      const response = await onSubmit({ ...values, name_lang, title_lang, default_banner_titles })
      response.success
        ? message.success(response.message)
        : message.error(response.message)
    } catch (error) {
      openErrorNotification(error);
    }
  }

  const onFinishFailed: FormProps<PlaylistCreateEditFormFields>['onFinishFailed'] = (errorInfo) => {
    console.log(errorInfo)
  }

  const addBulkAudioUsingIds = () => {
    const audioIdsArr = audioIds.split(',').map((audio) => audio.trim());
    ref.current = true
    getAudioByAudioIds(audioIdsArr)
  }

  const audios = Form.useWatch('audios', form);
  const thumbnailPath = Form.useWatch('thumbnail_path', form);
  const language_code = Form.useWatch('language_code', form);
  const tab_image_path = Form.useWatch('tab_image_path', form) as unknown as UploadFile[];
  const default_banner_image_path = Form.useWatch('default_banner_image_path', form) as unknown as UploadFile[];
  const background_image_path = Form.useWatch('background_image_path', form) as unknown as UploadFile[];
  const lyricsPercentage = audios ? ((audios.filter((a) => a.lyrics_path && a.lyrics_path !== "").length / audios.length) * 100).toFixed(2) : 0;
  const videoPathPercentage = audios ? ((audios.filter((a) => !!a.video_share_path).length / audios.length) * 100).toFixed(2) : 0;

  const onLanguageChange = useCallback((language: string) => {
    const name_lang = form.getFieldValue('name_lang')
    const title_lang = form.getFieldValue('title_lang')
    const default_banner_titles = form.getFieldValue('default_banner_titles')
    if (name_lang && !name_lang.find((val: { language: string }) => val.language === language)) {
      name_lang.push({ language })
    }
    if (title_lang && !title_lang.find((val: { language: string }) => val.language === language)) {
      title_lang.push({ language })
    }
    if (default_banner_titles && !default_banner_titles.find((val: { language: string }) => val.language === language)) {
      default_banner_titles.push({ language })
    }
    form.setFieldValue('name_lang', name_lang)
    form.setFieldValue('title_lang', title_lang)
    form.setFieldValue('default_banner_titles', default_banner_titles)
  }, [])

  const playlistType = Form.useWatch('playlist_type', form);

  const addTabImageUsingPath = useCallback((path: string) => {
    form.setFieldValue('tab_image_path', getAntUploadFile(path, NEW_CDN_HOST))
  }, [form])

  const addDefaultBannerImageUsingPath = useCallback((path: string) => {
    form.setFieldValue('default_banner_image_path', getAntUploadFile(path, NEW_CDN_HOST))
  }, [form])

  const addBackgroundImageUsingPath = useCallback((path: string) => {
    form.setFieldValue('background_image_path', getAntUploadFile(path, NEW_CDN_HOST))
  }, [form])

  return (
    <Row style={{ padding: '40px', paddingLeft: '10%', paddingRight: '10%' }}>
      <Col span={24} xs={24} sm={24} md={24} lg={24}>
        <Card title={`Playlist Create Edit Form ${isClone ? ' (Clone)' : ''}`}>
          <Form
            name='playlist-create-edit-form'
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            layout="vertical"
            onFieldsChange={() => {
              // add your additionaly logic here
              setIsTouched(true);
            }}
          >
            <div style={{ width: 800 }}>
              <Form.Item  >
                <div style={{ border: '1px solid black', borderRadius: '10px', padding: '20px' }}>
                  <Typography.Title level={4}><b>Lyrices Precentage</b>: {lyricsPercentage} %</Typography.Title>
                  <Typography.Title level={4}><b>Video share path Precentage</b>: {videoPathPercentage} %</Typography.Title>
                </div>
              </Form.Item>
              <Form.Item name='language_code' label='Language' rules={[{ required: true }]}>
                <Select
                  onChange={onLanguageChange}
                >
                  {SUPPORTED_LANGUAGES_LIST.map(language =>
                    <Select.Option key={language.value} value={language.value}>
                      {language.alt_name} ({language.name})
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                label='Type'
                name='playlist_type'
                rules={[
                  { required: true, message: 'Please input type of the playlist' },
                ]}>
                <Select
                  options={PLAYLIST_TYPES.map((type) => ({
                    label: type,
                    value: type,
                  }))}
                />
              </Form.Item>
              <Form.Item
                label='Name'
                name='name'
                rules={[
                  { required: true, message: 'Please input name of the playlist' },
                ]}>
                <Input />
              </Form.Item>
              <Form.List name="name_lang" initialValue={[{ language: 'hi' }, { language: 'en' }]}>
                {(fields, { add, remove }) => (
                  <div>
                    <Typography.Title level={5}> Add playlist names </Typography.Title>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '70px', padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }} align="baseline">
                        <Form.Item
                          label={'Select Language'}
                          {...restField}
                          name={[name, 'language']}
                          rules={[{
                            required: true, validator(rule, value, callback) {
                              if (form.getFieldValue('name_lang').filter((title: any) => title.language === value).length > 1) {
                                callback("name for this language is already added!")
                              } else {
                                callback(undefined)
                              }
                            },
                          }]}
                        >
                          <Select
                            style={{ width: '350px' }}
                            placeholder={'Select language'}

                          >
                            {SUPPORTED_LANGUAGES_LIST.map(language =>
                              <Select.Option key={language.value} value={language.value}>
                                {language.alt_name} ({language.name})
                              </Select.Option>
                            )}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={'Playlist Name'}
                          {...restField}
                          name={[name, 'name']}
                          rules={[{ required: true }]}
                        >
                          <Input style={{ width: '350px' }} placeholder="name" />
                        </Form.Item>

                        <div style={{ width: 50, marginTop: '2px' }}>
                          {!['hi', 'en', language_code].includes(form.getFieldValue(['name_lang', name, 'language'])) && <Button style={{ border: '1px solid light' }} onClick={() => remove(name)}>
                            <MinusCircleOutlined />
                          </Button>
                          }
                        </div>


                      </Space>
                    ))}
                    <Form.Item>
                      <Button style={{ width: '200px', background: 'rgb(211, 211, 211)' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add playlist name
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </Form.List>

              <Form.Item
                label='Code'
                name='code'
                style={{ marginTop: '8px' }}
                rules={[
                  { required: true, message: 'Please input code of the playlist' },
                ]}>
                <Input />
              </Form.Item>
              <Form.Item
                label='Title'
                name='title'
                rules={[
                  { required: true, message: 'Please input title of the playlist' },
                ]}>
                <Input />
              </Form.Item>

              <Form.List name="title_lang" initialValue={[{ language: 'hi' }, { language: 'en' }]}>
                {(fields, { add, remove }) => (
                  <div>
                    <Typography.Title level={5}> Add title names </Typography.Title>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key}
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '70px', padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }} align="baseline">
                        <Form.Item
                          label={'Select Language'}
                          {...restField}
                          name={[name, 'language']}
                          rules={[{
                            required: true, validator(rule, value, callback) {
                              if (form.getFieldValue('title_lang').filter((title: any) => title.language === value).length > 1) {
                                callback("title for this language is already added!")
                              } else {
                                callback(undefined)
                              }
                            },
                          }]}
                        >
                          <Select
                            placeholder={'Select language'}
                            style={{ width: '350px' }}
                          >
                            {SUPPORTED_LANGUAGES_LIST.map(language =>
                              <Select.Option key={language.value} value={language.value}>
                                {language.alt_name} ({language.name})
                              </Select.Option>
                            )}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={'Playlist Title'}
                          {...restField}
                          name={[name, 'title']}
                          rules={[{ required: true }]}
                        >
                          <Input style={{ width: '350px' }} placeholder="title" />
                        </Form.Item>
                        <div style={{ width: 50, marginTop: '2px' }}>

                          {!['hi', 'en', language_code].includes(form.getFieldValue(['title_lang', name, 'language'])) && <Button style={{ border: '1px solid light' }} onClick={() => remove(name)}>
                            <MinusCircleOutlined />
                          </Button>
                          }
                        </div>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button style={{ width: '200px', background: 'rgb(211, 211, 211)' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add playlist title
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </Form.List>

              <Form.Item
                label='Description'
                name='description'
                rules={[
                  { required: true, message: 'Please input description of the playlist' },
                ]}>
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                label='Max Preview Count'
                name='max_preview_count'
                rules={[
                  { required: true, message: 'Please input max preview count' },
                ]}>
                <Input type='number' max={100} />
              </Form.Item>
              <Form.Item
                label='Playlist Order'
                name='playlist_order'
                rules={[
                  { required: true, message: 'Please input Playlist Order' },
                ]}
              >
                <Input type='number' />
              </Form.Item>
              <Form.Item label='New God Id' name={'loki_playlist_id'} rules={[{ required: (playlistType === "RIDDLER_SUPPORT" || playlistType === "POPULAR") }]}>
                <Input />
              </Form.Item>

              <Form.Item
                label='Riddler Playlist ID'
                name='riddler_playlist_id'
                rules={[
                  { required: (playlistType === "RIDDLER_SUPPORT" || playlistType === "POPULAR"), message: 'Please input Riddler Playlist ID' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Riddler Montly count'
                name='rddler_monthly_count'
                rules={[
                  { required: (playlistType === "RIDDLER_SUPPORT"), message: 'Please input Riddler Montly count' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Active'
                name='active'
                valuePropName='checked'
                rules={[
                  {
                    required: true,
                    message: 'Please select active true or false',
                  },
                ]}
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                label='Days Eligible'
                name='day_eligibility'
                rules={[
                  { required: true, message: 'Please select atleast one tag!' },
                ]}
              >
                <Select
                  filterOption={false}
                  mode='multiple'
                  onChange={(value) => {
                    if (value && value.length && value.includes("all")) {
                      if (value.length === DAYS_ELIGIBLE.length + 1) {
                        form.setFieldValue('day_eligibility', [])
                      }
                      form.setFieldValue('day_eligibility', [...DAYS_ELIGIBLE])
                    }
                  }}
                >
                  <Option key="all" value="all">---SELECT ALL---</Option>
                  {
                    DAYS_ELIGIBLE.map((day) => (
                      <Option key={day} value={day}>{day}</Option>
                    ))
                  }

                </Select>
              </Form.Item>
              <Form.Item
                label='Add Thumbnail'
                name='thumbnail_path'
                // valuePropName='fileList'
                // getValueFromEvent={normFile}
                rules={[
                  { required: true, message: 'Please add a thumbnail image' },
                ]}
              >
                <Select
                  showSearch
                  filterOption={false}
                  onSearch={onSearchThumbnail}
                >
                  {thumbnail.map((t) => {
                    return <Option key={t.image_path} value={t.image_path}>
                      <Space direction='horizontal'>
                        {t.image_path && t.image_path !== '' && <img src={CDN_BASE_URL + t.image_path} height={20} width={20}></img>}
                        {" " + t.code}
                      </Space>
                    </Option>
                  })}
                </Select>

              </Form.Item>
              <Form.Item >
                {thumbnailPath && thumbnailPath !== '' && <img src={CDN_BASE_URL + thumbnailPath} width={50} ></img>}
                <UploadThumbnailModal uploadThumbnailS3={uploadThumbnailS3} uploadThumbnail={uploadThumbnail} />
              </Form.Item>
              <Form.List name="default_banner_titles" initialValue={[{ language: 'hi' }, { language: 'en' }]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '70px', padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }} align="baseline">
                        <Form.Item
                          label={'Select Language'}
                          {...restField}
                          name={[name, 'language']}
                          rules={[{
                            required: false, validator(rule, value, callback) {
                              if (form.getFieldValue('default_banner_titles').filter((title: any) => title.language === value).length > 1) {
                                callback("This language is already added!")
                              } else {
                                callback(undefined)
                              }
                            },
                          }]}
                        >
                          <Select
                            style={{ width: '350px' }}
                            placeholder={'Select language'}
                          >
                            {SUPPORTED_LANGUAGES_LIST.map(language =>
                              <Select.Option key={language.value} value={language.value}>
                                {language.alt_name} ({language.name})
                              </Select.Option>
                            )}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={'Default Banner Title'}
                          {...restField}
                          name={[name, 'title']}
                          initialValue={undefined}
                        >
                          <Input style={{ width: '350px' }} placeholder="Default Banner Title" />
                        </Form.Item>

                        <div style={{ width: 50, marginTop: '2px' }}>

                          {!['hi', 'en', language_code].includes(form.getFieldValue(['default_banner_titles', name, 'language'])) && <Button style={{ border: '1px solid light' }} onClick={() => remove(name)}>
                            <MinusCircleOutlined />
                          </Button>
                          }
                        </div>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button style={{ width: '200px', background: 'rgb(211, 211, 211)' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add playlist name
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderTop: '1px solid black', padding: '20px', margin: '10px 0px' }}>
                <ImageContainer>
                  <AntdFileUploadV2
                    rules={[{ required: false }]}
                    name={'tab_image_path'}
                    accept="image/png,image/jpg,image/jpeg,image/gif,video/mp4"
                    s3UploadApi={uploadTabImage}
                    title={'Upload tab '}
                    // fileValidation={iconValidations}
                    fileList={tab_image_path}
                  />
                  <div > Or </div>
                  <Form.Item style={{ width: '100%', marginTop: '20px' }}>
                    <Input onChange={(e) => {
                      if (e.target.value.trim().length > 0) {
                        addTabImageUsingPath(e.target.value.trim())
                        e.target.value = '';
                      }
                    }} placeholder="Paste media path" />
                  </Form.Item>
                </ImageContainer>
                <ImageContainer>
                  <AntdFileUploadV2
                    rules={[{ required: false }]}
                    name={'default_banner_image_path'}
                    accept="image/png,image/jpg,image/jpeg,image/gif"
                    s3UploadApi={uploadDefaultBannerImage}
                    title={'Update banner'}
                    // fileValidation={iconValidations}
                    fileList={default_banner_image_path}
                  />
                  <div > Or </div>
                  <Form.Item style={{ width: '100%', marginTop: '20px' }}>
                    <Input onChange={(e) => {
                      if (e.target.value.trim().length > 0) {
                        addDefaultBannerImageUsingPath(e.target.value.trim())
                        e.target.value = '';
                      }
                    }} placeholder="Paste media path" />
                  </Form.Item>
                </ImageContainer>
                <ImageContainer>
                  <AntdFileUploadV2
                    rules={[{ required: false }]}
                    name={'background_image_path'}
                    accept="image/png,image/jpg,image/jpeg,image/gif"
                    s3UploadApi={uploadBackgroundImage}
                    title={'Upload background'}
                    // fileValidation={iconValidations}
                    fileList={background_image_path}
                  />
                  <div> Or </div>
                  <Form.Item style={{ width: '100%', marginTop: '20px' }}>
                    <Input onChange={(e) => {
                      if (e.target.value.trim().length > 0) {
                        addBackgroundImageUsingPath(e.target.value.trim())
                        e.target.value = '';
                      }
                    }} placeholder="Paste media path" />
                  </Form.Item>
                </ImageContainer>
              </div>
              {
                (playlistType === 'POPULAR' || playlistType === 'TRENDING') && <div style={{ border: '1px solid', padding: '20px', marginBottom: '20px' }}>
                  <Typography.Title style={{ textAlign: 'center' }} level={4}> POPULAR/TRENDING </Typography.Title>
                  {playlistType === 'POPULAR' &&

                    <Form.List name="active_time" initialValue={[new Date()]}>
                      {(fields, { add, remove }) => (
                        <div>
                          <Typography.Title level={5}> Fill active times </Typography.Title>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '70px', padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }} align="baseline">

                              <Form.Item name={[name, 'start_time']} label="Start time" required initialValue={deafaultStartTime} >
                                <TimePicker size="large" />
                              </Form.Item>
                              <Form.Item name={[name, 'end_time']} label="End time" required initialValue={deafaultEndTime}>
                                <TimePicker size="large" />
                              </Form.Item>

                              {name > 0 && <Button style={{ border: '1px solid light' }} onClick={() => remove(name)}>
                                <MinusCircleOutlined />
                              </Button>
                              }
                            </Space>
                          ))}
                          <Form.Item>
                            <Button style={{ width: '200px', background: 'rgb(211, 211, 211)' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              Add active time
                            </Button>
                          </Form.Item>
                        </div>
                      )}
                    </Form.List>
                  }
                  {(playlistType === "TRENDING" || playlistType === "POPULAR") && <>
                    <Form.Item
                      name={['trending_filter', 'days']}
                      label={'Days'}
                      required
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      name={['trending_filter', 'days_of_week']}
                      label={'Days of week'}
                      required
                    >
                      <Select
                        mode="multiple"
                      >
                        {
                          DAYS_ELIGIBLE.map((day) => (
                            <Option key={day} value={day}>{day}</Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </>
                  }
                  {playlistType === 'POPULAR' && <>
                    <Form.Item name={['trending_filter', "god"]} label="God" required >
                      <Input></Input>
                    </Form.Item>
                    <Space wrap>
                      <Form.Item required label="Start time" name={['trending_filter', "active_time", "start_time"]} initialValue={deafaultStartTime}>
                        <TimePicker size="large" />
                      </Form.Item>
                      <Form.Item required label="End time" name={['trending_filter', "active_time", "end_time"]} initialValue={deafaultEndTime}>
                        <TimePicker size="large" />
                      </Form.Item>
                    </Space>
                  </>

                  }
                </div>
              }

            </div>
            <div style={{ width: '95%', border: '1px solid gray', padding: '25px 5%', marginBottom: 16 }}>
              <SearchItems
                language_code={language_code}
                items={searchItems}
                onAddItem={onAddAudioItem}
                onSearch={onSearchAudio}
              />
              <Form.Item label="Add audio by audio IDs" style={{ display: 'flex', flexDirection: 'column' }}>
                <Input onChange={(e) => { setAudioIds(e.target.value) }} value={audioIds} style={{ width: '80%' }} />
                <Button onClick={addBulkAudioUsingIds} style={{ width: '20%', background: '#D3D3D3' }} type="default"> Add audios</Button>
              </Form.Item>
              <Button style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', background: '#D3D3D3' }} size="middle" onClick={() => navigator.clipboard.writeText(audios.map((audio) => audio.id).toString())}> COPY ALL THE SELECTED AUDIO IDs <CopyOutlined /></Button>
              <Form.Item
                name='audios'
              >
                <SortableCards limit={100} onRemoveItem={onRemoveAudioItem} language_code={language_code} />
              </Form.Item>

            </div>
            <Form.Item wrapperCol={{ span: 18, offset: 4 }}>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col >
    </Row >
  )
}
