import * as URL from "./apiRoutes";
import { apiClient } from '../../apiClient';
import { AxiosRequestConfig } from 'axios'

const getQueryParamByObject = (filtersData: any) => {
    const keys = Object.keys(filtersData)
    let searchParams = ''
    for (const key of keys) {
        if (Array.isArray(filtersData[key])) {
            let duplicateQuery = ''
            const query = filtersData[key].map((item: string) => {
                return `${key}=${item}`
            })
            duplicateQuery = query.join('&')
            searchParams =
                searchParams === ''
                    ? duplicateQuery
                    : `${searchParams}&${duplicateQuery}`
        } else {
            searchParams =
                searchParams === ''
                    ? `${key}=${filtersData[key]}`
                    : `${searchParams}&${key}=${filtersData[key]}`
        }
    }
    return searchParams
}

interface ServiceHandlers {
    [key: string]: (context: any, event: any) => void
}

interface MachineConfig {
    services: ServiceHandlers
}



class Feeds {
    api: any;
    constructor(config: AxiosRequestConfig) {
        this.api = apiClient.create(config);
    }

    getWidgetMaster() {
        return this.api.get(URL.GET_MASTER_WIDGETS);
    }
    getPlatforms() {
        return this.api.get(URL.GET_PLATFORMS);
    }
    getProperties() {
        return this.api.get(URL.GET_PROPERTIES);
    }
    createWidget(data: any) {
        return this.api.post(URL.CREATE_WIDGET, data);
    }
    getWidgets() {
        return this.api.get(URL.GET_WIDGETS);
    }
    getWidget(id: string) {
        return this.api.get(`${URL.GET_WIDGETS}/${id}`);
    }
    editWidget(data: any, id: string) {
        return this.api.put(`${URL.CREATE_WIDGET}/${id}`, data);
    }
    deleteWidget(data: any, id: string) {
        return this.api.delete(`${URL.DELETE_WIDGET}/${id}`, { data: { ...data } });
    }
    deactivateWidget(data: any, id: string) {
        return this.api.patch(`${URL.DISABLE_WIDGETS}/${id}`, {
            ...data,
        });
    }
    searchWidget(code: string) {
        return this.api.get(`${URL.SEARCH_WIDGETS}${code}`);
    }
    getSchedules() {
        return this.api.get(URL.GET_SCHEDULE);
    }
    getSchedulesByFilter(filters: any) {
        const searchParams = getQueryParamByObject(filters);
        return this.api.get(`${URL.GET_SCHEDULE}?${searchParams}`);
    }
    getSchedulesById(id: string) {
        return this.api.get(`${URL.GET_SCHEDULE}/${id}`);
    }
    createSchedules(data: any) {
        return this.api.post(URL.CREATE_SCHEDULE, data);
    }
    editSchedulesById(data: any, id: string) {
        return this.api.patch(`${URL.GET_SCHEDULE}/${id}`, data);
    }
    previewSchedules(filters: any) {
        const searchParams = getQueryParamByObject(filters);
        return this.api.get(`${URL.PREVIEW_SCHEDULE}?${searchParams}`);
    }
    deleteSchedule(data: any, id: string) {
        return this.api.delete(`${URL.DELETE_SCHEDULE}/${id}`, {
            data: { ...data },
        });
    }
    disableSchedule(data: any, id: string) {
        return this.api.patch(`${URL.DISABLE_SCHEDULE}/${id}`, {
            ...data,
        });
    }
    searchByWidgetCode(code: string) {
        return this.api.get(`${URL.SEARCH_BY_WIDGET_CODE}/${code}`);
    }
    getS3PathById(id: string) {
        return this.api.get(`${URL.GET_S3_PATH}${id}`);
    }
    getCtaRedirectionType() {
        return this.api.get(URL.GET_CTA_REDIRECTION_TYPE);
    }
    getCtaActionType() {
        return this.api.get(URL.GET_CTA_ACTION_TYPE);
    }
}
export default Feeds;
