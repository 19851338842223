import { EditOutlined, ScheduleOutlined } from '@ant-design/icons';
import { Button, Calendar } from 'antd';
import type { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';

export interface Props {
    onCreateScheduleClick: (date: moment.Moment) => void
}

const CreateScheduleButton = styled(Button)`
  position: absolute;
  bottom: 0;
  right: -6px;
  padding: 0;
  margin: 0 12px;
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
`
const EditScheduleButton = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 16px;
  padding: 0;
  margin: 0 12px;
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
`

const schedules = [{
    date: "2023-05-25",
    id: "646f288e0b42114153d41b4c",
    name: 'friday'
},
{
    date: "2023-05-26",
    id: "646f288e0b42114153d41b4t",
    name: 'monday'
},
{
    date: "2023-05-01",
    id: "646f288e0b42114153d41b4h",
    name: 'diwali'
},
{
    date: "2023-05-05",
    id: "646f288e0b42114153d41b4d",
    name: 'pongal'
}]

const CalenderView: React.FC<Props> = ({
    onCreateScheduleClick,
}) => {

    const dateCellRender = (value: Moment) => {
        const schedule = schedules?.find(
            schedule => schedule.date === value.format('YYYY-MM-DD'),
        )
        if (!schedule) {
            return (
                <CreateScheduleButton
                    type='link'
                    onClick={onCreateScheduleClick.bind(this, value)}
                >
                    <ScheduleOutlined />
                </CreateScheduleButton>
            )
        }
        return (
            <EditScheduleButton
                style={{ right: '-6px' }}
                type='link'
                onClick={onCreateScheduleClick.bind(this, value)}
            >
                <EditOutlined />
            </EditScheduleButton>
        )
    };

    return <Calendar dateCellRender={dateCellRender} style={{ paddingLeft: '15px', paddingRight: '15px' }} />
}

export default CalenderView
