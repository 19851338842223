import React, { useEffect, useState } from 'react'
import { useAppContext } from '@/components/AppContext'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import CreateAstrologerPage from '@a4b/ui/src/modules/monetisation/organisms/CreateAstrologer'
import ManageAstrologerCreate from './CreateAstrologer/screens/CreateAstrologerStep'
import ReviewStep from './CreateAstrologer/screens/ReviewStep'
import { Segmented } from 'antd'
import { VARIANT_TYPES } from '../ManageAstrologer/CreateAstrologer/components/AstrologerCreation'
import { A4B_APPS } from '@/utils/constants'

interface ManageAstrologerScreenProps {
}

const ManageAstrologerScreen: React.FC<ManageAstrologerScreenProps> = () => {
    const { languageState, app } = useAppContext()
    const [currentStep, setCurrentStep] = useState(0)
    const [currentAgent, setCurrentAgent] = useState('')
    const [isEditJourney, setIsEditJourney] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [variant, setVariant] = useState<VARIANT_TYPES>(VARIANT_TYPES.CONTROLED)
    const isSrimandirApp = app === A4B_APPS.SRIMANDIR
    const getPageBreadcrumbs = () => [
        {
            path: isSrimandirApp ? '/monetisation/astrologer/astrologer-list' : '/astro/astrologer/astrologer-list',
            breadcrumbName: 'Astrologer List',
        },
        {
            path: isSrimandirApp ? '/monetisation/astrologer/create' : '/astro/astrologer/create',
            breadcrumbName: 'Manage Astrologer',
        },
    ]

    useEffect(() => {
        const current_step = searchParams.get('current_step')
        setCurrentStep(Number(current_step))

        const isEdit = searchParams.get('edit')
        if (!isEdit) {
            return
        }
        setIsEditJourney(true)
    }, [searchParams])

    useEffect(() => {
        const agent_id = searchParams.get('agent_id') || ''
        setCurrentAgent(agent_id)
    }, [])

    const nextHandler = (agent_id?: string) => {
        agent_id && setCurrentAgent(agent_id)
        setTimeout(() => {
            setCurrentStep(currentStep + 1)
        }, 100)
        setSearchParams(
            createSearchParams({
                edit: searchParams.get('edit') || '',
                current_step: (currentStep + 1).toString(),
                agent_id: agent_id as string
            }),
        )
    }
    const previousHandler = (agent_id?: string) => {
        setCurrentStep(currentStep - 1)
        setSearchParams(
            createSearchParams({
                edit: searchParams.get('edit') || '',
                current_step: (currentStep - 1).toString(),
                agent_id: agent_id as string
            }),
        )
    }

    const stepList = [
        {
            title: 'Setup Astrologer',
            description: 'Astrologer information',
            content: (
                <ManageAstrologerCreate
                    currentStep={currentStep}
                    isEditJourney={isEditJourney}
                    agent={currentAgent}
                    previousHandler={previousHandler}
                    nextHandler={nextHandler}
                    languageState={languageState}
                    variant={variant}
                />
            ),
        },
        {
            title: 'Reviews',
            description: 'Add Reviews for Astrologer',
            content: (
                <ReviewStep
                    currentStep={currentStep}
                    isEditJourney={isEditJourney}
                    agent={currentAgent}
                    previousHandler={previousHandler}
                    languageState={languageState}
                />
            ),
        },
    ]
    return (
        <div style={{ padding: '0 40px' }}>
            <AdminBreadcrumb routes={getPageBreadcrumbs()} />
            <Segmented
                value={variant}
                size='large'
                defaultValue="center"
                style={{ marginBottom: 8 }}
                onChange={(value) => setVariant(value as VARIANT_TYPES)}
                options={[VARIANT_TYPES.CONTROLED, VARIANT_TYPES.VARIANT]}
                disabled={!isSrimandirApp}
            />
            <CreateAstrologerPage
                steps={stepList}
                currentStep={currentStep}
            />
        </div>
    )
}

export default withDefaultLayout(ManageAstrologerScreen)
