import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import AntdFormWrapper from "../../../../../components/Forms/AntdFormWrapper";
import UploadThumbnail from "../components/UploadThumbnial";
import { useAppContext } from "../../../../../components/AppContext";

// This interface is just a example for AntdFormWrapper 

export enum DRAWER_TYPE {
    UPLOAD_THUMBNAIL = 'Upload thumbnail',
}

const EXTRACT_FILE_PATHS = ['path'];

export default function useDrawerConfig(activeDrawer?: DRAWER_TYPE): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {
    const { networkInstance } = useAppContext();

    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.UPLOAD_THUMBNAIL,
                width: 1000,
            },
            //@ts-ignore
            drawerContent: <AntdFormWrapper<AudioUploadThumbnailOptions, AudioUploadThumbnailOptions> createApi={networkInstance.clientWithHeaders.contentApi.audio.uploadThumbnail} FormFields={UploadThumbnail} extractFilePaths={EXTRACT_FILE_PATHS} />
        }
    ], [networkInstance.clientWithHeaders.contentApi.audio.uploadThumbnail])
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}