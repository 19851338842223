/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Button, Col, Drawer, Form, Input, Row, Select, Switch } from 'antd'
import { HubListItem } from '@/../../../packages/a4b-api/src/modules/Monetisation/Offerings/types'
import S3Upload, { AllowedFormats } from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'

const { Option } = Select
interface Props {
    onClose: () => void
    visible: boolean
    formSubmithandler: (values: any) => void
    form: any
    initialValues: any
    isEditJourney: boolean
    hubList: any
}
const TempleCreation: React.FC<Props> = ({
    onClose,
    visible,
    formSubmithandler,
    form,
    initialValues,
    isEditJourney,
    hubList
}) => {
    return (
        <Drawer
            title='Create Temple'
            placement='right'
            onClose={onClose}
            visible={visible}
            destroyOnClose={true}
            width={'35%'}
        >
            <Form
                form={form}
                layout='vertical'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={initialValues}
            >
                <Form.Item
                    label='Name'
                    name='name'
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 14 }}
                    rules={[{ required: true, message: 'Please input temple name!' }]}
                >
                    <Input placeholder='Enter Temple Name' />
                </Form.Item>
                <Form.Item
                    label='Description'
                    name='description'
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 14 }}
                    rules={[{ required: true, message: 'Please input temple description!' }]}
                >
                    <Input placeholder='Enter Description' />
                </Form.Item>
                <Form.Item
                    label='City'
                    name='city'
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 14 }}
                    rules={[{ required: true, message: 'Please input temple city!' }]}
                >
                    <Input placeholder='Enter City' />
                </Form.Item>
                <Form.Item
                    label='State'
                    name='state'
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 14 }}
                    rules={[{ required: true, message: 'Please input temple state!' }]}
                >
                    <Input placeholder='Enter State' />
                </Form.Item>
                <Form.Item
                    label='God Name'
                    name='god_name'
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 14 }}
                    rules={[{ required: true, message: 'Please input God Name!' }]}
                >
                    <Input placeholder='Enter God Name' />
                </Form.Item>
                <Form.Item
                    label={`Hub`}
                    name='hub_id'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 14 }}
                    rules={[{ required: true, message: 'Please select hub!' }]}
                >
                    <Select style={{ width: '100%' }} placeholder='Type'>
                        {hubList?.hubs?.map((hub: HubListItem) => (
                            <Option key={hub?.id} value={hub?.id}>
                                {hub?.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label={`God Image`}
                            name='god_image'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    min: 0,
                                    max: 1,
                                    validator: async (rules: any, values: any) => {
                                        if (values?.length < rules.min) {
                                            return Promise.reject('Please upload god image')
                                        }
                                    },
                                },
                            ]}
                            valuePropName='fileList'
                        >
                            <S3Upload
                                cdnUrl={YODA_CDN_URL}
                                preSignUrl={YODA_PRESIGN_URL}
                                allowedFileExtension={[AllowedFormats.JPEG, AllowedFormats.PNG, AllowedFormats.JPG]}
                                min={0}
                                max={1}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={`Temple Image`}
                            name='temple_image'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    min: 0,
                                    max: 1,
                                    validator: async (rules: any, values: any) => {
                                        if (values?.length < rules.min) {
                                            return Promise.reject('Please upload temple image')
                                        }
                                    },
                                },
                            ]}
                            valuePropName='fileList'
                        >
                            <S3Upload
                                cdnUrl={YODA_CDN_URL}
                                preSignUrl={YODA_PRESIGN_URL}
                                allowedFileExtension={[AllowedFormats.JPEG, AllowedFormats.PNG, AllowedFormats.JPG]}
                                min={0}
                                max={1}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Active"
                            name="active"
                            valuePropName="checked"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ required: true }]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ paddingTop: '10px' }}
                >
                    {isEditJourney ? <Button type='primary' onClick={formSubmithandler}>
                        Update Temple
                    </Button> : <Button type='primary' onClick={formSubmithandler}>
                        Create Temple
                    </Button>}
                </Form.Item>
            </Form>
        </Drawer>
    )
}
export default TempleCreation