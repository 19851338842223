import { NavMenuItem } from '../types'

const astrologerMenu: NavMenuItem[] = [
    {
        title: 'Manage Astrologer',
        id: 'astrologer',
        linkItem: [
            {
                name: 'Astrologer',
                path: '/',
                id: '/',
            },
        ],
    },
]

export default astrologerMenu
