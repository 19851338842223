import React from "react"
import { Form, Row, Col, Button } from "antd"
import S3Upload from '@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload'
import { DeleteOutlined, HolderOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import TextArea from "antd/lib/input/TextArea"

interface Props {
    fields: any
    cdnUrl: string
    preSignUrl: string
    add: () => void
    remove: (item: any) => void
    datatype: string
    form: any
}

interface FormItemsProps {
    index: number
    field: any
    remove: (item: any) => void
    cdnUrl: string
    preSignUrl: string
    itemIndex: number
    form: any
}
const FormItems = (props: FormItemsProps) => {
    const { itemIndex, field, remove, cdnUrl, preSignUrl, form } = props;
    return (
        <div style={{
            border: '1px solid #e0dcdc',
            marginRight: '0px',
            marginBottom: '10px',
            padding: '10px 0px',
            width: '100%',
            position: 'relative',
            zIndex: 10000000,
            backgroundColor: 'white',
            cursor: 'grab'
        }}>
            <Row style={{ width: '100%', justifyContent: 'space-between', alignItems: "center", paddingLeft: '10px', paddingRight: "10px" }}>
                <HolderOutlined />
                <Form.Item
                    label={`Media`}
                    name={[field.name, 'media_url']}
                    rules={[
                        {
                            required: true,
                            validator: async (rules: any, values: any) => {
                                if (!values || values.length === 0) {
                                    return Promise.reject('Please upload Media');
                                }
                            },
                        },
                    ]}
                    valuePropName='fileList'
                    style={{ paddingLeft: '0px', marginBottom: '0px' }}
                >
                    <S3Upload
                        cdnUrl={cdnUrl}
                        preSignUrl={preSignUrl}
                        min={1}
                        max={1}
                    // onChange={(fileList) => {
                    //     if (fileList?.length > 0) {
                    //         console.log({ fileList })
                    //         const fileUrl = fileList?.[0]
                    //         const fileExtension = fileUrl?.split?.('.')?.pop?.()
                    //         console.log({ fileExtension })
                    //         const mediaType = getFileFormat(fileExtension)
                    //         console.log({ mediaType })

                    //         // Set the media_type in the form
                    //         form.setFieldValue([field.name, 'media_type'], mediaType)
                    //     }
                    // }}
                    />
                </Form.Item>
                <Form.Item
                    label={`Thumbnail`}
                    name={[field.name, 'media_thumbnail']}
                    rules={[
                        {
                            required: false,
                            min: 0,
                            max: 1,
                            validator: async (rules: any, values: any) => {
                                if (values?.length < rules.min) {
                                    return Promise.reject('Please upload Media')
                                }
                            },
                        },
                    ]}
                    valuePropName='fileList'
                    style={{ paddingLeft: '0px', marginBottom: "0px" }}
                >
                    <S3Upload
                        cdnUrl={cdnUrl}
                        preSignUrl={preSignUrl}
                        min={0}
                        max={1}
                    />
                </Form.Item>
                <Form.Item
                    label={`Media Description`}
                    name={[field.name, 'media_description']}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        {
                            required: false,
                            message: 'Input Description',
                        },
                    ]}
                    style={{ flexBasis: '45%' }}
                >
                    <TextArea placeholder="Description" />
                </Form.Item>
                <Button
                    style={{ marginRight: '5px', flexBasis: '5%', }}
                    onClick={() => {
                        remove(field.name)
                    }}
                    type='ghost'
                    icon={<DeleteOutlined />}
                    size='small'
                    danger
                ></Button>
            </Row>
        </div>
    )
}

const SortableFormItems = SortableElement(FormItems);

const PujaMedia: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, datatype, form }) => {
    return (
        <>
            <Row justify="space-between" style={{ width: '100%' }}>
                {fields && fields.map((field: any, index: number) => {
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <SortableFormItems
                            index={index}
                            itemIndex={index}
                            field={field}
                            remove={remove}
                            cdnUrl={cdnUrl}
                            preSignUrl={preSignUrl}
                            form={form}
                        />
                    )
                })}
                <Col span={24} style={{ paddingTop: '10px' }}>
                    <Row justify='start'>
                        <Col span={4}>
                            <Button
                                onClick={() => {
                                    add()
                                }}
                                type='ghost'
                                icon={<PlusCircleOutlined />}
                                size='middle'
                            >
                                {datatype}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default SortableContainer(PujaMedia)