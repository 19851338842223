import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { Response } from '../../Monetisation/Offerings/types'
import {
  CityData,
  CityList,
  EnableAvatar,
  GodList,
  TempleDetails,
  TempleList,
  UpdateAvatarUrl,
} from './types'

class TemplesApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  //TEMPLES
  getTempleList = (
    paginationParams: { limit: number; offset: number },
    referenceName?: string,
    cityCode?: string,
    isActive?: boolean,
    isAvatarEnabled?: boolean,
  ) => {
    return this.api.get<TempleList>(`/content/internal/v1/temple/temples`, {
      params: {
        limit: paginationParams?.limit,
        offset: paginationParams?.offset,
        ...(referenceName && { name: referenceName }),
        ...(cityCode && { city_code: cityCode }),
        is_active: isActive,
        ...(isAvatarEnabled && { is_avatar_enabled: isAvatarEnabled }),
      },
    })
  }

  createTemple = (payload: any) =>
    this.api.post<string>(`/content/internal/v1/temple/temples`, payload)

  updateTemple = (payload: any, temple_id: string) =>
    this.api.put<string>(
      `/content/internal/v1/temple/temples/${temple_id}`,
      payload,
    )

  getTempleById = (languageCode: string, templeId: string) =>
    this.api.get<TempleDetails>(
      `/content/internal/v1/temple/temples/${templeId}`,
      {
        params: {
          language: languageCode,
        },
      },
    )

  updateTempleStatus = (temple_id: string, payload: { is_active: boolean }) => {
    this.api.patch<string>(
      `/content/internal/v1/temple/temples/${temple_id}`,
      payload,
    )
  }

  updateAvatarStatus = (payload: any) =>
    this.api.post<string>(`/content/internal/v1/temple/temples`, payload) //need to update

  //CITIES
  getCityList = (is_active?: boolean) => {
    if (is_active) {
      return this.api.get<CityList>(
        `/content/internal/v1/temple/cities?is_active=${is_active}`,
      )
    }
    return this.api.get<CityList>(`/content/internal/v1/temple/cities`)
  }

  getCityById = (cityCode: string) =>
    this.api.get<CityData>(`/content/internal/v1/temple/cities/${cityCode}`)

  updateCityStatus = (city_code: string, payload: { is_active: boolean }) =>
    this.api.patch<string>(
      `/content/internal/v1/temple/cities/${city_code}`,
      payload,
    )

  createCity = (payload: any) =>
    this.api.post<string>(`/content/internal/v1/temple/cities`, payload)

  updateCity = (cityCode: string, payload: any) =>
    this.api.put<string>(
      `/content/internal/v1/temple/cities/${cityCode}`,
      payload,
    )

  //Gods
  getGods = (languageCode: string) =>
    this.api.get<Response<GodList>>(
      `/loki/internal/v1/gods?only_user_uploaded=false&language_code=${languageCode}&info=low`,
    )

  enableAvatar = (temple_id: string, payload: EnableAvatar) =>
    this.api.patch(
      `/gw1/content/internal/v1/temple/temples/${temple_id}/enable_avatar`,
      payload,
    )

  updateAvatarUrl = (temple_id: string, payload: UpdateAvatarUrl[]) =>
    this.api.patch(
      `/gw1/content/internal/v1/temple/temples/${temple_id}/avatars`,
      payload,
    )
}

export default TemplesApi
