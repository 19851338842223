import { Button, Select } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { PlaylistsTable, Props as PlaylistsTableProps } from '../../components/MusicContent/PlaylistsTable'
import { PLAYLIST_TYPES, SUPPORTED_LANGUAGES_LIST } from '../../constants'

interface Props extends PlaylistsTableProps {
  onCreatePlaylist: () => void,
  handleLanguageFilter: (language_code: string) => void
  handlePlaylistTypeFilter: (type: string) => void
  playlistType: string
  languageCode: string | undefined,
  playlistCreate: React.ReactNode
}
const Container = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
`

export const MusicPlaylist: React.FC<Props> = ({ onCreatePlaylist, playlistCreate, ...props }) => {
  return <Container>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '12px', }}>
      <Button type='primary' onClick={onCreatePlaylist} style={{ marginRight: 15 }}>Create Playlist</Button>
      {playlistCreate}
    </div>
    <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '12px', }}>
      <Select value={props.languageCode} style={{ width: '200px' }} placeholder={"Filter by language"} onChange={props.handleLanguageFilter}>
        {SUPPORTED_LANGUAGES_LIST.map((language) => <Select.Option key={language.value} value={language.value}>
          {language.alt_name} ({language.name})
        </Select.Option>
        )}
      </Select>
      <Select value={props.playlistType} style={{ width: '200px' }} placeholder={"Filter by language"} onChange={props.handlePlaylistTypeFilter}>
        {PLAYLIST_TYPES.map((type: string) => <Select.Option key={type} value={type}>
          {type}
        </Select.Option>
        )}
      </Select>
    </div>
    <PlaylistsTable {...props} />
  </Container>
}

