import { AutoComplete, Badge, Button, Divider, Form, Input, Select, Space } from 'antd'

import React, { useEffect } from 'react'

//types
import type { ContentStatus, SupportedContentFormats } from '@a4b/api'

//constants
import {
  CONTENT_FORMATS,
  CONTENT_STATUS,
  getLanguageNameFromValue,
} from '../../../constants'

export type ContentFilterFormFields = {
  status?: ContentStatus[]
  // tags: ContentTag[]
  name?: string
  formats?: SupportedContentFormats[]
}
export interface Props {
  tags: ContentTag[]
  filter?: ContentFilterFormFields
  onApplyFilterClicked: (filter: ContentFilterFormFields) => void,
  onTagSearch: (value: string, offset: number) => void
}

const { Option } = Select
const { useForm } = Form
export const LibraryContentFilter: React.FC<Props> = ({
  tags,
  filter,
  onApplyFilterClicked,
  onTagSearch,
}) => {
  const [offset, setOffset] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');
  const [form] = useForm<ContentFilterFormFields>()

  useEffect(() => {
    if (filter) form.setFieldsValue(filter)
  }, [filter])

  const handleApplyFilter = (values: ContentFilterFormFields) => {
    setOffset(0);
    onApplyFilterClicked(values)
  }

  const handleApplyFilterFailed = (
    errorInfo: ValidateErrorEntity<ContentFilterFormFields>,
  ) => {
    console.log({ errorInfo })
  }

  const handleSearch = (key: string, _offset?: number) => {
    onTagSearch(key, _offset || offset)
  }


  return (
    <Form
      form={form}
      layout={'inline'}
      onFinish={handleApplyFilter}
      onFinishFailed={handleApplyFilterFailed}
      style={{ height: '90px', display: 'flex', alignItems: 'center' }}
    >
      {/* <Form.Item label='Title' name='name'>
        <Input placeholder='Content Title' />
      </Form.Item> */}
      <Form.Item label='Status' name='status'>
        <Select
          mode='multiple'
          style={{ minWidth: '200px', maxWidth: '500px' }}
          placeholder='Content Status'
        >
          {CONTENT_STATUS.map((status, index) => (
            <Option key={index} value={status}>
              {status}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label='Format' name='formats'>
        <Select
          mode='multiple'
          style={{ minWidth: '200px' }}
          placeholder='Content Format'
        >
          {CONTENT_FORMATS.map((format, index) => (
            <Option key={index} value={format}>
              {format.toUpperCase()}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Tags'
        name='tags'
      >
        <Select
          mode='multiple'
          showSearch
          placeholder={'Search tags'}
          filterOption={false}
          searchValue={searchText}
          onSearch={(searchText) => {
            handleSearch(searchText, 0)
            setSearchText(searchText)
            setOffset(0);
          }}
          style={{ minWidth: '400px', width: '100%' }}
          dropdownRender={(menu) => {
            return <div>
              {menu}
              <Divider></Divider>
              <Button type="text"
                onClick={() => {
                  setOffset(Math.max(offset - 1, 0));
                  handleSearch(searchText, Math.max(offset - 1, 0));
                }}
                disabled={offset === 0}
              >
                Previous Page {offset}
              </Button>
              (Page {offset + 1})
              <Button type="text" value={1 + 1}
                onClick={() => {
                  console.log('offset', offset);
                  setOffset(offset + 1);
                  handleSearch(searchText, offset + 1)
                }
                }
                disabled={tags.length < 30}
              >
                Next Page {offset + 2}
              </Button>

            </div>
          }
          }
        >
          {tags.map((tag, index) => (
            <Option key={index} value={tag.id} >

              {
                tag.active ? <Badge status="success" text={`${tag.name} (${getLanguageNameFromValue(tag.language)})`} /> : <Badge status="error" text={`${tag.name} (${getLanguageNameFromValue(tag.language)})`} />
              }


            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="name" label="Name">
        <Input></Input>
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Apply
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LibraryContentFilter
