import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import AntdFormWrapper from "../../../../../components/Forms/AntdFormWrapper";
import { FormInstance } from "antd";
import AddChapter from "../components/AddChapter";
import { useAppContext } from "../../../../../components/AppContext";
import { Chapter } from "@a4b/api/src/modules/Gita/types";

// This interface is just a example for AntdFormWrapper 
interface TestComProps {
    form: FormInstance<string>
}

const TestCom = ({ form }: TestComProps) => <div> CREATE SOMETHING 2 DATA </div>
export enum DRAWER_TYPE {
    CREATE_CHAPTER = 'Create Chapter',
    EDIT_CHAPTER = 'Edit Chapter',
}



export default function useDrawerConfig(
    getChapters: () => Promise<void>,
    setActiveDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | undefined>>,
    activeDrawer?: DRAWER_TYPE,
    chapter?: Chapter,
): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {
    const { networkInstance } = useAppContext();

    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_CHAPTER,
                width: '70vw',
            },
            drawerContent: <AntdFormWrapper<any, any> submitSuccessfulCallback={() => {
                getChapters();
                setActiveDrawer(undefined);
            }} FormFields={AddChapter} createApi={networkInstance.clientWithHeaders.gita.addChapter} />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.EDIT_CHAPTER,
                width: '70vw',
            },
            drawerContent: <AntdFormWrapper<any, any>
                submitSuccessfulCallback={() => {
                    getChapters();
                    setActiveDrawer(undefined);
                }}
                formData={chapter} FormFields={AddChapter} updateApiId={networkInstance.clientWithHeaders.gita.updateChapter} />
        }
    ], [chapter, getChapters, networkInstance.clientWithHeaders.gita.addChapter, networkInstance.clientWithHeaders.gita.updateChapter, setActiveDrawer])
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}