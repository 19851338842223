import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { PageList, Response } from './types'

class PageInfo {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getPageInfoList = () =>
    this.api.get<Response<PageList>>(`/gw1/puja/internal/v1/pages`)

  updatePages = (pageId: string, payload: any) =>
    this.api.put(`/gw1/puja/internal/v1/pages/${pageId}`, payload)
}

export default PageInfo
