import {
  Button,
  DatePicker,
  Form,
  FormProps,
  Input, message
} from 'antd'
import moment from 'moment'

import React, { useEffect } from 'react'

import { SortableCards } from '../SortableCards'
import type { SortableItemData } from '../types'

export interface CreateEditScheduleFormFields {
  name: string
  date: moment.Moment
  ordering: SortableItemData[]
}

export interface Props {
  data?: Partial<CreateEditScheduleFormFields>
  date: string,
  onSubmit: (values: CreateEditScheduleFormFields) => Promise<{ success: boolean, message: string }>
  onRemoveItem: (item: SortableItemData) => void,
  onChange: (values: CreateEditScheduleFormFields) => void
}

const { useForm } = Form

export const CreateEditScheduleForm: React.FC<Props> = ({
  data,
  date,
  onSubmit,
  onRemoveItem,
  onChange
}) => {
  const [form] = useForm<CreateEditScheduleFormFields>()

  useEffect(() => {
    if (data) form.setFieldsValue(data)
  }, [data])

  const onFinish: FormProps<CreateEditScheduleFormFields>['onFinish'] =
    async values => {
      const response = await onSubmit(values)
      response.success
        ? message.success(response.message)
        : message.error(response.message)
    }

  const onFinishFailed: FormProps<CreateEditScheduleFormFields>['onFinishFailed'] =
    error => {
      console.log(error)
    }

  const onFormValuesChange = (changedValues: Partial<CreateEditScheduleFormFields>, values: CreateEditScheduleFormFields) => {
    onChange(values)
  }

  return (
    <Form
      name='create-schedule-form'
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onFormValuesChange}
      autoComplete='off'
    >
      <Form.Item
        label='Name'
        name='name'
        rules={[{ required: true, message: 'Please input name!' }]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label='Ordering'
        name='ordering'
        rules={[
          {
            required: true,
            message: 'Please add items to sort!',
            // validator: add validator
          },
        ]}
      >
        <SortableCards limit={20} onRemoveItem={onRemoveItem} />
      </Form.Item>
      <Form.Item
        label='date'
        name='date'
        rules={[
          {
            required: true,
            message: 'Please add date to schedule!',
            // validator: add validator
          },
        ]}
      >
        <DatePicker disabled />
      </Form.Item>
      {
        moment(date) > moment().subtract(1, 'd') &&
        <Form.Item wrapperCol={{ offset: 4, span: 20 }} shouldUpdate>
          <Button type='primary' htmlType='submit'>
            Schedule Now
          </Button>
        </Form.Item>
      }
    </Form >
  )
}

export default CreateEditScheduleForm
