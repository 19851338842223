import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
//constants
import { CDN_HOST, NEW_CDN_HOST, UPLOAD_CONTENT_URL } from '@/utils/constants'
import type { RcFile } from 'antd/lib/upload'
import { debounce } from 'lodash'

import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallbackPrompt } from '@/hooks/useCallbackPrompt'

//screens
import { ContentUpload as ContentUploadScreen } from '@a4b/ui/src/admin'
import type { ContentFormFields } from '@a4b/ui/src/admin'

//types
import type {
  ContentStatus,
  ContentTag,
  CreateLibraryContentOptions,
  LibraryMediaType,
  SupportedContentFormats,
} from '@a4b/api'

import { getFormattedData } from './helper'
import { Modal } from 'antd'

const ContentUpload = () => {
  const navigate = useNavigate()
  const { libraryId } = useParams()
  const { networkInstance, userProfile } = useAppContext()
  const [tags, setTags] = useState<ContentTag[]>([])
  const [libraryContent, setLibraryContent] = useState<ContentFormFields>()
  const [status, setStatus] = useState<ContentStatus>('DRAFT')
  const [showDialog, setShowDialog] = useState<boolean>(true)
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog)


  useEffect(() => {
    async function initTags() {
      const response = await networkInstance.clientWithHeaders.contentApi.tag.getTags({ ids: libraryContent?.tags })
      setTags(response.data.data.tags || [])
    }
    initTags()
  }, [networkInstance.clientWithHeaders.contentApi.tag, libraryContent?.tags])

  useEffect(() => {
    async function initLibaryContent() {
      if (!libraryId) return
      const response = await networkInstance.clientWithHeaders.contentApi.library.getLibraryContentById(
        libraryId,
      )
      setStatus(response.data.data.status)
      const formattedData = await getFormattedData(response.data.data)
      setLibraryContent(formattedData)
    }
    if (libraryId) initLibaryContent()
  }, [libraryId, networkInstance.clientWithHeaders.contentApi.library])

  const onUploadMedia = async (
    media_type: LibraryMediaType,
    format: SupportedContentFormats,
    file: RcFile | Blob,
  ) => {
    let responseData

    if (media_type === 'image') {
      const { data } =
        await networkInstance.clientWithHeaders.contentApi.library.getPresignedUrlForImageUpload(file?.type?.toLowerCase())
      responseData = data
    } else {
      const { data } =
        await networkInstance.clientWithHeaders.contentApi.library.getPresignedUrlForContentUpload({
          format,
          libraryId,
        })
      responseData = data
    }

    const s3SignedUploadUrl = responseData.data.url
    const attachmentId = responseData.data.attachmentId

    const headers = new Headers()
    headers.append('ContentType', file.type)

    await fetch(s3SignedUploadUrl, {
      method: 'PUT',
      body: file,
      headers,
    })
    return attachmentId
  }

  const getOptionsForLibrary = async (
    {
      name,
      description,
      type,
      shareText,
      tags,
      platforms,
      formats,
      textMetaData,
      audioMetaData,
      videoMetaData,
      pdfMetaData,
      thumbnail,
      validity,
      validityCheckbox,
      showThumbnailImage,
    }: ContentFormFields,
    status: ContentStatus = 'DRAFT',
  ): Promise<CreateLibraryContentOptions> => {
    let textAttachmentId
    if (textMetaData?.attachment) {
      const blob = new Blob([textMetaData?.attachment], {
        type: 'text/x-markdown',
      })
      textAttachmentId = await onUploadMedia('content', 'text', blob)
    }

    const options: CreateLibraryContentOptions = {
      name,
      description,
      tags,
      platforms,
      type,
      formats,
      thumbnailId: thumbnail ? thumbnail[0].response || '' : '',
      shareText,
      createdBy: userProfile.email || '',
      updatedBy: userProfile.email || '',
      status,
      showThumbnailImage,
      textMetaData: textAttachmentId
        ? {
          attachmentId: textAttachmentId,
          consumptionTime:
            parseFloat(textMetaData?.consumptionTime || '') || 0,
          thumbnailId: textMetaData?.thumbnailId && textMetaData?.thumbnailId.length > 0
            ? textMetaData?.thumbnailId[0].response || ''
            : '',
        }
        : undefined,
      audioMetaData: audioMetaData?.attachment
        ? {
          attachmentId: audioMetaData.attachment[0].response || '',
          consumptionTime: parseFloat(audioMetaData?.consumptionTime) || 0,
          thumbnailId: audioMetaData?.thumbnailId
            ? audioMetaData?.thumbnailId[0].response || ''
            : '',
        }
        : undefined,
      videoMetaData: videoMetaData
        ? {
          attachmentId: videoMetaData.attachment && videoMetaData.attachment.length > 0 ? videoMetaData.attachment[0].response : undefined,
          consumptionTime: parseFloat(videoMetaData?.consumptionTime) || 0,
          thumbnailId: videoMetaData?.thumbnailId
            ? videoMetaData?.thumbnailId[0].response || ''
            : '',
          youtubeLink: videoMetaData.youtubeLink
        }
        : undefined,
      pdfMetaData: pdfMetaData?.attachment
        ? {
          attachmentId: pdfMetaData.attachment[0].response || '',
          consumptionTime: parseFloat(pdfMetaData?.consumptionTime) || 0,
          thumbnailId: pdfMetaData?.thumbnailId
            ? pdfMetaData?.thumbnailId[0].response || ''
            : '',
        }
        : undefined,
    }

    if (validity) {
      options.validFrom = validity[0].startOf('day').toISOString()
      options.validTo = validity[1].endOf('day').toISOString()
    }
    if (validityCheckbox !== undefined) {
      // @ts-ignore
      options['validity'] = validityCheckbox
    }

    return options
  }

  const handleSaveContent = async (values: ContentFormFields) => {
    setShowDialog(false)
    confirmNavigation()
    const options = await getOptionsForLibrary(values, 'DRAFT')
    if (!libraryId) {
      try {
        await networkInstance.clientWithHeaders.contentApi.library.createLibraryContent(
          options,
        )
        navigate('/content/creator-listing')
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    } else {
      try {

        await networkInstance.clientWithHeaders.contentApi.library.updateLibraryContentById(
          libraryId,
          options,
        )
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    }
  }

  const handlePublishContent = async () => {
    if (!libraryId) return false

    setShowDialog(false)
    confirmNavigation()

    try {
      await networkInstance.clientWithHeaders.contentApi.library.updateLibraryContentById(
        libraryId,
        { status: 'PUBLISHED', updatedBy: userProfile.email },
      )
      navigate('/content/moderator-listing')
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const handleSendToQC = async (values: ContentFormFields) => {
    const options = await getOptionsForLibrary(values, 'READY_TO_BE_MODERATED')

    setShowDialog(false)
    confirmNavigation()
    if (!libraryId) {
      try {
        await networkInstance.clientWithHeaders.contentApi.library.createLibraryContent(
          options,
        )
        navigate('/content/creator-listing')
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    } else {
      try {

        await networkInstance.clientWithHeaders.contentApi.library.updateLibraryContentById(
          libraryId,
          options,
        )
        navigate('/content/creator-listing')
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    }
  }

  const handleRequestChanges = async (values: ContentFormFields) => {
    if (!libraryId) return false

    setShowDialog(false)
    confirmNavigation()

    try {
      await networkInstance.clientWithHeaders.contentApi.library.updateLibraryContentById(
        libraryId,
        { status: 'CHANGE_REQUESTED', updatedBy: userProfile.email },
      )
      navigate('/content/moderator-listing')
      return true
    } catch (error) {
      return false
    }
  }

  const onTagSearch = useCallback(
    async (value: string) => {
      const response = await networkInstance.clientWithHeaders.contentApi.tag.getTags({
        name: value,
        limit: 30,
      })
      setTags(response.data.data.tags || [])
    },
    [networkInstance.clientWithHeaders.contentApi.tag],
  )

  const tagSearchDebounceFn = debounce(onTagSearch, 1000)

  const handleOk = () => {
    setShowDialog(false)
    confirmNavigation()
  }

  const handleCancel = () => {
    setShowDialog(false)
    cancelNavigation()
  }

  return (
    <>
      <ContentUploadScreen
        status={status}
        data={libraryContent}
        uploadUrl={UPLOAD_CONTENT_URL}
        cdnHost={NEW_CDN_HOST}
        tags={tags} // pass tags relevant to the id selected
        uploadFunction={onUploadMedia}
        onSave={handleSaveContent}
        onPublish={handlePublishContent}
        onRequestChanges={handleRequestChanges}
        onSendQC={handleSendToQC}
        onTagSearch={tagSearchDebounceFn}
      />
      <Modal title='Are you sure you want to move?' visible={showPrompt} onOk={handleOk} onCancel={handleCancel}>
        <p>You will lose your existing changes if you move!</p>
      </Modal>
    </>
  )
}

export default withDefaultLayout(ContentUpload)
