import { throwError } from '../../../utils/helper';
type Item = {
  image_attachment_id: string;
  title: string;
  subtitle: string;
  r_community_id: string;
  cta_text: string;
};
type HeaderTags = {
  text: string;
  color: string;
};
class Social {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header!: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header_tags!: HeaderTags[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items!: Item[];

  getSerializedData = (widgetFormData: any) => {
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: any) => {
    let itemModified = widgetFormData.items.map((item: Item) => {
      return { ...item };
    });
    widgetFormData.items = itemModified;
    return widgetFormData;
  };

  headerValidator = async (rule: any, value: any) => {
    return;
  };
  headerTagsTextValidator = async (rule: any, value: any) => {
    if (!value) throwError('Header tags text required!');
  };
  headerTagsColorValidator = async (rule: any, value: any) => {
    if (!value) throwError('Header tags text required!');
  };
  imageAttachmentValidator = async (rule: any, value: any) => {
    if (!value) throwError('Image Attachment required!');
  };
  titleValidator = async (rule: any, value: any) => {
    if (!value) throwError('Title required!');
  };
  subtitleValidator = async (rule: any, value: any) => {
    if (!value) throwError('Subtitle required!');
  };
  communityIdValidator = async (rule: any, value: any) => {
    if (!value) throwError('Community Id required!');
  };
  ctaTextValidator = async (rule: any, value: any) => {
    if (!value) throwError('CTA text required!');
  };
}
export default Social;
export const SocialInstance = new Social();
