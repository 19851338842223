
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination from "@/hooks/useAntdPagination";
import { useSearchParams, useParams, Link } from "react-router-dom";
import { Chapter } from "@a4b/api/src/modules/Gita/types";
import { ArrowLeftOutlined, BackwardOutlined } from "@ant-design/icons";




const GitaSholkas = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const { networkInstance } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pagination, setPagination } = useAntdPagination();
  const [chapter, setChapter] = useState<Chapter>();
  const [shlokas, setShlokas] = useState<any[]>([]);
  const [shlokaId, setShlokaId] = useState<string>();
  const { id } = useParams();

  const getChapter = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.gita.getChapter(id as string)
      setChapter(res.data.data.chapter);
    } catch (error) {

    }
  }, [id, networkInstance.clientWithHeaders.gita])

  const getChapterShlokas = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.gita.getChapterShlokas(id as string)
      const shlokas: any[] = res.data.data.shlokas.map((_shloka: any) => {
        return {
          ..._shloka,
          banner_attachment: _shloka.banner_attachment ? {
            ..._shloka.banner_attachment,
            path: new URL(_shloka.banner_attachment.url).pathname
          } : null,
          thumbnail_attachment: _shloka.thumbnail_attachment ? {
            ..._shloka,
            path: new URL(_shloka.thumbnail_attachment.url).pathname
          } : null,
          content: Object.keys(_shloka.content).map((language: string) => {
            return {
              language,
              ..._shloka.content[language],
              audio_attachment: _shloka.content[language].audio_attachment ? {
                ..._shloka.content[language].audio_attachment,
                path: _shloka.content[language].audio_attachment ? new URL(_shloka.content[language].audio_attachment.url).pathname : ''
              } : null,
              video_attachment: _shloka.content[language].video_attachment ? {
                ..._shloka.content[language].video_attachment,
                path: _shloka.content[language].video_attachment ? new URL(_shloka.content[language].video_attachment.url).pathname : ''
              } : null,
            }
          })
        }
      })
      setShlokas(shlokas)
    } catch (error) {
      console.log({ error })
    }
  }, [id, networkInstance.clientWithHeaders.gita])

  useEffect(() => {
    getChapter();
    getChapterShlokas();
  }, [getChapter, getChapterShlokas])

  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }



  const drawerConfig = useDrawerConfig(getChapterShlokas, shlokas.find((_shloka) => _shloka.id === shlokaId), setActiveDrawer, chapter, activeDrawer);
  const { drawerProps, drawerContent } = drawerConfig;
  const tableConfig = useTableConfig(shlokas, setShlokaId, pagination, setPagination, setDrawer,);


  const onCreateRashipal = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_SHLOKA);
  }

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  }

  return <SocialScreenContainer
    title={`Shlokas ( ${chapter?.meta.title} )`}
    filtersUI={<Filters />}
    extra={<>
      <Button type="primary" onClick={onCreateRashipal}> Create shlokas </Button>
      <Link to="/gita/chapters">
        <Button style={{ marginLeft: '10px' }}> <ArrowLeftOutlined /> Back </Button>
      </Link>
    </>}
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />

};

export default withDefaultLayout(GitaSholkas)