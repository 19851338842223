
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import { Button, Collapse, Select, Slider, Tag } from "antd";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import { Experiments, PlatformConfig } from "@a4b/api/src/modules/AbExperiments/types";
import { renderDate } from "../../../../components/HelperComponents";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import SliderFilter from "./components/SliderFilter";
import exp from "constants";

const { Panel } = Collapse;

const StyledPanel = styled(Panel)`
  & .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
  }
`

const StyledDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
    margin: 10px 0px;
    background: #fafafa;
    
  `
const platformColor: any = {
  WEB: "#ffe7ba",
  IOS: "#ffccc7",
  ANDROID: "#d9f7be"
}

export const options = {
};

function groupBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K): Record<K, T[]> {
  return list.reduce((result, item) => {
    (result[getKey(item)] = result[getKey(item)] || []).push(item);
    return result;
  }, {} as Record<K, T[]>);
}

const AbExperimentsManager = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const { networkInstance } = useAppContext();
  const [experiments, setExperiments] = useState<Experiments[]>([])
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeExperimentWithInRange, setActiveExperimentWithInRange] = useState([1, 100]);
  const [hideTooltip, setHideTooltip] = useState(false);

  const platforms = searchParams.get("platforms") ? searchParams.get("platforms")?.split(",") : undefined;

  useEffect(() => {
    if (searchParams.get("drawer_type")) {
      setActiveDrawer(searchParams.get("drawer_type") as DRAWER_TYPE || undefined)
    }
  }, [searchParams])

  const getExperiments = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.abExperiments.getExperiments();
      setExperiments(res.data.data.experiments.sort((a, b) => b.created_at - a.created_at))
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.abExperiments])

  useEffect(() => {
    getExperiments()
  }, [getExperiments])

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
    setSearchParams({});
  }

  const systemIds = experiments.find((experiment) => experiment.id === searchParams.get("experiment_id"))
    ?.variants.find((variant) => variant.code === searchParams.get("variant_code"))?.forced_system_ids || []

  const systemIdForm = experiments.length > 0 && (searchParams.get("experiment_id") && searchParams.get("variant_code")) ? {
    experiment_id: searchParams.get("experiment_id") as string,
    variant_code: searchParams.get("variant_code") as string,
    system_ids: systemIds
  } : undefined
  const drawerConfig = useDrawerConfig(
    setActiveDrawer,
    getExperiments,
    onDrawerClose,
    systemIdForm,
    experiments.find((experiment) => (experiment.id === searchParams.get("editId"))),
    activeDrawer,
  );
  const { drawerProps, drawerContent } = drawerConfig;
  // const tableConfig = useTableConfig(experiments, setActiveDrawer, pagination, setPagination, setDrawer,);


  const onCreateAbExperiments = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_EXPERIMENTS);
  }

  let experimentsFilterWithPlatform = experiments;
  if (platforms && platforms.length > 0) {
    experimentsFilterWithPlatform =
      experiments.filter((experiment) => {
        return experiment.applicable_platforms?.some((platform) => {
          return platforms?.includes(platform.type)
        })
      });
  }

  const experimentsFilterWithInRange = experimentsFilterWithPlatform.map((experiment) => {
    const variants = experiment.variants.filter((variant) => {
      return (variant.gte >= activeExperimentWithInRange[0] && variant.gte <= activeExperimentWithInRange[1])
        || (variant.lte >= activeExperimentWithInRange[0] && variant.lte <= activeExperimentWithInRange[1])
    })
    const experimentCopy = { ...experiment }
    experimentCopy.variants = variants;
    return experimentCopy
  }).filter((experiment) => { return experiment.variants.length !== 0 })
  const experimentGroupByCode = groupBy(experimentsFilterWithInRange, ({ code }) => code) as unknown as { [key: string]: Experiments[] }

  return <SocialScreenContainer
    title={"Manage AB Experiments"}
    extra={
      <div style={{ display: "flex", alignItems: "center", gap: '40px' }}>
        <div>
          filter by platform: &nbsp;
          <Select style={{ width: "300px" }} value={platforms} onChange={(platforms) => {
            searchParams.set("platforms", platforms.join(","))
            setSearchParams(searchParams);
          }} allowClear mode="multiple">
            <Select.Option value={PlatformConfig.ANDROID}>{PlatformConfig.ANDROID}</Select.Option>
            <Select.Option value={PlatformConfig.IOS}>{PlatformConfig.IOS}</Select.Option>
            <Select.Option value={PlatformConfig.WEB}>{PlatformConfig.WEB}</Select.Option>
          </Select>
        </div>
        <div style={{ width: "300px" }}>
          <SliderFilter setHideTooltip={setHideTooltip} tooltipVisible={!activeDrawer} setActiveExperimentWithInRange={setActiveExperimentWithInRange} />
        </div>
        <Button onClick={onCreateAbExperiments}> Create AB experiments </Button>
      </div>
    }
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
    content={<Collapse >
      {
        Object.keys(experimentGroupByCode).map((key, expreimentIndex) => {
          if (!experimentGroupByCode) return null;
          return <StyledPanel header={<div style={{ marginLeft: "10px" }}>
            <h3 style={{ display: 'inline' }}>{key}</h3>
            &nbsp;&nbsp;&nbsp; <i>{experimentGroupByCode[key]?.[0]?.description}</i>
            <div style={{ float: "right" }}>
              {
                experimentGroupByCode[key][0].applicable_platforms?.map((platform, index) => {
                  return <Tag key={index} style={{ marginLeft: "10px", background: platformColor[platform.type] }} >
                    {platform.type}
                    <b style={{ fontSize: "12px", paddingLeft: 20 }}>
                      (
                      {platform.gte} to&nbsp;
                      {platform.lte}
                      )
                    </b>
                  </Tag>
                })
              }
            </div>
          </div>} key={key}>
            <p>{experimentGroupByCode[key]?.map((item: any, objectIndex: number): ReactNode => {
              return <StyledDiv style={{ gap: "50px", position: "relative", borderLeft: objectIndex === 0 ? '5px solid #389e0d' : '5px solid #d9d9d9' }}>
                <div style={{ position: "absolute", bottom: "0", right: "0" }}>
                  {
                    experimentGroupByCode[key][0].applicable_platforms?.map((platform, index) => {
                      return <span key={index} style={{ background: platformColor[platform.type], fontSize: "10px", padding: 4 }} >
                        {platform.type}
                        <b style={{ fontSize: "10px", paddingLeft: 20 }}>
                          (
                          {platform.gte} to&nbsp;
                          {platform.lte}
                          )
                        </b>
                      </span>
                    })
                  }
                </div>
                <div style={{ flexGrow: 1 }}>
                  {
                    item.variants?.map((variant: any, variantIndex: number) => {
                      return <div style={{ display: 'flex', padding: '30px 0px', gap: '20px', alignItems: 'center' }}>
                        <div style={{ width: '200px', overflowWrap: "break-word" }}>
                          {variant.code}
                          <br></br>
                          {objectIndex === 0 &&
                            <Button
                              type="link"
                              size="small"
                              style={{ fontSize: '12px', paddingLeft: 0 }}
                              onClick={() => {
                                searchParams.set("drawer_type", DRAWER_TYPE.UPDATE_SYSTEM_IDS)
                                searchParams.set("experiment_id", item.id)
                                searchParams.set("variant_code", variant.code)
                                setSearchParams(searchParams);
                              }}
                            >
                              Forced System IDs
                            </Button>
                          }
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          <Slider disabled range style={{ paddingTop: "20px", right: '50px' }}
                            value={[variant.lte, variant.gte]}
                            tooltipVisible={(!activeDrawer && !hideTooltip)}
                            getTooltipPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
                          />
                        </div>
                        <div style={{ width: '180px' }}>
                          {variant.description}
                        </div>
                      </div>
                    })
                  }
                </div>

                <div style={{ width: '110px' }}>{renderDate(item.created_at)}</div>
                <div>{item.created_by}</div>
                <div style={{ width: '100px' }}>
                  {objectIndex === 0 &&
                    <div style={{ display: "flex", flexDirection: "column", }}>
                      <Button onClick={() => {
                        setSearchParams({ editId: experimentGroupByCode[key][0].id })
                        setActiveDrawer(DRAWER_TYPE.CREATE_EXPERIMENTS)
                      }} type="link"> Clone </Button>

                    </div>
                  }
                </div>
              </StyledDiv>
            })}
            </p>
          </StyledPanel>
        })
      }
    </Collapse>}
  />
};

export default withDefaultLayout(AbExperimentsManager)