export interface AbexperimentPayload {
  code: string
  description: string
  created_by: string
  variants: Variant[]
}

export interface Experiments {
  id: string
  code: string
  description: string
  created_by: string
  created_at: number
  variants: Variant[]
  variant: Variant
  applicable_platforms?: ApplicationPlatform[]
}


export interface ApplicationPlatform {
  type: PlatformConfig
  gte: number
  lte: number
}

export enum PlatformConfig {
  WEB = 'WEB',
  IOS = 'IOS',
  ANDROID = 'ANDROID'
}

export interface SystemIdPayload {
  experiment_id: string
  variant_code: string
  system_ids: string[]
}
export interface Variant {
  code: string
  description: string
  gte: number
  lte: number
  forced_system_ids: string[]
}
