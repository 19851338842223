import { throwError } from "../../../utils/helper";

type Item = {
  title: string;
};

class MenuNotification {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items: Item[] = [];

  getSerializedData = (widgetFormData: MenuNotification) => {
    let itemModified = widgetFormData?.items?.map((item: Item) => {
      return {
        ...item,
      };
    });
    widgetFormData.items = itemModified;
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: MenuNotification) => {
    return widgetFormData;
  };

  titleValidator = async (rule: any, value: any) => {
    if (!value) throwError("Title required!");
  };
}
export default MenuNotification;
export const MenuNotificationInstance = new MenuNotification();
