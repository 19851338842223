import { throwError } from "../../../utils/helper";

type Item = {
  image_attachment_id: string;
  title: string;
  subtitle: string;
  cta_text: string;
  share_text: string;
};

class AppShare {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items: Item[] = [];

  getSerializedData = (widgetFormData: AppShare) => {
    let itemModified = widgetFormData?.items?.map((item: Item) => {
      return {
        ...item,
      };
    });
    widgetFormData.items = itemModified;
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: AppShare) => {
    return widgetFormData;
  };

  attachmentValidator = async (rule: any, value: any) => {
    if (!value) throwError("Thumbnail url required!");
  };
  titleValidator = async (rule: any, value: any) => {
    if (!value) throwError("Title required!");
  };
  subtitleValidator = async (rule: any, value: any) => {
    if (!value) throwError("SubTitle required!");
  };
  thumbnailImageValidator = async (rule: any, value: any) => {
    if (!value) throwError("Image Attachment required.");
  };
  ctaTextValidator = async (rule: any, value: any) => {
    if (!value) throwError("CTA Text required!");
  };
  shareTextValidator = async (rule: any, value: any) => {
    if (!value) throwError("Share Text required!");
  };
}
export default AppShare;
export const AppShareInstance = new AppShare();
