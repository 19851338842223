import { Form, Select, Input, Button, InputNumber, Checkbox, Radio } from "antd";
import { REQUIRED_RULE } from "../../../../../utils/form.validation";
import { CONDITION_TYPES } from "../utils/constants";
import { ConditionType, Condition } from "../utils/types";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { DeleteFilled } from "@ant-design/icons";
import { OPERATORS } from "../../Journey/utils/types";

const StyledSelect = styled(Select)`
    min-width: 200px;
`

const renderCompressionFeild = (property: {
    data_type: "STRING" | "INT" | "BOOLEAN",
    options: string[]
}, operator: OPERATORS) => {
    let field: JSX.Element | undefined = undefined;
    let name = "value_string";

    if (operator === OPERATORS.EXISTS) {
        return null;
    } else {
        if (property.options.length > 0) {
            field = <Select showSearch>
                {property.options.map((option) => {
                    return <Select.Option key={option} value={option}>{option}</Select.Option>
                })}
            </Select>
        } else {
            switch (property.data_type) {
                case "STRING":
                    field = <Input></Input>
                    break;
                case "INT":
                    field = <InputNumber></InputNumber>
                    name = "value_int64";
                    break;

                default:
                    field = <Input></Input>;
                    break;
            }
        }
    }

    return <Form.Item name={['property', name]} label="Enter comparison value" >
        {field}
    </Form.Item>
}

const renderOperatorsOptions = (property: {
    data_type: "STRING" | "INT" | "BOOLEAN",
    options: string[]
}) => {

    switch (property.data_type) {
        case "INT":
            return <>
                <Select.Option value={OPERATORS.EQUAL}>{OPERATORS.EQUAL}</Select.Option>
                <Select.Option value={OPERATORS.NOT_EQUAL}>{OPERATORS.NOT_EQUAL}</Select.Option>
                <Select.Option value={OPERATORS.EXISTS}>{OPERATORS.EXISTS}</Select.Option>
                <Select.Option value={OPERATORS.GREATER_THAN_OR_EQUAL}>{OPERATORS.GREATER_THAN_OR_EQUAL}</Select.Option>
                <Select.Option value={OPERATORS.LESS_THAN_OR_EQUAL}>{OPERATORS.LESS_THAN_OR_EQUAL}</Select.Option>
            </>
        case "STRING":
            return <>
                <Select.Option value={OPERATORS.EQUAL}>{OPERATORS.EQUAL}</Select.Option>
                <Select.Option value={OPERATORS.NOT_EQUAL}>{OPERATORS.NOT_EQUAL}</Select.Option>
                <Select.Option value={OPERATORS.EXISTS}>{OPERATORS.EXISTS}</Select.Option>
            </>

        case "BOOLEAN":
            return <>
                <Select.Option value={OPERATORS.EQUAL}>{OPERATORS.EQUAL}</Select.Option>
                <Select.Option value={OPERATORS.NOT_EQUAL}>{OPERATORS.NOT_EQUAL}</Select.Option>
                <Select.Option value={OPERATORS.EXISTS}>{OPERATORS.EXISTS}</Select.Option>
            </>
        default:
            return <>
                <Select.Option value={OPERATORS.EQUAL}>{OPERATORS.EQUAL}</Select.Option>
                <Select.Option value={OPERATORS.NOT_EQUAL}>{OPERATORS.NOT_EQUAL}</Select.Option>
                <Select.Option value={OPERATORS.EXISTS}>{OPERATORS.EXISTS}</Select.Option>
            </>
    }

}

enum SELECT_EVENT_TYPE {
    'EVENT_NAME' = 'Event name',
    'EVENT_PROPERTY' = 'Event property'
}

export interface ConditionFormProps {
    modifyCondition: (identifier: string | undefined, condition?: Condition) => void,
    identifier: string,
    formData?: Partial<Condition>,
    eventsAndProperties: any;
    commonEventProperties: any;
}

const ConditionForm = (props: ConditionFormProps) => {
    const [form] = Form.useForm();
    const [isTouched, setIsTouched] = useState(false);
    const { modifyCondition, identifier, formData, eventsAndProperties, commonEventProperties } = props;
    const eventProperties = { ...commonEventProperties };
    const eventKeys = Object.keys(eventsAndProperties)
    for (let i = 0; i < eventKeys.length; i++) {
        const obj = eventsAndProperties[eventKeys[i]]
        const keys = Object.keys(obj);
        for (let j = 0; j < keys.length; j++) {
            eventProperties[keys[j]] = obj[keys[j]]
        }
    }

    const selectedEventType = Form.useWatch('filter_type', form);
    const type = Form.useWatch('type', form);
    const operator = Form.useWatch(['property', 'operator'], form);
    const path = Form.useWatch(['property', 'path'], form);
    useEffect(() => {
        form.setFieldsValue(formData)
    }, [form, formData])


    return <Form
        size="small"
        layout="horizontal"
        form={form}
        initialValues={formData}
        onValuesChange={() => { setIsTouched(true) }}
        onFinish={(values) => {
            delete values.filter_type;
            if (values.type === ConditionType.EVAL) {
                modifyCondition(identifier, values);
            } else {
                // @ts-ignore
                values.conditions = formData?.conditons || []
                modifyCondition(identifier, values);
            }
            form.resetFields()
            setIsTouched(false)
        }}
    >
        <Form.Item rules={REQUIRED_RULE} name={"type"} label={"Type"}>
            <StyledSelect>
                {
                    CONDITION_TYPES.map((conditionType) => {
                        return <Select.Option key={conditionType} value={conditionType}>{conditionType}</Select.Option>
                    })
                }
            </StyledSelect>
        </Form.Item>
        {
            type === ConditionType.EVAL && <div>
                <Form.Item label={"Select filter type"} name={'filter_type'} rules={REQUIRED_RULE} initialValue={SELECT_EVENT_TYPE.EVENT_PROPERTY}>
                    <Radio.Group onChange={(e) => {
                        if (e.target.value === SELECT_EVENT_TYPE.EVENT_NAME) {
                            form.setFieldValue(['property', 'path'], 'event.super_property.name');
                            form.setFieldValue(['property', 'operator'], 'STRING');
                            form.setFieldValue(['property', 'value_string'], undefined);
                        } else {
                            form.setFieldValue(['property', 'path'], undefined);
                            form.setFieldValue(['property', 'operator'], undefined);
                            form.setFieldValue(['property', 'value_string'], undefined);
                        }
                    }} >
                        <Radio.Button value={SELECT_EVENT_TYPE.EVENT_PROPERTY}>{SELECT_EVENT_TYPE.EVENT_PROPERTY}</Radio.Button>
                        <Radio.Button value={SELECT_EVENT_TYPE.EVENT_NAME}>{SELECT_EVENT_TYPE.EVENT_NAME}</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                {
                    (selectedEventType === SELECT_EVENT_TYPE.EVENT_PROPERTY || selectedEventType === undefined) ? <>

                        <Form.Item rules={REQUIRED_RULE} name={["property", "path"]} label="Path">
                            <Select showSearch allowClear>
                                {
                                    Object.keys(eventProperties).map((property) => {
                                        return <Select.Option key={property} value={property}>{property}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name={["property", "operator"]} label="Operator" rules={REQUIRED_RULE}>
                            <Select showSearch>
                                {path && eventProperties[path] && renderOperatorsOptions(eventProperties[path])}
                            </Select>
                        </Form.Item>
                        {path && eventProperties[path] && operator && renderCompressionFeild(eventProperties[path], operator)}
                    </> : <>
                        <Form.Item rules={REQUIRED_RULE} name={["property", "path"]} label="Path" initialValue={'event.super_property.name'}>
                            <Input disabled ></Input>
                        </Form.Item>
                        <Form.Item name={["property", "operator"]} label="Operator" rules={REQUIRED_RULE} initialValue={'STRING'}>
                            <Input disabled ></Input>
                        </Form.Item>
                        <Form.Item name={["property", 'value_string']} label={"Value"} rules={REQUIRED_RULE}>
                            <Select showSearch>
                                {Object.keys(eventsAndProperties).map((eventName) => {
                                    return <Select.Option value={eventName}>{eventName}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </>
                }
            </div>
        }
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Button disabled={!isTouched} htmlType="submit">{formData ? "Update" : "Create"}</Button>
            {
                formData && <>
                    {
                        isTouched &&
                        <Button onClick={(() => {
                            form.setFieldsValue(formData);
                            setIsTouched(false);
                        })}> Reset </Button>
                    }
                    <Button onClick={((e) => {
                        modifyCondition(identifier, undefined);
                    })}><DeleteFilled /> Delete </Button>
                </>
            }
        </div>
    </Form >

}

export default ConditionForm;