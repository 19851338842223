//types
import { Shrine } from '@/../../../packages/a4b-api/src/modules/Temple/types'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { PlusSquareOutlined } from '@ant-design/icons'

import {
  Badge,
  Button,
  Card,
  Col, DatePicker, Form, Image, Pagination,
  PaginationProps,
  Row,
  Select,
  Space, Table,
  TablePaginationConfig,
  message
} from 'antd'

import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { SUPPORTED_LANGUAGES_LIST } from '@/utils/constants'
import CreateActionCards from './createActionCard'
import { transpileFormData, parseFormData } from './helper'

const defaultPageSize = 10
const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const ManageStories: React.FC = () => {
  const { networkInstance } = useAppContext()
  const [actionCardsData, setActionCards] = useState<Shrine.ActionCards[]>()
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 0,
    pageSize: defaultPageSize,
  })

  const [form] = Form.useForm();
  const [selectedLanguage, setSelectedLanguage] = useState<string>('hi')
  const [start_date, setStartDate] = useState<any>()
  const [end_date, setEndDate] = useState<any>()
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [totalRecords, setTotalRecords] = useState(defaultPageSize)
  const [currentActionCard, setCurrentActionCard] = useState<any>()

  const [isEditJourney, setIsEditJourney] = React.useState(false)

  const closeActionCardCreate = () => {
    setShowCreateForm(false)
    isEditJourney && setIsEditJourney(false)
    setCurrentActionCard(undefined)
    setPagination({ current: 1, pageSize: 10 })
  }

  const triggerEdit = (actionCard: any, isClone: boolean) => {
    const formData = parseFormData(actionCard, isClone)
    setCurrentActionCard(formData)
    setInitialValues(formData)
    setShowCreateForm(true)
    if (!isClone) {
      setIsEditJourney(true)
    }
  }

  const getActionCards = useCallback(async (paginationData: any, language) => {
    try {
      const actionCards =
        await networkInstance.clientWithHeaders.shrineApi.getActionCards(paginationData, language)
      if (actionCards?.data?.data?.actionCards?.length >= (pagination.pageSize || defaultPageSize)) {
        setTotalRecords(actionCards?.data?.data?.count)
      }
      setActionCards(actionCards?.data?.data?.actionCards)

    } catch (error) {
      console.log('Cors error:', error)
    }
  }, [networkInstance.clientWithHeaders.shrineApi, pagination])

  useEffect(() => {
    const params: any = {
      limit: pagination.pageSize,
      offset:
        Number((pagination?.current || 1) - 1) * Number(pagination.pageSize)
    }
    if (start_date) {
      params["start_time"] = start_date?.valueOf()
    }
    if (end_date) {
      params["end_time"] = end_date?.valueOf()
    }
    getActionCards(params, selectedLanguage)
  }, [getActionCards, networkInstance.clientWithHeaders.shrineApi.getActionCards, pagination, selectedLanguage, start_date, end_date])


  const createActionCards = useCallback(async (formData: any) => {
    try {
      await networkInstance.clientWithHeaders.shrineApi.createActionCard(formData)
      message.success("Action card created sucessfully.")
      closeActionCardCreate()
    } catch (error: any) {
      const statusCode = error?.response?.status
      const errorMessage = error?.response?.data?.error?.args?.validation_errors?.target_config?.error
      message.error(`Status code: ${statusCode} | Error message: ${errorMessage}`)
    }
  }, [networkInstance.clientWithHeaders.shrineApi])

  const updateActionCards = useCallback(async (formData: any) => {
    try {
      await networkInstance.clientWithHeaders.shrineApi.updateActionCard(formData, currentActionCard?.id)
      message.success("Action card updated sucessfully.")
      closeActionCardCreate()
    } catch (error: any) {
      const statusCode = error?.response?.status
      const errorMessage = error?.response?.data?.error?.args?.validation_errors?.target_config?.error
      message.error(`Status code: ${statusCode} | Error message: ${errorMessage}`)
    }
  }, [networkInstance.clientWithHeaders.shrineApi, currentActionCard])

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (
    current: number,
    pageSize: number,
  ) => {
    setPagination({ current: ((current || 0)), pageSize })
  }

  const dataColumns = [
    {
      title: 'Card Code',
      dataIndex: 'code',
      key: 'code',
      width: '10%',
      render: (text: any, record: any, index: any) => <span>{text}</span>,
    },
    {
      title: 'Default Text',
      dataIndex: ['content', 'default', 'text'],
      key: 'default_text',
      width: '15%',
      render: (text: any, record: any, index: any) => <span>
        {text}
      </span>,
    },
    {
      title: 'Default CTA Text',
      dataIndex: ['content', 'default', 'cta_text'],
      key: 'default_cta_text',
      width: '15%',
      render: (text: any, record: any, index: any) => <span>
        {text}
      </span>,
    },
    {
      title: 'Icon',
      dataIndex: ['content', 'icon_attachment', 'url'],
      key: 'icon_attachment',
      width: '15%',
      render: (text: any, record: any, index: any) => <span>
        <Image src={text} width={50} height={50} />
      </span>,
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
      width: '10%',
      render: (text: any, record: any, index: any) => <span>{text > 0 ? moment(text)?.format('DD-MM-YYYY HH:mm:ss') : text}</span>,
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
      width: '10%',
      render: (text: any, record: any, index: any) => <span>{text > 0 ? moment(text)?.format('DD-MM-YYYY HH:mm:ss') : text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      width: '8%',
      render: (text: string, record: any) => {
        if (record?.active === true) {
          return <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', height: '100%' }}>
            <Badge.Ribbon text="Active" color="green" />
          </div>
        } else {
          return <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', height: '100%' }}>
            <Badge.Ribbon text="Inactive" color="red" />
          </div>
        }
      }
    },
    {
      title: 'Clone',
      key: `clone_${+new Date()}`,
      width: '5%',
      render: (text: any, record: any, index: any) => {
        return (
          <Row style={{ width: 100 }}>
            {<Col span={12}>
              <Button type='default' onClick={() => triggerEdit(record, true)}>Clone</Button>
            </Col>}
          </Row>
        )
      },
    },
    {
      title: 'Action',
      key: `action_${+new Date()}`,
      width: '5%',
      render: (text: any, record: any, index: any) => {
        return (
          <Row style={{ width: 100 }}>
            {<Col span={12}>
              <Button type='link' onClick={() => triggerEdit(record, false)}>Edit</Button>
            </Col>}
          </Row>
        )
      },
    },
  ]

  const drawerCloseHandler = () => {
    setShowCreateForm(false)
    form?.resetFields()
  }

  const onFinishHandler = (values: any) => {
    const formData = transpileFormData(values, selectedLanguage)
    if (isEditJourney) {
      updateActionCards(formData)
    } else {
      createActionCards(formData)
    }
  }
  return (

    <Container
      title='Manage Action Cards'
      style={{ margin: '10px' }}
      extra={
        <Row style={{ width: 200 }} justify='space-between'>
          <Col span={8}>
            <Button type='primary' onClick={() => {
              form?.resetFields()
              setShowCreateForm(true)
            }}>
              <PlusSquareOutlined /> Create Action Cards

            </Button>
          </Col>
        </Row>
      }
    >
      <Space direction={'vertical'} style={{ width: '100%' }}></Space>
      <Row>
        <Col span={6}>
          <Space>
            <div>
              Language : &nbsp;
            </div>
            <Select
              style={{ width: 200 }}
              onChange={(val) => { setSelectedLanguage(val) }}
              value={selectedLanguage}
              showSearch
              optionLabelProp="label"
              optionFilterProp="label"
            >
              {SUPPORTED_LANGUAGES_LIST.map((item) => <Select.Option key={item.code} label={item.alt_name} value={item.value}>{item.alt_name} ({item.name})</Select.Option>)}
            </Select>
          </Space>
        </Col>
        <Col span={7}>
          Filter By Start Date: &nbsp; <DatePicker onChange={(value: any) => setStartDate(value)} allowClear showTime />
        </Col>
        <Col span={6}>
          Filter By End Date: &nbsp; <DatePicker onChange={(value: any) => setEndDate(value)} allowClear showTime />
        </Col>
      </Row>
      <Table pagination={false} dataSource={actionCardsData} style={{ paddingTop: "20px" }} columns={dataColumns}>
      </Table>
      <Row>
        <Col span={24} style={{ display: "flex", justifyContent: "end", padding: "18px" }}>
          <Pagination
            pageSizeOptions={[
              '10',
              '20',
              '30',
              '40',
              '50',
              '100',
              '150',
              '200',
            ]}
            onChange={onShowSizeChange}
            pageSize={pagination.pageSize}
            defaultCurrent={1}
            total={totalRecords}
            showSizeChanger
            current={pagination.current}
          />
        </Col>

      </Row>

      <CreateActionCards
        onFinish={onFinishHandler}
        initialValues={initialValues}
        form={form} language='English'
        networkInstance={networkInstance}
        closeHandler={drawerCloseHandler}
        showForm={showCreateForm}
      />
    </Container>
  )
}

export default withDefaultLayout(ManageStories)
