import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CloneScheduleFormFields, ScheduleList } from '@a4b/ui/src/admin'

import { CreateScheduleOptions, ScheduleType, ScheduleData, ContentTag } from '@a4b/api'
import moment from 'moment'

const TagContentScheduleList = () => {
  const { tagId } = useParams()
  const navigate = useNavigate()
  const { networkInstance, userProfile } = useAppContext()
  const [schedulesDates, setScheduledDates] = useState<ScheduleData[]>([])
  const [tagData, setTagData] = useState<ContentTag>()

  useEffect(() => {
    async function initSchedules() {
      if (!tagData) return;
      const { data } =
        await networkInstance.clientWithHeaders.contentApi.schedule.getSchedulesPreview({
          resource: ScheduleType.TAG_CONTENT_SCHEDULE,
          libraryId: tagId,
          language: tagData?.language
        })
      if (data.data) {
        const schedule = Object.keys(data.data).map((key, index) => ({
          date: key,
          id: data.data[key],
        }))
        setScheduledDates(schedule)
      }
    }
    initSchedules()
  }, [networkInstance.clientWithHeaders.contentApi.schedule, tagData, tagId])

  useEffect(() => {
    async function initTagData() {
      if (!tagId) {
        return
      }
      const { data } =
        await networkInstance.clientWithHeaders.contentApi.tag.getTagById(tagId);

      setTagData(data.data)
    }
    initTagData()
  }, [networkInstance.clientWithHeaders.contentApi.tag, tagId])

  const handleCreateSchedule = (date: moment.Moment) => {
    const formattedDate = date.format('YYYY-MM-DD')
    navigate(
      `/content/tag-content-schedule/${tagId}/create?date=${formattedDate}&language=${tagData?.language}`,
    )
  }

  const handleViewSchedule = (schedule: ScheduleData) => {
    navigate(`/content/tag-content-schedule/${tagId}/edit/${schedule.id}`)
  }

  const handleCloneScheduleSubmit = async (
    schedule: ScheduleData,
    values: CloneScheduleFormFields,
  ) => {
    const { data } =
      await networkInstance.clientWithHeaders.contentApi.schedule.getScheduleById(
        schedule.id,
      )
    const clonedScheduleData = data.data
    const cloneToDate = values.cloneTo
    const date = moment().format('YYYY-MM-DD') === cloneToDate.format('YYYY-MM-DD')
      ? moment().add(1, 'minute').toISOString()
      : cloneToDate.startOf('day').toISOString()

    const options: CreateScheduleOptions = {
      name: `Tag content schedule for ${cloneToDate.format('DD-MM-YYYY')}`,
      date,
      ordering: clonedScheduleData.ordering,
      entity: ScheduleType.TAG_CONTENT_SCHEDULE,
      parentId: tagId,
      createdBy: userProfile.email || '',
      updatedBy: userProfile.email || '',
      language: clonedScheduleData.language
    }

    try {
      const { status, data } =
        await networkInstance.clientWithHeaders.contentApi.schedule.createSchedule(options)
      const scheduleId = data.data.id
      const updatedScheduleDates = [...schedulesDates]
      updatedScheduleDates.push({
        id: scheduleId,
        date: cloneToDate.format('YYYY-MM-DD'),
      })
      setScheduledDates(updatedScheduleDates)
      const skippedLibraries = data.data.skippedLibraries
      if (skippedLibraries) {
        return { success: true, message: `Successfully created schedule, skipped ${skippedLibraries}` };
      }
      return { success: true, message: 'Successfully created schedule' };
    } catch (error) {
      return { success: false, message: 'Failed to create schedule' };
    }
  }

  const handleDeleteSchedule = async (schedule: ScheduleData) => {
    try {
      const response =
        await networkInstance.clientWithHeaders.contentApi.schedule.deleteScheduleById(schedule.id)
      if (response.status === 200) {
        const scheduleId = schedule.id
        const updatedScheduleDates = schedulesDates.filter((schedule) => schedule.id !== scheduleId)
        setScheduledDates(updatedScheduleDates)
        return true
      }
      return false
    } catch (error) {
      return false
    }
  }

  return (
    <div>
      <ScheduleList
        tagData={tagData}
        schedules={schedulesDates}
        type={ScheduleType.TAG_CONTENT_SCHEDULE}
        onCreateScheduleClick={handleCreateSchedule}
        onViewScheduleClick={handleViewSchedule}
        onCloneScheduleSubmit={handleCloneScheduleSubmit}
        onDeleteScheduleClick={handleDeleteSchedule}
      />
    </div>
  )
}

export default withDefaultLayout(TagContentScheduleList)
