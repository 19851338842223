import React, { useState, useCallback, memo, useRef, useEffect } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, message, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { Shrine } from '@a4b/api/src/modules/Temple/types';
import { Rule } from 'antd/lib/form';

const imageFileValidations = (file: RcFile) => {
  if (!file) return true;
  let isFailed = true;
  switch (file.type) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/jpg':
    case 'image/webp': {
      const isLt2M = file.size <= 150 * 1024;
      if (!isLt2M) {
        isFailed = false;
        message.error('Image must smaller than 150kb');
      }
      break;
    }
    case 'image/gif':
      {
        const allowedSize = file.size <= 1024 * 1024;
        if (!allowedSize) {
          isFailed = false;
          message.error('Gif Image must smaller than 1MB');
        }
      }
      break

    case 'video/mp4':
      {
        const allowedSize = file.size <= (1024 * 1024) * 2;
        if (!allowedSize) {
          isFailed = false;
          message.error('Video must smaller than 2MB');
        }
      }
      break;

    default:
      message.error('File type is not allowed !');
  }

  return isFailed;
};

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

export interface FileUploadProps {
  getS3PathApi: (file?: any) => Promise<Shrine.S3Info>,
  accept: string
  title: string
  label?: string
  name: string | (string | number)[]
  rules?: Rule[]
  disabled?: boolean
  style?: React.CSSProperties
  fileValidation?: (file: RcFile) => boolean
}

/**
 * Changes camel case to a human readable format. So helloWorld, hello-world and hello_world becomes "Hello World". 
 * */
function prettifyCamelCase(value = ""): string {
  let output = "";
  const len = value.length;
  let char: string;

  for (let i = 0; i < len; i++) {
    char = value.charAt(i);

    if (i == 0) {
      output += char.toUpperCase();
    }
    else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
      output += " " + char;
    }
    else if (char == "-" || char == "_") {
      output += " ";
    }
    else {
      output += char;
    }
  }

  return output;
}

export const getPathFormFile = (file: UploadFile[]): string => file[0].response.path

export const getTypeFormFile = (file: UploadFile[]): string => file[0].response.customType

export const getEditImageFiles = (files: { url: string, type: string }[]) => {
  return files?.map((item) => {
    const lastIndex = item.url?.lastIndexOf('/')
    const fileName = item.url?.slice(lastIndex + 1)
    return {
      uid: +new Date(),
      name: fileName,
      status: 'done',
      url: item.url,
      response: {
        path: item.url?.replace(/^.*\/\/[^/]+/, ''),
        customType: item.type
      },
      key: fileName,
      size: 100,
      type: 'image/' + item.type?.toLowerCase()
    }
  })
}

export const getEditImageFilesWithPath = (files: { path: string, type: string }[]) => {
  return files?.map((item) => {
    const lastIndex = item.path?.lastIndexOf('/')
    const fileName = item.path?.slice(lastIndex + 1)
    return {
      uid: +new Date(),
      name: fileName,
      status: 'done',
      url: item.path,
      response: {
        path: item.path.replace(/^.*\/\/[^/]+/, ''),
        customType: item.type
      },
      key: fileName,
      size: 100,
      type: 'image/' + item.type.toLowerCase()
    }
  })
}

const FileUpload = (props: FileUploadProps) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<UploadFile>()
  const uploadRef = useRef<{ fileList: [any] }>();

  const { getS3PathApi, accept, title, label, name, rules, style, disabled, fileValidation } = props;

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'removed') return;
    setFile(info.file);
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false)
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{title}</div>
    </div>
  );

  const uploadAction: UploadProps['customRequest'] = useCallback(async (options: any) => {
    const headers = new Headers()
    headers.append('ContentType', options.file.type)
    const { path, url, type } = await getS3PathApi(options.file);
    const res = await fetch(url, {
      method: 'PUT',
      body: options.file,
      headers,
    })
    // console.log({ test: 'https://srm-sbox-cdn.a4b.io/' + path })
    if (options.onSuccess) options.onSuccess(options.file);
    options.file['path'] = path
    options.file['customType'] = type
    setFile(options.file)
  }, [])

  const onRemove = useCallback(() => {
    setFile(undefined)
  }, [])

  useEffect(() => {
    // using it to hide upload button on image 
    setTimeout(() => {
      if (uploadRef.current?.fileList) {
        setFile(uploadRef.current?.fileList[0])
      }
    }, 500)
  }, [])
  return (
    <Form.Item
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore 
      label={label ? label : typeof name === 'string' ? prettifyCamelCase(name) : prettifyCamelCase(name[name.length - 1])}
      name={name}
      valuePropName='fileList'
      getValueFromEvent={normFile}
      rules={[...rules || [], {
        validator: (rule, value, callback) => {
          if (!value) {
            callback(undefined)
            return
          }
          if (value && value.length > 0 && (value[0].type === "application/json" || value[0].type === "image/lottie")) {
            callback(undefined)
            return;
          }
          if (fileValidation) {
            if (!fileValidation(value[0])) callback("Invalid file size"); else callback(undefined)

          } else {

            if (!imageFileValidations(value[0])) callback("Invalid file size"); else callback(undefined)
          }
        },
      }]}
      style={style}
      preserve={false}
    >
      <Upload
        name={'input_file'}
        listType="picture-card"
        className="avatar-uploader"
        customRequest={uploadAction}
        onChange={handleChange}
        onRemove={onRemove}
        accept={accept}
        fileList={file ? [file] : []}
        maxCount={1}
        disabled={!!disabled}
        ref={uploadRef}
      >
        {!file && uploadButton}
      </Upload>
    </Form.Item >
  );
};


export default FileUpload;

export const getAntUploadFile = (url: string, NEW_CDN_HOST: string) => {
  const lastIndex = url?.lastIndexOf('/')
  const fileName = url?.slice(lastIndex + 1)
  return [{
    uid: +new Date() + '',
    name: fileName,
    status: 'done',
    url: NEW_CDN_HOST + url,
    response: {
      path: url,
    },
    key: fileName,
    size: 10,
    type: 'image/' + url.split('.').pop()
  }]
}


export interface AntdFileUploadV2Props {
  accept: string
  title: string
  name: string
  rules?: Rule[]
  disabled?: boolean
  style?: React.CSSProperties
  s3UploadApi: (options: any) => Promise<{ url: string, path: string }>;
  fileValidation?: (file: RcFile) => boolean
  fileList?: UploadFile[]
}


export const AntdFileUploadV2 = (props: AntdFileUploadV2Props) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<UploadFile | undefined>(props.fileList ? props.fileList[0] : undefined);
  const uploadRef = useRef<{ fileList: [any] }>();


  const { s3UploadApi, accept, title, name, rules, style, disabled, fileValidation, fileList } = props;

  useEffect(() => {
    if (fileList) {
      setFile(fileList[0])
    }
  }, [fileList])

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'removed') {
      return;
    }
    setFile(info.file);
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false)
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{title}</div>
    </div>
  );

  const uploadAction: UploadProps['customRequest'] = useCallback(async (options: any) => {
    const { path, url } = await s3UploadApi(options);
    // console.log({ test: 'https://srm-sbox-cdn.a4b.io/' + path })
    if (options.onSuccess) options.onSuccess(options.file);
    options.file['path'] = path
    // setFile(options.file)
  }, [s3UploadApi])

  const onRemove = useCallback(() => {
    setFile(undefined)
  }, [])

  const rulesArr = rules ? rules : []
  return (
    <Form.Item
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore 
      label={typeof name === 'string' ? prettifyCamelCase(name) : prettifyCamelCase(name[name.length - 1])}
      name={name}
      getValueFromEvent={normFile}
      rules={[...rulesArr, {
        validator: (rule, value, callback) => {
          if (!value || value.length === 0) {
            callback(undefined);
            return
          }
          if (fileValidation) {
            if (!fileValidation(value[0])) {
              callback("Invalid file size");
              setFile(undefined)
              setLoading(false)
            } else {
              callback(undefined)
            }
          }
          // handling default case
          if (!imageFileValidations(value[0])) {
            callback("Invalid file size");
            setFile(undefined);
            setLoading(false)
          } else {
            callback(undefined)
          }

        },
      }]}
      style={style}
      preserve={false}
    >
      <Upload
        name={'input_file'}
        listType="picture-card"
        className="avatar-uploader"
        customRequest={uploadAction}
        onChange={handleChange}
        onRemove={onRemove}
        accept={accept}
        fileList={file ? [file] : undefined}
        maxCount={1}
        disabled={!!disabled}
        ref={uploadRef}
      >
        {!file && uploadButton}
      </Upload>
    </Form.Item >
  );
};


