import {
    Space,
    Card,
} from 'antd'
import styled from 'styled-components'
//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useNavigate } from 'react-router-dom'
//Screens
import CalenderView from '@a4b/ui/src/modules/monetisation/molecules/CalenderView'

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  width: 100%;
`
const ContentScheduler = () => {
    const navigate = useNavigate()

    const onCreateScheduleClick = (date: moment.Moment) => {
        console.log('create..')
        const formattedDate = date.format('YYYY-MM-DD')
        navigate(
            `/monetisation/offerings/content/event-schedule/${formattedDate}`,
        )
    }

    return (
        <Container>
            <Space direction={'vertical'} style={{ width: '100%' }}></Space>
            <Card style={{ width: '75%', display: 'flex', justifyContent: 'start', height: '80px' }} >
                <p style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>Content Scheduler</p>
            </Card>
            <div style={{ height: '75%', width: '75%' }}>
                <CalenderView
                    onCreateScheduleClick={onCreateScheduleClick}
                />
            </div>
        </Container>
    )
}

export default withDefaultLayout(ContentScheduler)
