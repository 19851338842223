import { PageListItem } from '@a4b/api/src/modules/Monetisation/Offerings/types'

export const formatPageinfoByPageType = (
  pageType: PageListItem | undefined,
  payload: any,
  languageCode: string,
) => {
  switch (pageType?.key) {
    case 'store_list_page_pooja':
      const formObjectPooja: any = {}
      formObjectPooja['faqs'] = {
        create: {
          faq_type: pageType?.key,
          faqs: payload?.faqs
            ?.filter((faq: any) => !faq.id)
            ?.map((faq: any, index: number) => ({
              ...faq,
              active: true,
            })),
        },
        update: {
          faq_type: pageType?.key,
          faqs: payload?.faqs
            ?.filter((faq: any) => faq.id)
            ?.map((faq: any, index: number) => ({
              ...faq,
              active: true,
            })),
        },
      }
      if (payload?.reviews) {
        formObjectPooja['reviews'] = {
          create: {
            store_id: pageType?.id,
            reviews: payload?.reviews
              ?.filter((item: any) => !item?.id)
              ?.map((item: any, index: number) => ({
                review: item.review,
                user_name: item.user_name,
                user_image_url: item.user_image_url[0],
                position: item?.position,
                rating: item.rating,
              })),
            language_code: languageCode,
          },
          update: {
            store_id: pageType?.id,
            reviews: payload?.reviews
              ?.filter((item: any) => item?.id)
              ?.map((item: any, index: number) => ({
                id: item.id,
                review: item.review,
                user_name: item.user_name,
                user_image_url: item.user_image_url[0],
                position: item?.position,
                rating: item.rating,
              })),
            language_code: languageCode,
          },
        }
      }
      return formObjectPooja

    case 'store_list_page':
      const formObject: any = {}
      formObject['faqs'] = {
        create: {
          faq_type: 'store_list_page',
          faqs: payload?.faqs
            ?.filter((faq: any) => !faq.id)
            ?.map((faq: any, index: number) => ({
              ...faq,
              active: true,
            })),
        },
        update: {
          faq_type: 'store_list_page',
          faqs: payload?.faqs
            ?.filter((faq: any) => faq.id)
            ?.map((faq: any, index: number) => ({
              ...faq,
              active: true,
            })),
        },
      }
      if (payload?.tutorials) {
        formObject['tutorials'] = payload?.tutorials?.id
          ? {
              update: {
                id: payload?.tutorials?.id,
                title: payload?.tutorials?.title,
                description: payload?.tutorials?.description,
                type: 'store_list_page',
                media_info: {
                  media_type: 'video',
                  media_url: payload?.tutorials?.media_url?.[0],
                },
              },
            }
          : {
              create: {
                title: payload?.tutorials?.title,
                description: payload?.tutorials?.description,
                type: 'store_list_page',
                media_info: {
                  media_type: 'video',
                  media_url: payload?.tutorials?.media_url?.[0],
                },
              },
            }
      }
      return formObject
    case 'cart_review_page':
      return {
        faqs: {
          create: {
            faq_type: 'cart_review_page',
            faqs: payload?.faqs
              ?.filter((faq: any) => !faq.id)
              ?.map((faq: any, index: number) => ({
                ...faq,
                active: true,
              })),
          },
          update: {
            faq_type: 'cart_review_page',
            faqs: payload?.faqs
              ?.filter((faq: any) => faq.id)
              ?.map((faq: any, index: number) => ({
                ...faq,
                active: true,
              })),
          },
        },
      }
    case 'cart_review_page_pooja':
      return {
        faqs: {
          create: {
            faq_type: 'cart_review_page_pooja',
            faqs: payload?.faqs
              ?.filter((faq: any) => !faq.id)
              ?.map((faq: any, index: number) => ({
                ...faq,
                active: true,
              })),
          },
          update: {
            faq_type: 'cart_review_page_pooja',
            faqs: payload?.faqs
              ?.filter((faq: any) => faq.id)
              ?.map((faq: any, index: number) => ({
                ...faq,
                active: true,
              })),
          },
        },
      }

    case 'payment_successful_page':
      return {
        steps: {
          create: {
            page_type: 'payment_successful_page',
            steps: payload?.steps?.filter((step: any) => !step.id),
          },
          update: {
            page_type: 'payment_successful_page',
            steps: payload?.steps?.filter((step: any) => step.id),
          },
        },
      }
    case 'payment_successful_page_pooja':
      return {
        steps: {
          create: {
            page_type: 'payment_successful_page_pooja',
            steps: payload?.steps?.filter((step: any) => !step.id),
          },
          update: {
            page_type: 'payment_successful_page_pooja',
            steps: payload?.steps?.filter((step: any) => step.id),
          },
        },
      }

    case 'payment_failed_page':
      return {
        steps: {
          create: {
            page_type: 'payment_failed_page',
            steps: payload?.steps?.filter((step: any) => !step.id),
          },
          update: {
            page_type: 'payment_failed_page',
            steps: payload?.steps?.filter((step: any) => step.id),
          },
        },
      }
    case 'offerings_feed_page':
    case 'offerings_feed_page_with_pooja':
      const feedFormObject: any = {}
      feedFormObject['reviews'] = {
        create: {
          store_id: payload?.store_id,
          reviews: payload?.reviews
            ?.filter((item: any) => !item?.id)
            ?.map((item: any, index: number) => ({
              review: item.review,
              user_name: item.user_name,
              user_image_url: item.user_image_url[0],
              position: item?.position,
              rating: item.rating,
            })),
        },
        update: {
          store_id: payload?.store_id,
          reviews: payload?.reviews
            ?.filter((item: any) => item?.id)
            ?.map((item: any, index: number) => ({
              id: item.id,
              review: item.review,
              user_name: item.user_name,
              user_image_url: item.user_image_url[0],
              position: item?.position,
              rating: item.rating,
            })),
        },
      }
      if (payload?.tutorials) {
        feedFormObject['tutorials'] = payload?.tutorials?.id
          ? {
              update: {
                id: payload?.tutorials?.id,
                title: payload?.tutorials?.title,
                description: payload?.tutorials?.description,
                type: pageType.key,
                media_info: {
                  media_type: 'video',
                  media_url: payload?.tutorials?.media_url?.[0],
                },
              },
            }
          : {
              create: {
                title: payload?.tutorials?.title,
                description: payload?.tutorials?.description,
                type: pageType.key,
                media_info: {
                  media_type: 'video',
                  media_url: payload?.tutorials?.media_url?.[0],
                },
              },
            }
      }
      if (payload?.help_tutorials) {
        feedFormObject['help_tutorials'] = payload?.help_tutorials?.id
          ? {
              update: {
                id: payload?.help_tutorials?.id,
                title: payload?.help_tutorials?.title,
                description: payload?.help_tutorials?.description,
                type: 'offerings_feed_help_tutorial_page',
                media_info: {
                  media_type: 'video',
                  media_url: payload?.help_tutorials?.media_url?.[0],
                },
              },
            }
          : {
              create: {
                title: payload?.help_tutorials?.title,
                description: payload?.help_tutorials?.description,
                type: 'offerings_feed_help_tutorial_page',
                media_info: {
                  media_type: 'video',
                  media_url: payload?.help_tutorials?.media_url?.[0],
                },
              },
            }
      }
      return
    case 'order_fulfilment_generic_faq':
    case 'remedy_cart_review_page_faq':
    case 'remedy_order_fulfilment_faq':
      const formObjectFulfilment: any = {}
      formObjectFulfilment['faqs'] = {
        create: {
          faq_type: pageType?.key,
          faqs: payload?.faqs
            ?.filter((faq: any) => !faq.id)
            ?.map((faq: any, index: number) => ({
              ...faq,
              active: true,
            })),
        },
        update: {
          faq_type: pageType?.key,
          faqs: payload?.faqs
            ?.filter((faq: any) => faq.id)
            ?.map((faq: any, index: number) => ({
              ...faq,
              active: true,
            })),
        },
      }
      return formObjectFulfilment
  }
}
