import { Table } from "antd";

interface Props {
  record: any;
}
const HeroArticleDataTable = ({ record }: Props) => {
  const dataColumn = [
    {
      title: "Article Id",
      dataIndex: "r_article_id",
      key: "r_article_id",
      ellipsis: true,
      width: "10%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "CTA Text",
      dataIndex: "cta_text",
      key: "cta_text",
      ellipsis: true,
      width: "10%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
  ];
  return (
    <div className="padding-left">
      <Table
        columns={dataColumn}
        dataSource={record?.content?.items}
        pagination={false}
        scroll={{ y: 240 }}
      ></Table>
    </div>
  );
};
export default HeroArticleDataTable;
