import { Button, Col, Form, Input, Row, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { TagsColor } from "@/utils/utilProps";
import DeletableContainer from "../deletableContainer/DeletableContainer";
const { Option } = Select;
interface Props {
  widgetClass: any;
}
const HeaderTags = ({ widgetClass }: Props) => {
  return (
    <Form.List name={["content", "header_tags"]}>
      {(fields, { add, remove, move }) => {
        return (
          <div>
            {fields.map((field: any, index: number) => {
              return (
                <Form.Item key={field.key}>
                  <DeletableContainer
                    closeHandler={() => {
                      remove(field.name);
                    }}
                    itemText={`Header Tags : ${index + 1}`}
                    showItemTextInBold={true}
                    moveFunction={move}
                    fields={fields}
                    currentIndex={index}
                    isInnerContainer={false}
                    fieldsLength={fields.length}
                    move={move}
                    add={add}
                  >
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          required={true}
                          label="Header Tag Text"
                          name={[field.name, "text"]}
                          fieldKey={[field.fieldKey, "text"]}
                          rules={[
                            {
                              validator: widgetClass.headerTagsTextValidator,
                            },
                          ]}
                        >
                          <Input placeholder="Tag Text" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          required={true}
                          label="Header Tag Color"
                          name={[field.name, "color"]}
                          fieldKey={[field.fieldKey, "color"]}
                          rules={[
                            {
                              validator: widgetClass.headerTagsColorValidator,
                            },
                          ]}
                        >
                          <Select placeholder="Tag Color">
                            {TagsColor?.map((item: any, index: number) => {
                              return (
                                <Option
                                  value={item.value}
                                  key={`${item.value}_${index}`}
                                >
                                  {item.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </DeletableContainer>
                </Form.Item>
              );
            })}
            <Form.Item>
              <Row justify="end">
                <Col span={24}>
                  <Row justify="end">
                    <Col span={4}>
                      <Button
                        onClick={() => {
                          add();
                        }}
                        type="dashed"
                        icon={<PlusCircleOutlined />}
                        size="middle"
                      >
                        Add Header Tags
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default HeaderTags;
