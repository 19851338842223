import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../apiClient'
import {
  Commits,
  GoogleTranslation,
  Languages,
  MasterTranslation,
} from './types'

class BitbucketApi {
  api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getAllServices = async (authToken: string) =>
    this.api.get<MasterTranslation.MasterTranslationResponse>(
      '/repositories/apps-for-bharat/translation-repo/src/master/?pagelen=25',
      {
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      },
    )

  getAllLanguages = async (
    authToken: string,
    serviceName: string,
    serviceHash: string,
  ) =>
    this.api.get<Languages.LanguageResponse>(
      `https://api.bitbucket.org/2.0/repositories/apps-for-bharat/translation-repo/src/${serviceHash}/${serviceName}/`,
      {
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      },
    )

  getJsonData = async (
    authToken: string,
    serviceName: string,
    serviceHash: string,
    jsonFileName: string,
  ) =>
    this.api.get(
      `https://api.bitbucket.org/2.0/repositories/apps-for-bharat/translation-repo/src/${serviceHash}/${serviceName}/${jsonFileName}/`,
      {
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      },
    )

  getCommits = async (authToken: string) =>
    this.api.get<Commits.CommitResponse>(
      `https://api.bitbucket.org/2.0/repositories/apps-for-bharat/translation-repo/commits/`,
      {
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      },
    )

  postJsonData = async (authToken: string, formData: object) =>
    this.api.post(
      `https://api.bitbucket.org/2.0/repositories/apps-for-bharat/translation-repo/src/`,
      formData,
      {
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      },
    )

  googleTranslate = async (
    googleOAuth: string,
    text: string,
    language: string,
  ) =>
    this.api.get<GoogleTranslation.TranslationResponse>(
      `https://translation.googleapis.com/language/translate/v2?key=${googleOAuth}&q=${text}&target=${language}`,
    )
}

export default BitbucketApi
