import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../apiClient'
import { AddRechargePackagePayload, RootObject } from './types'

class WalletRechargePackages {
  private api: AxiosInstance

  constructor(
    config: AxiosRequestConfig,
    appHeaders?: { 'x-afb-app-name': string },
  ) {
    const headers = {
      ...config.headers,
      ...(appHeaders || {}),
    }
    const updatedConfig = { ...config, headers }
    this.api = apiClient.create(updatedConfig)
  }

  getRechargePackage = async (variant: boolean, countryCode = 'IN') => {
    const query = variant ? `variant=${variant}` : ''
    return this.api.get<any>(
      `gw1/fiat/internal/v1/recharge_packages?` + query,
      {
        headers: {
          'x-afb-country-code': countryCode,
        },
      },
    )
  }

  addRechargePackage = (payload: AddRechargePackagePayload) =>
    this.api.post('gw1/fiat/internal/v1/recharge_packages', payload)

  updateRechargePackagePayload = (
    rechargePackageId: string,
    payload: RootObject,
  ) =>
    this.api.patch(
      'gw1/fiat/internal/v1/recharge_packages/' + rechargePackageId,
      payload,
    )

  deleteRechargePackagePayload = (rechargePackageId: string) =>
    this.api.delete(
      'gw1/fiat/internal/v1/recharge_packages/' + rechargePackageId,
    )

  getCampaings = async () =>
    this.api.get('karna/internal/v1/campaigns?limit=1000&offset=0&active=true')
}

export default WalletRechargePackages
