import { CreateUser, UpdateUser } from '@a4b/api/src/modules/Monetisation/Offerings/types'

const formatHubIds = (formData: any): any => {
    if (formData?.user_type === 'hub_manager' && !Array.isArray(formData?.hub_ids)) {
        return [formData?.hub_ids];
    }
    return formData?.hub_ids;
}

export const formatUserCreation = (formData: any, creatorId: any): CreateUser => {
    const formatedObject: CreateUser = {
        name: formData?.name,
        email: formData?.email,
        contact_number: formData?.contact_number ? `+91${formData.contact_number}` : '',
        user_type: formData?.user_type,
        hub_ids: formatHubIds(formData),
        creator_id: creatorId,
    }
    return formatedObject
}

export const formatUserUpdation = (formData: any, editorId: any, action: string, userId: any): UpdateUser => {
    const formatedObject: UpdateUser = {
        action: action,
        archived: false,
        user_type: formData?.user_type,
        hub_ids: formatHubIds(formData),
        editor_id: editorId,
        user_id: userId
    }
    return formatedObject
}

export const formatUserArchive = (formData: any, editorId: any, action: string): UpdateUser => {
    const formatedObject: UpdateUser = {
        action: action,
        archived: true,
        user_type: formData?.user_type,
        editor_id: editorId,
        hub_ids: formatHubIds(formData),
        user_id: formData?.user_id
    }
    return formatedObject
}