import { useState } from "react";
import { getReadableWidgetName } from "@/utils/helper";
import { Button, Dropdown, Menu, Table } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import moment from "moment";

interface Props {
  dataSource: any[];

  actionMenuClickHandler: (key: string, record: any) => void;
}
const WidgetManageDataTable = ({
  dataSource,
  actionMenuClickHandler,
}: Props) => {
  const [expandedRowKeys, setExpandRowKeys] = useState<string[]>([]);
  const onExpandHandle = (isExpanded: boolean, record: any) => {
    if (isExpanded) {
      setExpandRowKeys([record.key]);
    } else {
      let position: number = expandedRowKeys.findIndex(
        (item) => item === record.key
      );
      expandedRowKeys.splice(position, 1);
    }
  };
  const getWidgetByType = (record: any) => {
    return <div style={{ paddingLeft: "48px" }}>{record.description}</div>;
  };
  const menu = (record: any) => {
    return (
      <Menu
        onClick={(e: any) => {
          e.domEvent.stopPropagation();
          actionMenuClickHandler(e.key, record);
        }}
      >
        <Menu.Item key="delete">
          <Button
            size="middle"
            icon={<DeleteFilled style={{ color: "red" }} />}
          >
            Delete
          </Button>
        </Menu.Item>
        <Menu.Item key="edit">
          <Button
            size="middle"
            icon={<EditFilled style={{ color: "#1990FF" }} />}
            disabled={true}
          >
            Edit
          </Button>
        </Menu.Item>
        <Menu.Item key="toggleStatus">
          {record.active ? (
            <Button size="middle">Deactivate</Button>
          ) : (
            <Button disabled={!record.active} size="middle">
              Deactivated
            </Button>
          )}
        </Menu.Item>
      </Menu>
    );
  };
  const dataColumns = [
    {
      title: "code",
      dataIndex: "code",
      key: "code",
      width: "25%",
    },
    {
      title: "Widget Type",
      dataIndex: "widget_type",
      key: "widget_type",
      render: (text: any, record: any, index: any) => {
        return <span>{getReadableWidgetName(text)}</span>;
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      ellipsis: true,
      render: (text: any, record: any, index: any) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      ellipsis: true,
      render: (text: any, record: any, index: any) => {
        return <span>{moment.unix(text).format("YYYY-MM-DD HH:mm:ss")}</span>;
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      width: "8%",
      render: (text: any, record: any, index: any) => {
        return <span>{text === true ? "Yes" : "No"}</span>;
      },
    },

    {
      title: "Action",
      key: "action",
      width: "8%",
      render: (text: any, record: any, index: any) => (
        <Dropdown.Button
          overlay={() => {
            return menu(record);
          }}
        ></Dropdown.Button>
      ),
    },
  ];
  return (
    <Table
      //rowSelection={rowSelectionConfig}
      columns={dataColumns}
      dataSource={dataSource}
      pagination={{ defaultPageSize: 8 }}
      expandable={{
        expandedRowRender: getWidgetByType,
        onExpand: onExpandHandle,
        expandedRowKeys: expandedRowKeys,
        expandRowByClick: true,
      }}
    />
  );
};

export default WidgetManageDataTable;
