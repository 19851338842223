import { Descriptions } from "antd";

interface Props {
  record: any;
}
const MenuNotificationDataTable = ({ record }: Props) => {
  return (
    <div className="padding-left">
      {
        <Descriptions size="small" title="Item" bordered>
          {record?.content?.items?.map((item: any) => {
            return (
              <Descriptions.Item label="Title">{item?.title}</Descriptions.Item>
            );
          })}
        </Descriptions>
      }
    </div>
  );
};
export default MenuNotificationDataTable;
