import { CreateShareAppPayload, Shareapp } from "@a4b/api/src/modules/ShareText/types";
import { Button, Checkbox, Form, Input, InputNumber, Select, message } from "antd";
import { useCallback, useEffect } from "react";
import { SHARE_TEXT_CONFIG } from "../../helpers/config";
import { AntdFileUploadV2, getAntUploadFile } from "../../../../../components/Forms/AntdFileUpload";
import { useAppContext } from "../../../../../components/AppContext";
import { RcFile, UploadFile } from "antd/lib/upload";
import { openNotification } from "../../../../../utils";

const iconValidations = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG/GIF/WEBP file!');
  }
  let isFailed = true;
  if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/webp') {
    const isLt2M = file.size <= 10 * 1024;
    if (!isLt2M) {
      isFailed = false;
      message.error('Image must smaller than 10kb');
    }
  } else {
    const isLt2M = file.size <= 1024 * 1024;
    if (!isLt2M) {
      isFailed = false;
      message.error('Gif Image must smaller than 1MB');
    }
  }

  return isJpgOrPng && isFailed;
};

export interface CreateUpdateAppFormProps {
  editShareApp?: Shareapp | null
  getShareTextApps: () => Promise<void>
}
const CreateUpdateAppForm = (props: CreateUpdateAppFormProps) => {
  const { editShareApp, getShareTextApps } = props;
  const [form] = Form.useForm<CreateShareAppPayload>();
  const { networkInstance } = useAppContext();

  const createUpdateApp = useCallback((values) => {
    values.icon_path = values.icon_path[0].response.path
    if (editShareApp) {
      try {
        networkInstance.clientWithHeaders.shareText.updateShareApp(editShareApp.id, values);
        getShareTextApps();
        openNotification('success', { message: "Share text app updated sucessfully" })
      } catch (error) {
        openNotification('error', { message: "Failed to update Share text app", description: (error as string).toString() })
      }
    } else {
      try {
        networkInstance.clientWithHeaders.shareText.createShareApp(values);
        getShareTextApps();
        openNotification('success', { message: "Share text app created sucessfully" })
      } catch (error) {
        openNotification('error', { message: "Failed to create Share text app", description: (error as string).toString() })
      }
    }
  }, [editShareApp, getShareTextApps, networkInstance.clientWithHeaders.shareText])

  useEffect(() => {
    if (editShareApp) {
      //@ts-ignore
      form.setFieldsValue({ ...editShareApp, icon_path: getAntUploadFile(editShareApp.icon_path) })
    }
  }, [editShareApp, form])

  const uploadIcon = useCallback(async (options: any): Promise<{ url: string, path: string }> => {
    const headers = new Headers()
    headers.append('ContentType', options.file.type)
    const data = await networkInstance.clientWithHeaders.shareText.getPreSignedUrlPng();

    const { url, path } = data.data.data;
    const res = await fetch(url, {
      method: 'PUT',
      body: options.file,
      headers,
    })
    return { url, path }
  }, [networkInstance.clientWithHeaders.shareText])

  const iconEditFileList = Form.useWatch('icon_path', form) as unknown as UploadFile<any>[];

  return <div>
    <Form
      form={form}
      onFinish={createUpdateApp}
      layout="vertical"
    >
      <Form.Item
        name={'platform'}
        label={'Platform'}
        required
      >
        <Select>
          {SHARE_TEXT_CONFIG.data.platform_type.map((platformType) => {

            return <Select.Option value={platformType} >
              {platformType}
            </Select.Option>
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={"package_type"}
        label={"Package type"}
        required
      >
        <Select>
          {SHARE_TEXT_CONFIG.data.package_type.map((packageType) => {
            return <Select.Option value={packageType} >
              {packageType}
            </Select.Option>
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={"name"}
        label={"Name"}
        required
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"package"}
        label={"Package"}
        required
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"action_package"}
        label={"Action Package"}
        required
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"landing"}
        label={"Landing"}
        initialValue={'default'}
        required
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"default_order"}
        label={"Display order"}
        required
      >
        <InputNumber />
      </Form.Item>
      <AntdFileUploadV2
        rules={[{ required: true }]}
        name={'icon_path'}
        accept="image/png"
        s3UploadApi={uploadIcon}
        title={'upload icon'}
        fileValidation={iconValidations}
        fileList={iconEditFileList}
      />
      <Form.Item
        name={"share_text_character_limit"}
        label={"Character limit"}
        required
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name={"supported_file_types"}
        label={"Supported file types"}
        required
      >
        <Select
          mode="multiple"
          allowClear
        >
          {
            SHARE_TEXT_CONFIG.data.attachment_type.map((attachmentType) => <Select.Option value={attachmentType}>{attachmentType}</Select.Option>)
          }
        </Select>
      </Form.Item>
      <Form.Item
        name={"active"}
        label={"Active"}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" >Submit</Button>
      </Form.Item>
    </Form>
  </div>
}

export default CreateUpdateAppForm;