import React, { useMemo } from 'react';
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import 'antd/dist/antd.css';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { AudioTag } from '@a4b/api/src/modules/Content/Audio/types';
import { Button, Form, Input, InputNumber, Select, Typography, Upload } from 'antd';
import { SocialScreenContainer } from '@a4b/ui/src/admin';
import { CommunityListCard } from '@a4b/ui/src/modules/admin/components/Social1.5/CommunityListCard';
import { EyeOutlined, ScheduleOutlined, SendOutlined, UploadOutlined } from '@ant-design/icons';
import { NEW_CDN_HOST, UPLOAD_CONTENT_URL } from '@/utils/constants';
import { v4 as uuidv4 } from 'uuid';
import { CommunityCategory, Community, CreateCommunityPayload } from '@/../../../packages/a4b-api/src/modules/Social/types';
import { openNotification } from '@/utils';






const defaultPagination = {
  current: 1,
  pageSize: 50,
  total: -1
}


// const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
//   notification[type]({
//     message: title,
//     description
//   });
// };

const { Option } = Select;

const SocialCommunities = () => {
  const navigate = useNavigate();
  const { networkInstance, userProfile } = useAppContext();
  const [showDrawer, setShowDrawer] = useState(false);
  const [categories, setCategories] = useState<CommunityCategory[]>([])
  const [communities, setCommunities] = useState<Community[]>([])
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
  const [form] = Form.useForm();

  const loadCommunityAuthors = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.communityApi.getCommunityTags();
      setCategories(res.data.data.categories);
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.communityApi])

  const loadCommunities = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.communityApi.getCommunities();
      setCommunities(res.data.data.communities);
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.communityApi])

  useEffect(() => {
    loadCommunityAuthors();
    loadCommunities();
  }, [loadCommunities, loadCommunityAuthors])

  const getDataForUpload = useCallback(() => {
    const ref_content_code = `social_${uuidv4()}`
    return {
      ref_content_code,
      media_type: 'IMAGE',
    }
  }, [])

  const handleDrawerClose = useCallback(() => {
    setShowDrawer(false);
  }, [setShowDrawer])

  const handleDrawerOpen = useCallback(() => {
    setShowDrawer(true);
  }, [setShowDrawer])

  const normFile = useCallback((e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }, [])


  const createCommunity = useCallback(async (values: CreateCommunityPayload) => {
    console.log(values.profile_picture_attachment_path, 'check it now ')
    const payload = { ...values }
    //@ts-ignore 
    payload['profile_picture_attachment_path'] = values.profile_picture_attachment_path[0].response.data.content.s3_path
    //@ts-ignore
    payload['cover_picture_attachment_path'] = values.cover_picture_attachment_path[0].response.data.content.s3_path
    try {
      await networkInstance.clientWithHeaders.communityApi.createCommunity(payload)
      openNotification('success', { message: 'Community author created successfully!' })
      form.resetFields();
      loadCommunityAuthors();
    } catch (error: any) {
      openNotification('error', { message: 'Community author creation failed!', description: error.toString(), })
    }
  }, [form, loadCommunityAuthors, networkInstance.clientWithHeaders.communityApi])

  const drawerContent = useMemo(() => {
    return <Form
      form={form}
      layout="vertical"
      onFinish={createCommunity}
    //  initialValues={{ requiredMarkValue: requiredMark }}
    //  onValuesChange={onRequiredTypeChange}
    //  requiredMark={requiredMark}
    >
      <Form.Item
        label='Local Name'
        name='local_name'
        rules={[{ required: true, message: 'Local name required' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='English Name'
        name='en_name'
        rules={[{ required: true, message: 'English name required' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Categories'
        name='category_ids'
        rules={[{ required: true, message: 'Select a category' }]}
      >
        <Select mode='multiple'>
          {
            categories.map((category, index) => (
              <Option key={category.id} value={category.id}>
                {category.local_name}
              </Option>
            ))
          }
        </Select>
      </Form.Item>
      <Form.Item
        label='Welcome Message'
        name='welcome_message'
        rules={[{ required: true, message: 'welcome Message required' }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label='Share Text'
        name='share_text'
        rules={[{ required: true, message: 'Share Text required' }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label='Nudge Message'
        name='nudge_message'
        rules={[{ required: true, message: 'Nudge Message required' }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label='Short Description'
        name='short_description'
        rules={[{ required: true, message: 'Short Description required' }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label='Long Description'
        name='long_description'
        rules={[{ required: true, message: 'Long Description required' }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label='Profile Picture'
        name='profile_picture_attachment_path'
        valuePropName='fileList'
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Profile Picture required' }]}
      >
        <Upload
          action={UPLOAD_CONTENT_URL}
          accept={'image/*'}
          data={() => getDataForUpload()}
          name='input_file'
          listType='picture'
          multiple={false}
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label='Cover Picture'
        name='cover_picture_attachment_path'
        valuePropName='fileList'
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Cover Picture required' }]}
      >
        <Upload
          action={UPLOAD_CONTENT_URL}
          accept={'image/*'}
          data={() => getDataForUpload()}
          name='input_file'
          listType='picture'
          multiple={false}
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  }, [categories, createCommunity, form, getDataForUpload, normFile])

  const columns = useMemo(() => [
    // {
    //   title: 'Community code',
    //   dataIndex: 'code',
    //   key: 'id',
    // },
    {
      title: 'Name',
      dataIndex: 'local_name'
    },
    {
      title: 'Cover image',
      dataIndex: 'cover_attachment_url',
      render: (imgUrl: string) => {
        return <img height={100} width={100} src={imgUrl}></img>
      }
    },
    {
      title: 'Profile image',
      dataIndex: 'profile_attachment_url',
      render: (imgUrl: string) => {
        return <img height={50} width={50} src={imgUrl}></img>
      }
    },
    {
      title: 'Share text',
      dataIndex: 'share_text',
    },
    {
      title: 'Shot des',
      dataIndex: 'short_description',
    },
    {
      title: 'Welcome Msg',
      dataIndex: 'welcome_message',
    },
    {
      title: 'Deeplink',
      dataIndex: 'deeplink',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (id: string) => <div>
        <Button type='link' > <EyeOutlined /> <Link to={`/social.1.5/messages/${id}`}>View</Link></Button>
        {/* <Button type='link'><ScheduleOutlined /> Schedules</Button>
        <Button type='link'> <SendOutlined /> Create Post</Button> */}
      </div>,
    },
  ], []);



  const drawerProps = useMemo(() => ({ title: 'Create community', size: 'large', visible: showDrawer, onClose: handleDrawerClose }), [handleDrawerClose, showDrawer])

  return (
    <SocialScreenContainer
      title={'My communites'}
      drawerProps={drawerProps}
      drawerContent={drawerContent}
      tableProps={{ columns, dataSource: communities, size: 'small' }}
      extra={<Button onClick={handleDrawerOpen}> Create a new community </Button>}
    >
    </SocialScreenContainer>
  )
}

export default withDefaultLayout(SocialCommunities)
