import { throwError } from "../../../utils/helper";

type Item = {
  r_article_id: number;
  cta_text: string;
};
type HeaderTags = {
  text: string;
  color: string;
};
class HeroArticle {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header!: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header_tags!: HeaderTags[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items: Item[] = [];

  getSerializedData = (widgetFormData: HeroArticle) => {
    let itemModified = widgetFormData?.items?.map((item: Item) => {
      return {
        ...item,
        r_article_id: Number(item.r_article_id),
      };
    });
    widgetFormData.items = itemModified;

    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: HeroArticle) => {
    return widgetFormData;
  };

  headerValidator = async (rule: any, value: any) => {
    if (!value) return Promise.resolve();
  };
  headerTagsTextValidator = async (rule: any, value: any) => {
    if (!value) throwError("Header tags text required!");
  };
  headerTagsColorValidator = async (rule: any, value: any) => {
    if (!value) throwError("Header tags color required!");
  };
  articleIdvalidator = async (rule: any, value: any) => {
    if (!value) throwError("Article Id required!");
  };
  ctaTextValidator = async (rule: any, value: any) => {
    if (!value) throwError("CTA Text required!");
  };
}
export default HeroArticle;
export const HeroArticleInstance = new HeroArticle();
