const parseStringToObject = (input: string) => {
  const parsedObj: any = {}
  const arr = input?.split(',')
  arr.forEach(key => {
    parsedObj[key] = ''
  })
  return parsedObj
}

export const handleCreateOrUpdate = (
  values: any,
  campaignList: any[],
  editUserSegment: any,
) => {
  const result = values?.campaigns
    ?.map((name: any) =>
      campaignList?.filter(obj => obj?.name === name)?.map(obj => obj?.id),
    )
    ?.flat()
  const parsedUserIds = values?.user_ids
    ? parseStringToObject(values?.user_ids)
    : {}
  const payload: any = {
    name: values?.name,
    type: values?.type,
    position: values?.position,
    user_ids: parsedUserIds,
    campaigns: result,
    active: values?.active || false,
    sku_order: values.sku_order,
    service_type: values?.service_type,
  }
  if (editUserSegment?.id) {
    payload.id = editUserSegment?.id
  }
  return payload
}

export const parseUserSegmentByIdResponse = (
  responseData: any,
  campaignList: any,
) => {
  let parseData: any = {
    id: responseData?.id,
    name: responseData?.name,
    type: responseData?.type,
    position: responseData?.position,
    active: responseData?.active,
    sku_order: responseData?.sku_order,
    service_type: responseData?.service_type,
    campaigns: responseData?.campaigns
      ?.map((id: any) =>
        campaignList
          ?.filter((obj: any) => obj?.id === id)
          ?.map((obj: any) => obj?.name),
      )
      ?.flat(),
    user_ids: Object.keys(responseData?.user_ids).join(','),
  }
  return parseData
}
