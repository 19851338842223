//types
import { Card, Col, Row, Space, Steps } from 'antd'
import styled from 'styled-components'

import React from 'react'

const { Step } = Steps
interface Props {
    currentStep: number
    steps: any[]
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const OverrideSteps = styled(Steps)`
  && .ant-steps-item-description {
    max-width: 250px;
  }
  padding-bottom: 20px;
`
const CreateAstrologerPage: React.FC<Props> = ({
    currentStep,
    steps,
}) => {
    return (
        <Container style={{ width: '100%' }}>
            <Space direction={'vertical'} style={{ width: '100%' }}></Space>
            <OverrideSteps
                style={{ paddingLeft: 10, paddingRight: 10 }}
                current={currentStep}
            >
                {steps.map(item => (
                    <Step
                        key={item.title}
                        title={item.title}
                        description={item.description}
                    />
                ))}
            </OverrideSteps>
            <Row>
                <Col span={24}>{steps[currentStep].content}</Col>
            </Row>
        </Container>
    )
}

export default CreateAstrologerPage
