//context
import { useAppContext } from '@/components/AppContext'
//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'

//hooks
//types
import React, { useEffect, useState } from 'react'

import EpujaVideoUpload from '@a4b/ui/src/modules/monetisation/organisms/EpujaVideoUpload'

//organisms
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import { useForm } from 'antd/lib/form/Form'
import { message } from 'antd'



interface Props { }

const getPageBreadcrumbs = () => [
  {
    path: '/e-puja/video-upload',
    breadcrumbName: 'Upload videos',
  },
]

const VideoUpload: React.FC<Props> = () => {
  const { networkInstance, monetisationRoles } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

  const [createItemForm] = useForm()
  const [initialFormValues, setInitialValues] = useState({})
  const [uploadedList, setUploadedList] = React.useState<any[]>([])

  useEffect(() => {
    try {
      const list = JSON.parse(window.localStorage.getItem("epujs_uploaded_list") || "[]");
      if (list.length)
        setUploadedList(list || [])
    } catch (error) {
      console.log("Parse Error=>", error)
    }
  }, [])

  const formSubmitHandler = async () => {
    createItemForm
      .validateFields()
      .then(async values => {
        const list = JSON.parse(window.localStorage.getItem("epujs_uploaded_list") || "[]");
        const payload = {
          intro_video_url: createItemForm.getFieldValue("intro_video_url")?.[0],
          raw_video_url: createItemForm.getFieldValue("raw_video_url")?.[0],
          outro_video_url: createItemForm.getFieldValue("outro_video_url")?.[0],
        }
        const filteredFile = list?.filter((file: any) => file?.uploadedFileName === createItemForm.getFieldValue("raw_video_url")?.[0])
        if (filteredFile?.length) {
          message.warn({ content: "File with this name already uploaded, please upload with different name.", duration: 5 })
          return;
        }
        try {
          const response: any = await monetisationOfferingsApi.batchApi.uploadVideo(payload)
          const fileName = createItemForm.getFieldValue("raw_video_url")?.[0]
          const fileUploaded = []

          if (list?.length) {
            fileUploaded.push(...list)
          }
          fileUploaded.unshift({ uploadedFileName: fileName, videoUrl: response?.data?.data?.processed_video_url })

          window.localStorage.setItem("epujs_uploaded_list", JSON.stringify(fileUploaded));
          setUploadedList(fileUploaded)
          createItemForm.resetFields()
          message.success({ content: "Videos uploaded sucessfully." })
        } catch (error) {
          message.error({ content: "Error occured while uploading video" })
        }
      })
      .catch(errorInfo => { })
  }

  return (
    <div style={{ padding: '0 40px' }}>
      <AdminBreadcrumb routes={getPageBreadcrumbs()} />
      <EpujaVideoUpload
        cdnUrl={YODA_CDN_URL}
        preSignUrl={YODA_PRESIGN_URL}
        form={createItemForm}
        formSubmithandler={formSubmitHandler}
        initialValues={initialFormValues}
        dataSource={uploadedList}
      />


    </div>
  )
}

export default withDefaultLayout(VideoUpload)
