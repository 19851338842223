import { Button, Checkbox, Form, Input, InputNumber, Popconfirm, Select } from "antd";
import EventSchemaForm from "./EventSchemaForm";
import { IUpdateNode, Journey } from "../utils/types";
import styled from "styled-components";
import { useEffect } from "react";
import { REQUIRED_RULE } from "../../../../../utils/form.validation";
import React from "react";
import JourneyCohortForm from "./JourneyCohortForm";

interface JourneyFormProps {
  journey?: Journey;
  onSubmit?: (values: Journey) => void;
  onUpdate?: IUpdateNode,
  updateEditIdentifier?: (identifier?: [number, number]) => void
}

const HiddenFormItem = styled(Form.Item)`
  display: none;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`

const JourneyForm = ({ journey, onSubmit, onUpdate, updateEditIdentifier }: JourneyFormProps) => {
  const [form] = Form.useForm<Journey>();
  const onFinish = (values: Journey) => {
    if (journey && onUpdate) {
      onUpdate([0, 1], values);
      if (updateEditIdentifier) updateEditIdentifier()
    } else {
      if (onSubmit) {
        onSubmit(values);
      }
    }
  }

  useEffect(() => {
    if (journey) {
      form.setFieldsValue(journey);
    }
  }, [form, journey]);

  return <Form
    form={form}
    onFinish={onFinish}
    layout="vertical"
    style={{ background: '#ccc', padding: '20px', borderRadius: '5px' }}
  >
    <Form.Item name="name" label="Name" rules={REQUIRED_RULE}>
      <Input />
    </Form.Item>
    <Form.Item name="priority" label="Priority" rules={[REQUIRED_RULE[0], {
      validator(rule, value, callback) {
        if (value >= 0 && value <= 1000) {
          callback();
        } else {
          callback("Priority should be >= 0 and <= 1000");
        }
      },
    }]}>
      <InputNumber />
    </Form.Item>
    <Form.Item name="run_parallel_always" label="Run parallel always" valuePropName="checked" initialValue={false}>
      <Checkbox></Checkbox>
    </Form.Item>
    <HiddenFormItem name={"fallback"} initialValue={undefined} />
    <JourneyCohortForm form={form} headingBackground={'#ffffff'} />
    <EventSchemaForm type="affirmative" form={form} />
    <ButtonContainer>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {journey ? "Edit" : "Add"} Journey root node
        </Button>
      </Form.Item>
      {
        journey && <Popconfirm
          placement="top"
          title={"Are you sure you want to remove this node?"}
          onConfirm={() => { if (updateEditIdentifier) updateEditIdentifier() }}
          okText="Yes"
          cancelText="No"
        >
          <Button type="default"  > Cancel </Button>
        </Popconfirm>
      }
    </ButtonContainer>
  </Form>
};

export default JourneyForm;