import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormProps,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
} from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { LyricsEditor } from '../LyricsEditor';
import type { AudioTag, AudioThumbnail } from '@a4b/api';
import type { RcFile, UploadFile } from 'antd/lib/upload';
import UploadThumbnailModal from './UploadThumbnailModal';
import { AudioUploadThumbnailOptions, VideoTemplate } from '@a4b/api/src/modules/Content/Audio/types';
import { SUPPORTED_LANGUAGES_LIST } from '../../../constants';
import { AntdFileUploadV2, getAntUploadFile } from '../../FileUpload';
import { openErrorNotification } from '../../../../../utils/helper';
import { NEW_CDN_HOST } from '../../../../../../../../apps/admin/src/utils/constants';


export interface Props {
  data?: MusicMetaDataFormFields,
  uploadThumbnailS3: (file: RcFile) => Promise<string>,
  uploadThumbnail: (options: AudioUploadThumbnailOptions) => Promise<string>
  tags: AudioTag[],
  onTagSearch: (searchKey: string) => void,
  thumbnail: AudioThumbnail[],
  onSearchThumbnail: (searchKey: string) => void,
  onSubmit: (values: MusicMetaDataFormFields) => Promise<{ success: boolean, message: string }>
  CDN_BASE_URL: string
  onSearchVideoTemplates: (code: string) => void,
  videoTemplates: VideoTemplate[]
  uploadDefaultBannerImage: (options: any) => Promise<{
    url: string;
    path: string;
  }>,
  uploadBackgroundImage: (options: any) => Promise<{
    url: string;
    path: string;
  }>,
}

type SingerNameField = {
  search: string[];
  hi: string[];
  en: string[];
}

type AudioTypeField = {
  search: string;
  hi: string;
  en: string;
}
type AudioTitleField = {
  search: string;
  hi: string;
  en: string;
}

export enum RiddlerAudioType {
  famous = 'famous',
  bhajan = 'bhajan',
  paath = 'paath',
  aarti = 'aarti',
  mantra = 'mantra',
  chalisa = 'chanlisa',
  katha = 'katha',
  geeta_shlok = 'geeta_shlok',
  sleep = 'sleep',
  meditation = 'meditation',
  solution = 'solution',
  stress_relief = 'stress_relief',
  others = 'others',
}

export type MusicMetaDataFormFields = {
  new_god_ids: any;
  background_image_path: any;
  banner_image_path: any;
  suggestive_tool_tip_texts: any;
  banner_titles: any;
  audio_id: string;
  audio_language: string;
  singer_name_search: string;
  singer_name: any;
  audio_title_search: string;
  audio_title: any;
  tags: string[];
  lyrics_path: string;
  thumbnail_path: string;
  riddler_audio_type: string;
  riddler_audio_id?: string | null;
  riddler_deeplink?: string | null;
  new_god_id?: string | null;
  lyrics?: string | null
}

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}


const { useForm } = Form
const { Option } = Select;


export const MetaDataEditForm: React.FC<Props> = ({ data, tags = [], onTagSearch,
  videoTemplates, onSearchVideoTemplates, onSearchThumbnail, thumbnail, onSubmit,
  uploadBackgroundImage, uploadDefaultBannerImage,
  uploadThumbnailS3, uploadThumbnail, CDN_BASE_URL }) => {
  const [form] = useForm<MusicMetaDataFormFields>()

  useEffect(() => {
    if (!data) return
    const singers: { language: string, singer_names: string }[] = []
    const titles: { language: string, title: string }[] = []
    const banner_titles: { language: string, title: string }[] = []
    const suggestive_tool_tip_texts: { language: string, title: string }[] = []
    const new_god_ids: { language: string, new_god_id: string }[] = []
    // fix type errors later 
    Object.keys(data.singer_name).forEach((key, index: number) => {
      singers.push({ language: key, singer_names: data.singer_name[key].map((name: string) => ({ name: name })) })
    })
    Object.keys(data.audio_title).forEach((key) => {
      titles.push({ language: key, title: data.audio_title[key] })
    })
    if (data.banner_titles) {
      Object.keys(data.banner_titles).forEach((key) => {
        banner_titles.push({ language: key, title: data.banner_titles[key] })
      })
    }
    if (data.suggestive_tool_tip_texts) {
      Object.keys(data.suggestive_tool_tip_texts).forEach((key) => {
        suggestive_tool_tip_texts.push({ language: key, title: data.suggestive_tool_tip_texts[key] })
      })
    }

    if (data.new_god_ids) {
      Object.keys(data.new_god_ids).forEach((key) => {
        new_god_ids.push({ language: key, new_god_id: data.new_god_ids[key] });
      })
    }

    const banner_image_path = data.banner_image_path ? getAntUploadFile(data.banner_image_path, CDN_BASE_URL) : undefined;
    const background_image_path = data.background_image_path ? getAntUploadFile(data.background_image_path, CDN_BASE_URL) : undefined;
    form.setFieldsValue({ ...data, singers, titles, banner_image_path, background_image_path, banner_titles, suggestive_tool_tip_texts, new_god_ids } as any)
  }, [data])

  const onFinish = async (values: any) => {
    const singer_name = {}
    values?.singers.forEach((element: { language: string, singer_names: any[] }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      singer_name[element.language] = element.singer_names.map((nameFeild: any) => nameFeild.name);
    })
    const titles = {}
    values?.titles.forEach((element: { language: string, title: string }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      titles[element.language] = element.title;
    })

    const suggestive_tool_tip_texts = {}
    values?.suggestive_tool_tip_texts.forEach((element: { language: string, title: string }) => {
      if (!element.title) return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      suggestive_tool_tip_texts[element.language] = element.title;
    })
    const banner_titles = {}
    values?.banner_titles.forEach((element: { language: string, title?: string }) => {
      if (!element.title) return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      banner_titles[element.language] = element.title;
    })

    const new_god_ids = {}
    values?.new_god_ids.forEach((element: { language: string, new_god_id?: string }) => {
      if (!element.new_god_id) return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      new_god_ids[element.language] = element.new_god_id;
    })

    values.banner_image_path = (values.banner_image_path && values.banner_image_path.length > 0) ? values.banner_image_path[0].response.path : null
    values.background_image_path = (values.background_image_path && values.background_image_path.length > 0) ? values.background_image_path[0].response.path : null


    delete values['singers']
    try {

      const response = await onSubmit({
        ...values, singer_name, audio_title: titles, new_god_ids,
        banner_titles: Object.keys(banner_titles).length > 0 ? banner_titles : null,
        suggestive_tool_tip_texts: Object.keys(suggestive_tool_tip_texts).length > 0 ? suggestive_tool_tip_texts : null
      })
      response.success
        ? message.success(response.message)
        : message.error(response.message)
    } catch (error) {
      openErrorNotification(error);
    }
  }

  const language = Form.useWatch('audio_language', form);

  useEffect(() => {
    if (language && language !== 'hi' && language !== 'en') {
      const singers: [any] = form.getFieldValue('singers');
      if (singers.findIndex((singer) => singer.language === language) === -1) {
        singers.push({ language: language })
        form.setFieldValue('singers', singers);
      }

      const titles: [any] = form.getFieldValue('titles');
      if (titles.findIndex((title) => title.language === language) === -1) {
        titles.push({ language: language })
        form.setFieldValue('titles', titles);
      }

      const banner_titles: [any] = form.getFieldValue('banner_titles');
      if (banner_titles.findIndex && banner_titles.findIndex((banner_title) => banner_title.language === language) === -1) {
        banner_titles.push({ language: language })
        form.setFieldValue('banner_titles', banner_titles);
      }

      const suggestive_tool_tip_texts: [any] = form.getFieldValue('suggestive_tool_tip_texts');
      if (suggestive_tool_tip_texts.findIndex && suggestive_tool_tip_texts.findIndex((suggestive_tool_tip_text) => suggestive_tool_tip_text.language === language) === -1) {
        suggestive_tool_tip_texts.push({ language: language })
        form.setFieldValue('suggestive_tool_tip_texts', suggestive_tool_tip_texts);
      }
    }
  }, [language])

  const onFinishFailed: FormProps<MusicMetaDataFormFields>['onFinishFailed'] = (errorInfo) => {
    console.log(errorInfo)
  }

  const thumbnailPath = Form.useWatch('thumbnail_path', form);
  const video_template_path = Form.useWatch('video_template_path', form);

  const banner_image_path = Form.useWatch('banner_image_path', form) as unknown as UploadFile[];
  const background_image_path = Form.useWatch('background_image_path', form) as unknown as UploadFile[];

  const addBannerImageUsingPath = useCallback((path: string) => {

    form.setFieldValue('banner_image_path', getAntUploadFile(path, NEW_CDN_HOST))
  }, [form])

  const addBackgroundImagePath = useCallback((path: string) => {
    form.setFieldValue('background_image_path', getAntUploadFile(path, NEW_CDN_HOST))
  }, [form])

  return (
    <Row style={{ padding: '40px', paddingLeft: '10%', paddingRight: '10%' }}>
      <Col span={24} xs={24} sm={24} md={24} lg={24}>
        <Card title={'Music Metadata update'}>
          <Form
            name='music-metadata-upload-form'
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            layout="vertical"
          >
            <Form.Item name='audio_language' label='Language' rules={[{ required: true }]}>
              <Select
              >
                {SUPPORTED_LANGUAGES_LIST.map(language =>
                  <Select.Option key={language.value} value={language.value}>
                    {language.alt_name} ({language.name})
                  </Select.Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label='Tags'
              name='tags'
              rules={[
                { required: true, message: 'Please select atleast one tag!' },
              ]}
            >
              <Select
                showSearch
                filterOption={false}
                mode="tags"
                onSearch={onTagSearch}
                options={tags.map((tag) => ({
                  label: tag.tag,
                  value: tag.tag,
                }))}
              />
            </Form.Item>
            <br></br>
            <br></br>
            <Form.Item label={'Singer search name'} name={'singer_name_search'} rules={[{ required: true }]}>
              <Input placeholder='Singer name search' />
            </Form.Item>


            <Form.List name="singers" initialValue={[{ language: 'hi' }, { language: 'en' }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', alignItems: 'center', minHeight: '70px', padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }}>
                      <Form.Item
                        label={'Select Language'}
                        {...restField}
                        name={[name, 'language']}
                        rules={[{
                          required: true, validator(rule, value, callback) {
                            if (form.getFieldValue('singers').filter((singer: any) => singer.language === value).length > 1) {
                              callback("Singer for this language is already added!")
                            } else {
                              callback(undefined)
                            }
                          },
                        }]}
                      >
                        <Select
                          style={{ width: '180px' }}
                          placeholder={'Select language'}
                        >
                          {SUPPORTED_LANGUAGES_LIST.map(language =>
                            <Select.Option key={language.value} value={language.value} >
                              {language.alt_name} ({language.name})
                            </Select.Option>
                          )}
                        </Select>
                      </Form.Item>
                      <Form.List name={[name, 'singer_names']} initialValue={[{ name: '' }]}>
                        {(fields, { add, remove }) => (
                          <>
                            <Space key={key} style={{ display: 'flex', alignItems: 'center', width: '500px', flexWrap: 'wrap' }}>
                              {fields.map(({ key, name: index, ...restField }) => (
                                <>
                                  <Form.Item
                                    label={'Singer name'}
                                    {...restField}
                                    name={[index, 'name']}
                                    rules={[{ required: true }]}
                                  >
                                    <Input placeholder="Name" />
                                  </Form.Item>
                                  {index > 0 && <Button style={{ border: '1px solid light' }} onClick={() => remove(index)} >
                                    <MinusCircleOutlined />
                                  </Button>}

                                </>
                              ))}
                              <Button style={{ border: '1px solid lightblue' }} onClick={() => add()}>
                                <PlusOutlined />
                              </Button>
                            </Space>
                          </>
                        )}
                      </Form.List>
                      {!['hi', 'en', language].includes(form.getFieldValue(['singers', name, 'language'])) && <Button style={{ border: '1px solid light' }} onClick={() => remove(name)}>
                        <MinusCircleOutlined /> remove singer
                      </Button>}
                    </Space>
                  ))}
                  <Form.Item>
                    <Button style={{ width: '200px' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add singer name
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <br></br>
            <br></br>
            <Form.Item label={'Title search name'} name={'audio_title_search'} rules={[{ required: true }]}>
              <Input placeholder='Title Search name' />
            </Form.Item>
            <Form.List name="titles" initialValue={[{ language: 'hi' }, { language: 'en' }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', minHeight: '70px', alignItems: 'center', padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }} align="baseline">
                      <Form.Item
                        label={'Select Language'}
                        {...restField}
                        name={[name, 'language']}
                        rules={[{
                          required: true, validator(rule, value, callback) {
                            if (form.getFieldValue('titles').filter((title: any) => title.language === value).length > 1) {
                              callback("Title for this language is already added!")
                            } else {
                              callback(undefined)
                            }
                          },
                        }]}
                      >
                        <Select
                          style={{ width: '180px' }}
                          placeholder={'Select language'}
                        >
                          {SUPPORTED_LANGUAGES_LIST.map(language =>
                            <Select.Option key={language.value} value={language.value}>
                              {language.alt_name} ({language.name})
                            </Select.Option>
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={'Audio title'}
                        {...restField}
                        name={[name, 'title']}
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Audio title" />
                      </Form.Item>
                      {!['hi', 'en', language].includes(form.getFieldValue(['titles', name, 'language'])) && <Button style={{ border: '1px solid light' }} onClick={() => remove(name)}>
                        <MinusCircleOutlined /> remove audio title
                      </Button>
                      }
                    </Space>
                  ))}
                  <Form.Item>
                    <Button style={{ width: '200px' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add audio title
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <br></br>
            <br></br>
            <Form.List name="new_god_ids" >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', minHeight: '70px', alignItems: 'center', padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }} align="baseline">
                      <Form.Item
                        label={'Select Language'}
                        {...restField}
                        name={[name, 'language']}
                        rules={[{
                          required: true, validator(rule, value, callback) {
                            if (form.getFieldValue('new_god_ids').filter((title: any) => title.language === value).length > 1) {
                              callback("Title for this language is already added!")
                            } else {
                              callback(undefined)
                            }
                          },
                        }]}
                      >
                        <Select
                          style={{ width: '180px' }}
                          placeholder={'Select language'}
                        >
                          {SUPPORTED_LANGUAGES_LIST.map(language =>
                            <Select.Option key={language.value} value={language.value}>
                              {language.alt_name} ({language.name})
                            </Select.Option>
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={'New god id'}
                        style={{ width: '400px' }}
                        {...restField}
                        name={[name, 'new_god_id']}
                      >
                        <Input placeholder="New god id" />
                      </Form.Item>
                      <Button style={{ border: '1px solid light' }} onClick={() => remove(name)}>
                        <MinusCircleOutlined /> Remove new god id
                      </Button>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button style={{ width: '200px' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add new god id
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <br></br>
            <br></br>
            <Form.Item
              label='Add Lyrics'
              name={'lyrics'}

            >
              <LyricsEditor />
            </Form.Item>
            <Form.Item
              label='Add Thumbnail'
              name='thumbnail_path'
              // valuePropName='fileList'
              // getValueFromEvent={normFile}
              rules={[
                { required: true, message: 'Please add a thumbnail image' },
              ]}
            >
              <Select
                showSearch
                filterOption={false}
                onSearch={onSearchThumbnail}
              >
                {thumbnail.map((t) => {
                  return <Option key={t.image_path} value={t.image_path}>
                    {t.image_path && t.image_path !== '' && <img src={CDN_BASE_URL + t.image_path} height={20} width={20}></img>}
                    {" " + t.code}
                  </Option>
                })}
              </Select>

            </Form.Item>
            <Form.Item label="Thumbnail Preview" >
              {thumbnailPath && thumbnailPath !== '' && <img src={CDN_BASE_URL + thumbnailPath} height={40} width={40}></img>}
              <UploadThumbnailModal uploadThumbnailS3={uploadThumbnailS3} uploadThumbnail={uploadThumbnail} />
            </Form.Item>
            <Form.Item label='Riddler Audio Type' name={'riddler_audio_type'}>
              <Select
                placeholder={'Riddler Audio Type'}
                style={{ minWidth: '400px', width: '100%' }}
              >
                {Object.keys(RiddlerAudioType).map((audioType, index) => (
                  <Option key={index} value={audioType}>
                    {audioType.toUpperCase()}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='Video template path'
              name='video_template_path'
            >
              <Select
                showSearch
                filterOption={false}
                onSearch={onSearchVideoTemplates}
              >
                {videoTemplates.map((t) => {
                  return <Option key={t.id} value={t.video_path}>
                    <Space direction='horizontal'>
                      {" " + t.code}
                    </Space>
                  </Option>
                })}
              </Select>
            </Form.Item>
            {
              video_template_path && <>
                <Form.Item label="Video share start duration (In seconds)" name={'video_share_start_duration'} required >
                  <InputNumber ></InputNumber>
                </Form.Item>
                <Form.Item label="Video share play duration (In seconds)" name={'video_share_play_duration'} required rules={[{ type: "number", min: 1, max: 30 }]}>
                  <InputNumber></InputNumber>
                </Form.Item>
              </>
            }

            <Form.Item label='Riddler Audio Id' name={'riddler_audio_id'}>
              <Input />
            </Form.Item>
            <Form.Item label='Riddler Deeplink' name={'riddler_deeplink'}>
              <Input />
            </Form.Item>
            <Form.Item label='Riddler God Id' name={'riddler_god_id'}>
              <Input />
            </Form.Item>
            <Form.Item label='New God Id' name={'new_god_id'}>
              <Input />
            </Form.Item>
            <Form.List name="suggestive_tool_tip_texts" initialValue={[{ language: 'hi' }, { language: 'en' }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', minHeight: '70px', alignItems: 'center', padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }} align="baseline">
                      <Form.Item
                        label={'Select Language'}
                        {...restField}
                        name={[name, 'language']}
                        rules={[{
                          required: false, validator(rule, value, callback) {
                            if (form.getFieldValue('suggestive_tool_tip_texts').filter((title: any) => title.language === value).length > 1) {
                              callback("Title for this language is already added!")
                            } else {
                              callback(undefined)
                            }
                          },
                        }]}
                      >
                        <Select
                          style={{ width: '180px' }}
                          placeholder={'Select language'}
                        >
                          {SUPPORTED_LANGUAGES_LIST.map(language =>
                            <Select.Option key={language.value} value={language.value}>
                              {language.alt_name} ({language.name})
                            </Select.Option>
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={'Enter suggestive tooltip texts '}
                        {...restField}
                        name={[name, 'title']}
                      >
                        <Input placeholder="Suggestive tooltip texts" />
                      </Form.Item>
                      {!['hi', 'en', language].includes(form.getFieldValue(['suggestive_tool_tip_texts', name, 'language'])) && <Button style={{ border: '1px solid light' }} onClick={() => remove(name)}>
                        <MinusCircleOutlined /> remove
                      </Button>
                      }
                    </Space>
                  ))}
                  <Form.Item>
                    <Button style={{ width: '300px' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add suggestive tooltip texts
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.List name="banner_titles" initialValue={[{ language: 'hi' }, { language: 'en' }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', minHeight: '70px', alignItems: 'center', padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }} align="baseline">
                      <Form.Item
                        label={'Select Language'}
                        {...restField}
                        name={[name, 'language']}
                        rules={[{
                          required: false, validator(rule, value, callback) {
                            if (form.getFieldValue('banner_titles').filter((title: any) => title.language === value).length > 1) {
                              callback("Title for this language is already added!")
                            } else {
                              callback(undefined)
                            }
                          },
                        }]}
                      >
                        <Select
                          style={{ width: '180px' }}
                          placeholder={'Select language'}
                        >
                          {SUPPORTED_LANGUAGES_LIST.map(language =>
                            <Select.Option key={language.value} value={language.value}>
                              {language.alt_name} ({language.name})
                            </Select.Option>
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={'Enter banner title '}
                        {...restField}
                        name={[name, 'title']}
                      >
                        <Input placeholder="Banner title" />
                      </Form.Item>
                      {!['hi', 'en', language].includes(form.getFieldValue(['banner_titles', name, 'language'])) && <Button style={{ border: '1px solid light' }} onClick={() => remove(name)}>
                        <MinusCircleOutlined /> remove
                      </Button>
                      }
                    </Space>
                  ))}
                  <Form.Item>
                    <Button style={{ width: '300px' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add banner titles
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item label='Audio Types' name={'audio_types'}>
              <Select
                placeholder={'Audio Types'}
                style={{ minWidth: '400px', width: '100%' }}
                mode="multiple"
              >
                {Object.keys(RiddlerAudioType).map((audioType, index) => (
                  <Option key={index} value={audioType}>
                    {audioType.toUpperCase()}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <AntdFileUploadV2
                rules={[{ required: false }]}
                name={'banner_image_path'}
                accept="image/png,image/jpg,image/jpeg,image/webp"
                s3UploadApi={uploadDefaultBannerImage}
                title={'Update banner'}
                // fileValidation={iconValidations}
                fileList={banner_image_path}
              />
              <span> Or </span>
              <Form.Item style={{ width: '80%', margin: 0 }}>
                <Input onChange={(e) => {
                  if (e.target.value.trim().length > 0) {
                    addBannerImageUsingPath(e.target.value.trim())
                    e.target.value = '';
                  }
                }} placeholder="Paste media path" />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <AntdFileUploadV2
                rules={[{ required: false }]}
                name={'background_image_path'}
                accept="image/png,image/jpg,image/jpeg,image/webp"
                s3UploadApi={uploadBackgroundImage}
                title={'Upload background'}
                // fileValidation={iconValidations}
                fileList={background_image_path}
              />
              <span> Or </span>
              <Form.Item style={{ width: '80%', margin: 0 }}>
                <Input onChange={(e) => {
                  if (e.target.value.trim().length > 0) {
                    addBackgroundImagePath(e.target.value.trim())
                    e.target.value = '';
                  }
                }} placeholder="Paste media path" />
              </Form.Item>
            </div>

            {/* <Form.Item label='Video share path' name={'video_share_path'}>
              <Input />
            </Form.Item> */}

            <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
              <Button type='primary' htmlType='submit'>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col >
    </Row >
  )
}
