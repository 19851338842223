//context
import { useAppContext } from '@/components/AppContext'
//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'
//organisms

import { Button, Form, Input, Tag, Typography, message } from 'antd'

import React, { useCallback, useState } from 'react'
import { AntdFileUploadV2 } from '../../../../components/Forms/AntdFileUpload'
import { RcFile } from 'antd/lib/upload'
import { openNotification } from '../../../../utils'
import styled from 'styled-components'
import { NEW_CDN_HOST } from '../../../../utils/constants'
import { CopyOutlined } from '@ant-design/icons'

const audioUploadValidations = (file: RcFile) => {
  return true;

  //   const isLt2M = file.size <= 10 * 1024;
  //   if (!isLt2M) {
  //     isFailed = false;
  //     message.error('Image must smaller than 10kb');
  //   }


  // return isJpgOrPng && isFailed;
};

const Container = styled.div`
  max-width: 800px;
  margin: 20px auto;
  display: flex;
  y-gap: 30px;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
`

interface Props { }

const MusicUploadScreen: React.FC<Props> = () => {
  const { networkInstance } = useAppContext();
  const [m3u8Url, setM3u8Url] = useState<string>();


  const uploadAudio = useCallback(async (options) => {
    const res = await networkInstance.clientWithHeaders.contentApi.audio.getUrlToAudioUpload();
    const { url, path } = res.data.data;
    const headers = new Headers()
    headers.append('ContentType', options.file.type)
    await fetch(url, {
      method: 'PUT',
      body: options.file,
      headers,
    })
    return { url, path }
  }, [networkInstance.clientWithHeaders.contentApi.audio])

  const onCreate = useCallback(async (values) => {
    values.file_path = values.file_path[0].response.path;
    try {
      const res = await networkInstance.clientWithHeaders.contentApi.audio.getUrlToUploadAudioForConversion(values);
      setM3u8Url(res.data.data.path)
    } catch (error) {
      openNotification('error', { message: 'Failed to post the data try again!' })

    }
  }, [networkInstance.clientWithHeaders.contentApi.audio])

  const form = Form.useForm()[0];
  return (
    <div style={{ padding: '0 40px', }}>
      <Container>
        <Typography.Title level={4}>Music CDN Upload</Typography.Title>
        {m3u8Url && <Tag onClick={() => { navigator.clipboard.writeText(NEW_CDN_HOST + m3u8Url); message.success("copied !!") }} style={{ padding: 5, fontSize: '1em' }}>{NEW_CDN_HOST + m3u8Url} <CopyOutlined /> </Tag>}
        {m3u8Url && <Tag onClick={() => { navigator.clipboard.writeText(m3u8Url); message.success("copied !!") }} style={{ padding: 5, fontSize: '1em' }}>{m3u8Url} <CopyOutlined /> </Tag>}
        <Form
          form={form}
          onFinish={onCreate}
          layout="vertical"
        >
          <AntdFileUploadV2
            rules={[{ required: true }]}
            name={'file_path'}
            accept="audio/mp3,audio/flac"
            s3UploadApi={uploadAudio}
            title={'upload media'}
            fileValidation={audioUploadValidations}
          // fileList={media}
          />
          <Form.Item
            name={'file_indicator'}
            label={'File Indicator'}
            required
            style={{ width: '300px' }}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" >Submit</Button>
          </Form.Item>
        </Form>
      </Container >
    </div>
  )
}

export default withDefaultLayout(MusicUploadScreen)
