import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row, Select } from 'antd'
import { SUPPORTED_COUNTRY_LIST, SUPPORTED_LANGUAGES_LIST, LanguageList, CountryList, Country, Language } from '../../../admin/constants';

type List = Array<{
    label: string,
    value: string
}>

interface Props {
    form: any
    showCloneModal: boolean
    handleCloneModal: () => void
    handleCloneSubmit: () => void
}


const StoreCloneModal: React.FC<Props> = ({ form, showCloneModal, handleCloneModal, handleCloneSubmit }) => {
    const [countryList, setCountryList] = useState<List>()
    const [languageList, setLanguageList] = useState<List>()

    const parseCountryList = (countryList: CountryList) => {
        const list: List = []
        countryList?.map((country: Country) =>
            list.push({
                label: country?.country_name,
                value: country?.country_code,
            })
        )
        return list
    }

    const parseLanguageList = (languageList: LanguageList) => {
        const list: List = []
        languageList?.map((language: Language) =>
            list.push({
                label: language?.alt_name,
                value: language?.value,
            })
        )
        return list
    }

    useEffect(() => {
        setCountryList(parseCountryList(SUPPORTED_COUNTRY_LIST))
        setLanguageList(parseLanguageList(SUPPORTED_LANGUAGES_LIST))
    }, [])

    return (
        <Modal
            title="Store Clone"
            visible={showCloneModal}
            onOk={handleCloneSubmit}
            onCancel={handleCloneModal}
            okText='Submit'
            cancelText='Edit'
            width='600px'
        >
            <Form
                form={form}
                layout='vertical'
                name='clone modal'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
            >
                <Col>
                    <Row justify='space-between'>
                        <span style={{ flexBasis: '20%' }}>Source: {' '}</span>
                        <span style={{ flexBasis: '40%' }}>
                            <Form.Item
                                name='source_country'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Source Country',
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: 200 }}
                                    options={countryList}
                                    placeholder='Select Country'
                                >
                                </Select>
                            </Form.Item>
                        </span>
                        <span style={{ flexBasis: '40%' }}>
                            <Form.Item
                                name='source_language'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Source Language',
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: 200 }}
                                    options={languageList}
                                    placeholder='Select Language'
                                >
                                </Select>
                            </Form.Item>
                        </span>
                    </Row>
                    <Row justify='space-between' style={{ marginTop: '15px' }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <span style={{ flexBasis: '20%' }}>Destination: {' '}</span>
                            <span style={{ flexBasis: '40%' }}>
                                <Form.Item
                                    name='destination_country'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select Destination Country',
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{ width: 200 }}
                                        options={countryList}
                                        placeholder='Select Country'
                                    >
                                    </Select>
                                </Form.Item>
                            </span>
                            <span style={{ flexBasis: '40%' }}>
                                <Form.Item
                                    name='destination_language'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select Destination Language',
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{ width: 200 }}
                                        options={languageList}
                                        placeholder='Select Language'
                                    >
                                    </Select>
                                </Form.Item>
                            </span>
                        </div>
                    </Row>
                </Col>
            </Form>
        </Modal>
    )
}

export default StoreCloneModal
