import { Table } from "antd";

interface Props {
  platform_property: any;
}
const Platform = ({ platform_property }: Props) => {
  const getdescription = (record: any) => {
    return (
      <div style={{ paddingLeft: "48px" }}>
        <b>Platform Descrption : </b>
        {record.description}
      </div>
    );
  };
  const dataColumn = [
    {
      title: "ID (Platform)",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "30%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      ellipsis: true,
      width: "20%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      ellipsis: true,
      width: "20%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      ellipsis: true,
      width: "15%",
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      ellipsis: true,
      width: "15%",
      render: (text: any, record: any, index: any) => {
        return <span>{text === true ? "Yes" : "No"}</span>;
      },
    },
  ];
  return (
    <Table
      columns={dataColumn}
      dataSource={[{ ...platform_property, key: platform_property.id }]}
      expandable={{
        expandedRowRender: getdescription,
        expandRowByClick: true,
      }}
      pagination={false}
    ></Table>
  );
};

export default Platform;
