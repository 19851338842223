import { CaretRightOutlined, CheckCircleTwoTone, CopyOutlined, DownloadOutlined, ExclamationCircleOutlined, ExportOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons'
import { BlobProvider, Font } from '@react-pdf/renderer'
import {
  Button,
  Col,
  Collapse,
  Drawer,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Spin,
  Steps,
  Tabs,
  Tag,
  Typography,
} from 'antd'
import _ from 'lodash'

import React, { useRef, useState } from 'react'

import {
  Batch,
  PoojaMemberData,
} from '@a4b/api/src/modules/Monetisation/Puja/types'

import '../../../../../../Style.css'
import BatchPrintView from '@a4b/ui/src/modules/monetisation/puja/molecules/BatchPrintView'
import S3Upload, { AllowedFormats } from '@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload'
import { youtubeUrlRegex } from '../../../../../../utils/constants'
import SankalpNameTagging from '../SankalpNameTagging'
import { downloadVideo } from '@/utils/helper'
import { SANKALP_VIDEO_STATUS } from '../../utils/helper'
const { Panel } = Collapse

declare global {
  interface Window {
    XLSX: any
  }
}
Font.register({
  family: 'Noto Sans Hindi',
  fonts: [
    {
      src: `/Noto_Sans/NotoSans-Regular.ttf`,
    },
    {
      src: `/Noto_Sans/NotoSans-Bold.ttf`,
      fontWeight: 'bold',
    },
  ],
})
const { TabPane } = Tabs
const { Step } = Steps
const { Option } = Select
interface Props {
  onClose: () => void
  visible: boolean
  batchData: Batch | undefined
  currentStep: number
  cdnUrl: string
  preSignUrl: string
  form: any
  handleFormSubmit: () => void
  isPreviewDone: boolean
  openPreview: () => void
  onFormChange: () => void
  fetchBatch: (batchCode: string) => void
  handleChangeStep: (step: number) => void
}

const BatchView: React.FC<Props> = ({
  onClose,
  visible,
  batchData,
  currentStep,
  cdnUrl,
  preSignUrl,
  form,
  handleFormSubmit,
  isPreviewDone,
  openPreview,
  onFormChange,
  fetchBatch,
  handleChangeStep
}) => {
  const refTable: any = useRef()
  const [sankalpNameTagform] = Form.useForm()
  const [activeTab, setActiveTab] = useState('1')
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function downloadBlob(blob: any, name: string) {
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = data
    link.download = name

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    )

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data)
      link.remove()
    }, 100)
  }
  let findMaxMembersCount: number[] = []
  if (batchData?.product_type === "pooja") {
    const poojaMemberData = batchData?.pooja_member_data || [];

    if (poojaMemberData.length > 0) {
      const max = poojaMemberData?.reduce?.((prev: PoojaMemberData, current: PoojaMemberData) => {
        return (prev?.members?.length > current?.members?.length) ? prev : current;
      }, poojaMemberData[0])

      findMaxMembersCount = Array.from({ length: Number(max?.members?.length) }).map((u, i) => i);
    } else {
      findMaxMembersCount = [];
    }
  }


  const handleTabChange = (key: string) => {
    setActiveTab(key)
  }

  const handleFetchLatestBatchData = (key: string) => {
    setLoading(true)
    handleTabChange(key)
    setTimeout(() => {
      fetchBatch(batchData?.batch_code!)
      setLoading(false)
      handleChangeStep(2)
      sankalpNameTagform.resetFields()
    }, 2000)
  }

  console.log({ batchData })

  return (
    <Drawer
      width={1200}
      title={
        <Row justify='space-between'>
          <Typography>Batch view</Typography>
          {batchData?.video_tagging_status === SANKALP_VIDEO_STATUS.PROCESSING &&
            <Tag icon={<ExclamationCircleOutlined />} color='warning'>Video is still processing, please wait until it is processed</Tag>
          }
        </Row>
      }
      placement='right'
      size='large'
      onClose={() => {
        sankalpNameTagform.resetFields()
        onClose()
        handleTabChange('1')
      }}
      visible={visible}
    >
      <>
        <Collapse
          bordered={false}
          defaultActiveKey={activeTab === '2' ? ['2'] : ['1', '2']}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="site-collapse-custom-collapse"
        >
          <Panel header="Batch Information" key="1" className="site-collapse-custom-panel">
            <Row>
              <Col span={18}>
                <Row>
                  <Col span={8}>
                    <Typography.Title level={5}>Batch Code</Typography.Title>
                  </Col>
                  <Col span={16}><Tag color='green'>{batchData?.batch_code}</Tag></Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Typography.Title level={5}>Batch ID</Typography.Title>
                  </Col>
                  <Col span={16}>{batchData?.batch_id}</Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Typography.Title level={5}>SKU Name</Typography.Title>
                  </Col>
                  <Col span={16}>{batchData?.product_name}</Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Typography.Title level={5}>No. of Items</Typography.Title>
                  </Col>
                  <Col span={16}>{batchData?.no_of_items}</Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Typography.Title level={5}>Temple Name</Typography.Title>
                  </Col>
                  <Col span={16}>{batchData?.store_name}</Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Typography.Title level={5}>Product Type</Typography.Title>
                  </Col>
                  <Col span={16}>{batchData?.product_type?.toLocaleUpperCase()}</Col>
                </Row>
              </Col>
              <Col span={6}>
                <Row style={{ height: '100%' }}>
                  <Col
                    span={24}
                    style={{ alignSelf: 'center', justifySelf: 'center' }}
                  >
                    Batch status —
                    <Tag color={'blue'} style={{ marginLeft: '5px' }}>
                      {batchData?.status?.toLocaleUpperCase()}
                    </Tag>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Row>
          <Col span={24}>
            <Tabs
              activeKey={activeTab}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={handleTabChange}
              centered
            >

              <TabPane tab='Order Progression' key='1'>
                {loading ?
                  <Row style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin indicator={<LoadingOutlined spin />} size="large" />
                  </Row>
                  :
                  <>
                    <Steps direction='vertical' current={currentStep}>
                      <Step
                        title='Order Created'
                        description={
                          <div>{`This batch was created with ${batchData?.no_of_items} items`}</div>
                        }
                      />
                      {/* <Step
                    title='Export name List'
                    description={
                      <div>
                        {(batchData?.product_type === 'pooja' && currentStep === 1 ? (
                          <Col span={24} style={{ marginTop: '10px' }}>
                            <Button
                              type='primary'
                              onClick={() => {
                                const wb =
                                  window?.XLSX?.utils?.table_to_book?.(
                                    refTable.current,
                                  )
                                window?.XLSX?.writeFile(
                                  wb,
                                  `namelist_${batchData?.batch_id}.xlsx`,
                                )
                                handleNameListExport()
                              }}
                            >
                              <ExportOutlined />
                              Export Name List
                            </Button>
                          </Col>
                        ) : (
                          <BlobProvider
                            document={
                              <BatchPrintView
                                batchData={batchData}
                                dataType='name_list'
                              />
                            }
                          >
                            {({ blob, loading }) =>
                              loading ? (
                                'Loading document...'
                              ) : (
                                <Button
                                  type='primary'
                                  onClick={() => {
                                    downloadBlob(
                                      blob,
                                      `namelist_${batchData?.batch_id}.pdf`,
                                    )
                                    handleNameListExport()
                                  }}
                                >
                                  <ExportOutlined />
                                  Export Name List
                                </Button>
                              )
                            }
                          </BlobProvider>
                        ))}
                        {(batchData?.product_type === 'pooja'
                          ? currentStep === 3
                          : currentStep === 2) && (
                            <div>Name list exported sucessfully</div>
                          )}
                      </div>
                    }
                  /> */}
                      {(batchData?.product_type === 'pooja' || batchData?.product_type === 'item') && (
                        <Step
                          title='Name Sankalp tagging'
                          description={
                            <div>
                              {currentStep === 1 ? (
                                <Row style={{ marginTop: '10px' }}>
                                  <Col span={3}>
                                    <Button
                                      type='primary'
                                      onClick={() => handleTabChange('2')
                                      }
                                    >
                                      Proceed
                                    </Button>
                                  </Col>
                                  <Col span={6}>
                                    <Button
                                      type='default'
                                      onClick={() =>
                                        handleChangeStep(3)
                                      }
                                    >
                                      Skip Tagging
                                    </Button>
                                  </Col>
                                </Row>
                              ) : (
                                currentStep > 1 ?
                                  <Row style={{ marginTop: '10px' }}>
                                    <Col span={3}>
                                      <Button
                                        type='primary'
                                        onClick={() => handleTabChange('2')
                                        }
                                      >
                                        Update
                                      </Button>
                                    </Col>
                                  </Row> :
                                  <Row>
                                    <Col span={24}>Tag the Name and Gotra in raw video</Col>
                                  </Row>
                              )}
                            </div>
                          }
                        />
                      )}
                      {/* {batchData?.product_type === 'prayer' && (
                    <Step
                      title='Export Prayer List'
                      description={
                        <div>
                          <BlobProvider
                            document={
                              <BatchPrintView
                                batchData={batchData}
                                dataType='prayer_list'
                              />
                            }
                          >
                            {({ blob, loading }) =>
                              loading ? (
                                'Loading document...'
                              ) : (
                                <Button
                                  type='primary'
                                  onClick={() => {
                                    downloadBlob(
                                      blob,
                                      `prayer_${batchData?.batch_id}.pdf`,
                                    )
                                    handlePrayerExport()
                                  }}
                                >
                                  <ExportOutlined />
                                  Export Prayer
                                </Button>
                              )
                            }
                          </BlobProvider>
                        </div>
                      }
                    />
                  )} */}
                      {
                        <Step
                          title='Generate final video'
                          description={
                            (batchData?.video_tagging_status === SANKALP_VIDEO_STATUS.PROCESSED && batchData?.tagged_video_url ?
                              <>
                                <span style={{ color: '#00000073' }}>Final video is available <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor="#52c41a" /></span>
                                <a
                                  onClick={() => {
                                    message.info('Download started, check the downloads', 3)
                                    handleChangeStep(3)
                                  }}
                                  style={{ marginLeft: '20px' }} href={batchData?.tagged_video_url} target='blank'>Download video <DownloadOutlined /></a>
                                <Button onClick={() => {
                                  navigator.clipboard.writeText(batchData?.tagged_video_url)
                                  message.info('Link copied', 2)
                                  handleChangeStep(3)
                                }
                                }
                                  style={{ marginLeft: '20px' }} type="primary" icon={<CopyOutlined />}>
                                  Copy link
                                </Button>

                              </>
                              : batchData?.video_tagging_status === SANKALP_VIDEO_STATUS.PROCESSING ?
                                <div style={{ marginTop: '5px' }}>
                                  <span><Tag color='blue'>Processing <SyncOutlined spin /></Tag></span>
                                  <span style={{ marginLeft: '5px', color: '#00000073' }}>It might take 15-20 mins</span>
                                  {/* <Button onClick={() => handleChangeStep(3)} color='default' style={{ marginLeft: '20px' }}>Skip</Button> */}
                                </div> :
                                <>
                                  <span style={{ color: '#00000073' }}>Video not available</span>
                                  {/* {currentStep === 2 && <Button onClick={() => handleChangeStep(3)} color='default' style={{ marginLeft: '20px' }}>Skip</Button>} */}
                                </>
                            )
                          }
                        />
                      }
                      <Step
                        title='Upload Video'
                        description={
                          <div>
                            <span>
                              {batchData?.product_type === 'pooja'
                                ? `Update the status here, once the pooja is done`
                                : `Update the status here, once the offering is completed`}
                            </span>
                            {(batchData?.product_type === 'pooja' && currentStep === 3) && (
                              <div>
                                <Form
                                  form={form}
                                  layout='vertical'
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  onFieldsChange={onFormChange}
                                >

                                  <Form.Item
                                    label="Media type"
                                    name="videoType"
                                    labelCol={{ span: 12 }}
                                    wrapperCol={{ span: 12 }}
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Please select cta media type!',
                                      },
                                    ]}
                                  >
                                    <Select placeholder='Type'>
                                      <Option value='video'>Video</Option>
                                      <Option value='youtube'>Youtube</Option>
                                    </Select>
                                  </Form.Item>

                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                      prevValues.videoType !== currentValues.videoType
                                    }
                                  >
                                    {({ getFieldValue }) => (
                                      getFieldValue("videoType") === "video" &&
                                      <Form.Item
                                        label='Video URL'
                                        name='video_url'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                          {
                                            min: 1,
                                            max: 1,
                                            validator: async (
                                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                              rules: any,
                                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                              values: any,
                                            ) => {
                                              if (values?.length < rules.min) {
                                                return Promise.reject(
                                                  'Please upload Video',
                                                )
                                              }
                                            },
                                          },
                                        ]}
                                      >
                                        <S3Upload
                                          cdnUrl={cdnUrl}
                                          preSignUrl={preSignUrl}
                                          min={1}
                                          max={1}
                                          // preserveFilename={true}
                                          allowedFileExtension={[AllowedFormats.MP4]}
                                        />
                                      </Form.Item>
                                    )}
                                  </Form.Item>

                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                      prevValues.videoType !== currentValues.videoType
                                    }
                                  >

                                    {({ getFieldValue }) => (
                                      getFieldValue("videoType") === "youtube" &&

                                      <Form.Item
                                        label='Youtube video link'
                                        name='video_url'
                                        labelCol={{ span: 12 }}
                                        wrapperCol={{ span: 12 }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please input youtube video link!',
                                          },
                                          {
                                            pattern: youtubeUrlRegex,
                                            message: 'Please input a valid Youtube video link!',
                                          },
                                        ]}
                                      >
                                        <Input placeholder='Enter youtube video link' />
                                      </Form.Item>

                                    )}
                                  </Form.Item>

                                  <Row style={{ paddingTop: '20px' }}>
                                    <Col span={6}>
                                      <Form.Item
                                        shouldUpdate
                                      >
                                        {() =>
                                          form
                                            .getFieldsError()
                                            .filter(
                                              ({ errors }: any) => errors.length,
                                            ).length > 0 || !isPreviewDone ? (
                                            <Button type='primary' disabled={true}>
                                              Submit
                                            </Button>
                                          ) : (
                                            <Popconfirm
                                              placement='topLeft'
                                              title={
                                                'This video has been published on the aplication. are you sure to update this video?'
                                              }
                                              onConfirm={handleFormSubmit}
                                              okText='Yes Submit'
                                              cancelText='No'
                                            >
                                              <Button type='primary'>Submit</Button>
                                            </Popconfirm>
                                          )
                                        }
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item shouldUpdate className='submit'>
                                        {({ getFieldValue }) => {
                                          return form
                                            .getFieldsError()
                                            .filter(
                                              ({ errors }: any) => errors.length,
                                            ).length > 0 || (getFieldValue("videoType") === 'youtube' && getFieldValue("video_url")?.length <= 0) ? (
                                            <Button type='primary' disabled={true}>
                                              Preview
                                            </Button>
                                          ) : (
                                            <Button
                                              onClick={openPreview}
                                              type='primary'
                                            >
                                              Preview
                                            </Button>
                                          )
                                        }}
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form>
                              </div>
                            )}
                          </div>
                        }
                      />
                    </Steps>
                    {batchData?.product_type === "pooja" && <table hidden ref={refTable} className='puja-table'>
                      <thead>
                        <tr>
                          <th>Pooja Name / ID</th>
                          <th>SKU ID</th>
                          <th>Gotra</th>
                          {findMaxMembersCount?.map(
                            (item: number, index: number) => (
                              <th key={index}>Name {index + 1}</th>
                            ),
                          )}

                          <th>Phone Number</th>
                          <th>WhatsApp Number</th>
                          <th>Opted for prasad</th>
                          <th>Address</th>
                          <th>Pincode</th>
                        </tr>
                      </thead>
                      <tbody>
                        {batchData?.pooja_member_data?.map(
                          (pujamembers: PoojaMemberData, index: number) => {
                            return (<tr key={index}>
                              <td>{batchData?.product_name}</td>
                              <td>{batchData?.inventory_id}</td>
                              <td>{pujamembers?.gotra}</td>
                              {findMaxMembersCount?.map(
                                (index: number) => (
                                  <>
                                    {<td key={index}>{pujamembers?.members?.[index]?.full_name}</td>}
                                  </>
                                ),
                              )}
                              <td>{pujamembers?.mobile_number}</td>
                              <td>{pujamembers?.whatsapp_number}</td>
                              <td>{pujamembers?.is_opted_for_prasad ? "Yes" : "No"}</td>
                              <td>
                                {`${pujamembers?.booking_address?.house_info},${pujamembers?.booking_address?.area_info}`}
                                <br />
                                {`${pujamembers?.booking_address?.city_name},${pujamembers?.booking_address?.landmark}`}
                                <br />
                                {`${pujamembers?.booking_address?.state_name}`}
                              </td>
                              <td>
                                {pujamembers?.booking_address?.pin_code}
                              </td>
                            </tr>)
                          }

                        )}
                      </tbody>
                    </table>}
                  </>
                }
              </TabPane>
              {
                currentStep >= 1 &&
                <TabPane tab='Name Sankalp tagging' key='2'>
                  <SankalpNameTagging form={sankalpNameTagform} batchData={batchData} handleTabChange={handleFetchLatestBatchData} />
                </TabPane>
              }
              <TabPane tab='Name List' key='3'>
                {batchData?.product_type === 'pooja' ? (
                  <Row justify='end'>
                    <Col span={24}>
                      <Button
                        type='primary'
                        onClick={() => {
                          const wb = window?.XLSX?.utils?.table_to_book?.(
                            refTable.current,
                          )
                          window?.XLSX?.writeFile(
                            wb,
                            `namelist_${batchData?.batch_id}.xlsx`,
                          )
                        }}
                      >
                        <ExportOutlined />
                        Export Name List
                      </Button>
                    </Col>
                    <Col
                      span={24}
                      style={{ width: '800px', overflow: 'scroll' }}
                    >
                      <table ref={refTable} className='puja-table'>
                        <thead>
                          <tr>
                            <th>Pooja Name / ID</th>
                            <th>SKU ID</th>
                            <th>Gotra</th>
                            {findMaxMembersCount?.map(
                              (item: number, index: number) => (
                                <th key={index}>Name {index + 1}</th>
                              ),
                            )}

                            <th>Phone Number</th>
                            <th>WhatsApp Number</th>
                            <th>Opted for prasad</th>
                            <th>Address</th>
                            <th>Pincode</th>
                            <th>Add-ons</th>
                          </tr>
                        </thead>
                        <tbody>
                          {batchData?.pooja_member_data?.map(
                            (pujamembers: PoojaMemberData, index: number) => {
                              return (<tr key={index}>
                                <td>{batchData?.product_name}</td>
                                <td>{batchData?.inventory_id}</td>
                                <td>{pujamembers?.gotra}</td>
                                {findMaxMembersCount?.map(
                                  (index: number) => (
                                    <>
                                      {<td key={index}>{pujamembers?.members?.[index]?.full_name}</td>}
                                    </>
                                  ),
                                )}
                                <td>{pujamembers?.mobile_number}</td>
                                <td>{pujamembers?.whatsapp_number}</td>
                                <td>{pujamembers?.is_opted_for_prasad ? "Yes" : "No"}</td>
                                <td>
                                  {`${pujamembers?.booking_address?.house_info},${pujamembers?.booking_address?.area_info}`}
                                  <br />
                                  {`${pujamembers?.booking_address?.city_name},${pujamembers?.booking_address?.landmark}`}
                                  <br />
                                  {`${pujamembers?.booking_address?.state_name}`}
                                </td>
                                <td>
                                  {pujamembers?.booking_address?.pin_code}
                                </td>
                                <td>
                                  {batchData?.meta_data?.add_ons &&
                                    Object.entries(batchData?.meta_data?.add_ons)
                                      ?.filter(([key, _]) => key === pujamembers?.order_id)?.map((item: any) => (item[1]?.join(' ,  ')))}
                                </td>

                              </tr>)
                            }
                          )}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row justify='end'>
                      <div>
                        <BlobProvider
                          document={
                            <BatchPrintView
                              batchData={batchData}
                              dataType='name_list'
                            />
                          }
                        >
                          {({ blob, loading }) =>
                            loading ? (
                              'Loading document...'
                            ) : (
                              <Button
                                type='primary'
                                onClick={() => {
                                  downloadBlob(
                                    blob,
                                    `namelist_${batchData?.batch_id}.pdf`,
                                  )
                                }}
                              >
                                <ExportOutlined />
                                Export Name List
                              </Button>
                            )
                          }
                        </BlobProvider>
                      </div>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {' '}
                        <Typography.Title level={4}>{`Batch ID/Code : ${batchData?.batch_code || batchData?.batch_id
                          }`}</Typography.Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        {batchData?.offering_names.map(
                          (name: string, index: number) => (
                            <Typography.Title level={4} key={index}>
                              {`${index + 1}. ${name}`} {batchData?.meta_data?.quantity_list?.[index] ? `X ${batchData?.meta_data?.quantity_list?.[index]}` : ""}
                            </Typography.Title>
                          ),
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </TabPane>
              {batchData?.product_type === 'prayer' && (
                <TabPane tab='Prayer List' key='3'>
                  <Row>
                    <Col span={16}>
                      {batchData?.meta_data?.prayer_notes?.map(
                        (prayer_notes: string, index: number) => (
                          <Typography.Title level={4} key={index}>
                            {`${index + 1}. ${prayer_notes}`}
                          </Typography.Title>
                        ),
                      )}
                    </Col>
                    <Col span={8}>
                      <BlobProvider
                        document={
                          <BatchPrintView
                            batchData={batchData}
                            dataType='prayer_list'
                          />
                        }
                      >
                        {({ blob, loading }) =>
                          loading ? (
                            'Loading document...'
                          ) : (
                            <Button
                              type='primary'
                              onClick={() => {
                                downloadBlob(
                                  blob,
                                  `prayer_${batchData?.batch_id}.pdf`,
                                )
                              }}
                            >
                              <ExportOutlined />
                              Export Prayer
                            </Button>
                          )
                        }
                      </BlobProvider>
                    </Col>
                  </Row>
                </TabPane>
              )}
            </Tabs>
          </Col>
        </Row>
      </>
    </Drawer >
  )
}
export default BatchView
