import { UserRoles } from '@a4b/api/src/modules/Monetisation/Offerings/types'

export enum LoginState {
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
}

export interface IUserProfile {
  loginStatus: LoginState
  name?: string
  imageUrl?: string
  email?: string
}

class UserProfile implements IUserProfile {
  loginStatus: LoginState = LoginState.LOGGED_OUT
  name?: string
  imageUrl?: string
  email?: string
  monetisationRoles?: UserRoles

  constructor(profile?: IUserProfile) {
    this.setProfileData(profile)
  }

  setProfileData(profile?: IUserProfile) {
    if (!profile) {
      return
    }
    this.loginStatus = profile.loginStatus
    this.name = profile.name
    this.imageUrl = profile.imageUrl
    this.email = profile.email
  }
  setMonetisationROles(roles: UserRoles) {
    this.monetisationRoles = roles
  }
}
export default UserProfile
