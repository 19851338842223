import React from "react";
import { Button, Form, Input, InputNumber } from "antd";
import { useForm } from "antd/lib/form/Form";
import { REQUIRED_RULE } from "../../../../../utils/form.validation";
import styled from "styled-components";
import NudgeForm from "./NudgeForm";
import { useAppContext } from "../../../../../components/AppContext";
import { openErrorNotification, openNotification } from "../../../../../utils";

const HiddenFormItem = styled(Form.Item)`
  display: none;
`;
const CustomNudge = () => {
  const form = useForm()[0];
  const { networkInstance } = useAppContext();
  const onFinish = async (values: any) => {
    delete values["nudge"]["wait_interval_seconds"];
    try {
      const { data } = await networkInstance.clientWithHeaders.inAppNudge.customNudge(values);
      openNotification("success", { message: "Custom nudge sent successfully" })
    } catch (error) {
      openErrorNotification(error);
    }

  }
  return (<Form
    form={form}
    onFinish={onFinish}
    layout="vertical"
  >
    <Form.Item name="user_id" label="User id" rules={[REQUIRED_RULE[0], {
      validator(rule, value, callback) {
        if (!isNaN(value)) {
          callback();
        } else {
          callback("User id should be a number");
        }
      },
    }]}>
      <Input />
    </Form.Item>
    <Form.Item name={"timeout_interval_seconds"} label="Timeout interval (seconds)" rules={[REQUIRED_RULE[0], {
      validator(rule, value, callback) {
        if (value >= 0 && value <= 100) {
          callback();
        } else {
          callback("Timeout should be >= 0 and <= 100");
        }
      },
    }]}>
      <InputNumber onChange={(interval_seconds: number) => { if (interval_seconds) form.setFieldValue('timeout_interval', interval_seconds * 1000) }} />
    </Form.Item>
    <HiddenFormItem name="timeout_interval" >
      <InputNumber />
    </HiddenFormItem>
    <NudgeForm form={form} type="affirmative"></NudgeForm>
    <Form.Item>
      <Button type="primary" htmlType="submit">Submit</Button>
    </Form.Item>
  </Form>)
}

export default CustomNudge;