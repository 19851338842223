import React from "react"
import { Form, Row, Col, Button, Input, Select } from "antd"
import TextArea from "antd/lib/input/TextArea";
import { DeleteOutlined, HolderOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import S3Upload from "@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload";
// import { getFileFormat } from "../../utils/helper";

interface Props {
    fields: any
    cdnUrl: string
    preSignUrl: string
    add: () => void
    remove: (item: any) => void
    datatype: string
    form: any
}
interface FormItemsProps {
    index: number
    field: any
    remove: (item: any) => void
    cdnUrl: string
    preSignUrl: string
    itemIndex: number
    form: any
}
const FormItems = (props: FormItemsProps) => {
    const { field, remove, itemIndex, cdnUrl, preSignUrl, form } = props;
    console.log('xxxx', form.getFieldValue([field.name, 'header']))
    return (
        <div style={{
            border: '1px solid #e0dcdc',
            marginRight: '0px',
            marginBottom: '10px',
            padding: '10px 0px',
            width: '100%',
            position: 'relative',
            zIndex: 10000000,
            backgroundColor: 'white',
            cursor: 'grab'
        }}>
            <Col key={itemIndex} style={{ width: '100%', justifyContent: 'end', alignItems: "center", paddingLeft: '10px', paddingRight: "10px" }}>
                <Row style={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Col span={2}>
                        <HolderOutlined />
                    </Col>
                    <Col span={22}>
                        <Row style={{ width: '100%', justifyContent: 'end' }}>
                            <Button
                                style={{ width: '5%', marginRight: '10px' }}
                                onClick={() => {
                                    remove(field.name)
                                }}
                                type='ghost'
                                icon={<DeleteOutlined />}
                                size='small'
                                danger
                            />
                        </Row>
                        <Form.Item
                            label={`Header`}
                            name={[field.name, 'header']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: false,
                                    message: 'Input Header',
                                },
                            ]}
                        >
                            <Input placeholder="Enter header" />
                        </Form.Item>
                        <Form.Item
                            label={`Description`}
                            name={[field.name, 'description']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: false,
                                    message: 'Input Description',
                                },
                            ]}
                        >
                            <TextArea placeholder="Description" />
                        </Form.Item>
                        <Form.Item
                            label={`CTA text`}
                            name={[field.name, 'cta_text']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: false,
                                    message: 'Input CTA text',
                                },
                            ]}
                        >
                            <Input placeholder="Enter CTA text" />
                        </Form.Item>
                        <Row justify="space-between" style={{ width: '100%' }}>
                            <Form.Item name={[field.name, "cta_media", "media_type"]} label="Media type" style={{ flexBasis: '30%' }}>
                                <Select
                                    placeholder="Select media type"
                                >
                                    <Select.Option value="media">Media</Select.Option>
                                    <Select.Option value="url">URL</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item shouldUpdate={true}>
                                {({ getFieldValue }) => {
                                    return getFieldValue(['message_cards', field.name, 'cta_media', 'media_type']) === "url" ? (
                                        <Form.Item
                                            label="Media URL"
                                            name={[field.name, "cta_media", "media_url"]}
                                            // rules={[{ required: false, message: "Input Media URL" }]}
                                            style={{ flexBasis: "30%", marginLeft: "10px" }}
                                        >
                                            <Input placeholder="Enter URL" />
                                        </Form.Item>
                                    ) : (
                                        <Form.Item
                                            label="Media"
                                            name={[field.name, "cta_media", "media_url"]}
                                            // rules={[
                                            //     {
                                            //         required: false,
                                            //         validator: async (rules, values) => {
                                            //             if (!values || values.length === 0) {
                                            //                 return Promise.reject("Please upload Media");
                                            //             }
                                            //         },
                                            //     },
                                            // ]}
                                            valuePropName="fileList"
                                            style={{ paddingLeft: "0px", marginBottom: "0px" }}
                                        >
                                            <S3Upload
                                                cdnUrl={cdnUrl}
                                                preSignUrl={preSignUrl}
                                                min={0}
                                                max={1}
                                            // onChange={(fileList) => {
                                            //     if (fileList?.length > 0) {
                                            //         const fileUrl = fileList?.[0]?.url || fileList?.[0]?.response?.url;
                                            //         const fileExtension = fileUrl?.split?.(".")?.pop?.();
                                            //         const mediaType = getFileFormat(fileExtension);
                                            //         form.setFieldValue([field.name, "media_type"], mediaType);
                                            //     }
                                            // }}
                                            />
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>


                            <Form.Item
                                label={`Thumbnail`}
                                name={[field.name, 'cta_media', 'media_thumbnail']}
                                rules={[
                                    {
                                        required: false,
                                        min: 0,
                                        max: 1,
                                        validator: async (rules: any, values: any) => {
                                            if (values?.length < rules.min) {
                                                return Promise.reject('Please upload Media')
                                            }
                                        },
                                    },
                                ]}
                                valuePropName='fileList'
                                style={{ paddingLeft: '0px', marginBottom: "0px" }}
                            >
                                <S3Upload
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    min={0}
                                    max={1}
                                />
                            </Form.Item>
                        </Row>
                        <Form.Item
                            label={`Media Description`}
                            name={[field.name, 'cta_media', 'media_description']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: false,
                                    message: 'Input Description',
                                },
                            ]}
                        >
                            <TextArea placeholder="Description" />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </div>
    )
}

const SortableFormItems = SortableElement(FormItems);

const MessageCards: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, datatype, form }) => {
    return (
        <Row justify="space-between" style={{ width: '100%' }}>
            {fields && [...fields].reverse().map((field: any, index: number) => {
                return (
                    // eslint-disable-next-line react/jsx-key
                    <SortableFormItems
                        index={fields.length - 1 - index} // Adjust the index to match the reversed array
                        itemIndex={fields.length - 1 - index} // Adjust the itemIndex accordingly
                        field={field}
                        remove={remove}
                        cdnUrl={cdnUrl}
                        preSignUrl={preSignUrl}
                        form={form}
                    />
                )
            })}
            <Col span={24} style={{ paddingTop: '10px' }}>
                <Row justify='start'>
                    <Col span={4}>
                        <Button
                            onClick={() => {
                                add()
                            }}
                            type='ghost'
                            icon={<PlusCircleOutlined />}
                            size='middle'
                        >
                            {datatype}
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}


export default SortableContainer(MessageCards)