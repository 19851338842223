import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import AntdFormWrapper from "../../../../../components/Forms/AntdFormWrapper";
import RechargePackageForm from "../components/RechargePackageForm";
import { useAppContext } from "@/components/AppContext";
import { GetRechargePackageRes } from "@a4b/api/src/modules/Wallet/types";
import { SEGMENT } from "../components/utils/types";
import { A4B_APPS } from "@/utils/constants";
import WalletRechargePackages from "@a4b/api/src/modules/Wallet/Wallet";

export enum DRAWER_TYPE {
    CREATE_RECHARGE_PACKAGE = 'Create Recharge Package',
    UPDATE_RECHARGE_PACKAGE = 'Update Recharge Package'
}

export default function useDrawerConfig(
    setActiveDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | undefined>>,
    getActiveRechargePackages: () => Promise<void>,
    segment: SEGMENT,
    rechagePackage?: GetRechargePackageRes,
    activeDrawer?: DRAWER_TYPE,
): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | {
    drawerProps: undefined,
    drawerContent: undefined,
} {
    const { networkInstance, app } = useAppContext()
    const { wallet, walletAstro } = networkInstance.clientWithHeaders
    const api: WalletRechargePackages = (app === A4B_APPS.SRIMANDIR) ? wallet : walletAstro
    const isSrimandir = app === A4B_APPS.SRIMANDIR

    const memoizedInitPackageData = useMemo(() => {
        const initRechargePackageData = {
            app_code: isSrimandir ? A4B_APPS.SRIMANDIR : A4B_APPS.ASTRO,
            ...(!isSrimandir && {
                language: 'en',
                language_mode: 'en',
                currency_code: 'USD'
            })
        }
        return initRechargePackageData
    }, [isSrimandir])

    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_RECHARGE_PACKAGE,
                width: 700,
            },
            drawerContent: <AntdFormWrapper<any, any>
                createApi={api.addRechargePackage}
                FormFields={RechargePackageForm}
                initValues={segment === SEGMENT.VARIANT ? { variant: true } : memoizedInitPackageData}
                submitSuccessfulCallback={() => {
                    setActiveDrawer(undefined);
                    getActiveRechargePackages();
                }}
            />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.UPDATE_RECHARGE_PACKAGE,
                width: 700,
            },
            drawerContent: <AntdFormWrapper<any, any>
                updateApiId={api.updateRechargePackagePayload}
                formData={segment === SEGMENT.VARIANT ? { ...rechagePackage, variant: true } : rechagePackage}
                FormFields={RechargePackageForm}
                submitSuccessfulCallback={() => {
                    setActiveDrawer(undefined);
                    getActiveRechargePackages();
                }}
            />
        }
    ], [api.addRechargePackage, api.updateRechargePackagePayload, segment, memoizedInitPackageData, rechagePackage, setActiveDrawer, getActiveRechargePackages])
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}