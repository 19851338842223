import { useAppContext } from '@/components/AppContext'
import ManageWidgets from '@/modules/feeds/container/widgets/ManageWidgets'
import AstrologerStatus from '@/modules/monetisation/Astrologer/AstrologerStatus'
import HomePage from '@/modules/monetisation/HomePage'
import HubList from '@/modules/monetisation/offerings/HubList'
import { RouteProps } from 'react-router-dom'

const ConditionalRoute = ({ children, ...rest }: RouteProps) => {
  const { monetisationRoles } = useAppContext()
  if (monetisationRoles?.user_type === 'admin' || monetisationRoles?.user_type === 'super_admin') {
    return <HomePage />
  } else if (monetisationRoles?.user_type === 'hub_manager') {
    return <HubList />
  } else if (monetisationRoles?.user_type === 'astrologer') {
    return <AstrologerStatus />
  }
  return <ManageWidgets />
}

export default ConditionalRoute
