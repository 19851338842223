import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { Response } from '../../../types'
import { Rashifal } from './types'
import { apiClient } from '../../../apiClient'

const API_END_POINT_PATH = '/gandalf/internal/v1/rashifal'

class RashifalApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getRashifal = (language_code: string, date: string, limit: number, offset: number) =>
    this.api.get<Response<{ count: number; rashifals: Rashifal.RashifalItem[] }>>(
      `${API_END_POINT_PATH}?language=${language_code}&date=${date}&limit=${limit}&offset=${offset}`
    )

  createRashifal = (payload: Rashifal.CreateRashifalPayload) =>
    this.api.post<Response<{ data: string }>>(
      API_END_POINT_PATH,
      payload,
    )
  editRashifal = (id: string, payload: Rashifal.EditRashifalPayload) =>
    this.api.put<Response<{ data: string }>>(
      API_END_POINT_PATH + '/' + id,
      payload,
    )
  deleteRashifal = (id: string) =>
    this.api.delete<Response<{ data: string }>>(
      API_END_POINT_PATH + '/' + id
    )
}

export default RashifalApi
