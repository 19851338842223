import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { Response } from '../../types'
import type {
  Community,
  CommunityAuthor,
  CommunityAuthorData,
  CommunityCategory,
  CommunityData,
  CommunityMessage,
  CommunitySendMessageOptions,
  CommunityTags,
  CommunityTagsData,
  CreateCommunityPayload,
  CreateMessagePayload,
  INFO_LEVEL,
} from './types'
import { apiClient } from '../../apiClient'

class CommunityApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getCommunityByCode = (code: string, info: INFO_LEVEL = 'LOW') =>
    this.api.get<Response<Community>>(
      `/vixen/external/v2/communities/${code}/?info=${info}`,
    )

  getCommunitiesForPurohit = () =>
    this.api.get<Response<CommunityData>>(
      '/vixen/external/v2/community-purohits/communities',
    )

  sendMessageForPurohit = (options: CommunitySendMessageOptions) =>
    this.api.post('/vixen/external/v2/community-purohits/send-message', options)

  //TODO: maybe move tags and author to a different class
  getTags = () =>
    this.api.get<Response<CommunityTagsData>>('/vixen/external/v2/tags/?q')

  getAuthorsForCommunity = (community_code: string) =>
    this.api.get<Response<CommunityAuthorData>>('/vixen/external/v2/authors', {
      params: {
        community_code,
      },
    })

  // social 1.5 api's
  getCommunityTags = () => {
    return this.api.get<Response<{ total_count: number, categories: CommunityCategory[] }>>('/social/internal/v1/categories');
  }

  getContentTags = () => {
    return this.api.get<Response<{ total_count: number, tags: CommunityTags[] }>>('/social/internal/v1/tags');
  }

  getCommunities = () => this.api.get<Response<{ total_count: number, communities: Community[] }>>('/social/internal/v1/communities');

  getCommunityMessages = (communityId: string, limit: number, offset: number) => {
    return this.api.get<Response<{ total_count: number, messages: CommunityMessage[] }>>(`/social/internal/v1/community/${communityId}/messages?limit=${limit}&offset=${offset}`)
  }

  getAuthors = () => {
    return this.api.get<Response<{ total_count: number, authors: CommunityAuthor[] }>>(`/social/internal/v1/authors?limit=100`)
  }

  // todo remove optional 
  getCommunityAuthors = (community_id?: string) => {
    return this.api.get<Response<{ total_count: number, authors: CommunityAuthor[] }>>(`/social/internal/v1/authors?limit=100&${community_id ? `community_id=${community_id}` : ``}`)
  }

  createAuthor = (payload: { name: string, profile_image_id: string, community_id: string, profile_image_path: string }) => {
    return this.api.post('/social/internal/v1/authors', payload)
  }

  createCommunity = (payload: CreateCommunityPayload) => {
    return this.api.post('/social/internal/v1/communities', payload)
  }

  createMessage = (payload: CreateMessagePayload) => {
    return this.api.post('/social/internal/v1/messages', payload)
  }

  createCategory = (payload: { code: string, local_name: string }) => {
    return this.api.post('/social/internal/v1/categories', payload)
  }

  updateCategory = (payload: { rank: string }, categoryId: string) => {
    return this.api.patch('/social/internal/v1/categories/' + categoryId, payload)
  }

  createTag = async (payload: { code: string, name: string }) => {
    return this.api.post('/social/internal/v1/tags', payload)
  }

}

export default CommunityApi
