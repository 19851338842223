import { useAppContext } from '@/components/AppContext';
import { Button, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

interface PlaySongProps {
    audioId: string
}
const PlaySongs: React.FC<PlaySongProps> = (props: PlaySongProps) => {
    const { audioId } = props;
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const { networkInstance } = useAppContext();
    const [songPaths, setSongPaths] = useState<{ cdnAudioUrl: string, originalAudioUrl: string, m3u8AudioUrl: string } | undefined>()
    const audioRef1 = useRef<HTMLAudioElement>(null);
    const audioRef2 = useRef<HTMLAudioElement>(null);
    const audioRef3 = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        if (open) {
            (async () => {
                setLoading(true)
                const res = await networkInstance.clientWithHeaders.contentApi.audio.getSongUrl(audioId);
                setLoading(false)
                setSongPaths({ cdnAudioUrl: res.data.data.cdn_audio_url, originalAudioUrl: res.data.data.original_audio_url, m3u8AudioUrl: res.data.data.m3u8_audio_url });
            })()
        }
    }, [audioId, networkInstance.clientWithHeaders.contentApi.audio, open, setSongPaths])

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        if (audioRef1.current) {
            audioRef1.current.pause();
        }
        if (audioRef2.current) {
            audioRef2.current.pause();
        }
        setOpen(false);
    };
    return (
        <div>
            <Button type="link" onClick={showModal}>
                Listen Song
            </Button>
            <Modal
                visible={open}
                title="Listen Song"
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>
                ]}
            >
                {
                    loading ? 'loading...' : <div>
                        <p> <b>CDN audio url</b> - {songPaths?.cdnAudioUrl}</p>
                        <audio ref={audioRef1} controls >
                            <source src={songPaths?.cdnAudioUrl} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                        <p><b> Original audio url</b> - {songPaths?.originalAudioUrl} </p>
                        <audio ref={audioRef2} controls >
                            <source src={songPaths?.originalAudioUrl} />
                            Your browser does not support the audio element.
                        </audio>
                        <p> <b>m3u8 Audio Url</b> - {songPaths?.m3u8AudioUrl} </p>

                    </div>
                }
            </Modal>
        </div>
    );
};

export default PlaySongs;