import React, { useCallback, useEffect, useState } from 'react'
import { useAppContext } from '@/components/AppContext'
import DragAndDropModal from '@/hoc/DragAndDropModal'
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import { Col, Image, Row, Tag, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import SectionCreation from '@a4b/ui/src/modules/monetisation/molecules/SectionCreation'
import {
  CreateStore,
  EditSection,
  ManageCategoryItem,
  ManageStoreCard,
  Product,
} from '@a4b/api/src/modules/Monetisation/Offerings/types'
import _ from 'lodash'
import { parseCreateSectionData, parseInventoryList } from './helper'

interface Props {
  showItemCreation: boolean
  isEditJourney: boolean
  createItemCallback?: (status: boolean) => void
  UpdateItemCallback?: (status: boolean) => void
  closeItemCreation: () => void
  formData: EditSection | undefined
}
interface ShowStoreInfoProps {
  data?: Partial<CreateStore>
}
const ShowStoreInfo: React.FC<ShowStoreInfoProps> = ({ data }) => {
  return (
    <Row style={{ display: 'flex', alignItems: 'center', height: 'auto', justifyContent: 'center' }}>
      <Col span={12}>{data?.title}</Col>
      <Col span={4}>No of SKU's:{data?.no_of_items}</Col>
      {
        data?.active ?
          <Col span={4}><Tag color='#00BD68'>Active</Tag></Col>
          :
          <Col span={4}><Tag color='#FF5151'>Inactive</Tag></Col>
      }
      <Col span={4}>
        <Image width={80} height={60} src={data?.icon_url} />
      </Col>
    </Row>
  )
}
const ShowStoreCardInfo: React.FC<ShowStoreInfoProps> = ({ data }) => {
  return (
    <Row>
      <Col span={14}>{data?.title}</Col>
      <Col span={4}>
        <Image width={80} height={60} src={data?.icon_url} />
      </Col>
    </Row>
  )
}
const ShowCategoryInfo: React.FC<ShowStoreInfoProps> = ({ data }: any) => {
  return (
    <Row>
      <Col span={12}>Title: {data?.title}</Col>
      <Col span={12}>Item Type: {data?.item_type}</Col>
      <Col span={12}>{data?.description}</Col>
      <Col span={12}>Template: {data?.template}</Col>
    </Row>
  )
}
const sectionDataTypes = ['info_media', 'previous_pooja_media', 'banner_items', 'today_importance', 'srimandir_services', 'glimpses_of_services', 'associated_temples', 'associated_pilgrims', 'previous_order_recommendation_card', 'banner_large','promotional_banners'];

const CreateSection: React.FC<Props> = ({
  showItemCreation,
  closeItemCreation,
  createItemCallback,
  UpdateItemCallback,
  isEditJourney,
  formData,
}) => {
  const { networkInstance } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

  const [editFormData, setEditFormData] = useState<any>()
  const [createItemForm] = useForm()

  const [storeList, setStoreList] = useState<CreateStore[]>([])
  const [inventoryList, setInventoryList] = useState<{ [storeId: string]: any }>({})
  const [storeCards, setStoreCards] = useState<ManageStoreCard[]>([])
  const [categories, setCategories] = useState<ManageCategoryItem[]>([])
  const [sortedSkuLists, setSortedSkuLists] = useState<ManageStoreCard[]>([])
  const [sortedCategoryList, setSortedCategoryList] = useState<ManageStoreCard[]>([])
  const [sortedStoreLists, setSortedStoreLists] = useState<CreateStore[]>([])
  const [showDandDModal, setDandDModalVisibility] = useState(false)

  const getStoreList = useCallback(async () => {
    try {
      const storeList = await monetisationOfferingsApi.storesApi.getStoreList({
        limit: 1000,
        offset: 0,
      })
      let store_list = storeList?.data?.data?.store_list
      setStoreList(
        store_list
          .filter((item) => item)
          .sort((item1, item2) => {
            if (item1?.active && !item2?.active) {
              return -1
            } else if (!item1?.active && item2?.active) {
              return 1
            } else {
              return item1?.position - item2?.position;
            }
          })
      )
    } catch (error) {
      message.error({
        content: 'Error while fetching store list',
        duration: 3,
      })
      console.log(error)
    }
  }, [monetisationOfferingsApi.storesApi])

  const getStoreCards = useCallback(async () => {
    try {
      const storeCards = await monetisationOfferingsApi.storesApi.getStoreCards({
        limit: 1000,
        offset: 0,
        active: true,
      })

      setStoreCards(storeCards?.data?.data?.store_cards)
    } catch (error) {
      message.error({
        content: 'Error while fetching store list',
        duration: 3,
      })
      console.log(error)
    }
  }, [monetisationOfferingsApi.storesApi])

  const getCategories = useCallback(async () => {
    try {
      const categories = await monetisationOfferingsApi.storesApi.getCategories({
        limit: 1000,
        offset: 0,
      })

      setCategories(
        categories?.data?.data?.categories?.map((item: ManageCategoryItem) => ({
          ...item,
          id: item?.category_id,
        })),
      )
    } catch (error) {
      message.error({
        content: 'Error while fetching categories',
        duration: 3,
      })
      console.log(error)
    }
  }, [monetisationOfferingsApi.storesApi])

  const getInventoryList = useCallback(async (storeId: string) => {
    try {
      const inventoryList = await monetisationOfferingsApi.storesApi.getProductList(storeId)
      const parsedInventoryList = parseInventoryList(storeId, inventoryList?.data?.data?.products)
      setInventoryList((prevList) => ({
        ...prevList,
        [storeId]: parsedInventoryList,
      }))
      return parsedInventoryList
    } catch (error) {
      message.error({
        content: 'Error while fetching InventoryList',
        duration: 3,
      })
      console.log(error)
    }
  }, [monetisationOfferingsApi.storesApi])

  useEffect(() => {
    getStoreList()
    getStoreCards()
    getCategories()
  }, [getStoreList, getStoreCards, isEditJourney, getCategories])

  const fetchData = async (editForm: any) => {
    const sankalpBannerStoreIds = editForm?.data?.sankalp_banner_items?.map((bannerObj: { store_id: string, inventory_id: string }) => bannerObj?.store_id)

    if (sankalpBannerStoreIds && sankalpBannerStoreIds?.length > 0) {
      try {
        const inventoryResults = await Promise.all(
          sankalpBannerStoreIds?.map(async (storeId: string) => ({
            [storeId]: await getInventoryList(storeId),
          }))
        )
        setInventoryList(Object.assign({}, ...inventoryResults))
      } catch (error) {
        console.error('Error while fetching inventory lists:', error);
      }
    }
  }

  useEffect(() => {
    if (isEditJourney) {
      const offeringStoreId = storeList?.filter((store) => formData?.data?.today_importance?.offering_store_ids?.includes(store.id))?.map((item) => item?.id)
      const poojaStoreId = storeList?.filter((store) => formData?.data?.today_importance?.pooja_store_ids?.includes(store.id))?.map((item) => item?.id)
      const editForm = {
        ...formData,
        title: formData?.title,
        description: formData?.description,
        item_type: formData?.item_type,
        section_type: formData?.section_type,
        position: formData?.position,
        id: formData?.id,
        ...(formData?.data?.type === 'today_importance' && { data: { ...formData?.data, today_importance: { ...formData?.data?.today_importance, offering_store_ids: offeringStoreId, pooja_store_ids: poojaStoreId } } })
      }
      let section_type: any = createItemForm.getFieldValue('section_type')


      if ((section_type === 'hero' || section_type === 'list_item_small' || section_type === 'list_item_large') && formData?.item_type === 'sku') {
        const filteredStoreCard: any = storeCards?.filter(
          (storeCard: ManageStoreCard) =>
            formData?.data?.find((card: any) => card === storeCard.id),
        )
        const sortedItems = _.sortBy(filteredStoreCard, (item: ManageStoreCard) => formData?.data?.indexOf(item?.id))
        setSortedSkuLists(sortedItems)
        setSortedStoreLists([])
      }
      if ((section_type === 'hero' || section_type === 'list_item_small' || section_type === 'list_item_large') && formData?.item_type === 'store') {
        let tempFilteredStoreList: any = storeList?.filter((store: CreateStore) =>
          formData?.data?.find((card: any) => card === store.id),
        )
        const filteredStoreList = _.sortBy(tempFilteredStoreList, function (item) {
          return formData?.data.indexOf(item.id)
        });

        setSortedStoreLists(filteredStoreList)
        setSortedSkuLists([])
      }
      if (formData?.section_type === 'category') {
        const filteredCategories: any = categories?.filter(
          (form_category: ManageCategoryItem) =>
            formData?.data?.filter((card: any) => card === form_category.id).length,
        )
        setSortedCategoryList(filteredCategories)
        setSortedStoreLists([])
      }

      if (editForm?.data?.type === 'sankalp_banner') {
        fetchData(editForm)
      }

      setEditFormData(editForm)
      createItemForm.setFieldsValue(editForm)
    } else {
      setTimeout(() => {
        createItemForm.resetFields()
      }, 300)
      setEditFormData({})
    }
  }, [isEditJourney, createItemForm, storeList, storeCards, categories, formData])

  const handleCreateOrUpdate = async (values: any) => {
    if (isEditJourney) {
      const id = formData?.id || ''
      try {
        await monetisationOfferingsApi.storesApi.updateSection(id, {
          ...values,
          active: formData?.active || false,
          id: id,
        })

        message.success('Section updated successfully')
        if (UpdateItemCallback) {
          UpdateItemCallback(true)
        }

        createItemForm.resetFields()
        closeItemCreation()
      } catch (error) {
        message.error('Section update failed')
      }
    } else {
      message.loading({ content: 'Creating item...', key: 'create-section' })
      await monetisationOfferingsApi.storesApi.createSection(values)
      message.success({
        content: 'Section created successfully',
        duration: 2,
        key: 'create-section',
      })
    }
    createItemForm.resetFields()
    setSortedSkuLists([])
    setSortedStoreLists([])
    closeItemCreation()
  }

  const formSubmithandler = () => {
    createItemForm
      .validateFields()
      .then(values => {
        const formData = { ...values, position: Number(values?.position) }
        const parsedSectionData = parseCreateSectionData(formData, storeList)
        if (sectionDataTypes.includes(formData?.data?.type)) {
          handleCreateOrUpdate(parsedSectionData)
        }
        if ((values?.section_type === 'hero' || values?.section_type === 'list_item_small' || values?.section_type === 'list_item_large') && values?.item_type === 'sku') {
          if (!sortedSkuLists?.length) {
            message.error({
              content: 'Please select some Store cards.',
              duration: 3,
            })
            return
          } else {
            formData['data'] = sortedSkuLists?.map(
              (item: any) => item?.id,
            )
          }
        }
        if ((values?.section_type === 'hero' || values?.section_type === 'list_item_small' || values?.section_type === 'list_item_large') && values?.item_type === 'store') {
          if (!sortedStoreLists?.length) {
            message.error({
              content: 'Please select some Stores.',
              duration: 3,
            })
            return
          } else {
            formData['data'] = sortedStoreLists?.map(
              (item: any) => item?.id,
            )
          }
        }
        if (values?.section_type === 'category') {
          if (!sortedCategoryList?.length) {
            message.error({
              content: 'Please select some Categories.',
              duration: 3,
            })
            return
          } else {
            formData['data'] = sortedCategoryList?.map(
              (item: any) => item?.id,
            )
          }
        }
        if (sectionDataTypes.includes(formData?.data?.type)) {
          return
        } else {
          handleCreateOrUpdate(formData)
        }
      })
      .catch(errorInfo => { })
  }
  const onFormChange = (formValues: any) => {
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    if (!isEditJourney && formValues?.data?.type === 'previous_order_recommendation_card') {
      const myArray = daysOfWeek.map((day, index) => (
        {
          day,
          day_number: index,
          card_type: undefined,
          deeplink: '',
          god_name: '',
          media: {
            media_type: undefined,
            media_url: undefined,
            media_thumbnail: undefined,
          },
        }));
      createItemForm.setFieldValue(['data', 'banner_large_items'], myArray)
    }
    if (Object.keys(formValues)?.[0] === 'section_type' && formValues?.section_type !== 'data') {
      createItemForm.setFieldValue("item_type", "")
    }
    if (formValues?.section_type === 'data') {
      createItemForm.setFieldValue("item_type", "data")
    }
    if (formValues?.item_type === 'sku') {
      setSortedStoreLists([])
    }
    if (formValues?.item_type === 'store') {
      setSortedSkuLists([])
    }
  }

  const handleOnCancel = () => {
    setDandDModalVisibility(false)
  }

  const handleOnOk = (sortedList: any[]) => {
    setDandDModalVisibility(false)
    if (createItemForm.getFieldValue('section_type') === 'category') {
      setSortedCategoryList(sortedList)
      setSortedStoreLists([])
      setSortedSkuLists([])
    }
    if (createItemForm.getFieldValue('item_type') === 'sku') {
      setSortedSkuLists(sortedList)
      setSortedStoreLists([])
    }
    if (createItemForm.getFieldValue('item_type') === 'store') {
      setSortedStoreLists(sortedList)
      setSortedSkuLists([])
    }
  }

  const openSelectionWindow = () => {
    setDandDModalVisibility(true)
  }
  return (
    <>
      <SectionCreation
        visible={showItemCreation}
        onClose={closeItemCreation}
        cdnUrl={YODA_CDN_URL}
        preSignUrl={YODA_PRESIGN_URL}
        formSubmithandler={formSubmithandler}
        form={createItemForm}
        initialValues={editFormData}
        isEditJourney={isEditJourney}
        storeList={storeList}
        onFormChange={onFormChange}
        openSelectionWindow={openSelectionWindow}
        sortedData={
          (createItemForm.getFieldValue('section_type') === 'hero' || createItemForm.getFieldValue('section_type') === 'list_item_small' || createItemForm.getFieldValue('section_type') === 'list_item_large') && createItemForm.getFieldValue('item_type') === 'sku'
            ? sortedSkuLists
            : (createItemForm.getFieldValue('section_type') === 'hero' || createItemForm.getFieldValue('section_type') === 'list_item_small' || createItemForm.getFieldValue('section_type') === 'list_item_large') && createItemForm.getFieldValue('item_type') === 'store'
              ? sortedStoreLists
              : createItemForm.getFieldValue('section_type') === 'category'
                ? sortedCategoryList
                : []
        }
        componentRender={
          (createItemForm.getFieldValue('section_type') === 'hero' || createItemForm.getFieldValue('section_type') === 'list_item_small' || createItemForm.getFieldValue('section_type') === 'list_item_large') && createItemForm.getFieldValue('item_type') === 'sku' ? (
            <ShowStoreCardInfo key={'sku'} />
          ) : (createItemForm.getFieldValue('section_type') === 'hero' || createItemForm.getFieldValue('section_type') === 'list_item_small' || createItemForm.getFieldValue('section_type') === 'list_item_large') && createItemForm.getFieldValue('item_type') === 'store' ? (
            <ShowStoreInfo key={'store'} />
          ) : (
            createItemForm.getFieldValue('section_type') === 'category'
              ? <ShowCategoryInfo key="category" />
              : ""
          )
        }
        getInventoryList={getInventoryList}
        inventoryList={inventoryList}
      />
      <DragAndDropModal
        showModal={showDandDModal}
        handleOnCancel={handleOnCancel}
        handleOnOk={handleOnOk}
        sortedData={
          (createItemForm.getFieldValue('section_type') === 'hero' || createItemForm.getFieldValue('section_type') === 'list_item_small' || createItemForm.getFieldValue('section_type') === 'list_item_large') && createItemForm.getFieldValue('item_type') === 'sku'
            ? sortedSkuLists
            : (createItemForm.getFieldValue('section_type') === 'hero' || createItemForm.getFieldValue('section_type') === 'list_item_small' || createItemForm.getFieldValue('section_type') === 'list_item_large') && createItemForm.getFieldValue('item_type') === 'store'
              ? sortedStoreLists
              : createItemForm.getFieldValue('section_type') === 'category'
                ? sortedCategoryList
                : []
        }
        itemList={
          (createItemForm.getFieldValue('section_type') === 'hero' || createItemForm.getFieldValue('section_type') === 'list_item_small' || createItemForm.getFieldValue('section_type') === 'list_item_large') && createItemForm.getFieldValue('item_type') === 'sku'
            ? storeCards
            : (createItemForm.getFieldValue('section_type') === 'hero' || createItemForm.getFieldValue('section_type') === 'list_item_small' || createItemForm.getFieldValue('section_type') === 'list_item_large') && createItemForm.getFieldValue('item_type') === 'store'
              ? storeList?.filter((store: CreateStore) => store?.active === true)
              : createItemForm.getFieldValue('section_type') === 'category'
                ? categories
                : []
        }
        title={`Select and arrange ${createItemForm.getFieldValue(
          'item_type',
        )}`}
      >
        {createItemForm.getFieldValue('section_type') === 'category' && (
          <ShowCategoryInfo key={'category'} />
        )}
        {(createItemForm.getFieldValue('section_type') === 'hero' || createItemForm.getFieldValue('section_type') === 'list_item_small' || createItemForm.getFieldValue('section_type') === 'list_item_large') && createItemForm.getFieldValue('item_type') === 'sku' && (
          <ShowStoreCardInfo key={'sku'} />
        )}
        {(createItemForm.getFieldValue('section_type') === 'hero' || createItemForm.getFieldValue('section_type') === 'list_item_small' || createItemForm.getFieldValue('section_type') === 'list_item_large') && createItemForm.getFieldValue('item_type') === 'store' && (
          <ShowStoreInfo key={'store'} />
        )}
      </DragAndDropModal>
    </>
  )
}

export default React.memo(CreateSection)
