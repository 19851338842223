import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useAppContext } from '@/components/AppContext'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AudioPlaylist } from '@a4b/api';

import { MusicPlaylist } from '@a4b/ui/src/admin'
import { NEW_CDN_HOST } from '@/utils/constants';
import { PLAYLIST_TYPES } from '@a4b/ui/src/modules/admin/constants';
import CsvUploadPlaylist from './CSVUploadPlaylist';

const isProduction = process.env.REACT_APP_VIXEN_API_URL === 'https://api.a4b.io';

const LANGUAGE_CODE = 'LANAGUAGE_CODE'
const PLAYLIST_TYPE = 'PLAYLIST_TYPE'

const setToLocalStorage = (key: string, value: string) => {
  console.log({ key, value })
  localStorage.setItem(key, value)
}

const getFromLocalStorage = (key: string) => {
  console.log({ data: localStorage.getItem(key), key })
  return localStorage.getItem(key)
}

const PAGE_SIZE = 10
const MusicPlaylistListing = () => {
  const navigate = useNavigate();
  const { networkInstance, userProfile } = useAppContext();

  const [loading, setLoading] = useState(true)
  const [playlists, setPlaylists] = useState<AudioPlaylist[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [languageCode, setLanguageCode] = useState(getFromLocalStorage(LANGUAGE_CODE) || undefined)
  const [playlistType, setPlaylistType] = useState(getFromLocalStorage(PLAYLIST_TYPE) || PLAYLIST_TYPES[0])
  const fetchPlaylists = useCallback(async (page: number, pageSize: number, languageCode: string | undefined, playlistType: string) => {
    setLoading(true)
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    const response = await networkInstance.clientWithHeaders.contentApi.audio.getAudioPlaylists({ limit, offset, language_code: languageCode, playlist_type: playlistType });
    setPlaylists(response.data.data?.playlists || [])
    setTotalCount(response.data.data?.count || 0)
    setLoading(false)
  }, [networkInstance.clientWithHeaders.contentApi.audio])

  useEffect(() => {
    fetchPlaylists(1, PAGE_SIZE, languageCode, playlistType)
  }, [fetchPlaylists, languageCode, playlistType])

  const onPageChanged = (page: number, pageSize: number) => {
    fetchPlaylists(page, pageSize, languageCode, playlistType);
  }

  const onViewPlaylist = (playlist: AudioPlaylist) => {
    navigate(`/music/playlists/edit/${playlist.id}`)
  }

  const onClonePlaylist = (playlist: AudioPlaylist) => {
    navigate(`/music/playlists/edit/${playlist.id}?clone=yes`)
  }

  const onCreatePlaylist = () => {
    navigate(`/music/playlists/create`)
  }


  return <div>
    <MusicPlaylist
      cdnHost={NEW_CDN_HOST}
      pageSize={PAGE_SIZE}
      playlists={playlists}
      loading={loading}
      totalCount={totalCount}
      languageCode={languageCode}
      onPageChanged={onPageChanged}
      onViewPlaylist={onViewPlaylist}
      onClonePlaylist={onClonePlaylist}
      onCreatePlaylist={onCreatePlaylist}
      handleLanguageFilter={(val) => { setToLocalStorage(LANGUAGE_CODE, val); setLanguageCode(val) }}
      handlePlaylistTypeFilter={(val) => { setToLocalStorage(PLAYLIST_TYPE, val); setPlaylistType(val) }}
      playlistType={playlistType}
      playlistCreate={<CsvUploadPlaylist></CsvUploadPlaylist>}
    />
    <Link to={'/music/bulk-update-playlist'}> Bulk update playlist</Link>

  </div>
}

export default withDefaultLayout(MusicPlaylistListing)