import { Button, Empty } from "antd";
interface Props {
  description: string;
  actionTitle: string;
  actionHandler: () => void;
}
const EmptyData: React.FunctionComponent<Props> = ({
  description,
  actionTitle,
  actionHandler,
}: Props) => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{
        height: 60,
      }}
      description={<span>{description}</span>}
    >
      <Button type="primary" onClick={actionHandler}>
        {actionTitle}
      </Button>
    </Empty>
  );
};
export default EmptyData;
