import { NavMenuItem } from '../types'

const hubManagerMenus: NavMenuItem[] = [
  {
    title: 'Monetisation',
    id: 'monetisation',
    linkItem: [
      {
        name: 'Offerings',
        path: '/monetisation/offerings',
        id: '/monetisation/offerings',
      },
    ],
  },
]

export default hubManagerMenus
