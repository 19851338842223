
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button, Modal, Tag } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import { Definition, EventFilters, EventGroup } from "@a4b/api/src/modules/Events/types";
import { useNavigate, useParams, useSearchParams, } from "react-router-dom";
import { DEFAULT_COLUMNS } from "./utils/constants";
import { CloseCircleOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { openNotification } from "../../../../utils";

const PAGINATION_CONFIG = { limit: 20, offset: 0, total: 0 };

const css = `
  .events-table table {
    font-size: 13px;
  }
  .events-table thead > tr > th  { 
    padding-top: 60px !important;
  }
  .move-column-top {
    vertical-align: top;
  }
  .change-log  table {
    font-size: 12px;
  }
`

const Events = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const { networkInstance } = useAppContext();
  const [paginationInfo, setPaginationInfo] = useState(PAGINATION_CONFIG);
  const [events, setEvents] = useState<Definition[]>([]);
  const [groups, setGroups] = useState<EventGroup[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tableColumn, setTableColumn] = useState(DEFAULT_COLUMNS);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showColumnSettingDrawer = () => {
    setActiveDrawer(DRAWER_TYPE.CHANGE_COLUMN_SETTING);
  }

  useEffect(() => {
    if (id && activeDrawer === undefined) {
      setActiveDrawer(DRAWER_TYPE.PREVIEW_DEFINATION)
    }
  }, [activeDrawer, id])

  const updateNavigationId = useCallback((definationId) => {
    navigate(`/events-management/list/${definationId}?${searchParams}`);
  }, [navigate, searchParams])

  const removeNavigationId = useCallback(() => {
    navigate(`/events-management/list?${searchParams}`);
  }, [navigate, searchParams])

  const getGroups = useCallback(async () => {
    try {
      const response = await networkInstance.clientWithHeaders.events.getGroups();
      setGroups(response.data.data.groups);
    } catch (error) {

    }
  }, [networkInstance])

  useEffect(() => {
    getGroups();
  }, [getGroups])


  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }

  const getEvents = useCallback(async () => {
    try {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      const filters: EventFilters = {
        filters: {
          name: updatedSearchParams.get('name') || undefined,
          description: updatedSearchParams.get('description') || undefined,
          properties: updatedSearchParams.get('property_name') ? [{ name: updatedSearchParams.get('property_name') || '' }] : undefined,
          group: updatedSearchParams.get('group') || undefined,
          tags: updatedSearchParams.get('tags')?.split(',') || undefined,
          status: updatedSearchParams.get('status') || undefined,
          producer: updatedSearchParams.get('producer') || undefined,
          event_type: updatedSearchParams.get('event_type') || undefined,
          platform_type: updatedSearchParams.get('platform_type') || undefined,
          platform_code: updatedSearchParams.get('platform_code') || undefined,
        }
      }
      setIsLoading(true);
      const { data } = await networkInstance.clientWithHeaders.events.getEvents(filters);
      setIsLoading(false)
      if (data.data.definitions.length !== paginationInfo.total) {
        setPaginationInfo({ ...paginationInfo, total: data.data.definitions.length })
      }

      setEvents(data.data.definitions.map((defination) => {
        const destinations: string[] = []
        if (defination.destinations) {
          Object.keys(defination.destinations).forEach((key) => {
            // @ts-ignore
            if (defination.destinations[key].is_allowed) {
              destinations.push(key);
            }
          })
        }
        defination.destinations = destinations;
        return defination;
      }));
    } catch (error) {
      setIsLoading(false);
      openNotification('error', { message: 'Something went wrong. Please try again.' })
    }
  }, [searchParams, networkInstance.clientWithHeaders.events, paginationInfo])

  const eventDefination = useMemo(() => events.find((e) => e.id === id), [events, id]);
  const drawerConfig = useDrawerConfig(setActiveDrawer, tableColumn, setTableColumn, getEvents, removeNavigationId, eventDefination, activeDrawer, setIsFormTouched);
  const { drawerProps, drawerContent } = drawerConfig;

  useEffect(() => {
    console.log("how many times this is getting called ")
    getEvents();
  }, [getEvents])

  const tableConfig = useTableConfig(
    isLoading,
    events,
    groups,
    paginationInfo,
    setPaginationInfo,
    setDrawer,
    updateNavigationId,
    getEvents,
    showColumnSettingDrawer,
    tableColumn
  );


  const onCreateDefination = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_DEFINATIONS);
  }

  const onCreateGroup = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_GROUP);
  }

  const onDrawerClose = () => {
    if (DRAWER_TYPE.CREATE_GROUP === activeDrawer) {
      // todo handle this on callback of form wrapper 
      getGroups();
    }
    if (isFormTouched === true && (activeDrawer === DRAWER_TYPE.CREATE_DEFINATIONS || activeDrawer === DRAWER_TYPE.EDIT_DEFINATIONS)) {
      Modal.confirm({
        title: 'Are you sure you want to close. You will lose all the changes.',
        icon: <ExclamationCircleFilled />,
        // content: 'Some descriptions',
        onOk() {
          setActiveDrawer(undefined);
          navigate(`/events-management/list?${searchParams}`);
        },
        onCancel() {
        },
      });
    } else {
      setActiveDrawer(undefined);
      navigate(`/events-management/list?${searchParams}`);
    }
  }

  const createSearchUi = () => {
    const filterChips: ReactElement[] = []
    searchParams.forEach((value, key, index) => {
      filterChips.push(<span style={{ display: "flex", alignItems: "stretch", justifyContent: "flex-start" }}>
        <Tag style={{ marginRight: "0px" }}>{key}: <b>{value}</b> </Tag> <Button size="small" onClick={() => { searchParams.delete(key); setSearchParams(searchParams); }}><CloseCircleOutlined /></Button>
      </span>)
    })
    return filterChips.length > 0 ? <div style={{ display: "flex", gap: '10px' }}>
      Applied filters: {filterChips}
    </div> : null
  }

  return <SocialScreenContainer
    title={"Events "}
    filtersUI={createSearchUi()}
    extra={<div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Filters groups={groups} />
      <Button onClick={onCreateGroup}> Create group </Button> &nbsp;
      <Button type="primary" onClick={onCreateDefination}> Create definition </Button>
    </div>
    }
    content={<>
      <style>{css}</style>
      <div style={{ paddingTop: '30px' }}>
        <img src="https://srm-cdn.a4b.io/images/orig/lex_state_engine.png" width={'100%'} alt="lex-info"></img>
      </div>
    </>
    }
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />

};

export default withDefaultLayout(Events)