import {
  AppContextInterface,
  AppCtxProvider,
  NetworkInstance
} from '@/components/AppContext'
import Routes from '@/components/Routes'
import jwt_decode from "jwt-decode";
import UserProfile, { LoginState } from '@/entities/UserProfile'
import { message, Spin } from 'antd'
import { AxiosRequestHeaders } from 'axios'
import styled from 'styled-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import A4BApi from '@a4b/api'
import { CountryState, LanguageState, UserRoles } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { parseJwt } from './utils/helper'
import Cookies from 'js-cookie'
import { A4B_APPS, A4B_APPS_TYPES, JWT_DECODE } from './utils/constants';
import moment from 'moment-timezone';

let window = (global as any).window

let vixenBaseUrl: string = process.env.REACT_APP_VIXEN_API_URL || ''

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

message.config({
  top: 80,
})

const getNetworkInstance = (language_code?: string): NetworkInstance => {
  const token = JSON.parse(localStorage.getItem('userinfo') || '{}')?.access_token
  const jwtRes: any = token ? jwt_decode(token) : {}
  const headers: AxiosRequestHeaders = {
    'x-afb-app-ver': '1.0.0',
    'x-afb-device-id': window.navigator.userAgent,
    'x-afb-platform': 'web',
    'Authorization': 'Bearer ' + token
  }
  if (jwtRes.sub) {
    headers['X-AFB-R-UID'] = jwtRes.sub;
  }

  const monetisationExtraHeaders = { "Accept-Language": language_code || "hi" };
  const clientWithHeaders = new A4BApi(
    { baseURL: vixenBaseUrl, headers },
    monetisationExtraHeaders,
    { baseURL: `https://api.bitbucket.org/2.0` }
  )

  return {
    clientWithHeaders
  }
}

const App = () => {
  const [userRoles, setUserRoles] = useState<UserRoles>()
  const [app, setApp] = useState<A4B_APPS_TYPES | null>(null)
  const [languageState, setLanguageContext] = useState<LanguageState>({ isLanguageSelectionActive: true, monetisationLanguage: "hi" })
  const [countryState, setCountryState] = useState<CountryState>({ isCountryCodeActive: true, countryCode: "IN" })
  const [isRolesFetching, setRolesFetching] = useState<boolean>(true)
  let userProfile = useMemo(() => new UserProfile(), [])
  // const location = useLocation()

  const networkInstance = useMemo(
    () => getNetworkInstance(languageState?.monetisationLanguage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [languageState?.monetisationLanguage],
  )

  // useEffect(() => {
  //   if (location.pathname.startsWith('/astro')) {
  //     setApp(A4B_APPS.ASTRO)
  //   } else {
  //     setApp(A4B_APPS.SRIMANDIR)
  //   }
  // }, [location.pathname])

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userinfo') || '{}')
    const refreshToken = userInfo.refresh_token
    if (!refreshToken) {
      return
    }
    const expireTimeRemaining = userInfo.expires_in - moment().utc().unix()
    setTimeout(() => {
      networkInstance.clientWithHeaders.adminControl.refreshToken(refreshToken)
        .then((res) => {
          localStorage.setItem('userinfo', JSON.stringify(res.data.data.token))
        })
        .catch((error: any) => {
          console.log({ error })
        })
    }, (expireTimeRemaining - 4 * 60 * 60) * 1000)
  })

  useEffect(() => {
    try {
      const authToken: string = Cookies.get('gauth-token') || ""
      if (authToken) {
        const identity = parseJwt(authToken)
        if (identity?.email_verified) {
          userProfile.setProfileData({
            loginStatus: identity?.email_verified
              ? LoginState.LOGGED_IN
              : LoginState.LOGGED_OUT,
            name: identity.name,
            email: identity.email,
            imageUrl: identity.picture,
          })
        }
      }
    } catch (error) {
      console.log("Error occured while login", error)
    }
  }, [userProfile])

  const getRoles = useCallback(async () => {
    try {
      const rolesresult = await networkInstance.clientWithHeaders.userApi.getUserByEmail(userProfile?.email!)
      setUserRoles(rolesresult?.data?.data)
      setRolesFetching(false)
    } catch (error) {
      setRolesFetching(false)
      const agentResult = await networkInstance.clientWithHeaders.monetisationOfferingsApi.agentApi.getAllAgents({ limit: 10, offset: 0, email: userProfile?.email }, 'IN')
      if (agentResult?.data?.error?.message === 'no data found') {
        setUserRoles({
          id: '',
          name: '',
          email: '',
          user_type: '',
          roles: {
            user_type: '',
          },
        })
      } else {
        setUserRoles({
          id: agentResult?.data?.data?.[0]?.id,
          name: agentResult?.data?.data?.[0]?.name,
          email: userProfile?.email!,
          user_type: 'astrologer',
          roles: {
            user_type: 'astrologer',
          },
        })
      }
    }
  }, [networkInstance.clientWithHeaders.monetisationOfferingsApi.agentApi, userProfile?.email])

  useEffect(() => {
    userProfile?.email ? getRoles() : setRolesFetching(false)
  }, [getRoles, userProfile?.email])

  if (isRolesFetching) {
    return (
      <LoadingContainer>
        <Spin tip='Loading...' spinning={true} />
      </LoadingContainer>
    )
  }
  const access_token: string | undefined = JSON.parse(localStorage.getItem('userinfo') || '{}').access_token
  const roles = access_token ?
    jwt_decode<JWT_DECODE>(access_token).roles : []

  const initialState: AppContextInterface = {
    app,
    setApp,
    userProfile,
    networkInstance,
    monetisationRoles: userRoles,
    languageState,
    userRoles: roles,
    setLanguageContext,
    countryState,
    setCountryState
  }

  return (
    <>
      <AppCtxProvider initialState={initialState}>
        <Routes />
      </AppCtxProvider>
    </>
  )
}

export default App

