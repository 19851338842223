import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode, useMemo } from "react";
import AntdFormWrapper from "../../../../../components/Forms/AntdFormWrapper";
import { FormInstance } from "antd";
import { useAppContext } from "../../../../../components/AppContext";
import AddShloka from "../components/AddShloka";
import { Chapter } from "@a4b/api/src/modules/Gita/types";

// This interface is just a example for AntdFormWrapper 
interface TestComProps {
    form: FormInstance<string>
}

const TestCom = ({ form }: TestComProps) => <div> CREATE SOMETHING 2 DATA </div>
export enum DRAWER_TYPE {
    CREATE_SHLOKA = 'Create Shloka',
    EDIT_SHLOKA = 'Edit Shloka'
}



export default function useDrawerConfig(
    getChapterShlokas: () => Promise<void>,
    shloka: any,
    setActiveDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | undefined>>,
    chapter: Chapter | undefined,
    activeDrawer?: DRAWER_TYPE
): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {
    const { networkInstance } = useAppContext();
    // define Drawers 
    const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = useMemo(() => [
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.CREATE_SHLOKA,
                width: '80vw',
            },
            drawerContent: <AntdFormWrapper<any, any> submitSuccessfulCallback={() => {
                getChapterShlokas();
                setActiveDrawer(undefined);
            }}
                FormFields={AddShloka}
                createApi={networkInstance.clientWithHeaders.gita.addShloka}
                initValues={{ chapter_no: chapter?.chapter_no, content: chapter ? Object.keys(chapter?.content).map((language) => ({ language })) : [] }}
            />
        },
        {
            drawerProps: {
                visible: true,
                title: DRAWER_TYPE.EDIT_SHLOKA,
                width: '80vw',
            },
            drawerContent: <AntdFormWrapper<any, any> submitSuccessfulCallback={() => {
                getChapterShlokas();
                setActiveDrawer(undefined);
            }}
                FormFields={AddShloka}
                updateApiId={networkInstance.clientWithHeaders.gita.updateShloka}
                formData={shloka}
                initValues={{ chapter_no: chapter?.chapter_no, content: chapter ? Object.keys(chapter?.content).map((language) => ({ language })) : [] }}
            />
        }
    ], [chapter, getChapterShlokas, networkInstance.clientWithHeaders.gita.addShloka, networkInstance.clientWithHeaders.gita.updateShloka, setActiveDrawer, shloka])
    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}