
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfix, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import { Rashifal } from "@/../../../packages/a4b-api/src/modules/Content/Rashifal/types";
import moment from "moment";
import useAntdPagination from "@/hooks/useAntdPagination";



const RashiFal = function () {
  const [languageCode, setLanguageCode] = useState('hi');
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const [rashifals, setRashifals] = useState<Rashifal.RashifalItem[]>([]);
  const [editRashifal, setEditRashifal] = useState<Rashifal.RashifalItem>();
  const drawerConfig = useDrawerConfix(editRashifal, activeDrawer);
  const { drawerProps, drawerContent } = drawerConfig;
  const { networkInstance } = useAppContext();
  const { pagination, setPagination } = useAntdPagination();

  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }

  const setRashiFal = (rashifal: Rashifal.RashifalItem) => {
    setEditRashifal(rashifal);
  }


  const loadRashifal = useCallback(async () => {
    if (!pagination) return;
    try {
      const offset = (pagination.current ? pagination.current - 1 : 0) * (pagination.pageSize || 50)
      const res = await networkInstance.clientWithHeaders.contentApi.rashifal.getRashifal(languageCode, date, pagination.pageSize || 50, offset);
      setRashifals(res.data.data.rashifals);
      if (pagination.total !== res.data.data.count) {
        setPagination({ ...pagination, total: res.data.data.count })
      }
    } catch (error) {
    }
  }, [date, languageCode, networkInstance.clientWithHeaders.contentApi.rashifal, pagination, setPagination]);

  const tableConfig = useTableConfig(rashifals, setDrawer, setRashiFal, loadRashifal, pagination, setPagination);

  useEffect(() => {
    loadRashifal();
  }, [loadRashifal])

  const onCreateRashipal = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_RASHI);
  }

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
    loadRashifal();
  }

  return <SocialScreenContainer
    title={"Rashifal"}
    filtersUI={<Filters languageCode={languageCode} setLanguageCode={setLanguageCode} date={date} setDate={setDate} />}
    extra={<Button onClick={onCreateRashipal}> Create rashifal </Button>}
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />

};

export default withDefaultLayout(RashiFal)