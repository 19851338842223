import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { Response } from '../../../types'
import type {
  CreateLibraryContentOptions,
  LibraryContent,
  LibraryContentQueryParams,
  LibraryMediaType,
} from './types'
import { apiClient } from '../../../apiClient'

class LibraryApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createLibraryContent = (options: CreateLibraryContentOptions) =>
    this.api.post<Response<LibraryContent>>(
      '/content/internal/v1/library',
      options,
    )

  getLibraryContent = (params?: LibraryContentQueryParams) =>
    this.api.get<Response<{ libraries: LibraryContent[] }>>(
      '/content/internal/v1/library',
      {
        params: {
          tags: params?.tags?.join(','),
          status: params?.status?.join(','),
          name: params?.name,
          formats: params?.formats?.join(','),
          limit: params?.limit,
          offset: params?.offset,
          ids: params?.ids?.join(','),
        },
      },
    )
  getLibraryContentCount = (params?: LibraryContentQueryParams) =>
    this.api.get<Response<{ count: number }>>(
      '/content/internal/v1/library/count',
      {
        params: {
          tags: params?.tags?.join(','),
          status: params?.status?.join(','),
          name: params?.name,
          formats: params?.formats?.join(','),
          limit: params?.limit,
          offset: params?.offset,
          ids: params?.ids?.join(','),
        },
      },
    )

  getLibraryContentById = (id: string) =>
    this.api.get<Response<LibraryContent>>(`/content/internal/v1/library/${id}`)

  updateLibraryContentById = (
    id: string,
    options?: Partial<CreateLibraryContentOptions>,
  ) =>
    this.api.put<Response<LibraryContent>>(
      `/content/internal/v1/library/${id}`,
      options,
    )

  getPresignedUrlForImageUpload = (extension?: string) =>
    this.api.get<Response<{ url: string; attachmentId: string }>>(
      `/content/internal/v1/library/image/upload?${extension ? `extension=${extension.substring(extension.lastIndexOf('/') + 1)}` : ''}`,
    )

  getPresignedUrlForContentUpload = (params?: {
    format: string
    libraryId?: string
  }) =>
    this.api.get<Response<{ url: string; attachmentId: string }>>(
      `/content/internal/v1/library/upload`,
      {
        params,
      },
    )
}

export default LibraryApi
