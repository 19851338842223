export enum STATUS {
  IDEL = "IDEL",
  FETCHING = "FETCHING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}
export enum EVENT {
  FETCH = "FETCH",
}
export enum UPLOAD_STATUS {
  IDEL = "IDEL",
  FETCHING = "FETCHING",
  SUCCESS = "SUCCESS",
  PROGRESS_UPDATED = "PROGRESS_UPDATED",
  ERROR = "ERROR",
  CANCEL = "CANCEL",
}
export enum ATTACHMENT {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum ATTACHMENT_OP {
  CURRENT_INDEX = "CURRENT_INDEX",
  INSERT_UCCODE = "INSERT_UCCODE",
  REMOVE = "REMOVE",
  ADD = "ADD",
  CLEAR = "CLEAR",
}
