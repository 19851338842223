import { Astrologer } from '@a4b/api/src/modules/Monetisation/Astrologer/types'

export const parseAgentResponse = (data: Astrologer) => {
  const agentResponse = {
    app_code: data.app_code,
    user_id: data?.afb_userid,
    name: data?.name,
    display_names: data?.display_names
      ? Object.keys(data?.display_names).map((language: string) => {
          return {
            language: language,
            name: data.display_names[language],
          }
        })
      : undefined,
    description: data?.description,
    profile_pic: [data?.profile_pic],
    profile_banner: [data?.profile_banner],
    intro_audio: [data?.intro_audio],
    phone: data?.phone?.startsWith('+91') ? data?.phone?.slice(3) : data?.phone,
    email: data?.email,
    base_charge: data?.base_charge,
    actual_charge: data?.actual_charge,
    experience: data?.experience,
    expertise: data?.expertise,
    education: data?.education,
    gallery: data?.gallery?.map((item: any) => ({
      media_type: item?.media_type,
      media_url: [item?.media_url],
    })),
    certificates: data?.certificates?.map((item: any) => ({
      media_type: item?.media_type,
      media_url: [item?.media_url],
      deeplink: item?.deeplink,
    })),
    rating: data?.rating,
    status: data?.status,
    position: data?.position,
    languages_spoken: data?.languages_spoken,
    language_code: data?.language_code,
    currency: data?.currency,
    country_code: data?.country_code,
    total_calls: data?.total_calls,
    total_reviews: data?.total_reviews,
    total_chats: data?.total_chats,
    call: data?.call || false,
    chat: data?.chat || false,
    label: data?.label,
    wait_list_enabled: data?.wait_list_enabled,
  }
  return agentResponse
}

export const agentPayloadData = (
  data: any,
  languageCode: string,
  countryCode: string,
) => {
  const payload: Astrologer = {
    afb_userid: data.user_id,
    name: data?.name,
    app_code: data?.app_code,
    display_names: data?.display_names,
    description: data?.description,
    profile_pic: data?.profile_pic?.[0],
    profile_banner: data?.profile_banner?.[0],
    intro_audio: data?.intro_audio?.[0],
    phone: `+91${data?.phone}`,
    email: data?.email,
    base_charge: data?.base_charge,
    actual_charge: data?.actual_charge,
    experience: data?.experience,
    expertise: data?.expertise,
    education: data?.education,
    gallery: data?.gallery?.map((item: any) => ({
      media_type: item?.media_type,
      media_url: item?.media_url?.[0],
    })),
    certificates: data?.certificates?.map((item: any) => ({
      media_type: item?.media_type,
      media_url: item?.media_url?.[0],
      deeplink: item?.deeplink,
    })),
    rating: data?.rating,
    status: data?.status,
    position: data?.position,
    languages_spoken: data?.languages_spoken,
    language_code: languageCode,
    currency: countryCode === 'IN' ? 'INR' : 'USD',
    country_code: countryCode,
    total_calls: data?.total_calls,
    total_reviews: data?.total_reviews,
    total_chats: data?.total_chats,
    call: data?.call,
    chat: data?.chat,
    label: data?.label,
    wait_list_enabled: data?.wait_list_enabled,
  }
  if (data.variant_enabled !== undefined) {
    payload.variant_enabled = data.variant_enabled
  }
  return payload
}
