import { getLanguageNameFromValue } from '@/utils/constants'

import { City } from '@a4b/api/src/modules/Content/Temples/types'

export const parseCityResponseData = (cities: City[]) => {
  const cityList: any = cities.map(city => {
    const languageArray = Object.entries(city?.translations)
    const languages = languageArray.map(
      language =>
        `${getLanguageNameFromValue(language?.[0])} - ${
          language?.[1]?.city_name
        }`,
    )
    return {
      id: city.id,
      city_code: city.city_code,
      is_active: city.is_active,
      language_code: languages,
    }
  })

  return cityList
}
