import { ArrowLeftOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import React from 'react'
import * as Marked from 'marked'

export interface Props {
  content: string
  thumbnail: string
}

const Wrapper = styled.div`
  background: white;
  width: 360px;
  height: 600px;
  margin-top: 40px;
`

const Container = styled.div`
  background: white;
  width: 360px;
  height: 520px;
  overflow: scroll;
  font-family: 'Mukta';
`

const TopBar = styled.div`
  height: 24px;
  width: 100%;
  background: #f18912;
`

const Header = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`

const Thumbnail = styled.div`
  background: #f9f8fd;
  height: 180px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ThumbnailImage = styled.img`
  background: #d9d9d9;
  border-radius: 12px;
  width: 120px;
  height: 160px;
  object-fit: cover;
`

const Content = styled.div`
  padding: 16px;

  word-break: break-all;
`

const getRenderedContent = (content: string) => {
  const renderer = new Marked.Renderer()
  return Marked.parse(content, { renderer })
}
export const ContentPreview: React.FC<Props> = ({ content, thumbnail }) => {

  return (
    <Wrapper>
      <TopBar />
      <Header>
        <ArrowLeftOutlined />
      </Header>
      <Container>
        <Thumbnail>
          <ThumbnailImage src={thumbnail} />
        </Thumbnail>
        <Content dangerouslySetInnerHTML={{ __html: getRenderedContent(content) }} />
      </Container>
    </Wrapper >
  )
}

export default ContentPreview
