import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'

import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//screens
import {
  CloneScheduleFormFields,
  TagList as TagListScreen,
} from '@a4b/ui/src/admin'

import type { ContentTag, BulkCloneTagContentScheduleOptions } from '@a4b/api'
import { debounce } from 'lodash'
import moment from 'moment'

interface Props { }

const TagsListing: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { networkInstance, userProfile } = useAppContext()
  const [tags, setTags] = useState<ContentTag[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [language, setLanguage] = useState('hi')
  const [isActive, setIsActive] = useState(true)


  useEffect(() => {
    async function initTags() {
      setLoading(true)
      const p1 = networkInstance.clientWithHeaders.contentApi.tag.getTags({ name: searchKey, language: language, active: isActive })
      const p2 = networkInstance.clientWithHeaders.contentApi.tag.getTagsCount({ name: searchKey })
      const [response1, response2] = await Promise.all([p1, p2])
      setTags(response1.data.data.tags || [])
      setTotalCount(response2.data.data.count)
      setLoading(false)
    }
    initTags()
  }, [networkInstance.clientWithHeaders, language, searchKey, isActive])

  const handleCreateNewTagClicked = () => {
    navigate('/content/tags/create')
  }

  const handleEditTagClicked = (item: ContentTag) => {
    navigate(`/content/tags/edit/${item.id}`)
  }

  const handleViewScheduleClicked = (item: ContentTag) => {
    navigate(`/content/tag-content-schedule/${item.id}`)
  }

  const handleActiveDeactiveClicked = async (item: ContentTag) => {
    const response = await networkInstance.clientWithHeaders.contentApi.tag.updateTag(item.id, {
      active: !item.active,
      updatedBy: userProfile.email,
    })
    if (response.status === 204) {
      const updatedTags = [...tags]
      const tagToUpdate = updatedTags.find(tag => tag.id === item.id)
      if (tagToUpdate) {
        tagToUpdate.active = !item.active
      }
      setTags(updatedTags)
    } else {
      console.log('failed to activate/deactivate tag')
    }
  }

  const handlePageChanged = async (page: number, pageSize: number) => {
    setLoading(true)
    const params = {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      language: language,
    }
    const response = await networkInstance.clientWithHeaders.contentApi.tag.getTags(params)
    setTags(response.data.data.tags || [])
    setLoading(false)
  }

  const handleCloneSchedule = async (values: CloneScheduleFormFields) => {
    const options: BulkCloneTagContentScheduleOptions = {
      cloneFrom: values.cloneFrom.startOf('day').toISOString(),
      cloneTo: moment().format('YYYY-MM-DD') === values.cloneTo.format('YYYY-MM-DD')
        ? moment().add(1, 'minute').toISOString()
        : values.cloneTo.startOf('day').toISOString(),
      language: values.language,
    }
    try {
      const response =
        await networkInstance.clientWithHeaders.contentApi.schedule.bulkCloneTagContentSchedules(options)
      if (response.status === 201) return { success: true, message: 'Successfully created schedule' };
      return { success: false, message: 'Failed to create schedule' };
    } catch (error) {
      return { success: false, message: 'Failed to create schedule' };
    }
  }

  const handleTagSearch = (key: string) => {
    setSearchKey(key)
  }

  const onTagSearch = debounce(handleTagSearch, 1000)

  const onIsActiveChange = (isActive: boolean) => {
    setIsActive(isActive)
  }

  const onLanguageChange = (language_code: string) => {
    setLanguage(language_code)
  }

  return (
    <TagListScreen
      tags={tags}
      loading={loading}
      total={totalCount}
      onSearch={onTagSearch}
      onLanguageChange={onLanguageChange}
      onCreateNewTagClicked={handleCreateNewTagClicked}
      onEditClicked={handleEditTagClicked}
      onActiveDeactiveClicked={handleActiveDeactiveClicked}
      onViewScheduleClicked={handleViewScheduleClicked}
      onPageChanged={handlePageChanged}
      onCloneScheduleSubmit={handleCloneSchedule}
      isActive={isActive}
      onIsActiveChange={onIsActiveChange}
    />
  )
}
export default withDefaultLayout(TagsListing)
