import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { CreateOrUpdateReview, ReviewByAgentData } from './types'
import { apiClient } from '../../../apiClient'

class ReviewsApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createReview = (payload: CreateOrUpdateReview) =>
    this.api.post(`gw1/titan/internal/v1/reviews/bulk`, payload)

  updateReview = (payload: CreateOrUpdateReview) =>
    this.api.put(`gw1/titan/internal/v1/reviews/update-reviews`, payload)

  getReviewByAgent = (agentId: string) =>
    this.api.get<ReviewByAgentData>(`gw1/titan/internal/v1/reviews?agent_id=${agentId}`)

  deleteReview = (agentId: string) =>
    this.api.delete(`gw1/titan/internal/v1/reviews/${agentId}`)
}

export default ReviewsApi
