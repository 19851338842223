import { Form, Input, Row, Select, Col, Button } from "antd"
import React from "react"
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import { DeleteOutlined, DragOutlined, HolderOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc"

interface Props {
    fields: any
    cdnUrl: string
    preSignUrl: string
    add: () => void
    remove: (item: any) => void
    datatype: string
    form: any
}
const { Option } = Select;

interface FormItemsProps {
    index: number
    field: any
    remove: (item: any) => void
    cdnUrl: string
    preSignUrl: string
    itemIndex: number
}
const FormItems = (props: FormItemsProps) => {
    const { itemIndex, field, remove, cdnUrl, preSignUrl } = props;
    return (
        <div style={{
            border: '1px solid #e0dcdc',
            marginRight: '0px',
            marginBottom: '10px',
            padding: '10px 0px',
            width: '100%',
            position: 'relative',
            zIndex: 10000000,
            backgroundColor: 'white',
            cursor: 'grab'
        }}>
            <Row style={{ width: '100%', justifyContent: 'space-between', alignItems: "center", paddingLeft: '10px', paddingRight: "10px" }}>
                <HolderOutlined />
                <Form.Item
                    label={`Media`}
                    name={[field.name, 'media', 'media_url']}
                    rules={[
                        {
                            required: true,
                            min: 0,
                            max: 1,
                            validator: async (rules: any, values: any) => {
                                if (values?.length < rules.min) {
                                    return Promise.reject('Please upload Media')
                                }
                            },
                        },
                    ]}
                    valuePropName='fileList'
                    style={{ paddingLeft: '0px', marginBottom: "0px" }}
                >
                    <S3Upload
                        cdnUrl={cdnUrl}
                        preSignUrl={preSignUrl}
                        min={1}
                        max={1}
                    />
                </Form.Item>
                <Form.Item
                    label={`Media type ${itemIndex + 1}`}
                    name={[field.name, 'media', 'media_type']}
                    labelCol={{ span: 18 }}
                    wrapperCol={{ span: 18 }}
                    rules={[
                        {
                            required: true,
                            message: 'Enter description of the category',
                        },
                    ]}
                >
                    <Select size="small" style={{ width: 150 }} placeholder='Please Select Media type'>
                        <Option value={'image'} key={'image'}>
                            Image
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label={`Deeplink ${itemIndex + 1}`}
                    name={[field.name, 'deeplink']}
                    labelCol={{ span: 32 }}
                    wrapperCol={{ span: 32 }}
                    style={{ flexBasis: '40%', marginRight: '10px' }}
                    rules={[
                        {
                            required: true,
                            message: 'Enter Deeplink',
                        },
                    ]}
                >
                    <Input size="small" placeholder='Enter Deeplink' />
                </Form.Item>


                <Button
                    style={{ marginRight: '5px', flexBasis: '10%', }}
                    onClick={() => {
                        remove(field.name)
                    }}
                    type='ghost'
                    icon={<DeleteOutlined />}
                    size='small'
                    danger
                ></Button>
            </Row>


        </div>
    )
}

const SortableFormItems = SortableElement(FormItems);

const HighlightedPooja: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, datatype, form }) => {
    return (
        <>
            <Row justify="space-between" style={{ width: '100%' }}>
                {fields && fields.map((field: any, index: number) => {
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <SortableFormItems
                            index={index}
                            itemIndex={index}
                            field={field}
                            remove={remove}
                            cdnUrl={cdnUrl}
                            preSignUrl={preSignUrl}
                        />
                    )
                })}
                <Col span={24} style={{ paddingTop: '10px' }}>
                    <Row justify='start'>
                        <Col span={4}>
                            <Button
                                onClick={() => {
                                    add()
                                }}
                                type='ghost'
                                icon={<PlusCircleOutlined />}
                                size='middle'
                            >
                                {datatype}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default SortableContainer(HighlightedPooja)