import { Button, Form, Input, Popconfirm, Select } from 'antd'

import React, { useEffect } from 'react'
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import { AudioContentState, AudioTag } from '@a4b/api/src/modules/Content/Audio/types'
import { SUPPORTED_LANGUAGES_LIST } from '../../../constants'


export type ContentFilterFormFields = {
  status: AudioContentState
  // tags: ContentTag[]
  // god: string[]
  title: string
  singer: string
  tag: string
  audioLanguage: string
  audioType: string
  thirdPartyName: [string]
}
export interface Props {
  tags: AudioTag[]
  statusOptions: string[]
  thirdPartyNameOptions: string[]
  filter?: ContentFilterFormFields
  onApplyFilterClicked: (filter: ContentFilterFormFields) => void,
  onTagSearch: (value: string) => void
  buttonText?: string
  withPrompt?: boolean
}

const { Option } = Select
const { useForm } = Form

const MusicFilter: React.FC<Props> = ({
  filter,
  onApplyFilterClicked,
  statusOptions,
  thirdPartyNameOptions,
  onTagSearch,
  tags = [],
  buttonText = "Apply",
  withPrompt = false,
}) => {
  const [form] = useForm<ContentFilterFormFields>()

  useEffect(() => {
    if (filter) form.setFieldsValue(filter)
  }, [filter])

  const handleApplyFilter = (values: ContentFilterFormFields) => {
    onApplyFilterClicked(values)
  }

  const handleApplyFilterFailed = (
    errorInfo: ValidateErrorEntity<ContentFilterFormFields>,
  ) => {
    console.log({ errorInfo })
  }

  const handleSearch = (key: string) => {
    onTagSearch(key)
  }

  return (
    <Form
      form={form}
      layout={'inline'}
      onFinish={handleApplyFilter}
      onFinishFailed={handleApplyFilterFailed}
      style={{ display: 'flex', padding: '20px', alignItems: 'center' }}
    >
      {/* <Form.Item label='Title' name='name'>
        <Input placeholder='Content Title' />
      </Form.Item> */}
      <Form.Item label='Status' name='status' required>
        <Select
          style={{ minWidth: '200px', maxWidth: '500px' }}
          placeholder='Content Status'
        >
          {statusOptions.map((status, index) => (
            <Option key={index} value={status}>
              {status}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Third party name'
        name='thirdPartyName'
        style={{ margin: '10px' }}
        rules={[{ max: 1, type: 'array' }]}
      >
        <Select
          showSearch
          placeholder={'Search Third party name'}
          mode='tags'
          filterOption={false}
          // onSearch={handleSearchThirdPartyName}
          style={{ minWidth: '400px', width: '100%' }}
        >
          {thirdPartyNameOptions.map((thirdPartyName, index) => (
            <Option key={index} value={thirdPartyName}>
              {thirdPartyName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {/* <Form.Item label='God' name='god'>
        <Select
          mode='multiple'
          style={{ minWidth: '200px' }}
          placeholder='God'
        >
          {CONTENT_FORMATS.map((format, index) => (
            <Option key={index} value={format}>
              {format.toUpperCase()}
            </Option>
          ))}
        </Select>
      </Form.Item> */}
      <Form.Item
        label='Audio title'
        name='title'
        style={{ margin: '10px' }}
      >
        <Input placeholder="Title" />
      </Form.Item>
      <Form.Item
        label='Audio language'
        name='audioLanguage'
      >
        <Select style={{ width: '200px' }}>
          {SUPPORTED_LANGUAGES_LIST.map(language =>
            <Select.Option key={language.value} value={language.value}>
              {language.alt_name} ({language.name})
            </Select.Option>
          )}
        </Select>
      </Form.Item>
      <Form.Item
        label='Audio type'
        name='audioType'
        style={{ margin: '10px' }}
      >
        <Input placeholder="Audio type" />
      </Form.Item>
      <Form.Item
        label='singer'
        name='singer'
        style={{ margin: '10px' }}
      >
        <Input placeholder="Singer name" />
      </Form.Item>
      <Form.Item
        label='Tag'
        name='tag'
        style={{ margin: '10px' }}
      >
        <Select
          showSearch
          placeholder={'Search tags'}
          filterOption={false}
          onSearch={handleSearch}
          style={{ minWidth: '400px', width: '100%' }}
        >
          {tags.map((tag, index) => (
            <Option key={index} value={tag.tag}>
              {tag.tag}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item style={{ margin: '10px' }}>
        {
          withPrompt ? <Popconfirm
            placement="topLeft"
            title={<div style={{ fontSize: 16 }}>Are you sure you want to fetch those audio records? <br /> <strong>Avoid using this repetitively!!</strong></div>}
            onConfirm={() => { form.submit() }}
            okText="Continue"
            cancelText="Cancel"
          >
            <Button type='primary' htmlType='submit'>
              {buttonText}
            </Button>
          </Popconfirm>
            : <Button type='primary' htmlType='submit'>
              {buttonText}
            </Button>
        }

      </Form.Item>
    </Form>
  )
}

export default MusicFilter
