import { CreateTemple } from '@a4b/api/src/modules/Monetisation/Offerings/types'

export const formatTempleCreation = (formData: any): CreateTemple => {
    const formatedObject: CreateTemple = {
        temple_id: formData?.temple_id,
        name: formData?.name,
        description: formData?.description,
        city: formData?.city,
        state: formData?.state,
        hub_id: formData?.hub_id,
        god_name: formData?.god_name,
        god_image: formData?.god_image[0],
        temple_image: formData?.temple_image[0],
        active: formData?.active,
    }

    return formatedObject
}

export const parseTempleData = (formData: any): CreateTemple => {
    const formObject: any = {
        temple_id: formData?.temple_id,
        name: formData?.name,
        description: formData?.description,
        city: formData?.city,
        state: formData?.state,
        hub_id: formData?.hub_id,
        god_name: formData?.god_name,
        god_image: [formData?.god_image],
        temple_image: [formData?.temple_image],
        active: formData?.active,
    }

    return formObject
}
