import { useState } from 'react';
import { Button, Descriptions, Dropdown, Menu, Table } from 'antd';
import { CopyOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import moment from 'moment';

import {
  ActionDataTable,
  AppShareDataTable,
  ContentCollectionDataTable,
  HeroArticleDataTable,
  HeroContentDataTable,
  HeroSongDataTable,
  HeroVideoDataTable,
  MiniMandirDataTable,
  PanchangDataTable,
  SongPlaylistDataTable,
  YoutubeLiveDataTable,
  YoutubeScheduleDataTable,
  ActionListDataTable,
  ActionShortcutsDataTable,
  ActionV2DataTable,
  MenuLoginDataTable,
  MenuNotificationDataTable,
} from '@/modules/feeds/pages/schedulesDataTable';

import PlatformProperty from '@/modules/feeds/pages/schedulesDataTable/Platform';
import Widget from '@/modules/feeds/pages/schedulesDataTable/Widget';
import { getReadableWidgetName } from '@/utils/helper';
import HeaderTags from '@/modules/feeds/pages/schedulesDataTable/HeaderTags';
import SocialDataTable from '../../pages/schedulesDataTable/homeFeeds/SocialDataTable';
import GreetingsDataTable from '../../pages/schedulesDataTable/homeFeeds/GreetingsDataTable';
import { SUPPORTED_LANGUAGES_LIST } from '../../../../utils/constants';

interface Props {
  scheduleList: any[];
  actionMenuClickHandler: (key: string, record: any) => void;
  deleteScheduleHandler: (record: any) => void;
  currentTab: string;
}
const ScheduleManageDataTable = ({
  scheduleList,
  actionMenuClickHandler,
  deleteScheduleHandler,
  currentTab,
}: Props) => {
  const [expandedRowKeys, setExpandRowKeys] = useState<string[]>([]);

  const menu = (record: any) => {
    return (
      <Menu
        onClick={(e) => {
          e.domEvent.stopPropagation();
          actionMenuClickHandler(e.key, record);
        }}
      >
        <Menu.Item key="clone">
          <Button
            /*  onChange={(e) => {
              e.stopPropagation();
              cloneScheduleHandler(record);
            }} */
            size="middle"
            icon={<CopyOutlined style={{ color: '#1990FF' }} />}
          >
            Clone
          </Button>
        </Menu.Item>
        <Menu.Item key="delete">
          <Button
            onChange={(e) => {
              e.stopPropagation();
              deleteScheduleHandler(record);
            }}
            size="middle"
            icon={<DeleteFilled style={{ color: 'red' }} />}
            disabled={currentTab === 'preview'}
          >
            Delete
          </Button>
        </Menu.Item>
        <Menu.Item key="edit">
          <Button
            disabled={false}
            size="middle"
            /* onClick={(e) => {
              e.stopPropagation();
              EditScheduleHandler(record);
            }} */
            icon={<EditFilled style={{ color: '#1990FF' }} />}
          >
            Edit
          </Button>
        </Menu.Item>
        <Menu.Item key="toggleStatus">
          {record?.active ? (
            <Button
              size="middle"
              disabled={currentTab === 'preview'}
            /* onClick={(e) => {
              e.stopPropagation();
              toggleScheduleStatus(record);
            }} */
            >
              Deactivate
            </Button>
          ) : (
            <Button
              size="middle"
              disabled={currentTab === 'preview'}
            /* onClick={(e) => {
              e.stopPropagation();
              toggleScheduleStatus(record);
            }} */
            >
              Activate
            </Button>
          )}
        </Menu.Item>
      </Menu>
    );
  };

  const getWidgetByType = (record: any) => {
    switch (record?.widget?.widget_type) {
      case 'MINI_MANDIR':
        return <MiniMandirDataTable record={record} />;
      case 'ACTION':
        return <ActionDataTable record={record} />;
      case 'HERO_ARTICLE':
        return <HeroArticleDataTable record={record} />;
      case 'HERO_SONG':
        return <HeroSongDataTable record={record} />;
      case 'HERO_CONTENT':
        return <HeroContentDataTable record={record} />;
      case 'HERO_VIDEO':
        return <HeroVideoDataTable record={record} />;
      case 'SONG_PLAYLIST':
        return <SongPlaylistDataTable record={record} />;
      case 'YOUTUBE_SCHEDULE':
        return <YoutubeScheduleDataTable record={record} />;
      case 'YOUTUBE_LIVE':
        return <YoutubeLiveDataTable record={record} />;
      case 'CONTENT_COLLECTION':
        return <ContentCollectionDataTable record={record} />;
      case 'PANCHANG':
        return <PanchangDataTable record={record} />;
      case 'APP_SHARE':
        return <AppShareDataTable record={record} />;
      case 'MENU_NOTIFICATION':
        return <MenuNotificationDataTable record={record} />;
      case 'MENU_LOGIN':
        return <MenuLoginDataTable record={record} />;
      case 'ACTION_V2':
        return <ActionV2DataTable record={record} />;
      case 'ACTION_SHORTCUTS':
        return <ActionShortcutsDataTable record={record} />;
      case 'ACTION_LIST':
        return <ActionListDataTable record={record} />;
      case 'HERO_SOCIAL_COMMUNITY':
        return <SocialDataTable record={record} />;
      case 'GREETING':
        return <GreetingsDataTable record={record} />;
    }
  };
  const getStartAndEndDate = (record: any) => {
    return (
      <div style={{ paddingLeft: '48px' }}>
        <Descriptions size="small" title={'Validity'} bordered>
          <Descriptions.Item label="Start Date">
            {
              <div>
                {record.start_timestamp &&
                  moment
                    .unix(record.start_timestamp)
                    .format('YYYY-MM-DD HH:mm:ss')}{' '}
              </div>
            }
          </Descriptions.Item>
          <Descriptions.Item label="End Date">
            {
              <div>
                {record.end_timestamp &&
                  moment
                    .unix(record.end_timestamp)
                    .format('YYYY-MM-DD HH:mm:ss')}{' '}
              </div>
            }
          </Descriptions.Item>
          <Descriptions.Item label="Start Time">
            {
              <div>
                <span>{record.start_day_time}</span>{' '}
              </div>
            }
          </Descriptions.Item>
          <Descriptions.Item label="End Time">
            {
              <div>
                <span>{record.end_day_time}</span>
              </div>
            }
          </Descriptions.Item>
          <Descriptions.Item label="Valid on Days">
            <div>
              {record.daysOfTheWeek.includes('All')
                ? 'All days'
                : record.daysOfTheWeek
                  ?.filter((item: string) => item !== 'All')
                  ?.join(',')}
            </div>
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  };
  const getDetailedData = (record: any) => {
    return (
      <div key={record?.key}>
        {getStartAndEndDate(record)}
        <PlatformProperty platform_property={record.platform_property} />
        <Widget widget={record.widget} />
        <HeaderTags
          header={record?.content?.header}
          header_tags={record?.content?.header_tags}
        />
        {getWidgetByType(record)}
      </div>
    );
  };
  const onExpandHandle = (isExpanded: boolean, record: any) => {
    if (isExpanded) {
      setExpandRowKeys([record.key]);
    } else {
      let position: number = expandedRowKeys.findIndex(
        (item) => item === record.key
      );
      expandedRowKeys.splice(position, 1);
    }
  };

  const dataColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: '32%',
      render: (text: any, record: any, index: any) => {
        return <div>{record?.id}</div>;
      },
    },
    {
      title: 'Widget Type',
      dataIndex: 'widget_type',
      key: 'widget_type',
      ellipsis: true,
      width: '20%',
      render: (text: any, record: any, index: any) => {
        return <div>{getReadableWidgetName(record?.widget?.widget_type)}</div>;
      },
      filters: [
        { text: 'Active', value: 'true' },
        { text: 'InActive', value: 'false' },
      ],
      onFilter: (value: any, record: any) => record.widget,
    },
    {
      title: 'Widget Code',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      width: '22%',
      render: (text: any, record: any, index: any) => {
        return <div>{record?.widget?.code}</div>;
      },
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      ellipsis: true,
      width: '10%',
      onFilter: (value: string, record: { language: string; }) => record.language === value,
      render: (text: string, record: any, index: any) => {
        return SUPPORTED_LANGUAGES_LIST.find((language) => language.value === text)?.alt_name;
      },
    },
    {
      title: 'Language Mode',
      dataIndex: 'language_mode',
      key: 'language_mode',
      ellipsis: true,
      filters: SUPPORTED_LANGUAGES_LIST.filter((language) => language.value === 'hi' || language.value === 'en').map((language) => ({ text: language.alt_name, value: language.value })),
      onFilter: (value: string, record: { language_mode: string; }) => record.language_mode === value,
      width: '10%',
      render: (text: string, record: any, index: any) => {
        return SUPPORTED_LANGUAGES_LIST.find((language) => language.value === text)?.alt_name;
      },
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
      width: '8%',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
      width: '8%',
    },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      key: 'created_by',

      render: (text: any, record: any, index: any) => {
        return <div>{record?.created_by?.split('@')[0]}</div>;
      },
      ellipsis: true,
      width: '10%',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (text: any, record: any, index: any) => {
        return <div>{record?.active ? 'Yes' : 'No'}</div>;
      },
      ellipsis: true,
      width: '7%',
    },

    {
      title: 'Action',
      key: 'action',
      width: '8%',
      ellipsis: true,
      /* render: (text: any, record: any, index: any) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure to delete this schedule?"
            onConfirm={() => {
              deleteScheduleHandler(record);
            }}
            onCancel={() => {
              console.log("Delete cancelled");
            }}
            okText="Yes,Delete"
            cancelText="No"
          >
            <Button icon={<DeleteFilled style={{ color: "red" }} />}></Button>
          </Popconfirm>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              EditScheduleHandler(record);
            }}
            icon={<EditFilled style={{ color: "#1990FF" }} />}
          ></Button>
        </Space>
      ), */
      render: (text: any, record: any, index: any) => (
        <Dropdown.Button
          overlay={() => {
            return menu(record);
          }}
        ></Dropdown.Button>
      ),
    },
  ];
  return (
    <Table
      bordered={false}
      //@ts-ignore
      columns={dataColumns}
      dataSource={scheduleList}
      expandable={{
        expandedRowRender: getDetailedData,
        onExpand: onExpandHandle,
        expandedRowKeys: expandedRowKeys,
        expandRowByClick: true,
      }}
      pagination={{ defaultPageSize: 8 }}
    />
  );
};

export default ScheduleManageDataTable;
