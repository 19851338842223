import { Rashifal } from "@a4b/api/src/modules/Content/Rashifal/types";
import { getLanguageNameFromValue } from "@/utils/constants";
import { Button, Switch, TableColumnType, TablePaginationConfig, TableProps } from "antd";
import moment from "moment";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { openNotification } from "@/utils";
import { useAppContext } from "@/components/AppContext";
import { Chapter } from "@a4b/api/src/modules/Gita/types";
import { renderBoolean, renderDateTime, renderImage } from "../../../../../components/TableHelpers/ColumnHelper";
import { Link } from "react-router-dom";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";

export default function useTableConfig<T>(
  dataSource: T[],
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
  setChapterId: React.Dispatch<React.SetStateAction<undefined>>
): TableProps<Rashifal.RashifalItem[]> {

  const { networkInstance } = useAppContext();

  const columns: TableColumnType<Chapter>[] = [
    {
      title: "Banner attachment",
      dataIndex: "banner_attachment",
      render(value, record, index) {
        return renderImage(value.url);
      },
    },
    {
      title: "Thumbnail attachment",
      dataIndex: "thumbnail_attachment",
      render(value, record, index) {
        return renderImage(value.url)
      },
    },
    {
      title: "Meta",
      dataIndex: "meta",
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: "column", rowGap: '5px' }}>
          <div>Title: <b>{value?.title}</b></div>
          <div>Desc: <b>{value?.description}</b></div>
          <div>Keywords: <b>{value?.Keywords}</b></div>
        </div>
      },
    },
    {
      title: "Shlokas ",
      dataIndex: "total_shlokas",
    },
    {
      title: "Last updated",
      dataIndex: "updated_at",
      render(value, record, index) {
        if (!value) {
          return renderDateTime(record.created_at, {}, 0);
        } else {
          return renderDateTime(value, {}, 0);
        }
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Link to={`${record.chapter_no}/shlokas`} >
            <Button type="link"> <EyeOutlined /> View </Button>
          </Link>
          <Button type="link" onClick={() => {
            setChapterId(value);
            setDrawer(DRAWER_TYPE.EDIT_CHAPTER)
          }}><EditOutlined /> Edit</Button>
        </div>
      },
    }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  //@ts-ignore
  return { columns, dataSource, pagination, onChange: tableOnChange }
}