import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import {
    CreateGroupOptions, JoinGroupOptions
} from './types'
import { apiClient } from '../../apiClient'


class MandaliApi {
    private api: AxiosInstance

    constructor(config: AxiosRequestConfig) {
        this.api = apiClient.create(config)
    }


    createGroup = (options: CreateGroupOptions) =>
        this.api.post(
            '/vixen/external/v2/groups',
            options,
        )

    joinGroup = (groupId: string, options: JoinGroupOptions) =>
        this.api.post(
            `/vixen/external/v2/groups/${groupId}/join`,
            options,
        )


    searchGroup = (userCreatedStatus = false, userSubscriptionStatus = false) =>
        this.api.get(
            `/vixen/external/v2/groups?q=&created_by_me=${userCreatedStatus}&user_subscription_status=${userSubscriptionStatus}`,
        )

    groupDetails = (groupId: string) =>
        this.api.get(
            `/vixen/external/v2/groups/${groupId}`,
        )



    groupMembers = (groupId: string) =>
        this.api.get(
            `/vixen/external/v2/groups/${groupId}/members`,
        )

    getUserMasterId = (userId) =>
        this.api.get(
            `/expresso/mandir/api/users/profile.php?userId=${userId}`,
        )

    getGuestMapping = (masterUserId) =>
        this.api.get(
            `/expresso/mandir/api/users/getAssociatedUserIds.php?masterUserId=${masterUserId}`,
        )

    updateGroupCreater = (guestIds) =>
        this.api.patch(`/vixen/external/v2/groups`, {
            "guest_ids": guestIds
        })

}

export default MandaliApi
