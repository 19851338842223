export enum EVENT_STATUS {
    DRAFT = 'DRAFT',
    IMPLEMENTED = 'IMPLEMENTED',
    INSTRUMENTED = 'INSTRUMENTED',
    VERIFIED = 'VERIFIED',
    DEPRECATED = 'DEPRECATED',
    LEGACY = "LEGACY"
}

export const VALID_STATE_CHANGES = {
    [EVENT_STATUS.LEGACY]: [EVENT_STATUS.DRAFT, EVENT_STATUS.DEPRECATED],
    [EVENT_STATUS.DRAFT]: [EVENT_STATUS.INSTRUMENTED, EVENT_STATUS.DEPRECATED],
    [EVENT_STATUS.IMPLEMENTED]: [EVENT_STATUS.VERIFIED, EVENT_STATUS.DRAFT, EVENT_STATUS.DEPRECATED],
    [EVENT_STATUS.INSTRUMENTED]: [EVENT_STATUS.IMPLEMENTED, EVENT_STATUS.DRAFT, EVENT_STATUS.DEPRECATED],
    [EVENT_STATUS.VERIFIED]: [EVENT_STATUS.DEPRECATED, EVENT_STATUS.DRAFT],
    [EVENT_STATUS.DEPRECATED]: [],
}

export const EVENT_STATUS_LIST = [
    EVENT_STATUS.LEGACY,
    EVENT_STATUS.DRAFT,
    EVENT_STATUS.IMPLEMENTED,
    EVENT_STATUS.INSTRUMENTED,
    EVENT_STATUS.VERIFIED,
    EVENT_STATUS.DEPRECATED,
]

export const EVENT_STATE_COLOR_CODES = {
    [EVENT_STATUS.LEGACY]: "gray",
    [EVENT_STATUS.DRAFT]: "yellow",
    [EVENT_STATUS.INSTRUMENTED]: "orange",
    [EVENT_STATUS.IMPLEMENTED]: "cyan",
    [EVENT_STATUS.VERIFIED]: "green",
    [EVENT_STATUS.DEPRECATED]: "red"
}


export const PRODUCER_TYPES = [
    'CLIENT',
    'USER',
    'BACKEND',
]

export const EVENT_TYPES = [
    'SWIPE',
    'IMPRESSION',
    'CLICK',
    'TIMESPENT',
    'SYSTEM'
]

export enum PLATFORM_TYPES {
    ANDROID = "ANDROID",
    WEB_ANDROID = "WEB_ANDROID",
    IOS = "IOS",
    WEB_IOS = "WEB_IOS",
    WEB = "WEB",
    KAIOS = "KAIOS",
    BACKEND = "BACKEND"
}

export const PLATFORM_TYPES_ARRAY = [
    PLATFORM_TYPES.ANDROID,
    PLATFORM_TYPES.WEB_ANDROID,
    PLATFORM_TYPES.IOS,
    PLATFORM_TYPES.WEB,
    PLATFORM_TYPES.KAIOS,
    PLATFORM_TYPES.BACKEND
]

export const PROPERTY_DATA_TYPES = [
    { label: 'STRING', value: 'STRING', isSubProperty: true },
    { label: 'INT64', value: 'INT64', isSubProperty: true },
    { label: 'BOOL', value: 'BOOL', isSubProperty: true },
    { label: "OBJECT", value: "OBJECT" },
    { label: 'ARRAY_STRING', value: 'ARRAY_STRING', isSubProperty: true},
    { label: "ARRAY_OBJECT", value: "ARRAY_OBJECT" },
]

export const DEFAULT_COLUMNS: { [key: string]: boolean } = {
    created_at: true,
    description: true,
    producer: true,
    group: true,
    name: true,
    platform_code: true,
    platform_type: true,
    status: true,
    event_type: true,
    tags: true,
    updated_at: false,
    created_by: false,
    updated_by: false,
    version: true,
    id: false,
    action: true
}

export enum DESTINATIONS {
    MIXPANEL_IMPORT_EVENT = "MIXPANEL_IMPORT_EVENT",
    MIXPANEL_UPDATE_USER = "MIXPANEL_UPDATE_USER",
    CLEVERTAP_IMPORT_EVENT = "CLEVERTAP_IMPORT_EVENT",
    CLEVERTAP_UPDATE_USER = "CLEVERTAP_UPDATE_USER"
}

export const DESTINATIONS_ARRAY = [
    DESTINATIONS.MIXPANEL_IMPORT_EVENT,
    DESTINATIONS.MIXPANEL_UPDATE_USER,
    DESTINATIONS.CLEVERTAP_IMPORT_EVENT,
    DESTINATIONS.CLEVERTAP_UPDATE_USER
]

export const ALLWAYS_ACTIVE_COLUMNS = new Set(['name', 'status', 'version', 'action'])