/* eslint-disable no-constant-condition */
import React from 'react'

import { Button, Col, Input, Row, Select, Card, Switch, Space, Table, Tag } from 'antd'
import styled from 'styled-components'

import { PlusSquareOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { CreateStore } from '@a4b/api/src/modules/Monetisation/offerings/types'
import { City } from '@a4b/api/src/modules/Content/Temples/types'

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 10px;
`
const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
interface ManageCityPageProps {
    cityList: City[]
    onStatusChange: (item: any) => void
}

const ManageCityPage: React.FC<ManageCityPageProps> = ({
    cityList,
    onStatusChange,
}) => {

    const dataColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
        },
        {
            title: 'City code',
            dataIndex: 'city_code',
            key: 'city_code',
            ellipsis: true,
            width: '25%',
        },
        {
            title: 'Language',
            key: 'language_code',
            dataIndex: 'language_code',
            width: '25%',
            render: (tags: any) => (
                <>
                    {tags.map((tag: any) => {
                        return (
                            <Tag color={tag?.startsWith('English') ? 'blue' : 'purple'} key={tag}>
                                {tag}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Active',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (text: string, record: any) => {
                return <Switch
                    checked={record.is_active}
                    onChange={() => onStatusChange(record)}
                />
            }
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text: string, record: any) => {
                return <Link to={`/temples/city?edit=true&city_code=${record.city_code}`}>
                    <Button type='link' color='cyan' style={{ cursor: 'pointer' }}>Edit</Button>
                </Link>
            }
        },
    ]

    return (
        <Container
            title='Manage Cities'
            style={{ width: '100%' }}
            extra={
                <Row>
                    <Col>
                        <Button type='primary'>
                            <Link to='/temples/city?edit=false'>
                                <PlusSquareOutlined /> Add City
                            </Link>
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Space direction={'vertical'} style={{ width: '100%' }}></Space>
            <Row>
                <Col span={28}>
                    <Table
                        sticky={true}
                        rowKey={(record: any) => record?.id}
                        columns={dataColumns}
                        dataSource={cityList}
                    />
                </Col>
            </Row>
            <LabelRightAlign>
            </LabelRightAlign>
        </Container>

    )
}
export default ManageCityPage
