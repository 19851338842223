import { useMemo } from 'react';
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useCallback, useEffect, useState } from 'react'
import 'antd/dist/antd.css';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { Button, Dropdown, Menu, Select, Switch, Tag, message } from 'antd';
import { SocialScreenContainer } from '@a4b/ui/src/admin';
import { openNotification } from '@/utils';
import { Shrine } from '@/../../../packages/a4b-api/src/modules/Temple/types';
import useAntdPagination from '@/hooks/useAntdPagination';
import GodForm from '../../components/forms/GodForm';
import TempleGodAssociatedItems from '../../components/GodAssociatedItems';
import GodCard from '../../components/GodCard';
import CloneGodForm from '../../components/forms/CloneGodForm';
import { SUPPORTED_LANGUAGES_LIST } from '@/utils/constants';
import { CopyOutlined, MoreOutlined } from '@ant-design/icons';
import GodItemMapping from '../../components/GodItemMapping';
import { Link, useNavigate } from 'react-router-dom';

enum DRAWER_TYPE {
  GOD_CREATE_FORM = 'GOD_CREATE_FORM',
  GOD_EDIT_FORM = 'GOD_EDIT_FORM',
  GOD_ASSOCIATED_ITEM_LIST = 'GOD_ASSOCIATED_ITEM_LIST',
  GOD_ITEM_MAPPING = 'GOD_ITEM_MAPPING',
  VIEW_GOD = 'VIEW_GOD',
  CLONE_GOD = "CLONE_GOD"
}

const TempleGod = () => {
  const { networkInstance } = useAppContext();
  const navigate = useNavigate()
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const [editGod, setEditGod] = useState<undefined | Shrine.God>();
  const [selectedGodType, setSelectedGodType] = useState<'User' | 'System'>('System');
  const [selectedLanguage, setSelectedLanguage] = useState<string>('hi')
  const [gods, setGods] = useState<Shrine.God[]>([])
  const { pagination, setPagination } = useAntdPagination();

  const loadGods = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.shrineApi.getGods(pagination.pageSize, (pagination.current ? pagination.current - 1 : 0) * (pagination.pageSize || 20), selectedGodType === 'User', selectedLanguage)
      setGods(res.data.data.gods);
      if (pagination.total !== res.data.data.count) {
        setPagination({ ...pagination, total: res.data.data.count })
      }
    } catch (error) {
    }
  }, [networkInstance.clientWithHeaders.shrineApi, pagination, selectedGodType, selectedLanguage, setPagination])

  useEffect(() => {
    loadGods()
  }, [loadGods])

  const handleDrawerClose = useCallback(() => {
    setActiveDrawer(undefined);
    setEditGod(undefined);
  }, [])


  const activateGod = useCallback(async (godId: string) => {
    const payload = {
      active: true
    }
    try {
      await networkInstance.clientWithHeaders.shrineApi.updateGodStatus(godId, payload)
      openNotification('success', { message: 'God activated successfully!' })
      loadGods();
    } catch (error: any) {
      openNotification('error', { message: 'God activation failed!', description: error.toString(), })
    }
  }, [loadGods, networkInstance.clientWithHeaders.shrineApi])


  const deactivateGod = useCallback(async (godId: string) => {
    const payload = {
      active: false
    }
    try {
      await networkInstance.clientWithHeaders.shrineApi.updateGodStatus(godId, payload)
      openNotification('success', { message: 'God deactivated successfully!' })
      loadGods();
    } catch (error: any) {
      openNotification('error', { message: 'God deactivation failed!', description: error.toString(), })
    }
  }, [loadGods, networkInstance.clientWithHeaders.shrineApi])

  const [messageApi, contextHolder] = message.useMessage();

  const info = useMemo(() => () => {
    messageApi.info('Copied!!');
  }, [messageApi]);


  const columns = useMemo(() => [
    {
      title: 'Image',
      dataIndex: 'id',
      render: (id: string, data: Shrine.God) => <img src={data?.thumbnails?.large?.url} height={50} width={50} alt={'god image'} />
    },
    {
      title: 'ID / Code',
      dataIndex: 'id',
      width: 400,
      render: (id: string, data: Shrine.God) => {
        return (
          <div>
            <span>{id} <CopyOutlined style={{ fontSize: 20 }} onClick={() => { navigator.clipboard.writeText(id); info(); }} /></span>
            <br /><br />
            <span><strong>Code:</strong> {data.code}</span>
          </div>
        )
      }
    },
    {
      title: 'Position',
      dataIndex: 'position',
      sorter: (a: Shrine.God, b: Shrine.God) => a.position - b.position,
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (isActive: boolean) => <Tag color={isActive ? 'green' : 'red'} > {isActive ? 'Yes' : 'No'}</Tag>
    },
    {
      title: 'Action',
      width: 300,
      dataIndex: 'id',
      render: (id: string, dataSource: Shrine.God) => {
        const menu = <Menu>
          <Menu.Item key="edit" onClick={() => { setEditGod(dataSource); setActiveDrawer(DRAWER_TYPE.GOD_EDIT_FORM); }}  >
            <Button type="link" > Edit </Button>
          </Menu.Item>
          <Menu.Item key="clone_god" onClick={() => { setEditGod(dataSource); setActiveDrawer(DRAWER_TYPE.CLONE_GOD); }}>
            <Button type="link"  > Clone god </Button>
          </Menu.Item>
          <Menu.Item key="view_god" >
            <Link to={`/shrine/gods/${id}/avatars`}>
              <Button type="link"  > View avatars </Button>
            </Link>
          </Menu.Item>
          <Menu.Item key="god_item_mapping" onClick={() => { setEditGod(dataSource); setActiveDrawer(DRAWER_TYPE.GOD_ITEM_MAPPING); }} >
            <Button type="link" > God item Mapping </Button>
          </Menu.Item>
        </Menu>
        return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <Switch checked={dataSource.active} onClick={() => { dataSource.active ? deactivateGod(id) : activateGod(id) }} checkedChildren={'Deactivate'} unCheckedChildren="Activate" />
          <Dropdown overlay={menu} placement="bottomLeft">
            <div> <MoreOutlined style={{ fontSize: 30 }} /></div>
          </Dropdown>
          {/* <Link  to={`/temple/god-items/${id}/flower`} > Associate flower </Link>
        <Link to={`/temple/god-items/${id}/garland`} > Associate garland </Link> */}
        </div>
      }
    },
  ], [activateGod, deactivateGod, info]);

  const uesrGodColumns = useMemo(() => [
    {
      title: 'Image',
      dataIndex: 'id',
      render: (id: string, data: Shrine.God) => <img src={data?.user_image?.url} height={50} width={50} alt={'god image'} />
    },
    {
      title: 'Position',
      dataIndex: 'position',
      sorter: (a: Shrine.God, b: Shrine.God) => a.position - b.position,
    },
    {
      title: 'Is Active',
      dataIndex: 'active',
      render: (isActive: boolean) => <Tag color={isActive ? 'green' : 'red'} > {isActive ? 'Yes' : 'No'}</Tag>
    },
    {
      title: 'User Id',
      dataIndex: 'user_id',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (id: string, dataSource: Shrine.God) => <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Button type="link" onClick={() => { setEditGod(dataSource); setActiveDrawer(DRAWER_TYPE.VIEW_GOD); }} > View god </Button>
      </div>
    },
  ], []);


  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }


  const drawerContent = useMemo(() => ([
    {
      id: DRAWER_TYPE.GOD_CREATE_FORM,
      title: 'Create god',
      component: <GodForm loadGods={loadGods} handleDrawerClose={handleDrawerClose} />,
      width: 1100
    },
    {
      id: DRAWER_TYPE.GOD_EDIT_FORM,
      title: 'Update god',
      component: editGod ?
        <>
          <GodCard god={editGod}  ></GodCard>
          <br></br>
          <GodForm editGod={editGod} loadGods={loadGods} handleDrawerClose={handleDrawerClose} />
        </>
        : undefined,
      width: 1100
    },
    {
      id: DRAWER_TYPE.GOD_ASSOCIATED_ITEM_LIST,
      title: 'Associated god items list',
      component: editGod ? <TempleGodAssociatedItems god={editGod}></TempleGodAssociatedItems> : null,
      width: 1100
    },
    {
      id: DRAWER_TYPE.VIEW_GOD,
      title: 'View god',
      component: editGod ? <GodCard showSortStores god={editGod} isDefaultActive></GodCard> : null,
      width: 1100
    },
    {
      id: DRAWER_TYPE.CLONE_GOD,
      title: 'clone god',
      component: editGod ? <CloneGodForm god={editGod} loadGods={loadGods} handleDrawerClose={handleDrawerClose}></CloneGodForm> : null,
      width: 1100
    },
    {
      id: DRAWER_TYPE.GOD_ITEM_MAPPING,
      title: 'View Item Mapping',
      component: editGod ? <GodItemMapping god={editGod} ></GodItemMapping> : null,
      width: 1100
    }
  ]), [editGod, handleDrawerClose, loadGods])

  const drawerProps = useMemo(() => ({
    title: drawerContent.find((Drawer) => Drawer.id === activeDrawer)?.title || '', visible: !!activeDrawer,
    onClose: handleDrawerClose,
    width: drawerContent.find((Drawer) => Drawer.id === activeDrawer)?.width || 900,
  }), [activeDrawer, drawerContent, handleDrawerClose])

  const filterUi = useMemo(() => {
    return <div>
      God type : &nbsp;
      <Select
        style={{ width: 120 }}
        onChange={(val) => { setSelectedGodType(val) }}
        value={selectedGodType}
      >
        {['User', 'System'].map((item) => <Select.Option key={item} value={item}>{item}</Select.Option>)}
      </Select>
      &nbsp;&nbsp; Language : &nbsp;
      <Select
        style={{ width: 200 }}
        onChange={(val) => { setSelectedLanguage(val) }}
        value={selectedLanguage}
        showSearch
        optionLabelProp="label"
        optionFilterProp="label"
      >
        {SUPPORTED_LANGUAGES_LIST.map((item) => <Select.Option key={item.code} label={item.alt_name} value={item.value}>{item.alt_name} ({item.name})</Select.Option>)}
      </Select>
    </div>
  }, [selectedLanguage, selectedGodType])

  return (
    <>
      {contextHolder}
      <SocialScreenContainer
        title={'Shrine God'}
        drawerProps={drawerProps}
        drawerContent={activeDrawer ? drawerContent.find((Drawer) => Drawer.id === activeDrawer)?.component : undefined}
        tableProps={{ columns: selectedGodType === 'User' ? uesrGodColumns : columns, rowKey: 'id', dataSource: gods, pagination, onChange: tableOnChange, scroll: { y: 650 } }}
        extra={
          <>
            <Button onClick={() => setActiveDrawer(DRAWER_TYPE.GOD_CREATE_FORM)}> Create God  </Button>
            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => navigate('/shrine/schedule?type=god')}>God Schedule</Button>
          </>
        }
        filtersUI={filterUi}
      >
      </SocialScreenContainer>
    </>
  )
}

export default withDefaultLayout(TempleGod)

