import React, { useEffect, useState } from 'react'
import { useForm } from 'antd/lib/form/Form'
import { message } from 'antd'
import { useAppContext } from '@/components/AppContext'
import { Users } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { formatUserCreation, formatUserUpdation } from '../../utils/helper'
import UserCreation from '../CreateUserForm'
interface Props {
    showUserCreation: boolean
    isEditJourney: boolean
    closeUserCreation: () => void
    formData?: Users
    hubList: any
}

const CreateUser: React.FC<Props> = ({
    showUserCreation,
    closeUserCreation: closeUserCreation,
    isEditJourney,
    formData,
    hubList,
}) => {
    const { networkInstance, monetisationRoles } = useAppContext()
    const { monetisationOfferingsApi, userApi } = networkInstance.clientWithHeaders

    const [editFormData, setEditFormData] = useState<any>()
    const [createUserForm] = useForm()

    useEffect(() => {
        if (isEditJourney) {
            const editForm = {
                ...formData,
                name: formData?.name
            }
            setEditFormData(editForm)
            createUserForm.setFieldsValue(editForm)
        } else {
            setTimeout(() => {
                createUserForm.resetFields()
            }, 300)
            setEditFormData({})

        }
    }, [isEditJourney, formData, createUserForm])

    const handleCreateOrUpdate = async (payload: any) => {
        if (isEditJourney) {
            try {
                const id = formData?.id || ''
                await userApi.UpdateUser(
                    { ...payload, ...{ id: id } },
                    id,
                )
                message.success('user updated successfully')
                createUserForm.resetFields()
                closeUserCreation()
            } catch (error: any) {
                message.error({
                    content: 'Error While updating user',
                    duration: 2,
                })
            }
        } else {
            try {
                message.loading({ content: 'Creating user...', key: 'create-user' })
                await userApi.CreateUser(
                    payload,
                )
                message.success({
                    content: 'User Created Successfully',
                    duration: 2,
                })
                createUserForm.resetFields()
                closeUserCreation()
            } catch (error: any) {
                message.error({
                    content: 'Error While creating user',
                    duration: 2,
                })
            }
        }
    }

    const formSubmithandler = async () => {
        try {
            const values = await createUserForm.validateFields()
            let payload
            if (isEditJourney) {
                payload = formatUserUpdation(values, monetisationRoles?.id, 'UPDATE_MONETIZATION_USER_DETAILS', formData?.user_id)
                handleCreateOrUpdate(payload)
            } else {
                payload = formatUserCreation(values, monetisationRoles?.id)
                handleCreateOrUpdate(payload)
            }
        } catch (error) {
            console.error('Error preparing payload:', error)
        }
    }

    return (
        <UserCreation
            visible={showUserCreation}
            onClose={closeUserCreation}
            formSubmithandler={formSubmithandler}
            form={createUserForm}
            initialValues={editFormData}
            isEditJourney={isEditJourney}
            userType={monetisationRoles?.user_type}
            hubList={hubList}
        />
    )
}

export default CreateUser
