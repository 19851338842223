import { throwError } from '../../../utils/helper';

type Item = {
  r_song_id?: number;
  song_of_the_day?: boolean;
};

type HeaderTags = {
  text: string;
  color: string;
};
class SongPlaylist {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header!: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header_tags!: HeaderTags[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  playlist_id!: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items: Item[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cta_text!: string;

  getSerializedData = (widgetFormData: SongPlaylist) => {
    let itemModified = widgetFormData?.items?.map((item: Item) => {
      if (item.song_of_the_day) delete item.r_song_id;
      if (!item.song_of_the_day) delete item.song_of_the_day;
      return {
        ...item,
      };
    });
    widgetFormData.items = itemModified;
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: SongPlaylist) => {
    return widgetFormData;
  };

  headerValidator = async (rule: any, value: any) => {
    if (!value) return Promise.resolve();
  };
  headerTagsTextValidator = async (rule: any, value: any) => {
    if (!value) throwError('Header tags text required!');
  };
  headerTagsColorValidator = async (rule: any, value: any) => {
    if (!value) throwError('Header tags color required!');
  };
  playlistIdValidator = async (rule: any, value: any) => {
    if (value <= 0) {
      throwError('Playlist ID must be greater than 0.');
    }
    if (!value) throwError('Playlist ID required!');
  };
  ctaTextValidator = async (rule: any, value: any) => {
    if (!value) throwError('CTA Text required!');
  };
  songIdValidator = async (rule: any, value: any) => {
    if (value <= 0) throwError('Song ID must be greater than 0.');
    if (!value) throwError('Song ID required!');
  };
}
export default SongPlaylist;
export const SongPlaylistInstance = new SongPlaylist();
