/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Rate,
  Row, Space,
  Switch,
  TimePicker,
  Typography,
  Divider
} from 'antd'
import styled from 'styled-components'

import React from 'react'

import S3Upload from '../S3Upload'
const { RangePicker } = TimePicker
const { Title } = Typography
type Faqs = { question: string; answer: string }

type reviews = {
  userReview: string
  userName: string
  userRating: number
  userImageUrl: string
}
type FormData = {
  faqs: Faqs[]
  reviews: reviews[]
}

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 10px;
`
interface Props {
  form: any
  faqFromInitialValues: FormData
  cdnUrl: string
  preSignUrl: string
  currentStep: number
  isEditJourney: boolean
  nextHandler: () => void
  previousHandler: () => void
  submitFormHandler: () => void
  serviceType: string
  deleteFaq: (id: string, callback: any) => void
  deleteSteps: (id: string, callback: any) => void
  deleteReview: (id: string, callback: any) => void
}
const { TextArea } = Input
const AssignFaqs: React.FC<Props> = ({
  form,
  faqFromInitialValues,
  cdnUrl,
  preSignUrl,
  currentStep,
  isEditJourney,
  nextHandler,
  previousHandler,
  submitFormHandler,
  serviceType,
  deleteFaq,
  deleteSteps,
  deleteReview,
}) => {
  return (
    <Row>
      <Col span={24}>
        <Form
          form={form}
          layout='vertical'
          name='create faqs'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={faqFromInitialValues}
        >
          <Row>
            <Col span={24}>
              <Title level={4}>{`Temple FAQ's`}</Title>
            </Col>
            <Col span={24}>
              <Form.List name={['faqs']}>
                {(fields, { add, remove, move }) => {
                  return (
                    <Row>
                      {fields.map((field: any, index: number) => {
                        return (
                          <Col span={8} key={`fandq-${field.key}`}>
                            <Row
                              style={{
                                border: '1px solid #e0dcdc',
                                marginRight: '3px',
                                marginBottom: '3px',
                              }}
                            >
                              <Col
                                span={21}
                                style={{
                                  marginLeft: '3px',
                                }}
                              >
                                <Form.Item
                                  noStyle
                                  name={[field.name, 'id']}
                                >

                                </Form.Item>

                                <Form.Item
                                  label={`Question ${index + 1}`}
                                  name={[field.name, 'question']}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input question!',
                                    },
                                  ]}
                                >
                                  <Input placeholder='Enter question' />
                                </Form.Item>
                                <Form.Item
                                  label=''
                                  name={[field.name, 'answer']}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input answer!',
                                    },
                                  ]}
                                >
                                  <TextArea placeholder='Enter answer' />
                                </Form.Item>
                                <Form.Item
                                  label='Position'
                                  name={[field.name, 'position']}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input position!',
                                    },
                                  ]}
                                >
                                  <InputNumber placeholder='Enter position' />
                                </Form.Item>
                              </Col>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => {
                                  return true
                                }

                                }
                              >
                                {({ getFieldValue }: any) => {

                                  return < Col
                                    span={2}
                                    style={{
                                      alignSelf: 'start',
                                      paddingLeft: '10px',
                                      paddingTop: '5px',
                                    }}
                                  >
                                    <div style={{ paddingLeft: '5px' }}>
                                      <Button
                                        onClick={() => {
                                          const id = getFieldValue(['faqs', field.name, 'id']);
                                          if (!id) {
                                            remove(field.name)
                                          } else {
                                            deleteFaq(id, () => {

                                              remove(field.name)
                                            })
                                          }
                                        }}
                                        type='ghost'
                                        icon={<DeleteOutlined />}
                                        size='middle'
                                        danger
                                      ></Button>
                                    </div>
                                  </Col>
                                }

                                }
                              </Form.Item>



                            </Row>
                          </Col>
                        )
                      })}

                      <Col span={24} style={{ paddingTop: '10px' }}>
                        <Row justify='start'>
                          <Col span={4}>
                            <Button
                              onClick={() => {
                                add()
                                // addItem();
                              }}
                              type='ghost'
                              icon={<PlusCircleOutlined />}
                              size='middle'
                            >
                              Add Question
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )
                }}
              </Form.List>
            </Col>
            <Divider />

            <Col span={24} style={{ paddingTop: '10px' }}>
              <Title level={4}>User reviews</Title>
            </Col>
            <Col span={24}>
              <Form.List name={['reviews']}>
                {(fields, { add, remove, move }) => {
                  return (
                    <Row>
                      {fields.map((field: any, index: number) => {
                        return (
                          <Col span={8} key={`tutorials-${field.key}`}>
                            <Row
                              style={{
                                border: '1px solid #e0dcdc',
                                marginRight: '3px',
                                marginBottom: '3px',
                              }}
                            >
                              <Col
                                span={21}
                                style={{
                                  marginLeft: '3px',
                                }}
                              >
                                <Form.Item
                                  noStyle
                                  name={[field.name, 'id']}
                                >

                                </Form.Item>
                                <Form.Item
                                  label={`Review ${index + 1}`}
                                  name={[field.name, 'review']}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input review!',
                                    },
                                  ]}
                                >
                                  <TextArea placeholder='Enter review' />
                                </Form.Item>
                                <Form.Item
                                  label=''
                                  name={[field.name, 'user_name']}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input username!',
                                    },
                                  ]}
                                >
                                  <Input placeholder='Enter username' />
                                </Form.Item>

                                <Form.Item
                                  label={`User image`}
                                  name={[field.name, 'user_image_url']}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[
                                    {
                                      min: 1,
                                      max: 1,
                                      validator: async (
                                        rules: any,
                                        values: any,
                                      ) => {
                                        if (values?.length < rules.min) {
                                          return Promise.reject(
                                            'Please upload user image',
                                          )
                                        }
                                      },
                                    },
                                  ]}
                                  valuePropName='fileList'
                                >
                                  <S3Upload
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    min={1}
                                    max={1}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label='Position'
                                  name={[field.name, 'position']}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input position!',
                                    },
                                  ]}
                                >
                                  <InputNumber placeholder='Enter position' />
                                </Form.Item>
                                <Form.Item
                                  label=''
                                  name={[field.name, 'rating']}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input rating!',
                                    },
                                  ]}
                                >
                                  <Rate />
                                </Form.Item>
                              </Col>

                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => {
                                  return true
                                }

                                }
                              >
                                {({ getFieldValue }: any) => {

                                  return < Col
                                    span={2}
                                    style={{
                                      alignSelf: 'start',
                                      paddingLeft: '10px',
                                      paddingTop: '5px',
                                    }}
                                  >
                                    <div style={{ paddingLeft: '5px' }}>
                                      <Button
                                        onClick={() => {
                                          const id = getFieldValue(['reviews', field.name, 'id']);
                                          if (!id) {
                                            remove(field.name)
                                          } else {
                                            deleteReview(id, () => {

                                              remove(field.name)
                                            })
                                          }
                                        }}
                                        type='ghost'
                                        icon={<DeleteOutlined />}
                                        size='middle'
                                        danger
                                      ></Button>
                                    </div>
                                  </Col>
                                }

                                }
                              </Form.Item>

                            </Row>
                          </Col>
                        )
                      })}

                      <Col span={24} style={{ paddingTop: '10px' }}>
                        <Row justify='start'>
                          <Col span={4}>
                            <Button
                              onClick={() => {
                                add()
                              }}
                              type='ghost'
                              icon={<PlusCircleOutlined />}
                              size='middle'
                            >
                              Add Review
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )
                }}
              </Form.List>
            </Col>


            {serviceType === 'pooja' && (
              <>
                <Divider />
                <Col span={24}>
                  <Title level={4}>{`Pooja Benefits`}</Title>
                </Col>
                <Col span={24}>
                  <Form.List name={['pooja_benefits']}>
                    {(fields, { add, remove, move }) => {
                      return (
                        <Row>
                          {fields.map((field: any, index: number) => {
                            return (
                              <Col span={8} key={`pooja_benefits-${field.key}`}>
                                <Row
                                  style={{
                                    border: '1px solid #e0dcdc',
                                    marginRight: '3px',
                                    marginBottom: '3px',
                                  }}
                                >
                                  <Col
                                    span={21}
                                    style={{
                                      marginLeft: '3px',
                                    }}
                                  >
                                    <Form.Item
                                      noStyle
                                      name={[field.name, 'id']}
                                    >

                                    </Form.Item>
                                    <Form.Item
                                      label={`Step ${index + 1}`}
                                      name={[field.name, 'step']}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please input Step!',
                                        },
                                      ]}
                                    >
                                      <Input placeholder='Enter Step' />
                                    </Form.Item>
                                    <Form.Item
                                      label='Description'
                                      name={[field.name, 'description']}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please input description!',
                                        },
                                      ]}
                                    >
                                      <TextArea placeholder='Enter description' />
                                    </Form.Item>
                                    <Form.Item
                                    label='Position'
                                    name={[field.name, 'position']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input position!',
                                      },
                                    ]}
                                  >
                                    <InputNumber placeholder='Enter position' />
                                  </Form.Item>
                                    <Form.Item
                                      label={`Icon`}
                                      name={[field.name, 'pooja_benefit_icon']}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      rules={[
                                        {
                                          min: 1,
                                          max: 1,
                                          validator: async (
                                            rules: any,
                                            values: any,
                                          ) => {
                                            if (values?.length < rules.min) {
                                              return Promise.reject(
                                                'Please upload icon',
                                              )
                                            }
                                          },
                                        },
                                      ]}
                                      valuePropName='fileList'
                                    >
                                      <S3Upload
                                        cdnUrl={cdnUrl}
                                        preSignUrl={preSignUrl}
                                        min={1}
                                        max={1}
                                      />
                                    </Form.Item>

                                  </Col>

                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                      return true
                                    }

                                    }
                                  >
                                    {({ getFieldValue }: any) => {

                                      return < Col
                                        span={2}
                                        style={{
                                          alignSelf: 'start',
                                          paddingLeft: '10px',
                                          paddingTop: '5px',
                                        }}
                                      >
                                        <div style={{ paddingLeft: '5px' }}>
                                          <Button
                                            onClick={() => {
                                              const id = getFieldValue(['pooja_benefits', field.name, 'id']);
                                              if (!id) {
                                                remove(field.name)
                                              } else {
                                                deleteSteps(id, () => {

                                                  remove(field.name)
                                                })
                                              }
                                            }}
                                            type='ghost'
                                            icon={<DeleteOutlined />}
                                            size='middle'
                                            danger
                                          ></Button>
                                        </div>
                                      </Col>
                                    }

                                    }
                                  </Form.Item>

                                </Row>
                              </Col>
                            )
                          })}

                          <Col span={24} style={{ paddingTop: '10px' }}>
                            <Row justify='start'>
                              <Col span={4}>
                                <Button
                                  onClick={() => {
                                    add()
                                  }}
                                  type='ghost'
                                  icon={<PlusCircleOutlined />}
                                  size='middle'
                                >
                                  Add Pooja Benefit
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )
                    }}
                  </Form.List>
                </Col>

                <Divider />

                <Col span={24}>
                  <Title level={4}>{`Pooja Process`}</Title>
                </Col>
                <Col span={24}>
                  <Form.List name={['pooja_process']}>
                    {(fields, { add, remove, move }) => {
                      return (
                        <Row>
                          {fields.map((field: any, index: number) => {
                            return (
                              <Col span={8} key={`pooja_process-${field.key}`}>
                                <Row
                                  style={{
                                    border: '1px solid #e0dcdc',
                                    marginRight: '3px',
                                    marginBottom: '3px',
                                  }}
                                >
                                  <Col
                                    span={21}
                                    style={{
                                      marginLeft: '3px',
                                    }}
                                  >
                                    <Form.Item
                                      noStyle
                                      name={[field.name, 'id']}
                                    >

                                    </Form.Item>
                                    <Form.Item
                                      label={`Step ${index + 1}`}
                                      name={[field.name, 'step']}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please input Step!',
                                        },
                                      ]}
                                    >
                                      <Input placeholder='Enter Step' />
                                    </Form.Item>
                                    <Form.Item
                                      label='Description'
                                      name={[field.name, 'description']}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please input description!',
                                        },
                                      ]}
                                    >
                                      <TextArea placeholder='Enter description' />
                                    </Form.Item>
                                    <Form.Item
                                    label='Position'
                                    name={[field.name, 'position']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input position!',
                                      },
                                    ]}
                                  >
                                    <InputNumber placeholder='Enter position' />
                                  </Form.Item>
                                    <Form.Item
                                      label={`Icon`}
                                      name={[field.name, 'pooja_process_icon']}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      rules={[
                                        {
                                          min: 1,
                                          max: 1,
                                          validator: async (
                                            rules: any,
                                            values: any,
                                          ) => {
                                            if (values?.length < rules.min) {
                                              return Promise.reject(
                                                'Please upload icon',
                                              )
                                            }
                                          },
                                        },
                                      ]}
                                      valuePropName='fileList'
                                    >
                                      <S3Upload
                                        cdnUrl={cdnUrl}
                                        preSignUrl={preSignUrl}
                                        min={1}
                                        max={1}
                                      />
                                    </Form.Item>

                                  </Col>

                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                      return true
                                    }

                                    }
                                  >
                                    {({ getFieldValue }: any) => {

                                      return < Col
                                        span={2}
                                        style={{
                                          alignSelf: 'start',
                                          paddingLeft: '10px',
                                          paddingTop: '5px',
                                        }}
                                      >
                                        <div style={{ paddingLeft: '5px' }}>
                                          <Button
                                            onClick={() => {
                                              const id = getFieldValue(['pooja_process', field.name, 'id']);
                                              if (!id) {
                                                remove(field.name)
                                              } else {
                                                deleteSteps(id, () => {
                                                  remove(field.name)
                                                })
                                              }
                                            }}
                                            type='ghost'
                                            icon={<DeleteOutlined />}
                                            size='middle'
                                            danger
                                          ></Button>
                                        </div>
                                      </Col>
                                    }

                                    }
                                  </Form.Item>

                                </Row>
                              </Col>
                            )
                          })}

                          <Col span={24} style={{ paddingTop: '10px' }}>
                            <Row justify='start'>
                              <Col span={4}>
                                <Button
                                  onClick={() => {
                                    add()
                                  }}
                                  type='ghost'
                                  icon={<PlusCircleOutlined />}
                                  size='middle'
                                >
                                  Add Pooja Process
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )
                    }}
                  </Form.List>
                </Col>
              </>
            )}



            <Divider />

            <Col span={24} style={{ paddingTop: '10px' }}>
              <Title level={4}>Store Opening and Closing time</Title>
            </Col>
            <Col span={24} style={{ paddingTop: '10px' }}>
              <Form.Item
                label='Choose from and to time'
                name={'session'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <RangePicker format={'HH:mm'} />
              </Form.Item>
            </Col>

            <Divider />
            <Col span={24} style={{ paddingTop: '10px' }}>
              <Form.Item
                label='Temple Status(Optional)'
                name={'status'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                valuePropName='checked'
              >
                <Switch checked />
              </Form.Item>
            </Col>
          </Row>
        </Form >
      </Col >
      <Col span={24}>
        <LabelRightAlign>
          <Space>
            {currentStep > 0 && (
              <Button type='primary' onClick={previousHandler}>
                Previous
              </Button>
            )}
            {isEditJourney && currentStep !== 1 && (
              <Button type='primary' onClick={submitFormHandler}>
                Update
              </Button>
            )}
            {currentStep < 3 && currentStep !== 1 && !isEditJourney ? (
              <Button type='primary' onClick={submitFormHandler}>
                Submit
              </Button>
            ) : (
              currentStep !== 2 && (
                <Button type='primary' onClick={nextHandler}>
                  next
                </Button>
              )
            )}
          </Space>
        </LabelRightAlign>
      </Col>
    </Row >
  )
}
export default AssignFaqs
