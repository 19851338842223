import { Tag } from 'antd'
import { NavMenuItem } from '../types'

const adminMenus: NavMenuItem[] = [
  {
    title: 'Monetisation',
    id: 'monetisation',
    children: [
      {
        title: 'Offerings',
        id: 'offerings',
        linkItem: [
          {
            name: 'Batches',
            path: '/monetisation/offerings',
            id: '/monetisation/offerings',
          },
          {
            name: 'Manage Stores',
            path: '/monetisation/offerings/temples',
            id: '/monetisation/offerings/temples',
          },
          {
            name: 'Create Store',
            path: '/monetisation/offerings/temple/create',
            id: '/monetisation/offerings/temple/create',
          },
          {
            name: 'Manage Products',
            path: '/monetisation/offerings/items',
            id: '/monetisation/offerings/items',
          },
          {
            name: 'Manage Page Section',
            path: '/monetisation/offerings/manage_page_section',
            id: '/monetisation/offerings/manage_page_section',
          },
          {
            name: 'Manage Orders',
            path: '/monetisation/offerings/pooja-orders/orders',
            id: '/monetisation/offerings/pooja-orders/orders',
          },
          {
            name: 'Manage User segments',
            path: '/monetisation/offerings/segments/manage-user-segments',
            id: '/monetisation/offerings/segments/manage-user-segments',
          },
          {
            name: 'Content Scheduler',
            path: '/monetisation/offerings/content/event-schedule',
            id: '/monetisation/offerings/content/event-schedule',
          },
          {
            name: 'Asset Upload',
            path: '/monetisation/offerings/s3-upload',
            id: '/monetisation/offerings/s3-upload',
          },
          {
            name: 'Manage Users',
            path: '/monetisation/offerings/manage-users/users',
            id: '/monetisation/offerings/manage-users/users',
          },
          {
            name: 'Video Qc',
            path: '/monetisation/offerings/video-qc',
            id: '/monetisation/offerings/video-qc'
          },
          {
            name: 'Manage Temples',
            path: '/monetisation/offerings/manage-temples',
            id: '/monetisation/offerings/manage-temples',
          }
        ],
      },
      {
        title: 'Astro',
        id: 'astrologer',
        linkItem: [
          {
            name: 'Manage Astrologer',
            path: '/monetisation/astrologer/astrologer-list',
            id: '/monetisation/astrologer/astrologer-list',
          },
          {
            name: 'Manage Page Section',
            path: '/monetisation/astrologer/manage-page-section',
            id: '/monetisation/astrologer/manage-page-section',
          },
          {
            name: 'Manage Calls',
            path: '/monetisation/astrologer/manage-calls',
            id: '/monetisation/astrologer/manage-calls',
          },
        ],
      },
      {
        title: 'Wallet',
        id: 'wallet',
        linkItem: [
          {
            name: 'Recharge Packages',
            path: '/monetisation/wallet/recharge-packages-list',
            id: '/monetisation/wallet/recharge-packages-list',
          },
        ],
      },
      {
        title: 'E-Puja',
        id: 'epuja',
        linkItem: [
          {
            name: 'Video Upload',
            path: '/epuja/video-upload',
            id: '/epuja/video-upload',
          }
        ],
      },
      {
        title: 'Campaigns',
        id: 'campaigns',
        linkItem: [
          {
            name: 'Campaigns',
            path: '/monetisation/campaigns/manage-campaigns',
            id: '/monetisation/campaigns/manage-campaigns',
          }
        ],
      },
    ]
  },
  {
    title: 'Puja',
    id: 'puja',
    linkItem: [
      {
        name: 'Manage Batches',
        path: '/monetisation/puja',
        id: '/monetisation/puja',
      },
      {
        name: 'Manage Stores',
        path: '/monetisation/puja/temples',
        id: '/monetisation/puja/temples',
      },
      {
        name: 'Manage Filters',
        path: '/monetisation/puja/filters',
        id: '/monetisation/puja/filters',
      },
      {
        name: 'Manage Puja updates',
        path: '/monetisation/puja/puja-updates',
        id: '/monetisation/puja/puja-updates',
      },
      {
        name: 'Manage Products',
        path: '/monetisation/puja/items',
        id: '/monetisation/puja/items',
      },
      {
        name: 'Manage Page Section',
        path: '/monetisation/puja/manage_page_section',
        id: '/monetisation/puja/manage_page_section',
      },
      {
        name: 'Manage Orders',
        path: '/monetisation/puja/pooja-orders/orders',
        id: '/monetisation/puja/pooja-orders/orders',
      },
      {
        name: 'Manage User segments',
        path: '/monetisation/puja/segments/manage-user-segments',
        id: '/monetisation/puja/segments/manage-user-segments',
      },
      {
        name: 'Asset Upload',
        path: '/monetisation/puja/s3-upload',
        id: '/monetisation/puja/s3-upload',
      }
    ],
  },
  {
    title: <div>New Astro<Tag color='#108ee9' style={{ marginLeft: '5px' }}>New</Tag></div>,
    id: 'astro',
    children: [
      {
        title: 'Astro',
        id: 'astro_astrologer',
        linkItem: [
          {
            name: 'Manage Astrologer',
            path: '/astro/astrologer/astrologer-list',
            id: '/astro/astrologer/astrologer-list',
          },
          {
            name: 'Manage Page Section',
            path: '/astro/astrologer/manage-page-section',
            id: '/astro/astrologer/manage-page-section',
          },
          {
            name: 'Manage Calls',
            path: '/astro/astrologer/manage-calls',
            id: '/astro/astrologer/manage-calls',
          },
        ],
      },
      {
        title: 'Wallet',
        id: 'astro_wallet',
        linkItem: [
          {
            name: 'Recharge Packages',
            path: '/astro/wallet/recharge-packages-list',
            id: '/astro/wallet/recharge-packages-list',
          },
        ],
      },
      {
        title: 'Puja',
        id: 'astro_puja',
        linkItem: [
          {
            name: 'Manage Batches',
            path: '/astro/puja',
            id: '/astro/puja',
          },
          {
            name: 'Manage Stores',
            path: '/astro/puja/temples',
            id: '/astro/puja/temples',
          },
          {
            name: 'Manage Filters',
            path: '/astro/puja/filters',
            id: '/astro/puja/filters',
          },
          {
            name: 'Manage Puja updates',
            path: '/astro/puja/puja-updates',
            id: '/astro/puja/puja-updates',
          },
          {
            name: 'Manage Products',
            path: '/astro/puja/items',
            id: '/astro/puja/items',
          },
          {
            name: 'Manage Page Section',
            path: '/astro/puja/manage_page_section',
            id: '/astro/puja/manage_page_section',
          },
          {
            name: 'Manage Orders',
            path: '/astro/puja/pooja-orders/orders',
            id: '/astro/puja/pooja-orders/orders',
          },
          {
            name: 'Manage User segments',
            path: '/astro/puja/segments/manage-user-segments',
            id: '/astro/puja/segments/manage-user-segments',
          },
          {
            name: 'Asset Upload',
            path: '/astro/puja/s3-upload',
            id: '/astro/puja/s3-upload',
          }
        ],
      },
    ]
  },
]

export default adminMenus
