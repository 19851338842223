import React, { useCallback, useEffect, useState } from 'react'
import { useAppContext } from '@/components/AppContext'
import { useForm } from 'antd/lib/form/Form'
import { Row, message } from 'antd'
import _ from 'lodash'
import DragAndDropModal from '@/hoc/DragAndDropModal'
import AstrologerPageInfoForm from '@a4b/ui/src/modules/monetisation/molecules/AstrologerPageInfoForm'
import ManagePagesPage from '@a4b/ui/src/modules/monetisation/organisms/ManagePages'
import {
    PageListItem,
} from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { Sections } from '@a4b/api/src/modules/Monetisation/Astrologer/types'
import { A4B_APPS } from '@/utils/constants'
import MonetisationAstroApi from '@a4b/api/src/modules/Monetisation/Astrologer'

interface Props {
}

interface ShowSectionInfoProps {
    data?: Partial<Sections>
}

const ShowSectionInfo: React.FC<ShowSectionInfoProps> = ({ data }) => {
    return (
        <Row style={{ border: '1px solid grey', width: '100%' }}>
            <Row justify='space-between' style={{ width: '100%', padding: '10px' }}>
                <span>
                    {`Title: ${data?.title}`}
                </span>
                <span>
                    {`Desc: ${data?.description}`}
                </span>
                <span>
                    {`Type: ${data?.type}`}
                </span>
            </Row>
        </Row>
    )
}

const AstrologerPages: React.FC<Props> = () => {
    const { networkInstance, app } = useAppContext()
    const [pageForm] = useForm()
    const [sectionList, setSectionList] = useState<Sections[]>()
    const { monetisationAstroApi, monetisationNewAstroApi } = networkInstance.clientWithHeaders
    const api: MonetisationAstroApi = (app === A4B_APPS.SRIMANDIR) ? monetisationAstroApi : monetisationNewAstroApi
    const [sortedSectionList, setSortedSectionList] = useState<
        Sections[]
    >([])
    const [currentPageTypeData, setCurrentPageTypeData] = useState<any>()
    const [showDandDModal, setDandDModalVisibility] = useState(false)
    const [showPageForm, setShowPageForm] = useState(false)
    const [showUpdateButton, setShowUpdateButton] = useState(false)

    const [pageInfoList, setPageInfoList] = useState<any>()

    const getPageInfoList = useCallback(async () => {
        try {
            const pageInfoList = await api.pagesApi.getPages()
            setPageInfoList(pageInfoList?.data?.data?.pages)
        } catch (error) {
            message.error({
                content: 'Error while fetching page info list',
                duration: 3,
            })
        }
    }, [api.pagesApi])

    useEffect(() => {
        getPageInfoList()
    }, [getPageInfoList])

    const handleOnCancel = () => {
        setDandDModalVisibility(false)
    }

    const handleOnOk = (sortedList: any[]) => {
        setSortedSectionList(sortedList)
        const sortedIds = sortedList?.map((item: any) => item?.id)
        if (!_.isEqual(currentPageTypeData?.sections, sortedIds)) {
            setShowUpdateButton(true)
        } else {
            setShowUpdateButton(true)
        }
        setDandDModalVisibility(false)
    }
    const onCreateNew = () => { }

    const getSections = useCallback(
        async (pageList: any, currentPageData: any) => {
            try {
                const sections = await api.sectionsApi.getSections({
                    limit: 1000,
                    offset: 0,
                })
                setSectionList(sections?.data?.data?.sections)
                if (pageList?.length) {
                    const feedPage = pageList?.find(
                        (item: PageListItem) =>
                            (item?.key === currentPageData?.key) && (item?.language_code === currentPageData?.language_code),
                    )
                    const filteredSection = sections?.data?.data?.sections?.filter(
                        (section: Sections) =>
                            feedPage?.sections?.includes(section.id),
                    )
                    const sortedItems = _.sortBy(filteredSection, (item: Sections) => feedPage?.sections?.indexOf(item?.id))
                    if (sortedItems?.length) {
                        setSortedSectionList(sortedItems)
                    } else {
                        setSortedSectionList([])
                    }
                }
            } catch (error) {
                message.error({
                    content: 'Error while fetching sections',
                    duration: 3,
                })
            }
        }, [api.sectionsApi])

    const onClickHandler = (pageType: string, language_code: string) => {
        setShowPageForm(true)
        setShowUpdateButton(false)
        const filteredPageType = pageInfoList.find((item: any) => item.key === pageType && item.language_code === language_code)
        setCurrentPageTypeData(filteredPageType)
        getSections(pageInfoList, filteredPageType)
    }
    const handleCreatePage = async (pageId: string) => {
        const payload = sortedSectionList?.map((item: any) => item?.id)
        try {
            await api.pagesApi.updatePage(
                currentPageTypeData?.id || '',
                {
                    sections: payload,
                    app_code: app!
                },
            )
            message.success({ content: 'Section updated sucessfully', duration: 3 })
            getPageInfoList()
            setShowPageForm(false)
        } catch (error) {
            message.error({ content: 'Error occured while updating pages' })
        }
    }

    const formSubmithandler = (sections: any) => {
        pageForm
            .validateFields()
            .then(values => {
                handleCreatePage(currentPageTypeData?.id)
            })
            .catch(error => { })
    }

    const closePageForm = () => {
        setShowPageForm(false)
        setShowUpdateButton(false)
        setTimeout(() => {
            getPageInfoList()
        }, 500)
    }

    const formChangeHandler = (values: any) => {
        setShowUpdateButton(true)
    }

    return (
        <div style={{ padding: '0 40px' }}>
            <ManagePagesPage
                onCreateNew={onCreateNew}
                dataSource={pageInfoList}
                onClickhandler={onClickHandler}
                isSubtitle={false}
            />
            <AstrologerPageInfoForm
                form={pageForm}
                formSubmithandler={formSubmithandler}
                onClose={closePageForm}
                visible={showPageForm}
                pageTitle={currentPageTypeData?.name || ''}
                setDandDModalVisibility={setDandDModalVisibility}
                sortedSectionList={sortedSectionList!}
                showUpdateButton={showUpdateButton}
                renderer={<ShowSectionInfo key={'section-props'} />}
                formChangeHandler={formChangeHandler}
            />
            <DragAndDropModal
                showModal={showDandDModal}
                handleOnCancel={handleOnCancel}
                handleOnOk={handleOnOk}
                sortedData={sortedSectionList}
                itemList={sectionList!}
                title={`Select and arrange sections`}
            >
                <ShowSectionInfo key={'section-rendered'} />
            </DragAndDropModal>
        </div>
    )
}

export default AstrologerPages
