
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Content from "./components/Content";

const RoleManagement = function () {
  return <SocialScreenContainer
    title={"Role management"}
    content={<Content></Content>}
  />
};

export default withDefaultLayout(RoleManagement)