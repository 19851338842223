import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import {
  AstroAnalyticsData,
  AstroList,
  Astrologer,
  Call,
  CallList,
  PaginationParams,
  Response,
} from './types'
import { apiClient } from '../../../apiClient'

class AgentApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getAllAgents = (paginationParams: PaginationParams, country_code: string) =>
    this.api.get<AstroList>(`gw1/titan/internal/v1/agents`, {
      params: {
        limit: paginationParams?.limit,
        offset: paginationParams?.offset,
        email: paginationParams?.email,
      },
      headers: { 'x-afb-country-code': country_code },
    })

  getAnalytics = (filter: string) =>
    this.api.get<AstroAnalyticsData>(`gw1/titan/internal/v1/analytics?filter=${filter}`)


  getAgentAnalytics = (agentId: string, filter: string) =>
    this.api.get<AstroAnalyticsData>(`gw1/titan/internal/v1/agents/${agentId}/analytics?filter=${filter}`)

  getAgentById = (id: string, country_code: string, isVariant: boolean) =>
    this.api.get<Response<Astrologer>>(`gw1/titan/internal/v1/agents/${id}?variant=${isVariant}`, {
      headers: { 'x-afb-country-code': country_code },
    })

  createAgent = (payload: Astrologer) =>
    this.api.post(`gw1/titan/internal/v1/agents`, payload)

  updateAgent = (id: string, payload: Astrologer) =>
    this.api.put(`gw1/titan/internal/v1/agents/${id}`, payload)

  updateAgentStatus = (id: string, payload: any) =>
    this.api.patch(`/gw1/titan/internal/v1/agents/${id}`, payload)

  getCallList = (paginationParams: PaginationParams, status?: string) =>
    this.api.get<Call>(`gw1/titan/internal/v1/calls`, {
      params: {
        limit: paginationParams?.limit,
        offset: paginationParams?.offset,
        status: status,
      },
    })
}

export default AgentApi
