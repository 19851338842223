import { Col, Form, Row, Button, Select } from "antd"
import React from "react"
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'

interface Props {
    fields: any
    add: () => void
    remove: (item: any) => void
    cdnUrl: string
    preSignUrl: string
    disabled?: boolean
}
const { Option } = Select

const Gallery: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, disabled = false }) => {
    return (
        <Row justify="space-between">
            {fields.map((field: any, index: number) => {
                return (
                    <div key={index} style={{
                        border: '1.5px dashed #e0dcdc',
                        marginRight: '0px',
                        marginBottom: '10px',
                        padding: '10px',
                        width: '49%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <Row justify="space-between">
                            <Form.Item
                                // label={`Media type ${index + 1}`}
                                name={[field.name, 'media_type']}
                                rules={[{ required: true, message: 'Select Media type' }]}
                                style={{ width: '60%' }}
                            >
                                <Select
                                    placeholder='Select Media type'
                                    disabled={disabled}
                                >
                                    <Option value='image'>Image</Option>
                                    <Option value='gif'>GIF</Option>
                                </Select>
                            </Form.Item>
                            {
                                !disabled &&
                                <Button
                                    onClick={() => {
                                        remove(field.name)
                                    }}
                                    type='ghost'
                                    icon={<DeleteOutlined />}
                                    size='middle'
                                    danger
                                ></Button>
                            }
                        </Row>
                        <Form.Item
                            name={[field.name, 'media_url']}
                            valuePropName='fileList'
                            style={{ marginBottom: '0px' }}
                            rules={[
                                {
                                    required: false,
                                    min: 0,
                                    max: 1,
                                    validator: async (
                                        rules: any,
                                        values: any,
                                    ) => {
                                        if (values?.length < rules.min) {
                                            return Promise.reject(
                                                'Please Upload Image',
                                            )
                                        }
                                    },
                                },
                            ]}
                        >
                            <S3Upload
                                disabled={disabled}
                                cdnUrl={cdnUrl}
                                preSignUrl={preSignUrl}
                                min={1}
                                max={1}
                            />
                        </Form.Item>
                    </div>
                )
            })}
            <Col span={24} style={{ paddingTop: '5px' }}>
                <Row justify='start'>
                    <Col span={4}>
                        <Button
                            onClick={() => {
                                add()
                            }}
                            type='ghost'
                            icon={<PlusCircleOutlined />}
                            size='middle'
                        >
                            Add Image
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Gallery