/* eslint-disable prefer-spread */
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Checkbox, Drawer, Form, Input, Radio, Row, Switch, message } from 'antd'
import type { RadioChangeEvent } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { actionMapping, orderPayload } from '../../utils/helper'
import { useAppContext } from '@/components/AppContext'
import { A4B_APPS } from '@/utils/constants'

interface Props {
    showItemCreation: boolean
    closeItemCreation: () => void
    initialValues: any
    form: FormInstance<any>
    cdnUrl: string
    preSignUrl: string
    userRole: string
    handleUpdateOrders: () => void
}

interface Tabs {
    memberData: boolean,
    address: boolean,
    whatsappNumber: boolean,
    [key: string]: boolean
}

const OrderDetailsForm: React.FC<Props> = ({
    showItemCreation,
    closeItemCreation,
    initialValues,
    form,
    cdnUrl,
    preSignUrl,
    userRole,
    handleUpdateOrders
}) => {
    const [tabs, setTabs] = useState<Tabs>({
        memberData: false,
        address: false,
        whatsappNumber: false,
    })
    const [submit, setSubmit] = useState<boolean>(false)
    const [enableAddress, setEnableAddress] = useState<boolean>(false)
    const { networkInstance, monetisationRoles, userProfile, app } = useAppContext()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi

    useEffect(() => {
        let bookingMemberData

        setTabs((prevTabs) => ({
            ...prevTabs,
            memberData: true,
            address: false
        }))

        setEnableAddress(initialValues?.is_opted_for_prasad || initialValues?.is_deliverable)
        const memberCount = ((initialValues?.member_size) - (initialValues?.booking_member_data?.length) < 0 ? 0 : (initialValues?.member_size) - (initialValues?.booking_member_data?.length))
        console.log({ initialValues })
        if (!initialValues?.booking_member_data) {
            bookingMemberData = Array.apply(null, Array(initialValues?.member_size)).map(() => ({
                full_name: '',
                gotra: '',
                mobile_number: '',
                whatsapp_number: '',
                same_as_mobile_number: false
            }))
        } else {
            const additionalMembers = Array.apply(null, Array(memberCount ? memberCount : 0))?.map(() => ({
                full_name: '',
                gotra: '',
                mobile_number: '',
                whatsapp_number: '',
                same_as_mobile_number: false
            }))
            bookingMemberData = initialValues.booking_member_data.concat(additionalMembers)
        }
        form.setFieldsValue({ ...initialValues, booking_member_data: bookingMemberData })
    }, [initialValues])


    const onChangeRadioEvent = useCallback((e: RadioChangeEvent) => {
        switch (e.target.value) {
            case 'memberData':
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    memberData: true,
                    whatsappNumber: false,
                    address: false
                }))
                break
            case 'address':
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    memberData: false,
                    whatsappNumber: false,
                    address: true
                }))
                break
            case 'whatsappNumber':
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    memberData: false,
                    whatsappNumber: true,
                    address: false,
                }))
                break
        }
    }, [])

    const isNumeric = (value: number): boolean => /^\d+$/.test(String(value))

    const onFormChange = (event: any) => {
        if (event?.target?.value || event) {
            setSubmit(true)
        }
    }

    const handleOptedPrasad = () => {
        setSubmit(true)
        setEnableAddress(!enableAddress)
    }

    const closeOrderFormModal = () => {
        setTabs({
            memberData: false,
            address: false,
            whatsappNumber: false,
        })
        setSubmit(false)
        closeItemCreation()
    }

    const checkVideoUrl = (): boolean => {
        return form?.getFieldValue('items')?.some((item: any) => item?.meta_data?.video_url?.[0])
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            +91
        </Form.Item>
    )

    const handleCheckbox = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            const mobileNumber = form?.getFieldValue('booking_member_data')?.[0]?.mobile_number
            if (mobileNumber?.length !== 10) {
                message.warning('Mobile number should be 10 digits')
            } else {
                const bookingMemberData = form.getFieldValue('booking_member_data')
                const updatedBookingMemberData = [
                    {
                        ...bookingMemberData[0],
                        whatsapp_number: mobileNumber
                    },
                    ...bookingMemberData.slice(1)
                ]

                form.setFieldsValue({
                    booking_member_data: updatedBookingMemberData
                })
            }
        }
    }
    const clearFieldsValue = () => {
        setTabs((prevTabs: any) => ({
            ...prevTabs,
            memberData: false,
            whatsappNumber: false,
            address: false
        }))
        closeItemCreation()
    }

    const updateActiveTab = () => {
        setSubmit(false)
        if (tabs?.memberData) {
            setTabs((prevTabs: any) => ({
                ...prevTabs,
                memberData: false,
                whatsappNumber: false,
                address: true
            }))
        }
        if (tabs?.address) {
            if (monetisationRoles?.user_type === 'super_admin') {
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    memberData: false,
                    whatsappNumber: true,
                    address: false,
                }))
            } else {
                clearFieldsValue()
            }
        }
        if (tabs?.whatsappNumber) {
            clearFieldsValue()
        }
    }

    const updateRecord = async (order: any, action: string) => {
        const payload = orderPayload(order, action, initialValues?.country_code)
        if (payload?.error && payload?.error?.code === 'number_mismatch') {
            message.error('Mobile number and WhatsApp number are not the same!')
            return
        }
        const orderId = form?.getFieldValue('id')
        try {
            await monetisationPujaApi.orderApi.updateOrder(
                orderId,
                actionMapping?.[action],
                {
                    ...payload,
                    ...(action === 'memberData' && { country_code: initialValues?.country_code }),
                    user_email: userProfile?.email!
                }
            )
            message.success('Order has been successfully updated!')
            updateActiveTab()
            handleUpdateOrders()
        } catch (e) {
            message.error('Error while updating order!')
            console.log(e)
        }
    }

    return (
        <>
            <Drawer
                title="Edit Order Details"
                placement='right'
                onClose={closeOrderFormModal}
                visible={showItemCreation}
                destroyOnClose={true}
                size='large'
                width={600}
            >
                <div style={{ display: 'flex', width: '100%', justifyContent: 'start' }}>
                    <Radio.Group
                        defaultValue={initialValues?.service_type === 'memberData'}
                        onChange={onChangeRadioEvent}
                        style={{ marginBottom: 16 }}
                        buttonStyle="solid"
                        optionType="button"
                        value={Object?.keys(tabs)?.find((key) => tabs[key])}
                    >
                        {initialValues?.service_type === 'pooja' &&
                            <Radio.Button value="memberData">Member data</Radio.Button>
                        }
                        {(initialValues?.service_type === 'pooja') &&
                            <Radio.Button value="address">Address</Radio.Button>
                        }
                        {(initialValues?.service_type === 'pooja' && userRole === 'super_admin') &&
                            <Radio.Button value="whatsappNumber">Whatsapp Number</Radio.Button>
                        }
                    </Radio.Group>
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'start' }}>
                    <Form
                        form={form}
                        layout='vertical'
                        initialValues={{ ...initialValues }}
                        onFinish={(values) => {
                            updateRecord(values, Object.keys(tabs).find(key => tabs?.[key] === true) ?? '')
                        }}
                        style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start' }}
                    >
                        {(tabs?.memberData && initialValues?.service_type === 'pooja') &&
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                <Form.Item
                                    label='Member data'
                                    style={{ width: '100%' }}
                                >
                                    <Row style={{ width: '100%' }}>
                                        <Form.List name={['booking_member_data']}>
                                            {(fields) => {
                                                return (
                                                    <>
                                                        <Row style={{ width: '100%' }}>
                                                            {fields.map((field: any, index: number) => (
                                                                <>
                                                                    {
                                                                        index === 0 && (
                                                                            <div key={field?.key} style={{ width: '100%' }}>
                                                                                <div
                                                                                    key={field?.key}
                                                                                    style={{
                                                                                        border: '1px dashed #e0dcdc',
                                                                                        marginBottom: '10px',
                                                                                        padding: '10px',
                                                                                        width: '100%'
                                                                                    }}
                                                                                >
                                                                                    <Row justify="space-between" style={{ width: '100%', display: 'flex' }}>
                                                                                        <Form.Item
                                                                                            label={`Full Name`}
                                                                                            name={[field.name, 'full_name']}
                                                                                            labelCol={{ span: 18 }}
                                                                                            wrapperCol={{ span: 18 }}
                                                                                            style={{ width: '50%', marginBottom: '0px !important' }}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: (index === 0) ? true : false,
                                                                                                    message: 'Enter full name',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <Input placeholder='Enter name' style={{ width: '250px' }} onChange={(e) => onFormChange(e)} />
                                                                                        </Form.Item>
                                                                                        <Form.Item
                                                                                            label={`Gotra`}
                                                                                            name={[field.name, 'gotra']}
                                                                                            labelCol={{ span: 18 }}
                                                                                            wrapperCol={{ span: 18 }}
                                                                                            style={{ width: '50%' }}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: 'Enter gotra',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <Input placeholder='Enter Gotra' style={{ width: '250px' }} onChange={(e) => onFormChange(e)} />
                                                                                        </Form.Item>
                                                                                        <Form.Item
                                                                                            noStyle
                                                                                            shouldUpdate={(prevValues, currentValues) => true}
                                                                                        >
                                                                                            {({ getFieldValue }) => (
                                                                                                getFieldValue("country_code") === "IN" &&
                                                                                                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                                                                                    <Form.Item
                                                                                                        label={`Mobile`}
                                                                                                        name={[field.name, 'mobile_number']}
                                                                                                        labelCol={{ span: 18 }}
                                                                                                        wrapperCol={{ span: 18 }}
                                                                                                        style={{ width: '50%', marginBottom: '10px' }}
                                                                                                        rules={[
                                                                                                            {
                                                                                                                required: true,
                                                                                                                message: 'Enter Mobile Number',
                                                                                                            },
                                                                                                        ]}
                                                                                                    >
                                                                                                        <Input addonBefore={prefixSelector} placeholder='Enter Mobile number' minLength={10} maxLength={10} style={{ width: '250px' }} onChange={onFormChange} />
                                                                                                    </Form.Item>
                                                                                                    <Form.Item
                                                                                                        label={`Whatsapp Number`}
                                                                                                        name={[field.name, 'whatsapp_number']}
                                                                                                        labelCol={{ span: 18 }}
                                                                                                        wrapperCol={{ span: 18 }}
                                                                                                        style={{ width: '50%', marginBottom: '10px' }}
                                                                                                        rules={[
                                                                                                            {
                                                                                                                required: true,
                                                                                                                message: 'Enter Whats app Number',
                                                                                                            },
                                                                                                        ]}
                                                                                                    >
                                                                                                        <Input addonBefore={prefixSelector} placeholder='Enter Whatsapp number' minLength={10} maxLength={10} style={{ width: '250px' }} onChange={onFormChange} />
                                                                                                    </Form.Item>
                                                                                                    <Form.Item
                                                                                                        label={``}
                                                                                                        name={[field.name, 'same_as_mobile_number']}
                                                                                                        labelCol={{ span: 18 }}
                                                                                                        wrapperCol={{ span: 18 }}
                                                                                                        style={{ width: '50%', marginBottom: '0px' }}
                                                                                                        rules={[
                                                                                                            {
                                                                                                                required: false,
                                                                                                            },
                                                                                                        ]}
                                                                                                        valuePropName='checked'
                                                                                                    >
                                                                                                        <Checkbox onChange={handleCheckbox}>Same as Mobile number</Checkbox>
                                                                                                    </Form.Item>
                                                                                                </Row>
                                                                                            )}
                                                                                        </Form.Item>
                                                                                    </Row>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {
                                                                        index > 0 && (
                                                                            <div key={field?.key} style={{ width: '100%' }}>
                                                                                <div
                                                                                    key={field?.key}
                                                                                    style={{
                                                                                        border: '1px dashed #e0dcdc',
                                                                                        marginBottom: '10px',
                                                                                        padding: '10px',
                                                                                        width: '100%'
                                                                                    }}
                                                                                >
                                                                                    <Row justify="space-between" style={{ width: '100%', display: 'flex' }}>
                                                                                        <Form.Item
                                                                                            label={`Full Name`}
                                                                                            name={[field.name, 'full_name']}
                                                                                            labelCol={{ span: 18 }}
                                                                                            wrapperCol={{ span: 18 }}
                                                                                            style={{ width: '50%', marginBottom: '0px' }}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: (index === 0) ? true : false,
                                                                                                    message: 'Enter full name',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <Input placeholder='Enter name' style={{ width: '100% ' }} onChange={(e) => onFormChange(e)} />
                                                                                        </Form.Item>
                                                                                    </Row>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </>
                                                            ))}
                                                        </Row>
                                                    </>
                                                )
                                            }}
                                        </Form.List>
                                    </Row>
                                </Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    style={{ width: '30%', marginBottom: '20px' }}
                                    disabled={!submit}
                                >
                                    Submit
                                </Button>
                            </div>
                        }
                        {
                            (tabs?.address) &&
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                    <Form.Item
                                        name='is_opted_for_prasad'
                                        label={`Do you want Puja's prasad?`}
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        valuePropName='checked'
                                    >
                                        <Switch
                                            onChange={handleOptedPrasad}
                                            disabled={initialValues?.is_opted_for_prasad}
                                            checked={initialValues?.is_opted_for_prasad || initialValues?.is_deliverable}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name='is_deliverable'
                                        label={`Deliverable (Add-on)`}
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        valuePropName='checked'
                                    >
                                        <Switch
                                            disabled={true}
                                            checked={initialValues?.is_deliverable}
                                        />
                                    </Form.Item>
                                </div>
                                {(enableAddress) &&
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                        <Form.Item
                                            label='House Info'
                                            name={['booking_address', 'house_info']}
                                            labelCol={{ span: 28 }}
                                            wrapperCol={{ span: 28 }}
                                            rules={[{ required: true, message: 'Enter House Info' }]}
                                            style={{ width: '60%' }}
                                        >
                                            <Input
                                                placeholder='Enter House Info'
                                                onChange={(e) => onFormChange(e)}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='Area Info'
                                            name={['booking_address', 'area_info']}
                                            labelCol={{ span: 28 }}
                                            wrapperCol={{ span: 28 }}
                                            rules={[{ required: true, message: 'Enter Area Info' }]}
                                            style={{ width: '60%' }}
                                        >
                                            <Input
                                                placeholder='Enter Area Info'
                                                onChange={(e) => onFormChange(e)}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='Landmark'
                                            name={['booking_address', 'landmark']}
                                            labelCol={{ span: 28 }}
                                            wrapperCol={{ span: 28 }}
                                            rules={[{ required: true, message: 'Enter Landmark' }]}
                                            style={{ width: '60%' }}
                                        >
                                            <Input
                                                placeholder='Enter Landmark'
                                                onChange={(e) => onFormChange(e)}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='Pincode'
                                            name={['booking_address', 'pin_code']}
                                            labelCol={{ span: 28 }}
                                            wrapperCol={{ span: 28 }}
                                            rules={[{ required: true, message: 'Enter Pincode' }]}
                                            style={{ width: '60%' }}
                                        >
                                            <Input
                                                placeholder='Enter Pincode'
                                                onChange={(e) => onFormChange(e)}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='City'
                                            name={['booking_address', 'city_name']}
                                            labelCol={{ span: 28 }}
                                            wrapperCol={{ span: 28 }}
                                            rules={[{ required: true, message: 'Enter City' }]}
                                            style={{ width: '60%' }}
                                        >
                                            <Input
                                                placeholder='Enter City'
                                                onChange={(e) => onFormChange(e)}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='State'
                                            name={['booking_address', 'state_name']}
                                            labelCol={{ span: 28 }}
                                            wrapperCol={{ span: 28 }}
                                            rules={[{ required: true, message: 'Enter State' }]}
                                            style={{ width: '60%' }}
                                        >
                                            <Input
                                                placeholder='Enter State'
                                                onChange={(e) => onFormChange(e)}
                                            />
                                        </Form.Item>
                                    </div>
                                }
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    style={{ width: '30%', marginBottom: '20px' }}
                                    disabled={!submit}
                                >
                                    Submit
                                </Button>
                            </div>
                        }
                        {
                            (tabs?.whatsappNumber) &&
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Form.Item
                                    label='Whatsapp Number'
                                    name='booking_whatsapp_number'
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    rules={[{ required: true, message: 'Enter Whatsapp Number' }, ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value) {
                                                if (isNumeric(value)) {
                                                    return Promise.resolve()
                                                } else {
                                                    return Promise.reject(new Error('Please enter valid Whatsapp Number'))
                                                }
                                            }
                                        },
                                    })]}
                                    style={{ width: '60%', marginTop: '10px' }}
                                >
                                    <Input addonBefore={prefixSelector} placeholder='Enter Whatsapp number' minLength={10} maxLength={10} style={{ width: '250px' }} onChange={onFormChange} />
                                </Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    style={{ width: '30%', marginBottom: '20px' }}
                                    disabled={!submit}
                                >
                                    Submit
                                </Button>
                            </div>
                        }
                    </Form>
                </div>
            </Drawer >
        </>
    )
}

export default OrderDetailsForm
