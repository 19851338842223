import { getLanguageNameFromValue } from '@/utils/constants'

import { City, God, Temple } from '@a4b/api/src/modules/Content/Temples/types'

export const parseTempleListData = (temples: Temple[]) => {
  const templeList = temples?.map(temple => ({
    id: temple?.id,
    reference_name: temple?.reference_name,
    is_active: temple?.is_active,
    priority: temple?.priority,
    temple_code: temple?.temple_code,
    languages: temple?.languages?.map(language =>
      getLanguageNameFromValue(language),
    ),
    lang: temple?.languages?.[0],
    is_avatar_enabled: temple?.is_avatar_enabled,
    deeplink: temple?.deeplink,
    avatar_details: temple?.avatar_details,
  }))

  return templeList
}

export const parseCityList = (cities: City[]) => {
  const cityList = cities.map(city => ({
    label:
      city?.translations?.en?.city_name || city?.translations?.hi?.city_name,
    value: city?.city_code,
  }))
  return cityList
}

export const parseGodList = (gods: God[]) => {
  const godList = gods.map(god => ({
    label: god?.code,
    value: god?.id,
    thumbnail: god?.thumbnails?.url
  }))
  return godList
}
