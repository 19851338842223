import { throwError } from "../../../utils/helper";

type Item = {
  image_attachment_id: string;
};

class MenuAppVersion {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items: Item[] = [];

  getSerializedData = (widgetFormData: MenuAppVersion) => {
    let itemModified = widgetFormData?.items?.map((item: Item) => {
      return {
        ...item,
      };
    });
    widgetFormData.items = itemModified;
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: MenuAppVersion) => {
    return widgetFormData;
  };

  attachmentValidator = async (rule: any, value: any) => {
    if (!value) throwError("Image attachment required.");
  };
}
export default MenuAppVersion;
export const MenuAppVersionInstance = new MenuAppVersion();
