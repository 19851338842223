export const Typography = {
  JIO_H1: 20,
  JIO_H2: 20,
  JIO_H3: 20,
}
export enum SPACING {
  'ZERO' = '0px',
  'XS' = '4px',
  'S' = '8px',
  'M' = '12px',
  'L' = '16px',
  'XL' = '24px',
  '2XL' = '32px',
  '3XL' = '48px',
  '4XL' = '64px',
  '5XL' = '80px',

  'JIO_S' = '17px',
  'JIO_M' = '34px',
}

export enum CURVE {
  'ZERO' = '0px',
  'XS' = '2px',
  'S' = '4px',
  'M' = '8px',
  'L' = '12px',
  'XL' = '16px',
  'FULL' = '24px',
}
export enum BottomButtonClick {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}
export enum AarthiMenuType {
  FLOWER = 'FLOWER',
  SHANK = 'SHANK',
  THAALI = 'THAALI',
  BELL = 'BELL',
}
export enum MoreScreenMenu {
  MUSIC = 'MUSIC',
  PANCHANG = 'PANCHANG',
  GODS = 'GODS',
  RASHIFAL = 'RASHIFAL',
  HORA = 'HORA',
  CHOGHADIYA = 'CHOGHADIYA',
  ABOUT_US = 'ABOUT_US',
  FAQ = 'FAQ',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
}

export enum TIME_INFO_TYPE {
  GOOD = 'GOOD',
  MODERATE = 'MODERATE',
  BAD = 'BAD',
}
export interface PanchangForm {
  city: string
  date: string
}

export type PanchangRequest = {
  latitude?: string
  longitude?: string
  city?: string
  userId?: string
  date?: string
}
export type PanchangV2Request = {
  lat?: string
  lng?: string
  district_slug?: string
  mini?: boolean
  date?: string
  month?: string
}

export enum Mode {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum HORA_TYPE {
  VIVARAN = 'VIVARAN',
  ANUKUL = 'ANUKUL',
  PRATHIKUL = 'PRATHIKUL',
  INFO = 'INFO',
}

export enum KEY_TYPE {
  UP_KEY = 'UpKey',
  DOWN_KEY = 'DownKey',
  LEFT_KEY = 'LeftKey',
  RIGHT_KEY = 'RightKey',
  ENTER_KEY = 'EnterKey',
  SOFT_LEFT_KEY = 'SoftLeftKey',
  SOFT_RIGHT_KEY = 'SoftRightKey',
  CONTEXT_MENU_KEY = 'ContextMenuKey',
  BACKSPACE_KEY = 'BackspaceKey',
  DEFAULT = 'DEFAULT',
}
export enum AUDIO_TYPE {
  SHANKH = 'SHANKH',
  BELL = 'BELL',
}

export enum EVENT_TYPE {
  USER = 'user',
  APP = 'app',
}
export enum SUB_TYPE {
  CLICK = 'click',
  IMPRESSION = 'impression',
  TIMESPENT = 'timespent',
  SYSTEM = 'system',
  EMPTY = '',
}
export enum SOURCE {
    GOD_SECTION = 'godSelection',
    INTERNAL = 'internal',
    MANDIR = 'mandir',
    AARTI = 'aarti',
    MUSIC = 'music',
    MORE_MENU = 'moreMenu',
    PANCHANG = 'calendar',
    ALL_PLAYLIST = 'allPlaylist',
    RASHIFAL = 'rashifal',
    HORA = 'hora',
    CHOGADIYA = 'choghadiya',
    CALENDAR = 'calendar',
    LANDING = 'landing',
    CitySelection = 'city_selection',
    DateSelection = 'date_selection',
    ABOUT_US = 'aboutus',
    EPUJA = 'epuja',
    MENU = 'menu',
    EPUJA_L2 = 'epuja_l2',
    EPUJA_CART_REVIEW = 'epuja_cart_review',
    EPUJA_BOOKING_HISTORY_L2 = 'epuja_booking_history_l2',
    EPUJA_BOOKING_HISTORY_L1 = 'epuja_booking_history_l1',
    EPUJA_FEEDBACK = 'epuja_feedback',
    PUJA_GALLERY = 'puja_gallery',
    SANKALP_FORM_PAYMENT_SUCCESS = 'sankalp_form_payment_success',
    SANKALP_FORM = 'sankalp_form',
    CHADHAVA = "CHADHAVA"
}

export enum PLAYER_STATE {
  PLAYING = 'playing',
  PAUSED = 'paused',
  STOPPED = 'stopped',
}

export enum CAROUSEL_CARD_NAME {
  PANCHANG = 'panchang',
  AARTI = 'aarti',
  BHAJAN = 'bhajan',
}
export enum SOCIAL_LINK_NAME {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
}

export enum WeightType {
  BOLD = 'bold',
  NORMAL = 'normal',
  THIK_100 = 100,
  THIK_200 = 200,
  THIK_300 = 300,
  THIK_400 = 400,
  THIK_500 = 500,
  THIK_600 = 600,
  THIK_700 = 700,
  THIK_800 = 800,
  THIK_900 = 900,
}

export enum ButtonState {
  SELECTED = 'SELECTED',
  DESELECTED = 'DESELECTED',
}

export enum daysOfTheWeekString {
  'सोमवार',
  'मंगलवार',
  'बुधवार',
  'गुरुवार',
  'शुक्रवार',
  'शनिवार',
  'रविवार',
}
export enum monthsName {
  'dummy',
  'जनवरी',
  'फरवरी',
  'मार्च',
  'अप्रैल',
  'मई',
  'जून',
  'जुलाई',
  'अगस्त',
  'सितम्बर',
  'अक्टूबर',
  'नवम्बर',
  'दिसम्बर',
}
