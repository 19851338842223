import React from "react";
import { Button, Col, Form, Input, Row, Select, TimePicker } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import Panchang from "@/entities/feeds/Widgets/Panchang";
import { PanchangDays, TagsColor } from "@/utils/utilProps";

import DeletableContainer from "../deletableContainer/DeletableContainer";
import HeaderTags from "../headerTags/HeaderTags";
import { PanchangInstance } from "@/entities/feeds/Widgets";

interface Props {
  contentInstance: PanchangInstance;
}
const PanchangWidget: React.FC<Props> = ({ contentInstance }) => {
  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            label="Header"
            name={["content", "header"]}
          >
            <Input placeholder="Header" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <HeaderTags widgetClass={contentInstance} />
        </Col>
      </Row>
      <Form.List name={["content", "items"]}>
        {(fields, { add, remove, move }) => {
          return (
            <div>
              {fields.map((field: any, index: number) => {
                return (
                  <Form.Item key={field.key}>
                    <DeletableContainer
                      closeHandler={() => {
                        remove(field.name);
                      }}
                      itemText={`Item Attribute : ${index + 1}`}
                      showItemTextInBold={true}
                      moveFunction={move}
                      fields={fields}
                      currentIndex={index}
                      fieldsLength={fields.length}
                      move={move}
                      add={add}
                    >
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                            required={true}
                            label="Day"
                            name={[field.name, "day"]}
                            rules={[
                              {
                                validator: contentInstance.dayValidator,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Panchang Day"
                              style={{
                                width: "100%",
                              }}
                              options={PanchangDays}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                            required={true}
                            label="CTA Text"
                            name={[field.name, "cta_text"]}
                            rules={[
                              {
                                validator: contentInstance.ctaTextValidator,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                            required={true}
                            label="Cyclic Duration"
                            name={[field.name, "cyclic_duration"]}
                            rules={[
                              {
                                validator:
                                  contentInstance.cyclicDurationValidator,
                              },
                            ]}
                          >
                            <TimePicker
                              placeholder="Seconds"
                              format="ss"
                              showHour={false}
                              showMinute={false}
                              showNow={false}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.List name={[field.name, "cyclic_tags"]}>
                        {(cyclicTags, { add, remove, move }) => {
                          return (
                            <div>
                              {cyclicTags.map((field: any, index2: number) => {
                                return (
                                  <Form.Item key={field.key}>
                                    <DeletableContainer
                                      closeHandler={() => {
                                        remove(field.name);
                                      }}
                                      itemText={`Cyclic tag : ${index2 + 1}`}
                                      moveFunction={move}
                                      fields={cyclicTags}
                                      currentIndex={index2}
                                      isInnerContainer={true}
                                      fieldsLength={cyclicTags.length}
                                      move={move}
                                      add={add}
                                    >
                                      <Row>
                                        <Col span={12}>
                                          <Form.Item
                                            {...field}
                                            labelCol={{ span: 6 }}
                                            wrapperCol={{ span: 18 }}
                                            required={true}
                                            label="Text"
                                            name={[field.name, "text"]}
                                            rules={[
                                              {
                                                validator:
                                                  contentInstance.textTagValidator,
                                              },
                                            ]}
                                            style={{ marginBottom: "0px" }}
                                          >
                                            <Input />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            {...field}
                                            label="Color"
                                            name={[field.name, "color"]}
                                            labelCol={{ span: 6 }}
                                            wrapperCol={{ span: 18 }}
                                            style={{ marginBottom: "0px" }}
                                          >
                                            <Select
                                              style={{
                                                width: "100%",
                                              }}
                                              options={TagsColor}
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </DeletableContainer>
                                  </Form.Item>
                                );
                              })}
                              <Form.Item
                                noStyle
                                name="cyclic_tags_validation"
                                rules={[
                                  {
                                    validator: (rule: any, value: any) => {
                                      return contentInstance.cyclicTagsCountValidator(
                                        rule,
                                        value,
                                        cyclicTags
                                      );
                                    },
                                  },
                                ]}
                              >
                                <Row justify="end">
                                  <Col span={24}>
                                    <Row justify="end">
                                      <Col span={4}>
                                        <Button
                                          onClick={() => {
                                            add();
                                          }}
                                          type="dashed"
                                          icon={<PlusCircleOutlined />}
                                          size="middle"
                                        >
                                          Add Cyclic tags
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Form.Item>
                            </div>
                          );
                        }}
                      </Form.List>
                    </DeletableContainer>
                  </Form.Item>
                );
              })}

              <Form.Item>
                <Row justify="end">
                  <Col span={24}>
                    <Row justify="end">
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            add();
                            // addItem();
                          }}
                          type="dashed"
                          icon={<PlusCircleOutlined />}
                          size="middle"
                        >
                          Add Item Attributes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
              <div style={{ height: "20px" }}></div>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

export default PanchangWidget;
