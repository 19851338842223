import React, { useMemo } from 'react';
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'antd/dist/antd.css';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { AudioTag } from '@a4b/api/src/modules/Content/Audio/types';
import { Button, Form, Input, Typography } from 'antd';
import { SocialScreenContainer } from '@a4b/ui/src/admin';
import { CommunityTags } from '@/../../../packages/a4b-api/src/modules/Social/types';
import { openNotification } from '@/utils';




const defaultPagination = {
  current: 1,
  pageSize: 50,
  total: -1
}


// const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
//   notification[type]({
//     message: title,
//     description
//   });
// };

const SocialContentTags = () => {
  const navigate = useNavigate();
  const { networkInstance, userProfile } = useAppContext();
  const [showDrawer, setShowDrawer] = useState(false);
  const [tags, setTags] = useState<CommunityTags[]>([])
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
  const [form] = Form.useForm();

  const loadContentTags = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.communityApi.getContentTags();
      setTags(res.data.data.tags);
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.communityApi])

  useEffect(() => {
    loadContentTags();
  }, [loadContentTags])

  const handleDrawerClose = useCallback(() => {
    setShowDrawer(false);
  }, [setShowDrawer])

  const handleDrawerOpen = useCallback(() => {
    setShowDrawer(true);
  }, [setShowDrawer])

  const createContentTag = useCallback(async (values) => {
    const payload = {
      code: values.code,
      name: values.name,
      discription: values.discription
    }
    try {
      await networkInstance.clientWithHeaders.communityApi.createTag(payload)
      openNotification('success', { message: 'Content tag created successfully!' })
      form.resetFields();
      loadContentTags();
    } catch (error: any) {
      openNotification('error', { message: 'Content tag creation failed!', description: error.toString(), })
    }
  }, [form, loadContentTags, networkInstance.clientWithHeaders.communityApi])

  const drawerContent = useMemo(() => {
    return <Form
      form={form}
      layout="vertical"
      onFinish={createContentTag}
    //  initialValues={{ requiredMarkValue: requiredMark }}
    //  onValuesChange={onRequiredTypeChange}
    //  requiredMark={requiredMark}
    >
      <Form.Item
        label="Code"
        name="code"
        rules={[{ required: true, message: 'Please input code!', max: 70 }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Name of tag"
        name="name"
        rules={[{ required: true, message: 'Please input name!', max: 70 }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Tag description"
        name="description"
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  }, [createContentTag, form])

  const columns = useMemo(() => [
    {
      title: ' Name',
      dataIndex: 'name',
    },
    {
      title: ' Code',
      dataIndex: 'code',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: () => <Button type="link" disabled> Edit</Button>,
    },
  ], []);

  const drawerProps = useMemo(() => ({ title: 'Create content tag', visible: showDrawer, onClose: handleDrawerClose }), [handleDrawerClose, showDrawer])

  return (
    <SocialScreenContainer
      title={'Community tags'}
      drawerProps={drawerProps}
      drawerContent={drawerContent}
      tableProps={{ columns, dataSource: tags }}
      extra={<Button onClick={handleDrawerOpen}> Create content tag </Button>}
    >
    </SocialScreenContainer>
  )
}

export default withDefaultLayout(SocialContentTags)
