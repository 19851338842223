import { Button, Popconfirm, Switch, TableColumnType, TablePaginationConfig, TableProps } from "antd";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { useAppContext } from "@/components/AppContext";

export default function useTableConfig<T>(
  getActiveRechargePackages: () => Promise<void>,
  setRechargePackageId: React.Dispatch<React.SetStateAction<string | undefined>>,
  onUpdateRechargePackage: () => void,
  dataSource: T[],
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
): TableProps<any[]> {

  const { networkInstance } = useAppContext();


  const columns: TableColumnType<any>[] = [
    {
      title: 'S No',
      dataIndex: 'id',
      render(value, record, index) {
        return index + 1
      },
      width: 70,
      fixed: true
    },
    {
      title: 'Code',
      dataIndex: 'code',
      fixed: true
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Recharge Amount',
      dataIndex: 'recharge_amount',
    },
    {
      title: 'Offer Amount',
      dataIndex: 'offer_amount',
    },
    {
      title: 'Recharge Type',
      dataIndex: 'recharge_type',
      render(value, record, index) {
        return value === 'special' ? <p style={{ "color": "brown", "fontWeight": "bold" }}>Special</p> : <p>Default</p>
      },
    },
    {
      title: 'Language',
      dataIndex: 'language',
    },
    {
      title: 'Language Mode',
      dataIndex: 'language_mode',
    },
    {
      title: 'Order',
      dataIndex: 'order',
    },
    {
      title: 'Campaign Id',
      dataIndex: 'campaign_id',
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
    },
    {
      title: 'Updated By',
      dataIndex: 'updated_by',
    },
    {
      title: 'Actions',
      dataIndex: 'active',
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Switch style={{ width: '90px' }} checked={record.active} checkedChildren={"Deactive"} unCheckedChildren="Activate" onClick={async () => {
            await networkInstance.clientWithHeaders.wallet.updateRechargePackagePayload(record.id, { ...record, active: !record.active });
            getActiveRechargePackages();
          }} />
          <Button type="link" onClick={() => { onUpdateRechargePackage(); setRechargePackageId(record.id) }} >Edit</Button>

          <Popconfirm
            placement="topLeft"
            title={() => { return <div style={{ fontSize: 16 }}><br /> Click confirm to <strong>Delete</strong> Recharge Package continue!! <br></br></div> }}
            onConfirm={async () => {
              await networkInstance.clientWithHeaders.wallet.deleteRechargePackagePayload(record.id);
              getActiveRechargePackages();
            }}
            okText="Confirm"
            cancelText="Cancel"
          >
            <Button type="link" style={{ "color": "red" }}>Delete</Button>
          </Popconfirm>
        </div >
      },
      fixed: 'right',
    }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  //@ts-ignore
  return { columns, dataSource, pagination, onChange: tableOnChange, scroll: { x: 2400 } }
}