import { Definition } from "@a4b/api/src/modules/Events/types";
import { Button, Tag, Radio, Table } from "antd";
import styled from "styled-components";
import { DRAWER_TYPE } from "../../hooks/useDrawerConfig";
import { EVENT_STATE_COLOR_CODES, EVENT_STATUS, PROPERTY_DATA_TYPES } from "../../utils/constants";
import { prettifyCamelCase } from "../../../../../../utils/helper";
import { renderDate } from "../../../../../../components/HelperComponents";
import ChangeLog from "../ChangeLog";
import { useState } from "react";
import { title } from "process";
import { render } from "@testing-library/react";

const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: #dddddd;
  }
`
const StyledTable = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
`

const StyledTd = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`
const StyledTh = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`

const PropertyCell = styled.div`
  margin: 10px;
  flex: 1 0 calc(33.33% - 10px);
  font-size: 12px;
  font-weight: 500;
  max-width: calc(33.33% - 20px); 
  display: flex;
  gap: 5px;
  padding-bottom: 5px;
  flex-wrap: wrap;
  &>span.property{
    color: #595959;
    font-weight: 400;
  }
`

const StyledCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid #f0f0f0;
`

const PropertyContainer = styled.div`
  width: 100%;
  margin: 60px 0px;
`

const StyledHeading = styled.h3`
  font-size: 17px;
  color: #595959;
`

enum FILTER_GROUP {
    ALL = 'ALL',
    EVENT = "EVENT",
    EVENT_PROPERTIES = "EVENT_PROPERTIES",
    CHANGE_LOG = 'CHANGE_LOG'
}

interface PreviewProps {
    defination: Definition
    setActiveDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | undefined>>
}
const EventPreview = function ({ defination, setActiveDrawer }: PreviewProps) {
    const [filterGroup, setFilterGroup] = useState(FILTER_GROUP.ALL);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '30%'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: "40%"
        },
        {
            title: 'Is required',
            dataIndex: 'is_required',
            render: (value: boolean) => value ? 'Yes' : 'No',
            key: 'is_required',
            width: "10%"
        },
        {
            title: 'Data type',
            dataIndex: 'data_type',
            key: 'data_type',
            width: "10%"
        },
        {
            title: 'Options',
            dataIndex: 'options',
            render: (options: string[] | undefined) => options?.map((option) => <Tag>{option}</Tag>),
            key: 'options',
            width: "10%"
        },
    ];

    const subPropertyColumns = [...columns];
    columns[columns.length - 1] = {
        ...columns[columns.length - 1],
        width: "9%"
    };

    return <div style={{ position: 'relative' }}>
        <Radio.Group value={filterGroup} onChange={(event: any) => { setFilterGroup(event?.target.value) }} style={{ marginBottom: 16 }}>
            <Radio.Button value={FILTER_GROUP.ALL}>All</Radio.Button>
            <Radio.Button value={FILTER_GROUP.EVENT}> Event </Radio.Button>
            <Radio.Button value={FILTER_GROUP.EVENT_PROPERTIES}>Event Properties</Radio.Button>
            <Radio.Button value={FILTER_GROUP.CHANGE_LOG}>Changelog</Radio.Button>
        </Radio.Group>
        <Button style={{ position: 'fixed', right: 40, top: 10 }} type="primary" disabled={defination.status !== EVENT_STATUS.DRAFT} onClick={() => setActiveDrawer(DRAWER_TYPE.EDIT_DEFINATIONS)}> Edit </Button>
        <Button style={{ position: 'fixed', right: 120, top: 10 }} type="primary" disabled={defination.status !== EVENT_STATUS.IMPLEMENTED} onClick={() => setActiveDrawer(DRAWER_TYPE.CHECK_TICKETS)}> Check Tickets </Button>
        <StyledHeading style={{ marginTop: '30px' }}> Event info </StyledHeading>
        {
            (filterGroup === FILTER_GROUP.ALL || filterGroup === FILTER_GROUP.EVENT) &&
            <StyledCard>
                {Object.keys(defination).map((key) => {
                    switch (key) {
                        case 'properties':
                            return null
                        case 'created_at':
                            return <PropertyCell><span className="property">{prettifyCamelCase(key)}:</span> {renderDate(defination[key as keyof Definition])}</PropertyCell>
                        case 'updated_at':
                            return <PropertyCell><span className="property">{prettifyCamelCase(key)}:</span>  {defination[key as keyof Definition] ? renderDate(defination[key as keyof Definition]) : ''}</PropertyCell>
                        case 'id':
                            return null;
                        case 'status':
                            // @ts-ignore
                            return <PropertyCell><span className="property">{prettifyCamelCase(key)}:</span> <Tag color={EVENT_STATE_COLOR_CODES[defination[key]]} >{defination[key as keyof Definition]}</Tag> </PropertyCell>
                        case 'tags':
                            return <PropertyCell><span className="property">{prettifyCamelCase(key)}:</span> {defination[key as keyof Definition]?.map((tag: string) => (<Tag>{tag}</Tag>))}</PropertyCell>
                        case 'destinations':
                            return <PropertyCell><span className="property">{prettifyCamelCase("Backend Destinations")}:</span> {defination[key as keyof Definition]?.join(", ")}</PropertyCell>
                        case 'created_by':
                        case 'description':
                        case 'event_type':
                        case 'group':
                        case 'name':
                        case 'platform_code':
                        case 'platform_type':
                        case 'updated_by':
                        case 'version':
                        case 'producer':
                            return <PropertyCell><span className="property">{prettifyCamelCase(key)}:</span> {defination[key as keyof Definition]}</PropertyCell>
                        default:
                            return null;
                    }

                })}

            </StyledCard>
        }
        {
            (filterGroup === FILTER_GROUP.ALL || (filterGroup === FILTER_GROUP.EVENT_PROPERTIES)) && <PropertyContainer>
                <StyledHeading> Event properties </StyledHeading>
                <Table
                    dataSource={defination.properties?.map((property, index) => ({ ...property, key: property.id }))}
                    columns={subPropertyColumns}
                    bordered
                    expandable={{
                        expandedRowRender: (record) => {
                            // @ts-ignore
                            return <div ><Table size="small" style={{ paddingLeft: "35px" }} bordered showHeader={false} dataSource={record.sub_properties} columns={columns} pagination={false}></Table></div>
                        },
                        rowExpandable: (record) => record.data_type === "OBJECT" || record.data_type === "ARRAY_OBJECT",
                        defaultExpandAllRows: true
                    }}
                />
            </PropertyContainer>
        }
        {
            (filterGroup === FILTER_GROUP.ALL || filterGroup === FILTER_GROUP.CHANGE_LOG) && <>
                <StyledHeading> Change log </StyledHeading>
                <ChangeLog definitionId={defination.id}></ChangeLog>
            </>
        }
    </div>
}
export default EventPreview;