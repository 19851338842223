import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useForm } from 'antd/lib/form/Form'

import { Button, Col, Form, Input, Row, Select, Space, Modal, Switch, message, DatePicker } from 'antd'
import styled from 'styled-components'

import S3Upload, { AllowedFormats } from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'

import { HubList, HubListItem } from '@a4b/api/src/modules/Monetisation/Puja/types'
import { CopyOutlined } from '@ant-design/icons';
import { useAppContext } from '@/components/AppContext'
import { A4B_APPS } from '@/utils/constants'
import FilterForm from '../FilterForm'

const { Option } = Select
const { TextArea } = Input
interface Props {
  onFinish: (values: any) => void
  onFinishFailed: (errorInfo: any) => void
  cdnUrl: string
  preSignUrl: string
  form: any
  initialStoreData: any
  currentStep: number
  isEditJourney: boolean
  slugState: boolean
  nextHandler: () => void
  previousHandler: () => void
  submitFormHandler: () => void
  onValuesChange: (values: any) => void
  handleCloneModal: () => void
  acceptLanguage: string
}

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 10px;
`
const tags: any = {
  en: ['Festival', 'Kundali dosha', 'Business & Finance', 'Marriage & Love', 'Career'],
  hi: ['त्योहार', 'कुंडली दोष', 'धन एवं व्यापार', 'प्यार एवं विवाह', 'करियर']
}

const StoreForm: React.FC<Props> = ({
  onFinish,
  onFinishFailed,
  cdnUrl,
  preSignUrl,
  form,
  initialStoreData,
  previousHandler,
  nextHandler,
  submitFormHandler,
  currentStep,
  isEditJourney,
  onValuesChange,
  slugState,
  handleCloneModal,
  acceptLanguage,
}) => {
  const { networkInstance, app } = useAppContext()
  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
  const navigate = useNavigate()

  const [visible, setVisible] = useState<boolean>(false)

  const [hubList, setHubList] = useState<HubList>()
  const [isLoadingHublist, setIsLoadingHublist] = useState<boolean>(false)


  const fetchHubList = useCallback(async () => {
    try {
      setIsLoadingHublist(true)
      const hubListPromise = monetisationPujaApi.hub.getHubList({ limit: 100, offset: 0 });

      const [hubListResponse] = await Promise.all([hubListPromise]);

      setHubList(hubListResponse?.data?.data);
      setIsLoadingHublist(false)
    } catch (error) {
      setIsLoadingHublist(false)
      message.warning('Error while fetching hub list or categories', 3);
      console.log(error);
    }
  }, [monetisationPujaApi.hub]);

  useEffect(() => {
    if (currentStep === 0) {
      fetchHubList();
    }
  }, [currentStep, fetchHubList]);

  return (
    <div>
      <Form
        form={form}
        layout='vertical'
        name='create store'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <Col span={24}>
          <Row>
            <Col span={8}>
              {isEditJourney && <Form.Item
                label='Store Id'
                name='store_id'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input style={{ color: 'black' }} disabled></Input>
              </Form.Item>}
              <Form.Item
                label='Select Hub'
                name='hub_id'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: 'Please select hub name!' }]}
              >
                <Select placeholder='Select hub name' loading={isLoadingHublist}>
                  {hubList?.hubs?.map((hub: HubListItem) => (
                    <Option value={hub.id} key={hub.id}>
                      {hub.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label='Name of Store(Old format)'
                name='title'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'Please input name of the temple!' },
                ]}
              >
                <Input placeholder='Enter temple name' />
              </Form.Item>
              <Form.Item
                label='Specificity'
                name='pooja_specificity'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'Please Puja Tithi or Specificity' },
                ]}
              >
                <Input placeholder='Enter Puja Tithi or Specificity' />
              </Form.Item>
              <Form.Item
                label='Name of Store(New format)'
                name='pooja_title'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'Please Title' },
                ]}
              >
                <Input placeholder='Enter Puja Title' />
              </Form.Item>
              <Form.Item
                label='Use case'
                name='pooja_usecase'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'Please Puja Use case' },
                ]}
              >
                <Input placeholder='Enter Puja Use case' />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.service_type !== currentValues.service_type
                }
              >
                {({ getFieldValue }) => (
                  getFieldValue("service_type") === "pooja" &&
                  (!slugState ? (<>
                    <Form.Item
                      label='Slug'
                      name='slug'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        { required: true, message: 'Please input slug' },
                      ]}
                    >
                      <Input placeholder='Enter Slug' onInput={(e: any) => e.target.value = e.target.value.toLowerCase()} />
                    </Form.Item>
                  </>) : (<>
                    <Form.Item
                      label='Slug'
                      name='slug'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      validateStatus="error"
                      help="Enter valid Slug"
                      rules={[
                        { required: true, message: 'Please input slug' },
                      ]}
                    >
                      <Input placeholder='Enter Slug' onInput={(e: any) => e.target.value = e.target.value.toLowerCase()} />
                    </Form.Item>
                  </>))

                )}
              </Form.Item>
              <Form.Item
                label='Short Description'
                name='short_description'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'Please input short description!' },
                ]}
              >
                <TextArea placeholder='Enter short description' />
              </Form.Item>
              <Form.Item
                label='Long Description'
                name='long_description'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'Please input long description!' },
                ]}
              >
                <TextArea placeholder='Enter long description' />
              </Form.Item>

              <Row justify='space-between' style={{ width: '100%', marginTop: '10px' }}>
                <Form.Item
                  label='City'
                  name='city'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message: 'Please input city name!' }]}
                  style={{ width: '48%' }}
                >
                  <Input placeholder='Enter city name' />
                </Form.Item>

                <Form.Item
                  label='State'
                  name='state'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message: 'Please input state name!' }]}
                  style={{ width: '48%' }}
                >
                  <Input placeholder='Enter state name' />
                </Form.Item>
              </Row>
              <Form.Item
                label={`Temple & Pandit Details`}
                name='location_details'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: false,
                    message: 'Please enter location details',
                  },
                ]}
              >
                <Button style={{ width: '100%', background: '#1890ff', color: 'white' }} onClick={() => setVisible(true)}>
                  Add temple and Pandit Details
                </Button>
                <Modal
                  title="Location details"
                  visible={visible}
                  onOk={() => setVisible(false)}
                  onCancel={() => setVisible(false)}
                  width="45vw"
                  style={{ marginTop: '-95px' }}
                >
                  <Row style={{ display: "flex", width: "40%", justifyContent: "space-between" }}>
                    <Form.Item
                      labelCol={{ span: 18 }}
                      wrapperCol={{ span: 18 }}
                      label="Enable Location "
                      name={['location_details', 'show_location_details']}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Row>

                  <h3>Temple details</h3>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={["location_details", "temple_details", "name"]}
                        label="Name"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: false, message: 'Please input your Temple name' }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={["location_details", "temple_details", "description"]}
                        label="Description"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: false, message: 'Please input your Temple description' }]}
                      >
                        <TextArea />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingLeft: '20px' }}>
                      <Form.Item
                        name={["location_details", "temple_details", "location"]}
                        label="Location"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: false, message: 'Please input your Temple location' }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label={`Temple image`}
                        name={["location_details", "temple_details", "image"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: false,
                            min: 0,
                            max: 1,
                            validator: async (rules: any, values: any) => {
                              if (values?.length < rules.min) {
                                return Promise.reject('Please upload Temple Image')
                              }
                            },
                          },
                        ]}
                        valuePropName='fileList'
                      >
                        <S3Upload
                          cdnUrl={cdnUrl}
                          preSignUrl={preSignUrl}
                          min={1}
                          max={1}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Divider /> */}
                  <h3>Pandit details</h3>
                  <Row>
                    <Col span={12}>

                      <Form.Item
                        name={["location_details", "pandit_details", "name"]}
                        label="Name"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: false, message: 'Please input your Pandit name' }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={["location_details", "pandit_details", "description"]}
                        label="Description"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: false, message: 'Please input your Pandit description' }]}
                      >
                        <TextArea />
                      </Form.Item>

                    </Col>
                    <Col span={12} style={{ paddingLeft: '20px' }}>
                      <Form.Item
                        name={["location_details", "pandit_details", "location"]}
                        label="Location"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: false, message: 'Please input your Pandit location' }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label={`Pandit image`}
                        name={["location_details", "pandit_details", "image"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: false,
                            min: 0,
                            max: 1,
                            validator: async (rules: any, values: any) => {
                              if (values?.length < rules.min) {
                                return Promise.reject('Please upload Pandit Image')
                              }
                            },
                          },
                        ]}
                        valuePropName='fileList'
                      >
                        <S3Upload
                          cdnUrl={cdnUrl}
                          preSignUrl={preSignUrl}
                          min={1}
                          max={1}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Modal>
              </Form.Item>
            </Col >
            {/* <Col span={1}></Col> */}
            <Col style={{ marginLeft: '25px' }} span={8}>
              <Form.Item
                label='Puja Image L1'
                style={{ marginBottom: '0px' }}
              >
                <div style={{
                  border: '1px dashed #e0dcdc',
                  marginRight: '0px',
                  marginBottom: '3px',
                  padding: '10px',
                }} title='Order Intro Media'>
                  <Row justify="space-between" style={{ width: '100%' }}>
                    <Form.Item
                      label={`Media type`}
                      name='coverMediaType'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please select cover media type!',
                        },
                      ]}
                      style={{}}
                    >
                      <Select style={{ width: 100 }} placeholder='Type'>
                        <Option value='lottie'>Lottie</Option>
                        <Option value='image'>Image</Option>
                        <Option value='video'>Video</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label={`Media`}
                      name='media_url'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          min: 1,
                          max: 1,
                          validator: async (rules: any, values: any) => {
                            if (values?.length < rules.min) {
                              return Promise.reject('Please upload Cover media Images')
                            }
                          },
                        },
                      ]}
                      valuePropName='fileList'
                    >
                      <S3Upload
                        cdnUrl={cdnUrl}
                        preSignUrl={preSignUrl}
                        min={1}
                        max={1}
                      />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.coverMediaType !== currentValues.coverMediaType
                      }
                    >
                      {({ getFieldValue }) => (
                        getFieldValue("coverMediaType") === "video" &&
                        <Form.Item
                          label={`Thumbnail`}
                          name='thumbnail'
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            {
                              min: 1,
                              max: 1,
                              validator: async (rules: any, values: any) => {
                                if (values?.length < rules.min) {
                                  return Promise.reject('Please upload Thumbnail Image')
                                }
                              },
                            },
                          ]}
                          valuePropName='fileList'
                        >
                          <S3Upload
                            cdnUrl={cdnUrl}
                            preSignUrl={preSignUrl}
                            min={0}
                            max={1}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Row>
                </div>
              </Form.Item>
              <Form.Item
                label='Puja Images L2'
                style={{ marginTop: '20px' }}
              >
                <div style={{
                  border: '1px dashed #e0dcdc',
                  marginRight: '0px',
                  paddingTop: '10px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }} title='Order Intro Media'>
                  <Row justify='space-between'>
                    <Form.Item
                      label={`Temple listing images`}
                      name='info_media_image'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      valuePropName='fileList'
                      rules={[
                        {
                          min: 1,
                          max: 10,
                          validator: async (rules: any, values: any) => {
                            if (values?.length < rules.min) {
                              return Promise.reject('Please upload Temple listing Images')
                            }
                          },
                        },
                      ]}
                    >
                      <S3Upload cdnUrl={cdnUrl} preSignUrl={preSignUrl} min={1} max={10} />
                    </Form.Item>
                    <Form.Item
                      label={`Temple listing video`}
                      name='info_media_video'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      valuePropName='fileList'
                      extra='Upload video'
                      rules={[
                        {
                          min: 0,
                          max: 1,
                          validator: async (rules: any, values: any) => {
                            if (values?.length < rules.min) {
                              return Promise.reject('Please upload Cover media video')
                            }
                          },
                        },
                      ]}
                    >
                      <S3Upload cdnUrl={cdnUrl} preSignUrl={preSignUrl} min={0} max={1} />
                    </Form.Item>
                  </Row>
                </div>
              </Form.Item>

              <Form.Item
                style={{ width: '100%' }}
                name='schedule_date'
                label='Schedule Date'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: false,
                    message: 'Pick Date',
                  },
                ]}
              >
                <DatePicker style={{ width: '100%' }} showTime />
              </Form.Item>

              <Form.Item
                label='Lunar calender date'
                name='hindi_calendar_date'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: false, message: 'Please enter Hindi calendar date' },
                ]}
              >
                <Input placeholder='Enter Hindi calendar date' />
              </Form.Item>

              <Row style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: '10px', }}>
                <Form.Item
                  label={`Icon URL`}
                  name='icon_url'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      min: 0,
                      max: 1,
                      validator: async (rules: any, values: any) => {
                        if (values?.length < rules.min) {
                          return Promise.reject('Please upload icon')
                        }
                      },
                    },
                  ]}
                  valuePropName='fileList'
                >
                  <S3Upload cdnUrl={cdnUrl} preSignUrl={preSignUrl} min={0} max={1} />
                </Form.Item>

                <Form.Item
                  label={`Service Type`}
                  name='service_type'
                  labelCol={{ span: 22 }}
                  wrapperCol={{ span: 22 }}
                  rules={[
                    {
                      required: false,
                      message: 'Please select service type!',
                    },
                  ]}
                >
                  <Select style={{ width: 120 }} placeholder='Service Type' disabled>
                    <Option value='pooja'>Pooja</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Show Reorder Card(L2)"
                  name='show_reorder_card_l2'
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Row>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.service_type !== currentValues.service_type
                }
              >
                {({ getFieldValue }) => (
                  getFieldValue("service_type") === "pooja" &&
                  <Form.Item
                    label={`Astro puja list`}
                    name='astro_pooja_list'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: false,
                        message: 'Please select Astro puja list!',
                      },
                    ]}
                  >
                    <Select placeholder='Astro Puja list' mode="multiple">
                      <Option value='KAL_SARPA'>KAL SARPA</Option>
                      <Option value='PITRI_DOSHA'>PITRI DOSHA</Option>
                      <Option value='NAKSHATRA_PUJA'>NAKSHATRA PUJA</Option>
                      <Option value='WELCOME_PUJA'>WELCOME PUJA</Option>
                      <Option value='MANGAL_DOSHA'>MANGAL DOSHA</Option>
                      <Option value='GURU_CHANDAL_DOSHA'>GURU CHANDAL DOSHA</Option>
                      <Option value='GRAHAN_DOSHA'>GRAHAN DOSHA</Option>
                      <Option value='GANDMOOL_DOSHA'>GANDMOOL DOSHA</Option>
                      <Option value='SHANI_DOSHA'>SHANI DOSHA</Option>
                      <Option value='RAHU_DOSHA'>RAHU DOSHA</Option>
                      <Option value='NAVGRAHA_DOSHA'>NAVGRAHA DOSHA</Option>
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item
                label={`Tags`}
                name='tags'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: false,
                    message: 'Please select Tags!',
                  },
                ]}
              >
                <Select placeholder='Tags' mode="multiple">
                  {tags?.[acceptLanguage]?.map((tag: string) => (
                    <Option key={tag} value={tag}>{tag}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col >
            {/* <Col span={1}></Col> */}
            <Col style={{ marginLeft: '25px' }} span={7}>
              <Form.Item
                label='Closed Cover Media'
                style={{ marginBottom: '0px' }}
              >
                <div style={{
                  border: '1px dashed #e0dcdc',
                  marginRight: '0px',
                  marginBottom: '3px',
                  padding: '10px',
                }} title='Order Intro Media'>
                  <Row justify='space-between'>
                    <Form.Item
                      label={`Media type`}
                      name='closedCoverMediaType'
                      labelCol={{ span: 20 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please select closed cover media type!',
                        },
                      ]}
                    >
                      <Select style={{ width: 100 }} placeholder='Type'>
                        <Option value='lottie'>Lottie</Option>
                        <Option value='image'>Image</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={`Media`}
                      name='closed_media_url'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          min: 1,
                          max: 1,
                          validator: async (rules: any, values: any) => {
                            if (values?.length < rules.min) {
                              return Promise.reject('Please upload Closed Cover Media')
                            }
                          },
                        },
                      ]}
                      valuePropName='fileList'
                    >
                      <S3Upload
                        cdnUrl={cdnUrl}
                        preSignUrl={preSignUrl}
                        min={1}
                        max={1}
                      />
                    </Form.Item>
                  </Row>
                </div>
              </Form.Item>

              <Form.Item
                label='Closed Temple Media'
                style={{ marginTop: '20px' }}
              >
                <div style={{
                  border: '1px dashed #e0dcdc',
                  marginRight: '0px',
                  marginBottom: '3px',
                  padding: '10px',
                }} title='Order Intro Media'>
                  <Row justify='space-between'>
                    <Form.Item
                      label={`Images`}
                      name='closed_info_media_image'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      valuePropName='fileList'
                      extra='Upload images'
                      rules={[
                        {
                          min: 1,
                          max: 4,
                          validator: async (rules: any, values: any) => {
                            if (values?.length < rules.min) {
                              return Promise.reject('Please upload closed Temple listing Images')
                            }
                          },
                        },
                      ]}
                    >
                      <S3Upload cdnUrl={cdnUrl} preSignUrl={preSignUrl} min={1} max={4} />
                    </Form.Item>

                    <Form.Item
                      label={`Videos`}
                      name='closed_info_media_video'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      valuePropName='fileList'
                      extra='Upload video'
                      rules={[
                        {
                          min: 0,
                          max: 1,
                          validator: async (rules: any, values: any) => {
                            if (values?.length < rules.min) {
                              return Promise.reject('Please upload Closed Cover media video')
                            }
                          },
                        },
                      ]}
                    >
                      <S3Upload cdnUrl={cdnUrl} preSignUrl={preSignUrl} min={0} max={1} />
                    </Form.Item>
                  </Row>
                </div>
              </Form.Item>
              <Row justify='space-between'>
                <Col span={24}>
                  <Form.Item
                    label='Whatsapp Media'
                    style={{ marginTop: '0px' }}
                  >
                    <div style={{
                      border: '1px dashed #e0dcdc',
                      marginRight: '0px',
                      marginBottom: '3px',
                      padding: '10px',
                    }} title='Order Intro Media'>
                      <Row justify='space-between'>
                        <Form.Item
                          label='Media type'
                          name='whatsappMediaType'
                          rules={[{ required: false, message: 'Please select media type!' }]}
                        >
                          <Select style={{ width: 100 }} placeholder='Type'>
                            <Option value='video'>Video</Option>
                            <Option value='image'>Image</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.whatsappMediaType !== currentValues.whatsappMediaType
                          }
                        >
                          {({ getFieldValue }) => (
                            getFieldValue("whatsappMediaType") === "video" && <Row>
                              <Col span={12}>
                                <Form.Item
                                  label='Media'
                                  name='whatsapp_share_media'
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  valuePropName='fileList'
                                  rules={[
                                    {
                                      min: 0,
                                      max: 1,
                                      validator: async (rules: any, values: any) => {
                                        if (values?.length < rules.min) {
                                          return Promise.reject('Please upload MP4 media url')
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  <S3Upload
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    allowedFileExtension={[AllowedFormats.MP4]}
                                    min={0}
                                    max={1}
                                  />
                                </Form.Item>
                              </Col>

                            </Row>

                          )}
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.whatsappMediaType !== currentValues.whatsappMediaType
                          }
                        >
                          {({ getFieldValue }) => (
                            getFieldValue("whatsappMediaType") === "image" && <Row>
                              <Col span={12}>
                                <Form.Item
                                  label='Media'
                                  name='whatsapp_share_media'
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  valuePropName='fileList'
                                  rules={[
                                    {
                                      min: 0,
                                      max: 1,
                                      validator: async (rules: any, values: any) => {
                                        if (values?.length < rules.min) {
                                          return Promise.reject('Please upload image ')
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  <S3Upload
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    allowedFileExtension={[AllowedFormats.PNG, AllowedFormats.JPEG,]}
                                    min={0}
                                    max={1}
                                  />
                                </Form.Item>

                              </Col>
                            </Row>

                          )}
                        </Form.Item>
                      </Row>
                    </div></Form.Item>
                  <Row>
                    <Button
                      icon={<CopyOutlined />}
                      type='primary'
                      onClick={handleCloneModal}
                      style={{ marginTop: '0px', width: '100%' }}
                    >
                      Clone
                    </Button>
                  </Row>
                </Col>
              </Row >
            </Col >
          </Row >
        </Col >
        <Col span={24}>
          <Row>
            <Col span={11} style={{ marginTop: '20px' }}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Enable Mantra Card"
                name={['enable_mantra_data']}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.enable_mantra_data !== currentValues.enable_mantra_data
                }
              >
                {({ getFieldValue }) =>

                  <Form.Item
                    label='Mantra Card'
                    rules={[{ required: true, message: 'Please select media type!' }]}
                  >
                    <div style={{ border: '1px dashed #e0dcdc', padding: '10px' }}>
                      <Form.Item
                        label='Title'
                        name={['mantra_data', 'title']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          { required: getFieldValue("enable_mantra_data"), message: 'Please input Title' },
                        ]}
                      >
                        <Input placeholder='Enter Mantra Title' disabled={!getFieldValue("enable_mantra_data")} />
                      </Form.Item>
                      <Form.Item
                        label='Subtitle'
                        name={['mantra_data', 'subtitle']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          { required: getFieldValue("enable_mantra_data"), message: 'Please input Subtitle' },
                        ]}
                      >
                        <Input placeholder='Enter Mantra Subtitle' disabled={!getFieldValue("enable_mantra_data")} />
                      </Form.Item>
                      <Form.Item
                        label='Deeplink'
                        name={['mantra_data', 'deeplink']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          { required: getFieldValue("enable_mantra_data"), message: 'Please input deeplink' },
                        ]}
                      >
                        <Input placeholder='Enter Deeplink' disabled={!getFieldValue("enable_mantra_data")} />
                      </Form.Item>
                      <Form.Item
                        label='Icon'
                        name={['mantra_data', 'icon']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        valuePropName='fileList'
                        rules={[
                          {
                            min: getFieldValue("enable_mantra_data") ? 1 : 0,
                            max: 1,
                            validator: async (rules: any, values: any) => {
                              if (values?.length < rules.min) {
                                return Promise.reject('Please upload image ')
                              }
                            },
                          },
                        ]}
                      >
                        <S3Upload
                          cdnUrl={cdnUrl}
                          preSignUrl={preSignUrl}
                          min={getFieldValue("enable_mantra_data") ? 1 : 0}
                          max={1}
                          disabled={!getFieldValue("enable_mantra_data")}
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                }
              </Form.Item>
            </Col>
            <Col span={2}></Col>
            <Col span={11} style={{ marginTop: '20px' }}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Enable Puja Ritual update"
                name={['enable_puja_faq']}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.enable_puja_faq !== currentValues.enable_puja_faq
                }
              >
                {({ getFieldValue }) =>
                  <>
                    <Form.Item
                      label='Puja Ritual update'
                      rules={[{ required: true, message: 'Please select media type!' }]}
                    >
                      <div style={{ border: '1px dashed #e0dcdc', padding: '10px' }}>
                        <Form.Item

                          label='Title'
                          name={['puja_faq', 'title']}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            { required: getFieldValue("enable_puja_faq"), message: 'Please input FAQ Title' },
                          ]}
                        >
                          <Input placeholder='Enter FAQ Title' disabled={!getFieldValue("enable_puja_faq")} />
                        </Form.Item>
                        <Form.Item
                          label='Description'
                          name={['puja_faq', 'description']}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            { required: getFieldValue("enable_puja_faq"), message: 'Please input FAQ Description' },
                          ]}
                        >
                          <TextArea placeholder='Enter FAQ Description' disabled={!getFieldValue("enable_puja_faq")} />
                        </Form.Item>
                        <Form.Item
                          label='CTA Text'
                          name={['puja_faq', 'l2_cta_text']}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            { required: getFieldValue("enable_puja_faq"), message: 'Please input FAQ Cta Text' },
                          ]}
                        >
                          <Input placeholder='Enter FAQ CTA Text' disabled={!getFieldValue("enable_puja_faq")} />
                        </Form.Item>

                        <Form.Item
                          label='Media Url'
                          name={['puja_faq', 'media_urls']}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          valuePropName='fileList'
                          rules={[
                            {
                              min: getFieldValue("enable_puja_faq") ? 1 : 0,
                              max: 1,
                              validator: async (rules: any, values: any) => {
                                if (values?.length < rules.min) {
                                  return Promise.reject('Please upload image ')
                                }
                              },
                            },
                          ]}
                        >
                          <S3Upload
                            cdnUrl={cdnUrl}
                            preSignUrl={preSignUrl}
                            min={getFieldValue("enable_puja_faq") ? 1 : 0}
                            max={10}
                            disabled={!getFieldValue("enable_puja_faq")}
                          />
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </>
                }
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Form >
      <Col span={24}>
        <LabelRightAlign>
          <Space>
            <Button type='primary' onClick={() => navigate("/monetisation/puja/temples")}>
              Cancel
            </Button>
            {currentStep > 0 && (
              <Button type='primary' onClick={previousHandler}>
                Previous
              </Button>
            )}
            {isEditJourney && currentStep !== 1 && (
              <Button type='primary' onClick={submitFormHandler}>
                Update
              </Button>
            )}
            {currentStep < 3 && currentStep !== 1 && !isEditJourney ? (
              <Button type='primary' onClick={submitFormHandler}>
                Submit
              </Button>
            ) : (
              currentStep !== 2 && (
                <Button type='primary' onClick={nextHandler}>
                  Next
                </Button>
              )
            )}
          </Space>
        </LabelRightAlign>
        {initialStoreData && <FilterForm storeInfo={{ storeId: initialStoreData?.store_id, storeName: initialStoreData?.title }} />}
      </Col>
    </div >
  )
}
export default StoreForm
