import { Radio } from 'antd';
import React from 'react';
import { RUN_PARALLEL_ALWAYS_FILTER_TYPES } from '../utils/contants';
interface FiltersProps {
  runParallelAlways: RUN_PARALLEL_ALWAYS_FILTER_TYPES
  setRunParallelAlways: React.Dispatch<React.SetStateAction<RUN_PARALLEL_ALWAYS_FILTER_TYPES>>
}
export default function Filters(props: FiltersProps) {
  const { runParallelAlways, setRunParallelAlways } = props;
  return <div style={{ display: 'flex', gap: '20px' }}>
    <label>Filter by (Run parallel always): </label>
    <Radio.Group value={runParallelAlways} onChange={(event: any) => { setRunParallelAlways(event?.target.value) }} style={{ marginBottom: 16 }}>
      <Radio.Button value={RUN_PARALLEL_ALWAYS_FILTER_TYPES.BOTH}>All</Radio.Button>
      <Radio.Button value={RUN_PARALLEL_ALWAYS_FILTER_TYPES.YES}> Yes </Radio.Button>
      <Radio.Button value={RUN_PARALLEL_ALWAYS_FILTER_TYPES.NO}> No </Radio.Button>
    </Radio.Group>
  </div>
} 