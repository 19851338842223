/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Col,
  Drawer,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Space
} from 'antd'

import React from 'react'

import {
  CreateStore, Product, StoreCard
} from '@a4b/api/src/modules/Monetisation/offerings/types'

import S3Upload, { AllowedFormats } from '../S3Upload'
import TextArea from 'antd/lib/input/TextArea'

const { Option } = Select
interface Props {
  onClose: () => void
  visible: boolean
  cdnUrl: string
  preSignUrl: string
  formSubmithandler: (values: any) => void
  form: any
  initialValues: StoreCard
  isEditJourney: boolean
  inventoryList?: Product[]
  storeList: CreateStore[]
  onFormChange: (formValues: any) => void
}
const StoreCardCreation: React.FC<Props> = ({
  onClose,
  visible,
  cdnUrl,
  preSignUrl,
  formSubmithandler,
  form,
  initialValues,
  isEditJourney,
  inventoryList,
  storeList,
  onFormChange,
}) => {
  return (
    <Drawer
      title='Create New Item'
      placement='right'
      onClose={onClose}
      visible={visible}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout='vertical'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={initialValues}
        onValuesChange={onFormChange}
      >
        <Form.Item
          label='Title'
          name='title'
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          rules={[{ required: false }]}
        >
          <Input placeholder='Enter title' />
        </Form.Item>
        <Form.Item
          label='Description'
          name='description'
          labelCol={{ span: 22 }}
          wrapperCol={{ span: 22 }}
          rules={[
            {
              required: false,
              message: 'Enter description of the store card',
            },
          ]}
        >
          <TextArea placeholder='Enter description' rows={3} />
        </Form.Item>
        <Form.Item
          label='Tag'
          name='tag'
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          rules={[{ required: true, message: 'Enter tag of the srore card' }]}
        >
          <Input placeholder='Enter tag' />
        </Form.Item>
        <Form.Item
          label={`Position`}
          name='position'
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          rules={[{ required: true, message: 'Please input position!' }]}
        >
          <Input type={'number'} placeholder={`position`} />
        </Form.Item>

        <Form.Item
          label='Select Store'
          name='store_id'
          hasFeedback
          rules={[{ required: true, message: 'Please select store!' }]}
        >
          <Select placeholder='Please select a store'>
            {storeList?.map((store: CreateStore) => (
              <Option value={store.id} key={store.id}>
                <Row>
                  <Space>
                    <Image
                      preview={false}
                      width={40}
                      height={30}
                      src={store.icon_url}
                    />
                    {`   ${store.title || store.display_title}`}
                  </Space>
                </Row>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.store_id !== currentValues.store_id
          }
        >
          {({ getFieldValue }) => (
            <Form.Item
              label='Select Inventory'
              name='inventory_id'
              rules={[{ required: true, message: 'Please select inventory!' }]}
            >
              <Select
                disabled={!getFieldValue('store_id')}
                placeholder='Please select inventory'
              >
                {inventoryList?.map((product: Product) => (
                  <Option value={product.id} key={product.id}>
                    <Row>
                      <Image
                        preview={false}
                        width={40}
                        height={30}
                        src={product.image_url}
                      />
                      {product.title}
                    </Row>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label='Select Media Type'
          name='media_type'
          rules={[{ required: true, message: 'Please select media type!' }]}
        >
          <Radio.Group>
            <Radio value={"video"}>Video</Radio>
            <Radio value={"image"}>Image</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.media_type !== currentValues.media_type
          }
        >
          {({ getFieldValue }) => (
            getFieldValue("media_type") === "video" && <Row>
              <Col span={12}>
                <Form.Item
                  label='Media URL'
                  name='media_url'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  valuePropName='fileList'
                  rules={[
                    {
                      min: 1,
                      max: 1,
                      validator: async (rules: any, values: any) => {
                        if (values?.length < rules.min) {
                          return Promise.reject('Please upload MP4 media url')
                        }
                      },
                    },
                  ]}
                >
                  <S3Upload
                    cdnUrl={cdnUrl}
                    preSignUrl={preSignUrl}
                    allowedFileExtension={[AllowedFormats.MP4]}
                    min={1}
                    max={1}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Media thumbnail image'
                  name='media_thumbnail'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  valuePropName='fileList'
                  rules={[
                    {
                      min: 1,
                      max: 1,
                      validator: async (rules: any, values: any) => {
                        if (values?.length < rules.min) {
                          return Promise.reject('Please upload media thumb Image')
                        }
                      },
                    },
                  ]}
                >
                  <S3Upload
                    allowedFileExtension={[AllowedFormats.JPEG, AllowedFormats.PNG]}
                    cdnUrl={cdnUrl}
                    preSignUrl={preSignUrl}
                    min={1}
                    max={1}
                  />
                </Form.Item>
              </Col>
            </Row>

          )}
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.media_type !== currentValues.media_type
          }
        >
          {({ getFieldValue }) => (
            getFieldValue("media_type") === "image" && <Row>
              <Col span={12}>
                <Form.Item
                  label='Media URL'
                  name='media_url'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  valuePropName='fileList'
                  rules={[
                    {
                      min: 1,
                      max: 1,
                      validator: async (rules: any, values: any) => {
                        if (values?.length < rules.min) {
                          return Promise.reject('Please upload image ')
                        }
                      },
                    },
                  ]}
                >
                  <S3Upload
                    cdnUrl={cdnUrl}
                    preSignUrl={preSignUrl}
                    allowedFileExtension={[AllowedFormats.PNG, AllowedFormats.JPEG,]}
                    min={1}
                    max={1}
                  />
                </Form.Item>
              </Col>

            </Row>

          )}
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ paddingTop: '30px' }}
        >
          {isEditJourney ? (
            <Button type='primary' onClick={formSubmithandler}>
              Update Item
            </Button>
          ) : (
            <Button type='primary' onClick={formSubmithandler}>
              Create Item
            </Button>
          )}
        </Form.Item>
      </Form>
    </Drawer>
  )
}
export default StoreCardCreation
