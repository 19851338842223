import { Rashifal } from "@/../../../packages/a4b-api/src/modules/Content/Rashifal/types";
import { useAppContext } from "@/components/AppContext";
import { FormSelect } from "@/components/Forms/Select";
import { openNotification } from "@/utils";
import { SUPPORTED_LANGUAGES_LIST } from "@/utils/constants";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker, Form, Input, Select, Space } from "antd";
import form from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

export const RASHIES = ["aries", "taurus", "gemini", "cancer", "leo", "virgo", "libra", "scorpio", "sagittarius", "capricorn", "aquarius", "pisces"];
interface AddEditRashifalProps {
  rashifal: Rashifal.RashifalItem
}
const EditRashifal = function (props: AddEditRashifalProps) {
  const { networkInstance } = useAppContext();
  const { rashifal } = props;
  const [form] = Form.useForm();
  const editRashifal = async (values: any) => {
    values.date = values.date.format("YYYY-MM-DD");
    console.log({ values })
    try {
      await networkInstance.clientWithHeaders.contentApi.rashifal.editRashifal(rashifal.id, values);
      openNotification('success', { message: 'Rashifals updated successfully!' })
    } catch (error: any) {
      openNotification('error', { message: 'Rashifals updation failed!', description: error.toString(), })
    }
  };

  form.setFieldsValue({ ...rashifal, date: moment(rashifal.date) });

  return <div>
    <Form
      form={form}
      onFinish={editRashifal}
      layout="vertical"
    >
      <Form.Item
        label="Language"
        name="language"
        rules={[{
          required: true,
        }
        ]}
      >
        <Select
          showSearch
          optionLabelProp="label"
          optionFilterProp="label"
          style={{ width: '200px' }}
          disabled
        >
          {SUPPORTED_LANGUAGES_LIST.map((language) => ({ lable: `${language.name} (${language.alt_name})`, value: language.value })).map((listItem, index) => (
            <Select.Option label={listItem.lable} value={listItem.value} key={listItem.value}>
              {listItem.lable}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="rashi"
        name={'rashi'}
        rules={[{ required: true }]}
      >
        <Select disabled={true} style={{ width: 130 }}>

        </Select>
      </Form.Item>
      <Form.Item
        label="Rashifal data"
        name={'rashifal_data'}
        rules={[{ required: true }]}
      >
        <TextArea rows={4} autoSize />
      </Form.Item>
      <Form.Item
        label="Active"
        name={'active'}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        label="Date"
        name="date"
        rules={[{
          required: true,
        }
        ]}
      >
        <DatePicker disabled />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" >Submit</Button>
      </Form.Item>
    </Form>
  </div>
}

export default EditRashifal;