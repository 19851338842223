import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Card } from 'antd'
import styled from 'styled-components'

import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import type { SortableItemData } from '../types'

interface Props {
  item: SortableItemData
  enabled: boolean
  itemIndex: number
  onRemoveItem: (item: SortableItemData) => void
}

const { Meta } = Card

const Container = styled.div`
  margin: 20px;
  width: 150px;
  position: relative;
  overflow: hidden;
`

const CardImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
`

const Overlay = styled.div`
  position: absolute;
  background: gray;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.4;
`
const CloseButton = styled(Button)`
  position: absolute;
  right: 5px;
  top: 0px;
  padding: 0;

  svg {
    background: white !important;
    border-radius: 100%;
  }
`

const CardItem: React.FC<Props> = ({
  item,
  enabled,
  itemIndex,
  onRemoveItem,
}) => {
  const handleRemoveItemClicked = () => {
    onRemoveItem(item)
  }

  return (
    <Container>
      <Card cover={item.imageUrl && <CardImage src={item.imageUrl} />}>
        <Meta title={`${itemIndex + 1}. ${item.name}`} />
        <CloseButton onClick={handleRemoveItemClicked} danger type='link'>
          <CloseCircleOutlined />
        </CloseButton>
      </Card>
      {!enabled && <Overlay />}
    </Container>
  )
}

export const SortableCardItem = SortableElement(CardItem)
