import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Input, message, Row, Select, Spin, Switch } from 'antd';
import { FormInstance, useForm, } from 'antd/es/form/Form';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { UserSegments, UserSegment, UpdateInventoryContent, InventoryContentById } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { useAppContext } from '@/components/AppContext';
import Modal from 'antd/lib/modal/Modal';

interface Props {
    showDynamicInventoryUpdate: boolean;
    inventoryId: string;
    inventoryName: string;
    closeDynamicUpdate: () => void;
}

const DynamicInventoryUpdate: React.FC<Props> = ({
    showDynamicInventoryUpdate,
    inventoryId,
    inventoryName,
    closeDynamicUpdate,
}) => {
    const [form] = useForm()
    const { networkInstance } = useAppContext()
    const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

    const [userSegments, setUserSegments] = useState<UserSegment[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [contentValues, setContentValues] = useState<any>()
    const [onFieldChange, setOnFieldChanged] = useState<boolean>(false);

    useEffect(() => {
        if (showDynamicInventoryUpdate && inventoryId) {
            setLoading(true);
            fetchUserSegments();
            getInventoryContent();
        }
    }, [showDynamicInventoryUpdate, inventoryId]);

    const fetchUserSegments = async () => {
        try {
            const userSegmentsData = await monetisationOfferingsApi?.userSegmentsApi?.getAllUserSegments({
                limit: 1000,
                offset: 0,
            }, {
                filterType: 'dropdown'
            })

            console.log('Fetched User Segments:', userSegmentsData?.data?.data?.user_segments)

            setUserSegments(userSegmentsData?.data?.data?.user_segments);
        } catch (error) {
            console.error('Error while fetching user segments:', error);
        }
    };

    const getInventoryContent = useCallback(async () => {
        try {
            if (inventoryId) {
                const inventoryResponse = await monetisationOfferingsApi?.storesApi?.getInventoryContentById(inventoryId, 'dynamic')
                const inventoryContents = inventoryResponse?.data?.data?.content || [];
                setLoading(false);
                form.setFieldsValue({ inventory_content: inventoryContents });
                setContentValues(inventoryContents)
            }
        } catch (error) {
            closeDynamicUpdate()
            setLoading(false);
        }
    }, [inventoryId]);

    const handleUpdate = async (values: any) => {
        try {
            const updatedValues = values?.inventory_content?.filter((item: any, index: number) => {
                const originalItem = contentValues[index];
                if (!originalItem) {
                    item.is_edited = false;
                    item.inventory_id = inventoryId;
                    item.record_type = 'dynamic';
                    return true;
                } else {
                    const editedFields = Object.keys(item).filter(key => item[key] !== originalItem[key]);
                    if (editedFields.length > 0) {
                        item.is_edited = true;
                        return true;
                    }
                    return false;
                }
            });

            updatedValues?.forEach((item: any) => {
                item.price = parseFloat(item?.price);
                item.position = parseFloat(item?.position);
            });

            const payload: UpdateInventoryContent = {
                content: updatedValues,
            };

            const response = await monetisationOfferingsApi?.storesApi?.updateInventoryConent(inventoryId, payload);
            if (response.status === 200) {
                message.success(response?.data?.data || 'No data');
                closeDynamicUpdate()
            }
        } catch (error) {
            closeDynamicUpdate();
            message.error(`Error: An error occurred while updating inventory`);
        }
    };

    const handleFieldChange = () => {
        setOnFieldChanged(true);
    };

    return (
        <Modal
            title="Update Dynamic Inventory"
            onCancel={() => {
                closeDynamicUpdate()
                setOnFieldChanged(false)
                form?.resetFields()
            }}
            visible={showDynamicInventoryUpdate}
            destroyOnClose={true}
            width={980}
            footer={[
                <Button key="cancel" onClick={() => {
                    closeDynamicUpdate();
                    setOnFieldChanged(false);
                    form?.resetFields();
                }}>
                    Cancel
                </Button>,
                <Button key="update" type="primary" onClick={form.submit} disabled={!onFieldChange}>
                    Update
                </Button>,
            ]}
            bodyStyle={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}
            centered
            >
            <Spin spinning={loading}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleUpdate}
                    onChange={handleFieldChange}
                >
                    <h2>Inventory Title : {inventoryName}</h2>
                    <br />
                    <Form.List name="inventory_content">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                    <Row key={key} gutter={16} style={{ marginBottom: '1rem' }}>
                                        <Col>
                                            <Form.Item
                                                label='Id'
                                                name={[name, 'id']}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input style={{ color: 'black' }} disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item
                                                label="User Segment"
                                                name={[name, 'user_segment_id']}
                                                rules={[{ required: true, message: 'Enter User Segment' }]}
                                            >
                                                <Select placeholder="Select User Segment" onChange={handleFieldChange}>
                                                    {userSegments
                                                        .filter(segment => segment.active || segment.id === form.getFieldValue(['content', index, 'user_segment_id']))
                                                        .map(segment => (
                                                            <Select.Option key={segment.id} value={segment.id}>
                                                                {segment.name}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                label="Price"
                                                name={[name, 'price']}
                                                rules={[{ required: true, message: 'Enter Price' }]}
                                            >
                                                <Input type="number" min={1} placeholder="Price" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                label="Position"
                                                name={[name, 'position']}
                                                rules={[{ required: true, message: 'Enter Position' }]}
                                            >
                                                <Input type="number" min={1} placeholder="Position" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                label="Active"
                                                name={[name, 'active']}
                                                valuePropName="checked"
                                                rules={[{ required: true, message: '' }]}

                                            >
                                                <Switch onChange={handleFieldChange} ></Switch>
                                            </Form.Item>
                                        </Col>
                                        <Col span={2} style={{ marginLeft: '-100px' }}>
                                            {(index >= contentValues?.length) && (
                                                <Button
                                                    type="link"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => remove(index)}
                                                    style={{ display: 'inline', tabSize: '50px' }}
                                                >
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                ))}
                                <Row>
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            className="ant-btn ant-btn-dashed"
                                            onClick={() => {
                                                add();
                                            }}
                                            icon={<PlusCircleOutlined />}
                                            htmlType='button'
                                        >
                                            Create
                                        </Button>
                                    </Form.Item>
                                </Row>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Spin>
        </Modal>

    );
};

export default DynamicInventoryUpdate;
