import { Affix, Card, Space } from 'antd'
import styled from 'styled-components'

import React, { useEffect, useState } from 'react'

import {
  CreateEditScheduleForm,
  CreateEditScheduleFormFields,
  Props as CreateEditScheduleFormProps,
} from '../../components/LibraryContent/Schedule/CreateEditScheduleForm'
import {
  SearchItems,
  Props as SearchItemsProps,
} from '../../components/LibraryContent/Schedule/SearchItems'
import SchedulePreview from '../../components/LibraryContent/SchedulePreview'

//types
import { SortableItemData } from 'admin'

interface Props extends Omit<CreateEditScheduleFormProps, 'onChange'>, SearchItemsProps {
  title: string
}


const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 50px;
  
  > div {
    width: 50%;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
`


const PreviewAffix = styled(Affix)`
    display: flex;
    justify-content: center;
  .ant-affix {
    display: flex;
    justify-content: center;
  }
`

export const CreateEditSchedule: React.FC<Props> = ({
  data,
  date,
  title,
  onSubmit,
  onAddItem,
  onRemoveItem,
  onSearch,
  items,
}) => {

  const [orderingItems, setOrderingItems] = useState<SortableItemData[]>([])

  useEffect(() => {
    if (data) {
      setOrderingItems(data?.ordering || [])
    }
  }, [data])

  const handleScheduleChange = (values: CreateEditScheduleFormFields) => {
    setOrderingItems(values.ordering)
  }

  return (
    <Wrapper>
      <Container>
        <Card title={title}>
          <Space direction='vertical' style={{ width: '100%' }}>
            <SearchItems
              items={items}
              onAddItem={onAddItem}
              onSearch={onSearch}
            />
            <CreateEditScheduleForm
              date={date}
              data={data}
              onSubmit={onSubmit}
              onRemoveItem={onRemoveItem}
              onChange={handleScheduleChange}
            />
          </Space>
        </Card>
      </Container>
      <PreviewAffix offsetTop={65}>
        <SchedulePreview items={orderingItems} />
      </PreviewAffix>
    </Wrapper>
  )
}

export default CreateEditSchedule
