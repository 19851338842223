import { useEffect, useState, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppContext } from '@/components/AppContext'
import { A4B_APPS, YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import { message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import ReviewCreation from '../../components/ReviewCreation'
import { LanguageState } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { CreateOrUpdateReview } from '@a4b/api/src/modules/Monetisation/Astrologer/types'
import { parseReviewResponse, reviewPayload } from './helper'
import MonetisationAstroApi from '@a4b/api/src/modules/Monetisation/Astrologer'

interface Props {
    isEditJourney: boolean
    currentStep: number
    agent: string
    previousHandler: (agent_id: string) => void
    languageState: LanguageState
}

const ReviewStep: React.FC<Props> = ({
    currentStep,
    isEditJourney,
    agent,
    previousHandler,
    languageState,
}) => {
    const [reviewForm] = useForm()
    const { networkInstance, app } = useAppContext()
    const { monetisationAstroApi, monetisationNewAstroApi } = networkInstance.clientWithHeaders
    const api: MonetisationAstroApi = (app === A4B_APPS.SRIMANDIR) ? monetisationAstroApi : monetisationNewAstroApi
    const [searchParams] = useSearchParams()
    const [agentId] = useState<string>(searchParams.get('agent_id') as string)
    const [loader, setLoader] = useState<boolean>(false)

    const getReviewByAgent = useCallback(async () => {
        try {
            if (!agentId) {
                return
            }
            const agentResponse = await api.reviewsApi.getReviewByAgent(agentId)
            const parsedReviewResponse = parseReviewResponse(agentResponse?.data?.data)
            reviewForm.setFieldsValue(parsedReviewResponse)
        } catch (error: any) {
            message.error(error.message, 2)
        }
    }, [agentId, api.reviewsApi, reviewForm])

    useEffect(() => {
        getReviewByAgent()
    }, [getReviewByAgent])

    const handleCreateOrUpdateReview = async (payload: CreateOrUpdateReview) => {
        setLoader(true)
        try {
            await api.reviewsApi.createReview(payload)
            message.success('Reviews updated successfully', 2)
            setTimeout(() => {
                getReviewByAgent()
                setLoader(false)
            }, 1000)
        } catch (error) {
            setLoader(false)
            message.error('Error while updating review', 2)
        }
    }

    const submitFormHandler = () => {
        reviewForm
            .validateFields()
            .then((values: any) => {
                const reviewPayloadData = reviewPayload(values, languageState?.monetisationLanguage, agentId)
                handleCreateOrUpdateReview(reviewPayloadData)
            })
            .catch(error => {
                console.log({ error })
                message.error('Agent creation error')
            })
    }

    const deleteReview = async (id: string, callback: any) => {
        setLoader(true)
        try {
            await api.reviewsApi.deleteReview(id)
            message.success({
                content: 'Review deleted successfully',
                duration: 3,
            })
            callback()
            setTimeout(() => {
                getReviewByAgent()
                setLoader(false)
            }, 1000)
        } catch (error) {
            setLoader(false)
            message.error({
                content: 'Review deletion failed',
                duration: 2,
            })
        }
    }

    return (
        <ReviewCreation
            cdnUrl={YODA_CDN_URL}
            preSignUrl={YODA_PRESIGN_URL}
            form={reviewForm}
            currentStep={currentStep}
            previousHandler={() => previousHandler(agentId)}
            isEditJourney={isEditJourney}
            submitFormHandler={submitFormHandler}
            deleteReview={deleteReview}
            loader={loader}
        />
    )
}

export default ReviewStep
