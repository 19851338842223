import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import React, { useEffect } from 'react'
import { Spin, Col } from 'antd'
import { HubListScreen } from '@a4b/ui/src/modules/monetisation/puja/screens/HubList'
import { HubList, HubListItem } from '@a4b/api/src/modules/Monetisation/Puja/types'
import HubCreationContainer from '../CreateHub'
import Error from '@/components/Error'
import { A4B_APPS } from '@/utils/constants'

interface Props { }

const getPageBreadcrumbs = () => [
  {
    path: '/monetisation/puja/temples',
    breadcrumbName: 'Manage Stores',
  },
  {
    path: '/monetisation/puja',
    breadcrumbName: 'Hub List',
  },
]

const HubListContainer: React.FC<Props> = () => {
  const { networkInstance, monetisationRoles, app } = useAppContext()
  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
  const [showHubCreation, setShowHubCreation] = React.useState(false)
  const [hubListData, setHubList] = React.useState<HubList>()
  const [errorPage, setErrorPage] = React.useState<boolean>(false)

  const getHubList = async () => {
    try {
      const hubList = await monetisationPujaApi.hub.getHubList({
        limit: 100,
        offset: 0,
      })

      if (monetisationRoles?.user_type === 'admin' || monetisationRoles?.user_type === 'super_admin') {
        setHubList(hubList?.data?.data)
      } else {
        const filteredList: HubListItem[] = hubList?.data?.data?.hubs.filter(
          (hub: HubListItem) => hub.manager_email === monetisationRoles?.email,
        )

        setHubList({ ...hubList?.data?.data, hubs: filteredList })
      }
    } catch (error) {
      setErrorPage(true)
      console.log(error)
    }
  }

  useEffect(() => {
    getHubList()
  }, [monetisationRoles])

  const showHubForm = () => {
    setShowHubCreation(true)
  }
  const closeHubForm = () => {
    setShowHubCreation(false)
  }
  const onHubCreation = (status: boolean) => {
    status && getHubList()
    setShowHubCreation(false)
  }
  return (
    <div style={{ padding: '0 40px' }}>
      {errorPage ?
        <Error text='Error While fetching Hub List!' />
        :
        (!hubListData ?
          <Col style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '20%' }}>
            <Spin />
          </Col>
          :
          <>
            <AdminBreadcrumb routes={getPageBreadcrumbs()} />
            <HubListScreen
              hubList={hubListData}
              triggerCreationForm={showHubForm}
              showAddNewHub={monetisationRoles?.user_type === 'admin' || monetisationRoles?.user_type === 'super_admin'}
            />
          </>)
      }
      <HubCreationContainer
        showCreateHub={showHubCreation}
        closeCreateHub={closeHubForm}
        journetType={'create'}
        UpdateHubCallback={onHubCreation}
        createHubCallback={onHubCreation}
        hubId={''}
      />
    </div>
  )
}

export default withDefaultLayout(HubListContainer)
