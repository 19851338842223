
import { Button, PaginationProps, Popconfirm, TableColumnType, TablePaginationConfig, TableProps, Tag } from "antd";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { Affirmative, EventProperty, Journey } from "../utils/types";
import styled from "styled-components";
import { JOURNEY_STATUS, NEXT_STATUS_CONFIG_KEYS, STATUS_COLOR_CONFIG } from "../utils/contants";
import { DeleteOutlined, EditOutlined, EyeOutlined, RightSquareOutlined } from "@ant-design/icons";

const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: #dddddd;
  }
`
const StyledTable = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 10px;
  padding-top: 8px;
`

const StyledTd = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 4px 8px;
  text-align: center;
`
const StyledTh = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 4px 8px;
  text-align: center;
`

const SmallSpan = styled.span`
  font-size: 12px;
  & strong {
    font-weight: 500;
  }
`
const showTotal: PaginationProps['showTotal'] = (total) => `Total ${total} items`;

export default function useTableConfig<T>(
  changeJourneyState: (journeyId: string, currentStatus: NEXT_STATUS_CONFIG_KEYS) => Promise<void>,
  setViewJourney: (journey?: Journey) => void,
  setEditJourney: (journey?: Journey) => void,
  dataSource: T[],
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
): TableProps<any[]> {



  const columns: TableColumnType<Journey>[] = [
    // {
    //   title: 'S.no',
    //   dataIndex: 'id',
    //   align: 'center',
    //   render(value, record, index) {
    //     return index + 1
    //   },
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
    },
    {
      width: '20%',
      title: 'Cohort',
      align: 'center',
      dataIndex: 'cohort',
      render(value, record, index) {
        return <div >
          <SmallSpan> <strong>Entry day since install</strong> : {value.entry_properties[0].value_int64}</SmallSpan>
          <br />
          <SmallSpan> <strong>Exit day since the install</strong> :{value.exit_properties[0].value_int64}</SmallSpan>
          {
            value?.entry_properties?.length > 1 && <StyledTable >
              <thead>
                <StyledTr>
                  <StyledTh>Path </StyledTh>
                  <StyledTh>Operator </StyledTh>
                  <StyledTh>Value </StyledTh>
                </StyledTr>
                {value?.entry_properties?.map((item: EventProperty, index: number) => {
                  if (index === 0) return null;
                  return <StyledTr>
                    <StyledTd>{item.path}</StyledTd>
                    <StyledTd>{item.operator}</StyledTd>
                    <StyledTd>{item.value_int64}</StyledTd>
                  </StyledTr>
                })}
              </thead>
            </StyledTable>
          }
        </div>
      }
    },

    {
      title: "Event Schema",
      dataIndex: 'event_schema',
      align: 'center',
      width: 600,
      render(value, record, index) {
        return <>
          <span><strong>Event name </strong>: {value.event_name}  </span>
          <StyledTable >
            {
              value.properties.length > 0 &&
              <thead>
                <StyledTr>
                  <StyledTh>Path</StyledTh>
                  <StyledTh>Operator</StyledTh>
                  <StyledTh>Value</StyledTh>
                </StyledTr>
                {value.properties.map((item: EventProperty) => {
                  return <StyledTr key={index}>
                    <StyledTd>{item.path}</StyledTd>
                    <StyledTd>{item.operator}</StyledTd>
                    <StyledTd>{item.value_int64 || item.value_string}</StyledTd>
                  </StyledTr>
                })}
              </thead>
            }
          </StyledTable>
        </>;
      }
    },
    {
      title: 'Priority',
      align: 'center',
      dataIndex: 'priority',
      width: '100px'
    },
    {
      title: 'Run parallel always',
      align: 'center',
      dataIndex: 'run_parallel_always',
      render(value, record, index) {
        return value ? <Tag color="green"> Yes</Tag> : <Tag color="red"> No</Tag>
      },
      width: '100px'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render(value: JOURNEY_STATUS, record, index) {
        return <Tag color={STATUS_COLOR_CONFIG[value]}>{value}</Tag>
      },
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      align: 'center',
      render(value, record, index) {
        return new Date(value).toLocaleString()
      }
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      align: 'center',
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {
            record.status === 'DRAFTED' &&
            <Popconfirm
              placement="topLeft"
              title={"Are you sure you want to publish this journey?"}
              onConfirm={() => {
                changeJourneyState(value, 'DRAFTED');
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" size="small" >
                <RightSquareOutlined /> Publish
              </Button>
            </Popconfirm>
          }
          {
            record.status === 'PUBLISHED' &&
            <Popconfirm
              placement="topLeft"
              title={"Are you sure you want to deprecate this journey?"}
              onConfirm={() => {
                changeJourneyState(value, 'PUBLISHED');
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" type="primary" style={{ background: '#ff6666', border: 'none' }}>
                <DeleteOutlined /> Deprecate
              </Button>
            </Popconfirm>
          }
          {
            record.status === 'DRAFTED' &&
            <Button size="small" type="link" onClick={() => {
              const journey = { ...record } as Journey;
              // Add identifier to the journey
              const addIdentifierToJourney = (identifier: [number, number], journey?: Journey | Affirmative) => {
                if (!journey) {
                  return null
                }
                journey.identifier = identifier
                if (!journey.fallback) {
                  // just all fallback as underfined to for type checks 
                  journey.fallback = undefined
                }
                const { affirmative, fallback } = journey;
                if (affirmative) {
                  addIdentifierToJourney([identifier[0] + 1, 1], affirmative)
                }
                if (fallback) {
                  fallback.identifier = [identifier[0] + 1, 0]
                }
              }
              addIdentifierToJourney([0, 1], journey)
              setEditJourney(journey)
            }
            }> <EditOutlined /> Edit </Button>
          }
          {
            (record.status === 'PUBLISHED' || record.status === 'DEPRECATED') &&
            <Button size="small" type="link" onClick={() => setViewJourney(record as Journey)}> <EyeOutlined /> View </Button>
          }
        </div>
      },
      fixed: 'right'
    }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  //@ts-ignore
  return { columns, dataSource, pagination: { ...pagination, showTotal }, onChange: tableOnChange, size: 'small', bordered: true, sticky: true, scroll: { x: 'max-content' } }
}