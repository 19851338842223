import React from 'react';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { ATTACHMENT } from '@/utils/enum';
import DeletableContainer from '../deletableContainer/DeletableContainer';
import Riddler from '@/network/Riddler/Riddler';
import Attachment from '@/ui/organisms/Attachment';
import ActionShortcuts from '@/entities/feeds/Widgets/ActionShortcuts';
import NumberInput from '@/modules/feeds/components/Number';
import { ActionShortcutsInstance } from '@/entities/feeds/Widgets';

const { TextArea } = Input;
interface Props {
  ctaActionType: any[];
  ctaRedirectionType: any[];
  riddlerInstance: any;
  contentInstance: ActionShortcutsInstance;
}
const ActionShortcutsWidget: React.FC<Props> = ({
  ctaActionType,
  ctaRedirectionType,
  riddlerInstance,
  contentInstance,
}: Props) => {
  return (
    <Row>
      <Col span={24}>
        <Form.List name={['content', 'items']}>
          {(fields, { add, remove, move }) => {
            return (
              <div>
                {fields.map((field: any, index: number) => {
                  return (
                    <Form.Item key={field.key}>
                      <DeletableContainer
                        closeHandler={() => {
                          remove(field.name);
                        }}
                        itemText={`Item Attribute : ${index + 1}`}
                        showItemTextInBold={true}
                        moveFunction={move}
                        fields={fields}
                        currentIndex={index}
                        fieldsLength={fields.length}
                        move={move}
                        add={add}
                      >
                        <Row>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              required={true}
                              label="Title"
                              name={[field.name, 'title']}
                              rules={[
                                {
                                  validator: contentInstance.titleValidator,
                                },
                              ]}
                            >
                              <TextArea rows={3} placeholder="Title" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              label="Icon Attachment"
                              name={[field.name, 'icon_attachment_id']}
                              rules={[
                                {
                                  validator: contentInstance.iconCodeValidator,
                                },
                              ]}
                            >
                              <Attachment
                                attachmentType={ATTACHMENT.IMAGE}
                                riddlerInstance={riddlerInstance}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              name={[field.name, 'cta_action_type']}
                              label="CTA Action Type"
                              required={true}
                              rules={[
                                {
                                  validator: contentInstance.ctaActionValidator,
                                },
                              ]}
                              hasFeedback={true}
                            >
                              <Select
                                placeholder="CTA Action Type"
                                options={ctaActionType}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                currentValues?.content?.items[field.name]
                                  ?.cta_action_type !==
                                prevValues?.content?.items[field.name]
                                  ?.cta_action_type
                              }
                            >
                              {({ getFieldValue }) =>
                                getFieldValue([
                                  'content',
                                  'items',
                                  field.name,
                                  'cta_action_type',
                                ]) === 'PLAY_SONG' ? (
                                  <Row>
                                    <Col span={16}>
                                      <Form.Item
                                        {...field}
                                        labelCol={{ span: 12 }}
                                        wrapperCol={{ span: 12 }}
                                        label="Song of the day"
                                        name={[field.name, 'song_of_the_day']}
                                        valuePropName="checked"

                                      >
                                        <Switch />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        noStyle
                                        shouldUpdate={(
                                          prevValues,
                                          currentValues
                                        ) =>
                                          currentValues?.content?.items[
                                            field.name
                                          ]?.song_of_the_day !==
                                          prevValues?.content?.items[field.name]
                                            ?.song_of_the_day
                                        }
                                      >
                                        {({ getFieldValue }) =>
                                          getFieldValue([
                                            'content',
                                            'items',
                                            field.name,
                                            'song_of_the_day',
                                          ]) != true ? (
                                            <Form.Item
                                              {...field}
                                              labelCol={{ span: 8 }}
                                              wrapperCol={{ span: 16 }}
                                              label="Song ID"
                                              name={[field.name, 'r_song_id']}
                                              rules={[
                                                {
                                                  validator:
                                                    contentInstance?.songIdValidator,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Song ID"
                                              />
                                            </Form.Item>
                                          ) : null
                                        }
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                ) : null
                              }
                            </Form.Item>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                currentValues?.content?.items[field.name]
                                  ?.cta_action_type !==
                                prevValues?.content?.items[field.name]
                                  ?.cta_action_type
                              }
                            >
                              {({ getFieldValue }) =>
                                getFieldValue([
                                  'content',
                                  'items',
                                  field.name,
                                  'cta_action_type',
                                ]) === 'RING_BELL' ||
                                  getFieldValue([
                                    'content',
                                    'items',
                                    field.name,
                                    'cta_action_type',
                                  ]) === 'PLAY_SHANKH' ||
                                  getFieldValue([
                                    'content',
                                    'items',
                                    field.name,
                                    'cta_action_type',
                                  ]) === 'FLOWER_SHOWER' ? (
                                  <Form.Item
                                    {...field}
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    label="God ID"
                                    name={[field.name, 'r_god_id']}
                                    tooltip={{
                                      title:
                                        'When God Id is 0 ,No value is configured.',
                                      icon: <InfoCircleOutlined />,
                                    }}
                                  >
                                    <NumberInput
                                      initValue={0}
                                      placeholder="God ID"
                                    />
                                  </Form.Item>
                                ) : null
                              }
                            </Form.Item>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                currentValues?.content?.items[field.name]
                                  ?.cta_action_type !==
                                prevValues?.content?.items[field.name]
                                  ?.cta_action_type
                              }
                            >
                              {({ getFieldValue }) =>
                                getFieldValue([
                                  'content',
                                  'items',
                                  field.name,
                                  'cta_action_type',
                                ]) === 'OPEN_ARTICLE' ? (
                                  <Form.Item
                                    {...field}
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    label="Article ID"
                                    name={[field.name, 'r_article_id']}
                                    rules={[
                                      {
                                        validator:
                                          contentInstance.articleIdValidator,
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Article ID"
                                    />
                                  </Form.Item>
                                ) : null
                              }
                            </Form.Item>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                currentValues?.content?.items[field.name]
                                  ?.cta_action_type !==
                                prevValues?.content?.items[field.name]
                                  ?.cta_action_type
                              }
                            >
                              {({ getFieldValue }) =>
                                getFieldValue([
                                  'content',
                                  'items',
                                  field.name,
                                  'cta_action_type',
                                ]) === 'REDIRECTION' ? (
                                  <Form.Item
                                    {...field}
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    name={[field.name, 'cta_redirection_type']}
                                    label="Redirection Type"
                                    required={true}
                                    rules={[
                                      {
                                        validator:
                                          contentInstance.redirectionTypeValidator,
                                      },
                                    ]}
                                    hasFeedback={true}
                                  >
                                    <Select
                                      placeholder="Redirection Type"
                                      options={ctaRedirectionType}
                                    />
                                  </Form.Item>
                                ) : null
                              }
                            </Form.Item>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                currentValues?.content?.items[field.name]
                                  ?.cta_action_type !==
                                prevValues?.content?.items[field.name]
                                  ?.cta_action_type
                              }
                            >
                              {({ getFieldValue }) =>
                                getFieldValue([
                                  'content',
                                  'items',
                                  field.name,
                                  'cta_action_type',
                                ]) === 'PLAY_VIDEO' ? (
                                  <Form.Item
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    name={[field.name, 'video_attachment_id']}
                                    label="Video Attachment ID"
                                    required={true}
                                    rules={[
                                      {
                                        validator:
                                          contentInstance.videoRefCodeValidator,
                                      },
                                    ]}
                                  >
                                    <Attachment
                                      attachmentType={ATTACHMENT.VIDEO}
                                      riddlerInstance={riddlerInstance}
                                    />
                                  </Form.Item>
                                ) : null
                              }
                            </Form.Item>
                          </Col>
                        </Row>
                      </DeletableContainer>
                    </Form.Item>
                  );
                })}
                <Form.Item>
                  <Row justify="end">
                    <Col span={24}>
                      <Row justify="end">
                        <Col span={4}>
                          <Button
                            onClick={() => {
                              add();
                            }}
                            type="dashed"
                            icon={<PlusCircleOutlined />}
                            size="middle"
                          >
                            Add Item Attributes
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Item>
                <div style={{ height: '20px' }}></div>
              </div>
            );
          }}
        </Form.List>
      </Col>
    </Row>
  );
};

export default ActionShortcutsWidget;
