import React, { useEffect } from 'react'
import { Button, Col, DatePicker, Drawer, Form, Input, InputNumber, Row, Select } from 'antd'
import { FormInstance } from 'antd/es/form/Form'

interface Props {
    showItemCreation: boolean
    closeItemCreation: () => void
    initialValues: any
    form: FormInstance<any>
    createDelivery: (nameList: any) => void
}

const { TextArea } = Input
const { Option } = Select

const CreateDeliveryOrder: React.FC<Props> = ({
    showItemCreation,
    closeItemCreation,
    initialValues,
    form,
    createDelivery,
}) => {
    const handleChangePickupLocation = (location: string) => {
        switch (location) {
            case 'corporate_office_blr':
                form.setFieldsValue({
                    pickup_info: {
                        phone: '9036118316',
                        address: '435,1st Floor, 17th Cross road, 19th Main Rd, ',
                        pincode: '560102',
                        landmark: 'above Axis Bank, Sector 4, HSR Layout, ',
                        district: 'Bangalore',
                        city: 'Bangalore',
                        state: 'Karnataka',
                        country: 'India'
                    }
                })
                break
            case 'guwahati_hub_vendor':
                form.setFieldsValue({
                    pickup_info: {
                        phone: '6002931139',
                        address: '01 Matri sankha Bhandar, pandu college,',
                        pincode: '781012',
                        landmark: 'college road, mailgaon, ',
                        district: 'Kamrup',
                        city: 'guwahati',
                        state: 'Assam',
                        country: 'India'
                    }
                })
                break
            case 'ujjain':
                form.setFieldsValue({
                    pickup_info: {
                        phone: '7000778514',
                        address: '13 avantika plaza freeganj ',
                        pincode: '456010',
                        landmark: 'Near khandelwal sonography  ',
                        district: 'Ujjain',
                        city: 'Ujjain',
                        state: 'Madhya Pardesh',
                        country: 'India'
                    }
                })
                break
            case 'haridwar':
                form.setFieldsValue({
                    pickup_info: {
                        phone: '9760925620',
                        address: 'Radhe radhe tower, shankar ashram road arya nagar chowk,',
                        pincode: '249401',
                        landmark: 'Near suzuki bike showroom, karishma bazaar,',
                        district: 'Haridwar',
                        city: 'Haridwar',
                        state: 'Uttarakhand',
                        country: 'India'
                    }
                })
                break
        }
    }

    useEffect(() => {
        form.setFieldsValue(initialValues)
    }, [initialValues])

    return (
        <>
            <Drawer
                title="Create Order delivery"
                placement='right'
                onClose={closeItemCreation}
                visible={showItemCreation}
                destroyOnClose={true}
                width={600}
                size='large'
            >
                <Form
                    form={form}
                    layout='vertical'
                    initialValues={{ ...initialValues }}
                    onFinish={createDelivery}
                >
                    <Col>
                        <Form.Item
                            label="Pickup location"
                            // name={['cta', 'media', 'media_type']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: false,
                                    message: 'Please select cta media type!',
                                },
                            ]}
                        >
                            <Select placeholder='Pickup location' onChange={(location: string) => handleChangePickupLocation(location)} defaultValue={null}>
                                <Option value='corporate_office_blr'>Corporate Office BLR</Option>
                                <Option value='guwahati_hub_vendor'>Guwahati Hub - vendor</Option>
                                <Option value='haridwar'>Haridwar</Option>
                                <Option value='ujjain'>Ujjain</Option>
                            </Select>
                        </Form.Item>
                        <Row justify='space-between'>
                            <Form.Item
                                label='Name'
                                name={['pickup_info', 'name']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[{ required: true, message: 'Enter Name' }]}
                            >
                                <Input style={{ width: '250px' }} placeholder='Enter Name' />
                            </Form.Item>
                            <Form.Item
                                label='Pickup time'
                                name={['pickup_info', 'pickup_time']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[{ required: true, message: 'Input pickup time' }]}
                            >
                                <DatePicker style={{ width: '250px' }} showTime />
                            </Form.Item>
                        </Row>
                        <Row justify='space-between'>
                            <Form.Item
                                label='Email'
                                name={['pickup_info', 'email']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[{ required: false, message: 'Enter Email' }]}
                            >
                                <Input style={{ width: '250px' }} placeholder='Enter Email' />
                            </Form.Item>
                            <Form.Item
                                label='Phone'
                                name={['pickup_info', 'phone']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[{ required: false, message: 'Enter Phone Number' }]}
                            >
                                <InputNumber maxLength={10} minLength={10} style={{ width: '250px' }} placeholder='Enter Phone Number' />
                            </Form.Item>
                        </Row>
                        <Form.Item
                            label='Address'
                            name={['pickup_info', 'address']}
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                            rules={[{ required: true, message: 'Enter Address' },
                            {
                                validator: (_, value, callback) => {
                                    if (!/\d/.test(value)) {
                                        callback('Address should contain at least one number')
                                    } else if (value.length <= 10) {
                                        callback('Address should be more than 10 characters')
                                    } else {
                                        callback()
                                    }
                                }
                            }

                            ]}
                        >
                            <TextArea placeholder='Enter Address' />
                        </Form.Item>
                        <Row justify='space-between'>
                            <Form.Item
                                label='Pincode'
                                name={['pickup_info', 'pincode']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[{ required: true, message: 'Enter Pincode' }]}
                            >
                                <InputNumber maxLength={6} minLength={6} style={{ width: '250px' }} placeholder='Enter Pincode' />
                            </Form.Item>
                            <Form.Item
                                label='Landmark'
                                name={['pickup_info', 'landmark']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[{ required: false, message: 'Enter Landmark' }]}
                            >
                                <Input style={{ width: '250px' }} placeholder='Enter Landmark' />
                            </Form.Item>
                        </Row>
                        <Row justify='space-between'>
                            <Form.Item
                                label='District'
                                name={['pickup_info', 'district']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[{ required: false, message: 'Enter District' }]}
                            >
                                <Input style={{ width: '250px' }} placeholder='Enter District' />
                            </Form.Item>
                            <Form.Item
                                label='City'
                                name={['pickup_info', 'city']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[{ required: true, message: 'Enter City' }]}
                            >
                                <Input style={{ width: '250px' }} placeholder='Enter City' />
                            </Form.Item>
                        </Row>
                        <Row justify='space-between'>
                            <Form.Item
                                label='State'
                                name={['pickup_info', 'state']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[{ required: true, message: 'Enter State' }]}
                            >
                                <Input style={{ width: '250px' }} placeholder='Enter State' />
                            </Form.Item>
                            <Form.Item
                                label='Country'
                                name={['pickup_info', 'country']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[{ required: true, message: 'Enter Country' }]}
                            >
                                <Input style={{ width: '250px' }} placeholder='Enter Country' />
                            </Form.Item>
                        </Row>
                        <Button type='primary' style={{ width: '250px', marginTop: '10px' }} htmlType="submit">Create</Button>
                    </Col>
                </Form>
            </Drawer >
        </>
    )
}

export default CreateDeliveryOrder
