import axios from 'axios';
import { Service } from 'axios-middleware';
import Cookies from 'js-cookie';


export const logoutUser = () => {
    Cookies.remove('gauth-token')
    localStorage.removeItem('userinfo')
    window.location.href = '/'
}

const client = axios;

const service = new Service(axios);

service.register({
    // onRequest(config) {
    //     console.log('onRequest');
    //     return config;
    // },
    // onSync(promise) {
    //     console.log('onSync');
    //     return promise;
    // },
    // onResponse(response: any) {
    //     console.log('onResponse');
    //     return response;
    // },
    onResponseError(error: any) {
        if (error?.response?.status === 401) {
            if (error?.response?.config?.method === 'get') {
                logoutUser()
            }
        }
        return Promise.reject(error);
    }
});

console.log('Ready to fetch.');

export const apiClient = client;