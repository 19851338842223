import { throwError } from '../../../utils/helper';
type Item = {
  image_attachment_id: string;
  title: string;
  cta_action_type: string;
  r_song_id?: number;
  song_of_the_day?: boolean;
  r_article_id: number;
  r_god_id?: number;
  video_attachment_id: string;
  cta_redirection_type: string;
  video_attachment_type?: string;
};
type HeaderTags = {
  text: string;
  color: string;
};
class ContentCollection {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header!: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  header_tags!: HeaderTags[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  items: Item[] = [];

  getSerializedData = (widgetFormData: ContentCollection) => {
    let items = widgetFormData?.items.map((item: Item): Item => {
      delete item.video_attachment_type;
      if (item.song_of_the_day) delete item.r_song_id;
      if (!item.song_of_the_day) delete item.song_of_the_day;
      if (item?.r_god_id === 0) delete item.r_god_id;
      return {
        ...item,
      };
    });
    widgetFormData.items = items;
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: ContentCollection) => {
    let itemModified = widgetFormData.items.map((item: Item) => {
      return { ...item };
    });
    widgetFormData.items = itemModified;
    return widgetFormData;
  };

  headerValidator = async (rule: any, value: any) => {
    return;
  };
  headerTagsTextValidator = async (rule: any, value: any) => {
    if (!value) throwError('Header tags text required!');
  };
  headerTagsColorValidator = async (rule: any, value: any) => {
    if (!value) throwError('Header tags color required!');
  };
  imageAttachmentValidator = async (rule: any, value: any) => {
    if (!value) throwError('Image Attachment required!');
  };
  titleValidator = async (rule: any, value: any) => {
    if (!value) throwError('Title required!');
  };
  articleIdValidator = async (rule: any, value: any) => {
    if (value <= 0) {
      throwError('Article Id must be greater than 0');
    }
    if (!value) throwError('Article Id required!');
  };

  ctaActionValidator = async (rule: any, value: any) => {
    if (!value) throwError('CTA Action Type required!');
  };
  songIdValidator = async (rule: any, value: any) => {
    if (!value) throwError('Song ID required!');
  };
  redirectionTypeValidator = async (rule: any, value: any) => {
    if (!value) throwError('Redirection Type required!');
  };
  thumbnailImageValidator = async (rule: any, value: any) => {
    if (!value) throwError('Thumbnail Image required!');
  };
  videoAttachmentTypeValidator = async (rule: any, value: any) => {
    if (!value) throwError('Video Attachment Type required!');
  };
  videoRefCodeValidator = async (rule: any, value: any) => {
    if (!value) throwError('Video ref Code required!');
  };
}
export default ContentCollection;
export const ContentCollectionInstance = new ContentCollection();
