import { ReloadOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Col } from "antd";

interface Props {
    text: string
}

const Error: React.FC<Props> = ({ text }) => {
    return (
        <Col style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '20%', alignItems: 'center', fontWeight: '600', fontSize: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <span><WarningOutlined /></span>
                <span style={{ marginLeft: '10px' }}>
                    {text}
                </span>
            </div>
            <Button style={{ marginTop: '10px' }} type='primary' onClick={() => window.location.reload()}>
                <ReloadOutlined />
                <span style={{ marginLeft: '10px' }}>
                    Reload
                </span>
            </Button>
        </Col>
    )
}

export default Error