import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useCallback, useState } from 'react'
import 'antd/dist/antd.css';
import { Card } from 'antd';
import PlaylistFilter, { PlaylistFilters } from '@a4b/ui/src/modules/admin/components/music/PlaylistFilter';
import CsvUploadUpdatePlaylist from './CSVUploadUpdatePlayList';



const defaultFilter: PlaylistFilters = {
}


const PlaylistBulkUpdate = () => {
  const { networkInstance } = useAppContext();
  const [filters, setFilters] = useState(defaultFilter);

  const generateAudioCsv = useCallback(async (filters) => {
    try {
      try {
        await networkInstance.clientWithHeaders.contentApi.audio.getBulkPlaylistUpdateData(filters.language, filters.playlist_type)
      } catch (error) {
      }
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.contentApi.audio])

  return (
    <Card
      title='Playlist bulk update'
      extra={<CsvUploadUpdatePlaylist />}
    >
      <PlaylistFilter
        filter={filters}
        onApplyFilterClicked={function (_filter: PlaylistFilters): void {
          generateAudioCsv(_filter);
          setFilters(_filter);
        }}
      />
    </Card>
  )
}

export default withDefaultLayout(PlaylistBulkUpdate)
