import { AttachmentAction, AttachmentState } from "@/utils/types";

export const getInitialState = (
  attachmentId: string | string[] | undefined
): AttachmentState => {
  let init = { currentIndex: 0 };
  if (Array.isArray(attachmentId)) {
    return {
      ...init,
      attachmentIdList: attachmentId.length ? attachmentId : [""],
    };
  } else {
    return { ...init, attachmentIdList: attachmentId ? [attachmentId] : [""] };
  }
};
export const attachmentReducer = (
  prevState: AttachmentState,
  action: AttachmentAction
): AttachmentState => {
  let { type, indexValue, ucCode } = action;
  let { attachmentIdList, currentIndex } = prevState;
  switch (type) {
    case "ADD":
      attachmentIdList.push("");
      return { ...prevState, attachmentIdList: [...attachmentIdList] };
    case "REMOVE":
      attachmentIdList.splice(action.indexValue, 1);
      return { ...prevState, attachmentIdList: [...attachmentIdList] };
    case "INSERT_UCCODE":
      attachmentIdList[currentIndex] = ucCode || "";
      return { ...prevState, attachmentIdList: [...attachmentIdList] };
    case "CLEAR":
      attachmentIdList[indexValue] = "";
      return { ...prevState, attachmentIdList: [...attachmentIdList] };
    case "CURRENT_INDEX":
      return { ...prevState, currentIndex: indexValue };
    default:
      return prevState;
  }
};
