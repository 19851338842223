import React from 'react';
import { Form, Select, FormInstance, Input, InputNumber, Checkbox } from "antd";
import styled from "styled-components";
import { REQUIRED_RULE } from "../../../../../utils/form.validation";
import { URL_TYPES, LANGUAGE_TYPES, IMAGE_TYPES, NUMBER_TYPES, useJourneyContext, TEMPLATE_TYPES, LIVE_SUPPORTED_LANGUAGES, BOOLEANS_TYPES } from "../../Journey/utils/contants";
import UploadImage from "./UploadImage";
import { NudgeConfiguration } from "../../Journey/utils/types";
import NPSFeedbackTemplate from "../../Journey/components/templates/NPSFeedbackTemplate";



const LanguageFields = styled.div<{ attribute: string, backgroundColor?: string }>`
  border: 1px dotted #000;
  position: relative;
  border-radius: 5px;
  margin: 30px 0px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  padding-top: 30px;
  &::before {
    content: ${(props: { attribute: string }) => `"${props.attribute.toUpperCase()}"`};
    display: block;
    position: absolute;
    margin-left: 20px;
    margin-top: 0px;
    transform: translateY(calc(-50% - 30px));
    padding: 0px 15px; 
    font-size: 20px;
    background-color: ${props => props.backgroundColor || "#fff"};
  }
`
interface NudgeFormProps {
  form: FormInstance<any>,
  headingBackground?: string,
  type: "fallback" | "affirmative"
}

const renderAttribute = (form: FormInstance<any>, attribute: string, headingBackground?: string) => {
  switch (true) {
    case URL_TYPES.has(attribute):
      return <Form.Item
        name={["attributes", attribute]}
        rules={[REQUIRED_RULE[0], {
          type: "url",
          message: "This field must be a valid url."
        }]}
        label={attribute}
      >
        <Input></Input>
      </Form.Item>
    case LANGUAGE_TYPES.has(attribute):
      return <LanguageFields attribute={attribute} backgroundColor={headingBackground}>
        {LIVE_SUPPORTED_LANGUAGES.map((lang) => {
          return <Form.Item
            key={lang.value}
            name={["attributes", attribute, lang.value]}
            rules={lang.value === 'hi' ? REQUIRED_RULE : []}
            label={lang.alt_name}
            style={{ width: '100%' }}
          >
            <Input></Input>
          </Form.Item>
        })}
      </LanguageFields>
    case IMAGE_TYPES.has(attribute):
      return <UploadImage imagePath={form.getFieldValue(["attributes", attribute])} attribute={attribute} form={form}></UploadImage>
    case NUMBER_TYPES.has(attribute):
      return <Form.Item
        name={["attributes", attribute]}
        rules={REQUIRED_RULE}
        label={attribute}
      >
        <InputNumber />
      </Form.Item>
    case BOOLEANS_TYPES.has(attribute):
      return <Form.Item
        name={["attributes", attribute]}
        rules={REQUIRED_RULE}
        label={attribute}
        valuePropName='checked'
      >
        <Checkbox />
      </Form.Item>
    case TEMPLATE_TYPES.has(attribute):
      return <NPSFeedbackTemplate form={form} backgroundColor={headingBackground as string} path={["attributes", attribute]}></NPSFeedbackTemplate>
    default:
      return <Form.Item
        name={["attributes", attribute]}
        rules={REQUIRED_RULE}
        label={attribute}
      >
        <Input />
      </Form.Item>
  }
};

const NudgeForm = ({ form, headingBackground, type }: NudgeFormProps) => {
  const journeyContext = useJourneyContext();
  const { nudgeConfiguration: NUDGE_TEMPLATES } = journeyContext;
  const template: keyof NudgeConfiguration = Form.useWatch(["template"], form);
  const page_tag: string = Form.useWatch(["page_tag"], form);
  const anchor_tag: string = Form.useWatch(["anchor_tag"], form);
  return <>
    {
      type === 'affirmative' && <>
        <Form.Item label="Wait interval in seconds" name={["wait_interval_seconds"]} rules={[{
          validator(rule, value, callback) {
            if (value >= 0 && value <= 1000) {
              callback();
            } else {
              callback("Wait interval in seconds should be >= 0 and <= 1000");
            }
          },
        }]} initialValue={0}>
          <InputNumber onChange={(value: number) => { form.setFieldValue(["wait_interval_millis"], (value || 0) * 1000) }}></InputNumber>
        </Form.Item>
        <Form.Item name={["wait_interval_millis"]} style={{ display: 'none' }} initialValue={0}>
          <InputNumber ></InputNumber>
        </Form.Item>
      </>
    }
    <Form.Item rules={REQUIRED_RULE} name={["template"]} label="Select template">
      <Select showSearch onChange={() => {
        form.setFieldValue(["page_tag"], undefined);
        form.setFieldValue(["anchor_tag"], undefined);
      }}>
        {
          Object.keys(NUDGE_TEMPLATES).map((template) => {
            return <Select.Option key={template} value={template}>{template}
            </Select.Option>
          })
        }
      </Select>
    </Form.Item>
    <Form.Item rules={REQUIRED_RULE} name={["page_tag"]} label="Select page_tag">
      <Select showSearch disabled={!template} onChange={() => {
        form.setFieldValue(["anchor_tag"], undefined);
      }}>
        {
          template && NUDGE_TEMPLATES[template] && Object.keys(NUDGE_TEMPLATES[template]).map((page_tag) => {
            return <Select.Option key={page_tag} value={page_tag}>{page_tag}
            </Select.Option>
          })
        }
      </Select>
    </Form.Item>
    <Form.Item rules={REQUIRED_RULE} name={["anchor_tag"]} label="Select anchor tag">
      <Select showSearch disabled={!page_tag}>
        {
          template && page_tag &&
          NUDGE_TEMPLATES[template] &&
          // @ts-ignore
          NUDGE_TEMPLATES[template][page_tag] && Object.keys(NUDGE_TEMPLATES[template][page_tag]).map((anchor_tag) => {
            return <Select.Option key={anchor_tag} value={anchor_tag}>{anchor_tag}
            </Select.Option>
          })
        }
      </Select>
    </Form.Item>
    {
      template && page_tag && anchor_tag &&
      NUDGE_TEMPLATES[template] &&
      // @ts-ignore
      NUDGE_TEMPLATES[template][page_tag][anchor_tag] && NUDGE_TEMPLATES[template][page_tag][anchor_tag].map((attribute, index) => {
        return renderAttribute(form, attribute, headingBackground);
      })
    }
  </>;
};

export default NudgeForm;