
export const REQUIRED_RULE = [{ required: true }]
export const ONLY_ALPHA_NUMARIC_RULE = { pattern: /^[a-zA-Z0-9_]*$/, message: 'Hyphens and white space is not allowed' }
export const SUPPORTED_IMAGE_ACCEPT_TYPE = '.png,.jpg,.jpeg,.gif,.webp';

export const godCodeValidation = (rule: any, value: any, callback: (value: string | undefined) => void) => {
    let regex = new RegExp(/^[a-z0-9_]+$/);
    if (!regex.test(value)) callback("Only small case english latters, numbers and _ is allowed."); else callback(undefined)
}


