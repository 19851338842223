export const widgetApiMessages = {
  widgetCreateError: "Error occured while creating widget.",
  widgetExistError: "Widget code you are trying to create is already exist.",
  widgetUpdateError: "Error occured while updating the widget.",
  widgetCreateSuccess: "Widget created sucessfully.",
  widgetUpdateSuccess: "Widget updated sucessfully.",
  widgetDeleteSuccess: "Widget deleted sucessfully.",
  widgetDeactivatedSuccess: "Widget de-activated sucessfully.",
  widgetDeleteError: "Error occured while deleting widget.",
  masterWidgetGetError: "Error occured while getting master widget.",
  widgetListGetError: "Error occured while getting widget list.",
  widgetGetErrorForEdit:
    "Error occured while getting widget for edit. Please try again later.",
  widgetDisableError:
    "Error occured while deactivating widget. Please try again later.",
};
export const scheduleApiMessages = {
  DELETE_FAILED: "Error occured while deleting schedule",
  DELETE_SUCCESS: "Schedule deleted sucessfully",
  DISABLE_SUCCESS: "Schedule Updated sucessfully",
  DISABLE_ERROR:
    "Error occured while deactivating schedule. Please try again later.",
};
export const getErrorByType: any = {
  DB_DUPLICATE_ENTRY: "Duplicate reference code.",
  API_RESPONSE_FAILED: "File upload failed.",
};
