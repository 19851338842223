//hooks
import { useEffect, useState, useCallback } from 'react'

//context
import { useAppContext } from '@/components/AppContext'

//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { Form, message, Modal } from 'antd'
import type { TablePaginationConfig } from 'antd/es/table'

//organisms
import TempleListTablePage from '@a4b/ui/src/modules/monetisation/organisms/TempleListTablePage'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'

//types
import { Temple, TempleDetail } from '@a4b/api/src/modules/Content/Temples/types'
import { parseCityList, parseGodList, parseTempleListData } from './helper'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'

import AvatarUploadModal from './AvatarUploadModal'
import QRPreviewModal from './QrPreviewModal'

const getPageBreadcrumbs = () => [
    {
        path: '/temples/manage-cities',
        breadcrumbName: 'Manage City',
    },
    {
        path: '/temples/manage-temples',
        breadcrumbName: 'Manage Temples',
    },
]

type FilterValueType = string | boolean | CheckboxChangeEvent
interface FiltersType {
    isAvatarOnBoarded: boolean | undefined
    isActive: boolean | undefined
    cityCode: string
    referenceName: string
}
export interface List {
    label: string
    value: string
    thumbnail?: string
}

const TempleList = () => {
    const { networkInstance } = useAppContext()
    const { clientWithHeaders } = networkInstance
    const [avatarForm] = Form.useForm()

    const [showTempleEnableModal, setShowTempleEnableModal] = useState<boolean>(false)
    const [showTempleDisableModal, setShowTempleDisableModal] = useState<boolean>(false)

    const [showAvatarEnableModal, setShowAvatarEnableModal] = useState<boolean>(false)
    const [templeDetail, setTempleDetail] = useState<TempleDetail>()
    const [showQRModal, setShowQRModal] = useState<boolean>(false)
    const [qrcodeValue, setQrcodeValue] = useState<string>()
    const [isAvatarEdit, setIsAvatarEdit] = useState<boolean>(false)

    const [cityList, setCityList] = useState<List[]>()
    const [templeList, setTempleList] = useState<Temple[]>()
    const [godList, setGodList] = useState<List[]>()
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
    })
    const [currentTemple, setCurrentTemple] = useState<any>()
    const [filters, setFilters] = useState<FiltersType>({
        isAvatarOnBoarded: undefined,
        isActive: undefined,
        cityCode: '',
        referenceName: ''
    })

    const getTempleList = useCallback(async () => {
        try {
            const templeListResponse = await clientWithHeaders.contentApi.temples.getTempleList({
                limit: pagination.pageSize!,
                offset:
                    Number((pagination?.current || 1) - 1) * Number(pagination.pageSize),
            }, filters.referenceName, filters.cityCode, filters.isActive, filters.isAvatarOnBoarded)
            if (!templeListResponse?.data?.data) {
                message.info('No data found', 2)
                setTempleList([])
                setPagination({ ...pagination, total: 0 })
                return
            }
            const parsedTempleList = parseTempleListData(templeListResponse?.data?.data)
            setTempleList(parsedTempleList)
            setPagination({ ...pagination, total: templeListResponse?.data?.total_count || 10 })
        } catch (error) {
            message.error('Error while fetching Temples', 3)
        }
    }, [clientWithHeaders.contentApi.temples, pagination?.current, filters])

    const getCityList = useCallback(async () => {
        try {
            const cityListResponse = await clientWithHeaders.contentApi.temples.getCityList()
            if (!cityListResponse?.data?.data?.Cities?.length) {
                setCityList([])
                message.info('No cities found', 2)
                return
            }
            const parsedCityResponse = parseCityList(cityListResponse?.data?.data?.Cities)
            setCityList(parsedCityResponse)
        } catch (error) {
            message.error('Error while fetching Cities', 3)
        }
    }, [clientWithHeaders.contentApi.temples])

    const getGodList = useCallback(async () => {
        try {
            const godListResponse = await clientWithHeaders.contentApi.temples.getGods('hi')
            const parsedGodList = parseGodList(godListResponse?.data?.data?.gods)
            setGodList(parsedGodList)
        } catch (error) {
            console.log('Error while fetching Gods')
        }
    }, [clientWithHeaders.contentApi.temples])


    useEffect(() => {
        getTempleList()
    }, [getTempleList])

    useEffect(() => {
        getCityList()
    }, [getCityList])

    useEffect(() => {
        getGodList()
    }, [getGodList])


    const onStatusChange = (item: any) => {
        setCurrentTemple(item)
        if (item?.is_active) {
            setShowTempleDisableModal(true)
        } else {
            setShowTempleEnableModal(true)
        }
    }

    const onModalOk = async () => {
        message.loading({
            content: 'Updating store status',
            duration: 1,
            key: 'update_status',
        })
        try {
            await clientWithHeaders.contentApi.temples.updateTempleStatus(currentTemple?.id, { is_active: !currentTemple?.is_active })
            setShowTempleEnableModal(false)
            setShowTempleDisableModal(false)
            message.success({
                content: 'Temple status updated sucessfully',
                duration: 2,
                key: 'update_status',
            })
            setTimeout(function () {
                getTempleList()
            }, 2000)
        } catch (error) {
            setShowTempleEnableModal(false)
            setShowTempleDisableModal(false)
            message.error({
                content: 'Error while updating temple status',
                duration: 2,
                key: 'update_status',
            })
        }
    }

    const onModalCancel = () => {
        setShowTempleEnableModal(false)
        setShowTempleDisableModal(false)
    }

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPagination({
            ...pagination,
        })
    }

    const handleFilters = (type: string, value: FilterValueType) => {
        switch (type) {
            case 'avatarOnBoard':
                if (typeof value === 'object' && value !== null) {
                    setFilters(prevFilters => ({
                        ...prevFilters,
                        isAvatarOnBoarded: value?.target?.checked
                    }))
                }
                break
            case 'active':
                if (typeof value === 'boolean' || typeof value === "undefined") {
                    setFilters(prevFilters => ({
                        ...prevFilters,
                        isActive: value
                    }))
                }
                break
            case 'city':
                if (typeof value === 'string' || typeof value === "undefined") {
                    setFilters(prevFilters => ({
                        ...prevFilters,
                        cityCode: value
                    }))
                }
                break
            case 'referenceName':
                if (typeof value === 'string' || typeof value === "undefined") {
                    setFilters(prevFilters => ({
                        ...prevFilters,
                        referenceName: value
                    }))
                }
                break
        }
    }

    const generateQRCode = (link: string) => {
        setQrcodeValue(link)
        setShowQRModal(true)
    }

    const handleAvatarChange = async (item: any, isAvatarEdit = false) => {
        setIsAvatarEdit(isAvatarEdit)
        avatarForm?.setFieldsValue({
            ...avatarForm,
            god_id: item.avatar_details?.[0]?.god_id,
            avatar_link: [item.avatar_details?.[0]?.avatar_link],
            language_code: 'hi',
            onboard_on_regional_temple: item.avatar_details?.[0]?.onboard_on_regional_temple,
        })
        setShowAvatarEnableModal(true)
        setTempleDetail({
            ...templeDetail,
            id: item?.id,
            is_avatar_enabled: item?.is_avatar_enabled,
            avatar_details: item?.avatar_details,
            name: item?.reference_name,
            temple_code: item?.temple_code
        })
    }

    const handleUploadAvatar = () => {
        avatarForm
            .validateFields()
            .then(async (values: any) => {
                if (isAvatarEdit) {
                    try {
                        await clientWithHeaders.contentApi.temples.updateAvatarUrl(
                            templeDetail?.id!,
                            [{ god_id: values?.god_id, avatar_url: values?.avatar_link?.[0] }]
                        )
                        avatarForm?.resetFields()
                        message.success('Avatar updated successfully', 2)
                    } catch (e) {
                        avatarForm?.resetFields()
                        message.error('Error while updating avatar', 2)
                    }
                } else {
                    if (templeDetail?.is_avatar_enabled) {
                        try {
                            await clientWithHeaders.contentApi.temples.enableAvatar(templeDetail?.id, {
                                is_enabled: false,
                                gods: [
                                    {
                                        language_code: values?.language_code,
                                        onboard_on_regional_temple: values?.onboard_on_regional_temple || false,
                                        god_id: values?.god_id
                                    }
                                ]
                            })
                            message.success('Avatar disabled successfully', 2)
                        } catch (e) {
                            message.error('Error while avatar disabling', 2)
                        }
                    } else {
                        try {
                            await clientWithHeaders.contentApi.temples.enableAvatar(templeDetail?.id!, {
                                is_enabled: true,
                                gods: [
                                    {
                                        language_code: values?.language_code,
                                        onboard_on_regional_temple: values?.onboard_on_regional_temple || false,
                                        god_id: values?.god_id
                                    }
                                ]
                            })
                            message.success('Avatar enabled successfully', 2)
                        } catch (e) {
                            message.error('Error while avatar enabling', 2)
                        }
                    }
                }
                setShowAvatarEnableModal(false)
                setTimeout(function () {
                    getTempleList()
                    setTempleDetail({
                        ...templeDetail,
                        id: '',
                        is_avatar_enabled: false,
                        avatar_details: [],
                        name: '',
                        temple_code: ''
                    })
                }, 1000)
            })
            .catch(error => {
                message.error('Please fill the required fields')
            })
    }

    const handleCancelAvatarModal = () => {
        setShowAvatarEnableModal(false)
        setIsAvatarEdit(false)
    }

    const handleCancelQrModal = () => {
        setShowQRModal(false)
        setQrcodeValue('')
    }

    return (
        <div style={{ padding: '0 40px' }}>
            <AdminBreadcrumb routes={getPageBreadcrumbs()} />
            <TempleListTablePage
                onStatusChange={onStatusChange}
                handleAvatarChange={handleAvatarChange}
                templeList={templeList!}
                handleTableChange={handleTableChange}
                pagination={pagination}
                handleFilters={handleFilters}
                cityList={cityList!}
                generateQR={generateQRCode}
            />
            <Modal
                title='Activate Temple'
                visible={showTempleEnableModal}
                onOk={onModalOk}
                onCancel={onModalCancel}
                okText=' Yes, Activate'
            >
                <p>Are you sure to activate this temple ?</p>
            </Modal>
            <Modal
                title='Deactivate Temple'
                visible={showTempleDisableModal}
                onOk={onModalOk}
                onCancel={onModalCancel}
                okText='Yes, Deactivate'
            >
                <p>Are you sure to Deactivate this temple ?</p>
            </Modal>
            <AvatarUploadModal
                showModal={showAvatarEnableModal}
                handleOk={handleUploadAvatar}
                handleCancel={handleCancelAvatarModal}
                form={avatarForm}
                godList={godList!}
                templeDetail={templeDetail!}
                isAvatarEdit={isAvatarEdit}
            />
            <QRPreviewModal
                showModal={showQRModal}
                handleOk={handleUploadAvatar}
                handleCancelQrModal={handleCancelQrModal}
                value={qrcodeValue!}
            />
        </div>
    )
}

export default withDefaultLayout(TempleList)
