import React from 'react';
import {
    Card,
    Col,
    Row,
    Select,
    Table,
    TableProps,
} from 'antd';
import styled from 'styled-components';

interface Props {
    tableConfig: TableProps<any>;
    sortBy: 'created_at' | 'position';
    isActive: 'true' | 'false'
    updateFilters: (updateType: 'sortBy' | 'isArchive' | 'isActive' | 'serviceType', value?: 'true' | 'false' | 'created_at' | 'position' | 'pooja') => void;
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`;

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`;

const PujaList: React.FC<Props> = ({
    tableConfig,
    sortBy,
    isActive,
    updateFilters,
}) => {
    const config: TableProps<any> = {
        ...tableConfig,
        pagination: false
    }

    return (
        <Container
            title='Manage Puja Updates'
            style={{ width: '100%' }}
            extra={
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col>
                        <label style={{ paddingLeft: '10px' }}>Filter by: </label>
                        <Select placeholder="Status" value={isActive} onChange={(value) => { updateFilters('isActive', value) }} style={{ width: '120px' }}>
                            <Select.Option value="true">Active</Select.Option>
                            <Select.Option value="false">Inactive</Select.Option>
                        </Select>
                        <label style={{ paddingLeft: '10px' }}>Sort by: </label>
                        <Select value={sortBy} onChange={(value) => { updateFilters('sortBy', value) }} style={{ width: '120px' }}>
                            <Select.Option value="created_at">Created at</Select.Option>
                            <Select.Option value="active">Active</Select.Option>
                            <Select.Option value="position">Position</Select.Option>
                        </Select>
                    </Col>
                </Row>
            }
        >
            <Row>
                <Col span={28}>
                    <Table {...config} />
                </Col>
            </Row>
            <LabelRightAlign>
            </LabelRightAlign>
        </Container>
    );
};

export default PujaList;
