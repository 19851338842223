
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination, { DEFAULT_PAGINATION_CONFIG } from "@/hooks/useAntdPagination";
import { Functionality } from "@a4b/api/src/modules/ShareText/types";
import { debounce } from "lodash";
import { useSearchParams, useNavigate, useLocation, createSearchParams } from "react-router-dom";

const ShareTextFunctionality = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const { networkInstance } = useAppContext();
  const { pagination, setPagination } = useAntdPagination();
  const [shareFunctionlities, setShareFunctionalities] = useState<Functionality[] | null>()
  const [editFunctionality, setEditFunctionality] = useState<Functionality | null>();
  const [searchParams] = useSearchParams();
  const [functionality, setFunctionality] = useState(searchParams.get('functionality') || '');
  const [subFunctionality, setSubFunctionality] = useState(searchParams.get('sub_functionality') || '');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        functionality: functionality,
        sub_functionality: subFunctionality
      }).toString()
    });
  }, [functionality, location.pathname, navigate, subFunctionality])


  const getShareFunctionalities = useCallback(async (functionalityText: string, subFunctionalityText: string) => {
    try {
      const res = await networkInstance.clientWithHeaders.shareText.searchShareFunctionalities((pagination.pageSize) || 50, ((pagination.current || 1) - 1) * (pagination.pageSize || 50), { functionality_text: functionalityText, sub_functionality_text: subFunctionalityText });
      setShareFunctionalities(res.data.data.functionalities)
      if (pagination.total !== res.data.data.count) {
        setPagination({ ...DEFAULT_PAGINATION_CONFIG, total: res.data.data.count })
      }
    } catch (error) {
    }
  }, [networkInstance.clientWithHeaders.shareText, pagination, setPagination])


  const reloadShareFunctionality = useCallback(() => {
    console.log({ test: 'check it now ' })
    getShareFunctionalities(functionality, subFunctionality);
  }, [functionality, getShareFunctionalities, subFunctionality]);


  const createShareText = useCallback((functionalityCode: string) => {
    navigate({
      pathname: '/share-text/listing',
      search: createSearchParams({
        selectedShareFunctionlity: functionalityCode,
      }).toString()
    })
  }, [navigate]);

  const getShareFunctionalitiesDebounce = useMemo(() => debounce(getShareFunctionalities, 2000), [getShareFunctionalities])

  const onChangeFunctionality = useCallback((value: string) => {
    setFunctionality(value);
  }, [])
  const onChangeSubFunctionality = useCallback((value: string) => {
    setSubFunctionality(value);
  }, [])


  useEffect(() => {
    getShareFunctionalitiesDebounce(functionality, subFunctionality)
  }, [functionality, getShareFunctionalitiesDebounce, subFunctionality])

  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }

  const onCreateFunctionality = useCallback(() => {
    setActiveDrawer(DRAWER_TYPE.CREATE_FUNCTIONALITY);
  }, [])

  const onDrawerClose = useCallback(() => {
    setActiveDrawer(undefined);
  }, [])

  // Drawer config 
  const drawerConfig = useDrawerConfig(reloadShareFunctionality, activeDrawer, editFunctionality);
  const { drawerProps, drawerContent } = drawerConfig;
  // table config 
  const tableConfig = useTableConfig(shareFunctionlities || [], pagination, setPagination, setDrawer, setEditFunctionality, createShareText);

  return <SocialScreenContainer
    title={"Share functionality"}
    filtersUI={<Filters reloadShareFunctionality={reloadShareFunctionality} functionality={functionality} setFunctionality={onChangeFunctionality} subFunctionality={subFunctionality} setSubFunctionality={onChangeSubFunctionality} />}
    extra={<Button onClick={onCreateFunctionality}> Create share functionality </Button>}
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />
};

export default withDefaultLayout(ShareTextFunctionality)