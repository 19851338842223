import React from 'react'

import { AudioPlaylist } from '@a4b/api'
import { Table, Image, Tag, Button } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { PresetColorTypes } from 'antd/es/_util/colors';
import { getLanguageNameFromValue } from '../../../constants';

export interface Props {
  playlists: AudioPlaylist[],
  pageSize: number;
  loading: boolean,
  totalCount: number,
  cdnHost: string,
  onPageChanged: (page: number, pageSize: number) => void;
  onViewPlaylist: (playlist: AudioPlaylist) => void
  onClonePlaylist: (playlist: AudioPlaylist) => void
}

const getColumns = (cdnHost: string, onViewPlaylist: (playlist: AudioPlaylist) => void, onClonePlaylist: (playlist: AudioPlaylist) => void): ColumnsType<AudioPlaylist> => [
  {

    title: 'thumbnail',
    dataIndex: 'thumbnail_path',
    key: 'thumbnail_path',
    render: (thumbnail_path: string) => {
      return <Image src={`${cdnHost}${thumbnail_path}`} width={50} preview={false} />
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Audios Count',
    dataIndex: 'audios',
    key: 'audios',
    render: (audios) => {
      return <>{audios.length}</>
    }
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Language',
    dataIndex: 'language_code',
    render: (language: string) => getLanguageNameFromValue(language)
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    render: (active: boolean) => {
      return <div>{active.toString()}</div>
    }
  },
  {
    title: 'Days',
    dataIndex: 'day_eligibility',
    key: 'day_eligibility',
    width: '20%',
    render: (day_eligibility: string[]) => {
      return (
        <>
          {(day_eligibility || []).map((day, index) => {
            const colorIndex = (day.charCodeAt(0) * day.charCodeAt(2)) % PresetColorTypes.length
            return (
              <Tag color={PresetColorTypes[colorIndex]} key={index} style={{ margin: '5px' }}>
                {day}
              </Tag>
            )
          })}
        </>
      )
    },
  },
  {
    title: 'Actions',
    render: (_, record) => {
      return <>
        <Button type='primary' size="small" onClick={onViewPlaylist.bind(this, record)}>View </Button>
        <br></br>
        <Button style={{ marginTop: 5 }} type='primary' size="small" onClick={onClonePlaylist.bind(this, record)}>Clone </Button>
      </>
    }
  }
]

export const PlaylistsTable: React.FC<Props> = ({ cdnHost, playlists, loading, pageSize, totalCount, onPageChanged, onViewPlaylist, onClonePlaylist }) => {
  return <Table
    data-testid='playlist-table'
    bordered
    size='small'
    loading={loading}
    columns={getColumns(cdnHost, onViewPlaylist, onClonePlaylist)}
    rowKey={record => record.id}
    dataSource={playlists}
    pagination={{
      hideOnSinglePage: true,
      onChange: onPageChanged,
      total: totalCount,
      pageSize
    }}
  />
}