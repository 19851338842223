import { useCallback, useContext, useState } from 'react';
import { Affirmative, Fallback, IJourneyLevels, Journey } from "../utils/types";
import JourneyForm from "./JourneyForm";
import JourneyLevel from './JourenyLevel';
import { Button } from 'antd';
import { useAppContext } from '../../../../../components/AppContext';
import { openErrorNotification, openNotification } from '../../../../../utils';
import { useJourneyContext } from '../utils/contants';
import styled from 'styled-components';
import React from 'react';

const SaveJourneyButton = styled(Button)`
  margin-bottom: 20px;
`;
export interface Props {
  journey: Journey;
}

/*  
  Definations 
  1. Journey: - A root node of tree. which contains children nodes. affirmitive and fallback.
  2. Affirmative: - A node which contains children nodes. affirmitive and fallback.
  3. Fallback: - A child node for affirmative node. It can't have children nodes.
  4. Node: - A node which can be either journey, affirmative or fallback.
  node.identifier: - A unique identifier for node. It will be a array of numbers with length of 2. o index for rows and 1 index for type.
  index 0 will be fallback and index 1 will be affirmative.

  TODO - Remove type in children nodes. With identifier we can identify the type of node.
*/
interface CreateJourneyProps {
  journey?: Journey;
}
const CreateJourney = (props: CreateJourneyProps) => {
  const { journey: editJourney } = props;
  const [journey, setJourney] = useState<Journey | undefined>(editJourney || undefined);
  const { networkInstance } = useAppContext();
  const { reloadJourneys } = useJourneyContext();

  const createJourney = useCallback(async () => {
    if (journey) {
      try {
        const { data } = await networkInstance.clientWithHeaders.inAppNudge.createJournay(journey);
        openNotification("success", { message: `( ${journey.name} ) Journey created successfully ` });
        reloadJourneys();
        setJourney(undefined);
      } catch (error) {
        // @ts-ignore
        openErrorNotification(error);
      }
    }
  }, [journey, networkInstance.clientWithHeaders.inAppNudge, reloadJourneys]);

  const updateJourney = useCallback(async () => {
    if (journey) {
      try {
        const { data } = await networkInstance.clientWithHeaders.inAppNudge.editJournay(journey);
        reloadJourneys();
        openNotification("success", { message: `( ${journey.name} ) Journey updated successfully ` });
      } catch (error) {
        // @ts-ignore
        openErrorNotification(error);
      }
    }
  }, [journey, networkInstance.clientWithHeaders.inAppNudge, reloadJourneys]);



  const createNode = useCallback((parentIdentifier: [number, number], nodeData: { type: 'affirmative', values: Affirmative | null | undefined } | { type: 'fallback', values: Fallback | null | undefined }) => {
    const addNode = (parentIdentifier: [number, number], node: Journey | Affirmative | Fallback) => {
      if (!node) {
        return null;
      }
      const { identifier, affirmative } = node;
      if (parentIdentifier.toString() === identifier.toString()) {
        if (nodeData.type === "affirmative") {

          node.affirmative = nodeData.values;
          if (node.affirmative) {
            node.affirmative.identifier = [identifier[0] + 1, 1];
          }
        }
        if (nodeData.type === "fallback" && 'fallback' in node) {
          node.fallback = nodeData.values;
          if (node.fallback) {
            node.fallback.identifier = [identifier[0] + 1, 0];
          }
        }
      }
      if (affirmative) {
        addNode(parentIdentifier, affirmative);
      }
      if ('fallback' in node && node.fallback) {
        addNode(parentIdentifier, node.fallback);
      }
    }
    if (journey) {
      addNode(parentIdentifier, journey);
      setJourney({ ...journey });
    }
  }, [journey])

  const updateNode = useCallback((identifier: [number, number], nodeData: Affirmative | Fallback | Journey) => {
    const update = (identifier: [number, number], node: Affirmative | Journey | Fallback) => {
      if (!node) {
        return null;
      }
      const { identifier: currentNodeIdentifier, affirmative } = node;
      if (identifier.toString() === currentNodeIdentifier.toString()) {
        return { ...node, nudge: null, trigger_count_cap: null, ...nodeData };
      }
      if (affirmative) {
        node.affirmative = update(identifier, affirmative) as Affirmative;
      }
      if ('fallback' in node && node.fallback) {
        node.fallback = update(identifier, node.fallback) as Fallback;
      }
      return node;
    }

    if (journey) {
      const updatedJourney = update(identifier, journey);
      setJourney({ ...journey, ...updatedJourney });
      return
    }
  }, [journey])

  const deleteNode = useCallback((identifier: [number, number]) => {
    const remove = (identifier: [number, number], node: Affirmative | Journey | Fallback | undefined) => {
      if (!node) {
        return null;
      }
      const { identifier: currentNodeIdentifier, affirmative } = node;

      if (currentNodeIdentifier.toString() === identifier.toString()) {
        node = undefined;
        return node;
      }
      if (affirmative) {
        node.affirmative = remove(identifier, affirmative) as Affirmative;
      }
      if ('fallback' in node && node.fallback) {
        node.fallback = remove(identifier, node.fallback) as Fallback;
      }
      return node;
    }

    if (journey) {
      const updatedJourney = remove(identifier, journey) as Affirmative;
      setJourney({ ...journey, ...updatedJourney });
    }
  }, [journey])


  const createJourneyLevels = (journeyLevels: IJourneyLevels, affirmative?: Affirmative | Journey | null, fallback?: Fallback | null,) => {
    if (affirmative === undefined) {
      return null;
    }
    journeyLevels.push([fallback, affirmative]);
    createJourneyLevels(journeyLevels, affirmative?.affirmative, affirmative ? affirmative.fallback : undefined,)
  }

  const journeyLevels: IJourneyLevels = [];
  createJourneyLevels(journeyLevels, journey,);

  return <div style={{
    backgroundSize: '5px 5px',
    padding: '20px',
    overflow: 'scroll'
  }}>
    {

      journey ? <>
        {
          editJourney ?
            <SaveJourneyButton disabled={!journey.affirmative} type="primary" name="" onClick={updateJourney}  >Update journey</SaveJourneyButton>
            : <SaveJourneyButton disabled={!journey.affirmative} type="primary" name="" onClick={createJourney}  >Save journey </SaveJourneyButton>
        }
        {
          journeyLevels.map((level, index) => {
            return <JourneyLevel key={index} level={index} row={level} nextRow={index < journeyLevels.length ? journeyLevels[index + 1] : undefined} createNode={createNode} updateNode={updateNode} deleteNode={deleteNode} />
          })
        }
        <JourneyLevel level={journeyLevels.length} newAffirmativeParent={[journeyLevels.length - 1, 1]} row={[undefined, journeyLevels.length === 1 ? null : undefined]} createNode={createNode} updateNode={updateNode} deleteNode={deleteNode} />
      </>
        : <JourneyForm onSubmit={(values: Journey) => { setJourney({ ...values, identifier: [0, 1] }) }} />
    }
  </div >
};

export default CreateJourney;