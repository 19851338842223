import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import {
  Batch,
  BatchData,
  BatchParam,
  CompleteBatch,
  GenerateBatch,
  OrderMediaUploadPayload,
  Response,
  Translate,
  TransliterationResponse,
} from './types'

const translateDomain =
  process.env.REACT_APP_ADMIN_BASE_URL || 'https://api-admin.a4b.io'

class BatchApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getBatchList = (batchParam: BatchParam) =>
    this.api.get<Response<BatchData>>(`/yoda/internal/v1/batches/get-batches`, {
      params: batchParam,
    })
  completeBatch = (batchId: string, payload: CompleteBatch) =>
    this.api.put<Response<any>>(`/yoda/internal/v1/batches/${batchId}`, payload)

  uploadOrderMedia = (batchId: string, payload: OrderMediaUploadPayload) =>
    this.api.patch<Response<any>>(
      `/yoda/internal/v1/batches/${batchId}`,
      payload,
    )

  translateOfferingNamesToHindi = (batchId: string) =>
    this.api.post<TransliterationResponse>(`/gw1/yoda/internal/v1/batches/${batchId}/transliterate`)

  translateText = async (batchid: string, nameList: string[]) => {
    const nameListString = encodeURIComponent(JSON.stringify(nameList))
    return await axios.get<Translate>(
      `${translateDomain}/api/translate?batch_id=${batchid}&namelist=${nameListString}`,
    )
  }
  translateToEnglish = async (batchid: string, nameList: string[]) => {
    const nameListString = encodeURIComponent(JSON.stringify(nameList))
    return await axios.get<Translate>(
      `${translateDomain}/api/translateToEnglish?batch_id=${batchid}&namelist=${nameListString}`,
    )
  }
  translateToEnglishPost = async (batchid: string, payload: any) => {
    return await axios.post<Translate>(
      `${translateDomain}/api/translateToEnglish?batch_id=${batchid}`,
      payload,
    )
  }

  listTranslate = async (list: string[], target = 'en', source?: string) => {
    return await axios.get<string[]>(
      `${translateDomain}/api/listTranslate?stringList=${encodeURI(
        JSON.stringify(list),
      )}&target=${target}${source ? '&source=' + source : ''}`,
    )
  }

  translateStringsToHindi = async (strings: string[]) => {
    return await axios.post<{
      data: {
        output: {
          total: number
          strings: string[]
        }
        input: {
          total: number
          strings: string[]
        }
      }
      message: string
    }>(
      `https://xen4v2owityekewgbam5e73lrm0sjoii.lambda-url.ap-south-1.on.aws`,
      {
        target: 'hi',
        strings,
      },
    )
  }

  translatePujaText = async (batchid: string, nameList: string[][]) => {
    const nameListString = encodeURIComponent(JSON.stringify(nameList))
    return await axios.get<string[][]>(
      `${translateDomain}/api/translatePuja?batch_id=${batchid}&namelist=${nameListString}`,
    )
  }
  translatePujaTextToEnglish = async (
    batchid: string,
    nameList: string[][],
  ) => {
    const nameListString = encodeURIComponent(JSON.stringify(nameList))
    return await axios.get<string[][]>(
      `${translateDomain}/api/translatePujaToEnglish?batch_id=${batchid}&namelist=${nameListString}`,
    )
  }
  uploadVideo = (payload: any) =>
    this.api.post<Response<any>>(`/yoda/system/process-video`, payload)

  generateBatches = (payload: any) =>
    this.api.post<Response<GenerateBatch>>(`/yoda/internal/v1/batches`, payload)

  searchBatches = (payload: string) =>
    this.api.get<Response<Batch>>(`/yoda/internal/v1/batches/${payload}`)

  createDeliveryOrder = (payload: any) =>
    this.api.post(
      '/gw1/yoda/internal/v1/batches/create-delivery-order',
      payload,
    )

  updateAssociate = (
    batchId: string,
    action: string,
    payload: any,
  ) =>
    this.api.patch<Response<any>>(
      `/gw1/yoda/internal/v1/batches/${batchId}/${action}`,
      payload,
    )
}

export default BatchApi
