import { Category } from "@a4b/api/src/modules/Monetisation/Puja/types/categories";
import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Image, Row, TableProps, Tag } from "antd";
import { TablePaginationConfig } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom'

const useTableConfig = (
    categoryList: Category[],
    isLoading: boolean,
    setShowCategoryDrawer: React.Dispatch<React.SetStateAction<boolean>>,
    setShowFilterDrawer: React.Dispatch<React.SetStateAction<boolean>>,
    setCurrentCategory: React.Dispatch<React.SetStateAction<Category | undefined>>
): TableProps<Category> => {
    const [pagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 1000,
    })
    const [dataSource, setDataSource] = useState(categoryList)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        setDataSource(categoryList)
    }, [categoryList])

    const dataColumns = [
        {
            title: 'Edit',
            key: 'edit',
            render: (text: any, record: any, index: any) => {
                return (
                    <Button style={{ border: 'none' }} onClick={() => {
                        setCurrentCategory(record)
                        setShowCategoryDrawer(true)
                        const newSearchParams = new URLSearchParams(searchParams.toString())
                        newSearchParams.set('edit', 'true')
                        setSearchParams(newSearchParams)
                    }}><EditOutlined /></Button>
                )
            },
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '25%',
            ellipsis: true
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },

        {
            title: 'View type',
            dataIndex: 'view_type',
            key: 'view_type',
            render: (text: any, record: any, index: any) => {
                return (
                    <Tag color="blue">{text}</Tag>
                )
            },
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (text: any, record: any, index: any) => {
                return (
                    <Image
                        width={40}
                        height={40}
                        src={text}
                    />
                )
            },
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
            render: (text: any, record: any, index: any) => {
                let platform;
                platform = record?.is_web ? 'web' : 'app'
                if (platform === 'web') {
                    return <Tag color="orange">web</Tag>
                } else {
                    return <Tag color="purple">app</Tag>
                }
            },
        },
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
            render: (text: any, record: any) => {
                if (text) {
                    return text
                } else {
                    return '-'
                }
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any, index: any) => {
                return (
                    <Row>
                        <Col span={6}>
                            <Button type='link' onClick={() => {
                                setCurrentCategory(record)
                                setShowFilterDrawer(true)
                            }}>
                                Filter
                            </Button>
                        </Col>
                    </Row >
                )
            },
        },
    ]
    return { pagination, dataSource: dataSource, columns: dataColumns, loading: isLoading, size: 'middle', sticky: true }
}

export default useTableConfig