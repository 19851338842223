//types
import { PlusSquareOutlined } from '@ant-design/icons'
import { Button, Card, Pagination, Space } from 'antd'
import styled from 'styled-components'

import React from 'react'

import HubListItemInfo from '@a4b/ui/src/modules/monetisation/molecules/HubListItemInfo'
import OrderInfoContainer from '@a4b/ui/src/modules/monetisation/molecules/OrderInfoContainer'

import { HubList, HubListItem } from '@a4b/api/src/modules/Monetisation/Puja/types'

interface Props {
  triggerCreationForm: () => void
  data: HubList | undefined
  showAddNewHub: boolean
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const ButtonContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
`
const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`
const HubListPage: React.FC<Props> = ({
  data,
  triggerCreationForm,
  showAddNewHub,
}) => {
  return (
    <Container title='Hub List' style={{ width: '100%' }}>
      <Space direction={'vertical'} style={{ width: '100%' }}></Space>
      <OrderInfoContainer
        orderCount={data?.total_new_orders || 0}
        processingCount={data?.total_processing_orders || 0}
      ></OrderInfoContainer>
      <ButtonContainer>
        {showAddNewHub && (
          <Button type='link' onClick={triggerCreationForm}>
            <PlusSquareOutlined /> Add new Hub
          </Button>
        )}
      </ButtonContainer>
      {data?.hubs?.map((item: HubListItem, index: number) => (
        <HubListItemInfo
          data={item}
          link={`/monetisation/puja/hub/${item.id}?name=${item.name}`}
          key={`${item.name}-${index}`}
        />
      ))}
      <LabelRightAlign>
        <Pagination defaultPageSize={5} defaultCurrent={1} total={30} />
      </LabelRightAlign>
    </Container>
  )
}

export default HubListPage
