import { DrawerProps } from "@/../../../packages/a4b-ui/src/modules/admin/screens/SocialScreenContainer";
import { ReactNode } from "react";
import AddVideoForm from "../components/AddVideoForm";


export enum DRAWER_TYPE {
    CREATE_SOMETHING1 = 'Create Video template',
}

// define Drawers 
const Drawers: { drawerProps: DrawerProps, drawerContent: ReactNode }[] = [
    {
        drawerProps: {
            visible: true,
            title: DRAWER_TYPE.CREATE_SOMETHING1,
            width: 1000,
        },
        drawerContent: <AddVideoForm></AddVideoForm>
    },
]

export default function useDrawerConfig(activeDrawer?: DRAWER_TYPE): {
    drawerProps: DrawerProps;
    drawerContent: ReactNode;
} | { drawerProps: undefined, drawerContent: undefined } {

    return Drawers.find((Drawer) => Drawer.drawerProps.title === activeDrawer) || { drawerProps: undefined, drawerContent: undefined }
}