import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { GetQuizRes, AddQuizPayload, UpdateQuizPayload } from "./types"
import moment from 'moment'
import { apiClient } from '../../apiClient';


class Coins {
    private api: AxiosInstance

    constructor(config: AxiosRequestConfig) {
        this.api = apiClient.create(config)
    }

    // Write all the get calls below 
    getQuiz = async () =>
        this.api.get<GetQuizRes>('/gw1/zeus/internal/v1/quiz')

    // Write all the post calls below
    addQuiz = (payload: AddQuizPayload) => {
        payload.start_at = moment(payload.start_at).startOf("day").valueOf()
        payload.end_at = moment(payload.end_at).endOf("day").valueOf()
        return this.api.post(
            '/gw1/zeus/internal/v1/quiz',
            payload,
        )
    }


    // Write all the patch calls below 
    updateQuizPayload = (payload: UpdateQuizPayload) => {
        payload.start_at = moment(payload.start_at).valueOf()
        payload.end_at = moment(payload.end_at).valueOf()
        return this.api.patch('/gw1/zeus/internal/v1/quiz', payload)
    }

    // Write all the put calls below 

}

export default Coins;
