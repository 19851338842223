import { Button, Form, Input } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";

interface FiltersProps {
  code?: string | null;
}
export default function Filters(props: FiltersProps) {
  const { code } = props;
  const [localCode, setLocalCode] = useState<string>(code || '');

  const navigate = useNavigate();
  const location = useLocation();

  const updateCode = useCallback(() => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        code: localCode,
      }).toString()
    });
  }, [localCode, location.pathname, navigate]);


  return <div style={{ display: 'flex', gap: '20px', width: 250 }}>
    <Input value={localCode} onChange={(e) => { setLocalCode(e.target.value) }} />

    <Button type="primary" disabled={code === localCode} onClick={updateCode}>
      Apply
    </Button>
  </div>
} 