import React, { useCallback, useEffect, useState } from 'react'
import { useAppContext } from '@/components/AppContext'
import { message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { YODA_CDN_URL, PUJA_PRESIGN_URL, A4B_APPS } from '@/utils/constants'
import StoreCardCreation from '@a4b/ui/src/modules/monetisation/puja/molecules/StoreCardCreation'

import {
  CreateStore, Product,
  StoreCard
} from '@a4b/api/src/modules/Monetisation/Puja/types'

import { formatItemCreation } from '../../../utils/store-card-helper'

interface Props {
  showItemCreation: boolean
  isEditJourney: boolean
  createItemCallback?: (status: boolean) => void
  UpdateItemCallback?: (status: boolean) => void
  closeItemCreation: () => void
  formData: StoreCard | undefined
}

const CreateStoreCard: React.FC<Props> = ({
  showItemCreation,
  closeItemCreation,
  createItemCallback,
  UpdateItemCallback,
  isEditJourney,
  formData,
}) => {
  const { networkInstance, app } = useAppContext()
  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi

  const [editFormData, setEditFormData] = useState<any>()
  const [createItemForm] = useForm()
  const [productList, setProductList] = useState<Product[]>([])
  const [storeList, setStoreList] = useState<CreateStore[]>([])

  const getStoreList = useCallback(async () => {
    try {
      const storeList = await monetisationPujaApi.storesApi.getStoreList({
        limit: 1000,
        offset: 0,
      })

      setStoreList(
        storeList?.data?.data?.store_list.sort(
          (item1: any, item2: any) => item1.position - item2.position,
        ),
      )
    } catch (error) {
      message.error({
        content: 'Error while fetching store list',
        duration: 3,
      })
      console.log(error)
    }
  }, [monetisationPujaApi.storesApi])

  const getStoreProductList = async (storeId: string) => {
    try {
      message.loading({ content: "Fetching inventories", duration: 2, key: "fetch-inventory" })
      const productListResponse =
        await monetisationPujaApi.storesApi.getProductList(storeId)
      setProductList(productListResponse?.data?.data?.products)
      message.success({ content: "Done Fetching inventories", key: "fetch-inventory" })
    } catch (error) {
      console.log(error)
      message.error({ content: "Fetching inventories failed, please try again.", key: "fetch-inventory" })
    }
  }

  useEffect(() => {
    getStoreList()
  }, [getStoreList])

  useEffect(() => {
    if (isEditJourney) {
      formData?.store_id && getStoreProductList(formData?.store_id)
      const editForm: any = {
        ...formData,
        title: formData?.title,
        description: formData?.description,
        tag: formData?.tag,
        inventory_id: formData?.inventory_id,
        store_id: formData?.store_id,

        active: formData?.active,
        id: formData?.id,
      }
      if (formData?.cover_media?.type === "video") {
        editForm["media_type"] = "video"
        editForm["media_url"] = [formData?.cover_media?.media_url]
        editForm["media_thumbnail"] = [formData?.cover_media?.media_thumbnail]
      }
      if (formData?.cover_media?.type === "image") {
        editForm["media_type"] = "image"
        editForm["media_url"] = [formData?.cover_media?.media_url]
      }
      setEditFormData(editForm)
      createItemForm.setFieldsValue(editForm)
    } else {
      setTimeout(() => {
        createItemForm.resetFields()
      }, 300)
      setEditFormData({})
    }
  }, [isEditJourney, createItemForm])
  const handleCreateOrUpdate = async (values: any) => {
    if (isEditJourney) {
      const updateFormData = formatItemCreation(values)
      const id = formData?.id || ''
      const updateItem = await monetisationPujaApi.storesApi.updateStoreCard(id,
        { ...updateFormData, active: formData?.active || false, id: id },
      )
      if (updateItem) {
        message.success('Store card updated successfully')

        if (UpdateItemCallback) {
          UpdateItemCallback(true)
        }
      } else {
        message.error('Store card update failed')
      }
      createItemForm.resetFields()
      closeItemCreation()
    } else {
      const createFormData = formatItemCreation(values)
      message.loading({ content: 'Creating item...', key: 'create-item' })
      await monetisationPujaApi.storesApi.createStoreCard(createFormData)
      message.success({
        content: 'Store card created successfully',
        duration: 2,
        key: 'create-store-card',
      })
      createItemForm.resetFields()
      closeItemCreation()
    }
  }

  const formSubmithandler = () => {
    createItemForm
      .validateFields()
      .then(values => {
        handleCreateOrUpdate(values)
      })
      .catch(errorInfo => { })
  }
  const onFormChange = (formValues: any) => {
    formValues?.store_id && getStoreProductList(formValues?.store_id)
  }
  return (
    <StoreCardCreation
      visible={showItemCreation}
      onClose={closeItemCreation}
      cdnUrl={YODA_CDN_URL}
      preSignUrl={PUJA_PRESIGN_URL}
      formSubmithandler={formSubmithandler}
      form={createItemForm}
      initialValues={editFormData}
      isEditJourney={isEditJourney}
      storeList={storeList}
      onFormChange={onFormChange}
      inventoryList={productList}
    />
  )
}

export default React.memo(CreateStoreCard)
