/* eslint-disable prefer-spread */
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Checkbox, Drawer, Form, Input, Radio, Row, Switch, message, Select, Tooltip } from 'antd'
import type { RadioChangeEvent } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import { actionMapping, orderPayload } from '../../utils/helper'
import { useAppContext } from '@/components/AppContext'
import { InfoCircleOutlined } from '@ant-design/icons'

interface Props {
    showItemCreation: boolean
    closeItemCreation: () => void
    initialValues: any
    form: FormInstance<any>
    cdnUrl: string
    preSignUrl: string
    userRole: string
    handleUpdateOrders: () => void
}

interface Tabs {
    offeringUserName: boolean,
    trackingData: boolean,
    deliveryStatus: boolean
    sku: boolean,
    memberData: boolean,
    address: boolean,
    whatsappNumber: boolean,
    [key: string]: boolean
}

const OrderDetailsForm: React.FC<Props> = ({
    showItemCreation,
    closeItemCreation,
    initialValues,
    form,
    cdnUrl,
    preSignUrl,
    userRole,
    handleUpdateOrders
}) => {
    const [tabs, setTabs] = useState<Tabs>({
        offeringUserName: false,
        trackingData: false,
        deliveryStatus: false,
        sku: false,
        memberData: false,
        address: false,
        whatsappNumber: false,
    })
    const [submit, setSubmit] = useState<boolean>(false)
    const { networkInstance, monetisationRoles, userProfile } = useAppContext()
    const { monetisationOfferingsApi } = networkInstance.clientWithHeaders
    const [submitDeliveryStatus, setSubmitDeliveryStatus] = useState<boolean>(false)
    const [submitTrackingData, setSubmitTrackingData] = useState<boolean>(false)


    const isManualUpdate = initialValues?.delivery_data?.is_manual_update

    useEffect(() => {
        let bookingMemberData
        if (initialValues?.service_type === 'offerings') {
            setTabs((prevTabs) => ({
                ...prevTabs,
                offeringUserName: true,
                trackingData: false,
                sku: false,
                memberData: false,
                address: false
            }))
        } else {
            if (checkVideoUrl()) {
                setTabs((prevTabs) => ({
                    ...prevTabs,
                    offeringUserName: false,
                    trackingData: false,
                    sku: true,
                    memberData: false,
                    address: false
                }))
            } else {
                setTabs((prevTabs) => ({
                    ...prevTabs,
                    offeringUserName: false,
                    trackingData: false,
                    sku: false,
                    memberData: true,
                    address: false
                }))
            }
            const memberCount = (initialValues?.member_size) - (initialValues?.booking_member_data?.length)
            if (!initialValues?.booking_member_data) {
                bookingMemberData = Array.apply(null, Array(initialValues?.member_size)).map(() => ({
                    full_name: '',
                    gotra: '',
                    mobile_number: '',
                    whatsapp_number: '',
                    same_as_mobile_number: false
                }))
            } else {
                const additionalMembers = Array.apply(null, Array(memberCount ? memberCount : 0))?.map(() => ({
                    full_name: '',
                    gotra: '',
                    mobile_number: '',
                    whatsapp_number: '',
                    same_as_mobile_number: false
                }))
                bookingMemberData = initialValues.booking_member_data.concat(additionalMembers)
            }
        }
        form.setFieldsValue({ ...initialValues, booking_member_data: bookingMemberData })
    }, [initialValues])

    const onChangeRadioEvent = useCallback((e: RadioChangeEvent) => {
        switch (e.target.value) {
            case 'offeringUserName':
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    offeringUserName: true,
                    trackingData: false,
                    sku: false,
                    memberData: false,
                    whatsappNumber: false,
                    address: false
                }))
                break
            case 'sku':
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    offeringUserName: false,
                    trackingData: false,
                    sku: true,
                    memberData: false,
                    whatsappNumber: false,
                    address: false
                }))
                break
            case 'trackingData':
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    offeringUserName: false,
                    trackingData: true,
                    deliveryStatus: false,
                    sku: false,
                    memberData: false,
                    whatsappNumber: false,
                    address: false
                }))
                break
            case 'deliveryStatus':
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    offeringUserName: false,
                    trackingData: false,
                    deliveryStatus: true,
                    sku: false,
                    memberData: false,
                    whatsappNumber: false,
                    address: false
                }))
                break
            case 'memberData':
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    offeringUserName: false,
                    trackingData: false,
                    sku: false,
                    memberData: true,
                    whatsappNumber: false,
                    address: false
                }))
                break
            case 'address':
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    offeringUserName: false,
                    trackingData: false,
                    sku: false,
                    memberData: false,
                    whatsappNumber: false,
                    address: true
                }))
                break
            case 'whatsappNumber':
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    offeringUserName: false,
                    trackingData: false,
                    sku: false,
                    memberData: false,
                    whatsappNumber: true,
                    address: false,
                }))
                break
        }
    }, [])

    const isNumeric = (value: number): boolean => /^\d+$/.test(String(value))

    const onFormChange = (event: any) => {
        if (event?.target?.value || event) {
            setSubmit(true)
        }
    }

    const closeOrderFormModal = () => {
        setTabs({
            offeringUserName: false,
            sku: false,
            trackingData: false,
            deliveryStatus: false,
            memberData: false,
            address: false,
            whatsappNumber: false,
        })
        setSubmit(false)
        closeItemCreation()
    }

    const checkVideoUrl = (): boolean => {
        return form?.getFieldValue('items')?.some((item: any) => item?.meta_data?.video_url?.[0])
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            +91
        </Form.Item>
    )

    const handleCheckbox = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            const mobileNumber = form?.getFieldValue('booking_member_data')?.[0]?.mobile_number
            if (mobileNumber?.length !== 10) {
                message.warning('Mobile number should be 10 digits')
            } else {
                const bookingMemberData = form.getFieldValue('booking_member_data')
                const updatedBookingMemberData = [
                    {
                        ...bookingMemberData[0],
                        whatsapp_number: mobileNumber
                    },
                    ...bookingMemberData.slice(1)
                ]

                form.setFieldsValue({
                    booking_member_data: updatedBookingMemberData
                })
            }
        }
    }
    const clearFieldsValue = () => {
        setTabs((prevTabs: any) => ({
            ...prevTabs,
            offeringUserName: false,
            trackingData: false,
            sku: false,
            memberData: false,
            whatsappNumber: false,
            address: false
        }))
        closeItemCreation()
    }

    const updateActiveTab = () => {
        setSubmit(false)
        if (tabs?.memberData) {
            setTabs((prevTabs: any) => ({
                ...prevTabs,
                offeringUserName: false,
                trackingData: false,
                sku: false,
                memberData: false,
                whatsappNumber: false,
                address: true
            }))
        }
        if (tabs?.address) {
            if (monetisationRoles?.user_type === 'super_admin') {
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    offeringUserName: false,
                    trackingData: false,
                    sku: false,
                    memberData: false,
                    whatsappNumber: true,
                    address: false,
                }))
            } else {
                clearFieldsValue()
            }
        }
        if (tabs?.whatsappNumber) {
            clearFieldsValue()
        }
        if (tabs?.offeringUserName) {
            if (checkVideoUrl()) {
                setTabs((prevTabs: any) => ({
                    ...prevTabs,
                    offeringUserName: false,
                    trackingData: false,
                    sku: true,
                    memberData: false,
                    whatsappNumber: false,
                    address: false
                }))
            } else {
                clearFieldsValue()
            }
        }
        if (tabs?.sku) {
            clearFieldsValue()
        }
    }

    const updateRecord = async (order: any, action: string) => {
        const payload = orderPayload(order, action, initialValues?.country_code)
        if (payload?.error && payload?.error?.code === 'number_mismatch') {
            message.error('Mobile number and WhatsApp number are not the same!')
            return
        }
        const orderId = form?.getFieldValue('id')
        try {
            await monetisationOfferingsApi.orderApi.updateOrder(
                orderId,
                actionMapping?.[action],
                {
                    ...payload,
                    ...(action === 'memberData' && { country_code: initialValues?.country_code }),
                    user_email: userProfile?.email!
                }
            )
            message.success('Order has been successfully updated!')
            updateActiveTab()
            handleUpdateOrders()
        } catch (e) {
            message.error('Error while updating order!')
            console.log(e)
        }
    }

    const updateSkuItem = (item: any) => {
        updateRecord(item, 'sku')
    }

    const onDeliveryStatusChange = (value: any) => {
        setSubmitDeliveryStatus(true);
        setSubmitTrackingData(false);
    }


    const onTrackingDataChange = (value: any) => {
        setSubmitTrackingData(true);
        setSubmitDeliveryStatus(false);
    }

    const [isInputHovered, setInputHovered] = useState(false);

    const handleMouseEnter = () => {
        setInputHovered(true);
    };

    const handleMouseLeave = () => {
        setInputHovered(false);
    };

    const getCourierName = (initialValues: any) => {
        var courierName
        if (initialValues?.delivery_data?.is_manual_update) {
            courierName = initialValues?.delivery_data?.m_courier_name
        } else {
            courierName = initialValues?.delivery_data?.result?.courier_name
        }
        return courierName;
    }

    var courierName = getCourierName(initialValues)

    return (
        <>
            <Drawer
                title="Edit Order Details"
                placement='right'
                onClose={closeOrderFormModal}
                visible={showItemCreation}
                destroyOnClose={true}
                size='large'
                width={600}
            >
                <div style={{ display: 'flex', width: '100%', justifyContent: 'start' }}>
                    <Radio.Group
                        defaultValue={initialValues?.service_type === 'offerings' ? 'offeringUserName' : (checkVideoUrl() ? 'sku' : 'memberData')}
                        onChange={onChangeRadioEvent}
                        style={{ marginBottom: 16 }}
                        buttonStyle="solid"
                        optionType="button"
                        value={Object?.keys(tabs)?.find((key) => tabs[key])}
                    >
                        {initialValues?.service_type === 'offerings' &&
                            <Radio.Button value="offeringUserName">Offering Username</Radio.Button>
                        }
                        {initialValues?.service_type === 'offerings' &&
                            initialValues?.delivery_status !== 'not_deliverable' &&
                            <Radio.Button value="trackingData">Manual Tracking Data</Radio.Button>
                        }
                        {checkVideoUrl() && <Radio.Button value="sku">SKU</Radio.Button>}
                        {initialValues?.service_type === 'pooja' &&
                            <Radio.Button value="memberData">Member data</Radio.Button>
                        }
                        {(initialValues?.service_type === 'offerings' && initialValues?.order_type === 'offerings_delivery') &&
                            <Radio.Button value="address">Address</Radio.Button>
                        }
                        {(initialValues?.service_type === 'pooja' && userRole === 'super_admin') &&
                            <Radio.Button value="whatsappNumber">Whatsapp Number</Radio.Button>
                        }
                    </Radio.Group>
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'start' }}>
                    <Form
                        form={form}
                        layout='vertical'
                        initialValues={{ ...initialValues }}
                        onFinish={(values) => {
                            updateRecord(values, Object.keys(tabs).find(key => tabs?.[key] === true) ?? '')
                        }}
                        style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start' }}
                    >
                        {(initialValues?.service_type === 'offerings' && tabs?.offeringUserName) &&
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                <Form.Item
                                    label='Offering User Name'
                                    name='offering_user_name'
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    rules={[{ required: true, message: 'Enter Offering User Name' }]}
                                    style={{ width: '60%', marginTop: '10px' }}
                                >
                                    <Input
                                        placeholder='Enter Offering User Name'
                                        onChange={(e) => onFormChange(e)}
                                    />
                                </Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    style={{ width: '30%', marginBottom: '20px' }}
                                    disabled={!submit}
                                >
                                    Submit
                                </Button>
                            </div>
                        }
                        {initialValues?.service_type === 'offerings' &&
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                {tabs?.trackingData &&
                                    <div style={{ width: '100%', padding: '20px', border: '1px solid #f0f0f0', borderRadius: '4px' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                        <Form.Item
                                            label='Tracking ID'
                                            name={['delivery_data', initialValues?.delivery_data?.is_manual_update ? 'm_tracking_id' : 'tracking_id']}
                                            labelCol={{ span: 28 }}
                                            wrapperCol={{ span: 28 }}
                                            rules={[{ required: true, message: 'Enter Tracking ID' }]}
                                            style={{ width: '60%' }}
                                            tooltip={{
                                                title: 'order created via clickpost - cannot edit the tracking data', icon: <InfoCircleOutlined />, placement: 'topRight',
                                                visible: isInputHovered && ((isManualUpdate && initialValues?.delivery_data?.m_tracking_id !== 0) || (!isManualUpdate && initialValues?.delivery_data?.tracking_id !== 0))
                                            }}
                                        >
                                            <Input
                                                placeholder='Enter Tracking ID'
                                                onChange={(e) => onTrackingDataChange(e)}
                                                // defaultValue={initialValues?.delivery_data?.is_manual_update ? initialValues?.delivery_data?.m_tracking_id : initialValues?.delivery_data?.tracking_id || ''}
                                                disabled={(isManualUpdate && initialValues?.delivery_data?.m_tracking_id !== 0) || (!isManualUpdate && initialValues?.delivery_data?.tracking_id !== 0)}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='Courier Name'
                                            name={['delivery_data', 'result', initialValues?.delivery_data?.is_manual_update ? 'm_courier_name' : 'courier_name']}
                                            labelCol={{ span: 28 }}
                                            wrapperCol={{ span: 28 }}
                                            rules={[{ message: 'Enter Tracking URL' }]}
                                            style={{ width: '60%' }}
                                            initialValue={courierName}
                                        >
                                            <Select
                                                placeholder='Select Courier name'
                                                onChange={(value) => onTrackingDataChange(value)}
                                                defaultValue={courierName}
                                                disabled={(isManualUpdate && initialValues?.delivery_data?.m_tracking_id !== 0) || (!isManualUpdate && initialValues?.delivery_data?.tracking_id !== 0)}
                                            >
                                                <Select.Option value="shiprocket">Shiprocket</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Button
                                            type="primary"
                                            style={{ width: '30%', marginBottom: '20px' }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                form.validateFields().then((values) => {
                                                    updateRecord(values, 'trackingData');
                                                });
                                            }}
                                            disabled={!submitTrackingData}
                                        >
                                            Submit Tracking
                                        </Button>
                                    </div>
                                }

                                {tabs?.trackingData &&
                                    <div style={{ width: '100%', padding: '20px', border: '1px solid #f0f0f0', borderRadius: '4px' }}>
                                        <Form.Item
                                            label='Delivery Status'
                                            name={'delivery_status'}
                                            labelCol={{ span: 28 }}
                                            wrapperCol={{ span: 28 }}
                                            rules={[{ message: 'Update the delivery status of order' }]}
                                            style={{ width: '60%' }}
                                        >
                                            <Select
                                                placeholder='Select Delivery Status'
                                                onChange={(value) => onDeliveryStatusChange(value)}
                                                disabled={!isManualUpdate && initialValues?.delivery_data?.tracking_id !== 0}

                                            >
                                                <Select.Option value="order_created">Order Created</Select.Option>
                                                <Select.Option value="pickup_initiated">Pickup Initiated</Select.Option>
                                                <Select.Option value="picked_up">Picked Up</Select.Option>
                                                <Select.Option value="intransit">In Transit</Select.Option>
                                                <Select.Option value="delivery_initiated">Delivery Initiated</Select.Option>
                                                <Select.Option value="delivery_success">Delivery Success</Select.Option>
                                                <Select.Option value="delivery_failed">Delivery Failed</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            style={{ width: '30%', marginBottom: '20px' }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                form.validateFields().then((values) => {
                                                    updateRecord(values, 'deliveryStatus');
                                                });
                                            }}
                                            disabled={!submitDeliveryStatus}
                                        >
                                            Submit
                                        </Button>
                                    </div>

                                }
                            </div>
                        }
                        {(tabs?.sku && checkVideoUrl()) &&
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                <Form.Item
                                    label='SKU Items'
                                    style={{ width: '90%' }}
                                >
                                    <Row style={{ width: '100%' }}>
                                        <Form.List name={['items']}>
                                            {(fields) => {
                                                return (
                                                    <Row style={{ width: '100%' }}>
                                                        {fields.map((field: any, index: number) => {
                                                            const isVideoUrlPresent = form?.getFieldValue('items')?.[index]?.meta_data?.video_url?.[0]
                                                            const mediaType = form?.getFieldValue('items')?.[index]?.meta_data?.medi_type
                                                            return (
                                                                isVideoUrlPresent &&
                                                                <div key={field?.key} style={{ paddingRight: '12px', paddingTop: '10px', width: '100%' }}>
                                                                    <div
                                                                        key={field?.key}
                                                                        style={{
                                                                            border: '1px dashed #e0dcdc',
                                                                            marginBottom: '10px',
                                                                            padding: '10px',
                                                                            width: '100%'
                                                                        }}
                                                                    >
                                                                        <Row justify="space-between" style={{ width: '100%' }}>
                                                                            <>
                                                                                <Form.Item
                                                                                    label={`ID ${index + 1}`}
                                                                                    name={[field.name, 'id']}
                                                                                    labelCol={{ span: 18 }}
                                                                                    wrapperCol={{ span: 18 }}
                                                                                    style={{ width: '100% !important' }}
                                                                                    rules={[
                                                                                        {
                                                                                            required: false,
                                                                                        },
                                                                                    ]}
                                                                                    hidden
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>

                                                                                <Form.Item
                                                                                    label={`Media type ${index + 1}`}
                                                                                    name={[field.name, 'meta_data', 'media_type']}
                                                                                    labelCol={{ span: 18 }}
                                                                                    wrapperCol={{ span: 18 }}
                                                                                    style={{ width: '100% !important' }}
                                                                                    rules={[
                                                                                        {
                                                                                            required: false,
                                                                                        },
                                                                                    ]}
                                                                                    hidden
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>

                                                                                <Form.Item
                                                                                    label={`SKU Name ${index + 1}`}
                                                                                    name={[field.name, 'name']}
                                                                                    labelCol={{ span: 18 }}
                                                                                    wrapperCol={{ span: 18 }}
                                                                                    style={{ width: '100% !important' }}
                                                                                    rules={[
                                                                                        {
                                                                                            required: false,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <Input disabled onChange={(e) => onFormChange(e)} />
                                                                                </Form.Item>

                                                                                {mediaType === 'video' && (
                                                                                    <Form.Item
                                                                                        label={`Media ${index + 1}`}
                                                                                        name={[field.name, 'meta_data', 'video_url']}
                                                                                        labelCol={{ span: 28 }}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                min: 0,
                                                                                                max: 1,
                                                                                                validator: async (rules: any, values: any) => {
                                                                                                    if (values?.length < rules.min) {
                                                                                                        return Promise.reject('Please upload Media');
                                                                                                    }
                                                                                                },
                                                                                            },
                                                                                        ]}
                                                                                        valuePropName="fileList"
                                                                                        style={{ marginBottom: '0px' }}
                                                                                    >
                                                                                        <S3Upload
                                                                                            cdnUrl={cdnUrl}
                                                                                            preSignUrl={preSignUrl}
                                                                                            min={1}
                                                                                            max={1}
                                                                                            onChange={(e) => onFormChange(e)}
                                                                                        />
                                                                                    </Form.Item>
                                                                                )}

                                                                                {mediaType === 'youtube' && (
                                                                                    <Form.Item
                                                                                        label={`Media ${index + 1}`}
                                                                                        name={[field.name, 'meta_data', 'video_url']}
                                                                                        labelCol={{ span: 28 }}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                            },
                                                                                        ]}
                                                                                        valuePropName="fileList"
                                                                                        style={{ marginBottom: '0px' }}
                                                                                    >
                                                                                        <Input
                                                                                            onChange={(e) => onFormChange(e)}
                                                                                        />
                                                                                    </Form.Item>
                                                                                )}

                                                                            </>
                                                                        </Row>
                                                                        <Button
                                                                            type="primary"
                                                                            style={{ width: '30%', marginBottom: '0px' }}
                                                                            disabled={!submit}
                                                                            onClick={() => {
                                                                                const formValues = form?.getFieldsValue()
                                                                                const itemValues = formValues?.items?.[index]
                                                                                updateSkuItem(itemValues)
                                                                            }}
                                                                        >
                                                                            Submit
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </Row>
                                                )
                                            }}
                                        </Form.List>
                                    </Row>
                                </Form.Item>
                            </div>
                        }
                        {(tabs?.memberData && initialValues?.service_type === 'pooja') &&
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                <Form.Item
                                    label='Member data'
                                    style={{ width: '100%' }}
                                >
                                    <Row style={{ width: '100%' }}>
                                        <Form.List name={['booking_member_data']}>
                                            {(fields) => {
                                                return (
                                                    <>
                                                        <Row style={{ width: '100%' }}>
                                                            {fields.map((field: any, index: number) => (
                                                                <>
                                                                    {
                                                                        index === 0 && (
                                                                            <div key={field?.key} style={{ width: '100%' }}>
                                                                                <div
                                                                                    key={field?.key}
                                                                                    style={{
                                                                                        border: '1px dashed #e0dcdc',
                                                                                        marginBottom: '10px',
                                                                                        padding: '10px',
                                                                                        width: '100%'
                                                                                    }}
                                                                                >
                                                                                    <Row justify="space-between" style={{ width: '100%', display: 'flex' }}>
                                                                                        <Form.Item
                                                                                            label={`Full Name`}
                                                                                            name={[field.name, 'full_name']}
                                                                                            labelCol={{ span: 18 }}
                                                                                            wrapperCol={{ span: 18 }}
                                                                                            style={{ width: '50%', marginBottom: '0px !important' }}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: (index === 0) ? true : false,
                                                                                                    message: 'Enter full name',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <Input placeholder='Enter name' style={{ width: '250px' }} onChange={(e) => onFormChange(e)} />
                                                                                        </Form.Item>
                                                                                        <Form.Item
                                                                                            label={`Gotra`}
                                                                                            name={[field.name, 'gotra']}
                                                                                            labelCol={{ span: 18 }}
                                                                                            wrapperCol={{ span: 18 }}
                                                                                            style={{ width: '50%' }}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: 'Enter gotra',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <Input placeholder='Enter Gotra' style={{ width: '250px' }} onChange={(e) => onFormChange(e)} />
                                                                                        </Form.Item>
                                                                                        <Form.Item
                                                                                            noStyle
                                                                                            shouldUpdate={(prevValues, currentValues) => true}
                                                                                        >
                                                                                            {({ getFieldValue }) => (
                                                                                                getFieldValue("country_code") === "IN" &&
                                                                                                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                                                                                    <Form.Item
                                                                                                        label={`Mobile`}
                                                                                                        name={[field.name, 'mobile_number']}
                                                                                                        labelCol={{ span: 18 }}
                                                                                                        wrapperCol={{ span: 18 }}
                                                                                                        style={{ width: '50%', marginBottom: '10px' }}
                                                                                                        rules={[
                                                                                                            {
                                                                                                                required: true,
                                                                                                                message: 'Enter Mobile Number',
                                                                                                            },
                                                                                                        ]}
                                                                                                    >
                                                                                                        <Input addonBefore={prefixSelector} placeholder='Enter Mobile number' minLength={10} maxLength={10} style={{ width: '250px' }} onChange={onFormChange} />
                                                                                                    </Form.Item>
                                                                                                    <Form.Item
                                                                                                        label={`Whatsapp Number`}
                                                                                                        name={[field.name, 'whatsapp_number']}
                                                                                                        labelCol={{ span: 18 }}
                                                                                                        wrapperCol={{ span: 18 }}
                                                                                                        style={{ width: '50%', marginBottom: '10px' }}
                                                                                                        rules={[
                                                                                                            {
                                                                                                                required: true,
                                                                                                                message: 'Enter Whats app Number',
                                                                                                            },
                                                                                                        ]}
                                                                                                    >
                                                                                                        <Input addonBefore={prefixSelector} placeholder='Enter Whatsapp number' minLength={10} maxLength={10} style={{ width: '250px' }} onChange={onFormChange} />
                                                                                                    </Form.Item>
                                                                                                    <Form.Item
                                                                                                        label={``}
                                                                                                        name={[field.name, 'same_as_mobile_number']}
                                                                                                        labelCol={{ span: 18 }}
                                                                                                        wrapperCol={{ span: 18 }}
                                                                                                        style={{ width: '50%', marginBottom: '0px' }}
                                                                                                        rules={[
                                                                                                            {
                                                                                                                required: false,
                                                                                                            },
                                                                                                        ]}
                                                                                                        valuePropName='checked'
                                                                                                    >
                                                                                                        <Checkbox onChange={handleCheckbox}>Same as Mobile number</Checkbox>
                                                                                                    </Form.Item>
                                                                                                </Row>
                                                                                            )}
                                                                                        </Form.Item>
                                                                                    </Row>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {
                                                                        index > 0 && (
                                                                            <div key={field?.key} style={{ width: '100%' }}>
                                                                                <div
                                                                                    key={field?.key}
                                                                                    style={{
                                                                                        border: '1px dashed #e0dcdc',
                                                                                        marginBottom: '10px',
                                                                                        padding: '10px',
                                                                                        width: '100%'
                                                                                    }}
                                                                                >
                                                                                    <Row justify="space-between" style={{ width: '100%', display: 'flex' }}>
                                                                                        <Form.Item
                                                                                            label={`Full Name`}
                                                                                            name={[field.name, 'full_name']}
                                                                                            labelCol={{ span: 18 }}
                                                                                            wrapperCol={{ span: 18 }}
                                                                                            style={{ width: '50%', marginBottom: '0px' }}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: (index === 0) ? true : false,
                                                                                                    message: 'Enter full name',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <Input placeholder='Enter name' style={{ width: '100% ' }} onChange={(e) => onFormChange(e)} />
                                                                                        </Form.Item>
                                                                                    </Row>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </>
                                                            ))}
                                                        </Row>
                                                    </>
                                                )
                                            }}
                                        </Form.List>
                                    </Row>
                                </Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    style={{ width: '30%', marginBottom: '20px' }}
                                    disabled={!submit}
                                >
                                    Submit
                                </Button>
                            </div>
                        }
                        {
                            (tabs?.address) &&
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', marginLeft: '-500px' }}>
                                <Form.Item
                                    label='House Info'
                                    name={['booking_address', 'house_info']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    rules={[{ required: true, message: 'Enter House Info' },
                                    {
                                        validator: (_, e) => {
                                            if (/\d/.test(e)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('House Info must contain at least one number');
                                        }
                                    }]}
                                    style={{ width: '60%' }}
                                >
                                    <Input
                                        placeholder='Enter House Info'
                                        onChange={(e) => onFormChange(e)}
                                        disabled={initialValues?.delivery_status !== 'deliverable' &&
                                            initialValues?.delivery_status !== 'order_failed'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Area Info'
                                    name={['booking_address', 'area_info']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    rules={[{ required: true, message: 'Enter Area Info' }]}
                                    style={{ width: '60%' }}
                                >
                                    <Input
                                        placeholder='Enter Area Info'
                                        onChange={(e) => onFormChange(e)}
                                        disabled={initialValues?.delivery_status !== 'deliverable' &&
                                            initialValues?.delivery_status !== 'order_failed'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Landmark'
                                    name={['booking_address', 'landmark']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    rules={[{ required: false, message: 'Enter Landmark' }]}
                                    style={{ width: '60%' }}
                                >
                                    <Input
                                        placeholder='Enter Landmark'
                                        onChange={(e) => onFormChange(e)}
                                        disabled={initialValues?.delivery_status !== 'deliverable' &&
                                            initialValues?.delivery_status !== 'order_failed'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Pincode'
                                    name={['booking_address', 'pin_code']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    rules={[{ required: true, message: 'Enter Pincode' },
                                    {
                                        validator: (_: any, e: any) => {
                                            const pincodeStr = e.toString();

                                            if (!/^\d+$/.test(pincodeStr)) {
                                                return Promise.reject(new Error('Pincode must only contain digits'));
                                            }

                                            if (pincodeStr.length !== 6) {
                                                return Promise.reject(new Error('Pincode must be exactly 6 digits'));
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                    style={{ width: '60%' }}
                                >
                                    <Input
                                        placeholder='Enter Pincode'
                                        onChange={(e) => onFormChange(e)}
                                        disabled={initialValues?.delivery_status !== 'deliverable' &&
                                            initialValues?.delivery_status !== 'order_failed'}
                                        style={{ width: '100%' }}
                                        minLength={0}
                                        maxLength={6}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='City'
                                    name={['booking_address', 'city_name']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    rules={[{ required: true, message: 'Enter City' }]}
                                    style={{ width: '60%' }}
                                >
                                    <Input
                                        placeholder='Enter City'
                                        onChange={(e) => onFormChange(e)}
                                        disabled={initialValues?.delivery_status !== 'deliverable' &&
                                            initialValues?.delivery_status !== 'order_failed'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='State'
                                    name={['booking_address', 'state_name']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    rules={[{ required: true, message: 'Enter State' }]}
                                    style={{ width: '60%' }}
                                >
                                    <Input
                                        placeholder='Enter State'
                                        onChange={(e) => onFormChange(e)}
                                        disabled={initialValues?.delivery_status !== 'deliverable' &&
                                            initialValues?.delivery_status !== 'order_failed'}
                                    />
                                </Form.Item>

                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    style={{ width: '30%', marginBottom: '20px' }}
                                    disabled={!submit}
                                >
                                    Submit
                                </Button>
                            </div>
                        }
                        {
                            (tabs?.whatsappNumber) &&
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Form.Item
                                    label='Whatsapp Number'
                                    name='booking_whatsapp_number'
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    rules={[{ required: true, message: 'Enter Whatsapp Number' }, ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value) {
                                                if (isNumeric(value)) {
                                                    return Promise.resolve()
                                                } else {
                                                    return Promise.reject(new Error('Please enter valid Whatsapp Number'))
                                                }
                                            }
                                        },
                                    })]}
                                    style={{ width: '60%', marginTop: '10px' }}
                                >
                                    <Input addonBefore={prefixSelector} placeholder='Enter Whatsapp number' minLength={10} maxLength={10} style={{ width: '250px' }} onChange={onFormChange} />
                                </Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    style={{ width: '30%', marginBottom: '20px' }}
                                    disabled={!submit}
                                >
                                    Submit
                                </Button>
                            </div>
                        }
                    </Form>
                </div>
            </Drawer >
        </>
    )
}

export default OrderDetailsForm
