import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import {
  CreateStore,
  Response,
  StoreContent,
  StoreContentData,
  StoreContentStatus,
} from './types'

class Scheduler {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createStoreContent = (payload: CreateStore) =>
    this.api.post<Response<StoreContentData>>(
      `/yoda/internal/v1/store-content`,
      payload,
    )

  getStoreContent = (
    language_code: string,
    date: string,
    country_code?: string,
  ) => {
    if (country_code) {
      return this.api.get<Response<StoreContent>>(
        `/yoda/internal/v1/store-content/?language=${language_code}&date=${date}`,
        { headers: { 'x-afb-country-code': country_code } },
      )
    }
    return this.api.get<Response<StoreContent>>(
      `/yoda/internal/v1/store-content/?language=${language_code}&date=${date}`,
    )
  }

  updateStoreContent = (storeContentId: string, payload: CreateStore) =>
    this.api.put(`/yoda/internal/v1/store-content/${storeContentId}`, payload)

  updateStoreContentStatus = (
    storeContentId: string,
    payload: StoreContentStatus,
  ) =>
    this.api.patch(`/yoda/internal/v1/store-content/${storeContentId}`, payload)
}

export default Scheduler
