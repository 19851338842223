import { Col, Form, Input, Row, Select, Button } from "antd"
import React from "react"
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'

interface Props {
    fields: any
    add: () => void
    remove: (item: any) => void
    cdnUrl: string
    preSignUrl: string
}
const { Option } = Select

const SrimandirService: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, }) => {
    return (
        <>
            <Row justify="space-between" style={{width: '100%'}}>
                {fields.map((field: any, index: number) => {
                    return (
                        <div key={index} style={{
                            border: '1px solid #e0dcdc',
                            marginRight: '0px',
                            marginBottom: '10px',
                            width: '49%',
                            padding: '10px',
                        }}>
                            <Row style={{ justifyContent: 'space-between', width: '100%' }}>
                                <Form.Item
                                    label={`Title ${index + 1}`}
                                    name={[field.name, 'title']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    style={{ flexBasis: '40%' }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter title',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter title' />
                                </Form.Item>

                                <Form.Item
                                    label={`Subtitle ${index + 1}`}
                                    name={[field.name, 'sub_title']}
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 28 }}
                                    style={{ flexBasis: '45%' }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Subtitle',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter Subtitle' />
                                </Form.Item>

                                <Button
                                    style={{ marginTop: '28px', marginRight: '5px', flexBasis: '8%' }}
                                    onClick={() => {
                                        remove(field.name)
                                    }}
                                    type='ghost'
                                    icon={<DeleteOutlined />}
                                    size='middle'
                                    danger
                                ></Button>

                            </Row>

                            <Form.Item
                                label={`Deeplink ${index + 1}`}
                                name={[field.name, 'deeplink']}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                style={{ flexBasis: '50%' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter Deeplink',
                                    },
                                ]}
                            >
                                <Input placeholder='Enter Deeplink' />
                            </Form.Item>

                            <Row style={{ display: 'flex' }}>
                                <Form.Item
                                    label={`Media type ${index + 1}`}
                                    name={[field.name, 'media', 'media_type']}
                                    labelCol={{ span: 18 }}
                                    wrapperCol={{ span: 18 }}
                                    style={{ flexBasis: '50%' }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter media type',
                                        },
                                    ]}
                                >
                                    <Select placeholder='Please Select Media type'>
                                        <Option value={'image'} key={'image'}>
                                            Image
                                        </Option>
                                        <Option value={'gif'} key={'gif'}>
                                            Gif
                                        </Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={`Media`}
                                    name={[field.name, 'media', 'media_url']}
                                    labelCol={{ span: 28 }}
                                    rules={[
                                        {
                                            required: true,
                                            min: 0,
                                            max: 1,
                                            validator: async (rules: any, values: any) => {
                                                if (values?.length < rules.min) {
                                                    return Promise.reject('Please upload Media')
                                                }
                                            },
                                        },
                                    ]}
                                    valuePropName='fileList'
                                >
                                    <S3Upload
                                        cdnUrl={cdnUrl}
                                        preSignUrl={preSignUrl}
                                        min={1}
                                        max={1}
                                    />
                                </Form.Item>
                            </Row>
                        </div>
                    )
                })}
            </Row>
            <Col span={24} style={{ paddingTop: '10px' }}>
                <Row justify='start'>
                    <Col span={4}>
                        <Button
                            onClick={() => {
                                add()
                            }}
                            type='ghost'
                            icon={<PlusCircleOutlined />}
                            size='middle'
                        >
                            Add Srimandir service
                        </Button>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default SrimandirService