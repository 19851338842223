
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination from "@/hooks/useAntdPagination";



const NewDraftPage = function () {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const drawerConfig = useDrawerConfig(activeDrawer);
  const { drawerProps, drawerContent } = drawerConfig;
  const { networkInstance } = useAppContext();
  const { pagination, setPagination } = useAntdPagination();

  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }


  const tableConfig = useTableConfig([
    { id: '1111', title: 'test ', title2: 'test 2' },
    { id: '1112', title: 'test ', title2: 'test 2' }
  ], pagination, setPagination, setDrawer,);


  const onCreateRashipal = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_SOMETHING1);
  }

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  }

  return <SocialScreenContainer
    title={"New page title"}
    filtersUI={<Filters />}
    extra={<Button onClick={onCreateRashipal}> Create something1 </Button>}
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />

};

export default withDefaultLayout(NewDraftPage)