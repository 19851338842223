import { CloseCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Checkbox, Form, FormInstance, Input, Select } from "antd"
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";
import { PROPERTY_DATA_TYPES } from "../utils/constants";
import { SizeType } from "antd/lib/config-provider/SizeContext";

export interface IProperyForm<TForm> {
    form: FormInstance<TForm>,
    name: number
    remove: (index: number | number[]) => void
    size?: SizeType
    parentDataType?: string
}

const CloseSpan = styled.span`
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
    font-size: 20px;
    color: #ff0000;
    &span {
        box-shadow: 0px 0px 5px 0px #000000;
    }
`

const PropertyForm = (props: IProperyForm<any>) => {
    const { form, name, remove, size, parentDataType } = props;
    return <>
        <Form.Item
            name={[name, 'name']}
            label="Name"
            rules={[{ required: true, message: 'Please input your Name!' }]}
        >
            <Input size={size} />
        </Form.Item>
        <Form.Item
            name={[name, 'description']}
            label="Description"
        >
            <TextArea size={size} />
        </Form.Item>
        <Form.Item
            name={[name, 'data_type']}
            label="Data Type"
            rules={[{ required: true, message: 'Please input data type!' }]}
        >
            <Select size={size} showSearch>
                {PROPERTY_DATA_TYPES.filter((property) => parentDataType ? property.isSubProperty : true).map(dataType => {
                    return <Select.Option value={dataType.value}>{dataType.label}</Select.Option>
                })}
            </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => {
            return (prevValues?.properties && prevValues?.properties[name]?.data_type) !== (curValues?.properties && curValues?.properties[name]?.data_type)
        }}>
            {() => {

                return form.getFieldValue(['properties', name, 'data_type']) !== 'BOOL' ?
                    <Form.Item
                        name={[name, 'options']}
                        label="Options"
                        rules={[{
                            validator(rule, value, callback) {
                                if (value?.length === 1) {
                                    callback("Number of values in Options either 0 or >= 2.")
                                } else {
                                    callback(undefined)
                                }
                            },
                        }]}
                    >
                        <Select size={size} mode="tags" />
                    </Form.Item>
                    : null
            }}
        </Form.Item>

        <Form.Item
            name={[name, 'is_required']}
            label="Is Required"
            initialValue={false}
            valuePropName="checked"
        >
            <Checkbox />
        </Form.Item>
        <CloseSpan>
            <CloseCircleOutlined onClick={() => remove(name)} />
        </CloseSpan>
    </>
}

export default PropertyForm;