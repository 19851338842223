import { useAppContext } from '@/components/AppContext'
//types
import { LoginState } from '@/entities/UserProfile'

import { Navigate, Outlet, RouteProps } from 'react-router-dom'

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const { userProfile } = useAppContext()
  const loggedIn =
    userProfile.loginStatus === LoginState.LOGGED_IN
  return loggedIn ? <Outlet /> : <Navigate to='/login' />
}

export default PrivateRoute
