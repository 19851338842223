import { AutoComplete, Button, Checkbox, Input, Modal, Select, Space, Typography } from 'antd'
import styled from 'styled-components'

import React, { useState } from 'react'

import {
  CloneScheduleForm,
  Props as CloneScheduleFormProps,
} from '../../components/LibraryContent/Schedule/CloneScheduleForm'
//components
import {
  Props as TagListProps,
  TagListing,
} from '../../components/LibraryContent/Tag/Listing'
import { getLanguageNameFromValue, SUPPORTED_LANGUAGES_CONFIG_ENGLISH_MODE } from '../../constants'

interface Props extends TagListProps, Omit<CloneScheduleFormProps, 'onClose'> {
  onCreateNewTagClicked: () => void,
  onSearch: (key: string) => void
  onLanguageChange: (key: string) => void
  onIsActiveChange: (isActive: boolean) => void
  isActive: boolean
}

const Container = styled.div`
  padding: 40px;
`

const CTAContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const { Option } = Select

export const TagList: React.FC<Props> = ({
  onCreateNewTagClicked,
  onCloneScheduleSubmit,
  onSearch,
  onLanguageChange,
  onIsActiveChange,
  isActive,
  ...props
}) => {
  console.log({ isActive })
  const [showModal, setShowModal] = useState(false)
  const handleCloneTagClick = () => {
    setShowModal(true)
  }

  const handleCloseClicked = () => {
    setShowModal(false)
  }

  return (
    <Container>
      <Typography.Title level={3}>Content Tag List</Typography.Title>
      <Space direction='vertical' size='middle' style={{ width: '100%' }}>
        <Space direction='horizontal' size='middle' style={{ width: '100%' }}>
          {/* <Select
            showSearch
            placeholder={'Search tags'}
            filterOption={false}
            onSearch={onSearch}
            onSelect={onSearch}
            style={{ minWidth: '400px', width: '100%' }}
          >
            {props.tags.map((tag, index) => (
              <Option key={index} value={tag.name}>
                {tag.name} ({getLanguageNameFromValue(tag.language)})
              </Option>
            ))}
          </Select> */}
          <Input placeholder='search tags' onChange={(e) => onSearch(e.target.value)}></Input>
          <Select
            showSearch
            defaultValue={'hi'}
            optionLabelProp="label"
            optionFilterProp="label"
            style={{ width: '400px' }}
            onChange={onLanguageChange}
          >
            {SUPPORTED_LANGUAGES_CONFIG_ENGLISH_MODE.map((lang, index) => (
              <Select.Option label={lang.alt_name} value={lang.value} key={lang.code}>
                {lang.alt_name}( {lang.name} )
              </Select.Option>
            ))}
          </Select>
          <label> Is active</label>
          <Checkbox checked={isActive} onChange={(e) => onIsActiveChange(e.target.checked)}></Checkbox>
          <CTAContainer>
            <Space size={'large'}>
              <Button onClick={handleCloneTagClick}>
                Clone All Tag Schedules
              </Button>
              <Button onClick={onCreateNewTagClicked} type='primary'>
                Create New Tag
              </Button>
            </Space>
          </CTAContainer>
        </Space>
        <TagListing {...props} />
      </Space>
      <Modal
        title='Tags Content Schedule Clone'
        visible={showModal}
        footer={null}
        onCancel={handleCloseClicked}
      >
        <CloneScheduleForm
          onCloneScheduleSubmit={onCloneScheduleSubmit}
          onClose={handleCloseClicked}
        />
      </Modal>
    </Container>
  )
}
