import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Drawer, Form, Input, InputNumber, Modal, Row, Select, Switch } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import TextArea from 'antd/lib/input/TextArea'
import { useSearchParams } from 'react-router-dom'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Product } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import DynamicInventoryUpdate from '../DynamicInventoryUpdateForm'
import moment, { Moment } from 'moment-timezone';
import { init } from 'xstate/lib/actionTypes'

type AddedItems = { added: Product[] }
interface Props {
    showItemCreation: boolean
    closeItemCreation: () => void
    initialValues: any
    form: FormInstance<any>
    updateRecord: (nameList: any) => void
    cdnUrl: string
    preSignUrl: string
    service_type: string
    addedItems: AddedItems
}

const ManageInventories: React.FC<Props> = ({
    showItemCreation,
    closeItemCreation,
    initialValues,
    form,
    updateRecord,
    cdnUrl,
    preSignUrl,
    service_type,
    addedItems
}) => {
    const [onFieldChanged, setOnFieldChanged] = useState<boolean>(false)
    const [deliverableChange, setDeliverableChange] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [visibleCta, setVisibleCta] = useState<boolean>(false)
    const [searchParams] = useSearchParams()
    const { Option } = Select

    const showModalCta = () => {
        setVisibleCta(true);
    }

    const handleCtaOk = () => {
        setVisibleCta(false);
    }

    const handleCtaCancel = () => {
        setVisibleCta(false);
    }

    const handleValueChange = (newValue: any) => {
        newValue && setOnFieldChanged(true)
    }

    useEffect(() => {
        form.setFieldsValue(initialValues)
    }, [initialValues])

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const disabledDate = (current: Moment | null): boolean => {
        const currentMoment = moment.tz("Asia/Kolkata")
        return current ? current < currentMoment.startOf('day') : false;
    }

    return (
        <>
            <Drawer
                title="Update inventory"
                placement='right'
                onClose={closeItemCreation}
                visible={showItemCreation}
                destroyOnClose={true}

                size='large'
            >
                <Form
                    form={form}
                    layout='vertical'
                    initialValues={{ ...initialValues, service_type: service_type }}
                    onFinish={updateRecord}
                >
                    <Col>
                        <Form.Item
                            label='Id'
                            name='id'
                        >
                            <Input style={{ color: 'black' }} disabled></Input>
                        </Form.Item>
                        <Row style={{ width: '100%' }}>
                            <Form.Item
                                label='Title'
                                name='title'
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                rules={[{ required: true, message: 'Enter Title' }]}
                                style={{ flexBasis: '48%' }}
                            >
                                <Input placeholder='Enter Title' onChange={(e) => handleValueChange(e.target.value)} />
                            </Form.Item>
                            {/* <Form.Item
                                label={`Country code`}
                                name='country_code'
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 28 }}
                                style={{ flexBasis: '48%', marginLeft: '10px' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select country code!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='Country code'
                                    options={countryListData}
                                >
                                </Select>
                            </Form.Item> */}
                        </Row>

                        <Form.Item
                            label='Description'
                            name='description'
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                            rules={[{ required: true, message: 'Enter Description' }]}
                        >
                            <TextArea placeholder='Enter Description' rows={3} onChange={(e) => handleValueChange(e.target.value)} />
                        </Form.Item>

                        <Row style={{ width: '100%' }}>

                            <Form.Item
                                label='Batch size'
                                name='batch_size'
                                labelCol={{ span: 18 }}
                                wrapperCol={{ span: 18 }}
                                rules={[{ required: true, message: 'Enter Batch size' }]}
                                style={{ flexBasis: '20%' }}
                            >
                                <InputNumber placeholder='Enter Batch size' onChange={(e) => handleValueChange(e)} />
                            </Form.Item>

                            <Form.Item
                                label='Quantities'
                                name='quantities'
                                labelCol={{ span: 14 }}
                                wrapperCol={{ span: 14 }}
                                rules={[{ required: true, message: 'Enter quantities' }]}
                                style={{ flexBasis: '20%' }}
                            >
                                <InputNumber placeholder='Enter quantities' onChange={(e) => handleValueChange(e)} />
                            </Form.Item>

                            <Form.Item
                                label='Currency'
                                name='currency'
                                labelCol={{ span: 32 }}
                                wrapperCol={{ span: 32 }}
                                rules={[{ required: true, message: 'Select Currency' }]}
                                style={{ flexBasis: '20%' }}
                            >
                                <Select
                                    style={{ width: '80%' }}
                                    placeholder='Enter price'
                                    onChange={(e) => handleValueChange(e)}
                                >
                                    <Option value='INR'>INR</Option>
                                    <Option value='USD'>USD</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label='Price'
                                name='price'
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }}
                                rules={[{ required: true, message: 'Enter price' }]}
                                style={{ flexBasis: '20%' }}
                            >
                                <InputNumber placeholder='Enter price' onChange={(e) => handleValueChange(e)} />
                            </Form.Item>

                            <Form.Item
                                label='Position'
                                name='position'
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }}
                                rules={[{ required: true, message: 'Enter position' }]}
                                style={{ flexBasis: '20%' }}
                            >
                                <InputNumber placeholder='Enter position' onChange={(e) => handleValueChange(e)} />
                            </Form.Item>

                        </Row>

                        <Row style={{ width: '100%' }}>
                            <Form.Item
                                label='Short Name'
                                name='short_name'
                                labelCol={{ span: 32 }}
                                wrapperCol={{ span: 32 }}
                                style={{ width: '45%' }}
                                rules={[{ required: false, message: 'Enter Short Name' }]}
                            >
                                <Input placeholder='Enter Short Name' onChange={(e) => handleValueChange(e.target.value)}/>
                            </Form.Item>

                            <Form.Item
                                label='Display Name'
                                name='display_name'
                                labelCol={{ span: 32 }}
                                wrapperCol={{ span: 32 }}
                                style={{ width: '55%', paddingLeft: '10px' }}
                                rules={[{ required: false, message: 'Enter Display Name' }]}
                            >
                                <Input placeholder='Enter Display Name' onChange={(e) => handleValueChange(e.target.value)} />
                            </Form.Item>
                        </Row>

                        <Row>
                            <Form.Item shouldUpdate={() => true} style={{ width: '100%' }}>
                                {({ getFieldValue }) => {
                                    return (getFieldValue("item_type") === 'item' && searchParams?.get('service_type') === 'pooja') ?
                                        <Form.Item
                                            label='Campaigns'
                                            style={{ width: '100%', marginBottom: '0px' }}
                                            labelCol={{ span: 32 }}
                                            wrapperCol={{ span: 32 }}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Please input Campaign',
                                                },
                                            ]}
                                            name='campaignList'
                                            hidden
                                        >
                                            <Select
                                                mode="multiple"
                                                size='middle'
                                                placeholder="Please select"
                                                defaultValue={[]}
                                                style={{ width: '100%' }}
                                                allowClear
                                                onChange={() => handleValueChange("Selected")}
                                            >
                                                {initialValues?.campaignArray?.map((id: any) => (
                                                    <Select.Option key={id[1]} value={id[1]} onChange={(e: any) => handleValueChange(e.target)}>
                                                        {id[1]}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item> :

                                        <Form.Item
                                            label='Campaigns'
                                            style={{ width: '100%', marginBottom: '0px' }}
                                            labelCol={{ span: 32 }}
                                            wrapperCol={{ span: 32 }}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Please input Campaign',
                                                },
                                            ]}
                                            name='campaignList'
                                        >
                                            <Select
                                                mode="multiple"
                                                size='middle'
                                                placeholder="Please select"
                                                defaultValue={[]}
                                                style={{ width: '100%' }}
                                                allowClear
                                                onChange={() => handleValueChange("Selected")}
                                            >
                                                {initialValues?.campaignArray?.map((id: any) => (
                                                    <Select.Option key={id[1]} value={id[1]} onChange={(e: any) => handleValueChange(e.target)}>
                                                        {id[1]}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                }
                                }
                            </Form.Item>
                            {searchParams?.get('store_type') === 'mixed' && <Form.Item
                                label='Store list'
                                name='origin_store_id'
                                style={{ width: '100%' }}
                                labelCol={{ span: 32 }}
                                wrapperCol={{ span: 32 }}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select store list',
                                    },
                                ]}

                            >
                                <Select
                                    size='middle'
                                    placeholder="Please select store list"
                                    style={{ width: '100%' }}
                                    allowClear
                                    onChange={(value) => console.log('Selected Value', value)}
                                >
                                    {initialValues?.storeList?.map((store: any) => (
                                        <Select.Option key={store?.value} value={store?.value}>
                                            {store?.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>}
                        </Row>

                        <Row>
                            <Form.Item shouldUpdate={() => true} style={{ width: '100%' }}>
                                {({ getFieldValue }) => {
                                    return (getFieldValue("item_type") === 'sankalp' &&
                                        <>
                                            <Row>
                                                <Form.Item
                                                    name={['sankalp_bg_image']}
                                                    label="Sankalp background"
                                                    labelCol={{ span: 28 }}
                                                    wrapperCol={{ span: 28 }}
                                                    rules={[
                                                        {
                                                            min: 1,
                                                            max: 1,
                                                            validator: async (rules: any, values: any) => {
                                                                if (values?.length < rules.min) {
                                                                    return Promise.reject('Sanakap Required')
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                    valuePropName='fileList'
                                                >
                                                    <S3Upload
                                                        cdnUrl={cdnUrl}
                                                        preSignUrl={preSignUrl}
                                                        min={1}
                                                        max={1}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Count'
                                                    name={['offering_config', 'count']}
                                                    labelCol={{ span: 22 }}
                                                    wrapperCol={{ span: 22 }}
                                                    rules={[{ required: true, message: 'Enter Count' }]}
                                                    style={{ flexBasis: '16%', paddingLeft: '15px' }}
                                                >
                                                    <InputNumber placeholder='Count' onChange={(e) => handleValueChange(e)} />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Interval'
                                                    name={['offering_config', 'interval']}
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    rules={[{ required: true, message: 'Select Interval' }]}
                                                    style={{ flexBasis: '20%', paddingLeft: '15px' }}
                                                >
                                                    <Select
                                                        placeholder='Enter Interval'
                                                        onChange={(e) => handleValueChange(e)}
                                                    >
                                                        <Option value='Day'>Day</Option>
                                                        <Option value='Month'>Month</Option>
                                                        <Option value='Monday'>Monday</Option>
                                                        <Option value='Tuesday'>Tuesday</Option>
                                                        <Option value='Wednesday'>Wednesday</Option>
                                                        <Option value='Thursday'>Thursday</Option>
                                                        <Option value='Friday'>Friday</Option>
                                                        <Option value='Saturday'>Saturday</Option>
                                                        <Option value='Sunday'>Sunday</Option>
                                                    </Select>
                                                </Form.Item>

                                                {getFieldValue(['offering_config', 'interval']) === 'Month' && (
                                                    <Form.Item
                                                        label='Date'
                                                        name={['offering_config', 'date']}
                                                        labelCol={{ span: 28 }}
                                                        wrapperCol={{ span: 28 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Pick Date',
                                                            },
                                                        ]}
                                                        style={{ flexBasis: '22%', paddingLeft: '12px' }}
                                                    >
                                                        <DatePicker />
                                                    </Form.Item>
                                                )}
                                            </Row>
                                            <Row>
                                                <Form.Item
                                                    label='Offering SKU Id'
                                                    name='offering_sku_id'
                                                    style={{ width: '100%' }}
                                                    labelCol={{ span: 32 }}
                                                    wrapperCol={{ span: 32 }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select Offering SKU Id',
                                                        },
                                                    ]}

                                                >
                                                    <Select
                                                        size='middle'
                                                        placeholder="Please select Offering SKU Id"
                                                        style={{ width: '100%' }}
                                                        allowClear
                                                    >
                                                        {addedItems?.added?.filter((product: Product) => product?.active)?.map((item: Product) => (
                                                            <Select.Option key={item?.id} value={item?.id}>
                                                                {item?.title}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Row>
                                        </>
                                    )
                                }
                                }
                            </Form.Item>
                        </Row>

                        <Row>
                            <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues?.deliverable !== currentValues?.deliverable}>
                                {({ getFieldValue }) => {
                                    const deliverable = getFieldValue('deliverable');

                                    return (
                                        <Form.Item
                                            label='Multi Add'
                                            style={{ width: '100%', marginBottom: '0px' }}
                                            labelCol={{ span: 32 }}
                                            wrapperCol={{ span: 32 }}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Please input Multi Add',
                                                },
                                            ]}
                                            name='multi_add'
                                        >
                                            <Switch
                                                disabled={deliverable}
                                                {...(deliverable ? { checked: false } : { defaultChecked: initialValues?.multi_add })}
                                            />

                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                            <Form.Item
                                style={{ marginLeft: '50px' }}
                                name='active'
                                label='Status'
                                valuePropName='checked'
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                style={{ marginLeft: '50px' }}
                                name='bestseller'
                                label='Best Seller'
                                valuePropName='checked'
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues?.item_type !== currentValues?.item_type}>
                                {({ getFieldValue }) => {
                                    const itemType = getFieldValue('item_type');

                                    return (
                                        <Form.Item
                                            style={{ marginLeft: '50px' }}
                                            name='deliverable'
                                            label='Deliverable'
                                            valuePropName='checked'
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <Switch disabled={itemType === 'sankalp'} />
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                            <Form.Item
                                style={{ marginLeft: '50px' }}
                                name='item_type'
                                label='Item type'
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                hidden
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                style={{ marginLeft: '50px' }}
                                label={`CTA`}
                                name='cta'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter CTA details',
                                    },
                                ]}
                            >
                                <Button style={{ width: 100, background: '#1890ff', color: 'white' }} onClick={showModalCta}>
                                    CTA
                                </Button>
                                <Modal
                                    title="CTA"
                                    visible={visibleCta}
                                    onOk={handleCtaOk}
                                    onCancel={handleCtaCancel}
                                    width="35vw"
                                    style={{ marginTop: '15px' }}
                                >
                                    <>
                                        <Col span={24}>
                                            <Row justify='space-between'>
                                                <Form.Item
                                                    label='Cta'
                                                    name={['cta', 'text']}
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    rules={[{ required: true, message: 'Please input cta text!' }]}
                                                    style={{ width: '100%' }}
                                                >
                                                    <Input placeholder='Enter Cta text' />
                                                </Form.Item>


                                            </Row>

                                            <Row justify='space-between'>
                                                <Form.Item
                                                    label='Cta Color'
                                                    name={['cta', 'color']}
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    rules={[{ required: false, message: 'Please input cta color!' }]}
                                                >
                                                    <Input placeholder='Enter Cta Color' />
                                                </Form.Item>
                                                <Form.Item
                                                    name={['cta', 'icon', 'url']}
                                                    label="Media url"
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    rules={[
                                                        {
                                                            min: 0,
                                                            max: 1,
                                                            validator: async (rules: any, values: any) => {
                                                                if (values?.length < rules.min) {
                                                                    return Promise.reject('Please upload Cta Image')
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                    valuePropName='fileList'
                                                >
                                                    <S3Upload
                                                        cdnUrl={cdnUrl}
                                                        preSignUrl={preSignUrl}
                                                        min={1}
                                                        max={1}
                                                    />
                                                </Form.Item>
                                            </Row>
                                        </Col>
                                    </>
                                </Modal>
                            </Form.Item>
                        </Row>

                        <Row style={{ width: '100%' }}>
                            <Form.Item
                                label='Item Image'
                                name='image_url'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName='fileList'
                                rules={[
                                    {
                                        min: 1,
                                        max: 1,
                                        validator: async (
                                            rules: any,
                                            values: any,
                                        ) => {
                                            if (values?.length < rules.min) {
                                                return Promise.reject(
                                                    'Upload Item Image',
                                                )
                                            }
                                        },
                                    },
                                ]}
                            >
                                <S3Upload
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    min={1}
                                    max={1}
                                    onChange={(e: any) => handleValueChange(e)}
                                />
                            </Form.Item>

                            <Form.Item
                                style={{ marginLeft: '20px' }}
                                label='Bottom sheet'
                                name='bottomsheet_image_url'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName='fileList'
                                rules={[
                                    {
                                        min: 1,
                                        max: 1,
                                        validator: async (
                                            rules: any,
                                            values: any,
                                        ) => {
                                            if (values?.length < rules.min) {
                                                return Promise.reject(
                                                    'Upload Bottom sheet',
                                                )
                                            }
                                        },
                                    },
                                ]}
                            >
                                <S3Upload
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    min={1}
                                    max={1}
                                    onChange={(e: any) => handleValueChange(e)}
                                />
                            </Form.Item>

                            <Form.Item shouldUpdate={() => true}>
                                {({ getFieldValue }) =>
                                    getFieldValue("item_type") !== 'pooja' &&
                                    <Form.Item
                                        style={{ marginLeft: '80px' }}
                                        label='Intro Video'
                                        name='intro_video_url'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        valuePropName='fileList'
                                        rules={[
                                            {
                                                min: 1,
                                                max: 1,
                                                validator: async (
                                                    rules: any,
                                                    values: any,
                                                ) => {
                                                    if (values?.length < rules.min) {
                                                        return Promise.reject(
                                                            'Upload intro video',
                                                        )
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        <S3Upload
                                            cdnUrl={cdnUrl}
                                            preSignUrl={preSignUrl}
                                            min={1}
                                            max={1}
                                            onChange={(e: any) => handleValueChange(e)}
                                        />
                                    </Form.Item>
                                }
                            </Form.Item>

                            <Form.Item shouldUpdate={() => true}>
                                {({ getFieldValue }) =>
                                    getFieldValue("item_type") !== 'pooja' &&
                                    <Form.Item
                                        style={{ marginLeft: '20px' }}
                                        label='Outro Video'
                                        name='outro_video_url'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        valuePropName='fileList'
                                        rules={[
                                            {
                                                min: 1,
                                                max: 1,
                                                validator: async (
                                                    rules: any,
                                                    values: any,
                                                ) => {
                                                    if (values?.length < rules.min) {
                                                        return Promise.reject(
                                                            'Upload Outro video',
                                                        )
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        <S3Upload
                                            cdnUrl={cdnUrl}
                                            preSignUrl={preSignUrl}
                                            min={1}
                                            max={1}
                                            onChange={(e: any) => handleValueChange(e)}
                                        />
                                    </Form.Item>
                                }
                            </Form.Item>

                        </Row>

                        <Row style={{ width: '100%' }}>

                            <Form.Item shouldUpdate={() => true}>
                                {({ getFieldValue }) =>
                                    getFieldValue("item_type") === 'pooja' &&
                                    <Form.Item
                                        label='Member size'
                                        name='member_size'
                                        style={{ flexBasis: '25%' }}
                                        rules={[{ required: true, message: 'Enter Member size' }]}
                                    >
                                        <InputNumber placeholder='Enter Member size' onChange={(e) => handleValueChange(e)} />
                                    </Form.Item>
                                }
                            </Form.Item>

                            <Form.Item shouldUpdate={() => true}>
                                {({ getFieldValue }) =>
                                    getFieldValue("item_type") === 'pooja' &&
                                    <Form.Item
                                        label='Specificity'
                                        name='specificity'
                                        style={{ flexBasis: '50%', marginLeft: '15px' }}
                                        rules={[{ required: true, message: 'Enter Specificity' }]}
                                    >
                                        <Input placeholder='Enter Specificity' onChange={(e) => handleValueChange(e.target.value)} />
                                    </Form.Item>
                                }
                            </Form.Item>

                        </Row>

                        <Row style={{ width: '100%' }}>
                            <Form.Item shouldUpdate={() => true}>
                                {({ getFieldValue }) =>
                                    (getFieldValue("item_type") === 'pooja' || searchParams?.get('store_type') === 'preBooking') &&
                                    <Form.Item
                                        name='process_date'
                                        label='Process Date'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Pick Date',
                                            },
                                        ]}
                                    >
                                        <DatePicker disabledDate={disabledDate} showTime />
                                    </Form.Item>
                                }
                            </Form.Item>
                        </Row>

                        <Form.Item
                            shouldUpdate={() => true}
                        >
                            {({ getFieldValue }) =>
                                getFieldValue("item_type") === 'pooja' &&
                                <Row style={{ border: '1px solid #e0dcdc', padding: '10px', borderRadius: '5px' }}>
                                    <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                        <Form.Item
                                            label='Bullet Icon'
                                            name={['additional_info', 'iconImg']}
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            valuePropName='fileList'
                                            style={{ marginBottom: '0px' }}
                                            rules={[
                                                {
                                                    min: 1,
                                                    max: 1,
                                                    validator: async (
                                                        rules: any,
                                                        values: any,
                                                    ) => {
                                                        if (values?.length < rules.min) {
                                                            return Promise.reject(
                                                                'Upload Icon',
                                                            )
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <S3Upload
                                                cdnUrl={cdnUrl}
                                                preSignUrl={preSignUrl}
                                                min={1}
                                                max={1}
                                                onChange={(e: any) => handleValueChange(e)}
                                            />
                                        </Form.Item>
                                        <Form.Item shouldUpdate={() => true} style={{ width: '80%' }}>
                                            {({ getFieldValue }) =>
                                                getFieldValue(['additional_info', 'error']) === true &&
                                                <Form.Item
                                                    label='Additional Info'
                                                    name={['additional_info', 'additionInfo']}
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    style={{ marginBottom: '0px', width: '100%' }}
                                                >
                                                    <TextArea rows={4} style={{ paddingRight: '30px' }} />
                                                </Form.Item>}
                                        </Form.Item>
                                    </Row>
                                    <Form.List name={['additional_info', 'list']}>
                                        {(fields, { add, remove }) => {
                                            return (
                                                <>
                                                    <Col span={24} style={{ width: '100%' }}>
                                                        {fields.map((field: any, index: number) => {
                                                            return (
                                                                <Col key={field.key} style={{ paddingRight: '12px', paddingTop: '10px' }}>
                                                                    <div
                                                                        style={{
                                                                            border: '1px dashed #e0dcdc',
                                                                            padding: '10px',
                                                                        }}
                                                                    >
                                                                        <Row justify="space-between" style={{ width: '100%' }}>
                                                                            <Form.Item
                                                                                label={`Point ${index + 1}`}
                                                                                name={[field.name]}
                                                                                labelCol={{ span: 28 }}
                                                                                wrapperCol={{ span: 28 }}
                                                                                style={{ flexBasis: '85%', marginBottom: '0px' }}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: 'Enter Additional Info',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <TextArea rows={2} placeholder="Enter Additional Info" />
                                                                            </Form.Item>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    remove(field.name);
                                                                                }}
                                                                                type="ghost"
                                                                                icon={<DeleteOutlined />}
                                                                                size="middle"
                                                                                danger
                                                                                style={{ marginTop: '30px' }}
                                                                            ></Button>
                                                                        </Row>

                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Col>

                                                    <Col span={24} style={{ paddingTop: '15px' }}>
                                                        <Row justify="start">
                                                            <Col span={4}>
                                                                <Button
                                                                    onClick={() => {
                                                                        add()
                                                                    }}
                                                                    type="ghost"
                                                                    icon={<PlusCircleOutlined />}
                                                                    size="middle"
                                                                >
                                                                    Add Additional Info
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </>
                                            );
                                        }}
                                    </Form.List>
                                </Row>
                            }
                        </Form.Item>

                        {/* <Form.Item shouldUpdate={() => true}>
                                {({ getFieldValue }) =>
                                    getFieldValue("item_type") === 'pooja' &&
                                    <Form.Item
                                        name='additional_info'
                                        label='Additional Info'
                                        labelCol={{ span: 28 }}
                                        wrapperCol={{ span: 28 }}
                                        style={{ marginLeft: '20px', width: '200%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Enter Additional info',
                                            },
                                        ]}
                                    >
                                        <TextArea rows={3} onChange={(e) => handleValueChange(e.target)} placeholder='Enter Additional Info' />
                                    </Form.Item>
                                }
                            </Form.Item> */}

                        <Button htmlType="submit" disabled={!onFieldChanged}>Update</Button>
                        <Button onClick={handleButtonClick} style={{ marginLeft: '20px' }}>Dynamic Update</Button>
                        <DynamicInventoryUpdate
                            showDynamicInventoryUpdate={showModal}
                            inventoryId={initialValues?.id}
                            inventoryName={initialValues?.title}
                            closeDynamicUpdate={handleCloseModal}
                        />

                    </Col>
                </Form>
            </Drawer >
        </>
    )
}

export default ManageInventories
