
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useCallback, useEffect, useState } from "react";
import { SocialScreenContainer } from "@a4b/ui/src/admin";
import Filters from "./components/Filters";
import { Button } from "antd";
import useTableConfig from "./hooks/useTableConfig";
import useDrawerConfig, { DRAWER_TYPE } from "./hooks/useDrawerConfig";
import { useAppContext } from "@/components/AppContext";
import useAntdPagination, { DEFAULT_PAGINATION_CONFIG } from "@/hooks/useAntdPagination";
import { Shareapp } from "@a4b/api/src/modules/ShareText/types";
import { SHARE_TEXT_CONFIG } from "../helpers/config";
import { createSearchParams, useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const ShareTextApp = function (props: any) {
  const [activeDrawer, setActiveDrawer] = useState<DRAWER_TYPE>();
  const { networkInstance } = useAppContext();
  const { pagination, setPagination } = useAntdPagination();
  const [shareApps, setShareApp] = useState<Shareapp[] | null>(null);
  const [editShareApp, setEditShareApp] = useState<Shareapp | null>(null);

  const [searchParams] = useSearchParams();
  const [selectedPlatform, setSelectedPlatform] = useState(searchParams.get('platform') || SHARE_TEXT_CONFIG.data.platform_type[3]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        platform: selectedPlatform
      }).toString()
    });
  }, [location.pathname, navigate, selectedPlatform])

  const onChangePlatform = useCallback((val) => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        platform: val
      }).toString()
    });
    setSelectedPlatform(val);
  }, [location.pathname, navigate])


  const getShareTextApps = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.shareText.getShareApps((pagination.pageSize) || 50, ((pagination.current || 1) - 1) * (pagination.pageSize || 50), selectedPlatform);
      setShareApp(res.data.data.share_apps)
      if (pagination.total !== res.data.data.count) {
        setPagination({ ...DEFAULT_PAGINATION_CONFIG, total: res.data.data.count })
      }
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.shareText, pagination, selectedPlatform, setPagination])

  useEffect(() => {
    getShareTextApps()
  }, [getShareTextApps])

  const setDrawer = (Drawer: DRAWER_TYPE) => {
    setActiveDrawer(Drawer);
  }

  const triggerEditShareApp = useCallback((shareApp: Shareapp) => {
    setEditShareApp(shareApp);
    setActiveDrawer(DRAWER_TYPE.UPDATE_APP);
  }, [])


  const drawerConfig = useDrawerConfig(getShareTextApps, activeDrawer, editShareApp);
  const { drawerProps, drawerContent } = drawerConfig;

  const tableConfig = useTableConfig(shareApps || [], pagination, setPagination, setDrawer, triggerEditShareApp);


  const onCreateApp = () => {
    setActiveDrawer(DRAWER_TYPE.CREATE_APP);
  }

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  }

  return <SocialScreenContainer
    title={"Share apps"}
    filtersUI={<Filters selectedPlatform={selectedPlatform} changeSelectedPlatform={onChangePlatform} />}
    extra={<Button onClick={onCreateApp}> Create app </Button>}
    tableProps={tableConfig}
    drawerProps={drawerProps ? { ...drawerProps, onClose: onDrawerClose } : undefined}
    drawerContent={drawerContent}
  />

};

export default withDefaultLayout(ShareTextApp)