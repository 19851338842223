/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Button, Drawer, Form, Input, Select } from 'antd'
import { HubListItem } from '@/../../../packages/a4b-api/src/modules/Monetisation/Offerings/types'

const { Option } = Select
interface Props {
    onClose: () => void
    visible: boolean
    formSubmithandler: (values: any) => void
    form: any
    initialValues: any
    isEditJourney: boolean
    userType: any
    hubList: any
}
const UserCreation: React.FC<Props> = ({
    onClose,
    visible,
    formSubmithandler,
    form,
    initialValues,
    isEditJourney,
    userType,
    hubList
}) => {

    let userTypeoptions: string[] = [];
    if (userType === 'super_admin') {
        userTypeoptions = ['admin', 'hub_manager', 'hub_associate'];
    } else if (userType === 'admin') {
        userTypeoptions = ['hub_manager', 'hub_associate'];
    } else if (userType === 'hub_manager') {
        userTypeoptions = ['hub_associate'];
    }

    const displayNameMap: any = {
        admin: 'Admin',
        hub_manager: 'Hub Manager',
        hub_associate: 'Hub Associate'
    };

    return (
        <Drawer
            title='Create User'
            placement='right'
            onClose={onClose}
            visible={visible}
            destroyOnClose={true}
            width={'35%'}
        >
            <Form
                form={form}
                layout='vertical'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={initialValues}
            >
                <Form.Item
                    label='User Name'
                    name='name'
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 14 }}
                    rules={[{ required: true, message: 'Please input user name!' }]}
                >
                    <Input placeholder='Enter User Name' />
                </Form.Item>
                <Form.Item
                    label='Email'
                    name='email'
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 14 }}
                    rules={[{ required: true, message: 'Please input email!' }]}
                >
                    <Input placeholder='Enter User Email' />
                </Form.Item>
                <Form.Item
                    label={`User type`}
                    name='user_type'
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 14 }}
                    rules={[{ required: true, message: '' }]}
                >
                    <Select style={{ width: '100%' }} placeholder='Type' disabled={initialValues?.user_type}>
                        {userTypeoptions?.map((option) => (
                            <Option key={option} value={option}>
                                {displayNameMap[option]}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues?.user_type !== currentValues?.user_type}>
                    {({ getFieldValue }) => {
                        return (
                            <Form.Item
                                label="Contact Number"
                                name="contact_number"
                                labelCol={{ span: 14 }}
                                wrapperCol={{ span: 14 }}
                                rules={[
                                    { required: getFieldValue('user_type') === 'hub_associate' || getFieldValue('user_type') === 'hub_manager' || initialValues?.user_type === 'hub_associate' || initialValues?.user_type === 'hub_manager', message: 'Please input contact number!' },
                                ]}
                            >
                                <Input
                                    placeholder="Enter contact number of the user"
                                    disabled={initialValues?.contact_number !== undefined && initialValues?.contact_number !== '' && initialValues?.contact_number !== null}
                                    addonBefore="+91"
                                    minLength={10}
                                    maxLength={10}
                                />
                            </Form.Item>
                        );
                    }}
                </Form.Item>
                <Form.Item shouldUpdate={() => true} style={{ width: '100%' }}>
                    {({ getFieldValue }) => {
                        const userType = getFieldValue("user_type") || initialValues?.user_type;
                        return (userType === 'hub_associate' || userType === 'hub_manager') &&
                            <Form.Item
                                label={`Hubs`}
                                name='hub_ids'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 14 }}
                                rules={[{ required: true, message: 'Please select hub!' }]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder='Type'
                                    mode={userType === 'hub_associate' ? "multiple" : undefined}
                                    disabled={userType === 'hub_manager' && initialValues?.hub_ids}
                                >
                                    {hubList?.hubs?.map((hub: HubListItem) => (
                                        <Option key={hub?.id} value={hub?.id}>
                                            {hub?.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                    }
                    }
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ paddingTop: '30px' }}
                >
                    {isEditJourney ? <Button type='primary' onClick={formSubmithandler}>
                        Update User
                    </Button> : <Button type='primary' onClick={formSubmithandler}>
                        Create User
                    </Button>}
                </Form.Item>
            </Form>
        </Drawer>
    )
}
export default UserCreation
