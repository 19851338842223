import { useMemo } from 'react';
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useCallback, useEffect, useState } from 'react'
import 'antd/dist/antd.css';
import { Button, Form, Select, Switch, TablePaginationConfig, Tag, message, } from 'antd';
import { SocialScreenContainer } from '@a4b/ui/src/admin';
import { openNotification } from '@/utils';
import { Shrine } from '@/../../../packages/a4b-api/src/modules/Temple/types';
import { getEditImageFiles, getPathFormFile, getTypeFormFile } from '@a4b/ui/src/modules/admin/components/FileUpload';
import useAntdPagination from '@/hooks/useAntdPagination';
import { FORM_CONFIG, FORM_ITEM_TYPE, GOD_ITEMS, GOD_ITEM_TYPES } from './dynamic-items-definitions';
import { CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import GodItemForm from './god-item-form';
import { useNavigate } from 'react-router-dom';


const ITEM_FORM_DEFAULT_FEILDS_SET = new Set(['code', 'type', 'access_type', 'thumbnail', 'attachment', 'coins_cost', 'validity_period', 'available_for_all_gods', 'days_active']);


const storeSelectedGodItem = (selectedGodItem: string) => {
  localStorage.setItem("SELECTED_GOD_ITEM", selectedGodItem);
}

const getStoredSelectedGodItem = () => {
  return localStorage.getItem("SELECTED_GOD_ITEM");
}

// console.log({ GOD_ITEMS })
const TempleGodItems = () => {
  const { networkInstance } = useAppContext();
  const navigate = useNavigate()
  const [showDrawer, setShowDrawer] = useState(false);
  const [editItem, setEditItem] = useState<undefined | Shrine.Item>();
  const [associateItemId, setAssociateItemId] = useState<undefined | string>();
  const [godItems, setGodItems] = useState<Shrine.Item[]>([])
  const { pagination, setPagination } = useAntdPagination();
  const [form] = Form.useForm();
  const [selectedGodItem, setSelectedGodItem] = useState<GOD_ITEM_TYPES>(getStoredSelectedGodItem() as GOD_ITEM_TYPES || GOD_ITEM_TYPES.CHADAVA_ICON);

  const loadGodItems = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.shrineApi.getGodItems(pagination.pageSize, (pagination.current ? pagination.current - 1 : 0) * (pagination.pageSize || 50), selectedGodItem);
      setGodItems(res.data.data.items);
      if (pagination.total === -1) {
        setPagination({ ...pagination, total: res.data.data.count })
      }
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.shrineApi, pagination, selectedGodItem, setPagination])

  useEffect(() => {
    loadGodItems()
  }, [loadGodItems])

  useEffect(() => {
    const itemCopy: any = { ...editItem };
    // @ts-ignore
    itemCopy['attachment'] = editItem?.attachment ? getEditImageFiles([editItem['attachment']]) : undefined;
    itemCopy['thumbnail'] = editItem?.thumbnail ? getEditImageFiles([editItem['thumbnail']]) : undefined;

    if (editItem?.property) {
      Object.keys(editItem.property).forEach((propertyKey) => {
        // @ts-ignore
        const config = FORM_CONFIG[editItem.type];
        if (config[propertyKey]?.type === FORM_ITEM_TYPE.STRING || config[propertyKey]?.type === FORM_ITEM_TYPE.NUMBER) {
          // @ts-ignore 
          itemCopy[propertyKey] = editItem.property[propertyKey]

        } else {
          //@ts-ignore
          if (propertyKey === 'states') {
            //@ts-ignore 
            itemCopy['0'] = editItem.property[propertyKey]['0'] ? getEditImageFiles([editItem.property[propertyKey]['0']]) : undefined
            //@ts-ignore 
            itemCopy['100'] = editItem.property[propertyKey]['100'] ? getEditImageFiles([editItem.property[propertyKey]['100']]) : undefined
            return
          }

          // @ts-ignore
          itemCopy[propertyKey] = editItem.property[propertyKey] && editItem.property[propertyKey].type !== '' ?
            //@ts-ignore
            getEditImageFiles([editItem.property[propertyKey]])
            : undefined
        }
      })
    }
    if (editItem?.access_type === 'coins_based') {
      itemCopy['coins_cost'] = editItem.coins_threshold
      itemCopy['validity_period'] = editItem.validity_prd_secs
    }
    if (editItem?.access_type === 'days_active_based') {
      itemCopy['coins_cost'] = editItem.coins_threshold
      itemCopy['validity_period'] = editItem.validity_prd_secs
      itemCopy['days_active'] = editItem.days_active_threshold
    }

    itemCopy['classification'] = editItem?.property?.['classification'] ? editItem?.property?.["classification"] : "others"

    // handling audio attechment url for item type is prayer 
    if (itemCopy.type === GOD_ITEM_TYPES.PRAYER) {
      itemCopy['audio_attachment'].path = itemCopy['audio_attachment'].url.replace(/^.*\/\/[^\/]+/, '');
      delete itemCopy['audio_attachment'].url
    }

    // handling date range for vishash 
    if (itemCopy.type === GOD_ITEM_TYPES.VISHESH) {
      // @ts-ignore
      itemCopy['date_range'] = [moment(editItem['start_time']), moment(editItem['end_time'])]
    }
    console.log({ itemCopy })
    form.setFieldsValue(itemCopy)
  }, [editItem, form])


  const handleDrawerClose = useCallback(() => {
    setShowDrawer(false);
    setEditItem(undefined);
    form.resetFields();
  }, [form])

  const handleDrawerOpen = useCallback(() => {
    setShowDrawer(true);
  }, [setShowDrawer])

  const updateGodItem = useCallback(async (values) => {
    if (editItem?.id === undefined) return null;
    const payload: Shrine.CreateGodItemPayload = {
      code: values.code,
      type: values.type,
      attachment: {
        path: getPathFormFile(values.attachment),
        type: getTypeFormFile(values.attachment)
      },
      property: {},
      access_type: values.access_type,
      available_for_all_gods: !!values.available_for_all_gods
    }
    if (values.thumbnail) {
      payload['thumbnail'] = {
        path: getPathFormFile(values.thumbnail),
        type: getTypeFormFile(values.thumbnail)
      }
    }

    if (values.access_type === 'coins_based') {
      payload['coins_cost'] = values.coins_cost
      payload['validity_period'] = values.validity_period
    }
    if (values.access_type === 'days_active_based') {
      payload['coins_cost'] = values.coins_cost
      payload['days_active'] = values.days_active
      payload['validity_period'] = values.validity_period
    }

    const property: { [key: string]: string | { type: string, path: string } } = {};
    Object.keys(values).forEach((fieldName: string) => {
      if (!ITEM_FORM_DEFAULT_FEILDS_SET.has(fieldName)) {
        // @ts-ignore 
        const godItem = FORM_CONFIG[values.type]
        if (godItem[fieldName].type === FORM_ITEM_TYPE.DATE_RANGE) {
          // @ts-ignore
          payload[godItem[fieldName].field_names[0]] = moment(values[fieldName][0]).startOf('day').valueOf()
          // @ts-ignore
          payload[godItem[fieldName].field_names[1]] = moment(values[fieldName][1]).endOf('day').valueOf()
          return
        }
        if (godItem[fieldName].type === FORM_ITEM_TYPE.STRING || godItem[fieldName].type === FORM_ITEM_TYPE.NUMBER) {
          property[fieldName] = values[fieldName]
        } else {
          property[fieldName] = {
            path: getPathFormFile(values[fieldName]),
            type: getTypeFormFile(values[fieldName])
          }
        }
      }
    })
    if (values.type === GOD_ITEM_TYPES.IGNITABLE) {
      //@ts-ignore
      property['states'] = {};
      //@ts-ignore
      property['states']['0'] = property['0'];
      //@ts-ignore
      property['states']['100'] = property['100'];
      delete property['100'];
      delete property['0'];
    }
    payload['property'] = property;

    // removing coin cost and validity period for prayer type
    if (payload.type === GOD_ITEM_TYPES.PRAYER) {
      delete payload['coins_cost']
      delete payload['validity_period']
    }
    try {
      await networkInstance.clientWithHeaders.shrineApi.updateGodItem(editItem?.id, payload)
      openNotification('success', { message: 'god item updated successfully!' })
      form.resetFields();
      setShowDrawer(false);
      loadGodItems();
    } catch (error: any) {
      openNotification('error', { message: 'got item updation failed!', description: error.toString(), })
    }
  }, [editItem?.id, form, loadGodItems, networkInstance.clientWithHeaders.shrineApi])



  const createGodItem = useCallback(async (values) => {
    const payload: Shrine.CreateGodItemPayload = {
      code: values.code,
      type: values.type,
      attachment: {
        path: getPathFormFile(values.attachment),
        type: getTypeFormFile(values.attachment)
      },
      property: {},
      access_type: values.access_type,
      available_for_all_gods: !!values.available_for_all_gods
    }
    if (values.thumbnail) {
      payload['thumbnail'] = {
        path: getPathFormFile(values.thumbnail),
        type: getTypeFormFile(values.thumbnail)
      }
    }
    if (values.access_type === 'coins_based') {
      payload['coins_cost'] = values.coins_cost
      payload['validity_period'] = values.validity_period
    }
    if (values.access_type === 'days_active_based') {
      payload['coins_cost'] = values.coins_cost
      payload['days_active'] = values.days_active
      payload['validity_period'] = values.validity_period
    }
    if (values.access_type === 'free') {
      payload['coins_cost'] = 0
      payload['validity_period'] = 0
    }

    // string up property based on types 
    const property: { [key: string]: string | { type: string, path: string } } = {};
    Object.keys(values).forEach((fieldName: string) => {
      if (!ITEM_FORM_DEFAULT_FEILDS_SET.has(fieldName)) {
        // @ts-ignore 
        const godItem = FORM_CONFIG[values.type]
        if (godItem[fieldName].type === FORM_ITEM_TYPE.DATE_RANGE) {
          // @ts-ignore
          payload[godItem[fieldName].field_names[0]] = moment(values[fieldName][0]).startOf('day').valueOf()
          // @ts-ignore
          payload[godItem[fieldName].field_names[1]] = moment(values[fieldName][1]).endOf('day').valueOf()
          return
        }
        if (godItem[fieldName].type === FORM_ITEM_TYPE.STRING || godItem[fieldName].type === FORM_ITEM_TYPE.NUMBER) {
          property[fieldName] = values[fieldName]
        } else {
          property[fieldName] = {
            path: getPathFormFile(values[fieldName]),
            type: getTypeFormFile(values[fieldName])
          }
        }
      }
    })
    if (values.type === GOD_ITEM_TYPES.IGNITABLE) {
      //@ts-ignore
      property['states'] = {};
      //@ts-ignore
      property['states']['0'] = property['0'];
      //@ts-ignore
      property['states']['100'] = property['100'];
      delete property['100'];
      delete property['0'];
    }
    payload['property'] = property;

    // removing coin cost and validity period for prayer type
    if (payload.type === GOD_ITEM_TYPES.PRAYER) {
      delete payload['coins_cost']
      delete payload['validity_period']
    }

    try {
      await networkInstance.clientWithHeaders.shrineApi.createGodItem(payload)
      openNotification('success', { message: 'god item created successfully!' })
      form.resetFields();
      loadGodItems();
    } catch (error: any) {
      openNotification('error', { message: 'got item creation failed!', description: error.toString(), })
    }
  }, [form, loadGodItems, networkInstance.clientWithHeaders.shrineApi])

  const [messageApi, contextHolder] = message.useMessage();


  const info = useMemo(() => () => {
    messageApi.info('Copied!!');
  }, [messageApi]);

  const drawerContent = useMemo(() => {
    return <Form
      form={form}
      onFinish={editItem ? updateGodItem : createGodItem}
      layout="vertical"
    >
      {/* Moved God item form seperate component */}
      <GodItemForm form={form} editItem={editItem} />
    </Form>

  }, [createGodItem, editItem, form, updateGodItem])


  const activateGod = useCallback(async (godId: string) => {
    const payload = {
      active: true
    }
    try {
      await networkInstance.clientWithHeaders.shrineApi.updateGodItem(godId, payload);
      openNotification('success', { message: 'God item activated successfully!' })
      const _godItems = godItems.map((godItem) => godItem.id === godId ? { ...godItem, active: !godItem.active } : godItem);
      setGodItems(_godItems);
    } catch (error: any) {
      openNotification('error', { message: 'God item activation failed!', description: error.toString(), })
    }
  }, [godItems, networkInstance.clientWithHeaders.shrineApi])


  const deactivateGod = useCallback(async (godId: string) => {
    const payload = {
      active: false
    }
    try {
      await networkInstance.clientWithHeaders.shrineApi.updateGodItem(godId, payload);
      openNotification('success', { message: 'God deactivated successfully!' })
      const _godItems = godItems.map((godItem) => godItem.id === godId ? { ...godItem, active: !godItem.active } : godItem);
      setGodItems(_godItems);
    } catch (error: any) {
      openNotification('error', { message: 'God deactivation failed!', description: error.toString(), })
    }
  }, [godItems, networkInstance.clientWithHeaders.shrineApi])

  const columns = useMemo(() => [
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnail',
      render: (thumbnail: { type: string, url: string }) => <img src={thumbnail.url} height={50} width={50} alt={'god image'} />
    },
    {
      title: 'Attachments',
      dataIndex: 'attachment',
      render: (attachment: { type: string, url: string }) => <img src={attachment.url} height={50} width={50} alt={'god image'} />
    },
    {
      title: 'ID / Code',
      width: 500,
      dataIndex: 'id',
      render: (id: string, data: Shrine.Item) => {
        return (
          <div>
            <span>{id} <CopyOutlined style={{ fontSize: 20 }} onClick={() => { navigator.clipboard.writeText(id); info(); }} /></span>
            <br /><br />
            <span><strong>Code:</strong> {data.code}</span>
          </div>
        )
      }
    },
    {
      title: 'Access type',
      dataIndex: 'access_type'
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (isActive: boolean) => <Tag color={isActive ? 'green' : 'red'} > {isActive ? 'Yes' : 'No'}</Tag>
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (id: string, data: Shrine.Item) => <>
        <Switch checked={data.active} onClick={() => { data.active ? deactivateGod(id) : activateGod(id) }} checkedChildren={'Deactivate'} unCheckedChildren="Activate" />
        <Button type="link" onClick={() => { setEditItem(data); handleDrawerOpen(); }} > Edit </Button>
      </>
    },
  ], [activateGod, deactivateGod, handleDrawerOpen, info]);

  const tableOnChange = (pagination: TablePaginationConfig) => {
    setPagination(pagination)
  }

  const filterUi = useMemo(() => {
    return <Select
      value={selectedGodItem}
      style={{ width: 300 }}
      onChange={(val: GOD_ITEM_TYPES) => { storeSelectedGodItem(val); setPagination({ ...pagination, total: -1 }); setSelectedGodItem(val) }}
    >
      {GOD_ITEMS.map((item) => <Select.Option value={item}>{item}</Select.Option>)}
    </Select>
  }, [pagination, selectedGodItem, setPagination])

  const drawerProps = useMemo(() => ({ title: associateItemId ? 'Associate item to god' : editItem ? 'Update god item' : 'Create god item', visible: showDrawer, onClose: handleDrawerClose, width: 900 }), [associateItemId, editItem, handleDrawerClose, showDrawer])

  return (
    <>
      {contextHolder}
      <SocialScreenContainer
        title={'Shrine god items'}
        drawerProps={drawerProps}
        drawerContent={drawerContent}
        tableProps={{ columns: columns, dataSource: godItems, pagination: pagination, onChange: tableOnChange, size: 'small', scroll: { y: 650 } }}
        extra={
          <>
            <Button onClick={handleDrawerOpen}>Create god item</Button>
            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => navigate('/shrine/schedule?type=item')}>Items Schedule</Button>
          </>
        }
        filtersUI={filterUi}
      >
      </SocialScreenContainer>
    </>
  )
}

export default withDefaultLayout(TempleGodItems)
