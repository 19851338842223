import { Rashifal } from "@a4b/api/src/modules/Content/Rashifal/types";
import { SUPPORTED_LANGUAGES_LIST, getLanguageNameFromValue } from "@/utils/constants";
import { Button, Switch, TableColumnType, TablePaginationConfig, TableProps, Tag } from "antd";
import moment from "moment";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { openNotification } from "@/utils";
import { useAppContext } from "@/components/AppContext";
import { Sharetext } from "@a4b/api/src/modules/ShareText/types";
import TextArea from "antd/lib/input/TextArea";
import { data } from "../../../../../ui/molecules/Community/ListItem/data";

export default function useTableConfig<T extends Sharetext>(
  dataSource: T[],
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
  onEditShareText: (record: Sharetext) => void,
  onCloneShareText: (record: Sharetext) => void
): TableProps<any> {
  const { networkInstance } = useAppContext();
  const columns: TableColumnType<Sharetext>[] = [
    {
      title: 'S No',
      dataIndex: 'id',
      render(value, record, index) {
        return index + 1
      },
    },
    {
      title: 'Functionality Code',
      dataIndex: 'share_functionality_code',
    },
    {
      title: 'Language',
      dataIndex: 'language_code',
      render(value, record, index) {
        return SUPPORTED_LANGUAGES_LIST.find((language) => language.value === value)?.alt_name;
      },
    },
    {
      title: 'Identifier',
      dataIndex: 'identifier',
    },
    {
      title: 'To all share apps',
      dataIndex: 'is_applicable_to_all_share_apps',
      render(value, record, index) {
        return value ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>
      },
    },
    {
      title: 'share_app_ids',
      dataIndex: 'share_app_ids',
      render(value, record, index) {
        return value && value.map((appId: string) => <Tag>{appId}</Tag>)
      },
    },
    {
      title: 'Is platform media priority',
      dataIndex: 'is_platform_media_priority',
      render(value, record, index) {
        return value ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>
      },
    },
    {
      title: 'State',
      dataIndex: 'active',
      render(value, record, index) {
        return value ? <Tag color="green">Active</Tag> : <Tag color="red"> Inactive</Tag>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button type="link" onClick={() => { onEditShareText(record) }}>Edit</Button>
          <Button type="link" onClick={() => { onCloneShareText(record) }}>Clone</Button>
        </div>
      },
    }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }


  //@ts-ignore
  return {
    columns, dataSource: dataSource.map((row) => ({ key: row.id, ...row })), pagination, onChange: tableOnChange, expandable: {
      expandedRowRender: (record) => <div style={{ display: 'flex', gap: '20px' }}>
        <div style={{ flex: 1 }}>
          <p style={{ margin: 0 }}><strong> Share text</strong></p>
          <TextArea style={{ margin: 0, color: '#000', background: '#fff', height: '200Px' }} value={record.share_text} disabled />
        </div>
        <div style={{ flex: 1 }}>
          <p style={{ margin: 0 }}><strong> Share text plain</strong></p>
          <TextArea style={{ margin: 0, color: '#000', background: '#fff', height: '200Px' }} value={record.share_text_plain} disabled />
        </div>
      </div>,
      rowExpandable: (record) => record.share_functionality_code !== 'Not Expandable',
    }
  }
}