//context
import { useAppContext } from '@/components/AppContext'

//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { message } from 'antd'

//hooks
import { useEffect, useState, useCallback } from 'react'

//organisms
import ManageAstrologerStatus from '@a4b/ui/src/modules/monetisation/organisms/ManageAstrologerStatus'

//types
import { AstroDetails } from '@a4b/api/src/modules/Monetisation/Astrologer/types'

const AstrologerStatus = () => {
    const { networkInstance, countryState, userProfile } = useAppContext()
    const { monetisationAstroApi } = networkInstance.clientWithHeaders
    const [astroList, setAstroList] = useState<AstroDetails[]>()

    const getAgentList = useCallback(async () => {
        try {
            const agentListResponse = await monetisationAstroApi.agentApi.getAllAgents({
                limit: 100,
                offset: 0,
                email: userProfile?.email
            }, countryState?.countryCode)
            if (!agentListResponse?.data?.data) {
                message.info('No data found', 2)
                setAstroList([])
                return
            }
            setAstroList([agentListResponse?.data?.data?.[0]])
        } catch (error) {
            message.error('Error while fetching Agent', 3)
        }
    }, [monetisationAstroApi.agentApi, userProfile?.email, countryState?.countryCode])

    useEffect(() => {
        getAgentList()
    }, [getAgentList])


    const onSubmitHandler = async (value: any) => {
        try {
            await monetisationAstroApi.agentApi.updateAgentStatus(value?.id, { status: value?.status })
            message.success('Agent status updated', 2)
            getAgentList()
        } catch (error) {
            message.error('Error while updating Agent status', 2)
        }
    }

    return (
        <div style={{ padding: '55px 40px' }}>
            <ManageAstrologerStatus
                astroList={astroList!}
                onSubmit={onSubmitHandler}
            />
        </div>
    )
}

export default withDefaultLayout(AstrologerStatus)
