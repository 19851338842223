
import { Affirmative, Fallback, IJourneyLevels, Journey } from "../utils/types";
import JourneyLevel from './JourenyLevel';
import React from 'react';
export interface Props {
  journey: Journey;
}



const ViewJourney = ({ journey }: Props) => {
  const createJourneyLevels = (journeyLevels: IJourneyLevels, affirmative?: Affirmative | Journey | null, fallback?: Fallback | null,) => {
    if (affirmative === undefined) {
      return null;
    }
    if (affirmative) {
      affirmative.identifier = [-1, -1]; // just a random value for now
    }
    if (fallback) {
      fallback.identifier = [-1, -1]; // just a random value for now
    }
    journeyLevels.push([fallback, affirmative]);
    createJourneyLevels(journeyLevels, affirmative?.affirmative, affirmative ? affirmative.fallback : undefined,)
  }

  const journeyLevels: IJourneyLevels = [];
  createJourneyLevels(journeyLevels, journey,);



  return <div style={{
    backgroundSize: '5px 5px',
    padding: '20px',
    overflow: 'scroll',
  }}>
    {

      journey && <>
        {
          journeyLevels.map((level, index) => {
            return <JourneyLevel key={index} level={index} row={level} nextRow={index < journeyLevels.length ? journeyLevels[index + 1] : undefined} createNode={function (identifier: [number, number], nodeData: { type: 'affirmative'; values: Affirmative | null | undefined; } | { type: 'fallback'; values: Fallback | null | undefined; }): void {
              throw new Error('Function not implemented.');
            }} updateNode={function (identifier: [number, number], nodeData: Journey | Affirmative | Fallback): void {
              throw new Error('Function not implemented.');
            }} deleteNode={function (identifier: [number, number]): void {
              throw new Error('Function not implemented.');
            }} />
          })
        }
      </>
    }
  </div >
};

export default ViewJourney;