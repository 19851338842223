/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Form, Input, InputNumber, Popconfirm, Row, Rate } from 'antd'
import { PageListItem, ManageSectionItem } from '@a4b/api/src/modules/Monetisation/offerings/types'
import { Title } from '@design/Title'
import S3Upload from '../S3Upload'

const { TextArea } = Input

interface Props {
  onClose: () => void
  visible: boolean
  cdnUrl: string
  preSignUrl: string
  pageType: string
  formSubmithandler: (values: any) => void
  form: any
  initialValues: any
  pageTitle: string
  renderer: any
  currentPageTypeData: PageListItem | undefined
  showUpdateButton: boolean
  updatePages: () => void
  setDandDModalVisibility: (show: boolean) => void
  sortedSectionList: ManageSectionItem[]
  deleteFaq: (id: string, callback: any) => void
  deleteTutorials: (id: string) => void
  deleteSteps: (id: string, callback: any) => void
  deleteReview: (id: string, callback: any) => void
  formChangeHandler: (values: any) => void
}
const PageInfoForm: React.FC<Props> = ({
  onClose,
  visible,
  cdnUrl,
  preSignUrl,
  formSubmithandler,
  form,
  initialValues,
  deleteTutorials,
  deleteFaq,
  pageTitle,
  currentPageTypeData,
  deleteSteps,
  deleteReview,
  setDandDModalVisibility,
  sortedSectionList,
  renderer,
  showUpdateButton,
  updatePages,
  formChangeHandler
}) => {

  return (
    <Drawer
      size='large'
      title={pageTitle}
      placement='right'
      onClose={onClose}
      visible={visible}
    >
      <Form
        form={form}
        layout='vertical'
        name='create faqs'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onChange={formChangeHandler}
      >
        <Row>
          {(currentPageTypeData?.key === 'store_list_page_pooja') &&
            <>
              <Col span={24} style={{ paddingTop: '10px' }}>
                <Title level={4}>User reviews</Title>
              </Col>
              <Col span={24}>

                <Form.List name={['reviews']}>
                  {(fields, { add, remove, move }) => {

                    return (
                      <Row  >
                        {fields.map((field: any, index: number) => {
                          return (
                            <Col span={12} key={`reviews-${field.key}`}>
                              <Row
                                style={{
                                  border: '1px solid #e0dcdc',
                                  marginRight: '3px',
                                  marginBottom: '3px',
                                }}
                              >
                                <Col
                                  span={20}
                                  style={{
                                    marginLeft: '3px',
                                  }}
                                >
                                  <Form.Item
                                    label={`Review ${index + 1}`}
                                    name={[field.name, 'review']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input review!',
                                      },
                                    ]}
                                  >
                                    <TextArea placeholder='Enter review' />
                                  </Form.Item>
                                  <Form.Item
                                    label=''
                                    name={[field.name, 'user_name']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input username!',
                                      },
                                    ]}
                                  >
                                    <Input placeholder='Enter username' />
                                  </Form.Item>

                                  <Form.Item
                                    label={`User image`}
                                    name={[field.name, 'user_image_url']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                      {
                                        min: 1,
                                        max: 1,
                                        validator: async (
                                          rules: any,
                                          values: any,
                                        ) => {
                                          if (values?.length < rules.min) {
                                            return Promise.reject(
                                              'Please upload user image',
                                            )
                                          }
                                        },
                                      },
                                    ]}
                                    valuePropName='fileList'
                                  >
                                    <S3Upload
                                      cdnUrl={cdnUrl}
                                      preSignUrl={preSignUrl}
                                      min={1}
                                      max={1}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    label='Position'
                                    name={[field.name, 'position']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input position!',
                                      },
                                    ]}
                                  >
                                    <InputNumber placeholder='Enter position' />
                                  </Form.Item>
                                  <Form.Item
                                    label=''
                                    name={[field.name, 'rating']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input rating!',
                                      },
                                    ]}
                                  >
                                    <Rate />
                                  </Form.Item>
                                </Col>

                                <Col
                                  span={2}
                                  style={{
                                    alignSelf: 'start',
                                    paddingLeft: '10px',
                                    paddingTop: '5px',
                                    marginRight: '10px'
                                  }}
                                >


                                  <Popconfirm
                                    placement="topRight"
                                    title={"Please confirm to delete"}
                                    onConfirm={() => {
                                      if (!form.getFieldsValue()?.reviews?.[field.name]?.id) {
                                        remove(field.name)
                                      } else {
                                        deleteReview(form.getFieldsValue()?.reviews?.[field.name]?.id, () => {
                                          remove(field.name)
                                        })
                                      }
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button

                                      type='ghost'
                                      icon={<DeleteOutlined />}
                                      size='middle'
                                      danger
                                    ></Button>
                                  </Popconfirm>
                                </Col>
                              </Row>
                            </Col>
                          )
                        })}

                        <Col span={24} style={{ paddingTop: '10px' }}>
                          <Row justify='start'>
                            <Col span={4}>
                              <Button
                                onClick={() => {
                                  add()
                                }}
                                type='ghost'
                                icon={<PlusCircleOutlined />}
                                size='middle'
                              >
                                Add Review
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )
                  }}
                </Form.List>

              </Col>
            </>
          }
          {
            (currentPageTypeData?.key === 'store_list_page' ||
              currentPageTypeData?.key === 'cart_review_page' ||
              currentPageTypeData?.key === 'cart_review_page_pooja' ||
              currentPageTypeData?.key === 'store_list_page_pooja'
            ) && (
              <>
                <Col span={24}>
                  <Title level={4}>Generic Feature FAQs</Title>
                </Col>
                <Col span={24}>
                  <Form.List name={['faqs']}>
                    {(fields, { add, remove }) => {

                      return (
                        <Row>
                          {fields.map((field: any, index: number) => {
                            return (
                              <Col span={12} key={`fandq-${field.key}`}>
                                <Row
                                  style={{
                                    border: '1px solid #e0dcdc',
                                    marginRight: '3px',
                                    marginBottom: '3px',
                                  }}
                                >
                                  <Col
                                    span={20}
                                    style={{
                                      marginLeft: '3px',
                                    }}
                                  >
                                    <Form.Item
                                      label={`Question ${index + 1}`}
                                      name={[field.name, 'question']}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please input question!',
                                        },
                                      ]}
                                    >
                                      <Input placeholder='Enter question' />
                                    </Form.Item>
                                    <Form.Item
                                      label=''
                                      name={[field.name, 'answer']}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please input answer!',
                                        },
                                      ]}
                                    >
                                      <TextArea placeholder='Enter answer' />
                                    </Form.Item>
                                    <Form.Item
                                      label=''
                                      name={[field.name, 'position']}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please input position!',
                                        },
                                      ]}
                                    >
                                      <InputNumber placeholder='Enter position' />
                                    </Form.Item>
                                  </Col>

                                  <Col
                                    span={2}
                                    style={{
                                      alignSelf: 'start',
                                      paddingLeft: '10px',
                                      paddingTop: '5px',
                                    }}
                                  >
                                    <div style={{ paddingLeft: '5px' }}>
                                      <Popconfirm
                                        placement="topRight"
                                        title={"Please confirm to delete"}
                                        onConfirm={() => {

                                          deleteFaq(form.getFieldsValue()?.faqs?.[field.name]?.id, () => {

                                            remove(field.name)
                                          })
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Button

                                          type='ghost'
                                          icon={<DeleteOutlined />}
                                          size='middle'
                                          danger
                                        ></Button>
                                      </Popconfirm>

                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            )
                          })}

                          <Col span={24} style={{ paddingTop: '10px' }}>
                            <Row justify='start'>
                              <Col span={4}>
                                <Button
                                  onClick={() => {
                                    add()
                                    // addItem();
                                  }}
                                  type='ghost'
                                  icon={<PlusCircleOutlined />}
                                  size='middle'
                                >
                                  Add Question
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )
                    }}
                  </Form.List>
                </Col>
              </>
            )
          }

          {
            currentPageTypeData?.key === 'store_list_page' && (
              <>
                <Col span={24} style={{ paddingTop: '10px' }}>
                  <Title level={4}>Add Tutorial(Optional)</Title>

                </Col>
                <Col span={24}>

                  <Row>
                    <Col span={12} key={`tutorials`}>
                      <Row
                        style={{
                          border: '1px solid #e0dcdc',
                          marginRight: '3px',
                          marginBottom: '3px',
                        }}
                      >
                        <Col
                          span={20}
                          style={{
                            marginLeft: '3px',
                          }}
                        >
                          <Form.Item noStyle name={["tutorials", "id"]}>

                          </Form.Item>
                          <Form.Item
                            label='Title'
                            name={["tutorials", "title"]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                              {
                                required: false,
                                message: 'Please input title!',
                              },
                            ]}
                          >
                            <Input placeholder='Enter title' />
                          </Form.Item>
                          <Form.Item
                            label='Description'
                            name={['tutorials', 'description']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                              {
                                required: false,
                                message: 'Please input description!',
                              },
                            ]}
                          >
                            <Input placeholder='Enter description' />
                          </Form.Item>
                          <Form.Item
                            label={`Upload Media`}
                            name={['tutorials', 'media_url']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                              {
                                min: 1,
                                max: 1,
                                validator: async (
                                  rules: any,
                                  values: any,
                                ) => {
                                  if (values?.length < rules.min) {
                                    return Promise.reject(
                                      'Please upload user image',
                                    )
                                  }
                                },
                              },
                            ]}
                            valuePropName='fileList'
                          >
                            <S3Upload
                              cdnUrl={cdnUrl}
                              preSignUrl={preSignUrl}
                              min={1}
                              max={1}
                            />
                          </Form.Item>

                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={7}></Col>
                    <Col span={4}>
                      <Popconfirm
                        placement="topRight"
                        title={"Please confirm to delete"}
                        onConfirm={() => {
                          deleteTutorials(form.getFieldsValue()?.tutorials?.id)
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button

                          type='ghost'
                          icon={<DeleteOutlined />}
                          size='middle'
                          danger
                        >Delete tutorial</Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                </Col>
              </>
            )
          }
          {
            (currentPageTypeData?.key === 'payment_successful_page' ||
              currentPageTypeData?.key === 'payment_successful_page_pooja' ||
              currentPageTypeData?.key === 'payment_failed_page') && (
              <>
                <Col span={24} style={{ paddingTop: '10px' }}>
                  <Title level={4}>Add Steps</Title>
                </Col>
                <Col span={24}>
                  <Form.List name={['steps']}>
                    {(fields, { add, remove }) => {
                      return (
                        <Row>
                          {fields.map((field: any, index: number) => {
                            return (
                              <Col span={12} key={`steps-${field.key}`}>
                                <Row
                                  style={{
                                    border: '1px solid #e0dcdc',
                                    marginRight: '3px',
                                    marginBottom: '3px',
                                  }}
                                >
                                  <Col
                                    span={20}
                                    style={{
                                      marginLeft: '3px',
                                    }}
                                  >
                                    <Form.Item
                                      label={`Step ${index + 1}`}
                                      name={[field.name, 'step']}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please input step!',
                                        },
                                      ]}
                                    >
                                      <Input placeholder='Enter step' />
                                    </Form.Item>
                                    <Form.Item
                                    label='Position'
                                    name={[field.name, 'position']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input position!',
                                      },
                                    ]}
                                  >
                                    <InputNumber placeholder='Enter position' />
                                  </Form.Item>
                                  </Col>

                                  <Col
                                    span={2}
                                    style={{
                                      alignSelf: 'start',
                                      paddingLeft: '10px',
                                      paddingTop: '5px',
                                    }}
                                  >
                                    <div style={{ paddingLeft: '5px' }}>

                                      <Popconfirm
                                        placement="topRight"
                                        title={"Please confirm to delete"}
                                        onConfirm={() => {

                                          deleteSteps(form.getFieldsValue()?.steps?.[field.name]?.id, () => {

                                            remove(field.name)
                                          })
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Button

                                          type='ghost'
                                          icon={<DeleteOutlined />}
                                          size='middle'
                                          danger
                                        ></Button>
                                      </Popconfirm>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            )
                          })}

                          <Col span={24} style={{ paddingTop: '10px' }}>
                            <Row justify='start'>
                              <Col span={4}>
                                <Button
                                  onClick={() => {
                                    add()
                                    // addItem();
                                  }}
                                  type='ghost'
                                  icon={<PlusCircleOutlined />}
                                  size='middle'
                                >
                                  Add a Step
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )
                    }}
                  </Form.List>
                </Col>
              </>
            )
          }
          {
            (currentPageTypeData?.key == 'store_list_page_pooja'
              || currentPageTypeData?.key?.includes('banner_items')
              || currentPageTypeData?.key === 'srimandir_services_page'
              || currentPageTypeData?.key === 'banner_large_items_slp'
              || currentPageTypeData?.key === ('order_history_l2_page_offerings')
              || currentPageTypeData?.key === ('order_history_l2_page_pooja')
              || currentPageTypeData?.key === ('sankalp_banners_slp')
              || currentPageTypeData?.key === ('promotional_banners_v2_slp')
              || currentPageTypeData?.key === ('promotional_banners_slp')
            ) &&
            <Row>
              {sortedSectionList?.map((item: ManageSectionItem) => React.cloneElement(renderer, { data: item }))}
              <Col span={24} style={{ paddingTop: '10px' }} >
                <Button onClick={() => setDandDModalVisibility(true)} type='ghost' size='middle'>
                  Select Sections
                </Button>
              </Col>
            </Row>
          }
          {
            showUpdateButton && <Col span={24} style={{ paddingTop: '10px' }}>
              <Button onClick={formSubmithandler} type='ghost' size='middle'>
                Submit
              </Button>
            </Col>
          }
        </Row >
      </Form >
    </Drawer >
  )
}
export default PageInfoForm
