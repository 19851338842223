import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useAppContext } from '@/components/AppContext'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AudioPlaylist, AudioPlaylistCreateUpdateOptions, AudioThumbnail } from '@a4b/api';

import { MusicPlaylistCreateEditScreen, PlaylistCreateEditFormFields, SortableItemData } from '@a4b/ui/src/admin'
import { CDN_BASE_URL, NEW_CDN_HOST } from '@/utils/constants';
import { RcFile, UploadFileStatus } from 'antd/lib/upload/interface';
import { debounce } from 'lodash';
import { AudioUploadThumbnailOptions, SortableItemAudio } from '@/../../../packages/a4b-api/src/modules/Content/Audio/types';

const isProduction = process.env.REACT_APP_VIXEN_API_URL === 'https://api.a4b.io';


const MusicPlaylistCreateEdit = () => {
  const navigate = useNavigate();
  const { networkInstance, userProfile } = useAppContext();
  const { playlistId } = useParams()
  const [playlist, setPlaylist] = useState<AudioPlaylist>()
  const [audioItems, setAudioItems] = useState<SortableItemAudio[]>([])
  const [audioSearchItems, setAudioSearchItems] = useState<SortableItemAudio[]>([])
  const [thumbnail, setThumbnail] = useState<AudioThumbnail[]>([])
  const [searchParams, setSearchParams] = useSearchParams();
  const isClone = searchParams.get("clone")

  useEffect(() => {
    async function fetchPlaylistById() {
      if (!playlistId) {
        setPlaylist(undefined)
        return
      }
      const audioApi = networkInstance.clientWithHeaders.contentApi.audio

      const { data } = await audioApi.getAudioPlaylistById(playlistId)
      const playlist = data.data.playlist
      setPlaylist(playlist)

      const response = await audioApi.getAudiosForPlaylist(playlistId)
      const audios = response.data.data.audios
      console.log({ audios });
      const audioItems = playlist.audios.map((audioId: string) => {
        const audioItem = audios?.find((item) => item.id === audioId)
        if (!audioItem) return null
        return {
          id: audioId,
          imageUrl: `${NEW_CDN_HOST}/${audioItem.thumbnail_path}`,
          name: audioItem.audio_title.search,
          audio_language: audioItem.audio_language,
          audio_title: audioItem.audio_title.hi,
          partner_audio_unique_identifier: audioItem.partner_audio_unique_identifier,
          partner_name: audioItem.partner_name,
          riddler_audio_id: audioItem.riddler_audio_id,
          riddler_audio_type: audioItem.riddler_audio_type,
          riddler_god_id: audioItem.riddler_god_id,
          riddler_deeplink: audioItem.riddler_deeplink,
          video_share_path: audioItem.video_share_path,
          lyrics_path: audioItem.lyrics_path,
        }
      }).filter((item: SortableItemData | null) => item !== null) as SortableItemData[]
      setAudioItems(audioItems)
    }
    fetchPlaylistById()
  }, [networkInstance.clientWithHeaders.contentApi.audio, playlistId])

  const getOptionsForPlaylist = (values: PlaylistCreateEditFormFields): AudioPlaylistCreateUpdateOptions => {
    return {
      ...values,
      max_preview_count: parseInt(values.max_preview_count),
      playlist_order: parseInt(values.playlist_order || '0'),
      thumbnail_path: values.thumbnail_path ? values.thumbnail_path : '',
      audios: values.audios.map((audio) => audio.id)
    }
  }

  const handleSubmit = async (values: PlaylistCreateEditFormFields) => {
    const options = getOptionsForPlaylist(values)
    let response
    if (!playlistId || isClone === 'yes') { //create
      response = await networkInstance.clientWithHeaders.contentApi.audio.createPlaylist(options)
    } else {
      response = await networkInstance.clientWithHeaders.contentApi.audio.updatePlaylist(playlistId, options)
    }
    if (response?.status === 200) {
      navigate('/music/playlists');
      return { success: true, message: 'Successfully updated playlist' }
    }
    return { success: false, message: 'Failed to update playlist' }
  }



  const formData = useMemo((): Partial<PlaylistCreateEditFormFields> => {
    if (!playlist) return {
      audios: audioItems,
    }
    return {
      name: playlist.name,
      name_lang: playlist.name_lang,
      code: playlist.code,
      title: playlist.title,
      title_lang: playlist.title_lang,
      description: playlist.description,
      playlist_type: playlist.type,
      active: playlist.active,
      day_eligibility: playlist.day_eligibility,
      max_preview_count: playlist.max_preview_count.toString(),
      thumbnail_path: playlist.thumbnail_path,
      audios: audioItems,
      riddler_playlist_id: playlist.riddler_playlist_id,
      rddler_monthly_count: playlist.rddler_monthly_count,
      playlist_order: playlist.playlist_order.toString(),
      language_code: playlist.language_code,
      loki_playlist_id: playlist.loki_playlist_id,
      default_banner_titles: playlist.default_banner_titles,
      default_banner_image_path: playlist.default_banner_image_path,
      tab_image_path: playlist.tab_image_path,
      background_image_path: playlist.background_image_path,
      trending_filter: playlist.trending_filter,
      active_time: playlist.active_time,
    }
  }, [audioItems, playlist])

  const handleAddAudioItem = async (item: SortableItemAudio) => {
    const itemIndex = audioItems.findIndex(
      audioItem => audioItem.id === item.id,
    )
    if (itemIndex === -1) {
      audioItems.push(item)
      setAudioItems([...audioItems])
      return true
    }
    return false
  }

  const handleRemoveAudioItem = (item: SortableItemAudio) => {
    const updatedOrderingItems = audioItems.filter(
      audioItem => audioItem.id !== item.id,
    )
    setAudioItems(updatedOrderingItems)
  }

  const onItemSearch = useCallback(
    async (value: string) => {
      const { data } =
        await networkInstance.clientWithHeaders.contentApi.audio.searchAudios({
          text: value,
          state: 'PUBLISHED',
          limit: 30,
        })
      const audios = data.data.audios
      const searchItemData: SortableItemAudio[] = audios.map(audio => ({
        id: audio.id,
        name: audio.audio_title.hi,
        imageUrl: audio.thumbnail_path && audio.thumbnail_path !== '' ? `${NEW_CDN_HOST}${audio.thumbnail_path}` : null,
        audio_language: audio.audio_language,
        audio_title: audio.audio_title.hi,
        partner_audio_unique_identifier: audio.partner_audio_unique_identifier,
        partner_name: audio.partner_name,
        riddler_audio_id: audio.riddler_audio_id,
        riddler_audio_type: audio.riddler_audio_type,
        riddler_god_id: audio.riddler_god_id,
        riddler_deeplink: audio.riddler_deeplink,
        video_share_path: audio.video_share_path,
        lyrics_path: audio.lyrics_path,
      }))
      setAudioSearchItems(searchItemData)
    },
    [networkInstance.clientWithHeaders.contentApi.audio],
  )

  const getAudioByAudioIds = async (audioIds: string[]) => {
    try {
      const audioItemsRes = await Promise.all(audioIds.map((audioId) => networkInstance.clientWithHeaders.contentApi.audio.getAudioById(audioId)));

      audioItemsRes.forEach((audioRes) => {
        const audio = audioRes.data.data.audio;
        handleAddAudioItem({
          id: audio.id,
          name: audio.audio_title.hi,
          imageUrl: audio.thumbnail_path && audio.thumbnail_path !== '' ? `${NEW_CDN_HOST}${audio.thumbnail_path}` : null,
          audio_language: audio.audio_language,
          audio_title: audio.audio_title.hi,
          partner_audio_unique_identifier: audio.partner_audio_unique_identifier,
          partner_name: audio.partner_name,
          riddler_audio_id: audio.riddler_audio_id,
          riddler_audio_type: audio.riddler_audio_type,
          riddler_god_id: audio.riddler_god_id,
          riddler_deeplink: audio.riddler_deeplink,
          video_share_path: audio.video_share_path,
          lyrics_path: audio.lyrics_path,
        })
      })
    } catch (error) {

    }
  }

  useEffect(() => {
    onItemSearch('')
  }, [onItemSearch])

  const onSearchThumbnail = useCallback(
    async (code: string) => {
      const response = await networkInstance.clientWithHeaders.contentApi.audio.getThumbnailAudio({
        code
      })
      setThumbnail(response.data.data.thumbnails || [])
    },
    [networkInstance.clientWithHeaders.contentApi.audio],
  )

  const uploadThumbnailS3 = async (
    file: RcFile | Blob,
  ) => {
    const { data } =
      await networkInstance.clientWithHeaders.contentApi.audio.getUrlToUploadThumbnail()

    const s3SignedUploadUrl = data.data.url
    const path = data.data.path

    const headers = new Headers()
    headers.append('ContentType', file.type)

    await fetch(s3SignedUploadUrl, {
      method: 'PUT',
      body: file,
      headers,
    })
    return path
  }

  const uploadThumbnail = async (options: AudioUploadThumbnailOptions) => {
    try {
      const res = await networkInstance.clientWithHeaders.contentApi.audio.uploadThumbnail(options);
      return res.data.data.message
    } catch (error) {
      return 'Failed to upload thubnail';
    }
  }

  const uploadMedia = useCallback(async (options: any): Promise<{ url: string, path: string }> => {
    const headers = new Headers()
    headers.append('ContentType', options.file.type)
    let data;
    if (options.file.type.endsWith('png')) {
      data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_png');
    }
    if (options.file.type.endsWith('jpg')) {
      data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_jpg');
    }
    if (options.file.type.endsWith('jpeg')) {
      data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_jpeg');
    }
    if (options.file.type.endsWith('gif')) {
      data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_gif');
    }
    if (options.file.type.endsWith('webp')) {
      data = await networkInstance.clientWithHeaders.contentApi.audio.generatePresignedUrl('common_webp');
    }
    if (!data) {
      throw "Frailed to get presinged url"
    }

    const { url, path } = data.data.data;
    const res = await fetch(url, {
      method: 'PUT',
      body: options.file,
      headers,
    })
    return { url, path }
  }, [networkInstance.clientWithHeaders.contentApi.audio])


  const searchAudioDebounce = debounce(onItemSearch, 1000)
  return <div>
    <MusicPlaylistCreateEditScreen
      data={formData}
      thumbnail={thumbnail}
      onSearchThumbnail={onSearchThumbnail}
      uploadThumbnailS3={uploadThumbnailS3}
      uploadThumbnail={uploadThumbnail}
      searchItems={audioSearchItems}
      onAddAudioItem={handleAddAudioItem}
      onSearchAudio={searchAudioDebounce}
      onRemoveAudioItem={handleRemoveAudioItem}
      CDN_BASE_URL={CDN_BASE_URL}
      onSubmit={handleSubmit}
      getAudioByAudioIds={getAudioByAudioIds}
      uploadDefaultBannerImage={uploadMedia}
      uploadTabImage={uploadMedia}
      uploadBackgroundImage={uploadMedia}
      isClone={isClone === 'yes'}
    />
  </div>
}

export default withDefaultLayout(MusicPlaylistCreateEdit)