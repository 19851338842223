import { NudgeConfiguration } from "./types";
import { createGenericContext } from "./helpers";
import { SUPPORTED_LANGUAGES_LIST } from "@a4b/ui/src/modules/admin/constants";

export const URL_TYPES = new Set(["deeplink_cta"]);
export const LANGUAGE_TYPES = new Set(["text_exit", "title", "subtitle", "text_cta"]);
export const IMAGE_TYPES = new Set(["icon_url", 'image_url']);
export const NUMBER_TYPES = new Set(["index"]);
export const TEMPLATE_TYPES = new Set(["questions"]);
export const BOOLEANS_TYPES = new Set(["hide_dismiss_icon"]);

export const [useJourneyContext, JourneyContextProvider] = createGenericContext<{
    nudgeConfiguration: NudgeConfiguration,
    eventsAndProperties: any,
    commonEventProperties: any,
    reloadJourneys: () => void
}>();

export const NEXT_STATUS_CONFIG = {
    DRAFTED: 'PUBLISHED',
    PUBLISHED: 'DEPRECATED'
}

export enum JOURNEY_STATUS {
    DRAFTED = 'DRAFTED',
    PUBLISHED = 'PUBLISHED',
    DEPRECATED = 'DEPRECATED'
}

export const STATUS_COLOR_CONFIG = {
    [JOURNEY_STATUS.DRAFTED]: 'yellow',
    [JOURNEY_STATUS.PUBLISHED]: 'green',
    [JOURNEY_STATUS.DEPRECATED]: 'red'
}

export type NEXT_STATUS_CONFIG_KEYS = keyof typeof NEXT_STATUS_CONFIG;

export enum RUN_PARALLEL_ALWAYS_FILTER_TYPES {
    BOTH = 'Both',
    YES = 'Yes',
    NO = 'No'
}

export const LIVE_SUPPORTED_LANGUAGES = SUPPORTED_LANGUAGES_LIST.filter((lang) => lang.state === "live");
