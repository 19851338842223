import { Functionality } from "@a4b/api/src/modules/ShareText/types";
import { SUPPORTED_LANGUAGES_LIST } from "@a4b/ui/src/modules/admin/constants"
import { Button, Form, Input, Select } from "antd"
import { DebouncedFunc } from "lodash";

interface FiltersProps {
  selectedLanguage: string,
  setSelectedLanguage: (val: string) => void,
  shareFunctionlities: Functionality[] | null | undefined
  updateSelectedShareFunctionality: (val: string) => void
  selectedShareFunctionlityCode: string
  getShareFunctionalitiesDebounce: DebouncedFunc<(functionalityText: string, subFunctionalityText: string) => Promise<void>>
  identifier: string,
  updateIdentifier: (val: string) => void;
  reloadShareText: () => Promise<void>
}

export default function Filters(props: FiltersProps) {
  const {
    selectedLanguage,
    setSelectedLanguage,
    shareFunctionlities,
    getShareFunctionalitiesDebounce,
    updateSelectedShareFunctionality,
    selectedShareFunctionlityCode,
    identifier,
    updateIdentifier,
    reloadShareText
  } = props;

  return <Form layout="vertical" style={{ display: 'flex', gap: '20px', flexDirection: 'row' }}>
    <Form.Item label="Select language">
      <Select
        style={{ width: 200 }}
        onChange={setSelectedLanguage}
        value={selectedLanguage}
        showSearch
        optionLabelProp="label"
        optionFilterProp="label"
      >
        <Select.Option value={''}>--Clear--</Select.Option>
        {SUPPORTED_LANGUAGES_LIST.map((item) => <Select.Option key={item.code} label={item.alt_name} value={item.value}>{item.alt_name} ({item.name})</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      label={"Filter using functionality"}
    >
      <Select
        style={{ width: 300 }}
        onSearch={(val) => { if (val.length > 1) { getShareFunctionalitiesDebounce(val, '') } }}
        showSearch
        optionFilterProp="label"
        placeholder="Search using functionality"
        optionLabelProp="label"
        onChange={updateSelectedShareFunctionality}
        value={selectedShareFunctionlityCode}
      >
        <Select.Option value={""}>--Clear-- </Select.Option>
        {
          shareFunctionlities && shareFunctionlities.map((shareFunctionlity) => <Select.Option label={shareFunctionlity.code} value={shareFunctionlity.code}><div >
            {shareFunctionlity.code}
          </div></Select.Option>)
        }
      </Select>
    </Form.Item>
    <Form.Item
      label={"Identifier"}
    >
      <Input value={identifier} onChange={(e) => updateIdentifier(e.target.value)}></Input>
    </Form.Item>
    <Form.Item label=" ">
      <Button onClick={reloadShareText} type="primary">Apply Filters </Button>
    </Form.Item>
  </Form>
} 