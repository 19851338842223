import withDefaultLayout from '@/hoc/WithDefaultLayout'

import React from 'react'

//screens
import {
  ContentFilterFormFields,
  ContentList as ContentListScreen,
} from '@a4b/ui/src/admin'

//hooks
import useLibrary from '../../hooks/useLibrary'

interface Props { }

const defaultFilter: ContentFilterFormFields = {
  status: ['READY_TO_BE_MODERATED', 'PUBLISHED', 'DELETED'],
}

const ModeratorListing: React.FC<Props> = () => {
  const {
    loading,
    tags,
    totalCount,
    content,
    filter,
    onTagSearch,
    onEditContentClicked,
    onSendToQCClicked,
    onViewContentClicked,
    onPageChanged,
    onApplyFilterClicked,
    onAddNewContentClicked,
    onDeleteContentClicked,
    onSendToQCDraft
  } = useLibrary(defaultFilter, 'Moderator')

  return (
    <div>
      <ContentListScreen
        title='Moderator Library Content'
        tags={tags}
        onTagSearch={onTagSearch}
        loading={loading}
        filter={filter}
        data={content}
        total={totalCount}
        onPageChanged={onPageChanged}
        onEditContentClicked={onEditContentClicked}
        onSendToQCClicked={onSendToQCClicked}
        onViewContentClicked={onViewContentClicked}
        onApplyFilterClicked={onApplyFilterClicked}
        onAddNewContentClicked={onAddNewContentClicked}
        onDeleteContentClicked={onDeleteContentClicked}
        onSendToQCDraft={onSendToQCDraft}
      />
    </div>
  )
}

export default withDefaultLayout(ModeratorListing)
