import { SUPPORTED_LANGUAGES_LIST } from "@a4b/ui/src/modules/admin/constants";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, FormInstance, Input, InputNumber, Row, Select, Tag } from "antd"
import { useWatch } from "antd/lib/form/Form";
import FormList from "antd/lib/form/FormList";
import TextArea from "antd/lib/input/TextArea";
import { useMemo, useCallback } from "react";
import { useAppContext } from "../../../../../components/AppContext";
import SimpleImageUplaod from "../../../../../components/Forms/SimpleImageUpload";
import { REQUIRED_RULE } from "../../../../../utils/form.validation";
import styled from "styled-components";

const ContentContainer = styled.div`
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    &>.content-heading{
        position: absolute;
        top: -15px;
        height: 30px;
        width: 260px;
        border-radius: 5px;
        background: #fff;
        padding-left: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 18px;
    }
   
`

const ContentBox = styled.div`
    position: relative;
    border: 1px dotted #ccc;
    padding: 0px;
    border-radius: 5px;
    & > .language-name {
        position: absolute;
        top: -15px;
        right: 20px;
        height: 30px;
        border-radius: 5px;
        background: #fff;
        padding: 10px;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 15px;
    }

`

const CloseButton = styled.span`
    position: absolute;
    right: 0px;
    top: -5px;
    color: red;
    cursor: pointer;
    top: 5px;
    right: 10px;
    font-size: 20px;
`

const SubmitButton = styled(Button)`
    position: absolute;
    top: 10px;
    right: 10px;
`

const Seotag = styled(Tag)`
    color: #69b1ff;
    font-size: 11px;
`



interface AddShlokaProps {
    form: FormInstance<any>,
    formData?: any
}

const AddShloka = function (props: AddShlokaProps) {
    const { form, formData } = props;
    const { networkInstance } = useAppContext();
    const contents = useWatch('content', form);
    const usedLanguage = useMemo(() =>
        contents ?
            new Set(contents.map((content: any) => { return content?.language })) :
            new Set([])
        , [contents]);


    const s3UplaodApi = useCallback(async (options?: { type?: string }) => {
        let response = { url: '', path: '' };
        switch (options?.type) {
            case "image/png": {
                const res = await networkInstance.clientWithHeaders.gita.getUploadUrl('common_png')
                response = res.data.data;
                break;
            }
            case "image/jpg": {
                const res = await networkInstance.clientWithHeaders.gita.getUploadUrl('common_jpg')
                response = res.data.data;
                break;
            }
            case "image/jpeg": {
                const res = await networkInstance.clientWithHeaders.gita.getUploadUrl('common_jpeg')
                response = res.data.data;
                break;
            }
            case "image/gif": {
                const res = await networkInstance.clientWithHeaders.gita.getUploadUrl("common_gif")
                response = res.data.data
                break;
            }
            case "image/svg": {
                const res = await networkInstance.clientWithHeaders.gita.getUploadUrl("common_svg")
                response = res.data.data;
                break;
            }
            case "image/webp": {
                const res = await networkInstance.clientWithHeaders.gita.getUploadUrl("common_webp")
                response = res.data.data;
                break;
            }
            case "audio/mp3":
            case "audio/mpeg": {
                const res = await networkInstance.clientWithHeaders.gita.getUploadUrl("common_mp3")
                response = res.data.data;
                break;
            }
            case "video/mp4":
            case "video/mpeg": {
                const res = await networkInstance.clientWithHeaders.gita.getUploadUrl("common_mp4")
                response = res.data.data;
                break;
            }
            default:
                throw Error("File type is not supported ")
        }
        return response;
    }, [networkInstance.clientWithHeaders.gita])

    return <>
        <Row justify="space-between" gutter={[16, 16]}>

            <Form.Item hidden rules={REQUIRED_RULE} name={"chapter_no"} label={"Chapter no"}>
                <InputNumber disabled></InputNumber>
            </Form.Item>
            <Col span={12}>
                <Form.Item rules={REQUIRED_RULE} name={"shloka_start_no"} label={"Shlokas start no"}>
                    <InputNumber style={{ width: '100%' }}></InputNumber>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item rules={REQUIRED_RULE} name={"shloka_end_no"} label={"Shlokas end no"}>
                    <InputNumber style={{ width: '100%' }}></InputNumber>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item rules={REQUIRED_RULE} name={["meta", "title"]} label={<span> Title <Seotag>SEO</Seotag></span>}>
                    <Input></Input>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item rules={REQUIRED_RULE} name={["meta", "keywords"]} label={<span> Keywords <Seotag >SEO</Seotag></span>}>
                    <Select mode="tags">
                    </Select>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item rules={REQUIRED_RULE} name={["meta", "description"]} label={<span> Description <Seotag >SEO</Seotag></span>}>
                    <TextArea style={{ height: '80px' }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item rules={REQUIRED_RULE} name={"shloka"} label={"Shloka"}>
                    <TextArea style={{ height: '80px' }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <SimpleImageUplaod allowUploadUsingUrl rules={[...REQUIRED_RULE]} getS3PathApi={s3UplaodApi} name={['thumbnail_attachment']} form={form} />
            </Col>
            <Col span={12}>
                <SimpleImageUplaod allowUploadUsingUrl rules={REQUIRED_RULE} getS3PathApi={s3UplaodApi} name={['banner_attachment']} form={form} />
            </Col>
        </Row>
        <FormList name={"content"} >
            {
                (fields, { add, remove }, errors) => {
                    return <>
                        <ContentContainer>
                            <div className="content-heading">
                                Add Language wise content
                            </div>
                            <Row justify="space-between" gutter={[16, 16]}>
                                {fields.map((field, index) => {
                                    return <Col span={12}>
                                        <ContentBox>
                                            <div className="language-name">
                                                {SUPPORTED_LANGUAGES_LIST.find((language) => {
                                                    return form.getFieldValue(['content', index, 'language']) === language.value
                                                })?.alt_name}
                                            </div>
                                            <div style={{ padding: '10px 30px' }}>
                                                <Form.Item hidden rules={REQUIRED_RULE} name={[field.name, "language"]} label={"Language"}>
                                                    <Select size="small" >
                                                        {
                                                            SUPPORTED_LANGUAGES_LIST.map((language) => {
                                                                return <Select.Option value={language.value}
                                                                    disabled={usedLanguage.has(language.value)}
                                                                >
                                                                    {language.alt_name}
                                                                </Select.Option>
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item rules={REQUIRED_RULE} name={[field.name, "name"]} label={"Name"}>
                                                    <Input size="small"></Input>
                                                </Form.Item>
                                                <Form.Item rules={REQUIRED_RULE} name={[field.name, "transliterated_text"]} label={"Transliterated text"}>
                                                    <TextArea style={{ height: '80px' }} />
                                                </Form.Item>
                                                <Form.Item rules={REQUIRED_RULE} name={[field.name, "meaning"]} label={"Meaning"}>
                                                    <TextArea style={{ height: '80px' }} />
                                                </Form.Item>
                                                <Form.Item rules={REQUIRED_RULE} name={[field.name, "description"]} label={"Description"}>
                                                    <TextArea style={{ height: '80px' }} />
                                                </Form.Item>
                                                <Row justify="space-between" gutter={[10, 10]}>
                                                    <Col span={12}>
                                                        <SimpleImageUplaod getS3PathApi={s3UplaodApi} accept=".mp3" formListPath={['content', field.name, 'audio_attachment']} name={[field.name, 'audio_attachment']} allowUploadUsingUrl form={form} />
                                                    </Col>
                                                    <Col span={12}>
                                                        <SimpleImageUplaod getS3PathApi={s3UplaodApi} accept=".mp4" formListPath={['content', field.name, 'video_attachment']} name={[field.name, 'video_attachment']} allowUploadUsingUrl form={form} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </ContentBox>
                                    </Col>

                                })}
                            </Row>
                            {/* <AddContentButton type="dashed" onClick={() => add()}><PlusCircleOutlined /> Add more </AddContentButton> */}
                        </ContentContainer>
                    </>
                }
            }
        </FormList>
        {
            formData ?
                <SubmitButton type="primary" htmlType="submit"> Update Shloka</SubmitButton> :
                <SubmitButton type="primary" htmlType="submit"> Create Shloka</SubmitButton>
        }
    </>
}

export default AddShloka;