import React, { useEffect } from 'react'
import { useForm } from 'antd/lib/form/Form'
import { Button, Col, Drawer, Form, Input, InputNumber, Row, Select, Switch } from 'antd'
import AlpBanner from '../AlpBanner'
import { useAppContext } from '@/components/AppContext'

const { TextArea } = Input
const { Option } = Select
interface Props {
    visible: boolean
    onClose: () => void
    cdnUrl: string
    preSignUrl: string
    formSubmithandler: (values: any) => void
    form: any
    initialValues: any
    isEditJourney: boolean
}

const sectionTypes = [
    { value: 'data', label: 'Data' }
];

const countryCodes = [
    { value: 'IN', label: 'India' },
    { value: 'US', label: 'US' }
];

const AstrologerSectionCreation: React.FC<Props> = ({
    onClose,
    visible,
    cdnUrl,
    preSignUrl,
    formSubmithandler,
    form,
    initialValues,
    isEditJourney,
}) => {
    const { app } = useAppContext()
    const [createItemForm] = useForm()

    useEffect(() => {
        if (isEditJourney) {
            form.setFieldsValue({ ...initialValues });
        } else {
            form.resetFields()
            form.setFieldsValue({ ...initialValues, app_code: app })
        }
    }, [app, createItemForm, form, initialValues, isEditJourney])

    return (
        <Drawer
            title={`${isEditJourney ? "Update" : "Create New"}  Section`}
            placement='right'
            onClose={onClose}
            visible={visible}
            destroyOnClose={true}
            size='large'
        >
            <Form
                form={form}
                layout='vertical'
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                initialValues={initialValues}
            >
                <Col span={32}>
                    <Row style={{ width: '100%' }}>
                        <Form.Item
                            label='Title'
                            name='title'
                            labelCol={{ span: 22 }}
                            wrapperCol={{ span: 22 }}
                            style={{ width: '50%' }}
                            rules={[{ required: true, message: 'Enter title' }]}
                        >
                            <Input placeholder='Enter title' />
                        </Form.Item>
                        <Form.Item
                            label='Description'
                            name='description'
                            labelCol={{ span: 22 }}
                            wrapperCol={{ span: 22 }}
                            style={{ width: '50%' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter description of the category',
                                },
                            ]}
                        >
                            <TextArea placeholder='Enter description' rows={3} />
                        </Form.Item>
                    </Row>

                    <Row justify='space-between' style={{ width: '95%' }}>
                        <Form.Item
                            label='App code'
                            name='app_code'
                            labelCol={{ span: 22 }}
                            wrapperCol={{ span: 22 }}
                            style={{ width: '25%' }}
                        >
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            label='Country code'
                            name='country_code'
                            labelCol={{ span: 22 }}
                            wrapperCol={{ span: 22 }}
                            style={{ width: '25%' }}
                            rules={[
                                { required: true, message: 'Country code' },
                            ]}
                        >
                            <Select placeholder='Country code'>
                                {countryCodes?.map(item => (
                                    <Option key={item?.value} value={item?.value}>{item?.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Row>

                    <Row justify='space-between' style={{ width: '95%' }}>
                        <Form.Item
                            label='Type'
                            name='type'
                            labelCol={{ span: 22 }}
                            wrapperCol={{ span: 22 }}
                            style={{ width: '25%' }}
                            rules={[
                                { required: true, message: 'Please select type!' },
                            ]}
                        >
                            <Select placeholder='Please select type' >
                                {sectionTypes?.map(item => (
                                    <Option key={item?.value} value={item?.value}>{item?.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={`Position`}
                            name='position'
                            labelCol={{ span: 22 }}
                            wrapperCol={{ span: 22 }}
                            rules={[{ required: true, message: 'Please input position!' }]}
                        >
                            <InputNumber type={'number'} placeholder={`position`} />
                        </Form.Item>

                        <Form.Item
                            label={`Active`}
                            name={'active'}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input Section enable',
                                },
                            ]}
                            valuePropName='checked'
                        >
                            <Switch />
                        </Form.Item>
                    </Row>

                    <Form.Item
                        labelCol={{ span: 22 }}
                        wrapperCol={{ span: 22 }}
                        style={{ width: '105%' }}
                    >
                        <Form.List name={['data']}>
                            {(fields, { add, remove }) => {
                                return (
                                    <AlpBanner fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} />
                                )
                            }}
                        </Form.List>
                    </Form.Item>
                </Col>

                <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ paddingTop: '30px' }}
                >
                    {isEditJourney ? (
                        <Button type='primary' onClick={formSubmithandler}>
                            Update Section
                        </Button>
                    ) : (
                        <Button type='primary' onClick={formSubmithandler}>
                            Create Section
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </Drawer >
    )
}
export default AstrologerSectionCreation
