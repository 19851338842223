import React from 'react';
import { Form, Select } from 'antd';
import S3Upload from '@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload';
import { PUJA_PRESIGN_URL, YODA_CDN_URL } from '@/utils/constants';

const { Option } = Select;

type MediaPropertiesProps = {
    fieldPath: Array<string>;
};

const MediaProperties: React.FC<MediaPropertiesProps> = ({ fieldPath }) => {
    return (
        <div style={{ border: '1px #8c8c8c dashed', padding: '15px', borderRadius: '10px', marginBottom: '10px' }}>
            <Form.Item
                label="Media Type"
                name={[...fieldPath, 'media_type']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: 'Please select background color type!' }]}
            >
                <Select
                    placeholder="Select color type"
                >
                    <Option value="image">Image</Option>
                    <Option value="video">Video</Option>
                    <Option value="lottie">Lottie</Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="Media URL"
                name={[...fieldPath, 'media_url']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                valuePropName='fileList'
                rules={[
                    {
                        required: true,
                        min: 1,
                        max: 1,
                        validator: async (rules: any, values: any) => {
                            if (values?.length < rules.min) {
                                return Promise.reject('Please upload Media')
                            }
                        },
                    },
                ]}
            >
                <S3Upload
                    cdnUrl={YODA_CDN_URL}
                    preSignUrl={PUJA_PRESIGN_URL}
                    min={1}
                    max={1}
                />
            </Form.Item>
        </div>
    );
};

export default MediaProperties;
