import React, { useContext } from 'react'
import UserProfile from '@/entities/UserProfile'
import { A4BApi } from '@a4b/api'
import { UserRoles, LanguageState, CountryState } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { A4B_APPS_TYPES, USER_ROLES } from '../../utils/constants'

export interface NetworkInstance {
  clientWithHeaders: A4BApi
}
export interface AppContextInterface {
  app: A4B_APPS_TYPES | null
  setApp: (app: A4B_APPS_TYPES | null) => void
  userProfile: UserProfile
  networkInstance: NetworkInstance
  monetisationRoles?: UserRoles
  languageState: LanguageState,
  userRoles: USER_ROLES[],
  setLanguageContext: (language: LanguageState) => void
  countryState: CountryState
  setCountryState: (country: CountryState) => void
}

export const AppCtx = React.createContext<AppContextInterface | null>(null)

interface Props {
  initialState: AppContextInterface
}

export const AppCtxProvider: React.FC<Props> = ({ children, initialState }) => {
  return <AppCtx.Provider value={initialState}>{children}</AppCtx.Provider>
}

export const useAppContext = () => {
  const context = useContext(AppCtx)
  if (!context) {
    throw new Error('useAppContext must be used within a AppCtxProvider')
  }
  return context
}
