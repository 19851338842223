import { Rashifal } from "@a4b/api/src/modules/Content/Rashifal/types";
import { getLanguageNameFromValue } from "@/utils/constants";
import { Button, Switch, TableColumnType, TablePaginationConfig, TableProps, Tag } from "antd";
import moment from "moment";
import { DRAWER_TYPE } from "./useDrawerConfig";
import { openNotification } from "@/utils";
import { useAppContext } from "@/components/AppContext";
import { Functionality } from "@a4b/api/src/modules/ShareText/types";

export default function useTableConfig<T>(
  dataSource: T[],
  pagination: TablePaginationConfig,
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>,
  setDrawer: (Drawer: DRAWER_TYPE) => void,
  setEditFunctionality: React.Dispatch<React.SetStateAction<Functionality | null | undefined>>,
  createShareText: (functionalityId: string) => void
): TableProps<Functionality[]> {

  const { networkInstance } = useAppContext();

  const columns: TableColumnType<Functionality>[] = [
    {
      title: 'S No',
      dataIndex: 'id',
      render(value, record, index) {
        return index + 1
      },
    },
    {
      title: 'Functionality',
      dataIndex: 'functionality',
    },
    {
      title: 'Sub Functionality',
      dataIndex: 'sub_functionality'
    },
    {
      title: 'Version',
      dataIndex: 'version',
    },

    {
      title: 'Dynamic Share Keys',
      dataIndex: 'dynamic_share_keys',
      render(value, record, index) {
        return value && value.map((val: string) => <Tag >{val}</Tag>)
      },
    },

    {
      title: 'Is Freezed',
      dataIndex: 'freezed',
      render(value, record, index) {
        return value ? <Tag color="green">Yes</Tag> : <Tag color="red"> No</Tag>
      },
    },
    {
      title: 'Support Multiple Identification',
      dataIndex: 'support_multiple_identification',
      render(value, record, index) {
        return value ? <Tag color="green">Yes</Tag> : <Tag color="red"> No</Tag>
      },
    },

    {
      title: 'State',
      dataIndex: 'active',
      render(value, record, index) {
        return value ? <Tag color="green">Active</Tag> : <Tag color="red"> Inactive</Tag>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render(value, record, index) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button type="link" onClick={() => { setEditFunctionality(record); setDrawer(DRAWER_TYPE.EDIT_FUNCTIONALITY) }} >Edit</Button>
          <Button type="link" onClick={() => { createShareText(record.code) }} >Create share text for this item</Button>
        </div>
      },
    }
  ]

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }
  //@ts-ignore
  return { columns, dataSource, pagination, onChange: tableOnChange }
}