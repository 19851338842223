import React, { useEffect, useRef } from 'react';
import { Col, Modal, Row, Tabs, Typography } from 'antd';
import ReactPlayer from 'react-player';

interface Props {
    visible: boolean;
    batchData: any;
    handleModalClose: () => void;
    activeTab: any
    handleTabChange: (key: string) => void
}

const ModalPreview: React.FC<Props> = ({
    visible,
    batchData,
    handleModalClose,
    activeTab,
    handleTabChange,
}) => {

    const rawRef = useRef<ReactPlayer>(null)
    const processedRef = useRef<ReactPlayer>(null)


    useEffect(() => {
        if (!visible) {
            if (rawRef?.current) {
                rawRef?.current?.getInternalPlayer()?.pause?.();
            }
            if (processedRef?.current) {
                processedRef?.current?.getInternalPlayer()?.pause?.();
            }
        }
    }, [visible]);

    useEffect(() => {
        if (activeTab === '1' && processedRef?.current) {
            processedRef?.current?.getInternalPlayer()?.pause?.();

        } else if (activeTab === '2' && rawRef?.current) {
            rawRef?.current?.getInternalPlayer()?.pause?.();
        }
    }, [activeTab])

    return (
        <Modal
            visible={visible}
            onCancel={handleModalClose}
            footer={null}
            centered
            width={950}
            bodyStyle={{ height: '750px' }}
        >
            {batchData && (
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flex: 1, height: '100%', }}>
                        <Row justify='center' style={{ height: '100%' }}>
                            <Col span={24} style={{ justifySelf: 'center', height: '100%', position: 'relative' }}>
                                <Tabs activeKey={activeTab} onChange={handleTabChange} style={{ height: '100%' }}>
                                    <Tabs.TabPane tab="Raw Video" key="1" style={{ height: '100%' }}>
                                        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {batchData?.video_url ?
                                                < ReactPlayer
                                                    key={batchData?.video_url}
                                                    url={batchData?.video_url}
                                                    width='100%'
                                                    height='650px'
                                                    controls
                                                    ref={rawRef}
                                                />
                                                : <Typography.Text>No Video Available</Typography.Text>}
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Processed Video" key="2" style={{ height: '100%' }}>
                                        <div style={{ height: '100%', position: 'relative' }}>
                                            {batchData?.meta_data?.processed_video_url
                                                ? < ReactPlayer
                                                    key={batchData?.meta_data?.processed_video_url}
                                                    url={batchData?.meta_data?.processed_video_url}
                                                    width='100%'
                                                    height='650px'
                                                    controls
                                                    ref={processedRef}
                                                />

                                                : <Typography.Text>No Processed Video Available</Typography.Text>}
                                        </div>
                                    </Tabs.TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ flex: 1, padding: '0 20px', overflowY: 'auto', marginTop: '48px' }}>
                        <Row gutter={16}>
                            <Col span={24} style={{ marginBottom: '16px' }}>
                                <div style={{ border: '1px solid #d9d9d9', borderRadius: '4px', padding: '16px', backgroundColor: '#fafafa' }}>
                                    <Row>
                                        <Col span={8}>
                                            <Typography.Title level={5}>Batch ID :</Typography.Title>
                                        </Col>
                                        <Col span={16}>{batchData?.batch_id}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <Typography.Title level={5}>Batch Code :</Typography.Title>
                                        </Col>
                                        <Col span={16}>{batchData?.batch_code}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <Typography.Title level={5}>SKU Name :</Typography.Title>
                                        </Col>
                                        <Col span={16}>{batchData?.product_name}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <Typography.Title level={5}>No. of Items :</Typography.Title>
                                        </Col>
                                        <Col span={16}>{batchData?.no_of_items}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <Typography.Title level={5}>Temple Name :</Typography.Title>
                                        </Col>
                                        <Col span={16}>{batchData?.store_name}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <Typography.Title level={5}>Product Type :</Typography.Title>
                                        </Col>
                                        <Col span={16}>{batchData?.product_type}</Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col span={24} style={{ marginTop: '16px' }}>
                                <div style={{ border: '1px solid #d9d9d9', borderRadius: '4px', padding: '16px', backgroundColor: '#fafafa' }}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography.Title level={5}>Offerings Name List :</Typography.Title>
                                            <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                                                {batchData?.offering_names?.filter((item: string) => item?.trim() !== '')?.map((item: any, index: any) => (
                                                    <li style={{ marginBottom: '8px' }} key={index}>
                                                        {`${item}`}
                                                        {batchData?.meta_data?.quantity_list?.[index] &&
                                                            ` X ${batchData?.meta_data?.quantity_list[index]}`
                                                        }
                                                    </li>
                                                ))}
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ModalPreview;
