import { extractMediaUrls } from '@/utils/helper'
import moment from 'moment'

import { CreateCategoryPayload } from '@a4b/api/src/modules/Monetisation/Puja/types/categories'
import { Filter } from '@a4b/api/src/modules/Monetisation/Puja/types/filters'

export const enum VIEW_TYPES {
  JOURNEY = 'JOURNEY',
  LIST = 'LIST',
  GRID = 'GRID',
}
const categoryFormMediaKeys = ['media_url', 'bg_image']
const filterFormMediaKeys = ['media_url']

export const prepareFilterPayload = (data: Partial<Filter>) => {
  console.log(data?.journey_tag_metadata, 'meta data')
  const payload = {
    name: data?.name,
    name_en: data?.name_en,
    slug: data?.slug,
    priority: data?.priority,
    description: data?.description,
    description_en: data?.description_en,
    category_id: data?.category_id,
    image: data?.image?.[0],
    filter_type: data?.filter_type,
    meta_data: {
      ...data?.meta_data,
      date: moment(data?.meta_data?.date)?.unix(),
    },
    journey_tag_metadata: {
      ...extractMediaUrls(data?.journey_tag_metadata, filterFormMediaKeys),
      attached_store_ids: data?.journey_tag_metadata?.attached_store_ids,
    },
  }
  return payload
}

export const parseFilterData = (data: Partial<Filter>) => {
  const parsedData = {
    ...data,
    meta_data: {
      ...data?.meta_data,
      date: data?.meta_data?.date
        ? moment.unix(data?.meta_data?.date)
        : undefined,
    },
    image: [data?.image],
  }
  return parsedData
}

export const prepareCategoryPayload = (data: any): CreateCategoryPayload => {
  const payload = {
    ...data,
    image: data?.image?.[0],
    bg_img_url: data?.bg_img_url?.[0],
    journey_details: extractMediaUrls(
      data?.journey_details,
      categoryFormMediaKeys,
    ),
  }
  return payload
}
