import { Button, Checkbox } from "antd";
import FormItem from "antd/es/form/FormItem";
import Form, { useForm } from "antd/lib/form/Form";
import { ALLWAYS_ACTIVE_COLUMNS } from "../utils/constants";
import styled from 'styled-components'
interface ChangeColumnSettingProps {
    tableColumn: {
        [key: string]: boolean;
    },
    setTableColumns: React.Dispatch<React.SetStateAction<{
        [key: string]: boolean;
    }>>,
    onApplyCallback: () => void
}

const StyledButton = styled(Button)`
    position: fixed;
    top: 15px;
    right: 20px;
`


const ChangeColumnSetting = (props: ChangeColumnSettingProps) => {
    const { tableColumn, setTableColumns, onApplyCallback } = props;
    const form = useForm()[0];
    return <Form form={form} onFinish={(values) => {
        setTableColumns(values);
        onApplyCallback();
    }} initialValues={tableColumn}>
        {
            Object.keys(tableColumn).map((column) => {
                return <FormItem label={column} name={column} valuePropName="checked">
                    <Checkbox disabled={ALLWAYS_ACTIVE_COLUMNS.has(column)}></Checkbox>
                </FormItem>
            })
        }
        <StyledButton htmlType="submit" type={'primary'}> Apply </StyledButton>
    </Form>
}

export default ChangeColumnSetting;
