import { Col, Form, Input, Row, Select, Button } from "antd"
import React from "react"
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import { CreateStore } from "@a4b/api/src/modules/Monetisation/Puja/types"

interface Props {
    storeList: CreateStore[]
    cdnUrl: string
    preSignUrl: string
}

const { Option } = Select
const TodayImportance: React.FC<Props> = ({ storeList, cdnUrl, preSignUrl, }) => {
    return (
        <>
            <div style={{
                border: '1px solid #e0dcdc',
                marginRight: '0px',
                marginBottom: '3px',
                width: '35vw',
                padding: '10px',
                gap: '5px'
            }}>
                <Col style={{ width: '100%' }}>
                    <Form.Item
                        label={`Title`}
                        name={['data', 'today_importance', 'title']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: 'Enter Title',
                            },
                        ]}
                    >
                        <Input placeholder='Enter Title' />
                    </Form.Item>

                    <Form.Item
                        label={`Sub Title`}
                        name={['data', 'today_importance', 'sub_title']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: 'Enter subtitle',
                            },
                        ]}
                    >
                        <Input placeholder='Please type Sub Title' />
                    </Form.Item>

                    <Row style={{ width: '100%', display: 'flex' }}>
                        <Col style={{ flexBasis: '50%' }}>
                            <Form.Item
                                label={`Media type`}
                                name={['data', 'today_importance', 'media', 'media_type']}
                                labelCol={{ span: 22 }}
                                wrapperCol={{ span: 22 }}
                            >
                                <Select placeholder='Please Select Media type'>
                                    <Option value={'image'} key={'image'}>
                                        Image
                                    </Option>
                                    <Option value={'gif'} key={'gif'}>
                                        Gif
                                    </Option>
                                </Select>
                            </Form.Item>

                        </Col>

                        <Form.Item
                            label={`Media`}
                            name={['data', 'today_importance', 'media', 'media_url']}
                            labelCol={{ span: 24 }}
                            style={{ flexBasis: '50%' }}
                            rules={[
                                {
                                    required: true,
                                    min: 0,
                                    max: 1,
                                    validator: async (rules: any, values: any) => {
                                        if (values?.length < rules.min) {
                                            return Promise.reject('Please upload Media')
                                        }
                                    },
                                },
                            ]}
                            valuePropName='fileList'
                        >
                            <S3Upload
                                cdnUrl={cdnUrl}
                                preSignUrl={preSignUrl}
                                min={1}
                                max={1}
                            />
                        </Form.Item>

                    </Row>
                    <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Form.Item
                            label={`Offering Header`}
                            name={['data', 'today_importance', 'offerings_header']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ flexBasis: '48%' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Offering Headers',
                                },
                            ]}
                        >
                            <Input placeholder='Enter Offering Headers' />
                        </Form.Item>

                        <Form.Item
                            label={`Pooja Header`}
                            name={['data', 'today_importance', 'pooja_header']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ flexBasis: '50%' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Pooja Headers',
                                },
                            ]}
                        >
                            <Input placeholder='Enter Pooja Headers' />
                        </Form.Item>
                    </Row>
                    <Row style={{ width: '100%' }}>
                        <Form.Item
                            label='Offering Store Titles'
                            name={['data', 'today_importance', 'offering_store_ids']}
                            labelCol={{ span: 32 }}
                            wrapperCol={{ span: 32 }}
                            style={{ width: '100%' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Offering Stores',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                size='middle'
                                placeholder="Please select offering store titles"
                                // defaultValue={[]}
                                allowClear
                            >
                                {storeList?.filter((item: any) => (item?.service_type === 'offerings'))?.map((store: any) => (
                                    <Select.Option key={`${store?.id}-offering`} value={store?.id}>
                                        {store?.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Row>

                    <Row style={{ width: '100%' }}>
                        <Form.Item
                            label='Pooja Store Titles'
                            name={['data', 'today_importance', 'pooja_store_ids']}
                            labelCol={{ span: 32 }}
                            wrapperCol={{ span: 32 }}
                            style={{ width: '100%' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Pooja Stores',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                size='middle'
                                placeholder="Please select pooja store titles"
                                // defaultValue={[]}
                                allowClear
                            >
                                {storeList?.filter((item: any) => (item?.service_type === 'pooja'))?.map((store: any) => (
                                    <Select.Option key={`${store?.id}-pooja`} value={store?.id}>
                                        {store?.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Row>
                </Col>
            </div>
        </>
    )
}

export default TodayImportance