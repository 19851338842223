import PrivateRoute from '@/components/Routes/PrivateRoute'

//screens
import { Route, Routes } from 'react-router-dom'
import VideoUpload from '../container/VideoUpload'

const EpujaRoutes = () => {
  return (
    <Routes>
      <Route path='epuja' element={<PrivateRoute />}>
        <Route path='video-upload' element={<VideoUpload />} />
      </Route>
    </Routes>
  )
}

export default EpujaRoutes