import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import { COLORS } from '@utils/colors'

type TitleLevels = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

export interface Props {
  level: TitleLevels
  color?: string
  style?: CSSProperties
}
type HTLMHeadingProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>

const H1Title = styled.h1`
  font-size: 48px;
  font-weight: 700;
`

const H2Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
`

const H3Title = styled.h3`
  font-size: 22px;
  font-weight: 700;
`

const H4Title = styled.h4`
  font-size: 20px;
  font-weight: 700;
`

const H5Title = styled.h5`
  font-size: 18px;
  font-weight: 700;
`

const H6Title = styled.h6`
  font-size: 18px;
  font-weight: 700;
`

const H7Title = styled.h6`
  font-size: 16px;
  font-weight: 700;
`

const H8Title = styled.p`
  font-size: 16px;
  font-weight: 400;
`

const H9Title = styled.p`
  font-size: 14px;
  font-weight: 600;
`

const getComponentForTitleType = (
  level: TitleLevels,
  color: string,
  customStyle?: CSSProperties,
) =>
  function Header({ children }: HTLMHeadingProps) {
    const style = { color, ...customStyle }
    switch (level) {
      case 1: {
        return <H1Title style={style}>{children}</H1Title>
      }
      case 2: {
        return <H2Title style={style}>{children}</H2Title>
      }
      case 3: {
        return <H3Title style={style}>{children}</H3Title>
      }
      case 4: {
        return <H4Title style={style}>{children}</H4Title>
      }
      case 5: {
        return <H5Title style={style}>{children}</H5Title>
      }
      case 6: {
        return <H6Title style={style}>{children}</H6Title>
      }
      case 7: {
        return <H7Title style={style}>{children}</H7Title>
      }
      case 8: {
        return <H8Title style={style}>{children}</H8Title>
      }
      case 9: {
        return <H9Title style={style}>{children}</H9Title>
      }
      default: {
        return <H6Title style={style}>{children}</H6Title>
      }
    }
  }

export const Title: React.FC<Props> = ({
  level,
  children,
  color = COLORS.BLACK[90],
  style,
}) => {
  const Component = getComponentForTitleType(level, color, style)
  return <Component>{children}</Component>
}
