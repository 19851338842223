//context
import { useAppContext } from '@/components/AppContext'
//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import { message } from 'antd'
import { useForm } from 'antd/lib/form/Form'

//hooks
import { useEffect, useState, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CONTENT_TOI_PRESIGN_URL, CONTENT_CDN_URL } from '@/utils/constants'

//organisms
import CityCreateOrUpdate from '@a4b/ui/src/modules/monetisation/molecules/Temple/CityCreateOrUpdate'

//types
import { parseCityResponseData, CityFormObject, parseCityCreationPayload } from './helper'

const getPageBreadcrumbs = () => [
    {
        path: '/temples/manage-cities',
        breadcrumbName: 'Manage cities',
    },
    {
        path: '/temples/city',
        breadcrumbName: 'City Create',
    },
]

const ManageCityCreate = () => {
    const navigate = useNavigate()
    const [cityFormInstance] = useForm()
    const { networkInstance, languageState } = useAppContext()
    const { clientWithHeaders } = networkInstance
    const [searchParams] = useSearchParams()

    const [cityCode] = useState<string>(searchParams.get('city_code') as string)
    const [cityList, setCityList] = useState<CityFormObject>()

    const getCityByCode = useCallback(async () => {
        try {
            if (!cityCode) {
                return
            }
            const cityResponse = await clientWithHeaders.contentApi.temples.getCityById(cityCode)
            const parsedCityResponse = parseCityResponseData(cityResponse?.data?.data, languageState)
            // console.log('parsedCityResponse', parsedCityResponse)
            setCityList(parsedCityResponse)
            cityFormInstance.setFieldsValue(parsedCityResponse)
        } catch (error) {
            message.error('Error while fetching City', 3)
        }
    }, [cityCode, cityFormInstance, clientWithHeaders.contentApi.temples])

    useEffect(() => {
        getCityByCode()
    }, [getCityByCode])

    const handleCityCreateOrUpdate = async (values: any) => {
        if (cityCode) {
            //Update journey
            try {
                const cityPayload = parseCityCreationPayload(values)
                await clientWithHeaders.contentApi.temples.updateCity(cityCode, cityPayload)
                message.success('City updated successfully', 2)
                navigate("/temples/manage-cities")
            } catch (error) {
                message.error('Error while Creating City', 2)
            }
        } else {
            //Create journey
            try {
                const cityPayload = parseCityCreationPayload(values)
                await clientWithHeaders.contentApi.temples.createCity(cityPayload)
                message.success('City created successfully', 2)
                navigate("/temples/manage-cities")
            } catch (error) {
                message.error('Error while Creating City', 2)
            }
        }
    }

    const submitFormHandler = () => {
        cityFormInstance
            .validateFields()
            .then((values: any) => {
                handleCityCreateOrUpdate(values)
            })
            .catch(error => {
                console.log('City Creation error')
            })
    }

    return (
        <div style={{ padding: '0 40px' }}>
            <AdminBreadcrumb routes={getPageBreadcrumbs()} />
            <CityCreateOrUpdate
                cdnUrl={CONTENT_CDN_URL}
                preSignUrl={CONTENT_TOI_PRESIGN_URL}
                form={cityFormInstance}
                submitFormHandler={submitFormHandler}
            />
        </div>
    )
}

export default withDefaultLayout(ManageCityCreate)
