//types
import moment from 'moment'

import { CreateStore } from '@a4b/api/src/modules/Monetisation/Offerings/types'

export const parseStorList = (storeList: CreateStore[]) => {
  const list: any = []
  storeList
    ?.filter(item => item?.active === true)
    ?.map(store =>
      list.push({
        label: store?.title,
        value: store?.id,
      }),
    )
  return list
}

export const parseStoreData = (
  formData: any,
  languageState: any,
): CreateStore => {
  const infoMedia = formData?.info_media
    ?.map((item: any) => item.type === 'image' && item.media_url)
    .filter((item: string) => item)

  const video = formData?.info_media.filter(
    (item: any) => item?.type === 'video',
  )
  const closedInfoMedia = formData?.closed_info_media
    ?.map((item: any) => item.type === 'image' && item.media_url)
    .filter((item: string) => item)

  const closedVideo = formData?.closed_info_media?.filter(
    (item: any) => item?.type === 'video',
  )
  const slug: string = formData?.slug?.replace(/\s+/g, '-')

  const formObject: any = {
    slug: slug,
    title: formData?.title,
    sub_title: formData?.sub_title,
    display_title: formData?.display_title,
    short_description: formData?.short_description,
    long_description: formData?.long_description,
    hub_id: formData?.hub_id,
    icon_url: [formData?.icon_url],
    media_url: [formData?.cover_media?.media_url],
    coverMediaType: formData?.cover_media?.type,
    thumbnail: [formData?.cover_media?.media_thumbnail],
    closedCoverMediaType: formData?.closed_cover_media?.type,
    closed_media_url: [formData?.closed_cover_media?.media_url],
    position: formData?.position || 1,
    info_media_image: infoMedia,
    closed_info_media_image: closedInfoMedia,
    service_type: formData?.service_type,
    active: formData?.active,
    cta: {
      text: formData?.cta?.text,
      media: {
        media_type: formData?.cta?.media?.media_type,
        media_url: [formData?.cta?.media?.media_url],
        visible: formData?.cta?.media?.visible,
      },
    },
    item_image_url: [formData?.item_image_url],
    city: formData?.city,
    state: formData?.state,
    store_id: formData?.store_id,
    id: formData?.id,
    ...(formData?.id && {
      id: formData?.id,
    }),
    whatsapp_share_media: formData?.whatsapp_share_media?.media_url
      ? [formData?.whatsapp_share_media?.media_url]
      : null,
    whatsappMediaType: formData?.whatsapp_share_media?.type
      ? formData?.whatsapp_share_media?.type
      : null,
    language_code: languageState.monetisationLanguage,
    country_code: formData?.country_code,
    location_details: {
      temple_details: {
        ...formData?.location_details?.temple_details,
        image: [formData?.location_details?.temple_details?.image],
      },
      pandit_details: {
        ...formData?.location_details?.pandit_details,
        image: [formData?.location_details?.pandit_details?.image],
      },
      show_location_details: formData?.location_details?.show_location_details,
    },
    ...(formData?.closed_cover_media?.media_url && {
      closed_media_url: [formData?.closed_cover_media?.media_url],
    }),
    ...(formData?.certificate_image && {
      certificate_image: [formData?.certificate_image],
    }),
    ...(video?.[0]?.media_url && {
      info_media_video: [video?.[0]?.media_url],
    }),
    ...(closedVideo?.[0]?.media_url && {
      closed_info_media_video: [closedVideo?.[0]?.media_url],
    }),
    ...(formData?.start_datetime && {
      start_datetime: moment(
        moment(formData?.start_datetime).format('YYYY-MM-DD HH:mm:ss'),
      ).utcOffset(0),
    }),
    ...(formData?.end_datetime && {
      end_datetime: moment(
        moment(formData?.end_datetime).format('YYYY-MM-DD HH:mm:ss'),
      ).utcOffset(0),
    }),
    ...(formData?.record_type && {
      record_type: formData?.record_type,
    }),
    tags: formData?.tags?.length ? formData?.tags : [],
  }
  return formObject
}
