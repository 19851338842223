import { Descriptions } from "antd";

import { getReadableWidgetName } from "@/utils/helper";

interface Props {
  record: any;
}
const MiniMandirDataTable = ({ record }: Props) => {
  return (
    <div className="padding-left">
      <Descriptions
        size="small"
        title={getReadableWidgetName(record?.widget?.widget_type)}
        bordered
      >
        <Descriptions.Item label="Detault CTA Text">
          {record?.content?.default_cta_text}
        </Descriptions.Item>
        <Descriptions.Item label="Main CTA Text">
          {record?.content?.main_cta_text}
        </Descriptions.Item>
        <Descriptions.Item label="Item Count">
          {record?.content?.item_count}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};
export default MiniMandirDataTable;
