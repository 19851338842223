import moment from 'moment'

const formatDateAndTime = (dateAndTime: any) => {
  const momentObjStart = moment(dateAndTime, 'YYYY-MM-DD HH:mm:ss')
  const formattedDate = momentObjStart.format('YYYY-MM-DD')
  const formattedTime = momentObjStart.format('HH:mm:ss')
  return `${formattedDate}T${formattedTime}.000Z`
}

export const formatCreateOrEditStoreContent = (
  formData: any,
  initialValues: any,
  languageState: any,
): any => {
  //initialvalues
  const certificateImage = initialValues?.certificate_image?.[0]
  const closedInfoMedia = initialValues?.closed_info_media_image.map(
    (item: any) => ({
      media_url: item,
      type: 'image',
    }),
  )
  if (initialValues?.closed_info_media_video?.length) {
    closedInfoMedia.unshift({
      media_url: initialValues?.closed_info_media_video?.[0],
      type: 'video',
    })
  }

  const infoMedia = initialValues?.info_media_image.map((item: any) => ({
    media_url: item,
    type: 'image',
  }))
  if (formData?.initialValues?.length) {
    infoMedia.unshift({
      media_url: initialValues?.info_media_video?.[0],
      type: 'video',
    })
  }
  const slug: string = initialValues?.slug?.replace(/\s+/g, '-')

  //formData
  const formattedStartDate = formatDateAndTime(formData?.start_datetime)
  const formattedEndDate = formatDateAndTime(formData?.end_datetime)

  const formObject: any = {
    title: formData?.title,
    display_title: formData?.display_title,
    sub_title: formData?.sub_title,
    short_description: formData?.short_description,
    long_description: formData?.long_description,
    icon_url: formData?.icon_url[0],
    cover_media: {
      media_url: formData?.media_url[0],
      type: formData?.coverMediaType,
      media_thumbnail: formData?.thumbnail?.[0] ?? null,
    },
    cta: {
      type: 'redirection',
      color: 'green',
      text: formData?.cta?.text,
      media: {
        media_type: formData?.cta?.media?.media_type,
        media_url: formData?.cta?.media?.media_url?.[0],
        visible: formData?.cta?.media?.visible,
      },
    },
    position: formData?.position || 1,
    item_image_url: formData?.item_image_url?.[0],
    record_type: 'schedule',
    start_datetime: formattedStartDate,
    end_datetime: formattedEndDate,
    active: formData?.active,
    country_code: formData?.country_code,
    tags: formData?.tags?.length ? formData?.tags : [],

    //intialvalues
    store_id: initialValues?.store_id,
    slug: slug,
    hub_id: initialValues?.hub_id,
    info_media: infoMedia,
    closed_cover_media: {
      media_url: initialValues?.closed_media_url[0],
      type: initialValues?.closedCoverMediaType,
    },
    closed_info_media: closedInfoMedia,
    city: initialValues?.city,
    state: initialValues?.state,
    id: initialValues?.id,
    certificate_image: certificateImage,
    service_type: initialValues?.service_type,
    language_code: languageState.monetisationLanguage,
    location_details: {
      temple_details: {
        name: initialValues?.location_details?.temple_details?.name,
        location: initialValues?.location_details?.temple_details?.location,
        description:
          initialValues?.location_details?.temple_details?.description,
        image: initialValues?.location_details?.temple_details?.image?.[0],
      },
      pandit_details: {
        name: initialValues?.location_details?.pandit_details?.name,
        location: initialValues?.location_details?.pandit_details?.location,
        description:
          initialValues?.location_details?.pandit_details?.description,
        image: initialValues?.location_details?.pandit_details?.image?.[0],
      },
      show_location_details:
        initialValues?.location_details?.show_location_details,
    },
    ...(initialValues?.whatsapp_share_media?.[0]
      ? {
          whatsapp_share_media: {
            media_url: initialValues?.whatsapp_share_media?.[0],
            type: initialValues?.whatsappMediaType,
          },
        }
      : {
          whatsapp_share_media: { media_url: '', type: '' },
        }),
  }
  return formObject
}
