import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ImageUploadRes, AddChapterPayload, AddShlokaPayload, UpdateChapterPayload, UpdateShlokaPayload, Chapter } from './types'
import { apiClient } from '../../apiClient'
const trasformCreateChapterPayload = (payload: AddChapterPayload) => {
    const transformedPayload: any = { ...payload }
    transformedPayload.content = {}
    payload.content.forEach((content) => {
        transformedPayload.content[content.language] = {
            name: content.name,
            description: content.description
        }
    })
    transformedPayload.meta.keywords = transformedPayload.meta.keywords.toString();
    return transformedPayload;
}

const trasformCreateShlokaPayload = (payload: AddShlokaPayload) => {
    const transformedPayload: any = { ...payload }
    transformedPayload.content = {}
    payload.content.forEach((content) => {
        transformedPayload.content[content.language] = {
            ...content
        }
    })
    transformedPayload.meta.keywords = transformedPayload.meta.keywords.toString();
    return transformedPayload;
}

class Gita {
    private api: AxiosInstance

    constructor(config: AxiosRequestConfig) {
        this.api = apiClient.create(config)
    }
    // Write all the get calls below 

    getShloka = async () =>
        this.api.get<any>('gw1/content/internal/gita/v1/chapter/1/shloka/2')

    getChapter = async (id: string) =>
        this.api.get<{ data: { chapter: Chapter } }>('gw1/content/internal/gita/v1/chapter/' + id)



    getUploadUrl = async (type: 'common_jpg' | 'common_jpeg' | 'common_gif' | 'common_png' | 'common_webp' | 'common_svg' | 'common_mp3' | 'common_mp4') =>
        this.api.get<ImageUploadRes>('content/internal/v1/audio/upload/' + type)



    getChapters = async () =>
        this.api.get<{ data: { chapters: Chapter[] } }>('gw1/content/internal/gita/v1/chapter')



    getChapterShlokas = async (id: string) =>
        this.api.get<any>(`gw1/content/internal/gita/v1/chapter/${id}/shloka`)


    // Write all the post calls below
    addChapter = (payload: AddChapterPayload) => {

        return this.api.post(
            'gw1/content/internal/gita/v1/chapter',
            trasformCreateChapterPayload(payload),
        )
    }

    addShloka = (payload: AddShlokaPayload) => {
        return this.api.post(
            'gw1/content/internal/gita/v1/shloka',
            trasformCreateShlokaPayload(payload),
        )
    }

    // Write all the patch calls below 


    // Write all the put calls below 

    updateChapter = (id: string, payload: UpdateChapterPayload) => {
        return this.api.put('gw1/content/internal/gita/v1/chapter/' + id, trasformCreateChapterPayload(payload))
    }

    updateShloka = (id: string, payload: UpdateShlokaPayload) => {
        return this.api.put('gw1/content/internal/gita/v1/shloka/' + id, trasformCreateShlokaPayload(payload))
    }

}

export default Gita;