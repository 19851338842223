import { Button, Form, Popconfirm, Select } from 'antd'

import React, { useEffect } from 'react'
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import { AudioContentState } from '@a4b/api/src/modules/Content/Audio/types'
import { PLAYLIST_TYPES, SUPPORTED_LANGUAGES_LIST } from '../../../constants'

export interface PlaylistFilters {
  playlistType?: string,
  language?: string,
}

export interface Props {
  filter?: PlaylistFilters,
  onApplyFilterClicked: (filter: PlaylistFilters) => void,
}

const { useForm } = Form

const PlaylistFilter: React.FC<Props> = ({
  filter,
  onApplyFilterClicked
}) => {
  const [form] = useForm<PlaylistFilters>()

  useEffect(() => {
    if (filter) form.setFieldsValue(filter)
  }, [filter])

  const handleApplyFilter = (values: PlaylistFilters) => {
    onApplyFilterClicked(values)
  }

  const handleApplyFilterFailed = (
    errorInfo: ValidateErrorEntity<PlaylistFilters>,
  ) => {
    console.log({ errorInfo })
  }


  return (
    <Form
      form={form}
      layout={'inline'}
      onFinish={handleApplyFilter}
      onFinishFailed={handleApplyFilterFailed}
      style={{ display: 'flex', padding: '20px', alignItems: 'center' }}
    >
      {/* <Form.Item label='Title' name='name'>
        <Input placeholder='Content Title' />
      </Form.Item> */}
      <Form.Item label='Playlist type' name='playlist_type'>
        <Select style={{ width: '200px' }} placeholder={"Filter by playlist"} >
          {PLAYLIST_TYPES.map((type: string) => <Select.Option key={type} value={type}>
            {type}
          </Select.Option>
          )}
        </Select>
      </Form.Item>
      <Form.Item
        label='Language'
        name='language'
      >
        <Select style={{ width: '200px' }} placeholder={"Filter by language"} >
          {SUPPORTED_LANGUAGES_LIST.map(language =>
            <Select.Option key={language.value} value={language.value}>
              {language.alt_name} ({language.name})
            </Select.Option>
          )}
        </Select>
      </Form.Item>
      <Form.Item style={{ margin: '10px' }}>
        <Popconfirm
          placement="topLeft"
          title={<div style={{ fontSize: 16 }}>Are you sure you want to fetch those playlist records? <br /> <strong>Avoid using this repetitively!!</strong></div>}
          onConfirm={() => { form.submit() }}
          okText="Continue"
          cancelText="Cancel"
        >
          <Button type='primary' htmlType='submit'>
            Generate playlist CSV files
          </Button>
        </Popconfirm>
      </Form.Item>
    </Form>
  )
}

export default PlaylistFilter;
