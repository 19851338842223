import React, { useCallback, useRef, useState } from 'react'
import { Radio, Form, Button, Upload, RadioChangeEvent, Row, Col, Input, message, Space, Typography, Alert } from 'antd'
import { v4 as uuidv4 } from 'uuid';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';

//types
import { AttachmentServiceResponse } from '@/modules/social/types';
import { UploadFile } from 'antd/lib/upload/interface';
import styled from 'styled-components';



type MediaFormat = 'image' | 'video' | 'audio' | 'pdf'

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

const getDataForUpload = (mediaFormat: MediaFormat) => {
  const ref_content_code = `cdn_upload_${uuidv4()}`
  const media_type = mediaFormat.toUpperCase()
  const video_source = mediaFormat === 'video' ? 'INTERNAL_CUSTOM' : undefined
  return {
    ref_content_code,
    media_type,
    video_source
  }
}

const Container = styled.div`
  max-width: 800px;
  margin: 20px auto;
`

const UploadButton = () => (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
)

interface Props {
  uploadUrl: string,
  cdnHost: string
}

const CDNUpload: React.FC<Props> = ({ uploadUrl, cdnHost }) => {
  const [mediaFormat, setMediaFormat] = useState<MediaFormat>('image')
  const [file, setFile] = useState<UploadFile<AttachmentServiceResponse>>()

  const handleMediaChange = useCallback((e: RadioChangeEvent) => {
    setMediaFormat(e.target.value)
  }, [])

  const handleUploadChange = (info: UploadChangeParam<UploadFile<AttachmentServiceResponse>>) => {
    if (!info.file || !info.file.response) {
      return
    }
    setFile(info.file)

  }

  const handleCopyClick = async () => {
    const cdnUrl = getCdnUrl()
    if (!cdnUrl) {
      return
    }
    try {
      await navigator.clipboard.writeText(cdnUrl)
      message.success('copied to clipboard')
    } catch (error) {
      message.error('failed to clipboard')
    }
  }

  const handleClearUpload = () => {
    setFile(undefined)
  }

  const getCdnUrl = () => {
    if (!file || !file.response) {
      return
    }
    return cdnHost + '/' + file.response.data.content.s3_path
  }

  const getAcceptParams = () => {
    if (mediaFormat === 'pdf') {
      return 'application/pdf'
    }
    return `${mediaFormat}/*`
  }

  const cdnUrl = getCdnUrl()
  return (
    <Container>
      {/* <Typography.Title level={4}>CDN Upload </Typography.Title> */}
      <Alert message="This is no more supported. Please reach to dev team if required." type="error" />
      <></>
      {/* <Space direction='vertical' style={{ width: '100%' }} size={'large'}>
        <Radio.Group onChange={handleMediaChange} value={mediaFormat}>
          <Radio value={'image'}>Image</Radio>
          <Radio value={'video'}>Video</Radio>
          <Radio value={'audio'}>Audio</Radio>
          <Radio value={'pdf'}>PDF</Radio>
        </Radio.Group>
        <Upload.Dragger
          maxCount={1}
          disabled={file ? true : false}
          fileList={file ? [file] : undefined}
          multiple={false}
          action={uploadUrl}
          accept={getAcceptParams()}
          data={() => getDataForUpload(mediaFormat)}
          name='input_file'
          listType='picture'
          onChange={handleUploadChange}
        >
          <UploadButton />
        </Upload.Dragger>
        {
          cdnUrl &&
          <Space direction='vertical' size={'large'} style={{ width: '100%' }}>
            <div style={{ display: 'flex' }}>
              <Input value={cdnUrl} readOnly style={{ flexGrow: 1, marginRight: '10px' }} />
              <Button icon={<CopyOutlined />} onClick={handleCopyClick} />
            </div>
            <Button type='primary' onClick={handleClearUpload}>Clear</Button>
          </Space>
        }
      </Space> */}

    </Container>
  )
}

export default CDNUpload