import { NavMenuItem } from '../types'

const menuRenderConfig: NavMenuItem[] = [
  {
    title: 'Content',
    id: 'content',
    children: [
      {
        title: 'Library',
        id: 'library',
        linkItem: [
          {
            name: 'Creator',
            path: '/content/creator-listing',
            id: '/content/creator-listing',
          },
          {
            name: 'Moderator',
            path: '/content/moderator-listing',
            id: '/content/moderator-listing',
          },
          {
            name: 'Tag',
            path: '/content/tags?type=tags',
            id: '/content/tags',
          },
          {
            name: 'Tag Order Schedule',
            path: '/content/tag-schedule',
            id: '/content/tags-schedule',
          },
          {
            name: 'Rashifal',
            path: '/content/rashifal',
            id: '/content/rashifal',
          }
        ],
      },
      {
        title: 'Music',
        id: 'music',
        linkItem: [
          {
            name: 'List',
            path: '/music/listing',
            id: '/music/listing',
          },
          {
            name: 'Playlists',
            path: '/music/playlists',
            id: '/music/playlists',
          },
          {
            name: 'Playlist Create',
            path: '/music/playlists/create',
            id: '/music/playlists/create',
          },
          {
            name: 'Video Template',
            path: '/music/video-template',
            id: '/music/video-template'
          },
          {
            name: 'Suggestion Cards',
            path: '/music/suggestion-cards',
            id: '/music/suggestion-cards'
          },
          {
            name: 'Thumbnails',
            path: '/music/thumbnails',
            id: '/music/thumbnails'
          }
        ],
      },
      {
        title: 'Translations',
        id: 'translations',
        linkItem: [
          {
            name: 'Services',
            path: '/translations/services',
            id: '/translations/services',
          }
        ]
      },
      {
        title: 'Temples',
        id: 'temples',
        linkItem: [
          {
            name: 'Manage temples',
            path: '/temples/manage-temples',
            id: '/temples/manage-temples',
          },
          {
            name: 'Manage Cities',
            path: '/temples/manage-cities',
            id: '/temples/manage-cities',
          },
        ]
      },
      {
        title: 'Misc',
        id: 'misc',
        linkItem: [
          {
            name: 'Music upload',
            path: '/misc/music-upload',
            id: '/misc/music-upload',
          },
        ],
      },
      {
        title: "Gita",
        id: 'gita',
        linkItem: [
          {
            name: 'Chapters',
            path: '/gita/chapters',
            id: '/gita/chapters'
          }
        ]
      },
    ]
  },
  {
    title: 'Config Feeds',
    id: 'feeds',
    linkItem: [
      {
        name: 'Widgets',
        path: '/feeds/widgets',
        id: 'feeds/widgets',
      },
      {
        name: 'Schedules',
        path: '/feeds/schedule',
        id: 'feeds/schedule',
      },
    ],
  },
  {
    title: 'Shrine',
    id: 'shrine',
    linkItem: [
      {
        name: 'Gods',
        path: '/shrine/gods',
        id: '/shrine/gods',
      },
      {
        name: 'Items',
        path: '/shrine/god-items',
        id: '/shrine/god-items',
      },
      {
        name: 'Stories',
        path: '/shrine/stories',
        id: '/shrine/stories',
      },
      {
        name: 'Action Cards',
        path: '/shrine/action-cards',
        id: '/shrine/action-cards',
      },
      {
        name: 'Schedule',
        path: '/shrine/schedule',
        id: '/shrine/schedule',
      }
    ]
  },
  {
    title: 'Shares',
    id: 'share-text',
    linkItem: [
      {
        name: 'Share app',
        path: '/share-text/app',
        id: '/share-text/app',
      },
      {
        name: 'Share functionality',
        path: '/share-text/functionality',
        id: '/share-text/functionality'
      },
      {
        name: 'Share text',
        path: '/share-text/listing',
        id: '/share-text/listing'
      }
    ]
  },
  {
    title: 'In app nudges',
    id: 'in-app-nudges',
    linkItem: [
      {
        name: ' Journey',
        path: '/in-app-nudges/journey',
        id: '/in-app-nudges/journey',
      },
      {
        name: 'Nudges',
        path: '/in-app-nudges/nudges',
        id: '/in-app-nudges/nudges',
      },
      {
        name: 'Dynamic Cohort',
        path: '/in-app-nudges/dynamic-cohort',
        id: '/in-app-nudges/dynamic-cohort',
      }
    ]
  },
  {
    title: 'Events management',
    id: 'events-management',
    linkItem: [
      {
        name: 'Events list',
        path: '/events-management/list',
        id: '/events-management/list',
      },
    ]
  },
  {
    title: "Coins",
    id: "coins",
    linkItem: [
      {
        name: "Manage quiz",
        path: "/coins/quiz",
        id: "/coins/quiz"
      }
    ]
  },
  {
    title: "AB experiments",
    id: "ab-experiments",
    linkItem: [
      {
        name: "AB experiments manager",
        path: "/ab-experiments/manage",
        id: "/ab-experiments/manage"
      }
    ]
  },
  {
    title: 'Social.1.5',
    id: 'social.1.5',
    linkItem: [
      {
        name: 'Community tag',
        path: '/social.1.5/community/tag',
        id: '/social.1.5/community/tag',
      },
      {
        name: 'Content tag',
        path: '/social.1.5/content/tag',
        id: '/social.1.5/content/tag',
      },
      {
        name: 'Communities',
        path: '/social.1.5/communities',
        id: '/social.1.5/communities',
      },
      {
        name: 'Messages',
        path: '/social.1.5/messages',
        id: '/social.1.5/messages',
      },
      {
        name: 'Admins',
        path: '/social.1.5/admins',
        id: '/social.1.5/admins',
      },
    ],
  },
]

export default menuRenderConfig
