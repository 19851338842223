import { throwError } from "../../../utils/helper";

class MiniMandir {
  /* sole purpose of variables declared below are 
  documentation reference purpose only */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  default_cta_text!: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  main_cta_text!: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  item_count!: number;

  getSerializedData = (widgetFormData: MiniMandir) => {
    return widgetFormData;
  };
  getDeSerializedData = (widgetFormData: MiniMandir) => {
    return widgetFormData;
  };

  defaultCtaValidator = async (rule: any, value: any) => {
    if (!value) throwError("Default CTA required!");
  };
  mainCtaValidator = async (rule: any, value: any) => {
    if (value === undefined) throwError("Main CTA required!");
    if (value < 0) throwError("Negetive values not allowed.");
  };
  godIdValidator = async (rule: any, value: any) => {
    if (!value) throwError("God ID required!");
  };
}
export default MiniMandir;
export const MiniMandirInstance = new MiniMandir();
